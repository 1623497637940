import React, { useMemo } from 'react';
import { FilterSectionType } from '../../../enum/FilterSectionType';
import { FormControlCounterService } from '../../../../../components/forms/components/form-builder/services/FormControlCounterService';

interface Props {
  type: FilterSectionType;
  activeControlsCount: number;
  hasActiveControlsCount?: boolean;
}

const FilterSectionTitle: React.FC<Props> = props => {
  const titleText = useMemo(() => {
    const count =
      props.hasActiveControlsCount &&
      FormControlCounterService.getActiveControlsCountsTitleByCount(props.activeControlsCount);

    return count ? `${props.type} ${count}` : props.type;
  }, [props.type, props.hasActiveControlsCount, props.activeControlsCount]);

  return <>{titleText}</>;
};

export default FilterSectionTitle;
