import { RoutePath } from '../enums/RoutePath';
import * as H from 'history';
import { match as Match } from 'react-router-dom';
import { isEqual } from 'lodash';
import { LocationQueryStringService } from './LocationQueryStringService';

export class NavUrlService {
  static isActiveInPaths(paths: RoutePath[]): (match: Match, location: H.Location) => boolean {
    return (match, location): boolean => paths.includes(location.pathname as RoutePath);
  }

  static isExactQueryParams(routePath: RoutePath): (match: Match, location: H.Location) => boolean {
    return (match, location): boolean => {
      if (!match) {
        return false;
      }

      const routePathQueryParams = LocationQueryStringService.getParsedUrlFromRoutePath(routePath).query;
      const currentQueryParams = LocationQueryStringService.getSearchDataFromQueryString(location.search);

      return isEqual(routePathQueryParams, currentQueryParams);
    };
  }
}
