import { RangeSelectorButtonType } from '../enums/RangeSelectorButtonType';
import { RangeSelectorButtonsOptions } from 'highcharts/highstock';

export const rangeSelectorButtons: { [key in RangeSelectorButtonType]: RangeSelectorButtonsOptions } = {
  [RangeSelectorButtonType.Day]: { type: RangeSelectorButtonType.Day, count: 5, text: '5d' },
  [RangeSelectorButtonType.Month]: { type: 'month', count: 1, text: '1m' },
  [RangeSelectorButtonType.ThreeMonth]: { type: 'month', count: 3, text: '3m' },
  [RangeSelectorButtonType.SixMonth]: { type: 'month', count: 6, text: '6m' },
  [RangeSelectorButtonType.YTD]: { type: RangeSelectorButtonType.YTD, count: 1, text: 'YTD' },
  [RangeSelectorButtonType.Year]: { type: RangeSelectorButtonType.Year, count: 1, text: '1y' },
  [RangeSelectorButtonType.TwoYear]: { type: RangeSelectorButtonType.Year, count: 2, text: '2y' },
  [RangeSelectorButtonType.ThreeYear]: { type: RangeSelectorButtonType.Year, count: 3, text: '3y' },
  [RangeSelectorButtonType.FiveYear]: { type: RangeSelectorButtonType.Year, count: 5, text: '5y' },
  [RangeSelectorButtonType.All]: { type: RangeSelectorButtonType.All, text: 'All' },
};
