import { FilterControlSettings } from '../../../types/FilterControlSettings';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { ControlSize } from '../../../../../enums/ControlSize';
import { PrependValueType } from '../../../../../components/forms/enums/PrependValueType';
import { CheckboxType } from '../../../../../components/forms/Checkbox';
import { rcRatingOptions } from '../../options/rcRatingOptions';

export const renaissanceRatingControls: FilterControlSettings = {
  [ViewFilterName.RcRating]: {
    type: FormControlType.CheckboxList,
    name: ViewFilterName.RcRating,
    props: {
      name: ViewFilterName.RcRating,
      type: CheckboxType.Checkbox,
      options: rcRatingOptions,
    },
  },
  [ViewFilterName._RatingFundamentals]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._RatingFundamentals,
    props: {
      label: 'Fundamentals',
      minName: ViewFilterName.RatingFundamentalsMin,
      maxName: ViewFilterName.RatingFundamentalsMax,
      minPrepend: [PrependValueType.Min],
      maxPrepend: [PrependValueType.Max],
      min: 1,
      max: 5,
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._RatingCorpGov]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._RatingCorpGov,
    props: {
      label: 'Corporate Governance',
      minName: ViewFilterName.RatingCorpGovMin,
      maxName: ViewFilterName.RatingCorpGovMax,
      minPrepend: [PrependValueType.Min],
      maxPrepend: [PrependValueType.Max],
      min: 1,
      max: 5,
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._RatingValuation]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._RatingValuation,
    props: {
      label: 'Valuation',
      minName: ViewFilterName.RatingValuationMin,
      maxName: ViewFilterName.RatingValuationMax,
      minPrepend: [PrependValueType.Min],
      maxPrepend: [PrependValueType.Max],
      min: 1,
      max: 5,
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._RatingTechnicals]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._RatingTechnicals,
    props: {
      label: 'Technicals',
      minName: ViewFilterName.RatingTechnicalsMin,
      maxName: ViewFilterName.RatingTechnicalsMax,
      minPrepend: [PrependValueType.Min],
      maxPrepend: [PrependValueType.Max],
      min: 1,
      max: 5,
      size: ControlSize.Small,
    },
  },
};
