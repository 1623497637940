import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ModalShowState } from '../../../../../../components/modal/types/ModalShowState';
import { useDeepEffect } from '../../../../../../effects/useDeepEffect';
import { ViewContext } from '../../ViewContext';
import ViewModal from '../view-modal/ViewModal';
import ViewFilterHeader from './ViewFilterHeader';
import { FilterSettings } from '../../../../../filters/interfaces/FilterSettings';
import ViewFilterAdvanced from './ViewFilterAdvanced';
import FilterSections from '../../../../../filters/components/filter-sections/FilterSections';
import { ViewFilterService } from '../../services/ViewFilterService';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../../../../../enums/RoutePath';

interface Props {
  modalShowState: ModalShowState;
  hideSaveAndRun?: boolean;
}

const ViewFilterModal: React.FC<Props> = props => {
  const { state } = useContext(ViewContext);

  const [defaultFilters, setDefaultFilters] = useState(state.defaultFilters);
  const [saveFilters, setSaveFilters] = useState(state.filters);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);

  const isResetDisabled = useMemo(() => ViewFilterService.isResetDisabled(saveFilters, state.defaultFilters), [
    state.defaultFilters,
    saveFilters,
  ]);

  const onReset = useCallback(() => {
    setDefaultFilters({ ...state.defaultFilters });
  }, [state.defaultFilters]);

  const onHide = useCallback(() => {
    setDefaultFilters(state.filters);
  }, [state.filters]);

  const onAdvancedClick = useCallback(() => setIsAdvanced(isAdvancedPrev => !isAdvancedPrev), []);

  const onFiltersChange = useCallback(filters => {
    setSaveFilters(filters);
  }, []);

  const onValidationStateChange = useCallback(isValid => {
    setIsValidForm(isValid);
  }, []);

  const FooterNote = () => {
    return (
      <div className="advanced-text">
        Note: For more advanced filters, please use the{' '}
        <Link to={RoutePath.ScreensAdd} target="_blank">
          IPO screen
        </Link>
      </div>
    );
  };

  useDeepEffect(() => {
    setDefaultFilters(state.filters);
  }, [state.filters]);

  return (
    <ViewModal
      modalShowState={props.modalShowState}
      header={<ViewFilterHeader isAdvanced={isAdvanced} />}
      hasAdvanced={state.options.toolbar?.modalFilters?.hasAdvancedBtn}
      body={
        <FilterSections
          settings={state.options.toolbar?.modalFilters as FilterSettings}
          defaultValues={defaultFilters}
          dictionaries={state.filtersDictionaries}
          isAdvanced={isAdvanced}
          onChange={onFiltersChange}
          onValidationStateChange={onValidationStateChange}
        />
      }
      footerAdditionalAction={
        state.options.toolbar?.modalFilters?.hasAdvancedBtn && (
          <ViewFilterAdvanced isAdvanced={isAdvanced} onAdvancedClick={onAdvancedClick} />
        )
      }
      hideSaveAndRun={props.hideSaveAndRun}
      viewSaveData={{ filters: saveFilters }}
      isResetDisabled={isResetDisabled}
      isRunDisabled={!isValidForm}
      isSaveAndRunDisabled={!isValidForm}
      onReset={onReset}
      onHide={onHide}
      footerInHeader
      footerNote={isAdvanced && <FooterNote />}
    />
  );
};

export default ViewFilterModal;
