import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { ScreensLocationData } from './constants/ScreensLocationData';
import { useCallback, useMemo, useState } from 'react';
import { ScreensLocationOptionsService } from '../services/ScreensLocationOptionsService';
import { ViewEvents } from '../../../../../shared/entities/view/components/view/interfaces/options/ViewEvents';
import { ViewState } from '../../../../../shared/entities/view/components/view/store/types';

export function useScreensLocationData(baseOptions: Partial<ScreenOptions>): ScreensLocationData {
  const [options, setOptions] = useState(baseOptions);

  const onNewView = useCallback((state: ViewState) => {
    setOptions(prevOptions => ScreensLocationOptionsService.getByFilters(prevOptions, state.filters));
  }, []);

  const onFiltersChange = useCallback((state: ViewState) => {
    setOptions(prevOptions => ScreensLocationOptionsService.getOnFiltersChange(prevOptions, state.filters));
  }, []);

  const events = useMemo<ViewEvents>(
    () => ({
      onNewView,
      onFiltersChange,
    }),
    [onNewView, onFiltersChange],
  );

  return useMemo(() => ({ options, events }), [options, events]);
}
