import React, { ReactNode, useCallback, useContext, useState } from 'react';
import './ViewModal.scss';
import Modal, { ModalSize } from '../../../../../../components/modal/Modal';
import ViewModalFooter from './components/ViewModalFooter';
import { ViewModalAction } from './enums/ViewModalAction';
import { ModalShowState } from '../../../../../../components/modal/types/ModalShowState';
import { useClassName } from '../../../../../../hooks/useClassName';
import { ViewModalBlock } from './viewModalBem';
import { ViewContext } from '../../ViewContext';
import { ViewSaveData } from './interfaces/ViewSaveData';
import SaveViewModalForm from '../view-toolbar/save-view/form/SaveViewModalForm';
import { updateViewByDataChange } from '../../store/actions/view';
import classNames from 'classnames';

interface Props {
  modalShowState: ModalShowState;
  header: ReactNode;
  body: ReactNode;
  footerAdditionalAction?: ReactNode;
  viewSaveData: Partial<ViewSaveData>;
  isResetDisabled: boolean;
  isSaveAndRunDisabled?: boolean;
  isRunDisabled?: boolean;
  onReset: Function;
  onHide: Function;
  footerInHeader?: boolean;
  footerNote?: ReactNode;
  hasAdvanced?: boolean;
  hideSaveAndRun?: boolean;
}

const ViewModal: React.FC<Props> = props => {
  const { onReset, onHide } = props;

  const cn = useClassName(ViewModalBlock.Root);

  const { dispatch } = useContext(ViewContext);

  const [isModalShow, setIsModalShow] = props.modalShowState;
  const isSaveModalShowState = useState(false);
  const [, setIsSaveModalShow] = isSaveModalShowState;

  const closeModals = useCallback((): void => {
    setIsModalShow(false);
    setIsSaveModalShow(false);
  }, [setIsModalShow, setIsSaveModalShow]);

  const onRunAction = useCallback((): void => {
    dispatch(updateViewByDataChange(props.viewSaveData));

    closeModals();
  }, [closeModals, props.viewSaveData, dispatch]);

  const onSaveAndRunAction = useCallback(() => {
    setIsModalShow(false);
    setIsSaveModalShow(true);
  }, [setIsModalShow, setIsSaveModalShow]);

  const onResetAction = useCallback(() => {
    onReset();
  }, [onReset]);

  const onModalAction = useCallback(
    action => {
      if (action === ViewModalAction.Run) {
        onRunAction();
      } else if (action === ViewModalAction.SaveAndRun) {
        onSaveAndRunAction();
      } else if (action === ViewModalAction.Reset) {
        onResetAction();
      }
    },
    [onRunAction, onSaveAndRunAction, onResetAction],
  );

  const onSaveViewModalHide = useCallback(() => {
    setIsSaveModalShow(false);
    setIsModalShow(true);
  }, [setIsSaveModalShow, setIsModalShow]);

  const onViewModalHide = useCallback(() => {
    // Reset modal to view state(filters, columns)
    // As we don't want to store state clicked by user
    onHide();

    closeModals();
  }, [closeModals, onHide]);

  return (
    <>
      <Modal
        className={classNames(
          cn({
            ['hasAdvanced']: props.hasAdvanced,
            ['footerInHeader']: props.footerInHeader,
          }),
        )}
        size={ModalSize.ExtraLarge}
        header={
          <>
            {props.header}
            {props.footerInHeader && (
              <ViewModalFooter
                isResetDisabled={props.isResetDisabled}
                isSaveAndRunDisabled={props.isSaveAndRunDisabled}
                isRunDisabled={props.isRunDisabled}
                hideSaveAndRun={props.hideSaveAndRun}
                additionalAction={props.footerAdditionalAction}
                onAction={onModalAction}
                footerNote={props.footerNote}
              />
            )}
          </>
        }
        body={
          <>
            {props.body}
            <ViewModalFooter
              isResetDisabled={props.isResetDisabled}
              isSaveAndRunDisabled={props.isSaveAndRunDisabled}
              isRunDisabled={props.isRunDisabled}
              hideSaveAndRun={props.hideSaveAndRun}
              additionalAction={props.footerAdditionalAction}
              onAction={onModalAction}
              footerNote={props.footerNote}
            />
          </>
        }
        show={isModalShow}
        onHide={onViewModalHide}
      />

      <SaveViewModalForm
        showState={isSaveModalShowState}
        viewSaveData={props.viewSaveData}
        onHide={onSaveViewModalHide}
      />
    </>
  );
};

export default ViewModal;
