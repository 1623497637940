import React from 'react';
import './AdditionalMenuListItem.scss';
import { RoutePath } from '../../enums/RoutePath';
import { NavLink } from 'react-router-dom';

interface Props {
  title: string;
  routePath: RoutePath;
}

const AdditionalMenuListItem: React.FC<Props> = props => {
  return (
    <li className="AdditionalMenuListItem">
      <NavLink to={props.routePath}>{props.title}</NavLink>
    </li>
  );
};

export default AdditionalMenuListItem;
