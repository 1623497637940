import React, { useContext, useCallback, useMemo } from 'react';
import CompanyInvestmentRationaleTerm, { CompanyInvestmentRationaleTermProps } from './CompanyInvestmentRationaleTerm';
import { CompanyContext } from '../context/CompanyContext';
import './CompanyInvestmentRationaleTerms.scss';
import CompanySectionRow from '../shared/CompanySectionRow';
import { Nullable } from '../../../shared/types/generics';
import { InvestmentRationaleDto } from '../../interfaces/InvestmentRationaleDto';
import { CompanyTab } from '../../enums/CompanyTab';

const CompanyInvestmentRationaleTerms: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const data: Nullable<InvestmentRationaleDto> = getTabData(CompanyTab.InvestmentRationale);

  const getIsTermEmpty = useCallback(
    (term: CompanyInvestmentRationaleTermProps[] | undefined) => term && !term.length,
    [],
  );

  const isAllTermsEmpty = useMemo(
    () => getIsTermEmpty(data?.ratings.longTerm) && getIsTermEmpty(data?.ratings.shortTerm),
    [data, getIsTermEmpty],
  );

  const swapFirstTwoItems = (first: any) => {
    const temp = first[0];
    first[0] = first[1];
    first[1] = temp;
  };

  // Assuming that there will always be “Company Fundamentals” and “Corporate Governance” in the array data.ratings.longTerm
  // and that there will be “Relative Valuation” and “Group Technicals” in the array data.ratings.shortTerm
  // Then if the first item of data.ratings.longTerm is not "Company Fundamentals", then swap it with the second item
  // and if the first item of data.ratings.shortTerm is not "Relative Valuation", then swap it with the second item
  if (data && data.ratings) {
    if (data.ratings.longTerm && data.ratings.longTerm.length === 2) {
      if (data.ratings.longTerm[0].ratingTypeName !== 'Company Fundamentals') {
        swapFirstTwoItems(data.ratings.longTerm);
      }
    }

    if (data.ratings.shortTerm && data.ratings.shortTerm.length === 2) {
      if (data.ratings.shortTerm[0].ratingTypeName !== 'Relative Valuation') {
        swapFirstTwoItems(data.ratings.shortTerm);
      }
    }
  }

  return (
    <>
      {data?.ratings && !isAllTermsEmpty && (
        <CompanySectionRow className="CompanyInvestmentRationaleTerms">
          <div className="CompanyInvestmentRationaleTerms__block">
            <p className="CompanyInvestmentRationaleTerms__title">Long Term</p>

            {data.ratings.longTerm.map((item: CompanyInvestmentRationaleTermProps, index: number) => (
              <CompanyInvestmentRationaleTerm key={index} {...item} />
            ))}
          </div>

          <div className="CompanyInvestmentRationaleTerms__block">
            <p className="CompanyInvestmentRationaleTerms__title">Short Term</p>

            {data.ratings.shortTerm.map((item: CompanyInvestmentRationaleTermProps, index: number) => (
              <CompanyInvestmentRationaleTerm key={index} {...item} />
            ))}
          </div>
        </CompanySectionRow>
      )}
    </>
  );
};

export default CompanyInvestmentRationaleTerms;
