import { isNil } from 'lodash';
import { CompanyFinancialsTermFilterName } from '../enums/CompanyFinancialsTermFilterName';
import { Nullable } from '../../../../shared/types/generics';
import { CompanyFinancialsDto } from '../../../interfaces/CompanyFinancialsDto';
import { CompanyFinancialsLocationFilterName } from '../enums/CompanyFinancialsLocationFilterName';
import { CompanyFinancialsLocationDto } from '../../../interfaces/CompanyFinancialsLocationDto';
import { SimpleGridProps } from '../../../../shared/components/grid/simple-grid/SimpleGrid';
import { SelectOption } from '../../../../shared/services/select/interfaces/SelectOption';
import { companyFinancialsTermOptions } from '../options/companyFinancialsTermOptions';
import { getCompanyFinancialsLocationOptions } from '../options/getCompanyFinancialsLocationOptions';

export class CompanyFinancialOptionsService {
  static getTermsValues(data: Nullable<CompanyFinancialsDto>): CompanyFinancialsTermFilterName[] {
    if (!data) {
      return [];
    }

    const filterNames = Object.values(CompanyFinancialsTermFilterName);

    return filterNames.filter(filterName => {
      const filterData = data[filterName];

      if (!filterData) {
        return false;
      }

      const filterDataValues = Object.values(filterData);

      return filterDataValues.some(filterDataValue => !isNil(filterDataValue));
    });
  }

  static getTermsOptions(data: Nullable<CompanyFinancialsDto>): SelectOption[] {
    const termsValues = this.getTermsValues(data);

    // If there's only one termsValues (Annual or Quarterly), don't show the label, as there's only one tab in this case.
    if (termsValues.length === 1) return [];

    return companyFinancialsTermOptions.filter(option =>
      termsValues.includes(option.value as CompanyFinancialsTermFilterName),
    );
  }

  static getLocationValues(
    termData?: CompanyFinancialsLocationDto<SimpleGridProps[]>,
  ): CompanyFinancialsLocationFilterName[] {
    if (!termData) {
      return [];
    }

    const filterNames = Object.values(CompanyFinancialsLocationFilterName);

    return filterNames.filter(filterName => !!termData[filterName]);
  }

  static getLocationOptions(termData?: CompanyFinancialsLocationDto<SimpleGridProps[]>): SelectOption[] {
    const locationValues = this.getLocationValues(termData);
    const locationCurrency = termData?.localCurrency || '';

    const options = getCompanyFinancialsLocationOptions(locationCurrency);
    // When USD$ is the only currency (=reporting currency), don't display the label for it.
    if (locationValues.length === 1) options[0].title = '';

    return options.filter(option => locationValues.includes(option.value as CompanyFinancialsLocationFilterName));
  }
}
