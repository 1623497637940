import React, { useContext, useMemo } from 'react';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import { Link } from 'react-router-dom';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import { CompanyTabsPath } from '../../../../../enums/CompanyTabsPath';
import { CompanyLocationService } from '../../../../../services/CompanyLocationService';
import CompanyProfileSidebarTable from '../company-profile-table/CompanyProfileSidebarTable';

const CompanyManagement: React.FC = () => {
  const { getTabData, ticker } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const data = useMemo(() => profile.management, [profile]);
  const linkPath = useMemo(() => CompanyLocationService.getRelativeTabPath(CompanyTabsPath.MgmtOwners, ticker), [
    ticker,
  ]);

  return (
    <>
      {data && (
        <CompanyProfileSideItem title={<Link to={linkPath}>Management</Link>}>
          <CompanyProfileSidebarTable {...data} expandedData={data.rows} isHeaderHidden hasAroundBorder={false} />
        </CompanyProfileSideItem>
      )}
    </>
  );
};

export default CompanyManagement;
