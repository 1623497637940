import React, { ReactNode } from 'react';
import Hyperlink, { HyperlinkCommonProps } from './Hyperlink';
import { RoutePath } from '../../enums/RoutePath';
import { Url } from '../../types';
import { useClassName } from '../../hooks/useClassName';

interface Props extends HyperlinkCommonProps {
  value: ReactNode;
  to?: RoutePath | Url;
}

const HyperlinkConditional: React.FC<Props> = props => {
  const { value, to } = props;

  const cn = useClassName('HyperlinkConditional', props.className);

  return (
    <>
      {to ? (
        <Hyperlink {...props} className={cn()} to={to}>
          {value}
        </Hyperlink>
      ) : (
        <div className={cn()}>{value}</div>
      )}
    </>
  );
};

export default HyperlinkConditional;
