import { FiltersValues } from '../../../../shared/entities/filters/types/FiltersValues';
import { ViewFilterName } from '../../../../shared/entities/filters/enum/types/ViewFilterName';
import { Location } from '../../../../shared/entities/filters/enum/Location';

export class StatsFilingsChartService {
  static getBlockTitle(pageFilters: FiltersValues): string {
    return pageFilters[ViewFilterName.Headquarter] === Location.US ? 'IPO Filing Activity' : 'IPO Pipeline Additions';
  }

  static getChartTitle(pageFilters: FiltersValues): string {
    return pageFilters[ViewFilterName.Headquarter] === Location.US ? 'Number of Filings' : 'Pipeline Activity';
  }
}
