import React, { useCallback, useContext, useMemo } from 'react';
import FormInput from '../../../../../../../components/forms/FormInput';
import { InputType } from '../../../../../../../components/forms/Input';
import FormGroup from '../../../../../../../components/forms/FormGroup';
import ClipboardCopy from '../../../../../../../components/clipboard-copy/ClipboardCopy';
import ButtonSubmit from '../../../../../../../components/forms/ButtonSubmit';
import Form from '../../../../../../../components/forms/Form';
import { ControlSize } from '../../../../../../../enums/ControlSize';
import { validationSchema } from './validationSchema';
import { ViewShareLinkFormFields } from './ViewShareLinkFormFields';
import { ViewLocationService } from '../../../services/ViewLocationService';
import { ViewContext } from '../../../ViewContext';
import { ViewShareLinkFormData } from './ViewShareLinkFormData';
import { ViewApiService } from '../../../../../../../api/ViewApiService';
import { setViewSharedLinks } from '../../../store/actions/link';
import { useDispatch } from 'react-redux';
import { handleAction } from '../../../../../../../../store/notification/actions';
import { ViewShareLinkMessage } from '../enum/ViewShareLinkMessage';

interface Props {
  onShareLink: () => void;
}

const ViewShareLinkForm: React.FC<Props> = props => {
  const { onShareLink } = props;
  const { state, dispatch } = useContext(ViewContext);
  const appDispatch = useDispatch();

  const url = useMemo<string>(() => ViewLocationService.getUrl(state.viewType, state.viewId), [
    state.viewId,
    state.viewType,
  ]);

  const shareLink = useCallback(
    async (data: ViewShareLinkFormData) => {
      const sharedLinks = await ViewApiService.shareLink(state.viewId, data);
      onShareLink();
      dispatch(setViewSharedLinks(sharedLinks));
    },
    [dispatch, state.viewId, onShareLink],
  );

  const onSubmit = useCallback(
    (data: ViewShareLinkFormData) => {
      appDispatch(
        handleAction(() => shareLink(data), {
          message: {
            text: ViewShareLinkMessage.CreateSharedLinkSuccess,
            values: [data[ViewShareLinkFormFields.LinkName]],
          },
        }),
      );
    },
    [appDispatch, shareLink],
  );

  return (
    <Form className="ViewShareLinkForm" validationSchema={validationSchema} onSubmit={onSubmit}>
      <FormGroup>
        <FormInput
          name={ViewShareLinkFormFields.LinkName}
          type={InputType.Text}
          placeholder="Name"
          label={url}
          hasLabelErrorStyle={false}
          after={
            <ClipboardCopy valueToCopy={url}>
              <ButtonSubmit
                submittedMessage=""
                message={'Create and Copy Link'}
                isSubmitted={false}
                size={ControlSize.Small}
              />
            </ClipboardCopy>
          }
        />
      </FormGroup>
    </Form>
  );
};

export default ViewShareLinkForm;
