import { CompanyPerfomanceVsIpoIndexTerm } from '../interfaces/CompanyPerfomanceVsIpoIndexTerm';
import { CompanyPerfomanceVsIpoIndexSeriesData } from '../types/CompanyPerfomanceVsIpoIndexSeriesData';
import { ArrayHelper } from '../../../../../shared/helpers/ArrayHelper';
import { DateHelper } from '../../../../../shared/helpers/date/DateHelper';
import { CompanyPerfomanceVsIpoIndexChartDto } from '../../../../types/CompanyPerfomanceVsIpoIndexChartDto';
import { TickerSymbol } from '../../../../../shared/types';
import { CompanyPerfomanceVsIpoIndexTermMS } from '../interfaces/CompanyPerfomanceVsIpoIndexTermMS';
import { DateFormat } from '../../../../../shared/helpers/date/enums/DateFormat';

const NO_DATE_SERIES_MS = -1;

export class CompanyPeerTradingDateRangeService {
  static getFormattedMaxDateRangeFromSeries(
    series: CompanyPerfomanceVsIpoIndexChartDto[],
  ): CompanyPerfomanceVsIpoIndexTerm {
    return this.getFormattedDateRange(this.getMaxDateRangeFromSeries(series));
  }

  private static getFormattedDateRange(dateRange: CompanyPerfomanceVsIpoIndexTermMS): CompanyPerfomanceVsIpoIndexTerm {
    return {
      startDate: this.getFormattedDate(dateRange.startDate),
      endDate: this.getFormattedDate(dateRange.endDate),
    };
  }

  private static getMaxDateRangeFromSeries(
    series: CompanyPerfomanceVsIpoIndexChartDto[],
  ): CompanyPerfomanceVsIpoIndexTermMS {
    const dateRange = this.getDateRangeFromData(series[0].data);

    series.forEach((item: CompanyPerfomanceVsIpoIndexChartDto) => {
      const itemDateRange = this.getDateRangeFromData(item.data);

      if (dateRange.startDate > itemDateRange.startDate) {
        dateRange.startDate = itemDateRange.startDate;
      }

      if (dateRange.endDate < itemDateRange.endDate) {
        dateRange.endDate = itemDateRange.endDate;
      }
    });

    return dateRange;
  }

  private static getDateRangeFromData(
    chartData: CompanyPerfomanceVsIpoIndexSeriesData[],
  ): CompanyPerfomanceVsIpoIndexTermMS {
    return {
      startDate: this.getStartDate(chartData),
      endDate: this.getEndDate(chartData),
    };
  }

  static getTickersFromSeries(series: CompanyPerfomanceVsIpoIndexChartDto[]): TickerSymbol[] {
    return series.map((item: CompanyPerfomanceVsIpoIndexChartDto) => item.name);
  }

  private static getStartDate(chartData: CompanyPerfomanceVsIpoIndexSeriesData[]): number {
    const firstSeriesData = ArrayHelper.getFirstElement(chartData);

    return this.getDateFromSeriesData(firstSeriesData);
  }

  private static getEndDate(chartData: CompanyPerfomanceVsIpoIndexSeriesData[]): number {
    const lastSeriesData = ArrayHelper.getLastElement(chartData);

    return this.getDateFromSeriesData(lastSeriesData);
  }

  private static getDateFromSeriesData(data?: CompanyPerfomanceVsIpoIndexSeriesData): number {
    if (!data) {
      return NO_DATE_SERIES_MS;
    }

    const dateMs = ArrayHelper.getFirstElement<number>(data) || NO_DATE_SERIES_MS;

    return dateMs;
  }

  private static getFormattedDate(dateMs: number): string {
    return DateHelper.formatNumberDate(dateMs, DateFormat.YearMonthDayISO);
  }
}
