import React from 'react';
import CompanyTeamNotesToast from './CompanyTeamNotesToast';
import './CompanyTeamNotesToasts.scss';
import { TeamNote } from '../../../interfaces/TeamNote';

interface Props {
  teamNotes: TeamNote[];
}

const CompanyTeamNotesToasts: React.FC<Props> = props => {
  return (
    <div className="CompanyTeamNotesToasts">
      {props.teamNotes.map((teamNote: TeamNote, index: number) => (
        <CompanyTeamNotesToast {...teamNote} key={index} />
      ))}
    </div>
  );
};

export default CompanyTeamNotesToasts;
