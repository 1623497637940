import { NewsFilterData } from '../../interfaces/NewsFilterData';
import LocationService from '../../../shared/services/LocationService';
import { RoutePath } from '../../../shared/enums/RoutePath';
import { LocationSearchData } from '../../../shared/interfaces/LocationSearchData';
import { NumberId, Url } from '../../../shared/types';

export class NewsLocationService {
  static getRoutePath(id: NumberId): Url {
    return LocationService.getRelativePath(`${RoutePath.News}/${id}`);
  }

  static createSearchUrl(filterData: NewsFilterData): Url {
    return LocationService.getRelativePath(RoutePath.NewsSearch, filterData as LocationSearchData);
  }
}
