import { store } from './store';
import { AuthService } from './shared/services/AuthService';

export class AppInitService {
  static async init(): Promise<void> {
    const state = store.getState();

    if (state.auth.isAuthorized) {
      await AuthService.setAuthorizedData();
    }
  }
}
