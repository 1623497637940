import { Thunk } from 'react-hook-thunk-reducer';
import {
  ActionTypes,
  SET_VIEW_COLUMNS_SETTINGS,
  SET_VIEW_SELECTED_COLUMNS,
  SetViewColumnsSettings,
  SetViewSelectedColumns,
  ViewState,
} from '../types';
import { ViewApiService } from '../../../../../../api/ViewApiService';
import { setViewError } from './notification';
import { ViewSelectedColumnsDto } from '../../interfaces/ViewSelectedColumnsDto';
import { Nullable } from '../../../../../../types/generics';
import { ViewColumnsOptionsDto } from '../../components/view-columns/add/interfaces/dto/ViewColumnsOptionsDto';

export const setViewColumnsSettings = (columnsSettings: Nullable<ViewColumnsOptionsDto>): SetViewColumnsSettings => {
  return {
    type: SET_VIEW_COLUMNS_SETTINGS,
    payload: { columnsOptions: columnsSettings },
  };
};

export const setViewSelectedColumns = (selectedColumns: ViewSelectedColumnsDto): SetViewSelectedColumns => {
  return {
    type: SET_VIEW_SELECTED_COLUMNS,
    payload: {
      selectedColumns,
    },
  };
};

export const initViewColumnsSettings = (): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state: ViewState = getState();
    let columnsSettings = null;

    if (state.options?.toolbar?.hasAddColumns) {
      try {
        columnsSettings = await ViewApiService.getColumns();
      } catch (e) {
        dispatch(setViewError(e));
      }
    }

    dispatch(setViewColumnsSettings(columnsSettings));
  };
};
