import React from 'react';
import { CompanyStagesTooltipDto } from '../../../../../../interfaces/CompanyStagesTooltipDto';
import CompanyProfileStepsTooltipContentItem from './CompanyProfileStepsTooltipContentItem';
import './CompanyProfileStepsTooltipContent.scss';
import {
  CompanyProfileStepsTooltipContentElement,
  CompanyProfileStepsTooltipContentBlock,
} from './companyProfileStepsTooltipContentBem';
import { useClassName } from '../../../../../../../shared/hooks/useClassName';

interface Props {
  items: CompanyStagesTooltipDto[];
}

const CompanyProfileStepsTooltipContent: React.FC<Props> = props => {
  const cn = useClassName(CompanyProfileStepsTooltipContentBlock.Root);

  return (
    <div className={cn()}>
      <div className={cn(CompanyProfileStepsTooltipContentElement.title)}>Lockups</div>

      <div className={cn(CompanyProfileStepsTooltipContentElement.content)}>
        {props.items.map((item: CompanyStagesTooltipDto, index: number) => (
          <CompanyProfileStepsTooltipContentItem key={index} sequenceNumber={index + 1} {...item} />
        ))}
      </div>
    </div>
  );
};

export default CompanyProfileStepsTooltipContent;
