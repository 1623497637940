import { ViewOptions } from '../../../shared/entities/view/components/view/interfaces/options/ViewOptions';
import { TimeFrame } from '../../../shared/entities/filters/enum/TimeFrame';
import { FileActionType } from '../../../shared/components/file-actions/enums/FileActionType';
import { ViewColumnSystemName } from '../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';

export const recentResearchViewOptions: ViewOptions = {
  header: 'Recent IPO Research',
  toolbar: {
    quickFilter: {
      hasLocation: true,
      hasDateRange: false,
      hasTimeFrameRange: true,
      timeFrameOptions: [TimeFrame.Month, TimeFrame.Quarter, TimeFrame.YTD, TimeFrame.Year, TimeFrame.TwoYears],
    },
    fileActionTypes: [FileActionType.Print],
  },
  grid: {
    isFixedHeight: false,
    idCols: [ViewColumnSystemName.Id, ViewColumnSystemName.CompanyTicker],
  },
};
