import React, { useEffect, useMemo, useState } from 'react';
import MasterCalendarToolbar from './components/MasterCalendarToolbar';
import MasterCalendarLegend from './components/MasterCalendarLegend';
import MasterCalendarBody from './components/MasterCalendarBody';
import { MasterCalendarContext, MasterCalendarContextValue } from './MasterCalendarContext';
import { Location } from '../shared/entities/filters/enum/Location';
import { CalendarEventGroup } from '../shared/entities/calendar/interfaces/CalendarEventGroup';
import { CalendarEvent } from '../shared/entities/calendar/interfaces/CalendarEvent';
import { CalendarFactory } from '../shared/entities/calendar/factories/CalendarFactory';
import { CalendarEventType } from '../shared/entities/calendar/enums/CalendarEventType';
import './MasterCalendar.scss';
import { MasterCalendarFilterService } from './services/MasterCalendarFilterService';
import { useClassName } from '../shared/hooks/useClassName';
import { MasterCalendarBlock } from './masterCalendarBem';
import { ResponsiveCalendarMode } from '../shared/components/responsive-calendar/enums/ResponsiveCalendarMode';
import { ControlSize } from '../shared/enums/ControlSize';

interface Props {
  className?: string;
  allEvents: CalendarEvent[];
  mode: ResponsiveCalendarMode;
  weeksCount?: number;
  size?: ControlSize;
  hasHeader?: boolean;
  height?: number;
  hasPrint?: boolean;
  isShortLegend?: boolean;
}

const MasterCalendar: React.FC<Props> = props => {
  const { allEvents, hasPrint = true, size = ControlSize.Medium } = props;

  const cn = useClassName(MasterCalendarBlock.Root, props.className);

  const [groups, setGroups] = useState<CalendarEventGroup[]>([]);
  const [activeEventTypes, setActiveEventTypes] = useState<CalendarEventType[]>([]);
  const [headquarter, setHeadquarter] = useState<Location>(Location.US);
  const [isSpac, setIsSpac] = useState<boolean>(false);

  const [filteredEvents, setFilteredEvents] = useState<CalendarEvent[]>([]);

  const value: MasterCalendarContextValue = useMemo(
    () => ({
      isSpac,
      headquarter,
      groups,
      activeEventTypes,
      filteredEvents,
      allEvents,
      setHeadquarter,
      setIsSpac,
      setActiveEventTypes,
    }),
    [
      isSpac,
      setIsSpac,
      headquarter,
      setHeadquarter,
      groups,
      activeEventTypes,
      setActiveEventTypes,
      filteredEvents,
      allEvents,
    ],
  );

  useEffect(() => setGroups(CalendarFactory.getEventGroups(headquarter)), [headquarter]);

  useEffect(() => {
    if (props.size === 'sm' && groups.length > 0) {
      // if Dashoard's Master Calendar, then set trade as default active filter
      // currently, 'sm' is being passed to Dashboard's Master Calendar
      const preSelectedFilterItems = ['trade'];
      const filteredGroups = groups.filter(group => preSelectedFilterItems.includes(group.type));
      setActiveEventTypes(CalendarFactory.getActiveEventTypes(filteredGroups));
    } else {
      setActiveEventTypes(CalendarFactory.getActiveEventTypes(groups));
    }
  }, [groups]);

  useEffect(() => {
    setFilteredEvents(
      MasterCalendarFilterService.filter(allEvents, {
        activeEventTypes,
      }),
    );
  }, [activeEventTypes, allEvents]);

  return (
    <MasterCalendarContext.Provider value={value}>
      <div
        className={cn({
          [size]: size,
        })}
      >
        <MasterCalendarToolbar hasPrint={hasPrint} />

        <MasterCalendarLegend size={size} isShortLegend={props.isShortLegend} />

        <MasterCalendarBody
          mode={props.mode}
          weeksCount={props.weeksCount}
          hasHeader={props.hasHeader}
          height={props.height}
        />
      </div>
    </MasterCalendarContext.Provider>
  );
};

export default MasterCalendar;
