import React, { useRef } from 'react';
import './DashboardWidget.scss';
import DashboardWidgetHeader from './DashboardWidgetHeader';
import { DashboardWidgetConfig } from '../interfaces/DashboardWidgetConfig';
import { stopPropagation } from '../../shared/constants/stopPropagation';
import { GridLayoutBreakpoint } from '../../shared/components/grid-layout/enums/GridLayoutBreakpoint';
import { Pixels } from '../../shared/types';
import { useDashboardWidgetDimensions } from '../hooks/useDashboardWidgetDimensions';
import { useDashboardWidgetData } from '../hooks/useDashboardWidgetData';
import { Dimensions } from '../../shared/interfaces/Dimensions';
import LoaderContainer from '../../shared/components/loader/LoaderContainer';
import { useLoadDashboardWidgetData } from '../hooks/useLoadDashboardWidgetData';
import { useClassName } from '../../shared/hooks/useClassName';
import { DashboardWidgetBlock, DashboardWidgetElement } from './dashboardWidgetBem';
import { ComponentMessage } from '../../shared/enums/ComponentMessage';

export interface DashboardWidgetProps {
  widget: DashboardWidgetConfig;
  breakpoint: GridLayoutBreakpoint;
  width: Pixels | null;
  resizedFlag?: number;
}

const DashboardWidget: React.FC<DashboardWidgetProps> = props => {
  const cn = useClassName(DashboardWidgetBlock.Root);

  const { component: ContentComponent, loadData, hasIndent } = useDashboardWidgetData(props.widget.type);
  const contentRef = useRef<HTMLDivElement>(null);
  const dimensions: Dimensions | null = useDashboardWidgetDimensions(props, contentRef, props.resizedFlag);
  const [data, isLoading] = useLoadDashboardWidgetData(loadData);

  return (
    <section className={cn()}>
      <DashboardWidgetHeader widget={props.widget} />

      <div
        className={cn(DashboardWidgetElement.Content, {
          indent: hasIndent && !isLoading,
        })}
        onMouseDown={stopPropagation}
        onTouchStart={stopPropagation}
        ref={contentRef}
      >
        {dimensions && !isLoading ? (
          data ? (
            <ContentComponent dimensions={dimensions} data={data} />
          ) : (
            <div className={cn(DashboardWidgetElement.NoData)}>{ComponentMessage.NoData}</div>
          )
        ) : (
          <LoaderContainer />
        )}
      </div>
    </section>
  );
};

export default DashboardWidget;
