import { StatsElementType } from '../../shared/entities/stats-element/enums/StatsElementType';
import { RoutePath } from '../../shared/enums/RoutePath';

export type PagesPathsTypes = Partial<
  {
    [key in RoutePath]: StatsElementType;
  }
>;

export const pagesPathsTypes: PagesPathsTypes = {
  [RoutePath.AdditionalStatsLargestUSIPOs]: StatsElementType.LargestIPOsUs,
  [RoutePath.AdditionalStatsLargestGlobalIPOs]: StatsElementType.LargestIPOsGlobal,
  [RoutePath.AdditionalStatsPricingsComparedToRange]: StatsElementType.PricingVsRange,
  [RoutePath.AdditionalStatsUSListingsFromNonUSIssuers]: StatsElementType.UsListedAdr,
  [RoutePath.AdditionalStatsProfitableVsUnprofitable]: StatsElementType.Profitability,
  [RoutePath.AdditionalStatsWithdrawals]: StatsElementType.Withdrawals,
  [RoutePath.AdditionalStatsAverageAge]: StatsElementType.AverageAge,
  [RoutePath.AdditionalStatsMedianAge]: StatsElementType.MedianAge,
  [RoutePath.StatsBacked]: StatsElementType.VentureCapital,
  [RoutePath.StatsFilings]: StatsElementType.Filings,
  [RoutePath.StatsIndustry]: StatsElementType.IndustryBreakdown,
  [RoutePath.StatsPricing]: StatsElementType.Pricing,
  [RoutePath.StatsProceeds]: StatsElementType.Proceeds,
  [RoutePath.StatsHistoricalIPOReturns]: StatsElementType.HistoricalIpoReturns,
  [RoutePath.StatsGlobalBreakdown]: StatsElementType.GlobalBreakdown,
};
