import { NewsRcExternalFilterType } from '../enums/NewsRcExternalFilterType';
import { NewsLandingItemDto } from '../../../../interfaces/NewsLandingItemDto';
import { NewsTypeService } from '../../../services/NewsTypeService';

export class NewsRcExternalFilterService {
  static filterByType(type: NewsRcExternalFilterType, news: NewsLandingItemDto[]): NewsLandingItemDto[] {
    switch (type) {
      case NewsRcExternalFilterType.All:
        return news;
      case NewsRcExternalFilterType.RCOnly:
        return news.filter(newsItem => NewsTypeService.isRC(newsItem.type));
      case NewsRcExternalFilterType.ExternalNewsOnly:
        return news.filter(newsItem => NewsTypeService.isExternal(newsItem.type));
    }
  }
}
