import React from 'react';
import './CompanyParagraph.scss';
import { CompanyParagraphItem } from '../../interfaces/CompanyParagraphItem';
import TextContainer from '../../../marketing/shared/TextContainer';
import CompanySectionRow from './CompanySectionRow';
import { useClassName } from '../../../shared/hooks/useClassName';

const CompanyParagraph: React.FC<CompanyParagraphItem> = props => {
  const cn = useClassName('CompanyParagraph');

  return (
    <CompanySectionRow className={cn()}>
      {props.label && <div className={cn('title')}>{props.label}</div>}

      <TextContainer className={cn('text')}>{props.text}</TextContainer>
    </CompanySectionRow>
  );
};

export default CompanyParagraph;
