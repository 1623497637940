import React, { useCallback, useContext } from 'react';
import './SavedViews.scss';
import { AlignTypesHorizontal } from '../../../shared/enums/AlignTypesHorizontal';
import PrimarySectionRow from '../../../shared/components/primary-section/PrimarySectionRow';
import SavedViewsSection from '../../../shared/entities/view/components/view/components/view-saved/SavedViewsSection';
import { useClassName } from '../../../shared/hooks/useClassName';
import { SavedViewsBlock } from './SavedViewsBem';
import { ViewDashboardContext } from '../../../shared/entities/view/components/view-dashboard/ViewDashboardContext';
import { MyFavouritesDto } from '../../interfaces/MyFavouritesDto';
import { BlockOrientation } from '../../../shared/enums/BlockOrientation';
import { MyFavouritesDtoProp } from '../../enums/MyFavouritesDtoProp';
import { SavedViewsType } from '../../../shared/entities/view/components/view/components/view-saved/enums/SavedViewsType';
import SavedViewsSectionsGroup from '../../../shared/entities/view/components/view/components/view-saved/SavedViewsSectionsGroup';
import { ControlSize } from '../../../shared/enums/ControlSize';

const savedViewsSectionsCommonProps = {
  width: ControlSize.Medium,
  noDataAlignTypeHorizontal: AlignTypesHorizontal.Start,
};

interface Props {
  header?: string;
  orientation?: BlockOrientation;
}

const SavedViews: React.FC<Props> = props => {
  const cn = useClassName(SavedViewsBlock.Root);

  const context = useContext(ViewDashboardContext);
  const data = context.data as MyFavouritesDto;
  const onDataChange = context.onItemDataChange;

  let sectionHeader = '';
  if (props.header) {
    sectionHeader = props.header;
  }

  let orientation = BlockOrientation.Line;
  if (props.orientation) {
    orientation = props.orientation;
  }

  const afterDeleteView = useCallback(savedViews => onDataChange(MyFavouritesDtoProp.SavedViews, savedViews), [
    onDataChange,
  ]);
  const afterDeleteScreen = useCallback(savedScreens => onDataChange(MyFavouritesDtoProp.SavedScreens, savedScreens), [
    onDataChange,
  ]);

  return (
    <PrimarySectionRow
      className={cn()}
      header={sectionHeader}
      alignHorizontal={AlignTypesHorizontal.Center}
      isHeaderNoBorder
    >
      <SavedViewsSectionsGroup orientation={orientation}>
        <SavedViewsSection
          {...savedViewsSectionsCommonProps}
          items={data[MyFavouritesDtoProp.SavedScreens]}
          type={SavedViewsType.Screens}
          afterDeleteView={afterDeleteScreen}
        />
        <SavedViewsSection
          {...savedViewsSectionsCommonProps}
          items={data[MyFavouritesDtoProp.SavedViews]}
          type={SavedViewsType.Views}
          afterDeleteView={afterDeleteView}
        />
      </SavedViewsSectionsGroup>
    </PrimarySectionRow>
  );
};

export default SavedViews;
