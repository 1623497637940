import { SearchAutocompleteItem } from '../types/SearchAutocompleteItem';
import { SearchCompanyItemData } from '../interfaces/SearchCompanyItemData';
import { SearchItemType } from '../enums/SearchItemType';
import { SearchResearchItemData } from '../interfaces/SearchResearchItemData';
import { SearchNewsItemData } from '../interfaces/SearchNewsItemData';
import { RandomizeService } from '../../../../shared/services/RandomizeService';
import { SearchItemDto } from '../interfaces/dto/SearchItemDto';
import { SearchAutocompleteItemViewOptions } from '../interfaces/SearchAutocompleteItemViewOptions';
import { IconName } from '../../../../shared/components/icon/IconName';
import { CompanyLocationService } from '../../../../company/services/CompanyLocationService';
import { NewsLocationService } from '../../../../news/shared/services/NewsLocationService';
import { SearchItemResultTypeValueDto } from '../enums/SearchItemResultTypeValueDto';
import { NewsType } from '../../../../news/enums/NewsType';
import { DateHelper } from '../../../../shared/helpers/date/DateHelper';
import { DateFormat } from '../../../../shared/helpers/date/enums/DateFormat';
import { SearchLatestVideosItemData } from '../interfaces/SearchLatestVideosItemData';
import { VideoLocationService } from '../../../../videos/shared/services/videoLocationService';

export class SearchAutocompleteFactory {
  static getItems(searchItems: SearchItemDto[]): SearchAutocompleteItem[] {
    return searchItems.map(searchItem => {
      let type: SearchItemType;
      let data: SearchCompanyItemData | SearchResearchItemData | SearchNewsItemData | SearchLatestVideosItemData;

      if (searchItem.resultType === SearchItemResultTypeValueDto.Profile) {
        type = SearchItemType.Company;
        data = this.getCompanyItemData(searchItem);
      } else if (searchItem.resultType === SearchItemResultTypeValueDto.Research) {
        type = SearchItemType.Research;
        data = this.getResearchItemData(searchItem);
      } else if (searchItem.resultType === SearchItemResultTypeValueDto.LatestVideos) {
        type = SearchItemType.LatestVideos;
        data = this.getLatestVideosItemData(searchItem);
      } else {
        type = SearchItemType.News;
        data = this.getNewsItemData(searchItem);
      }

      // TODO: check why ts return error
      return {
        value: this.getValue(),
        title: searchItem.keyword,
        date: searchItem.resultDate,
        matchType: searchItem.matchType,
        type,
        data,
      } as SearchAutocompleteItem;
    });
  }

  static getViewItemOptions(item: SearchAutocompleteItem): SearchAutocompleteItemViewOptions {
    switch (item.type) {
      case SearchItemType.Company:
        return {
          icon: IconName.Office,
          tag: 'Profile',
          routePath: CompanyLocationService.getRelativePath(item.data.tickerSymbol),
          matchType: item.matchType,
        };
      case SearchItemType.Research:
        return {
          icon: IconName.Pdf,
          tag: 'Research',
          routePath: item.data.link,
          matchType: item.matchType,
        };
      case SearchItemType.LatestVideos:
        return {
          icon: IconName.PlayCircle,
          tag: 'Latest Videos',
          routePath: VideoLocationService.getRoutePath(item.data.videoKey),
          matchType: item.matchType,
        };
      case SearchItemType.News:
        return {
          icon: IconName.Newspaper,
          extraIcon: item.data.hasVideo ? IconName.PlayCircle : undefined,
          tag: item.data.type,
          date:
            item.date &&
            DateHelper.formatDate(
              DateHelper.parseDateByFormat(item.date, DateFormat.MonthDayYear),
              DateFormat.MonthDayYearShort,
            ),
          routePath: NewsLocationService.getRoutePath(item.data.newsKey),
          matchType: item.matchType,
        };
    }
  }

  private static getLatestVideosItemData(searchItem: SearchItemDto): SearchLatestVideosItemData {
    return {
      videoKey: (searchItem.newsKey as number) || (searchItem.videoKey as number),
      type: searchItem.resultType as SearchItemResultTypeValueDto,
    };
  }

  private static getCompanyItemData(searchItem: SearchItemDto): SearchCompanyItemData {
    return {
      tickerSymbol: searchItem.tickerSymbol as string,
    };
  }

  private static getResearchItemData(searchItem: SearchItemDto): SearchResearchItemData {
    return {
      link: searchItem.link as string,
    };
  }

  private static getNewsItemData(searchItem: SearchItemDto): SearchNewsItemData {
    return {
      newsKey: searchItem.newsKey as number,
      type: searchItem.resultType as NewsType,
      hasVideo: searchItem.videoKey ? true : false,
    };
  }

  private static getValue(): string {
    return RandomizeService.generateUuid();
  }
}
