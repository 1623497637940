export enum ViewType {
  Priced = 'Priced',
  Scheduled = 'Scheduled',
  RecentResearch = 'RecentResearch',
  Pipeline = 'Pipeline',
  PrivateCompanyWatchlist = 'Pcw',
  PrivateCompanyMonitorList = 'Pcml',

  // Screens
  UnderwriterLanding = 'UnderwriterLanding',
  UnderwriterInUse = 'UnderwriterInUse',
  IndustryLanding = 'IndustryLanding',
  IndustryInUse = 'IndustryInUse',
  LocationLanding = 'LocationLanding',
  LocationInUse = 'LocationInUse',
  LockUpsInUse = 'LockUpsInUse',
  InvestorsLanding = 'InvestorsLanding',
  InvestorsInUse = 'InvestorsInUse',
  SpacsLanding = 'SpacsLanding',
  SpacsInUse = 'SpacsInUse',
  EstimatesInUse = 'EstimatesInUse',
  CustomInUse = 'CustomInUse',
  WithdrawnPostponedInUse = 'PostponedWithdrawn',
}
