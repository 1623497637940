import { RowNode } from '@ag-grid-community/core';
import { ViewGridSortService } from '../services/ViewGridSortService';
import { ViewColumnSystemName } from '../enums/ViewColumnSystemName';
import { CompanyPriceChangeDto } from '../../../../../../company/components/company-price-change/interfaces/CompanyPriceChangeDto';
import { CompanyPriceChangeDtoProp } from '../../../../../../company/components/company-price-change/enums/CompanyPriceChangeDtoProp';
import { Nullable } from '../../../../../types/generics';
import { ResearchColumnValue } from '../interfaces/column-values/ResearchColumnValue';
import { CompanyRatingFactory } from '../../../../../../company/components/company-rating/factories/CompanyRatingFactory';
import { CompanyRatingValueDto } from '../../../../../../company/components/company-rating/types/CompanyRatingValueDto';
import { store } from '../../../../../../store';
import { isFavouriteSelector } from '../../../../../../store/favourite-company/selectors';

export const companyNameComparator = (valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode): number => {
  return ViewGridSortService.sortText(
    nodeA.data[ViewColumnSystemName.CompanyName],
    nodeB.data[ViewColumnSystemName.CompanyName],
  );
};

export const companyTickerNameComparator = (valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode): number => {
  return ViewGridSortService.sortText(
    nodeA.data[ViewColumnSystemName.CompanyTicker],
    nodeB.data[ViewColumnSystemName.CompanyTicker],
  );
};

export const latestTokenDateComparator = (valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode): number => {
  // Turn your strings into dates, and then check which one is greater
  return new Date(nodeA.data[ViewColumnSystemName.LatestTokenDate]) >
    new Date(nodeB.data[ViewColumnSystemName.LatestTokenDate])
    ? 1
    : -1;
};

export const companyWithNotePopupComparator = (
  valueA: string,
  valueB: string,
  nodeA: RowNode,
  nodeB: RowNode,
): number => {
  return ViewGridSortService.sortText(
    nodeA.data[ViewColumnSystemName.CompanyWithNotePopUp][ViewColumnSystemName.CompanyTicker],
    nodeB.data[ViewColumnSystemName.CompanyWithNotePopUp][ViewColumnSystemName.CompanyTicker],
  );
};

export const OfferMonthTimingComparator = (valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode): number => {
  return ViewGridSortService.sortText(
    nodeA.data[ViewColumnSystemName.OfferMonthTimingSort],
    nodeB.data[ViewColumnSystemName.OfferMonthTimingSort],
  );
};

export const EstTimingComparator = (valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode): number => {
  return ViewGridSortService.sortText(
    nodeA.data[ViewColumnSystemName.EstTimingSort],
    nodeB.data[ViewColumnSystemName.EstTimingSort],
  );
};

export const DateIssuedComparator = (valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode): number => {
  return ViewGridSortService.sortText(
    nodeA.data[ViewColumnSystemName.DateIssued],
    nodeB.data[ViewColumnSystemName.DateIssued],
  );
};

export const todaysPriceChangeComparator = (
  valueA: Nullable<CompanyPriceChangeDto>,
  valueB: Nullable<CompanyPriceChangeDto>,
): number =>
  ViewGridSortService.sortNumber(
    valueA && valueA[CompanyPriceChangeDtoProp.GrowthPercent],
    valueB && valueB[CompanyPriceChangeDtoProp.GrowthPercent],
  );

export const researchRatingComparator = (valueA: CompanyRatingValueDto, valueB: CompanyRatingValueDto): number => {
  const valueAParsed = CompanyRatingFactory.getRating(valueA).value;
  const valueBParsed = CompanyRatingFactory.getRating(valueB).value;

  return ViewGridSortService.sortNumber(valueAParsed, valueBParsed);
};

export const researchComparator = (valueA: ResearchColumnValue, valueB: ResearchColumnValue): number => {
  // Rating Scores
  // 0 - 16: Has a research rating
  // -1: Has a Research Report or DCF Model
  // -2: Has Management Questions
  // -3: Has Enhanced Profile / Fundamental Insights
  // -4: Default - Has none of the above

  var valueAScore;
  var valueBScore;
  valueAScore = -4;
  valueBScore = -4;

  if (valueA.rating === null) {
    if ((valueA.dcfLink || valueA.reportLink) && valueA.reportLinkIcon !== 'q') {
      valueAScore = -1;
    } else if (valueA.reportLinkIcon === 'q') {
      valueAScore = -2;
    } else if (valueA.insightsLink) {
      valueAScore = -3;
    }
  } else {
    valueAScore = valueA.rating;
  }

  if (valueB.rating === null) {
    if ((valueB.dcfLink || valueB.reportLink) && valueB.reportLinkIcon !== 'q') {
      valueBScore = -1;
    } else if (valueB.reportLinkIcon === 'q') {
      valueBScore = -2;
    } else if (valueB.insightsLink) {
      valueBScore = -3;
    }
  } else {
    valueBScore = valueB.rating;
  }

  return researchRatingComparator(valueAScore, valueBScore);
};

export const favouriteComparator = (valueA: boolean, valueB: boolean, nodeA: RowNode, nodeB: RowNode): number => {
  const companyStore = store.getState().favouriteCompany;
  const getValue = (node: RowNode): boolean =>
    isFavouriteSelector(companyStore, node.data[ViewColumnSystemName.CompanyKey]);

  return ViewGridSortService.sortBoolean(getValue(nodeA), getValue(nodeB));
};

export const doubleComparator = (valueA: number, valueB: number): number => {
  return ViewGridSortService.sortNumber(valueA, valueB);
};
