import React, { memo, ReactNode, useMemo } from 'react';
import { GridColumn } from '../grid/shared/interfaces/GridColumn';
import { SimpleGridCellFactory } from '../grid/simple-grid/factories/SimpleGridCellFactory';

interface Props {
  isHeader?: boolean;
  column: GridColumn;
  children: ReactNode;
  isAutoCellWidth?: boolean;
}

const TableCell: React.FC<Props> = props => {
  const style = useMemo(() => SimpleGridCellFactory.getStyle(props.column, !!props.isHeader, props.isAutoCellWidth), [
    props.column,
    props.isAutoCellWidth,
    props.isHeader,
  ]);

  return props.isHeader ? (
    <th style={style} className="TableCell TableCell--header">
      {props.children}
    </th>
  ) : (
    <td style={style} className="TableCell">
      {props.children}
    </td>
  );
};

export default memo(TableCell);
