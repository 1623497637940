import React from 'react';
import StatsPricings from '../stats/stats/pricings/StatsPricings';
import StatsFilings from '../stats/stats/filings/StatsFilings';
import StatsProceeds from '../stats/stats/proceeds/StatsProceeds';
import StatsIndustry from '../stats/stats/industry/StatsIndustry';
import StatsBackedIpos from '../stats/stats/backed-ipos/StatsBackedIpos';
import { Route } from 'react-router';
import { RoutePath } from '../shared/enums/RoutePath';
import StatsHistoricalIpoReturns from '../stats/stats/historical-ipo-returns/StatsHistoricalIpoReturns';
import StatsGlobalBreakDown from '../stats/stats/global-breakdown/StatsGlobalBreakDown';
import StatsDashboard from '../stats/stats/main-dashboard/StatsDashboard';
import StatsRecentIposReturns from '../stats/stats/recent-ipos-returns/StatsRecentIposReturns';

const StatsRoutes: React.FC = () => {
  return (
    <>
      <Route path={RoutePath.Stats} exact>
        <StatsDashboard />
      </Route>

      <Route path={RoutePath.StatsPricing} exact>
        <StatsPricings />
      </Route>

      <Route path={RoutePath.StatsProceeds} exact>
        <StatsProceeds />
      </Route>

      <Route path={RoutePath.StatsFilings} exact>
        <StatsFilings />
      </Route>

      <Route path={RoutePath.StatsIndustry} exact>
        <StatsIndustry />
      </Route>

      <Route path={RoutePath.StatsBacked} exact>
        <StatsBackedIpos />
      </Route>

      <Route path={RoutePath.StatsHistoricalIPOReturns} exact>
        <StatsHistoricalIpoReturns />
      </Route>

      <Route path={RoutePath.StatsGlobalBreakdown} exact>
        <StatsGlobalBreakDown />
      </Route>

      <Route path={RoutePath.StatsRecentIposReturns} exact>
        <StatsRecentIposReturns />
      </Route>
    </>
  );
};

export default StatsRoutes;
