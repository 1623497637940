import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import ButtonCheckbox, { ButtonCheckboxCommonProps } from './ButtonCheckbox';
import { FormControlCommonProps } from './interfaces/FormControlCommonProps';

export interface FormButtonCheckboxProps extends ButtonCheckboxCommonProps, FormControlCommonProps {}

const FormButtonCheckbox: React.FC<FormButtonCheckboxProps> = props => {
  const { register, setValue, watch } = useFormContext();

  const value: boolean = watch && watch(props.name);

  useEffect(() => register?.({ name: props.name }), [register, props.name]);

  const onChangeValue = useCallback(
    (value: boolean) => {
      setValue?.(props.name, value);
    },
    [props.name, setValue],
  );

  return <ButtonCheckbox {...props} value={value} onChangeValue={onChangeValue} />;
};

export default FormButtonCheckbox;
