import React, { useContext, useState, useMemo } from 'react';
import { CompanyContext } from '../context/CompanyContext';
import CompanyMapTables from '../shared/CompanyMapTables';
import { CompanyFinancialsFilter } from './interfaces/CompanyFinancialsFilter';
import CompanyFinancialsFilterControl from './CompanyFinancialsFilterControl';
import { initialFilters } from './constant/initialFilters';
import { CompanyParagraphItem } from '../../interfaces/CompanyParagraphItem';
import CompanyParagraph from '../shared/CompanyParagraph';
import CompanyFinancialsBalanceSheet from './CompanyFinancialsBalanceSheet';
import { Nullable } from '../../../shared/types/generics';
import { CompanyTab } from '../../enums/CompanyTab';
import { CompanyFinancialsDto } from '../../interfaces/CompanyFinancialsDto';
import EmptyDataBlock from '../../../shared/components/EmptyDataBlock';
import CompanySection from '../shared/CompanySection';
import { CompanyFinancialOptionsService } from './services/CompanyFinancialOptionsService';
import { NumberService } from '../../../shared/services/NumberService';
import { SimpleGridRowStyleType } from '../../../shared/components/grid/simple-grid/enums/SimpleGridRowStyleType';
import HtmlStringFormatterJustified from '../../../shared/components/HtmlStringFormatterJustified';
import { DomService } from '../../../shared/services/dom/DomService';

const CompanyFinancials: React.FC = () => {
  const [filters, setFilters] = useState<CompanyFinancialsFilter>(initialFilters);

  const { getTabData } = useContext(CompanyContext);
  const data: Nullable<CompanyFinancialsDto> = getTabData(CompanyTab.Financials);

  const termData = useMemo(() => data?.[filters.term], [filters, data]);

  const tables = useMemo(() => data?.[filters.term]?.[filters.location], [filters, data]);
  const tablesProps = useMemo(
    () =>
      tables?.map((table: any) => {
        if (table.columns[0]?.headerName === 'Key metrics') {
          return {
            ...table,
            isFullWidth: true,
            tableType: 'financials',
            financialFiltersTerm: filters.term,
          };
        } else
          return {
            ...table,
            isFullWidth: true,
            tableType: 'financials',
            financialFiltersTerm: filters?.term,
            getRowStyle: (index: number) => {
              if (NumberService.isEven(index)) {
                return [SimpleGridRowStyleType.Bold];
              } else {
                return [SimpleGridRowStyleType.Italic];
              }
            },
          };
      }) || [],
    [filters, tables],
  );

  const isData = useMemo(() => !!tables || !!data?.balanceSheet?.[filters.location], [data, tables, filters.location]);

  const titleProps = useMemo(() => {
    return {
      tab: CompanyTab.Financials,
      lastUpdated: data?.finInsightsPostDate,
    };
  }, [data]);

  const termOptions = useMemo(() => CompanyFinancialOptionsService.getTermsOptions(data), [data]);
  const locationOptions = useMemo(() => CompanyFinancialOptionsService.getLocationOptions(termData), [termData]);

  React.useEffect(() => {
    const allAnnualTables: any = document.querySelectorAll('table.CompanyProfileTable.financials');
    if (allAnnualTables && allAnnualTables.length >= 2) {
      // t1Parent, t2Parent, and t3Parent are parent divs of each financial table. The scroll property works on the table's parent div.
      const t1Parent = allAnnualTables[0].parentElement;
      const t2Parent = allAnnualTables[1].parentElement;
      const t3Parent = allAnnualTables[2]?.parentElement;

      if (t1Parent && t2Parent && t3Parent) {
        DomService.syncFinancialTablesScroll(t1Parent, t2Parent, t3Parent);
      } else if (t1Parent && t2Parent) {
        DomService.syncFinancialTablesScroll(t1Parent, t2Parent);
      }
    }
  }, []);

  return (
    <>
      {data && <h3 className="printHead">Financials</h3>}
      <CompanySection className="CompanyFinancials" data={data} titleProps={titleProps}>
        <CompanyFinancialsFilterControl
          initialFilters={filters}
          termOptions={termOptions}
          locationOptions={locationOptions}
          onFiltersChange={setFilters}
        />
        {isData ? (
          <>
            {tables && <CompanyMapTables tables={tablesProps} isEqualSizes />}

            {data?.paragraphs?.map((paragraph: CompanyParagraphItem, index: number) => (
              <CompanyParagraph
                key={index}
                label={paragraph.label}
                text={<HtmlStringFormatterJustified value={paragraph.text as string} />}
              />
            ))}

            <CompanyFinancialsBalanceSheet filters={filters} />
          </>
        ) : (
          <EmptyDataBlock />
        )}
      </CompanySection>
    </>
  );
};

export default CompanyFinancials;
