import { StatsElementOptions } from '../../../../shared/entities/stats-element/interfaces/StatsElementOptions';
import { ChartType } from '../../../../shared/components/chart/enums/ChartType';
import { StatsElementChartProps } from '../../../../shared/entities/stats-element/components/chart/interfaces/StatsElementChartProps';
import { getStatsElementSimpleGridColumnYearToViewMapper } from '../../../../shared/entities/stats-element/components/simple-grid/constants/statsElementSimpleGridColumnMappers';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { ViewFilterName } from '../../../../shared/entities/filters/enum/types/ViewFilterName';
import { StatsFilterName } from '../../enum/StatsFilterName';

const PRICING_GRID_INDEX = 0;

export const pricingsChartOptions: Partial<StatsElementChartProps> = {
  type: ChartType.Base,
  title: '',
  optionsList: [
    {
      chart: {
        type: 'column',
      },
      yAxis: {
        title: {
          text: 'Number of IPOs',
        },
        stackLabels: {
          enabled: false,
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
          },
        },
        column: {
          grouping: false,
        },
      },
    },
  ],
};

export const pricingsStatsElementOptions: StatsElementOptions = {
  chartsOptions: [pricingsChartOptions],
  gridOptions: {
    [PRICING_GRID_INDEX]: {
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(context => {
        return {
          viewType: ViewType.Priced,
          startDateFilterName: ViewFilterName.OfferStartDate,
          endDateFilterName: ViewFilterName.OfferEndDate,
          optionsList: [
            {
              getFilterName: ViewFilterName.SecurityTypeBlankCheckList,
              getFilterValue: () => (context?.pageFilters?.[StatsFilterName.IncludeSPACs] ? 'include' : ''),
            },
          ],
        };
      }),
      isHighlightLastRow: true,
      isStickySecondColumn: true,
    },
  },
};
