import React from 'react';
import LoginForm from './form/LoginForm';
import './Login.scss';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../shared/enums/RoutePath';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import PrimarySectionRow from '../../shared/components/primary-section/PrimarySectionRow';
import DocumentHead from '../../shared/components/DocumentHead';
import { LoginTextElement } from './enum/LoginTextElement';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import LocationService from '../../shared/services/LocationService';

const Login: React.FC = () => {
  const isAuthorized = useSelector<RootState, boolean>(state => state.auth.isAuthorized);

  React.useEffect(() => {
    if (isAuthorized) {
      LocationService.redirectToReturnLocation();
    }
  });

  return (
    <div className="Login">
      <DocumentHead path={RoutePath.Login} />

      <PrimarySection header={'Log in to IPO Intelligence'}>
        <PrimarySectionRow>
          <LoginForm />
        </PrimarySectionRow>

        <PrimarySectionRow>
          <p className="Login__paragraph">
            Welcome to Renaissance Capital&apos;s institutional research portal. To learn more about the IPO
            Intelligence research service or, if you are a client needing assistance, please{' '}
            <a href="mailto:neinhorn@renaissancecapital.com?cc=diobbi@renaissancecapital.com; tng@renaissancecapital.com">
              email us
            </a>{' '}
            or call 203-622-2978.
          </p>
        </PrimarySectionRow>

        <PrimarySectionRow>
          <p className="Login__paragraph">
            Did you forget your password? <br />
            <Link className="Login__link" to={RoutePath.RecoverPassword}>
              {LoginTextElement.RecoverPassword}
            </Link>
          </p>
        </PrimarySectionRow>

        <PrimarySectionRow>
          <p className="Login__paragraph">
            Do you have other login issues? <br />
            <Link to={RoutePath.LoginHelp} className="Login__link">
              {LoginTextElement.FAQ}
            </Link>
          </p>
        </PrimarySectionRow>

        <PrimarySectionRow>
          <p className="Login__paragraph">
            We use Google Analytics to track our website usage. <br />
            <Link to={RoutePath.UserPrivacy} className="Login__link">
              {LoginTextElement.PrivacyPolicy}
            </Link>
          </p>
        </PrimarySectionRow>

        <PrimarySectionRow>
          <p className="Login__paragraph">
            Not a member? <br />
            <Link to={RoutePath.RequestTrial} className="Login__link">
              {LoginTextElement.Request}
            </Link>
          </p>
        </PrimarySectionRow>
      </PrimarySection>
    </div>
  );
};

export default Login;
