import React, { useCallback, useContext } from 'react';
import './ViewColumnsAddSectionItem.scss';

import { useClassName } from '../../../../../../../../../hooks/useClassName';
import { ViewColumnsAddSectionItemBlock } from './viewColumnsAddSectionItemBem';
import Checkbox from '../../../../../../../../../components/forms/Checkbox';
import { ViewColumnsAddContext, ViewColumnsAddContextProps } from '../../viewColumnsAddContext';

interface ViewColumnsAddSectionItem {
  id: string;
  label: string;
  isSelected: boolean;
  isDisabled: boolean;
}

const ColumnsSectionItem: React.FC<ViewColumnsAddSectionItem> = props => {
  const cn = useClassName(ViewColumnsAddSectionItemBlock.Root);

  const columnsContextValue = useContext<ViewColumnsAddContextProps>(ViewColumnsAddContext);

  const onChange = useCallback((): void => {
    columnsContextValue.onColumnCheck(props.id, !props.isSelected);
  }, [props.id, props.isSelected, columnsContextValue]);

  return (
    <li className={cn()}>
      <Checkbox
        name={props.id}
        label={props.label}
        isCheck={props.isSelected}
        isDisabled={props.isDisabled}
        isRaw
        onChange={onChange}
      />
    </li>
  );
};

export default ColumnsSectionItem;
