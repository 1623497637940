import { useEffect } from 'react';
import { HighlightOptions } from '../services/highlight/interfaces/HighlightOptions';
import { HighlightService } from '../services/highlight/HighlightService';
import '../services/highlight/Highlight.scss';

export function useHighlightEffect(searchValue: string, options: HighlightOptions): void {
  useEffect(() => {
    HighlightService.startHighlight(searchValue, options);
  }, [options, searchValue]);
}
