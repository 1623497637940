import React from 'react';
import './NewsCompanyLogo.scss';
import { useClassName } from '../../shared/hooks/useClassName';
import { TickerSymbol, Url } from '../../shared/types';
import CompanyLink from '../../company/components/company-link/CompanyLink';

interface Props {
  companyLogo: Url;
  ticker: TickerSymbol;
  alt?: string;
}

const NewsCompanyLogo: React.FC<Props> = props => {
  const cn = useClassName('NewsCompanyLogo');

  return (
    <CompanyLink
      className={cn()}
      ticker={props.ticker}
      visibleValue={<img className={cn('image')} src={props.companyLogo} alt={props.alt} />}
    />
  );
};

export default NewsCompanyLogo;
