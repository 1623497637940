export enum ViewColumnsAddSectionsBlock {
  Root = 'ViewColumnsAddSections',
}

export enum ViewColumnsAddSectionsElement {
  Row = 'row',
  Col = 'col',
}

export enum ViewColumnsAddSectionsModifiers {
  Two = 'two',
}
