import { StatsElementOptions } from '../../../../shared/entities/stats-element/interfaces/StatsElementOptions';
import { recentIpoPerfomanceChartOptions } from '../../main-dashboard/components/recent-ipo-performance-chart/constants/recentIpoPerfomanceStatsElementOptions';
import { OptionsListProp } from '../../../../shared/components/chart/types/OptionsListProp';
import { rangeSelectorButtons } from '../../../../shared/components/chart/constants/rangeSelectorButtons';
import { RangeSelectorButtonType } from '../../../../shared/components/chart/enums/RangeSelectorButtonType';

export const recentIposPerfomanceStatsElementOptions: StatsElementOptions = {
  chartsOptions: [
    {
      ...recentIpoPerfomanceChartOptions,
      title: '',
      optionsList: [
        ...(recentIpoPerfomanceChartOptions.optionsList as OptionsListProp),
        {
          rangeSelector: {
            buttons: [
              rangeSelectorButtons[RangeSelectorButtonType.ThreeMonth],
              rangeSelectorButtons[RangeSelectorButtonType.SixMonth],
              rangeSelectorButtons[RangeSelectorButtonType.YTD],
              rangeSelectorButtons[RangeSelectorButtonType.Year],
              rangeSelectorButtons[RangeSelectorButtonType.TwoYear],
              // rangeSelectorButtons[RangeSelectorButtonType.FiveYear], // Removing this Five Year as there is a duplicate 5 year button on /stats/recent-ipos-returns page
              rangeSelectorButtons[RangeSelectorButtonType.All],
            ],
            allButtonsEnabled: true,
            selected: 1,
            inputEnabled: false,
          },
        },
      ],
    },
  ],
};
