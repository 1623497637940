import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from '../marketing/login/Login';
import ResetPassword from '../marketing/resetPassword/ResetPassword';
import Request from '../marketing/request/Request';
import Dashboard from '../dashboard/Dashboard';
import Priced from '../views/priced/Priced';
import { RoutePath } from '../shared/enums/RoutePath';
import Password from '../marketing/password/Password';
import Contact from '../marketing/contact/Contact';
import Help from '../help/Help';
import AccountSettings from '../account-settings/AccountSettings';
import TalkToAnalyst from '../talk-to-analyst/TalkToAnalyst';
import RatingSystem from '../ratings-system/RatingsSystem';
import EmailPreferences from '../email-preferences/EmailPreferences';
import ResearchDashboard from '../research-dashboard/ResearchDashboard';
import Company from '../company/components/Company';
import NewsLanding from '../news/landing/NewsLanding';
import { ROUTER_ID_PARAM, ROUTER_TICKER_PARAM } from '../shared/constants/router';
import NewsSearch from '../news/search/NewsSearch';
import NotFound from '../shared/components/NotFound';
import SpecialReports from '../special-reports/SpecialReports';
import LoginHelp from '../marketing/loginHelp/LoginHelp';
import UserPrivacy from '../marketing/userPrivacy/UserPrivacy';
import Scheduled from '../views/scheduled/Scheduled';
import MyFavorites from '../my-favorites/MyFavorites';
import NewsArticle from '../news/article/NewsArticle';
import RecentResearch from '../views/recent-research/RecentResearch';
import Pipeline from '../views/pipeline/Pipeline';
import PrivateCompanyWatchlist from '../views/private-company-watchlist/PrivateCompanyWatchlist';
import ScreensRoutes from './ScreensRoutes';
import MasterFilterForm from '../master-filter-form/MasterFilterForm';
import SearchResultsContainer from '../search/SearchResultsContainer';
import MasterCalendarPage from '../master-calendar/MasterCalendarPage';
import Stats from '../stats/Stats';
import Tables from '../tables/Tables';
// import Disclosures from '../disclosures/Disclosures';
import PrivateRoute from '../shared/components/PrivateRoute';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import ShowPdf from '../show-pdf/ShowPdf';
import RedirectToExternalLink from '../shared/components/RedirectToExternalLink';
import {
  EMAILS_WITH_GA_ACCESS_LIST,
  EMAILS_WITH_GA_ANALYTICS_LIST,
  MARKETING_SITE_URL,
} from '../shared/constants/environment';
import VideoTutorials from '../videos/video-tutorials/VideoTutorials';
import LatestVideos from '../videos/latest-videos/LatestVideos';
import LatestVideo from '../videos/latest-videos/LatestVideo';
import ClientsAccessLog from '../clients-access-log/ClientsAccessLog';
import GoogleAnalytics from '../google-analytics/GoogleAnalytics';
import NotAuthorized from '../shared/components/NotAuthorized';
import PrivateCompanyMonitorList from '../views/private-company-monitor-list/PrivateCompanyMonitorList';

const Routes: React.FC = () => {
  const isAuthorized = useSelector<RootState, boolean>(state => state.auth.isAuthorized);
  const userEmail = useSelector<RootState, string | undefined>(state => state.auth?.accountData?.user?.email);
  const emailsWithGaAccessList = EMAILS_WITH_GA_ACCESS_LIST.split(',');
  // const canAccessGa: any = userEmail && emailsWithGaAnalyticsList.includes(userEmail);

  return (
    <Switch>
      <Route exact path={RoutePath.Home}>
        {isAuthorized ? <Redirect to={RoutePath.Dashboard} /> : <Login />}
      </Route>

      <Route path={RoutePath.Features}>
        <RedirectToExternalLink redirectLocation={`${MARKETING_SITE_URL + RoutePath.Features}`} self={true} />
      </Route>

      <Route path={RoutePath.WhoUsesUs}>
        <RedirectToExternalLink redirectLocation={`${MARKETING_SITE_URL + RoutePath.WhoUsesUs}`} self={true} />
      </Route>

      <Route path={RoutePath.Pricing}>
        <RedirectToExternalLink redirectLocation={`${MARKETING_SITE_URL + RoutePath.Pricing}`} self={true} />
      </Route>

      <Route path={RoutePath.About}>
        <RedirectToExternalLink redirectLocation={`${MARKETING_SITE_URL + RoutePath.About}`} self={true} />
      </Route>

      <Route path={RoutePath.Login}>
        <Login />
      </Route>

      <Route path={RoutePath.Overview}>
        <RedirectToExternalLink redirectLocation={`${MARKETING_SITE_URL + RoutePath.Overview}`} self={true} />
      </Route>

      <Route path={RoutePath.ResetPassword}>
        <ResetPassword />
      </Route>

      <Route path={RoutePath.RecoverPassword}>
        <Password />
      </Route>

      <Route path={RoutePath.RequestTrial}>
        <Request />
      </Route>

      <Route path={RoutePath.Contact}>
        <Contact />
      </Route>

      <Route path={RoutePath.Disclosures}>
        <RedirectToExternalLink redirectLocation={`${MARKETING_SITE_URL + RoutePath.Disclosures}`} self={true} />
      </Route>

      <Route path={RoutePath.Help}>
        <Help />
      </Route>

      <Route path={RoutePath.LoginHelp}>
        <LoginHelp />
      </Route>

      <Route path={RoutePath.UserPrivacy}>
        <UserPrivacy />
      </Route>

      <Route exact path={RoutePath.Research}>
        <RedirectToExternalLink redirectLocation={`${MARKETING_SITE_URL + RoutePath.IPOResearch}`} self={true} />
      </Route>

      <Route exact path={RoutePath.IPOResearch}>
        <RedirectToExternalLink redirectLocation={`${MARKETING_SITE_URL + RoutePath.IPOResearch}`} self={true} />
      </Route>

      <PrivateRoute path={RoutePath.VideoTutorials} exact>
        <VideoTutorials />
      </PrivateRoute>

      <PrivateRoute path={`${RoutePath.VideoTutorials}/:videoId`}>
        <VideoTutorials />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.LatestVideos} exact>
        <LatestVideos />
      </PrivateRoute>

      <PrivateRoute path={`${RoutePath.LatestVideos}/:videoId`} component={LatestVideo} />

      <PrivateRoute path={RoutePath.News} exact>
        <NewsLanding />
      </PrivateRoute>

      <PrivateRoute path={`${RoutePath.NewsSearch}`}>
        <NewsSearch />
      </PrivateRoute>

      <PrivateRoute path={`${RoutePath.News}/:${ROUTER_ID_PARAM}`}>
        <NewsArticle />
      </PrivateRoute>

      <PrivateRoute path={`${RoutePath.Company}/:${ROUTER_TICKER_PARAM}`}>
        <Company />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.ContactUs}>
        <TalkToAnalyst />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.MyFavourites}>
        <MyFavorites />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.TablesList}>
        <Tables />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.Dashboard}>
        <Dashboard />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.AccountSettings}>
        <AccountSettings />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.AccessLogs}>
        {userEmail && emailsWithGaAccessList.includes(userEmail) ? <ClientsAccessLog /> : <NotAuthorized />}
      </PrivateRoute>

      <PrivateRoute path={RoutePath.GoogleAnalytics} exact>
        {userEmail && emailsWithGaAccessList.includes(userEmail) ? <GoogleAnalytics /> : <NotAuthorized />}
      </PrivateRoute>

      <PrivateRoute path={`${RoutePath.GoogleAnalytics}${RoutePath.PageView}/:pageUrl`}>
        {userEmail && emailsWithGaAccessList.includes(userEmail) ? <GoogleAnalytics /> : <NotAuthorized />}
      </PrivateRoute>

      <PrivateRoute path={`${RoutePath.GoogleAnalytics}${RoutePath.AccountId}/:pageUrl`}>
        {userEmail && emailsWithGaAccessList.includes(userEmail) ? <GoogleAnalytics /> : <NotAuthorized />}
      </PrivateRoute>

      <PrivateRoute path={`${RoutePath.GoogleAnalytics}${RoutePath.UserId}/:pageUrl`}>
        {userEmail && emailsWithGaAccessList.includes(userEmail) ? <GoogleAnalytics /> : <NotAuthorized />}
      </PrivateRoute>

      <PrivateRoute path={`${RoutePath.GoogleAnalytics}${RoutePath.FileActivity}/:pageUrl`}>
        {userEmail && emailsWithGaAccessList.includes(userEmail) ? <GoogleAnalytics /> : <NotAuthorized />}
      </PrivateRoute>

      <PrivateRoute path={`${RoutePath.GoogleAnalytics}${RoutePath.ExhaustedResources}/:pageUrl`}>
        {userEmail && emailsWithGaAccessList.includes(userEmail) ? <GoogleAnalytics /> : <NotAuthorized />}
      </PrivateRoute>

      <PrivateRoute path={RoutePath.ResearchRatingsSystem}>
        <RatingSystem />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.EmailPreferences}>
        <EmailPreferences />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.ResearchDashboard}>
        <ResearchDashboard />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.ResearchSpecialReports}>
        <SpecialReports />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.ResearchRecent}>
        <RecentResearch />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.Priced}>
        <Priced />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.Scheduled}>
        <Scheduled />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.Pipeline}>
        <Pipeline />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.PrivateCompanyWatchlist}>
        <PrivateCompanyWatchlist />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.PrivateCompanyMonitorList}>
        <PrivateCompanyMonitorList />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.MasterCalendar}>
        <MasterCalendarPage />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.Screens}>
        <ScreensRoutes />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.Stats}>
        <Stats />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.SearchResults}>
        <SearchResultsContainer />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.MasterFilterForm}>
        <MasterFilterForm />
      </PrivateRoute>

      <PrivateRoute path={RoutePath.Showpdf}>
        <ShowPdf />
      </PrivateRoute>

      <Route path={RoutePath.NotFound}>
        <NotFound />
      </Route>

      <Redirect to={RoutePath.NotFound} />
    </Switch>
  );
};

export default Routes;
