import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import VideoBanner from '../video-banner/VideoBanner';
import './VideoCard.scss';
import Modal from 'react-modal';
import { videoCardStyle } from '../../custom-styles/videoCardStyle';
import { VideoLocationService } from '../../services/videoLocationService';
import FormattedDate from '../../../../shared/components/FormattedDate';

interface Props {
  title: string;
  imageLocation: string;
  descript: string;
  videoTypeKey: number;
  videoLocation?: string;
  embedCode?: string;
  imageLocationTypeKey?: number;
  videoKey?: number;
  newsKey?: number;
  publishDate?: string;
  onClick?: () => void;
  videoId?: string;
}

const VideoCard: React.FC<Props> = props => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  useEffect(() => {
    if (props.videoId === '' + props.videoKey) {
      setIsModalOpen(true);
    }
  }, [props.videoId, props.videoKey]);

  function closeModal() {
    setIsModalOpen(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  return (
    <div className={'video-card'}>
      <h3
      // onClick={() => {
      //   if (props.onClick) props.onClick();
      // }}
      >
        {props.title}
      </h3>

      {props.publishDate && (
        <div className="video-card-info">
          <FormattedDate date={props.publishDate} />
        </div>
      )}
      {props.videoTypeKey === 1 ? (
        <>
          <VideoBanner {...props} onClick={() => openModal()} />
          <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={videoCardStyle} contentLabel="Video Modal">
            {props.videoLocation && (
              <iframe
                src={`${VideoLocationService.getYoutubeVideoLocation(props.videoLocation)}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            )}
            <span className={'btn-close-modal'} onClick={() => setIsModalOpen(false)}>
              <span className={'btn-text'}> &#10006;</span>
            </span>
          </Modal>
        </>
      ) : (
        <VideoBanner {...props} />
      )}
      <p className={'video-description'}>
        {props.descript}
        {props.newsKey && (
          <strong>
            {' '}
            <br />
            <Link to={`/news/${props.newsKey}`}>Video featured in this article</Link>
          </strong>
        )}
      </p>
    </div>
  );
};

export default VideoCard;
