import React, { useCallback, useContext, useMemo } from 'react';
import FormTextSelect from '../../shared/components/forms/FormTextSelect';
import { MasterCalendarContext } from '../MasterCalendarContext';
import { locationOptions } from '../../shared/entities/filters/constants/options/locationOptions';
import { ViewFilterName } from '../../shared/entities/filters/enum/types/ViewFilterName';
import './MasterCalendarFilter.scss';
import Form from '../../shared/components/forms/Form';

const MasterCalendarFilter: React.FC = () => {
  const { headquarter, setHeadquarter } = useContext(MasterCalendarContext);
  const { isSpac, setIsSpac } = useContext(MasterCalendarContext);

  const defaultValues = useMemo(
    () => ({
      [ViewFilterName.Headquarter]: headquarter,
    }),
    [headquarter],
  );

  const onChange = useCallback(
    formData => {
      setHeadquarter(formData[ViewFilterName.Headquarter]);
    },
    [setHeadquarter],
  );

  React.useEffect(() => {
    if (localStorage.getItem('pref-isSpac') === 'true') {
      setIsSpac(true);
    }
  }, []);

  const onSpacChange = useCallback(() => {
    localStorage.setItem('pref-isSpac', '' + !isSpac);
    setIsSpac(!isSpac);
  }, [setIsSpac, isSpac]);

  return (
    <>
      <Form defaultValues={defaultValues} onChange={onChange}>
        <FormTextSelect options={locationOptions} name={ViewFilterName.Headquarter} />
      </Form>
      {(headquarter === 'Us' || headquarter === 'Global') && (
        <div className={`isSpacs`}>
          <input name="isGoing" type="checkbox" checked={isSpac} onChange={onSpacChange} />
          <label onClick={onSpacChange}>Include SPACs</label>
        </div>
      )}
    </>
  );
};

export default MasterCalendarFilter;
