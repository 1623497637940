import React from 'react';
import NewsTitle from '../../components/NewsTitle';
import './NewsLandingMost.scss';
import { NewsMostViewedDto } from '../../interfaces/NewsMostViewedDto';
import LoaderContainer from '../../../shared/components/loader/LoaderContainer';
import { useClassName } from '../../../shared/hooks/useClassName';
import NewsLandingMostItem from './NewsLandingMostItem';
import { useNewsMostItems } from '../hooks/useNewsMostItems';

const NewsLandingMost: React.FC = () => {
  const [items, isLoading] = useNewsMostItems();

  const cn = useClassName('NewsLandingMost');

  return (
    <div className={cn()}>
      <NewsTitle>Most Viewed</NewsTitle>

      {isLoading ? (
        <LoaderContainer />
      ) : (
        <ul className={cn('list')}>
          {items.map((item: NewsMostViewedDto, index: number) => (
            <li className={cn('item')} key={index}>
              <NewsLandingMostItem data={item} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NewsLandingMost;
