import React from 'react';
import View from '../../shared/entities/view/components/view/View';
import { scheduledViewOptions } from './constants/scheduledViewOptions';
import { ViewType } from '../../shared/entities/view/components/view/enums/ViewType';

const Scheduled: React.FC = () => {
  return <View options={scheduledViewOptions} viewType={ViewType.Scheduled} />;
};

export default Scheduled;
