import { FilterControlSettings } from '../../../types/FilterControlSettings';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { ControlSize } from '../../../../../enums/ControlSize';
import { dateControlOptions } from '../../options/dateControlsOptions';

export const dateControls: FilterControlSettings = {
  [ViewFilterName.DateAddedToPCW]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName.DateAddedToPCW,
    props: {
      name: ViewFilterName.DateAddedToPCW,
      label: 'Added to the PCW within the Past',
      options: dateControlOptions,
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.DateUpdateInPast]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName.DateUpdateInPast,
    props: {
      name: ViewFilterName.DateUpdateInPast,
      options: dateControlOptions,
      label: 'Updated in the Past',
      size: ControlSize.Small,
    },
  },
};
