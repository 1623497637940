import React from 'react';
import './CompanyProfileSideItem.scss';
import { useClassName } from '../../../../../shared/hooks/useClassName';
import SecondarySectionSwitch, {
  SecondarySectionSwitchProps,
} from '../../../../../shared/components/secondary-section/SecondarySectionSwitchProps';
import CompanySectionRow from '../../../shared/CompanySectionRow';

export type CompanyProfileSideItemProps = SecondarySectionSwitchProps;

const CompanyProfileSideItem: React.FC<CompanyProfileSideItemProps> = props => {
  const cn = useClassName('CompanyProfileSideItem', props.className);

  return (
    <CompanySectionRow>
      <SecondarySectionSwitch {...props} className={cn()} />
    </CompanySectionRow>
  );
};

export default CompanyProfileSideItem;
