import React from 'react';
import { ColorScheme } from '../../enums/ColorScheme';
import './PrimarySection.scss';
import { PrimarySectionIndent } from './PrimarySectionIndent';
import { PrimarySectionAdditionalActionOptions } from './interfaces/PrimarySectionAdditionalActionOptions';
import PrimarySectionAdditionalAction from './PrimarySectionAdditionalAction';
import { useClassName } from '../../hooks/useClassName';
import { PrimarySectionSubheaderOptions } from './interfaces/PrimarySectionSubheaderOptions';
import PrimarySectionSubheader from './PrimarySectionSubheader';
import { PrimarySectionBlock, PrimarySectionElement, PrimarySectionModifier } from './primarySectionBem';
import PageLoader from '../loader/PageLoader';
import { ControlSize } from '../../enums/ControlSize';
import { useLocation } from 'react-router-dom';
import { RoutePath } from '../../enums/RoutePath';

export interface PrimarySectionProps {
  indent?: PrimarySectionIndent;
  colorScheme?: ColorScheme;
  className?: string;
  header?: React.ReactNode;
  subHeader?: PrimarySectionSubheaderOptions;
  additionalAction?: PrimarySectionAdditionalActionOptions;
  isUnderlinedHeader?: boolean;
  maxContainerSize?: ControlSize;
  isLoading?: boolean;
}

const PrimarySection: React.FC<PrimarySectionProps> = props => {
  const { indent = PrimarySectionIndent.External, maxContainerSize } = props;

  const cn = useClassName(PrimarySectionBlock.Root, props.className);
  const containerCn = useClassName(PrimarySectionElement.Container);

  const urlLocation = useLocation();
  const addExtraPadding = urlLocation.pathname === RoutePath.ScreensWithdrawnPostponedInUse ? true : false;

  return (
    <section
      className={cn({
        [indent]: indent,
        [props.colorScheme as string]: props.colorScheme,
      })}
    >
      <div
        className={containerCn({
          [`${maxContainerSize}`]: maxContainerSize,
        })}
      >
        {props.additionalAction && <PrimarySectionAdditionalAction options={props.additionalAction} />}

        {props.header && (
          <h1
            className={cn(PrimarySectionElement.Header, {
              [PrimarySectionModifier.Underlined]: props.isUnderlinedHeader,
              [PrimarySectionModifier.ExtraPadding]: addExtraPadding,
            })}
          >
            {props.header}
          </h1>
        )}

        {props.subHeader && <PrimarySectionSubheader options={props.subHeader} />}

        <PageLoader loading={props.isLoading}>
          <div className={cn(PrimarySectionElement.Content)}>{props.children}</div>
        </PageLoader>
      </div>
    </section>
  );
};

export default PrimarySection;
