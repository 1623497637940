import { StatsElementOptions } from '../../../../../shared/entities/stats-element/interfaces/StatsElementOptions';

const MEDIAN_AGE_TOTALS_GRID_INDEX = 0;

export const medianAgeStatsElementOptions: StatsElementOptions = {
  chartsOptions: [
    {
      optionsList: [
        {
          yAxis: {
            title: {
              text: 'Median Company Age (years)',
            },
          },
        },
      ],
    },
  ],
  gridOptions: {
    [MEDIAN_AGE_TOTALS_GRID_INDEX]: {
      isHighlightLastRow: true,
      // onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(() => ({
      //   viewType: ViewType.CustomInUse,
      //   startDateFilterName: ViewFilterName.OfferStartDate,
      //   endDateFilterName: ViewFilterName.OfferEndDate,
      //   columns: {
      //     web: medianAgeCustomScreenColumns,
      //     excel: medianAgeCustomScreenColumns,
      //   },
      // })),
    },
  },
};
