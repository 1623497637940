import React, { useCallback, useMemo, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { AnimatedHeightType } from './enums/AnimatedHeightType';
import { DomService } from '../../../services/dom/DomService';
import { useClassName } from '../../../hooks/useClassName';
import { CustomAny } from '../../../types/generics';
import { useResize } from '../../../effects/useResize';

interface Props {
  isVisible: boolean;
  hasAnimation?: boolean;
  visibleHiddenHeight?: number;
  className?: string;
  onChangeContentHeight?: (canContentAnimate: boolean) => void;
}

const ANIMATION_DURATION_DEFAULT = 500;
const ANIMATION_DURATION_OFF = 0;
const HIDDEN_HEIGHT = 0;

const STYLE = {
  width: '100%',
};

const AnimatedHeight: React.FC<Props> = props => {
  const { hasAnimation = true, visibleHiddenHeight = HIDDEN_HEIGHT, onChangeContentHeight } = props;

  const ref = useRef<AnimateHeight>(null);

  const cn = useClassName('AnimatedHeight', props.className);

  const [canContentAnimate, setCanContentAnimate] = useState(true);

  const animationDuration = useMemo(() => (hasAnimation ? ANIMATION_DURATION_DEFAULT : ANIMATION_DURATION_OFF), [
    hasAnimation,
  ]);
  const height = useMemo(() => {
    if (!props.isVisible) {
      return visibleHiddenHeight;
    }

    return props.isVisible || !canContentAnimate ? AnimatedHeightType.Auto : visibleHiddenHeight;
  }, [props.isVisible, visibleHiddenHeight, canContentAnimate]);

  const onAnimationEnd = useCallback(() => {
    if (props.isVisible) {
      DomService.resize();
    }
  }, [props.isVisible]);

  useResize(() => {
    const contentEl: HTMLElement | undefined = (ref.current as CustomAny)?.contentElement;

    if (contentEl) {
      const contentElHeight = contentEl.clientHeight;
      const canContentAnimate = visibleHiddenHeight < contentElHeight;

      setCanContentAnimate(canContentAnimate);
      onChangeContentHeight?.(canContentAnimate);
    }
  }, [visibleHiddenHeight, onChangeContentHeight, props.children]);

  return (
    <AnimateHeight
      ref={ref}
      className={cn()}
      contentClassName={cn('content')}
      duration={animationDuration}
      height={height}
      style={STYLE}
      onAnimationEnd={onAnimationEnd}
    >
      {props.children}
    </AnimateHeight>
  );
};

export default AnimatedHeight;
