import { GridPublicApi } from '../interfaces/GridPublicApi';
import { GridApi, RowNode } from '@ag-grid-community/core';
import { GridDomLayout } from '../enums/GridDomLayout';
import { PrintService } from '../../../../services/PrintService';
import { GridUpdateCellParams } from '../interfaces/GridUpdateCellParams';
import { OnGridPrintFn } from '../types/OnGridPrintFn';

export class GridApiService {
  static getPublicApi(gridApi: GridApi, layout: GridDomLayout, onPrintFn: OnGridPrintFn): GridPublicApi {
    return {
      print: this.print.bind(this, gridApi, layout, onPrintFn),
      getVisibleRows: this.getVisibleRows.bind(this, gridApi),
      updateCellsByIds: this.updateCellsByIds.bind(this, gridApi),
      getColumnValues: this.getColumnValues.bind(this),
    };
  }

  // https://www.ag-grid.com/javascript-grid-for-print/#detailed-printing-example
  private static print(gridApi: GridApi, layout: GridDomLayout, onPrintFn: OnGridPrintFn): void {
    gridApi.setDomLayout(GridDomLayout.Print);

    onPrintFn(true, gridApi);

    setTimeout(() => {
      PrintService.print();

      onPrintFn(false, gridApi);

      setTimeout(() => {
        gridApi.setDomLayout(layout);
      }, 0);
    }, 2000);
  }

  private static getVisibleRows(gridApi: GridApi): RowNode[] {
    return gridApi.getRenderedNodes();
  }

  private static updateCellById<T>(gridApi: GridApi, param: GridUpdateCellParams<T>): void {
    const rowNode = this.getRowNodeById(gridApi, param.rowId);
    rowNode.setDataValue(param.cellName, param.cellValue);
  }

  private static updateCellsByIds<T>(gridApi: GridApi, params: GridUpdateCellParams<T>[]): void {
    params.forEach(param => this.updateCellById(gridApi, param));
  }

  private static getRowNodeById(gridApi: GridApi, rowId: string): RowNode {
    return gridApi.getRowNode(rowId);
  }

  private static getColumnValues<T>(rowNodes: RowNode[], columnName: string): T[] {
    return rowNodes.map<T>(rowNode => rowNode.data[columnName]);
  }
}
