import React from 'react';
import Helmet from 'react-helmet';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import PageLoader from '../../shared/components/loader/PageLoader';

import { VideosDto } from '../shared/interfaces/VideosDto';
import { usePromiseState } from '../../shared/hooks/promise-state/usePromiseState';
import VideoCard from '../shared/components/video-card/VideoCard';

interface Props {
  getMemoizedLatestVideos: () => Promise<VideosDto[]>;
}

const LatestVideosContent: React.FC<Props> = props => {
  const [LatestVideos, areLatestVideosLoading] = usePromiseState(props.getMemoizedLatestVideos, []);
  return (
    <>
      <Helmet>
        <title>Latest Videos</title>
      </Helmet>

      <PrimarySection className={'videos-section'} header="Latest Videos">
        <PageLoader loading={areLatestVideosLoading}>
          {LatestVideos.map((item, index) => {
            return (
              <VideoCard
                key={index}
                title={item.title}
                videoTypeKey={item.videoTypeKey}
                videoKey={item.videoKey}
                imageLocation={item.imageLocation}
                descript={item.descript}
                newsKey={item.newsKey}
                publishDate={item.publishDate}
              />
            );
          })}
        </PageLoader>
      </PrimarySection>
    </>
  );
};

export default LatestVideosContent;
