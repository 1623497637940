import { ReactNode } from 'react';
import { ControlDivider } from './enums/ControlDivider';

export class ControlDividerFactory {
  static getComponent(type: ControlDivider): ReactNode {
    switch (type) {
      case ControlDivider.Comma:
        return ',';
      case ControlDivider.Slash:
        return '/';
    }
  }
}
