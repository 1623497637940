import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useClassName } from '../../../../../../hooks/useClassName';
import { ViewGridSearchBlock, ViewGridSearchElement } from './viewGridSearchBem';
import { ViewGridContext } from '../../context/view-grid/ViewGridContext';
import { ViewGridSearchProp } from '../../interfaces/ViewGridSearchProp';
import FormSearch from '../../../../../../components/forms/FormSearch';
import FiltersMap from '../../../../../filters/components/filterable-component/FiltersMap';
import AccordionFormGroup from '../../../../../../components/forms/AccordionFormGroup';
import { ViewFilterService } from '../../../view/services/ViewFilterService';
import { FormControlCounterService } from '../../../../../../components/forms/components/form-builder/services/FormControlCounterService';
import { FormControl } from '../../../../../../components/forms/components/form-builder/types/formControlsTypes';
import { Location } from '../../../../../filters/enum/Location';
import { ScreensLocationScreenByValue } from '../../../../../../../screens/components/screens/location/enums/ScreensLocationScreenByValue';

const ViewGridSearch: React.FC = () => {
  const cn = useClassName(ViewGridSearchBlock.Root);
  const [option, setOption] = React.useState(Location.US);
  const [prevScreenBy, setPrevScreenBy] = React.useState(ScreensLocationScreenByValue.ListingLocation);

  const context = useContext(ViewGridContext);

  React.useEffect(() => {
    if (context?.search?.defaultValues?.screenBy === ScreensLocationScreenByValue.CompanyHQ) {
      if (prevScreenBy === ScreensLocationScreenByValue.ListingLocation) {
        context.search.defaultValues.LocationListingLocation = option;
      } else {
        setOption(context.search.defaultValues.LocationListingLocation);
      }

      setPrevScreenBy(ScreensLocationScreenByValue.CompanyHQ);
    }
    if (context?.search?.defaultValues?.screenBy === ScreensLocationScreenByValue.ListingLocation) {
      context.search.defaultValues.LocationListingLocation = Location.Global;
      setPrevScreenBy(ScreensLocationScreenByValue.ListingLocation);
    }
  }, [context?.search?.defaultValues]);

  const search = context.search as ViewGridSearchProp;

  const closeState = useState(true);

  const controlsNames = useMemo(() => {
    const mainControlsNames = FormControlCounterService.getControlsNames(search.controls as FormControl[]);
    const additionalControlNames = search.additionalControls
      ? FormControlCounterService.getControlsNames(search.additionalControls as FormControl[])
      : [];

    return [...mainControlsNames, ...additionalControlNames];
  }, [search.controls, search.additionalControls]);

  const additionalControlsTitle = useMemo(() => search.additionalControlsTitle || 'More', [
    search.additionalControlsTitle,
  ]);

  const onChange = useCallback(
    searchValues => {
      if (ViewFilterService.isSubFiltersChanged(searchValues, search.defaultValues)) {
        search.onChange?.(searchValues);
      }
    },
    [search.defaultValues, search.onChange],
  );

  return (
    <FormSearch
      className={cn()}
      defaultValues={search.defaultValues}
      controlsNames={controlsNames}
      onChange={onChange}
      onSubmit={search.onSearch}
    >
      <FiltersMap filterControls={search.controls} dictionaries={search.dictionaries} />

      {search.additionalControls && (
        <AccordionFormGroup
          classNames={ViewGridSearchElement.AdditionalControls}
          title={additionalControlsTitle}
          isIconPin={false}
          closeState={closeState}
        >
          {<FiltersMap filterControls={search.additionalControls} dictionaries={search.dictionaries} />}
        </AccordionFormGroup>
      )}
    </FormSearch>
  );
};

export default ViewGridSearch;
