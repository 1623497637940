import React from 'react';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { useClassName } from '../../../../../../hooks/useClassName';
import { EstValuationCellBlock } from './EstValuationCellBem';
import Tooltip from '../../../../../../components/tooltip/Tooltip';
import useBreakpointAvailable from '../../../../../../effects/useBreakpointAvailable';
import { profileMobileBreakpoints } from '../../../../../../../company/components/profile/shared/constants/profileMobileBreakpoints';

type Props = GridCellFormatterCommonProps;

const CfoCell: React.FC<Props> = props => {
  const cn = useClassName(EstValuationCellBlock.Root);
  const isMobile = useBreakpointAvailable(profileMobileBreakpoints);
  let PcmlCfoTitle = '';
  if (props.data.cfo) {
    if (props.data.cfo.length > 8) {
      PcmlCfoTitle = props.data.cfo.substring(0, 8) + '...';
    } else {
      PcmlCfoTitle = props.data.cfo;
    }
  }

  return (
    <CustomCellFormatterWrapper className={cn()} value={props.valueFormatted}>
      {props.data.cfo && props.data.cfo.length > 8 && !isMobile ? (
        <Tooltip
          className={cn()}
          isTitleHighlighted
          isUnderFixed
          //isUnderFixed={true}
          content={`CFO: ` + props.data.cfoVisible}
          title={PcmlCfoTitle}
        />
      ) : (
        PcmlCfoTitle
      )}
    </CustomCellFormatterWrapper>
  );
};

export default CfoCell;
