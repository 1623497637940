import React, { useCallback, useState, useMemo } from 'react';
import { isString } from 'lodash';
import FavouriteIcon from '../../../shared/components/icon/FavouriteIcon';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { isFavouriteSelector } from '../../../store/favourite-company/selectors';
import { addFavouriteCompany, removeFavouriteCompany } from '../../../store/favourite-company/actions';

export interface FavouriteProps {
  companyKey: number | string;
  companyName: string;
  isActionDisabled?: boolean;
}

const Favourite: React.FC<FavouriteProps> = props => {
  const companyKey = useMemo<number>(
    () => (isString(props.companyKey) ? parseInt(props.companyKey) : props.companyKey),
    [props.companyKey],
  );

  const dispatch = useDispatch();
  const isFavourite = useSelector<RootState, boolean>(state => isFavouriteSelector(state.favouriteCompany, companyKey));

  const [isDisabled, setIsDisabled] = useState(props.isActionDisabled);

  const onUpdateFavourite = useCallback(async () => {
    const company = {
      companyKey: companyKey,
      companyName: props.companyName,
    };

    const onFinally = (): void => setIsDisabled(false);

    setIsDisabled(true);

    if (isFavourite) {
      dispatch(removeFavouriteCompany(company, onFinally));
    } else {
      dispatch(addFavouriteCompany(company, onFinally));
    }
  }, [props.companyName, companyKey, dispatch, isFavourite]);

  return <FavouriteIcon isDisabled={isDisabled} isActive={isFavourite} onFavouriteClick={onUpdateFavourite} />;
};

export default Favourite;
