import React, { useContext, useMemo } from 'react';
import './ViewShareLink.scss';
import { ViewShareLinkBlock, ViewShareLinkElement } from './viewShareLinkBem';
import { ViewLocationService } from '../../services/ViewLocationService';
import { RoutePath } from '../../../../../../enums/RoutePath';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { ViewContext } from '../../ViewContext';
import { ColorScheme } from '../../../../../../enums/ColorScheme';
import { ViewSharedLink } from '../../interfaces/api/ViewSharedLink';
import { useClassName } from '../../../../../../hooks/useClassName';
import ClipboardCopy from '../../../../../../components/clipboard-copy/ClipboardCopy';
import Button from '../../../../../../components/forms/Button';

interface Props extends ViewSharedLink {
  onAction: () => void;
}

const ViewShareLink: React.FC<Props> = props => {
  const cn = useClassName(ViewShareLinkBlock.Root);

  const { state } = useContext(ViewContext);

  const url = useMemo<string>(() => ViewLocationService.getUrl(state.viewType, props.dataViewId), [
    props.dataViewId,
    state.viewType,
  ]);
  const routePath = useMemo<RoutePath>(() => ViewLocationService.getRoutePath(state.viewType, props.dataViewId), [
    props.dataViewId,
    state.viewType,
  ]);

  return (
    <div className={cn()}>
      <div className={cn(ViewShareLinkElement.Header)}>{props.linkName}</div>

      <div className={cn(ViewShareLinkElement.Actions)}>
        <div className={cn(ViewShareLinkElement.Action)}>
          <ClipboardCopy valueToCopy={url} onCopy={props.onAction}>
            <Button size={ControlSize.Small} colorScheme={ColorScheme.Primary}>
              Copy
            </Button>
          </ClipboardCopy>
        </div>

        <div className={cn(ViewShareLinkElement.Action)}>
          <Button
            size={ControlSize.Small}
            buttonLink={{
              isOpenInNewTab: true,
              routePath,
            }}
            colorScheme={ColorScheme.Primary}
            onClick={props.onAction}
          >
            Open
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewShareLink;
