import React from 'react';
import CompanyProfileSideBar from './CompanyProfileSideBar';
import CompanyProfileContent from './CompanyProfileContent';
import './CompanyDesktopProfile.scss';

const CompanyDesktopProfile: React.FC = () => {
  return (
    <div className="CompanyDesktopProfile">
      <CompanyProfileSideBar />

      <CompanyProfileContent />
    </div>
  );
};

export default CompanyDesktopProfile;
