import React from 'react';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { screensExpiredLockUpsInUseOptions } from './constants/screensExpiredLockUpsInUseOptions';
import ScreensInUse from '../../shared/pages/components/in-use/ScreensInUse';
import { EXPIRED_LOCK_UPS_VIEW_ID } from '../../../../shared/constants/environment';

const ScreensExpiredLockUpsInUse: React.FC = () => {
  const viewId = parseInt(EXPIRED_LOCK_UPS_VIEW_ID);

  return <ScreensInUse viewId={viewId} inUseType={ViewType.LockUpsInUse} options={screensExpiredLockUpsInUseOptions} />;
};

export default ScreensExpiredLockUpsInUse;
