import { Location } from '../../filters/enum/Location';
import { CalendarEvent } from '../interfaces/CalendarEvent';

export class CalendarFilterService {
  static filterByHeadquarter(headquarter: Location, events: CalendarEvent[]): CalendarEvent[] {
    if (headquarter === Location.Global) {
      return events;
    }

    return events.filter(event => event.headquarter === headquarter);
  }
  static filterBySpac(isSpac: boolean, headquarter: Location, events: CalendarEvent[]): CalendarEvent[] {
    if (isSpac === true || headquarter === Location.NonUS) {
      return events;
    }

    return events.filter(event => event.isSpac === false);
  }
}
