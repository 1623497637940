import React from 'react';
import './FormGroup.scss';
import { useClassName } from '../../hooks/useClassName';
import { FormGroupBlock, FormGroupExternalClassName, FormGroupModifier } from './formGroupBem';
import classNames from 'classnames';

interface Props {
  className?: string;
  isFullWidth?: boolean;
  hasNoIndentVertical?: boolean;
  hasShiftHorizontal?: boolean;
  isDisabled?: boolean;
}

const FormGroup: React.FC<Props> = props => {
  const cn = useClassName(FormGroupBlock.Root, props.className);

  return (
    <div
      className={classNames(
        cn({
          [FormGroupModifier.FullWidth]: props.isFullWidth,
          [FormGroupModifier.NoIndentVertical]: props.hasNoIndentVertical,
          [FormGroupModifier.ShiftHorizontal]: props.hasShiftHorizontal,
          [FormGroupModifier.IsDisabled]: props.isDisabled,
        }),
        FormGroupExternalClassName.FormGroup,
      )}
    >
      {props.children}
    </div>
  );
};

export default FormGroup;
