import React from 'react';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { screensSpacsInUseOptions } from './constants/screensSpacsInUseOptions';
import ScreensInUse from '../../shared/pages/components/in-use/ScreensInUse';

const ScreensSpacsInUse: React.FC = () => {
  return <ScreensInUse inUseType={ViewType.SpacsInUse} options={screensSpacsInUseOptions} />;
};

export default ScreensSpacsInUse;
