import { useMemo, useState, useCallback, useEffect } from 'react';
import { CompanyContextValue } from './CompanyContext';
import { TeamNote } from '../../interfaces/TeamNote';
import { TeamNotesApiService } from '../../../shared/api/TeamNotesApiService';
import { CompanyTeamNotesData } from '../team-notes/form/CompanyTeamNotesData';
import { useParams } from 'react-router-dom';
import { RouteTickerParam } from '../../../shared/interfaces/RouteTickerParam';
import { Nullable } from '../../../shared/types/generics';
import { CompanyApiService } from '../../../shared/api/CompanyApiService';
import { useContextData } from '../../../shared/hooks/context-data/useContextData';
import { CompanyPerfomanceVsIpoIndexTerm } from '../shared/performance-vs-ipo-Index-chart/interfaces/CompanyPerfomanceVsIpoIndexTerm';
import { CompanyPerfomanceVsIpoIndexChartDto } from '../../types/CompanyPerfomanceVsIpoIndexChartDto';
import { CompanyTabsDto } from '../../interfaces/CompanyTabsDto';
import { CompanyTab } from '../../enums/CompanyTab';
import { CompanyPeerTradingDateRangeService } from '../shared/performance-vs-ipo-Index-chart/service/CompanyPeerTradingDateRangeService';
import { TickerSymbol } from '../../../shared/types';
import { useAsyncFn } from '../../../shared/hooks/async-fn/useAsyncFn';
import { CompanyPeerTradingDto } from '../../interfaces/CompanyPeerTradingDto';
import { SimpleGridProps } from '../../../shared/components/grid/simple-grid/SimpleGrid';

export function useCompanyContextValue(): CompanyContextValue {
  const { ticker } = useParams<RouteTickerParam>();

  // using the following piece of code to account for tickers with a '/' in their name like HOM/U.CN
  // const loc = useLocation()
  //   ?.pathname?.split('/')
  //   .slice(2)
  //   .filter(param => param !== 'profile')
  //   .join('/');
  // const ticker = loc;

  const [perfomanceChartTickers, setPerfomanceChartTickers] = useState<TickerSymbol[]>([ticker]);
  const [perfomanceVsIpoIndexTerm, setPerfomanceVsIpoIndexTerm] = useState<Nullable<CompanyPerfomanceVsIpoIndexTerm>>(
    null,
  );

  // TODO: Refactor this piece of code to fetch data using best practices
  const [fundamentalComparisonData, setFundamentalComparisonData] = useState<Nullable<SimpleGridProps>>(null);
  const [technicalComparisonData, setTechnicalComparisonData] = useState<Nullable<SimpleGridProps>>(null);
  const [valuationComparisonData, setValuationComparisonData] = useState<Nullable<SimpleGridProps>>(null);

  useEffect(() => {
    let current = true;
    let fundamentalComparisonDataResponse: null | SimpleGridProps = null;
    let valuationComparisonDataResponse: null | SimpleGridProps = null;
    let technicalComparisonDataResponse: null | SimpleGridProps = null;

    CompanyApiService.getFundamentalComparisonData(ticker)
      .then(data => {
        fundamentalComparisonDataResponse = data;
      })
      .finally(() => {
        CompanyApiService.getValuationComparisonData(ticker)
          .then(data => {
            valuationComparisonDataResponse = data;
          })
          .finally(() => {
            CompanyApiService.getTechnicalComparisonData(ticker)
              .then(data => {
                technicalComparisonDataResponse = data;
              })
              .finally(() => {
                if (current) {
                  setFundamentalComparisonData(fundamentalComparisonDataResponse);
                  setTechnicalComparisonData(technicalComparisonDataResponse);
                  setValuationComparisonData(valuationComparisonDataResponse);
                }
              });
          });
      });

    return () => {
      current = false;
    };
  }, [ticker]);

  const tabsData = useContextData<Nullable<CompanyTabsDto>>(
    useCallback(() => CompanyApiService.getAllData(ticker), [ticker]),
    null,
  );

  const getTabData = useCallback(
    (tab: CompanyTab) => {
      const [data] = tabsData;
      const tabData = data?.[tab];

      return tabData;
    },
    [tabsData],
  );

  const unreadTeamNotesCount = useContextData<Nullable<number>>(
    TeamNotesApiService.getUnreadCount.bind(TeamNotesApiService, ticker),
    null,
  );

  const perfomanceVsIpoIndexChart = useContextData(
    (terms: CompanyPerfomanceVsIpoIndexTerm) =>
      CompanyApiService.getPerfomanceVsIpoIndexChart(perfomanceChartTickers, perfomanceVsIpoIndexTerm),
    null,
  );

  const teamNotes = useContextData<TeamNote[]>(TeamNotesApiService.getAll.bind(TeamNotesApiService, ticker), []);

  const updateTeamNotes = useCallback(() => {
    const [, loadTeamNotes] = teamNotes;

    loadTeamNotes();
  }, [teamNotes]);

  const removeTeamNote = useCallback(
    async (id: string) => {
      await TeamNotesApiService.deleteOne(id);

      updateTeamNotes();
    },
    [updateTeamNotes],
  );

  const sendTeamNote = useCallback(
    async (data: CompanyTeamNotesData) => {
      await TeamNotesApiService.sendOne(data.message, ticker);

      updateTeamNotes();
    },
    [updateTeamNotes, ticker],
  );

  const initializePerfomanceVsIpoIndexTerm = useCallback(
    (chartData: CompanyPerfomanceVsIpoIndexChartDto[]) => {
      const data = CompanyPeerTradingDateRangeService.getFormattedMaxDateRangeFromSeries(chartData);

      setPerfomanceVsIpoIndexTerm(data);
    },
    [setPerfomanceVsIpoIndexTerm],
  );

  const initializePerfomanceVsIpoIndexChart = useCallback(() => {
    const [, , , setChartData] = perfomanceVsIpoIndexChart;
    const peerTradingData = getTabData(CompanyTab.PeerTrading) as CompanyPeerTradingDto;

    setChartData(peerTradingData?.chart.series);

    if (peerTradingData?.chart.series) {
      setPerfomanceChartTickers(CompanyPeerTradingDateRangeService.getTickersFromSeries(peerTradingData?.chart.series));

      initializePerfomanceVsIpoIndexTerm(peerTradingData?.chart.series);
    }
  }, [perfomanceVsIpoIndexChart, getTabData, setPerfomanceChartTickers, initializePerfomanceVsIpoIndexTerm]);

  const addPerfomanceChartTicker = useAsyncFn<void>(async (ticker: TickerSymbol) => {
    if (!perfomanceChartTickers.includes(ticker) && !!ticker) {
      const [, , , setPerfomanceVsIpoIndexChart] = perfomanceVsIpoIndexChart;

      const data = await CompanyApiService.getPerfomanceVsIpoIndexChart(
        [...perfomanceChartTickers, ticker],
        perfomanceVsIpoIndexTerm,
      );

      setPerfomanceVsIpoIndexChart(data);
      setPerfomanceChartTickers(perfomanceChartTickers.concat([ticker]));
    }
  });

  return useMemo(
    () => ({
      perfomanceChartTickers,
      tabsData,
      fundamentalComparisonData,
      technicalComparisonData,
      valuationComparisonData,
      setPerfomanceVsIpoIndexTerm,
      initializePerfomanceVsIpoIndexChart,
      addPerfomanceChartTicker,
      getTabData,
      perfomanceVsIpoIndexTerm,
      ticker,
      perfomanceVsIpoIndexChart,
      teamNotes,
      unreadTeamNotesCount,
      removeTeamNote,
      sendTeamNote,
    }),
    [
      perfomanceChartTickers,
      tabsData,
      fundamentalComparisonData,
      technicalComparisonData,
      valuationComparisonData,
      setPerfomanceVsIpoIndexTerm,
      initializePerfomanceVsIpoIndexChart,
      addPerfomanceChartTicker,
      perfomanceVsIpoIndexTerm,
      ticker,
      getTabData,
      teamNotes,
      perfomanceVsIpoIndexChart,
      unreadTeamNotesCount,
      sendTeamNote,
      removeTeamNote,
    ],
  );
}
