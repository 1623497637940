import { EmailPreferenceDto } from '../form/interfaces/EmailPreferenceDto';
import { EmailPreferencesFormData } from '../form/EmailPreferencesFormData';
import { EmailPreferenceSectionItem } from '../form/interfaces/EmailPreferenceSectionItem';
import { EmailPreferenceSection } from '../form/interfaces/EmailPreferenceSection';
import { INITIAL_SECTIONS } from '../form/constants/sections';
import { CustomAny } from '../../shared/types/generics';

export class EmailPreferencesService {
  static getSectionsItemsFromFormData(
    sectionsData: EmailPreferenceDto,
    formData: EmailPreferencesFormData,
  ): EmailPreferenceDto {
    const { emailTypes, filterByCriteria } = Object.assign({}, sectionsData);

    if (emailTypes && filterByCriteria) {
      return {
        emailTypes: this.getSectionItemsFromFormData(emailTypes, formData),
        filterByCriteria: this.getSectionItemsFromFormData(filterByCriteria, formData),
      };
    }

    return INITIAL_SECTIONS;
  }

  static getSectionItemsFromFormData(
    sectionData: EmailPreferenceSectionItem[],
    formData: EmailPreferencesFormData,
  ): EmailPreferenceSectionItem[] {
    return sectionData.map((sectionItem: EmailPreferenceSectionItem) => ({
      ...sectionItem,
      preferences: this.getPreferencesWithValue(sectionItem.preferences, formData),
    }));
  }

  static getPreferencesWithValue(
    preferences: EmailPreferenceSection[],
    formData: EmailPreferencesFormData,
  ): EmailPreferenceSection[] {
    return preferences.map((item: EmailPreferenceSection) => ({
      ...item,
      value: this.getPreferenceValue(item, formData),
    }));
  }

  static getPreferenceValue(item: EmailPreferenceSection, formData: EmailPreferencesFormData) {
    if (!!formData[item.name]) {
      return formData[item.name] as string;
    }

    if (!!formData[item.name]) {
      return item.name;
    }

    return undefined;
  }

  static getFormDataFromSectionsItems(sectionsData: EmailPreferenceDto): Partial<EmailPreferencesFormData> {
    const newSectionsData = Object.assign({}, sectionsData);

    const emailTypesFormData = newSectionsData.emailTypes
      ? this.getFormDataFromSectionItems(newSectionsData.emailTypes)
      : {};
    const filterByCriteriaFormData = newSectionsData.filterByCriteria
      ? this.getFormDataFromSectionItems(newSectionsData.filterByCriteria)
      : {};

    return { ...emailTypesFormData, ...filterByCriteriaFormData };
  }

  static getFormDataFromSectionItems(sectionData: EmailPreferenceSectionItem[]): Partial<EmailPreferencesFormData> {
    return sectionData.reduce((accum: CustomAny, item: EmailPreferenceSectionItem) => {
      return { ...accum, ...this.getPreferencesFormData(item.preferences) };
    }, {});
  }

  static getPreferencesFormData(preferences: EmailPreferenceSection[]): EmailPreferencesFormData {
    return preferences.reduce((accum: CustomAny, item: EmailPreferenceSection) => {
      return { ...accum, [item.name]: item.value };
    }, {});
  }
}
