import React, { useMemo, SetStateAction, Dispatch } from 'react';
import './SubMenu.scss';
import SubMenuTooltip from './SubMenuTooltip';
import SubMenuAccordion from './SubMenuAccordion';
import useBreakpointAvailable from '../../../effects/useBreakpointAvailable';
import { NavItem } from '../../nav/types/NavItem';
import { mobileBreakpoints } from '../../../constants/breakpoints/mobileBreakpoints';

interface Props {
  title: React.ReactNode;
  items: NavItem[];
  className?: string;
  onToggle?: (isShow: boolean) => void;
  onItemClick?: () => void;
  show?: boolean;
  setShow?: Dispatch<SetStateAction<boolean>>;
  isClickForced?: boolean;
  isInUserMenu?: boolean;
}

const SubMenu: React.FC<Props> = props => {
  const { onItemClick } = props;

  const isMobileMenu = useBreakpointAvailable(mobileBreakpoints);

  const items = useMemo(
    () =>
      props.items.map(item => ({
        ...item,
        onClick: () => {
          item?.onClick?.();
          onItemClick?.();
        },
      })),
    [props.items, onItemClick],
  );

  return (
    <div className="SubMenu">
      {isMobileMenu ? (
        <SubMenuAccordion title={props.title} items={items} className={props.className} />
      ) : (
        <SubMenuTooltip
          title={props.title}
          items={items}
          show={props.show}
          setShow={props.setShow}
          isClickForced={props.isClickForced}
          className={props.className}
          onToggle={props.onToggle}
          isInUserMenu={props.isInUserMenu}
        />
      )}
    </div>
  );
};

export default SubMenu;
