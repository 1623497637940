import React from 'react';
import { HtmlString } from '../../shared/types/HtmlString';
import { ComponentMessage } from '../../shared/enums/ComponentMessage';
import { Link } from 'react-router-dom';

interface Props {
  value: HtmlString;
}

const FileNameExcelUrlCell: React.FC<Props> = props => {
  return (
    <div>
      <Link to={`/google-analytics/fileActivity/fileName=${props.value}`}>
        {props.value ? props.value : ComponentMessage.NoDataShort}
      </Link>
    </div>
  );
};

export default FileNameExcelUrlCell;
