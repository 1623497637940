import React from 'react';
import Tooltip from '../../shared/components/tooltip/Tooltip';
import { ControlSize } from '../../shared/enums/ControlSize';

interface Props {}

const PcwCriteriaSubheader: React.FC<Props> = () => {
  const ttContent =
    'We consider a number of factors when adding to our PCW. Filing confidentially or hiring banks typically warrants an addition, but we also consider funding rounds, valuation, investors, key hires, news reports, comments from executives, and macroeconomic, geopolitical, sector, and company tailwinds/headwinds.';
  const ttTitle = 'expect to go public';
  return (
    <>
      The PCW is a curated list of companies we &nbsp;{' '}
      <Tooltip title={ttTitle} content={ttContent} isTitleHighlighted={true} size={ControlSize.Medium} /> &nbsp; within
      the next 24 months
    </>
  );
};

export default PcwCriteriaSubheader;
