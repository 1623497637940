export enum ProfileDataElements {
  Stages = 'stages',
  BalanceSheet = 'balanceSheet',
  Capitalization = 'capitalization',
  DilutedShares = 'dilutedShares',
  CompanyData = 'companyData',
  FinancialData = 'financialData',
  Insights = 'insights',
  KeyShareholders = 'keyShareholders',
  Management = 'management',
  Milestones = 'milestones',
  OfferingData = 'offeringData',
  Overview = 'overview',
  Research = 'research',
  Underwriters = 'underwriters',
  LockUpPeriod = 'lockUpPeriod',
  PrivateCompanyData = 'privateCompanyData',
  FundamentalComparison = 'fundamentalComparison',
  ValuationComparison = 'valuationComparison',
  TechnicalComparison = 'technicalComparison',
  AnalystNotesAndNewsData = 'analystNotesAndNewsData',
  Description = 'description',
  Trading = 'trading',
  Secondary = 'secondary',
  Investors = 'investors',
}
