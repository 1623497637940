import React from 'react';
import './CompanyInsights.scss';
import classNames from 'classnames';
import DescriptionList from '../../../marketing/shared/DescriptionList';
import { InsightsVariant } from './enums/InsightsVariant';

interface Props {
  title: string | React.ReactNode;
  variant?: InsightsVariant;
  items: string[];
}

const CompanyInsights: React.FC<Props> = props => {
  return (
    <div className="CompanyInsights">
      <div
        className={classNames('CompanyInsights__title', {
          [`CompanyInsights__title--${props.variant}`]: props.variant,
        })}
      >
        {props.title}
      </div>

      <DescriptionList items={props.items} />
    </div>
  );
};

export default CompanyInsights;
