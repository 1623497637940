import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import ValueRange, { ValueRangeProps } from './ValueRange';

export type FormValueRangeProps = ValueRangeProps;

const FormValueRange: React.FC<FormValueRangeProps> = props => {
  const { errors } = useFormContext();

  const minError = errors[props.minName] as FieldError;
  const maxError = errors[props.maxName] as FieldError;

  return <ValueRange {...props} minError={minError} maxError={maxError} />;
};

export default FormValueRange;
