import React from 'react';
import './RatingsSystemParagraph.scss';
import classNames from 'classnames';
import TextContainer from '../marketing/shared/TextContainer';

export interface RaitingsSystemParagraphProps {
  title?: string | React.ReactNode;
  className?: string;
}

const RaitingsSystemParagraph: React.FC<RaitingsSystemParagraphProps> = props => {
  return (
    <div className={classNames('RaitingsSystemParagraph', props.className)}>
      <div>{props.title}</div>

      <div className="RaitingsSystemParagraph__content">
        <TextContainer>{props.children}</TextContainer>
      </div>
    </div>
  );
};

export default RaitingsSystemParagraph;
