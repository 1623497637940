import { viewGridColumnGroupsNames } from './viewGridColumnsGroupsNames';
import { ViewGridColumnGroupSettings } from '../interfaces/ViewGridColumnGroupSettings';
import { ViewColumnSystemName } from '../enums/ViewColumnSystemName';

const dealSizeMarketCupSettings = {
  sortField: ViewColumnSystemName.DealSize,
};
const exchangeheadquartersSettings = {
  sortField: ViewColumnSystemName.Exchange,
};
export const viewGridColumnsGroupsSettings: { [key: string]: ViewGridColumnGroupSettings } = {
  [viewGridColumnGroupsNames.dealSizeMarketCup]: dealSizeMarketCupSettings,
  [viewGridColumnGroupsNames.dealSizeMktCap]: dealSizeMarketCupSettings,
  [viewGridColumnGroupsNames.exchangeheadquarters]: exchangeheadquartersSettings,
};
