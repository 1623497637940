export enum StatsElementType {
  RecentIPOPerformance = 'recentIPOPerformance',
  IPOMarket = 'IpoMarket',
  Pricing = 'Pricing',
  Proceeds = 'Proceeds',
  Filings = 'Filings',
  AverageAge = 'AverageAge',
  MedianAge = 'MedianAge',
  Withdrawals = 'Withdrawals',
  PricingVsRange = 'PricingVsRange',
  UsListedAdr = 'UsListedAdr',
  Profitability = 'Profitability',
  HistoricalIpoReturns = 'HistoricalIpoReturns',
  IndustryBreakdown = 'IndustryBreakdown',
  VentureCapital = 'VentureCapital',
  GlobalBreakdown = 'GlobalBreakdown',
  MarketByMonth = 'MarketByMonth',
  RecentIpoPerformance = 'RecentIpoPerformance',
  DashboardRecentIPOPerformance = 'DashboardRecentIpoPerformance',
  DashboardReturnsBySector = 'DashboardIndustryBreakdown',
  DashboardIpoReturns = 'DashboardHistoricalIpoReturns',
  DashboardVentureCapital = 'DashboardVentureCapital',
  DashboardIposByRegion = 'DashboardGlobalBreakdown',
  DashboardHistoricalIpoReturns = 'DashboardHistoricalIpoReturns',
  DashboardIpoMarketByMonth = 'DashboardIpoMarketByMonth',
  LargestIPOsUs = 'LargestIPOsUs',
  LargestIPOsGlobal = 'LargestIPOsGlobal',
}
