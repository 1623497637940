import React, { useMemo } from 'react';
import './SecondarySection.scss';
import { useClassName } from '../../hooks/useClassName';
import { SecondarySectionBlock, SecondarySectionElement, SecondarySectionModifier } from './SecondarySectionBem';

export interface SecondarySectionProps {
  title?: React.ReactNode;
  actions?: React.ReactNode;
  notes?: string;
  className?: string;
  isUnderlinedTitle?: boolean;
  isFullWidthTitle?: boolean;
  hasShiftHorizontal?: boolean;
}

const SecondarySection: React.FC<SecondarySectionProps> = props => {
  const { title, actions, notes, className, isUnderlinedTitle = true, isFullWidthTitle = true } = props;

  const cn = useClassName(SecondarySectionBlock.Root, className);

  const hasTitle = useMemo(() => title || actions, [title, actions]);

  return (
    <div
      className={cn({
        [SecondarySectionModifier.ShiftHorizontal]: props.hasShiftHorizontal,
      })}
    >
      {hasTitle && (
        <>
          <div
            className={cn(SecondarySectionElement.Title, {
              [SecondarySectionModifier.Underlined]: isUnderlinedTitle,
              [SecondarySectionModifier.FullWidth]: isFullWidthTitle,
            })}
          >
            {title}
            {actions}
          </div>
          {notes && <div className={cn(SecondarySectionModifier.NoteSpan)}>{notes}</div>}
        </>
      )}

      <div className={cn(SecondarySectionElement.Content)}>{props.children}</div>
    </div>
  );
};

export default SecondarySection;
