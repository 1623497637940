import React from 'react';
import { useParams } from 'react-router-dom';
import { VideosApiService } from '../../shared/api/VideosApiService';
import { useMemoizedCallback } from '../../shared/hooks/useMemoizedCallback';
import { VideoIdParams } from '../../shared/interfaces/VideoIdParams';
import VideoTutorialsContent from './VideoTutorialsContent';

const VideoTutorials: React.FC = () => {
  const getMemoizedVideoTutorials = useMemoizedCallback(() => VideosApiService.getVideoTutorials());
  const { videoId } = useParams<VideoIdParams>();

  return <VideoTutorialsContent videoId={videoId} getMemoizedVideoTutorials={getMemoizedVideoTutorials} />;
};

export default VideoTutorials;
