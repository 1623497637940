import React from 'react';
import './CompanySectionRow.scss';
import classNames from 'classnames';

interface Props {
  title?: React.ReactNode;
  className?: string;
}

const CompanySectionRow: React.FC<Props> = props => {
  return (
    <div className={classNames('CompanySectionRow', props.className)}>
      {props.title && <div className="CompanySectionRow__title">{props.title}</div>}

      {props.children}
    </div>
  );
};

export default CompanySectionRow;
