import React, { useContext, useMemo } from 'react';
import './CompanySectionTitle.scss';
import { FileActionProp } from '../../../shared/components/file-actions/interfaces/FileActionProp';
import FileActions from '../../../shared/components/file-actions/FileActions';
import { FileActionType } from '../../../shared/components/file-actions/enums/FileActionType';
import { CompanyTab } from '../../enums/CompanyTab';
import { companyTabsTitles } from '../../constants/companyTabsTitles';
import { CompanyContext } from '../context/CompanyContext';
import { Nullable } from '../../../shared/types/generics';
import { ProfileData } from '../../interfaces/ProfileData';
import { useClassName } from '../../../shared/hooks/useClassName';
import CompanyRating from '../company-rating/CompanyRating';
import { CompanyRatingValueDto } from '../company-rating/types/CompanyRatingValueDto';

export interface CompanySectionTitleProps {
  tab: CompanyTab;
  defaultTitle?: React.ReactNode;
  ratingTitle?: React.ReactNode;
  className?: string;
  ratingValue?: CompanyRatingValueDto;
  ratingVisibleValue?: string;
  additionalInfo?: React.ReactNode;
  hasExcel?: boolean;
  pdfLink?: Nullable<string>;
  lastUpdated?: string;
}

const defaultFileActions: FileActionProp[] = [
  {
    type: FileActionType.Print,
    hasWrapper: true,
  },
];

const CompanySectionTitle: React.FC<CompanySectionTitleProps> = props => {
  const cn = useClassName('CompanySectionTitle', props.className);

  const { getTabData } = useContext(CompanyContext);
  const profileData: Nullable<ProfileData> = getTabData(CompanyTab.Profile);

  const excelAction = useMemo(
    () =>
      profileData?.description?.dcfLink && {
        type: FileActionType.Excel,
        hasWrapper: true,
        externalDownloadOptions: {
          url: profileData?.description?.dcfLink,
        },
      },
    [profileData],
  );

  const fileActions = useMemo(() => {
    const actions = [];

    if (excelAction && props.hasExcel) {
      actions.push(excelAction as FileActionProp);
    }

    if (props.pdfLink) {
      actions.push({
        type: FileActionType.Pdf,
        externalDownloadOptions: {
          url: props.pdfLink,
        },
      });
    }

    return actions.concat(defaultFileActions);
  }, [props.hasExcel, excelAction, props.pdfLink]);

  const defaultTitle = useMemo(() => props.defaultTitle || companyTabsTitles[props.tab], [
    props.defaultTitle,
    props.tab,
  ]);

  const ratingTitle = useMemo(() => props.ratingTitle || defaultTitle, [props.ratingTitle, defaultTitle]);

  const title = useMemo(() => (props.ratingValue ? ratingTitle : defaultTitle), [
    ratingTitle,
    props.ratingValue,
    defaultTitle,
  ]);

  return (
    <div className={cn()}>
      <div className={cn('asOf')}>
        {props.lastUpdated && (
          <>
            <strong>As of: {props.lastUpdated}</strong>
          </>
        )}
      </div>
      <div className={cn('title')}>
        {props.ratingValue && (
          <>
            {title}
            &nbsp;-&nbsp;
            <CompanyRating ratingValue={props.ratingValue} visibleValue={props.ratingVisibleValue} />
          </>
        )}
      </div>

      {fileActions && (
        <div className={cn('actions')}>
          <FileActions fileActions={fileActions} />
        </div>
      )}
    </div>
  );
};

export default CompanySectionTitle;
