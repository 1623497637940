import PrimarySectionRow from '../../../../../shared/components/primary-section/PrimarySectionRow';
import React from 'react';
import StatsElement from '../../../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../../../shared/entities/stats-element/enums/StatsElementType';
import { returnsBySectorStatsElementOptions } from './constants/returnsBySectorStatsElementOptions';
import { useStatsTitle } from '../../../hooks/useStatsTitle';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../../../../shared/enums/RoutePath';

const StatsReturnsBySector: React.FC = () => {
  return (
    <PrimarySectionRow header={<Link to={RoutePath.StatsIndustry}>{useStatsTitle('IPO Returns by Sector')}</Link>}>
      <StatsElement type={StatsElementType.DashboardReturnsBySector} options={returnsBySectorStatsElementOptions} />
    </PrimarySectionRow>
  );
};

export default StatsReturnsBySector;
