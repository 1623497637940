import React, { useContext, useMemo } from 'react';
import ViewDashboardSecondarySection from '../../../../shared/entities/view/components/view-dashboard/components/ViewDashboardSecondarySection';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { ViewDataTable } from '../../../../shared/entities/view/components/view/interfaces/api/ViewDataTable';
import { ViewLocationService } from '../../../../shared/entities/view/components/view/services/ViewLocationService';
import ViewDashboardGrid from '../../../../shared/entities/view/components/view-dashboard/components/ViewDashboardGrid';
import { ViewDashboardContext } from '../../../../shared/entities/view/components/view-dashboard/ViewDashboardContext';
import { favouritesIposCustomColumnsSettings } from '../constants/favouritesIposCustomColumnsSettings';

interface Props {
  viewType?: ViewType;
  text: string;
  dataTable: ViewDataTable;
}

const FavouriteIposSectionGrid: React.FC<Props> = props => {
  const { isInnerLoading } = useContext(ViewDashboardContext);

  const path = useMemo(() => props.viewType && ViewLocationService.getRoutePath(props.viewType), [props.viewType]);

  return (
    <ViewDashboardSecondarySection title={props.text} routePath={path}>
      <ViewDashboardGrid
        dataTable={props.dataTable}
        hasPagination
        isColumnsFitGrid={false}
        isLoading={isInnerLoading}
        customColumnsSettings={favouritesIposCustomColumnsSettings}
      />
    </ViewDashboardSecondarySection>
  );
};

export default FavouriteIposSectionGrid;
