import React, { useMemo } from 'react';
import DashboardChartWidget from './shared/DashboardChartWidget';
import { DashboardWidgetComponent } from '../../interfaces/DashboardWidgetComponent';
import Highcharts from 'highcharts/highstock';
import {
  getIpoMarketChartOptions,
  US_IPO_MARKET_CHART_CREDITS_TEXT,
} from '../../../stats/stats/main-dashboard/components/recent-ipo-performance-chart/constants/recentIpoPerfomanceStatsElementOptions';
import { ChartType } from '../../../shared/components/chart/enums/ChartType';
import { OptionsListProp } from '../../../shared/components/chart/types/OptionsListProp';

const DashboardUSIPOMarketWidget: DashboardWidgetComponent<Highcharts.Options> = props => {
  const { data } = props;

  const ipoMarketChartOptions = useMemo(() => getIpoMarketChartOptions(US_IPO_MARKET_CHART_CREDITS_TEXT), []);
  const optionsList = useMemo(() => [...(ipoMarketChartOptions.optionsList as OptionsListProp), data], [
    data,
    ipoMarketChartOptions,
  ]);

  return (
    <DashboardChartWidget
      type={ipoMarketChartOptions.type as ChartType}
      optionsList={optionsList}
      dimensions={props.dimensions}
    />
  );
};

export default DashboardUSIPOMarketWidget;
