import { CalendarEvent } from '../interfaces/CalendarEvent';
import { CalendarEventDto } from '../interfaces/CalendarEventDto';
import { CalendarEventType } from '../enums/CalendarEventType';
import { DateHelper } from '../../../helpers/date/DateHelper';
import { DateRange } from '../../../types';
import { SortService } from '../../../services/SortService';

export class CalendarDataService {
  static getEventFromDto(calendarEventDto: CalendarEventDto, type: CalendarEventType): CalendarEvent {
    return {
      ...calendarEventDto,
      type,
      date: DateHelper.parseISODate(calendarEventDto.start) as Date,
    };
  }

  static getEventsDateRange(events: CalendarEvent[]): DateRange {
    if (events.length === 0) {
      return [DateHelper.getToday(), DateHelper.getToday()];
    }

    const sorted = events
      .sort((eventA, eventB) => SortService.sortDate(eventA.date, eventB.date))
      .filter(event => event.date);

    return [sorted[0].date, sorted[sorted.length - 1].date];
  }
}
