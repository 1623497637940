import * as yup from 'yup';
import { INPUT_MAX_LENGTH } from '../../../../../../../constants/validation';
import { ViewShareLinkFormFields } from './ViewShareLinkFormFields';

export const validationSchema = yup.object().shape({
  [ViewShareLinkFormFields.LinkName]: yup
    .string()
    .required()
    .max(INPUT_MAX_LENGTH)
    .label('Name'),
});
