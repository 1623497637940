import React from 'react';
import { isNil } from 'lodash';
import { CompanyPriceChangeDto } from './interfaces/CompanyPriceChangeDto';
import { ViewGridCellValueService } from '../../../shared/entities/view/components/view-grid/services/ViewGridCellValueService';
import { CompanyPriceChangeDtoProp } from './enums/CompanyPriceChangeDtoProp';
import ColorNumberFormatter from '../../../shared/components/formatters/ColorNumberFormatter';

interface Props {
  data: CompanyPriceChangeDto;
  hideGrowthValue?: boolean;
}

const CompanyPriceChange: React.FC<Props> = props => {
  const { data, hideGrowthValue } = props;
  const growthValue = data[CompanyPriceChangeDtoProp.Growth];
  const growthPercent = data[CompanyPriceChangeDtoProp.GrowthPercent];

  return (
    <div>
      {ViewGridCellValueService.getVisible(data, CompanyPriceChangeDtoProp.Value)}{' '}
      {!isNil(growthValue) && !hideGrowthValue && (
        <ColorNumberFormatter
          value={growthValue}
          displayValue={ViewGridCellValueService.getVisible(data, CompanyPriceChangeDtoProp.Growth)}
        />
      )}{' '}
      {!isNil(growthPercent) && (
        <ColorNumberFormatter
          value={growthPercent}
          displayValue={`(${ViewGridCellValueService.getVisible(data, CompanyPriceChangeDtoProp.GrowthPercent)})`}
        />
      )}
    </div>
  );
};

export default CompanyPriceChange;
