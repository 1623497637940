export enum AutocompleteBlock {
  Root = 'Autocomplete',
}

export enum AutocompleteElement {
  Menu = 'menu',
}

export enum AutocompleteModifier {
  NoMenu = 'noMenu',
}
