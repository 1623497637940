import { ViewFilterName } from '../../../../../shared/entities/filters/enum/types/ViewFilterName';
import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { ViewSummaryAlignType } from '../../../../../shared/entities/view/components/view/enums/ViewSummaryAlignType';
import { TimeFrame } from '../../../../../shared/entities/filters/enum/TimeFrame';
import { FilterSectionType } from '../../../../../shared/entities/filters/enum/FilterSectionType';
import { ViewColumnSystemName } from '../../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { ViewGridColumnWidth } from '../../../../../shared/entities/view/components/view-grid/enums/ViewGridColumnWidth';

export const withdrawnPostponedInUseOptions: Partial<ScreenOptions> = {
  header: 'Withdrawn and Postponed',

  summary: {
    align: ViewSummaryAlignType.Grid,
  },

  toolbar: {
    modalFilters: {
      hideSavedViews: true, // hides "Save and Run" from filters along with hiding Saved Views
      sections: [
        {
          type: FilterSectionType.ActionDate,
          controls: [
            {
              name: ViewFilterName._OfferDate,
              props: {
                timeFrames: [TimeFrame.Quarter, TimeFrame.YTD, TimeFrame.Year, TimeFrame.All],
                hasDateRange: false,
              },
            },
          ],
        },
        {
          type: FilterSectionType.Location,
          controls: [{ name: ViewFilterName.LocationListingLocation }],
        },
        {
          type: FilterSectionType.CompanyInfo,
          controls: [{ name: ViewFilterName.SectorIndustry }],
        },
        {
          type: FilterSectionType.DealInfo,
          controls: [
            { name: ViewFilterName._DealSize },
            { name: ViewFilterName.UnderwriterKeyList },
            { name: ViewFilterName.Postponed },
            { name: ViewFilterName.Withdrawn },
            { name: ViewFilterName.Acquired },
          ],
        },
        {
          type: FilterSectionType.SecurityType,
          controls: [{ name: ViewFilterName.IncludeSpac }],
        },
      ],
    },

    quickFilter: {
      hasIncludeSPAC: true,
      hasLocation: true,
      hasTimeFrameRange: true,
      timeFrameOptions: [TimeFrame.Quarter, TimeFrame.YTD, TimeFrame.Year, TimeFrame.All],
      hasDateRange: true,
      hideDateRange: false,
      hasIncludeAShares: false,
      hasIncludeSPACtextFilter: false,
      hasExpiredLockUp: false,
      hasPostponed: true,
      hasWithdrawn: true,
      hasAcquired: true,
    },
  },

  grid: {
    isFixedHeight: false,
    customColumnsSettings: [
      {
        field: ViewColumnSystemName.Sector,
        width: ViewGridColumnWidth.Lg,
        minWidth: ViewGridColumnWidth.Lg,
      },
      {
        field: ViewColumnSystemName.ActionName,
        width: 140,
        minWidth: 140,
      },
    ],
  },
};
