import React, { useCallback, useContext, useMemo } from 'react';
import { DashboardWidgetType } from '../../enums/DashboardWidgetType';
import './DashboardEditModal.scss';
import { DashboardContext } from '../../DashboardContext';
import { DashboardEditFormData } from '../../interfaces/DashboardEditFormData';
import FormEntityActionModal from '../../../shared/components/modal/FormEntityActionModal';
import { ModalEntityActionType } from '../../../shared/components/modal/enums/ModalEntityActionType';
import { dashboardAvailableWidgetTypes } from '../../constants/dashboardWidgetTypes';
import { dashboardWidgetsData } from '../../constants/dashboardWidgetsData';
import FormCheckbox from '../../../shared/components/forms/FormCheckbox';
import { DashboardEditFormField } from '../../enums/DashboardEditFormField';
import { ModalShowState } from '../../../shared/components/modal/types/ModalShowState';
import FormGroup from '../../../shared/components/forms/FormGroup';

interface Props {
  showState: ModalShowState;
}

const DashboardEditModal: React.FC<Props> = props => {
  const { widgets, setWidgetTypes } = useContext(DashboardContext);
  const types: DashboardWidgetType[] = useMemo(() => widgets.map(widget => widget.type), [widgets]);

  const defaultValues = useMemo(() => ({ types }), [types]);

  const onSubmit = useCallback(
    ({ types }: DashboardEditFormData) => {
      setWidgetTypes(types);
    },
    [setWidgetTypes],
  );

  return (
    <FormEntityActionModal
      showState={props.showState}
      entityName="Widgets"
      actionType={ModalEntityActionType.Save}
      defaultValues={defaultValues}
      body={
        <>
          {dashboardAvailableWidgetTypes.map(type => {
            const widgetData = dashboardWidgetsData[type];

            return (
              <FormGroup key={type}>
                <FormCheckbox name={DashboardEditFormField.Types} value={type} label={widgetData.title} />
              </FormGroup>
            );
          })}
        </>
      }
      onSubmit={onSubmit}
    />
  );
};

export default DashboardEditModal;
