import PrimarySectionRow from '../../../../../shared/components/primary-section/PrimarySectionRow';
import React from 'react';
import StatsElement from '../../../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../../../shared/entities/stats-element/enums/StatsElementType';
import { iposByRegionStatsElementOptions } from './constants/iposByRegionStatsElementOptions';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../../../../shared/enums/RoutePath';

const StatsIposByRegion: React.FC = () => {
  return (
    <PrimarySectionRow header={<Link to={RoutePath.StatsGlobalBreakdown}>Global IPOs by Region</Link>}>
      <StatsElement type={StatsElementType.DashboardIposByRegion} options={iposByRegionStatsElementOptions} />
    </PrimarySectionRow>
  );
};

export default StatsIposByRegion;
