import { isEqual } from 'lodash';
import { ViewAppliedFilters } from '../interfaces/ViewAppliedFilters';
import { isNil } from 'lodash';
import { ObjectHelper } from '../../../../../helpers/ObjectHelper';
import { ViewOptions } from '../interfaces/options/ViewOptions';

export class ViewFilterService {
  static getNewFilters(formFilters: ViewAppliedFilters, currentFilters: ViewAppliedFilters): ViewAppliedFilters {
    return this.deleteEmptyFilters({
      ...currentFilters,
      ...formFilters,
    });
  }

  static shouldUpdateQuickFilters(
    subFilters: ViewAppliedFilters = {},
    allFilters: ViewAppliedFilters = {},
    options: ViewOptions,
  ): boolean {
    // Commenting the following block of code as we want a location filter update to cause changes even  when start and end date is not provided
    /*
    const hasQuickDateRangeFilter = ViewOptionsService.hasQuickDateRangeFilter(options);
    const offerStartDate = subFilters[ViewFilterName.OfferStartDate];
    const offerEndDate = subFilters[ViewFilterName.OfferEndDate];
    const offerTimeFrame = subFilters[ViewFilterName.OfferTimeFrame];
    if (hasQuickDateRangeFilter && !offerTimeFrame) {
      if (!(offerStartDate && offerEndDate)) {
        return false;
      }
    }
    */

    return this.isSubFiltersChanged(subFilters, allFilters);
  }

  static isSubFiltersChanged(subFilters: ViewAppliedFilters = {}, allFilters: ViewAppliedFilters = {}): boolean {
    const subFiltersKeys = Object.keys(subFilters);

    return !subFiltersKeys.every(subFilterKey => {
      const subFilterValue = subFilters[subFilterKey];
      const allFilterValue = allFilters[subFilterKey];

      return subFilterValue === allFilterValue || (isNil(subFilterValue) && isNil(allFilterValue));
    });
  }

  static isAllFiltersChanged(newFilters: ViewAppliedFilters, currentFilters: ViewAppliedFilters): boolean {
    return !isEqual(newFilters, currentFilters);
  }

  static deleteEmptyFilters(formFilters: ViewAppliedFilters): ViewAppliedFilters {
    return ObjectHelper.deleteEmptyProps(formFilters);
  }

  static isResetDisabled(currentFilters: ViewAppliedFilters, defaultFilters: ViewAppliedFilters): boolean {
    return isEqual(currentFilters, defaultFilters);
  }
}
