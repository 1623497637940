import { Nullable } from '../../../../../../types/generics';
import { GridSort } from '../../../../../../components/grid/grid/interfaces/GridSort';
import { ActionTypes, SET_VIEW_SORT, SetViewSort, ViewState } from '../types';
import { Thunk } from 'react-hook-thunk-reducer';
import { ViewApiService } from '../../../../../../api/ViewApiService';
import { setViewError } from './notification';
import { ViewLocationService } from '../../services/ViewLocationService';
import { isDataInitSelector } from '../selectors';
import { ViewId } from '../../types/ViewId';

export const setViewSort = (sort: Nullable<GridSort>): SetViewSort => {
  return {
    type: SET_VIEW_SORT,
    payload: {
      sort,
    },
  };
};

export const updateViewSort = (sort: Nullable<GridSort>, newViewId: ViewId): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state: ViewState = getState();

    dispatch(setViewSort(sort));

    try {
      await ViewApiService.saveSorting(state.viewId as number, sort);

      if (ViewLocationService.shouldUpdateByLocationChange(newViewId, state.viewId, isDataInitSelector(state))) {
        ViewLocationService.updateViewUrl(state.viewId);
      }
    } catch (e) {
      dispatch(setViewError(e));
    }
  };
};
