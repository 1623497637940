import React, { useMemo } from 'react';
import ViewTriggerCell from '../../../../../view/components/view-grid/components/cells/view-trigger-cell/ViewTriggerCell';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import { StatsElementSimpleGridViewTriggerService } from '../../services/StatsElementSimpleGridViewTriggerService';
import { StatsElementSimpleGridViewTriggerCellViewLinkProps } from './interfaces/StatsElementSimpleGridViewTriggerCellViewLinkProps';
import { ViewGridCellValueService } from '../../../../../view/components/view-grid/services/ViewGridCellValueService';

export interface StatsElementSimpleGridViewTriggerCellProps
  extends GridCellFormatterCommonProps,
    StatsElementSimpleGridViewTriggerCellViewLinkProps {}

const StatsElementSimpleGridViewTriggerCell: React.FC<StatsElementSimpleGridViewTriggerCellProps> = props => {
  const { data, field } = props;

  const optionsList = useMemo(() => StatsElementSimpleGridViewTriggerService.getFilterOptionsList(props), [props]);

  const displayValue = ViewGridCellValueService.getVisibleIfExists(data, field);

  return (
    <ViewTriggerCell
      data={data}
      value={displayValue}
      field={field}
      columns={props.columns}
      viewType={props.viewType}
      optionsList={optionsList}
      getIsLinkAvailable={props.getIsLinkAvailable}
      hasLinkStyle
    />
  );
};

export default StatsElementSimpleGridViewTriggerCell;
