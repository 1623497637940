import React, { ReactNode } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import './Modal.scss';

export enum ModalSize {
  Small = 'sm',
  Large = 'lg',
  ExtraLarge = 'xl',
}

export interface ModalProps {
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  show?: boolean;
  size?: ModalSize;
  isCenter?: boolean;
  onHide?: () => void;
  className?: string;
}

const Modal: React.FC<ModalProps> = props => {
  const { size = ModalSize.ExtraLarge, show = true } = props;

  return (
    <BootstrapModal
      show={show}
      size={size}
      onHide={props.onHide}
      dialogClassName={props.className}
      centered={props.isCenter}
    >
      {props.header && (
        <BootstrapModal.Header closeButton>
          <header className="Modal__header">{props.header}</header>
        </BootstrapModal.Header>
      )}

      {props.body && <BootstrapModal.Body>{props.body}</BootstrapModal.Body>}

      {props.footer && <BootstrapModal.Footer>{props.footer}</BootstrapModal.Footer>}
    </BootstrapModal>
  );
};

export default Modal;
