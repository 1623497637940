import React from 'react';
import './CompanyTabsPrint.scss';
import CompanyInvestmentRationale from './investment-rationale/CompanyInvestmentRationale';
import CompanyFundamentalInsights from './fundamental-insights/CompanyFundamentalInsights';
import CompanyFinancials from './financials/CompanyFinancials';
import CompanyMgmtQs from './mgmt-qs/CompanyMgmtQs';
import CompanyMgmtOwners from './mgmt-owners/CompanyMgmtOwners';
import CompanyRelativeValuation from './relative-valuation/CompanyRelativeValuation';
import CompanyPeerTrading from './peer-trading/CompanyPeerTrading';
import CompanyMilestones from './milestones/CompanyMilestones';
import { useClassName } from '../../shared/hooks/useClassName';
import { CompanyTabsPrintBlock } from './companyTabsPrintBem';

const CompanyTabsPrint: React.FC = () => {
  const cn = useClassName(CompanyTabsPrintBlock.Root);

  return (
    <div className={cn()}>
      {/* <CompanyProfile /> */}

      <CompanyInvestmentRationale />

      <CompanyFundamentalInsights />

      <CompanyFinancials />

      <CompanyMgmtQs />

      <CompanyMgmtOwners />

      <CompanyRelativeValuation />

      <CompanyPeerTrading />

      <CompanyMilestones />
    </div>
  );
};

export default CompanyTabsPrint;
