import { cloneDeep } from 'lodash';
import { SelectOption } from './interfaces/SelectOption';
import { SelectOptionValue } from './types/SelectOptionValue';
import { noSelectOptionParentValue } from './constants/noSelectOptionParentValue';
import { MapTreeOptionFn } from './types/MapTreeOptionFn';

export class SelectTreeService {
  static getTree<T>(options: SelectOption[], mapFn: MapTreeOptionFn<T>): T[] {
    const rootOptions = this.getRootFlatTreeOptions(cloneDeep(options));

    return this.mapToTree(rootOptions, options, mapFn);
  }

  private static getRootFlatTreeOptions(options: SelectOption[]): SelectOption[] {
    return options.filter(option => option.parentValue === noSelectOptionParentValue || !option.parentValue);
  }

  private static mapToTree<T>(
    rootFlatTreeOptions: SelectOption[],
    allFlatTreeOptions: SelectOption[],
    mapFn: MapTreeOptionFn<T>,
  ): T[] {
    return rootFlatTreeOptions.map((flatTreeOption: SelectOption) => {
      let children;

      if (this.hasFlatTreeChildren(allFlatTreeOptions, flatTreeOption.value)) {
        const flatChildren = this.getFlatTreeChildren(allFlatTreeOptions, flatTreeOption.value);
        children = this.mapToTree(flatChildren, allFlatTreeOptions, mapFn);
      }

      return mapFn(flatTreeOption, children);
    });
  }

  private static hasFlatTreeChildren(options: SelectOption[], parentValue: SelectOptionValue): boolean {
    return options.some(option => option.parentValue === parentValue);
  }

  private static getFlatTreeChildren(options: SelectOption[], parentValue: SelectOptionValue): SelectOption[] {
    return options.filter(option => option.parentValue === parentValue);
  }
}
