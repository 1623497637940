import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { AxiosInstance } from 'axios';
import { AuthService } from '../services/AuthService';
import { TokenHeaderService } from '../services/token/TokenHeaderService';
import { HttpCode } from '../enums/HttpCode';

export const setAuthRefreshInterceptor = (axiosInstance: AxiosInstance) => {
  createAuthRefreshInterceptor(
    axiosInstance,
    async failedRequest => {
      await AuthService.refresh();
      TokenHeaderService.setConfigTokenHeader(failedRequest.response.config);
    },
    {
      statusCodes: [HttpCode.Forbidden, HttpCode.Unauthorized, HttpCode.InternalServerError],
    },
  );
};
