export enum DilutedSharesContentBlock {
  Root = 'DilutedSharesContent',
}

export enum DilutedSharesContentElement {
  Header = 'header',
  Title = 'title',
  Info = 'info',
  Description = 'description',
  Financial = 'financial',
  TableData = 'tableData',
  Footnote = 'footnote',
  TtData = 'ttData',
  TotalTtData = 'totalTableData',
  DilutiveSharesData = 'dilutiveSharesData',
}
