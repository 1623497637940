import React from 'react';
import './ResetPassword.scss';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import ResetPasswordForm from './form/ResetPasswordForm';
import PrimarySectionRow from '../../shared/components/primary-section/PrimarySectionRow';
import { RoutePath } from '../../shared/enums/RoutePath';
import DocumentHead from '../../shared/components/DocumentHead';

const ResetPassword: React.FC = () => {
  return (
    <section className="ResetPassword">
      <DocumentHead path={RoutePath.ResetPassword} />

      <PrimarySection header={'Reset password'}>
        <PrimarySectionRow>
          <ResetPasswordForm />
        </PrimarySectionRow>
      </PrimarySection>
    </section>
  );
};

export default ResetPassword;
