import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import Checkbox, { CheckboxProps } from './Checkbox';

export type FormCheckboxProps = CheckboxProps;

const FormCheckbox: React.FC<FormCheckboxProps> = props => {
  const { errors, register } = useFormContext();

  // TODO: Move logic to form or error component
  const error: FieldError | undefined = errors?.[props?.name] as FieldError;

  return <Checkbox {...props} register={register} error={error} />;
};

export default FormCheckbox;
