import React, { useCallback, useState, useEffect } from 'react';
import { CompanyFinancialsFilter } from './interfaces/CompanyFinancialsFilter';
import TextSelect from '../../../shared/components/forms/components/text-select/TextSelect';
import { ControlDivider } from '../../../shared/components/forms/services/control-divider/enums/ControlDivider';
import './CompanyFinancialsFilterControl.scss';
import CompanySectionRow from '../shared/CompanySectionRow';
import { CompanyFinancialsTermFilterName } from './enums/CompanyFinancialsTermFilterName';
import { CompanyFinancialsLocationFilterName } from './enums/CompanyFinancialsLocationFilterName';
import { SelectOption } from '../../../shared/services/select/interfaces/SelectOption';

interface Props {
  initialFilters: CompanyFinancialsFilter;
  termOptions: SelectOption[];
  locationOptions: SelectOption[];
  onFiltersChange: (filter: CompanyFinancialsFilter) => void;
}

const CompanyFinancialsFilterControl: React.FC<Props> = props => {
  const { termOptions, locationOptions } = props;

  const [filters, setFilters] = useState(props.initialFilters);

  const onTermFilterChange = useCallback(
    (value: CompanyFinancialsTermFilterName) => {
      setFilters({ ...filters, term: value });
    },
    [filters],
  );

  const onLocationFilterChange = useCallback(
    (value: CompanyFinancialsLocationFilterName) => {
      setFilters({ ...filters, location: value });
    },
    [filters],
  );

  useEffect(() => {
    props.onFiltersChange(filters);
  }, [filters, props]);

  return (
    <CompanySectionRow className="CompanyFinancialsFilterControl">
      <div className="CompanyFinancialsFilterControl__item">
        <b>Income Statement</b>
      </div>

      <div className="CompanyFinancialsFilterControl__controls">
        <div className="CompanyFinancialsFilterControl__item">
          <TextSelect
            divider={ControlDivider.Slash}
            value={filters.term}
            options={termOptions}
            onChangeValue={onTermFilterChange}
          />
        </div>

        <div className="CompanyFinancialsFilterControl__item">
          <TextSelect
            divider={ControlDivider.Slash}
            value={filters.location}
            options={locationOptions}
            onChangeValue={onLocationFilterChange}
          />
        </div>
      </div>
    </CompanySectionRow>
  );
};

export default CompanyFinancialsFilterControl;
