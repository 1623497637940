import { cloneDeep } from 'lodash';
import { ViewAppliedFilters } from '../../../../../shared/entities/view/components/view/interfaces/ViewAppliedFilters';
import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { ScreensLocationScreenByValue } from '../enums/ScreensLocationScreenByValue';
import { ScreensLocationScreenByTitle } from '../enums/ScreensLocationScreenByTitle';
import { ScreensLocationFilterName } from '../enums/ScreensLocationFilterName';
import { ViewQuickFilterOptions } from '../../../../../shared/entities/view/components/view/interfaces/options/ViewQuickFilterOptions';

export class ScreensLocationOptionsService {
  static getByFilters(prevOptions: Partial<ScreenOptions>, filters: ViewAppliedFilters): Partial<ScreenOptions> {
    const newOptions = cloneDeep(prevOptions);
    const screenBy = filters[ScreensLocationFilterName.ScreenBy];

    if (!newOptions.grid) {
      newOptions.grid = {};
    }

    if (newOptions.isLanding) {
      newOptions.grid.title = this.getGridTitleFilter(screenBy);
    }

    return newOptions;
  }

  static getOnFiltersChange(prevOptions: Partial<ScreenOptions>, filters: ViewAppliedFilters): Partial<ScreenOptions> {
    const newOptions = cloneDeep(prevOptions);
    const screenBy = filters[ScreensLocationFilterName.ScreenBy];

    this.updateQuickFilter(newOptions, { hasLocation: this.hasLocationFilter(screenBy) });

    return newOptions;
  }

  private static updateQuickFilter(options: Partial<ScreenOptions>, quickFilter: ViewQuickFilterOptions): void {
    if (!options.toolbar) {
      options.toolbar = {};
    }

    if (!options.toolbar.quickFilter) {
      options.toolbar.quickFilter = {};
    }

    options.toolbar.quickFilter = {
      ...options.toolbar.quickFilter,
      ...quickFilter,
    };
  }

  private static getGridTitleFilter(screenBy: ScreensLocationScreenByValue): string {
    const prefix = 'IPO ACTIVITY BY';
    let filter;

    if (screenBy === ScreensLocationScreenByValue.CompanyHQ) {
      filter = ScreensLocationScreenByTitle.CompanyHQ;
    } else {
      filter = ScreensLocationScreenByTitle.ListingLocation;
    }

    return `${prefix} ${filter}`.toUpperCase();
  }

  private static hasLocationFilter(screenBy: ScreensLocationScreenByValue): boolean {
    return screenBy !== ScreensLocationScreenByValue.ListingLocation;
  }
}
