import { SelectOption } from '../../../../../../shared/services/select/interfaces/SelectOption';
import { ScreensLocationScreenByValue } from '../../enums/ScreensLocationScreenByValue';
import { ScreensLocationScreenByTitle } from '../../enums/ScreensLocationScreenByTitle';

export const screensLocationScreenByOptions: SelectOption[] = [
  {
    title: ScreensLocationScreenByTitle.ListingLocation,
    value: ScreensLocationScreenByValue.ListingLocation,
  },
  {
    title: ScreensLocationScreenByTitle.CompanyHQ,
    value: ScreensLocationScreenByValue.CompanyHQ,
  },
];
