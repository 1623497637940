import React, { ReactNode } from 'react';
import './GridOverlay.scss';
import OverlayContainer from '../../overlay-container/OverlayContainer';
import Loader from '../../loader/Loader';
import { useAbsoluteElementCenter } from '../../../hooks/useAbsoluteElementCenter';

interface Props {
  parentEl: HTMLElement;
  isLoading: boolean;
  error?: string;
}

const GridOverlay: React.FC<Props> = props => {
  const getContent = (): ReactNode => {
    if (props.isLoading) {
      return <Loader />;
    } else if (props.error) {
      return <span>{props.error}</span>;
    }

    return null;
  };

  const centerStyle = useAbsoluteElementCenter(props.parentEl, props.isLoading);

  return (
    <OverlayContainer className="GridOverlay" contentStyle={centerStyle}>
      {getContent()}
    </OverlayContainer>
  );
};

export default GridOverlay;
