import React, { useContext } from 'react';
import './ViewFooter.scss';
import { ViewContext } from '../../ViewContext';
import { hasFootnoteSelector, hasNotesSelector } from '../../store/selectors';
import ViewFootnote from './ViewFootnote';
import ViewNotes from './ViewNotes';
import ViewApiFootnote from './ViewApiFootnote';

const ViewFooter: React.FC = () => {
  const { state } = useContext(ViewContext);

  return (
    <>
      {hasFootnoteSelector(state) && <ViewFootnote />}

      {hasNotesSelector(state) && <ViewNotes />}
    </>
  );
};

export default ViewFooter;
