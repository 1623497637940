import React, { useCallback, useContext, useMemo, useState } from 'react';
import Wizard from '../../../../../../../shared/components/forms/components/wizard/Wizard';
import { WizardStep } from '../../../../../../../shared/components/forms/components/wizard/interfaces/WizardStep';
import ScreensAddFiltersForm from './ScreensAddFiltersForm';
import { useClassName } from '../../../../../../../shared/hooks/useClassName';
import { ScreensAddWizardBlock } from './screensAddWizardBem';
import ViewColumnsWeb from '../../../../../../../shared/entities/view/components/view/components/view-columns/ViewColumnsWeb';
import ViewColumnsExcel from '../../../../../../../shared/entities/view/components/view/components/view-columns/ViewColumnsExcel';
import { ViewApiService } from '../../../../../../../shared/api/ViewApiService';
import { ViewType } from '../../../../../../../shared/entities/view/components/view/enums/ViewType';
import { ViewLocationService } from '../../../../../../../shared/entities/view/components/view/services/ViewLocationService';
import ScreenAddModalForm from './form/ScreenAddModalForm';
import { ScreenAddFormData } from './form/ScreenAddFormData';
import { ScreenAddFormFields } from './form/ScreenAddFormFields';
import { ViewFiltersDictionaries } from '../../../../../../../shared/entities/filters/interfaces/ViewFiltersDictionaries';
import { ScreensContext } from '../../../../../../contexts/screensContext';
import { ViewColumnsOptionsDto } from '../../../../../../../shared/entities/view/components/view/components/view-columns/add/interfaces/dto/ViewColumnsOptionsDto';
import { useAsyncFn } from '../../../../../../../shared/hooks/async-fn/useAsyncFn';
import { ScreensDefaultFiltersService } from '../../../../../shared/pages/services/ScreensDefaultFiltersService';

interface Props {
  filtersDictionaries: ViewFiltersDictionaries;
  columnsOptions: ViewColumnsOptionsDto;
}
const ScreensAddWizard: React.FC<Props> = props => {
  const cn = useClassName(ScreensAddWizardBlock.Root);

  const { savedScreensData } = useContext(ScreensContext);
  const [, , , setSavedScreens] = savedScreensData;
  const [filters, setFilters] = useState({});
  const [columnsWeb, setColumnsWeb] = useState<string[]>([]);
  const [columnsExcel, setColumnsExcel] = useState<string[]>([]);
  const [columnsExcelChanged, setColumnsExcelChanged] = useState(false);
  const [isAddFormShow, setIsAddFormShow] = useState(false);
  const [isValidFiltersForm, setIsValidFiltersForm] = useState(false);

  const baseReqData = useMemo(
    () => ({
      viewType: ViewType.CustomInUse,
      sort: null,
      columns: {
        web: columnsWeb,
        excel: columnsExcel,
      },
      filters,
    }),
    [filters, columnsWeb, columnsExcel],
  );
  const isNextDisabled = useMemo(() => !isValidFiltersForm, [isValidFiltersForm]);

  const onFiltersChange = useCallback(
    currentFilters => {
      const currentDealStatuses: string[] = currentFilters?.DealStatus_In;
      ScreensDefaultFiltersService.useDealStatusDependecy(setColumnsWeb, setColumnsExcel, currentDealStatuses);
      setFilters(currentFilters);
    },
    [setFilters],
  );

  const onColumnsWebChange = useCallback(
    columnIds => {
      setColumnsWeb(columnIds);

      // If user has not changed excel columns yet, then replicate setColumnsWebChange onto columnsExcel
      if (!columnsExcelChanged) {
        setColumnsExcel(columnIds);
      }
    },
    [setColumnsWeb, columnsExcelChanged],
  );

  const onColumnsExcelChange = useCallback(
    columnIds => {
      setColumnsExcel(columnIds);
      setColumnsExcelChanged(true);
    },
    [setColumnsExcel, setColumnsExcelChanged],
  );

  const afterCreateView = useCallback(viewId => ViewLocationService.navigateToView(ViewType.CustomInUse, viewId), []);

  const createScreen = useCallback(
    async (formData: ScreenAddFormData) => {
      const res = await ViewApiService.createCustomScreenView({
        name: formData[ScreenAddFormFields.Name],
        ...baseReqData,
      });

      setSavedScreens(res.savedScreens);

      afterCreateView(res.viewId);
    },
    [baseReqData, setSavedScreens, afterCreateView],
  );

  const [onRun, isLoading] = useAsyncFn(async () => {
    const viewId = await ViewApiService.createView(baseReqData);
    afterCreateView(viewId);
  });

  const onSaveAndRun = useCallback(() => setIsAddFormShow(true), [setIsAddFormShow]);
  const onSubmit = useCallback(createScreen, [createScreen]);

  const onFiltersFormValidationStateChange = useCallback(isValid => {
    setIsValidFiltersForm(isValid);
  }, []);

  const steps: WizardStep[] = useMemo(
    () => [
      {
        title: 'Filters',
        component: (
          <ScreensAddFiltersForm
            dictionaries={props.filtersDictionaries}
            onChange={onFiltersChange}
            onValidationStateChange={onFiltersFormValidationStateChange}
            selectedFilters={filters}
          />
        ),
      },
      {
        title: 'Web Columns',
        component: (
          <ViewColumnsWeb
            isInModal={false}
            columnsOptions={props.columnsOptions}
            selectedColumnsIds={columnsWeb}
            onChange={onColumnsWebChange}
          />
        ),
      },
      {
        title: 'Excel Columns',
        component: (
          <ViewColumnsExcel
            isInModal={false}
            columnsOptions={props.columnsOptions}
            selectedColumnsIds={columnsExcel}
            onChange={onColumnsExcelChange}
          />
        ),
      },
    ],
    [
      props.columnsOptions,
      props.filtersDictionaries,
      columnsWeb,
      columnsExcel,
      filters,
      onFiltersChange,
      onColumnsWebChange,
      onColumnsExcelChange,
      onFiltersFormValidationStateChange,
    ],
  );

  const finishActionsProps = useMemo(
    () => [
      {
        children: 'Run',
        isDisabled: isLoading,
        onClick: onRun,
      },
      {
        children: 'Save And Run',
        onClick: onSaveAndRun,
      },
    ],
    [isLoading, onRun, onSaveAndRun],
  );

  return (
    <>
      <Wizard
        className={cn()}
        steps={steps}
        isNextDisabled={isNextDisabled}
        finishActionsProps={finishActionsProps}
        isAbsoluteStepTitles={false}
        onFinish={onSaveAndRun}
      />

      <ScreenAddModalForm showState={[isAddFormShow, setIsAddFormShow]} onSubmit={onSubmit} />
    </>
  );
};

export default ScreensAddWizard;
