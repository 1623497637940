import React from 'react';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { screensIndustryInUseOptions } from './constants/screensIndustryInUseOptions';
import ScreensInUse from '../../shared/pages/components/in-use/ScreensInUse';

const ScreensIndustryInUse: React.FC = () => {
  return <ScreensInUse inUseType={ViewType.IndustryInUse} options={screensIndustryInUseOptions} />;
};

export default ScreensIndustryInUse;
