import { AxiosRequestConfig } from 'axios';
import TokenStorageService from './TokenStorageService';

export class TokenHeaderService {
  static setConfigTokenHeader(config: AxiosRequestConfig): void {
    config.headers['Authorization'] = this.getTokenHeader();
  }

  private static getTokenHeader(): string {
    const token = TokenStorageService.getAccessToken();

    return `Bearer ${token}`;
  }
}
