import * as yup from 'yup';
import { PasswordFormFields } from './password-form-fields';

export const validationSchema = yup.object().shape({
  [PasswordFormFields.Email]: yup
    .string()
    .email()
    .required()
    .label('Email'),
});
