import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { ViewSummaryAlignType } from '../../../../../shared/entities/view/components/view/enums/ViewSummaryAlignType';

export const screensExpiredLockUpsInUseOptions: Partial<ScreenOptions> = {
  header: 'Expired Lock-Up Screen',
  summary: {
    align: ViewSummaryAlignType.Grid,
  },
  toolbar: {
    quickFilter: {
      hasIncludeSPAC: false,
      hasLocation: false,
      hasTimeFrameRange: false,
      hasDateRange: false,
      hideDateRange: false,
      hasIncludeAShares: false,
      hasIncludeSPACtextFilter: false,
      hasUpcomingLockUp: true,
    },
  },
};
