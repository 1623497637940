import React, { ReactNode, Fragment } from 'react';
import { useClassName } from '../hooks/useClassName';

export interface DescriptionListItem {
  term: ReactNode;
  definition: ReactNode;
}

interface Props {
  className?: string;
  items: DescriptionListItem[];
}

const DescriptionList: React.FC<Props> = props => {
  const { className = 'DescriptionList' } = props;
  const cn = useClassName(className);

  return (
    <dl className={cn()}>
      {props.items.map((item, index: number) => (
        <Fragment key={index}>
          <dt className={cn('term')}>{item.term}</dt>
          <dd className={cn('definition')}>{item.definition}</dd>
        </Fragment>
      ))}
    </dl>
  );
};

export default DescriptionList;
