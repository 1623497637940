export enum PrependValueType {
  GreaterEqual = '≥',
  Min = 'Min',
  Max = 'Max',
  Money = '$',
  From = 'From',
  To = 'To',
  Lower = 'Lower',
  Higher = 'Higher',
  BelowMidpoint = 'From',
  AboveMidpoint = 'To',
}
