import { screensCustomFilterSections } from '../../../contstants/screensCustomFilterSections';
import { ScreenOptions } from '../../../../../shared/pages/interfaces/ScreenOptions';
import { FileActionType } from '../../../../../../../shared/components/file-actions/enums/FileActionType';

export const screensCustomInUseOptions: Partial<ScreenOptions> = {
  header: 'Custom Screen',
  toolbar: {
    hasAddColumns: true,
    modalFilters: {
      sections: screensCustomFilterSections,
    },
    quickFilter: {
      hasIncludeAShares: false,
      hasIncludeSPAC: false,
    },
    fileActionTypes: [FileActionType.Save, FileActionType.Excel, FileActionType.Print],
  },
};
