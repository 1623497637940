import React from 'react';
import './CompanyProfileSideBar.scss';
import CompanyFinancialData from '../shared/components/financial-data/CompanyFinancialData';
import CompanyCapitalization from '../shared/components/capitalization/CompanyCapitalization';
import CompanyUnderwriters from '../shared/components/underwriters/CompanyUnderwriters';
import CompanyBalanceSheet from '../shared/components/balance-sheet/CompanyBalanceSheet';
import CompanyKeyShareholders from '../shared/components/key-shareholders/CompanyKeyShareholders';
import CompanyManagement from '../shared/components/management/CompanyManagement';
import CompanyMilestones from '../shared/components/milestones/CompanyMilestones';
import CompanyCompanyData from '../shared/components/company-data/CompanyCompanyData';
import CompanySecFilings from '../shared/components/sec-filings/CompanySecFilings';
import CompanyLockUpPeriod from '../shared/components/lock-up-period/CompanyLockUpPeriod';
import CompanyPrivateCompanyData from '../shared/components/private-company-data/CompanyPrivateCompanyData';
import SecondarySectionsGroup from '../../../../shared/components/secondary-section/SecondarySectionsGroup';
import CompanyOfferingData from '../shared/components/offering-data/CompanyOfferingData';
import CompanyResearch from '../shared/components/research/CompanyResearch';
import CompanySecondaryOfferingData from '../shared/components/secondary-offering/CompanySecondaryOfferingData';
import CompanyInvestors from '../shared/components/investors/CompanyInvestors';

const CompanyProfileSideBar: React.FC = () => {
  return (
    <SecondarySectionsGroup className="CompanyProfileSideBar">
      <CompanyPrivateCompanyData />

      <CompanyResearch />

      <CompanySecFilings />

      <CompanySecondaryOfferingData />

      <CompanyOfferingData />

      <CompanyCapitalization />

      <CompanyUnderwriters />

      <CompanyFinancialData />

      <CompanyBalanceSheet />

      <CompanyKeyShareholders />

      <CompanyInvestors />

      <CompanyManagement />

      <CompanyLockUpPeriod />

      <CompanyMilestones />

      <CompanyCompanyData />
    </SecondarySectionsGroup>
  );
};

export default CompanyProfileSideBar;
