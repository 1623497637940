import { useEffect, useMemo, useState } from 'react';
import { ViewGridContextValue } from './ViewGridContext';
import { GridPublicApi } from '../../../../../../components/grid/grid/interfaces/GridPublicApi';
import { ViewGridContextData } from './interfaces/ViewGridContextData';
import { store as appStore } from '../../../../../../../store';
import { addSuccessNotification, handleErrorNotification } from '../../../../../../../store/notification/actions';
import { CustomAny } from '../../../../../../types/generics';
import { viewGridPinColumns } from '../../constants/viewGridPinColumns';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';
import { useViewGridColumns } from '../../hooks/useViewGridColumns';
import { useViewGridRows } from '../../hooks/useViewGridRows';

export function useViewGridContextValue(data: ViewGridContextData): ViewGridContextValue {
  const {
    success,
    error,
    rows,
    sort = null,
    customColumnsSettings,
    search,
    idCols = [ViewColumnSystemName.CompanyTicker],
    isDateColumnSortingInverse = true,
    isInit = true,
    shouldWrapCellContainer,
    onColumnMapReady,
    context,
  } = data;

  const [gridPublicApi, setGridPublicApi] = useState<GridPublicApi | undefined>();

  const columns = useViewGridColumns(data.columns, rows, {
    sort,
    customColumnsSettings,
    isDateColumnSortingInverse,
    shouldWrapCellContainer,
    onColumnMapReady,
    context,
  });
  const updatedRows = useViewGridRows(rows, columns);

  const isGridVisible = useMemo(() => isInit && data.columns !== undefined, [data.columns, isInit]);
  const hasHeader = useMemo(() => !!data.toolbar || !!data.summary, [data.toolbar, data.summary]);
  const mobilePinColumns = useMemo(() => data.mobilePinColumns || viewGridPinColumns, [data.mobilePinColumns]);

  useEffect(() => {
    error && appStore.dispatch(handleErrorNotification(error) as CustomAny);
  }, [error]);

  useEffect(() => {
    success && appStore.dispatch(addSuccessNotification(success));
  }, [success]);

  return useMemo(
    () => ({
      rows: updatedRows,
      gridPublicApi,
      setGridPublicApi,
      isGridVisible,
      columns,
      hasHeader,
      mobilePinColumns,
      search,
      idCols,
    }),
    [gridPublicApi, setGridPublicApi, isGridVisible, columns, hasHeader, mobilePinColumns, search, idCols, updatedRows],
  );
}
