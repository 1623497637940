import React, { useCallback } from 'react';
import { ControlSize } from '../../../../../../shared/enums/ControlSize';
import './CompanyProfileTable.scss';
import { GridRow } from '../../../../../../shared/components/grid/shared/types/GridRow';
import ViewSimpleGrid, {
  ViewSimpleGridProps,
} from '../../../../../../shared/entities/view/components/view-grid/ViewSimpleGrid';
import { AlignTypesHorizontal } from '../../../../../../shared/enums/AlignTypesHorizontal';
import { useClassName } from '../../../../../../shared/hooks/useClassName';
import { ViewGridColumnWidth } from '../../../../../../shared/entities/view/components/view-grid/enums/ViewGridColumnWidth';
import useBreakpointAvailable from '../../../../../../shared/effects/useBreakpointAvailable';
import { mgmtTableMobileBreakpoints } from '../../constants/profileMobileBreakpoints';
import { ViewColumnSystemName } from '../../../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';

export interface CompanyProfileTableProps extends ViewSimpleGridProps {
  expandedData: GridRow[];
  isTitleDefaultWidth?: boolean;
  isColumnsAlignEnd?: boolean;
  tableType?: string;
  financialFiltersTerm?: string;
}

const CompanyProfileTable: React.FC<CompanyProfileTableProps> = props => {
  const isMobile: boolean = useBreakpointAvailable(mgmtTableMobileBreakpoints);
  const {
    expandedData,
    columns,
    className,
    title,
    isTitleDefaultWidth,
    isColumnsAlignEnd,
    onColumnMapReady,
    ...viewSimpleGridProps
  } = props;

  React.useEffect(() => {
    const stubsCells = document.querySelectorAll('.StubsCell, .Tp, .Mp, .qm');
    const stubsCellsLength = stubsCells && stubsCells.length;

    for (let i = 0; i < stubsCellsLength; i++) {
      const closestTableCell = stubsCells[i].closest('.TableCell');
      if (closestTableCell) {
        closestTableCell.classList?.add('stubs-table-cell');
      }
    }

    for (let i = 0; i < stubsCellsLength; i++) {
      const closestTableRow = stubsCells[i].closest('.TableRow');
      if (closestTableRow) {
        const eachRowsChildStubCells = closestTableRow.querySelectorAll('.stubs-table-cell');
        eachRowsChildStubCells &&
          eachRowsChildStubCells.forEach((item, index) => {
            if (index === 0) {
              item.classList?.add('first-stubs-table-cell');
            }
            if (index === eachRowsChildStubCells.length - 1) {
              item.classList?.add('last-stubs-table-cell');
            }
          });
      }
    }
  }, []);

  const cn = useClassName('CompanyProfileTable', className);

  const onColumnMapReadyProfileTable = useCallback(
    (column, index, context) => {
      const isTitleColumn = index === 0;
      const isCurrentColumnTitleDefaultWidth = isTitleDefaultWidth && isTitleColumn;

      if (isMobile) {
        if (
          column.field === ViewColumnSystemName.KeyShareholder ||
          column.field === ViewColumnSystemName.KeyExecutive
        ) {
          column.minWidth = ViewGridColumnWidth.Sm;
          column.width = ViewGridColumnWidth.Sm;
        }
        if (column.field === ViewColumnSystemName.Ownership) {
          column.minWidth = ViewGridColumnWidth.Md;
          column.width = ViewGridColumnWidth.Md;
        }
        if (column.field === ViewColumnSystemName.Position) {
          column.minWidth = ViewGridColumnWidth.Xs;
          column.width = ViewGridColumnWidth.Xs;
        }
        if (column.field === ViewColumnSystemName.Age) {
          column.minWidth = ViewGridColumnWidth.Xxs;
          column.width = ViewGridColumnWidth.Xxs;
        }
        if (column.field === ViewColumnSystemName.Corporate_Background) {
          column.isAutoWidth = false;
          column.width = ViewGridColumnWidth.Xl;
        }
        if (column.field === ViewColumnSystemName.Additional_Details) {
          column.isAutoWidth = false;
          column.width = ViewGridColumnWidth.Xl;
        }
      }

      if (!isMobile) {
        if (column.field === ViewColumnSystemName.CompanyName) {
          column.width = ViewGridColumnWidth.Xld;
        }
      }

      const newColumn = {
        ...column,
        ...onColumnMapReady?.(column, index, context),
      };

      return {
        ...newColumn,
        // First column is always title so align to start
        minWidth: isCurrentColumnTitleDefaultWidth ? ViewGridColumnWidth.Lg : newColumn.minWidth,
        width: isCurrentColumnTitleDefaultWidth ? ViewGridColumnWidth.Lg : newColumn.width,
        alignHorizontal: isTitleColumn
          ? AlignTypesHorizontal.Start
          : isColumnsAlignEnd
          ? AlignTypesHorizontal.End
          : newColumn.alignHorizontal,
      };
    },
    [onColumnMapReady, isColumnsAlignEnd, isTitleDefaultWidth, isMobile],
  );

  return (
    <ViewSimpleGrid
      {...viewSimpleGridProps}
      className={`${cn()}${props.tableType ? ' ' + props.tableType : ''}${
        props.financialFiltersTerm ? ' ' + props.financialFiltersTerm : ''
      }`}
      size={ControlSize.Small}
      columns={columns}
      rows={expandedData}
      title={title}
      isColumnHeaderNoWrap
      isStickyFirstColumn
      onColumnMapReady={onColumnMapReadyProfileTable}
      hasHtmlFormatter
    />
  );
};

export default CompanyProfileTable;
