import React from 'react';
import './PcwSourceCell.scss';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import { ViewGridCellValueService } from '../../services/ViewGridCellValueService';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';
import Tooltip from '../../../../../../components/tooltip/Tooltip';
import { useClassName } from '../../../../../../hooks/useClassName';

interface PcwSourceCellProps extends GridCellFormatterCommonProps {
  tableData: any;
}

type Props = PcwSourceCellProps;

const PcwSourceCell: React.FC<Props> = props => {
  const cn = useClassName('PcwSourceCell');

  const { data, tableData } = props;

  let note = undefined;

  switch (data.Name) {
    case 'Est. Sales':
      note = tableData.estSalesNote;
      break;
    case 'Est. Valuation':
      note = tableData.estValuationSource;
      break;
    case 'Est. Funding':
      note = tableData.estFundingNote;
      break;
  }

  const visibleValue = ViewGridCellValueService.getVisible(data, ViewColumnSystemName.CompanyData);

  return (
    <CustomCellFormatterWrapper className={cn()} value={visibleValue}>
      {note ? (
        <Tooltip
          title={visibleValue}
          content={
            <span className={cn('source')}>
              <b>Source:</b> {note}
            </span>
          }
          hasTitleLinkStyle
        />
      ) : (
        visibleValue
      )}
    </CustomCellFormatterWrapper>
  );
};

export default PcwSourceCell;
