import { EmailPreference } from '../enums/EmailPreference';
import { EmailPreferenceSectionItem } from '../interfaces/EmailPreferenceSectionItem';
import { EmailPreferenceSection } from '../interfaces/EmailPreferenceSection';
import { SelectOption } from '../../../shared/services/select/interfaces/SelectOption';
import { EmailPreferenceDto } from '../interfaces/EmailPreferenceDto';

const RESEARCH_FILTERS: SelectOption[] = [
  {
    title: 'All',
    value: 0,
  },
  {
    title: 'Only Favorites',
    value: 1,
  },
];

const getResearchFilters = (values: EmailPreference[]): SelectOption[] =>
  RESEARCH_FILTERS.map((option: SelectOption, index: number) => ({
    title: option.title,
    value: values[index].toString(),
  }));

const setSectionItems = (sectionItems: EmailPreferenceSectionItem[]): void => {
  sectionItems.forEach(
    (sectionItem: EmailPreferenceSectionItem) =>
      (sectionItem.controls = sectionItem.preferences.map((preference: EmailPreferenceSection) =>
        preference.name.toString(),
      )),
  );
};

export const EMAIL_TYPES_SECTION_ITEM: EmailPreferenceSectionItem[] = [
  {
    title: 'Research',
    preferences: [
      {
        label: 'Management Questions',
        name: EmailPreference.ManagementQuestions,
        value: null,
        filterOptions: getResearchFilters([
          EmailPreference.AllManagementQuestions,
          EmailPreference.FlaggedManagementQuestions,
        ]),
      },
      {
        label: 'Pre-IPO Report/Early Take',
        name: EmailPreference.PreIpoReport,
        filterOptions: getResearchFilters([EmailPreference.AllPreIpoReport, EmailPreference.FlaggedPreIpoReport]),
        value: null,
      },
      {
        label: 'Terms Changes',
        name: EmailPreference.TermsChanges,
        filterOptions: getResearchFilters([EmailPreference.AllTermsChanges, EmailPreference.FlaggedTermsChanges]),
        value: null,
      },
      {
        label: 'Pricing Alerts',
        name: EmailPreference.PrisingAlerts,
        filterOptions: getResearchFilters([EmailPreference.AllPrisingAlerts, EmailPreference.FlaggedPrisingAlerts]),
        value: null,
      },
      {
        label: 'Postponed/Withdrawn',
        name: EmailPreference.Postpones,
        filterOptions: getResearchFilters([EmailPreference.AllPostpones, EmailPreference.FlaggedPostpones]),
        value: null,
      },
    ],
  },
  {
    title: 'Market Info',
    preferences: [
      {
        label: 'Morning Recap (Mon-Sat)',
        name: EmailPreference.MorningRecap,
        value: null,
      },
      {
        label: 'Weekly Calendar (Fri)',
        name: EmailPreference.WeeklyCalendar,
        value: null,
      },
      {
        label: 'US Weekly Winners & Losers (Fri)',
        name: EmailPreference.WeeklyWinnersAndLoosers,
        value: null,
      },
      {
        label: 'Intl Weekly Winners & Losers (Fri)',
        name: EmailPreference.WeeklyWinnersAndLoosersIntl,
        value: null,
      },
      {
        label: 'Special Reports (Quarterly Reviews, etc.)',
        name: EmailPreference.SpecialReports,
        value: null,
      },
    ],
  },
  {
    title: 'Pipeline/Private',
    preferences: [
      {
        label: 'New Public Filing',
        name: EmailPreference.NewPublicFiling,
        value: null,
      },
    ],
  },
];
setSectionItems(EMAIL_TYPES_SECTION_ITEM);

export const FILTER_BY_CRITERIA_SECTION_ITEM: EmailPreferenceSectionItem[] = [
  {
    title: 'Listing',
    preferences: [
      {
        label: 'U.S.',
        name: EmailPreference.US,
        value: null,
      },
      {
        label: 'ADRs',
        name: EmailPreference.ADRs,
        value: null,
      },
      {
        label: 'Non-U.S.',
        name: EmailPreference.NonUS,
        value: null,
      },
    ],
  },
  {
    title: 'Market Cap',
    preferences: [
      {
        label: 'Micro (<$200M)',
        name: EmailPreference.Micro,
        value: null,
      },
      {
        label: 'Small/Micro ($200M-$1.5B)',
        name: EmailPreference.SmallMicro,
        value: null,
      },
      {
        label: 'Mid ($1.5B-$5B)',
        name: EmailPreference.Mid,
        value: null,
      },
      {
        label: 'Mid/Large ($5B-$12B)',
        name: EmailPreference.MidLarge,
        value: null,
      },
      {
        label: 'Large (>$12B)',
        name: EmailPreference.Large,
        value: null,
      },
    ],
  },
  {
    title: 'Style',
    preferences: [
      {
        label: 'Growth (P/B>2.5x)',
        name: EmailPreference.Growth,
        value: null,
      },
      {
        label: 'Value (P/B<2.5x)',
        name: EmailPreference.Value,
        value: null,
      },
    ],
  },
  {
    title: 'Industry',
    preferences: [
      {
        label: 'Consumer Disc & Staples',
        name: EmailPreference.ConsumerDiscStaples,
        value: null,
      },
      {
        label: 'Energy & Mat’ls & Utilities',
        name: EmailPreference.EnergyMatLsUtilities,
        value: null,
      },
      {
        label: 'Financials',
        name: EmailPreference.Financials,
        value: null,
      },
      {
        label: 'Health Care',
        name: EmailPreference.HealthCare,
        value: null,
      },
      {
        label: 'Industrials',
        name: EmailPreference.Industrials,
        value: null,
      },
      {
        label: 'Tech & Communication Svcs',
        name: EmailPreference.TechTelecom,
        value: null,
      },
    ],
  },
];

setSectionItems(FILTER_BY_CRITERIA_SECTION_ITEM);

export const DEFAULT_SECTIONS: EmailPreferenceDto = {
  emailTypes: EMAIL_TYPES_SECTION_ITEM,
  filterByCriteria: FILTER_BY_CRITERIA_SECTION_ITEM,
};

export const INITIAL_SECTIONS: EmailPreferenceDto = {
  emailTypes: null,
  filterByCriteria: null,
};
