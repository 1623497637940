import React from 'react';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { NewsLocationService } from '../../../../../../../news/shared/services/NewsLocationService';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import Hyperlink from '../../../../../../components/typography/Hyperlink';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';
import { ResearchTypeTitle } from '../../../../../filters/enum/types/ResearchTypeTitle';
import { ResearchTypeColumnValue } from '../../interfaces/column-values/ResearchTypeColumnValue';

type Props = GridCellFormatterCommonProps;

const ResearchKeywordCell: React.FC<Props> = props => {
  const { data } = props;

  const researchType: ResearchTypeColumnValue = data[ViewColumnSystemName.ResearchType];
  const reportKey = data[ViewColumnSystemName.ReportKey];
  const resultsCount = data[ViewColumnSystemName.ResultsCount];

  const isNews = researchType.title === ResearchTypeTitle.AnalystNote;

  return (
    <CustomCellFormatterWrapper value={props.value}>
      {isNews ? (
        <Hyperlink to={NewsLocationService.getRoutePath(reportKey)} isExternal>
          {props.value}
        </Hyperlink>
      ) : (
        <div>{props.value}</div>
      )}

      {resultsCount && <div>Results Count: {resultsCount}</div>}
    </CustomCellFormatterWrapper>
  );
};

export default ResearchKeywordCell;
