import React, { useMemo } from 'react';
import StatsPage from '../StatsPage';
import PrimarySectionRow from '../../../shared/components/primary-section/PrimarySectionRow';
import StatsElement from '../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../shared/entities/stats-element/enums/StatsElementType';
import { ViewFilterName } from '../../../shared/entities/filters/enum/types/ViewFilterName';
import { StatsGlobalFilter } from '../types/StatsGlobalFilter';
import { recentIposPerfomanceStatsElementOptions } from './constants/recentIposPerfomanceStatsElementOptions';

const StatsRecentIposReturns: React.FC = () => {
  const filtersNames = useMemo(() => [ViewFilterName.Headquarter], []) as StatsGlobalFilter[];

  return (
    <StatsPage header="Renaissance IPO Index" filtersNames={filtersNames}>
      <PrimarySectionRow>
        <StatsElement type={StatsElementType.RecentIPOPerformance} options={recentIposPerfomanceStatsElementOptions} />
      </PrimarySectionRow>
    </StatsPage>
  );
};

export default StatsRecentIposReturns;
