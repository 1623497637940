import { ResponsiveCalendarEvent } from './interfaces/ResponsiveCalendarEvent';
import { EventSourceInput } from '@fullcalendar/core';

export class ResponsiveCalendarService {
  static prepareEvents<T>(events: ResponsiveCalendarEvent<T>[]): EventSourceInput {
    return events.map(event => ({
      title: event.title,
      start: event.start,
      extendedProps: event.extendedProps,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    }));
  }
}
