import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import './GridScroll.scss';
import debounce from 'lodash.debounce';
import { GridApi } from '@ag-grid-enterprise/all-modules';
import { GridClass } from './enums/GridClass';
import GridScrollAction from './GridScrollAction';
import { GridScrollService } from './services/GridScrollService';
import { GridScrollDirection } from './enums/GridScrollDirection';
import { IconName } from '../../icon/IconName';
import { useScrollbarInfo } from '../../../hooks/useScrollbarInfo';
import { DomService } from '../../../services/dom/DomService';
import { useAbsoluteElementCenter } from '../../../hooks/useAbsoluteElementCenter';
import { isDeviceDesktop } from '../../../constants/device/device';

interface Props {
  parentEl: HTMLElement;
  gridApi: GridApi;
}

enum GridScrollEvents {
  BodyScroll = 'scroll',
  WindowResize = 'resize',
  ColumnResize = 'columnResized',
}

let isScrolling = false;

const GridScroll: React.FC<Props> = props => {
  const { parentEl } = props;

  const { scrollbarWidth, hasScrollbar } = useScrollbarInfo({
    context: parentEl,
    selector: DomService.getClassSelector(GridClass.RootWrapper),
  });
  const rightActionStyle = useMemo(
    () => ({
      right: hasScrollbar ? scrollbarWidth : 0,
    }),
    [hasScrollbar, scrollbarWidth],
  );

  const centerStyle = useAbsoluteElementCenter(parentEl);

  const [isRightAvailable, setIsRightAvailable] = useState<boolean>(false);
  const [isLeftAvailable, setIsLeftAvailable] = useState<boolean>(false);
  const isScrollAvailable = isDeviceDesktop;

  const gridBodyScroll = useMemo<Element>(() => {
    return parentEl.getElementsByClassName(GridClass.BodyScroll)[0];
  }, [parentEl]);

  const gridRoot = useMemo<Element>(() => {
    return parentEl.getElementsByClassName(GridClass.Root)[0];
  }, [parentEl]);

  const scrollRecursion = async (direction: GridScrollDirection): Promise<void> => {
    const isAvailableDirection = GridScrollService.isAvailable(gridBodyScroll, direction);
    const isScrollAvailable = isScrolling && gridBodyScroll && isAvailableDirection;

    if (isScrollAvailable) {
      await GridScrollService.scroll({ element: gridBodyScroll, direction });
      scrollRecursion(direction);
    }
  };

  const scroll = async (direction: GridScrollDirection): Promise<void> => {
    isScrolling = true;
    scrollRecursion(direction);
  };

  const onRightMouseDown = async (): Promise<void> => {
    scroll(GridScrollDirection.Right);
  };

  const onLeftMouseDown = async (): Promise<void> => {
    scroll(GridScrollDirection.Left);
  };

  const onActionMouseUp = (): void => {
    isScrolling = false;
  };

  useEffect(() => {
    const DEBOUNCE_TIME = 300;

    const setActionsAvailability = (): void => {
      setIsRightAvailable(GridScrollService.isRightAvailable(gridBodyScroll));
      setIsLeftAvailable(GridScrollService.isLeftAvailable(gridBodyScroll));
    };

    const onBodyScroll = debounce((): void => {
      setActionsAvailability();
    }, DEBOUNCE_TIME);

    const onWindowResize = debounce((): void => {
      setActionsAvailability();
    }, DEBOUNCE_TIME);

    const onColumnResize = debounce((): void => {
      setActionsAvailability();
    }, DEBOUNCE_TIME);

    // Init first component render
    setActionsAvailability();

    gridBodyScroll.addEventListener(GridScrollEvents.BodyScroll, onBodyScroll);
    window.addEventListener(GridScrollEvents.WindowResize, onWindowResize);
    props.gridApi.addEventListener(GridScrollEvents.ColumnResize, onColumnResize);

    return (): void => {
      gridBodyScroll.removeEventListener(GridScrollEvents.BodyScroll, onBodyScroll);
      window.removeEventListener(GridScrollEvents.WindowResize, onWindowResize);
      props.gridApi.removeEventListener(GridScrollEvents.ColumnResize, onColumnResize);
    };
  }, [gridBodyScroll, props.gridApi]);

  return ReactDOM.createPortal(
    <>
      <GridScrollAction
        iconName={IconName.RightArrow}
        isHidden={!isRightAvailable || !isScrollAvailable}
        containerStyle={rightActionStyle}
        contentStyle={centerStyle}
        onActionMouseDown={onRightMouseDown}
        onActionMouseUp={onActionMouseUp}
      />

      <GridScrollAction
        iconName={IconName.LeftArrow}
        isHidden={!isLeftAvailable || !isScrollAvailable}
        contentStyle={centerStyle}
        onActionMouseDown={onLeftMouseDown}
        onActionMouseUp={onActionMouseUp}
      />
    </>,
    gridRoot,
  );
};

export default GridScroll;
