import React from 'react';
import { useParams } from 'react-router-dom';
import { VideosApiService } from '../../shared/api/VideosApiService';
import { useMemoizedCallback } from '../../shared/hooks/useMemoizedCallback';
import { VideoIdParams } from '../../shared/interfaces/VideoIdParams';
import LatestVideoContent from './LatestVideoContent';

const LatestVideos: React.FC = () => {
  const { videoId } = useParams<VideoIdParams>();

  const getMemoizedLatestVideo = useMemoizedCallback(() => VideosApiService.getLatestVideo(videoId), [videoId]);

  return <LatestVideoContent getMemoizedLatestVideo={getMemoizedLatestVideo} />;
};

export default LatestVideos;
