import React, { useMemo, useCallback } from 'react';
import './CompanyInvestmentRationaleTerm.scss';
import classNames from 'classnames';

export interface CompanyInvestmentRationaleTermProps {
  ratingKey: number;
  ratingTypeName: React.ReactNode;
}

const FIRST_BOX_NUMBER = 1;
const MAX_BOXES_COUNT = 5;

const CompanyInvestmentRationaleTerm: React.FC<CompanyInvestmentRationaleTermProps> = props => {
  const getBoxClassName = useCallback(
    (boxNumber: number): string =>
      classNames('CompanyInvestmentRationaleTerm__progressBox', {
        'CompanyInvestmentRationaleTerm__progressBox--active': boxNumber <= props.ratingKey,
      }),
    [props.ratingKey],
  );

  const boxes: React.ReactNode = useMemo(() => {
    const elements = [];

    for (let boxNumber = FIRST_BOX_NUMBER; boxNumber <= MAX_BOXES_COUNT; boxNumber++) {
      elements.push(<div key={boxNumber} className={getBoxClassName(boxNumber)} />);
    }

    return elements;
  }, [getBoxClassName]);

  return (
    <div className="CompanyInvestmentRationaleTerm">
      <p className="CompanyInvestmentRationaleTerm__title">{props.ratingTypeName}</p>

      <div className="CompanyInvestmentRationaleTerm__progress">
        {boxes}

        <div className="CompanyInvestmentRationaleTerm__progressNumber">{props.ratingKey}</div>
      </div>
    </div>
  );
};

export default CompanyInvestmentRationaleTerm;
