import { useEffect } from 'react';
import DashboardApiService from '../../shared/api/DashboardApiService';
import { DashboardWidgetConfig } from '../interfaces/DashboardWidgetConfig';
import { dashboardDefaultWidgetsConfigs } from '../constants/dashboardDefaultWidgetsConfigs';

export function useDashboardConfiguration(setWidgets: (widgets: DashboardWidgetConfig[]) => void): void {
  useEffect(() => {
    DashboardApiService.getWidgets().then((response: DashboardWidgetConfig[] | null): void =>
      setWidgets(response ? response : dashboardDefaultWidgetsConfigs),
    );
  }, [setWidgets]);
}
