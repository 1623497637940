import { memoize } from 'lodash';
import ApiService from './ApiService';
import { ApiPath } from './enums/ApiPath';
import { ViewDto } from '../entities/view/components/view/interfaces/api/ViewDto';
import { ViewType } from '../entities/view/components/view/enums/ViewType';
import { SaveViewFormData } from '../entities/view/components/view/components/view-toolbar/save-view/form/SaveViewFormData';
import { ViewSaveViewAndRunReqParams } from '../entities/view/components/view/interfaces/api/ViewSaveViewAndRunReqParams';
import { ViewReqParamsBase } from '../entities/view/components/view/interfaces/api/ViewReqParamsBase';
import { ViewShareLinkFormData } from '../entities/view/components/view/components/view-share-link-modal/form/ViewShareLinkFormData';
import { ViewSharedLink } from '../entities/view/components/view/interfaces/api/ViewSharedLink';
import { GridSort } from '../components/grid/grid/interfaces/GridSort';
import { Nullable } from '../types/generics';
import { ViewId } from '../entities/view/components/view/types/ViewId';
import { ViewCreateCustomScreenReqParams } from '../entities/view/components/view/interfaces/api/ViewCreateCustomScreenReqParams';
import { ViewSaved } from '../entities/view/components/view/interfaces/api/ViewSaved';
import { ViewFiltersDictionaries } from '../entities/filters/interfaces/ViewFiltersDictionaries';
import { ViewCreateCustomScreenDto } from '../entities/view/components/view/interfaces/api/ViewCreateCustomScreenDto';
import { ViewColumnsOptionsDto } from '../entities/view/components/view/components/view-columns/add/interfaces/dto/ViewColumnsOptionsDto';
import { ApiResponseSuccessFileData } from '../types/api/ApiResponse';
import { DealStatus } from '../entities/filters/enum/DealStatus';
import { ViewFilterName } from '../entities/filters/enum/types/ViewFilterName';

export class ViewApiService {
  static getByViewType(viewType: ViewType): Promise<ViewDto> | any {
    return ApiService.get<ViewDto>(`${ApiPath.View}/Type/${viewType}`);
  }

  static getById(id: number): Promise<ViewDto> {
    return ApiService.get<ViewDto>(`${ApiPath.View}/${id}`);
  }

  static createView(data: ViewReqParamsBase): Promise<ViewId> {
    if (data?.filters?.DealStatusSelect === '8') {
      data.viewType = ViewType.WithdrawnPostponedInUse;
      delete data.filters.DealStatusSelect;
      data?.filters?.DealStatus && delete data?.filters?.DealStatus;
      data.filters = { ...data.filters, ...{ Withdrawn: true, [ViewFilterName.SectorIndustry]: ['70'] } };
    }

    if (data?.filters?.DealStatusSelect === '1') {
      data.filters = { ...data.filters, ...{ DealStatus: DealStatus.Upcoming } };
    } else if (data?.filters?.DealStatusSelect === '8') {
    } else if (data?.filters?.DealStatusSelect) {
      data.filters = { ...data.filters, ...{ DealStatus: DealStatus.Priced } };
    }

    if (data?.filters?.ViewType === 'LocationLanding') {
      data.viewType = ViewType.LocationLanding;
    }

    return ApiService.post<ViewId>(`${ApiPath.View}/Create`, data);
  }

  static saveView(id: number, data: SaveViewFormData): Promise<ViewSaved[]> {
    return ApiService.post<ViewSaved[]>(`${ApiPath.View}/${id}/SaveView`, data);
  }

  static saveViewAndRun(data: ViewSaveViewAndRunReqParams): Promise<ViewDto> {
    return ApiService.post<ViewDto>(`${ApiPath.View}/SaveViewAndRun`, data);
  }

  static run(data: ViewReqParamsBase): Promise<ViewDto> {
    if (data?.filters?.DealStatusSelect === '1') {
      data.filters = { ...data.filters, ...{ DealStatus: DealStatus.Upcoming } };
    } else if (data?.filters?.DealStatusSelect === '8') {
    } else if (data?.filters?.DealStatusSelect) {
      data.filters = { ...data.filters, ...{ DealStatus: DealStatus.Priced } };
    }
    return ApiService.post<ViewDto>(`${ApiPath.View}/Run`, data);
  }

  static saveSorting(id: number, sort: Nullable<GridSort>): Promise<void> {
    return ApiService.post<void>(`${ApiPath.View}/${id}/SaveSorting`, sort);
  }

  static exportById(id: number): Promise<ApiResponseSuccessFileData> {
    return ApiService.getFile(`${ApiPath.View}/${id}/Export`);
  }

  static deleteView(viewId: number): Promise<ViewSaved[]> {
    return ApiService.delete<ViewSaved[]>(`${ApiPath.View}/DeleteSavedView/${viewId}`);
  }

  static shareLink(viewId: number, data: ViewShareLinkFormData): Promise<ViewSharedLink[]> {
    return ApiService.post<ViewSharedLink[]>(`${ApiPath.View}/${viewId}/SaveAndShare`, data);
  }

  static getColumns(): Promise<ViewColumnsOptionsDto> {
    return ApiService.get(`${ApiPath.View}/CustomScreenColumns`);
  }

  static getSavedScreens(): Promise<ViewSaved[]> {
    return ApiService.get(`${ApiPath.View}/SavedScreens`);
  }

  static createCustomScreenView(data: ViewCreateCustomScreenReqParams): Promise<ViewCreateCustomScreenDto> {
    return ApiService.post(`${ApiPath.View}/CreateCustomScreen`, data);
  }

  static getCustomScreenFiltersDictionaries(): Promise<ViewFiltersDictionaries> {
    return ApiService.get(`${ApiPath.View}/CustomScreenFilterDictionaries`);
  }
}

// Cached dictionaries
ViewApiService.getColumns = memoize(ViewApiService.getColumns.bind(ViewApiService));
ViewApiService.getCustomScreenFiltersDictionaries = memoize(
  ViewApiService.getCustomScreenFiltersDictionaries.bind(ViewApiService),
);
