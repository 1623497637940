import React, { useState } from 'react';
import { TrialRequestFormFields } from './trial-request-form-fields';
import { validationSchema } from './validation-schema';
import FormInput from '../../../shared/components/forms/FormInput';
import { TrialRequestFormData } from './trial-request-form-data';
import Form from '../../../shared/components/forms/Form';
import FormGroup from '../../../shared/components/forms/FormGroup';
import { ColorScheme } from '../../../shared/enums/ColorScheme';
import FormCaptcha from '../../../shared/components/forms/FormCaptcha';
import ButtonSubmit from '../../../shared/components/forms/ButtonSubmit';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../../shared/enums/RoutePath';
import { RequestEmailService } from '../request-email.service';
import ApiContactService from '../../../shared/api/ContactApiService';
import { SubmitFormFn } from '../../../shared/components/forms/types/SubmitFormFn';
import { ControlSize } from '../../../shared/enums/ControlSize';
import { AlignTypesHorizontal } from '../../../shared/enums/AlignTypesHorizontal';
import { TrialRequestTextElement } from './enum/TrialRequestTextElement';

const RequestForm: React.FC = () => {
  const email: string | undefined = RequestEmailService.extractValue();
  const defaultValues: Partial<TrialRequestFormData> = {
    [TrialRequestFormFields.BusinessEmail]: email,
  };
  const [isSubmitted, setIsSubmitted] = useState(false);
  const onSubmit: SubmitFormFn<TrialRequestFormData> = data => {
    return ApiContactService.requestTrialRequest(data).then(() => setIsSubmitted(true));
  };

  return (
    <Form
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      size={ControlSize.Medium}
      alignHorizontal={AlignTypesHorizontal.Center}
    >
      <FormGroup>
        <FormInput name={TrialRequestFormFields.FirstName} label="First Name" isDisabled={isSubmitted} />
      </FormGroup>

      <FormGroup>
        <FormInput name={TrialRequestFormFields.LastName} label="Last Name" isDisabled={isSubmitted} />
      </FormGroup>

      <FormGroup>
        <FormInput name={TrialRequestFormFields.PhoneNumber} label="Phone Number" isDisabled={isSubmitted} />
      </FormGroup>

      <FormGroup>
        <FormInput name={TrialRequestFormFields.BusinessEmail} label="Business Email" isDisabled={isSubmitted} />
      </FormGroup>

      <FormGroup>
        <FormInput name={TrialRequestFormFields.Company} label="Company" isDisabled={isSubmitted} />
      </FormGroup>

      <FormGroup>
        <FormInput name={TrialRequestFormFields.JobTitle} label="Job Title" isDisabled={isSubmitted} />
      </FormGroup>

      {/* <FormGroup>
        <FormCheckbox name={TrialRequestFormFields.Agree} label={<Agreement />} isDisabled={isSubmitted} />
      </FormGroup> */}

      {!isSubmitted && (
        <FormGroup>
          <FormCaptcha name={TrialRequestFormFields.Captcha} />
        </FormGroup>
      )}

      <FormGroup>
        <ButtonSubmit
          isSubmitted={isSubmitted}
          message="Request free trial"
          submittedMessage="Your request has been successfully created"
          colorScheme={ColorScheme.Attention}
        />
      </FormGroup>

      <FormGroup>
        <Link to={RoutePath.Login}>{TrialRequestTextElement.Login}</Link>
      </FormGroup>
    </Form>
  );
};

export default RequestForm;
