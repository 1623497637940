import React from 'react';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { withdrawnPostponedInUseOptions } from './constants/withdrawnPostponedInUseOptions';
import ScreensInUse from '../../shared/pages/components/in-use/ScreensInUse';

const ScreensWithdrawnPostponedInUse: React.FC = () => {
  return <ScreensInUse inUseType={ViewType.WithdrawnPostponedInUse} options={withdrawnPostponedInUseOptions} />;
};

export default ScreensWithdrawnPostponedInUse;
