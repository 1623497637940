import { StatsElementOptions } from '../../../../../../shared/entities/stats-element/interfaces/StatsElementOptions';
import { ChartType } from '../../../../../../shared/components/chart/enums/ChartType';
import { rangeSelectorButtons } from '../../../../../../shared/components/chart/constants/rangeSelectorButtons';
import { RangeSelectorButtonType } from '../../../../../../shared/components/chart/enums/RangeSelectorButtonType';
import { labelsPercentageFormatter } from '../../../../../../shared/components/chart/constants/formatters';
import { ChartTooltipFormatterService } from '../../../../../../shared/components/chart/services/ChartTooltipFormatterService';
import { CustomAny } from '../../../../../../shared/types/generics';
import { NumberHelper } from '../../../../../../shared/helpers/NumberHelper';
import { NumberFormat } from '../../../../../../shared/enums/NumberFormat';
import { ChartValuesType } from '../../../../../../shared/components/chart/enums/ChartValuesType';
import { StatsElementChartProps } from '../../../../../../shared/entities/stats-element/components/chart/interfaces/StatsElementChartProps';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../../../../../shared/enums/RoutePath';
import React from 'react';
import { BlockOrientation } from '../../../../../../shared/enums/BlockOrientation';
import { ChartDateFormat } from '../../../../../../shared/components/chart/enums/ChartDateFormat';

export const RECENT_IPO_PERFOMANCE_CHART_OPTIONS = 0;
export const IPO_MARKET_CHART_OPTIONS_INDEX = 1;

export const US_IPO_MARKET_CHART_CREDITS_TEXT = 'IPOs ≥ $5mm Deal Size';
export const NONUS_GLOBAL_IPO_MARKET_CHART_CREDITS_TEXT = 'IPOs ≥ $100mm Deal Size';

export const recentIpoPerfomanceChartOptions: Partial<StatsElementChartProps> = {
  title: <Link to={RoutePath.StatsRecentIposReturns}>Recent IPO Performance</Link>,
  type: ChartType.LineStock,
  optionsList: [
    {
      rangeSelector: {
        buttons: [
          rangeSelectorButtons[RangeSelectorButtonType.ThreeMonth],
          rangeSelectorButtons[RangeSelectorButtonType.YTD],
          rangeSelectorButtons[RangeSelectorButtonType.Year],
          rangeSelectorButtons[RangeSelectorButtonType.ThreeYear],
          rangeSelectorButtons[RangeSelectorButtonType.FiveYear],
        ],
        allButtonsEnabled: true,
        selected: 1,
        inputEnabled: false,
      },
      series: [
        {
          type: 'line',
          marker: { enabled: false },
          stacking: 'normal',
        },
      ],
      yAxis: {
        labels: {
          formatter: labelsPercentageFormatter,
        },
      },
      tooltip: {
        // Taken from the IPO Pro site
        // Custom tooltip formatter is not return {point.change} property
        pointFormat:
          '<span class="highcharts-color-{point.colorIndex}"> ● </span>{series.name}: <b>{point.y}</b> ({point.change:.1f}%)<br/>',
        valueDecimals: 1,
        split: false,
        xDateFormat: ChartDateFormat.DayOfWeekMonthShortDayYear,
      },
      // credits: {
      //   text: '',
      // },
      plotOptions: { series: { compare: 'percent' } },
    },
  ],
};

export const getIpoMarketChartOptions = (creditsText?: string): Partial<StatsElementChartProps> => ({
  type: ChartType.Base,
  title: 'IPO Market',
  optionsList: [
    {
      yAxis: [
        {
          title: {
            text: 'Proceeds in Billions (US$)',
          },
          stackLabels: {
            enabled: true,
            formatter() {
              return NumberHelper.getFormatValue(this.total, NumberFormat.CurrencyFloatWithLetter);
            },
          },
          labels: {
            formatter() {
              return NumberHelper.getFormatValue(this.value, NumberFormat.CurrencyIntegerWithLetter);
            },
          },
        },
        {
          title: {
            text: 'Number of IPOs',
          },
          labels: {
            formatter() {
              return NumberHelper.getFormatValue(this.value, NumberFormat.Integer);
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        formatter() {
          return ChartTooltipFormatterService.getTooltip({
            points: this.points as CustomAny[],
            title: `${this.x}`,
            pointsValueType: [ChartValuesType.Currency, ChartValuesType.Currency, ChartValuesType.Integer],
          });
        },
      },
      series: [
        {
          type: 'column',
        },
        {
          type: 'column',
        },
        {
          type: 'line',
          yAxis: 1,
        },
      ],
      plotOptions: {
        column: {
          //stacking: 'normal',
          grouping: false,
          dataLabels: {
            //enabled: false,
            enabled: true,
            formatter() {
              return NumberHelper.getFormatValue(this.y, NumberFormat.CurrencyFloatWithLetter);
            },
          },
        },
      },
      credits: {
        text: creditsText,
      },
    },
  ],
});

export const getRecentIpoPerfomanceStatsElementOptions = (creditsText?: string): StatsElementOptions => ({
  pageOptions: {
    chartsOrientation: BlockOrientation.Line,
  },
  chartsOptions: {
    [RECENT_IPO_PERFOMANCE_CHART_OPTIONS]: recentIpoPerfomanceChartOptions,
    [IPO_MARKET_CHART_OPTIONS_INDEX]: getIpoMarketChartOptions(creditsText),
  },
});
