import React, { useCallback, useState } from 'react';
import './DashboardNewsWidget.scss';
import { DashboardWidgetComponent } from '../../interfaces/DashboardWidgetComponent';
import NewsList from '../../../news/components/NewsList';
import { DashboardNewsDto } from '../../interfaces/DashboardNewsDto';
import NewsRcExternalFilter from '../../../news/shared/components/news-rc-external-filter/NewsRcExternalFilter';
import { useClassName } from '../../../shared/hooks/useClassName';
import { DashboardNewsWidgetBlock, DashboardNewsWidgetElement } from './dashboardNewsWidgetBem';
import { DASHBOARD_WIDGET_DATE_FORMAT } from '../../constants/dashboardWidgetDefaultOptions';

const DashboardNewsWidget: DashboardWidgetComponent<DashboardNewsDto[]> = props => {
  const { data } = props;

  const cn = useClassName(DashboardNewsWidgetBlock.Root);

  const [rows, setRows] = useState([]);

  const onFilter = useCallback(news => setRows(news), []);

  return (
    <div className={cn()}>
      <NewsRcExternalFilter className={cn(DashboardNewsWidgetElement.Filter)} news={data} onFilter={onFilter} />

      <NewsList expandedData={rows} hasDateTag dateTagFormat={DASHBOARD_WIDGET_DATE_FORMAT} />
    </div>
  );
};

export default DashboardNewsWidget;
