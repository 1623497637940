import React from 'react';
import StatsElement from '../../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../../shared/entities/stats-element/enums/StatsElementType';
import AdditionalStatsContentContainer from '../../shared/components/AdditionalStatsContentContainer';
import AdditionalStatsPage from '../AdditionalStatsPage';

const LargestUSIPOs: React.FC = () => {
  return (
    <AdditionalStatsPage>
      <AdditionalStatsContentContainer title="Largest US IPOs">
        <StatsElement type={StatsElementType.LargestIPOsUs} />
      </AdditionalStatsContentContainer>
    </AdditionalStatsPage>
  );
};

export default LargestUSIPOs;
