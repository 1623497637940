import { GridColumn } from '../../shared/interfaces/GridColumn';
import { CSSProperties } from 'react';

export class SimpleGridCellFactory {
  static getStyle(column: GridColumn, isHeader: boolean, isAutoCellWidth?: boolean): CSSProperties {
    const style: CSSProperties = {};

    if (!isAutoCellWidth) {
      style.minWidth = column.minWidth;
      style.maxWidth = column.maxWidth;
      style.width = column.isAutoWidth ? 'auto' : column.width;
    }

    if (column.isEllipsis && !isHeader) {
      style.overflow = 'hidden';
      style.whiteSpace = 'nowrap';
      style.textOverflow = 'ellipsis';
    }

    return style;
  }
}
