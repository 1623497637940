import React, { ReactNode } from 'react';
import { useClassName } from '../../hooks/useClassName';
import { ConfirmationActionModalBlock } from './confirmationActionModalBem';
import ActionModal from './ActionModal';
import { NotificationSuccessMessage } from '../../../store/notification/types';
import { ModalShowState } from './types/ModalShowState';
import { FormProps } from '../forms/Form';

interface Props {
  className?: string;
  showState: ModalShowState;
  header: React.ReactNode;
  bodyAction: React.ReactNode;
  successMessage?: NotificationSuccessMessage;
  modalTrigger?: ReactNode;
  form?: FormProps;
  onConfirm: () => Promise<void>;
  onHide?: () => void;
}

const ConfirmationActionModal: React.FC<Props> = props => {
  const cn = useClassName(ConfirmationActionModalBlock.Root, props.className);

  return (
    <ActionModal
      className={cn()}
      header={props.header}
      body={<div>Are you sure to {props.bodyAction}?</div>}
      form={props.form}
      action="Yes"
      showState={props.showState}
      successMessage={props.successMessage}
      onAction={props.onConfirm}
      onHide={props.onHide}
    />
  );
};

export default ConfirmationActionModal;
