import React from 'react';
import './Reference.scss';
import { useClassName } from '../../hooks/useClassName';
import { ReferenceBem } from './referenceBem';

const Reference: React.FC = props => {
  const cn = useClassName(ReferenceBem.Root);

  return <sup className={cn()}>{props.children}</sup>;
};

export default Reference;
