import { ViewColumnSystemName } from '../enums/ViewColumnSystemName';
import { GridAgColumn } from '../../../../../components/grid/grid/interfaces/GridAgColumn';
import { GridCellFormatterType } from '../../../../../components/grid/shared/enums/GridCellFormatterType';
import CompanyCell from '../components/cells/CompanyCell';
import CompanyWithDescriptionCell from '../components/cells/CompanyWithDescriptionCell';
import { ViewGridColumnWidth } from '../enums/ViewGridColumnWidth';

export const companyNameWithTitleEllipsis = {
  field: ViewColumnSystemName.CompanyName,
  isEllipsis: true,
  minWidth: ViewGridColumnWidth.Sm,
  width: ViewGridColumnWidth.Sm,
  cellFormatter: {
    type: GridCellFormatterType.Custom,
    props: {
      component: CompanyCell,
      isTitleEllipsis: true,
      isNameShort: false,
    },
  },
} as Partial<GridAgColumn>;

export const companyWithBusinessDescriptionFullDescription = {
  field: ViewColumnSystemName.CompanyWithDescription,
  cellFormatter: {
    type: GridCellFormatterType.Custom,
    props: {
      component: CompanyWithDescriptionCell,
      hasFullBusinessDescription: true,
    },
  },
} as Partial<GridAgColumn>;
