import { usePromiseState } from '../../shared/hooks/promise-state/usePromiseState';
import { NewsApiService } from '../../shared/api/NewsApiService';
import { useCallback } from 'react';
import { NewsFilterConfigDto } from '../interfaces/NewsFilterConfigDto';

export function useNewsFilterConfig(): [NewsFilterConfigDto, boolean] {
  return usePromiseState(
    useCallback(() => NewsApiService.getFilterConfig(), []),
    { sectorIndustry: [] },
  );
}
