import { FileExtension } from '../../enums/FileExtension';
import {
  GetAndDownloadFileOptions,
  GetAndDownloadOptionsByFn,
  GetAndDownloadOptionsByUrl,
} from './interfaces/GetAndDownloadFileOptions';
import { Nullable } from '../../types/generics';
import { HttpHeaderService } from '../http-header/HttpHeaderService';
import ApiService from '../../api/ApiService';

export class FileService {
  static async getAndDownload(options: GetAndDownloadFileOptions): Promise<void> {
    let getFileFn;

    if ((options as GetAndDownloadOptionsByUrl).url) {
      getFileFn = () => ApiService.getFile((options as GetAndDownloadOptionsByUrl).url);
    } else {
      getFileFn = () => (options as GetAndDownloadOptionsByFn).fn();
    }

    const data = await getFileFn();
    const contentDisposition = HttpHeaderService.parseContentDisposition(data.contentDisposition);
    const name = this.getName(contentDisposition?.fileName, options.name);
    const extension = this.getExtension(contentDisposition?.extension, options.extension);

    this.download(name, data.blob, extension);
  }

  static download(name: string, file: Blob, extension: Nullable<string>): void {
    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');
    const extensionSrt = extension ? `.${extension}` : '';

    a.href = url;
    a.download = `${name}${extensionSrt}`;
    a.click();
  }

  private static getName(headerFileName?: string, name?: string): string {
    if (name) {
      return name;
    } else if (headerFileName) {
      return headerFileName;
    } else {
      return 'file';
    }
  }

  private static getExtension(headerExtension?: FileExtension, extension?: FileExtension): FileExtension | null {
    if (extension) {
      return extension;
    } else if (headerExtension) {
      return headerExtension;
    } else {
      return null;
    }
  }
}
