import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import TextSelect, { TextSelectCommonProps } from './components/text-select/TextSelect';
import { CustomAny } from '../../types/generics';
import { SelectOption } from '../../services/select/interfaces/SelectOption';
import { SelectOptionValue } from '../../services/select/types/SelectOptionValue';
import { ViewFilterName } from '../../entities/filters/enum/types/ViewFilterName';
import { useCurrentRoutePath } from '../../hooks/useCurrentRoutePath';
import { DISABLED_HEADQUARTERS_ROUTES } from '../../../stats/additional-stats/constants/disabledHeadquartersRoutes';

export interface FormTextSelectProps extends TextSelectCommonProps {
  name: string;
}

const FormTextSelect: React.FC<FormTextSelectProps> = props => {
  const { register, setValue, watch } = useFormContext();
  const value: SelectOptionValue = watch && watch(props.name);
  const currentRoutePath = useCurrentRoutePath();
  useEffect(() => {
    register?.({ name: props.name });
    if (localStorage.getItem(`pref-${props.name}`)) {
      if (
        !(
          props.name === ViewFilterName.Headquarter &&
          Object.keys(DISABLED_HEADQUARTERS_ROUTES).indexOf(currentRoutePath) > -1
        )
      ) {
        setValue(props.name, localStorage.getItem(`pref-${props.name}`));
      }
    }
  }, [register, props.name]);

  const onChangeValue = useCallback(
    (value: CustomAny) => {
      if (props.name === ViewFilterName.Headquarter) {
        localStorage.setItem(`pref-${ViewFilterName.Headquarter}`, value);
      }

      setValue?.(props.name, value);
    },
    [props.name, setValue],
  );

  return (
    <TextSelect value={value} options={props.options as SelectOption[]} onChangeValue={onChangeValue} {...props} />
  );
};

export default FormTextSelect;
