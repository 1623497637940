import React from 'react';
import './NewsRecapScheduled.scss';
import { NewsRecapScheduledDto } from '../interfaces/NewsRecapScheduledDto';
import { useClassName } from '../../shared/hooks/useClassName';
import DescriptionList, { DescriptionListItem } from '../../shared/components/DescriptionList';
import CompanyLink from '../../company/components/company-link/CompanyLink';

interface Props {
  news: NewsRecapScheduledDto[];
}

const NewsRecapScheduled: React.FC<Props> = props => {
  const cn = useClassName('NewsRecapScheduled');
  return (
    <ul className={cn()}>
      {props.news ? (
        props.news.map((news: NewsRecapScheduledDto, index: number) => {
          const items: DescriptionListItem[] = [
            { term: 'Price Range', definition: news.priceRange },
            { term: 'Deal Size', definition: news.dealSize },
            { term: 'Underwriter', definition: news.underwriterName },
          ];

          return (
            <li className={cn('item')} key={index}>
              <CompanyLink className={cn('link')} ticker={news.tickerSymbol} visibleValue={news.companyName} />

              <DescriptionList items={items} />
            </li>
          );
        })
      ) : (
        <div className={cn('item')}>Nothing here today</div>
      )}
    </ul>
  );
};

export default NewsRecapScheduled;
