import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import DateSelect, { DateSelectProps } from './DateSelect';
import { DateSelectValue } from './types/DateSelectValue';

export interface FormDateSelectProps extends Partial<DateSelectProps> {
  name: string;
}

const FormDateSelect: React.FC<FormDateSelectProps> = props => {
  const { register, setValue, watch } = useFormContext();
  const value: DateSelectValue = watch && watch(props.name);

  useEffect(() => register({ name: props.name }), [register, props.name]);

  const onChangeValue = useCallback(
    (value: DateSelectValue) => {
      setValue(props.name, value);
    },
    [props.name, setValue],
  );

  return <DateSelect value={value} onChangeValue={onChangeValue} {...props} />;
};

export default FormDateSelect;
