import React from 'react';
import { DashboardWidgetComponent } from '../../interfaces/DashboardWidgetComponent';
import { ViewDataTable } from '../../../shared/entities/view/components/view/interfaces/api/ViewDataTable';
import DashboardTable from './shared/DashboardTable';

const DashboardRecentResearchWidget: DashboardWidgetComponent<ViewDataTable> = props => {
  return <DashboardTable width={props.dimensions?.width} dataTable={props.data} pinTickerColumn />;
};

export default DashboardRecentResearchWidget;
