import Highcharts from 'highcharts';
import { rangeSelectorButtons } from '../../../../../shared/components/chart/constants/rangeSelectorButtons';
import { RangeSelectorButtonType } from '../../../../../shared/components/chart/enums/RangeSelectorButtonType';
import { labelsPercentageFormatter } from '../../../../../shared/components/chart/constants/formatters';
// import { HtmlString } from '../../../../../shared/types/HtmlString';
// import { ChartTooltipFormatterService } from '../../../../../shared/components/chart/services/ChartTooltipFormatterService';
// import { CustomAny } from '../../../../../shared/types/generics';
// import { DateHelper } from '../../../../../shared/helpers/date/DateHelper';
// import { NumberHelper } from '../../../../../shared/helpers/NumberHelper';
// import { NumberFormat } from '../../../../../shared/enums/NumberFormat';
import { ChartDateFormat } from '../../../../../shared/components/chart/enums/ChartDateFormat';

export const pricedCompanyPerfomanceVsIpoIndexChartOptions: Highcharts.Options = {
  exporting: {
    buttons: {
      contextButton: {
        y: -10,
      },
    },
  },
  rangeSelector: {
    buttons: [
      rangeSelectorButtons[RangeSelectorButtonType.Day],
      rangeSelectorButtons[RangeSelectorButtonType.Month],
      rangeSelectorButtons[RangeSelectorButtonType.ThreeMonth],
      rangeSelectorButtons[RangeSelectorButtonType.YTD],
      rangeSelectorButtons[RangeSelectorButtonType.Year],
      rangeSelectorButtons[RangeSelectorButtonType.All],
    ],
    buttonPosition: {
      align: 'right',
    },
    allButtonsEnabled: true,
    inputEnabled: false,
  },
  yAxis: {
    opposite: true,
    labels: {
      formatter: labelsPercentageFormatter,
    },
  },
  xAxis: {
    type: 'datetime',
  },
  plotOptions: {
    series: {
      compare: 'percent',
    },
  },
  tooltip: {
    // Taken from the IPO Pro site
    // Custom tooltip formatter is not return {point.change} property
    pointFormat:
      '<span class="highcharts-color-{point.colorIndex}"> ● </span>{series.name}: <b>{point.y}</b> ({point.change:.1f}%)<br/>',
    valueDecimals: 2,
    split: false,
    xDateFormat: ChartDateFormat.DayOfWeekMonthShortDayYear,
  },
  // tooltip: {
  //   split: false,
  //   formatter(): HtmlString {
  //     return ChartTooltipFormatterService.getTooltip({
  //       points: this.points as CustomAny[],
  //       title: DateHelper.formatNumberDate(this.x),
  //       formatter: value =>
  //         NumberHelper.getFormatValue(NumberHelper.percentageToNumber(value), NumberFormat.PercentageFloat),
  //     });
  //   },
  //   shared: true,
  // },
};
