import React from 'react';
import Modal, { ModalProps, ModalSize } from '../../../../../../components/modal/Modal';
import ModalRow from '../../../../../../components/modal/ModalRow';
import ViewShareLinkForm from './form/ViewShareLinkForm';
import ViewShareLinks from './ViewShareLinks';

interface Props extends ModalProps {
  onAction: () => void;
}

const ViewShareLinkModal: React.FC<Props> = props => {
  return (
    <Modal
      size={ModalSize.Large}
      header={<></>}
      body={
        <>
          <ModalRow header="Create and share link" content={<ViewShareLinkForm onShareLink={props.onAction} />} />
          <ModalRow header="Shared links" content={<ViewShareLinks onAction={props.onAction} />} />
        </>
      }
      {...props}
    />
  );
};

export default ViewShareLinkModal;
