import { RoutePath } from '../../enums/RoutePath';

export const screensPaths = [
  RoutePath.ScreensAdd,
  RoutePath.ScreensUnderwriterLanding,
  RoutePath.ScreensUnderwriterInUse,
  RoutePath.ScreensIndustryLanding,
  RoutePath.ScreensIndustryInUse,
  RoutePath.ScreensLocationLanding,
  RoutePath.ScreensLocationInUse,
  RoutePath.ScreensInvestorsLanding,
  RoutePath.ScreensInvestorsInUse,
  RoutePath.ScreensEstimatesInUse,
  RoutePath.ScreensCustomInUse,
  RoutePath.ScreensExpiredLockUpsInUse,
  RoutePath.ScreensLockUpsInUse,
  RoutePath.ScreensWithdrawnPostponedInUse,
  RoutePath.ScreensSpacsLanding,
];
