import React from 'react';
import './FilerControlChildren.scss';

interface Props {
  hasChildren?: boolean;
}

const FilerControlChildren: React.FC<Props> = props => {
  return <>{props.hasChildren && <div className="FilerControlChildren">{props.children}</div>}</>;
};

export default FilerControlChildren;
