import React, { MouseEventHandler, TouchEventHandler, useMemo } from 'react';
import classNames from 'classnames';
import './Button.scss';
import { ButtonType } from '../../enums/ButtonType';
import { ColorScheme } from '../../enums/ColorScheme';
import { ControlSize } from '../../enums/ControlSize';
import { Link } from 'react-router-dom';
import Loader from '../loader/Loader';
import { ButtonLinkProps } from './interfaces/ButtonLinkProps';

export interface ButtonProps {
  type?: ButtonType;
  colorScheme?: ColorScheme;
  block?: boolean;
  isOutline?: boolean;
  isDisabled?: boolean;
  size?: ControlSize;
  className?: string;
  isLoading?: boolean;
  buttonLink?: ButtonLinkProps;
  isAutoSize?: boolean;
  isSimple?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
  onMouseDown?: MouseEventHandler;
  onTouchStart?: TouchEventHandler;
}

const Button: React.FC<ButtonProps> = props => {
  const isSimple = useMemo(() => !props.colorScheme || props.isSimple, [props.colorScheme, props.isSimple]);

  const { isOutline, colorScheme, isAutoSize = isSimple } = props;

  const content = useMemo<React.ReactNode>(() => (props.isLoading ? <Loader /> : props.children), [
    props.isLoading,
    props.children,
  ]);

  const getColorSchemeClassNames = (className: string, scheme: ColorScheme): { [className: string]: boolean } => ({
    [`btn-${className}`]: colorScheme === scheme && !isOutline && !isSimple,
    [`btn-outline-${className}`]: colorScheme === scheme && !!isOutline && !isSimple,
  });

  const getClassNames = (): string =>
    classNames('Button btn', props.className, {
      ...getColorSchemeClassNames('primary', ColorScheme.Primary),
      ...getColorSchemeClassNames('attention', ColorScheme.Attention),
      ...getColorSchemeClassNames('success', ColorScheme.Success),
      ...getColorSchemeClassNames('light-gray', ColorScheme.LightGray),
      ...getColorSchemeClassNames('secondary', ColorScheme.Secondary),
      ...getColorSchemeClassNames('info', ColorScheme.Info),

      'btn-sm': props.size === ControlSize.Small,
      'btn-lg': props.size === ControlSize.Large,

      'btn-block': props.block,

      'Button--simple': isSimple,

      'Button--loading': props.isLoading,

      'Button--autosize': isAutoSize,
    });

  return (
    <>
      {props.buttonLink ? (
        <Link
          to={props.buttonLink.routePath}
          className={getClassNames()}
          onClick={props.onClick}
          target={props.buttonLink.isOpenInNewTab ? '_blank' : ''}
        >
          {content}
        </Link>
      ) : (
        <button
          className={getClassNames()}
          type={props.type}
          disabled={props.isDisabled}
          onClick={props.onClick}
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
        >
          {content}
        </button>
      )}
    </>
  );
};

export default Button;
