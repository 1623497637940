import React from 'react';
import { FilterSettings } from '../../interfaces/FilterSettings';
import { AnyObject } from '../../../../types/generics';
import { ViewFiltersDictionaries } from '../../interfaces/ViewFiltersDictionaries';
import { ViewAppliedFilters } from '../../../view/components/view/interfaces/ViewAppliedFilters';
import { useFilterSectionsFormContext } from './context/useFilterSectionsFormContext';
import { FilterSectionsFormContext } from './context/FilterSectionsFormContext';
import FilterSectionsForm from './FilterSectionsForm';
import { FormValidationStateChangeFn } from '../../../../components/forms/types/FormValidationStateChangeFn';

export interface FilterSectionsProps {
  settings: FilterSettings;
  defaultValues?: AnyObject;
  dictionaries?: Partial<ViewFiltersDictionaries>;
  isAdvanced?: boolean;
  onChange?: (filters: ViewAppliedFilters) => void;
  onValidationStateChange?: FormValidationStateChangeFn;
}

const FilterSections: React.FC<FilterSectionsProps> = props => {
  return (
    <FilterSectionsFormContext.Provider value={useFilterSectionsFormContext(props)}>
      <FilterSectionsForm />
    </FilterSectionsFormContext.Provider>
  );
};

export default FilterSections;
