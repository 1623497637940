import * as yup from 'yup';
import { StringSchema } from 'yup';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH, PASSWORD_REGEX } from '../../../../../../constants/validation';

export const getPasswordSchema = (label: string): StringSchema =>
  yup
    .string()
    .min(MIN_PASSWORD_LENGTH)
    .max(MAX_PASSWORD_LENGTH)
    .matches(
      PASSWORD_REGEX,
      'Password should have at least one upper case letter, one digit, and one special character',
    )
    .required()
    .label(label);
