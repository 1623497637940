import { QuestionTypeOption } from '../../../shared/enums/QuestionTypeOption';
import { SelectOption } from '../../../shared/services/select/interfaces/SelectOption';

export const QUESTION_TYPE_OPTIONS: SelectOption[] = [
  {
    title: QuestionTypeOption.SpecificIPO,
    value: QuestionTypeOption.SpecificIPO,
  },
  {
    title: QuestionTypeOption.GeneralIPOData,
    value: QuestionTypeOption.GeneralIPOData,
  },
  {
    title: QuestionTypeOption.MyAccount,
    value: QuestionTypeOption.MyAccount,
  },
  {
    title: QuestionTypeOption.HowToUseIPOIntelligence,
    value: QuestionTypeOption.HowToUseIPOIntelligence,
  },
  {
    title: QuestionTypeOption.SiteError,
    value: QuestionTypeOption.SiteError,
  },
  {
    title: QuestionTypeOption.Other,
    value: QuestionTypeOption.Other,
  },
];
