import React, { ReactNode, useCallback, useMemo } from 'react';
import './PopoverOverlay.scss';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import uniqueId from 'lodash.uniqueid';
import { useClassName } from '../hooks/useClassName';

interface Props {
  trigger: ReactNode;
  isWithModal?: boolean;
  isBelowAll?: boolean;
  isCloseOnContentClick?: boolean;
}

const PopoverOverlay: React.FC<Props> = props => {
  const cn = useClassName('PopoverOverlay');

  const id = useMemo(() => uniqueId('PopoverOverlay-'), []);

  const onContentClick = useCallback(() => {
    if (props.isCloseOnContentClick) {
      document.body.click();
    }
  }, [props.isCloseOnContentClick]);

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose={true}
      overlay={
        <Popover
          id={id}
          className={cn({
            withModal: props.isWithModal,
            belowAll: props.isBelowAll,
          })}
        >
          <Popover.Content onClick={onContentClick}>{props.children}</Popover.Content>
        </Popover>
      }
    >
      {props.trigger}
    </OverlayTrigger>
  );
};

export default PopoverOverlay;
