import React from 'react';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import { useClassName } from '../../../../../../hooks/useClassName';
import { InvestorsCellBlock } from './InvestorsCellBem';
import useBreakpointAvailable from '../../../../../../effects/useBreakpointAvailable';
import { profileMobileBreakpoints } from '../../../../../../../company/components/profile/shared/constants/profileMobileBreakpoints';
import PcmlTooltipExpander from '../../../../../../components/expander/PcmlTooltipExpander';

type Props = GridCellFormatterCommonProps;

const expandingLength = 2;

const PcmlInvestorsCell: React.FC<Props> = props => {
  const cn = useClassName(InvestorsCellBlock.Root);
  const isMobile = useBreakpointAvailable(profileMobileBreakpoints);
  const investorsLong = props.value?.split('|');
  const investorsShort: string[] = [];
  if (investorsLong) {
    investorsLong.forEach((element: string) => {
      if (element.length > 11) {
        investorsShort.push(element.substring(0, 11) + '...');
      } else {
        investorsShort.push(element);
      }
    });
  }

  return (
    <>
      {investorsShort ? (
        <CustomCellFormatterWrapper className={cn()} value={investorsShort[0]}>
          {!isMobile && (
            <PcmlTooltipExpander
              expandData={investorsShort}
              ttData={investorsLong}
              expandingLength={expandingLength}
              isUnderFixed
            />
          )}
          {isMobile && investorsShort[0]}
        </CustomCellFormatterWrapper>
      ) : (
        props.value
      )}
    </>
  );
};

export default PcmlInvestorsCell;
