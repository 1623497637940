import React, { useCallback, useMemo } from 'react';
import './DashboardIPOsOnMoveWidgetToolbar.scss';
import { NewsRcExternalFilterField } from '../../../../news/shared/components/news-rc-external-filter/form/NewsRcExternalFilterField';
import Form from '../../../../shared/components/forms/Form';
import FormGroup from '../../../../shared/components/forms/FormGroup';
import { ControlDivider } from '../../../../shared/components/forms/services/control-divider/enums/ControlDivider';
import { ControlSize } from '../../../../shared/enums/ControlSize';
import { DashboardIPOsOnMoveWidgetFilterField } from './form/DashboardIPOsOnMoveWidgetFilterField';
import { TimeFrame } from '../../../../shared/entities/filters/enum/TimeFrame';
import FormTimeFrameSelect from '../../../../shared/components/forms/FormTimeFrameSelect';
import { DashboardIPOsOnMoveTimeFrame } from './types/DashboardIPOsOnMoveTimeFrame';

interface Props {
  className?: string;
  onFilter: (timeFrame: DashboardIPOsOnMoveTimeFrame) => void;
  widgetWidth?: number;
}

// const timeFrames: DashboardIPOsOnMoveTimeFrame[] = [TimeFrame.Day, TimeFrame.Week, TimeFrame.Month];

const DashboardIPOsOnMoveWidgetToolbar: React.FC<Props> = props => {
  const { onFilter } = props;

  const timeFrames: DashboardIPOsOnMoveTimeFrame[] =
    props.widgetWidth && props.widgetWidth < 350
      ? [TimeFrame.Day, TimeFrame.altWeek, TimeFrame.altMonth]
      : [TimeFrame.Day, TimeFrame.Week, TimeFrame.Month];

  const defaultValues = useMemo(
    () => ({
      [DashboardIPOsOnMoveWidgetFilterField.Type]: TimeFrame.Day,
    }),
    [],
  );

  const onChange = useCallback(
    formData => {
      onFilter(formData[DashboardIPOsOnMoveWidgetFilterField.Type]);
    },
    [onFilter],
  );

  return (
    <Form className={props.className} defaultValues={defaultValues} changeOnInit onChange={onChange}>
      <FormGroup>
        <FormTimeFrameSelect
          timeFrameName={NewsRcExternalFilterField.Type}
          timeFrames={timeFrames}
          timeFrameDivider={ControlDivider.Slash}
          hasDateRange={false}
          hideDateRange={true}
          size={ControlSize.Small}
        />
      </FormGroup>
    </Form>
  );
};

export default DashboardIPOsOnMoveWidgetToolbar;
