import { ViewColumnSystemName } from '../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';

export const researchGridCustomColumnsSettings = [
  {
    field: ViewColumnSystemName.UnderwriterName,
    flex: 1,
  },
  {
    field: ViewColumnSystemName.Exchange,
    flex: 1,
  },
];
