import React, { useMemo } from 'react';
import View from '../../../../../../shared/entities/view/components/view/View';
import { ViewTypeScreenInUse } from '../../../../../../shared/entities/view/components/view/types/screen/ViewTypeScreenInUse';
import { ScreenOptionsService } from '../../services/ScreenOptionsService';
import { screenInUseSharedOptions } from './constants/screenInUseSharedOptions';
import { ScreenOptions } from '../../interfaces/ScreenOptions';
import { ViewEvents } from '../../../../../../shared/entities/view/components/view/interfaces/options/ViewEvents';

interface Props {
  inUseType: ViewTypeScreenInUse;
  options: Partial<ScreenOptions>;
  events?: ViewEvents;
  viewId?: number;
}

const ScreensInUse: React.FC<Props> = props => {
  const options = useMemo(() => ScreenOptionsService.get(props.inUseType, props.options, screenInUseSharedOptions), [
    props.inUseType,
    props.options,
  ]);

  return <View viewId={props.viewId} viewType={props.inUseType} options={options} events={props.events} />;
};

export default ScreensInUse;
