import React from 'react';
import './SimpleGridRowNoData.scss';
import { noGridData } from '../../shared/constants/grid';
import { useClassName } from '../../../../hooks/useClassName';
import { SimpleGridRowNoDataBlock } from './SimpleGridRowNoDataBem';

interface Props {
  columnsCount: number;
  text?: string;
}

const SimpleGridRowNoData: React.FC<Props> = props => {
  const { text = noGridData } = props;

  const cn = useClassName(SimpleGridRowNoDataBlock.Root);

  return (
    <tr className={cn()}>
      <td colSpan={props.columnsCount}>{text}</td>
    </tr>
  );
};

export default SimpleGridRowNoData;
