import { Reducer } from 'react';
import {
  ActionTypes,
  SET_GRID_PUBLIC_API,
  SET_VIEW_ADDITIONAL_DATA,
  SET_VIEW_COLUMNS_SETTINGS,
  SET_VIEW_DATA,
  SET_VIEW_ERROR,
  SET_VIEW_FILTERS,
  SET_VIEW_IS_SOCKET_WORK,
  SET_VIEW_LOADING,
  SET_VIEW_OPTIONS,
  SET_VIEW_SAVED_VIEWS,
  SET_VIEW_SELECTED_COLUMNS,
  SET_VIEW_SHARED_LINKS,
  SET_VIEW_SORT,
  SET_VIEW_SUCCESS,
  ViewState,
} from './types';
import { ViewType } from '../enums/ViewType';
import { ViewId } from '../types/ViewId';
import { ViewOptions } from '../interfaces/options/ViewOptions';
import { ViewFilterName } from '../../../../filters/enum/types/ViewFilterName';
import { Location } from '../../../../filters/enum/Location';
import { SocketService } from '../../../../../services/socket/SocketService';
import { SocketEvent } from '../socket/enums/SocketEvent';
import { SocketAction } from '../socket/enums/SocketAction';
import { socketRoutePath } from '../socket/constants/socketRoutePath';
import { ViewSummaryField } from '../enums/ViewSummaryField';
import { ViewTypeService } from '../services/ViewTypeService';
import { ScreensLocationFilterName } from '../../../../../../screens/components/screens/location/enums/ScreensLocationFilterName';

export const createInitialState = (viewType: ViewType, viewId: ViewId, options: ViewOptions): ViewState => {
  return {
    gridPublicApi: null,
    viewData: null,
    socket: new SocketService<SocketEvent, SocketAction>({
      routePath: socketRoutePath,
    }),
    isSocketWork: false,
    rows: undefined,
    columnsGroups: undefined,
    filters: {},
    selectedColumns: {
      web: [],
      excel: [],
    },
    defaultFilters: {},
    savedViews: [],
    sharedLinks: [],
    summary: {
      [ViewSummaryField.Companies]: null,
      [ViewSummaryField.Proceeds]: null,
      [ViewSummaryField.PercentTradingAboveIPOPrice]: null,
      [ViewSummaryField.AvgPriceVsMidpoint]: null,
      [ViewSummaryField.AvgFirstDayPop]: null,
      [ViewSummaryField.AvgAftermarketReturn]: null,
      [ViewSummaryField.AvgReturnFromIPO]: null,
      [ViewSummaryField.AvgFirstThreeMo]: null,
      [ViewSummaryField.AvgIpoDiscount]: null,
    },
    notes: [],
    footnote: undefined,
    isLoading: false,
    error: undefined,
    success: undefined,
    sort: null,
    viewId,
    viewType,
    isScreen: ViewTypeService.isScreen(viewType),
    options,
    filtersDictionaries: {
      [ViewFilterName.LocationExchange]: [],
      [ViewFilterName.LocationExchangeList]: [],
      [ViewFilterName.ExchangeCountry]: [],
      [ViewFilterName.ExchangeRegion]: [],
      [ViewFilterName.Investor]: [],
      [ViewFilterName.SectorIndustry]: [],
      [ViewFilterName.Underwriter]: [],
      [ViewFilterName.UnderwriterKeyList]: [],
      [ViewFilterName.UnderwriterTypeKey]: [],
      [ViewFilterName.HeadquartersCityStateCountry]: {
        [Location.Global]: [],
        [Location.NonUS]: [],
        [Location.US]: [],
      },
      [ViewFilterName.InitialPerformancePeriod]: [],
      [ViewFilterName.RecentPerformancePeriod]: [],
      [ViewFilterName.EstimatedTiming]: [],
      [ScreensLocationFilterName.ListingLocation]: {},
    },
    additionalData: null,
    columnsOptions: undefined,
  };
};

export const reducer: Reducer<ViewState, ActionTypes> = (state, action) => {
  switch (action.type) {
    case SET_VIEW_OPTIONS:
      return {
        ...state,
        options: action.payload.options,
      };
    case SET_GRID_PUBLIC_API:
      return {
        ...state,
        gridPublicApi: action.payload.gridPublicApi,
      };
    case SET_VIEW_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        error: undefined,
      };
    case SET_VIEW_DATA:
      return {
        ...state,
        viewData: action.payload.viewData,
        rows: action.payload.rows,
        columnsGroups: action.payload.columnsGroups,
        filters: action.payload.filters,
        selectedColumns: action.payload.selectedColumns,
        defaultFilters: action.payload.defaultFilters,
        savedViews: action.payload.savedViews,
        sharedLinks: action.payload.sharedLinks,
        summary: action.payload.summary,
        notes: action.payload.notes,
        footnote: action.payload.footnote,
        viewId: action.payload.viewData.id,
        sort: action.payload.sort,
        filtersDictionaries: action.payload.filtersDictionaries,
      };
    case SET_VIEW_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case SET_VIEW_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
      };
    case SET_VIEW_SORT:
      return {
        ...state,
        sort: action.payload.sort,
      };
    case SET_VIEW_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
      };
    case SET_VIEW_SELECTED_COLUMNS:
      return {
        ...state,
        selectedColumns: action.payload.selectedColumns,
      };
    case SET_VIEW_SAVED_VIEWS:
      return {
        ...state,
        savedViews: action.payload.savedViews,
      };
    case SET_VIEW_SHARED_LINKS:
      return {
        ...state,
        sharedLinks: action.payload.sharedLinks,
      };
    case SET_VIEW_ADDITIONAL_DATA: {
      return {
        ...state,
        additionalData: { ...action.payload },
      };
    }
    case SET_VIEW_COLUMNS_SETTINGS: {
      return {
        ...state,
        columnsOptions: action.payload.columnsOptions,
      };
    }
    case SET_VIEW_IS_SOCKET_WORK: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
