import { ViewOptions } from '../../../shared/entities/view/components/view/interfaces/options/ViewOptions';
import { FilterSectionType } from '../../../shared/entities/filters/enum/FilterSectionType';
import { ViewFilterName } from '../../../shared/entities/filters/enum/types/ViewFilterName';
import { FileActionType } from '../../../shared/components/file-actions/enums/FileActionType';
import { ViewSummaryAlignType } from '../../../shared/entities/view/components/view/enums/ViewSummaryAlignType';
import { ViewColumnSystemName } from '../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { ViewGridColumnWidth } from '../../../shared/entities/view/components/view-grid/enums/ViewGridColumnWidth';

export const pipelineViewOptions: ViewOptions = {
  header: 'IPO Pipeline',
  toolbar: {
    modalFilters: {
      sections: [
        {
          type: FilterSectionType.DealTiming,
          controls: [
            {
              name: ViewFilterName._DealTimingFiledRange,
            },
            {
              name: ViewFilterName.DealTimingUpdatedInPast,
            },
          ],
        },
        {
          type: FilterSectionType.CompanyInfo,
          controls: [{ name: ViewFilterName.SectorIndustry }, { name: ViewFilterName._Headquarters }],
        },
        {
          type: FilterSectionType.Investors,
          controls: [
            { name: ViewFilterName.Investor },
            { name: ViewFilterName.SpinOff },
            { name: ViewFilterName.MgmtOwned },
            { name: ViewFilterName.PE },
            { name: ViewFilterName.PeGrowthOther },
            { name: ViewFilterName.VentureCapital },
          ],
        },
        {
          type: FilterSectionType.SecurityType,
          controls: [
            { name: ViewFilterName.SecurityTypeCommonSharesList },
            { name: ViewFilterName.SecurityTypeADRsList },
            { name: ViewFilterName.SecurityTypeREITList },
            { name: ViewFilterName.SecurityTypeMLPList },
            { name: ViewFilterName.SecurityTypeBlankCheckList },
            { name: ViewFilterName.SecurityTypeRegAList },
            { name: ViewFilterName.SecurityTypeDirectListingList },
          ],
        },
        {
          type: FilterSectionType.Location,
          controls: [
            { name: ViewFilterName.LocationListingLocation },
            { name: ViewFilterName.ExchangeCountry },
            { name: ViewFilterName.ExchangeRegion },
            { name: ViewFilterName.IncludeChina },
            { name: ViewFilterName.LocationExchangeList },
          ],
        },
        {
          type: FilterSectionType.DealInfo,
          controls: [
            { name: ViewFilterName._DealSize },
            { name: ViewFilterName.UnderwriterKeyList },
            { name: ViewFilterName.UnderwriterRole },
            { name: ViewFilterName.InsiderBuying },
            { name: ViewFilterName.InsiderSelling },
            { name: ViewFilterName.InsiderSellingPct },
          ],
        },
        {
          type: FilterSectionType.Favorite,
          controls: [{ name: ViewFilterName.Favorite }],
        },
        {
          type: FilterSectionType.DealStatus,
          controls: [
            { name: ViewFilterName._DealStatusScheduledList },
            { name: ViewFilterName._DealStatusFiledTermsList },
            { name: ViewFilterName._DealStatusPostponedList },
            // { name: ViewFilterName.DealStatusWithdrawnAcquiredPostponed },
          ],
        },
        {
          type: FilterSectionType.RenaissanceRating,
          controls: [{ name: ViewFilterName.RcRating }],
        },
        {
          type: FilterSectionType.Financials,
          controls: [
            { name: ViewFilterName._RevenueGrowth },
            { name: ViewFilterName.RevenueGrowthPeriod },
            { name: ViewFilterName._LtmLatestSales },
            { name: ViewFilterName._LtmGrossMargin },
            { name: ViewFilterName._LtmEbitdaMargin },
            { name: ViewFilterName._LtmNetMargin },
            { name: ViewFilterName._FinancialsLTMFreeCashFlow },
            { name: ViewFilterName._IpoDebtToEbitda },
            { name: ViewFilterName.MostRecentFinancialsDateMin },
            { name: ViewFilterName.Dividend },
          ],
        },
      ],
    },
    fileActionTypes: [FileActionType.Save, FileActionType.Excel, FileActionType.Print],
    quickFilter: {
      hasLocation: true,
      hasDateRange: true,
      hasIncludeSPACtextFilter: true,
    },
  },
  apiFooter: true,
  grid: {
    idCols: [ViewColumnSystemName.CompanyKey],
    isFixedHeight: false,
    customColumnsSettings: [
      {
        field: ViewColumnSystemName.CompanyWithDescription,
        heightMultiplier: 1.314,
      },
      {
        field: ViewColumnSystemName.DealSize,
        width: ViewGridColumnWidth.Sm,
        minWidth: ViewGridColumnWidth.Sm,
      },
      {
        field: ViewColumnSystemName.LastUpdateDaysPipeline,
        width: ViewGridColumnWidth.Sm,
        minWidth: ViewGridColumnWidth.Sm,
      },
    ],
  },
  summary: {
    align: ViewSummaryAlignType.Simple,
  },
};
