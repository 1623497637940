import React from 'react';
import { useClassName } from '../../../../../../hooks/useClassName';
import { UnderwritersTooltipBlock } from './UnderwritersCellBem';
import { UnderwritersTooltipDto } from '../../../../../../../../src/company/interfaces/UnderwritersTooltipDto';

const UnderwritersCell: React.FC<UnderwritersTooltipDto> = props => {
  const cn = useClassName(UnderwritersTooltipBlock.Root);
  const { data } = props;

  return (
    <div className={cn()}>
      <table>
        <thead>
          <td>
            <b>Underwriters</b>
          </td>
          <td />
        </thead>
        <tbody>
          {data?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.underwriterName}</td>
                <td>{item.underwriterRole}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UnderwritersCell;
