import { StatsElementSimpleGridViewTriggerCellViewLinkProps } from '../../../../../shared/entities/stats-element/components/simple-grid/components/cells/interfaces/StatsElementSimpleGridViewTriggerCellViewLinkProps';
import { GridRow } from '../../../../../shared/components/grid/shared/types/GridRow';
import { StatsElementDataFilterType } from '../../../../../shared/entities/stats-element/types/StatsElementDataFilterType';
import { ViewType } from '../../../../../shared/entities/view/components/view/enums/ViewType';
import { ViewFilterName } from '../../../../../shared/entities/filters/enum/types/ViewFilterName';
import { ViewGridCellValueService } from '../../../../../shared/entities/view/components/view-grid/services/ViewGridCellValueService';
import { ViewColumnSystemName } from '../../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { StatsElementFilterInternalName } from '../../../../../shared/entities/stats-element/enums/StatsElementGridFilterName';
import { ProfitableVsUnprofitableIpoTypeColumnValue } from '../enums/ProfitableVsUnprofitableIpoTypeColumnValue';
import {
  PROFITABLE_VS_UNPROFITABLE_IPO_FILTER_VALUE_MAX,
  PROFITABLE_VS_UNPROFITABLE_IPO_FILTER_VALUE_MIN,
} from '../constants/profitableVsUnprofitableIpoTypeFilterValues';
import { PROFITABLE_VS_UNPROFITABLE_AVAILABLE_LINK_FILTERS } from '../constants/profitableVsUnprofitableAvailableLinkFIlters';

export class ProfitableVsUnprofitableLinkFiltersFactory {
  static getProps(dataType: StatsElementDataFilterType): StatsElementSimpleGridViewTriggerCellViewLinkProps {
    const getCustom = (row: GridRow) => {
      const ipoType = this.getIpoType(row);

      let filterName;
      let filterValue;

      if (ipoType === ProfitableVsUnprofitableIpoTypeColumnValue.Profitable) {
        filterValue = PROFITABLE_VS_UNPROFITABLE_IPO_FILTER_VALUE_MIN;

        if (dataType === StatsElementFilterInternalName.ProfitableEbitda) {
          filterName = ViewFilterName.LtmEbitdaMarginMin;
        } else if (dataType === StatsElementFilterInternalName.ProfitableNetIncome) {
          filterName = ViewFilterName.LtmNetMarginMin;
        } else if (dataType === StatsElementFilterInternalName.ProfitableFreeCashFlow) {
          filterName = ViewFilterName.FinancialsLTMFreeCashFlowMin;
        }
      } else if (ipoType === ProfitableVsUnprofitableIpoTypeColumnValue.Unprofitable) {
        filterValue = PROFITABLE_VS_UNPROFITABLE_IPO_FILTER_VALUE_MAX;

        if (dataType === StatsElementFilterInternalName.ProfitableEbitda) {
          filterName = ViewFilterName.LtmEbitdaMarginMax;
        } else if (dataType === StatsElementFilterInternalName.ProfitableNetIncome) {
          filterName = ViewFilterName.LtmNetMarginMax;
        } else if (dataType === StatsElementFilterInternalName.ProfitableFreeCashFlow) {
          filterName = ViewFilterName.FinancialsLTMFreeCashFlowMax;
        }
      }

      return {
        [filterName as string]: filterValue,
      };
    };

    const getIsLinkAvailable = (row: GridRow) => {
      const ipoType = this.getIpoType(row);

      return this.isFilterAvailable(dataType, ipoType);
    };

    return {
      viewType: ViewType.Priced,
      startDateFilterName: ViewFilterName.OfferStartDate,
      endDateFilterName: ViewFilterName.OfferEndDate,
      optionsList: [
        {
          getCustom,
        },
      ],
      getIsLinkAvailable,
    };
  }

  private static isFilterAvailable(
    dataType: StatsElementDataFilterType,
    ipoType: ProfitableVsUnprofitableIpoTypeColumnValue,
  ): boolean {
    return (
      ipoType !== ProfitableVsUnprofitableIpoTypeColumnValue.Total &&
      PROFITABLE_VS_UNPROFITABLE_AVAILABLE_LINK_FILTERS.includes(dataType)
    );
  }

  private static getIpoType(row: GridRow): ProfitableVsUnprofitableIpoTypeColumnValue {
    return ViewGridCellValueService.getRaw(row, ViewColumnSystemName.StatsIpoType);
  }
}
