import React from 'react';
import './IconWithContent.scss';
import Icon, { IconProps } from './Icon';
import { useClassName } from '../../hooks/useClassName';
import { IconWithContentBlock, IconWithContentModifier } from './IconWithContentBem';

interface Props extends IconProps {
  isReversed?: boolean;
}

const IconWithContent: React.FC<Props> = props => {
  const cn = useClassName(IconWithContentBlock.Root, props.className);

  return (
    <div
      className={cn({
        [IconWithContentModifier.Reversed]: props.isReversed,
      })}
    >
      <Icon {...props} /> {props.children}
    </div>
  );
};

export default IconWithContent;
