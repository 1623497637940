import React from 'react';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import ColorNumberFormatter from '../../../../../../components/formatters/ColorNumberFormatter';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import { ViewGridCellValueService } from '../../services/ViewGridCellValueService';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';

type Props = GridCellFormatterCommonProps;
const PriceChangeCell: React.FC<Props> = props => {
  return (
    <CustomCellFormatterWrapper value={props.value}>
      <ColorNumberFormatter
        value={props.value}
        displayValue={ViewGridCellValueService.getVisible(props.data, ViewColumnSystemName.PriceChange)}
      />
    </CustomCellFormatterWrapper>
  );
};

export default PriceChangeCell;
