import { Reducer } from 'redux';
import { ActionTypes, ADD_FAVOURITE_COMPANY, REMOVE_FAVOURITE_COMPANY, SET_FAVOURITE_COMPANIES, State } from './types';
import { addFavouriteCompany, removeFavouriteCompany } from './helpers';

const initialState: State = {
  companies: [],
};

export const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case SET_FAVOURITE_COMPANIES:
      return {
        ...state,
        companies: action.payload.companies,
      };
    case ADD_FAVOURITE_COMPANY:
      return {
        ...state,
        companies: addFavouriteCompany(state.companies, action.payload.company),
      };
    case REMOVE_FAVOURITE_COMPANY:
      return {
        ...state,
        companies: removeFavouriteCompany(state.companies, action.payload.companyKey),
      };
    default:
      return state;
  }
};
