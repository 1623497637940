import React, { useContext, useMemo } from 'react';
import { FilterSectionsFormContext } from '../context/FilterSectionsFormContext';

const FilterSectionsAdvancedControls: React.FC = props => {
  const { isAdvanced, settings } = useContext(FilterSectionsFormContext);

  const hasAdvancedControls = useMemo(() => isAdvanced || !settings.hasAdvancedBtn, [
    isAdvanced,
    settings.hasAdvancedBtn,
  ]);

  return <>{hasAdvancedControls && props.children}</>;
};

export default FilterSectionsAdvancedControls;
