import React, { useContext, useMemo } from 'react';
import { CompanyContext } from '../../../../context/CompanyContext';
import CompanyProfileInsights from '../insights/CompanyProfileInsights';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';

const ProfileInsights: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const data = useMemo(() => profile.insights, [profile]);

  return <>{data && <CompanyProfileInsights {...data} />}</>;
};

export default ProfileInsights;
