import React from 'react';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import CompanyRating from '../../../../../../../company/components/company-rating/CompanyRating';
import { useClassName } from '../../../../../../../shared/hooks/useClassName';
import { ResearchRatingBlock } from './ResearchRatingBem';

type Props = GridCellFormatterCommonProps;

const ResearchRatingCell: React.FC<Props> = props => {
  const cn = useClassName(ResearchRatingBlock.Root);

  return (
    <CustomCellFormatterWrapper className={cn()} value={props.value}>
      <CompanyRating ratingValue={props.value} />
    </CustomCellFormatterWrapper>
  );
};

export default ResearchRatingCell;
