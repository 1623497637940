import React from 'react';
import FilterControl from '../FilterControl';
import { ViewFilterName } from '../../../../enum/types/ViewFilterName';

const OfferDateFilterSection: React.FC = () => {
  return (
    <>
      <FilterControl name={ViewFilterName._OfferDate} />
    </>
  );
};

export default OfferDateFilterSection;
