import React, { memo, useCallback, useEffect, useState } from 'react';
import { SelectOption } from '../../services/select/interfaces/SelectOption';
import { ControlSize } from '../../enums/ControlSize';
import FormInput from './FormInput';
import FormControlsList, { FormControlsListProps } from './FormControlsList';
import TextSelect from './components/text-select/TextSelect';
import { useFormContext } from 'react-hook-form';
import { SelectService } from '../../services/select/SelectService';
import { SelectOptionValue } from '../../services/select/types/SelectOptionValue';
import { useClassName } from '../../hooks/useClassName';
import { FormTextSelectWithInputBlock } from './FormTextSelectWithInputBem';
import { InputType } from './Input';

export interface FormTextSelectWithInputProps extends FormControlsListProps {
  name: string;
  textSelectOptions: SelectOption[];
  textSelectLabel?: string;
  inputPlaceholder?: string;
  inputType?: InputType;
  isDisabled?: boolean;
  size?: ControlSize;
}

const FormTextSelectWithInput: React.FC<FormTextSelectWithInputProps> = props => {
  const cn = useClassName(FormTextSelectWithInputBlock.Root);

  const { setValue, watch } = useFormContext();
  const inputValue = watch(props.name);

  const [textSelectValue, setTextSelectValue] = useState();

  const onChangeTextSelect = useCallback(
    (value: SelectOptionValue) => {
      setValue(props.name, value);
      setTextSelectValue(value);
    },
    [props.name, setValue],
  );

  useEffect(() => {
    const option = SelectService.getOptionByValue(props.textSelectOptions, inputValue);

    if (option) {
      setTextSelectValue(option.value);
    } else {
      setTextSelectValue(undefined);
    }
  }, [inputValue, props.textSelectOptions]);

  return (
    <FormControlsList isInline={props.isInline} className={cn()}>
      <TextSelect
        options={props.textSelectOptions}
        label={props.textSelectLabel}
        size={props.size}
        value={textSelectValue}
        onChangeValue={onChangeTextSelect}
      />

      <FormInput name={props.name} placeholder={props.inputPlaceholder} type={props.inputType} size={props.size} />
    </FormControlsList>
  );
};

export default memo(FormTextSelectWithInput);
