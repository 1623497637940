import { TimeFrame } from '../../../shared/entities/filters/enum/TimeFrame';
import { TimeFrameHelper } from '../../../shared/helpers/TimeFrameHelper';

export class ScheduledCalendarFilterService {
  static getStartDate(timeFrame: TimeFrame): Date {
    const displayTimeFrame: TimeFrame =
      timeFrame === TimeFrame.ThisWeek || timeFrame === TimeFrame.All ? TimeFrame.ThisWeek : TimeFrame.NextWeek;

    return TimeFrameHelper.getDateRangeByTimeFrame(displayTimeFrame)[0];
  }
}
