import { useCallback } from 'react';
import { HandleFnOptions } from './interfaces/HandleFnOptions';
import { CustomAny } from '../../types/generics';
import { store } from '../../../store';
import { handleAction } from '../../../store/notification/actions';

export function useHandleFn<T>(
  fn: (...args: CustomAny[]) => Promise<void>,
  options?: HandleFnOptions,
): (...args: CustomAny[]) => void {
  return useCallback(
    (...args: CustomAny[]) => {
      store.dispatch(handleAction(fn.bind(fn, ...args), options) as CustomAny);
    },
    [fn, options],
  );
}
