import React, { useCallback } from 'react';
import './Notificaion.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Notification as NotificationToastInterface } from './interfaces/Notification';
import NotificationToast from './NotificationToast';
import { removeNotification } from '../../../store/notification/actions';
import AnimatedList from '../animation/animated-list/AnimatedList';

const Notification: React.FC = () => {
  const dispatch = useDispatch();
  const notifications = useSelector<RootState, NotificationToastInterface[]>(state => state.notification.notifications);

  const onClose = useCallback(
    (id: string) => {
      dispatch(removeNotification(id));
    },
    [dispatch],
  );

  return (
    <div className="Notification">
      <AnimatedList>
        {notifications.map(notification => (
          <NotificationToast key={notification.id} {...notification} onClose={(): void => onClose(notification.id)} />
        ))}
      </AnimatedList>
    </div>
  );
};

export default Notification;
