import React, { useMemo } from 'react';
import { DateFormat } from '../helpers/date/enums/DateFormat';
import { DateHelper } from '../helpers/date/DateHelper';

interface Props {
  date: Date | string;
  format?: DateFormat;
}

const FormattedDate: React.FC<Props> = props => {
  const date = useMemo<string>(
    () =>
      typeof props.date === 'string'
        ? DateHelper.formatISODateStr(props.date, props.format)
        : DateHelper.formatDate(props.date, props.format),
    [props.date, props.format],
  );

  return <>{date}</>;
};

export default FormattedDate;
