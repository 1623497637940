import React, { useMemo } from 'react';
import { CompanyTemplateService } from '../../../company/services/CompanyTemplateService';
import { useClassName } from '../../../shared/hooks/useClassName';

interface Props {
  keywords?: string[];
  className?: string;
}

const NewsKeywords: React.FC<Props> = props => {
  const cn = useClassName('NewsKeywords', props.className);

  const keywordsVisible = useMemo(() => {
    if (!props.keywords) {
      return;
    }

    const text = props.keywords.join(', ');

    return CompanyTemplateService.getTextWithReactLinksByTicker(text);
  }, [props.keywords]);

  return (
    <>
      {props.keywords && (
        <div className={cn()}>
          {props.keywords.length > 1 ? 'Keywords/Tickers:' : 'Keyword/Ticker:'} {keywordsVisible}
        </div>
      )}
    </>
  );
};

export default NewsKeywords;
