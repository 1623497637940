import React from 'react';
import './ButtonsList.scss';
import { ButtonsListBlock } from './buttonsListBem';
import { useClassName } from '../../hooks/useClassName';
import { AlignTypesHorizontal } from '../../enums/AlignTypesHorizontal';

interface Props {
  isMobileAdaptive?: boolean;
  alignTypeHorizontal?: AlignTypesHorizontal;
  className?: string;
  position?: string;
}

const ButtonsList: React.FC<Props> = props => {
  const { isMobileAdaptive = true, alignTypeHorizontal = AlignTypesHorizontal.Start } = props;

  const cn = useClassName(ButtonsListBlock.Root, props.className);

  return (
    <section
      className={cn({
        mobileAdaptive: isMobileAdaptive,
        [alignTypeHorizontal]: alignTypeHorizontal,
        ...(props.position && { [props.position]: props.position }),
      })}
    >
      {props.children}
    </section>
  );
};

export default ButtonsList;
