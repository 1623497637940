import { StatsElementOptions } from '../../../../../../shared/entities/stats-element/interfaces/StatsElementOptions';
import { pricingsChartOptions } from '../../../../pricings/constants/pricingsStatsElementOptions';
import { getFilingsChartOptions } from '../../../../filings/constants/getFilingsStatsElementOptions';
import { proceedsChartOptions } from '../../../../proceeds/constants/proceedsStatsElementOptions';
import React from 'react';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../../../../../shared/enums/RoutePath';
import { BlockOrientation } from '../../../../../../shared/enums/BlockOrientation';

export const getMarketByMonthStatsElementOptions = (
  filingsBlockTitle: string,
  filingsChartTitle: string,
): StatsElementOptions => ({
  pageOptions: {
    chartsOrientation: BlockOrientation.Line,
  },
  chartsOptions: [
    {
      ...pricingsChartOptions,
      title: <Link to={RoutePath.StatsPricing}>Pricing Activity</Link>,
    },
    {
      ...proceedsChartOptions,
      title: <Link to={RoutePath.StatsProceeds}>Proceeds Raised</Link>,
    },
    {
      ...getFilingsChartOptions(filingsChartTitle),
      title: <Link to={RoutePath.StatsFilings}>{filingsBlockTitle}</Link>,
    },
  ],
});
