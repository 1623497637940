import React, { useCallback, useMemo } from 'react';
import './MasterCalendarPage.scss';
import PrimarySection from '../shared/components/primary-section/PrimarySection';
import DocumentHead from '../shared/components/DocumentHead';
import { RoutePath } from '../shared/enums/RoutePath';
import { usePromiseState } from '../shared/hooks/promise-state/usePromiseState';
import { CalendarEvent } from '../shared/entities/calendar/interfaces/CalendarEvent';
import { MasterCalendarDataService } from './services/MasterCalendarDataService';
import PageLoader from '../shared/components/loader/PageLoader';
import MasterCalendar from './MasterCalendar';
import useBreakpointAvailable from '../shared/effects/useBreakpointAvailable';
import { mobileBreakpoints } from '../shared/constants/breakpoints/mobileBreakpoints';
import { ResponsiveCalendarMode } from '../shared/components/responsive-calendar/enums/ResponsiveCalendarMode';

const MasterCalendarPage: React.FC = () => {
  const isMobile: boolean = useBreakpointAvailable(mobileBreakpoints);

  const mode = useMemo(() => (isMobile ? ResponsiveCalendarMode.WeekVertical : ResponsiveCalendarMode.Month), [
    isMobile,
  ]);

  const [allEvents, isLoading] = usePromiseState<CalendarEvent[]>(
    useCallback(() => MasterCalendarDataService.getEvents(), []),
    [],
  );

  return (
    <PrimarySection className={'MasterCalendarPage'} header="Master Calendar">
      <DocumentHead path={RoutePath.MasterCalendar} />

      <PageLoader loading={isLoading}>
        <MasterCalendar allEvents={allEvents} mode={mode} />
      </PageLoader>
    </PrimarySection>
  );
};

export default MasterCalendarPage;
