import React from 'react';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { useClassName } from '../../../../../../hooks/useClassName';
import { FundingToDateCellBlock } from './FundingToDateCellBem';
import Tooltip from '../../../../../../components/tooltip/Tooltip';
import { profileMobileBreakpoints } from '../../../../../../../company/components/profile/shared/constants/profileMobileBreakpoints';
import useBreakpointAvailable from '../../../../../../effects/useBreakpointAvailable';

type Props = GridCellFormatterCommonProps;

const FundingToDateCell: React.FC<Props> = props => {
  const cn = useClassName(FundingToDateCellBlock.Root);
  const isMobile = useBreakpointAvailable(profileMobileBreakpoints);

  const FundingSource = props.data.fundingDataNote;

  return (
    <CustomCellFormatterWrapper className={cn()} value={props.valueFormatted}>
      {FundingSource && props.value && !isMobile ? (
        <Tooltip
          className={cn()}
          isTitleHighlighted
          isUnderFixed={true}
          content={`Source: ` + props.data.fundingDataNote}
          title={props.valueFormatted}
        />
      ) : (
        props.valueFormatted
      )}
    </CustomCellFormatterWrapper>
  );
};

export default FundingToDateCell;
export {};
