import React, { useCallback, useEffect } from 'react';
import SearchInput, { SearchInputProps } from './SearchInput';
import { FieldError, useFormContext } from 'react-hook-form';

type Props = SearchInputProps;

const FormSearchInput: React.FC<Props> = props => {
  const { register, setValue, errors, watch } = useFormContext();

  const value = watch(props.name);

  const error: FieldError | undefined = errors?.[props?.name] as FieldError;

  useEffect(() => register?.({ name: props.name }), [register, props.name]);

  const onChangeValue = useCallback(
    value => {
      setValue?.(props.name, value);
    },
    [setValue, props.name],
  );

  return <SearchInput {...props} value={value} error={error} onChangeValue={onChangeValue} />;
};

export default FormSearchInput;
