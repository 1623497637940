import { LocationSearchData } from '../interfaces/LocationSearchData';
import { useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { History } from 'history';
import { LocationQueryStringService } from '../services/LocationQueryStringService';

export function useLocationSearchData<T extends LocationSearchData>(search: History.Search): T {
  const [searchData, setSearchData] = useState<T>({} as T);

  const locationSearchData = LocationQueryStringService.getSearchDataFromQueryString(search) as T;

  if (!isEqual(locationSearchData, searchData)) {
    setSearchData(locationSearchData);
  }

  return useMemo(() => searchData, [searchData]);
}
