import React from 'react';
import { EmailPreferenceSection } from '../interfaces/EmailPreferenceSection';
import FormCheckboxTextSelect from '../../../shared/components/forms/FormCheckboxTextSelect';
import FormCheckbox from '../../../shared/components/forms/FormCheckbox';

interface Props {
  preference: EmailPreferenceSection;
}

const EmailPreferencesFormSectionPreference: React.FC<Props> = props => {
  return (
    <>
      {props.preference.filterOptions ? (
        <FormCheckboxTextSelect
          label={props.preference.label}
          name={props.preference.name.toString()}
          options={props.preference.filterOptions}
        />
      ) : (
        <FormCheckbox name={props.preference.name.toString()} label={props.preference.label} />
      )}
    </>
  );
};

export default EmailPreferencesFormSectionPreference;
