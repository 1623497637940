import React, { CSSProperties } from 'react';
import { FilterSectionsFormState } from '../interfaces/FilterSectionsFormState';
import FilterSection from './FilterSection';

interface Props {
  sectionsStates: FilterSectionsFormState[];
  style: CSSProperties;
}

const FilterSectionsList: React.FC<Props> = props => {
  return (
    <div className="FilterSectionsList" style={props.style}>
      {props.sectionsStates.map(
        sectionState =>
          sectionState.isVisible && (
            <FilterSection key={sectionState.type} type={sectionState.type} sectionState={sectionState} />
          ),
      )}
    </div>
  );
};

export default FilterSectionsList;
