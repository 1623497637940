import React, { Component } from 'react';
import { GridCellFormatterCommonProps, GridCellFormatterCustomProps } from '../../../interfaces/gridCellFormatter';

interface Props extends GridCellFormatterCustomProps, GridCellFormatterCommonProps {}

class CustomCellFormatter extends Component<Props> {
  render() {
    return <>{React.createElement(this.props.component, { ...this.props })}</>;
  }
}

export default CustomCellFormatter;
