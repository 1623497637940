export enum FavouriteIconBlock {
  Root = 'FavouriteIcon',
}

export enum FavouriteIconModifier {
  Active = 'active',
  NotActive = 'notActive',
  Disabled = 'disabled',
  HoverCursor = 'hoverCursor',
}
