export enum DateFormat {
  MonthDayYear = 'MM/dd/yyyy',
  MonthDayYearShort = 'MM/dd/yy',
  MonthDay = 'MM/dd',
  MonthtextDay = 'MMM d',
  MonthDayTh = 'MMMM do', // February 23th
  LongMonthDayYear = 'MMMM dd, yyyy', // February 27, 2020
  YearMonthDayISO = 'yyyy-MM-dd', // Fullcalendar understands this format
  LongMonthDayYearWithTime = 'MMMM dd, yyyy kk:mm',
  YearMonthTextSort = 'yyyy MMM',
  MonthYearDayTime = 'MM/dd/yy, kk:mm',
}
