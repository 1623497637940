import React, { useContext } from 'react';
import { ViewDashboardContext } from '../../shared/entities/view/components/view-dashboard/ViewDashboardContext';
import { ResearchDashboardDto } from '../interfaces/ResearchDashboardDto';
import AnnualRatingsPerformanceChart from '../../shared/entities/chart/AnnualRatingsPerformanceChart';
import { ResearchDashboardProp } from '../enums/ResearchDashboardProp';

const ResearchDashboardAnnualRatingsPerformanceChart: React.FC = () => {
  const context = useContext(ViewDashboardContext);
  const data = context.data as ResearchDashboardDto;

  return <AnnualRatingsPerformanceChart options={data[ResearchDashboardProp.TrackRecord]} />;
};

export default ResearchDashboardAnnualRatingsPerformanceChart;
