import { NewsItemTag } from '../interfaces/NewsItemTag';
import { NewsTagModifier } from '../enums/NewsTagModifier';
import { NewsListItem } from '../components/interfaces/NewsListItem';
import { DateHelper } from '../../shared/helpers/date/DateHelper';
import { DateFormat } from '../../shared/helpers/date/enums/DateFormat';

export function getDateTag(
  item: NewsListItem,
  hasDateTag?: boolean,
  dateTagFormat?: DateFormat,
): NewsItemTag | undefined {
  if (item.breakDate && hasDateTag) {
    return {
      title: DateHelper.formatISODateStr(item.breakDate, dateTagFormat),
      modifier: NewsTagModifier.Gray,
    };
  }
}
