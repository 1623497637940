import React, { useMemo, useState } from 'react';
import { StatsElementGridDto } from './types/StatsElementGridDto';
import { SimpleGridProps } from '../../../../components/grid/simple-grid/SimpleGrid';
import { ControlDivider } from '../../../../components/forms/services/control-divider/enums/ControlDivider';
import { StatsElementSimpleGridBlock } from './statsElementSimpleGridBem';
import { useClassName } from '../../../../hooks/useClassName';
import SecondarySection from '../../../../components/secondary-section/SecondarySection';
import './StatsElementSimpleGrid.scss';
import { ControlSize } from '../../../../enums/ControlSize';
import { StatsElementFiltersInternalService } from '../shared/filters-internal/services/StatsElementFiltersInternalService';
import StatsElementFilterInternalTextSelect from '../shared/filters-internal/components/StatsElementFilterInternalTextSelect';
import { SimpleGridType } from '../../../../components/grid/simple-grid/enums/SimpleGridType';
import ViewSimpleGrid from '../../../view/components/view-grid/ViewSimpleGrid';
import { StatsElementGridOptions } from './interfaces/StatsElementGridOptions';
import { StatsElementGridContext } from './interfaces/StatsElementGridContext';
import { ColorScheme } from '../../../../enums/ColorScheme';
import { useEffect, useContext } from 'react';
import { StatsElementContext } from '../context/StatsElementContext';
import { StatsElementSimpleGridModifier } from './enums/StatsElementSimpleGridModifier';

interface Props extends StatsElementGridDto {
  index: number;
  gridContext: StatsElementGridContext;
  gridOptions?: StatsElementGridOptions;
}

const StatsElementSimpleGrid: React.FC<Props> = props => {
  const { gridContext, gridOptions, index, ...otherProps } = props;

  const cn = useClassName(StatsElementSimpleGridBlock.Root);

  const { addGridToGridsToExcel } = useContext(StatsElementContext);

  const initialFilterValue = useMemo(() => StatsElementFiltersInternalService.getFirstFilterName(otherProps), [
    otherProps,
  ]);
  const [filterValue, setFilterValue] = useState(initialFilterValue);

  const filtersOptions = useMemo(() => StatsElementFiltersInternalService.getTextSelectOptionsFromFilters(otherProps), [
    otherProps,
  ]);

  const simpleGridProps = useMemo(
    () => StatsElementFiltersInternalService.getFilterValue<SimpleGridProps>(props, filterValue),
    [props, filterValue],
  );

  const isStickyFirstColumn = useMemo(() => !props.gridOptions?.isNoStickyFitstColumn, [props.gridOptions]);
  const isStickySecondColumn = useMemo(() => props.gridOptions?.isStickySecondColumn, [props.gridOptions]);

  useEffect(() => {
    if (simpleGridProps) {
      addGridToGridsToExcel(simpleGridProps, props.index);
    }
  }, [simpleGridProps, addGridToGridsToExcel, props.index]);

  return (
    <SecondarySection
      className={cn({
        [StatsElementSimpleGridModifier.StickySecondColumn]: isStickySecondColumn,
        [StatsElementSimpleGridModifier.StickyFirstColumn]: isStickyFirstColumn,
      })}
      hasShiftHorizontal={gridOptions?.hasShiftHorizontal}
      title={
        <StatsElementFilterInternalTextSelect
          divider={ControlDivider.Slash}
          value={filterValue}
          options={filtersOptions}
          onChangeValue={setFilterValue}
        />
      }
    >
      {simpleGridProps && (
        <ViewSimpleGrid
          {...simpleGridProps}
          {...props.gridOptions}
          size={ControlSize.Small}
          styleType={SimpleGridType.Default}
          isStickyFirstColumn={isStickyFirstColumn}
          isResponsive
          backgroundColorScheme={ColorScheme.White}
          context={gridContext}
          isAutoCellWidth
          isFullWidth
          onColumnMapReady={gridOptions?.onColumnMapReady}
        />
      )}
    </SecondarySection>
  );
};

export default StatsElementSimpleGrid;
