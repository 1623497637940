import { FiltersValues } from '../../../shared/entities/filters/types/FiltersValues';
import { createContext } from 'react';
import { StatsPageFiltersSettings } from '../interfaces/StatsPageFiltersSettings';
import { StatsGridsToExcel } from '../../../shared/entities/stats-element/interfaces/StatsGridsToExcel';
import { GetFileFn } from '../../../shared/services/file/interfaces/GetAndDownloadFileOptions';

export interface StatsPageContextValue {
  pageFilters: FiltersValues;
  pageFiltersSettings?: StatsPageFiltersSettings;
  isExcelActionAvailable: boolean;
  onGridsToExcelChange: (excelGrids: StatsGridsToExcel) => void;
  onExcelAction?: GetFileFn;
  onInlineFiltersChange: (partialFilters: FiltersValues) => void;
  onPopupFiltersChange: (partialFilters: FiltersValues) => void;
  setPageFilters: (pageFilters: FiltersValues) => void;
}

export const StatsPageContext = createContext<StatsPageContextValue>({
  pageFilters: {},
  isExcelActionAvailable: false,
  onGridsToExcelChange: () => {},
  onInlineFiltersChange: () => {},
  onPopupFiltersChange: () => {},
  setPageFilters: () => {},
});
