import {
  ADD_FAVOURITE_COMPANY,
  REMOVE_FAVOURITE_COMPANY,
  SET_FAVOURITE_COMPANIES,
  SetFavouriteCompanies,
} from './types';
import { CompanyFavouriteDto } from '../../company/interfaces/CompanyFavouriteDto';
import { CustomAny } from '../../shared/types/generics';
import { NotificationActionFn, NotificationSuccessMessage } from '../notification/types';
import { handleAction } from '../notification/actions';
import { ThunkAction } from 'redux-thunk';
import { FavoritesApiService } from '../../shared/api/FavoritesApiService';
import { FavouriteMessage } from '../../shared/components/favourites/enums/FavouriteMessage';

export function setFavouriteCompanies(companies: CompanyFavouriteDto[]): SetFavouriteCompanies {
  return {
    type: SET_FAVOURITE_COMPANIES,
    payload: { companies },
  };
}

export function updateCompanies(
  actionFn: NotificationActionFn,
  message: NotificationSuccessMessage,
  onFinally: () => void,
): ThunkAction<void, {}, {}, CustomAny> {
  return (dispatch): void => {
    dispatch(
      handleAction(actionFn, {
        message,
        onFinally,
      }),
    );
  };
}

export function addFavouriteCompany(
  company: CompanyFavouriteDto,
  onFinally: () => void,
): ThunkAction<void, {}, {}, CustomAny> {
  return dispatch => {
    dispatch(
      updateCompanies(
        async () => {
          await FavoritesApiService.add(company.companyKey, company.companyName);

          dispatch({
            type: ADD_FAVOURITE_COMPANY,
            payload: { company },
          });
        },
        {
          values: [company.companyName],
          text: FavouriteMessage.SuccessAdd,
        },
        onFinally,
      ),
    );
  };
}

export function removeFavouriteCompany(
  company: CompanyFavouriteDto,
  onFinally: () => void,
): ThunkAction<void, {}, {}, CustomAny> {
  return dispatch => {
    dispatch(
      updateCompanies(
        async () => {
          await FavoritesApiService.remove(company.companyKey);

          dispatch({
            type: REMOVE_FAVOURITE_COMPANY,
            payload: { companyKey: company.companyKey },
          });
        },
        {
          values: [company.companyName],
          text: FavouriteMessage.SuccessRemove,
        },
        onFinally,
      ),
    );
  };
}
