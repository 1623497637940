import { EmailPreferencesContextValue } from '../context/EmailPreferencesContext';
import { useState, useMemo, useCallback } from 'react';
import { EmailPreferenceDto } from '../form/interfaces/EmailPreferenceDto';
import { INITIAL_SECTIONS, DEFAULT_SECTIONS } from '../form/constants/sections';
import AuthApiService from '../../shared/api/AuthApiService';
import { ObjectHelper } from '../../shared/helpers/ObjectHelper';
import { useHandleFn } from '../../shared/hooks/handle-fn/useHandleFn';

export function useEmailPreferencesContextValue(): EmailPreferencesContextValue {
  const [sectionsData, setSectionsData] = useState<EmailPreferenceDto>(INITIAL_SECTIONS);
  const [initialLoadedData, setInitialLoadedData] = useState<EmailPreferenceDto>(INITIAL_SECTIONS);

  const loadAllSections = useHandleFn(
    useCallback(async () => {
      const data = await AuthApiService.getEmailPreferences();

      if (ObjectHelper.arePropsEmpty(data)) {
        setSectionsData(DEFAULT_SECTIONS);
      } else {
        setSectionsData(data);
        setInitialLoadedData(data);
      }
    }, [setSectionsData]),
  );

  const updateAllSections = useCallback(async () => AuthApiService.putEmailPreferences(sectionsData), [sectionsData]);

  return useMemo(
    () => ({
      initialLoadedData,
      sectionsData,
      loadAllSections,
      setSectionsData,
      updateAllSections,
    }),
    [initialLoadedData, sectionsData, loadAllSections, setSectionsData, updateAllSections],
  );
}
