import React, { useMemo } from 'react';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';
import { GridRow } from '../../../../../../components/grid/shared/types/GridRow';
import ViewSimpleGrid from '../../ViewSimpleGrid';
import { ComponentWithExpandDataListProps } from '../../../../../../components/expander/interfaces/ComponentWithExpandDataListProps';
import { ReportDto } from '../../../../../../../special-reports/interfaces/ReportDto';
import { viewGridColumns } from '../../constants/viewGridColumns';
import { ViewGridColumnDto } from '../../interfaces/ViewGridColumnDto';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { ColorScheme } from '../../../../../../enums/ColorScheme';

interface Props extends ComponentWithExpandDataListProps {
  expandedData: ReportDto[];
  hasAroundBorder?: boolean;
  hasRating?: boolean;
  size?: ControlSize;
  backgroundColorScheme?: ColorScheme;
}

export const viewColumns = [
  { ...viewGridColumns[ViewColumnSystemName.SpecialReportsDate] },
  { ...viewGridColumns[ViewColumnSystemName.ReportTitle] },
] as ViewGridColumnDto[];

export const viewColumnsWithRating = [
  ...viewColumns,
  { ...viewGridColumns[ViewColumnSystemName.ProfileRating] },
] as ViewGridColumnDto[];

const ReportsGrid: React.FC<Props> = props => {
  const { hasRating, expandedData, ...tableProps } = props;

  const columns = useMemo(() => (hasRating ? viewColumnsWithRating : viewColumns), [hasRating]);

  const rows = useMemo<GridRow[]>(
    () =>
      expandedData.map(specialReport => ({
        [ViewColumnSystemName.SpecialReportsDate]: specialReport.reportDate,
        [ViewColumnSystemName.ReportTitle]: specialReport.title,
        [ViewColumnSystemName.ReportLink]: specialReport.link,
        [ViewColumnSystemName.ProfileRating]: specialReport.rating,
      })),
    [expandedData],
  );

  return <ViewSimpleGrid {...tableProps} columns={columns} rows={rows} isHeaderHidden isFullWidth />;
};

export default ReportsGrid;
