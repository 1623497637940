import { ViewColumnsOptionsDto } from '../interfaces/dto/ViewColumnsOptionsDto';
import { ViewColumnsAddSettings } from '../interfaces/ViewColumnsAddSettings';
import { VIEW_COLUMNS_ADD_SETUP_SETTINGS } from '../constants/viewColumnsAddSetupSettings';
import { ViewColumnsSectionDto } from '../interfaces/dto/ViewColumnsSectionDto';
import { ViewColumnsSectionId } from '../enums/ViewColumnsSectionId';
import { CustomAny } from '../../../../../../../../types/generics';
import { ViewColumnsAddItem } from '../interfaces/ViewColumnsAddItem';
import { ViewColumnsColumnDto } from '../interfaces/dto/ViewColumnsColumnDto';
import { ViewColumnsAddService } from './ViewColumnsAddService';

export class ViewColumnsAddDataService {
  static getBaseColumnsSettings(columnsOptions: ViewColumnsOptionsDto): ViewColumnsAddSettings[] {
    return VIEW_COLUMNS_ADD_SETUP_SETTINGS.map(sectionGroup => ({
      ...sectionGroup,
      items: sectionGroup.items.map((section: CustomAny) => {
        const sectionOptions = this.getSectionOptionsById(section.id, columnsOptions);

        return {
          ...sectionOptions,
          ...section,
          items: this.getSectionColumnsSettingsFromOptions(sectionOptions.items),
        };
      }),
    }));
  }

  static getSelectedColumnsIds(columnsOptions: ViewColumnsOptionsDto, selectedColumnsIds?: string[]): string[] {
    if (ViewColumnsAddService.hasSelectedColumns(selectedColumnsIds)) {
      return selectedColumnsIds as string[];
    }

    return this.getSelectedColumnsIdsFromOptions(columnsOptions);
  }

  private static getSectionOptionsById(
    id: ViewColumnsSectionId,
    columnsOptions: ViewColumnsOptionsDto,
  ): ViewColumnsSectionDto {
    return columnsOptions.items.find(section => section.id === id) as ViewColumnsSectionDto;
  }

  private static getSectionColumnsSettingsFromOptions(columns: ViewColumnsColumnDto[]): ViewColumnsAddItem[] {
    return columns.map(column => ({
      ...column,
    }));
  }

  private static getSelectedColumnsIdsFromOptions(columnsOptions: ViewColumnsOptionsDto): string[] {
    const allColumns: ViewColumnsColumnDto[] = columnsOptions.items.reduce(
      (columns, section) => columns.concat(section.items),
      [] as ViewColumnsColumnDto[],
    );

    return allColumns.filter(column => column.isDisabled).map(column => column.id);
  }
}
