import { SearchResultsDto } from '../../search/interfaces/SearchResultsDto';
import { SearchItemDto } from '../../search/components/search/interfaces/dto/SearchItemDto';
import { ApiPath } from './enums/ApiPath';
import ApiService from './ApiService';
import { SearchResultsData } from '../../search/interfaces/SearchResultsData';
import { SearchItemCompanyDto } from '../../search/components/search/interfaces/dto/SearchItemCompanyDto';

export class SearchApiService {
  static search(searchTerm: string): Promise<SearchItemDto[]> {
    return ApiService.get(`${ApiPath.Search}/SmartSearch`, { params: { searchTerm } });
  }

  static getSearchResults(searchData: SearchResultsData): Promise<SearchResultsDto> {
    return ApiService.post(ApiPath.Search, searchData);
  }

  static getNotFavoriteCompanies(searchTerm: string): Promise<SearchItemCompanyDto[]> {
    return ApiService.get(`${ApiPath.Search}/CompaniesNotInFavoritesSearch`, { params: { searchTerm } });
  }

  // TODO: update for valid url
  static getAllCompanies(searchTerm: string): Promise<SearchItemCompanyDto[]> {
    return ApiService.get(`${ApiPath.Search}/CompaniesSearch`, { params: { searchTerm } });
  }
}
