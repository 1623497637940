import { isEqual } from 'lodash';
import { ViewSelectedColumnsDto } from '../interfaces/ViewSelectedColumnsDto';

export class ViewColumnsService {
  static isSelectedColumnsChanged(newColumns: ViewSelectedColumnsDto, currentColumns: ViewSelectedColumnsDto): boolean {
    return !isEqual(newColumns, currentColumns);
  }

  static isResetDisabled(currentColumns: ViewSelectedColumnsDto, defaultColumns: ViewSelectedColumnsDto): boolean {
    return !this.isSelectedColumnsChanged(currentColumns, defaultColumns);
  }
}
