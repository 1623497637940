import React, { memo, ReactNode, useCallback, useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
import Label from '../../Label';
import Error from '../../Error';
import { FieldError } from 'react-hook-form';
import './DateSelect.scss';
import { DateSelectValue } from './types/DateSelectValue';
import { DatePickerPosition } from './enums/DatePickerPosition';
import { DateSelectBlock, DateSelectElement } from './DateSelectBem';
import { DateSelectRestrictionValue } from './types/DateSelectRestrictionValue';
import { DateHelper } from '../../../../helpers/date/DateHelper';
import { ControlSize } from '../../../../enums/ControlSize';
import { useClassName } from '../../../../hooks/useClassName';
import { DateSelectService } from './services/DateSelectService';
import { DatePrickerDateFormat } from './enums/DatePrickerDateFormat';

export interface DateSelectProps {
  value: DateSelectValue;
  placeholder?: string;
  label?: string | ReactNode;
  datePickerPosition?: DatePickerPosition;
  isDisabled?: boolean;
  error?: FieldError | undefined;
  size?: ControlSize;
  minDateRestrictionValue?: DateSelectRestrictionValue;
  maxDateRestrictionValue?: DateSelectRestrictionValue;
  onChangeValue: (value: DateSelectValue) => void;
}

const POPPER_MODIFIERS = {
  preventOverflow: {
    enabled: true,
  },
};

const DateSelect: React.FC<DateSelectProps> = props => {
  const { onChangeValue, datePickerPosition = DatePickerPosition.BottomEnd } = props;

  const cn = useClassName(DateSelectBlock.Root);

  const value = useMemo<Date | undefined>(() => DateHelper.parseISODate(props.value), [props.value]);

  const minDate = useMemo<Date | undefined>(() => DateHelper.parseISODate(props.minDateRestrictionValue), [
    props.minDateRestrictionValue,
  ]);
  const maxDate = useMemo(() => DateSelectService.getRestrictionDate(props.maxDateRestrictionValue), [
    props.maxDateRestrictionValue,
  ]);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    onChangeValue(DateSelectService.getNewValueOnBlur(event.target.value));
  };

  const onOptionClick = useCallback(
    (date: Date) => {
      onChangeValue(DateSelectService.getNewValue(date));
    },
    [onChangeValue],
  );

  const onChange = useCallback(() => {}, []);

  return (
    <div
      className={cn({
        [`${props.size}`]: props.size,
      })}
    >
      {props.label && <Label error={props.error}>{props.label}:</Label>}

      <div className={cn(DateSelectElement.Control)}>
        <ReactDatePicker
          popperPlacement={datePickerPosition}
          selected={value}
          disabled={props.isDisabled}
          placeholderText={props.placeholder}
          showYearDropdown={true}
          showMonthDropdown={true}
          dateFormat={DatePrickerDateFormat.Short}
          minDate={minDate}
          maxDate={maxDate}
          popperModifiers={POPPER_MODIFIERS}
          onSelect={onOptionClick}
          onBlur={onBlur}
          onChange={onChange}
        />
      </div>

      {props.error && <Error>{props.error}</Error>}
    </div>
  );
};
export default memo(DateSelect);
