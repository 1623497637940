import React from 'react';
import NewsList from '../../components/NewsList';
import NewsDate from '../../components/NewsDate';
import { NewsLandingItemDto } from '../../interfaces/NewsLandingItemDto';
import { useGroupNewsLandingItems } from '../hooks/useGroupNewsLandingItems';
import { NewsLandingItemsGroup } from '../interfaces/NewsLandingItemsGroup';

interface Props {
  items: NewsLandingItemDto[];
  isNonUs: boolean;
}

const NewsLandingList: React.FC<Props> = props => {
  const groups: NewsLandingItemsGroup[] = useGroupNewsLandingItems(props.items);

  return (
    <div className="NewsLandingList">
      {groups.map(group => (
        <section className="NewsLandingList__date" key={group.date}>
          <NewsDate>{group.date}</NewsDate>
          <NewsList expandedData={group.items} isNonUs={props.isNonUs} />
        </section>
      ))}
    </div>
  );
};

export default NewsLandingList;
