import React, { useContext, useMemo } from 'react';
import { CompanyContext } from '../context/CompanyContext';
import { CompanyFinancialsFilter } from './interfaces/CompanyFinancialsFilter';
import CompanyProfileTable from '../profile/shared/components/company-profile-table/CompanyProfileTable';
import './CompanyFinancialsBalanceSheet.scss';
import CompanySectionRow from '../shared/CompanySectionRow';
import { Nullable } from '../../../shared/types/generics';
import { CompanyFinancialsDto } from '../../interfaces/CompanyFinancialsDto';
import { CompanyTab } from '../../enums/CompanyTab';
import { ChartFactory } from '../../../shared/components/chart/factories/ChartFactory';
import { useClassName } from '../../../shared/hooks/useClassName';
import {
  CompanyFinancialsBalanceSheetBlock,
  CompanyFinancialsBalanceSheetElement,
} from './companyFinancialsBalanceSheetBem';
import { ChartType } from '../../../shared/components/chart/enums/ChartType';
import { ChartProps } from '../../../shared/components/chart/Chart';

interface Props {
  filters: CompanyFinancialsFilter;
}

const CompanyFinancialsBalanceSheet: React.FC<Props> = props => {
  const { getTabData } = useContext(CompanyContext);
  const data: Nullable<CompanyFinancialsDto> = getTabData(CompanyTab.Financials);

  const filteredData = useMemo(() => data?.balanceSheet?.[props.filters.location], [props.filters, data]);

  const cn = useClassName(CompanyFinancialsBalanceSheetBlock.Root);

  const chartProps: ChartProps = useMemo(
    () => ({
      className: cn(CompanyFinancialsBalanceSheetElement.Item),
      optionsList: [filteredData?.chart],
    }),
    [filteredData, cn],
  );

  const chart = useMemo(() => {
    ChartFactory.getChart(chartProps, ChartType.Base);
  }, [chartProps]);

  return (
    <>
      {filteredData && (
        <CompanySectionRow className={cn()}>
          <div className={cn(CompanyFinancialsBalanceSheetElement.Item)}>
            <CompanyProfileTable
              {...filteredData.grid}
              expandedData={filteredData.grid.rows}
              isColumnsAlignEnd
              isFullWidth={false}
              isAutoCellWidth
            />
          </div>

          {chart}
        </CompanySectionRow>
      )}
    </>
  );
};

export default CompanyFinancialsBalanceSheet;
