export enum SecondarySectionBlock {
  Root = 'SecondarySection',
}

export enum SecondarySectionElement {
  Title = 'title',
  Content = 'content',
}

export enum SecondarySectionModifier {
  Underlined = 'underlined',
  FullWidth = 'fullWidth',
  ShiftHorizontal = 'shiftHorizontal',
  NoteSpan = 'noteSpan',
  InnerDiv = 'innerDiv',
}
