import React, { useContext, useMemo } from 'react';
import { CompanyContext } from '../context/CompanyContext';
import CompanySection from '../shared/CompanySection';
import { CompanyTab } from '../../enums/CompanyTab';
import CompanyPerfomanceVsIpoIndexChart from '../shared/performance-vs-ipo-Index-chart/CompanyPerfomanceVsIpoIndexChart';
import CompanyMapTables from '../shared/CompanyMapTables';
import { CompanyPeerTradingDto } from '../../interfaces/CompanyPeerTradingDto';
import CompanyParagraph from '../shared/CompanyParagraph';
import { SimpleGridProps } from '../../../shared/components/grid/simple-grid/SimpleGrid';
import PageLoader from '../../../shared/components/loader/PageLoader';
import HtmlStringFormatterJustified from '../../../shared/components/HtmlStringFormatterJustified';
import { ProfileData } from '../../interfaces/ProfileData';

const CompanyPeerTrading: React.FC = () => {
  const { getTabData, technicalComparisonData } = useContext(CompanyContext);
  const data: CompanyPeerTradingDto = getTabData(CompanyTab.PeerTrading);

  const profile: ProfileData = getTabData(CompanyTab.Profile);
  const compData = useMemo(() => profile.description, [profile]);
  if (compData?.isPreliminaryComps && technicalComparisonData) {
    technicalComparisonData.title = 'Technical Comparison (Preliminary)';
  }

  const titleProps = useMemo(() => {
    const ratingValue = data?.rating;
    return {
      tab: CompanyTab.PeerTrading,
      ratingTitle: 'Technical Rating',
      ratingValue,
    };
  }, [data]);

  const grids: (SimpleGridProps | null)[] = [technicalComparisonData];

  const tables: (SimpleGridProps | null)[] = useMemo(
    () =>
      grids.map(grid => {
        if (grid) {
          return {
            ...grid,
            isFullWidth: true,
          };
        } else {
          return null;
        }
      }),
    [grids],
  );

  return (
    <>
      {data && <h3 className="printHead">Peer Trading</h3>}
      <CompanySection className="CompanyPeerTrading" data={data} titleProps={titleProps}>
        {data?.technicalDescription && (
          <CompanyParagraph text={<HtmlStringFormatterJustified value={data.technicalDescription as string} />} />
        )}
        <CompanyPerfomanceVsIpoIndexChart />
        <PageLoader loading={!!!technicalComparisonData}>
          <CompanyMapTables tables={tables} />
        </PageLoader>
      </CompanySection>
    </>
  );
};

export default CompanyPeerTrading;
