import { DependencyList, useEffect } from 'react';
import { debounce } from 'lodash';

export const useResize = (callback: () => void, deps: DependencyList = []): void => {
  useEffect(() => {
    const DEBOUNCE_TIME = 300;
    const EVENT_RESIZE = 'resize';

    const onResize = debounce((): void => {
      callback();
    }, DEBOUNCE_TIME);

    onResize();

    window.addEventListener(EVENT_RESIZE, onResize);

    return (): void => {
      window.removeEventListener(EVENT_RESIZE, onResize);
    };
    // eslint-disable-next-line
  }, [callback, ...deps]);
};
