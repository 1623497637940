import React from 'react';
import CompanyTooltip from '../../../../../../../company/components/company-tooltip/CompanyTooltip';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { useClassName } from '../../../../../../hooks/useClassName';
import { CompanyCellBlock } from './CompanyCellBem';
import useBreakpointAvailable from '../../../../../../effects/useBreakpointAvailable';
import { mobileMenuBreakpoints } from '../../../../../../../company/components/profile/shared/constants/profileMobileBreakpoints';

interface Props extends GridCellFormatterCommonProps {
  isNameShort?: boolean;
  isTitleEllipsis?: boolean;
}

const removeTicker = (value: string) => {
  const indexOfTicker = value.indexOf('(');
  const modifiedString = indexOfTicker > -1 ? value.substring(0, indexOfTicker - 1) : value;
  return modifiedString;
};

const CompanyCell: React.FC<Props> = props => {
  const cn = useClassName(CompanyCellBlock.Root);
  const isMobile = useBreakpointAvailable(mobileMenuBreakpoints);

  const value = isMobile ? removeTicker(props.value) : props.value;

  return (
    <CustomCellFormatterWrapper className={cn()} value={value}>
      {props.data?.isCompanyNotPresent ? (
        value
      ) : (
        <CompanyTooltip
          name={value}
          ticker={props.data[ViewColumnSystemName.Ticker]}
          isNameShort={props.isNameShort}
          isTitleEllipsis={props.isTitleEllipsis}
        />
      )}
    </CustomCellFormatterWrapper>
  );
};

export default CompanyCell;
