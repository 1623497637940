import {
  FormControl,
  FormControlProps,
} from '../../../../../components/forms/components/form-builder/types/formControlsTypes';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { CustomAny } from '../../../../../types/generics';
import { allViewFilterControls } from '../../../constants/filter-controls/allViewFilterControls';
import { FilterSettingsService } from '../../../services/FilterSettingsService';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { ControlSize } from '../../../../../enums/ControlSize';
import { FilterSection } from '../../../interfaces/FilterSection';

export class FilterControlFactory {
  static getList(sections: FilterSection[], excludeFilterNames: ViewFilterName[] = []): FormControl[] {
    const filterNames = FilterSettingsService.getFiltersNames(sections, excludeFilterNames);
    return filterNames.map(filterName => this.get(filterName, sections)).filter(control => !!control) as FormControl[];
  }

  static get(
    name: ViewFilterName,
    sections: FilterSection[],
    controlProps?: Partial<FormControlProps>,
  ): FormControl | undefined {
    const baseSettings: CustomAny = allViewFilterControls[name];
    const currentControlSettings = FilterSettingsService.getFilterByName(sections, name);

    // If there is no such control from settings
    // Then we wouldn't render it
    if (!currentControlSettings) {
      return;
    }

    return {
      ...baseSettings,
      props: {
        ...baseSettings.props,
        ...this.getPropsByType(baseSettings.type),
        ...controlProps,
        ...currentControlSettings.props,
      },
    } as FormControl;
  }

  private static getPropsByType(type: FormControlType): Partial<FormControlProps> {
    switch (type) {
      case FormControlType.Select:
      case FormControlType.SelectSwitchOptions:
        return {
          width: ControlSize.ExtraLarge,
          selectWidth: ControlSize.ExtraLarge,
        };
      default:
        return {};
    }
  }
}
