import React, { CSSProperties } from 'react';
import './GridScrollAction.scss';
import OverlayContainer from '../../overlay-container/OverlayContainer';
import Icon from '../../icon/Icon';
import { IconName } from '../../icon/IconName';
import { useClassName } from '../../../hooks/useClassName';
import { GridScrollActionBlock, GridScrollActionModifier } from './GridScrollActionBem';
import { ControlSize } from '../../../enums/ControlSize';

interface Props {
  iconName: IconName;
  isHidden: boolean;
  containerStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  onActionMouseDown?: () => void;
  onActionMouseUp?: () => void;
}

const GridScrollAction: React.FC<Props> = props => {
  const cn = useClassName(GridScrollActionBlock.Root);

  return (
    <OverlayContainer
      className={cn({
        [props.iconName]: props.iconName,
        [GridScrollActionModifier.Hidden]: props.isHidden,
      })}
      style={props.containerStyle}
      contentStyle={props.contentStyle}
      onContainerMouseDown={props.onActionMouseDown}
      onContainerMouseUp={props.onActionMouseUp}
    >
      <Icon name={props.iconName} size={ControlSize.ExtraLarge} />
    </OverlayContainer>
  );
};

export default GridScrollAction;
