import { AxiosError } from 'axios';
import { ApiError } from '../../types/api/ApiError';
import { GlobalError } from './types/GlobalError';
import { ApiResponseError } from '../../types/api/ApiResponse';

export class ErrorTypeService {
  static isApiError(error: GlobalError): error is ApiResponseError {
    return (error as AxiosError<ApiError>).isAxiosError !== undefined;
  }

  static isNoResponse(error: AxiosError): boolean {
    return !error.response;
  }

  static isNoResponseData(error: AxiosError): boolean {
    return !error?.response?.data;
  }

  static isServerError(error: AxiosError): boolean {
    return !!error?.response?.data?.Data;
  }
}
