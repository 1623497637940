import { RoutePath } from '../../../enums/RoutePath';

export const statsNavItems = [
  {
    text: 'Stats Dashboard',
    path: RoutePath.Stats,
  },
  {
    text: 'Pricings',
    path: RoutePath.StatsPricing,
  },
  {
    text: 'Proceeds',
    path: RoutePath.StatsProceeds,
  },
  {
    text: 'Filings',
    path: RoutePath.StatsFilings,
  },
  {
    text: 'Industry Breakdown',
    path: RoutePath.StatsIndustry,
  },
  {
    text: 'Global Breakdown',
    path: RoutePath.StatsGlobalBreakdown,
  },
  {
    text: 'Historical IPO Returns',
    path: RoutePath.StatsHistoricalIPOReturns,
  },
  {
    text: 'VC & PE Backed IPOs',
    path: RoutePath.StatsBacked,
  },
  {
    text: 'Renaissance IPO Index',
    path: RoutePath.StatsRecentIposReturns,
  },
  {
    text: 'Additional Stats',
    path: RoutePath.AdditionalStats,
  },
];
