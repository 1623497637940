export enum RoutePath {
  Home = '/',
  Showpdf = '/showpdf',
  Research = '/research',
  IPOResearch = '/ipo-research',
  ResearchDashboard = '/research/dashboard',
  ResearchRecent = '/research/recent',
  ResearchSpecialReports = '/research/special-reports',
  ResearchRatingsSystem = '/research/ratings-system',
  Screens = '/screens',
  ScreensAdd = '/screens/add',
  ScreensUnderwriterLanding = '/screens/landing/underwriter',
  ScreensUnderwriterInUse = '/screens/underwriter',
  ScreensIndustryLanding = '/screens/landing/industry',
  ScreensIndustryInUse = '/screens/industry',
  ScreensLocationLanding = '/screens/landing/location',
  ScreensLocationInUse = '/screens/location',
  ScreensLockUpsInUse = '/screens/lockups',
  ScreensExpiredLockUpsInUse = '/screens/expired-lockups',
  ScreensInvestorsLanding = '/screens/landing/investors',
  ScreensSpacsLanding = '/screens/landing/spacs',
  ScreensSpacsInUse = '/screens/spacs',
  ScreensInvestorsInUse = '/screens/investors',
  ScreensEstimatesInUse = '/screens/estimates',
  ScreensCustomInUse = '/screens/custom',
  ScreensWithdrawnPostponedInUse = '/screens/withdrawn-postponed',
  Features = '/features',
  WhoUsesUs = '/who-uses-us',
  Pricing = '/pricing',
  About = '/about',
  Overview = '/overview',
  Login = '/login',
  LoginHelp = '/login-help',
  UserPrivacy = '/user-privacy',
  ResetPassword = '/reset-password',
  RecoverPassword = '/password',
  RequestTrial = '/request',
  Contact = '/contact',
  Dashboard = '/dashboard',
  News = '/news',
  NewsSearch = '/news/search',
  Industry = '/industry',
  Estimates = '/estimates',
  Location = '/location',
  Investors = '/investors',
  Underwriter = '/underwriter',
  AccountSettings = '/account-settings',
  Analyst = '/analyst',
  Help = '/help',
  Filed = '/filed',
  ContactUs = '/contact-us',
  Disclosures = '/disclosures',
  MasterCalendar = '/master-calendar',
  Poll = '/poll',
  GettingStarted = '/getting-started',
  Videos = '/videos',
  VideoTutorials = '/video-tutorials',
  LatestVideos = '/latest-videos',
  Faq = '/faq',
  Chat = '/chat',
  EmailPreferences = '/email-preferences',
  MyFavourites = '/favorites',
  BillingStatements = '/billing-statements',
  Company = '/company',
  Stats = '/stats',
  StatsPricing = '/stats/pricing',
  StatsProceeds = '/stats/proceeds',
  StatsFilings = '/stats/filings',
  StatsIndustry = '/stats/industry',
  StatsHistoricalIPOReturns = '/stats/historical-ipo-returns',
  StatsBacked = '/stats/backed',
  StatsGlobalBreakdown = '/stats/global-breakdown',
  StatsRecentIposReturns = '/stats/recent-ipos-returns',
  AdditionalStats = '/stats/additional-stats',
  AdditionalStatsLargestUSIPOs = '/stats/additional-stats/largest-us-ipos',
  AdditionalStatsLargestGlobalIPOs = '/stats/additional-stats/largest-global-ipos',
  AdditionalStatsPricingsComparedToRange = '/stats/additional-stats/pricings-to-range',
  AdditionalStatsUSListingsFromNonUSIssuers = '/stats/additional-stats/us-listings-non-us-issuers',
  AdditionalStatsProfitableVsUnprofitable = '/stats/additional-stats/profitable-vs-unprofitable',
  AdditionalStatsWithdrawals = '/stats/additional-stats/withdrawals',
  AdditionalStatsAverageAge = '/stats/additional-stats/average-age',
  AdditionalStatsMedianAge = '/stats/additional-stats/median-age',
  Priced = '/priced',
  Scheduled = '/scheduled',
  Pipeline = '/pipeline',
  PrivateCompanyWatchlist = '/private-company-watchlist',
  PrivateCompanyMonitorList = '/private-company-monitor-list',
  SearchResults = '/search-results',
  NotFound = '/not-found',
  NotAuthorized = '/not-authorized',
  //TODO: Remove when sections will be done
  MasterFilterForm = '/master-filter-form',
  TablesList = '/tables',
  GoogleAnalytics = '/google-analytics',
  AccessLogs = '/access-logs',
  PageView = '/page-view',
  AccountId = '/accountId',
  UserId = '/UserActivity',
  FileActivity = '/fileActivity',
  ExhaustedResources = '/exhaustedResources',
}
