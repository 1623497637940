import React, { useContext, useMemo } from 'react';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import { CompanyLockUpPeriodRowsDto } from './interfaces/CompanyLockUpPeriodRowsDto';
import { NumberHelper } from '../../../../../../shared/helpers/NumberHelper';
import { NumberFormat } from '../../../../../../shared/enums/NumberFormat';
import { CompanyLockUpPeriodRowsName } from './enums/CompanyLockUpPeriodRowsName';
import CompanyProfileSidebarTable from '../company-profile-table/CompanyProfileSidebarTable';

const CompanyLockUpPeriod: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const data = useMemo(() => profile.lockUpPeriod, [profile]);

  const formattedRows: CompanyLockUpPeriodRowsDto[] | undefined = useMemo(
    () =>
      data?.rows.map((row: CompanyLockUpPeriodRowsDto) => ({
        [CompanyLockUpPeriodRowsName.Date]: row.Date,
        [CompanyLockUpPeriodRowsName.DaysToLockup]:
          row[CompanyLockUpPeriodRowsName.DaysToLockup] > 0 ? row[CompanyLockUpPeriodRowsName.DaysToLockup] : 'Expired',
        [CompanyLockUpPeriodRowsName.Shares]:
          row.Shares && NumberHelper.getFormatValue(Number(row.Shares), NumberFormat.FloatWithComma),
      })),
    [data],
  );

  return (
    <>
      {data && (
        <CompanyProfileSideItem className="CompanyLockUpPeriod" title="Lock-Up Period">
          <CompanyProfileSidebarTable {...data} expandedData={formattedRows as CompanyLockUpPeriodRowsDto[]} />
        </CompanyProfileSideItem>
      )}
    </>
  );
};

export default CompanyLockUpPeriod;
