import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { NewsItemTag } from '../interfaces/NewsItemTag';
import { useClassName } from '../../shared/hooks/useClassName';
import './NewsItemTags.scss';

export interface NewsItemProps {
  tags: NewsItemTag[];
}

const NewsItemTags: React.FC<NewsItemProps> = props => {
  const cn = useClassName('NewsItemTags');
  const hasTags = props.tags.length > 0;

  if (!hasTags) {
    return <></>;
  }

  const getTagBody = (tag: NewsItemTag): ReactNode => {
    if (tag.custom) {
      return tag.custom;
    }

    return tag.url ? (
      <Link className={cn('link')} to={tag.url} target={tag.isBlank ? '_blank' : undefined}>
        {tag.title}
      </Link>
    ) : (
      tag.title
    );
  };

  return (
    <ul className={cn()}>
      {props.tags.map((tag, index) => (
        <li
          className={cn('item', {
            [tag.modifier]: !!tag.modifier,
          })}
          key={index}
        >
          {getTagBody(tag)}
        </li>
      ))}
    </ul>
  );
};

export default NewsItemTags;
