import React, { useContext, useMemo } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import { CompanyTab } from '../../../enums/CompanyTab';
import { ProfileData } from '../../../interfaces/ProfileData';
import Favourite from '../../../../shared/components/favourites/Favourite';

const CompanyHeaderFavourites: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);

  const profileData = getTabData(CompanyTab.Profile) as ProfileData;
  const companyKey = useMemo(() => profileData.description?.companyKey, [profileData]);
  const companyName = useMemo(() => profileData.description?.companyName, [profileData]);

  return <>{companyKey && companyName && <Favourite companyKey={companyKey} companyName={companyName} />}</>;
};

export default CompanyHeaderFavourites;
