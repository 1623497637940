import React, { useMemo } from 'react';
import './ViewGridFilterQuickForm.scss';
import FormTextSelect from '../../../../../components/forms/FormTextSelect';
import { ControlSize } from '../../../../../enums/ControlSize';
import { locationOptions } from '../../../../filters/constants/options/locationOptions';
import ViewGridToolbarGroup from '../../view-grid/components/parts/ViewGridToolbarGroup';
import FormTimeFrameSelect from '../../../../../components/forms/FormTimeFrameSelect';
import { ViewAppliedFilters } from '../../view/interfaces/ViewAppliedFilters';
import { ViewFilterName } from '../../../../filters/enum/types/ViewFilterName';
import Form, { FormProps } from '../../../../../components/forms/Form';
import { ViewGridFilterQuickFormOptions } from './interfaces/ViewGridFilterQuickFormOptions';
import FormButtonCheckbox from '../../../../../components/forms/FormButtonCheckbox';
import { FilterSectionsValueService } from '../../../../filters/services/FilterSectionsValueService';
import { useClassName } from '../../../../../hooks/useClassName';
import { useLocation } from 'react-router-dom';
import FormButtonTextValuedCheckbox from '../../../../../components/forms/FormButtonTextValuedCheckbox';
import classNames from 'classnames';

export interface ViewGridFilterQuickFormProps extends ViewGridFilterQuickFormOptions, Partial<FormProps> {
  filters: ViewAppliedFilters;
  onChange: (formFilters: ViewAppliedFilters) => void;
}

const ViewGridFilterQuickForm: React.FC<ViewGridFilterQuickFormProps> = props => {
  const cn = useClassName('ViewFilterQuickForm');

  const urlLocation = useLocation();
  const addCenteringClass =
    urlLocation.pathname === '/scheduled' || urlLocation.pathname === '/pipeline' ? true : false;

  const location = props.filters[ViewFilterName.LocationListingLocation];

  const isIncludeSPACAvailable = useMemo(
    () =>
      FilterSectionsValueService.isIncludeSpacAvailable(location) &&
      (props.hasIncludeSPAC || props.hasIncludeSPACtextFilter),
    [location, props.hasIncludeSPAC, props.hasIncludeSPACtextFilter],
  );
  const isIncludeASharesAvailable = useMemo(
    () => FilterSectionsValueService.isIncludeAsharesAvailable(location) && props.hasIncludeAShares,
    [location, props.hasIncludeAShares],
  );

  const areActionButtonsAvailable = props.hasPostponed || props.hasWithdrawn || props.hasAcquired;

  /* const hasInclude = useMemo(() => isIncludeSPACAvailable || isIncludeASharesAvailable, [
    isIncludeSPACAvailable,
    isIncludeASharesAvailable,
  ]); */

  return (
    <Form
      className={classNames(
        'ViewFilterQuickForm',
        { 'Center-Form': addCenteringClass },
        { 'lg-down-margin': areActionButtonsAvailable },
      )}
      defaultValues={props.filters}
      {...props}
    >
      <ViewGridToolbarGroup className={props.hasLocation ? '' : 'Disable-Select'}>
        <FormTextSelect name={ViewFilterName.LocationListingLocation} options={locationOptions} />
      </ViewGridToolbarGroup>

      <ViewGridToolbarGroup>
        <FormTimeFrameSelect
          timeFrameName={ViewFilterName.OfferTimeFrame}
          minName={ViewFilterName.OfferStartDate}
          maxName={ViewFilterName.OfferEndDate}
          minDate={props.dateRangeMinRestrictionValue}
          maxDate={props.dateRangeMaxRestrictionValue}
          timeFrames={props.timeFrameOptions}
          hasDateRange={props.hasDateRange}
          hideDateRange={props.hideDateRange}
          hasTimeFrame={props.hasTimeFrameRange}
          size={ControlSize.Small}
          isInline
        />
      </ViewGridToolbarGroup>

      <ViewGridToolbarGroup className={classNames(cn('include'), { hasActionButtons: areActionButtonsAvailable })}>
        <>
          {areActionButtonsAvailable && (
            <span className={'action-type-buttons'}>
              {props.hasWithdrawn && (
                <FormButtonCheckbox name={ViewFilterName.Withdrawn} size={ControlSize.Small} label="Withdrawn" />
              )}
              {props.hasPostponed && (
                <FormButtonCheckbox name={ViewFilterName.Postponed} size={ControlSize.Small} label="Postponed" />
              )}
              {props.hasAcquired && (
                <FormButtonCheckbox name={ViewFilterName.Acquired} size={ControlSize.Small} label="Acquired" />
              )}
            </span>
          )}

          {props.hasIncludeSPAC && (
            <FormButtonCheckbox
              className={`${isIncludeSPACAvailable ? '' : 'Disable-Select'}`}
              name={ViewFilterName.IncludeSpac}
              size={ControlSize.Small}
              label="Include SPAC"
            />
          )}
          {props.hasIncludeSPACtextFilter && (
            <FormButtonTextValuedCheckbox
              className={`${isIncludeSPACAvailable ? '' : 'Disable-Select'}`}
              name={'SecurityTypeBlankCheckList'}
              textValue={'include'}
              size={ControlSize.Small}
              label="Include SPAC"
            />
          )}
          {props.hasIncludeAShares && (
            <FormButtonCheckbox
              className={`${isIncludeASharesAvailable ? '' : 'Disable-Select'}`}
              name={ViewFilterName.IncludeChina}
              size={ControlSize.Small}
              label="Include A-Shares"
            />
          )}
        </>
      </ViewGridToolbarGroup>
    </Form>
  );
};

export default ViewGridFilterQuickForm;
