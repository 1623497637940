/* eslint-disable */
export const viewStab = {
  data: {
    id: 13563,
    viewType: 'Scheduled',
    dataTable: {
      columns: [
        {
          name: 'offerDate',
          nestedColumns: [{ name: 'offerDate', title: 'Offer Date', type: 'Date' }],
        },
        {
          name: 'tradeDate',
          nestedColumns: [{ name: 'tradeDate', title: 'Trade Date', type: 'Date' }],
        },
        {
          name: 'research',
          nestedColumns: [{ name: 'research', title: 'Research', type: 'Complex' }],
        },
        {
          name: 'favorite',
          nestedColumns: [{ name: 'favorite', title: 'Favorite', type: 'Boolean' }],
        },
        {
          name: 'companyTicker',
          nestedColumns: [{ name: 'companyTicker', title: 'Company', type: 'Complex' }],
        },
        {
          name: 'LatestSales',
          nestedColumns: [{ name: 'LatestSales', title: 'LTM Sales', type: 'Number' }],
        },
        {
          name: 'LtmEbitMargin',
          nestedColumns: [{ name: 'LtmEbitMargin', title: 'EBIT%', type: 'Number' }],
        },
        {
          name: 'underwriter',
          nestedColumns: [{ name: 'underwriter', title: 'Underwriter', type: 'Text' }],
        },
        {
          name: 'shares',
          nestedColumns: [{ name: 'shares', title: 'Shares', type: 'Number' }],
        },
        {
          name: 'priceRange',
          nestedColumns: [{ name: 'priceRange', title: 'Price Range', type: 'Text' }],
        },
        {
          name: 'dealSize',
          nestedColumns: [{ name: 'dealSize', title: 'Deal Size', type: 'Number' }],
        },
        { name: 'mktCap', nestedColumns: [{ name: 'mktCap', title: 'Mkt Cap', type: 'Number' }] },
      ],
      rows: [
        {
          id: 0,
          noteNumber: 1,
          companyTicker: 'Royalty Pharma (RPRX)',
          companyKey: '29785',
          ticker: 'RPRX',
          companyName: 'Royalty Pharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: 'https://www.ipointelligence.com/Profile/showPDF.aspx?filename=RPRX_061120',
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=RPRX_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=RPRX',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'Royalty Pharma (RPRX)',
            description: 'Buys royalty interests in marketed and late-stage biopharmaceuticals.',
          },
          companyWithDescriptionVisible: 'Royalty Pharma (RPRX)\nBuys royalty interests in marketed and late-stage ...',
          LatestSales: 1860229000.0,
          LatestSalesVisible: '$1860.23',
          LtmEbitMargin: 0.948,
          LtmEbitMarginVisible: '94.8%',
          underwriter: 'JP Morgan',
          underwriterVisible: 'JP Morgan',
          shares: 70000000.0,
          sharesVisible: '$70.00',
          priceRange: { min: 25.0, max: 28.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$25 - $28',
          dealSize: 1855000064.0,
          dealSizeVisible: '$1855.00',
          mktCap: 15777648640.0,
          mktCapVisible: '$15777.65',
          footnote:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        },
        {
          id: 1,
          noteNumber: 2,
          companyTicker: 'GVS (GVS.RC)',
          companyKey: '29596',
          ticker: 'GVS.RC',
          companyName: 'GVS',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=GVS.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=GVS.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'GVS (GVS.RC)',
            description: 'Italian maker of masks and air filtration systems including biohazard masks.',
          },
          companyWithDescriptionVisible: 'GVS (GVS.RC)\nItalian maker of masks and air filtration systems ...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: 0.1976,
          LtmEbitMarginVisible: '19.8%',
          underwriter: 'Goldman',
          underwriterVisible: 'Goldman',
          shares: 61000000.0,
          sharesVisible: '$61.00',
          priceRange: { min: 7.0, max: 8.3, change: 'No Change', currency: '$' },
          priceRangeVisible: '$7 - $8',
          dealSize: 527955000.0,
          dealSizeVisible: '$527.96',
          mktCap: 1514625000.0,
          mktCapVisible: '$1514.63',
          footnote:
            'Capital Research and Management has indicated intent to purchase 5.7 million shares on the IPO (9.3% of the offering).',
        },
        {
          id: 2,
          noteNumber: 3,
          companyTicker: 'PharmaSGP (PSG.GR)',
          companyKey: '29798',
          ticker: 'PSG.GR',
          companyName: 'PharmaSGP',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=PSG.GR_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=PSG.GR',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'PharmaSGP (PSG.GR)',
            description: 'German pharmaceutical company offering non-chemical OTC drugs. ',
          },
          companyWithDescriptionVisible: 'PharmaSGP (PSG.GR)\nGerman pharmaceutical company offering non-chemica...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: 0.3569,
          LtmEbitMarginVisible: '35.7%',
          underwriter: 'Berenberg Bank',
          underwriterVisible: 'Berenberg Bank',
          shares: 6600000.0,
          sharesVisible: '$6.60',
          priceRange: { min: 31.5, max: 36.5, change: 'No Change', currency: '$' },
          priceRangeVisible: '$32 - $37',
          dealSize: 253869000.0,
          dealSizeVisible: '$253.87',
          mktCap: 461580000.0,
          mktCapVisible: '$461.58',
          footnote: 'The selling shareholders have an option to upsize the deal by 1.8 million shares on 6/18.',
        },
        {
          id: 3,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 4,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 5,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 6,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 7,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 8,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 9,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 10,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 11,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 12,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 13,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 14,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 15,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 16,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 17,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 18,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 19,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 20,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
        {
          id: 21,
          noteNumber: null,
          companyTicker: 'SK Biopharma (SKBP.RC)',
          companyKey: '26175',
          ticker: 'SKBP.RC',
          companyName: 'SK Biopharma',
          offerDate: null,
          offerDateVisible: '',
          tradeDate: null,
          tradeDateVisible: '',
          research: {
            rating: null,
            reportLink: null,
            dcfLink: 'https://www.ipointelligence.com/Profile/showExcel.aspx?filename=SKBP.RC_DCF',
            analystNoteLink: 'https://www.ipointelligence.com/IPOResearch/AnalystNotesByTicker.aspx?ticker=SKBP.RC',
          },
          researchVisible: null,
          favorite: false,
          favoriteVisible: 'No',
          companyWithDescription: {
            companyTicker: 'SK Biopharma (SKBP.RC)',
            description: 'South Korean biotech developing treatments for CNS disorders.',
          },
          companyWithDescriptionVisible:
            'SK Biopharma (SKBP.RC)\nSouth Korean biotech developing treatments for CNS...',
          LatestSales: null,
          LatestSalesVisible: '',
          LtmEbitMargin: null,
          LtmEbitMarginVisible: '',
          underwriter: 'Citi',
          underwriterVisible: 'Citi',
          shares: 19578310.0,
          sharesVisible: '$19.58',
          priceRange: { min: 36000.0, max: 49000.0, change: 'No Change', currency: '$' },
          priceRangeVisible: '$36 - $49',
          dealSize: 672612840.0,
          dealSizeVisible: '$672.61',
          mktCap: 2690451704.0,
          mktCapVisible: '$2690.45',
          footnote: null,
        },
      ],
      sort: { column: 'offerDate', direction: 'desc' },
    },
    availableColumns: [],
    appliedFilters: { headquarters: 'Us', offerTimeFrame: 'thisWeek' },
    savedFilters: [
      { filterName: 'test', dataViewId: 12807 },
      { filterName: 'test 2', dataViewId: 12808 },
    ],
    sharedLinks: [
      { linkName: 'aaaaa', dataViewId: 6494 },
      {
        linkName: 'asdasdasd',
        dataViewId: 6493,
      },
      { linkName: 'kl/khl/hk/', dataViewId: 6492 },
      {
        linkName: 'hkl/khl/',
        dataViewId: 6491,
      },
      { linkName: 'kl/kh/', dataViewId: 6490 },
      {
        linkName: 'hkl/khl/',
        dataViewId: 6489,
      },
      { linkName: 'hkl/hkl/', dataViewId: 6488 },
      { linkName: 'iloi', dataViewId: 6487 },
      {
        linkName: '90-90-',
        dataViewId: 6486,
      },
      { linkName: 'asdasd', dataViewId: 1216 },
    ],
    summary: {
      companies: 4,
      companiesVisible: '4',
      proceeds: 3309436904.0,
      proceedsVisible: '$3.31B',
      percentTradingAboveIPOPrice: null,
      percentTradingAboveIPOPriceVisible: null,
      avgPriceVsMidpoint: null,
      avgPriceVsMidpointVisible: null,
      avgFirstDayPop: null,
      avgFirstDayPopVisible: null,
      avgAftermarketReturn: null,
      avgAftermarketReturnVisible: null,
      avgReturnFromIPO: null,
      avgReturnFromIPOVisible: null,
      avgFirstThreeMo: null,
      avgFirstThreeMoVisible: null,
    },
    defaultFilters: { headquarters: 'Us', offerTimeFrame: 'thisWeek' },
    filtersDictionaries: {
      Initial_Performance_Period: [
        { title: '1 week', value: 'oneWkPerf' },
        {
          title: '2 weeks',
          value: 'twoWkPerf',
        },
        { title: '1 month', value: 'oneMoPerf' },
        {
          title: '3 months',
          value: 'threeMoPerf',
        },
        { title: '6 months', value: 'sixMoPerf' },
        { title: '1 year', value: 'oneYrPerf' },
      ],
      Recent_Performance_Period: [
        { title: '1 week', value: 'oneWkPerfRecent' },
        {
          title: '2 weeks',
          value: 'twoWkPerfRecent',
        },
        { title: '1 month', value: 'oneMoPerfRecent' },
        {
          title: '3 months',
          value: 'threeMoPerfRecent',
        },
        { title: '6 months', value: 'sixMoPerfRecent' },
        { title: '1 year', value: 'oneYrPerfRecent' },
      ],
      Founded_Since: [{ title: '2000', value: '2000' }],
      ExchangeRegion: [
        { title: 'Asia Pacific', value: 'Asia Pacific' },
        {
          title: 'Europe',
          value: 'Europe',
        },
        { title: 'North America', value: 'North America' },
        {
          title: 'Latin America',
          value: 'Latin America',
        },
        { title: 'Middle East and Africa', value: 'Middle East and Africa' },
      ],
      Sector_Industry: [
        { value: '35', title: 'Health Care' },
        {
          value: '3510',
          title: 'Health Care Equipment & Services',
          parentValue: '35',
        },
        {
          value: '3520',
          title: 'Pharmaceuticals, Biotechnology & Life Sciences',
          parentValue: '35',
        },
        { value: '351010', title: 'Health Care Equipment & Supplies', parentValue: '3510' },
        {
          value: '352010',
          title: 'Biotechnology',
          parentValue: '3520',
        },
        { value: '352020', title: 'Pharmaceuticals', parentValue: '3520' },
      ],
      Headquarters_City_State_Country: {
        Us: [
          {
            value: '223032',
            title: 'New York',
            parentValue: '223',
          },
          { value: '2230320004', title: 'New York', parentValue: '223032' },
          {
            value: '223',
            title: 'United States',
          },
        ],
        NonUs: [
          { value: '82', title: 'Germany' },
          { value: '106', title: 'Italy' },
          {
            value: '193',
            title: 'South Korea',
          },
          { value: '820000003', title: 'Munich', parentValue: '82' },
          {
            value: '1060000001',
            title: 'Bologna',
            parentValue: '106',
          },
          { value: '1930000002', title: 'Gyonggi', parentValue: '193' },
        ],
        Global: [
          { value: '82', title: 'Germany' },
          { value: '106', title: 'Italy' },
          {
            value: '193',
            title: 'South Korea',
          },
          { value: '223032', title: 'New York', parentValue: '223' },
          {
            value: '820000003',
            title: 'Munich',
            parentValue: '82',
          },
          { value: '1060000001', title: 'Bologna', parentValue: '106' },
          {
            value: '1930000002',
            title: 'Gyonggi',
            parentValue: '193',
          },
          { value: '2230320004', title: 'New York', parentValue: '223032' },
        ],
      },
      Underwriter: [
        { title: 'BBVA', value: 'BBVA' },
        {
          title: 'Berenberg Bank',
          value: 'Berenberg Bank',
        },
        { title: 'BofA Securities', value: 'BofA Securities' },
        {
          title: 'Citi',
          value: 'Citi',
        },
        { title: 'Cowen', value: 'Cowen' },
        {
          title: 'DNB Markets',
          value: 'DNB Markets',
        },
        { title: 'Evercore ISI', value: 'Evercore ISI' },
        {
          title: 'Goldman Sachs',
          value: 'Goldman Sachs',
        },
        { title: 'J.P. Morgan', value: 'J.P. Morgan' },
        {
          title: 'Korea Investment & Securities',
          value: 'Korea Investment & Securities',
        },
        { title: 'Mediobanca', value: 'Mediobanca' },
        {
          title: 'Morgan Stanley',
          value: 'Morgan Stanley',
        },
        { title: 'NH Investment & Securities', value: 'NH Investment & Securities' },
        {
          title: 'Scotiabank',
          value: 'Scotiabank',
        },
        { title: 'SunTrust Robinson Humphrey', value: 'SunTrust Robinson Humphrey' },
        {
          title: 'TD Securities',
          value: 'TD Securities',
        },
        { title: 'UBS Investment Bank', value: 'UBS Investment Bank' },
      ],
      ExchangeCountry: [
        { title: 'Germany', value: 'Germany' },
        {
          title: 'Italy',
          value: 'Italy',
        },
        { title: 'South Korea', value: 'South Korea' },
        { title: 'United States', value: 'United States' },
      ],
      Exchange: [
        { title: 'Borsa Italiana', value: 'Borsa Italiana' },
        {
          title: 'Frankfurt Stock Exchange',
          value: 'Frankfurt Stock Exchange',
        },
        { title: 'Korea Exchange', value: 'Korea Exchange' },
        { title: 'Nasdaq', value: 'Nasdaq' },
      ],
    },
    notes: [
      {
        companyTicker: 'Royalty Pharma (RPRX)',
        note:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      },
      {
        companyTicker: 'GVS (GVS.RC)',
        note:
          'Capital Research and Management has indicated intent to purchase 5.7 million shares on the IPO (9.3% of the offering).',
      },
      {
        companyTicker: 'PharmaSGP (PSG.GR)',
        note: 'The selling shareholders have an option to upsize the deal by 1.8 million shares on 6/18.',
      },
    ],
  },
};
