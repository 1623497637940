import { identity, memoize, pickBy } from 'lodash';
import ApiService from './ApiService';
import { ApiPath } from './enums/ApiPath';
import { NewsLandingDto } from '../../news/interfaces/NewsLandingDto';
import { NewsArticleDto } from '../../news/interfaces/NewsArticleDto';
import { NumberId } from '../types';
import { NewsFilterData } from '../../news/interfaces/NewsFilterData';
import { NewsFilterResultDto } from '../../news/interfaces/NewsFilterResultDto';
import { NewsFilterConfigDto } from '../../news/interfaces/NewsFilterConfigDto';
import { NewsRecapScheduledDto } from '../../news/interfaces/NewsRecapScheduledDto';
import { NewsRecapResearchDto } from '../../news/interfaces/NewsRecapResearchDto';
import { NewsMostViewedDto } from '../../news/interfaces/NewsMostViewedDto';
import { NewsRecapHeadlineDto } from '../../news/interfaces/NewsRecapHeadlineDto';
import { NewsRecapHeadlineType } from '../../news/enums/NewsRecapHeadlineType';
import { newsPerPage } from '../../news/constants/newsConstants';
import { NewsRecapDate } from '../../news/interfaces/NewsRecapDate';
import { stringify } from 'query-string';
import { NewsRecapPcwAdditionsDto } from '../../news/interfaces/NewsRecapPcwAdditionsDto';
//import { pickBy } from 'lodash/fp';

export class NewsApiService {
  static getRecapHeadlines(type: NewsRecapHeadlineType): Promise<NewsRecapHeadlineDto[]> {
    return ApiService.get(ApiPath.NewsRecapHeadlines, {
      params: {
        version: type,
      },
    });
  }

  static getRecapScheduled(): Promise<NewsRecapScheduledDto[]> {
    return ApiService.get(ApiPath.NewsRecapScheduled);
  }

  static getRecapPcwAdditions(): Promise<NewsRecapPcwAdditionsDto[]> {
    return ApiService.get(ApiPath.NewsRecapRecapPcwAdditions);
  }

  static getNewsRecapDate(): Promise<NewsRecapDate> {
    return ApiService.get(ApiPath.NewsRecapDate);
  }

  static getMostViewed(): Promise<NewsMostViewedDto[]> {
    return ApiService.get(ApiPath.NewsMostViewed);
  }

  static getRecapResearches(): Promise<NewsRecapResearchDto[]> {
    return ApiService.get(ApiPath.NewsRecapResearch);
  }

  static getArticle(id: NumberId): Promise<NewsArticleDto> {
    return ApiService.get(`${ApiPath.NewsArticle}/${id}`);
  }

  static getFilterConfig(): Promise<NewsFilterConfigDto> {
    return ApiService.get<NewsFilterConfigDto>(ApiPath.NewsConfig);
  }

  static getNewsLanding(): Promise<NewsLandingDto> {
    return ApiService.get(ApiPath.NewsLanding);
  }

  static getFilteredNews(params: NewsFilterData, count: number = newsPerPage): Promise<NewsFilterResultDto[]> {
    // return ApiService.get(ApiPath.NewsFilter, { params: { ...params, topN: count } });
    return ApiService.get(ApiPath.NewsFilter, {
      params: { ...params, topN: count },
      paramsSerializer: params => {
        return stringify(pickBy(params, identity), { arrayFormat: 'none' });
      },
    });
  }
}

// Cached dictionaries
NewsApiService.getFilterConfig = memoize(NewsApiService.getFilterConfig.bind(NewsApiService));
