import React, { Component } from 'react';
import { ColorScheme } from '../../../../../../enums/ColorScheme';
import ColorSchemeFormatter from '../../../../../formatters/ColorSchemeFormatter';
import { GridCellFormatterCommonProps } from '../../../interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from './CustomCellFormatterWrapper';

interface Props extends GridCellFormatterCommonProps {
  value: string | number;
  colorScheme: ColorScheme;
}

class ColorSchemeCellFormatter extends Component<Props> {
  render() {
    return (
      <CustomCellFormatterWrapper value={this.props.value}>
        <ColorSchemeFormatter value={this.props.value} colorScheme={this.props.colorScheme} />
      </CustomCellFormatterWrapper>
    );
  }
}

export default ColorSchemeCellFormatter;
