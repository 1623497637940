import React from 'react';
import { TooltipContentProps } from '../../../../../../shared/components/tooltip/interface/TooltipContentProps';
import { useClassName } from '../../../../../../shared/hooks/useClassName';
import { ArticlesContentBlock, ArticlesContentElement } from './ArticlesContentBem';
import './ArticlesTooltipContent.scss';
import { PcmlTooltipArticlesDto } from '../../../../../../company/interfaces/PcmlTooltipArticlesDto';

const ArticlesTooltipContent: React.FC<TooltipContentProps<any>> = props => {
  const { data } = props;

  const cn = useClassName(ArticlesContentBlock.Root);

  return (
    <section>
      <div className={cn(ArticlesContentElement.Header)}>
        <h5 className={cn(ArticlesContentElement.Title)}>Articles</h5>
      </div>
      <table className={cn(ArticlesContentElement.Table)}>
        {data.map((item: PcmlTooltipArticlesDto) => {
          return (
            <tr key={item.newsUrl}>
              <td>
                <strong>Source: </strong>
                <a href={item.newsUrl} target="_blank" rel="noopener noreferrer">
                  {item.newsSource} ({item.newsDate})
                </a>
              </td>
            </tr>
          );
        })}
      </table>
    </section>
  );
};

export default ArticlesTooltipContent;
