import { ViewDto } from '../interfaces/api/ViewDto';
import { GridRow } from '../../../../../components/grid/shared/types/GridRow';
import { ViewAppliedFilters } from '../interfaces/ViewAppliedFilters';
import { ViewSummary } from '../interfaces/api/ViewSummary';
import { ViewSharedLink } from '../interfaces/api/ViewSharedLink';
import { Nullable } from '../../../../../types/generics';
import { GridSort } from '../../../../../components/grid/grid/interfaces/GridSort';
import { ViewFiltersDictionaries } from '../../../../filters/interfaces/ViewFiltersDictionaries';
import { ViewNote } from '../interfaces/api/ViewNote';
import { ViewType } from '../enums/ViewType';
import { ViewId } from '../types/ViewId';
import { ViewIdType } from '../enums/ViewIdType';
import { ViewApiService } from '../../../../../api/ViewApiService';
import { ViewGridColumnGroupDto } from '../../view-grid/interfaces/ViewGridColumnGroupDto';
import { ViewSelectedColumnsDto } from '../interfaces/ViewSelectedColumnsDto';
import { ViewSaved } from '../interfaces/api/ViewSaved';
import {
  pricedSPACIPOs,
  seekingMergerTargets,
  pendingSPACMergers,
  scheduledVotes,
  completedDeSPACs,
  liquidatedSPACs,
} from '../constants/SpacsColumns';
import { ViewColumnSystemName } from '../../view-grid/enums/ViewColumnSystemName';

export class ViewDataService {
  static getView(viewType: ViewType, viewId: ViewId = ViewIdType.DefaultView): Promise<ViewDto> {
    return this.isDefaultView(viewId) ? ViewApiService.getByViewType(viewType) : ViewApiService.getById(viewId);
  }

  static isDefaultView(viewId: number): boolean {
    return viewId === ViewIdType.DefaultView;
  }

  static getColumnsGroups(viewData: ViewDto): ViewGridColumnGroupDto[] {
    let columns = viewData.dataTable.columns || [];

    if (viewData?.appliedFilters?.DealStatusSelect === '2') {
      columns = columns.filter(item => pricedSPACIPOs.includes(item.name as ViewColumnSystemName));
    }
    if (viewData?.appliedFilters?.DealStatusSelect === '3') {
      columns = columns.filter(item => seekingMergerTargets.includes(item.name as ViewColumnSystemName));
    }
    if (viewData?.appliedFilters?.DealStatusSelect === '4') {
      columns = columns.filter(item => pendingSPACMergers.includes(item.name as ViewColumnSystemName));
    }
    if (viewData?.appliedFilters?.DealStatusSelect === '5') {
      columns = columns.filter(item => scheduledVotes.includes(item.name as ViewColumnSystemName));
    }
    if (viewData?.appliedFilters?.DealStatusSelect === '6') {
      columns = columns.filter(item => completedDeSPACs.includes(item.name as ViewColumnSystemName));
    }
    if (viewData?.appliedFilters?.DealStatusSelect === '7') {
      columns = columns.filter(item => liquidatedSPACs.includes(item.name as ViewColumnSystemName));
    }

    return columns;
  }

  static getRows(viewData: ViewDto): GridRow[] {
    return viewData.dataTable.rows || [];
  }

  static getAppliedFilters(viewData: ViewDto): ViewAppliedFilters {
    return viewData.appliedFilters || {};
  }

  static getSelectedColumns(viewData: ViewDto): ViewSelectedColumnsDto {
    return (
      viewData.selectedColumns || {
        web: [],
        excel: [],
      }
    );
  }

  static getSummary(viewData: ViewDto): ViewSummary {
    return viewData.summary;
  }

  static getDefaultFilters(viewData: ViewDto): ViewAppliedFilters {
    return viewData.defaultFilters || {};
  }

  static getSavedViews(viewData: ViewDto): ViewSaved[] {
    return viewData.savedViews || [];
  }

  static getSharedLinks(viewData: ViewDto): ViewSharedLink[] {
    return viewData.sharedLinks || [];
  }

  static hasSharedLinks(sharedLinks: ViewSharedLink[]): boolean {
    return sharedLinks.length > 0;
  }

  static getNotes(viewData: ViewDto): ViewNote[] {
    return viewData.notes || [];
  }
  static getFootnote(viewData: ViewDto): string | undefined {
    return viewData.footnote;
  }

  static getSort(viewData: ViewDto): Nullable<GridSort> {
    return viewData.dataTable.sort;
  }

  static getFiltersDictionaries(viewData: ViewDto): ViewFiltersDictionaries {
    return viewData.filtersDictionaries || {};
  }
}
