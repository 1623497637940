import { isNil } from 'lodash';
import { ViewOptions } from '../interfaces/options/ViewOptions';
import { ViewDefaultValues } from '../interfaces/ViewDefaultValues';

export class ViewDefaultValuesService {
  static getDefaultValues(options: ViewOptions): ViewDefaultValues {
    return {
      isFixedHeight: this.getHeightValue(options?.grid?.isFixedHeight),
    };
  }

  private static getHeightValue(isFixedHeight?: boolean): boolean {
    return isNil(isFixedHeight) ? true : isFixedHeight;
  }
}
