import { SearchAutocompleteItem } from '../types/SearchAutocompleteItem';
import { SearchApiService } from '../../../../shared/api/SearchApiService';
import { SearchAutocompleteFactory } from '../factories/SearchAutocompleteFactory';

export class SearchDataServices {
  static async getAutocompleteItems(searchValue: string): Promise<SearchAutocompleteItem[]> {
    const searchItems = await SearchApiService.search(searchValue);

    return SearchAutocompleteFactory.getItems(searchItems);
  }
}
