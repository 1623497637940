import React from 'react';
import { HtmlString } from '../types/HtmlString';
import { StubsCellBlock } from './StubsCellBem';
import { useClassName } from '../hooks/useClassName';
import { ComponentMessage } from '../enums/ComponentMessage';

interface Props {
  value: HtmlString;
}

const StubsCell: React.FC<Props> = props => {
  const cn = useClassName(StubsCellBlock.Root);

  return <div className={cn()}>{props.value ? props.value : ComponentMessage.NoDataShort}</div>;
};

export default StubsCell;
