import React, { ReactNode, useMemo } from 'react';
import { NavItem } from '../nav/types/NavItem';
import TooltipContentList from './TooltipContentList';
import { TooltipContentItem } from './interface/TooltipContentItem';
import { NavItemFactory } from '../nav/factories/NavItemFactory';

interface Props {
  items: NavItem[];
  noData?: ReactNode;
}

const TooltipContentNavList: React.FC<Props> = props => {
  const contentItems = useMemo<TooltipContentItem[]>(() => NavItemFactory.getNavItemControls(props.items), [
    props.items,
  ]);

  return <TooltipContentList items={contentItems} noData={props.noData} />;
};

export default TooltipContentNavList;
