import React, { useState } from 'react';
import './SearchResuts.scss';
import PrimarySection from '../shared/components/primary-section/PrimarySection';
import PrimarySectionRow from '../shared/components/primary-section/PrimarySectionRow';
import { useClassName } from '../shared/hooks/useClassName';
import { SearchResultsBlock, SearchResultsElement } from './searchResultsBem';
import { GridExternalClassName } from '../shared/components/grid/grid/gridBem';
import { useHighlightEffect } from '../shared/hooks/useHighlightEffect';
import { BemService } from '../shared/services/BemService';
import SearchResultsDashboard from './components/searchResultsDashboard/SearchResultsDashboard';

interface Props {
  searchValue: string;
}

const HIGHLIGHT_CLASS_NAMES = [
  GridExternalClassName.Cell,
  BemService.getElement(SearchResultsBlock.Root, SearchResultsElement.SearchValue),
];

const SearchResults: React.FC<Props> = props => {
  const { searchValue } = props;
  const [count, setCount] = useState(0);

  const reRenderForUpdatingHighlight = () => {
    setCount(count + 1);
  };

  const cn = useClassName(SearchResultsBlock.Root);

  useHighlightEffect(searchValue, {
    classNames: HIGHLIGHT_CLASS_NAMES,
  });

  return (
    <PrimarySection className={cn()}>
      <PrimarySectionRow key={searchValue} className={cn(SearchResultsElement.Title)}>
        Search Results for <span className={cn(SearchResultsElement.SearchValue)}>{searchValue}</span>
      </PrimarySectionRow>

      <PrimarySectionRow>
        {/* <SearchResultsTabs onChange={reRenderForUpdatingHighlight} /> */}
        <SearchResultsDashboard onChange={reRenderForUpdatingHighlight} />
      </PrimarySectionRow>
    </PrimarySection>
  );
};

export default SearchResults;
