import React, { useCallback, useContext, useMemo } from 'react';
import './ViewToolbar.scss';
import ViewGridToolbarGroupSavedFilters from './ViewToolbarGroupSavedViews';
import { ViewToolbarOptions } from '../../interfaces/options/ViewToolbarOptions';
import ViewToolbarFileActions from './ViewToolbarFileActions';
import { ViewContext } from '../../ViewContext';
import ViewToolbarGroupAddColumns from './ViewToolbarGroupAddColumns';
import ViewGridToolbarGroupFilterQuick from '../../../view-grid-toolbar-groups/view-grid-toolbar-group-quick-filter/ViewGridToolbarGroupFilterQuick';
import { ViewAppliedFilters } from '../../interfaces/ViewAppliedFilters';
import { updateQuickViewFilters } from '../../store/actions/filter';
import { FilterSectionsValueService } from '../../../../../filters/services/FilterSectionsValueService';
import { ViewFilterName } from '../../../../../filters/enum/types/ViewFilterName';
import { ViewQuickFilterOptions } from '../../interfaces/options/ViewQuickFilterOptions';
import Spacer from '../../../../../../components/Spacer';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { ColorScheme } from '../../../../../../enums/ColorScheme';
import Button from '../../../../../../components/forms/Button';
import { RoutePath } from '../../../../../../enums/RoutePath';

const ViewToolbar: React.FC = () => {
  const { state, dispatch } = useContext(ViewContext);

  const toolbar = state.options.toolbar as ViewToolbarOptions;
  const quickFilter = toolbar.quickFilter;

  let isTimeFrameAvailable =
    FilterSectionsValueService.isTimeFrameAvailable(state.filters[ViewFilterName.DealStatusDealStatusList]) ||
    quickFilter?.hasUpcomingTimeFrame
      ? true
      : false;

  if (state?.filters && state.filters[ViewFilterName.DealStatusSelect] === '5') {
    isTimeFrameAvailable = false;
  }

  const quickFilterVisible = useMemo<ViewQuickFilterOptions>(
    () => ({
      ...quickFilter,
      hideDateRange: isTimeFrameAvailable && quickFilter?.hideDateRange,
      hasDateRange: isTimeFrameAvailable && quickFilter?.hasDateRange,
      hasTimeFrameRange: isTimeFrameAvailable && quickFilter?.hasTimeFrameRange,
    }),
    [isTimeFrameAvailable, quickFilter],
  );

  const hasQuickFilter = useMemo(
    () =>
      quickFilterVisible.hasLocation ||
      quickFilterVisible.hasTimeFrameRange ||
      quickFilterVisible.hasDateRange ||
      quickFilterVisible.hasIncludeAShares ||
      quickFilterVisible.hasWithdrawn ||
      quickFilterVisible.hasPostponed ||
      quickFilterVisible.hasAcquired,
    [
      quickFilterVisible.hasLocation,
      quickFilterVisible.hasTimeFrameRange,
      quickFilterVisible.hasDateRange,
      quickFilterVisible.hasIncludeAShares,
      quickFilterVisible.hasWithdrawn,
      quickFilterVisible.hasPostponed,
      quickFilterVisible.hasAcquired,
    ],
  );

  const hasNoLeftSideComponents = useMemo(() => !(toolbar.modalFilters || toolbar.hasAddColumns || hasQuickFilter), [
    toolbar.modalFilters,
    toolbar.hasAddColumns,
    hasQuickFilter,
  ]);

  const onQuickFilterChange = useCallback(
    (formFilters: ViewAppliedFilters) => {
      if (!state.isLoading) {
        dispatch(updateQuickViewFilters(formFilters));
      }
    },
    [dispatch, state.isLoading],
  );

  return (
    <>
      {toolbar.modalFilters && (
        <ViewGridToolbarGroupSavedFilters hideSavedViews={toolbar.modalFilters.hideSavedViews} />
      )}

      {toolbar.hasAddColumns && <ViewToolbarGroupAddColumns />}

      {quickFilterVisible.hasExpiredLockUp && (
        <Button
          size={ControlSize.Small}
          className={'lock-ups-button'}
          colorScheme={ColorScheme.Primary}
          buttonLink={{ routePath: RoutePath.ScreensExpiredLockUpsInUse }}
        >
          Expired Lock-ups
        </Button>
      )}
      {quickFilterVisible.hasPcwNav && (
        <>
          <Button
            size={ControlSize.Small}
            className={'pc-button'}
            colorScheme={ColorScheme.Primary}
            buttonLink={{ routePath: RoutePath.PrivateCompanyWatchlist }}
          >
            PCW
          </Button>
          <Spacer />
        </>
      )}
      {quickFilterVisible.hasPcmlNav && (
        <>
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          <strong>View other private companies we are monitoring as potential IPOs:</strong>
          <Button
            size={ControlSize.Small}
            className={'pc-button'}
            colorScheme={ColorScheme.Primary}
            buttonLink={{ routePath: RoutePath.PrivateCompanyMonitorList }}
          >
            Monitor List
          </Button>
          <Spacer />
        </>
      )}
      {quickFilterVisible.hasUpcomingLockUp && (
        <Button
          size={ControlSize.Small}
          className={'lock-ups-button'}
          colorScheme={ColorScheme.Primary}
          buttonLink={{ routePath: RoutePath.ScreensLockUpsInUse }}
        >
          Upcoming Lock-up Expirations
        </Button>
      )}

      {hasQuickFilter && (
        <ViewGridToolbarGroupFilterQuick
          {...quickFilterVisible}
          filters={state.filters}
          onChange={onQuickFilterChange}
        />
      )}

      {hasNoLeftSideComponents && <Spacer />}

      <ViewToolbarFileActions />
    </>
  );
};

export default ViewToolbar;
