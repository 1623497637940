import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { SocketInitOptions } from './interfaces/SocketInitOptions';
import { SocketEvents } from './types/SocketEvents';
import { CustomAny } from '../../types/generics';
import { SocketEventResponse } from './types/SocketEventResponse';
import { SOCKET_URL } from '../../constants/environment';

export class SocketService<Event extends string, Action extends string> {
  private readonly routePath: string;
  private readonly connection: HubConnection;
  private events: SocketEvents<Event> = {};

  constructor(options: SocketInitOptions<Event>) {
    this.routePath = options.routePath;
    this.connection = this.createConnection();
    this.subscribeEvents(options.events || this.events);
  }

  async start(): Promise<void> {
    return await this.connection.start();
  }

  async stop(): Promise<void> {
    return await this.connection.stop();
  }

  async invoke<T = undefined, K = CustomAny>(action: Action, data: T): Promise<K | void> {
    return await this.connection.invoke(action, data);
  }

  subscribeEvents(events: SocketEvents<Event>): void {
    this.events = events;

    this.eventNames.forEach(event => {
      this.connection.on(event, (response: SocketEventResponse<CustomAny>) => this.events[event]?.(response.data));
    });
  }

  unSubscribeEvent(eventToUnsubscribe: Event): void {
    this.eventNames.forEach(event => {
      if (event === eventToUnsubscribe) {
        this.connection.off(event, (response: SocketEventResponse<CustomAny>) => this.events[event]?.(response.data));
        delete this.events[event];
      }
    });
  }

  get isConnected(): boolean {
    return this.connection.state === HubConnectionState.Connected;
  }

  private createConnection(): HubConnection {
    return new signalR.HubConnectionBuilder()
      .withUrl(this.connectionUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .configureLogging(signalR.LogLevel.None)
      .withAutomaticReconnect()
      .build();
  }

  private get eventNames(): Event[] {
    return Object.keys(this.events) as Event[];
  }

  public get eventNamesLength(): number {
    return Object.keys(this.events).length;
  }

  private get connectionUrl(): string {
    return `${SOCKET_URL}${this.routePath}`;
  }
}
