import { ViewTypeScreen } from '../types/screen/ViewTypeScreen';
import { ViewType } from '../enums/ViewType';

export const viewTypeScreens: ViewTypeScreen[] = [
  ViewType.InvestorsInUse,
  ViewType.LocationInUse,
  ViewType.IndustryInUse,
  ViewType.UnderwriterInUse,
  ViewType.EstimatesInUse,
  ViewType.CustomInUse,
  ViewType.InvestorsLanding,
  ViewType.LocationLanding,
  ViewType.IndustryLanding,
  ViewType.UnderwriterLanding,
];
