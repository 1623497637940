import React, { useMemo } from 'react';
import { RoutePath } from '../../enums/RoutePath';
import { Url } from '../../types';
import { Link } from 'react-router-dom';

export interface HyperlinkCommonProps {
  isExternal?: boolean;
  title?: string;
  className?: string;
  isBlank?: boolean;
}

interface Props extends HyperlinkCommonProps {
  to: RoutePath | Url;
}

const Hyperlink: React.FC<Props> = props => {
  const { isExternal, isBlank } = props;

  const blankProps = useMemo(() => (isBlank ? { target: '_blank', rel: 'noopener noreferrer' } : {}), [isBlank]);

  return isExternal ? (
    <a className={props.className} href={props.to} title={props.title} {...blankProps}>
      {props.children}
    </a>
  ) : (
    <Link className={props.className} to={props.to as string} title={props.title} {...blankProps}>
      {props.children}
    </Link>
  );
};

export default Hyperlink;
