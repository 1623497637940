import React from 'react';

interface Props {
  isAdvanced: boolean;
}

const ViewFilterHeader: React.FC<Props> = props => {
  return <span className={'Filter-Header'}>{props.isAdvanced ? 'Advanced Filter' : 'Filter'}</span>;
};

export default ViewFilterHeader;
