import React from 'react';
import './DashboardSpecialReportsWidgetList.scss';
import DashboardSpecialReportsWidgetItem from './DashboardSpecialReportsWidgetItem';
import { ReportDto } from '../../../../special-reports/interfaces/ReportDto';

interface Props {
  specialReports: ReportDto[];
}

const DashboardSpecialReportsWidgetList: React.FC<Props> = props => {
  return (
    <ul className="DashboardSpecialReportsWidgetList">
      {props.specialReports.map((specialReport: ReportDto) => (
        <DashboardSpecialReportsWidgetItem
          key={specialReport.title}
          title={specialReport.title}
          date={specialReport.reportDate}
          filePath={specialReport.link}
        />
      ))}
    </ul>
  );
};

export default DashboardSpecialReportsWidgetList;
