import { DateRange } from '../types';
import isEqual from 'date-fns/isEqual';

export class DateRangeHelper {
  static isEqual(...ranges: DateRange[]): boolean {
    const firstRange: DateRange | undefined = ranges[0];

    return ranges.slice(1).every(range => isEqual(firstRange[0], range[0]) && isEqual(firstRange[1], range[1]));
  }
}
