import React, { useCallback } from 'react';
import './MyFavorites.scss';
import PrimarySection from '../shared/components/primary-section/PrimarySection';
import { RoutePath } from '../shared/enums/RoutePath';
import DocumentHead from '../shared/components/DocumentHead';
import SavedViews from './components/saved-views/SavedViews';
import FavoriteIpos from './components/favorite-ipos/FavoriteIpos';
import { PrimarySectionIndent } from '../shared/components/primary-section/PrimarySectionIndent';
import { useClassName } from '../shared/hooks/useClassName';
import { MyFavoritesBlock } from './MyFavoritesBem';
import ViewDashboard from '../shared/entities/view/components/view-dashboard/ViewDashboard';
import { FavoritesApiService } from '../shared/api/FavoritesApiService';

const MyFavorites: React.FC = () => {
  const cn = useClassName(MyFavoritesBlock.Root);

  const getDataFn = useCallback(() => FavoritesApiService.getDashboard(), []);

  return (
    <ViewDashboard getDataFn={getDataFn} dashboardType={'MyFavoritesDashboard'}>
      <DocumentHead path={RoutePath.MyFavourites} />

      <PrimarySection className={cn()} indent={PrimarySectionIndent.Compact}>
        <SavedViews header="Saved Views" />

        <FavoriteIpos />
      </PrimarySection>
    </ViewDashboard>
  );
};

export default MyFavorites;
