import React, { useMemo } from 'react';
import TextSelect, { TextSelectProps } from '../../../../../../components/forms/components/text-select/TextSelect';
import { StatsElementFilterInternalName } from '../../../../enums/StatsElementGridFilterName';
import { ControlSize } from '../../../../../../enums/ControlSize';

const StatsElementFilterInternalTextSelect: React.FC<TextSelectProps> = props => {
  const defaultFilters: string[] = useMemo(
    () => [StatsElementFilterInternalName.NoFilter, StatsElementFilterInternalName.Default],
    [],
  );

  const isHidden = useMemo(() => props.value && defaultFilters.includes(props.value as string), [
    props.value,
    defaultFilters,
  ]);

  return <>{!isHidden && <TextSelect {...props} size={ControlSize.Small} />}</>;
};

export default StatsElementFilterInternalTextSelect;
