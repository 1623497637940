import { VideosDto } from '../../videos/shared/interfaces/VideosDto';
import ApiService from './ApiService';
import { ApiPath } from './enums/ApiPath';

export class VideosApiService {
  static getLatestVideo(videoId: string): Promise<VideosDto> {
    return ApiService.get(ApiPath.VideoById, {
      params: {
        id: videoId,
      },
    });
  }
  static getLatestVideos(): Promise<VideosDto[]> {
    return ApiService.get(ApiPath.VideosByType, {
      params: {
        id: 2,
      },
    });
  }
  static getVideoTutorials(): Promise<VideosDto[]> {
    return ApiService.get(ApiPath.VideosByType, {
      params: {
        id: 1,
      },
    });
  }
}
