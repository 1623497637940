export enum CompanyRatingText {
  Weak = 'Weak',
  VeryWeak = 'Very Weak',
  VWeak = 'V. Weak',
  Neutral = 'Neutral',
  Strong = 'Strong',
  VeryStrong = 'Very Strong',
  VStrong = 'V. Strong',
  NoRating = 'N/R',
}
