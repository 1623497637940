export enum ContactFormField {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Company = 'company',
  JobTitle = 'jobTitle',
  Comment = 'comment',
  Agree = 'agree',
  Captcha = 'recaptchaToken',
}
