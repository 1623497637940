import React from 'react';
import './DragDropItemStatic.scss';

interface DragDropItemStatic {
  itemComponent: React.ReactNode;
}

const DragDropItemStatic: React.FC<DragDropItemStatic> = props => <>{props.itemComponent}</>;

export default DragDropItemStatic;
