import pipe from 'lodash/fp/pipe';
import { CalendarEvent } from '../../shared/entities/calendar/interfaces/CalendarEvent';
import { CalendarEventType } from '../../shared/entities/calendar/enums/CalendarEventType';

export class MasterCalendarFilterService {
  static filter(
    events: CalendarEvent[],
    filters: {
      activeEventTypes: CalendarEventType[];
    },
  ): CalendarEvent[] {
    return pipe(this.filterByTypes.bind(this, filters.activeEventTypes))(events);
  }

  private static filterByTypes(activeEventTypes: CalendarEventType[], events: CalendarEvent[]): CalendarEvent[] {
    return events.filter(event => activeEventTypes.includes(event.type));
  }
}
