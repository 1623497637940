import React, { useContext, useMemo } from 'react';
import './MasterCalendarBody.scss';
import { MasterCalendarContext } from '../MasterCalendarContext';
import Calendar from '../../shared/entities/calendar/Calendar';
import { ResponsiveCalendarMode } from '../../shared/components/responsive-calendar/enums/ResponsiveCalendarMode';
import { CalendarDataService } from '../../shared/entities/calendar/services/CalendarDataService';

interface Props {
  mode: ResponsiveCalendarMode;
  weeksCount?: number;
  hasHeader?: boolean;
  height?: number;
}

const MasterCalendarBody: React.FC<Props> = props => {
  const { filteredEvents, headquarter, allEvents, isSpac } = useContext(MasterCalendarContext);

  const visibleRange = useMemo(() => CalendarDataService.getEventsDateRange(allEvents), [allEvents]);

  return (
    <Calendar
      className="MasterCalendarBody"
      events={filteredEvents}
      headquarter={headquarter}
      isSpac={isSpac}
      mode={props.mode}
      weeksCount={props.weeksCount}
      hasHeader={props.hasHeader}
      height={props.height}
      validRange={visibleRange}
    />
  );
};

export default MasterCalendarBody;
