import Mark from 'mark.js';
import './Highlight.scss';
import { HighlightBlock } from './highlightBlock';
import { HighlightOptions } from './interfaces/HighlightOptions';
import { DomService } from '../dom/DomService';

export class HighlightService {
  static startHighlight(value: string, options: HighlightOptions): void {
    const classesSelector = DomService.getClassesSelector(options.classNames);
    const mark = new Mark(classesSelector);
    setTimeout(() => {
      mark.unmark();
      mark.mark(value, {
        element: 'span',
        className: HighlightBlock.Root,
        accuracy: 'partially',
        separateWordSearch: false,
      });
    }, 0);
  }

  static stopHighlight(interval: number): void {
    clearInterval(interval);
  }
}
