import React from 'react';
import NewsLandingSection from './NewsLandingSection';
import { NewsSectionType } from '../../enums/NewsSectionType';
import './NewsLandingSections.scss';
import { useNewsLandingData } from '../../hooks/useNewsLandingData';

const NewsLandingSections: React.FC = () => {
  const [data, isLoading] = useNewsLandingData();

  return (
    <div className="NewsLandingSections">
      <NewsLandingSection
        isLoading={isLoading}
        type={NewsSectionType.RcArticles}
        items={data[NewsSectionType.RcArticles]}
      />

      <NewsLandingSection
        isLoading={isLoading}
        type={NewsSectionType.UsExternalNews}
        items={data[NewsSectionType.UsExternalNews]}
      />

      <NewsLandingSection
        isLoading={isLoading}
        type={NewsSectionType.NonUsExternalNews}
        items={data[NewsSectionType.NonUsExternalNews]}
      />
    </div>
  );
};

export default NewsLandingSections;
