import React from 'react';
import Helmet from 'react-helmet';
import MultipleQuotes from '../shared/MultipleQuotes';
import HeaderLine from '../shared/HeaderLine';
import TrialRequestForm from './form/TrialRequestForm';
import './Request.scss';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import { PrimarySectionIndent } from '../../shared/components/primary-section/PrimarySectionIndent';
import { ColorScheme } from '../../shared/enums/ColorScheme';
import PrimarySectionRow from '../../shared/components/primary-section/PrimarySectionRow';
import { AlignTypesHorizontal } from '../../shared/enums/AlignTypesHorizontal';
import CarouselWithAllClients from '../shared/CarouselWithAllClients';
import { OptimizedPictureType } from '../shared/optimized-picture/enums/OptimizedPictureType';
import FeatureNoTrialButton from '../shared/FeatureNoTrialButton';

const Request: React.FC = () => {
  return (
    <div className="Request">
      <Helmet>
        <title>Request &mdash; Renaissance Capital</title>
      </Helmet>
      <FeatureNoTrialButton
        header={
          <span>
            Obtain actionable,
            <span className="d-lg-block d-xl-block" /> independent ipo insights
          </span>
        }
        optimizedPictureType={OptimizedPictureType.ResearchMeeting}
      >
        Renaissance Capital’s IPO Intelligence research platform brings you comprehensive, unbiased pre-IPO
        <span className="d-lg-block d-xl-block" /> research and models on U.S. and international IPOs, so you can
        separate the good IPOs from the bad.
      </FeatureNoTrialButton>
      <PrimarySection header={'Get started with IPO intelligence'}>
        <PrimarySectionRow alignHorizontal={AlignTypesHorizontal.Center} className="Request__description">
          <p>
            Qualified institutional investors can fill out the form below to request a trial of IPO Intelligence today.
          </p>
        </PrimarySectionRow>

        <PrimarySectionRow>
          <TrialRequestForm />
        </PrimarySectionRow>
      </PrimarySection>

      <PrimarySection indent={PrimarySectionIndent.Internal} colorScheme={ColorScheme.Primary}>
        <HeaderLine>Trusted by the smartest investors and deal makers</HeaderLine>
      </PrimarySection>

      <PrimarySection>
        <PrimarySectionRow className="Request__value">
          IPO Intelligence clients value our research. We have a 92% renewal rate and many accounts have been with us
          since we launched in 1991.
        </PrimarySectionRow>
      </PrimarySection>

      <PrimarySection indent={PrimarySectionIndent.Internal}>
        <CarouselWithAllClients />
      </PrimarySection>

      <PrimarySection>
        <MultipleQuotes />
      </PrimarySection>
    </div>
  );
};

export default Request;
