import React, { useMemo } from 'react';
import './Stepper.scss';
import Step, { StepProps } from './Step';
import classNames from 'classnames';
import { isNumber } from 'lodash/fp';
import { StepperService } from './services/StepperService';

export interface StepperProps {
  activeStep?: number;
  steps: StepProps[];
  isAbsoluteStepTitles?: boolean;
  className?: string;
}

const Stepper: React.FC<StepperProps> = props => {
  const { isAbsoluteStepTitles = true } = props;

  const isActiveStep = useMemo(() => isNumber(props.activeStep), [props.activeStep]);

  return (
    <div className={classNames('Stepper', props.className)}>
      {props.steps.map((item: StepProps, index: number) => (
        <Step
          key={index}
          {...item}
          stepStatus={isActiveStep ? StepperService.getStepStatus(props.activeStep as number, index) : item.stepStatus}
          isAbsoluteTitle={isAbsoluteStepTitles}
        />
      ))}
    </div>
  );
};

export default Stepper;
