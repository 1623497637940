import React from 'react';
import { NewsItemTag } from '../interfaces/NewsItemTag';
import { NewsTagModifier } from '../enums/NewsTagModifier';
import ViewLink from '../../shared/entities/view/components/view-link/ViewLink';
import { ViewReqParamsBase } from '../../shared/entities/view/components/view/interfaces/api/ViewReqParamsBase';
import { getExchangeViewLinkData } from '../../shared/entities/view/components/view-link/constants/viewLinks';

export function getNewsExchangeTag(item: {
  exchangeName?: string;
  exchangeCountryKey?: number;
}): NewsItemTag | undefined {
  // In accordance with https://wiki.itransition.com/pages/viewpage.action?pageId=606978107

  if (item.exchangeName && item.exchangeCountryKey) {
    const linkData: ViewReqParamsBase = getExchangeViewLinkData(item.exchangeCountryKey.toString());

    return {
      title: item.exchangeName,
      modifier: NewsTagModifier.Purple,
      custom: item.exchangeCountryKey && <ViewLink title={item.exchangeName} data={linkData} />,
    };
  }
}
