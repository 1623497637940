import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './Accordion.scss';
import AccordionHeader from './AccordionHeader';
import AccordionContent from './AccordionContent';
import { BreakpointName } from '../../enums/BreakpointName';
import useBreakpointAvailable from '../../effects/useBreakpointAvailable';
import { AccordionCloseState } from './types/AccordionCloseState';
import { ControlSize } from '../../enums/ControlSize';
import { useClassName } from '../../hooks/useClassName';
import { AccordionBlock, AccordionModifier } from './accordionBem';

export interface AccordionProps {
  title: React.ReactNode;
  hasHeader?: boolean;
  isFullHeight?: boolean;
  breakpoints?: BreakpointName[];
  closeState?: AccordionCloseState;
  classNames?: string;
  isIconPin?: boolean;
  isAvailable?: boolean;
  size?: ControlSize;
  hasAnimation?: boolean;
  onHeaderClick?: (isAvailable: boolean) => void;
  onScheduled?: boolean;
}

const Accordion: React.FC<AccordionProps> = props => {
  const { isAvailable: isAvailableExternal = true, hasHeader = true, hasAnimation = true } = props;

  const cn = useClassName(AccordionBlock.Root, props.classNames);

  //  eslint-disable-next-line
  const [isClose, setIsClose] = props.closeState || useState<boolean>(false);
  const [isAvailable, setIsAvailable] = useState(true);
  const isBreakpointAvailable: boolean = useBreakpointAvailable(props.breakpoints);
  const hasAnimationInner = useMemo(() => hasAnimation && isAvailable, [hasAnimation, isAvailable]);

  useEffect(() => {
    if (isBreakpointAvailable && isAvailableExternal) {
      setIsAvailable(true);
    } else {
      setIsAvailable(false);
      setIsClose(false);
    }
  }, [isBreakpointAvailable, isAvailable, isAvailableExternal, setIsClose]);

  const onHeaderClick = useCallback((): void => {
    if (isAvailable) {
      if (props.onScheduled) {
        localStorage.setItem('pref-scheduledCalendarPreviewClose', '' + !isClose);
      }
      setIsClose(!isClose);
    }

    props.onHeaderClick?.(isAvailable);
  }, [isClose, isAvailable, setIsClose, props.onHeaderClick]);

  return (
    <section
      className={cn({
        [`${props.size}`]: props.size,
        [AccordionModifier.FullHeight]: props.isFullHeight,
      })}
    >
      {hasHeader && (
        <AccordionHeader
          isAccordionAvailable={isAvailable}
          title={props.title}
          isClose={isClose}
          isIconPin={props.isIconPin}
          onClick={onHeaderClick}
        />
      )}

      <AccordionContent isClose={isClose} hasAnimation={hasAnimationInner}>
        {props.children}
      </AccordionContent>
    </section>
  );
};

export default Accordion;
