import React from 'react';
import ScreensLanding from '../../shared/pages/components/landing/ScreensLanding';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { useScreensLocationData } from './hooks/useScreensLocationData';
import { screensLocationLandingOptions } from './constants/screensLocationLandingOptions';

const ScreensLocationLanding: React.FC = () => {
  const { options, events } = useScreensLocationData(screensLocationLandingOptions);

  return <ScreensLanding landingType={ViewType.LocationLanding} options={options} events={events} />;
};

export default ScreensLocationLanding;
