import { MARKETING_SITE_URL } from './environment';

export const RENAISSANCE_CAPITAL_WEBSITE = 'https://www.renaissancecapital.com';
export const NEWSLETTER_URL = 'https://ipoupdates.renaissancecapital.com/';
export const ABOUT_RESEARCH_URL = 'https://www.renaissancecapital.com/Docs/About-Research.pdf';
export const RENAISSANCE_CAPITAL_FACEBOOK_URL = 'https://web.facebook.com/RenaissanceCapitalIPO/?_rdc=1&_rdr';
export const RENAISSANCE_CAPITAL_TWITTER_URL = 'https://twitter.com/IPOtweet';
export const RENAISSANCE_CAPITAL_LINKEDIN_URL = 'https://www.linkedin.com/company/renaissance-capital_3';
export const RENAISSANCE_CAPITAL_YOUTUBE_URL = 'https://www.youtube.com/@RenaissanceCapitalIPO';
export const IMPORTANT_DISCLOSURES_URL = 'https://ipopro.renaissancecapital.com/Disclosures';
export const IPO_INTELLIGENCE_MARKETING_SITE_OVERVIEW_URL = `${MARKETING_SITE_URL}/overview`;
export const IPO_INTELLIGENCE_MARKETING_SITE_RESEARCH_URL = `${MARKETING_SITE_URL}/ipo-research`;
export const IPO_INTELLIGENCE_MARKETING_SITE_FEATURES_URL = `${MARKETING_SITE_URL}/features`;
export const IPO_INTELLIGENCE_MARKETING_SITE_WHO_USES_US_URL = `${MARKETING_SITE_URL}/who-uses-us`;
export const IPO_INTELLIGENCE_MARKETING_SITE_PRICING_URL = `${MARKETING_SITE_URL}/pricing`;
export const IPO_INTELLIGENCE_MARKETING_SITE_ABOUT_URL = `${MARKETING_SITE_URL}/about`;
export const IPO_INTELLIGENCE_MARKETING_SITE_DISCLOSURES_URL = `${MARKETING_SITE_URL}/disclosures`;
