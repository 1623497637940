import React, { useState } from 'react';
import { PasswordFormFields } from './password-form-fields';
import { validationSchema } from './validation-schema';
import { InputType } from '../../../shared/components/forms/Input';
import { PasswordFormData } from './password-form-data';
import Form from '../../../shared/components/forms/Form';
import FormGroup from '../../../shared/components/forms/FormGroup';
import AuthApiService from '../../../shared/api/AuthApiService';
import ButtonSubmit from '../../../shared/components/forms/ButtonSubmit';
import { SubmitFormFn } from '../../../shared/components/forms/types/SubmitFormFn';
import FormInput from '../../../shared/components/forms/FormInput';
import { ControlSize } from '../../../shared/enums/ControlSize';
import { AlignTypesHorizontal } from '../../../shared/enums/AlignTypesHorizontal';

const PasswordForm: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const onSubmit: SubmitFormFn<PasswordFormData> = data => {
    return AuthApiService.recoverPassword(data).then(() => setIsSubmitted(true));
  };

  return (
    <Form
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      size={ControlSize.Medium}
      alignHorizontal={AlignTypesHorizontal.Center}
    >
      <FormGroup>
        <FormInput
          name={PasswordFormFields.Email}
          label="Email"
          type={InputType.Email}
          autoComplete="email"
          isDisabled={isSubmitted}
        />
      </FormGroup>

      <ButtonSubmit message="Send Email" submittedMessage="Instructions sent to your mail" isSubmitted={isSubmitted} />
    </Form>
  );
};

export default PasswordForm;
