import React from 'react';
import './MasterCalendarLegendItemWrapper.scss';
import { CalendarEventType } from '../../shared/entities/calendar/enums/CalendarEventType';
import { useClassName } from '../../shared/hooks/useClassName';
import { MasterCalendarLegendItemWrapperBlock } from './masterCalendarLegendItemWrapperBem';

interface Props {
  type: CalendarEventType;
  className?: string;
}

const MasterCalendarLegendItemWrapper: React.FC<Props> = props => {
  const cn = useClassName(MasterCalendarLegendItemWrapperBlock.Root, props.className);

  return (
    <div
      className={cn({
        [props.type]: props.type,
      })}
    >
      {props.children}
    </div>
  );
};

export default MasterCalendarLegendItemWrapper;
