import React, { memo, useMemo } from 'react';
import FormCheckbox from './FormCheckbox';
import { CheckboxType } from './Checkbox';
import FormGroup from './FormGroup';
import { CheckboxOptions } from './interfaces/CheckboxOptions';
import { useFormContext } from 'react-hook-form';

export interface FormCheckboxListProps {
  name: string;
  options: CheckboxOptions[];
  type: CheckboxType;
}

const FormCheckboxList: React.FC<FormCheckboxListProps> = props => {
  const formContext = useFormContext();
  const { watch, setValue } = formContext;
  const value = watch(props.name);

  const options = useMemo(
    () =>
      props.options.filter(option => {
        // if (option.dependsFrom) {
        //   return !!value && value.includes(option.dependsFrom);
        // }
        return true;
      }),
    [props.options, value],
  );

  const onClick = (e: any, y: any) => {
    //Postponed depends on Filed
    if (y?.target?.value === 'Postponed') {
      if (Array.isArray(value) && !value.includes('Postponed') && !value.includes('Filed')) {
        value.push('Filed');
        value.push('Postponed');
        setValue(props.name, value);
      }
    }
    if (y?.target?.value === 'Filed') {
      if (Array.isArray(value) && value.includes('Filed') && value.includes('Postponed')) {
        value.splice(value.indexOf('Postponed'), 1);
        value.splice(value.indexOf('Filed'), 1);
        setValue(props.name, value);
      }
    }
  };

  return (
    <>
      {options.map(option => {
        const value = option.value as string | number;

        return (
          <FormGroup key={value} hasNoIndentVertical hasShiftHorizontal={!!option.dependsFrom}>
            <FormCheckbox name={props.name} onClick={onClick} value={value} label={option.title} type={props.type} />
          </FormGroup>
        );
      })}
    </>
  );
};

export default memo(FormCheckboxList);
