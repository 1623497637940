export enum ActionModalBlock {
  Root = 'ActionModal',
}

export enum ActionModalElement {
  Body = 'body',
  Actions = 'actions',
  Submit = 'submit',
  Cancel = 'cancel',
}
