import { FilterControlSettings } from '../../../types/FilterControlSettings';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { ControlSize } from '../../../../../enums/ControlSize';
import { TimeFrame } from '../../../enum/TimeFrame';

export const offerDateControls: FilterControlSettings = {
  [ViewFilterName._OfferDate]: {
    type: FormControlType.TimeFrameSelect,
    name: ViewFilterName._OfferDate,
    props: {
      timeFrameName: ViewFilterName.OfferTimeFrame,
      timeFrames: [
        TimeFrame.ThisWeek,
        TimeFrame.NextWeek,
        TimeFrame.All,
        TimeFrame.Week,
        TimeFrame.Month,
        TimeFrame.Quarter,
        TimeFrame.YTD,
        TimeFrame.Year,
        TimeFrame.TwoYears,
        TimeFrame.FiveYears,
      ],
      minName: ViewFilterName.OfferStartDate,
      maxName: ViewFilterName.OfferEndDate,
      size: ControlSize.Small,
      isUnselectAvailable: true,
    },
  },
};
