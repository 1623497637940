import React, { useMemo } from 'react';
import './ViewDashboardSecondarySection.scss';
import { Link } from 'react-router-dom';
import {
  ViewDashboardSecondarySectionBlock,
  ViewDashboardSecondarySectionElement,
} from './viewDashboardSecondarySectionBem';
import { useClassName } from '../../../../../hooks/useClassName';
import SecondarySection from '../../../../../components/secondary-section/SecondarySection';

interface Props {
  title?: string;
  routePath?: string;
}

const ViewDashboardSecondarySection: React.FC<Props> = props => {
  const cn = useClassName(ViewDashboardSecondarySectionBlock.Root);
  const hasTitle = useMemo(() => props.title, [props.title]);

  const title = useMemo(
    () =>
      props.routePath ? (
        <Link className={cn(ViewDashboardSecondarySectionElement.Title)} to={props.routePath}>
          {props.title}
        </Link>
      ) : (
        <div className={cn(ViewDashboardSecondarySectionElement.Title)}>{props.title}</div>
      ),
    [props.routePath, props.title, cn],
  );

  return (
    <SecondarySection className={cn()}>
      {hasTitle && title}

      <div className={cn(ViewDashboardSecondarySectionElement.Content)}>{props.children}</div>
    </SecondarySection>
  );
};

export default ViewDashboardSecondarySection;
