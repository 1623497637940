import React, { useEffect, useState } from 'react';
import './ClientsAccessLog.scss';
import Helmet from 'react-helmet';
import PrimarySection from '../shared/components/primary-section/PrimarySection';
import { useClassName } from '../shared/hooks/useClassName';
import { GoogleAnalyticsApiService } from '../shared/api/GoogleAnalyticsApiService';
import { cloneDeep } from 'lodash';
import BasicGrid from '../shared/components/basic-grid/BasicGrid';
import { AccessLogDto } from './interfaces/AccessLogDto';
import { ViewDataTable } from '../shared/entities/view/components/view/interfaces/api/ViewDataTable';
import { ViewColumnSystemName } from '../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { ViewColumnDataType } from '../shared/entities/view/components/view-grid/enums/ViewColumnDataType';

const ClientsAccessLog: React.FC = () => {
  // const cn = useClassName('GoogleAnalytics');
  const [accessLogs, setAccessLogs] = useState<any>(null);

  function buildAccessLogs(logs: AccessLogDto[]) {
    const builtLogs: ViewDataTable = {
      sort: null,
      columns: [
        {
          name: 'latestTokenDate',
          nestedColumns: [
            {
              name: ViewColumnSystemName.LatestTokenDate,
              title: 'Latest Date',
              type: ViewColumnDataType.Text,
            },
          ],
        },
        {
          name: 'isActive',
          nestedColumns: [
            {
              name: ViewColumnSystemName.IsActive,
              title: 'Status',
              type: ViewColumnDataType.Boolean,
            },
          ],
        },
        {
          name: 'firstName',
          nestedColumns: [
            {
              name: ViewColumnSystemName.FirstName,
              title: 'First Name',
              type: ViewColumnDataType.Text,
            },
          ],
        },
        {
          name: 'lastName',
          nestedColumns: [
            {
              name: ViewColumnSystemName.LastName,
              title: 'Last Name',
              type: ViewColumnDataType.Text,
            },
          ],
        },

        {
          name: 'email',
          nestedColumns: [
            {
              name: ViewColumnSystemName.Email,
              title: 'Email',
              type: ViewColumnDataType.Text,
            },
          ],
        },
        {
          name: 'accountName',
          nestedColumns: [
            {
              name: ViewColumnSystemName.AccountName,
              title: 'Account Name',
              type: ViewColumnDataType.Text,
            },
          ],
        },
      ],
      rows: logs,
    };
    return builtLogs;
  }

  useEffect(() => {
    async function getAccessLogs() {
      const logs = await GoogleAnalyticsApiService.getAccessLog();

      const builtLogs = buildAccessLogs(logs);
      setAccessLogs(cloneDeep(builtLogs));
    }
    getAccessLogs();
  }, []);

  return (
    <>
      <Helmet>
        <title>Clients Access Log</title>
      </Helmet>

      <PrimarySection header="Clients Access Log">
        <>
          <div>
            <b>
              <a
                href="https://zy226.infusionsoft.com/Reports/searchTemplate.jsp?filterId=988&action=loadFilter&reportClass=CompanySearch&view=resultsPage"
                target="_blank"
                rel="noopener noreferrer"
              >
                Research Expiration Dates
              </a>
            </b>
          </div>
          <br />

          {accessLogs && (
            <>
              <BasicGrid hasSearchBox={true} hasActiveCheck={false} dataTable={accessLogs} />
            </>
          )}
        </>
      </PrimarySection>
    </>
  );
};

export default ClientsAccessLog;
