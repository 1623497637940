import { Context, createContext } from 'react';
import { StatsPagesFiltersSettings } from '../interfaces/StatsPagesFiltersSettings';

export interface StatsContextValue {
  pagesFiltersSettings: StatsPagesFiltersSettings;
}

export const StatsContext: Context<StatsContextValue> = createContext<StatsContextValue>({
  pagesFiltersSettings: {},
});
