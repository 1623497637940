import React, { useContext, useMemo } from 'react';
import NewsList from '../../../../../../news/components/NewsList';
import { CompanyContext } from '../../../../context/CompanyContext';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import { profileDefaultExpandingLength } from '../../constants/profileDefaultExpandingLength';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import './CompanyAnalystNotesAndNews.scss';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyAnalystNotesAndNewsBlock } from './CompanyAnalystNotesAndNewsBem';
import { useClassName } from '../../../../../../shared/hooks/useClassName';

const CompanyAnalystNotesAndNews: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);
  const data = useMemo(() => profile.analystNotesAndNewsData, [profile.analystNotesAndNewsData]);
  const cn = useClassName(CompanyAnalystNotesAndNewsBlock.Root);

  return (
    <>
      {data && (
        <CompanyProfileSideItem
          className={cn()}
          title="Analyst Notes & News"
          component={NewsList}
          componentProps={{
            hasDateTag: true,
            hasProfileLayout: true,
          }}
          expandData={data}
          expandingLength={profileDefaultExpandingLength}
        />
      )}
    </>
  );
};

export default CompanyAnalystNotesAndNews;
