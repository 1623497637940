import { NewsStoryType } from '../enums/NewsStoryType';
import { SelectOption } from '../../shared/services/select/interfaces/SelectOption';
import { newsStoryTypeName } from './newsStoryTypeName';

export const newsStoryTypesSelectOptions: SelectOption[] = [
  {
    value: NewsStoryType.IpoPricing,
    title: newsStoryTypeName[NewsStoryType.IpoPricing],
  },
  {
    value: NewsStoryType.NewIpoFiling,
    title: newsStoryTypeName[NewsStoryType.NewIpoFiling],
  },
  {
    value: NewsStoryType.OtherAnnouncement,
    title: newsStoryTypeName[NewsStoryType.OtherAnnouncement],
  },
  {
    value: NewsStoryType.Postponed,
    title: newsStoryTypeName[NewsStoryType.Postponed],
  },
  {
    value: NewsStoryType.SetTerms,
    title: newsStoryTypeName[NewsStoryType.SetTerms],
  },
  {
    value: NewsStoryType.TermsDown,
    title: newsStoryTypeName[NewsStoryType.TermsDown],
  },
  {
    value: NewsStoryType.TermsUp,
    title: newsStoryTypeName[NewsStoryType.TermsUp],
  },
  {
    value: NewsStoryType.UpdatedFinancials,
    title: newsStoryTypeName[NewsStoryType.UpdatedFinancials],
  },
  {
    value: NewsStoryType.Withdrawn,
    title: newsStoryTypeName[NewsStoryType.Withdrawn],
  },
];
