import { ViewSimpleGridProps } from '../../../../../../../shared/entities/view/components/view-grid/ViewSimpleGrid';
import { useMemo } from 'react';
import useBreakpointAvailable from '../../../../../../../shared/effects/useBreakpointAvailable';
import { mobileBreakpoints } from '../../../../../../../shared/constants/breakpoints/mobileBreakpoints';
import { ColorScheme } from '../../../../../../../shared/enums/ColorScheme';

export const useCompanyProfileSidebarTableProps = (): Partial<ViewSimpleGridProps> => {
  const isMobile = useBreakpointAvailable(mobileBreakpoints);

  return useMemo(
    () => ({
      backgroundColorScheme: isMobile ? ColorScheme.White : ColorScheme.Gray,
      isFullWidth: true,
    }),
    [isMobile],
  );
};
