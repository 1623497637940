import {
  ADD_NOTIFICATION,
  AddNotificationAction,
  CLEAR_NOTIFICATIONS,
  ClearNotifications,
  NotificationActionFn,
  NotificationSuccessMessage,
  REMOVE_NOTIFICATION,
  RemoveNotificationAction,
} from './types';
import { NotificationAdd } from '../../shared/components/notification/interfaces/NotificationAdd';
import { ColorScheme } from '../../shared/enums/ColorScheme';
import { TemplateService } from '../../shared/services/TemplateService';
import { ErrorMessageService } from '../../shared/services/error/ErrorMessageService';
import { ThunkAction } from 'redux-thunk';
import { AppThunk, CustomAny } from '../../shared/types/generics';
import { ErrorHandlerService } from '../../shared/services/error/ErrorHandlerService';
import { GlobalError } from '../../shared/services/error/types/GlobalError';
import { ERROR_TIME, SUCCESS_TIME } from './constants';
import { ErrorData } from '../../shared/services/error/interfaces/ErrorData';
import { HandleFnOptions } from '../../shared/hooks/handle-fn/interfaces/HandleFnOptions';

export function addNotification(toast: NotificationAdd): AddNotificationAction {
  return {
    type: ADD_NOTIFICATION,
    payload: {
      toast,
    },
  };
}

export function removeNotification(id: string): RemoveNotificationAction {
  return {
    type: REMOVE_NOTIFICATION,
    payload: {
      id,
    },
  };
}

export function clearNotifications(): ClearNotifications {
  return {
    type: CLEAR_NOTIFICATIONS,
    payload: {},
  };
}

export function addSuccessNotification(successMessage: NotificationSuccessMessage): AddNotificationAction {
  return addNotification({
    type: ColorScheme.Success,
    text: TemplateService.replace(successMessage.text, successMessage.values),
    showTime: SUCCESS_TIME,
  });
}

export function addErrorNotification(error: ErrorData): AddNotificationAction {
  return addNotification({
    type: ColorScheme.Danger,
    text: ErrorMessageService.getSummaryMessage(error),
    showTime: ERROR_TIME,
  });
}

export function handleErrorNotification(e: GlobalError): AppThunk {
  return dispatch => {
    ErrorHandlerService.handle((error: ErrorData) => {
      dispatch(addErrorNotification(error));
    }, e);
  };
}

export function handleAction(
  actionFn: NotificationActionFn,
  options?: HandleFnOptions,
): ThunkAction<void, {}, {}, CustomAny> {
  const message = options?.message;
  const onError = options?.onError;
  const onFinally = options?.onFinally;

  return async (dispatch): Promise<void> => {
    try {
      await actionFn();

      if (message) {
        dispatch(addSuccessNotification(message));
      }
    } catch (e) {
      dispatch(handleErrorNotification(e));

      if (onError) {
        onError(e);
      }
    } finally {
      if (onFinally) {
        onFinally();
      }
    }
  };
}
