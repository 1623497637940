import React from 'react';
import { SortableElement, SortableElementProps } from 'react-sortable-hoc';
import './DragDropItemDraggable.scss';

interface DragDropItemDraggable extends SortableElementProps {
  itemComponent: React.ReactNode;
}

const DragDropItemDraggable: React.ComponentClass<DragDropItemDraggable> = SortableElement(
  (props: DragDropItemDraggable) => <>{props.itemComponent}</>,
);

export default DragDropItemDraggable;
