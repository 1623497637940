import * as yup from 'yup';
import { StringSchema } from 'yup';

const invalidDomainsRegExp = /@(gmail.com|yahoo.com|aol.com|msn.com|hotmail.com|outlook.com|icloud.com)$/i;

export const businessEmailSchema: StringSchema = yup
  .string()
  .label('Business Email')
  .required()
  // .min(EMAIL_MIN_LENGTH)
  // .max(EMAIL_MAX_LENGTH)
  .email()
  .test(
    'businessEmail',
    'Incorrect format of the email address. Please, enter a Business Email',
    (value: string): boolean => !invalidDomainsRegExp.test(value),
  );
