import { DashboardWidgetConfig } from '../interfaces/DashboardWidgetConfig';
import { isEqual } from 'lodash';
import { dashboardDefaultWidgetsConfigs } from '../constants/dashboardDefaultWidgetsConfigs';
import { DashboardWidgetType } from '../enums/DashboardWidgetType';

export class DashboardWidgetService {
  static areDefaultWidgets(widgets: DashboardWidgetConfig[]): boolean {
    return isEqual(widgets, dashboardDefaultWidgetsConfigs);
  }

  static getWidgetByType(
    widgets: DashboardWidgetConfig[],
    type: DashboardWidgetType,
  ): DashboardWidgetConfig | undefined {
    return widgets.find(item => item.type === type);
  }
}
