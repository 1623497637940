import React from 'react';
import './AccordionActionIcon.scss';
import ToggleIcon from '../icon/ToggleIcon';

interface Props {
  isClose: boolean;
  isAccordionAvailable: boolean;
}

const AccordionActionIcon: React.FC<Props> = props => {
  if (!props.isAccordionAvailable) {
    return <></>;
  }

  return <ToggleIcon isClose={props.isClose} />;
};

export default AccordionActionIcon;
