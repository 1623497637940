import React from 'react';
import { NavItem } from '../types/NavItem';
import { NavItemService } from '../services/NavItemService';
import { NavItemControl } from '../interfaces/NavItemControl';
import { NavLink } from 'react-router-dom';
import { NavItemLink } from '../interfaces/NavItemLink';

export class NavItemFactory {
  static getNavItemControls(items: NavItem[]): NavItemControl[] {
    return items.map(item => this.getNavItemControl(item));
  }

  static getNavItemControl(item: NavItem): NavItemControl {
    const isLink = NavItemService.isNavLink(item);

    return {
      component: isLink ? (
        <NavLink
          exact
          to={(item as NavItemLink).path}
          isActive={(item as NavItemLink)?.isActive}
          onClick={(item as NavItemLink).onClick}
        >
          {item.text}
        </NavLink>
      ) : (
        item.text
      ),
      isHeader: !isLink,
    };
  }
}
