import React, { useMemo, useCallback } from 'react';
import './Calendar.scss';
import { CalendarEvent } from './interfaces/CalendarEvent';
import { CalendarFactory } from './factories/CalendarFactory';
import { CalendarFilterService } from './services/CalendarFilterService';
import { Location } from '../filters/enum/Location';
import ResponsiveCalendar, { ResponsiveCalendarProps } from '../../components/responsive-calendar/ResponsiveCalendar';
import CalendarEventItem from './CalendarEvent';
import { CalendarEventExtendedProps } from './interfaces/CalendarEventExtendedProps';
import { CalendarEventMeta } from './interfaces/CalendarEventMeta';
import { ResponsiveCalendarEvent } from '../../components/responsive-calendar/interfaces/ResponsiveCalendarEvent';
import { useClassName } from '../../hooks/useClassName';
import { CalendarBlock } from './calendarBem';

interface Props extends ResponsiveCalendarProps {
  events?: CalendarEvent[];
  headquarter: Location;
  isSpac?: boolean;
  onScheduled?: boolean;
}

const Calendar: React.FC<Props> = props => {
  const { events = [] } = props;

  const cn = useClassName(CalendarBlock.Root, props.className);

  let filteredEvents = useMemo(() => CalendarFilterService.filterByHeadquarter(props.headquarter, events), [
    props.headquarter,
    events,
  ]);

  if (props.isSpac !== undefined) {
    filteredEvents = CalendarFilterService.filterBySpac(props.isSpac, props.headquarter, filteredEvents);
  }
  const responsiveCalendarEvents = useMemo(() => CalendarFactory.getEvents(filteredEvents), [filteredEvents]);

  const renderEventContent = useCallback((meta: CalendarEventMeta) => {
    const calendarEventItemProps = meta.event.extendedProps as CalendarEventExtendedProps;

    return <CalendarEventItem {...calendarEventItemProps} />;
  }, []);

  const sortEventFn = useCallback(
    (
      eventA: ResponsiveCalendarEvent<CalendarEventExtendedProps>,
      eventB: ResponsiveCalendarEvent<CalendarEventExtendedProps>,
    ) => CalendarFactory.sortEvents(eventA, eventB),
    [],
  );

  return (
    <ResponsiveCalendar
      {...props}
      eventContent={renderEventContent}
      className={cn()}
      events={responsiveCalendarEvents}
      validRange={props.validRange}
      sortEventsFn={sortEventFn}
      onScheduled={props.onScheduled}
    />
  );
};

export default Calendar;
