import React from 'react';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { screensInvestorsInUseOptions } from './constants/screensInvestorsInUseOptions';
import ScreensInUse from '../../shared/pages/components/in-use/ScreensInUse';

const ScreensInvestorsInUse: React.FC = () => {
  return <ScreensInUse inUseType={ViewType.InvestorsInUse} options={screensInvestorsInUseOptions} />;
};

export default ScreensInvestorsInUse;
