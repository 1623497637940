import React from 'react';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { useClassName } from '../../../../../../hooks/useClassName';
import Tooltip from '../../../../../../components/tooltip/Tooltip';
import useBreakpointAvailable from '../../../../../../effects/useBreakpointAvailable';
import { profileMobileBreakpoints } from '../../../../../../../company/components/profile/shared/constants/profileMobileBreakpoints';
import { PcmlNoteCellBlock } from './PcmlNoteCellBem';
import { ControlSize } from '../../../../../../enums/ControlSize';

type Props = GridCellFormatterCommonProps;

const PcmlNoteCell: React.FC<Props> = props => {
  const cn = useClassName(PcmlNoteCellBlock.Root);
  const isMobile = useBreakpointAvailable(profileMobileBreakpoints);
  let PcmlNote;
  if (props.data.pcmlNote) {
    PcmlNote = props.data.pcmlNote;
  }

  let PcmlNoteTitle = '';
  if (props.data.pcmlNote) {
    if (props.data.pcmlNote.length > 12) {
      PcmlNoteTitle = props.data.pcmlNote.substring(0, 12) + '...';
    } else {
      PcmlNoteTitle = props.data.pcmlNote;
    }
  }

  return (
    <>
      {PcmlNote && props.data.pcmlNote.length > 12 && !isMobile ? (
        <CustomCellFormatterWrapper className={cn()} value={props.valueFormatted}>
          <Tooltip
            className={cn()}
            isTitleHighlighted
            isUnderFixed
            size={ControlSize.Medium}
            content={`Note: ` + props.data.pcmlNoteVisible}
            title={PcmlNoteTitle}
          />
        </CustomCellFormatterWrapper>
      ) : (
        PcmlNoteTitle
      )}
    </>
  );
};

export default PcmlNoteCell;
