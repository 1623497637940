import { ColorScheme } from '../../enums/ColorScheme';

export class NotificationService {
  static getTitle(type: ColorScheme): string {
    if (type === ColorScheme.Success) {
      return 'Success';
    } else if (type === ColorScheme.Danger) {
      return 'Error';
    } else {
      return '';
    }
  }
}
