import { ViewOptions } from '../../../shared/entities/view/components/view/interfaces/options/ViewOptions';
import { FilterSectionType } from '../../../shared/entities/filters/enum/FilterSectionType';
import { ViewFilterName } from '../../../shared/entities/filters/enum/types/ViewFilterName';
import { TimeFrame } from '../../../shared/entities/filters/enum/TimeFrame';
import { FileActionType } from '../../../shared/components/file-actions/enums/FileActionType';
import { ViewSummaryAlignType } from '../../../shared/entities/view/components/view/enums/ViewSummaryAlignType';
import { ViewColumnSystemName } from '../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { DatePickerRestrictionDateType } from '../../../shared/components/forms/components/date/enums/DatePickerRestrictionDateType';
import { GridCellFormatterType } from '../../../shared/components/grid/shared/enums/GridCellFormatterType';
import TodaysPriceChangeCell from '../../../shared/entities/view/components/view-grid/components/cells/TodaysPriceChangeCell';

export const pricedViewOptions: ViewOptions = {
  header: 'Recently Priced IPOs',
  toolbar: {
    modalFilters: {
      hasAdvancedBtn: true,
      sections: [
        {
          type: FilterSectionType.OfferDate,
          controls: [
            {
              name: ViewFilterName._OfferDate,
              props: {
                timeFrames: [
                  TimeFrame.Week,
                  TimeFrame.Month,
                  TimeFrame.Quarter,
                  TimeFrame.YTD,
                  TimeFrame.Year,
                  TimeFrame.TwoYears,
                  TimeFrame.FiveYears,
                ],
              },
            },
          ],
        },
        {
          type: FilterSectionType.CompanyInfo,
          controls: [
            { name: ViewFilterName.SectorIndustry },
            { name: ViewFilterName._Headquarters },
            { name: ViewFilterName.FoundedSince },
          ],
        },
        {
          type: FilterSectionType.Investors,
          controls: [
            { name: ViewFilterName.Investor },
            { name: ViewFilterName.SpinOff },
            { name: ViewFilterName.MgmtOwned },
            { name: ViewFilterName.PE },
            { name: ViewFilterName.PeGrowthOther },
            { name: ViewFilterName.VentureCapital },
          ],
        },
        {
          type: FilterSectionType.SecurityType,
          controls: [
            { name: ViewFilterName.SecurityTypeCommonSharesList },
            { name: ViewFilterName.SecurityTypeADRsList },
            { name: ViewFilterName.SecurityTypeREITList },
            { name: ViewFilterName.SecurityTypeMLPList },
            { name: ViewFilterName.SecurityTypeBlankCheckList },
            { name: ViewFilterName.SecurityTypeRegAList },
            { name: ViewFilterName.SecurityTypeDirectListingList },
          ],
        },
        {
          type: FilterSectionType.Returns,
          controls: [
            { name: ViewFilterName._FirstDayReturn },
            { name: ViewFilterName._AfterMarketReturn },
            { name: ViewFilterName._TotalReturn },
            { name: ViewFilterName._InitialPerformance },
            { name: ViewFilterName._RecentPerformance },
          ],
        },
        {
          type: FilterSectionType.Location,
          controls: [
            { name: ViewFilterName.LocationListingLocation },
            { name: ViewFilterName.ExchangeCountry },
            { name: ViewFilterName.ExchangeRegion },
            { name: ViewFilterName.IncludeChina },
            { name: ViewFilterName.LocationExchangeList },
          ],
        },
        {
          type: FilterSectionType.DealInfo,
          controls: [
            { name: ViewFilterName._DealSize },
            { name: ViewFilterName.UnderwriterKeyList },
            { name: ViewFilterName.UnderwriterRole },
            { name: ViewFilterName.InsiderBuying },
            { name: ViewFilterName.InsiderSelling },
            { name: ViewFilterName.InsiderSellingPct },
            { name: ViewFilterName.TermsChange },
            { name: ViewFilterName.IPOPriceRange },
            { name: ViewFilterName._IPOPricedRange },
            { name: ViewFilterName._DealSizeVsExpected },
            { name: ViewFilterName._FloatToMarketCap },
          ],
        },
        {
          type: FilterSectionType.Favorite,
          controls: [{ name: ViewFilterName.Favorite }],
        },
        {
          type: FilterSectionType.RenaissanceRating,
          controls: [
            { name: ViewFilterName.RcRating },
            { name: ViewFilterName._RatingFundamentals },
            { name: ViewFilterName._RatingCorpGov },
            { name: ViewFilterName._RatingValuation },
            { name: ViewFilterName._RatingTechnicals },
          ],
        },
        {
          type: FilterSectionType.Financials,
          controls: [
            { name: ViewFilterName._RevenueGrowth },
            { name: ViewFilterName.RevenueGrowthPeriod },
            { name: ViewFilterName._LtmLatestSales },
            { name: ViewFilterName._LtmGrossMargin },
            { name: ViewFilterName._LtmEbitdaMargin },
            { name: ViewFilterName._LtmNetMargin },
            { name: ViewFilterName._FinancialsLTMFreeCashFlow },
            { name: ViewFilterName._IpoDebtToEbitda },
            { name: ViewFilterName.MostRecentFinancialsDateMin },
            { name: ViewFilterName.Dividend },
          ],
        },
        {
          type: FilterSectionType.Valuation,
          controls: [
            { name: ViewFilterName._MarketCapAtIPO },
            { name: ViewFilterName._IpoEvToLtmSales },
            { name: ViewFilterName._IpoEvToEbitda },
            { name: ViewFilterName._IpoPe },
            { name: ViewFilterName._IpoPb },
          ],
        },
      ],
    },
    quickFilter: {
      hasLocation: true,
      hasDateRange: true,
      dateRangeMaxRestrictionValue: DatePickerRestrictionDateType.Today,
      hasTimeFrameRange: true,
      timeFrameOptions: [TimeFrame.Month, TimeFrame.Quarter, TimeFrame.YTD, TimeFrame.Year, TimeFrame.TwoYears],
      hasIncludeSPACtextFilter: true,
      hasIncludeAShares: true,
    },
    fileActionTypes: [FileActionType.Save, FileActionType.Excel, FileActionType.Print, FileActionType.Link],
  },
  summary: {
    align: ViewSummaryAlignType.Grid,
  },
  apiFooter: true,
  grid: {
    idCols: [ViewColumnSystemName.Ticker],
    isFixedHeight: false,
    customColumnsSettings: [
      {
        field: ViewColumnSystemName.TodaysPriceChange,
        minWidth: 125,
        width: 125,
        cellFormatter: {
          type: GridCellFormatterType.Custom,
          props: {
            component: TodaysPriceChangeCell,
            hideGrowthValue: true,
          },
        },
      },
      {
        field: ViewColumnSystemName.DealSize,
        minWidth: 55,
        width: 55,
      },
      {
        field: ViewColumnSystemName.ExchangeName,
        minWidth: 95,
        width: 95,
      },
      {
        field: ViewColumnSystemName.IpoDiscount,
        minWidth: 70,
        width: 70,
      },
      {
        field: ViewColumnSystemName.FirstDayReturn,
        minWidth: 70,
        width: 70,
      },
      {
        field: ViewColumnSystemName.TotalReturn,
        minWidth: 70,
        width: 70,
      },
      {
        field: ViewColumnSystemName.Favorite,
        minWidth: 25,
        width: 25,
      },
    ],
  },
};
