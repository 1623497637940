import React from 'react';
import './EmptyDataBlock.scss';
import { ComponentMessage } from '../enums/ComponentMessage';

interface Props {
  content?: React.ReactNode;
  addHeight?: boolean;
}

const EmptyDataBlock: React.FC<Props> = props => {
  return (
    <div className={`EmptyDataBlock ${props.addHeight ? ' AddHeight' : ''}`}>
      {props.content || ComponentMessage.NoData}
    </div>
  );
};

export default EmptyDataBlock;
