import React, { useMemo } from 'react';
import { GridRow } from '../../types/GridRow';
import { GridColumn } from '../../interfaces/GridColumn';
import { GridCellFactory } from '../../factories/GridCellFactory';
import HtmlStringFormatter from '../../../../HtmlStringFormatter';
import { isString } from 'lodash';

interface Props {
  row: GridRow;
  column: GridColumn;
  hasHtmlFormatter?: boolean;
}

const GridCell: React.FC<Props> = props => {
  const node = useMemo(() => GridCellFactory.getNode(props.row, props.column), [props.row, props.column]);
  const style = useMemo(() => GridCellFactory.getStyles(props.column), [props.column]);

  const isNodeString = useMemo(() => isString(node), [node]);
  const content = useMemo(
    () => (props.hasHtmlFormatter && isNodeString ? <HtmlStringFormatter content={node as string} /> : node),
    [node, props.hasHtmlFormatter, isNodeString],
  );

  return (
    <div style={style} className="GridCell">
      {content}
    </div>
  );
};

export default GridCell;
