import * as yup from 'yup';
import { ContactFormField } from './ContactFormField';
import { captchaSchema } from '../../../shared/components/forms/services/validation/contstants/validation/captchaSchema';
import { phoneNumberSchema } from '../../../shared/components/forms/services/validation/contstants/validation/phoneNumberSchema';

export const validationSchema = yup.object().shape({
  [ContactFormField.FirstName]: yup
    .string()
    .required()
    .label('First Name'),
  [ContactFormField.LastName]: yup
    .string()
    .required()
    .label('Last Name'),
  [ContactFormField.Email]: yup
    .string()
    .email()
    .required()
    .label('Email'),
  // [ContactFormField.PhoneNumber]: yup
  // .string()
  // .required()
  // .label('Phone Number'),
  [ContactFormField.PhoneNumber]: phoneNumberSchema,
  [ContactFormField.Company]: yup
    .string()
    .required()
    .label('Company'),
  [ContactFormField.JobTitle]: yup
    .string()
    .required()
    .label('Job Title'),
  [ContactFormField.Comment]: yup
    .string()
    .required()
    .label('Comment'),
  // [ContactFormField.Agree]: termsAndPrivacySchema,
  [ContactFormField.Captcha]: captchaSchema,
});
