import { DashboardWidgetType } from '../enums/DashboardWidgetType';
import { DashboardWidgetData } from '../interfaces/DashboardWidgetData';
import { RoutePath } from '../../shared/enums/RoutePath';
import DashboardNewsWidget from '../components/widgets/DashboardNewsWidget';
import DashboardCalendarWidget from '../components/widgets/DashboardCalendarWidget';
import DashboardPricedWidget from '../components/widgets/DashboardPricedWidget';
import DashboardRecentResearchWidget from '../components/widgets/DashboardRecentResearchWidget';
import DashboardSpecialReportsWidget from '../components/widgets/special-reports/DashboardSpecialReportsWidget';
import DashboardIPOsOnMoveWidget from '../components/widgets/ipos-on-move-widget/DashboardIPOsOnMoveWidget';
import { CustomAny } from '../../shared/types/generics';
import DashboardApiService from '../../shared/api/DashboardApiService';
import { DashboardNewsDto } from '../interfaces/DashboardNewsDto';
import { CalendarEvent } from '../../shared/entities/calendar/interfaces/CalendarEvent';
import { ViewDataTable } from '../../shared/entities/view/components/view/interfaces/api/ViewDataTable';
import DashboardUpcomingIPOsWidget from '../components/widgets/DashboardUpcomingIPOsWidget';
import DashboardMostViewedIPOs from '../components/widgets/DashboardMostViewedIPOs';
import DashboardNewFilingsWidget from '../components/widgets/DashboardNewFilingsWidget';
import { DashboardIPOsOnMoveDto } from '../types/DashboardIPOsOnMoveDto';
import DashboardMyFavouritesWidget from '../components/widgets/DashboardMyFavouritesWidget';
import { DashboardMyFavouritesDto } from '../interfaces/DashboardMyFavouritesDto';
import DashboardIPOPollWidget from '../components/widgets/DashboardIPOPollWidget';
import DashboardRenaissanceUSIPOIndexWidget from '../components/widgets/renaissance-us-ipo-index-widget/DashboardRenaissanceUSIPOIndexWidget';
import { ReportDto } from '../../special-reports/interfaces/ReportDto';
import DashboardUSIPOMarketWidget from '../components/widgets/DashboardUSIPOMarketWidget';
import DashboardMyViewsWidget from '../components/widgets/saved-views-widget/DashboardMyViewsWidget';
import DashboardMyScreensWidget from '../components/widgets/saved-views-widget/DashboardMyScreensWidget';
import { ViewSaved } from '../../shared/entities/view/components/view/interfaces/api/ViewSaved';
import Highcharts from 'highcharts/highstock';

export const dashboardWidgetsData: { [key in DashboardWidgetType]: DashboardWidgetData<CustomAny> } = {
  [DashboardWidgetType.News]: {
    title: 'News and Commentary',
    route: RoutePath.News,
    component: DashboardNewsWidget,
    loadData(): Promise<DashboardNewsDto[]> {
      return DashboardApiService.getNews();
    },
  },
  [DashboardWidgetType.Calendar]: {
    title: 'Master Calendar',
    route: RoutePath.MasterCalendar,
    component: DashboardCalendarWidget,
    loadData(): Promise<CalendarEvent[]> {
      return DashboardApiService.getCalendar();
    },
  },
  [DashboardWidgetType.Priced]: {
    title: 'Priced',
    route: RoutePath.Priced,
    component: DashboardPricedWidget,
    loadData(): Promise<ViewDataTable> {
      return DashboardApiService.getPriced();
    },
  },
  [DashboardWidgetType.UpcomingIPOs]: {
    title: 'Scheduled IPOs',
    route: RoutePath.Scheduled,
    component: DashboardUpcomingIPOsWidget,
    loadData(): Promise<ViewDataTable> {
      return DashboardApiService.getUpcomingIPOs();
    },
  },
  [DashboardWidgetType.RecentResearch]: {
    title: 'Recent Research',
    route: RoutePath.ResearchDashboard,
    component: DashboardRecentResearchWidget,
    loadData(): Promise<ViewDataTable> {
      return DashboardApiService.getRecentResearch();
    },
  },
  [DashboardWidgetType.MostViewedIPOs]: {
    title: 'Most Viewed IPOs',
    component: DashboardMostViewedIPOs,
    loadData(): Promise<ViewDataTable> {
      return DashboardApiService.getMostViewedIPOs();
    },
  },
  [DashboardWidgetType.NewFilings]: {
    title: 'New Filings',
    route: RoutePath.Pipeline,
    component: DashboardNewFilingsWidget,
    loadData(): Promise<ViewDataTable> {
      return DashboardApiService.getNewFilings();
    },
  },
  [DashboardWidgetType.IposOnMove]: {
    title: 'IPOs on the Move',
    component: DashboardIPOsOnMoveWidget,
    loadData(): Promise<DashboardIPOsOnMoveDto> {
      return DashboardApiService.getIPOsOnMove();
    },
  },
  [DashboardWidgetType.MyFavourites]: {
    title: 'My Favorites',
    route: RoutePath.MyFavourites,
    component: DashboardMyFavouritesWidget,
    loadData(): Promise<DashboardMyFavouritesDto> {
      return DashboardApiService.getMyFavourites();
    },
  },
  [DashboardWidgetType.IPOPoll]: {
    title: 'IPO Poll',
    component: DashboardIPOPollWidget,
    loadData(): Promise<ViewDataTable> {
      return DashboardApiService.getIPOPoll();
    },
  },
  [DashboardWidgetType.RenaissanceUSIPOIndex]: {
    title: 'Renaissance US IPO Index',
    route: RoutePath.StatsRecentIposReturns,
    component: DashboardRenaissanceUSIPOIndexWidget,
    hasIndent: true,
    loadData(): Promise<Highcharts.Options> {
      return DashboardApiService.getRenaissanceUSIPOIndex();
    },
  },
  [DashboardWidgetType.USIPOMarketIndex]: {
    title: 'US IPO Market',
    route: RoutePath.Stats,
    component: DashboardUSIPOMarketWidget,
    hasIndent: true,
    loadData(): Promise<Highcharts.Options> {
      return DashboardApiService.getUSIPOMarketIndex();
    },
  },
  [DashboardWidgetType.MyViews]: {
    title: 'My Views',
    route: RoutePath.MyFavourites,
    component: DashboardMyViewsWidget,
    loadData(): Promise<ViewSaved[]> {
      return DashboardApiService.getMyViews();
    },
  },
  [DashboardWidgetType.MyScreens]: {
    title: 'My Screens',
    route: RoutePath.MyFavourites,
    component: DashboardMyScreensWidget,
    loadData(): Promise<ViewSaved[]> {
      return DashboardApiService.getMyScreens();
    },
  },
  [DashboardWidgetType.SpecialReports]: {
    title: 'Special Reports',
    route: RoutePath.ResearchSpecialReports,
    component: DashboardSpecialReportsWidget,
    hasIndent: true,
    loadData(): Promise<ReportDto[]> {
      return DashboardApiService.getSpecialReports();
    },
  },
};
