import React from 'react';
import './Label.scss';
import classNames from 'classnames';
import { CustomAny } from '../../types/generics';

interface Props {
  className?: string;
  error?: CustomAny;
  htmlFor?: CustomAny;
  hasLabelErrorStyle?: boolean;
}

const Label: React.FC<Props> = props => {
  const { hasLabelErrorStyle = true } = props;

  return (
    <label
      className={classNames('Label', props.className, {
        'text-danger': hasLabelErrorStyle && props.error,
      })}
      htmlFor={props.htmlFor}
    >
      {props.children}
    </label>
  );
};

export default Label;
