import { GridLayoutBreakpoint } from '../enums/GridLayoutBreakpoint';
import { GridLayoutWidget } from '../interfaces/GridLayoutWidget';
import { Layout } from 'react-grid-layout';
import { cloneDeep } from 'lodash';

// These breakpoints are specific to React-Grid-Layout
export function getGridLayoutBreakpoint(width: number): GridLayoutBreakpoint {
  if (width >= 1189) {
    return GridLayoutBreakpoint.Large;
  } else if (width >= 962) {
    return GridLayoutBreakpoint.Medium;
  } else if (width >= 722) {
    return GridLayoutBreakpoint.Small;
  } else if (width >= 542) {
    return GridLayoutBreakpoint.ExtraSmall;
  } else {
    return GridLayoutBreakpoint.ExtraExtraSmall;
  }
}

// Copy widgets to a new object and update a specific layout based on the given breakpoint.
export function getUpdatedWidgets(widgets: GridLayoutWidget[], layout: Layout[], breakPoint: GridLayoutBreakpoint) {
  const updatedWidgets = cloneDeep(widgets);
  for (let i = 0; i < widgets.length; i++) {
    updatedWidgets[i]['layouts'][breakPoint]['width'] = layout[i].w;
    updatedWidgets[i]['layouts'][breakPoint]['height'] = layout[i].h;
    updatedWidgets[i]['layouts'][breakPoint]['top'] = layout[i].y;
    updatedWidgets[i]['layouts'][breakPoint]['left'] = layout[i].x;
  }
  return updatedWidgets;
}
