export enum GridScrollActionBlock {
  Root = 'GridScrollAction',
}

export enum GridScrollActionElement {
  LeftArrow = 'left-arrow',
  RightArrow = 'right-arrow',
}

export enum GridScrollActionModifier {
  Hidden = 'hidden',
}
