import React from 'react';
import HeaderPublicMenu from './HeaderPublicMenu';
import './Header.scss';
import HeaderLogo from './HeaderLogo';
import HeaderMainMenu from './HeaderMainMenu';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import HeaderSearch from './HeaderSearch';
import HeaderUserMenu from './HeaderUserMenu';

const Header: React.FC = () => {
  const isAuthorized = useSelector<RootState, boolean>(state => state.auth.isAuthorized);

  return (
    <header className="Header">
      <HeaderLogo />

      {isAuthorized ? (
        <>
          <HeaderSearch />
        </>
      ) : (
        <div className="Header__slogan">Helping Institutional Investors Profit from IPOs.</div>
      )}

      {isAuthorized ? <HeaderUserMenu /> : <HeaderPublicMenu />}

      <div className="Header__mainMenu">
        <HeaderMainMenu />
      </div>
    </header>
  );
};

export default Header;
