import React from 'react';
import { StatsElementOptions } from '../../../../shared/entities/stats-element/interfaces/StatsElementOptions';
import { ChartType } from '../../../../shared/components/chart/enums/ChartType';
import { getStatsElementSimpleGridColumnYearToViewMapper } from '../../../../shared/entities/stats-element/components/simple-grid/constants/statsElementSimpleGridColumnMappers';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { ViewFilterName } from '../../../../shared/entities/filters/enum/types/ViewFilterName';
import { StatsFilterName } from '../../enum/StatsFilterName';

const AVG_IPO_RETURNS_YEAR_END_GRID_INDEX = 0;

export const historicalIpoReturnsStatsElementOptions: StatsElementOptions = {
  chartsOptions: [
    {
      title: (
        <>
          Avg IPO Returns <u>at Year-End</u>
        </>
      ),
      type: ChartType.PersentageColumn,
      internalFilter: {
        label: 'IPO Returns',
      },
      optionsList: [
        {
          yAxis: {
            title: {
              text: 'Return From IPO Price (%)',
            },
          },
          legend: {
            enabled: false,
          },
          series: [
            {
              type: 'column',
              name: 'Return From IPO Price',
            },
          ],
        },
      ],
    },
  ],
  gridOptions: {
    [AVG_IPO_RETURNS_YEAR_END_GRID_INDEX]: {
      isHighlightLastRow: true,
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(context => ({
        viewType: ViewType.Priced,
        startDateFilterName: ViewFilterName.OfferStartDate,
        endDateFilterName: ViewFilterName.OfferEndDate,
        optionsList: [
          {
            getFilterName: ViewFilterName.SecurityTypeBlankCheckList,
            getFilterValue: () => (context?.pageFilters?.[StatsFilterName.IncludeSPACs] ? 'include' : ''),
          },
        ],
      })),
    },
  },
};
