import React from 'react';
import { RotatedGridRowProps } from '../../../../../../shared/components/rotated-grid/RotatedGridRow';
import CompanyPriceChange from '../../../../company-price-change/CompanyPriceChange';
import { CompanyTradingDto } from '../../../../../interfaces/CompanyTradingDto';
import ColorNumberFormatter from '../../../../../../shared/components/formatters/ColorNumberFormatter';
import { ViewGridCellValueService } from '../../../../../../shared/entities/view/components/view-grid/services/ViewGridCellValueService';
import { CompanyTradingDtoProp } from '../../../../../constants/CompanyTradingDtoProp';

export const getCompanyHeaderTradingTable = (data: CompanyTradingDto): RotatedGridRowProps[] => [
  {
    title: 'Latest',
    data: data[CompanyTradingDtoProp.Latest] && <CompanyPriceChange data={data[CompanyTradingDtoProp.Latest]} />,
  },
  {
    title: 'First Day Return',
    data: data[CompanyTradingDtoProp.FirstDayReturn] && (
      <ColorNumberFormatter
        value={data[CompanyTradingDtoProp.FirstDayReturn]}
        displayValue={ViewGridCellValueService.getVisible(data, CompanyTradingDtoProp.FirstDayReturn)}
      />
    ),
  },
  {
    title: 'Aftermarket Return',
    data: data[CompanyTradingDtoProp.AftermarketReturn] && (
      <ColorNumberFormatter
        value={data[CompanyTradingDtoProp.AftermarketReturn]}
        displayValue={ViewGridCellValueService.getVisible(data, CompanyTradingDtoProp.AftermarketReturn)}
      />
    ),
  },
  {
    title: 'Return from IPO',
    data: data[CompanyTradingDtoProp.ReturnFromIpo] && (
      <ColorNumberFormatter
        value={data[CompanyTradingDtoProp.ReturnFromIpo]}
        displayValue={ViewGridCellValueService.getVisible(data, CompanyTradingDtoProp.ReturnFromIpo)}
      />
    ),
  },
];
