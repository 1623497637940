import React from 'react';
import SecurityTypeListControls from '../controls/security-type/SecurityTypeListControls';

const SecurityTypeFilterSection: React.FC = () => {
  return (
    <>
      <SecurityTypeListControls />
    </>
  );
};

export default SecurityTypeFilterSection;
