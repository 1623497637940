import React, { useContext } from 'react';
import { IconName } from '../../../../../../shared/components/icon/IconName';
import './CompanyOfferingDataActions.scss';
import ButtonIcon from '../../../../../../shared/components/forms/ButtonIcon';
import { ColorScheme } from '../../../../../../shared/enums/ColorScheme';
import Hyperlink from '../../../../../../shared/components/typography/Hyperlink';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';

const CompanyOfferingDataActions: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  return (
    <>
      {profile.description?.roadshowLink && (
        <div className="CompanyOfferingDataActions">
          <Hyperlink to={profile.description.roadshowLink} isExternal isBlank>
            <ButtonIcon description="Net Roadshow" colorScheme={ColorScheme.Info} iconName={IconName.Video} />
          </Hyperlink>
        </div>
      )}
    </>
  );
};

export default CompanyOfferingDataActions;
