import React from 'react';
import { memoize } from 'lodash';
import TooltipAsync from '../../../shared/components/tooltip/TooltipAsync';
import { CompanyApiService } from '../../../shared/api/CompanyApiService';
import { ControlSize } from '../../../shared/enums/ControlSize';
import CompanyMilestonesTooltipContent from './CompanyMilestonesTooltipContent';

interface Props {
  title: string;
  ticker: string;
}

const CompanyMilestonesTooltip: React.FC<Props> = props => {
  return (
    <TooltipAsync
      title={props.title}
      component={CompanyMilestonesTooltipContent}
      size={ControlSize.Large}
      hasIndentHorizontal={false}
      hasIndentVertical={false}
      isTitleHighlighted
      isUnderFixed
      hasMaxHeight
      getDataFn={memoize(CompanyApiService.getMilestones.bind(CompanyApiService, props.ticker))}
    />
  );
};

export default CompanyMilestonesTooltip;
