import React, { ReactNode, ReactNodeArray } from 'react';
import { TickerSymbol } from '../../shared/types';
import { CompanyLocationService } from './CompanyLocationService';
import { TemplateService } from '../../shared/services/TemplateService';
import CompanyLink from '../components/company-link/CompanyLink';
import { RandomizeService } from '../../shared/services/RandomizeService';

export class CompanyTemplateService {
  static getTextWithHtmlLinksByTicker(text: string): string {
    return TemplateService.replaceElementToString(text, ticker => this.getCompanyHtmlLink(ticker));
  }

  static getTextWithReactLinksByTicker(text: string): ReactNodeArray {
    return TemplateService.replaceElementToReactElement(text, ticker => this.getCompanyReactLink(ticker));
  }

  static getTickerFromText(tickerInText: string): string {
    return tickerInText.replace(/\(|\)/g, '');
  }

  private static getCompanyReactLink(ticker: TickerSymbol, visibleValue?: string): ReactNode {
    return <CompanyLink key={RandomizeService.generateUuid()} ticker={ticker} visibleValue={visibleValue} />;
  }

  private static getCompanyHtmlLink(ticker: TickerSymbol, text: string = ticker): string {
    return `<a href=${CompanyLocationService.getRelativePath(ticker)}>${text}</a>`;
  }
}
