import React from 'react';
import { ViewDataTable } from '../../../shared/entities/view/components/view/interfaces/api/ViewDataTable';
import DashboardTable from './shared/DashboardTable';
import { DashboardWidgetComponent } from '../../interfaces/DashboardWidgetComponent';

const DashboardUpcomingIPOsWidget: DashboardWidgetComponent<ViewDataTable> = props => {
  return (
    <DashboardTable
      width={props.dimensions?.width}
      widgetType={'DashboardUpcomingIPOsWidget'}
      dataTable={props.data}
      pinTickerColumn
    />
  );
};

export default DashboardUpcomingIPOsWidget;
