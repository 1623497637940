import React, { useCallback, useState } from 'react';
import './ElementsHeightExpander.scss';
import { useClassName } from '../../hooks/useClassName';
import AnimatedHeight from '../animation/animated-height/AnimatedHeight';
import ElementsExpanderButton from './ElementsExpanderButton';

interface Props {
  visibleHiddenHeight?: number;
}

const ElementsHeightExpander: React.FC<Props> = props => {
  const { visibleHiddenHeight } = props;

  const cn = useClassName('ElementsHeightExpander');

  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandAvailable, setIsExpandAvailable] = useState(false);

  const onExpand = useCallback(() => {
    setIsExpanded(isExpanded => !isExpanded);
  }, []);

  const onChangeContentHeight = useCallback((canContentAnimate: boolean) => {
    setIsExpandAvailable(canContentAnimate);
  }, []);

  return (
    <div className={cn()}>
      <AnimatedHeight
        className={cn('content')}
        isVisible={isExpanded}
        visibleHiddenHeight={visibleHiddenHeight}
        onChangeContentHeight={onChangeContentHeight}
      >
        {props.children}
      </AnimatedHeight>

      {isExpandAvailable && (
        <ElementsExpanderButton className={cn('button')} isExpanded={isExpanded} onExpand={onExpand} />
      )}
    </div>
  );
};

export default ElementsHeightExpander;
