import React, { ReactNode } from 'react';
import './ModalRow.scss';

interface Props {
  header?: string;
  content?: ReactNode;
}

const ModalRow: React.FC<Props> = props => {
  return (
    <div className="ModalRow">
      <h2 className="ModalRow__header">{props.header}</h2>
      <div className="ModalRow__content">{props.content}</div>
    </div>
  );
};

export default ModalRow;
