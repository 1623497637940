import React, { useCallback, useContext, useMemo } from 'react';
import './ViewGridToolbarGroupFileActions.scss';
import ViewGridToolbarGroup from '../../view-grid/components/parts/ViewGridToolbarGroup';
import FileActions from '../../../../../components/file-actions/FileActions';
import { FileActionProp } from '../../../../../components/file-actions/interfaces/FileActionProp';
import { FileActionType } from '../../../../../components/file-actions/enums/FileActionType';
import { ViewGridContext } from '../../view-grid/context/view-grid/ViewGridContext';
import { ActionClickFn } from '../../../../../components/file-actions/types/actions';
import { useClassName } from '../../../../../hooks/useClassName';
import { ViewGridToolbarGroupFileActionsBlock } from './viewGridToolbarGroupFileActionsBem';
import { ViewGridToolbarGroupFileActionsFactory } from './factories/ViewGridToolbarGroupFileActionsFactory';
import { GetFileFn } from '../../../../../services/file/interfaces/GetAndDownloadFileOptions';

interface Props {
  fileActionTypes?: FileActionType[];
  className?: string;
  excelFn?: GetFileFn;
  onActionClick?: ActionClickFn;
}

const ViewGridToolbarGroupFileActions: React.FC<Props> = props => {
  const { excelFn, onActionClick } = props;

  const cn = useClassName(ViewGridToolbarGroupFileActionsBlock.Root, props.className);

  const { gridPublicApi } = useContext(ViewGridContext);

  const fileActionTypes = useMemo<FileActionProp[]>(
    () => ViewGridToolbarGroupFileActionsFactory.getActions({ fileActionTypes: props.fileActionTypes, excelFn }),
    [props.fileActionTypes, excelFn],
  );

  const onFileActionClick = useCallback(
    (actionType: FileActionType) => {
      if (actionType === FileActionType.Print) {
        gridPublicApi?.print();
      }

      onActionClick?.(actionType);
    },
    [onActionClick, gridPublicApi],
  );

  return (
    <ViewGridToolbarGroup className={cn()}>
      <FileActions fileActions={fileActionTypes} onActionClick={onFileActionClick} />
      {props.children}
    </ViewGridToolbarGroup>
  );
};

export default ViewGridToolbarGroupFileActions;
