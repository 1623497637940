import { useState, useEffect } from 'react';
import { BreakpointName } from '../enums/BreakpointName';
import useBreakpoint from './useBreakpoint';

function isAvailableFn(availableBreakpoints: BreakpointName[], currentBreakpoint: BreakpointName): boolean {
  let isAvailable;

  if (availableBreakpoints.length === 0) {
    isAvailable = true;
  } else {
    isAvailable = availableBreakpoints.includes(currentBreakpoint);
  }

  return isAvailable;
}

export default function useBreakpointAvailable(availableBreakpoints: BreakpointName[] = []): boolean {
  const currentBreakpoint: BreakpointName = useBreakpoint();

  const [isAvailable, setIsAvailable] = useState<boolean>(isAvailableFn(availableBreakpoints, currentBreakpoint));

  useEffect(() => {
    setIsAvailable(isAvailableFn(availableBreakpoints, currentBreakpoint));
  }, [availableBreakpoints, currentBreakpoint]);

  return isAvailable;
}
