import React, { useMemo } from 'react';
import SecondarySection, { SecondarySectionProps } from './SecondarySection';
import SecondarySectionExpandable, { SecondarySectionExpandableProps } from './SecondarySectionExpandable';

export type SecondarySectionSwitchProps = SecondarySectionProps | SecondarySectionExpandableProps;

const SecondarySectionSwitch: React.FC<SecondarySectionSwitchProps> = props => {
  const isExpandable = useMemo(() => !!(props as SecondarySectionExpandableProps).expandingLength, [props]);

  return isExpandable ? (
    <SecondarySectionExpandable {...(props as SecondarySectionExpandableProps)} />
  ) : (
    <SecondarySection {...props}>{props.children}</SecondarySection>
  );
};

export default SecondarySectionSwitch;
