import React, { useContext } from 'react';
import FilterControl from '../FilterControl';
import { ViewFilterName } from '../../../../enum/types/ViewFilterName';
import { FilterSectionsFormContext } from '../../context/FilterSectionsFormContext';

const InvestorsFilterSection: React.FC = () => {
  const { dictionaries } = useContext(FilterSectionsFormContext);

  return (
    <>
      <FilterControl
        name={ViewFilterName.Investor}
        controlProps={{
          options: dictionaries?.[ViewFilterName.Investor],
        }}
      />

      <FilterControl name={ViewFilterName.TopShareholder} />

      <FilterControl name={ViewFilterName.HistoryShareholders} />

      <FilterControl name={ViewFilterName.Ownership} />

      <FilterControl name={ViewFilterName.SpinOff} />

      <FilterControl name={ViewFilterName.MgmtOwned} />

      <FilterControl name={ViewFilterName.PE} />

      <FilterControl name={ViewFilterName.PeGrowthOther} />

      <FilterControl name={ViewFilterName.VentureCapital} />
    </>
  );
};

export default InvestorsFilterSection;
