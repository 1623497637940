import { ViewFilterName } from '../enum/types/ViewFilterName';
import { FiltersValues } from '../types/FiltersValues';
import { isNil } from 'lodash';
import { SelectOption } from '../../../services/select/interfaces/SelectOption';

export class FilterStoreService {
  static getValue(filters: FiltersValues, filterName: ViewFilterName): string | undefined | boolean {
    return filters[filterName];
  }

  static update(filters: FiltersValues, filterName: ViewFilterName, filterValue: string): FiltersValues {
    return { ...filters, [filterName]: filterValue };
  }

  static updateBulk(filters: FiltersValues, changes: Partial<FiltersValues>): FiltersValues {
    return { ...filters, ...changes };
  }

  static has(filters: FiltersValues, filterName: ViewFilterName): boolean {
    return !isNil(this.getValue(filters, filterName));
  }

  static getTitle(
    filters: FiltersValues,
    filterName: ViewFilterName,
    filtersOptions: SelectOption[],
  ): string | undefined {
    const currentFilter: string | undefined | boolean = this.getValue(filters, filterName);
    return filtersOptions.find((filter: SelectOption) => filter.value === currentFilter)?.title;
  }
}
