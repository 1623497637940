import React, { useCallback, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { GridApi } from '@ag-grid-enterprise/all-modules';
import { useClassName } from '../../../../../hooks/useClassName';
import Select from '../../../../forms/components/select/Select';
import { PortionSize } from '../../../../../enums/PortionSize';
import { GridClass } from '../../enums/GridClass';
import { ControlSize } from '../../../../../enums/ControlSize';
import { EnumService } from '../../../../../services/EnumService';

interface Props {
  parentEl: HTMLElement;
  gridApi: GridApi;
  initialPageSize: PortionSize;
}

const GridPaginationSize: React.FC<Props> = props => {
  const { parentEl, gridApi, initialPageSize } = props;

  const cn = useClassName('GridPaginationSize');

  const [pageSize, setPageSize] = useState(initialPageSize);

  const options = useMemo(() => EnumService.getNumberValues(PortionSize), []);
  const paginationContainer = useMemo<Element>(
    () => parentEl.getElementsByClassName(GridClass.PaginationContainer)[0],
    [parentEl],
  );

  const onChange = useCallback(
    value => {
      setPageSize(value);
      gridApi.paginationSetPageSize(value);
    },
    [gridApi],
  );

  return ReactDOM.createPortal(
    <Select
      className={cn()}
      options={options}
      values={pageSize}
      isMultiSelect={false}
      width={ControlSize.SmallExtraSmall}
      size={ControlSize.Small}
      hasNoClear
      onChange={onChange}
    />,
    paginationContainer,
  );
};

export default GridPaginationSize;
