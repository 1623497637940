import { GridPublicApi } from '../../../../../components/grid/grid/interfaces/GridPublicApi';
import { ViewColumnSystemName } from '../../view-grid/enums/ViewColumnSystemName';
import { SocketOnUpdateData } from '../socket/types/SocketOnUpdateData';
import { GridUpdateCellParams } from '../../../../../components/grid/grid/interfaces/GridUpdateCellParams';
import { CompanyPriceChangeDto } from '../../../../../../company/components/company-price-change/interfaces/CompanyPriceChangeDto';
import { SocketUpdateRowData } from '../socket/interfaces/SocketUpdateRowData';
import { SocketUpdateTickersData } from '../socket/types/SocketUpdateTickersData';
import { ViewGridColumnService } from '../../view-grid/services/ViewGridColumnService';
import { ViewGridColumnGroupDto } from '../../view-grid/interfaces/ViewGridColumnGroupDto';
import { GridRow } from '../../../../../components/grid/shared/types/GridRow';
import { DateHelper } from '../../../../../helpers/date/DateHelper';
import { VIEW_TODAYS_PRICE_CHANGE_PERIOD_YEARS } from '../constants/viewTodaysPriceChangePeriod';

export class ViewTodaysPriceChangeService {
  static getTickers(rows: GridRow[] = []): SocketUpdateTickersData {
    const periodStart = DateHelper.sub(DateHelper.getToday(), { years: VIEW_TODAYS_PRICE_CHANGE_PERIOD_YEARS });

    return rows
      .filter(row => DateHelper.isAfter(row[ViewColumnSystemName.OfferDate], periodStart))
      .map(row => row[ViewColumnSystemName.Ticker]);
  }

  static update(gridPublicApi: GridPublicApi, data: SocketOnUpdateData): void {
    const rows = this.getUpdateRows(data);

    gridPublicApi.updateCellsByIds(rows);
  }

  static hasTodaysPriceChange(columnsGroups: ViewGridColumnGroupDto[]): boolean {
    return ViewGridColumnService.hasColumnByName(columnsGroups, ViewColumnSystemName.TodaysPriceChange);
  }

  private static getUpdateRows(rows: SocketUpdateRowData[]): GridUpdateCellParams<CompanyPriceChangeDto>[] {
    return rows.map(row => ({
      cellValue: row.todaysPriceChange,
      cellName: ViewColumnSystemName.TodaysPriceChange,
      rowId: row[ViewColumnSystemName.Ticker],
    }));
  }
}
