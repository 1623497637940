import { NavItemLink } from '../../../components/nav/interfaces/NavItemLink';
import { RoutePath } from '../../../enums/RoutePath';

export const screenDefaultScreensNavItems = [
  {
    text: 'Estimates',
    path: RoutePath.ScreensEstimatesInUse,
  },
  {
    text: 'Industry',
    path: RoutePath.ScreensIndustryLanding,
  },
  {
    text: 'Investors',
    path: RoutePath.ScreensInvestorsLanding,
  },
  {
    text: 'Location',
    path: RoutePath.ScreensLocationLanding,
  },
  {
    text: 'Lock-ups',
    path: RoutePath.ScreensLockUpsInUse,
  },
  {
    text: 'SPACs',
    path: RoutePath.ScreensSpacsLanding,
  },
  {
    text: 'Underwriter',
    path: RoutePath.ScreensUnderwriterLanding,
  },
  {
    text: "Withdrawn/PP'd",
    path: RoutePath.ScreensWithdrawnPostponedInUse,
  },
];

export const screensNavItems: NavItemLink[] = [
  {
    text: 'Custom Screen',
    path: RoutePath.ScreensAdd,
  },
  ...screenDefaultScreensNavItems,
];
