import React from 'react';
import { privateCompanyMonitorListViewOptions } from './constants/privateCompanyMonitorListViewOptions';
import { ViewType } from '../../shared/entities/view/components/view/enums/ViewType';
import View from '../../shared/entities/view/components/view/View';

const PrivateCompanyMonitorList: React.FC = () => {
  return <View options={privateCompanyMonitorListViewOptions} viewType={ViewType.PrivateCompanyMonitorList} />;
};

export default PrivateCompanyMonitorList;
