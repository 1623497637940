import React, { useMemo } from 'react';
import TabsContainer from '../../tabs/TabsContainer';
import { RoutePath } from '../../../enums/RoutePath';
import TabLink from '../../tabs/TabLink';
import { RouteService } from '../../../services/RouteService';
import { NavProps } from '../interfaces/NavProps';
import classNames from 'classnames';
import './TabsNav.scss';

const TabsNav: React.FC<NavProps> = props => {
  const routesPaths = useMemo<RoutePath[]>(() => RouteService.getRoutePathsFromDescriptions(props.routesTitles), [
    props.routesTitles,
  ]);

  return (
    <section className={classNames('TabsNav', { [`TabsNav--${props.alignHorizontal}`]: props.alignHorizontal })}>
      <TabsContainer>
        {routesPaths.map((path: RoutePath, index: number) => (
          <TabLink key={index} to={path}>
            {props.routesTitles[path]}
          </TabLink>
        ))}
      </TabsContainer>

      <div className="TabsNav__content">{props.children}</div>
    </section>
  );
};

export default TabsNav;
