import React, { useMemo } from 'react';
import { DashboardWidgetComponent } from '../../interfaces/DashboardWidgetComponent';
import './DashboardCalendarWidget.scss';
import { useClassName } from '../../../shared/hooks/useClassName';
import { ResponsiveCalendarMode } from '../../../shared/components/responsive-calendar/enums/ResponsiveCalendarMode';
import { CalendarEvent } from '../../../shared/entities/calendar/interfaces/CalendarEvent';
import MasterCalendar from '../../../master-calendar/MasterCalendar';
import { DashboardCalendarWidgetBlock } from './dashboardCalendarWidgetBem';
import { ControlSize } from '../../../shared/enums/ControlSize';

const WIDTH_FOR_MOBILE_VIEW = 376;
// const HEIGHT_FOR_BIG_WEEK_VIEW = 262;
// const WEEKS_HORIZONTAL_SMALL_COUNT = 1;
const WEEKS_HORIZONTAL_BIG_COUNT = 2;

const DashboardCalendarWidget: DashboardWidgetComponent<CalendarEvent[]> = props => {
  const { data } = props;
  const { width, height } = props.dimensions;

  const cn = useClassName(DashboardCalendarWidgetBlock.Root);

  const options = useMemo(() => {
    let mode;
    let weeksCount;

    if (width > WIDTH_FOR_MOBILE_VIEW) {
      mode = ResponsiveCalendarMode.WeekHorizontal;
      weeksCount = WEEKS_HORIZONTAL_BIG_COUNT;
      // if (height > HEIGHT_FOR_BIG_WEEK_VIEW) {
      //   weeksCount = WEEKS_HORIZONTAL_BIG_COUNT;
      // } else {
      //   weeksCount = WEEKS_HORIZONTAL_SMALL_COUNT;
      // }
    } else {
      mode = ResponsiveCalendarMode.WeekVertical;
    }

    return { mode, weeksCount };
  }, [width, height]);

  return (
    <MasterCalendar
      className={cn({
        [options.mode]: options.mode,
      })}
      allEvents={data}
      hasHeader={false}
      hasPrint={false}
      size={ControlSize.Small}
      isShortLegend
      {...options}
    />
  );
};

export default DashboardCalendarWidget;
