import { FilterSettingsService } from '../../../shared/entities/filters/services/FilterSettingsService';
import { searchFilterControls } from '../../../shared/entities/filters/constants/filter-controls/searchFilterControls';
import { ViewFilterName } from '../../../shared/entities/filters/enum/types/ViewFilterName';
import { FiltersMapValue } from '../../../shared/entities/filters/components/filterable-component/interfaces/FiltersMapValue';
import { SelectService } from '../../../shared/services/select/SelectService';
import { dealStatusOptions } from '../../../shared/entities/filters/constants/options/dealStatusOptions';
import { DealStatus } from '../../../shared/entities/filters/enum/DealStatus';

export const screensDealStatusPricedUpcoming = FilterSettingsService.mergeControlSettings(
  searchFilterControls[ViewFilterName.DealStatusDealStatusList] as FiltersMapValue,
  {
    props: {
      options: SelectService.getOptionsByValues(dealStatusOptions, [DealStatus.Priced, DealStatus.Upcoming]),
    },
  },
);

export const screensDealStatusPriced = FilterSettingsService.mergeControlSettings(
  searchFilterControls[ViewFilterName.DealStatusDealStatusList] as FiltersMapValue,
  {
    props: {
      options: SelectService.getOptionsByValues(dealStatusOptions, [DealStatus.Priced]),
    },
  },
);
