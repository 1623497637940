import { CompanyTab } from '../enums/CompanyTab';

export const companyTabsTitles: { [key in CompanyTab]: string } = {
  [CompanyTab.Profile]: 'Overview',
  [CompanyTab.InvestmentRationale]: 'Investment Rationale',
  [CompanyTab.FundamentalInsights]: 'Fundamental Insights',
  [CompanyTab.Financials]: 'Financials',
  [CompanyTab.MgmtQs]: 'Mgmt Qs',
  [CompanyTab.MgmtOwners]: 'Mgmt/Owners',
  [CompanyTab.Milestones]: 'Milestones',
  [CompanyTab.PeerTrading]: 'Peer Trading',
  [CompanyTab.RelativeValuation]: 'Relative Valuation',
  [CompanyTab.TeamNotes]: 'Team Notes',
};
