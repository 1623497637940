import { UpdateViewFn } from '../../types/UpdateViewFn';
import { Thunk } from 'react-hook-thunk-reducer';
import {
  ActionTypes,
  SET_GRID_PUBLIC_API,
  SET_VIEW_ADDITIONAL_DATA,
  SET_VIEW_DATA,
  SET_VIEW_LOADING,
  SET_VIEW_OPTIONS,
  SET_VIEW_SAVED_VIEWS,
  SetGridPublicApi,
  SetViewAdditionalData,
  SetViewData,
  SetViewLoading,
  SetViewOptions,
  SetViewSavedViews,
  ViewState,
} from '../types';
import { ViewDto } from '../../interfaces/api/ViewDto';
import { ViewDataService } from '../../services/ViewDataService';
import { ViewId } from '../../types/ViewId';
import { isDataInitSelector, viewReqParamsBaseSelector } from '../selectors';
import { startSocket, stopSocket } from './socket';
import { GridPublicApi } from '../../../../../../components/grid/grid/interfaces/GridPublicApi';
import { setViewError, setViewSuccess } from './notification';
import { CustomAny } from '../../../../../../types/generics';
import { ViewLocationService } from '../../services/ViewLocationService';
import { ViewSocketService } from '../../services/ViewSocketService';
import { ViewApiService } from '../../../../../../api/ViewApiService';
import { ViewType } from '../../enums/ViewType';
import { FiltersValues } from '../../../../../filters/types/FiltersValues';
import { initViewColumnsSettings, setViewSelectedColumns } from './columns';
import { ViewOptions } from '../../interfaces/options/ViewOptions';
import { ViewSaved } from '../../interfaces/api/ViewSaved';
import { SaveViewFormFields } from '../../components/view-toolbar/save-view/form/SaveViewFormFields';
import { ViewSaveData } from '../../components/view-modal/interfaces/ViewSaveData';
import { ViewSavedService } from '../../services/ViewSavedService';
import { setViewFilters, updateSearchFilters } from './filter';
import { saveViewMessage } from '../../constants/viewsTypeTexts';
import { ErrorHandlerService } from '../../../../../../services/error/ErrorHandlerService';
import { ErrorDataProps } from '../../../../../../services/error/enum/ErrorDataProps';
import { HttpCode } from '../../../../../../enums/HttpCode';
import LocationService from '../../../../../../services/LocationService';
import {
  fiveYearUpcomingDefaultLocations,
  allUpcomingDefaultLocations,
} from '../../../../../../../screens/constants/screensLocationGroups';
import { DealStatus } from '../../../../../filters/enum/DealStatus';
import { TimeFrame } from '../../../../../filters/enum/TimeFrame';
import { RoutePath } from '../../../../../../enums/RoutePath';

export const setViewOptions = (options: ViewOptions): SetViewOptions => {
  return {
    type: SET_VIEW_OPTIONS,
    payload: { options },
  };
};

export const setGridPublicApi = (gridPublicApi: GridPublicApi): SetGridPublicApi => {
  return {
    type: SET_GRID_PUBLIC_API,
    payload: { gridPublicApi },
  };
};

export const setViewLoading = (isLoading: boolean): SetViewLoading => {
  return {
    type: SET_VIEW_LOADING,
    payload: { isLoading },
  };
};

export const setViewAdditionalData = (data: CustomAny): SetViewAdditionalData => {
  return {
    type: SET_VIEW_ADDITIONAL_DATA,
    payload: data,
  };
};

export const setViewSavedViews = (savedViews: ViewSaved[]): SetViewSavedViews => {
  return {
    type: SET_VIEW_SAVED_VIEWS,
    payload: {
      savedViews,
    },
  };
};

export const setViewData = (viewData: ViewDto): SetViewData => {
  return {
    type: SET_VIEW_DATA,
    payload: {
      viewData,
      rows: ViewDataService.getRows(viewData),
      columnsGroups: ViewDataService.getColumnsGroups(viewData),
      filters: ViewDataService.getAppliedFilters(viewData),
      selectedColumns: ViewDataService.getSelectedColumns(viewData),
      summary: ViewDataService.getSummary(viewData),
      defaultFilters: ViewDataService.getDefaultFilters(viewData),
      savedViews: ViewDataService.getSavedViews(viewData),
      sharedLinks: ViewDataService.getSharedLinks(viewData),
      notes: ViewDataService.getNotes(viewData),
      footnote: ViewDataService.getFootnote(viewData),
      sort: ViewDataService.getSort(viewData),
      filtersDictionaries: ViewDataService.getFiltersDictionaries(viewData),
    },
  };
};

export const updateView = (
  updateViewFn: UpdateViewFn,
  onAfterViewDateSet?: (viewData: ViewDto) => void,
): Thunk<ViewState, ActionTypes> => {
  return async (dispatch): Promise<void> => {
    dispatch(setViewLoading(true));

    try {
      const viewData: ViewDto = await updateViewFn();

      dispatch(setViewData(viewData));

      onAfterViewDateSet?.(viewData);
    } catch (e) {
      ErrorHandlerService.handle(errorData => {
        if (errorData[ErrorDataProps.Code] === HttpCode.NotFound) {
          LocationService.navigateNotFound();
        } else {
          dispatch(setViewError(e));
        }
      }, e);
    } finally {
      dispatch(setViewLoading(false));
    }
  };
};

export const initViewAdditionalData = (): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state: ViewState = getState();
    const additionalDataOptions = state.options?.additionalDataOptions || [];
    const additionalData: CustomAny = {};

    try {
      await Promise.all(
        additionalDataOptions.map(async additionalDataOption => {
          additionalData[additionalDataOption.prop] = await additionalDataOption.fn();
        }),
      );
    } catch (e) {
      dispatch(setViewError(e));
    } finally {
      dispatch(setViewAdditionalData(additionalData));
    }
  };
};

export const initView = (): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state: ViewState = getState();

    dispatch(initViewAdditionalData());
    dispatch(initViewColumnsSettings());
    dispatch(
      updateView(async () => {
        const view = await ViewDataService.getView(state.viewType, state.viewId);
        if (ViewSocketService.isAvailable(view.dataTable.columns)) {
          dispatch(startSocket());
        }

        return view;
      }),
    );
  };
};

export const destroyView = (): Thunk<ViewState, ActionTypes> => {
  return async (dispatch): Promise<void> => {
    dispatch(stopSocket());
  };
};

export const loadNewView = (updateViewFn: UpdateViewFn): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state = getState();

    const custom = state.options.filter?.customHandlerOptions;

    if (custom && custom.triggerFn(state)) {
      custom.onFilter(dispatch, state);
      return;
    }

    dispatch(
      updateView(
        async () => {
          return await updateViewFn();
        },
        viewData => ViewLocationService.updateViewUrl(viewData.id),
      ),
    );
  };
};

export const updateViewByLocationChange = (newViewId: ViewId): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state: ViewState = getState();

    if (!ViewLocationService.shouldUpdateByLocationChange(newViewId, state.viewId, isDataInitSelector(state))) {
      return;
    }

    dispatch(
      updateView(async () => {
        return ViewDataService.getView(state.viewType, newViewId);
      }),
    );
  };
};

export const navigateToNewView = (viewType: ViewType, filters: FiltersValues = {}): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state = getState();

    dispatch(setViewLoading(true));

    try {
      const viewId: ViewId = await ViewApiService.createView({
        viewType,
        sort: null,
        filters: {
          ...state.filters,
          ...filters,
        },
      });

      ViewLocationService.navigateToView(viewType, viewId);
    } catch (e) {
      dispatch(setViewError(e));
      dispatch(setViewLoading(false));
    }
  };
};

export const updateViewByCurrentState = (): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state = getState();

    // When DealStatus is Upcoming, timeframe options are grayed out, so we are adding default timeframe filters to existing filters
    const currentDealStatus = state?.filters?.DealStatus;
    if (state?.viewType === ViewType.SpacsInUse) {
      if (!state?.filters?.DealStatusSelect) {
        LocationService.redirect(RoutePath.ScreensSpacsLanding);
        return;
      }
    }

    if (currentDealStatus === DealStatus.Upcoming) {
      if (allUpcomingDefaultLocations.includes(state?.viewType)) {
        dispatch(updateSearchFilters({ timeframe: TimeFrame.All }));
      } else if (fiveYearUpcomingDefaultLocations.includes(state?.viewType)) {
        dispatch(updateSearchFilters({ timeframe: TimeFrame.FiveYears }));
      }
    }

    if (state?.filters?.DealStatusSelect === '8') {
      const viewType = ViewType.WithdrawnPostponedInUse;
      dispatch(navigateToNewView(viewType, state.filters));
    } else {
      dispatch(loadNewView(ViewApiService.run.bind(ViewApiService, viewReqParamsBaseSelector(getState()))));
    }
  };
};

export const saveView = (viewName: string): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state: ViewState = getState();

    dispatch(setViewLoading(true));

    try {
      const savedViews = await ViewApiService.saveView(state.viewId, {
        [SaveViewFormFields.Name]: viewName,
        [SaveViewFormFields.Columns]: state.selectedColumns,
      });
      dispatch(setViewSavedViews(savedViews));

      dispatch(setViewSuccess({ text: saveViewMessage, values: [viewName] }));
    } catch (e) {
      dispatch(setViewError(e));
    } finally {
      dispatch(setViewLoading(false));
    }
  };
};

export const updateAndSaveView = (viewName: string, shouldViewUpdate: boolean): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    if (shouldViewUpdate) {
      dispatch(
        loadNewView(async () => {
          const view = await ViewApiService.saveViewAndRun({
            ...viewReqParamsBaseSelector(getState()),
            name: viewName,
          });

          dispatch(setViewSuccess({ text: saveViewMessage, values: [viewName] }));

          return view;
        }),
      );
    } else {
      dispatch(saveView(viewName));
    }
  };
};

export const updateViewByDataChange = (
  rawSaveData: Partial<ViewSaveData>,
  viewName?: string,
): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state = getState();

    const saveData = {
      filters: rawSaveData.filters || state.filters,
      columns: rawSaveData.columns || state.selectedColumns,
    };

    const stateSaveData = {
      filters: state.filters,
      columns: state.selectedColumns,
    };

    const shouldViewUpdate = ViewSavedService.shouldViewUpdate(stateSaveData, saveData);

    if (!shouldViewUpdate && !viewName) {
      return;
    }

    dispatch(setViewFilters(saveData.filters));
    dispatch(setViewSelectedColumns(saveData.columns));

    if (viewName) {
      dispatch(updateAndSaveView(viewName, shouldViewUpdate));
    } else {
      dispatch(updateViewByCurrentState());
    }
  };
};
