import React, { useContext, useMemo } from 'react';
import { StatsElementContext } from './context/StatsElementContext';
import { StatsElementInfoType } from '../enums/StatsElementInfoType';
import HtmlStringFormatter from '../../../components/HtmlStringFormatter';
import { useClassName } from '../../../hooks/useClassName';
import { StatsElementInfoBlock, StatsElementInfoModifier } from './statsElementInfoBem';
import './StatsElementInfo.scss';

interface Props {
  type: StatsElementInfoType;
}

const StatsElementInfo: React.FC<Props> = props => {
  const cn = useClassName(StatsElementInfoBlock.Root);

  const { info } = useContext(StatsElementContext);

  const content = useMemo(() => info?.[props.type], [info, props]);

  const isFootNote = useMemo(() => props.type === StatsElementInfoType.Footnote, [props]);

  return (
    <div
      className={cn({
        [StatsElementInfoModifier.Description]: !isFootNote,
        [StatsElementInfoModifier.FootNote]: isFootNote,
      })}
    >
      {content && (
        <>
          {isFootNote && <b>Note: </b>}
          <HtmlStringFormatter content={content} />
        </>
      )}
    </div>
  );
};

export default StatsElementInfo;
