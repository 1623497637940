import { GridRow } from '../types/GridRow';
import { GridColumn } from '../interfaces/GridColumn';
import React, { ReactNode } from 'react';
import { GridCellFormatter } from '../types/GridCellFormatter';
import { GridCellFormatterType } from '../enums/GridCellFormatterType';
import CustomCellFormatter from '../components/formatters/cell/CustomCellFormatter';
import FileActionsCellFormatter from '../components/formatters/cell/FileActionsCellFormatter';
import FavouriteCellFormatter from '../components/formatters/cell/FavouriteCellFormatter';
import LinkCellFormatter from '../components/formatters/cell/LinkCellFormatter';
import ColorNumberCellFormatter from '../components/formatters/cell/ColorNumberCellFormatter';
import ColorSchemeCellFormatter from '../components/formatters/cell/ColorSchemeCellFormatter';
import { GridCellDataService } from '../services/GridCellDataService';
import { NO_CELL_VALUE_MESSAGE } from '../constants/cell';
import { GridCellValue } from '../types/GridCellValue';
import { CssStyles } from '../../../../interfaces/CssStyles';
import { AlignTypesHorizontal } from '../../../../enums/AlignTypesHorizontal';
import { CustomAny } from '../../../../types/generics';

export class GridCellFactory {
  static getNode(row: GridRow, column: GridColumn): ReactNode {
    const value = GridCellDataService.getValue(row, column);

    if (this.isNoValue(value, column.cellFormatter)) {
      return NO_CELL_VALUE_MESSAGE;
    }

    if (column.cellFormatter) {
      return this.getValueWithFormatter(row, column);
    } else {
      return this.getSimpleValue(row, column);
    }
  }

  static getStyles(column: GridColumn): CssStyles {
    return {
      ...this.getAlign(column),
    };
  }

  private static getAlign(column: GridColumn): CssStyles {
    let justifyContent: string;
    let textAlign: string;

    if (!column.alignHorizontal || column.alignHorizontal === AlignTypesHorizontal.Start) {
      justifyContent = 'flex-start';
      textAlign = 'left';
    } else if (column.alignHorizontal === AlignTypesHorizontal.Center) {
      justifyContent = 'center';
      textAlign = 'center';
    } else {
      justifyContent = 'flex-end';
      textAlign = 'right';
    }

    return {
      display: column.isEllipsis ? 'block' : 'flex',
      alignItems: 'center',
      justifyContent,
      textAlign,
    };
  }

  private static getValueWithFormatter(row: GridRow, column: GridColumn): ReactNode {
    const cellFormatter = column.cellFormatter as GridCellFormatter;
    const value = GridCellDataService.getValue(row, column);

    let component: CustomAny;
    const props = column.cellFormatter?.props || {};

    switch (cellFormatter.type) {
      case GridCellFormatterType.Favourite: {
        component = FavouriteCellFormatter;
        break;
      }
      case GridCellFormatterType.Link: {
        component = LinkCellFormatter;
        break;
      }
      case GridCellFormatterType.ColorNumber: {
        component = ColorNumberCellFormatter;
        break;
      }
      case GridCellFormatterType.ColorScheme: {
        component = ColorSchemeCellFormatter;
        break;
      }
      case GridCellFormatterType.FileActions: {
        component = FileActionsCellFormatter;
        break;
      }
      case GridCellFormatterType.Custom: {
        component = CustomCellFormatter;
        break;
      }
    }

    return React.createElement(component, {
      data: row,
      value,
      field: column.field,
      ...props,
    });
  }

  private static getSimpleValue(row: GridRow, column: GridColumn): ReactNode {
    const value = GridCellDataService.getValue(row, column);

    return column.valueFormatter
      ? column.valueFormatter({
          value,
          data: row,
        })
      : value;
  }

  private static isNoValue(value: GridCellValue, cellFormatter?: GridCellFormatter): boolean {
    return GridCellDataService.hasNoValue(value) && cellFormatter?.type !== GridCellFormatterType.Custom;
  }
}
