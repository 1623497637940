import React, { useMemo } from 'react';
import { screensCustomFilterSections } from '../../../contstants/screensCustomFilterSections';
import { ViewAppliedFilters } from '../../../../../../../shared/entities/view/components/view/interfaces/ViewAppliedFilters';
import { ViewFiltersDictionaries } from '../../../../../../../shared/entities/filters/interfaces/ViewFiltersDictionaries';
import FilterSections from '../../../../../../../shared/entities/filters/components/filter-sections/FilterSections';
import { FormValidationStateChangeFn } from '../../../../../../../shared/components/forms/types/FormValidationStateChangeFn';
import { AnyObject } from '../../../../../../../shared/types/generics';

interface Props {
  dictionaries?: ViewFiltersDictionaries;
  onChange: (filters: ViewAppliedFilters) => void;
  onValidationStateChange: FormValidationStateChangeFn;
  selectedFilters?: AnyObject;
}

const ScreensAddFiltersForm: React.FC<Props> = props => {
  const settings = useMemo(
    () => ({
      sections: screensCustomFilterSections,
      isSectionsAccordion: true,
      hasToolbar: true,
      hasActiveControlsCount: true,
    }),
    [],
  );

  const defaultValues = useMemo(() => props.selectedFilters, []);

  return (
    <FilterSections
      settings={settings}
      dictionaries={props.dictionaries}
      defaultValues={defaultValues}
      onChange={props.onChange}
      onValidationStateChange={props.onValidationStateChange}
    />
  );
};

export default ScreensAddFiltersForm;
