import { screensIndustrySharedOptions } from './screensIndustrySharedOptions';
import { ViewOptionsService } from '../../../../../shared/entities/view/components/view/services/ViewOptionsService';
import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { ViewColumnSystemName } from '../../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { ViewSummaryAlignType } from '../../../../../shared/entities/view/components/view/enums/ViewSummaryAlignType';

export const screensIndustryInUseOptions: Partial<ScreenOptions> = ViewOptionsService.merge<ScreenOptions>(
  screensIndustrySharedOptions,
  {
    grid: {
      idCols: [ViewColumnSystemName.Id],
    },
    summary: {
      align: ViewSummaryAlignType.Grid,
    },
    toolbar: {
      quickFilter: {
        hasIncludeSPAC: false,
      },
    },
  },
);
