import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';
import { BreakpointName } from '../enums/BreakpointName';
import { BreakpointSizes } from '../enums/BreakpointSizes';

function getDeviceConfig(width: number): BreakpointName {
  if (width < BreakpointSizes.ExtraSmall) {
    return BreakpointName.ExtraSmall;
  } else if (width >= BreakpointSizes.ExtraSmall && width < BreakpointSizes.Small) {
    return BreakpointName.Small;
  } else if (width >= BreakpointSizes.Small && width < BreakpointSizes.Medium) {
    return BreakpointName.Medium;
  } else if (width >= BreakpointSizes.Medium && width < BreakpointSizes.Large) {
    return BreakpointName.Large;
  } else {
    return BreakpointName.ExtraLarge;
  }
}

export default function useBreakpoint(): BreakpointName {
  const [breakpointName, setBreakpointName] = useState<BreakpointName>(() => getDeviceConfig(window.innerWidth));

  useEffect(() => {
    const onWindowResize = throttle(function() {
      setBreakpointName(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener('resize', onWindowResize);
    return (): void => window.removeEventListener('resize', onWindowResize);
  }, []);

  return breakpointName;
}
