import React from 'react';
import { AlignTypesHorizontal } from '../../shared/enums/AlignTypesHorizontal';
import PcwCriteriaSubheader from '../private-company-watchlist/PcwCriteriaSubheader';
import { PcmlCriteriaSubheader } from '../private-company-monitor-list/PcmlCriteriaSubheader';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../shared/enums/RoutePath';

export const pcwSubheaderHelper = {
  alignTypeHorizontal: AlignTypesHorizontal.Center,
  component: <PcwCriteriaSubheader />,
};

export const pcmlSubheaderHelper = {
  alignTypeHorizontal: AlignTypesHorizontal.Center,
  component: <PcmlCriteriaSubheader />,
};

export const estimatesSubheaderHelper = {
  alignTypeHorizontal: AlignTypesHorizontal.Center,
  component: (
    <p>
      Access IPO estimates in the Excel download below.{' '}
      <Link to={RoutePath.ContactUs}>Contact us about a real-time data feed via our APIs.</Link>
    </p>
  ),
};
