import React, { useContext, useMemo } from 'react';
import { CustomAny } from '../../../shared/types/generics';
import { CompanyContext } from '../context/CompanyContext';
import { RoutePath } from '../../../shared/enums/RoutePath';
import { Redirect } from 'react-router';

interface Props {
  data: CustomAny;
}

const CompanyTabPage: React.FC<Props> = props => {
  const { tabsData } = useContext(CompanyContext);

  const [, , isLoading] = tabsData;

  const isData = useMemo(() => isLoading || props.data, [props.data, isLoading]);

  return <>{isData ? props.children : <Redirect to={RoutePath.NotFound} push />}</>;
};

export default CompanyTabPage;
