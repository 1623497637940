import { useState, useCallback, useMemo } from 'react';
import { ContextData } from './types/ContextData';
import { HandleFnOptions } from '../handle-fn/interfaces/HandleFnOptions';
import { useAsyncFn } from '../async-fn/useAsyncFn';
import { CustomAny } from '../../types/generics';
import { FnWithAnyProps } from '../../interfaces/FnWithAnyProps';

export function useContextData<T>(loadDataFn: FnWithAnyProps<Promise<T>>): ContextData<T>;
export function useContextData<T>(
  loadDataFn: FnWithAnyProps<Promise<T>>,
  initialValue: T,
  options?: HandleFnOptions,
): ContextData<T>;

export function useContextData<T>(
  loadDataFn: FnWithAnyProps<Promise<T>>,
  initialValue?: T | undefined,
  options?: HandleFnOptions,
): ContextData<T | undefined> {
  const [data, setData] = useState<T | undefined>(initialValue);

  const loadData = useCallback(
    async (...args: CustomAny[]) => {
      const value: T = await loadDataFn(...args);

      setData(value);
    },
    [loadDataFn],
  );

  const [wrappedLoadDataFn, isLoading, , , isLoaded] = useAsyncFn(loadData, options);

  return useMemo(() => [data, wrappedLoadDataFn, isLoading, setData, isLoaded], [
    data,
    isLoading,
    wrappedLoadDataFn,
    setData,
    isLoaded,
  ]);
}
