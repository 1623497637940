import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { RoutePath } from '../shared/enums/RoutePath';
import LargestUSIPOs from '../stats/additional-stats/components/largest-us-ipos/LargestUSIPOs';
import LargestGlobalIPOs from '../stats/additional-stats/components/largest-global-ipos/LargestGlobalIPOs';
import PricingsComparedToRange from '../stats/additional-stats/components/pricing-compared-to-range/PricingsComparedToRange';
import USListingsFromNonUSIssuers from '../stats/additional-stats/components/us-listings-from-non-us-issuers/USListingsFromNonUSIssuers';
import ProfitableVsUnprofitable from '../stats/additional-stats/components/profitable-vs-unprofitable/ProfitableVsUnprofitable';
import Withdrawals from '../stats/additional-stats/components/withdrawals/Withdrawals';
import AverageAge from '../stats/additional-stats/components/average-age/AverageAge';
import MedianAge from '../stats/additional-stats/components/median-age/MedianAge';

const AdditionalStatsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={RoutePath.AdditionalStatsLargestUSIPOs} exact>
        <LargestUSIPOs />
      </Route>

      <Route path={RoutePath.AdditionalStatsLargestGlobalIPOs} exact>
        <LargestGlobalIPOs />
      </Route>

      <Route path={RoutePath.AdditionalStatsPricingsComparedToRange} exact>
        <PricingsComparedToRange />
      </Route>

      <Route path={RoutePath.AdditionalStatsUSListingsFromNonUSIssuers} exact>
        <USListingsFromNonUSIssuers />
      </Route>

      <Route path={RoutePath.AdditionalStatsProfitableVsUnprofitable} exact>
        <ProfitableVsUnprofitable />
      </Route>

      <Route path={RoutePath.AdditionalStatsWithdrawals} exact>
        <Withdrawals />
      </Route>

      <Route path={RoutePath.AdditionalStatsAverageAge} exact>
        <AverageAge />
      </Route>

      <Route path={RoutePath.AdditionalStatsMedianAge} exact>
        <MedianAge />
      </Route>

      <Route path={RoutePath.AdditionalStats} exact>
        <Redirect to={RoutePath.AdditionalStatsLargestUSIPOs} />
      </Route>
    </Switch>
  );
};

export default AdditionalStatsRoutes;
