import { screensUnderwriterSharedOptions } from './screensUnderwriterSharedOptions';
import { ViewOptionsService } from '../../../../../shared/entities/view/components/view/services/ViewOptionsService';
import { ViewColumnSystemName } from '../../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { ViewSummaryAlignType } from '../../../../../shared/entities/view/components/view/enums/ViewSummaryAlignType';

export const screensUnderwriterInUseOptions: Partial<ScreenOptions> = ViewOptionsService.merge<ScreenOptions>(
  screensUnderwriterSharedOptions,
  {
    grid: {
      idCols: [ViewColumnSystemName.Id],
    },
    summary: {
      align: ViewSummaryAlignType.Grid,
    },
  },
);
