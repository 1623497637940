import React, { useContext, useMemo } from 'react';
import './FavoriteIpos.scss';
import PrimarySectionRow from '../../../shared/components/primary-section/PrimarySectionRow';
import FavoriteIposForm from './components/form/FavoriteIposForm';
import { useClassName } from '../../../shared/hooks/useClassName';
import { FavoriteIposBlock } from './favoriteIposBem';
import ViewDashboardSecondarySection from '../../../shared/entities/view/components/view-dashboard/components/ViewDashboardSecondarySection';
import FavouriteIposSectionGrid from './components/FavouriteIposSectionGrid';
import { ViewType } from '../../../shared/entities/view/components/view/enums/ViewType';
import { ViewDashboardContext } from '../../../shared/entities/view/components/view-dashboard/ViewDashboardContext';
import { MyFavouritesDto } from '../../interfaces/MyFavouritesDto';
import { MyFavouritesDtoProp } from '../../enums/MyFavouritesDtoProp';
import SecondarySectionsGroup from '../../../shared/components/secondary-section/SecondarySectionsGroup';
import NewsLinksGrid from '../../../news/components/NewsLinksGrid';
import { NewsLocationService } from '../../../news/shared/services/NewsLocationService';

const FavoriteIpos: React.FC = () => {
  const cn = useClassName(FavoriteIposBlock.Root);

  const data = useContext(ViewDashboardContext).data as MyFavouritesDto;

  const newsPath = useMemo(() => NewsLocationService.createSearchUrl({}), []);

  return (
    <PrimarySectionRow className={cn()} header="Favorite IPOs" isHeaderNoBorder>
      <SecondarySectionsGroup>
        <ViewDashboardSecondarySection>
          <FavoriteIposForm />
        </ViewDashboardSecondarySection>

        <FavouriteIposSectionGrid
          viewType={ViewType.Scheduled}
          dataTable={data[MyFavouritesDtoProp.Scheduled]}
          text="Scheduled"
        />

        <FavouriteIposSectionGrid
          viewType={ViewType.Priced}
          dataTable={data[MyFavouritesDtoProp.Priced]}
          text="Priced"
        />

        <FavouriteIposSectionGrid
          viewType={ViewType.Pipeline}
          dataTable={data[MyFavouritesDtoProp.Filled]}
          text="Filed"
        />

        <FavouriteIposSectionGrid text="Others" dataTable={data[MyFavouritesDtoProp.Others]} />

        <ViewDashboardSecondarySection title="Favorites News" routePath={newsPath}>
          <NewsLinksGrid rows={data[MyFavouritesDtoProp.News]} />
        </ViewDashboardSecondarySection>
      </SecondarySectionsGroup>
    </PrimarySectionRow>
  );
};

export default FavoriteIpos;
