import React from 'react';
import CompanyTooltip from '../../../company/components/company-tooltip/CompanyTooltip';
import './CalendarEvent.scss';
import { CalendarEventBlock } from './CalendarEventBem';
import { useClassName } from '../../hooks/useClassName';
import MasterCalendarLegendItemWrapper from '../../../master-calendar/components/MasterCalendarLegendItemWrapper';
import { CalendarEventExtendedProps } from './interfaces/CalendarEventExtendedProps';

type Props = CalendarEventExtendedProps;

const CalendarEvent: React.FC<Props> = props => {
  const { favouriteProps } = props;

  const cn = useClassName(CalendarEventBlock.Root);

  return (
    <MasterCalendarLegendItemWrapper
      className={cn({
        [props.type]: props.type,
      })}
      type={props.type}
    >
      <CompanyTooltip {...props} favouriteProps={favouriteProps} additionalInfoHtml={props.span} />
    </MasterCalendarLegendItemWrapper>
  );
};

export default CalendarEvent;
