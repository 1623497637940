import { FilterControlSettings } from '../../../types/FilterControlSettings';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { ControlSize } from '../../../../../enums/ControlSize';
import { revenueGrowthTimeFrameOptions } from '../../options/revenueGrowthTimeFrameOptions';
import { PrependValueType } from '../../../../../components/forms/enums/PrependValueType';
import { AppendValueType } from '../../../../../components/forms/enums/AppendValueType';

export const financialsControls: FilterControlSettings = {
  [ViewFilterName._RevenueGrowth]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._RevenueGrowth,
    props: {
      label: 'Revenue Growth',
      minName: ViewFilterName.RevenueGrowthMin,
      maxName: ViewFilterName.RevenueGrowthMax,
      minPrepend: [PrependValueType.Min],
      minAppend: [AppendValueType.Percentage],
      maxPrepend: [PrependValueType.Max],
      maxAppend: [AppendValueType.Percentage],
      size: ControlSize.Small,
      // width: ControlSize.Small,
    },
  },
  [ViewFilterName.RevenueGrowthPeriod]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName.RevenueGrowthPeriod,
    props: {
      name: ViewFilterName.RevenueGrowthPeriod,
      label: 'Revenue Growth Period',
      options: revenueGrowthTimeFrameOptions,
      size: ControlSize.Small,
      isUnselectAvailable: true,
    },
  },
  [ViewFilterName._EstimatedSales]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._EstimatedSales,
    props: {
      label: 'Estimated Sales ($mm)',
      minName: ViewFilterName.EstimatedSalesMin,
      maxName: ViewFilterName.EstimatedSalesMax,
      minPrepend: [PrependValueType.Min, PrependValueType.Money],
      maxPrepend: [PrependValueType.Max, PrependValueType.Money],
      size: ControlSize.Small,
      width: ControlSize.Small,
    },
  },
  [ViewFilterName._LtmLatestSales]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._LtmLatestSales,
    props: {
      label: 'LTM Sales ($mm)',
      minName: ViewFilterName.LtmLatestSalesMin,
      maxName: ViewFilterName.LtmLatestSalesMax,
      minPrepend: [PrependValueType.Min, PrependValueType.Money],
      maxPrepend: [PrependValueType.Max, PrependValueType.Money],
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._LtmGrossMargin]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._LtmGrossMargin,
    props: {
      label: 'LTM Gross Margin',
      minName: ViewFilterName.LtmGrossMarginMin,
      maxName: ViewFilterName.LtmGrossMarginMax,
      minPrepend: [PrependValueType.Min],
      minAppend: [AppendValueType.Percentage],
      maxPrepend: [PrependValueType.Max],
      maxAppend: [AppendValueType.Percentage],
      size: ControlSize.Small,
      width: ControlSize.Small,
    },
  },
  [ViewFilterName._LtmEbitdaMargin]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._LtmEbitdaMargin,
    props: {
      label: 'LTM EBITDA Margin',
      minName: ViewFilterName.LtmEbitdaMarginMin,
      maxName: ViewFilterName.LtmEbitdaMarginMax,
      minPrepend: [PrependValueType.Min],
      minAppend: [AppendValueType.Percentage],
      maxPrepend: [PrependValueType.Max],
      maxAppend: [AppendValueType.Percentage],
      size: ControlSize.Small,
      // width: ControlSize.Small,
    },
  },
  [ViewFilterName._LtmNetMargin]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._LtmNetMargin,
    props: {
      label: 'LTM Net Margin',
      minName: ViewFilterName.LtmNetMarginMin,
      maxName: ViewFilterName.LtmNetMarginMax,
      minPrepend: [PrependValueType.Min],
      minAppend: [AppendValueType.Percentage],
      maxPrepend: [PrependValueType.Max],
      maxAppend: [AppendValueType.Percentage],
      size: ControlSize.Small,
      width: ControlSize.Small,
    },
  },
  [ViewFilterName._FinancialsLTMCashFlowFromOperation]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._FinancialsLTMCashFlowFromOperation,
    props: {
      label: 'LTM Cash Flow From Operations',
      minName: ViewFilterName.FinancialsLTMCashFlowFromOperationMin,
      maxName: ViewFilterName.FinancialsLTMCashFlowFromOperationMax,
      minPrepend: [PrependValueType.Min, PrependValueType.Money],
      maxPrepend: [PrependValueType.Max, PrependValueType.Money],
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._FinancialsLTMCapex]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._FinancialsLTMCapex,
    props: {
      label: 'LTM Capex',
      minName: ViewFilterName.FinancialsLTMCapexMin,
      maxName: ViewFilterName.FinancialsLTMCapexMax,
      minPrepend: [PrependValueType.Min, PrependValueType.Money],
      maxPrepend: [PrependValueType.Max, PrependValueType.Money],
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._FinancialsLTMFreeCashFlow]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._FinancialsLTMFreeCashFlow,
    props: {
      label: 'LTM Free Cash Flow',
      minName: ViewFilterName.FinancialsLTMFreeCashFlowMin,
      maxName: ViewFilterName.FinancialsLTMFreeCashFlowMax,
      minPrepend: [PrependValueType.Min, PrependValueType.Money],
      maxPrepend: [PrependValueType.Max, PrependValueType.Money],
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._IpoDebtToEbitda]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._IpoDebtToEbitda,
    props: {
      label: 'IPO Debt to EBITDA',
      minName: ViewFilterName.IpoDebtToEbitdaMin,
      maxName: ViewFilterName.IpoDebtToEbitdaMax,
      minPrepend: [PrependValueType.Min],
      maxPrepend: [PrependValueType.Max],
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.MostRecentFinancialsDateMin]: {
    type: FormControlType.DateSelect,
    name: ViewFilterName.MostRecentFinancialsDateMin,
    props: {
      label: 'Most Recent Financials',
      name: ViewFilterName.MostRecentFinancialsDateMin,
      placeholder: 'Select Date',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.Dividend]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.Dividend,
    props: {
      name: ViewFilterName.Dividend,
      label: 'Quarterly Dividend',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._FinancialsPostIpoCash]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._FinancialsPostIpoCash,
    props: {
      label: 'Post-IPO Cash',
      minName: ViewFilterName.FinancialsPostIpoCashMin,
      maxName: ViewFilterName.FinancialsPostIpoCashMax,
      minPrepend: [PrependValueType.Min, PrependValueType.Money],
      maxPrepend: [PrependValueType.Max, PrependValueType.Money],
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._FinancialsPostIpTotalAssets]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._FinancialsPostIpTotalAssets,
    props: {
      label: 'Post-IPO Total Assets',
      minName: ViewFilterName.FinancialsPostIpoTotalAssetsMin,
      maxName: ViewFilterName.FinancialsPostIpoTotalAssetsMax,
      minPrepend: [PrependValueType.Min, PrependValueType.Money],
      maxPrepend: [PrependValueType.Max, PrependValueType.Money],
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._FinancialsPostIpoTotalDebt]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._FinancialsPostIpoTotalDebt,
    props: {
      label: 'Post-IPO Total Debt',
      minName: ViewFilterName.FinancialsPostIpoTotalDebtMin,
      maxName: ViewFilterName.FinancialsPostIpoTotalDebtMax,
      minPrepend: [PrependValueType.Min, PrependValueType.Money],
      maxPrepend: [PrependValueType.Max, PrependValueType.Money],
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._FinancialsPostIpoEquity]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._FinancialsPostIpoEquity,
    props: {
      label: 'Post-IPO Equity',
      minName: ViewFilterName.FinancialsPostIpoEquityMin,
      maxName: ViewFilterName.FinancialsPostIpoEquityMax,
      minPrepend: [PrependValueType.Min, PrependValueType.Money],
      maxPrepend: [PrependValueType.Max, PrependValueType.Money],
      size: ControlSize.Small,
    },
  },
};
