import React from 'react';
import Helmet from 'react-helmet';
import ContactForm from './form/ContactForm';
import './Contact.scss';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';

const Contact: React.FC = () => {
  return (
    <div className="Contact">
      <Helmet>
        <title>Contact Us &mdash; Renaissance Capital</title>
      </Helmet>

      <PrimarySection header="Contact Us">
        <ContactForm />
      </PrimarySection>
    </div>
  );
};

export default Contact;
