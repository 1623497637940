import React, { useContext, useCallback } from 'react';
import FormEntityActionModal from '../../../../shared/components/modal/FormEntityActionModal';
import { ModalEntityActionType } from '../../../../shared/components/modal/enums/ModalEntityActionType';
import { FiltersValues } from '../../../../shared/entities/filters/types/FiltersValues';
import StatsPopupFiltersControls from './StatsPopupFiltersControls';
import { ObjectHelper } from '../../../../shared/helpers/ObjectHelper';
import { StatsPageContext } from '../../context/StatsPageContext';
import { ViewFilterName } from '../../../../shared/entities/filters/enum/types/ViewFilterName';
import { StatsFilterName } from '../../enum/StatsFilterName';

interface Props {
  showState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

const StatsFilterPopup: React.FC<Props> = props => {
  const { pageFilters, onPopupFiltersChange } = useContext(StatsPageContext);

  const onSubmit = useCallback(
    (filters: FiltersValues) => {
      // modalFilters are going to be a copy of page filters
      // minus the filters not applied using popupfilters
      // So, if popup filters were intially populated, and then
      // and then if we send blank filters, we execute onPopupFiltersChange
      // If there were no initial popupfilters applied, and new filters are empty
      // then we don't execute onPopupFiltersChange
      const modalFilters = { ...pageFilters };
      delete modalFilters[ViewFilterName.Headquarter];
      delete modalFilters[StatsFilterName.IncludeAShares];
      delete modalFilters[StatsFilterName.IncludeSPACs];

      if (!ObjectHelper.arePropsEmpty(filters) || !ObjectHelper.arePropsEmpty(modalFilters)) {
        onPopupFiltersChange(filters);
      }
    },
    [onPopupFiltersChange],
  );

  return (
    <FormEntityActionModal
      showState={props.showState}
      entityName="Filter"
      defaultValues={pageFilters}
      actionType={ModalEntityActionType.Custom}
      body={<StatsPopupFiltersControls />}
      onSubmit={onSubmit}
    />
  );
};

export default StatsFilterPopup;
