import { Context, createContext } from 'react';
import { ContextData } from '../../shared/hooks/context-data/types/ContextData';
import { ViewSaved } from '../../shared/entities/view/components/view/interfaces/api/ViewSaved';
import { NavItemLink } from '../../shared/components/nav/interfaces/NavItemLink';

export interface ScreensContextValue {
  savedScreensData: ContextData<ViewSaved[] | undefined>;
  savedScreensLinks: NavItemLink[];
  isContextDataLoading: boolean;
}

export const ScreensContext: Context<ScreensContextValue> = createContext<ScreensContextValue>({
  savedScreensData: [[], () => Promise.resolve(), false, () => {}, false],
  isContextDataLoading: true,
  savedScreensLinks: [],
});
