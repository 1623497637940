import React, { Component } from 'react';
import ColorNumberFormatter from '../../../../../formatters/ColorNumberFormatter';
import { GridCellFormatterColorNumberProps, GridCellFormatterCommonProps } from '../../../interfaces/gridCellFormatter';
import CellFormatterWrapper from './CustomCellFormatterWrapper';

interface Props extends GridCellFormatterColorNumberProps, GridCellFormatterCommonProps {}

class ColorNumberCellFormatter extends Component<Props, { displayValue?: string }> {
  constructor(props: Props) {
    super(props);

    this.state = { displayValue: this.getDisplayValue(props) };
  }

  getDisplayValue(props: Props) {
    return props.displayValueColumn ? props.data[props.displayValueColumn] : undefined;
  }

  render() {
    return (
      <CellFormatterWrapper value={this.props.value}>
        <ColorNumberFormatter value={this.props.value} displayValue={this.state.displayValue} />
      </CellFormatterWrapper>
    );
  }
}

export default ColorNumberCellFormatter;
