import { Thunk } from 'react-hook-thunk-reducer';
import { ActionTypes, ViewState } from '../types';
import { ViewTodaysPriceChangeService } from '../../services/ViewTodaysPriceChangeService';
import { SocketUpdateTickersData } from '../../socket/types/SocketUpdateTickersData';
import { SocketAction } from '../../socket/enums/SocketAction';
import { canUpdatePriceChangeSelector } from '../selectors';
import { SocketEvent } from '../../socket/enums/SocketEvent';

export const updateTodaysPriceChangeTickers = (): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state: ViewState = getState();
    const tickers = ViewTodaysPriceChangeService.getTickers(state.rows);
    try {
      await state?.socket?.invoke<SocketUpdateTickersData>(SocketAction.UpdateTickers, tickers);
    } catch (e) {
      // TODO: handle when error updating tickers
      console.log(e);
    }
  };
};

export const UnSubscribePriceChange = (): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state: ViewState = getState();
    try {
      await state?.socket?.unSubscribeEvent(SocketEvent.TodaysPriceChangeUpdate);
    } catch (e) {
      // TODO: handle when error updating tickers
      // console.log(e);
    }
  };
};

export const updateTodaysPriceChangeByAvailability = (): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state = getState();
    const canUpdatePriceChange = canUpdatePriceChangeSelector(state);

    if (canUpdatePriceChange) {
      dispatch(updateTodaysPriceChangeTickers());
    }
  };
};
