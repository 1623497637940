import { FilterSectionsFormContextValue } from './FilterSectionsFormContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FilterSectionsStateService } from '../services/FilterSectionsStateService';
import { BreakpointName } from '../../../../../enums/BreakpointName';
import { FilterSectionsProps } from '../FilterSections';
import { ViewFilterService } from '../../../../view/components/view/services/ViewFilterService';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FilterSettingsService } from '../../../services/FilterSettingsService';
import { useThrottle } from 'react-use';
import { Location } from '../../../enum/Location';

const formDataThrottleTime = 500;

export function useFilterSectionsFormContext(data: FilterSectionsProps): FilterSectionsFormContextValue {
  const { dictionaries, isAdvanced = true, settings, defaultValues, onChange, onValidationStateChange } = data;

  const settingsWithControlsProps = useMemo(() => FilterSectionsStateService.getSettingsWithControlProps(settings), [
    settings,
  ]);
  const [sectionsStates, setSectionsState] = useState(() =>
    FilterSectionsStateService.getInital(settingsWithControlsProps),
  );
  const sectionsTypes = useMemo(() => FilterSettingsService.getSectionTypes(sectionsStates), [sectionsStates]);
  const [formData, setFormData] = useState();
  const formDataThrottled = useThrottle(formData, formDataThrottleTime);
  let isNonUs = formData?.[ViewFilterName.LocationListingLocation] === Location.NonUS;

  const sectionsBreakpoints = useMemo(() => (settings.isSectionsAccordion ? undefined : [BreakpointName.ExtraSmall]), [
    settings.isSectionsAccordion,
  ]);

  const onCloseChange = useCallback((types, isClose) => {
    setSectionsState(sectionsStates =>
      FilterSectionsStateService.getUpdated(
        FilterSectionsStateService.getCloseChange.bind(FilterSectionsStateService, types, isClose),
        sectionsStates,
      ),
    );
  }, []);

  const onFormChange = useCallback(
    formData => {
      const filters = ViewFilterService.deleteEmptyFilters(formData);
      if (!filters.RevenueGrowthPeriod && (filters.RevenueGrowth_Min !== '' || filters.RevenueGrowth_Max !== '')) {
        filters.RevenueGrowthPeriod = 'ytd';
        if (defaultValues) {
          defaultValues['RevenueGrowthPeriod'] = 'ytd';
        }
      } else {
        if (defaultValues && defaultValues.hasOwnProperty('RevenueGrowthPeriod')) {
          delete defaultValues.RevenueGrowthPeriod;
        }
      }

      if (!filters.timeFrameUpdated && !filters.timeframe && defaultValues) {
        filters.timeframe = '90Days';
        defaultValues['timeframe'] = '90Days';
        if (
          (filters.DealStatus_In?.length > 0 &&
            (filters.DealStatus_In.includes('Scheduled') ||
              filters.DealStatus_In.includes('Filed') ||
              filters.DealStatus_In.includes('Postponed') ||
              filters.DealStatus_In.includes('PCW'))) ||
          filters.StatusWithAcqPos
        ) {
          delete defaultValues['timeframe'];
          filters.timeframe = '';
        }
      }

      if (!filters.timeframe) {
        filters.timeframe = '';
      }

      setFormData(filters);
      onChange?.(filters);
    },
    [onChange],
  );

  useEffect(() => {
    if (data?.defaultValues?.[ViewFilterName.LocationListingLocation] === Location.NonUS) {
      isNonUs = true;
    }
  }, []);

  useEffect(
    () =>
      setSectionsState(sectionsStates =>
        FilterSectionsStateService.getUpdated(
          FilterSectionsStateService.getVisibleChange.bind(FilterSectionsStateService, {
            hasAdvancedBtn: settings.hasAdvancedBtn,
            isNonUs,
            isAdvanced,
          }),
          sectionsStates,
        ),
      ),
    [isAdvanced, formData, isNonUs, settings.hasAdvancedBtn],
  );

  useEffect(() => {
    if (!settings.hasActiveControlsCount) {
      return;
    }

    setSectionsState(sectionsStates =>
      FilterSectionsStateService.getUpdated(
        FilterSectionsStateService.getFormChange.bind(
          FilterSectionsStateService,
          settingsWithControlsProps.sections,
          formDataThrottled,
        ),
        sectionsStates,
      ),
    );
  }, [formDataThrottled, settingsWithControlsProps.sections, settings.hasActiveControlsCount]);

  return useMemo(
    () => ({
      dictionaries,
      isAdvanced,
      settings,
      sectionsStates,
      sectionsTypes,
      sectionsBreakpoints,
      defaultValues,
      onCloseChange,
      onFormChange,
      onValidationStateChange,
    }),
    [
      dictionaries,
      isAdvanced,
      settings,
      sectionsStates,
      sectionsTypes,
      sectionsBreakpoints,
      defaultValues,
      onCloseChange,
      onFormChange,
      onValidationStateChange,
    ],
  );
}
