import React from 'react';
import SimpleGrid, { SimpleGridPropsCommon } from '../../../../components/grid/simple-grid/SimpleGrid';
import { SimpleGridType } from '../../../../components/grid/simple-grid/enums/SimpleGridType';
import { ViewGridColumnOptions } from './types/ViewGridColumnOptions';
import { useViewSimpleGridColumns } from './hooks/useViewSimpleGridColumns';
import { useViewGridRows } from './hooks/useViewGridRows';
import { ViewGridColumnsSetupOptions } from './interfaces/ViewGridColumnsSetupOptions';

export interface ViewSimpleGridProps extends SimpleGridPropsCommon, ViewGridColumnsSetupOptions {
  columns: ViewGridColumnOptions[];
  width?: number;
  widgetType?: string;
  pinTickerColumn?: boolean;
}

const ViewSimpleGrid: React.FC<ViewSimpleGridProps> = props => {
  const { styleType = SimpleGridType.Grid } = props;

  const [columns, isTickerPinned] = useViewSimpleGridColumns(props.columns, props.rows, props.width, props.widgetType, {
    sort: props.sort,
    customColumnsSettings: props.customColumnsSettings,
    isDateColumnSortingInverse: props.isDateColumnSortingInverse,
    shouldWrapCellContainer: props.shouldWrapCellContainer,
    onColumnMapReady: props.onColumnMapReady,
    context: props.context,
  });

  const rows = useViewGridRows(props.rows, props.columns);

  return (
    <SimpleGrid
      {...props}
      isTickerPinned={isTickerPinned && props.pinTickerColumn}
      styleType={styleType}
      functionalType={SimpleGridType.Grid}
      columns={columns}
      rows={rows}
    />
  );
};

export default ViewSimpleGrid;
