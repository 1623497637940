import React from 'react';
import { useClassName } from '../../../shared/hooks/useClassName';
import {
  CompanyTitleWrapperBlock,
  CompanyTitleWrapperElement,
  CompanyTitleWrapperModifier,
} from './companyTooltipTitleWrapperBem';
import './CompanyTitleWrapper.scss';
import Favourite, { FavouriteProps } from '../../../shared/components/favourites/Favourite';
import { CompanyRatingValueDto } from '../company-rating/types/CompanyRatingValueDto';
import CompanyRating from '../company-rating/CompanyRating';
import HtmlStringFormatter from '../../../shared/components/HtmlStringFormatter';

interface Props {
  favouriteProps?: FavouriteProps;
  ratingText?: CompanyRatingValueDto;
  additionalInfoHtml?: string;
  isEllipsis?: boolean;
}

const CompanyTitleWrapper: React.FC<Props> = props => {
  const cn = useClassName(CompanyTitleWrapperBlock.Root);

  return (
    <div
      className={cn({
        [CompanyTitleWrapperModifier.Ellipsis]: props.isEllipsis,
      })}
    >
      {props.favouriteProps && <Favourite {...props.favouriteProps} />}

      <div className={cn(CompanyTitleWrapperElement.Content)}>
        {props.children}

        {props.additionalInfoHtml && <HtmlStringFormatter content={props.additionalInfoHtml} />}

        {props.ratingText && <CompanyRating ratingValue={props.ratingText} />}
      </div>
    </div>
  );
};

export default CompanyTitleWrapper;
