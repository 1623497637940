import { GridAgColumn } from '../../../../shared/components/grid/grid/interfaces/GridAgColumn';
import { ViewColumnSystemName } from '../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';

export const videosCustomColumnsSettings: Partial<GridAgColumn>[] = [
  {
    field: ViewColumnSystemName.VideoTitle,
    flex: 1,
  },
  {
    field: ViewColumnSystemName.VideoType,
    flex: 0.5,
  },
  {
    field: ViewColumnSystemName.PublishDate,
    width: 100,
    minWidth: 100,
  },
];
