import React from 'react';
import FilterControl from '../../FilterControl';
import { ViewFilterName } from '../../../../../enum/types/ViewFilterName';
import { useStatusAvailabilityDependencies } from '../../../../../hooks/useStatusAvailabilityDependencies';

const STATUS_AVAILABILITY_FILTER_NAMES = [
  ViewFilterName._DealStatusScheduledList,
  ViewFilterName._DealStatusFiledTermsList,
  ViewFilterName._DealStatusPostponedList,
];

const DealStatusStatusAvailabilityControls: React.FC = () => {
  useStatusAvailabilityDependencies(STATUS_AVAILABILITY_FILTER_NAMES);

  return (
    <>
      <FilterControl name={ViewFilterName._DealStatusScheduledList} />

      <FilterControl name={ViewFilterName._DealStatusFiledTermsList} />

      <FilterControl name={ViewFilterName._DealStatusPostponedList} />
    </>
  );
};

export default DealStatusStatusAvailabilityControls;
