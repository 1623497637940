import { usePromiseState } from '../../../shared/hooks/promise-state/usePromiseState';
import { useCallback } from 'react';
import { NewsApiService } from '../../../shared/api/NewsApiService';
import { NewsMostViewedDto } from '../../interfaces/NewsMostViewedDto';

export function useNewsMostItems(): [NewsMostViewedDto[], boolean] {
  return usePromiseState<NewsMostViewedDto[]>(
    useCallback(() => NewsApiService.getMostViewed(), []),
    [],
  );
}
