import { GridCellFormatterType as GridCellFormatterEnum } from '../../../../../components/grid/shared/enums/GridCellFormatterType';
import StatsElementSimpleGridViewTriggerCell from '../components/cells/StatsElementSimpleGridViewTriggerCell';
import { OnViewGridColumnMapReady } from '../../../../view/components/view-grid/types/OnViewGridColumnMapReady';
import { StatsElementSimpleGridViewTriggerCellViewLinkProps } from '../components/cells/interfaces/StatsElementSimpleGridViewTriggerCellViewLinkProps';
import { StatsElementSimpleGridViewTriggerService } from '../services/StatsElementSimpleGridViewTriggerService';
import { StatsElementGridContext } from '../interfaces/StatsElementGridContext';
import { ViewGridColumnWidth } from '../../../../view/components/view-grid/enums/ViewGridColumnWidth';
import { StatsElementSimpleGridColumnYerToViewMapperGetPropsFn } from '../types/StatsElementSimpleGridColumnYerToViewMapperGetPropsFn';
import { ViewFilterName } from '../../../../filters/enum/types/ViewFilterName';
import { EnumHelper } from '../../../../../helpers/EnumHelper';
import { StatsFilterName } from '../../../../../../stats/stats/enum/StatsFilterName';

const getPropsWithRequiredFilters = (
  context: StatsElementGridContext,
  getProps: StatsElementSimpleGridColumnYerToViewMapperGetPropsFn,
) => {
  const props = getProps(context);

  if (!props.optionsList) {
    props.optionsList = [];
  }

  // There is a mistaken filter name "Headquarter" on stats pages
  // Should be "LocationListingLocation"
  // So we remap here to valid name, so view could filter it right
  // We also copy all of the filters from the Stats filters onto
  // the Grid element ViewLinks, so the linked pages receive the correct filters
  // Include Spac filter is applied on the specific Stat's page. see pricingsStatsElementOptions.ts

  if (context && context.pageFilters) {
    props.optionsList.push({
      getFilterName: ViewFilterName.LocationListingLocation,
      getFilterValue: () => context?.pageFilters?.[ViewFilterName.Headquarter],
    });

    for (const filter of Object.keys(context.pageFilters)) {
      let filterName = undefined;
      let filterValueFn = undefined;
      if (filter === ViewFilterName.IncludeSpac || filter === ViewFilterName.Headquarter) {
        continue;
      }
      if (filter === StatsFilterName.IncludeAShares) {
        filterName = ViewFilterName.IncludeChina;
        filterValueFn = () => context?.pageFilters && context.pageFilters[StatsFilterName.IncludeAShares];
      } else {
        const key = EnumHelper.getEnumKeyByEnumValue(ViewFilterName, filter);
        filterName = filter as ViewFilterName;
        filterValueFn = () =>
          context?.pageFilters && context.pageFilters[ViewFilterName[key as keyof typeof ViewFilterName]];
      }
      props?.optionsList?.push({
        getFilterName: filterName,
        getFilterValue: filterValueFn,
      });
    }
  }

  return props;
};

export const getStatsElementSimpleGridColumnYearToViewMapper: (
  getProps: (context: StatsElementGridContext) => StatsElementSimpleGridViewTriggerCellViewLinkProps,
) => OnViewGridColumnMapReady = (getProps: StatsElementSimpleGridColumnYerToViewMapperGetPropsFn) => (
  column,
  index,
  context,
) => {
  return StatsElementSimpleGridViewTriggerService.isColumnViewTriggerCell(column)
    ? {
        ...column,
        width: ViewGridColumnWidth.Md,
        cellFormatter: {
          type: GridCellFormatterEnum.Custom,
          props: {
            component: StatsElementSimpleGridViewTriggerCell,
            ...getPropsWithRequiredFilters(context as StatsElementGridContext, getProps),
          },
        },
      }
    : {
        ...column,
        flex: 1,
      };
};
