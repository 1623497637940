import { RoutePath } from '../../../shared/enums/RoutePath';
import { Location } from '../../../shared/entities/filters/enum/Location';

export type DisabledHeadquartersRoutes = Partial<
  {
    [key in RoutePath]: Location;
  }
>;

export const DISABLED_HEADQUARTERS_ROUTES: DisabledHeadquartersRoutes = {
  [RoutePath.AdditionalStats]: Location.US,
  [RoutePath.AdditionalStatsLargestUSIPOs]: Location.US,
  [RoutePath.AdditionalStatsLargestGlobalIPOs]: Location.Global,
  [RoutePath.AdditionalStatsAverageAge]: Location.US,
  [RoutePath.AdditionalStatsMedianAge]: Location.US,
  [RoutePath.AdditionalStatsWithdrawals]: Location.US,
  [RoutePath.AdditionalStatsProfitableVsUnprofitable]: Location.US,
  [RoutePath.AdditionalStatsUSListingsFromNonUSIssuers]: Location.US,
  [RoutePath.StatsGlobalBreakdown]: Location.Global,
};
