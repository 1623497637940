import { FiltersValues } from '../../../shared/entities/filters/types/FiltersValues';
import { ViewFilterName } from '../../../shared/entities/filters/enum/types/ViewFilterName';
import { Location } from '../../../shared/entities/filters/enum/Location';
import { StatsFilterName } from '../enum/StatsFilterName';
import { StatsPagesFiltersSettings } from '../interfaces/StatsPagesFiltersSettings';
import { RoutePath } from '../../../shared/enums/RoutePath';
import { useCallback, useMemo, useState } from 'react';
import { AdditionalStatsService } from '../../additional-stats/services/AdditionalStatsService';
import { pagesPathsTypes } from '../../constants/pagesPathsTypes';
import { StatsPageContextValue } from './StatsPageContext';
import { ObjectHelper } from '../../../shared/helpers/ObjectHelper';
import { StatsApiService } from '../../../shared/api/StatsApiService';
import { StatsElementType } from '../../../shared/entities/stats-element/enums/StatsElementType';
import { SimpleGridProps } from '../../../shared/components/grid/simple-grid/SimpleGrid';
import { ViewGridProps } from '../../../shared/entities/view/components/view-grid/ViewGrid';

export function useStatsPageContextValue(
  pagesFiltersSettings: StatsPagesFiltersSettings,
  currentRoutePath: RoutePath,
  onPageFiltersChange?: (pageFilters: FiltersValues) => void,
): StatsPageContextValue {
  const type = pagesPathsTypes[currentRoutePath];
  const pageFiltersSettings = useMemo(() => {
    const pageFilterSettingsPropName = type;
    return pageFilterSettingsPropName && pagesFiltersSettings[pageFilterSettingsPropName];
  }, [pagesFiltersSettings, type]);

  const [gridsToExcel, setGridsToExcel] = useState({});
  const isExcelActionAvailable = !ObjectHelper.arePropsEmpty(gridsToExcel);

  const [pageFilters, setPageFilters] = useState<FiltersValues>(() => {
    const headquarterByRoute = AdditionalStatsService.getDisabledHeadquarterFilter(currentRoutePath);
    const headquarter = headquarterByRoute ? headquarterByRoute : Location.US;
    const includeFilterName =
      headquarter === Location.US ? StatsFilterName.IncludeSPACs : StatsFilterName.IncludeAShares;

    let filters: FiltersValues = {
      [ViewFilterName.Headquarter]: headquarter,
      [includeFilterName]: false,
    };

    if (pageFiltersSettings?.defaultFilterValues) {
      filters = { ...filters, ...pageFiltersSettings.defaultFilterValues };
    }

    onPageFiltersChange?.(filters);

    return filters;
  });

  const includeFilters = useMemo(() => {
    const hasIncludeFilter = pageFilters[StatsFilterName.IncludeSPACs] || pageFilters[StatsFilterName.IncludeAShares];
    const isUsHeadquarter = pageFilters[ViewFilterName.Headquarter] === Location.US;

    if (hasIncludeFilter) {
      if (isUsHeadquarter) {
        return { [StatsFilterName.IncludeAShares]: false };
      } else {
        return { [StatsFilterName.IncludeSPACs]: false };
      }
    }

    return {};
  }, [pageFilters]);

  const onFiltersChange = useCallback(
    (partialFilters: FiltersValues) => {
      setPageFilters(oldFilters => {
        let updatedFilters;

        const newFilters = {
          ...oldFilters,
          ...partialFilters,
          ...includeFilters,
        };

        // TODO: check why on filters change triggered many times
        if (ObjectHelper.isEqualNoEmpty(newFilters, oldFilters)) {
          updatedFilters = oldFilters;
        } else {
          updatedFilters = newFilters;
        }

        onPageFiltersChange?.(updatedFilters);

        return updatedFilters;
      });
    },
    [onPageFiltersChange, includeFilters],
  );

  const onPopupFiltersChange = useCallback(filters => onFiltersChange(filters), [onFiltersChange]);

  const onInlineFiltersChange = useCallback(filters => onFiltersChange(filters), [onFiltersChange]);

  const onGridsToExcelChange = useCallback(newGridsToExcel => {
    setGridsToExcel(newGridsToExcel);
  }, []);

  const onExcelAction = useCallback(() => {
    const values = Object.values(gridsToExcel) as Partial<SimpleGridProps | ViewGridProps>[];

    return StatsApiService.getExcelGrids(values, type as StatsElementType);
  }, [gridsToExcel, type]);

  return useMemo(
    () => ({
      pageFilters,
      pageFiltersSettings,
      onInlineFiltersChange,
      onPopupFiltersChange,
      setPageFilters,
      isExcelActionAvailable,
      onGridsToExcelChange,
      onExcelAction,
    }),
    [
      pageFilters,
      pageFiltersSettings,
      onInlineFiltersChange,
      onPopupFiltersChange,
      setPageFilters,
      isExcelActionAvailable,
      onGridsToExcelChange,
      onExcelAction,
    ],
  );
}
