import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { ViewColumnSystemName } from '../../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { ViewOptionsService } from '../../../../../shared/entities/view/components/view/services/ViewOptionsService';
import { screensLocationSharedOptions } from './screensLocationSharedOptions';
import { ScreensLocationFilterName } from '../enums/ScreensLocationFilterName';

export const screensLocationLandingOptions: Partial<ScreenOptions> = ViewOptionsService.merge<ScreenOptions>(
  screensLocationSharedOptions,
  {
    isLanding: true,
    grid: {
      idCols: [ViewColumnSystemName.Headquarters],
    },
    screenTriggerOptions: {
      columnName: ViewColumnSystemName.Headquarters,
      filterName: ScreensLocationFilterName.ListingLocation,
      filterValueColumnName: ViewColumnSystemName.HeadquartersKey,
    },
  },
);
