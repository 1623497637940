import { ErrorData } from './interfaces/ErrorData';
import { ErrorDataProps } from './enum/ErrorDataProps';
import { ErrorInvalidParam } from './interfaces/ErrorInvalidParam';

export class ErrorMessageService {
  static getSummaryMessage(error: ErrorData): string {
    return error[ErrorDataProps.SummaryErrors] ? this.getMessage(error[ErrorDataProps.SummaryErrors] as string[]) : '';
  }

  static getParamMessage(param: ErrorInvalidParam): string {
    return this.getMessage(param.reason);
  }

  static getMessage(messages: string[]): string {
    return messages ? messages.join(', ') : '';
  }
}
