import { max } from 'lodash';
import { GridRow } from '../../shared/types/GridRow';
import { Nullable } from '../../../../types/generics';
import { PortionSize } from '../../../../enums/PortionSize';
import { GridAgColumn } from '../interfaces/GridAgColumn';
import { GridHeightMultiplier } from '../enums/GridHeightMultiplier';
import { ROW_HEIGHT_MIN } from '../constants/rows';

export class GridRowService {
  static getRowId(row: GridRow, idCols: string[]): string {
    return idCols.map(idCol => row[idCol]).join();
  }

  static getRowsCount(rows: Nullable<GridRow[]>): number {
    return rows ? rows.length : 0;
  }

  static sliceRows(rows: GridRow[], size: PortionSize): GridRow[] {
    return rows.slice(0, size);
  }

  static getRowHeight(columns: GridAgColumn[]): number {
    const heights = columns.map(column => (column.heightMultiplier || GridHeightMultiplier.Xs) * ROW_HEIGHT_MIN);
    return max(heights) as number;
  }
}
