import { useEffect, useState, useCallback, useMemo } from 'react';
import { ExpandHookOptions } from './interfaces/ExpandHookOptions';
import { isString } from 'lodash';
import { ExpandDataListValue } from '../../components/expander/types/ExpandDataListValue';

export const useExpand = (
  value: ExpandDataListValue,
  expandingLength: number,
  initialIsExpanded = false,
): ExpandHookOptions => {
  const isStringData = useMemo(() => isString(value), [value]);

  const [isExpanded, setIsExpanded] = useState(initialIsExpanded);

  const reducedValue = useMemo(() => {
    const reduced = value && value.slice(0, expandingLength);
    const hasDots = reduced.length < value.length && isStringData;

    return hasDots ? `${reduced}...` : reduced;
  }, [value, expandingLength, isStringData]);
  const newValue = useMemo(() => (isExpanded ? value : reducedValue), [value, isExpanded, reducedValue]);

  const [data, setData] = useState(newValue);

  const onExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
    setData(newValue);
  }, [isExpanded, newValue, setData]);

  useEffect(() => setData(newValue), [newValue]);

  return useMemo<ExpandHookOptions>(
    () => ({
      expandedData: data,
      isExpanded,
      isStringData,
      onExpand,
    }),
    [data, isExpanded, isStringData, onExpand],
  );
};
