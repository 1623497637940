import React from 'react';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import './SpecialReportsDateCell.scss';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import { ViewGridCellValueService } from '../../services/ViewGridCellValueService';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';
import { useClassName } from '../../../../../../hooks/useClassName';
import { SpecialReportsDateBlock, SpecialReportsDateCellElement } from './SpecialReportsDateBem';
import FormattedDate from '../../../../../../components/FormattedDate';
import { DateFormat } from '../../../../../../helpers/date/enums/DateFormat';
type Props = GridCellFormatterCommonProps;

const SpecialReportsDateCell: React.FC<Props> = props => {
  const cn = useClassName(SpecialReportsDateBlock.Root);
  const date = ViewGridCellValueService.getRaw(props.data, ViewColumnSystemName.SpecialReportsDate);

  return (
    <CustomCellFormatterWrapper className={cn(SpecialReportsDateCellElement)} value={date}>
      <FormattedDate date={date} format={DateFormat.MonthDayYearShort} />
    </CustomCellFormatterWrapper>
  );
};

export default SpecialReportsDateCell;
