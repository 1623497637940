import { isNumber } from 'lodash';
import { CompanyRatingValue } from '../interfaces/CompanyRatingValue';
import { CompanyRatingValueDto } from '../types/CompanyRatingValueDto';
import { CompanyRatingText } from '../enums/CompanyRatingText';
import { ColorScheme } from '../../../../shared/enums/ColorScheme';

export class CompanyRatingFactory {
  static getRating(ratingValue: CompanyRatingValueDto, visibleValue?: string): CompanyRatingValue {
    return {
      value: this.getValue(ratingValue),
      visibleValue: visibleValue || ratingValue,
      colorScheme: this.getColorScheme(ratingValue),
    };
  }

  private static getValue(ratingValue: CompanyRatingValueDto): number {
    if (this.isText(ratingValue)) {
      switch (ratingValue) {
        case CompanyRatingText.VeryStrong:
        case CompanyRatingText.VStrong:
          return 4;
        case CompanyRatingText.Strong:
          return 3;
        case CompanyRatingText.Neutral:
          return 2;
        case CompanyRatingText.Weak:
          return 1;
        case CompanyRatingText.VeryWeak:
        case CompanyRatingText.VWeak:
          return 0;
        case CompanyRatingText.NoRating:
          return -1;
      }
    } else if (ratingValue === '') {
      return -5;
    } else if (!ratingValue) {
      return -5;
    } else {
      if (isNumber(ratingValue)) {
        return ratingValue;
      } else {
        return parseInt(ratingValue);
      }
    }
  }

  private static getColorScheme(ratingText: CompanyRatingValueDto): ColorScheme {
    const numberRating = this.getValue(ratingText);

    if (ratingText === CompanyRatingText.Neutral || ratingText === CompanyRatingText.NoRating) {
      return ColorScheme.Primary;
    } else if (ratingText === CompanyRatingText.VeryWeak || ratingText === CompanyRatingText.Weak) {
      return ColorScheme.Danger;
    } else if (ratingText === CompanyRatingText.Strong || ratingText === CompanyRatingText.VeryStrong) {
      return ColorScheme.Success;
    } else if (numberRating <= 10) {
      return ColorScheme.Danger;
    } else if (numberRating >= 14) {
      return ColorScheme.Success;
    } else {
      return ColorScheme.Primary;
    }
  }

  private static isText(ratingText: CompanyRatingValueDto): ratingText is CompanyRatingText {
    return Object.values(CompanyRatingText).includes(ratingText as CompanyRatingText);
  }
}
