import React from 'react';
import { NewsItemTag } from '../interfaces/NewsItemTag';
import { NewsTagModifier } from '../enums/NewsTagModifier';
import { IconName } from '../../shared/components/icon/IconName';
import { ControlSize } from '../../shared/enums/ControlSize';
import Icon from '../../shared/components/icon/Icon';
import { ColorScheme } from '../../shared/enums/ColorScheme';

export function getIconTag(item: { videoKey?: number }): NewsItemTag | undefined {
  // In accordance with https://wiki.itransition.com/pages/viewpage.action?pageId=606978107
  if (item.videoKey) {
    return {
      title: IconName.PlayCircle,
      modifier: NewsTagModifier.Orange,
      custom: (
        <Icon
          className={`${IconName.PlayCircle}`}
          name={IconName.PlayCircle}
          size={ControlSize.ExtraSmall}
          colorScheme={ColorScheme.Attention}
        />
      ),
    };
  }
}
