import PrimarySectionRow from '../../../../../shared/components/primary-section/PrimarySectionRow';
import React from 'react';
import StatsElement from '../../../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../../../shared/entities/stats-element/enums/StatsElementType';
import { ventureCapitalStatsElementOptions } from './constants/ventureCapitalStatsElementOptions';

import { RoutePath } from '../../../../../shared/enums/RoutePath';
import { Link } from 'react-router-dom';

const StatsVentureCapital: React.FC = () => {
  return (
    <PrimarySectionRow header={<Link to={RoutePath.StatsBacked}>{'Venture Capital and Private Equity Activity'}</Link>}>
      <StatsElement type={StatsElementType.DashboardVentureCapital} options={ventureCapitalStatsElementOptions} />
    </PrimarySectionRow>
  );
};

export default StatsVentureCapital;
