import { StatsElementFilterInternalName } from '../enums/StatsElementGridFilterName';
import { SortService } from '../../../services/SortService';

export class StatsInternalFilterFactory {
  // TODO: temporary solution
  // should get a sorted array of filters from the backend side rather than sort on the front-end side
  static getWeight(name: StatsElementFilterInternalName): number {
    switch (name) {
      case StatsElementFilterInternalName.Total:
        return 0;
      case StatsElementFilterInternalName.TotalReturn:
        return 10;
      case StatsElementFilterInternalName.Totals:
        return 20;
      case StatsElementFilterInternalName.TotalsPct:
        return 30;
      case StatsElementFilterInternalName.Percentages:
        return 40;

      case StatsElementFilterInternalName.FromIpo:
        return 50;
      case StatsElementFilterInternalName.FirstDay:
        return 60;
      case StatsElementFilterInternalName.Aftermarket:
        return 70;
      case StatsElementFilterInternalName.Annual:
        return 80;
      case StatsElementFilterInternalName.Decade:
        return 90;
      case StatsElementFilterInternalName.Past1Mo:
        return 99;
      case StatsElementFilterInternalName.Monthly:
        return 100;
      case StatsElementFilterInternalName.Quarter:
        return 110;
      case StatsElementFilterInternalName.AboveIssue:
        return 120;
      case StatsElementFilterInternalName.DollarsRaised:
        return 130;
      case StatsElementFilterInternalName.DollarsRaisedPct:
        return 140;
      case StatsElementFilterInternalName.ReturnsPct:
        return 150;

      case StatsElementFilterInternalName.NumberAndPercentOfTotal:
        return 160;
      case StatsElementFilterInternalName.ProceedsAndPercentOfTotal:
        return 170;

      case StatsElementFilterInternalName.NumberAndProceeds:
        return 180;
      case StatsElementFilterInternalName.PercentOfTotal:
        return 190;
      default:
        return -1;
    }
  }

  static sortByWeight(names: StatsElementFilterInternalName[]): StatsElementFilterInternalName[] {
    return names.sort((name1, name2) => SortService.sortNumber(this.getWeight(name1), this.getWeight(name2)));
  }
}
