import React from 'react';
import arrayMove from 'array-move';
import './DragDropGrid.scss';
import DragDropList from './DragDropList';
import { SortEnd } from 'react-sortable-hoc';
import { CustomAny } from '../../../types/generics';

interface Props {
  itemsComponents: React.ReactNode[];
  items: CustomAny[];
  onSortEnd: (items: CustomAny[]) => void;
}

const DragDropGrid: React.FC<Props> = props => {
  const getSortedItems = (sort: SortEnd): CustomAny[] => {
    return arrayMove(props.items, sort.oldIndex, sort.newIndex);
  };

  const onSortEnd = (sort: SortEnd): void => {
    props.onSortEnd(getSortedItems(sort));
  };

  return (
    <section className="DragDropGrid">
      <DragDropList {...props} axis={'xy'} onSortEnd={onSortEnd} />
    </section>
  );
};

export default DragDropGrid;
