import Highcharts from 'highcharts';
import { AnimationDuration } from '../../../../enums/AnimationDuration';
import { ChartExportButton } from '../../enums/ChartExportButton';

const LINE_SERIES_Z_INDEX = 1;
const COLUMN_SERIES_Z_INDEX = 0;

export const baseChartOptions: Highcharts.Options = {
  chart: {
    animation: true,
    styledMode: true,
    height: 400,
  },
  exporting: {
    enabled: true,
    buttons: {
      contextButton: {
        y: 0,
        menuItems: [
          ChartExportButton.DownloadPNG,
          ChartExportButton.DownloadJPEG,
          ChartExportButton.DownloadPDF,
          ChartExportButton.DownloadSVG,
        ],
      },
    },
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  legend: {
    enabled: true,
  },
  plotOptions: {
    line: {
      zIndex: LINE_SERIES_Z_INDEX,
    },
    column: {
      zIndex: COLUMN_SERIES_Z_INDEX,
    },
    series: {
      animation: {
        duration: AnimationDuration.Chart,
      },
      dataLabels: {
        enabled: true,
      },
    },
  },
  yAxis: {
    stackLabels: {
      enabled: true,
    },
  },
  tooltip: {
    shared: true,
  },
  credits: {
    enabled: true,
    position: {
      align: 'center',
      x: 5,
    },
    text: ``,
    href: ``,
  },
};
