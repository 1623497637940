import React, { useCallback } from 'react';
import FormGroup from '../../../../../../../../shared/components/forms/FormGroup';
import FormInput from '../../../../../../../../shared/components/forms/FormInput';
import { InputType } from '../../../../../../../../shared/components/forms/Input';
import { ModalEntityActionType } from '../../../../../../../../shared/components/modal/enums/ModalEntityActionType';
import FormEntityActionModal from '../../../../../../../../shared/components/modal/FormEntityActionModal';
import { ScreenAddFormFields } from './ScreenAddFormFields';
import { ScreenAddFormData } from './ScreenAddFormData';
import { ScreensAddMessage } from '../enums/ScreensAddMessage';
import { SubmitFormFn } from '../../../../../../../../shared/components/forms/types/SubmitFormFn';
import { ControlSize } from '../../../../../../../../shared/enums/ControlSize';
import { ModalShowState } from '../../../../../../../../shared/components/modal/types/ModalShowState';
import { validationSchema } from './validationSchema';

interface Props {
  showState: ModalShowState;
  onSubmit: SubmitFormFn<ScreenAddFormData>;
}

const ScreenAddModalForm: React.FC<Props> = props => {
  const getSuccessMessage = useCallback(
    (formData: ScreenAddFormData) => ({
      text: ScreensAddMessage.SuccessAdd,
      values: [formData[ScreenAddFormFields.Name]],
    }),
    [],
  );

  return (
    <FormEntityActionModal
      showState={props.showState}
      validationSchema={validationSchema}
      entityName="Screen"
      actionType={ModalEntityActionType.Add}
      getSuccessMessage={getSuccessMessage}
      body={
        <FormGroup>
          <FormInput
            name={ScreenAddFormFields.Name}
            type={InputType.Text}
            placeholder="Enter a Screen Name"
            size={ControlSize.Small}
          />
        </FormGroup>
      }
      onSubmit={props.onSubmit}
    />
  );
};

export default ScreenAddModalForm;
