import React from 'react';
import './FavouriteHeaderFormatter.scss';
import FavouriteIcon from '../../../../../icon/FavouriteIcon';
import { GridHeaderFormatterProps } from '../../../interfaces/GridHeaderFormatterProps';

type Props = GridHeaderFormatterProps;

const FavouriteHeaderFormatter: React.FC<Props> = props => {
  return (
    <div className="FavouriteHeaderFormatter">
      <FavouriteIcon hasHoverCursor={props.isSortable} />
    </div>
  );
};

export default FavouriteHeaderFormatter;
