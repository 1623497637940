import { FormControlType } from '../../../../../../shared/components/forms/components/form-builder/enums/FormControlType';
import { ScreensLocationFilterName } from '../../enums/ScreensLocationFilterName';
import { ControlSize } from '../../../../../../shared/enums/ControlSize';
import { BlockOrientation } from '../../../../../../shared/enums/BlockOrientation';
import { ControlDivider } from '../../../../../../shared/components/forms/services/control-divider/enums/ControlDivider';
import { screensLocationScreenByOptions } from './screensLocationScreenByOptions';
import { screensDealStatusPricedUpcoming } from '../../../../../constants/controls/screensDealStatusControls';
import { FormSelectProps } from '../../../../../../shared/components/forms/components/select/FormSelect';
import { LocationOptionValueService } from '../../../../../../shared/entities/filters/services/LocationOptionValueService';

export const screenByControl = {
  type: FormControlType.TextSelect,
  name: ScreensLocationFilterName.ScreenBy,
  props: {
    name: ScreensLocationFilterName.ScreenBy,
    label: 'Screen By',
    size: ControlSize.Small,
    divider: ControlDivider.Slash,
    options: screensLocationScreenByOptions,
    orientation: BlockOrientation.Line,
  },
};

export const screensLocationSwitchControl = {
  type: FormControlType.SelectSwitchOptions,
  name: ScreensLocationFilterName.ListingLocation,
  props: {
    textSelectsProps: [screensDealStatusPricedUpcoming.props, screenByControl.props],
    selectProps: {
      name: ScreensLocationFilterName.ListingLocation,
      size: ControlSize.Small,
      width: ControlSize.Medium,
      orientation: BlockOrientation.Line,
      isMultiSelect: false,
      placeholder: 'Chose a Location',
      getSelectOptionViewOptionsFn: option => ({
        isHighlighted: LocationOptionValueService.isAllLocationValue(option.value),
      }),
    } as FormSelectProps,
    orientation: BlockOrientation.Line,
  },
};
