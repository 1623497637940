import React from 'react';
import { ViewFilterName } from '../../../../../enum/types/ViewFilterName';
import FilterControl from '../../FilterControl';

const DealStatusInControl: React.FC = () => {
  return (
    <>
      <FilterControl name={ViewFilterName.DealStatusIn} />
    </>
  );
};

export default DealStatusInControl;
