import { minStepIndex } from '../constants/defaults';
import { WizardStep } from '../interfaces/WizardStep';
import { ReactNode } from 'react';

export class WizardService {
  static getNext(currentStep: number): number {
    return currentStep + 1;
  }

  static isNextAvailable(currentStep: number, steps: WizardStep[]): boolean {
    return this.getNext(currentStep) < this.getStepsCount(steps);
  }

  static getPrev(currentStep: number): number {
    return currentStep - 1;
  }

  static isPrevAvailable(currentStep: number): boolean {
    return this.getPrev(currentStep) >= minStepIndex;
  }

  static getStepsCount(steps: WizardStep[]): number {
    return steps.length;
  }

  static isFinishAvailable(currentStep: number, steps: WizardStep[]): boolean {
    return !this.isNextAvailable(currentStep, steps);
  }

  static getComponent(steps: WizardStep[], activeStep: number): ReactNode {
    return steps.find((step, index) => this.isStepComponentVisible(activeStep, index))?.component;
  }

  private static isStepComponentVisible(activeStep: number, currentStep: number): boolean {
    return activeStep === currentStep;
  }
}
