import React from 'react';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import { useClassName } from '../../../../../../hooks/useClassName';
import { InvestorsCellBlock } from './InvestorsCellBem';
import TooltipExpander from '../../../../../../components/expander/TooltipExpander';
import useBreakpointAvailable from '../../../../../../effects/useBreakpointAvailable';
import { profileMobileBreakpoints } from '../../../../../../../company/components/profile/shared/constants/profileMobileBreakpoints';

type Props = GridCellFormatterCommonProps;

const expandingLength = 2;

const InvestorsCell: React.FC<Props> = props => {
  const cn = useClassName(InvestorsCellBlock.Root);
  const isMobile = useBreakpointAvailable(profileMobileBreakpoints);

  return (
    <CustomCellFormatterWrapper className={cn()} value={props.value?.[0]}>
      {!isMobile && <TooltipExpander expandData={props.value} expandingLength={expandingLength} isUnderFixed />}
      {isMobile && props.value?.[0]}
    </CustomCellFormatterWrapper>
  );
};

export default InvestorsCell;
