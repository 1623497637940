import { Action } from 'redux';
import { Location } from 'history';
import { AccountData } from '../../account-settings/interfaces/AccountData';

export const SET_IS_AUTHORIZED = 'SET_IS_AUTHORIZED';
export const SET_RETURN_LOCATION = 'SET_RETURN_LOCATION';
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';

export interface State {
  isAuthorized: boolean;
  returnLocation?: Location;
  accountData?: AccountData;
}

export interface SetIsAuthorizedAction extends Action<typeof SET_IS_AUTHORIZED> {
  isAuthorized: boolean;
}

export interface SetReturnLocation extends Action<typeof SET_RETURN_LOCATION> {
  location: Location;
}

export interface SetAccountData extends Action<typeof SET_ACCOUNT_DATA> {
  accountData: AccountData;
}

export type ActionTypes = SetIsAuthorizedAction | SetReturnLocation | SetAccountData;
