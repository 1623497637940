import React, { useCallback } from 'react';
import './CompanyOverviewExtraHeader.scss';
import { FileActionProp } from '../../../../../../shared/components/file-actions/interfaces/FileActionProp';
import { FileActionType } from '../../../../../../shared/components/file-actions/enums/FileActionType';
import FileActions from '../../../../../../shared/components/file-actions/FileActions';
import { setIsPrint } from '../../../../../../store/profile-print/actions';
import { useDispatch } from 'react-redux';

const CompanyOverview: React.FC = () => {
  const dispatch = useDispatch();

  const actions: FileActionProp[] = [
    {
      type: FileActionType.Print,
    },
  ];

  const ActionClickFn = useCallback(
    (actionType: FileActionType) => {
      // When called, it will print profile from CompanyTabsContent.tsx
      if (actionType === FileActionType.Print) {
        dispatch(setIsPrint(true));
      }
    },
    [dispatch],
  );

  return (
    <div className={'CompanyOverviewExtraHeader'}>
      <p
        className={'CompanyOverviewExtraHeaderHeading'}
        onClick={() => {
          dispatch(setIsPrint(true));
        }}
      >
        Print Profile
      </p>
      <FileActions onActionClick={ActionClickFn} fileActions={actions} />
    </div>
  );
};

export default CompanyOverview;
