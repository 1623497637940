export enum ViewColumnsAddSelectedItemBlock {
  Root = 'ViewColumnsAddSelectedItem',
}

export enum ViewColumnsAddSelectedItemElement {
  Label = 'label',
  Content = 'content',
}

export enum ViewColumnsAddSelectedItemModifiers {
  Info = 'info',
}
