import { StatsElementOptions } from '../../../../shared/entities/stats-element/interfaces/StatsElementOptions';
import { ChartType } from '../../../../shared/components/chart/enums/ChartType';
import { NumberHelper } from '../../../../shared/helpers/NumberHelper';
import { NumberFormat } from '../../../../shared/enums/NumberFormat';
import { ChartTooltipFormatterService } from '../../../../shared/components/chart/services/ChartTooltipFormatterService';
import { CustomAny } from '../../../../shared/types/generics';
import { ChartValuesType } from '../../../../shared/components/chart/enums/ChartValuesType';
import { getStatsElementSimpleGridColumnYearToViewMapper } from '../../../../shared/entities/stats-element/components/simple-grid/constants/statsElementSimpleGridColumnMappers';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { ViewFilterName } from '../../../../shared/entities/filters/enum/types/ViewFilterName';
import { BlockOrientation } from '../../../../shared/enums/BlockOrientation';

const MAX_PERCENT = 1;
const MIN_PERCENT = 0;

const chartOptions: Highcharts.Options = {
  yAxis: [
    {
      title: {
        text: 'Proceeds',
      },
      labels: {
        formatter() {
          return NumberHelper.getFormatValue(this.value, NumberFormat.CurrencyIntegerWithLetter);
        },
      },
    },
    {
      title: {
        text: 'Number of IPOs',
      },
      opposite: true,
    },
    {
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      max: MAX_PERCENT,
      min: MIN_PERCENT,
    },
  ],
  series: [
    {
      type: 'column',
      dataLabels: {
        formatter() {
          return NumberHelper.getFormatValue(this.point.y as number, NumberFormat.CurrencyFloatWithLetter);
        },
      },
    },
    {
      type: 'line',
      yAxis: 1,
    },
    // {
    //   type: 'line',
    //   yAxis: 2,
    //   dataLabels: {
    //     formatter() {
    //       return NumberHelper.getFormatValue(this.point.y as number, NumberFormat.PercentageInteger);
    //     },
    //   },
    // },
  ],
  tooltip: {
    formatter() {
      return ChartTooltipFormatterService.getTooltip({
        points: this.points as CustomAny[],
        title: `${this.x}`,
        pointsValueType: [ChartValuesType.Currency, ChartValuesType.Integer, ChartValuesType.Percentage],
      });
    },
  },
};

const TOTAL_IPOS_GRID_INDEX = 0;
const IPOS_VC_BACKED_GRID_INDEX = 1;
const IPOS_PE_BACKED_GRID_INDEX = 2;
const IPOS_PE_LBO_BACKED_GRID_INDEX = 3;
const IPOS_PE_GROWS_OTHER_BACKED_GRID_INDEX = 4;

export const backedIposStatsElementOptions: StatsElementOptions = {
  pageOptions: {
    chartsOrientation: BlockOrientation.Line,
  },
  chartsOptions: [
    {
      title: 'Venture Capital Backed IPOs',
      type: ChartType.Base,
      optionsList: [chartOptions],
    },
    {
      title: 'Private Equity Backed IPOs',
      type: ChartType.Base,
      optionsList: [chartOptions],
    },
  ],
  gridOptions: {
    [TOTAL_IPOS_GRID_INDEX]: {
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(() => ({
        viewType: ViewType.Priced,
        startDateFilterName: ViewFilterName.OfferStartDate,
        endDateFilterName: ViewFilterName.OfferEndDate,
      })),
    },
    [IPOS_VC_BACKED_GRID_INDEX]: {
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(() => ({
        viewType: ViewType.Priced,
        startDateFilterName: ViewFilterName.OfferStartDate,
        endDateFilterName: ViewFilterName.OfferEndDate,
        optionsList: [
          {
            getFilterName: ViewFilterName.VentureCapital,
            getFilterValue: () => true,
          },
        ],
      })),
    },
    [IPOS_PE_BACKED_GRID_INDEX]: {
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(() => ({
        viewType: ViewType.Priced,
        startDateFilterName: ViewFilterName.OfferStartDate,
        endDateFilterName: ViewFilterName.OfferEndDate,
        optionsList: [
          {
            getFilterName: ViewFilterName.PE,
            getFilterValue: () => true,
          },
          {
            getFilterName: ViewFilterName.PeGrowthOther,
            getFilterValue: () => true,
          },
        ],
      })),
    },
    [IPOS_PE_LBO_BACKED_GRID_INDEX]: {
      hasShiftHorizontal: true,
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(() => ({
        viewType: ViewType.Priced,
        startDateFilterName: ViewFilterName.OfferStartDate,
        endDateFilterName: ViewFilterName.OfferEndDate,
        optionsList: [
          {
            getFilterName: ViewFilterName.PE,
            getFilterValue: () => true,
          },
        ],
      })),
    },
    [IPOS_PE_GROWS_OTHER_BACKED_GRID_INDEX]: {
      hasShiftHorizontal: true,
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(() => ({
        viewType: ViewType.Priced,
        startDateFilterName: ViewFilterName.OfferStartDate,
        endDateFilterName: ViewFilterName.OfferEndDate,
        optionsList: [
          {
            getFilterName: ViewFilterName.PeGrowthOther,
            getFilterValue: () => true,
          },
        ],
      })),
    },
  },
};
