import React, { useContext } from 'react';
import { StatsElementContext } from '../context/StatsElementContext';
import SecondarySectionsGroup from '../../../../components/secondary-section/SecondarySectionsGroup';
import { StatsElementViewGridDto } from './interfaces/StatsElementViewGridDto';
import StatsElementViewGrid from './StatsElementViewGrid';
import { StatsElementGridContext } from '../simple-grid/interfaces/StatsElementGridContext';

interface Props {
  gridContext: StatsElementGridContext;
}

const StatsElementViewGridMap: React.FC<Props> = props => {
  const { viewGrids, options } = useContext(StatsElementContext);

  return (
    <SecondarySectionsGroup>
      {viewGrids?.map((viewGrid: StatsElementViewGridDto, index: number) => (
        <StatsElementViewGrid
          key={index}
          index={index}
          viewGrid={viewGrid}
          gridContext={props.gridContext}
          gridOptions={options?.viewGridOptions?.[index]}
        />
      ))}
    </SecondarySectionsGroup>
  );
};

export default StatsElementViewGridMap;
