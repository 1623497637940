import React, { useCallback } from 'react';
import { GridColumn } from '../../shared/interfaces/GridColumn';
import { SimpleGridType } from '../enums/SimpleGridType';
import { SimpleGridHeaderCellFactory } from '../factories/SimpleGridHeaderCellFactory';
import TableRow from '../../../table/TableRow';
import TableHeader from '../../../table/TableHeader';

interface Props {
  columns: GridColumn[];
  functionalType: SimpleGridType;
  isNoWrap?: boolean;
  isAutoCellWidth?: boolean;
}

const SimpleGridHeader: React.FC<Props> = props => {
  const isGridFunctionalType = props.functionalType === SimpleGridType.Grid;

  const getTableHeaderCell = useCallback(
    (column: GridColumn) => {
      if (isGridFunctionalType) {
        return SimpleGridHeaderCellFactory.getHeaderCell(column, props.isNoWrap);
      }

      return column.headerName;
    },
    [isGridFunctionalType, props.isNoWrap],
  );

  return (
    <TableHeader>
      <TableRow
        isHeader
        isAutoCellWidth={props.isAutoCellWidth}
        cells={props.columns.map((column: GridColumn) => ({
          value: getTableHeaderCell(column),
          column,
        }))}
      />
    </TableHeader>
  );
};

export default SimpleGridHeader;
