import React, { useContext, useMemo } from 'react';
import './CompanyResearch.scss';
import { isEmpty } from 'lodash';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import ReportsGrid from '../../../../../../shared/entities/view/components/view-grid/components/grids/ReportsGrid';
import { ControlSize } from '../../../../../../shared/enums/ControlSize';
import { ReportDto } from '../../../../../../special-reports/interfaces/ReportDto';
import FileActions from '../../../../../../shared/components/file-actions/FileActions';
import { FileActionProp } from '../../../../../../shared/components/file-actions/interfaces/FileActionProp';
import { FileActionType } from '../../../../../../shared/components/file-actions/enums/FileActionType';
import { useClassName } from '../../../../../../shared/hooks/useClassName';
import { useCompanyProfileSidebarTableProps } from '../company-profile-table/hooks/useCompanyProfileSidebarTableProps';
import { DateHelper } from '../../../../../../shared/helpers/date/DateHelper';
import { DateFormat } from '../../../../../../shared/helpers/date/enums/DateFormat';

const CompanyResearch: React.FC = () => {
  const cn = useClassName('CompanyResearch');

  const { getTabData } = useContext(CompanyContext);

  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const data = useMemo(() => profile.research, [profile]);

  const fileLink = profile?.description?.dcfLink;
  const linkUpdateDate = profile?.description?.dcfUpdatedDate;

  const hasGrid = useMemo(() => data && !isEmpty(data), [data]);
  const hasLink = useMemo(() => !!fileLink, [fileLink]);

  const hasData = useMemo(() => hasGrid || hasLink, [hasGrid, hasLink]);

  let showNrNote = false;

  // Deciding whether to show note based on research present
  if (profile?.description?.notDoingResearch === true) {
    showNrNote = true;
  }

  // Changing when no data appears to link to fundamental insights
  const fileActions = useMemo<FileActionProp[]>(
    () =>
      fileLink
        ? [
            {
              type: FileActionType.Excel,
              title: 'DCF Model',
              titlePostfix: linkUpdateDate && (
                <i>Updated {DateHelper.formatISODateStr(linkUpdateDate, DateFormat.MonthDayYearShort)}</i>
              ),
              externalDownloadOptions: {
                url: fileLink,
              },
              isReverseOrder: true,
            },
          ]
        : [],
    [fileLink, linkUpdateDate],
  );

  const tableProps = useCompanyProfileSidebarTableProps();

  return (
    <>
      {showNrNote === true ? (
        <CompanyProfileSideItem className={cn()} title="Research" notes={'Profile Only'}>
          {hasGrid && (
            <ReportsGrid
              hasRating
              expandedData={data as ReportDto[]}
              hasAroundBorder={false}
              size={ControlSize.Small}
              {...tableProps}
            />
          )}

          {fileLink && <FileActions className={cn('fileLink')} fileActions={fileActions} size={ControlSize.Small} />}
        </CompanyProfileSideItem>
      ) : (
        <>
          {hasData && (
            <CompanyProfileSideItem className={cn()} title="Research">
              {hasGrid && (
                <ReportsGrid
                  hasRating
                  expandedData={data as ReportDto[]}
                  hasAroundBorder={false}
                  size={ControlSize.Small}
                  {...tableProps}
                />
              )}

              {fileLink && (
                <FileActions className={cn('fileLink')} fileActions={fileActions} size={ControlSize.Small} />
              )}
            </CompanyProfileSideItem>
          )}
        </>
      )}
    </>
  );
};

export default CompanyResearch;
