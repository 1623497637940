import React, { useMemo } from 'react';
import './DragDropItem.scss';
import DragDropItemDraggable from './DragDropItemDraggable';
import DragDropItemStatic from './DragDropItemStatic';
import { useClassName } from '../../../hooks/useClassName';
import { DragDropItemBlock, DragDropItemModifier } from './dragDropItemBem';

interface DragDropItem {
  itemComponent: React.ReactNode;
  index: number;
  isStatic?: boolean;
  isInModal?: boolean;
}

const DragDropItem: React.FC<DragDropItem> = props => {
  const cn = useClassName(DragDropItemBlock.Root);

  const itemComponent = useMemo(
    () => (
      <section
        className={cn({
          [DragDropItemModifier.Modal]: props.isInModal,
        })}
      >
        {props.itemComponent}
      </section>
    ),
    [props.itemComponent, cn, props.isInModal],
  );

  return props.isStatic ? (
    <DragDropItemStatic itemComponent={itemComponent} />
  ) : (
    <DragDropItemDraggable itemComponent={itemComponent} index={props.index} />
  );
};

export default DragDropItem;
