import React, { useMemo } from 'react';
import StatsPage from '../StatsPage';
import PrimarySectionRow from '../../../shared/components/primary-section/PrimarySectionRow';
import StatsElement from '../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../shared/entities/stats-element/enums/StatsElementType';
import { industryBreakdownStatsElementOptions } from './constants/industryBreakdownStatsElementOptions';
import { ViewFilterName } from '../../../shared/entities/filters/enum/types/ViewFilterName';
import { StatsFilterName } from '../enum/StatsFilterName';
import { StatsGlobalFilter } from '../types/StatsGlobalFilter';

const StatsIndustry: React.FC = () => {
  const filtersNames = useMemo(
    () => [ViewFilterName.Headquarter, StatsFilterName.IncludeSPACs],
    [],
  ) as StatsGlobalFilter[];

  return (
    <StatsPage header="IPO Industry Breakdown" filtersNames={filtersNames}>
      <PrimarySectionRow>
        <StatsElement type={StatsElementType.IndustryBreakdown} options={industryBreakdownStatsElementOptions} />
      </PrimarySectionRow>
    </StatsPage>
  );
};

export default StatsIndustry;
