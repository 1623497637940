export enum AccordionHeaderBlock {
  Root = 'AccordionHeader',
}

export enum AccordionHeaderElement {
  Title = 'title',
  IconPin = 'icon-pin',
}

export enum AccordionHeaderModifier {
  Available = 'available',
}
