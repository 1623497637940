import React, { useState } from 'react';
import { validationSchema } from './validationSchema';
import Form from '../../../shared/components/forms/Form';
import FormGroup from '../../../shared/components/forms/FormGroup';
import FormInput from '../../../shared/components/forms/FormInput';
import { ControlSize } from '../../../shared/enums/ControlSize';
import { AlignTypesHorizontal } from '../../../shared/enums/AlignTypesHorizontal';
import { ResetPasswordFormFields } from './ResetPasswordFormFields';
import { InputType } from '../../../shared/components/forms/Input';
import ButtonSubmit from '../../../shared/components/forms/ButtonSubmit';
import { ResetPasswordFormData } from './ResetPasswordFormData';
import AuthApiService from '../../../shared/api/AuthApiService';
import { useQueryParams } from '../../../shared/effects/useQueryParams';
import { RecoveryPasswordQueryParams } from '../enum/RecoveryPasswordQueryParams';
import { SubmitFormFn } from '../../../shared/components/forms/types/SubmitFormFn';
import { AuthService } from '../../../shared/services/AuthService';

const ResetPasswordForm: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const token = useQueryParams().get(RecoveryPasswordQueryParams.Token) as string;
  const email = useQueryParams().get(RecoveryPasswordQueryParams.Email);

  const onSubmit: SubmitFormFn<ResetPasswordFormData> = async data => {
    await AuthService.authorize(AuthApiService.reset.bind(AuthApiService, data, token));
    setIsSubmitted(true);
  };

  return (
    <Form
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      size={ControlSize.Medium}
      alignHorizontal={AlignTypesHorizontal.Center}
      defaultValues={{
        [ResetPasswordFormFields.Email]: email,
      }}
    >
      <FormGroup>
        <FormInput name={ResetPasswordFormFields.Email} label="Email" type={InputType.Email} autoComplete="email" />
      </FormGroup>

      <FormGroup>
        <FormInput
          name={ResetPasswordFormFields.Password}
          label="Password"
          type={InputType.Password}
          autoComplete="new-password"
        />
      </FormGroup>

      <FormGroup>
        <FormInput
          name={ResetPasswordFormFields.ConfirmPassword}
          label="Confirm password"
          type={InputType.Password}
          autoComplete="new-password"
        />
      </FormGroup>

      <ButtonSubmit submittedMessage="The password is successfully reset" message="Reset" isSubmitted={isSubmitted} />
    </Form>
  );
};

export default ResetPasswordForm;
