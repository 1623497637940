import React, { useCallback, useMemo } from 'react';
import './DashboardTable.scss';
import { ViewDataTable } from '../../../../shared/entities/view/components/view/interfaces/api/ViewDataTable';
import ViewSimpleGrid from '../../../../shared/entities/view/components/view-grid/ViewSimpleGrid';
import { ControlSize } from '../../../../shared/enums/ControlSize';
import { ColorScheme } from '../../../../shared/enums/ColorScheme';
import { companyNameWithTitleEllipsis } from '../../../../shared/entities/view/components/view-grid/constants/viewGridCustomColumnsSettings';
import { GridAgColumn } from '../../../../shared/components/grid/grid/interfaces/GridAgColumn';
import { ViewColumnSystemName } from '../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { ViewGridColumnWidth } from '../../../../shared/entities/view/components/view-grid/enums/ViewGridColumnWidth';
import useBreakpointAvailable from '../../../../shared/effects/useBreakpointAvailable';
import { mobileBreakpoints } from '../../../../shared/constants/breakpoints/mobileBreakpoints';

export interface Props {
  dataTable: ViewDataTable;
  customColumnsSettings?: Partial<GridAgColumn>[];
  fixedWidthColumns?: ViewColumnSystemName[];
  colorScheme?: ColorScheme;
  isCompanyNameTitleEllipsis?: boolean;
  widgetType?: string;
  width?: number;
  pinTickerColumn?: boolean;
}

const DEFAULT_FIXED_WIDTH_COLUMNS = [
  ViewColumnSystemName.Favorite,
  ViewColumnSystemName.RowNum,
  ViewColumnSystemName.LastClose,
  ViewColumnSystemName.DealSize,
  ViewColumnSystemName.IpoPrice,
  ViewColumnSystemName.Last,
  ViewColumnSystemName.ResearchRating,
  ViewColumnSystemName.Ticker,
  ViewColumnSystemName.CompanyName,
  ViewColumnSystemName.Research,
  ViewColumnSystemName.PriceRange,
  ViewColumnSystemName.TodaysPriceChange,
  ViewColumnSystemName.PriceChange,
  ViewColumnSystemName.Type,
  ViewColumnSystemName.StockPrice,
];

// All date columns will have one short date format, so we need to set width the same for all
const DATE_COLUMNS = [
  ViewColumnSystemName.ReportDate,
  ViewColumnSystemName.TradeDate,
  ViewColumnSystemName.ExpPricingDate,
  ViewColumnSystemName.FileDate,
];

const DashboardTable: React.FC<Props> = props => {
  const { isCompanyNameTitleEllipsis = true, fixedWidthColumns = [] } = props;
  const isMobile = useBreakpointAvailable(mobileBreakpoints);
  const fixedWidthColumnsUpdated = useMemo(() => [...DEFAULT_FIXED_WIDTH_COLUMNS, ...fixedWidthColumns], [
    fixedWidthColumns,
  ]);

  const customColumnsSettings = useMemo(() => {
    let settings: Partial<GridAgColumn>[] = [];

    if (props.customColumnsSettings) {
      settings = settings.concat(props.customColumnsSettings);
    }

    return settings;
  }, [props.customColumnsSettings]);

  const onColumnMapReady = useCallback(
    column => {
      let newColumn = { ...column };

      if (column.field === ViewColumnSystemName.CompanyName) {
        if (isCompanyNameTitleEllipsis) {
          newColumn = {
            ...newColumn,
            ...companyNameWithTitleEllipsis,
          };
        }

        if (props.widgetType && (props.widgetType === 'DashboardUpcomingIPOsWidget' || props.widgetType === 'Priced')) {
          if (props.width && props.width < 400) {
            newColumn.hide = true;
          }
        } else {
          if (props.width && props.width < 310) {
            newColumn.hide = true;
          }
        }
        if (props.width && props.width > 500) {
          newColumn.minWidth = ViewGridColumnWidth.Lgx;
          newColumn.width = ViewGridColumnWidth.Lgx;
        } else if (props.width && props.width < 400) {
          newColumn.width = ViewGridColumnWidth.Sx;
          if (props.widgetType && props.widgetType === 'NewFilings') {
            newColumn.width = isMobile ? ViewGridColumnWidth.Md : ViewGridColumnWidth.Lg;
            if (props.width < 350) {
              newColumn.width = ViewGridColumnWidth.Sm;
            }
            if (props.width < 330) {
              newColumn.width = ViewGridColumnWidth.Sx;
            }
          }
        } else {
          newColumn.width = ViewGridColumnWidth.Lg;
        }
      }

      if (column.field === ViewColumnSystemName.IpoPrice) {
        newColumn.minWidth = 60;
        newColumn.width = 60;
      }

      if (column.field === ViewColumnSystemName.Last) {
        newColumn.minWidth = 60;
        newColumn.width = 60;
      }

      if (column.field === ViewColumnSystemName.Type) {
        newColumn.minWidth = ViewGridColumnWidth.Sx;
        newColumn.width = ViewGridColumnWidth.Sx;
      }
      if (column.field === ViewColumnSystemName.TodaysPriceChange) {
        newColumn.minWidth = ViewGridColumnWidth.Md;
        newColumn.width = ViewGridColumnWidth.Md;
      }
      if (column.field === ViewColumnSystemName.StockPrice) {
        newColumn.minWidth = ViewGridColumnWidth.Xs;
        newColumn.width = ViewGridColumnWidth.Xs;
      }
      if (column.field === ViewColumnSystemName.PriceChange) {
        newColumn.minWidth = ViewGridColumnWidth.Xs;
        newColumn.width = ViewGridColumnWidth.Xs;
      }

      if (column.field === ViewColumnSystemName.DealSize) {
        newColumn.minWidth = ViewGridColumnWidth.Xs;
        newColumn.width = ViewGridColumnWidth.Sx;
      }

      if (column.field === ViewColumnSystemName.LastClose) {
        newColumn.minWidth = ViewGridColumnWidth.Xs;
        newColumn.width = ViewGridColumnWidth.Xs;
      }

      if (column.field === ViewColumnSystemName.Ticker) {
        newColumn.minWidth = ViewGridColumnWidth.Xs;
        newColumn.width = ViewGridColumnWidth.Xs;
      }

      if (column.field === ViewColumnSystemName.PriceRange) {
        newColumn.minWidth = 120;
        newColumn.width = 120;
      }

      if (props.widgetType === 'IPOsOnMove') {
        if (column.field === ViewColumnSystemName.Ticker) {
          newColumn.width = ViewGridColumnWidth.Xxs;
          newColumn.minWidth = ViewGridColumnWidth.Xxs;
        }
        if (column.field === ViewColumnSystemName.LastClose) {
          newColumn.width = ViewGridColumnWidth.Xxs;
          newColumn.minWidth = ViewGridColumnWidth.Xxs;
        }
        if (column.field === ViewColumnSystemName.PriceChange) {
          newColumn.width = ViewGridColumnWidth.Xxs;
          newColumn.minWidth = ViewGridColumnWidth.Xxs;
        }
      }

      if (props.widgetType === 'NewFilings') {
        if (column.field === ViewColumnSystemName.Research) {
          newColumn.width = 65;
          newColumn.minWidth = 65;
        }
      }

      if (!fixedWidthColumnsUpdated.includes(column.field)) {
        newColumn.isAutoWidth = true;
      }

      if (DATE_COLUMNS.includes(column.field)) {
        newColumn.minWidth = ViewGridColumnWidth.Xs;
        newColumn.width = ViewGridColumnWidth.Xs;
        newColumn.isAutoWidth = false;
      }

      if (column.field === ViewColumnSystemName.TradeDate) {
        newColumn.minWidth = ViewGridColumnWidth.Sx;
        newColumn.width = ViewGridColumnWidth.Sx;
      }

      return newColumn;
    },
    [isCompanyNameTitleEllipsis, fixedWidthColumnsUpdated, props.width],
  );
  return (
    <ViewSimpleGrid
      className={`DashboardTable${props.widgetType ? ` ${props.widgetType}` : ''}`}
      columns={props.dataTable.columns}
      customColumnsSettings={customColumnsSettings}
      rows={props.dataTable.rows}
      size={ControlSize.Small}
      isResponsive={false}
      hasAroundBorder={false}
      colorScheme={props.colorScheme}
      isFullWidth
      onColumnMapReady={onColumnMapReady}
      width={props.width}
      widgetType={props.widgetType}
      pinTickerColumn={props.pinTickerColumn}
    />
  );
};

export default DashboardTable;
