import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import './NewsArticle.scss';
import { usePromiseState } from '../../shared/hooks/promise-state/usePromiseState';
import { NewsApiService } from '../../shared/api/NewsApiService';
import { RouteIdParams } from '../../shared/interfaces/RouteIdParams';
import { NewsArticleDto } from '../interfaces/NewsArticleDto';
import Helmet from 'react-helmet';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import PageLoader from '../../shared/components/loader/PageLoader';
import { useClassName } from '../../shared/hooks/useClassName';
import NewsArticleContent from './NewsArticleContent';
import { NewsArticleBlock } from './NewsArticleBem';
import { ControlSize } from '../../shared/enums/ControlSize';

const NewsArticle: React.FC = () => {
  const cn = useClassName(NewsArticleBlock.Root);
  const { id } = useParams<RouteIdParams>();
  const [article, isLoading] = usePromiseState<NewsArticleDto>(
    useCallback(() => NewsApiService.getArticle(parseInt(id)), [id]),
  );

  return (
    <PageLoader loading={isLoading} otherwise={article} otherwiseContent="News article was not found">
      {article && (
        <article className={cn()}>
          <Helmet>
            <title>{article?.headline}</title>
          </Helmet>

          <PrimarySection header={article?.headline} maxContainerSize={ControlSize.Medium}>
            <NewsArticleContent article={article as NewsArticleDto} />
          </PrimarySection>
        </article>
      )}
    </PageLoader>
  );
};

export default NewsArticle;
