import React from 'react';
import './ViewGridSummary.scss';
import { useClassName } from '../../../../../../hooks/useClassName';
import { ViewGridSummaryBlock } from './viewGridSummaryBem';

const ViewGridSummary: React.FC = props => {
  const cn = useClassName(ViewGridSummaryBlock.Root);

  return <div className={cn()}>{props.children}</div>;
};

export default ViewGridSummary;
