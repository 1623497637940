import { FilterControlSettings } from '../../../types/FilterControlSettings';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { ControlSize } from '../../../../../enums/ControlSize';
import { InputType } from '../../../../../components/forms/Input';
import { PrependValueType } from '../../../../../components/forms/enums/PrependValueType';
import { AppendValueType } from '../../../../../components/forms/enums/AppendValueType';

export const returnsControls: FilterControlSettings = {
  [ViewFilterName._FirstDayReturn]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._FirstDayReturn,
    props: {
      label: 'First-Day Return',
      minName: ViewFilterName.FirstDayReturnMin,
      maxName: ViewFilterName.FirstDayReturnMax,
      minPrepend: [PrependValueType.Min],
      minAppend: [AppendValueType.Percentage],
      maxPrepend: [PrependValueType.Max],
      maxAppend: [AppendValueType.Percentage],
      size: ControlSize.Small,
      // width: ControlSize.Small,
    },
  },
  [ViewFilterName._AfterMarketReturn]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._AfterMarketReturn,
    props: {
      label: 'Aftermarket Return',
      minName: ViewFilterName.AfterMarketReturnMin,
      maxName: ViewFilterName.AfterMarketReturnMax,
      minPrepend: [PrependValueType.Min],
      minAppend: [AppendValueType.Percentage],
      maxPrepend: [PrependValueType.Max],
      maxAppend: [AppendValueType.Percentage],
      size: ControlSize.Small,
      // width: ControlSize.Small,
    },
  },
  [ViewFilterName._TotalReturn]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._TotalReturn,
    props: {
      label: 'Total Return',
      minName: ViewFilterName.TotalReturnMin,
      maxName: ViewFilterName.TotalReturnMax,
      minPrepend: [PrependValueType.Min],
      minAppend: [AppendValueType.Percentage],
      maxPrepend: [PrependValueType.Max],
      maxAppend: [AppendValueType.Percentage],
      size: ControlSize.Small,
      // width: ControlSize.Small,
    },
  },
  [ViewFilterName._ReturnsAnnualizedReturn]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._ReturnsAnnualizedReturn,
    props: {
      label: 'Annualized Return (past year = 1)',
      minName: ViewFilterName.ReturnsAnnualizedReturnMin,
      maxName: ViewFilterName.ReturnsAnnualizedReturnMax,
      minPrepend: [PrependValueType.Min],
      minAppend: [AppendValueType.Percentage],
      maxPrepend: [PrependValueType.Max],
      maxAppend: [AppendValueType.Percentage],
      size: ControlSize.Small,
      width: ControlSize.Small,
    },
  },
  [ViewFilterName._InitialPerformance]: {
    type: FormControlType.SelectWithValueRange,
    name: ViewFilterName._InitialPerformance,
    title: 'Initial Performance',
    props: {
      selectName: ViewFilterName.InitialPerformancePeriod,
      //selectLabel: 'First',
      selectLabel: '',
      selectPlaceholder: 'Select Period',
      rangeMinName: ViewFilterName.InitialPerformanceMin,
      rangeMaxName: ViewFilterName.InitialPerformanceMax,
      rangeMinPrepend: [PrependValueType.From],
      rangeMinAppend: [AppendValueType.Percentage],
      rangeMaxPrepend: [PrependValueType.To],
      rangeMaxAppend: [AppendValueType.Percentage],
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._RecentPerformance]: {
    type: FormControlType.SelectWithValueRange,
    name: ViewFilterName._RecentPerformance,
    title: 'Recent Performance',
    props: {
      selectName: ViewFilterName.RecentPerformancePeriod,
      selectLabel: '',
      //selectLabel: 'Past',
      selectPlaceholder: 'Select Period',
      rangeMinName: ViewFilterName.RecentPerformanceMin,
      rangeMaxName: ViewFilterName.RecentPerformanceMax,
      rangeMinPrepend: [PrependValueType.From],
      rangeMinAppend: [AppendValueType.Percentage],
      rangeMaxPrepend: [PrependValueType.To],
      rangeMaxAppend: [AppendValueType.Percentage],
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.ReturnsCurrentPrice]: {
    type: FormControlType.Input,
    name: ViewFilterName.ReturnsCurrentPrice,
    props: {
      name: ViewFilterName.ReturnsCurrentPrice,
      label: 'Current Price:',
      type: InputType.Number,
      size: ControlSize.Small,
      width: ControlSize.ExtraSmall,
      prepend: [PrependValueType.GreaterEqual],
    },
  },
  [ViewFilterName.ReturnsCompTicker]: {
    type: FormControlType.Input,
    name: ViewFilterName.ReturnsCompTicker,
    props: {
      name: ViewFilterName.ReturnsCompTicker,
      label: "Comp'd to (IPOs that had this comp):",
      type: InputType.Text,
      size: ControlSize.Small,
      width: ControlSize.ExtraLarge,
    },
  },
};
