import { SelectOption } from '../../../../../services/select/interfaces/SelectOption';
import { TreeNode, TreeNodeProps } from 'react-dropdown-tree-select';
import { SelectTreeService } from '../../../../../services/select/SelectTreeService';
import { SelectOptionValue } from '../../../../../services/select/types/SelectOptionValue';
import { ArrayService } from '../../../../../services/ArrayService';
import { GetSelectOptionViewOptionsFn } from '../types/GetSelectOptionViewOptionsFn';
import { SelectModifier } from '../SelectBem';
import { DomService } from '../../../../../services/dom/DomService';

export class TreeSelectService {
  static getNodes(
    options: SelectOption[] = [],
    values: SelectOptionValue[] = [],
    getSelectOptionViewOptionsFn?: GetSelectOptionViewOptionsFn,
  ): TreeNodeProps[] {
    return SelectTreeService.getTree<TreeNodeProps>(options, (flatOption: SelectOption, children?: TreeNodeProps[]) => {
      return {
        label: flatOption.title,
        value: flatOption.value ? flatOption.value.toString() : '',
        checked: this.isSelected(flatOption.value, values),
        ...this.getSelectOptionFromViewOptions(flatOption, getSelectOptionViewOptionsFn),
        children,
      };
    });
  }

  static isSelected(currentValue: SelectOptionValue, values: SelectOptionValue[]): boolean {
    return values.some(value => currentValue === value);
  }

  static getValue(selectedNodes: TreeNode[]): SelectOptionValue[] {
    return selectedNodes.map(node => node.value);
  }

  static isEqualControlValues(values: SelectOptionValue[], nodes: TreeNode[]): boolean {
    const nodesValues = TreeSelectService.getValue(nodes);

    return ArrayService.areSameValues(values, nodesValues);
  }

  static isEqualOptions(prev: SelectOption[], current: SelectOption[]): boolean {
    return ArrayService.areSameValues(prev, current);
  }

  private static getSelectOptionFromViewOptions(
    flatOption: SelectOption,
    getSelectOptionViewOptionsFn?: GetSelectOptionViewOptionsFn,
  ): Partial<TreeNodeProps> {
    const viewOptions = getSelectOptionViewOptionsFn?.(flatOption);
    const classNames: string[] = [];

    if (viewOptions?.isHighlighted) {
      classNames.push(SelectModifier.Highlighted);
    }

    return {
      className: DomService.getClassNamesStr(classNames),
    };
  }
}
