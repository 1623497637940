import React from 'react';
import PrimarySectionAdditionActionItem from '../../../../shared/components/primary-section/PrimarySectionAdditionActionItem';
import { TooltipPosition } from '../../../../shared/components/tooltip/enums/TooltipPosition';
import PrimarySectionAdditionActionList from '../../../../shared/components/primary-section/PrimarySectionAdditionActionList';
import Tooltip from '../../../../shared/components/tooltip/Tooltip';
import { ControlSize } from '../../../../shared/enums/ControlSize';
import AdditionalActionTooltip from './AdditionalActionTooltip';

const ScreensAdditionalAction: React.FC = () => {
  return (
    <PrimarySectionAdditionActionList>
      <PrimarySectionAdditionActionItem>
        <Tooltip
          title="My Screens"
          content={<AdditionalActionTooltip />}
          isTitleHighlighted
          position={TooltipPosition.Bottom}
          size={ControlSize.MediumerLarge}
        />
      </PrimarySectionAdditionActionItem>
    </PrimarySectionAdditionActionList>
  );
};

export default ScreensAdditionalAction;
