export enum CompanyTooltipContentBlock {
  Root = 'CompanyTooltipContent',
}

export enum CompanyTooltipContentElement {
  Header = 'header',
  Title = 'title',
  Info = 'info',
  Description = 'description',
  Financial = 'financial',
  TableData = 'tableData',
  Footnote = 'footnote',
}
