import React, { useContext } from 'react';
import FilterControl from '../FilterControl';
import { ViewFilterName } from '../../../../enum/types/ViewFilterName';
import { FilterSectionsFormContext } from '../../context/FilterSectionsFormContext';
import FilterSectionsAdvancedControls from '../FilterSectionsAdvancedControls';

const CompanyInfoFilterSection: React.FC = () => {
  const { dictionaries } = useContext(FilterSectionsFormContext);

  return (
    <>
      <FilterControl
        name={ViewFilterName.SectorIndustry}
        controlProps={{
          options: dictionaries?.[ViewFilterName.SectorIndustry],
        }}
      />

      <FilterControl
        name={ViewFilterName.Industry}
        controlProps={{
          options: dictionaries?.[ViewFilterName.Industry],
        }}
      />

      <FilterControl
        name={ViewFilterName.EstimatedTiming}
        controlProps={{
          options: dictionaries?.[ViewFilterName.EstimatedTiming],
        }}
      />

      <FilterControl
        name={ViewFilterName._Headquarters}
        controlProps={{
          optionsData: dictionaries?.[ViewFilterName.HeadquartersCityStateCountry],
        }}
      />

      <FilterControl name={ViewFilterName.EmergingGrowth} />

      <FilterSectionsAdvancedControls>
        <FilterControl name={ViewFilterName.FoundedSince} />
      </FilterSectionsAdvancedControls>
    </>
  );
};

export default CompanyInfoFilterSection;
