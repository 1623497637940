export enum FormControlType {
  DateRangeSelect = 'DateRangeSelect',
  Checkbox = 'Checkbox',
  TimeFrameSelect = 'TimeFrameSelect',
  Select = 'Select',
  TextSelect = 'TextSelect',
  SelectSwitchOptions = 'SelectSwitchOptions',
  ValueRange = 'ValueRange',
  CheckboxList = 'RadioList',
  Input = 'Input',
  DateSelect = 'DateSelect',
  SelectWithValueRange = 'SelectWithValueRange',
  TextSelectWithInput = 'TextSelectWithInput',
  ButtonCheckbox = 'ButtonCheckbox',
}
