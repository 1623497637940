import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CompanyProfile from './profile/CompanyProfile';
import CompanyInvestmentRationale from './investment-rationale/CompanyInvestmentRationale';
import CompanyFundamentalInsights from './fundamental-insights/CompanyFundamentalInsights';
import CompanyMgmtOwners from './mgmt-owners/CompanyMgmtOwners';
import CompanyPeerTrading from './peer-trading/CompanyPeerTrading';
import CompanyTeamNotes from './team-notes/CompanyTeamNotes';
import CompanyRelativeValuation from './relative-valuation/CompanyRelativeValuation';
import CompanyFinancials from './financials/CompanyFinancials';
import { CompanyTabsPath } from '../enums/CompanyTabsPath';
import { useRelativeTabPath } from './hooks/useRelativeTabPath';
import CompanyMgmtQs from './mgmt-qs/CompanyMgmtQs';
import CompanyMilestones from './milestones/CompanyMilestones';
import CompanyTabPage from './shared/CompanyTabPage';
import { CompanyContext } from './context/CompanyContext';
import { Nullable } from '../../shared/types/generics';
import { ProfileData } from '../interfaces/ProfileData';
import { CompanyTab } from '../enums/CompanyTab';
import { InvestmentRationaleDto } from '../interfaces/InvestmentRationaleDto';
import { CompanyFundamentalInsightsDto } from '../interfaces/CompanyFundamentalInsightsDto';
import { CompanyMgmtOwnersDto } from '../interfaces/CompanyMgmtOwnersDto';
import { CompanyPeerTradingDto } from '../interfaces/CompanyPeerTradingDto';
import { CompanyRelativeValuationDto } from '../interfaces/CompanyRelativeValuationDto';
import { CompanyMgmtQsDto } from '../interfaces/CompanyMgmtQsDto';
import { SimpleGridProps } from '../../shared/components/grid/simple-grid/SimpleGrid';
import { CompanyFinancialsDto } from '../interfaces/CompanyFinancialsDto';

const CompanyRoutes: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);

  const companyProfileData: Nullable<ProfileData> = getTabData(CompanyTab.Profile);
  const companyInvestmentRationaleData: Nullable<InvestmentRationaleDto> = getTabData(CompanyTab.InvestmentRationale);
  const companyFundamentalInsightsData: Nullable<CompanyFundamentalInsightsDto> = getTabData(
    CompanyTab.FundamentalInsights,
  );
  const companyMgmtOwnersData: Nullable<CompanyMgmtOwnersDto> = getTabData(CompanyTab.MgmtOwners);
  const companyPeerTradingData: CompanyPeerTradingDto = getTabData(CompanyTab.PeerTrading);
  const companyRelativeValuationData: Nullable<CompanyRelativeValuationDto> = getTabData(CompanyTab.RelativeValuation);
  const companyMgmtQsData: Nullable<CompanyMgmtQsDto> = getTabData(CompanyTab.MgmtQs);
  const companyMilestonesData: Nullable<SimpleGridProps> = getTabData(CompanyTab.Milestones);
  const companyFinancialsData: Nullable<CompanyFinancialsDto> = getTabData(CompanyTab.Financials);

  return (
    <Switch>
      <Route path={useRelativeTabPath(CompanyTabsPath.Profile)}>
        <CompanyTabPage data={companyProfileData}>
          <CompanyProfile />
        </CompanyTabPage>
      </Route>

      <Route path={useRelativeTabPath(CompanyTabsPath.InvestmentRationale)}>
        <CompanyTabPage data={companyInvestmentRationaleData}>
          <CompanyInvestmentRationale />
        </CompanyTabPage>
      </Route>

      <Route path={useRelativeTabPath(CompanyTabsPath.FundamentalInsights)}>
        <CompanyTabPage data={companyFundamentalInsightsData}>
          <CompanyFundamentalInsights />
        </CompanyTabPage>
      </Route>

      <Route path={useRelativeTabPath(CompanyTabsPath.MgmtOwners)}>
        <CompanyTabPage data={companyMgmtOwnersData}>
          <CompanyMgmtOwners />
        </CompanyTabPage>
      </Route>

      <Route path={useRelativeTabPath(CompanyTabsPath.PeerTrading)}>
        <CompanyTabPage data={companyPeerTradingData}>
          <CompanyPeerTrading />
        </CompanyTabPage>
      </Route>

      <Route path={useRelativeTabPath(CompanyTabsPath.RelativeValuation)}>
        <CompanyTabPage data={companyRelativeValuationData}>
          <CompanyRelativeValuation />
        </CompanyTabPage>
      </Route>

      <Route path={useRelativeTabPath(CompanyTabsPath.Financials)}>
        <CompanyTabPage data={companyFinancialsData}>
          <CompanyFinancials />
        </CompanyTabPage>
      </Route>

      <Route path={useRelativeTabPath(CompanyTabsPath.MgmtQs)}>
        <CompanyTabPage data={companyMgmtQsData}>
          <CompanyMgmtQs />
        </CompanyTabPage>
      </Route>

      <Route path={useRelativeTabPath(CompanyTabsPath.Milestones)}>
        <CompanyTabPage data={companyMilestonesData}>
          <CompanyMilestones />
        </CompanyTabPage>
      </Route>

      <Route path={useRelativeTabPath(CompanyTabsPath.TeamNotes)}>
        <CompanyTeamNotes />
      </Route>

      <Redirect to={useRelativeTabPath(CompanyTabsPath.Profile)} />
    </Switch>
  );
};

export default CompanyRoutes;
