import React from 'react';
import { TooltipContentProps } from '../../../../../../shared/components/tooltip/interface/TooltipContentProps';
import { useClassName } from '../../../../../../shared/hooks/useClassName';
import { DilutedSharesTooltipDto } from '../../../../../interfaces/DilutedSharesTooltipDto';
import { DilutedSharesContentBlock, DilutedSharesContentElement } from './DilutedSharesContentBem';
import './DilutedSharesTooltipContent.scss';
import CompanyTooltipContentGrid from '../../../../company-tooltip/CompanyTooltipContentGrid';

const DilutedSharesTooltipContent: React.FC<TooltipContentProps<DilutedSharesTooltipDto>> = props => {
  const cn = useClassName(DilutedSharesContentBlock.Root);

  const { data } = props;

  const newData = [];
  const dilutiveShares = [];
  const totalShares = [];
  const notes = [];

  for (let i = 0; i < data.rows.length; i++) {
    if (i === 1) {
      for (let j = 0; j < data.rows[1].dilutedSharesData?.length; j++) {
        if (j === 0) {
          const sharesWithSpace =
            data.rows[1].dilutedSharesData[j].dilutiveSharesText.split('at')[0] +
            '\xa0\xa0at\xa0\xa0' +
            data.rows[1].dilutedSharesData[j].dilutiveSharesText.split('at')[1];

          const item: any = {
            title: 'Dilutive Shares',
            data: sharesWithSpace,
          };
          dilutiveShares.push(item);
        } else {
          const sharesWithSpace =
            data.rows[1].dilutedSharesData[j].dilutiveSharesText.split('at')[0] +
            '\xa0\xa0at\xa0\xa0' +
            data.rows[1].dilutedSharesData[j].dilutiveSharesText.split('at')[1];

          const item: any = {
            title: '',
            data: sharesWithSpace,
          };
          dilutiveShares.push(item);
        }
      }
    }

    if (
      data.rows[i].Name &&
      i !== 1 &&
      data.rows[i].Name !== 'Note' &&
      data.rows[i].Name !== 'Diluted Shares Outstanding'
    ) {
      const item: any = {
        title: data.rows[i].Name,
        data: data.rows[i].dilutedSharesData,
      };

      newData.push(item);
    } else if (data.rows[i].Name && i !== 1 && data.rows[i].Name === 'Note' && data.rows[i].dilutedSharesData) {
      const item: any = {
        title: data.rows[i].Name,
        data: data.rows[i].dilutedSharesData,
      };
      notes.push(item);
    } else if (data.rows[i].Name && i !== 1 && data.rows[i].Name === 'Diluted Shares Outstanding') {
      const item: any = {
        title: data.rows[i].Name,
        data: data.rows[i].dilutedSharesData,
      };

      totalShares.push(item);
    }
  }

  return (
    <section className={cn()}>
      <div className={cn(DilutedSharesContentElement.Header)}>
        <h5 className={cn(DilutedSharesContentElement.Title)}>Diluted Share Calculation</h5>
      </div>
      <div className={cn(DilutedSharesContentElement.TableData)}>
        <CompanyTooltipContentGrid rows={newData} />
      </div>
      <div className={cn(DilutedSharesContentElement.DilutiveSharesData)}>
        <CompanyTooltipContentGrid rows={dilutiveShares} />
      </div>
      <div className={cn(DilutedSharesContentElement.TotalTtData)}>
        <CompanyTooltipContentGrid rows={totalShares} />
      </div>
      {notes.map(note => {
        return (
          <div className={cn(DilutedSharesContentElement.Footnote)} key={note}>
            <b>Note:</b> {note.data}
          </div>
        );
      })}
    </section>
  );
};

export default DilutedSharesTooltipContent;
