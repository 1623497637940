import React, { memo, useMemo } from 'react';
import DropdownTreeSelect, { TreeNode } from 'react-dropdown-tree-select';
import { SelectLibFactory } from './factories/SelectLibFactory';

export interface TreeSelectLibProp {
  isMultiSelect: boolean;
  data: TreeNode[];
  isDisabled?: boolean;
  placeholder?: string;
  onChangeValue: (currentNode: TreeNode, selectedNodes: TreeNode[]) => void;
  onBlur?: () => void;
}

const SelectLib: React.FC<TreeSelectLibProp> = memo(props => {
  const libProps = useMemo(() => SelectLibFactory.getProps(props), [props]);

  return <DropdownTreeSelect {...libProps} />;
});

SelectLib.displayName = 'SelectLib';

export default SelectLib;
