import React from 'react';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { useClassName } from '../../../../../../hooks/useClassName';
import { DealSizeCellBlock } from './DealSizeCellBem';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import CellNoteTooltip from '../tooltip/CellNoteTooltip';
import { ViewGridCellValueService } from '../../services/ViewGridCellValueService';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';

type Props = GridCellFormatterCommonProps;

const DealSizeCell: React.FC<Props> = props => {
  const cn = useClassName(DealSizeCellBlock.Root);

  return (
    <CustomCellFormatterWrapper className={cn()} value={props.value}>
      <CellNoteTooltip
        value={ViewGridCellValueService.getVisible(props.data, ViewColumnSystemName.DealSize)}
        data={props.data}
      />
    </CustomCellFormatterWrapper>
  );
};

export default DealSizeCell;
