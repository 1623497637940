import React, { useCallback, useContext } from 'react';
import { useClassName } from '../../../../../../hooks/useClassName';
import { ViewFilterValuesBlock } from './viewFilterValuesBem';
import { ViewContext } from '../../ViewContext';
import FormControlVisibleValueList from '../../../../../../components/forms/components/form-control-visible-value/FormControlVisibleValueList';
import { viewFilterValuesControlsSelector } from '../../store/selectors';
import { FormControl } from '../../../../../../components/forms/components/form-builder/types/formControlsTypes';
import { deleteFilters } from '../../store/actions/filter';
import { FormControlDeleteVisibleValueOptions } from '../../../../../../components/forms/components/form-control-visible-value/interfaces/FormControlDeleteVisibleValueOptions';

const ViewFilterValues: React.FC = () => {
  const cn = useClassName(ViewFilterValuesBlock.Root);

  const { dispatch, state } = useContext(ViewContext);

  const controls = viewFilterValuesControlsSelector(state);

  const onDelete = useCallback(
    (deleteValueOptions: FormControlDeleteVisibleValueOptions) => {
      dispatch(deleteFilters(deleteValueOptions));
    },
    [dispatch],
  );

  return (
    <FormControlVisibleValueList
      className={cn()}
      controls={controls as FormControl[]}
      values={state.filters}
      dictionaries={state.filtersDictionaries}
      onDelete={onDelete}
    />
  );
};

export default ViewFilterValues;
