import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { RoutePath } from '../../enums/RoutePath';
import classNames from 'classnames';
import LocationService from '../../services/LocationService';

interface Props {
  to: RoutePath;
  className?: string;
}

const TabLink: React.FC<Props> = props => {
  const [currentRoutePath, setCurrentRoutePath] = useState(LocationService.currentPath);

  useEffect(() => {
    setCurrentRoutePath(LocationService.currentPath);
  }, [LocationService.currentPath]);

  return (
    <>
      {props.to === currentRoutePath ? (
        <div className={classNames('TabLink nav-item nav-link active', props.className)}>{props.children}</div>
      ) : (
        <NavLink className={classNames('TabLink nav-item nav-link', props.className)} to={props.to}>
          {props.children}
        </NavLink>
      )}
    </>
  );
};

export default TabLink;
