import React, { ReactNode, useCallback, useContext } from 'react';
import { ButtonType } from '../../enums/ButtonType';
import { ColorScheme } from '../../enums/ColorScheme';
import Button from './Button';
import { FormStateContext } from './FormStateContext';
import { ControlSize } from '../../enums/ControlSize';
import { useClassName } from '../../hooks/useClassName';
import { ButtonSubmitBlock } from './buttonSubmitBem';

interface ButtonSubmitProps {
  isSubmitted: boolean;
  message: ReactNode;
  submittedMessage: ReactNode;
  colorScheme?: ColorScheme;
  size?: ControlSize;
  isBlock?: boolean;
  isDisabled?: boolean;
  isAutoSize?: boolean;
  isSimple?: boolean;
  responsiveNoLeftMargin?: boolean;
  className?: string;
}

const ButtonSubmit: React.FC<ButtonSubmitProps> = props => {
  const { isBlock = true, colorScheme = ColorScheme.Primary } = props;

  const cn = useClassName(ButtonSubmitBlock.Root, props.className);

  const formStateContext: FormStateContext = useContext<FormStateContext>(FormStateContext);

  const getButtonLabel = useCallback(() => {
    if (props.isSubmitted) {
      return props.submittedMessage;
    } else {
      return props.message;
    }
  }, [props.isSubmitted, props.message, props.submittedMessage]);

  return (
    <Button
      className={`${cn()}${props.responsiveNoLeftMargin ? ' responsive-no-left-margin' : ''}`}
      type={ButtonType.Submit}
      block={isBlock}
      colorScheme={props.isSubmitted ? ColorScheme.Success : colorScheme}
      isDisabled={
        props.isSubmitted || formStateContext.isLoading || props.isDisabled //|| !formStateContext.isNewSubmitFormValues
      }
      isLoading={formStateContext.isLoading}
      isAutoSize={props.isAutoSize}
      isSimple={props.isSimple}
      size={props.size}
    >
      {getButtonLabel()}
    </Button>
  );
};

export default ButtonSubmit;
