import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FullCalendar, { EventSourceInput, ViewApi } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import './ResponsiveCalendar.scss';
import { DateRange } from '../../types';
import { DateRangeHelper } from '../../helpers/DateRangeHelper';
import { ResponsiveCalendarService } from './ResponsiveCalendarService';
import { ResponsiveCalendarHeight } from './enums/ResponsiveCalendarHeight';
import { ResponsiveCalendarEvent } from './interfaces/ResponsiveCalendarEvent';
import { ResponsiveCalendarMode } from './enums/ResponsiveCalendarMode';
import { useClassName } from '../../hooks/useClassName';
import AccordionSecondary from '../accordion/AccordionSecondary';
import { WeekDay } from '../../enums/WeekDay';
import { ResponsiveCalendarBlock } from './ResponsiveCalendarBem';
import { CustomAny } from '../../types/generics';
import { CalendarEventMeta } from '../../entities/calendar/interfaces/CalendarEventMeta';
import { ResponsiveCalendarHeaderToolbarElement } from './enums/ResponsiveCalendarHeaderToolbarElement';
import { DateHelper } from '../../helpers/date/DateHelper';
import { DateFormat } from '../../helpers/date/enums/DateFormat';
import { ResponsiveCalendarOption } from './enums/ResponsiveCalendarOption';
import { ResponsiveCalendarViewType } from './types/ResponsiveCalendarViewType';
import { WEEK_MODE_WEEKS_COUNT_DEFAULT } from './constants/responsiveCalendarSettings';

interface Props extends ResponsiveCalendarProps {
  events: ResponsiveCalendarEvent<CustomAny>[];
}

export interface ResponsiveCalendarProps {
  mode: ResponsiveCalendarMode;
  weeksCount?: number;
  hasHeader?: boolean;
  height?: number | ResponsiveCalendarHeight;
  className?: string;
  eventContent?: (eventMeta: CalendarEventMeta) => React.ReactNode;
  headerAction?: ReactNode;
  hasAccordion?: boolean;
  startDate?: Date | string | number | number[];
  validRange?: DateRange;
  sortEventsFn?: (eventA: ResponsiveCalendarEvent<CustomAny>, eventB: ResponsiveCalendarEvent<CustomAny>) => number;
  onDateRangeChange?(range: DateRange): void;
  onScheduled?: boolean;
}

const plugins = [listPlugin, dayGridPlugin];

const ResponsiveCalendar: React.FC<Props> = props => {
  const { height = ResponsiveCalendarHeight.Auto, hasAccordion = false, startDate, hasHeader = true } = props;
  const [isClose, setIsClose] = useState<boolean>(
    localStorage.getItem('pref-scheduledCalendarPreviewClose') === 'true' ? true : false,
  );

  const cn = useClassName(ResponsiveCalendarBlock.Root, props.className);

  const fullCalendarRef = useRef<FullCalendar>(null);
  const [range, setRange] = useState<DateRange>();
  const events: EventSourceInput = useMemo(() => ResponsiveCalendarService.prepareEvents<CustomAny>(props.events), [
    props.events,
  ]);
  const headerToolbar = useMemo(() => {
    if (hasHeader) {
      return {
        left: ResponsiveCalendarHeaderToolbarElement.Prev,
        center: ResponsiveCalendarHeaderToolbarElement.Title,
        right: ResponsiveCalendarHeaderToolbarElement.Next,
      };
    }

    return false;
  }, [hasHeader]);

  const viewSettings = useMemo(() => {
    let type;
    let views;

    switch (props.mode) {
      case ResponsiveCalendarMode.WeekVertical:
        type = ResponsiveCalendarViewType.ListWeek;
        break;
      case ResponsiveCalendarMode.WeekHorizontal:
        type = ResponsiveCalendarViewType.DayGridWeek;
        views = {
          [ResponsiveCalendarViewType.DayGridWeek]: {
            duration: { weeks: props.weeksCount || WEEK_MODE_WEEKS_COUNT_DEFAULT },
          },
        };
        break;
      case ResponsiveCalendarMode.Month:
      default:
        type = ResponsiveCalendarViewType.DayGridMonth;
    }

    return {
      initialView: type,
      key: type,
      views,
    };
  }, [props.mode, props.weeksCount]);

  const validRange = useMemo(
    () =>
      props.validRange && {
        start: DateHelper.formatDate(props.validRange[0], DateFormat.YearMonthDayISO),
        end: DateHelper.formatDate(props.validRange[1], DateFormat.YearMonthDayISO),
      },
    [props.validRange],
  );

  const onRangeRender = useCallback(
    (data: { view: ViewApi }) => {
      const nextRange: DateRange = [data.view.currentStart, data.view.currentEnd];

      if (!range || !DateRangeHelper.isEqual(range, nextRange)) {
        setRange(nextRange);
      }
    },
    [range, setRange],
  );

  useEffect(() => range && props.onDateRangeChange?.(range), [range, props.onDateRangeChange]);
  useEffect(() => {
    if (startDate) {
      fullCalendarRef.current?.getApi().gotoDate(startDate);
    }
  }, [startDate]);

  useEffect(() => {
    if (validRange) {
      fullCalendarRef.current?.getApi().setOption(ResponsiveCalendarOption.ValidRange, validRange);
    }
  }, [validRange]);

  return (
    <section className={cn()}>
      <AccordionSecondary
        title={props.headerAction && <div className={cn('headerAction')}>{props.headerAction}</div>}
        isAvailable={hasAccordion}
        onScheduled={props.onScheduled}
        closeState={[isClose, setIsClose]}
      >
        <div className={cn('calendar')}>
          <FullCalendar
            ref={fullCalendarRef}
            weekends={false}
            displayEventTime={false}
            headerToolbar={headerToolbar}
            height={height}
            buttonText={{
              today: 'Today',
            }}
            eventContent={props.eventContent}
            datesSet={onRangeRender}
            events={events}
            themeSystem="bootstrap"
            plugins={plugins}
            firstDay={WeekDay.Monday}
            initialDate={props.startDate}
            eventOrder={props.sortEventsFn as CustomAny}
            validRange={validRange}
            {...viewSettings}
          />
        </div>
      </AccordionSecondary>
    </section>
  );
};

export default ResponsiveCalendar;
