import React from 'react';
import { Link } from 'react-router-dom';
import { VIDEO_BANNER_IMAGE_LOCATION } from '../../../../shared/constants/environment';
import { VideoLocationService } from '../../services/videoLocationService';
import './VideoBanner.scss';

interface Props {
  title: string;
  imageLocation: string;
  descript: string;
  videoTypeKey: number;
  videoKey?: number;
  onClick?: () => void;
  imageLocationTypeKey?: number;
}

const VideoCard: React.FC<Props> = props => {
  return (
    <span
      onClick={() => {
        if (props.videoTypeKey === 1) {
          props.onClick && props.onClick();
          window.history.replaceState(null, 'Video Tutorials', `/video-tutorials`);
        }
      }}
      className={'img-with-tint'}
    >
      {props.videoTypeKey === 1 ? (
        <>
          <img
            src={`${props.imageLocationTypeKey === 3 ? `${VIDEO_BANNER_IMAGE_LOCATION}` : ''}${props.imageLocation}`}
          />
          <span className={'tint'} />
        </>
      ) : (
        <>
          <Link to={`${VideoLocationService.getRoutePath(props.videoKey)}`}>
            <img src={`${props.imageLocation}`} />
            <span className={'tint'} />
          </Link>
        </>
      )}
    </span>
  );
};

export default VideoCard;
