import { NavItemLink } from '../../../../../components/nav/interfaces/NavItemLink';
import { ViewSaved } from '../interfaces/api/ViewSaved';
import { ViewLocationService } from './ViewLocationService';
import { ViewType } from '../enums/ViewType';
import { ViewSaveData } from '../components/view-modal/interfaces/ViewSaveData';
import { ViewFilterService } from './ViewFilterService';
import { ViewColumnsService } from './ViewColumnsService';
import { ActionListNavItem } from '../../../../../components/nav/components/action-list/interfaces/ActionListNavItem';
import { ViewId } from '../types/ViewId';
import { ViewIdType } from '../enums/ViewIdType';
import { viewTypeRoutesInverted } from '../constants/viewTypeRoutes';
import { ViewRoutePath } from '../types/ViewRoutePath';
import { NavUrlService } from '../../../../../services/NavUrlService';

export class ViewSavedService {
  static getNavItemLink(savedViews: ViewSaved[]): NavItemLink[] {
    return savedViews.map(savedView => {
      const routePath = ViewLocationService.getRoutePath(ViewType.CustomInUse, savedView.viewId);

      return {
        text: savedView.name,
        path: routePath,
        isActive: NavUrlService.isExactQueryParams(routePath),
      };
    });
  }

  static getSavedViewsFromNavItemLinks(links: NavItemLink[]): ViewSaved[] {
    return links.map(link => {
      return {
        viewId: ViewIdType.DefaultView,
        viewType: viewTypeRoutesInverted[link.path as ViewRoutePath],
        name: (link.text as string) || '',
      };
    });
  }

  static getActionListNavItemsFormSavedViews(savedViews: ViewSaved[]): ActionListNavItem[] {
    return savedViews.map(savedView => ({
      id: savedView.viewId,
      path: ViewLocationService.getRoutePath(savedView.viewType, savedView.viewId),
      text: savedView.name,
    }));
  }

  static getById(viewId: ViewId, savedViews: ViewSaved[]): ViewSaved | undefined {
    return savedViews.find(savedView => savedView.viewId === viewId);
  }

  static shouldViewUpdate(stateSaveData: ViewSaveData, newSaveData: ViewSaveData): boolean {
    return (
      ViewFilterService.isAllFiltersChanged(newSaveData.filters, stateSaveData.filters) ||
      ViewColumnsService.isSelectedColumnsChanged(newSaveData.columns, stateSaveData.columns)
    );
  }
}
