export enum ControlSize {
  ExtraExtraSmall = 'xxs',
  ExtraSmall = 'xs',
  SmallerExtraSmall = 'ssx',
  SmallExtraSmall = 'sx',
  Small = 'sm',
  MediumSmall = 'ms',
  Medium = 'md',
  MediumerLarge = 'mml',
  MediumLarge = 'ml',
  Large = 'lg',
  ExtraLarge = 'xl',
}
