import React, { useContext, useMemo } from 'react';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import { Link } from 'react-router-dom';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import { CompanyTabsPath } from '../../../../../enums/CompanyTabsPath';
import { CompanyLocationService } from '../../../../../services/CompanyLocationService';
import CompanyProfileSidebarTable from '../company-profile-table/CompanyProfileSidebarTable';
import { profileKeyShareHoldersExpandingLength } from '../../constants/profileKeyShareHoldersExpandingLength';
import { ProfileDataService } from '../../services/ProfileDataService';
import { GridRow } from '../../../../../../shared/components/grid/shared/types/GridRow';

const CompanyKeyShareholders: React.FC = () => {
  const { getTabData, ticker } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const linkPath = useMemo(() => CompanyLocationService.getRelativeTabPath(CompanyTabsPath.MgmtOwners, ticker), [
    ticker,
  ]);

  const expandData = useMemo(() => ProfileDataService.getCompanyTableRows(profile.keyShareholders) as GridRow[], [
    profile.keyShareholders,
  ]);

  return (
    <>
      {profile.keyShareholders && (
        <CompanyProfileSideItem
          title={<Link to={linkPath}>Key Shareholders</Link>}
          component={CompanyProfileSidebarTable}
          componentProps={{
            columns: profile.keyShareholders.columns,
            isHeaderHidden: true,
            hasAroundBorder: false,
          }}
          expandData={expandData}
          expandingLength={profileKeyShareHoldersExpandingLength}
        />
      )}
    </>
  );
};

export default CompanyKeyShareholders;
