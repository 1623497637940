import React, { useContext, useMemo } from 'react';
import { CompanyContext } from '../context/CompanyContext';
import CompanySection from '../shared/CompanySection';
import { CompanyTab } from '../../enums/CompanyTab';
import { Nullable } from '../../../shared/types/generics';
import SimpleGrid, { SimpleGridProps } from '../../../shared/components/grid/simple-grid/SimpleGrid';
import { ControlSize } from '../../../shared/enums/ControlSize';

const CompanyMgmtQs: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const data: Nullable<SimpleGridProps> = getTabData(CompanyTab.Milestones);

  const titleProps = useMemo(() => {
    return {
      tab: CompanyTab.Milestones,
    };
  }, []);

  return (
    <>
      {data && <h3 className="printHead">Company Milestones</h3>}
      <CompanySection data={data} titleProps={titleProps}>
        {data && <SimpleGrid size={ControlSize.Small} {...data} />}
      </CompanySection>
    </>
  );
};

export default CompanyMgmtQs;
