import React from 'react';
import { RoutePath } from '../enums/RoutePath';
import PrimarySection from './primary-section/PrimarySection';
import DocumentHead from './DocumentHead';

const NotAuthorized: React.FC = () => {
  return (
    <section className="NotAuthorized">
      <DocumentHead path={RoutePath.NotAuthorized} />

      <PrimarySection>
        <h1>You are not authorized to view this page</h1>
        <h1>Please sign-in to an account with access</h1>
      </PrimarySection>
    </section>
  );
};

export default NotAuthorized;
