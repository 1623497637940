import React, { useMemo } from 'react';
import { ViewTypeScreenLanding } from '../../../../../../shared/entities/view/components/view/types/screen/ViewTypeScreenLanding';
import View from '../../../../../../shared/entities/view/components/view/View';
import { ScreenOptions } from '../../interfaces/ScreenOptions';
import { ScreenOptionsService } from '../../services/ScreenOptionsService';
import { screenLandingSharedOptions } from './constants/screenLandingSharedOptions';
import { ViewEvents } from '../../../../../../shared/entities/view/components/view/interfaces/options/ViewEvents';
import { ControlSize } from '../../../../../../shared/enums/ControlSize';

interface Props {
  landingType: ViewTypeScreenLanding;
  options: Partial<ScreenOptions>;
  events?: ViewEvents;
}

const ScreensLanding: React.FC<Props> = props => {
  const options = useMemo(
    () => ScreenOptionsService.get(props.landingType, props.options, screenLandingSharedOptions),
    [props.landingType, props.options],
  );

  return (
    <View viewType={props.landingType} options={options} events={props.events} maxGridWidth={ControlSize.Medium} />
  );
};

export default ScreensLanding;
