import React, { useCallback, useEffect } from 'react';
import './AdditionalMenu.scss';
import { useLocation } from 'react-router-dom';
import * as H from 'history';
import { useDispatch, useSelector } from 'react-redux';
import PrimarySection from '../primary-section/PrimarySection';
import { PrimarySectionIndent } from '../primary-section/PrimarySectionIndent';
import AdditionalMenuAccountSettings from './AdditionalMenuAccountSettings';
import { AccountSettingsRoutes } from './constants/AccountSettingsRoutes';
import LocationService from '../../services/LocationService';
import { RoutePath } from '../../enums/RoutePath';
import { RootState } from '../../../store';
import { setAdditionalMenuVisibility } from '../../../store/header/actions';
import { VideosRoutes } from './constants/VideosRoutes';
import AdditionalMenuVideos from './AdditionalMenuVideos';

const AdditionalMenuRoutes: RoutePath[] = [...VideosRoutes, ...AccountSettingsRoutes];

const AdditionalMenu: React.FC = () => {
  const isVisible = useSelector<RootState, boolean>(state => state.header.isAdditionalMenuVisible);
  const dispatch = useDispatch();
  const location: H.Location = useLocation();

  useEffect(() => {
    dispatch(
      setAdditionalMenuVisibility(
        LocationService.hasCurrentPath(AdditionalMenuRoutes, location) ||
          LocationService.includesCurrentPath(RoutePath.LatestVideos, location) ||
          LocationService.includesCurrentPath(RoutePath.VideoTutorials, location),
      ),
    );
  }, [location, dispatch]);

  const getAdditionalMenuComponent = useCallback((): React.ReactNode => {
    if (LocationService.hasCurrentPath(AccountSettingsRoutes, location)) {
      return <AdditionalMenuAccountSettings />;
    } else if (
      LocationService.hasCurrentPath(VideosRoutes, location) ||
      LocationService.includesCurrentPath(RoutePath.LatestVideos, location) ||
      LocationService.includesCurrentPath(RoutePath.VideoTutorials, location)
    ) {
      return <AdditionalMenuVideos />;
    } else {
      return null;
    }
  }, [location]);

  return (
    <>
      {isVisible && (
        <PrimarySection indent={PrimarySectionIndent.None} className="AdditionalMenu">
          {getAdditionalMenuComponent()}
        </PrimarySection>
      )}
    </>
  );
};

export default AdditionalMenu;
