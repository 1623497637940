import { additionalStatsRoutesTitles } from '../../../stats/additional-stats/constants/additionalStatsRoutesTitles';
import { statsNavItems } from '../../entities/navigation/constants/statsNavItems';
import { RoutePath } from '../../enums/RoutePath';

const statsPaths: RoutePath[] = [];

statsNavItems.forEach(item => {
  statsPaths.push(item.path);
});

const additionalStatsRoutesTitlesKeys: string[] = Object.keys(additionalStatsRoutesTitles);
additionalStatsRoutesTitlesKeys.forEach(item => {
  statsPaths.push(item as RoutePath);
});

export { statsPaths };
