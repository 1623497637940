import React, { useEffect, useMemo } from 'react';
import './CompanyTabsContent.scss';
import { Nullable } from '../../shared/types/generics';
import { CompanyTabsDto } from '../interfaces/CompanyTabsDto';
import InnerNav from '../../shared/components/nav/components/InnerNav';
import { useCompanyRoutesTitles } from './hooks/useCompanyRoutesTitles';
import CompanyRoutes from './CompanyRoutes';
import PrimarySectionRow from '../../shared/components/primary-section/PrimarySectionRow';
import { useClassName } from '../../shared/hooks/useClassName';
import { CompanyTabsContentBlock, CompanyTabsContentElement } from './companyTabsContentBem';
import { useCurrentRoutePath } from '../../shared/hooks/useCurrentRoutePath';
import { CompanyTab } from '../enums/CompanyTab';
import { companyTabPathToName } from '../constants/companyTabPathToName';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setIsPrint } from '../../store/profile-print/actions';
import { AlignTypesHorizontal } from '../../shared/enums/AlignTypesHorizontal';

interface Props {
  data: Nullable<CompanyTabsDto>;
}

const CompanyTabsContent: React.FC<Props> = props => {
  const cn = useClassName(CompanyTabsContentBlock.Root);
  const dispatch = useDispatch();
  const isPrint = useSelector<RootState, boolean>(state => state.profilePrint.isPrint);

  const routePath = useCurrentRoutePath();

  const titles = useCompanyRoutesTitles();
  const activeTabName = useMemo(
    () => Object.values(CompanyTab).find(tab => routePath.includes(companyTabPathToName[tab])),
    [routePath],
  );

  useEffect(() => {
    if (isPrint) {
      setTimeout(() => {
        dispatch(setIsPrint(false));
        window.print();
      }, 2000);
    }
  }, [isPrint, dispatch]);

  return props.data ? (
    <>
      <PrimarySectionRow
        className={cn({
          [`${activeTabName}`]: activeTabName,
        })}
      >
        <InnerNav routesTitles={titles} className={cn(CompanyTabsContentElement.Navigation)}>
          <CompanyRoutes />
        </InnerNav>
      </PrimarySectionRow>
    </>
  ) : (
    <PrimarySectionRow alignHorizontal={AlignTypesHorizontal.Center}>
      <h2>Sorry, no profile is available.</h2>
    </PrimarySectionRow>
  );
};

export default CompanyTabsContent;
