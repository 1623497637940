export class DomService {
  static getClassesSelector(classNames: string[]): string {
    return classNames.map(className => this.getClassSelector(className)).join(',');
  }

  static getClassSelector(className: string): string {
    return `.${className}`;
  }

  static findElement(context: Document | Element = document, selector: string): HTMLElement | null {
    return context.querySelector(selector);
  }

  static hasElementScrollbar(context: Document | Element = document, selector: string): boolean {
    const el = this.findElement(context, selector);

    if (!el) {
      return false;
    }

    return el.scrollHeight > el.clientHeight;
  }

  static resize(): void {
    window.dispatchEvent(new Event('resize'));
  }

  static getClassNamesStr(classNames: string[]): string {
    return classNames.join(' ');
  }

  static syncFinancialTablesScroll(t1Parent: HTMLElement, t2Parent: HTMLElement, t3Parent?: HTMLElement) {
    // t1Parent, t2Parent, and t3Parent are parent divs of each financial table. The scroll property works on the table's parent div.
    function syncScroll(event: any) {
      if (event.type === 'scroll') {
        const targetElement = event.target;
        t1Parent.scrollLeft = targetElement.scrollLeft;
        t2Parent.scrollLeft = targetElement.scrollLeft;
        if (t3Parent) {
          t3Parent.scrollLeft = targetElement.scrollLeft;
        }
      }
    }

    t1Parent.addEventListener('scroll', (e: any) => syncScroll(e));
    t2Parent.addEventListener('scroll', (e: any) => syncScroll(e));

    if (t3Parent) {
      t3Parent.addEventListener('scroll', (e: any) => syncScroll(e));
    }
  }
}
