import React from 'react';
import './GoogleAnalytics.scss';
import PrimarySection from '../shared/components/primary-section/PrimarySection';

const ExhaustedResources: React.FC = () => {
  return (
    <>
      <PrimarySection header="Google Analytics" className="analytics-container">
        <p className="exhaustedResources">
          <strong>
            Google Analytics resources have been exhausted. <br />
            The maximum query limit for Google Analytics was reached. <br />
            Please wait an hour to view more data on this page.
          </strong>
        </p>
      </PrimarySection>
    </>
  );
};

export default ExhaustedResources;
