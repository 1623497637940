import { GridAgColumn } from '../../../../shared/components/grid/grid/interfaces/GridAgColumn';
import { ViewColumnSystemName } from '../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';

export const companiesCustomColumnsSettings: Partial<GridAgColumn>[] = [
  {
    field: ViewColumnSystemName.CompanyTicker,
    flex: 0,
  },
  {
    field: ViewColumnSystemName.BusinessOverview,
    flex: 1,
  },
  {
    field: ViewColumnSystemName.Underwriter,
    width: 100,
    minWidth: 100,
  },
];
