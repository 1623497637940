import React, { useCallback, useContext, useState } from 'react';
import Form from '../../../../shared/components/forms/Form';
import FormGroup from '../../../../shared/components/forms/FormGroup';
import { CompanyTeamNotesField } from './CompanyTeamNotesField';
import FormTextarea from '../../../../shared/components/forms/FormTextarea';
import { ColorScheme } from '../../../../shared/enums/ColorScheme';
import Button from '../../../../shared/components/forms/Button';
import { CompanyContext } from '../../context/CompanyContext';
import { validationSchema } from './validationSchema';

const CompanyTeamNotesForm: React.FC = () => {
  const { sendTeamNote, teamNotes } = useContext(CompanyContext);

  const [defaultValues, setDefaultValues] = useState({});

  const [, , isLoading] = teamNotes;

  const onSubmit = useCallback(
    data => {
      sendTeamNote(data);
      setDefaultValues({});
    },
    [sendTeamNote],
  );

  return (
    <Form validationSchema={validationSchema} defaultValues={defaultValues} onSubmit={onSubmit}>
      <FormGroup>
        <FormTextarea placeholder="Enter your comment" name={CompanyTeamNotesField.Message} />
      </FormGroup>

      <Button isDisabled={isLoading} colorScheme={ColorScheme.Primary} isOutline block>
        Send a Comment
      </Button>
    </Form>
  );
};

export default CompanyTeamNotesForm;
