import React from 'react';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../../../../enums/RoutePath';

export const viewEntity = 'View';
export const screenEntity = 'Screen';

export const savedScreens = 'Saved Screens';
export const savedViews = 'Saved Views';

export const noDataView = 'No Saved Views';
export const noDataScreen = 'No Saved Screens';

export const noDataViewWithLink = (
  <span>
    No Saved Views.
    <br />
    Run and Save a View on the Scheduled, Priced, Pipeline, or PCW pages.
  </span>
);
export const noDataScreenWithLink = (
  <span>
    No Saved Screens.
    <br />
    Run and Save a Screen <Link to={RoutePath.ScreensAdd}>here</Link>.
  </span>
);

export const viewsHeader = 'My Views';
export const screensHeader = 'My Screens';
export const screensDefaultHeader = 'Default Screens';

export const saveViewMessage = '"{viewName}" has been saved';
