import { Context, createContext } from 'react';
import { TeamNote } from '../../interfaces/TeamNote';
import { CompanyTeamNotesData } from '../team-notes/form/CompanyTeamNotesData';
import { TickerSymbol } from '../../../shared/types';
import { Nullable, CustomAny } from '../../../shared/types/generics';
import { ContextData } from '../../../shared/hooks/context-data/types/ContextData';
import { CompanyPerfomanceVsIpoIndexTerm } from '../shared/performance-vs-ipo-Index-chart/interfaces/CompanyPerfomanceVsIpoIndexTerm';
import { CompanyPerfomanceVsIpoIndexChartDto } from '../../types/CompanyPerfomanceVsIpoIndexChartDto';
import { CompanyTabsDto } from '../../interfaces/CompanyTabsDto';
import { CompanyTab } from '../../enums/CompanyTab';
import { AsyncFnData } from '../../../shared/hooks/async-fn/interfaces/AsyncFnData';
import { SimpleGridProps } from '../../../shared/components/grid/simple-grid/SimpleGrid';

export interface CompanyContextValue {
  tabsData: ContextData<Nullable<CompanyTabsDto>>;
  fundamentalComparisonData: Nullable<SimpleGridProps>;
  valuationComparisonData: Nullable<SimpleGridProps>;
  technicalComparisonData: Nullable<SimpleGridProps>;
  teamNotes: ContextData<TeamNote[]>;
  unreadTeamNotesCount: ContextData<Nullable<number>>;
  perfomanceVsIpoIndexChart: ContextData<
    Nullable<CompanyPerfomanceVsIpoIndexChartDto[]>,
    (terms: CompanyPerfomanceVsIpoIndexTerm) => void
  >;
  ticker: TickerSymbol;
  perfomanceVsIpoIndexTerm: Nullable<CompanyPerfomanceVsIpoIndexTerm>;
  perfomanceChartTickers: TickerSymbol[];
  setPerfomanceVsIpoIndexTerm: (data: Nullable<CompanyPerfomanceVsIpoIndexTerm>) => void;
  initializePerfomanceVsIpoIndexChart: () => void;
  addPerfomanceChartTicker: AsyncFnData<void>;
  sendTeamNote: (data: CompanyTeamNotesData) => void;
  removeTeamNote: (id: string) => void;
  getTabData: (tab: CompanyTab) => CustomAny;
}

export const CompanyContext: Context<CompanyContextValue> = createContext<CompanyContextValue>({
  tabsData: [null, () => Promise.resolve(), false, () => {}, false],
  fundamentalComparisonData: null,
  valuationComparisonData: null,
  technicalComparisonData: null,
  teamNotes: [[], () => Promise.resolve(), false, () => {}, false],
  unreadTeamNotesCount: [null, () => Promise.resolve(), false, () => {}, false],
  ticker: '',
  perfomanceVsIpoIndexChart: [null, () => Promise.resolve(), false, () => {}, false],
  perfomanceVsIpoIndexTerm: null,
  perfomanceChartTickers: [],
  setPerfomanceVsIpoIndexTerm() {},
  initializePerfomanceVsIpoIndexChart() {},
  addPerfomanceChartTicker: [() => Promise.resolve(), false, undefined, () => {}, false],
  getTabData(): void {},
  sendTeamNote(): void {},
  removeTeamNote(): void {},
});
