import React from 'react';
import './Accordion.scss';
import { useClassName } from '../../hooks/useClassName';
import { AccordionContentBlock, AccordionContentModifiers } from './AccordionContentBem';
import AnimatedHeight from '../animation/animated-height/AnimatedHeight';

interface Props {
  isClose: boolean;
  hasAnimation: boolean;
}

const AccordionContent: React.FC<Props> = props => {
  const cn = useClassName(AccordionContentBlock.Root);

  return (
    <AnimatedHeight isVisible={!props.isClose} hasAnimation={props.hasAnimation}>
      <div
        className={cn({
          [AccordionContentModifiers.Close]: props.isClose,
          [AccordionContentModifiers.Open]: !props.isClose,
        })}
      >
        {props.children}
      </div>
    </AnimatedHeight>
  );
};

export default AccordionContent;
