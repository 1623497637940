import React, { memo, useMemo } from 'react';
import FormattedDate from '../../shared/components/FormattedDate';
import { DateFormat } from '../../shared/helpers/date/enums/DateFormat';
import { useClassName } from '../../shared/hooks/useClassName';
import { NewsRelatedArticleDto } from '../interfaces/NewsRelatedArticleDto';
import { Link } from 'react-router-dom';
import { NewsLocationService } from '../shared/services/NewsLocationService';
import './NewsArticleRelated.scss';
import { TickerSymbol } from '../../shared/types';
import CompanyLink from '../../company/components/company-link/CompanyLink';
import { NewsType } from '../enums/NewsType';

interface Props {
  ticker?: TickerSymbol;
  articles: NewsRelatedArticleDto[];
  type: NewsType;
  className?: string;
}

const NewsArticleRelated: React.FC<Props> = props => {
  const cn = useClassName('NewsArticleRelated', props.className);
  const relatedTitle = useMemo(
    () =>
      props.type === NewsType.Blog
        ? 'Other commentary'
        : props.ticker && (
            <>
              News related to: <CompanyLink ticker={props.ticker} />
            </>
          ),
    [props.type, props.ticker],
  );

  return (
    <div className={cn()}>
      <div>{relatedTitle}</div>
      <ul>
        {props.articles.map((article: NewsRelatedArticleDto, index: number) => {
          const url = NewsLocationService.getRoutePath(article.newsKey);

          return (
            <li className={cn('item')} key={index}>
              <Link className={cn('link')} to={url}>
                {article.headline}
              </Link>

              <span className={cn('date')}>
                <FormattedDate date={article.breakDate} format={DateFormat.LongMonthDayYear} />
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default memo(NewsArticleRelated);
