import { DashboardContext } from '../DashboardContext';
import { useCallback, useContext } from 'react';
import { DashboardWidgetConfig } from '../interfaces/DashboardWidgetConfig';
import without from 'lodash/without';
import { DashboardWidgetType } from '../enums/DashboardWidgetType';

export function useDashboardRemoveWidget(widget: DashboardWidgetConfig): () => void {
  const { widgets, setWidgetTypes } = useContext(DashboardContext);

  return useCallback(() => {
    const removedArray = without(widgets, widget);
    const widgetTypes: DashboardWidgetType[] = [];
    removedArray.forEach(item => {
      widgetTypes.push(item.type);
    });
    setWidgetTypes(widgetTypes);
  }, [widgets, setWidgetTypes, widget]);
}
