import React, { useMemo } from 'react';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import './ReportTitleCell.scss';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import { FileActionProp } from '../../../../../../components/file-actions/interfaces/FileActionProp';
import { FileActionType } from '../../../../../../components/file-actions/enums/FileActionType';
import FileActions from '../../../../../../components/file-actions/FileActions';
import { ViewGridCellValueService } from '../../services/ViewGridCellValueService';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';
import { useClassName } from '../../../../../../../shared/hooks/useClassName';
import { ReportTitleBlock } from './ReportTitleBem';

type Props = GridCellFormatterCommonProps;

const ReportTitleCell: React.FC<Props> = props => {
  const cn = useClassName(ReportTitleBlock.Root);

  const title = ViewGridCellValueService.getRaw(props.data, ViewColumnSystemName.ReportTitle);
  const url = ViewGridCellValueService.getRaw(props.data, ViewColumnSystemName.ReportLink);

  const fileActions = useMemo<FileActionProp[]>(() => {
    const actions: FileActionProp[] = [];

    switch (title) {
      case 'Fundamental Insights':
        actions.push({
          type: FileActionType.Insight,
          title,
          routePath: url,
          tooltipTitle: 'Fundamental Insights',
        });
        break;
      case 'Financial Insights':
        actions.push({
          type: FileActionType.Insight,
          title,
          routePath: url,
          tooltipTitle: 'Financial Insights',
        });
        break;
      case 'Governance Insights':
        actions.push({
          type: FileActionType.Insight,
          title,
          routePath: url,
          tooltipTitle: 'Governance Insights',
        });
        break;
      case 'Mgmt Questions':
        actions.push({
          type: FileActionType.MGMTQ,
          title,
          externalDownloadOptions: {
            url,
          },
          tooltipTitle: 'Mgmt. Questions',
        });
        break;
      default:
        // PDF Report
        actions.push({
          type: FileActionType.Pdf,
          title,
          externalDownloadOptions: {
            url,
          },
          tooltipTitle: 'Pdf Report',
        });
        break;
    }

    return actions;
  }, [title, url]);

  return (
    <CustomCellFormatterWrapper className={cn()} value={title}>
      <FileActions fileActions={fileActions} />
    </CustomCellFormatterWrapper>
  );
};

export default ReportTitleCell;
