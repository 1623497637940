import React, { useCallback, useState } from 'react';
import Button from '../../../../../../components/forms/Button';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { IconName } from '../../../../../../components/icon/IconName';
import ViewToolbarSavedViewsPopover from './view-toolbar-saved-filters/ViewToolbarSavedViewsPopover';
import ViewGridToolbarGroup from '../../../view-grid/components/parts/ViewGridToolbarGroup';
import IconWithContent from '../../../../../../components/icon/IconWithContent';
import ButtonsList from '../../../../../../components/forms/ButtonsList';
import { useClassName } from '../../../../../../hooks/useClassName';
import { ViewGroupSavedViewsBlock, ViewGroupSavedViewsElement } from './viewToolbarGroupSavedViewsBem';
import ViewFilterModal from '../view-filter/ViewFilterModal';

interface Props {
  hideSavedViews?: boolean;
}

const ViewToolbarGroupSavedViews: React.FC<Props> = props => {
  const cn = useClassName(ViewGroupSavedViewsBlock.Root);

  const [isModalShow, setIsModalShow] = useState(false);
  const onFilterIconClick = useCallback(() => setIsModalShow(true), []);

  return (
    <ViewGridToolbarGroup className={cn()}>
      <ButtonsList isMobileAdaptive={false}>
        <Button size={ControlSize.Small} onClick={onFilterIconClick} className={cn(ViewGroupSavedViewsElement.Filter)}>
          <IconWithContent name={IconName.Filter} size={ControlSize.Small}>
            Filter
          </IconWithContent>
        </Button>

        {!props.hideSavedViews && <ViewToolbarSavedViewsPopover />}
      </ButtonsList>
      <ViewFilterModal hideSaveAndRun={props.hideSavedViews} modalShowState={[isModalShow, setIsModalShow]} />
    </ViewGridToolbarGroup>
  );
};

export default ViewToolbarGroupSavedViews;
