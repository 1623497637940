export enum TrialRequestFormFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  BusinessEmail = 'businessEmail',
  PhoneNumber = 'phoneNumber',
  Company = 'company',
  JobTitle = 'jobTitle',
  Agree = 'agree',
  Captcha = 'recaptchaToken',
}
