import React, { useEffect, useState } from 'react';
import './ViewColumnsAddSection.scss';
import ColumnsSectionItem from './ViewColumnsAddSectionItem';
import AccordionPrimary from '../../../../../../../../../components/accordion/AccordionPrimary';
import { ViewColumnsAddItem } from '../../interfaces/ViewColumnsAddItem';
import { BreakpointName } from '../../../../../../../../../enums/BreakpointName';
import { useClassName } from '../../../../../../../../../hooks/useClassName';
import { ViewColumnsAddSectionBlock, ViewColumnsAddSectionModifier } from './viewColumnsAddSectionBem';

interface Props {
  title: string;
  items: ViewColumnsAddItem[];
  isClose: boolean;
  isHighlighted?: boolean;
}

const ViewColumnsAddSection: React.FC<Props> = props => {
  const cn = useClassName(ViewColumnsAddSectionBlock.Root);

  const closeState = useState(props.isClose);
  const [, setClose] = closeState;

  useEffect(() => setClose(props.isClose), [setClose, props.isClose]);

  return (
    <section
      className={cn({
        [ViewColumnsAddSectionModifier.Highlighted]: props.isHighlighted,
      })}
    >
      <AccordionPrimary title={props.title} breakpoints={[BreakpointName.ExtraSmall]} closeState={closeState}>
        <ul>
          {props.items.map((item: ViewColumnsAddItem) => (
            <ColumnsSectionItem
              key={item.id}
              id={item.id}
              label={item.label}
              isSelected={!!item.isSelected}
              isDisabled={!!item.isDisabled}
            />
          ))}
        </ul>
      </AccordionPrimary>
    </section>
  );
};

export default ViewColumnsAddSection;
