import React, { Dispatch, SetStateAction } from 'react';
import { TooltipPosition } from '../../tooltip/enums/TooltipPosition';
import Tooltip from '../../tooltip/Tooltip';
import { NavItem } from '../../nav/types/NavItem';
import TooltipContentNavList from '../../tooltip/TooltipContentNavList';

interface Props {
  title: React.ReactNode;
  items: NavItem[];
  className?: string;
  onToggle?: (isShow: boolean) => void;
  show?: boolean;
  setShow?: Dispatch<SetStateAction<boolean>>;
  isClickForced?: boolean;
  isInUserMenu?: boolean;
}
const SubMenuTooltip: React.FC<Props> = props => {
  return (
    <Tooltip
      isTitleHighlighted={props.isClickForced}
      hideHighlight
      title={props.title}
      content={<TooltipContentNavList items={props.items} />}
      position={TooltipPosition.Bottom}
      delayShow={0}
      className={`${props.isInUserMenu ? '' : 'sub-menu-tooltip'} ${props.className ? ' ' + props.className : ''}`}
      onToggle={props.onToggle}
      show={props.show}
      setShow={props.setShow}
      arrowSize={props.isInUserMenu ? 6 : 9}
      distance={0}
      tipContentHover={false}
    />
  );
};

export default SubMenuTooltip;
