import React from 'react';
import { Link } from 'react-router-dom';
import './NewsLinkCell.scss';
import { NewsLinkDto } from '../../../../../../../news/interfaces/NewsLinkDto';
import { DateFormat } from '../../../../../../helpers/date/enums/DateFormat';
import { DateHelper } from '../../../../../../helpers/date/DateHelper';
import { NewsLocationService } from '../../../../../../../news/shared/services/NewsLocationService';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { useClassName } from '../../../../../../hooks/useClassName';
import { NewsLinkCellBlock, NewsLinkCellElement } from './newsLinkCellBem';
import Icon from '../../../../../../components/icon/Icon';
import { IconName } from '../../../../../../components/icon/IconName';
import { ColorScheme } from '../../../../../../enums/ColorScheme';
import { ControlSize } from '../../../../../../enums/ControlSize';

type Props = GridCellFormatterCommonProps;

const NewsLinkCell: React.FC<Props> = props => {
  const cn = useClassName(NewsLinkCellBlock.Root);

  const data = props.data as NewsLinkDto;

  return (
    <CustomCellFormatterWrapper className={cn()} value={data.newsKey}>
      <Link to={NewsLocationService.getRoutePath(data.newsKey)} target="_blank">
        {data.headline}
      </Link>

      <span className={cn(NewsLinkCellElement.Date)}>
        {' '}
        {data.videoKey && (
          <Icon
            className={`${IconName.PlayCircle} inline-icon`}
            name={IconName.PlayCircle}
            size={ControlSize.Small}
            colorScheme={ColorScheme.Attention}
          />
        )}
        {DateHelper.formatISODateStr(data.breakDate, DateFormat.MonthtextDay)}{' '}
      </span>
    </CustomCellFormatterWrapper>
  );
};

export default NewsLinkCell;
