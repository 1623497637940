import React, { useMemo } from 'react';
import { ViewFilterName } from '../../../shared/entities/filters/enum/types/ViewFilterName';
import { StatsGlobalFilter } from '../types/StatsGlobalFilter';
import StatsPage from '../StatsPage';
import StatsRecentIpoPerfomance from './components/recent-ipo-performance-chart/StatsRecentIpoPerfomance';
import StatsReturnsBySector from './components/returns-by-sector/StatsReturnsBySector';
import StatsIpoReturns from './components/ipo-returns/StatsIpoReturns';
import StatsMarketByMonth from './components/market-by-month/StatsMarketByMonth';
import { StatsFilterName } from '../enum/StatsFilterName';
import StatsDashboardChartsSwitchByLocation from './components/StatsDashboardChartsSwitchByLocation';

const StatsDashboard: React.FC = () => {
  const filtersNames = useMemo(
    () => [ViewFilterName.Headquarter, StatsFilterName.IncludeSPACs],
    [],
  ) as StatsGlobalFilter[];

  return (
    <StatsPage header="IPO Stats" filtersNames={filtersNames}>
      <StatsRecentIpoPerfomance />

      <StatsMarketByMonth />

      <StatsReturnsBySector />

      <StatsDashboardChartsSwitchByLocation />

      <StatsIpoReturns />
    </StatsPage>
  );
};

export default StatsDashboard;
