import React, { useMemo } from 'react';
import './ChangeDynamicFormatter.scss';
import { useClassName } from '../../hooks/useClassName';
import Icon, { IconProps } from '../icon/Icon';
import { ColorScheme } from '../../enums/ColorScheme';
import { ControlSize } from '../../enums/ControlSize';
import { IconName } from '../icon/IconName';
import { ChangeDynamicType } from './enums/ChangeDynamicType';

interface Props {
  type: ChangeDynamicType;
}

const ChangeDynamicFormatter: React.FC<Props> = props => {
  const cn = useClassName('ChangeDynamicFormatter');

  const iconProps = useMemo<IconProps>(() => {
    let stateProps = {};

    if (props.type === ChangeDynamicType.Up) {
      stateProps = {
        colorScheme: ColorScheme.Success,
        name: IconName.Increase,
      };
    } else if (props.type === ChangeDynamicType.Down) {
      stateProps = {
        colorScheme: ColorScheme.Danger,
        name: IconName.Decrease,
      };
    }
    return {
      className: cn(),
      size: ControlSize.ExtraSmall,
      ...stateProps,
    } as IconProps;
  }, [props.type, cn]);

  return <>{props.type !== ChangeDynamicType.Stable && <Icon {...iconProps} />}</>;
};

export default ChangeDynamicFormatter;
