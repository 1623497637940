import React from 'react';
import ViewColumnsAdd, { ViewColumnsAddProps } from './add/ViewColumnsAdd';

type Props = ViewColumnsAddProps;

const ViewColumnsWeb: React.FC<Props> = props => {
  return (
    <ViewColumnsAdd
      isInModal={props.isInModal}
      columnsOptions={props.columnsOptions}
      selectedColumnsIds={props.selectedColumnsIds}
      onChange={props.onChange}
    />
  );
};

export default ViewColumnsWeb;
