import React, { useMemo } from 'react';
import FilterControl from '../FilterControl';
import { ViewFilterName } from '../../../../enum/types/ViewFilterName';
import FilterSectionsAdvancedControls from '../FilterSectionsAdvancedControls';
import FilterSectionItemsGroup from '../FilterSectionItemsGroup';

const FinancialsFilterSection: React.FC = () => {
  return (
    <>
      <FilterControl name={ViewFilterName._RevenueGrowth} />

      <FilterControl name={ViewFilterName.RevenueGrowthPeriod} />

      <FilterControl name={ViewFilterName._EstimatedSales} />

      <FilterSectionsAdvancedControls>
        <FilterControl name={ViewFilterName._LtmLatestSales} />

        <FilterControl name={ViewFilterName._LtmGrossMargin} />
      </FilterSectionsAdvancedControls>

      <FilterControl name={ViewFilterName._LtmEbitdaMargin} />

      <FilterSectionsAdvancedControls>
        <FilterControl name={ViewFilterName._LtmNetMargin} />

        <FilterSectionItemsGroup
          filterNames={useMemo(
            () => [
              ViewFilterName._FinancialsPostIpoCash,
              ViewFilterName._FinancialsPostIpTotalAssets,
              ViewFilterName._FinancialsPostIpoTotalDebt,
              ViewFilterName._FinancialsPostIpoEquity,
              ViewFilterName._IpoDebtToEbitda,
            ],
            [],
          )}
        >
          <FilterControl name={ViewFilterName._FinancialsPostIpoCash} />

          <FilterControl name={ViewFilterName._FinancialsPostIpTotalAssets} />

          <FilterControl name={ViewFilterName._FinancialsPostIpoTotalDebt} />

          <FilterControl name={ViewFilterName._FinancialsPostIpoEquity} />

          <FilterControl name={ViewFilterName._IpoDebtToEbitda} />
        </FilterSectionItemsGroup>

        <FilterSectionItemsGroup
          filterNames={useMemo(
            () => [
              ViewFilterName._FinancialsLTMCashFlowFromOperation,
              ViewFilterName._FinancialsLTMCapex,
              ViewFilterName._FinancialsLTMFreeCashFlow,
              ViewFilterName.MostRecentFinancialsDateMin,
            ],
            [],
          )}
        >
          <FilterControl name={ViewFilterName._FinancialsLTMCashFlowFromOperation} />

          <FilterControl name={ViewFilterName._FinancialsLTMCapex} />

          <FilterControl name={ViewFilterName._FinancialsLTMFreeCashFlow} />

          <FilterControl name={ViewFilterName.MostRecentFinancialsDateMin} />

          <FilterControl name={ViewFilterName.Dividend} />
        </FilterSectionItemsGroup>
      </FilterSectionsAdvancedControls>
    </>
  );
};

export default FinancialsFilterSection;
