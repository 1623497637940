import React, { useMemo } from 'react';
import Button, { ButtonProps } from '../../Button';
import { ColorScheme } from '../../../../enums/ColorScheme';
import { ControlSize } from '../../../../enums/ControlSize';

interface Props extends ButtonProps {
  isSelected: boolean;
}

const TextSelectElementButton: React.FC<Props> = props => {
  const { isSelected, ...buttonProps } = props;

  const unSelectedProps: ButtonProps = useMemo(
    () => ({
      colorScheme: ColorScheme.Primary,
      isOutline: true,
    }),
    [],
  );

  const selectedProps: ButtonProps = useMemo(
    () => ({
      colorScheme: ColorScheme.Info,
    }),
    [],
  );

  const selectableProps = useMemo(() => (isSelected ? selectedProps : unSelectedProps), [
    isSelected,
    selectedProps,
    unSelectedProps,
  ]);

  return <Button {...buttonProps} {...selectableProps} size={ControlSize.Small} />;
};

export default TextSelectElementButton;
