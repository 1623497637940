import React, { memo, MouseEvent, ReactNode, useCallback, useMemo } from 'react';
import './Checkbox.scss';
import { FieldError } from 'react-hook-form';
import classNames from 'classnames';
import uniqueId from 'lodash.uniqueid';
import Label from './Label';
import Error from './Error';
import { CustomAny } from '../../types/generics';

export enum CheckboxType {
  Checkbox = 'checkbox',
  Radio = 'radio',
}

export interface CheckboxProps {
  name: string;
  value?: string | number;
  label?: string | ReactNode;
  isDisabled?: boolean;
  type?: CheckboxType;
  error?: FieldError;
  register?: CustomAny;
  isRaw?: boolean;
  isCheck?: boolean;
  onChange?(isCheck: boolean): void;
  onClick?(isCheck: boolean, even: MouseEvent): void;
}

const Checkbox: React.FC<CheckboxProps> = props => {
  const { error } = props;
  const id = useMemo(() => uniqueId('Checkbox-'), []);

  React.useEffect(() => {
    // TODO: Refactor how Screen's add page loads default values.
    // Right now, onFormChange, the default values are loaded.
    // By checking and unchecking 'Dividend, we simulate a change on the form
    // Which in turn loads up the defaultValues as defined in the onFormChange function
    // To find onFormChange, see src/shared/entities/filters/components/filter-sections/context/useFilterSectionsFormContext.ts

    if (props.name === 'Dividend') {
      document.getElementById(id)?.click();
      document.getElementById(id)?.click();
    }
  });

  const onChange = useCallback((): void => {
    props.onChange?.(!props.isCheck);
  }, [props.onChange, props.isCheck]);

  const onClick = useCallback(
    (event: MouseEvent): void => {
      props.onClick?.(!props.isCheck, event);
    },
    [props.onClick, props.isCheck],
  );

  const className = useMemo<string>(
    () =>
      classNames('form-check-input', {
        'is-invalid': error,
      }),
    [error],
  );

  return (
    <div className={`Checkbox Checkbox--${props.name} form-check`} onClick={onClick}>
      {props.isRaw || !props.register ? (
        <input
          className={className}
          type={props.type}
          name={props.name}
          value={props.value}
          id={id}
          disabled={props.isDisabled}
          checked={props.isCheck}
          onChange={onChange}
        />
      ) : (
        <input
          className={className}
          type={props.type}
          name={props.name}
          value={props.value}
          ref={props.register}
          id={id}
          disabled={props.isDisabled}
        />
      )}

      {props.label && (
        <Label className="form-check-label" error={error} htmlFor={id}>
          {props.label}
        </Label>
      )}

      {error && <Error>{error.message}</Error>}
    </div>
  );
};

Checkbox.defaultProps = {
  type: CheckboxType.Checkbox,
  isRaw: false,
  isCheck: false,
};

export default memo(Checkbox);
