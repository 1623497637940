import { SelectOptionsData } from '../types/SelectOptionsData';
import { SelectOptionProp } from '../types/SelectOptionProp';
import { SelectOptionValue } from '../../../../../services/select/types/SelectOptionValue';
import { AnyObject } from '../../../../../types/generics';
import { FormTextSelectProps } from '../../../FormTextSelect';

export class FormSelectOptionsSwitchService {
  static getOptions(optionsData: SelectOptionsData, switchValues: SelectOptionValue[] = []): SelectOptionProp[] {
    const propName = this.getDictionaryPropName(switchValues);

    return (optionsData && optionsData[propName]) || [];
  }

  static getSwitchValuesByProps(textSelectsProps: FormTextSelectProps[], formValues: AnyObject): SelectOptionValue[] {
    return textSelectsProps.map(textSelectProps => formValues[textSelectProps.name]);
  }

  static getSwitchNamesByProps(textSelectsProps: FormTextSelectProps[]): string[] {
    return textSelectsProps.map(textSelectProps => textSelectProps.name);
  }

  static getSwitchKeyValuesByProps(textSelectsProps: FormTextSelectProps[], formValues: AnyObject): AnyObject {
    return textSelectsProps.reduce(
      (result, textSelectProp) => ({
        ...result,
        [textSelectProp.name]: formValues[textSelectProp.name],
      }),
      {},
    );
  }

  private static getDictionaryPropName(switchValues: SelectOptionValue[] = []): string {
    return switchValues.join('');
  }
}
