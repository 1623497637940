import { NewsStoryType } from '../enums/NewsStoryType';

export const newsStoryTypeName: { [key in NewsStoryType]: string } = {
  [NewsStoryType.IpoPricing]: 'IPO Pricing',
  [NewsStoryType.NewIpoFiling]: 'New IPO Filing',
  [NewsStoryType.OtherAnnouncement]: 'Other',
  [NewsStoryType.Postponed]: 'Postponed',
  [NewsStoryType.SetTerms]: 'Set Terms',
  [NewsStoryType.TermsDown]: 'Terms-Down',
  [NewsStoryType.TermsUp]: 'Terms-Up',
  [NewsStoryType.UpdatedFinancials]: 'Updated Financials',
  [NewsStoryType.Withdrawn]: 'Withdrawn',
};
