import { DashboardContextValue } from '../DashboardContext';
import { useCallback, useMemo, useState } from 'react';
import { DashboardWidgetConfig } from '../interfaces/DashboardWidgetConfig';
import { DashboardWidgetType } from '../enums/DashboardWidgetType';
import difference from 'lodash/difference';
import DashboardApiService from '../../shared/api/DashboardApiService';
import { useDashboardConfiguration } from './useDashboardConfiguration';
import { dashboardDefaultWidgetsConfigs } from '../constants/dashboardDefaultWidgetsConfigs';
import { DashboardWidgetService } from '../services/DashboardWidgetService';

export function useDashboardContextValue(): DashboardContextValue {
  const [widgets, setWidgets] = useState<DashboardWidgetConfig[]>([]);
  const isDefaults = useMemo(() => DashboardWidgetService.areDefaultWidgets(widgets), [widgets]);
  const saveWidgets = useCallback((next: DashboardWidgetConfig[]) => {
    DashboardApiService.saveWidgets(DashboardWidgetService.areDefaultWidgets(next) ? null : next);
  }, []);
  const onWidgetsChange = useCallback(
    (next: DashboardWidgetConfig[]) => {
      setWidgets(next);
      saveWidgets(next);
    },
    [saveWidgets],
  );
  const setDefaultWidgets = useCallback(() => onWidgetsChange(dashboardDefaultWidgetsConfigs), [onWidgetsChange]);
  const setWidgetTypes = useCallback(
    (nextTypes: DashboardWidgetType[]) => {
      const widgetTypes = widgets.map(widget => widget.type);
      let nextWidgets = widgets.slice();

      difference(widgetTypes, nextTypes).forEach(
        type => (nextWidgets = nextWidgets.filter(widget => widget.type !== type)),
      );
      difference(nextTypes, widgetTypes).forEach(type => {
        const widget = DashboardWidgetService.getWidgetByType(dashboardDefaultWidgetsConfigs, type);

        if (widget) {
          nextWidgets = [...nextWidgets, widget];
        }
      });

      onWidgetsChange(nextWidgets);
    },
    [widgets, onWidgetsChange],
  );

  useDashboardConfiguration(setWidgets);

  return useMemo(
    () => ({
      widgets,
      isDefaults,
      setWidgets,
      setDefaultWidgets,
      setWidgetTypes,
      onWidgetsChange,
    }),
    [widgets, isDefaults, setDefaultWidgets, setWidgetTypes, onWidgetsChange],
  );
}
