import React, { useMemo } from 'react';
import ChangeDynamicFormatter from '../../../../../../components/formatters/ChangeDynamicFormatter';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import { PriceRangeColumnValue } from '../../interfaces/column-values/PriceRangeColumnValue';
import { ViewGridCellValueService } from '../../services/ViewGridCellValueService';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';
import { PriceRangeChange } from '../../interfaces/column-values/enum/PriceRangeChange';
import { ChangeDynamicType } from '../../../../../../components/formatters/enums/ChangeDynamicType';
import { useClassName } from '../../../../../../hooks/useClassName';
import { PriceRangeCellBlock } from './PriceRangeCellBem';

type Props = GridCellFormatterCommonProps;

const PriceRangeCell: React.FC<Props> = props => {
  const cn = useClassName(PriceRangeCellBlock.Root);

  const value = props.value as PriceRangeColumnValue | undefined;

  const changeDynamicType = useMemo(() => {
    if (value?.change === PriceRangeChange.RaisedRange) {
      return ChangeDynamicType.Up;
    } else if (value?.change === PriceRangeChange.LoweredRange) {
      return ChangeDynamicType.Down;
    } else {
      return ChangeDynamicType.Stable;
    }
  }, [value]);

  return (
    <CustomCellFormatterWrapper className={cn()} value={props.value}>
      <ChangeDynamicFormatter type={changeDynamicType} />
      {ViewGridCellValueService.getVisible(props.data, ViewColumnSystemName.PriceRange)}
    </CustomCellFormatterWrapper>
  );
};

export default PriceRangeCell;
