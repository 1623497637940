import React from 'react';
import AdditionalMenuList from './AdditionalMenuList';
import AdditionalMenuListItem from './AdditionalMenuListItem';
import { RoutePath } from '../../enums/RoutePath';

const AdditionalMenuAccountSettings: React.FC = () => {
  return (
    <AdditionalMenuList>
      <AdditionalMenuListItem title="Account Settings" routePath={RoutePath.AccountSettings} />
      <AdditionalMenuListItem title="Email Preferences" routePath={RoutePath.EmailPreferences} />
      <AdditionalMenuListItem title="My Favorites" routePath={RoutePath.MyFavourites} />
    </AdditionalMenuList>
  );
};

export default AdditionalMenuAccountSettings;
