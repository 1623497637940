import { GridLayoutBreakpoint } from '../../shared/components/grid-layout/enums/GridLayoutBreakpoint';
import { DashboardDefaultOptions } from '../types/DashboardDefaultOptions';
import { DateFormat } from '../../shared/helpers/date/enums/DateFormat';

const DASHBOARD_WIDGET_MIN_HEIGHT = 6;
export const DASHBOARD_WIDGET_DATE_FORMAT = DateFormat.MonthDay;

export const DASHBOARD_WIDGET_DEFAULT_OPTIONS: DashboardDefaultOptions = {
  [GridLayoutBreakpoint.Large]: {
    minHeight: DASHBOARD_WIDGET_MIN_HEIGHT,
  },
  [GridLayoutBreakpoint.Medium]: {
    minHeight: DASHBOARD_WIDGET_MIN_HEIGHT,
  },
  [GridLayoutBreakpoint.Small]: {
    minHeight: DASHBOARD_WIDGET_MIN_HEIGHT,
  },
  [GridLayoutBreakpoint.ExtraSmall]: {
    minHeight: DASHBOARD_WIDGET_MIN_HEIGHT,
  },
  [GridLayoutBreakpoint.ExtraExtraSmall]: {
    minHeight: DASHBOARD_WIDGET_MIN_HEIGHT,
  },
};
