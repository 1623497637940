import React from 'react';
import PrimarySectionRow from '../../../shared/components/primary-section/PrimarySectionRow';
import InnerNav from '../../../shared/components/nav/components/InnerNav';
import { additionalStatsRoutesTitles } from '../constants/additionalStatsRoutesTitles';
import { InnerNavPositions } from '../../../shared/components/nav/enums/InnerNavPositions';
import { RoutePath } from '../../../shared/enums/RoutePath';
import StatsPage from '../../stats/StatsPage';
import { ViewFilterName } from '../../../shared/entities/filters/enum/types/ViewFilterName';
import { StatsGlobalFilter } from '../../stats/types/StatsGlobalFilter';
import { mobileBreakpoints } from '../../../shared/constants/breakpoints/mobileBreakpoints';
import { BreakpointName } from '../../../shared/enums/BreakpointName';

const filtersNames = [ViewFilterName.Headquarter] as StatsGlobalFilter[];
const MOBILE_BREAKPOINTS = [...mobileBreakpoints, BreakpointName.Medium];

const AdditionalStatsPage: React.FC = props => {
  return (
    <StatsPage isUnderlinedHeader header="Additional Stats" filtersNames={filtersNames}>
      <PrimarySectionRow>
        <InnerNav
          routesTitles={additionalStatsRoutesTitles}
          position={InnerNavPositions.Left}
          backRouteTitle="Main IPO Stats"
          backRoutePath={RoutePath.Stats}
          mobileBreakpoints={MOBILE_BREAKPOINTS}
        >
          {props.children}
        </InnerNav>
      </PrimarySectionRow>
    </StatsPage>
  );
};

export default AdditionalStatsPage;
