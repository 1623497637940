import { ViewDashboardDto } from './type/ViewDashboardDto';
import { createContext } from 'react';
import { AnyObject } from '../../../../types/generics';
import { ViewDashboardItemDataDto } from './type/ViewDashboardItemDataDto';

export interface ViewDashboardContextValue {
  isInnerLoading: boolean;
  data?: ViewDashboardDto;
  innerFilters?: AnyObject;
  onInnerFilterChange: (key: string, values: AnyObject) => void;
  onFilter: () => void;
  onItemDataChange: (key: string, data: ViewDashboardItemDataDto) => void;
  onDataChange: () => void;
}

export const ViewDashboardContext = createContext<ViewDashboardContextValue>({
  data: {},
  isInnerLoading: false,
  onInnerFilterChange: () => {},
  onFilter: () => {},
  onItemDataChange: () => {},
  onDataChange: () => {},
});
