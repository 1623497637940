import React, { useContext, useMemo } from 'react';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import Hyperlink from '../../../../../../shared/components/typography/Hyperlink';
import { useClassName } from '../../../../../../shared/hooks/useClassName';
import { CompanyCompanyDataBlock, CompanyCompanyDataElement } from './companyCompanyDataBem';
import './CompanyCompanyData.scss';
import { PROTOCOL_REX_EXP } from '../../../../../../shared/constants/regExp';
import CompanyProfileSidebarTable from '../company-profile-table/CompanyProfileSidebarTable';

const CompanyCompanyData: React.FC = () => {
  const cn = useClassName(CompanyCompanyDataBlock.Root);

  const { getTabData } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const data = useMemo(() => profile.companyData, [profile]);

  const link = useMemo(() => profile.description?.webAddress, [profile.description]);
  const linkTitle = useMemo(() => link?.replace(PROTOCOL_REX_EXP, ''), [link]);

  return (
    <>
      {data && (
        <CompanyProfileSideItem className={cn()} title="Company Data">
          <CompanyProfileSidebarTable {...data} expandedData={data.rows} isHeaderHidden hasAroundBorder={false} />

          {link && (
            <Hyperlink className={cn(CompanyCompanyDataElement.Link)} to={link} isExternal isBlank>
              {linkTitle}
            </Hyperlink>
          )}
        </CompanyProfileSideItem>
      )}
    </>
  );
};

export default CompanyCompanyData;
