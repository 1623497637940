import { TokenStorageKey } from '../token/enums/TokenStorageKey';

// To avoid the error during server side rendering
const localStorage = window.localStorage;
const sessionStorage = window.sessionStorage;

export default class StorageService {
  static setItem(key: TokenStorageKey, value: string, permanent = true): void {
    if (permanent) {
      localStorage?.setItem(key, value);
    } else {
      sessionStorage?.setItem(key, value);
    }

    localStorage?.setItem(key, value);
  }

  static getItem(key: TokenStorageKey): string | null {
    return localStorage?.getItem(key) || sessionStorage?.getItem(key);
  }

  static removeItem(key: TokenStorageKey): void {
    localStorage?.removeItem(key);
    sessionStorage?.removeItem(key);
  }
}
