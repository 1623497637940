import React, { useMemo } from 'react';
import './TabsContainer.scss';
import Tabs from 'react-bootstrap/Tabs';
import uniqueId from 'lodash.uniqueid';
import { SelectCallback } from 'react-bootstrap/helpers';
import { ControlSize } from '../../enums/ControlSize';
import { ColorScheme } from '../../enums/ColorScheme';
import { useClassName } from '../../hooks/useClassName';
import { TabsContainerBlock, TabsContainerElement, TabsContainerModifier } from './tabsContainerBem';

interface Props {
  className?: string;
  type?: TabsType;
  onSelect?: SelectCallback;
  size?: ControlSize;
  tabContentGutter?: ControlSize;
  colorScheme?: ColorScheme;
  unmountOnExit?: boolean;
}

export enum TabsType {
  Link = 'link',
  Tab = 'tab',
}

const TabsContainer: React.FC<Props> = props => {
  const { type = TabsType.Link, size = ControlSize.Medium } = props;

  const cn = useClassName(TabsContainerBlock.Root, props.className);

  const id = useMemo(() => uniqueId('Tabs-'), []);

  return (
    <div
      className={cn({
        [size]: size,
        [`${props.colorScheme}`]: props.colorScheme,
        [`${props.tabContentGutter}${TabsContainerModifier.TabContentGutter}`]: props.tabContentGutter,
      })}
    >
      {type === TabsType.Link ? (
        <nav className={TabsContainerElement.NavNavTabs}>{props.children}</nav>
      ) : (
        <Tabs onSelect={props.onSelect} id={id} transition={false} unmountOnExit={props.unmountOnExit}>
          {props.children}
        </Tabs>
      )}
    </div>
  );
};

export default TabsContainer;
