import { FormControl } from '../types/formControlsTypes';
import { AnyObject, CustomAny } from '../../../../../types/generics';
import { FormControlType } from '../enums/FormControlType';
import { FormCheckboxProps } from '../../../FormCheckbox';
import { FormDateRangeSelectProps } from '../../date/FormDateRangeSelect';
import { FormTimeFrameSelectProps } from '../../../FormTimeFrameSelect';
import { FormSelectProps } from '../../select/FormSelect';
import { FormTextSelectProps } from '../../../FormTextSelect';
import { FormSelectSwitchOptionsProps } from '../../select/FormSelectOptionsSwitch';
import { FormValueRangeProps } from '../../../FormValueRange';
import { FormCheckboxListProps } from '../../../FormCheckboxList';
import { FormInputProps } from '../../../FormInput';
import { FormDateSelectProps } from '../../date/FormDateSelect';
import { FormSelectWithValueRangeProps } from '../../select/FormSelectWithValueRange';
import { FormTextSelectWithInputProps } from '../../../FormTextSelectWithInput';
import { FormControlValueService } from './FormControlValueService';
import { FormControlCheckboxListService } from './FormControlCheckboxListService';
import { FormSelectOptionsSwitchService } from '../../select/services/FormSelectOptionsSwitchService';
import { FORM_CONTROL_NO_ACTIVE_COUNT } from '../constants/counters';

export class FormControlCounterService {
  static getActiveControlsCounts(controls: FormControl[], formData: AnyObject): number {
    return controls.reduce((sum, control) => {
      const count = this.getActiveControlCount(control, formData);
      return sum + count;
    }, FORM_CONTROL_NO_ACTIVE_COUNT);
  }

  static getActiveControlsCountsTitle(controls: FormControl[], formData: AnyObject): string {
    return this.getActiveControlsCountsTitleByCount(this.getActiveControlsCounts(controls, formData));
  }

  static getActiveControlsCountsTitleByCount(count: number): string {
    return count > 0 ? `(${count})` : '';
  }

  static getControlsNames(controls: FormControl[]): string[] {
    return controls.reduce((names: string[], control) => [...names, ...this.getControlNamesByType(control)], []);
  }

  private static getControlNamesByType(control: FormControl): string[] {
    const names: (string | undefined)[] = [];

    switch (control.type) {
      case FormControlType.Checkbox: {
        const props = control.props as FormCheckboxProps;
        names.push(props.name);
        break;
      }
      case FormControlType.DateRangeSelect: {
        const props = control.props as FormDateRangeSelectProps;
        names.push(props.minName, props.maxName);
        break;
      }
      case FormControlType.TimeFrameSelect: {
        const props = control.props as FormTimeFrameSelectProps;
        names.push(props.timeFrameName, props.minName, props.maxName);
        break;
      }
      case FormControlType.Select: {
        const props = control.props as FormSelectProps;
        names.push(props.name);
        break;
      }
      case FormControlType.TextSelect: {
        const props = control.props as FormTextSelectProps;
        names.push(props.name);
        break;
      }
      case FormControlType.SelectSwitchOptions: {
        const props = control.props as FormSelectSwitchOptionsProps;
        names.push(
          props.selectProps.name,
          ...FormSelectOptionsSwitchService.getSwitchNamesByProps(props.textSelectsProps),
        );
        break;
      }
      case FormControlType.ValueRange: {
        const props = control.props as FormValueRangeProps;
        names.push(props.minName, props.maxName);
        break;
      }
      case FormControlType.CheckboxList: {
        const props = control.props as FormCheckboxListProps;
        names.push(props.name);
        break;
      }
      case FormControlType.Input: {
        const props = control.props as FormInputProps;
        names.push(props.name);
        break;
      }
      case FormControlType.DateSelect: {
        const props = control.props as FormDateSelectProps;
        names.push(props.name);
        break;
      }
      case FormControlType.SelectWithValueRange: {
        const props = control.props as FormSelectWithValueRangeProps;
        names.push(props.rangeMinName, props.rangeMaxName);
        break;
      }
      case FormControlType.TextSelectWithInput: {
        const props = control.props as FormTextSelectWithInputProps;
        names.push(props.name);
        break;
      }
      case FormControlType.ButtonCheckbox: {
        const props = control.props as FormCheckboxProps;
        names.push(props.name);
        break;
      }
    }

    return names.filter(name => name) as string[];
  }

  private static getControlValuesByType(control: FormControl, formData: AnyObject): CustomAny[] {
    const names = this.getControlNamesByType(control);
    return names.map(name => formData[name]);
  }

  private static getActiveControlCount(control: FormControl, formData: AnyObject): number {
    const controlValues = this.getControlValuesByType(control, formData);
    const availableControlValues = controlValues.filter(value => !FormControlValueService.hasNoValue(value));

    if (FormControlCheckboxListService.isCheckboxType(control)) {
      return FormControlCheckboxListService.getCheckboxTypeActiveControlValues(availableControlValues);
    } else {
      return availableControlValues.length;
    }
  }
}
