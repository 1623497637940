import React, { FunctionComponent, useEffect, useState } from 'react';
import './HeaderSearch.scss';
import escEffect from '../../effects/escEffect';
import classNames from 'classnames';
import { useClassName } from '../../hooks/useClassName';
import { HeaderSearchBlock } from './HeaderSearchBem';
import Search from '../../../search/components/search/Search';
import Icon from '../icon/Icon';
import { IconName } from '../icon/IconName';
import { ControlSize } from '../../enums/ControlSize';

const HeaderSearch: FunctionComponent = () => {
  const cn = useClassName(HeaderSearchBlock.Root);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const close = (): void => setIsOpen(false);
  const toggle = (): void => setIsOpen(!isOpen);

  useEffect(escEffect(close, isOpen));

  return (
    <div className={cn()}>
      <button className="HeaderSearch__toggle btn btn-sm" onClick={toggle}>
        {isOpen ? (
          <Icon name={IconName.Close} size={ControlSize.Medium} />
        ) : (
          <Icon name={IconName.BoldMagnifier} size={ControlSize.Large} />
        )}
      </button>

      <div
        className={classNames('HeaderSearch__form', {
          'HeaderSearch__form--open': isOpen,
        })}
      >
        <div className="container responsive-no-right-pad">
          <div className="HeaderSearch__container">
            <Search onSubmit={close} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSearch;
