import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import PageLoader from '../../../../components/loader/PageLoader';
import { ViewDashboardGetDataFn } from './type/ViewDashboardGetDataFn';
import { ViewDashboardContext } from './ViewDashboardContext';
import { AnyObject } from '../../../../types/generics';
import { useAsyncFn } from '../../../../hooks/async-fn/useAsyncFn';
import { ObjectHelper } from '../../../../helpers/ObjectHelper';
import { ViewDashboardItemDataDto } from './type/ViewDashboardItemDataDto';

interface Props {
  getDataFn: ViewDashboardGetDataFn;
  outerFilters?: AnyObject;
  dashboardType?: string;
}

const ViewDashboard: React.FC<Props> = props => {
  const { getDataFn /* , dashboardType */ } = props;

  const [innerFilters, setInnerFilters] = useState({});

  const searchData = useMemo(
    () =>
      ObjectHelper.getChildrenObjects({
        ...innerFilters,
        ...props.outerFilters,
      }),
    [innerFilters, props.outerFilters],
  );

  const [getData, isLoading, data, setData] = useAsyncFn(
    useCallback(() => getDataFn(searchData), [getDataFn, searchData]),
  );

  const isInnerLoading = useMemo(() => !!data && isLoading, [data, isLoading]);
  const isGlobalLoading = useMemo(() => !data && isLoading, [data, isLoading]);

  const onInnerFilterChange = useCallback((key: string, values: AnyObject) => {
    setInnerFilters(innerFilters => ({
      ...innerFilters,
      [key]: values,
    }));
  }, []);

  const onFilter = useCallback(() => getData(), [getData]);

  const onItemDataChange = useCallback(
    (key: string, itemData: ViewDashboardItemDataDto) => {
      setData({
        ...data,
        [key]: itemData,
      });
    },
    [data, setData],
  );

  const onDataChange = useCallback(() => getData(), [getData]);

  // eslint-disable-next-line
  useEffect(() => getData(), [props.outerFilters]);

  return (
    <ViewDashboardContext.Provider
      value={{
        data,
        isInnerLoading,
        innerFilters,
        onInnerFilterChange,
        onFilter,
        onItemDataChange,
        onDataChange,
      }}
    >
      <PageLoader loading={isGlobalLoading}>{!isEmpty(data) && props.children}</PageLoader>
    </ViewDashboardContext.Provider>
  );
};

export default ViewDashboard;
