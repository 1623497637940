import React, { useState } from 'react';
import './FooterMenuItem.scss';
import { NavLink } from 'react-router-dom';
import { NavItemLink } from '../nav/interfaces/NavItemLink';
import Accordion from '../accordion/Accordion';
import { ControlSize } from '../../enums/ControlSize';
export interface FooterMenuItemProps extends NavItemLink {
  items?: FooterMenuItemProps[];
}

const FooterMenuItem: React.FC<FooterMenuItemProps> = props => {
  const closeState = useState(true);
  return (
    <li className="FooterMenuItem">
      {props.items ? (
        <Accordion
          classNames={'Footer'}
          size={ControlSize.ExtraExtraSmall}
          closeState={closeState}
          title={
            <NavLink
              className="FooterMenuItem__link"
              to={props.link ? { pathname: props.link } : props.path}
              target={props.link ? '_blank' : ''}
            >
              {props.text}
            </NavLink>
          }
        >
          <ul className="FooterMenuItem__items">
            {props.items.map((item: FooterMenuItemProps, index: number) => (
              <li key={index} className="FooterMenuItem">
                {item.link ? (
                  <NavLink className="FooterMenuItem__link" to={{ pathname: item.link }} target="_blank">
                    {item.text}
                  </NavLink>
                ) : (
                  <NavLink className="FooterMenuItem__link" to={item.path}>
                    {item.text}
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        </Accordion>
      ) : (
        <NavLink className="FooterMenuItem__link" to={props.path}>
          {props.text}
        </NavLink>
      )}
      {/* <ul className="FooterMenuItem__items">
        {props.items &&
          props.items.map((item: FooterMenuItemProps, index: number) => <FooterMenuItem key={index} {...item} />)}
      </ul> */}
    </li>
  );
};

export default FooterMenuItem;
