import React from 'react';
import './AccordionSecondary.scss';
import Accordion, { AccordionProps } from './Accordion';
import classNames from 'classnames';

const AccordionSecondary: React.FC<AccordionProps> = props => {
  return (
    <Accordion {...props} classNames={classNames('AccordionSecondary', props.classNames)}>
      {props.children}
    </Accordion>
  );
};

export default AccordionSecondary;
