import React from 'react';
import Helmet from 'react-helmet';
import './Dashboard.scss';
import PrimarySection from '../shared/components/primary-section/PrimarySection';
import DashboardWidgets from './components/DashboardWidgets';
import DashboardEdit from './components/edit/DashboardEdit';
import { DashboardContext } from './DashboardContext';
import { useDashboardContextValue } from './hooks/useDashboardContextValue';
import { PrimarySectionIndent } from '../shared/components/primary-section/PrimarySectionIndent';

const Dashboard: React.FC = () => {
  return (
    <div className="Dashboard">
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <DashboardContext.Provider value={useDashboardContextValue()}>
        <PrimarySection indent={PrimarySectionIndent.Internal}>
          <DashboardWidgets />
          <DashboardEdit />
        </PrimarySection>
      </DashboardContext.Provider>
    </div>
  );
};

export default Dashboard;
