import { FilterControlSettings } from '../../../types/FilterControlSettings';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { ControlSize } from '../../../../../enums/ControlSize';
import { InputType } from '../../../../../components/forms/Input';
import { termsChangeOptions } from '../../options/termsChangeOptions';
import { dealInfoIpoPricedOptions } from '../../options/dealInfoIpoPricedOptions';
import { underwriterRoleOptions } from '../../options/underwriterRoleOptions';
import { AppendValueType } from '../../../../../components/forms/enums/AppendValueType';
import { PrependValueType } from '../../../../../components/forms/enums/PrependValueType';

export const dealInfoControls: FilterControlSettings = {
  [ViewFilterName._DealSize]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._DealSize,
    props: {
      label: 'Deal Size ($mm)',
      minName: ViewFilterName.DealSizeMin,
      maxName: ViewFilterName.DealSizeMax,
      minPrepend: [PrependValueType.Min],
      maxPrepend: [PrependValueType.Max],
      size: ControlSize.Small,
      width: ControlSize.SmallerExtraSmall,
      maxPlaceholder: '$',
      minPlaceholder: '$',
    },
  },
  [ViewFilterName.DealInfoPreIpoExchange]: {
    type: FormControlType.Select,
    name: ViewFilterName.DealInfoPreIpoExchange,
    props: {
      name: ViewFilterName.DealInfoPreIpoExchange,
      label: 'Pre-IPO Exchange (Cross/Up-Listing)',
      placeholder: 'Choose Pre-IPO Exchange',
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isMultiSelect: false,
    },
  },
  [ViewFilterName.Underwriter]: {
    type: FormControlType.Select,
    name: ViewFilterName.Underwriter,
    props: {
      name: ViewFilterName.Underwriter,
      label: 'Underwriter',
      placeholder: 'Choose Underwriter',
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isMultiSelect: false,
    },
  },
  [ViewFilterName.UnderwriterKeyList]: {
    type: FormControlType.Select,
    name: ViewFilterName.UnderwriterKeyList,
    props: {
      name: ViewFilterName.UnderwriterKeyList,
      label: 'Underwriter',
      placeholder: 'Choose Underwriter',
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isMultiSelect: true,
    },
  },
  [ViewFilterName.UnderwriterRole]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName.UnderwriterRole,
    props: {
      name: ViewFilterName.UnderwriterRole,
      label: 'Underwriter Role',
      options: underwriterRoleOptions,
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isUnselectAvailable: true,
    },
  },
  [ViewFilterName.UnderwriterTypeKey]: {
    type: FormControlType.Select,
    name: ViewFilterName.UnderwriterTypeKey,
    props: {
      name: ViewFilterName.UnderwriterTypeKey,
      label: 'Role',
      placeholder: 'Choose Role',
      size: ControlSize.Small,
      width: ControlSize.Medium,
    },
  },
  [ViewFilterName.InsiderBuying]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.InsiderBuying,
    props: {
      name: ViewFilterName.InsiderBuying,
      label: 'Insider Buying',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.InsiderSelling]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.InsiderSelling,
    props: {
      name: ViewFilterName.InsiderSelling,
      label: 'Insider Selling',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.InsiderSellingPct]: {
    type: FormControlType.Input,
    name: ViewFilterName.InsiderSellingPct,
    props: {
      name: ViewFilterName.InsiderSellingPct,
      label: 'Over',
      size: ControlSize.Small,
      append: [AppendValueType.Percentage],
      type: InputType.Number,
      width: ControlSize.Small,
    },
  },
  [ViewFilterName.IsCrossListing]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.IsCrossListing,
    props: {
      name: ViewFilterName.IsCrossListing,
      label: 'Cross Listing',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.IsDualListing]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.IsDualListing,
    props: {
      name: ViewFilterName.IsDualListing,
      label: 'Dual Listing',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.Postponed]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.Postponed,
    props: {
      name: ViewFilterName.Postponed,
      label: 'Postponed',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.Withdrawn]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.Withdrawn,
    props: {
      name: ViewFilterName.Withdrawn,
      label: 'Withdrawn',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.Acquired]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.Acquired,
    props: {
      name: ViewFilterName.Acquired,
      label: 'Acquired',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.IsUpListing]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.IsUpListing,
    props: {
      name: ViewFilterName.IsUpListing,
      label: 'Uplisting',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.TermsChange]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName.TermsChange,
    props: {
      name: ViewFilterName.TermsChange,
      label: 'Terms Change',
      options: termsChangeOptions,
      size: ControlSize.Small,
      isUnselectAvailable: true,
    },
  },
  [ViewFilterName.IPOPriceRange]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName.IPOPriceRange,
    props: {
      name: ViewFilterName.IPOPriceRange,
      label: 'IPO Priced',
      options: dealInfoIpoPricedOptions,
      size: ControlSize.Small,
      isUnselectAvailable: true,
    },
  },
  [ViewFilterName._IPOPricedRange]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._IPOPricedRange,
    props: {
      label: 'IPO Priced vs. Midpoint',
      minName: ViewFilterName.IPOPricedMin,
      maxName: ViewFilterName.IPOPricedMax,
      minPrepend: [PrependValueType.BelowMidpoint],
      minAppend: [AppendValueType.Percentage],
      maxPrepend: [PrependValueType.AboveMidpoint],
      maxAppend: [AppendValueType.Percentage],
      size: ControlSize.Small,
      width: ControlSize.ExtraSmall,
    },
  },
  [ViewFilterName._DealSizeVsExpected]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._DealSizeVsExpected,
    props: {
      label: 'Deal Size vs. Expected',
      minName: ViewFilterName.DealSizeVsExpectedMin,
      maxName: ViewFilterName.DealSizeVsExpectedMax,
      minPrepend: [PrependValueType.Lower],
      minAppend: [AppendValueType.Percentage],
      maxPrepend: [PrependValueType.Higher],
      maxAppend: [AppendValueType.Percentage],
      size: ControlSize.Small,
      width: ControlSize.Small,
    },
  },
  [ViewFilterName._DealSizeMarketCapVsExpected]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._DealSizeMarketCapVsExpected,
    props: {
      label: 'Market Cap vs. Expected',
      minName: ViewFilterName.DealSizeMarketCapVsExpectedMin,
      maxName: ViewFilterName.DealSizeMarketCapVsExpectedMax,
      minPrepend: [PrependValueType.Lower],
      minAppend: [AppendValueType.Percentage],
      maxPrepend: [PrependValueType.Higher],
      maxAppend: [AppendValueType.Percentage],
      size: ControlSize.Small,
      width: ControlSize.Small,
    },
  },
  [ViewFilterName._FloatToMarketCap]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._FloatToMarketCap,
    props: {
      label: 'Float to Market Cap',
      minName: ViewFilterName.FloatToMarketCapMin,
      maxName: ViewFilterName.FloatToMarketCapMax,
      minPrepend: [PrependValueType.Min],
      maxPrepend: [PrependValueType.Max],
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.DealInfoDualClassSharesOnly]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.DealInfoDualClassSharesOnly,
    props: {
      name: ViewFilterName.DealInfoDualClassSharesOnly,
      label: 'Dual Class Shares Only',
      size: ControlSize.Small,
    },
  },
};
