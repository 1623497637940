import { cloneDeep } from 'lodash';
import { FiltersMapValue } from '../../../../../shared/entities/filters/components/filterable-component/interfaces/FiltersMapValue';
import { companyInfoControls } from '../../../../../shared/entities/filters/constants/filter-controls/view-filter-sections/companyInfoControls';
import { ViewFilterName } from '../../../../../shared/entities/filters/enum/types/ViewFilterName';
import { dealInfoControls } from '../../../../../shared/entities/filters/constants/filter-controls/view-filter-sections/dealInfoControls';
import { ControlSize } from '../../../../../shared/enums/ControlSize';
import { FilterSettingsService } from '../../../../../shared/entities/filters/services/FilterSettingsService';

export const statsPageFiltersControls: FiltersMapValue[] = cloneDeep([
  FilterSettingsService.mergeControlSettings(companyInfoControls[ViewFilterName.SectorIndustry] as FiltersMapValue, {
    props: {
      width: ControlSize.ExtraLarge,
    },
  }),
  FilterSettingsService.mergeControlSettings(dealInfoControls[ViewFilterName._DealSize] as FiltersMapValue, {
    props: {
      width: ControlSize.ExtraLarge,
    },
  }),
  FilterSettingsService.mergeControlSettings(dealInfoControls[ViewFilterName.UnderwriterKeyList] as FiltersMapValue, {
    props: {
      width: ControlSize.ExtraLarge,
      isMultiSelect: true,
    },
  }),
]);
