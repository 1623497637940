import React from 'react';
import Divider from '../Divider';
import FooterLogo from './FooterLogo';
import FooterMenu from './FooterMenu';
import FooterFollowUs from './FooterFollowUs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import './Footer.scss';
import { IPO_INTELLIGENCE_MARKETING_SITE_DISCLOSURES_URL } from '../../constants/urls';
const Footer: React.FC = () => {
  const isAuthorized = useSelector<RootState, boolean>(state => state.auth.isAuthorized);
  const today = new Date();

  return (
    <footer className="Footer">
      <div className="Footer__header">
        <FooterLogo />

        <FooterFollowUs />
      </div>

      {isAuthorized && (
        <>
          <Divider />
          <FooterMenu />
        </>
      )}

      <Divider />

      <div className="Footer__text">
        Renaissance Capital LLC is an SEC-registered investment adviser.
        <br />{' '}
        <a
          className="FooterFollowUs__Disclosurelink"
          href={IPO_INTELLIGENCE_MARKETING_SITE_DISCLOSURES_URL}
          rel={'canonical'}
        >
          Important Disclosures
        </a>
        <br />
        &#169; {today.getFullYear()} Renaissance Capital LLC. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
