import { TextSelectElementType } from '../enums/TextSelectElementType';
import { ClassNameFn } from '../../../../../interfaces/ClassNameFn';
import { TextSelectElement } from '../TextSelectBem';

export class TextSelectElementClassNameFactory {
  static getElementClassName(type: TextSelectElementType, cnFn: ClassNameFn) {
    switch (type) {
      case TextSelectElementType.Text:
        return cnFn(TextSelectElement.TextButton);
      case TextSelectElementType.Button:
        return cnFn(TextSelectElement.Button);
    }
  }
}
