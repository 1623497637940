import React, { useMemo } from 'react';
import './FilterSectionsLayout.scss';
import FilterSectionsList from './FilterSectionsList';
import { FilterSectionsFormState } from '../interfaces/FilterSectionsFormState';
import useBreakpoint from '../../../../../effects/useBreakpoint';
import { FilterSectionsLayoutFactory } from '../factories/FilterSectionsLayoutFactory';
import { useClassName } from '../../../../../hooks/useClassName';
import { FilterSectionsLayoutBlock } from './filterSectionsLayoutBem';
import { useFilterSectionsDependencies } from '../../../hooks/useFilterSectionsDependencies';

interface Props {
  sectionsStates: FilterSectionsFormState[];
}

const FilterSectionsLayout: React.FC<Props> = props => {
  const cn = useClassName(FilterSectionsLayoutBlock.Root);

  const breakpoint = useBreakpoint();

  useFilterSectionsDependencies();

  const options = useMemo(() => FilterSectionsLayoutFactory.getOptions(breakpoint, props.sectionsStates), [
    breakpoint,
    props.sectionsStates,
  ]);

  return (
    <section className={cn()}>
      {options.chunks.map((chunk, index) => (
        <FilterSectionsList key={index} sectionsStates={chunk} style={options.style} />
      ))}
    </section>
  );
};

export default FilterSectionsLayout;
