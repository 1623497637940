import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react';
import './DateRangeSelect.scss';
import DateSelect from './DateSelect';
import { DateSelectValue } from './types/DateSelectValue';
import { DateRangeControl } from './enums/DateRangeControl';
import { OnChangeDateRangeFn } from './types/OnChangeDateRangeFn';
import { DatePickerPosition } from './enums/DatePickerPosition';
import FormControlsList, { FormControlsListProps } from '../../FormControlsList';
import { DateRangeSelectBlock, DateRangeSelectElement } from './DateRangeSelectBem';
import { ControlSize } from '../../../../enums/ControlSize';
import { useClassName } from '../../../../hooks/useClassName';
import { DateSelectRestrictionValue } from './types/DateSelectRestrictionValue';
import { DateHelper } from '../../../../helpers/date/DateHelper';
import { DateFormat } from '../../../../helpers/date/enums/DateFormat';
import { DateSelectService } from './services/DateSelectService';

export interface DateRangeSelectProps extends FormControlsListProps {
  minName?: string;
  maxName?: string;
  minValue: DateSelectValue;
  maxValue: DateSelectValue;
  minPlaceholder?: string;
  maxPlaceholder?: string;
  datePickersPosition?: DatePickerPosition;
  label?: string | ReactNode;
  isDisabled?: boolean;
  className?: string;
  size?: ControlSize;
  minDateRestrictionValue?: DateSelectRestrictionValue;
  maxDateRestrictionValue?: DateSelectRestrictionValue;
  onChange: OnChangeDateRangeFn;
}

const DateRangeSelect: React.FC<DateRangeSelectProps> = props => {
  const [startUndefined, setStartUndefined] = useState(false);
  const [endUndefined, setEndUndefined] = useState(false);

  const cn = useClassName(DateRangeSelectBlock.Root, props.className);
  const { minPlaceholder = 'Min Date', maxPlaceholder = 'Max Date', minValue, maxValue, onChange } = props;

  const startMaxDate = useMemo(
    () => DateSelectService.getValueByRestriction(props.maxValue, props.maxDateRestrictionValue),
    [props.maxValue, props.maxDateRestrictionValue],
  );

  const endMinDate = useMemo(
    () => DateSelectService.getValueByRestriction(props.minValue, props.minDateRestrictionValue),
    [props.minValue, props.minDateRestrictionValue],
  );

  const onChangeValue = useCallback(
    (control: DateRangeControl, value: DateSelectValue) => {
      const today = DateHelper.formatDate(DateHelper.getToday(), DateFormat.YearMonthDayISO);
      const isTodayAfter = DateHelper.isAfter(today, value);

      let newMinValue = minValue;
      let newMaxValue = maxValue;
      if (control === DateRangeControl.Min) {
        if (value === undefined) {
          setStartUndefined(true);
          return;
        }
        setStartUndefined(false);
        if ((!maxValue || endUndefined) && value) {
          if (isTodayAfter) {
            newMaxValue = today;
          } else {
            newMaxValue = value;
          }
          setEndUndefined(false);
        }

        newMinValue = value;
      }

      if (control === DateRangeControl.Max) {
        if (value === undefined) {
          setEndUndefined(true);
          return;
        }
        setEndUndefined(false);
        if ((!minValue || startUndefined) && value) {
          newMinValue = undefined;
        }
        newMaxValue = value;
      }

      onChange(newMinValue, newMaxValue);
    },
    [minValue, maxValue, onChange, endUndefined, startUndefined],
  );

  return (
    <FormControlsList className={cn()} isInline={props.isInline}>
      {props.label && <label className={cn(DateRangeSelectElement.Label)}>{props.label}</label>}

      <div className={cn(DateRangeSelectElement.Controls)}>
        <DateSelect
          value={startUndefined ? undefined : props.minValue}
          isDisabled={props.isDisabled}
          datePickerPosition={props.datePickersPosition}
          placeholder={minPlaceholder}
          size={props.size}
          minDateRestrictionValue={props.minDateRestrictionValue}
          maxDateRestrictionValue={endUndefined ? undefined : startMaxDate}
          onChangeValue={(value: DateSelectValue): void => onChangeValue(DateRangeControl.Min, value)}
        />

        <DateSelect
          value={endUndefined ? undefined : props.maxValue}
          isDisabled={props.isDisabled}
          datePickerPosition={props.datePickersPosition}
          placeholder={maxPlaceholder}
          size={props.size}
          minDateRestrictionValue={startUndefined ? undefined : endMinDate}
          maxDateRestrictionValue={props.maxDateRestrictionValue}
          onChangeValue={(value: DateSelectValue): void => onChangeValue(DateRangeControl.Max, value)}
        />
      </div>
    </FormControlsList>
  );
};

export default memo(DateRangeSelect);
