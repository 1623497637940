import React, { useCallback, useMemo } from 'react';
import { NewsLandingItemDto } from '../../../interfaces/NewsLandingItemDto';
import Form from '../../../../shared/components/forms/Form';
import FormGroup from '../../../../shared/components/forms/FormGroup';
import FormTextSelect from '../../../../shared/components/forms/FormTextSelect';
import { newsRcExternalFilterOptions } from './constants/newsRcExternalFilterOptions';
import { NewsRcExternalFilterField } from './form/NewsRcExternalFilterField';
import { ControlSize } from '../../../../shared/enums/ControlSize';
import { NewsRcExternalFilterService } from './services/NewsRcExternalFilterService';
import { NewsRcExternalFilterType } from './enums/NewsRcExternalFilterType';
import { ControlDivider } from '../../../../shared/components/forms/services/control-divider/enums/ControlDivider';

interface Props {
  className?: string;
  news: NewsLandingItemDto[];
  onFilter: (filteredRows: NewsLandingItemDto[]) => void;
}

const NewsRcExternalFilter: React.FC<Props> = props => {
  const { onFilter } = props;

  const defaultValues = useMemo(
    () => ({
      [NewsRcExternalFilterField.Type]: NewsRcExternalFilterType.All,
    }),
    [],
  );

  const onChange = useCallback(
    formData => {
      const type = formData[NewsRcExternalFilterField.Type] as NewsRcExternalFilterType;

      onFilter(NewsRcExternalFilterService.filterByType(type, props.news));
    },
    [props.news, onFilter],
  );

  return (
    <Form className={props.className} defaultValues={defaultValues} changeOnInit onChange={onChange}>
      <FormGroup>
        <FormTextSelect
          name={NewsRcExternalFilterField.Type}
          options={newsRcExternalFilterOptions}
          divider={ControlDivider.Slash}
          size={ControlSize.Small}
        />
      </FormGroup>
    </Form>
  );
};

export default NewsRcExternalFilter;
