import React, { useCallback } from 'react';
import FormDateRangeSelect from '../../shared/components/forms/components/date/FormDateRangeSelect';
import { ControlSize } from '../../shared/enums/ControlSize';
import { NewsFilterData } from '../interfaces/NewsFilterData';
import { NewsFilterField } from '../enums/NewsFilterField';
import LocationService from '../../shared/services/LocationService';
import { RoutePath } from '../../shared/enums/RoutePath';
import { LocationSearchData } from '../../shared/interfaces/LocationSearchData';
import FormSelect, { FormSelectProps } from '../../shared/components/forms/components/select/FormSelect';
import { useNewsFilterConfig } from '../hooks/useNewsFilterConfig';
import { DatePickerRestrictionDateType } from '../../shared/components/forms/components/date/enums/DatePickerRestrictionDateType';
import { newsLocationOptions } from '../constants/newsLocationOptions';
import FormSearch from '../../shared/components/forms/FormSearch';
import FormGroup from '../../shared/components/forms/FormGroup';
import { newsFilterControls } from '../../shared/entities/filters/constants/filter-controls/newsFilterControls';

interface Props {
  defaultValues?: NewsFilterData;
}

const NewsFilter: React.FC<Props> = props => {
  const [filters] = useNewsFilterConfig();

  const newsTypeControlProps = newsFilterControls[NewsFilterField.NewsType]?.props as FormSelectProps;
  const storyTypeControl = newsFilterControls[NewsFilterField.StoryType]?.props as FormSelectProps;

  const onSubmit = useCallback((data: NewsFilterData) => {
    LocationService.redirect(RoutePath.NewsSearch, data as LocationSearchData, true);
  }, []);

  return (
    <FormSearch className="NewsFilter" onSubmit={onSubmit} defaultValues={props.defaultValues}>
      <FormGroup>
        {/*
          Original code for selection of one filter in the location category on News page
          <FormTextSelect name={NewsFilterField.Location} options={newsLocationOptions} />
        */}
        <FormSelect
          name={NewsFilterField.Location}
          placeholder="Location"
          options={newsLocationOptions}
          size={ControlSize.Small}
          width={ControlSize.MediumSmall}
          isMultiSelect
        />
      </FormGroup>

      <FormGroup>
        <FormDateRangeSelect
          minName={NewsFilterField.StartDate}
          maxName={NewsFilterField.EndDate}
          minPlaceholder="From"
          maxPlaceholder="To"
          label="Date"
          size={ControlSize.Small}
          maxDateRestrictionValue={DatePickerRestrictionDateType.Today}
          isInline
        />
      </FormGroup>

      <FormGroup>
        <FormSelect
          name={NewsFilterField.SectorIndustry}
          placeholder="Sector/Industry"
          options={filters.sectorIndustry}
          size={ControlSize.Small}
          width={ControlSize.MediumSmall}
          isMultiSelect
        />
      </FormGroup>

      <FormGroup>
        <FormSelect
          name={newsTypeControlProps.name}
          placeholder="News Type"
          options={newsTypeControlProps.options}
          size={ControlSize.Small}
          width={ControlSize.MediumSmall}
          isMultiSelect
        />
      </FormGroup>

      <FormGroup>
        <FormSelect
          name={storyTypeControl.name}
          placeholder="Story Type"
          options={storyTypeControl.options}
          size={ControlSize.Small}
          width={ControlSize.MediumSmall}
          isMultiSelect
        />
      </FormGroup>
    </FormSearch>
  );
};

export default NewsFilter;
