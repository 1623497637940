import React, { useContext, useMemo } from 'react';
import CompanyInvestmentRationaleTerms from './CompanyInvestmentRationaleTerms';
import CompanyInvestmentRationaleDescription from './CompanyInvestmentRationaleDescription';
import CompanyProfileInsights from '../profile/shared/components/insights/CompanyProfileInsights';
import { CompanyContext } from '../context/CompanyContext';
import CompanySectionRow from '../shared/CompanySectionRow';
import CompanySection from '../shared/CompanySection';
import { CompanyTab } from '../../enums/CompanyTab';
import { InvestmentRationaleDto } from '../../interfaces/InvestmentRationaleDto';
import { Nullable } from '../../../shared/types/generics';

const CompanyInvestmentRationale: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const data: Nullable<InvestmentRationaleDto> = getTabData(CompanyTab.InvestmentRationale);

  const titleProps = useMemo(() => {
    let ratingValue;
    if (data?.analysisRating) {
      ratingValue = data?.analysisRating;
    } else if (data?.ratings.sum) {
      ratingValue = data?.ratings.sum;
    }

    const ratingVisibleValue = data?.analysisRating;

    const additionalInfo = data?.ratings.sum ? data?.analysisRating : undefined;

    return {
      tab: CompanyTab.InvestmentRationale,
      ratingTitle: 'Rating Analysis',
      ratingValue,
      ratingVisibleValue,
      additionalInfo,
    };
  }, [data]);

  return (
    <>
      {data && <h3 className="printHead">Investment Rationale</h3>}
      <CompanySection className="CompanyInvestmentRationale" data={data} titleProps={titleProps}>
        <CompanyInvestmentRationaleTerms />
        <CompanySectionRow>
          <CompanyInvestmentRationaleDescription />
        </CompanySectionRow>
        <CompanySectionRow>{data && <CompanyProfileInsights {...data.bullBear} />}</CompanySectionRow>
      </CompanySection>
    </>
  );
};

export default CompanyInvestmentRationale;
