import { Thunk } from 'react-hook-thunk-reducer';
import { ActionTypes, SET_VIEW_IS_SOCKET_WORK, SetViewIsSocketWork, ViewState } from '../types';
import { GridPublicApi } from '../../../../../../components/grid/grid/interfaces/GridPublicApi';
import { SocketEvent } from '../../socket/enums/SocketEvent';
import { SocketOnUpdateData } from '../../socket/types/SocketOnUpdateData';
import { ViewTodaysPriceChangeService } from '../../services/ViewTodaysPriceChangeService';

export const setIsSocketWork = (isSocketWork: boolean): SetViewIsSocketWork => {
  return {
    type: SET_VIEW_IS_SOCKET_WORK,
    payload: {
      isSocketWork,
    },
  };
};

export const initSocketEvents = (gridPublicApi: GridPublicApi): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state: ViewState = getState();
    const socket = state.socket;
    socket.subscribeEvents({
      [SocketEvent.TodaysPriceChangeUpdate]: (data: SocketOnUpdateData) =>
        ViewTodaysPriceChangeService.update(gridPublicApi, data),
    });
  };
};

export const startSocket = (): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state: ViewState = getState();

    try {
      await state.socket.start();
      dispatch(setIsSocketWork(true));
    } catch (e) {
      // TODO: handle error when no connection
      console.log(e);
    }
  };
};

export const stopSocket = (): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state: ViewState = getState();

    try {
      await state.socket.stop();
    } catch (e) {
      // TODO: handle error when no stop error
      console.log(e);
    }
  };
};
