import React, { ReactNode, useMemo } from 'react';
import { FieldError, FormContextValues, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import uniqueId from 'lodash.uniqueid';
import Label from './Label';
import FormError from './FormError';

export interface TextareaProps extends Partial<FormContextValues> {
  name: string;
  label?: string | ReactNode;
  placeholder?: string;
  autoComplete?: string;
  isDisabled?: boolean;
}

const Textarea: React.FC<TextareaProps> = props => {
  const { register, errors } = useFormContext();
  const error: FieldError | undefined = errors[props.name] as FieldError;
  const id = useMemo(() => uniqueId('Textarea-'), []);

  return (
    <>
      {props.label && (
        <Label error={error} htmlFor={id}>
          {props.label}
        </Label>
      )}

      <textarea
        className={classNames('form-control', {
          'is-invalid': error,
        })}
        autoComplete={props.autoComplete}
        name={props.name}
        placeholder={props.placeholder}
        ref={register}
        id={id}
        disabled={props.isDisabled}
      />

      <FormError name={props.name} />
    </>
  );
};

export default Textarea;
