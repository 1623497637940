import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import './FilterSection.scss';
import { FilterSectionType } from '../../../enum/FilterSectionType';
import AccordionPrimary from '../../../../../components/accordion/AccordionPrimary';
import { FilterSectionsFormContext } from '../context/FilterSectionsFormContext';
import { FilterSectionsStateService } from '../services/FilterSectionsStateService';
import { FilterSectionsFormState } from '../interfaces/FilterSectionsFormState';
import FilterSectionTitle from './FilterSectionTitle';

export interface SectionProps {
  type: FilterSectionType;
  sectionState: FilterSectionsFormState;
}

const FilterSection: React.FC<SectionProps> = props => {
  const { sectionsBreakpoints, settings, onCloseChange } = useContext(FilterSectionsFormContext);
  const controlsComponents = useMemo(() => FilterSectionsStateService.getSectionControlsComponentsByType(props.type), [
    props.type,
  ]);

  const closeState = useState(props.sectionState.isClose);
  const [close, setClose] = closeState;

  useEffect(() => onCloseChange([props.type], close), [props.type, close, onCloseChange]);
  useEffect(() => setClose(props.sectionState.isClose), [setClose, props.sectionState.isClose]);

  return (
    <AccordionPrimary
      classNames="FilterSection"
      title={
        <FilterSectionTitle
          type={props.type}
          activeControlsCount={props.sectionState.activeControlsCount}
          hasActiveControlsCount={settings.hasActiveControlsCount}
        />
      }
      breakpoints={sectionsBreakpoints}
      closeState={closeState}
    >
      <ul className="FilterSection__list">{controlsComponents}</ul>
    </AccordionPrimary>
  );
};

export default memo(FilterSection);
