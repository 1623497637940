import React from 'react';
import { masterFilterFormSettings } from './constants/masterFilterFormSettings';
import PrimarySection from '../shared/components/primary-section/PrimarySection';
import { ViewFilterName } from '../shared/entities/filters/enum/types/ViewFilterName';
import FilterSections from '../shared/entities/filters/components/filter-sections/FilterSections';

const MasterFilterForm: React.FC = () => {
  return (
    <PrimarySection>
      <FilterSections
        settings={{
          sections: masterFilterFormSettings,
        }}
        defaultValues={{
          [ViewFilterName.InsiderSelling]: true,
        }}
      />
    </PrimarySection>
  );
};

export default MasterFilterForm;
