import React, { useContext, useMemo } from 'react';
import DilutedSharesTitleWrapper from './DilutedSharesTitleWrapper';
import { ControlSize } from '../../../../../../shared/enums/ControlSize';
import TooltipDilutedShares from '../../../../../../shared/components/tooltip/TooltipDilutedShares';
import DilutedSharesTooltipContent from './DilutedSharesTooltipContent';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import { TooltipTriggerType } from '../../../../../../shared/components/tooltip/enums/TooltipTriggerType';

export interface CompanyTooltipProps {
  dataValue: string;
}

const DilutedSharesToolTip: React.FC<CompanyTooltipProps> = props => {
  const { getTabData } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const data = useMemo(() => profile.dilutedShares, [profile]);

  return (
    <>
      <DilutedSharesTitleWrapper>
        <TooltipDilutedShares
          title={props.dataValue}
          component={DilutedSharesTooltipContent}
          size={ControlSize.MediumLarge}
          hasIndentHorizontal={false}
          getData={data}
          triggerType={TooltipTriggerType.Click}
        />
      </DilutedSharesTitleWrapper>
    </>
  );
};

export default DilutedSharesToolTip;
