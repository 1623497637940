import { ViewColumnSystemName } from '../../view/types/ViewColumnSystemName';
import { ViewColumnPrefix } from '../enums/ViewColumnPrefix';
import { ViewGridColumnDto } from '../interfaces/ViewGridColumnDto';
import { ViewGridColumnGroupDto } from '../interfaces/ViewGridColumnGroupDto';

export class ViewGridColumnService {
  static getVisibleColumnName(columnSystemName: ViewColumnSystemName): string {
    return `${columnSystemName}${ViewColumnPrefix.Visible}`;
  }

  static getColumnByName(
    columnsGroups: ViewGridColumnGroupDto[],
    name: ViewColumnSystemName,
  ): ViewGridColumnDto | undefined {
    return this.getColumns(columnsGroups).find(column => column.name === name);
  }

  static hasColumnByName(columnsGroups: ViewGridColumnGroupDto[], name: ViewColumnSystemName): boolean {
    return !!this.getColumnByName(columnsGroups, name);
  }

  static getColumns(columnsGroups: ViewGridColumnGroupDto[]): ViewGridColumnDto[] {
    return columnsGroups.reduce(
      (columns: ViewGridColumnDto[], columnGroup: ViewGridColumnGroupDto) => columns.concat(columnGroup.nestedColumns),
      [],
    );
  }

  static getNestedColumnsTitles(columnsGroup: ViewGridColumnGroupDto): string[] {
    return columnsGroup.nestedColumns.map(column => column.title);
  }

  static getColumnsNames(columnsGroups: ViewGridColumnGroupDto[]): ViewColumnSystemName[] {
    return this.getColumns(columnsGroups).map(column => column.name);
  }
}
