import React from 'react';
import Helmet from 'react-helmet';
import PasswordForm from './form/PasswordForm';
import './Password.scss';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import PrimarySectionRow from '../../shared/components/primary-section/PrimarySectionRow';
import { AlignTypesHorizontal } from '../../shared/enums/AlignTypesHorizontal';

const Password: React.FC = () => {
  return (
    <PrimarySection header="Recover Your Password">
      <div className="Password">
        <Helmet>
          <title>Recover Your Password &mdash; Renaissance Capital</title>
        </Helmet>

        <PrimarySectionRow className="Password__description" alignHorizontal={AlignTypesHorizontal.Center}>
          Enter your email address and we will send you an email containing a temporary password.
        </PrimarySectionRow>

        <PrimarySectionRow>
          <PasswordForm />
        </PrimarySectionRow>
      </div>
    </PrimarySection>
  );
};

export default Password;
