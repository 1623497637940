import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { ViewColumnSystemName } from '../../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { ViewOptionsService } from '../../../../../shared/entities/view/components/view/services/ViewOptionsService';
import { screensIndustrySharedOptions } from './screensIndustrySharedOptions';
import { ViewFilterName } from '../../../../../shared/entities/filters/enum/types/ViewFilterName';

export const screensIndustryLandingOptions: Partial<ScreenOptions> = ViewOptionsService.merge<ScreenOptions>(
  screensIndustrySharedOptions,
  {
    grid: {
      title: 'ALL INDUSTRY ACTIVITY',
      idCols: [ViewColumnSystemName.SectorIndustry],
    },
    screenTriggerOptions: {
      columnName: ViewColumnSystemName.SectorIndustry,
      filterName: ViewFilterName.Industry,
      filterValueColumnName: ViewColumnSystemName.IndustryKey,
    },
  },
);
