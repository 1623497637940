import React, { useMemo } from 'react';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import { FileActionProp } from '../../../../../../components/file-actions/interfaces/FileActionProp';
import { FileActionType } from '../../../../../../components/file-actions/enums/FileActionType';
import FileActions from '../../../../../../components/file-actions/FileActions';

type Props = GridCellFormatterCommonProps;

const ResearchTypeWidgetCell: React.FC<Props> = props => {
  const { value, data } = props;

  const url = data.reportLink;

  const fileActions = useMemo<FileActionProp[]>(
    () => [
      {
        type: FileActionType.Pdf,
        title: value,
        isIconVisible: false,
        externalDownloadOptions: {
          url,
        },
      },
    ],
    [value, url],
  );

  return (
    <CustomCellFormatterWrapper value={value}>
      <FileActions fileActions={fileActions} />
    </CustomCellFormatterWrapper>
  );
};

export default ResearchTypeWidgetCell;
