import { StatsElementOptions } from '../../../../shared/entities/stats-element/interfaces/StatsElementOptions';
import { ChartType } from '../../../../shared/components/chart/enums/ChartType';
import { NumberHelper } from '../../../../shared/helpers/NumberHelper';
import { ChartTooltipFormatterService } from '../../../../shared/components/chart/services/ChartTooltipFormatterService';
import { CustomAny } from '../../../../shared/types/generics';
import { StatsElementChartProps } from '../../../../shared/entities/stats-element/components/chart/interfaces/StatsElementChartProps';
import { getStatsElementSimpleGridColumnYearToViewMapper } from '../../../../shared/entities/stats-element/components/simple-grid/constants/statsElementSimpleGridColumnMappers';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { ViewFilterName } from '../../../../shared/entities/filters/enum/types/ViewFilterName';
import { NumberFormat } from '../../../../shared/enums/NumberFormat';
import { StatsFilterName } from '../../enum/StatsFilterName';

const PROCEEDS_GRID_INDEX = 0;

export const proceedsChartOptions: Partial<StatsElementChartProps> = {
  type: ChartType.Base,
  title: '',
  optionsList: [
    {
      chart: {
        type: 'column',
      },
      yAxis: {
        title: {
          text: 'Proceeds in Billions (US$)',
        },
        labels: {
          formatter() {
            return NumberHelper.getFormatValue(this.value as number, NumberFormat.CurrencyFloatWithLetter);
          },
        },
        stackLabels: {
          enabled: false,
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            formatter() {
              return NumberHelper.getFormatValue(this.y as number, NumberFormat.CurrencyFloatWithLetter);
            },
            enabled: true,
          },
        },
        column: {
          grouping: false,
        },
      },
      tooltip: {
        formatter() {
          return ChartTooltipFormatterService.getTooltip({
            points: this.points as CustomAny[],
            title: `${this.x}`,
            formatter: value => NumberHelper.getFormatValue(value as number, NumberFormat.CurrencyFloatWithLetter),
          });
        },
      },
    },
  ],
};

export const proceedsStatsElementOptions: StatsElementOptions = {
  chartsOptions: [proceedsChartOptions],
  gridOptions: {
    [PROCEEDS_GRID_INDEX]: {
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(context => ({
        viewType: ViewType.Priced,
        startDateFilterName: ViewFilterName.OfferStartDate,
        endDateFilterName: ViewFilterName.OfferEndDate,
        optionsList: [
          {
            getFilterName: ViewFilterName.SecurityTypeBlankCheckList,
            getFilterValue: () => (context?.pageFilters?.[StatsFilterName.IncludeSPACs] ? 'include' : ''),
          },
        ],
      })),
      isHighlightLastRow: true,
      isStickySecondColumn: true,
    },
  },
};
