import { max } from 'lodash';
import { AnimationDuration } from '../enums/AnimationDuration';

export class PrintService {
  static print(animationsDurations?: AnimationDuration[]): void {
    let delay = 0;

    if (animationsDurations) {
      // Add one second to be sure all rendered
      // Not ideal solution for now
      delay = 1000 + (max(animationsDurations) || 0);
    }

    setTimeout(() => window?.print?.(), delay);
  }
}
