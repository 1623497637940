import { socketAvailableTimeFrames } from './constants/socketRoutePath';
import { ViewState } from '../store/types';

export const shouldInitSocketEvents = (state: ViewState): boolean => {
  return state?.socket?.eventNamesLength === 0 && state?.isSocketWork && state?.socket?.isConnected;
};

export const isDateExceedingLimit = (state: ViewState): boolean => {
  const curTimeFrame = state?.viewData?.appliedFilters?.timeframe;
  const startDate = state?.viewData?.appliedFilters?.startDate;
  const endDate = state?.viewData?.appliedFilters?.endDate;
  let isDateExceedingLimitFlag = true;

  if (curTimeFrame) {
    isDateExceedingLimitFlag = !socketAvailableTimeFrames.includes(curTimeFrame);
  } else if (startDate && endDate) {
    const diffOffDatesInNumberOfDays = Math.floor((Date.parse(endDate) - Date.parse(startDate)) / 86400000);
    if (diffOffDatesInNumberOfDays > 365) {
      isDateExceedingLimitFlag = true;
    } else {
      isDateExceedingLimitFlag = false;
    }
  }
  return isDateExceedingLimitFlag;
};
