import React, { useContext } from 'react';
import './ViewFootnote.scss';
import { ViewContext } from '../../ViewContext';

const ViewFootnote: React.FC = () => {
  const { state } = useContext(ViewContext);
  const footnote = state.footnote;

  return <div className="ViewFootnote">Notes: {footnote}</div>;
};

export default ViewFootnote;
