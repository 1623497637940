import PrimarySectionRow from '../../../../../shared/components/primary-section/PrimarySectionRow';
import React from 'react';
import StatsElement from '../../../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../../../shared/entities/stats-element/enums/StatsElementType';
import { ipoReturnsStatsElementOptions } from './constants/ipoReturnsStatsElementOptions';
import { useStatsTitle } from '../../../hooks/useStatsTitle';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../../../../shared/enums/RoutePath';

const StatsIpoReturns: React.FC = () => {
  return (
    <PrimarySectionRow header={<Link to={RoutePath.StatsHistoricalIPOReturns}>{useStatsTitle('IPO Returns')}</Link>}>
      <StatsElement type={StatsElementType.DashboardIpoReturns} options={ipoReturnsStatsElementOptions} />
    </PrimarySectionRow>
  );
};

export default StatsIpoReturns;
