import React, { useContext, useMemo } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import CompanyProfileSideItem from '../../profile/shared/components/CompanyProfileSideItem';
import CompanyProfileTable from '../../profile/shared/components/company-profile-table/CompanyProfileTable';
import { Link } from 'react-router-dom';
import { CompanyTabsPath } from '../../../enums/CompanyTabsPath';
import { ProfileData } from '../../../interfaces/ProfileData';
import { CompanyTab } from '../../../enums/CompanyTab';
import { CompanyLocationService } from '../../../services/CompanyLocationService';
import PageLoader from '../../../../shared/components/loader/PageLoader';

const CompanyFundamentalComparisonTable: React.FC = () => {
  const { getTabData, ticker, fundamentalComparisonData } = useContext(CompanyContext);

  // const profile: ProfileData = getTabData(CompanyTab.Profile);
  // const data: SimpleGridProps = useMemo(() => profile.fundamentalComparison as SimpleGridProps, [profile]);

  const linkPath = useMemo(() => CompanyLocationService.getRelativeTabPath(CompanyTabsPath.RelativeValuation, ticker), [
    ticker,
  ]);

  const profile: ProfileData = getTabData(CompanyTab.Profile);
  const data = useMemo(() => profile.description, [profile]);
  var title = 'Fundamental Comparison';
  if (data?.isPreliminaryComps) {
    title = 'Fundamental Comparison (Preliminary)';
  }

  return (
    <>
      <PageLoader
        loading={
          !!!fundamentalComparisonData ||
          fundamentalComparisonData?.rows[0]?.ticker?.toUpperCase() !== ticker?.toUpperCase()
        }
      >
        {fundamentalComparisonData && (
          <CompanyProfileSideItem title={<Link to={linkPath}>{title}</Link>}>
            <CompanyProfileTable
              columns={fundamentalComparisonData.columns}
              rows={fundamentalComparisonData.rows?.slice(0, 3)}
              expandedData={fundamentalComparisonData.rows?.slice(0, 3)}
              isFullWidth
              isColumnsAlignEnd
            />
          </CompanyProfileSideItem>
        )}
      </PageLoader>
    </>
  );
};

export default CompanyFundamentalComparisonTable;
