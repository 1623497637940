import React from 'react';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { screensUnderwriterInUseOptions } from './constants/screensUnderwriterInUseOptions';
import ScreensInUse from '../../shared/pages/components/in-use/ScreensInUse';

const ScreensUnderwriterInUse: React.FC = () => {
  return <ScreensInUse inUseType={ViewType.UnderwriterInUse} options={screensUnderwriterInUseOptions} />;
};

export default ScreensUnderwriterInUse;
