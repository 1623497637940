import React from 'react';
import './RotatedGridRow.scss';
import { GridCellValue } from '../grid/shared/types/GridCellValue';
import { ControlSize } from '../../enums/ControlSize';
import { useClassName } from '../../hooks/useClassName';

export interface RotatedGridRowProps {
  title?: string;
  data?: GridCellValue;
  isEmpty?: boolean;
  size?: ControlSize;
  hasNoTitleMaxWidth?: boolean;
  hasTitleNoWrap?: boolean;
}

const RotatedGridRow: React.FC<RotatedGridRowProps> = props => {
  const { hasNoTitleMaxWidth, hasTitleNoWrap } = props;

  const cn = useClassName('RotatedGridRow');

  return (
    <tr
      className={cn({
        empty: props.isEmpty,
        [`${props.size}`]: props.size,
      })}
    >
      <td className={cn('title', { noMaxWidth: hasNoTitleMaxWidth, noWrap: hasTitleNoWrap })}>
        {props.title ? `${props.title}:` : ''}
      </td>
      <td className={cn('data')}>{props.data}</td>
    </tr>
  );
};

export default RotatedGridRow;
