export enum ViewColumnsSectionId {
  KeyFields = 'keyFields',
  CompanyInfo = 'companyInfo',
  DealInfo = 'dealInfo',
  ResearchRating = 'researchRating',
  ReturnsTrading = 'returnsTrading',
  Valuation = 'valuation',
  Comps = 'comps',
  InvestorsFunding = 'investorsFunding',
  Financials = 'finansials',
}
