import * as yup from 'yup';
import { ResetPasswordFormFields } from './ResetPasswordFormFields';
import { getConfirmPasswordSchema } from '../../../shared/components/forms/services/validation/contstants/validation/confirmPasswordSchema';
import { getPasswordSchema } from '../../../shared/components/forms/services/validation/contstants/validation/passwordSchema';

export const validationSchema = yup.object().shape({
  [ResetPasswordFormFields.Email]: yup
    .string()
    .email()
    .required()
    .label('Business Email'),
  [ResetPasswordFormFields.Password]: getPasswordSchema('Password'),
  [ResetPasswordFormFields.ConfirmPassword]: getConfirmPasswordSchema(
    ResetPasswordFormFields.Password,
    'Confirm password',
  ),
});
