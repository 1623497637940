import React from 'react';
import { SortableContainer, SortableContainerProps } from 'react-sortable-hoc';
import './DragDropList.scss';

interface DragDropList extends SortableContainerProps {
  itemsComponents: React.ReactNode[];
}

const DragDropList: React.ComponentClass<DragDropList> = SortableContainer((props: DragDropList) => {
  return <section className="DragDropList">{props.itemsComponents}</section>;
});

export default DragDropList;
