import React from 'react';
import FilterControl from '../FilterControl';
import { ViewFilterName } from '../../../../enum/types/ViewFilterName';

const DateFilterSection: React.FC = () => {
  return (
    <>
      <FilterControl name={ViewFilterName.DateAddedToPCW} />

      <FilterControl name={ViewFilterName.DateUpdateInPast} />
    </>
  );
};

export default DateFilterSection;
