import React, { useContext } from 'react';
import { StatsPageContext } from '../../context/StatsPageContext';
import FiltersMap from '../../../../shared/entities/filters/components/filterable-component/FiltersMap';
import { statsPageFiltersControls } from './constants/statsPageFiltersControls';

const StatsPopupFiltersControls: React.FC = () => {
  const { pageFiltersSettings } = useContext(StatsPageContext);

  return (
    <FiltersMap filterControls={statsPageFiltersControls} dictionaries={pageFiltersSettings?.filtersDictionaries} />
  );
};

export default StatsPopupFiltersControls;
