import React from 'react';
import View from '../../shared/entities/view/components/view/View';
import { pricedViewOptions } from './constants/pricedViewOptions';
import { ViewType } from '../../shared/entities/view/components/view/enums/ViewType';

const Priced: React.FC = () => {
  return <View options={pricedViewOptions} viewType={ViewType.Priced} />;
};

export default Priced;
