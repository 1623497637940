import React from 'react';
import { Link } from 'react-router-dom';
import './NewsItem.scss';
import { useNewsTags } from '../hooks/useNewsTags';
import { useClassName } from '../../shared/hooks/useClassName';
import { NewsListItem } from './interfaces/NewsListItem';
import NewsItemTags from './NewsItemTags';
import { NewsLocationService } from '../shared/services/NewsLocationService';
import { DateFormat } from '../../shared/helpers/date/enums/DateFormat';

export interface NewsItemProps {
  data: NewsListItem;
  hasEmoji?: boolean;
  hasDateTag?: boolean;
  dateTagFormat?: DateFormat;
  hasProfileLayout?: boolean;
}

const NewsItem: React.FC<NewsItemProps> = props => {
  const { /* hasEmoji = true, */ data } = props;

  const tags = useNewsTags(data, props.hasDateTag, props.dateTagFormat, props.hasProfileLayout);

  const cn = useClassName('NewsItem');

  const url = NewsLocationService.getRoutePath(data.newsKey);

  return (
    <div className={cn()}>
      {/* {hasEmoji && data.emojiPath && (
        <div className={cn('icon')} style={{ backgroundImage: `url(${data.emojiPath})` }} />
      )} */}

      <div className={cn(`content${props.hasProfileLayout ? ' profile' : ''}`)}>
        <Link className={cn(`link${props.hasProfileLayout ? ' profile' : ''}`)} to={url}>
          {data.headline}
        </Link>

        <NewsItemTags tags={tags} />
      </div>
    </div>
  );
};

export default NewsItem;
