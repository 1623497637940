import { ViewColumnsSectionId } from '../enums/ViewColumnsSectionId';
import { ViewColumnsAddSetupSettings } from '../interfaces/ViewColumnsAddSetupSettings';

export const VIEW_COLUMNS_ADD_SETUP_SETTINGS: ViewColumnsAddSetupSettings[] = [
  {
    items: [
      {
        id: ViewColumnsSectionId.KeyFields,
        isHighlighted: true,
        isClose: false,
      },
    ],
  },
  {
    items: [
      {
        id: ViewColumnsSectionId.DealInfo,
        isClose: true,
      },
    ],
  },
  {
    items: [
      {
        id: ViewColumnsSectionId.CompanyInfo,
        isClose: true,
      },
      {
        id: ViewColumnsSectionId.ResearchRating,
        isClose: true,
      },
      {
        id: ViewColumnsSectionId.ReturnsTrading,
        isClose: true,
      },
      {
        id: ViewColumnsSectionId.Comps,
        isClose: true,
      },
    ],
  },
  {
    items: [
      {
        id: ViewColumnsSectionId.Valuation,
        isClose: true,
      },
      {
        id: ViewColumnsSectionId.InvestorsFunding,
        isClose: true,
      },
    ],
  },
  {
    items: [
      {
        id: ViewColumnsSectionId.Financials,
        isClose: true,
      },
    ],
    isTwoColumn: true,
  },
];
