import React from 'react';
import { FilterSectionType } from '../../../enum/FilterSectionType';
import DateFilterSection from '../components/sections/DateFilterSection';
import ReturnsFilterSection from '../components/sections/ReturnsFilterSection';
import DealTimingFilterSection from '../components/sections/DealTimingFilterSection';
import LocationFilterSection from '../components/sections/LocationFilterSection';
import ValuationFilterSection from '../components/sections/ValuationFilterSection';
import RenaissanceRatingFilterSection from '../components/sections/RenaissanceRatingFilterSection';
import DealStatusFilterSection from '../components/sections/DealStatusFilterSection';
import OfferDateFilterSection from '../components/sections/OfferDateFilterSection';
import FileDateFilterControls from '../components/sections/FileDateFilterControls';
import CompanyInfoFilterSection from '../components/sections/CompanyInfoFilterSection';
import FavouriteFilterSection from '../components/sections/FavouriteFilterSection';
import InvestorsFilterSection from '../components/sections/InvestorsFilterSection';
import DealInfoFilterSection from '../components/sections/DealInfoFilterSection';
import FinancialsFilterSection from '../components/sections/FinancialsFilterSection';
import SecurityTypeFilterSection from '../components/sections/SecurityTypeFilterSection';
import { FilterSectionsFormSettings } from '../types/FilterSectionsFormSettings';

export const filterSectionsFormSettings: FilterSectionsFormSettings = {
  [FilterSectionType.Date]: {
    controls: <DateFilterSection />,
  },
  [FilterSectionType.DealStatus]: {
    controls: <DealStatusFilterSection />,
  },
  [FilterSectionType.OfferDate]: {
    controls: <OfferDateFilterSection />,
  },
  [FilterSectionType.ActionDate]: {
    controls: <OfferDateFilterSection />,
  },
  [FilterSectionType.FileDate]: {
    controls: <FileDateFilterControls />,
  },
  [FilterSectionType.DealTiming]: {
    controls: <DealTimingFilterSection />,
  },
  [FilterSectionType.CompanyInfo]: {
    controls: <CompanyInfoFilterSection />,
  },
  [FilterSectionType.Investors]: {
    controls: <InvestorsFilterSection />,
  },
  [FilterSectionType.Location]: {
    controls: <LocationFilterSection />,
  },
  [FilterSectionType.Returns]: {
    controls: <ReturnsFilterSection />,
  },
  [FilterSectionType.DealInfo]: {
    controls: <DealInfoFilterSection />,
  },
  [FilterSectionType.SecurityType]: {
    controls: <SecurityTypeFilterSection />,
  },
  [FilterSectionType.Favorite]: {
    controls: <FavouriteFilterSection />,
  },
  [FilterSectionType.RenaissanceRating]: {
    controls: <RenaissanceRatingFilterSection />,
  },
  [FilterSectionType.Financials]: {
    controls: <FinancialsFilterSection />,
  },
  [FilterSectionType.Valuation]: {
    controls: <ValuationFilterSection />,
  },
};
