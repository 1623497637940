import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import './ButtonsNav.scss';
import ButtonNav from './ButtonNav';
import { NavProps } from '../interfaces/NavProps';
import { RoutePath } from '../../../enums/RoutePath';
import BackNavButton from './BackNavButton';
import { RouteService } from '../../../services/RouteService';

const ButtonsNav: React.FC<NavProps> = props => {
  const routesPaths = useMemo<RoutePath[]>(() => RouteService.getRoutePathsFromDescriptions(props.routesTitles), [
    props.routesTitles,
  ]);
  const location = useLocation();

  const isActive = useCallback((path: RoutePath) => location.pathname === path, [location]);

  return (
    <section className="ButtonsNav">
      <div className="ButtonsNav__buttons">
        {props.backRoutePath && <BackNavButton routePath={props.backRoutePath}>{props.backRouteTitle}</BackNavButton>}
        {routesPaths.map((path: RoutePath, index: number) => (
          <ButtonNav
            key={index}
            routePath={path}
            title={props.routesTitles[path] as RoutePath}
            isActive={isActive(path)}
          />
        ))}
      </div>

      <div className="ButtonsNav__content">{props.children}</div>
    </section>
  );
};

export default ButtonsNav;
