import { omit, keys } from 'lodash';
import { FormControlDeleteVisibleValueOptions } from '../interfaces/FormControlDeleteVisibleValueOptions';
import { AnyObject } from '../../../../../types/generics';
import { FormControlCheckboxListService } from '../../form-builder/services/FormControlCheckboxListService';
import { FormControlCheckboxList } from '../../form-builder/types/formControlsTypes';

export class FormControlVisibleValueService {
  static deleteControlValues(deleteValueOptions: FormControlDeleteVisibleValueOptions, values: AnyObject): AnyObject {
    const control = deleteValueOptions.control;
    const controlValues = deleteValueOptions.values;
    const names = keys(controlValues);

    if (!controlValues) {
      return values;
    }

    return names.reduce((newValues: AnyObject, name: string) => {
      if (FormControlCheckboxListService.isCheckboxType(control)) {
        return FormControlCheckboxListService.deleteCheckboxTypeValues(
          controlValues[name],
          control as FormControlCheckboxList,
          newValues,
        );
      } else {
        return omit(newValues, name);
      }
    }, values);
  }
}
