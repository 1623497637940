import { NewsFilterResultDto } from '../../interfaces/NewsFilterResultDto';
import { useCallback, useEffect, useState } from 'react';
import { NewsFilterData } from '../../interfaces/NewsFilterData';
import { NewsApiService } from '../../../shared/api/NewsApiService';
import { newsPerPage } from '../../constants/newsConstants';
import { updateNewsFilterEndDate } from '../../helpers/updateNewsFilterEndDate';
import { NewsFilterField } from '../../enums/NewsFilterField';
import { NewsLocation } from '../../enums/NewsLocation';

//  From a user perspective, that is how the filter should work though:
//  Private = Private Company checked,
//  US = P.C. not checked and Location = US,
//  Non-US = P.C. not checked and Location = Non-US,
//  All =All Location and P.C.
export function useNewsSearchData(
  filterData: NewsFilterData,
): [NewsFilterResultDto[], () => void, boolean, boolean, boolean] {
  const [news, setNews] = useState<NewsFilterResultDto[]>([]);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(false);
  const [isMoreLoading, setIsMoreLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const appendNews = useCallback((nextNews: NewsFilterResultDto[]) => setNews(news.concat(nextNews)), [news, setNews]);

  const parseFilterData = useCallback((filterData: NewsFilterData) => {
    const parsedFilterData = { ...filterData };
    const location = parsedFilterData[NewsFilterField.Location];

    // if (location === NewsLocation.All || location === NewsLocation.Private) {
    // if (location === NewsLocation.Private) {
    //   delete parsedFilterData[NewsFilterField.Location];
    //   parsedFilterData[NewsFilterField.Private] = true;
    // }

    // If "All" - remove the filter by location. Return as is for the rest of the location filters.

    if (location === NewsLocation.All) {
      delete parsedFilterData[NewsFilterField.Location];
    }

    return parsedFilterData;
  }, []);

  // Loads news using the received filter data
  const load = useCallback(
    async (filterData: NewsFilterData) => {
      const news = await NewsApiService.getFilteredNews(parseFilterData(filterData), newsPerPage);

      setHasMore(news.length === newsPerPage);

      return news;
    },
    [parseFilterData],
  );

  // Callback for loading additional items
  const loadMore = useCallback(async () => {
    if (!isMoreLoading && !isInitialLoading) {
      setIsMoreLoading(true);

      load(updateNewsFilterEndDate(filterData, news)).then(nextNews => {
        appendNews(nextNews);
        setIsMoreLoading(false);
      });
    }
  }, [isInitialLoading, isMoreLoading, load, filterData, news, appendNews]);

  // Loads new items if filter data is changed
  useEffect(() => {
    setIsInitialLoading(true);

    load(filterData).then(news => {
      setNews(news);
      setIsInitialLoading(false);
    });
  }, [load, filterData]);

  return [news, loadMore, isInitialLoading, hasMore, isMoreLoading];
}
