import React, { useContext, useCallback } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import ChartDateRangeFilter from '../../../../shared/components/chart/filters/ChartDateRangeFilter';
import Spacer from '../../../../shared/components/Spacer';
import './CompanyPerfomanceVsIpoIndexChartActions.scss';
import { useClassName } from '../../../../shared/hooks/useClassName';
import { CompanyPerfomanceVsIpoIndexChartActionsBlock } from './companyPerfomanceVsIpoIndexChartActionsBem';
import { DateRangeFilterData } from '../../../../shared/interfaces/DateRangeFilterData';

interface Props {
  setExtremes?: React.Dispatch<React.SetStateAction<any>>;
}

const CompanyPerfomanceVsIpoIndexChartActions: React.FC<Props> = props => {
  const cn = useClassName(CompanyPerfomanceVsIpoIndexChartActionsBlock.Root);

  const { perfomanceVsIpoIndexTerm, setPerfomanceVsIpoIndexTerm, perfomanceVsIpoIndexChart } = useContext(
    CompanyContext,
  );

  const [, loadPerfomanceVsIpoIndexChart] = perfomanceVsIpoIndexChart;

  const onDateRangeChange = useCallback(
    (data: DateRangeFilterData) => {
      setPerfomanceVsIpoIndexTerm(data);

      if (props.setExtremes && data) {
        props.setExtremes({
          minValue: data.startDate && Date.parse(data.startDate),
          maxValue: data.endDate && Date.parse(data.endDate),
        });
      }
    },
    [setPerfomanceVsIpoIndexTerm, loadPerfomanceVsIpoIndexChart],
  );

  return (
    <div className={cn()}>
      <ChartDateRangeFilter value={perfomanceVsIpoIndexTerm} onDateRangeChange={onDateRangeChange} />

      <Spacer />

      {/*
 Now this functionality is not fully worked, will be needed in the future
<CompanyPerfomanceVsIpoIndexChartAddTickerModal />*/}
    </div>
  );
};

export default CompanyPerfomanceVsIpoIndexChartActions;
