import React from 'react';
import './FormControlsList.scss';
import { useClassName } from '../../hooks/useClassName';
import { FormControlsListBlock, FormControlsListModifier } from './FormControlsListBem';

export interface FormControlsListProps {
  isInline?: boolean;
  className?: string;
}

const FormControlsList: React.FC<FormControlsListProps> = props => {
  const cn = useClassName(FormControlsListBlock.Root, props.className);

  return (
    <div
      className={cn({
        [FormControlsListModifier.Inline]: props.isInline,
      })}
    >
      {props.children}
    </div>
  );
};

export default FormControlsList;
