import { FilterControlSettings } from '../../../types/FilterControlSettings';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { ControlSize } from '../../../../../enums/ControlSize';
import { locationOptions } from '../../options/locationOptions';

export const locationControls: FilterControlSettings = {
  [ViewFilterName.LocationListingLocation]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName.LocationListingLocation,
    props: {
      name: ViewFilterName.LocationListingLocation,
      options: locationOptions,
      size: ControlSize.Small,
      isUnselectAvailable: true,
    },
  },
  [ViewFilterName.ExchangeCountry]: {
    type: FormControlType.Select,
    name: ViewFilterName.ExchangeCountry,
    props: {
      name: ViewFilterName.ExchangeCountry,
      label: 'Exchange Country',
      placeholder: 'Select Exchange Country',
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isMultiSelect: false,
    },
  },
  [ViewFilterName.ExchangeCountry]: {
    type: FormControlType.Select,
    name: ViewFilterName.ExchangeCountry,
    props: {
      name: ViewFilterName.ExchangeCountry,
      label: 'Exchange Country',
      placeholder: 'Select Exchange Country',
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isMultiSelect: false,
    },
  },
  [ViewFilterName.ExchangeRegion]: {
    type: FormControlType.Select,
    name: ViewFilterName.ExchangeRegion,
    props: {
      name: ViewFilterName.ExchangeRegion,
      label: 'Exchange Region',
      placeholder: 'Select Exchange Region',
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isMultiSelect: false,
    },
  },
  [ViewFilterName.IncludeChina]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.IncludeChina,
    props: {
      name: ViewFilterName.IncludeChina,
      label: 'Include China A shares',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.LocationExchangeList]: {
    type: FormControlType.Select,
    name: ViewFilterName.LocationExchangeList,
    props: {
      name: ViewFilterName.LocationExchangeList,
      label: 'Exchange',
      placeholder: 'Choose Exchange',
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isMultiSelect: true,
    },
  },
  [ViewFilterName.LocationExchange]: {
    type: FormControlType.Select,
    name: ViewFilterName.LocationExchange,
    props: {
      name: ViewFilterName.LocationExchange,
      label: 'Exchange',
      placeholder: 'Choose Exchange',
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isMultiSelect: false,
    },
  },
};
