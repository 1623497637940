import AutocompleteLib from 'react-autocomplete';
import React, { ChangeEvent, CSSProperties, HTMLProps, useCallback } from 'react';
import { AutocompleteItem as AutocompleteItemInterface } from './interfaces/AutocompleteItem';
import { AutocompleteBlock, AutocompleteElement, AutocompleteModifier } from './autocompleteBem';
import { useClassName } from '../../hooks/useClassName';
import './Autocomplete.scss';
import Loader from '../loader/Loader';
import AutocompleteItem from './AutocompleteItem';
import { ComponentMessage } from '../../enums/ComponentMessage';
import { AlignTypesHorizontal } from '../../enums/AlignTypesHorizontal';
import { Nullable } from '../../types/generics';
import { OnRenderAutocompleteItem } from './types/OnRenderAutocompleteItem';
import { SelectService } from '../../services/select/SelectService';

export interface AutocompleteProps {
  className?: string;
  items: AutocompleteItemInterface[];
  selectedItem?: Nullable<AutocompleteItemInterface>;
  displayValue?: string;
  renderInput?: (props: HTMLProps<HTMLInputElement>) => React.ReactNode;
  isLoading?: boolean;
  isNoData?: boolean;
  isBeforeSearchPlaceholder?: boolean;
  isWidthByInput?: boolean;
  onChange: (value: string) => void;
  onSelect: (value: string, item: AutocompleteItemInterface) => void;
  onRenderItem?: OnRenderAutocompleteItem;
}

const Autocomplete: React.FC<AutocompleteProps> = props => {
  const { isWidthByInput = true, onRenderItem, onChange, onSelect } = props;

  const cn = useClassName(AutocompleteBlock.Root);
  const getItemDisplayValue = useCallback((item: AutocompleteItemInterface) => item.title, []);

  const getMenu = useCallback(
    (items: React.ReactNode[], value: string, styles: CSSProperties) => {
      const newStyles = {
        maxWidth: isWidthByInput ? styles.minWidth : styles.maxWidth,
      };

      let updatingItemsControl;

      if (props.isLoading) {
        updatingItemsControl = (
          <AutocompleteItem isHoverable={false} alignHorizontal={AlignTypesHorizontal.Center}>
            <Loader />
          </AutocompleteItem>
        );
      } else if (props.isNoData) {
        updatingItemsControl = <AutocompleteItem isHoverable={false}>{ComponentMessage.NoData}</AutocompleteItem>;
      } else if (props.isBeforeSearchPlaceholder) {
        updatingItemsControl = <AutocompleteItem isHoverable={false}>Enter several letters...</AutocompleteItem>;
      }

      // Not fixed issue https://github.com/reactjs/react-autocomplete/issues/328
      // have to make display: none
      return (
        <div
          className={cn(AutocompleteElement.Menu, {
            [AutocompleteModifier.NoMenu]: props.selectedItem,
          })}
          style={newStyles}
        >
          {updatingItemsControl}

          <div
            style={{
              display: updatingItemsControl && 'none',
            }}
          >
            {items.map((item: React.ReactNode) => item)}
          </div>
        </div>
      );
    },
    [cn, props.isNoData, props.isLoading, props.selectedItem, props.isBeforeSearchPlaceholder, isWidthByInput],
  );

  const getItem = useCallback(
    (item: AutocompleteItemInterface, isHighlighted) => {
      const key = SelectService.getSelectOptionKey(item);

      return (
        <div key={key}>
          <AutocompleteItem key={key} isHighlighted={isHighlighted}>
            {onRenderItem ? onRenderItem(item) : item.title}
          </AutocompleteItem>
        </div>
      );
    },

    [onRenderItem],
  );

  const onAutocompleteChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, value: string) => {
      onChange(value);
    },
    [onChange],
  );

  const onAutocompleteSelect = useCallback(
    (value: string, item: AutocompleteItemInterface) => {
      onSelect?.(value, item);
    },
    [onSelect],
  );

  return (
    <AutocompleteLib
      value={props.displayValue}
      items={props.items}
      getItemValue={getItemDisplayValue}
      renderItem={getItem}
      renderMenu={getMenu}
      renderInput={props.renderInput}
      onChange={onAutocompleteChange}
      onSelect={onAutocompleteSelect}
    />
  );
};

export default Autocomplete;
