import React from 'react';
import './UnderwritersCell.scss';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { useClassName } from '../../../../../../hooks/useClassName';
import { UnderwritersCellBlock } from './UnderwritersCellBem';
import { CompanyApiService } from '../../../../../../api/CompanyApiService';
import TooltipAsync from '../../../../../../components/tooltip/TooltipAsync';
import UnderwritersTooltipContent from './UnderwritersTooltipContent';
import { memoize } from 'lodash';
import { ControlSize } from '../../../../../../enums/ControlSize';

type Props = GridCellFormatterCommonProps;

const UnderwritersCell: React.FC<Props> = props => {
  const cn = useClassName(UnderwritersCellBlock.Root);

  return (
    <CustomCellFormatterWrapper className={cn()} value={props.valueFormatted}>
      <TooltipAsync
        title={props.valueFormatted}
        isTitleHighlighted
        isUnderFixed
        component={UnderwritersTooltipContent}
        size={ControlSize.Medium}
        hasIndentHorizontal={false}
        getDataFn={memoize(CompanyApiService.getUnderwriters.bind(CompanyApiService, props.data.ticker))}
      />
    </CustomCellFormatterWrapper>
  );
};

export default UnderwritersCell;
