import React, { useState } from 'react';
import './NewsLandingRecap.scss';
import Button from '../../../shared/components/forms/Button';
import { ControlSize } from '../../../shared/enums/ControlSize';
import NewsRecap from '../../components/NewsRecap';
import Icon from '../../../shared/components/icon/Icon';
import { IconName } from '../../../shared/components/icon/IconName';
import { NewsApiService } from '../../../shared/api/NewsApiService';
import { useMemoizedCallback } from '../../../shared/hooks/useMemoizedCallback';
import { NewsRecapHeadlineType } from '../../enums/NewsRecapHeadlineType';
import { NewsRecapDate } from '../../interfaces/NewsRecapDate';
import { useNewsRecapDate } from '../hooks/useNewsRecapDate';

const NewsLandingRecap: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const recapDate: NewsRecapDate = useNewsRecapDate();
  const getMemoizedScheduledNews = useMemoizedCallback(() => NewsApiService.getRecapScheduled());
  const getMemoizedPcwAdditions = useMemoizedCallback(() => NewsApiService.getRecapPcwAdditions());
  const getMemoizedResearches = useMemoizedCallback(() => NewsApiService.getRecapResearches());
  const getMemoizedAnalystNotes = useMemoizedCallback(() =>
    NewsApiService.getRecapHeadlines(NewsRecapHeadlineType.AnalystNotes),
  );
  const getMemoizedUsExternalNews = useMemoizedCallback(() =>
    NewsApiService.getRecapHeadlines(NewsRecapHeadlineType.UsExternal),
  );
  const getMemoizedNonUsExternalNews = useMemoizedCallback(() =>
    NewsApiService.getRecapHeadlines(NewsRecapHeadlineType.NonUsExternal),
  );

  return (
    <div className="NewsLandingRecap">
      <Button className="NewsLandingRecap__button" size={ControlSize.Small} onClick={(): void => setIsOpen(!isOpen)}>
        {recapDate} <Icon name={isOpen ? IconName.DownArrow : IconName.RightArrow} size={ControlSize.ExtraSmall} />
      </Button>

      {isOpen && (
        <NewsRecap
          getMemoizedScheduledNews={getMemoizedScheduledNews}
          getMemoizedResearches={getMemoizedResearches}
          getMemoizedAnalystNotes={getMemoizedAnalystNotes}
          getMemoizedUsExternalNews={getMemoizedUsExternalNews}
          getMemoizedNonUsExternalNews={getMemoizedNonUsExternalNews}
          getMemoizedPcwAdditions={getMemoizedPcwAdditions}
        />
      )}
    </div>
  );
};

export default NewsLandingRecap;
