import React from 'react';
import './TableRow.scss';
import TableCell from './TableCell';
import { SimpleGridCell } from '../grid/simple-grid/interfaces/SimpleGridCell';
import { useClassName } from '../../hooks/useClassName';

interface Props {
  isHeader?: boolean;
  cells?: SimpleGridCell[];
  isAutoCellWidth?: boolean;
  className?: string;
}

const TableRow: React.FC<Props> = props => {
  const { isAutoCellWidth } = props;

  const cn = useClassName('TableRow', props.className);

  return (
    <tr className={cn()}>
      {props.cells &&
        props.cells.map((cell: SimpleGridCell, index: number) => (
          <TableCell column={cell.column} isHeader={props.isHeader} key={index} isAutoCellWidth={isAutoCellWidth}>
            {cell.value}
          </TableCell>
        ))}

      {props.children}
    </tr>
  );
};

export default TableRow;
