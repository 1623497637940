import React, { useContext } from 'react';
import { StatsElementType } from '../enums/StatsElementType';
import { StatsElementOptions } from '../interfaces/StatsElementOptions';
import { StatsElementContext } from './context/StatsElementContext';
import { useStatsElementContextValue } from './context/useStatsElementContextValue';
import StatsElementContent from './StatsElementContent';
import { StatsPageContext } from '../../../../stats/stats/context/StatsPageContext';

interface Props {
  type: StatsElementType;
  options?: StatsElementOptions;
}

const StatsElement: React.FC<Props> = props => {
  const { type, options } = props;

  const { pageFilters } = useContext(StatsPageContext);

  return (
    <StatsElementContext.Provider value={useStatsElementContextValue(type, pageFilters, options)}>
      <StatsElementContent />
    </StatsElementContext.Provider>
  );
};

export default StatsElement;
