import React, { useMemo } from 'react';
import './CompanyRating.scss';
import { CompanyRatingValueDto } from './types/CompanyRatingValueDto';
import { CompanyRatingFactory } from './factories/CompanyRatingFactory';
import { useClassName } from '../../../shared/hooks/useClassName';
import { CompanyRatingBlock } from './companyRatingBem';

interface Props {
  ratingValue: CompanyRatingValueDto;
  visibleValue?: string;
}

const CompanyRating: React.FC<Props> = props => {
  const { ratingValue, visibleValue } = props;

  const cn = useClassName(CompanyRatingBlock.Root);

  const rating = useMemo(() => CompanyRatingFactory.getRating(ratingValue, visibleValue), [ratingValue, visibleValue]);

  return (
    <span
      className={cn({
        [rating.colorScheme]: rating.colorScheme,
      })}
    >
      {rating.visibleValue}
    </span>
  );
};

export default CompanyRating;
