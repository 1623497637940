import { ScreensContextValue } from './screensContext';
import { useMemo } from 'react';
import { useContextData } from '../../shared/hooks/context-data/useContextData';
import { ViewSaved } from '../../shared/entities/view/components/view/interfaces/api/ViewSaved';
import { ViewApiService } from '../../shared/api/ViewApiService';
import { useLoadDataEffect } from '../../company/components/hooks/useLoadDataEffect';
import { ViewSavedService } from '../../shared/entities/view/components/view/services/ViewSavedService';

export function useScreensContextValue(): ScreensContextValue {
  const savedScreensData = useContextData<ViewSaved[] | undefined>(() => ViewApiService.getSavedScreens());
  const [savedScreens, loadSavedScreens, isSavedScreensLoading] = savedScreensData;

  const savedScreensLinks = useMemo(() => ViewSavedService.getNavItemLink(savedScreens || []), [savedScreens]);

  const isContextDataLoading = useMemo(() => !savedScreens && isSavedScreensLoading, [
    savedScreens,
    isSavedScreensLoading,
  ]);

  useLoadDataEffect(savedScreens, loadSavedScreens);

  return useMemo(() => ({ savedScreensData, isContextDataLoading, savedScreensLinks }), [
    savedScreensData,
    isContextDataLoading,
    savedScreensLinks,
  ]);
}
