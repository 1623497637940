import React, { useContext, useMemo } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import CompanyProfileSideItem from '../../profile/shared/components/CompanyProfileSideItem';
import CompanyProfileTable from '../../profile/shared/components/company-profile-table/CompanyProfileTable';
import { Link } from 'react-router-dom';
import { CompanyTabsPath } from '../../../enums/CompanyTabsPath';
import { ProfileData } from '../../../interfaces/ProfileData';
import { CompanyTab } from '../../../enums/CompanyTab';
import { CompanyLocationService } from '../../../services/CompanyLocationService';
import PageLoader from '../../../../shared/components/loader/PageLoader';

const CompanyValuationComparisonTable: React.FC = () => {
  const { getTabData, ticker, valuationComparisonData } = useContext(CompanyContext);

  // const profile: ProfileData = getTabData(CompanyTab.Profile);
  // const data = useMemo(() => profile.valuationComparison, [profile]);

  const linkPath = useMemo(() => CompanyLocationService.getRelativeTabPath(CompanyTabsPath.RelativeValuation, ticker), [
    ticker,
  ]);

  const profile: ProfileData = getTabData(CompanyTab.Profile);
  const data = useMemo(() => profile.description, [profile]);
  let title = 'Valuation Comparison';
  if (data?.isPreliminaryComps) {
    title = 'Valuation Comparison (Preliminary)';
  }

  return (
    <>
      <PageLoader
        loading={
          !!!valuationComparisonData ||
          valuationComparisonData?.rows[0]?.ticker?.toUpperCase() !== ticker?.toUpperCase()
        }
      >
        {valuationComparisonData && (
          <CompanyProfileSideItem title={<Link to={linkPath}>{title}</Link>}>
            <CompanyProfileTable
              columns={valuationComparisonData.columns}
              rows={valuationComparisonData.rows?.slice(0, 3)}
              expandedData={valuationComparisonData.rows?.slice(0, 3)}
              isFullWidth
            />
          </CompanyProfileSideItem>
        )}
      </PageLoader>
    </>
  );
};

export default CompanyValuationComparisonTable;
