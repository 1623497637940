import { ViewState } from './types';
import { ViewReqParamsBase } from '../interfaces/api/ViewReqParamsBase';
import { ViewSocketService } from '../services/ViewSocketService';
import { FormControl } from '../../../../../components/forms/components/form-builder/types/formControlsTypes';
import { FilterControlFactory } from '../../../../filters/components/filter-sections/factories/FilterControlFactory';
import { viewQuickFilterNames } from '../constants/viewQuickFilterNames';

export const isDataInitSelector = (state: ViewState): boolean => !!state.viewData;
export const isInitSelector = (state: ViewState): boolean =>
  state.columnsOptions !== undefined && isDataInitSelector(state);
export const viewReqParamsBaseSelector = (state: ViewState): ViewReqParamsBase => ({
  filters: state.filters,
  columns: state.selectedColumns,
  sort: state.sort,
  viewType: state.viewType,
});
export const canUpdatePriceChangeSelector = (state: ViewState): boolean =>
  !!state.gridPublicApi &&
  !!state.columnsGroups &&
  ViewSocketService.isAvailable(state.columnsGroups) &&
  state.isSocketWork;
export const hasNotesSelector = (state: ViewState): boolean => !!state.notes && state.notes.length > 0;
export const hasFootnoteSelector = (state: ViewState): boolean => !!state.footnote;
export const hasFooterSelector = (state: ViewState): boolean => hasFootnoteSelector(state) || hasNotesSelector(state);
export const viewFilterValuesControlsSelector = (state: ViewState): undefined | FormControl[] => {
  const modalFiltersSettings = state.options.toolbar?.modalFilters;

  return (
    modalFiltersSettings &&
    (FilterControlFactory.getList(modalFiltersSettings.sections, viewQuickFilterNames) as FormControl[])
  );
};
export const hasViewFilterValuesControlsSelector = (state: ViewState): boolean =>
  !!viewFilterValuesControlsSelector(state);
