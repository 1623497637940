import React, { useMemo } from 'react';
import CompanyContent from './CompanyContent';
import { CompanyContext } from './context/CompanyContext';
import { useCompanyContextValue } from './context/useCompanyContextValue';
import './CompanyContent.scss';
import DocumentHead from '../../shared/components/DocumentHead';

const Company: React.FC = () => {
  const contextValue = useCompanyContextValue();
  const ticker = contextValue.ticker;

  const documentHead = useMemo(() => {
    const text = `${ticker.toUpperCase()} Company Profile`;

    return {
      title: text,
      description: text,
    };
  }, [ticker]);

  return (
    <CompanyContext.Provider value={contextValue}>
      <DocumentHead custom={documentHead} />

      <CompanyContent />
    </CompanyContext.Provider>
  );
};

export default Company;
