import { FilterControlSettings } from '../../types/FilterControlSettings';
import { FormControlType } from '../../../../components/forms/components/form-builder/enums/FormControlType';
import { ControlSize } from '../../../../enums/ControlSize';
import { ResearchType } from '../../enum/types/ResearchType';
import { SearchResultsFilterName } from '../../../../../search/enums/SearchResultsFilterName';

export const researchControls: FilterControlSettings = {
  [ResearchType.ReportProfile]: {
    type: FormControlType.Checkbox,
    name: ResearchType.ReportProfile,
    props: {
      name: SearchResultsFilterName.ResearchTypes,
      value: ResearchType.ReportProfile,
      label: 'Report/Profile',
      size: ControlSize.Small,
    },
  },
  [ResearchType.ManagementQuestions]: {
    type: FormControlType.Checkbox,
    name: ResearchType.ManagementQuestions,
    props: {
      name: SearchResultsFilterName.ResearchTypes,
      value: ResearchType.ManagementQuestions,
      label: 'Management Questions',
      size: ControlSize.Small,
    },
  },
  [ResearchType.AnalystNote]: {
    type: FormControlType.Checkbox,
    name: ResearchType.AnalystNote,
    props: {
      name: SearchResultsFilterName.ResearchTypes,
      value: ResearchType.AnalystNote,
      label: 'Analyst Note',
      size: ControlSize.Small,
    },
  },
};
