import { SavedViewSectionOptions } from '../interfaces/SavedViewSectionOptions';
import { SavedViewsType } from '../enums/SavedViewsType';
import {
  noDataScreen,
  noDataScreenWithLink,
  noDataView,
  noDataViewWithLink,
  screenEntity,
  screensDefaultHeader,
  screensHeader,
  viewEntity,
  viewsHeader,
} from '../../../constants/viewsTypeTexts';
import { SavedViewsMessage } from '../enums/SavedViewsMessage';
import { ViewSavedService } from '../../../services/ViewSavedService';
import { SavedViewsSectionProps } from '../SavedViewsSection';

export class SavedViewsSectionFactory {
  static get(props: SavedViewsSectionProps): SavedViewSectionOptions {
    let options: Partial<SavedViewSectionOptions> = {};

    switch (props.type) {
      case SavedViewsType.DefaultScreens:
        options = {
          header: screensDefaultHeader,
          entity: screenEntity,
          noData: '',
          successMessage: '',
        };
        break;
      case SavedViewsType.Screens:
        options = {
          header: screensHeader,
          entity: screenEntity,
          successMessage: SavedViewsMessage.DeleteScreenSuccess,
          noData: props.isNoDataLink ? noDataScreenWithLink : noDataScreen,
        };
        break;
      case SavedViewsType.Views:
        options = {
          entity: viewEntity,
          header: viewsHeader,
          successMessage: SavedViewsMessage.DeleteViewSuccess,
          noData: props.isNoDataLink ? noDataViewWithLink : noDataView,
        };
        break;
    }

    options.header = props.hasNoHeader ? undefined : options.header;
    options.items = ViewSavedService.getActionListNavItemsFormSavedViews(props.items);

    return options as SavedViewSectionOptions;
  }
}
