import React, { useContext } from 'react';
import { ViewDashboardContext } from '../../shared/entities/view/components/view-dashboard/ViewDashboardContext';
import { ResearchDashboardDto } from '../interfaces/ResearchDashboardDto';
import ViewDashboardGrid from '../../shared/entities/view/components/view-dashboard/components/ViewDashboardGrid';
import { ResearchDashboardProp } from '../enums/ResearchDashboardProp';
import { recentResearchViewOptions } from '../../views/recent-research/constants/recentResearchViewOptions';

const ResearchDashboardProfilesGrid: React.FC = () => {
  const context = useContext(ViewDashboardContext);
  const data = context.data as ResearchDashboardDto;

  return (
    <ViewDashboardGrid
      dataTable={data[ResearchDashboardProp.RecentlyUpdatedProfiles]}
      colIds={recentResearchViewOptions.grid?.idCols}
    />
  );
};

export default ResearchDashboardProfilesGrid;
