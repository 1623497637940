import React from 'react';
import { useClassName } from '../../../../shared/hooks/useClassName';
import { PricingsComparedToRangeBlock } from './pricingsComparedToRangeBem';
import AdditionalStatsContentContainer from '../../shared/components/AdditionalStatsContentContainer';
import StatsElement from '../../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../../shared/entities/stats-element/enums/StatsElementType';
import { pricingsComparedToRangeStatsElementOptions } from './constants/pricingsComparedToRangeStatsElementOptions';
import AdditionalStatsPage from '../AdditionalStatsPage';

const PricingsComparedToRange: React.FC = () => {
  const cn = useClassName(PricingsComparedToRangeBlock.Root);

  return (
    <AdditionalStatsPage>
      <AdditionalStatsContentContainer className={cn()} title="IPO Pricings Compared To Range">
        <StatsElement type={StatsElementType.PricingVsRange} options={pricingsComparedToRangeStatsElementOptions} />
      </AdditionalStatsContentContainer>
    </AdditionalStatsPage>
  );
};

export default PricingsComparedToRange;
