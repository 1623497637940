import { Dispatch } from 'react';
import { CustomAny } from '../../../../../shared/types/generics';
import { FiltersValues } from '../../../../../shared/entities/filters/types/FiltersValues';
import { navigateToNewView } from '../../../../../shared/entities/view/components/view/store/actions/view';
import { ViewTypeScreen } from '../../../../../shared/entities/view/components/view/types/screen/ViewTypeScreen';
import { ScreenOptions } from '../interfaces/ScreenOptions';
import { ViewOptions } from '../../../../../shared/entities/view/components/view/interfaces/options/ViewOptions';
import { ViewOptionsService } from '../../../../../shared/entities/view/components/view/services/ViewOptionsService';
import { ScreenTriggerOptions } from '../interfaces/ScreenTriggerOptions';
import { GridAgColumn } from '../../../../../shared/components/grid/grid/interfaces/GridAgColumn';
import { GridCellFormatterType } from '../../../../../shared/components/grid/shared/enums/GridCellFormatterType';
import ScreenTriggerCell from '../components/landing/components/ScreenTriggerCell';
import { GridRow } from '../../../../../shared/components/grid/shared/types/GridRow';
import { ViewGridCellValueService } from '../../../../../shared/entities/view/components/view-grid/services/ViewGridCellValueService';
import { SelectService } from '../../../../../shared/services/select/SelectService';
import { screensLandingToInUse } from '../../../../constants/screensLandingToInUse';
import { ViewTypeScreenLanding } from '../../../../../shared/entities/view/components/view/types/screen/ViewTypeScreenLanding';
import { screensInUseToLanding } from '../../../../constants/screensInUseToLanding';
import { ViewTypeScreenInUse } from '../../../../../shared/entities/view/components/view/types/screen/ViewTypeScreenInUse';
import { ViewReqParamsBase } from '../../../../../shared/entities/view/components/view/interfaces/api/ViewReqParamsBase';
import { ViewType } from '../../../../../shared/entities/view/components/view/enums/ViewType';
import { ViewAppliedFilters } from '../../../../../shared/entities/view/components/view/interfaces/ViewAppliedFilters';

export class ScreenOptionsService {
  static get(
    screenType: ViewTypeScreen,
    screensOptions: Partial<ScreenOptions>,
    sharedOptions: Partial<ScreenOptions>,
  ): ViewOptions {
    const options = ViewOptionsService.merge<ScreenOptions>(sharedOptions, screensOptions);

    this.setSearchInUseTrigger(screenType, options);
    this.setGridOptions(screenType, options);

    return options;
  }

  static getInUseViewData(
    row: GridRow,
    screenType: ViewTypeScreen,
    isLanding: boolean,
    filters: ViewAppliedFilters,
    triggerOptions: ScreenTriggerOptions,
  ): ViewReqParamsBase {
    const cellValue = triggerOptions.filterValueColumnName
      ? ViewGridCellValueService.getRaw(row, triggerOptions.filterValueColumnName)
      : ViewGridCellValueService.getRaw(row, triggerOptions.columnName as string);
    const filterValue = triggerOptions.isMultiValue ? SelectService.getMultiSelectValue(cellValue) : cellValue;

    return {
      filters: {
        ...filters,
        [triggerOptions.filterName as string]: filterValue,
      },
      sort: null,
      viewType: this.getConnectedView(screenType, isLanding),
    };
  }

  private static setSearchInUseTrigger(screenType: ViewTypeScreen, options: ScreenOptions): void {
    if (!options.search) {
      return;
    }

    if (!options.filter) {
      options.filter = {};
    }

    options.filter.customHandlerOptions = {
      triggerFn: state => {
        const isAvailable = !!options.screenTriggerOptions?.isInUseAvailable?.(state.filters);

        // Landing and In-Use pages have opposite search triggers
        // So to not duplicates checks
        // We just revert availability function for In-Use page
        return options.isLanding ? isAvailable : !isAvailable;
      },
      onFilter: dispatch => this.onSearch(dispatch, screenType, options.isLanding),
    };
  }

  private static setGridOptions(screenType: ViewTypeScreen, options: ScreenOptions): void {
    const screenTriggerOptions = options.screenTriggerOptions;

    if (!options.grid) {
      options.grid = {};
    }

    if (screenTriggerOptions?.columnName) {
      options.grid.customColumnsSettings = [
        this.getConnectedScreenTriggerCell(screenType, options.isLanding, screenTriggerOptions),
      ];
    }
  }

  private static getConnectedScreenTriggerCell(
    screenType: ViewTypeScreen,
    isLanding: boolean,
    triggerOptions: ScreenTriggerOptions,
  ): Partial<GridAgColumn> {
    return {
      field: triggerOptions.columnName,
      cellFormatter: {
        type: GridCellFormatterType.Custom,
        props: {
          component: ScreenTriggerCell,
          screenType,
          isLanding,
          triggerOptions,
        },
      },
    };
  }

  private static getConnectedView(screenType: ViewTypeScreen, isLanding: boolean): ViewType {
    return (isLanding
      ? screensLandingToInUse[screenType as ViewTypeScreenLanding]
      : screensInUseToLanding[screenType as ViewTypeScreenInUse]) as ViewTypeScreen;
  }

  private static onSearch(
    dispatch: Dispatch<CustomAny>,
    screenType: ViewTypeScreen,
    isLanding: boolean,
    filters: FiltersValues = {},
  ): void {
    const connectedView = this.getConnectedView(screenType, isLanding);

    ScreenOptionsService.navigateOnSearch(dispatch, connectedView as ViewTypeScreen, filters);
  }

  public static navigateOnSearch(
    dispatch: Dispatch<CustomAny>,
    screensType: ViewTypeScreen,
    filters: FiltersValues = {},
  ): void {
    dispatch(navigateToNewView(screensType, { ...filters }));
  }
}
