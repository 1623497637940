import { NewsItemTag } from '../interfaces/NewsItemTag';
import { NewsTagModifier } from '../enums/NewsTagModifier';
import { NewsLocationService } from '../shared/services/NewsLocationService';
import { NewsFilterField } from '../enums/NewsFilterField';
import { NewsStoryType } from '../enums/NewsStoryType';

export function getNewsStoryTypeTag(item: { storyType?: NewsStoryType }): NewsItemTag | undefined {
  // In accordance with https://wiki.itransition.com/pages/viewpage.action?pageId=606978107

  const storyTypeMap = new Map<string, NewsStoryType>([
    ['IPO Pricing', NewsStoryType.IpoPricing],
    ['New IPO Filing', NewsStoryType.NewIpoFiling],
    ['Other', NewsStoryType.OtherAnnouncement],
    ['Postponed', NewsStoryType.Postponed],
    ['Announced Terms', NewsStoryType.SetTerms],
    ['Terms Revision - Down', NewsStoryType.TermsDown],
    ['Terms Revision - Up', NewsStoryType.TermsUp],
    ['Updated Financials', NewsStoryType.UpdatedFinancials],
    ['Withdrawn', NewsStoryType.Withdrawn],
  ]);

  if (item.storyType && item.storyType !== NewsStoryType.OtherAnnouncement) {
    return {
      title: item.storyType,
      modifier: NewsTagModifier.Green,
      url: NewsLocationService.createSearchUrl({
        [NewsFilterField.StoryType]: storyTypeMap.get(item.storyType),
      }),
    };
  }
}
