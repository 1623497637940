import { Layout, Layouts } from 'react-grid-layout';
import { GridLayoutWidget } from '../interfaces/GridLayoutWidget';
import reduce from 'lodash/reduce';
import { GridLayoutWidgetLayout } from '../interfaces/GridLayoutWidgetLayout';
import { GridLayoutWidgetLayouts } from '../interfaces/GridLayoutWidgetLayouts';
import { GridLayoutBreakpoint } from '../enums/GridLayoutBreakpoint';

export class GridLayoutService {
  static createLayouts(widgets: GridLayoutWidget[]): Layouts {
    return widgets.reduce((layouts: Layouts, widget: GridLayoutWidget) => {
      return reduce(
        widget.layouts,
        (widgetLayouts, widgetLayout: GridLayoutWidgetLayout, breakpoint: string) => {
          (layouts[breakpoint] || (layouts[breakpoint] = [])).push(this.createLayout(widget, widgetLayout));

          return widgetLayouts;
        },
        layouts,
      );
    }, {});
  }

  private static createLayout(widget: GridLayoutWidget, widgetLayout: GridLayoutWidgetLayout): Layout {
    return {
      i: widget.type,
      w: widgetLayout.width,
      h: widgetLayout.height,
      y: widgetLayout.top,
      x: widgetLayout.left,
      maxW: widgetLayout.maxWidth,
      minW: widgetLayout.minWidth,
      maxH: widgetLayout.maxHeight,
      minH: widgetLayout.minHeight,
    };
  }

  static createWidgets(layouts: Layouts): GridLayoutWidget[] {
    const breakpoints: GridLayoutBreakpoint[] = Object.values(GridLayoutBreakpoint);

    if (!layouts[breakpoints[0]]) {
      return [];
    }

    const widgetTypes: string[] = layouts[breakpoints[0]].map((layout: Layout) => layout.i);

    return widgetTypes.map((type: string) => ({ type, layouts: this.createWidgetLayouts(type, breakpoints, layouts) }));
  }

  private static createWidgetLayouts(
    type: string,
    breakpoints: GridLayoutBreakpoint[],
    layouts: Layouts,
  ): GridLayoutWidgetLayouts {
    return breakpoints.reduce(
      (widgetLayouts: Partial<GridLayoutWidgetLayouts>, breakpointName: GridLayoutBreakpoint) => {
        const breakpointTypeLayout: Layout = layouts[breakpointName].find(
          breakpointLayout => breakpointLayout.i === type,
        ) as Layout;

        widgetLayouts[breakpointName] = this.createWidgetLayout(breakpointTypeLayout);

        return widgetLayouts;
      },
      {},
    ) as GridLayoutWidgetLayouts;
  }

  private static createWidgetLayout(layout: Layout): GridLayoutWidgetLayout {
    const widgetLayout: GridLayoutWidgetLayout = {
      width: layout.w,
      height: layout.h,
      top: layout.y,
      left: layout.x,
    };

    layout.maxW && (widgetLayout.maxWidth = layout.maxW);
    layout.minW && (widgetLayout.minWidth = layout.minW);
    layout.maxH && (widgetLayout.maxHeight = layout.maxH);
    layout.minH && (widgetLayout.minHeight = layout.minH);

    return widgetLayout;
  }
}
