import React, { useContext, useEffect, useMemo, useState } from 'react';
import './CompanyContent.scss';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import CompanyHeader from './company-header/CompanyHeader';
import { CompanyContext } from './context/CompanyContext';
import { useLoadDataEffect } from './hooks/useLoadDataEffect';
import PageLoader from '../../shared/components/loader/PageLoader';
import { useQueryParams } from '../../shared/effects/useQueryParams';
import { CompanyProfileActionType } from '../enums/CompanyProfileActionType';
import { CompanyPageQueryParam } from '../enums/CompanyPageQueryParam';
import { CompanyContentBlock } from './CompanyContentBem';
import { useClassName } from '../../shared/hooks/useClassName';
import CompanyTabsContent from './CompanyTabsContent';
import { setIsPrint } from '../../store/profile-print/actions';
import { useDispatch } from 'react-redux';

const CompanyContent: React.FC = () => {
  const dispatch = useDispatch();
  const cn = useClassName(CompanyContentBlock.Root);

  const action = useQueryParams().get(CompanyPageQueryParam.Action) as CompanyProfileActionType | undefined;
  const [isPrinted, setIsPrinted] = useState(false);
  const [forceShowLoader, setForceShowLoader] = useState(true);
  const { tabsData, perfomanceVsIpoIndexChart, initializePerfomanceVsIpoIndexChart, ticker } = useContext(
    CompanyContext,
  );

  const [data, loadData, isLoading, , isTabsDataLoaded] = tabsData;
  const [perfomanceChartData] = perfomanceVsIpoIndexChart;

  const shouldPrint = useMemo(() => action === CompanyProfileActionType.Print && data && !isPrinted && !isLoading, [
    action,
    data,
    isPrinted,
    isLoading,
  ]);

  useLoadDataEffect(data, loadData, [ticker]);

  useLoadDataEffect(
    perfomanceChartData,
    () => {
      if (isTabsDataLoaded) {
        initializePerfomanceVsIpoIndexChart();
      }
    },
    [ticker, isTabsDataLoaded, data],
  );

  useEffect(() => {
    const id = setTimeout(() => {
      setForceShowLoader(false);
    }, 500);

    return () => {
      clearTimeout(id);
    };
  }, []);

  useEffect(() => {
    if (shouldPrint) {
      // When called, it will print all profile tabs from CompanyTabsContent.tsx
      dispatch(setIsPrint(true));
      setIsPrinted(true);
    }
  }, [shouldPrint]);

  return (
    <PageLoader
      loading={
        isLoading ||
        forceShowLoader ||
        (data?.profile?.description?.tickerSymbol?.toUpperCase() !== ticker?.toUpperCase() && data !== null)
      }
    >
      <PrimarySection className={cn()} subHeader={{ component: <CompanyHeader /> }}>
        <CompanyTabsContent data={data} />
      </PrimarySection>
    </PageLoader>
  );
};

export default CompanyContent;
