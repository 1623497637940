import { OrderedListItem } from '../../shared/components/ordered-list/interfaces/OrderedListItem';

export const loginHelpInfo: OrderedListItem[] = [
  {
    title: 'What do I do when I receive the error message "Username or password are incorrect, please try again."?',
    description:
      "Make sure you have entered the correct username (your email address), you are typing in the correct password, CAPS LOCK is not on and there are no extra spaces in your password. If you are unsure of your password, please click here to recover it. Your temporary password should then be sent to you via email. If you do not receive your password, please check your SPAM/Junk folder. Also, do not copy your password from the email. Type it in as sometimes extra spaces are copied when copying. If it still doesn't work, please try logging on via a different browser. If that works, log off and then go back to your original browser. It should work now.",
  },
  {
    title: 'Why do I periodically have to log in even if I check the "Remember Me" check box?',
    description:
      'We periodically require you to log in for security purposes. However, you can set your internet browser to remember your username and password. Please check with your IT department to set this up as we cannot change the security settings of your internet browser.',
  },
];
