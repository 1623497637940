import React, { useContext, useMemo } from 'react';
import Stepper from '../../../../../../shared/components/stepper/Stepper';
import './CompanyProfileSteps.scss';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import { CompanyProfileStepsService } from './services/CompanyProfileStepsService';
import CompanySectionRow from '../../../../shared/CompanySectionRow';

const CompanyProfileSteps: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const data = useMemo(() => profile.stages, [profile]);

  return (
    <>
      {data && (
        <CompanySectionRow>
          <Stepper
            className="CompanyProfileSteps"
            steps={CompanyProfileStepsService.convertToStepperData(data.dots, data.popUp)}
          />
        </CompanySectionRow>
      )}
    </>
  );
};

export default CompanyProfileSteps;
