import React, { useCallback, useMemo, useState } from 'react';
import './SavedViewsSection.scss';
import { SavedViewsSectionBlock, SavedViewsSectionModifier } from './savedViewsSectionBem';
import { useClassName } from '../../../../../../hooks/useClassName';
import { ViewSaved } from '../../interfaces/api/ViewSaved';
import { ViewSavedService } from '../../services/ViewSavedService';
import { ViewApiService } from '../../../../../../api/ViewApiService';
import { ViewId } from '../../types/ViewId';
import ActionListNav from '../../../../../../components/nav/components/action-list/ActionListNav';
import FormEntityActionModal from '../../../../../../components/modal/FormEntityActionModal';
import { ModalEntityActionType } from '../../../../../../components/modal/enums/ModalEntityActionType';
import { SavedViewsType } from './enums/SavedViewsType';
import { SavedViewsSectionFactory } from './factories/SavedViewsSectionFactory';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { AlignTypesHorizontal } from '../../../../../../enums/AlignTypesHorizontal';

export interface SavedViewsSectionProps {
  className?: string;
  items: ViewSaved[];
  type: SavedViewsType;
  hasNoHeader?: boolean;
  hasDelete?: boolean;
  width?: ControlSize;
  isFullHeight?: boolean;
  isNoDataLink?: boolean;
  hasOrderItem?: boolean;
  isGrid?: boolean;
  noDataAlignTypeHorizontal?: AlignTypesHorizontal;
  afterDeleteView?: (savedViews: ViewSaved[]) => void;
}

const SavedViewsSection: React.FC<SavedViewsSectionProps> = props => {
  const { hasDelete = true, width } = props;

  const cn = useClassName(SavedViewsSectionBlock.Root, props.className);

  const options = useMemo(() => SavedViewsSectionFactory.get({ ...props, hasDelete }), [props, hasDelete]);

  const deleteModalShowState = useState(false);
  const [, setDeleteModalShow] = deleteModalShowState;
  const [viewToDelete, setViewToDelete] = useState<ViewSaved>();

  const deleteView = useCallback(async () => {
    // TODO: update to delete view when api will be ready

    if (viewToDelete) {
      const savedViews = await ViewApiService.deleteView(viewToDelete.viewId);

      props.afterDeleteView?.(savedViews);
    }
  }, [viewToDelete, props.afterDeleteView]);

  const getSuccessDeleteMessage = useCallback(
    () => ({
      text: options.successMessage,
      values: [viewToDelete?.name || ''],
    }),
    [viewToDelete, options],
  );

  const onDeleteFromFavourites = useCallback(
    (id: string | number) => {
      setViewToDelete(ViewSavedService.getById(id as ViewId, props.items));
      setDeleteModalShow(true);
    },
    [props.items, setDeleteModalShow],
  );

  return (
    <>
      <ActionListNav
        className={cn({
          [`${width}${SavedViewsSectionModifier.Width}`]: width,
        })}
        items={options.items}
        noData={options.noData}
        header={options.header}
        isFullHeight={props.isFullHeight}
        hasOrderItem={props.hasOrderItem}
        isGrid={props.isGrid}
        noDataAlignTypeHorizontal={props.noDataAlignTypeHorizontal}
        onDeleteClick={hasDelete ? onDeleteFromFavourites : undefined}
      />

      <FormEntityActionModal
        showState={deleteModalShowState}
        entityName={options.entity}
        actionType={ModalEntityActionType.Delete}
        getSuccessMessage={getSuccessDeleteMessage}
        onSubmit={deleteView}
      />
    </>
  );
};

export default SavedViewsSection;
