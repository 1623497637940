import React, { useCallback, useMemo } from 'react';
import './ViewColumnsAdd.scss';
import ViewColumnsAddRow from './components/row/ViewColumnsAddRow';
import ViewColumnsAddSections from './components/sections/ViewColumnsAddSections';
import ViewColumnsAddSelected from './components/selected/ViewColumnsAddSelected';
import { ViewColumnsAddService } from './services/ViewColumnsAddService';
import { ViewColumnsAddItem } from './interfaces/ViewColumnsAddItem';
import { useClassName } from '../../../../../../../hooks/useClassName';
import { ViewColumnsAddBlock } from './viewColumnsAddBem';
import { ViewColumnsAddContext } from './viewColumnsAddContext';
import { ViewColumnsOptionsDto } from './interfaces/dto/ViewColumnsOptionsDto';
import { ViewColumnsAddDataService } from './services/ViewColumnsAddDataService';

export interface ViewColumnsAddProps {
  columnsOptions: ViewColumnsOptionsDto;
  selectedColumnsIds: string[];
  isInModal?: boolean;
  onChange: (columnNames: string[]) => void;
}

const ViewColumnsAdd: React.FC<ViewColumnsAddProps> = props => {
  const { onChange } = props;

  const cn = useClassName(ViewColumnsAddBlock.Root);

  const baseColumnsSettings = useMemo(() => ViewColumnsAddDataService.getBaseColumnsSettings(props.columnsOptions), [
    props.columnsOptions,
  ]);

  const selectedColumnsIds = useMemo(
    () => ViewColumnsAddDataService.getSelectedColumnsIds(props.columnsOptions, props.selectedColumnsIds),
    [props.columnsOptions, props.selectedColumnsIds],
  );

  const columnsSettings = useMemo(
    () => ViewColumnsAddService.getColumnsSettings(baseColumnsSettings, selectedColumnsIds),
    [baseColumnsSettings, selectedColumnsIds],
  );

  const selectedColumns = useMemo<ViewColumnsAddItem[]>(
    () => ViewColumnsAddService.getSelectedColumnsFromSettings(columnsSettings, selectedColumnsIds),
    [selectedColumnsIds, columnsSettings],
  );

  const onColumnCheck = useCallback(
    (columnId: string, isSelected: boolean): void => {
      onChange(ViewColumnsAddService.toggleColumn(columnId, isSelected, selectedColumns));
    },
    [selectedColumns, onChange],
  );

  const onSortEnd = useCallback(
    (columnsSorted: ViewColumnsAddItem[]) => {
      onChange(ViewColumnsAddService.getSelectedColumnsIds(columnsSorted));
    },
    [onChange],
  );

  return (
    <ViewColumnsAddContext.Provider
      value={{
        isInModal: props.isInModal,
        onColumnCheck,
        onSortEnd,
      }}
    >
      <section className={cn()}>
        <ViewColumnsAddRow title="" content={<ViewColumnsAddSections settings={columnsSettings} />} />

        <ViewColumnsAddRow
          title="Current columns"
          content={<ViewColumnsAddSelected selectedColumns={selectedColumns} />}
        />
      </section>
    </ViewColumnsAddContext.Provider>
  );
};

export default ViewColumnsAdd;
