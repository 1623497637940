import { FileActionType } from '../../../../../../../shared/components/file-actions/enums/FileActionType';
import { TimeFrame } from '../../../../../../../shared/entities/filters/enum/TimeFrame';
import { ViewSummaryAlignType } from '../../../../../../../shared/entities/view/components/view/enums/ViewSummaryAlignType';
import { ScreenOptions } from '../../../interfaces/ScreenOptions';
import { staticScreenInUseOptions } from './staticScreenInUseOptions';
import { screensPageAdditionalAction } from '../../../constants/screensPageAdditionalAction';

export const screenInUseSharedOptions: Partial<ScreenOptions> = {
  ...staticScreenInUseOptions,
  isLanding: false,
  toolbar: {
    fileActionTypes: [FileActionType.Excel, FileActionType.Print],
    quickFilter: {
      hasLocation: true,
      timeFrameOptions: [TimeFrame.Month, TimeFrame.Quarter, TimeFrame.YTD, TimeFrame.Year, TimeFrame.All],
      hasTimeFrameRange: true,
      hasDateRange: true,
      hasIncludeAShares: true,
      hasIncludeSPAC: true,
    },
  },
  grid: {
    isFixedHeight: false,
  },
  summary: {
    align: ViewSummaryAlignType.Simple,
  },
  additionalAction: screensPageAdditionalAction,
};
