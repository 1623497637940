import React, { ReactNode } from 'react';
import { CompanyInfoDescriptionDto } from '../../../interfaces/CompanyInfoDescriptionDto';
import { TreeNodeCommonProps } from '../../../../shared/components/tree-view/TreeNode';
import ViewLink from '../../../../shared/entities/view/components/view-link/ViewLink';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { ViewReqParamsBase } from '../../../../shared/entities/view/components/view/interfaces/api/ViewReqParamsBase';
import { ViewFilterName } from '../../../../shared/entities/filters/enum/types/ViewFilterName';
import { DealStatus } from '../../../../shared/entities/filters/enum/DealStatus';
import { TimeFrame } from '../../../../shared/entities/filters/enum/TimeFrame';
import { Location } from '../../../../shared/entities/filters/enum/Location';

export class CompanyHeaderService {
  static getTreeViewNodesFromInfo(info: CompanyInfoDescriptionDto): TreeNodeCommonProps[] {
    const location: Location = info?.countryName === 'United States' ? Location.US : Location.NonUS;
    const sectorCode: string = info?.sectorCode?.toString();
    const groupCode: string = info?.groupCode?.toString();
    const industryCode: string = info?.industryCode?.toString();
    const subIndustryCode: string = info?.subIndustryCode?.toString();

    if (!sectorCode) {
      return [{ content: this.getTreeViewNodeContent(location, info.gics1SectorName, sectorCode) }];
    }
    return [
      {
        content: this.getTreeViewNodeContent(location, info.gics1SectorName, sectorCode),
        childrenNodes: [
          {
            content: this.getTreeViewNodeContent(location, info.gics2GroupName, groupCode),
            isUsable: false,
            childrenNodes: [
              {
                content: this.getTreeViewNodeContent(location, info.gics3IndustryName, industryCode),
                isUsable: false,
                childrenNodes: [
                  {
                    content: this.getTreeViewNodeContent(location, info.gics4SubIndustryName, subIndustryCode),
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
  }

  private static getTreeViewNodeContent(location: Location, title: string, industryKey: string): ReactNode {
    return this.getTreeViewNodeContentLink(location, title, industryKey);
  }

  private static getTreeViewNodeContentLink(location: Location, title: string, industryKey: string): ReactNode {
    const data: ViewReqParamsBase = {
      viewType: ViewType.IndustryInUse,
      filters: {
        [ViewFilterName.Industry]: industryKey,
        [ViewFilterName.DealStatusDealStatusList]: DealStatus.Priced,
        [ViewFilterName.OfferTimeFrame]: TimeFrame.Year,
        [ViewFilterName.LocationListingLocation]: location,
      },
      sort: null,
    };

    return <ViewLink title={title} data={data} />;
  }
}
