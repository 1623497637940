import React from 'react';
import InfiniteScroller from 'react-infinite-scroller';
import Loader from './loader/Loader';

interface Props {
  loadMore: () => void;
  hasMore: boolean;
}

const InfiniteScroll: React.FC<Props> = props => {
  return (
    <InfiniteScroller
      loadMore={props.loadMore}
      hasMore={props.hasMore}
      initialLoad={false}
      loader={
        <div className="loader" key={0}>
          <Loader description="Loading news..." />
        </div>
      }
    >
      {props.children}
    </InfiniteScroller>
  );
};

export default InfiniteScroll;
