import { ManualFieldError } from 'react-hook-form';
import { CustomAny } from '../../../types/generics';
import { ErrorData } from '../../../services/error/interfaces/ErrorData';
import { ErrorMessageService } from '../../../services/error/ErrorMessageService';
import { ErrorDataProps } from '../../../services/error/enum/ErrorDataProps';
import { lowerFirst } from 'lodash';
import { ErrorInvalidParam } from '../../../services/error/interfaces/ErrorInvalidParam';

export class FormErrorService {
  static getFormErrors(error: ErrorData): ManualFieldError<CustomAny>[] {
    return [this.getSummary(error), ...this.getParams(error)];
  }

  private static getFormError(name: string, message: string): ManualFieldError<CustomAny> {
    return {
      name: lowerFirst(name), // TODO: remove lower case when api will fix issues
      message,
      type: 'required',
    };
  }

  private static getSummary(error: ErrorData): ManualFieldError<CustomAny> {
    return this.getFormError(ErrorDataProps.SummaryErrors, ErrorMessageService.getSummaryMessage(error));
  }

  private static getParams(error: ErrorData): ManualFieldError<CustomAny>[] {
    if (!error[ErrorDataProps.InvalidParams]) {
      return [];
    }

    return (error[ErrorDataProps.InvalidParams] as ErrorInvalidParam[]).map(param =>
      this.getFormError(param.name, ErrorMessageService.getParamMessage(param)),
    );
  }
}
