import { useMemo } from 'react';
import { ViewGridDataService } from '../services/ViewGridDataService';
import { GridAgColumn } from '../../../../../components/grid/grid/interfaces/GridAgColumn';
import { ViewGridColumnsSetupOptions } from '../interfaces/ViewGridColumnsSetupOptions';
import { ViewGridColumnOptions } from '../types/ViewGridColumnOptions';
import { GridRow } from '../../../../../components/grid/shared/types/GridRow';
import useBreakpointAvailable from '../../../../../effects/useBreakpointAvailable';
import { GRID_COLUMNS_PIN_BREAKPOINTS } from '../../../../../components/grid/grid/constants/gridColumnsPinBreakpoints';
import { ViewColumnSystemName } from '../enums/ViewColumnSystemName';
import { ViewGridSingleColumnFactory } from '../factories/ViewGridSingleColumnFactory';
import { viewGridColumns } from '../constants/viewGridColumns';
import { ViewGridColumnDto } from '../interfaces/ViewGridColumnDto';

const tickerColumn = ViewGridSingleColumnFactory.getColumn(
  viewGridColumns[ViewColumnSystemName.Ticker] as ViewGridColumnDto,
);
const COLUMN_NAMES_TO_SHOW_TICKER = [
  ViewColumnSystemName.CompanyTicker,
  ViewColumnSystemName.CompanyWithDescription,
  ViewColumnSystemName.CompanyName,
];

function useColumnsWithPinTicker(columns: GridAgColumn[]): GridAgColumn[] {
  const shouldPinColumns = useBreakpointAvailable(GRID_COLUMNS_PIN_BREAKPOINTS);

  return useMemo(() => {
    const hasTickerPinColumn = columns.some(column =>
      COLUMN_NAMES_TO_SHOW_TICKER.includes(column.field as ViewColumnSystemName),
    );

    if (shouldPinColumns && hasTickerPinColumn) {
      const isTickerColumnExists = columns.some(column => {
        return column.headerName === ViewColumnSystemName.Ticker || column.headerName === 'Ticker';
      });

      if (isTickerColumnExists) {
        return columns;
      }
      // TODO Fix: figure out why rows repeat themselves when we didn't had column.headerName === 'Ticker'
      return [tickerColumn, ...columns];
    }

    return columns;
  }, [columns, shouldPinColumns]);
}

export function useViewGridColumns(
  columnsOptions: ViewGridColumnOptions[] = [],
  rows: GridRow[] = [],
  setupOptions?: ViewGridColumnsSetupOptions,
): GridAgColumn[] {
  let columns = useMemo(
    () => ViewGridDataService.getColumnsFromViewColumnOptions(columnsOptions, rows, setupOptions),
    // remove props.sort from dependency array due to ag-grid store this state
    // eslint-disable-next-line
    [columnsOptions, rows],
  );

  columns = useColumnsWithPinTicker(columns);

  return columns;
}
