import React, { useCallback } from 'react';
import Button from '../../../../../../../components/forms/Button';
import { ButtonType } from '../../../../../../../enums/ButtonType';
import { ColorScheme } from '../../../../../../../enums/ColorScheme';
import { ControlSize } from '../../../../../../../enums/ControlSize';
import { ViewModalAction } from '../enums/ViewModalAction';
import { ViewModalActionsBlock, ViewModalActionsElement } from './viewFilterActionsBem';
import { useClassName } from '../../../../../../../hooks/useClassName';
import ButtonsList from '../../../../../../../components/forms/ButtonsList';
import { ViewModalActionFn } from '../types/ViewModalActionFn';

interface Props {
  isResetDisabled: boolean;
  isSaveAndRunDisabled?: boolean;
  isRunDisabled?: boolean;
  onAction: ViewModalActionFn;
  hideSaveAndRun?: boolean;
}

const ViewFilterActions: React.FC<Props> = props => {
  const cn = useClassName(ViewModalActionsBlock.Root);

  const { onAction } = props;

  const onModalAction = useCallback(
    (action: ViewModalAction) => {
      onAction(action);
    },
    [onAction],
  );

  return (
    <ButtonsList className={cn()}>
      <Button
        className={cn(ViewModalActionsElement.Run)}
        size={ControlSize.Small}
        type={ButtonType.Button}
        colorScheme={ColorScheme.Primary}
        isDisabled={props.isRunDisabled}
        onClick={onModalAction.bind(onModalAction, ViewModalAction.Run)}
      >
        Run
      </Button>
      {!props.hideSaveAndRun && (
        <Button
          className={cn(ViewModalActionsElement.Save)}
          size={ControlSize.Small}
          type={ButtonType.Button}
          isDisabled={props.isSaveAndRunDisabled}
          onClick={onModalAction.bind(onModalAction, ViewModalAction.SaveAndRun)}
        >
          Save and Run
        </Button>
      )}

      <Button
        className={cn(ViewModalActionsElement.Reset)}
        size={ControlSize.Small}
        type={ButtonType.Button}
        isDisabled={props.isResetDisabled}
        onClick={onModalAction.bind(onModalAction, ViewModalAction.Reset)}
      >
        Reset
      </Button>
    </ButtonsList>
  );
};

export default ViewFilterActions;
