import { CalendarEvent } from '../../shared/entities/calendar/interfaces/CalendarEvent';
import { CalendarEventType } from '../../shared/entities/calendar/enums/CalendarEventType';
import { MasterCalendarDto } from '../interfaces/MasterCalendarDto';
import { CalendarEventDto } from '../../shared/entities/calendar/interfaces/CalendarEventDto';
import CalendarApiService from '../../shared/api/CalendarApiService';
import { CalendarDataService } from '../../shared/entities/calendar/services/CalendarDataService';

export class MasterCalendarDataService {
  static async getEvents(): Promise<CalendarEvent[]> {
    const calendar = await CalendarApiService.getMasterCalendar();

    return this.mapResponseToEvents(calendar);
  }

  private static mapResponseToEvents(data: MasterCalendarDto): CalendarEvent[] {
    const eventData: CalendarEvent[] = [];
    const eventTypes = Object.keys(data) as CalendarEventType[];

    eventTypes.forEach((eventType: CalendarEventType): void =>
      data[eventType].forEach((element: CalendarEventDto) =>
        eventData.push(CalendarDataService.getEventFromDto(element, eventType)),
      ),
    );

    return eventData;
  }
}
