import { AxisLabelsFormatterContextObject, FormatterCallbackFunction } from 'highcharts';

export function percentageFormatter(value: number): string {
  return (value > 0 ? ' + ' : '') + value + '%';
}

export const labelsPercentageFormatter: FormatterCallbackFunction<AxisLabelsFormatterContextObject<
  number
>> = function(): string {
  return percentageFormatter(this.value);
};
