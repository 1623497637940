import React, { useContext } from 'react';
import PrimarySection, { PrimarySectionProps } from '../../shared/components/primary-section/PrimarySection';
import StatsFilters, { StatsFiltersProps } from './StatsFilters';
import DocumentHead from '../../shared/components/DocumentHead';
import { useCurrentRoutePath } from '../../shared/hooks/useCurrentRoutePath';
import { DocumentHeadRoutePath } from '../../shared/constants/documentHead';
import { StatsPageContext } from './context/StatsPageContext';
import { StatsContext } from './context/StatsContext';
import { useStatsPageContextValue } from './context/useStatsPageContextValue';
import { FiltersValues } from '../../shared/entities/filters/types/FiltersValues';

export interface StatsPageProps extends PrimarySectionProps, Partial<StatsFiltersProps> {
  onPageFiltersChange?: (pageFilters: FiltersValues) => void;
}

const StatsPage: React.FC<StatsPageProps> = props => {
  const { filtersNames = [] } = props;

  const { pagesFiltersSettings } = useContext(StatsContext);
  const routePath = useCurrentRoutePath() as DocumentHeadRoutePath;

  return (
    <StatsPageContext.Provider
      value={useStatsPageContextValue(pagesFiltersSettings, routePath, props.onPageFiltersChange)}
    >
      <PrimarySection
        header={props.header}
        subHeader={{
          component: (
            <StatsFilters
              filtersNames={filtersNames}
              hasFileActions={props.hasFileActions}
              excelOptions={props.excelOptions}
            />
          ),
        }}
        {...props}
      >
        <DocumentHead path={routePath} />

        {props.children}
      </PrimarySection>
    </StatsPageContext.Provider>
  );
};

export default StatsPage;
