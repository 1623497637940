import React, { useMemo } from 'react';
import CustomCellFormatterWrapper from '../../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { GridCellFormatterCommonProps } from '../../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import ViewLink from '../../../../view-link/ViewLink';
import { ViewTriggerCellProps } from './interface/ViewTriggerCellProps';
import { ViewTriggerCellDataService } from './services/ViewTriggerCellDataService';
import { ViewColumnSystemName } from '../../../enums/ViewColumnSystemName';
import { ViewGridCellValueService } from '../../../services/ViewGridCellValueService';

export interface Props extends GridCellFormatterCommonProps, ViewTriggerCellProps {}

const ViewTriggerCell: React.FC<Props> = props => {
  const visibleValue = useMemo(() => ViewGridCellValueService.getVisible(props.data, props.field), [
    props.data,
    props.field,
  ]);

  const viewData = useMemo(
    () =>
      ViewTriggerCellDataService.getViewData(
        props.data,
        props.value,
        props.field as ViewColumnSystemName,
        props.viewType,
        props.optionsList,
        props.getIsLinkAvailable,
        props.columns,
      ),
    [props.data, props.value, props.field, props.viewType, props.optionsList, props.getIsLinkAvailable, props.columns],
  );

  // Hide ViewLink if field is 'Key Shareholder' and IsInvestorScreenLink is set to false
  const showViewLink = props.field === 'Key Shareholder' && props.data.IsInvestorScreenLink === false ? false : true;

  return (
    <CustomCellFormatterWrapper value={props.value}>
      {viewData && showViewLink ? (
        <ViewLink title={props.value} data={viewData} hasLinkStyle={props.hasLinkStyle} />
      ) : (
        visibleValue
      )}
    </CustomCellFormatterWrapper>
  );
};

export default ViewTriggerCell;
