import { FilterControlSettings } from '../../../types/FilterControlSettings';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { TimeFrame } from '../../../enum/TimeFrame';
import { ControlSize } from '../../../../../enums/ControlSize';
import { updatedInPastOptions } from '../../options/updatedInPastOptions';
import { InputType } from '../../../../../components/forms/Input';

export const dealTimingControls: FilterControlSettings = {
  [ViewFilterName._DealTimingFiledRange]: {
    type: FormControlType.TimeFrameSelect,
    name: ViewFilterName._DealTimingFiledRange,
    props: {
      timeFrameName: ViewFilterName.DealTimingFiledRangeTimeFrame,
      timeFrames: [TimeFrame.Month, TimeFrame.Quarter, TimeFrame.Year, TimeFrame.All],
      timeFrameLabel: 'Filed Range',
      minName: ViewFilterName.DealTimingFiledRangeStartDate,
      maxName: ViewFilterName.DealTimingFiledRangeEndDate,
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.DealTimingUpdatedInPast]: {
    type: FormControlType.TextSelectWithInput,
    name: ViewFilterName.DealTimingUpdatedInPast,
    props: {
      name: ViewFilterName.DealTimingUpdatedInPast,
      textSelectOptions: updatedInPastOptions,
      textSelectLabel: 'Updated in the Past',
      inputPlaceholder: '# of Days',
      inputType: InputType.Number,
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._DealTimingExpectedOfferTiming]: {
    type: FormControlType.DateRangeSelect,
    name: ViewFilterName._DealTimingExpectedOfferTiming,
    props: {
      label: 'Expected Offer Timing',
      minName: ViewFilterName.DealTimingExpectedOfferStartDate,
      maxName: ViewFilterName.DealTimingExpectedOfferEndDate,
      size: ControlSize.Small,
    },
  },
};
