import React, { useCallback } from 'react';
import { OnChangeDateRangeFn } from '../../forms/components/date/types/OnChangeDateRangeFn';
import { DateRangeFilterData } from '../../../interfaces/DateRangeFilterData';
import { Nullable } from '../../../types/generics';
import { ChartDateRangeFilterBlock } from './chartDateRangeFilterBem';
import './ChartDateRangeFilter.scss';
import DateRangeSelect from '../../forms/components/date/DateRangeSelect';
import { ControlSize } from '../../../enums/ControlSize';

interface Props {
  value: Nullable<DateRangeFilterData> | undefined;
  onDateRangeChange: (data: DateRangeFilterData) => void;
}

const ChartDateRangeFilter: React.FC<Props> = props => {
  const onDateRangeChange = useCallback<OnChangeDateRangeFn>(
    (minValue, maxValue) => {
      const rangeValue: DateRangeFilterData = {};

      rangeValue.startDate = minValue;
      rangeValue.endDate = maxValue;

      props.onDateRangeChange(props.value ? { ...props.value, ...rangeValue } : rangeValue);
    },
    [props],
  );

  return (
    <DateRangeSelect
      className={ChartDateRangeFilterBlock.Root}
      minValue={props.value?.startDate}
      maxValue={props.value?.endDate}
      size={ControlSize.Small}
      onChange={onDateRangeChange}
    />
  );
};

export default ChartDateRangeFilter;
