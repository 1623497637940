import React from 'react';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { screensLocationInUseOptions } from './constants/screensLocationInUseOptions';
import ScreensInUse from '../../shared/pages/components/in-use/ScreensInUse';
import { useScreensLocationData } from './hooks/useScreensLocationData';

const ScreensLocationInUse: React.FC = () => {
  const { options, events } = useScreensLocationData(screensLocationInUseOptions);

  return <ScreensInUse inUseType={ViewType.LocationInUse} options={options} events={events} />;
};

export default ScreensLocationInUse;
