import { ViewColumnSystemName } from '../enums/ViewColumnSystemName';
import { ViewGridColumnDto } from '../interfaces/ViewGridColumnDto';
import { ViewColumnDataType } from '../enums/ViewColumnDataType';

export const viewGridColumns: { [key in ViewColumnSystemName]?: ViewGridColumnDto } = {
  [ViewColumnSystemName.ReportDate]: {
    name: ViewColumnSystemName.ReportDate,
    title: 'Report Date',
    type: ViewColumnDataType.Date,
  },
  [ViewColumnSystemName.SpecialReportsDate]: {
    name: ViewColumnSystemName.SpecialReportsDate,
    title: 'Special Report Date',
    type: ViewColumnDataType.Date,
  },
  [ViewColumnSystemName.ReportTitle]: {
    name: ViewColumnSystemName.ReportTitle,
    title: 'Report Title',
    type: ViewColumnDataType.Text,
  },
  [ViewColumnSystemName.NewsLink]: {
    name: ViewColumnSystemName.NewsLink,
    title: 'News Link',
    type: ViewColumnDataType.Complex,
  },
  [ViewColumnSystemName.ResearchRating]: {
    name: ViewColumnSystemName.ResearchRating,
    title: 'Rating',
    type: ViewColumnDataType.Text,
  },
  [ViewColumnSystemName.ProfileRating]: {
    name: ViewColumnSystemName.ProfileRating,
    title: 'Rating',
    type: ViewColumnDataType.Text,
  },
  [ViewColumnSystemName.Ticker]: {
    name: ViewColumnSystemName.Ticker,
    title: 'Ticker',
    type: ViewColumnDataType.Text,
  },
};
