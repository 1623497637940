import React from 'react';
import { NewsArticleDto } from '../interfaces/NewsArticleDto';
import TextContainer from '../../marketing/shared/TextContainer';
import { useClassName } from '../../shared/hooks/useClassName';
import NewsArticleRelated from './NewsArticleRelated';
import NewsArticleInfo from './NewsArticleInfo';
import './NewsArticleContent.scss';
import NewsCompanyLogo from '../components/NewsCompanyLogo';
import { CompanyTemplateService } from '../../company/services/CompanyTemplateService';
import Hyperlink from '../../shared/components/typography/Hyperlink';
import NewsKeywords from '../shared/components/NewsKeywords';

interface Props {
  article: NewsArticleDto;
}

const NewsArticleContent: React.FC<Props> = props => {
  const { article } = props;
  const cn = useClassName('NewsArticleContent');
  const hasRelatedNews = article?.relatedNews.length > 0;

  return (
    <div className={cn()}>
      <NewsArticleInfo article={article} />

      <TextContainer className={cn('text')}>
        {article.companyLogo && (
          <NewsCompanyLogo
            companyLogo={article.companyLogo}
            ticker={article.tickerSymbol}
            alt={article.companyLogoAltTag}
          />
        )}

        <div
          dangerouslySetInnerHTML={{
            __html: CompanyTemplateService.getTextWithHtmlLinksByTicker(article.newsSummary),
          }}
        />
      </TextContainer>

      {article.source && (
        <div className={cn('source')}>
          Read source story here:{' '}
          <Hyperlink to={article.link} isExternal isBlank>
            {article.source}
          </Hyperlink>
        </div>
      )}

      <div className={cn('links')}>
        <div className={cn('keywords')}>
          <NewsKeywords keywords={article.keywords} />
        </div>

        {hasRelatedNews && (
          <NewsArticleRelated
            className={cn('related')}
            ticker={article.tickerSymbol}
            articles={article.relatedNews}
            type={article.type}
          />
        )}
      </div>
    </div>
  );
};

export default NewsArticleContent;
