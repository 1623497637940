import { Reducer } from 'redux';
import { ActionTypes, SET_ACCOUNT_DATA, SET_RETURN_LOCATION, SET_IS_AUTHORIZED, State } from './types';
import TokenStorageService from '../../shared/services/token/TokenStorageService';

function createInitialState(): State {
  const token = TokenStorageService.getAccessToken();

  return {
    isAuthorized: !!token,
  };
}

export const reducer: Reducer<State, ActionTypes> = (state = createInitialState(), action) => {
  switch (action.type) {
    case SET_IS_AUTHORIZED:
      const { isAuthorized } = action;

      return {
        ...state,
        isAuthorized,
      };
    case SET_RETURN_LOCATION:
      return {
        ...state,
        returnLocation: action.location,
      };
    case SET_ACCOUNT_DATA:
      return {
        ...state,
        accountData: action.accountData,
      };
    default:
      return state;
  }
};
