import React, { useContext, useMemo } from 'react';
import CompanyRelativeValuationTables from './CompanyRelativeValuationTables';
import CompanySectionRow from '../shared/CompanySectionRow';
import './CompanyRelativeValuation.scss';
import CompanyRelativeValuationDescription from './CompanyRelativeValuationDescription';
import CompanySection from '../shared/CompanySection';
import { CompanyContext } from '../context/CompanyContext';
import { Nullable } from '../../../shared/types/generics';
import { CompanyRelativeValuationDto } from '../../interfaces/CompanyRelativeValuationDto';
import { CompanyTab } from '../../enums/CompanyTab';

const CompanyRelativeValuation: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const data: Nullable<CompanyRelativeValuationDto> = getTabData(CompanyTab.RelativeValuation);

  const titleProps = useMemo(() => {
    const ratingValue = data?.relativeValuation?.rating;

    return {
      tab: CompanyTab.RelativeValuation,
      ratingTitle: 'Relative Valuation Rating',
      hasExcel: true,
      ratingValue,
    };
  }, [data]);

  return (
    <>
      {data && <h3 className="printHead">Relative Valuation</h3>}
      <CompanySection className="CompanyRelativeValuation" data={data} titleProps={titleProps}>
        <CompanyRelativeValuationDescription />

        <CompanySectionRow>
          <CompanyRelativeValuationTables />
        </CompanySectionRow>
      </CompanySection>
    </>
  );
};

export default CompanyRelativeValuation;
