import React from 'react';
import CompanyProfileSteps from '../shared/components/steps/CompanyProfileSteps';
import CompanyOverview from '../shared/components/overview/CompanyOverview';
import CompanyCapitalization from '../shared/components/capitalization/CompanyCapitalization';
import CompanyUnderwriters from '../shared/components/underwriters/CompanyUnderwriters';
import CompanyFinancialData from '../shared/components/financial-data/CompanyFinancialData';
import CompanyKeyShareholders from '../shared/components/key-shareholders/CompanyKeyShareholders';
import CompanyManagement from '../shared/components/management/CompanyManagement';
import CompanyCompanyData from '../shared/components/company-data/CompanyCompanyData';
import CompanyBalanceSheet from '../shared/components/balance-sheet/CompanyBalanceSheet';
import './CompanyMobileProfile.scss';
import CompanyMilestones from '../shared/components/milestones/CompanyMilestones';
import CompanyAnalystNotesAndNews from '../shared/components/analyst-notes-and-news/CompanyAnalystNotesAndNews';
import CompanyLockUpPeriod from '../shared/components/lock-up-period/CompanyLockUpPeriod';
import CompanyPrivateCompanyData from '../shared/components/private-company-data/CompanyPrivateCompanyData';
import CompanyFundamentalComparisonTable from '../../shared/fundamental-comparison-table/CompanyFundamentalComparisonTable';
import CompanyValuationComparisonTable from '../../shared/valuation-comparison-table/CompanyValuationComparisonTable';
import CompanyTechnicalComparisonTable from '../../shared/technical-comparison-table/CompanyTechnicalComparisonTable';
import ProfileInsights from '../shared/components/profile-insights/ProfileInsights';
import CompanyPerfomanceVsIpoIndexChart from '../../shared/performance-vs-ipo-Index-chart/CompanyPerfomanceVsIpoIndexChart';
import CompanyOfferingData from '../shared/components/offering-data/CompanyOfferingData';
import CompanyResearch from '../shared/components/research/CompanyResearch';
import CompanySecondaryOfferingData from '../shared/components/secondary-offering/CompanySecondaryOfferingData';
import CompanySecFilings from '../shared/components/sec-filings/CompanySecFilings';

const CompanyMobileProfile: React.FC = () => {
  return (
    <div className="CompanyMobileProfile">
      <CompanyProfileSteps />

      <CompanyOverview />

      <CompanyAnalystNotesAndNews />

      <CompanyPrivateCompanyData />

      <CompanyResearch />

      <CompanySecFilings />

      <CompanySecondaryOfferingData />

      <CompanyOfferingData />

      <CompanyCapitalization />

      <CompanyUnderwriters />

      <ProfileInsights />

      <CompanyFinancialData />

      <CompanyBalanceSheet />

      <CompanyPerfomanceVsIpoIndexChart hasActions={false} />

      <CompanyFundamentalComparisonTable />

      <CompanyValuationComparisonTable />

      <CompanyTechnicalComparisonTable />

      <CompanyKeyShareholders />

      <CompanyManagement />

      <CompanyLockUpPeriod />

      <CompanyMilestones />

      <CompanyCompanyData />
    </div>
  );
};

export default CompanyMobileProfile;
