import { DealStatus } from '../../enum/DealStatus';

export const dealStatusInOptions = [
  {
    value: DealStatus.Scheduled,
    title: 'Scheduled',
  },
  {
    value: DealStatus.Priced,
    title: 'Priced IPOs',
  },
  {
    value: DealStatus.PCW,
    title: 'Private Co',
  },
  {
    value: DealStatus.Filed,
    title: 'On File',
  },
  {
    value: DealStatus.Postponed,
    title: 'Include Postponed',
    dependsFrom: DealStatus.Filed,
  },
];
