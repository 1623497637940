import React from 'react';
import './HtmlStringFormatterJustified.scss';
import { HtmlString } from '../types/HtmlString';
import reactHtmlParse from 'react-html-parser';
import convertHtml from '../helpers/convertHtml';
import { HtmlStringFormatterJustifiedBlock } from './htmlStringFormatterJustifiedBem';
import { useClassName } from '../hooks/useClassName';

import { ComponentMessage } from '../enums/ComponentMessage';

interface Props {
  value: HtmlString;
}

const HtmlStringFormatterJustified: React.FC<Props> = props => {
  const cn = useClassName(HtmlStringFormatterJustifiedBlock.Root);

  return (
    <div className={cn()}>
      {props.value === null ? ComponentMessage.NoDataShort : reactHtmlParse(convertHtml(props.value))}
    </div>
  );
};

export default HtmlStringFormatterJustified;
