import { ViewOptions } from '../../../shared/entities/view/components/view/interfaces/options/ViewOptions';
import { FilterSectionType } from '../../../shared/entities/filters/enum/FilterSectionType';
import { ViewFilterName } from '../../../shared/entities/filters/enum/types/ViewFilterName';
import { FileActionType } from '../../../shared/components/file-actions/enums/FileActionType';
import { ViewSummaryAlignType } from '../../../shared/entities/view/components/view/enums/ViewSummaryAlignType';
import { ViewColumnSystemName } from '../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { GridHeightMultiplier } from '../../../shared/components/grid/grid/enums/GridHeightMultiplier';
import { pcmlSubheaderHelper } from '../../constants/subHeaderHelpers';

export const privateCompanyMonitorListViewOptions: ViewOptions = {
  header: 'Private Company Monitor List',
  subHeader: pcmlSubheaderHelper,
  toolbar: {
    modalFilters: {
      sections: [
        {
          type: FilterSectionType.Date,
          controls: [{ name: ViewFilterName.DateUpdateInPast }],
        },
        {
          type: FilterSectionType.Investors,
          controls: [{ name: ViewFilterName.Investor }],
        },
        {
          type: FilterSectionType.CompanyInfo,
          controls: [{ name: ViewFilterName.Industry }],
        },
        {
          type: FilterSectionType.Valuation,
          controls: [{ name: ViewFilterName._Valuation }],
        },
      ],
    },
    quickFilter: {
      hasPcwNav: true,
    },
    fileActionTypes: [FileActionType.Save, FileActionType.Excel, FileActionType.Print],
  },
  grid: {
    isFixedHeight: false,
    customColumnsSettings: [
      {
        field: ViewColumnSystemName.PcmlCompanyName,
        heightMultiplier: GridHeightMultiplier.Sx,
      },
    ],
  },
  summary: {
    align: ViewSummaryAlignType.Simple,
  },
};
