import React, { ReactNode } from 'react';
import './CellNoteTooltip.scss';
import { GridRow } from '../../../../../../components/grid/shared/types/GridRow';
import Tooltip from '../../../../../../components/tooltip/Tooltip';
import { ViewGridCellValueService } from '../../services/ViewGridCellValueService';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { TooltipPosition } from '../../../../../../components/tooltip/enums/TooltipPosition';

interface Props {
  data: GridRow;
  value: ReactNode;
}

const CellNoteTooltip: React.FC<Props> = props => {
  const note = ViewGridCellValueService.getRaw(props.data, ViewColumnSystemName.Footnote);
  const companyTicker = ViewGridCellValueService.getRaw(props.data, ViewColumnSystemName.CompanyTicker);

  return (
    <>
      {note ? (
        <Tooltip
          title={props.value}
          content={
            <div className="CellNoteTooltip">
              <b>{companyTicker}</b>
              <div>{note}</div>
            </div>
          }
          size={ControlSize.Medium}
          position={TooltipPosition.Right}
          isTitleHighlighted
        />
      ) : (
        props.value
      )}
    </>
  );
};

export default CellNoteTooltip;
