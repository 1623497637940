import * as yup from 'yup';
import { LoginFormField } from './LoginFormField';
import { getPasswordSchema } from '../../../shared/components/forms/services/validation/contstants/validation/passwordSchema';

export const validationSchema = yup.object().shape({
  [LoginFormField.Email]: yup
    .string()
    .email()
    .required()
    .label('Business Email'),
  [LoginFormField.Password]: getPasswordSchema('Password'),
});
