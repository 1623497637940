import React from 'react';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';

type Props = GridCellFormatterCommonProps;

const PcmlLastFundingDateCell: React.FC<Props> = props => {
  let formattedDate = '';

  if (props.value) {
    const date = new Date(props.value);
    formattedDate = new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
    }).format(date);
  }

  return <>{formattedDate}</>;
};

export default PcmlLastFundingDateCell;
