import { invert } from 'lodash';
import { ViewType } from '../enums/ViewType';
import { RoutePath } from '../../../../../enums/RoutePath';
import { ViewRoutePath } from '../types/ViewRoutePath';

export const viewTypeRoutes: { [key in ViewType]: ViewRoutePath } = {
  [ViewType.Priced]: RoutePath.Priced,
  [ViewType.Scheduled]: RoutePath.Scheduled,
  [ViewType.RecentResearch]: RoutePath.ResearchRecent,
  [ViewType.Pipeline]: RoutePath.Pipeline,
  [ViewType.PrivateCompanyWatchlist]: RoutePath.PrivateCompanyWatchlist,
  [ViewType.PrivateCompanyMonitorList]: RoutePath.PrivateCompanyMonitorList,
  [ViewType.UnderwriterLanding]: RoutePath.ScreensUnderwriterLanding,
  [ViewType.UnderwriterInUse]: RoutePath.ScreensUnderwriterInUse,
  [ViewType.IndustryLanding]: RoutePath.ScreensIndustryLanding,
  [ViewType.IndustryInUse]: RoutePath.ScreensIndustryInUse,
  [ViewType.LocationLanding]: RoutePath.ScreensLocationLanding,
  [ViewType.LocationInUse]: RoutePath.ScreensLocationInUse,
  [ViewType.SpacsLanding]: RoutePath.ScreensSpacsLanding,
  [ViewType.SpacsInUse]: RoutePath.ScreensSpacsInUse,
  [ViewType.LockUpsInUse]: RoutePath.ScreensLockUpsInUse,
  [ViewType.InvestorsLanding]: RoutePath.ScreensInvestorsLanding,
  [ViewType.InvestorsInUse]: RoutePath.ScreensInvestorsInUse,
  [ViewType.EstimatesInUse]: RoutePath.ScreensEstimatesInUse,
  [ViewType.CustomInUse]: RoutePath.ScreensCustomInUse,
  [ViewType.WithdrawnPostponedInUse]: RoutePath.ScreensWithdrawnPostponedInUse,
};

export const viewTypeRoutesInverted = invert(viewTypeRoutes) as { [key in ViewRoutePath]: ViewType };
