import React, { useCallback, useContext, useMemo } from 'react';
import './EmailPreferencesForm.scss';
import Form from '../../shared/components/forms/Form';
import { validationSchema } from './validationSchema';
import { ControlSize } from '../../shared/enums/ControlSize';
import ButtonSubmit from '../../shared/components/forms/ButtonSubmit';
import EmailPreferencesFormSection from './components/EmailPreferencesFormSection';
import FormGroup from '../../shared/components/forms/FormGroup';
import { EmailPreferencesContext } from '../context/EmailPreferencesContext';
import { EmailPreferencesFormData } from './EmailPreferencesFormData';
import { EmailPreferencesService } from '../services/EmailPreferencesService';
import { ObjectHelper } from '../../shared/helpers/ObjectHelper';
import { EmailPreferenceSectionItem } from './interfaces/EmailPreferenceSectionItem';
import { useSelector } from 'react-redux';
import { userNameSelector } from '../../store/auth/selectors';
import { EMAIL_PREFERENCES_SUBMITTED_MESSAGE } from './constants/submittedMessage';

const EmailPreferencesForm: React.FC = () => {
  const userName = useSelector(userNameSelector);

  const { initialLoadedData, sectionsData, setSectionsData, updateAllSections } = useContext(EmailPreferencesContext);

  const defaultValues = useMemo(() => EmailPreferencesService.getFormDataFromSectionsItems(initialLoadedData), [
    initialLoadedData,
  ]);

  const getSuccessMessage = useCallback(() => ({ text: EMAIL_PREFERENCES_SUBMITTED_MESSAGE, values: [] }), []);

  const onChange = useCallback(
    (value: EmailPreferencesFormData) => {
      setSectionsData(EmailPreferencesService.getSectionsItemsFromFormData(sectionsData, value));
    },
    [setSectionsData, sectionsData],
  );

  return (
    <>
      {!ObjectHelper.arePropsEmpty(sectionsData) && (
        <Form
          className="EmailPreferencesForm"
          validationSchema={validationSchema}
          defaultValues={defaultValues}
          size={ControlSize.ExtraLarge}
          getSuccessMessage={getSuccessMessage}
          onSubmit={updateAllSections}
          onChange={onChange}
        >
          <FormGroup>
            <b>Username: {userName}</b>
          </FormGroup>

          <FormGroup>
            <b>Check the emails you&apos;d like to receive</b>
          </FormGroup>

          <EmailPreferencesFormSection
            title="Email Types"
            sectionItems={sectionsData.emailTypes as EmailPreferenceSectionItem[]}
          />

          <EmailPreferencesFormSection
            title="Filter by Criteria"
            sectionItems={sectionsData.filterByCriteria as EmailPreferenceSectionItem[]}
          />

          <ButtonSubmit message="Save Email Preferences" submittedMessage="" isSubmitted={false} />
        </Form>
      )}
    </>
  );
};

export default EmailPreferencesForm;
