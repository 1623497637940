import React, { useContext, useMemo } from 'react';
import FilterControl from '../FilterControl';
import { ViewFilterName } from '../../../../enum/types/ViewFilterName';
import RenaissanceRatingDetails from '../controls/renaissance-rating/RenaissanceRatingDetails';
import { FilterSectionsFormContext } from '../../context/FilterSectionsFormContext';
import { FilterSettingsService } from '../../../../services/FilterSettingsService';
import { renaissanceRatingControlsDetailsList } from '../../../../constants/filter-controls/view-filter-sections/renaissanceRatingDetailsControlsList';

const RenaissanceRatingFilterSection: React.FC = () => {
  const { settings } = useContext(FilterSectionsFormContext);
  const hasDetails = useMemo(
    () => FilterSettingsService.containFilters(settings.sections, renaissanceRatingControlsDetailsList),
    [settings.sections],
  );

  return (
    <>
      <FilterControl name={ViewFilterName.RcRating} />

      {hasDetails && <RenaissanceRatingDetails />}
    </>
  );
};

export default RenaissanceRatingFilterSection;
