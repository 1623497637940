import React from 'react';
import CompanyInsights from '../../../../shared/CompanyInsights';
import { InsightsVariant } from '../../../../shared/enums/InsightsVariant';
import './CompanyProfileInsights.scss';
import CompanyProfileSideItem from '../CompanyProfileSideItem';

export interface CompanyProfileInsightsProps {
  bullInsights: string[];
  bearInsights: string[];
}

const CompanyProfileInsights: React.FC<CompanyProfileInsightsProps> = props => {
  return (
    <>
      {props && (
        <CompanyProfileSideItem isUnderlinedTitle={false} className="CompanyProfileInsights">
          <CompanyInsights title="Bull Insights" variant={InsightsVariant.Positive} items={props.bullInsights} />

          <CompanyInsights title="Bear Insights" variant={InsightsVariant.Negative} items={props.bearInsights} />
        </CompanyProfileSideItem>
      )}
    </>
  );
};

export default CompanyProfileInsights;
