import React, { useContext, useMemo } from 'react';
import './ViewSummaryItem.scss';
import { isNil } from 'lodash';
import { ViewSummaryField } from '../../enums/ViewSummaryField';
import { useClassName } from '../../../../../../hooks/useClassName';
import { ViewSummaryItemBlock, ViewSummaryItemElement } from './viewSummaryItemBem';
import ColorNumberFormatter from '../../../../../../components/formatters/ColorNumberFormatter';
import { ViewGridCellValueService } from '../../../view-grid/services/ViewGridCellValueService';
import { ViewContext } from '../../ViewContext';
import { ComponentMessage } from '../../../../../../enums/ComponentMessage';

interface Props {
  title: string;
  field: ViewSummaryField;
  hasFormatter?: boolean;
  formatterBaseValue?: number;
}

const ViewSummaryItem: React.FC<Props> = props => {
  const { title, field, hasFormatter = true, formatterBaseValue } = props;

  const cn = useClassName(ViewSummaryItemBlock.Root);

  const { state } = useContext(ViewContext);

  const value = useMemo(() => state.summary?.[field], [state.summary, field]);
  const displayValue = useMemo(
    () => (isNil(value) ? ComponentMessage.NoDataShort : ViewGridCellValueService.getVisible(state.summary, field)),
    [state.summary, field, value],
  );

  return (
    <>
      {value !== undefined && (
        <div className={cn()}>
          <span className={cn(ViewSummaryItemElement.Title)}>{title}: </span>
          {hasFormatter ? (
            <ColorNumberFormatter
              value={value as number}
              displayValue={displayValue}
              formatterBaseValue={formatterBaseValue}
            />
          ) : (
            displayValue
          )}
        </div>
      )}
    </>
  );
};

export default ViewSummaryItem;
