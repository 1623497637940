import React, { useContext } from 'react';
import { ViewDashboardContext } from '../../../shared/entities/view/components/view-dashboard/ViewDashboardContext';
import { SearchResultsFilterGroupName } from '../../enums/SearchResultsFilterGroupName';
import { ViewDataTable } from '../../../shared/entities/view/components/view/interfaces/api/ViewDataTable';
import SearchResultsGrid from './SearchResultsGrid';
import { newsFiltersControls } from '../../shared/constants/controls/newsFiltersControls';

interface Props {
  onChange?: () => void;
}

const SearchResultsNewsGrid: React.FC<Props> = props => {
  const { data } = useContext(ViewDashboardContext);
  const dataTable = data?.[SearchResultsFilterGroupName.News] as ViewDataTable;

  return (
    <SearchResultsGrid
      gridTitle={'News'}
      dataTable={dataTable}
      searchControls={newsFiltersControls}
      filterGroupName={SearchResultsFilterGroupName.News}
      onChange={props.onChange}
    />
  );
};

export default SearchResultsNewsGrid;
