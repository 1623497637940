import { SearchApiService } from '../../../../shared/api/SearchApiService';
import { AutocompleteItem } from '../../../../shared/components/autocomplete/interfaces/AutocompleteItem';
import { SearchItemCompanyDto } from '../interfaces/dto/SearchItemCompanyDto';
import { SearchItemCompanyDtoProps } from '../interfaces/dto/enums/SearchItemCompanyDtoProps';

export class CompanySearchDataService {
  static async getNotFavorite(
    searchTerm: string,
    valueProp: SearchItemCompanyDtoProps,
  ): Promise<AutocompleteItem<SearchItemCompanyDto>[]> {
    const companies = await SearchApiService.getNotFavoriteCompanies(searchTerm);

    return this.getAutoCompleteSearchItems(companies, valueProp);
  }

  static async getAll(
    searchTerm: string,
    valueProp: SearchItemCompanyDtoProps,
  ): Promise<AutocompleteItem<SearchItemCompanyDto>[]> {
    const companies = await SearchApiService.getAllCompanies(searchTerm);

    return this.getAutoCompleteSearchItems(companies, valueProp);
  }

  private static getAutoCompleteSearchItems(
    companies: SearchItemCompanyDto[],
    valueProp: SearchItemCompanyDtoProps,
  ): AutocompleteItem<SearchItemCompanyDto>[] {
    return companies.map(company => ({
      title: company.companyTicker,
      value: company[valueProp],
      ...company,
    }));
  }
}
