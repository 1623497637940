import { ApiPath } from './enums/ApiPath';
import { MasterCalendarDto } from '../../master-calendar/interfaces/MasterCalendarDto';
import ApiService from './ApiService';
import { CalendarEvent } from '../entities/calendar/interfaces/CalendarEvent';

export default class CalendarApiService {
  static getMasterCalendar(): Promise<MasterCalendarDto> {
    return ApiService.get<MasterCalendarDto>(ApiPath.CalendarMaster);
  }

  static getScheduledCalendar(): Promise<CalendarEvent[]> {
    return ApiService.get<CalendarEvent[]>(ApiPath.CalendarScheduled);
  }
}
