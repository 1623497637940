import { NotificationSuccessMessage } from '../../../../../../../store/notification/types';
import { GlobalError } from '../../../../../../services/error/types/GlobalError';
import { SET_VIEW_ERROR, SET_VIEW_SUCCESS, SetViewError, SetViewSuccess } from '../types';

export const setViewSuccess = (successMessage: NotificationSuccessMessage): SetViewSuccess => {
  return {
    type: SET_VIEW_SUCCESS,
    payload: {
      success: successMessage,
    },
  };
};

export const setViewError = (error: GlobalError): SetViewError => {
  return {
    type: SET_VIEW_ERROR,
    payload: {
      error,
    },
  };
};
