import React, { useMemo } from 'react';
import './RotatedGrid.scss';
import RotatedGridRow, { RotatedGridRowProps } from './RotatedGridRow';
import { isNil } from 'lodash';
import { useClassName } from '../../hooks/useClassName';
import { RotatedGridBlock, RotatedGridModifier } from './RotatedGridBem';
import { ControlSize } from '../../enums/ControlSize';

export interface RotatedGridProps {
  rows: RotatedGridRowProps[];
  size?: ControlSize;
  hasNoTitleMaxWidth?: boolean;
  hasTitleNoWrap?: boolean;
  isGridAutoWidth?: boolean;
  className?: string;
}

const RotatedGrid: React.FC<RotatedGridProps> = props => {
  const { size = ControlSize.Medium } = props;

  const cn = useClassName(RotatedGridBlock.Root, props.className);

  const visibleRows = useMemo<RotatedGridRowProps[]>(() => props.rows.filter(row => !isNil(row.data) || row.isEmpty), [
    props.rows,
  ]);

  return (
    <table
      className={cn({
        [RotatedGridModifier.AutoWidth]: props.isGridAutoWidth,
      })}
    >
      <tbody>
        {visibleRows.map((row: RotatedGridRowProps, index: number) => (
          <RotatedGridRow
            key={index}
            size={size}
            hasNoTitleMaxWidth={props.hasNoTitleMaxWidth}
            hasTitleNoWrap={props.hasTitleNoWrap}
            {...row}
          />
        ))}
      </tbody>
    </table>
  );
};

export default RotatedGrid;
