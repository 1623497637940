import { Token } from '../../types';
import { TokenStorageKey } from './enums/TokenStorageKey';
import StorageService from '../storage/StorageService';
import { TokenDto } from '../../interfaces/TokenDto';

export default class TokenStorageService {
  static setTokens(tokenDto: TokenDto, shouldRemember: boolean): void {
    StorageService.setItem(TokenStorageKey.AccessToken, tokenDto.token, shouldRemember);
    StorageService.setItem(TokenStorageKey.RefreshToken, tokenDto.refreshToken, shouldRemember);
    StorageService.setItem(TokenStorageKey.GaAccountId, tokenDto.gaAccountId, shouldRemember);
    StorageService.setItem(TokenStorageKey.GaUserId, tokenDto.gaUserid, shouldRemember);

    if (shouldRemember) {
      StorageService.setItem(TokenStorageKey.ShouldRemember, shouldRemember.toString(), true);
    }
  }

  static updateTokens(tokenDto: TokenDto): void {
    this.setTokens(tokenDto, this.isShouldRemember());
  }

  static removeTokens(): void {
    StorageService.removeItem(TokenStorageKey.AccessToken);
    StorageService.removeItem(TokenStorageKey.RefreshToken);
    StorageService.removeItem(TokenStorageKey.ShouldRemember);
  }

  static getAccessToken(): Token | null {
    return StorageService.getItem(TokenStorageKey.AccessToken);
  }

  static getRefreshToken(): Token | null {
    return StorageService.getItem(TokenStorageKey.RefreshToken);
  }

  static isShouldRemember(): boolean {
    return !!StorageService.getItem(TokenStorageKey.ShouldRemember);
  }
}
