import React, { useMemo } from 'react';
import './Step.scss';
import { useClassName } from '../../hooks/useClassName';
import { StepStatus } from './enums/StepStatus';
import { StepBlock, StepElement } from './stepBem';
import Tooltip from '../tooltip/Tooltip';
import { ControlSize } from '../../enums/ControlSize';

export interface StepCommonProps {
  title: React.ReactNode;
  description?: React.ReactNode;
}

export interface StepProps extends StepCommonProps {
  stepStatus?: StepStatus;
  tooltipContent?: React.ReactNode;
  isAbsoluteTitle?: boolean;
}

const Step: React.FC<StepProps> = props => {
  const cn = useClassName(StepBlock.Root);

  const point = useMemo(
    () => (
      <div className={cn(StepElement.Point)}>
        <b
          className={cn(StepElement.Title, {
            absolute: props.isAbsoluteTitle,
          })}
        >
          {props.title}
        </b>

        <div className={cn(StepElement.Description)}>{props.description}</div>

        <div className={cn(StepElement.Circle)} />
      </div>
    ),
    [cn, props.description, props.title, props.isAbsoluteTitle],
  );

  const pointWithTooltip = useMemo(
    () =>
      props.tooltipContent ? <Tooltip size={ControlSize.Medium} title={point} content={props.tooltipContent} /> : point,
    [point, props.tooltipContent],
  );

  return (
    <div
      className={cn({
        [`${props.stepStatus}`]: props.stepStatus,
      })}
    >
      <div
        className={cn(StepElement.ProgressLine, {
          [`${props.stepStatus}`]: props.stepStatus,
        })}
      />

      {pointWithTooltip}
    </div>
  );
};

export default Step;
