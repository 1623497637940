import React, { useMemo } from 'react';
import './CompanyTooltipContent.scss';
import { getFirstTable, getSecondTable, getThirdTable } from './getCompanyTooltipData';
import { CompanyTooltipDto } from '../../interfaces/CompanyTooltipDto';
import { TooltipContentProps } from '../../../shared/components/tooltip/interface/TooltipContentProps';
import { useClassName } from '../../../shared/hooks/useClassName';
import { CompanyTooltipContentBlock, CompanyTooltipContentElement } from './CompanyTooltipContentBem';
import { AlignTypesHorizontal } from '../../../shared/enums/AlignTypesHorizontal';
import CompanyLink from '../company-link/CompanyLink';
import CompanyTooltipContentGrid from './CompanyTooltipContentGrid';

const CompanyTooltipContent: React.FC<TooltipContentProps<CompanyTooltipDto>> = props => {
  const cn = useClassName(CompanyTooltipContentBlock.Root);

  const { data } = props;
  const firstTable = useMemo(() => getFirstTable(data), [data]);
  const secondTable = useMemo(() => getSecondTable(data), [data]);
  const thirdTable = useMemo(() => getThirdTable(data), [data]);

  return (
    <section className={cn()}>
      <div className={cn(CompanyTooltipContentElement.Header)}>
        <h5 className={cn(CompanyTooltipContentElement.Title)}>
          <CompanyLink ticker={data.tickerSymbol} companyName={data.companyName} />
        </h5>
        {data.companyStatus && <div className={cn(CompanyTooltipContentElement.Info)}>{data.companyStatus}</div>}
      </div>

      {data.businessOverview && (
        <div className={cn(CompanyTooltipContentElement.Description)}>{data.businessOverview}</div>
      )}

      <div className={cn(CompanyTooltipContentElement.TableData)}>
        <CompanyTooltipContentGrid rows={firstTable} />

        <CompanyTooltipContentGrid
          className={cn(CompanyTooltipContentElement.Financial)}
          title={{
            component: <b>At IPO</b>,
            alignTypeHorizontal: AlignTypesHorizontal.End,
          }}
          rows={secondTable}
        />

        <CompanyTooltipContentGrid rows={thirdTable} />

        {data.footnote && (
          <div className={cn(CompanyTooltipContentElement.Footnote)}>
            <b>Note:</b> {data.footnote}
          </div>
        )}
      </div>
    </section>
  );
};

export default CompanyTooltipContent;
