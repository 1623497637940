import { NewsSectionType } from '../enums/NewsSectionType';
import { Url } from '../../shared/types';
import { NewsLocationService } from '../shared/services/NewsLocationService';
import { NewsFilterField } from '../enums/NewsFilterField';
import { NewsType } from '../enums/NewsType';
import { NewsLocation } from '../enums/NewsLocation';

export const newsSectionData: { [key in NewsSectionType]: { title: string; url: Url } } = {
  [NewsSectionType.RcArticles]: {
    title: 'RC Articles',
    url: NewsLocationService.createSearchUrl({
      [NewsFilterField.NewsType]: [NewsType.AnalystNote, NewsType.Blog, NewsType.NewsAlert],
    }),
  },
  [NewsSectionType.UsExternalNews]: {
    title: 'US External News',
    url: NewsLocationService.createSearchUrl({
      [NewsFilterField.Location]: NewsLocation.US,
      [NewsFilterField.NewsType]: [NewsType.External],
    }),
  },
  [NewsSectionType.NonUsExternalNews]: {
    title: 'Non-US External News',
    url: NewsLocationService.createSearchUrl({
      [NewsFilterField.Location]: NewsLocation.NonUS,
      [NewsFilterField.NewsType]: [NewsType.External],
    }),
  },
};
