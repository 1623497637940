import { FileActionType } from '../../../../../components/file-actions/enums/FileActionType';
import { GridCellFormatterFileActionsFormatterProp } from '../../../../../components/grid/shared/interfaces/gridCellFormatter';
import { GridRow } from '../../../../../components/grid/shared/types/GridRow';
import { FileActionProp } from '../../../../../components/file-actions/interfaces/FileActionProp';
import { CompanyLocationService } from '../../../../../../company/services/CompanyLocationService';
import { ViewColumnSystemName } from '../enums/ViewColumnSystemName';

export const printCompanyFileAction: GridCellFormatterFileActionsFormatterProp = {
  type: FileActionType.Document,
  mapFn: (action: GridCellFormatterFileActionsFormatterProp, data: GridRow): FileActionProp => ({
    ...action,
    routePath: CompanyLocationService.getPrintCompanyPath(data[ViewColumnSystemName.Ticker]),
  }),
};
