import { ViewColumnSystemName } from '../enums/ViewColumnSystemName';

interface ColumnTypeToCellFormatterType {
  [key: string]: ViewColumnSystemName;
}

export const columnTypeToCellFormatter: ColumnTypeToCellFormatterType = {
  [ViewColumnSystemName.Statement as string]: ViewColumnSystemName.Statement,
  [ViewColumnSystemName.Stubs as string]: ViewColumnSystemName.Stubs,
  [ViewColumnSystemName.StatsYear as string]: ViewColumnSystemName.StatsYear,
};
