import React from 'react';
import FilterControl from '../../FilterControl';
import { ViewFilterName } from '../../../../../enum/types/ViewFilterName';
import { useStatusAvailabilityDependencies } from '../../../../../hooks/useStatusAvailabilityDependencies';

const STK_CATEGORY_FILTER_NAMES = [
  ViewFilterName.SecurityTypeCommonSharesList,
  ViewFilterName.SecurityTypeADRsList,
  ViewFilterName.SecurityTypeBlankCheckList,
  ViewFilterName.IncludeSpac,
  ViewFilterName.SecurityTypeRegAList,
  ViewFilterName.SecurityTypeDirectListingList,
];

const CTK_CATEGORY_FILTER_NAMES = [ViewFilterName.SecurityTypeREITList, ViewFilterName.SecurityTypeMLPList];

const SecurityTypeListControls: React.FC = () => {
  useStatusAvailabilityDependencies(STK_CATEGORY_FILTER_NAMES);
  useStatusAvailabilityDependencies(CTK_CATEGORY_FILTER_NAMES);

  return (
    <>
      <FilterControl name={ViewFilterName.SecurityTypeCommonSharesList} />

      <FilterControl name={ViewFilterName.SecurityTypeADRsList} />

      <FilterControl name={ViewFilterName.SecurityTypeREITList} />

      <FilterControl name={ViewFilterName.SecurityTypeMLPList} />

      <FilterControl name={ViewFilterName.SecurityTypeBlankCheckList} />

      <FilterControl name={ViewFilterName.IncludeSpac} />

      <FilterControl name={ViewFilterName.SecurityTypeRegAList} />

      <FilterControl name={ViewFilterName.SecurityTypeDirectListingList} />
    </>
  );
};

export default SecurityTypeListControls;
