import React from 'react';
import ScreensLanding from '../../shared/pages/components/landing/ScreensLanding';
import { screensInvestorsLandingOptions } from './constants/screensInvestorsLandingOptions';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';

const ScreensInvestorsLanding: React.FC = () => {
  return <ScreensLanding landingType={ViewType.InvestorsLanding} options={screensInvestorsLandingOptions} />;
};

export default ScreensInvestorsLanding;
