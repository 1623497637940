import { RcRating } from '../../enum/RcRating';

export const rcRatingOptions = [
  {
    title: 'Outperform/Strong',
    value: RcRating.OutperformStrong,
  },
  {
    title: 'Marketperform/Neutral',
    value: RcRating.MarketperformNeutral,
  },
  {
    title: 'Underperform/Weak',
    value: RcRating.UnderperformWeak,
  },
  {
    title: 'Not Rated',
    value: RcRating.NotRated,
  },
];
