import { defaultsDeep } from 'lodash';
import { DashboardWidgetConfig } from '../interfaces/DashboardWidgetConfig';
import { DASHBOARD_WIDGET_DEFAULT_OPTIONS } from './dashboardWidgetDefaultOptions';

export const dashboardDefaultWidgetsConfigs: DashboardWidgetConfig[] = [
  {
    type: 'NewsAndCommentary',
    layouts: {
      lg: { width: 4, height: 10, top: 0, left: 0, minHeight: 6 },
      md: { width: 4, height: 10, top: 0, left: 0, minHeight: 6 },
      sm: { width: 6, height: 12, top: 0, left: 0, minHeight: 6 },
      xs: { width: 12, height: 8, top: 0, left: 0, minHeight: 6 },
      xxs: { width: 12, height: 8, top: 0, left: 0, minHeight: 6 },
    },
  },
  {
    type: 'Calendar',
    layouts: {
      lg: { width: 8, height: 10, top: 0, left: 4, minHeight: 6 },
      md: { width: 8, height: 10, top: 0, left: 4, minHeight: 6 },
      sm: { width: 6, height: 12, top: 0, left: 6, minHeight: 6 },
      xs: { width: 12, height: 8, top: 8, left: 0, minHeight: 6 },
      xxs: { width: 12, height: 8, top: 8, left: 0, minHeight: 6 },
    },
  },
  {
    type: 'UpcomingIPOs',
    layouts: {
      lg: { width: 6, height: 9, top: 10, left: 0, minHeight: 6, minWidth: 4 },
      md: { width: 6, height: 9, top: 10, left: 0, minHeight: 6 },
      sm: { width: 12, height: 9, top: 12, left: 0, minHeight: 6 },
      xs: { width: 12, height: 9, top: 16, left: 0, minHeight: 6 },
      xxs: { width: 12, height: 9, top: 16, left: 0, minHeight: 6 },
    },
  },
  {
    type: 'Priced',
    layouts: {
      lg: { width: 6, height: 9, top: 10, left: 6, minHeight: 6 },
      md: { width: 6, height: 9, top: 10, left: 6, minHeight: 6 },
      sm: { width: 12, height: 9, top: 21, left: 0, minHeight: 6 },
      xs: { width: 12, height: 9, top: 25, left: 0, minHeight: 6 },
      xxs: { width: 12, height: 9, top: 25, left: 0, minHeight: 6 },
    },
  },
  {
    type: 'RecentResearch',
    layouts: {
      lg: { width: 4, height: 9, top: 19, left: 0, minHeight: 6 },
      md: { width: 4, height: 9, top: 19, left: 0, minHeight: 6 },
      sm: { width: 6, height: 9, top: 30, left: 0, minHeight: 6 },
      xs: { width: 12, height: 9, top: 34, left: 0, minHeight: 6 },
      xxs: { width: 12, height: 9, top: 34, left: 0, minHeight: 6 },
    },
  },
  {
    type: 'NewFilings',
    layouts: {
      lg: { width: 4, height: 9, top: 19, left: 4, minHeight: 6 },
      md: { width: 4, height: 9, top: 19, left: 4, minHeight: 6 },
      sm: { width: 6, height: 9, top: 30, left: 6, minHeight: 6 },
      xs: { width: 12, height: 9, top: 43, left: 0, minHeight: 6 },
      xxs: { width: 12, height: 9, top: 43, left: 0, minHeight: 6 },
    },
  },
  {
    type: 'IPOsOnMove',
    layouts: {
      lg: { width: 4, height: 9, top: 19, left: 8, minHeight: 6, minWidth: 2 },
      md: { width: 4, height: 9, top: 19, left: 8, minHeight: 6, minWidth: 2 },
      sm: { width: 6, height: 9, top: 39, left: 0, minHeight: 6, minWidth: 2 },
      xs: { width: 12, height: 9, top: 52, left: 0, minHeight: 6, minWidth: 2 },
      xxs: { width: 12, height: 9, top: 52, left: 0, minHeight: 6, minWidth: 2 },
    },
  },
  {
    type: 'MostViewedIPOs',
    layouts: {
      lg: { width: 4, height: 9, top: 28, left: 0, minHeight: 6 },
      md: { width: 4, height: 9, top: 28, left: 0, minHeight: 6 },
      sm: { width: 6, height: 9, top: 39, left: 6, minHeight: 6 },
      xs: { width: 12, height: 9, top: 61, left: 0, minHeight: 6 },
      xxs: { width: 12, height: 9, top: 61, left: 0, minHeight: 6 },
    },
  },

  {
    type: 'MyFavourites',
    layouts: {
      lg: { width: 4, height: 9, top: 28, left: 4, minHeight: 6, minWidth: 2 },
      md: { width: 4, height: 9, top: 28, left: 4, minHeight: 6, minWidth: 2 },
      sm: { width: 6, height: 9, top: 48, left: 0, minHeight: 6, minWidth: 2 },
      xs: { width: 12, height: 9, top: 70, left: 0, minHeight: 6, minWidth: 2 },
      xxs: { width: 12, height: 9, top: 70, left: 0, minHeight: 6, minWidth: 2 },
    },
  },
  {
    type: 'MyScreens',
    layouts: {
      lg: { width: 2, height: 9, top: 28, left: 8, minHeight: 6 },
      md: { width: 2, height: 9, top: 28, left: 8, minHeight: 6 },
      sm: { width: 3, height: 9, top: 48, left: 6, minHeight: 6 },
      xs: { width: 6, height: 7, top: 79, left: 0, minHeight: 6 },
      xxs: { width: 6, height: 7, top: 79, left: 0, minHeight: 6 },
    },
  },
  {
    type: 'MyViews',
    layouts: {
      lg: { width: 2, height: 9, top: 28, left: 10, minHeight: 6 },
      md: { width: 2, height: 9, top: 28, left: 10, minHeight: 6 },
      sm: { width: 3, height: 9, top: 48, left: 9, minHeight: 6 },
      xs: { width: 6, height: 7, top: 79, left: 6, minHeight: 6 },
      xxs: { width: 6, height: 7, top: 79, left: 6, minHeight: 6 },
    },
  },
  {
    type: 'SpecialReports',
    layouts: {
      lg: { width: 4, height: 9, top: 37, left: 0, minHeight: 6 },
      md: { width: 4, height: 9, top: 37, left: 0, minHeight: 6 },
      sm: { width: 12, height: 9, top: 57, left: 0, minHeight: 6 },
      xs: { width: 12, height: 9, top: 86, left: 0, minHeight: 6 },
      xxs: { width: 12, height: 9, top: 86, left: 0, minHeight: 6 },
    },
  },

  {
    type: 'USIPOMarketIndex',
    layouts: {
      lg: { width: 4, height: 9, top: 37, left: 4, minHeight: 6 },
      md: { width: 4, height: 9, top: 37, left: 4, minHeight: 6 },
      sm: { width: 12, height: 9, top: 66, left: 0, minHeight: 6 },
      xs: { width: 12, height: 10, top: 95, left: 0, minHeight: 6 },
      xxs: { width: 12, height: 10, top: 95, left: 0, minHeight: 6 },
    },
  },
  {
    type: 'RenaissanceUSIPOIndex',
    layouts: {
      lg: { width: 4, height: 9, top: 37, left: 8, minHeight: 6 },
      md: { width: 4, height: 9, top: 37, left: 8, minHeight: 6 },
      sm: { width: 12, height: 9, top: 75, left: 0, minHeight: 6 },
      xs: { width: 12, height: 10, top: 105, left: 0, minHeight: 6 },
      xxs: { width: 12, height: 10, top: 105, left: 0, minHeight: 6 },
    },
  },
].map(config =>
  defaultsDeep({}, config, {
    layouts: DASHBOARD_WIDGET_DEFAULT_OPTIONS,
  }),
) as DashboardWidgetConfig[]; // TODO: update "type" field to enum when final config will be ready
