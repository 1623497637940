import { cloneDeep, isEqual } from 'lodash';
import { FilterSectionsFormState } from '../interfaces/FilterSectionsFormState';
import { FilterSettings } from '../../../interfaces/FilterSettings';
import { filterSectionsFormSettings } from '../constants/filterSectionsFormSettings';
import { FilterSettingsService } from '../../../services/FilterSettingsService';
import { FilterSectionType } from '../../../enum/FilterSectionType';
import { ReactNode } from 'react';
import { filterSectionsOrder } from '../../../constants/filterSectionsOrder';
import { FilterSectionVisibilityData } from '../interfaces/FilterSectionVisibilityData';
import { filterSectionsAdvanced } from '../../../constants/filterSectionsAdvanced';
import { filerSectionsNonUs } from '../../../constants/filterSectionsNonUs';
import { FilterSection } from '../../../interfaces/FilterSection';
import { AnyObject } from '../../../../../types/generics';
import { allViewFilterControls } from '../../../constants/filter-controls/allViewFilterControls';
import { FilterControlFactory } from '../factories/FilterControlFactory';
import { FormControlCounterService } from '../../../../../components/forms/components/form-builder/services/FormControlCounterService';

export class FilterSectionsStateService {
  static getSettingsWithControlProps(settings: FilterSettings): FilterSettings {
    const settingsWithControlProps = cloneDeep(settings);
    const controls = FilterSettingsService.getControls(settingsWithControlProps.sections);

    controls.forEach(control => {
      const controlSettings = allViewFilterControls[control.name];

      control.props = controlSettings?.props;
      control.type = controlSettings?.type;
    });

    return settingsWithControlProps;
  }

  static getInital(settings: FilterSettings): FilterSectionsFormState[] {
    const settingsSections = cloneDeep(settings.sections);
    const filterSectionsStates: FilterSectionsFormState[] = [];

    filterSectionsOrder.forEach(type => {
      const settingsSection = FilterSettingsService.getSectionByType(settingsSections, type);

      if (!settingsSection) {
        return;
      }

      filterSectionsStates.push({
        ...settingsSection,
        isClose: false,
        isVisible: true,
        activeControlsCount: 0,
      });
    });

    return filterSectionsStates;
  }

  static getUpdated(
    getStateFn: (prevState: FilterSectionsFormState[]) => FilterSectionsFormState[],
    prevState: FilterSectionsFormState[],
  ): FilterSectionsFormState[] {
    const newState = getStateFn(cloneDeep(prevState));

    if (isEqual(newState, prevState)) {
      return prevState;
    }

    return newState;
  }

  static getSectionControlsComponentsByType(type: FilterSectionType): ReactNode {
    return filterSectionsFormSettings[type].controls;
  }

  static getCloseChange(
    types: FilterSectionType[],
    isClose: boolean,
    sectionsStates: FilterSectionsFormState[],
  ): FilterSectionsFormState[] {
    types.forEach(type => {
      const section = FilterSettingsService.getSectionByType(sectionsStates, type);

      if (section) {
        section.isClose = isClose;
      }
    }, []);

    return sectionsStates;
  }

  static getVisibleChange(
    visibilityData: FilterSectionVisibilityData,
    sectionsStates: FilterSectionsFormState[],
  ): FilterSectionsFormState[] {
    return sectionsStates.map(sectionState => ({
      ...sectionState,
      isVisible: this.isVisible(sectionState.type, visibilityData),
    }));
  }

  static isVisible(type: FilterSectionType, visibilityData: FilterSectionVisibilityData): boolean {
    const isAdvancedControl = filterSectionsAdvanced.includes(type);
    const isNonUsControl = filerSectionsNonUs.includes(type);

    return !(
      (isAdvancedControl && visibilityData.hasAdvancedBtn && !visibilityData.isAdvanced) ||
      (isNonUsControl && !!visibilityData.isNonUs)
    );
  }

  static getFormChange(
    sectionsSettings: FilterSection[],
    formData: AnyObject = {},
    sectionsStates: FilterSectionsFormState[],
  ): FilterSectionsFormState[] {
    return sectionsStates.map(sectionState => {
      const sectionSettings = FilterSettingsService.getSectionByType(
        sectionsSettings,
        sectionState.type,
      ) as FilterSection;

      const controls = FilterControlFactory.getList([sectionSettings]);

      return {
        ...sectionState,
        activeControlsCount: FormControlCounterService.getActiveControlsCounts(controls, formData),
      };
    });
  }
}
