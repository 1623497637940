import ApiService from './ApiService';
import { ApiPath } from './enums/ApiPath';
import { ViewColumnSystemName } from '../entities/view/components/view-grid/enums/ViewColumnSystemName';
import { MyFavouritesDto } from '../../my-favorites/interfaces/MyFavouritesDto';
import { CompanyFavouriteDto } from '../../company/interfaces/CompanyFavouriteDto';

export class FavoritesApiService {
  static getAll(): Promise<CompanyFavouriteDto[]> {
    return ApiService.get(ApiPath.Favorites);
  }

  static getOne(companyKey: number): Promise<boolean> {
    return ApiService.get(`${ApiPath.Favorites}/${companyKey}`);
  }

  static add(companyKey: number, companyName: string): Promise<void> {
    return ApiService.post<void>(`${ApiPath.Favorites}`, {
      [ViewColumnSystemName.CompanyKey]: companyKey,
      [ViewColumnSystemName.CompanyName]: companyName,
    });
  }

  static remove(companyKey: number): Promise<void> {
    return ApiService.delete<void>(`${ApiPath.Favorites}/${companyKey}`);
  }

  static getDashboard(): Promise<MyFavouritesDto> {
    return ApiService.get<MyFavouritesDto>(`${ApiPath.Favorites}/Savings`);
  }
}
