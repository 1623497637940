import { GridAgColumn } from '../../../../shared/components/grid/grid/interfaces/GridAgColumn';
import { ViewColumnSystemName } from '../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';

export const researchCustomColumnsSettings: Partial<GridAgColumn>[] = [
  {
    field: ViewColumnSystemName.CompanyTicker,
    flex: 0,
  },
  {
    field: ViewColumnSystemName.ReportDate,
    width: 100,
    minWidth: 100,
  },
];
