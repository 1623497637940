import React, { useCallback, useMemo } from 'react';
import './FavouriteIcon.scss';
import Icon from './Icon';
import { IconName } from './IconName';
import { ColorScheme } from '../../enums/ColorScheme';
import { ControlSize } from '../../enums/ControlSize';
import { useClassName } from '../../hooks/useClassName';
import { FavouriteIconBlock, FavouriteIconModifier } from './FavouriteIconBem';

interface Props {
  isActive?: boolean;
  isDisabled?: boolean;
  hasHoverCursor?: boolean;
  onFavouriteClick?: (isActive: boolean) => void;
}

const FavouriteIcon: React.FC<Props> = props => {
  const { hasHoverCursor = true } = props;

  const cn = useClassName(FavouriteIconBlock.Root);

  const favouriteIcon: IconName = useMemo<IconName>(() => (props.isActive ? IconName.StarFilled : IconName.Star), [
    props.isActive,
  ]);

  const onFavouriteClick = useCallback(() => {
    if (!props.isDisabled) {
      props?.onFavouriteClick?.(!!props.isActive);
    }
  }, [props]);

  return (
    <Icon
      className={cn({
        [FavouriteIconModifier.Active]: props.isActive,
        [FavouriteIconModifier.NotActive]: !props.isActive,
        [FavouriteIconModifier.Disabled]: props.isDisabled,
        [FavouriteIconModifier.HoverCursor]: hasHoverCursor,
      })}
      name={favouriteIcon}
      colorScheme={ColorScheme.Attention}
      size={ControlSize.Small}
      onClick={onFavouriteClick}
    />
  );
};

FavouriteIcon.defaultProps = {
  isActive: false,
};

export default FavouriteIcon;
