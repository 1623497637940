import { NewsLandingDto } from '../interfaces/NewsLandingDto';
import { usePromiseState } from '../../shared/hooks/promise-state/usePromiseState';
import { useCallback } from 'react';
import { NewsApiService } from '../../shared/api/NewsApiService';
import { NewsSectionType } from '../enums/NewsSectionType';

export function useNewsLandingData(): [NewsLandingDto, boolean] {
  return usePromiseState<NewsLandingDto>(
    useCallback(() => NewsApiService.getNewsLanding(), []),
    {
      [NewsSectionType.RcArticles]: [],
      [NewsSectionType.UsExternalNews]: [],
      [NewsSectionType.NonUsExternalNews]: [],
    },
  );
}
