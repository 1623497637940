import React from 'react';
import './NewsRecapPcwAdditions.scss';
import { NewsRecapPcwAdditionsDto } from '../interfaces/NewsRecapPcwAdditionsDto';
import { useClassName } from '../../shared/hooks/useClassName';
import CompanyLink from '../../company/components/company-link/CompanyLink';

interface Props {
  news: NewsRecapPcwAdditionsDto[];
}

const NewsRecapPcwAdditions: React.FC<Props> = props => {
  const cn = useClassName('NewsRecapPcwAdditions');
  return (
    <ul className={cn()}>
      {props.news ? (
        props.news.map((news: NewsRecapPcwAdditionsDto, index: number) => {
          return (
            <li className={cn('item')} key={index}>
              <CompanyLink
                className={cn('link')}
                ticker={news.tickerSymbol}
                visibleValue={`${news.companyName} (${news.tickerSymbol}) `}
              />
            </li>
          );
        })
      ) : (
        <div className={cn('item')}>Nothing here today</div>
      )}
    </ul>
  );
};

export default NewsRecapPcwAdditions;
