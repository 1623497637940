import React, { useContext, useMemo } from 'react';
import CompanyTeamNotesForm from './form/CompanyTeamNotesForm';
import CompanySectionRow from '../shared/CompanySectionRow';
import CompanyTeamNotesToasts from './toasts/CompanyTeamNotesToasts';
import { CompanyContext } from '../context/CompanyContext';
import './CompanyTeamNotes.scss';
import { useLoadDataEffect } from '../hooks/useLoadDataEffect';
import PageLoader from '../../../shared/components/loader/PageLoader';
import { useClassName } from '../../../shared/hooks/useClassName';
import { CompanyTeamNotesBlock, CompanyTeamNotesElement } from './CompanyTeamNotesBem';
import { CompanyTab } from '../../enums/CompanyTab';
import CompanySection from '../shared/CompanySection';

const CompanyTeamNotes: React.FC = () => {
  const cn = useClassName(CompanyTeamNotesBlock.Root);
  const { teamNotes, ticker } = useContext(CompanyContext);

  const [data, loadData, isLoading] = teamNotes;

  const titleProps = useMemo(() => {
    return {
      tab: CompanyTab.TeamNotes,
    };
  }, []);

  useLoadDataEffect(data, loadData, [ticker]);

  return (
    <CompanySection className={cn()} data titleProps={titleProps}>
      <CompanySectionRow className={cn(CompanyTeamNotesElement.Chat)}>
        <PageLoader otherwise={data.length} otherwiseContent="No comments" loading={isLoading}>
          <CompanyTeamNotesToasts teamNotes={data} />
        </PageLoader>
        <CompanyTeamNotesForm />
      </CompanySectionRow>
    </CompanySection>
  );
};

export default CompanyTeamNotes;
