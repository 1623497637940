import React, { ReactElement, ReactNode } from 'react';
import './NewsRecap.scss';
import NewsTitle from './NewsTitle';
import PageLoader from '../../shared/components/loader/PageLoader';

interface Props {
  title: ReactNode;
  isLoading: boolean;
  items: any[];
  content: () => ReactElement;
  otherwise?: ReactNode;
  type?: ReactNode;
  url?: string;
}

const NewsRecapSection: React.FC<Props> = props => {
  // const { otherwise = 'Nothing here today' } = props;

  // const isData = props.items?.length > 0;

  return true ? (
    <section className={`NewsRecapSection NewsRecapSection--${props.type}`}>
      <NewsTitle url={props.url}>{props.title}</NewsTitle>

      <PageLoader loading={props.isLoading}>
        <div className="NewsRecap__content">{props.content()}</div>
      </PageLoader>
    </section>
  ) : null;
};

export default NewsRecapSection;
