import { isNil, min } from 'lodash';
import { GridAgColumn } from '../interfaces/GridAgColumn';
import { GridColumnsSetupOptions } from '../interfaces/GridColumnsSetupOptions';
import { ColDef } from '@ag-grid-community/core';
import { pinColumnWidth } from '../../shared/constants/column';
import { GridCellFactory } from '../../shared/factories/GridCellFactory';
import CustomGridHeader from '../../shared/components/formatters/header/CustomGridHeader';
import { GridClass } from '../enums/GridClass';
import { sortingOrderInverse } from '../constants/sortOrder';

export class GridColumnFactory {
  static getLibColumns(
    columns: GridAgColumn[],
    setupOptions: GridColumnsSetupOptions,
  ): { simpleColumns: ColDef[]; treeColumn: ColDef | undefined } {
    let treeColumn: ColDef | undefined = undefined;
    const simpleColumns: ColDef[] = [];

    columns.forEach((column: GridAgColumn) => {
      const commonColumnOptions = this.getColumnCommonOptions(column, setupOptions);

      if (column.isTree) {
        treeColumn = {
          ...commonColumnOptions,
          ...this.getTreeColumnOptions(column),
        };
      } else {
        simpleColumns.push({
          ...commonColumnOptions,
          ...this.getSimpleColumnOptions(column),
        });
      }
    });

    return { simpleColumns, treeColumn };
  }

  private static getColumnCommonOptions(column: GridAgColumn, setupOptions: GridColumnsSetupOptions): ColDef {
    const isSortable = isNil(column.sortable) ? true : column.sortable;

    const libColumn: ColDef = {
      field: column.field,
      headerName: column.headerName?.toString(), // For now React.Node is not supported there, temporary solution
      hide: column.hide || false,
      sortable: isSortable,
      sort: column.sort,
      unSortIcon: true,
      minWidth: column.minWidth,
      maxWidth: column.maxWidth,
      resizable: true,
      cellStyle: GridCellFactory.getStyles(column),
      headerComponentFramework: CustomGridHeader,
      headerComponentParams: {
        gridColumn: { ...column, sortable: isSortable },
      },
      cellClass: column.cellFormatter ? GridClass.CellReact : '',
      comparator: column.comparator,
      enableCellChangeFlash: column.enableCellChangeFlash || false,
      sortingOrder: column.isSortingInverse ? sortingOrderInverse : undefined,
      flex: column.flex,
    };

    if (setupOptions.isColumnsFitGrid) {
      libColumn.flex = column.flex || 1;
    } else if (column.width) {
      libColumn.width = column.width;
    }

    if (column.valueFormatter) {
      libColumn.valueFormatter = column.valueFormatter;
    }

    if (setupOptions.shouldPinColumns) {
      libColumn.pinned = this.isPinned(column, setupOptions.pinColumns);
    }

    if (libColumn.pinned) {
      const width = this.getMinWidth(libColumn.minWidth, pinColumnWidth);

      libColumn.minWidth = width;
      libColumn.width = width;
    }

    return libColumn;
  }

  private static getRendererCommonOptions(column: GridAgColumn): { field: string } {
    return {
      field: column.field,
    };
  }

  private static getSimpleColumnOptions(column: GridAgColumn): Partial<ColDef> {
    return {
      cellRenderer: column.cellFormatter && column.cellFormatter.type,
      cellRendererParams: column.cellFormatter && {
        ...column.cellFormatter.props,
        ...this.getRendererCommonOptions(column),
      },
    };
  }

  private static getTreeColumnOptions(column: GridAgColumn): Partial<ColDef> {
    return {
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: column.cellFormatter && column.cellFormatter.type,
        innerRendererParams: column.cellFormatter && {
          ...column.cellFormatter.props,
          ...this.getRendererCommonOptions(column),
        },
      },
    };
  }

  private static getMinWidth(minWidth?: number, maxWidth?: number): number {
    return min([minWidth, maxWidth]) as number;
  }

  private static isPinned(column: GridAgColumn, pinColumns: string[] = []): boolean {
    return pinColumns.some(pinColumn => pinColumn === column.field);
  }
}
