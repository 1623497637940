import React, { ReactNode, useMemo, useCallback, memo } from 'react';
import { FieldError } from 'react-hook-form';
import classNames from 'classnames';
import uniqueId from 'lodash.uniqueid';
import Label from './Label';
import Error from './Error';
import { ControlSize } from '../../enums/ControlSize';
import './Input.scss';
import Control, { ControlCommonProps } from './Control';
import Autocomplete, { AutocompleteProps } from '../autocomplete/Autocomplete';
import { CustomAny } from '../../types/generics';
import { AppendValue } from './types/AppendValue';
import { PrependValue } from './types/PrependValue';

export enum InputType {
  Text = 'text',
  Email = 'email',
  Tel = 'tel',
  Password = 'password',
  Number = 'number',
}

export interface InputProps extends ControlCommonProps {
  name: string;
  label?: string | ReactNode;
  placeholder?: string;
  type?: InputType;
  autoComplete?: string;
  isDisabled?: boolean;
  size?: ControlSize;
  error?: FieldError;
  register?: CustomAny;
  ref?: (ref: Element | null) => void;
  min?: number;
  max?: number;
  prepend?: PrependValue[];
  append?: AppendValue[];
  after?: React.ReactNode;
  id?: string;
  hasLabelErrorStyle?: boolean;
  autocompleteOptions?: AutocompleteProps;
}

const Input: React.FC<InputProps> = props => {
  const { error } = props;

  const id = useMemo(() => (props.id ? props.id : uniqueId('Input-')), [props.id]);
  const getInput = useCallback(
    (additionalProps?: React.HTMLProps<HTMLInputElement>) => (
      <input
        className={classNames('Input__control form-control', {
          'is-invalid': error,
          'form-control-lg': props.size === ControlSize.Large,
          'form-control-sm': props.size === ControlSize.Small,
        })}
        autoComplete={props.autoComplete}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        ref={props.register}
        id={id}
        disabled={props.isDisabled}
        min={props.min}
        max={props.max}
        {...additionalProps}
      />
    ),
    [props, error, id],
  );

  const autocompleteRenderInput = useCallback(
    (additionalProps: React.HTMLProps<HTMLInputElement>) => getInput(additionalProps),
    [getInput],
  );

  return (
    <Control
      {...props}
      className={classNames(`Input Input--${props.name}`, {
        'Input--large': props.size === ControlSize.Large,
        'Input--small': props.size === ControlSize.Small,
        'Input--hasAfter': props.after,
      })}
    >
      {props.label && (
        <Label error={error} htmlFor={id} hasLabelErrorStyle={props.hasLabelErrorStyle}>
          {props.label}
        </Label>
      )}

      <div
        className={classNames('input-group', {
          'input-group-lg': props.size === ControlSize.Large,
          'input-group-sm': props.size === ControlSize.Small,
          autocomplete: props.autocompleteOptions,
        })}
      >
        {props.prepend?.length && (
          <div className="input-group-prepend">
            {props.prepend.map((item, index) => (
              <span key={index} className="input-group-text">
                {item}
              </span>
            ))}
          </div>
        )}

        {props.autocompleteOptions ? (
          <Autocomplete {...props.autocompleteOptions} renderInput={autocompleteRenderInput} />
        ) : (
          getInput()
        )}

        {props.append && props.append.length && (
          <div className="input-group-append">
            {props.append.map((item, index) => (
              <span key={index} className="input-group-text">
                {item}
              </span>
            ))}
          </div>
        )}
        {props.after && <div className="Input__controlAfter">{props.after}</div>}
      </div>
      {error && <Error>{error.message}</Error>}
    </Control>
  );
};

Input.defaultProps = {
  type: InputType.Text,
};

export default memo(Input);
