import React from 'react';
import './ColorSchemeFormatter.scss';
import { ColorScheme } from '../../enums/ColorScheme';
import { useClassName } from '../../hooks/useClassName';
import { ColorSchemeFormatterBlock } from './ColorSchemeFormatterBem';

interface Props {
  value: string | number;
  colorScheme: ColorScheme;
}

const ColorSchemeFormatter: React.FC<Props> = props => {
  const cn = useClassName(ColorSchemeFormatterBlock.Root);

  return (
    <span
      className={cn({
        [props.colorScheme]: props.colorScheme,
      })}
    >
      {props.value}
    </span>
  );
};

export default ColorSchemeFormatter;
