import React, { ReactNode, useMemo } from 'react';
import { TickerSymbol } from '../../../shared/types';
import { CompanyLocationService } from '../../services/CompanyLocationService';
import HyperlinkConditional from '../../../shared/components/typography/HyperlinkConditional';
import { useCurrentRoutePath } from '../../../shared/hooks/useCurrentRoutePath';

interface Props {
  ticker: TickerSymbol;
  companyName?: string;
  visibleValue?: ReactNode;
  isExternal?: boolean;
  className?: string;
}

const CompanyLink: React.FC<Props> = props => {
  const { companyName, ticker } = props;

  const routePath = useCurrentRoutePath();

  const defaultText = useMemo(() => (companyName ? `${companyName} (${ticker})` : ticker), [companyName, ticker]);

  const text = useMemo(() => (props.visibleValue ? props.visibleValue : defaultText), [
    props.visibleValue,
    defaultText,
  ]);

  const to = useMemo(
    () =>
      CompanyLocationService.isCurrentCompanyProfilePage(routePath, ticker)
        ? undefined
        : CompanyLocationService.getRelativePath(ticker),
    [routePath, ticker],
  );

  return <HyperlinkConditional className={props.className} to={to} isExternal={props.isExternal} value={text} />;
};

export default CompanyLink;
