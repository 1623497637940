import React from 'react';
import { useClassName } from '../../../../shared/hooks/useClassName';
import './AdditionalStatsContentContainer.scss';
import {
  AdditionalStatsContentContainerBlock,
  AdditionalStatsContentContainerElement,
} from './additionalStatsContentContainerBem';

interface Props {
  title?: React.ReactNode;
  className?: string;
}

const AdditionalStatsContentContainer: React.FC<Props> = props => {
  const cn = useClassName(AdditionalStatsContentContainerBlock.Root, props.className);

  return (
    <div className={cn()}>
      <div className={cn(AdditionalStatsContentContainerElement.Title)}>{props.title}</div>

      <div className={cn(AdditionalStatsContentContainerElement.Content)}>{props.children}</div>
    </div>
  );
};

export default AdditionalStatsContentContainer;
