export enum DealStatus {
  Scheduled = 'Scheduled',
  Priced = 'Priced',
  PCW = 'PCW',
  Filed = 'Filed',
  Postponed = 'Postponed',
  Withdrawn = 'Withdrawn',
  Acquired = 'Acquired',
  Upcoming = 'Upcoming',
  WithdrawnPostponed = 'WithdrawnPostponed',
}
