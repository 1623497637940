import React, { useMemo } from 'react';
import { ViewDataTable } from '../../view/interfaces/api/ViewDataTable';
import ViewGrid from '../../view-grid/ViewGrid';
import { ViewColumnSystemName } from '../../view-grid/enums/ViewColumnSystemName';
import { FiltersMapValue } from '../../../../filters/components/filterable-component/interfaces/FiltersMapValue';
import { AnyObject } from '../../../../../types/generics';
import { ViewGridSearchProp } from '../../view-grid/interfaces/ViewGridSearchProp';
import { GridAgColumn } from '../../../../../components/grid/grid/interfaces/GridAgColumn';
import { GridPaginationOptions } from '../../../../../components/grid/grid/interfaces/GridPaginationOptions';
import { ComponentMessage } from '../../../../../enums/ComponentMessage';

interface Props {
  dataTable: ViewDataTable;
  hasPagination?: boolean;
  paginationOptions?: GridPaginationOptions;
  colIds?: ViewColumnSystemName[];
  searchControls?: FiltersMapValue[];
  isColumnsFitGrid?: boolean;
  searchDefaultValues?: AnyObject;
  customColumnsSettings?: Partial<GridAgColumn>[];
  shouldWrapCellContainer?: boolean;
  isLoading?: boolean;
  onSearch?: (formData: AnyObject) => void;
  onSearchDataChange?: (formData: AnyObject) => void;
  overlayNoRowsTemplate?: ComponentMessage;
  onChange?: () => void;
}

const ViewDashboardGrid: React.FC<Props> = props => {
  const { isColumnsFitGrid = true, isLoading = false } = props;

  const searchOptions = useMemo<ViewGridSearchProp | undefined>(
    () =>
      props.searchControls && {
        controls: props.searchControls,
        defaultValues: props.searchDefaultValues,
        onSearch: props.onSearch,
        onChange: props.onSearchDataChange,
      },
    [props.searchControls, props.searchDefaultValues, props.onSearch, props.onSearchDataChange],
  );

  return (
    <ViewGrid
      idCols={props.colIds}
      isLoading={isLoading}
      isFixedHeight={false}
      shouldPinColumnsMobile={false}
      isColumnsFitGrid={isColumnsFitGrid}
      hasPagination={props.hasPagination}
      paginationOptions={props.paginationOptions}
      search={searchOptions}
      customColumnsSettings={props.customColumnsSettings}
      shouldWrapCellContainer={props.shouldWrapCellContainer}
      overlayNoRowsTemplate={props.overlayNoRowsTemplate}
      onChange={props.onChange}
      onViewportChanged={props.onChange}
      {...props.dataTable}
    />
  );
};

export default ViewDashboardGrid;
