import { NewsType } from '../enums/NewsType';
import { SelectOption } from '../../shared/services/select/interfaces/SelectOption';

export const newsTypeSelectOptions: SelectOption[] = [
  {
    title: NewsType.AnalystNote,
    value: NewsType.AnalystNote,
  },
  {
    title: NewsType.Blog,
    value: NewsType.Blog,
  },
  {
    title: NewsType.External,
    value: NewsType.External,
  },
  {
    title: NewsType.NewsAlert,
    value: NewsType.NewsAlert,
  },
];
