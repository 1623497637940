import React, { Context } from 'react';

export interface FormStateContext {
  isLoading: boolean;
  isNewSubmitFormValues: boolean;
}

export const FormStateContext: Context<FormStateContext> = React.createContext<FormStateContext>({
  isLoading: false,
  isNewSubmitFormValues: false,
});
