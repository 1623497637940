import React from 'react';
import Helmet from 'react-helmet';
import PrimarySection from '../shared/components/primary-section/PrimarySection';
import TalkToAnalystForm from './form/TalkToAnalystForm';

const TalkToAnalyst: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
      </Helmet>

      <PrimarySection header="Contact Us">
        <TalkToAnalystForm />
      </PrimarySection>
    </>
  );
};

export default TalkToAnalyst;
