export enum CompanyProfileStepsTooltipContentItemBlock {
  Root = 'CompanyProfileStepsTooltipContentItem',
}

export enum CompanyProfileStepsTooltipContentItemElement {
  sequenceNumber = 'sequenceNumber',
  date = 'date',
  content = 'content',
  description = 'description',
  title = 'title',
  text = 'text',
  shares = 'shares',
}
