import React from 'react';
import { DashboardWidgetComponent } from '../../interfaces/DashboardWidgetComponent';
import { ViewDataTable } from '../../../shared/entities/view/components/view/interfaces/api/ViewDataTable';
import DashboardTable from './shared/DashboardTable';

const DashboardMostViewedIPOs: DashboardWidgetComponent<ViewDataTable> = props => {
  return (
    <DashboardTable
      dataTable={props.data}
      width={props.dimensions?.width}
      isCompanyNameTitleEllipsis={false}
      pinTickerColumn
    />
  );
};

export default DashboardMostViewedIPOs;
