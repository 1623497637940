import * as yup from 'yup';
import { TrialRequestFormFields } from './trial-request-form-fields';
import { captchaSchema } from '../../../shared/components/forms/services/validation/contstants/validation/captchaSchema';
import { termsAndPrivacySchema } from '../../../shared/components/forms/services/validation/contstants/validation/termsAndPrivacySchema';
import { businessEmailSchema } from '../../../shared/components/forms/services/validation/contstants/validation/businessEmailSchema';
import { INPUT_MAX_LENGTH } from '../../../shared/constants/validation';
import { phoneNumberSchema } from '../../../shared/components/forms/services/validation/contstants/validation/phoneNumberSchema';

export const validationSchema = yup.object().shape({
  [TrialRequestFormFields.FirstName]: yup
    .string()
    .required()
    .max(INPUT_MAX_LENGTH)
    .label('First Name'),
  [TrialRequestFormFields.LastName]: yup
    .string()
    .required()
    .max(INPUT_MAX_LENGTH)
    .label('Last Name'),
  [TrialRequestFormFields.BusinessEmail]: businessEmailSchema,
  [TrialRequestFormFields.PhoneNumber]: phoneNumberSchema,
  [TrialRequestFormFields.Company]: yup
    .string()
    .required()
    .max(INPUT_MAX_LENGTH)
    .label('Company'),
  [TrialRequestFormFields.JobTitle]: yup
    .string()
    .required()
    .max(INPUT_MAX_LENGTH)
    .label('Job Title'),
  // [TrialRequestFormFields.Agree]: termsAndPrivacySchema,
  [TrialRequestFormFields.Captcha]: captchaSchema,
});
