import React from 'react';
import './RecentResearch.scss';
import View from '../../shared/entities/view/components/view/View';
import { recentResearchViewOptions } from './constants/recentResearchViewOptions';
import { ViewType } from '../../shared/entities/view/components/view/enums/ViewType';

const RecentResearch: React.FC = () => {
  return <View options={recentResearchViewOptions} viewType={ViewType.RecentResearch} />;
};

export default RecentResearch;
