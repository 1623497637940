import React from 'react';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { NewsLocationService } from '../../../../../../../news/shared/services/NewsLocationService';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import Hyperlink from '../../../../../../components/typography/Hyperlink';
import Icon from '../../../../../../components/icon/Icon';
import { IconName } from '../../../../../../components/icon/IconName';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { ColorScheme } from '../../../../../../enums/ColorScheme';

type Props = GridCellFormatterCommonProps;

const NewsHeadlineCell: React.FC<Props> = props => {
  return (
    <CustomCellFormatterWrapper value={props.value}>
      <Hyperlink to={NewsLocationService.getRoutePath(props.data.newsKey)} isExternal>
        <span>
          {props.data?.videoKey && (
            <Icon
              className={`${IconName.PlayCircle} inline-icon`}
              name={IconName.PlayCircle}
              size={ControlSize.Small}
              colorScheme={ColorScheme.Attention}
            />
          )}
          {props.value}
        </span>
      </Hyperlink>
    </CustomCellFormatterWrapper>
  );
};

export default NewsHeadlineCell;
