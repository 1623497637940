import { defaultsDeep } from 'lodash';
import { ViewOptions } from '../interfaces/options/ViewOptions';

export class ViewOptionsService {
  static merge<T = ViewOptions>(base: Partial<T>, additional: Partial<T>): T {
    return defaultsDeep({}, additional, base);
  }

  static hasQuickDateRangeFilter<T extends ViewOptions>(options: T): boolean {
    return !!options.toolbar?.quickFilter?.hasDateRange;
  }
}
