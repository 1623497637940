export enum ViewSummaryField {
  Companies = 'companies',
  PcmlCompanies = 'pcmlCompanies',
  Proceeds = 'proceeds',
  PercentTradingAboveIPOPrice = 'percentTradingAboveIPOPrice',
  AvgPriceVsMidpoint = 'avgPriceVsMidpoint',
  AvgFirstDayPop = 'avgFirstDayPop',
  AvgAftermarketReturn = 'avgAftermarketReturn',
  AvgReturnFromIPO = 'avgReturnFromIPO',
  AvgFirstThreeMo = 'avgFirstThreeMo',
  AvgIpoDiscount = 'avgIpoDiscount',
}
