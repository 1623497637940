export enum TooltipBlock {
  Root = 'Tooltip',
}

export enum TooltipElement {
  Title = 'title',
  Content = 'content',
}

export enum TooltipModifier {
  IndentHorizontal = 'indentHorizontal',
  IndentVertical = 'indentVertical',
  Show = 'show',
  UnderFixed = 'underFixed',
  MaxHeight = 'maxHeight',
}
