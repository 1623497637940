import { ViewColumnsAddItem } from './interfaces/ViewColumnsAddItem';
import React, { Context } from 'react';

export interface ViewColumnsAddContextProps {
  isInModal?: boolean;
  onColumnCheck: (columnId: string, isSelected: boolean) => void;
  onSortEnd: (columnsSelectedSorted: ViewColumnsAddItem[]) => void;
}

export const ViewColumnsAddContext: Context<ViewColumnsAddContextProps> = React.createContext<
  ViewColumnsAddContextProps
>({
  onColumnCheck: () => {},
  onSortEnd: () => {},
});
