import { NewsItemTag } from '../interfaces/NewsItemTag';
import { useMemo } from 'react';
import { NewsListItem } from '../components/interfaces/NewsListItem';
import { getNewsSourceTag } from '../helpers/getNewsSourceTag';
import { getNewsTypeTag } from '../helpers/getNewsTypeTag';
import { getNewsStoryTypeTag } from '../helpers/getNewsStoryTypeTag';
import { getNewsExchangeTag } from '../helpers/getNewsExchangeTag';
import { getNewsCompanyProfileTag } from '../helpers/getNewsCompanyProfileTag';
import { getDateTag } from '../helpers/getDateTag';
import { DateFormat } from '../../shared/helpers/date/enums/DateFormat';
import { getIconTag } from '../helpers/getIconTag';

/**
 * Tags are formed in accordance with this specification
 * https://wiki.itransition.com/pages/viewpage.action?pageId=606978107
 */
export function useNewsTags(
  item: NewsListItem,
  hasDateTag?: boolean,
  dateTagFormat?: DateFormat,
  hasProfileLayout?: boolean,
): NewsItemTag[] {
  return useMemo(() => {
    const isTag = (tag: NewsItemTag | undefined): tag is NewsItemTag => tag !== undefined;

    if (hasProfileLayout) {
      return [
        getIconTag(item),
        getNewsTypeTag(item),
        getNewsSourceTag(item),
        getDateTag(item, hasDateTag, DateFormat.MonthDayYearShort),
      ].filter(isTag);
    } else {
      return [
        getIconTag(item),
        getDateTag(item, hasDateTag, dateTagFormat),
        getNewsTypeTag(item),
        getNewsCompanyProfileTag(item),
        getNewsSourceTag(item),
        getNewsStoryTypeTag(item),
        getNewsExchangeTag(item),
      ].filter(isTag);
    }
  }, [item, hasDateTag, dateTagFormat, hasProfileLayout]);
}
