export enum CompanyTab {
  Profile = 'profile',
  InvestmentRationale = 'investmentRationale',
  FundamentalInsights = 'fundamentalInsights',
  Financials = 'financials',
  MgmtQs = 'managementQuestions',
  MgmtOwners = 'managementOwners',
  RelativeValuation = 'relativeValuation',
  PeerTrading = 'peerTrading',
  TeamNotes = 'teamNotes',
  Milestones = 'milestones',
}
