import { StatsElementOptions } from '../../../../shared/entities/stats-element/interfaces/StatsElementOptions';
import { ChartType } from '../../../../shared/components/chart/enums/ChartType';
import { NumberFormat } from '../../../../shared/enums/NumberFormat';
import { NumberHelper } from '../../../../shared/helpers/NumberHelper';
import { ChartTooltipFormatterService } from '../../../../shared/components/chart/services/ChartTooltipFormatterService';
import { CustomAny } from '../../../../shared/types/generics';
import { ChartValuesType } from '../../../../shared/components/chart/enums/ChartValuesType';
import { StatsElementChartProps } from '../../../../shared/entities/stats-element/components/chart/interfaces/StatsElementChartProps';
import { getStatsElementSimpleGridColumnYearToViewMapper } from '../../../../shared/entities/stats-element/components/simple-grid/constants/statsElementSimpleGridColumnMappers';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { ViewFilterName } from '../../../../shared/entities/filters/enum/types/ViewFilterName';
import { ViewColumnSystemName } from '../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { DealStatus } from '../../../../shared/entities/filters/enum/DealStatus';
import { StatsFilterName } from '../../enum/StatsFilterName';
import { StatsFiltersTitle } from '../../shared/filter/enums/StatsFiltersTitle';

const MAX_PERCENT = 1;
const MIN_PERCENT = 0;

const IPO_INDUSTRIES_GRID_INDEX = 0;
const IPO_INDUSTRY_HISTORY_GRID_INDEX = 1;

export const returnsBySectorChartOptions: StatsElementChartProps = {
  title: 'Returns by Sector',
  internalFilter: {
    label: StatsFiltersTitle.ReturnsFrom,
  },
  dynamicFilters: [StatsFilterName._IPOsFromDateRange],
  type: ChartType.HorizontalColumn,
  optionsList: [
    {
      yAxis: {
        title: {
          text: '',
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter() {
          return ChartTooltipFormatterService.getTooltip({
            points: [],
            title: `${this.x}: <b>${NumberHelper.getFormatValue(
              NumberHelper.percentageToNumber(this.y),
              NumberFormat.PercentageInteger,
            )}<b/>`,
          });
        },
      },
    },
  ],
};

export const industryBreakdownStatsElementOptions: StatsElementOptions = {
  chartsOptions: [
    {
      title: '',
      type: ChartType.Base,
      dynamicFilters: [StatsFilterName.Industry],
      optionsList: [
        {
          yAxis: [
            {
              title: {
                text: 'Number of Total IPOs',
              },
              opposite: true,
            },
            {
              title: {
                text: null,
              },
              labels: {
                enabled: false,
              },
              max: MAX_PERCENT,
              min: MIN_PERCENT,
            },
            {
              stackLabels: {
                enabled: true,
                formatter() {
                  return NumberHelper.getFormatValue(this.total as number, NumberFormat.CurrencyFloatWithLetter);
                },
              },
              title: {
                text: 'Proceeds in Billions (US$)',
              },
            },
          ],
          series: [
            {
              type: 'column',
              yAxis: 2,
            },
            {
              type: 'column',
              yAxis: 2,
            },
            {
              type: 'line',
            },
            // {
            //   type: 'line',
            //   yAxis: 1,
            //   dataLabels: {
            //     formatter() {
            //       return NumberHelper.getFormatValue(this.y as number, NumberFormat.PercentageInteger);
            //     },
            //   },
            // },
          ],
          tooltip: {
            formatter() {
              return ChartTooltipFormatterService.getTooltip({
                points: this.points as CustomAny[],
                title: `${this.x}`,
                pointsValueType: [
                  ChartValuesType.Currency,
                  ChartValuesType.Currency,
                  ChartValuesType.Integer,
                  ChartValuesType.Percentage,
                ],
              });
            },
          },
          plotOptions: {
            column: {
              grouping: false,
              dataLabels: {
                enabled: true,
                format: '${point.y:,.1f}',
              },
            },
          },
        },
      ],
    },
    returnsBySectorChartOptions,
  ],
  gridOptions: {
    [IPO_INDUSTRY_HISTORY_GRID_INDEX]: {
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(context => ({
        viewType: ViewType.IndustryInUse,
        startDateFilterName: ViewFilterName.OfferStartDate,
        endDateFilterName: ViewFilterName.OfferEndDate,
        optionsList: [
          {
            getFilterName: ViewFilterName.Industry,
            getFilterValue: ViewColumnSystemName.StatsSectorKey,
          },
          {
            getFilterName: ViewFilterName.DealStatusDealStatusList,
            getFilterValue: () => DealStatus.Priced,
          },
          {
            getFilterName: ViewFilterName.IncludeSpac,
            getFilterValue: () => (context?.pageFilters?.[StatsFilterName.IncludeSPACs] ? 'true' : 'false'),
          },
        ],
      })),
      isHighlightLastRow: true,
    },
    [IPO_INDUSTRIES_GRID_INDEX]: {
      isNoStickyFitstColumn: true,
    },
  },
};
