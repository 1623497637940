import React, { useCallback, useMemo, useContext } from 'react';
import FormGroup from '../../../../shared/components/forms/FormGroup';
import { useCurrentRoutePath } from '../../../../shared/hooks/useCurrentRoutePath';
import FormTextSelect from '../../../../shared/components/forms/FormTextSelect';
import { AdditionalStatsService } from '../../../additional-stats/services/AdditionalStatsService';
import { ViewFilterName } from '../../../../shared/entities/filters/enum/types/ViewFilterName';
import { ControlDivider } from '../../../../shared/components/forms/services/control-divider/enums/ControlDivider';
import FormCheckbox from '../../../../shared/components/forms/FormCheckbox';
import { StatsFilterName } from '../../enum/StatsFilterName';
import { StatsGlobalFilter } from '../../types/StatsGlobalFilter';
import { Location } from '../../../../shared/entities/filters/enum/Location';
import { StatsPageContext } from '../../context/StatsPageContext';

export interface StatsFiltersProps {
  filtersNames?: StatsGlobalFilter[];
}

const StatsPageFiltersFormHeader: React.FC<StatsFiltersProps> = props => {
  const { pageFilters } = useContext(StatsPageContext);

  const location = pageFilters[ViewFilterName.Headquarter];

  const currentRoutePath = useCurrentRoutePath();

  const getHasFilter = useCallback((filtersName: StatsGlobalFilter) => !!props.filtersNames?.includes(filtersName), [
    props.filtersNames,
  ]);

  const isUsHeadquarter = useMemo(() => location === Location.US, [location]);

  let filteredLocationOptions = useMemo(
    () => AdditionalStatsService.getHeadquarterFilterLocationOptions(currentRoutePath),
    [currentRoutePath],
  );

  filteredLocationOptions = filteredLocationOptions.filter(item => item.title !== 'Global');

  return (
    <>
      <FormGroup>
        {getHasFilter(ViewFilterName.Headquarter) && (
          <FormTextSelect
            name={ViewFilterName.Headquarter}
            options={filteredLocationOptions}
            divider={ControlDivider.Slash}
          />
        )}
      </FormGroup>

      {getHasFilter(StatsFilterName.IncludeSPACs) && (
        <FormGroup>
          {isUsHeadquarter ? (
            <FormCheckbox name={StatsFilterName.IncludeSPACs} label="Include SPACs" />
          ) : (
            <FormCheckbox name={StatsFilterName.IncludeAShares} label="Include A-Shares" />
          )}
        </FormGroup>
      )}
    </>
  );
};

export default StatsPageFiltersFormHeader;
