import ApiService from './ApiService';
import { TeamNote } from '../../company/interfaces/TeamNote';
import { ApiPath } from './enums/ApiPath';
import { TickerSymbol } from '../types';

export class TeamNotesApiService {
  static getAll(ticker: TickerSymbol): Promise<TeamNote[]> {
    return ApiService.get<TeamNote[]>(`${ApiPath.TeamNotes}/${ticker}`);
  }

  static getUnreadCount(ticker: TickerSymbol): Promise<number> {
    return ApiService.get<number>(`${ApiPath.TeamNotesUnreadCount}/${ticker}`);
  }

  static deleteOne(id: string): Promise<void> {
    return ApiService.delete(`${ApiPath.TeamNotes}/${id}`);
  }

  static sendOne(note: string, ticker: TickerSymbol): Promise<TeamNote> {
    return ApiService.post(ApiPath.TeamNotes, { note, ticker });
  }
}
