import React from 'react';
import { DashboardWidgetComponent } from '../../../interfaces/DashboardWidgetComponent';
import { ViewSaved } from '../../../../shared/entities/view/components/view/interfaces/api/ViewSaved';
import DashboardSavedViewsWidget from './DashboardSavedViewsWidget';
import { SavedViewsType } from '../../../../shared/entities/view/components/view/components/view-saved/enums/SavedViewsType';

const DashboardMyViewsWidget: DashboardWidgetComponent<ViewSaved[]> = props => {
  return <DashboardSavedViewsWidget type={SavedViewsType.Views} savedViews={props.data} />;
};

export default DashboardMyViewsWidget;
