import React from 'react';
import { CompanyPriceChangeDto } from '../../../../../../../company/components/company-price-change/interfaces/CompanyPriceChangeDto';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import CompanyPriceChange from '../../../../../../../company/components/company-price-change/CompanyPriceChange';

interface Props extends GridCellFormatterCommonProps {
  hideGrowthValue?: boolean;
}
const TodaysPriceChangeCell: React.FC<Props> = props => {
  const value: CompanyPriceChangeDto = props.value;

  return (
    <CustomCellFormatterWrapper className="TodaysPriceChangeCell" value={props.value}>
      {value && <CompanyPriceChange hideGrowthValue={props.hideGrowthValue} data={value} />}
    </CustomCellFormatterWrapper>
  );
};

export default TodaysPriceChangeCell;
