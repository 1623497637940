import { FooterMenuItemProps } from './FooterMenuItem';
import { RoutePath } from '../../enums/RoutePath';
import { screensNavItems } from '../../entities/navigation/constants/screensNavItems';
import { statsNavItems } from '../../entities/navigation/constants/statsNavItems';

export const links: FooterMenuItemProps[] = [
  {
    text: 'Dashboard',
    path: RoutePath.Dashboard,
  },
  // {
  //   text: 'Commentary',
  //   path: RoutePath.ContactUs,
  // },
  {
    text: 'Scheduled',
    path: RoutePath.Scheduled,
  },
  {
    text: 'Research',
    path: RoutePath.Research,
  },
  {
    text: 'Priced',
    path: RoutePath.Priced,
  },
  // {
  //   text: 'Filed',
  //   path: RoutePath.Filed,
  // },
  {
    text: 'Pipeline',
    path: RoutePath.Pipeline,
  },
  {
    text: 'Private Co',
    path: RoutePath.PrivateCompanyWatchlist,
  },
  {
    text: 'News',
    path: RoutePath.News,
  },
  {
    text: 'Stats',
    path: RoutePath.Stats,
    items: statsNavItems,
  },
  {
    text: 'IPO Screens',
    path: RoutePath.ScreensAdd,
    items: screensNavItems,
  },
  {
    text: 'Master Calendar',
    path: RoutePath.MasterCalendar,
  },
  {
    text: 'Contact Us',
    path: RoutePath.ContactUs,
  },
  // {
  //   text: 'Help',
  //   path: RoutePath.Help,
  //   items: [
  //     {
  //       text: 'Welcome',
  //       path: RoutePath.Help,
  //     },
  //     {
  //       text: 'Video Tutorials',
  //       path: RoutePath.Help,
  //     },
  //     {
  //       text: 'FAQs',
  //       path: RoutePath.Help,
  //     },
  //     {
  //       text: 'Contact Us',
  //       path: RoutePath.Contact,
  //     },
  //   ],
  // },
  {
    text: 'My Account',
    path: RoutePath.AccountSettings,
    items: [
      {
        text: 'Account settings',
        path: RoutePath.AccountSettings,
      },
      // {
      //   text: 'My Profile',
      //   path: RoutePath.Analyst,
      // },
      {
        text: 'My Favorites',
        path: RoutePath.MyFavourites,
      },
      {
        text: 'Email Preferences',
        path: RoutePath.EmailPreferences,
      },
    ],
  },
  {
    text: 'IPO Data',
    path: '',
    link: 'https://developers.renaissancecapital.com/?utm_source=intelligence&utm_medium=footer&utm_campaign=apis',
    items: [
      {
        text: 'IPO APIs',
        path: '',
        link: 'https://developers.renaissancecapital.com/?utm_source=intelligence&utm_medium=footer&utm_campaign=apis',
      },
    ],
  },
  // {
  //   text: 'Poll',
  //   path: RoutePath.Poll,
  // },
];
