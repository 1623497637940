import React from 'react';
import { useClassName } from '../../hooks/useClassName';
import { RotatedGridWithElementsBlock, RotatedGridWithElementsElement } from './RotatedGridWithElementsBem';
import InnerComponent from '../inner-component/InnerComponent';
import RotatedGrid, { RotatedGridProps } from './RotatedGrid';
import { InnerComponentOptions } from '../inner-component/interfaces/InnerComponentOptions';

interface Props extends RotatedGridProps {
  title?: InnerComponentOptions;
}

const RotatedGridWithElements: React.FC<Props> = props => {
  const { title, className, ...rotatedGridProps } = props;

  const cn = useClassName(RotatedGridWithElementsBlock.Root, className);

  return (
    <section className={cn()}>
      {title && <InnerComponent className={cn(RotatedGridWithElementsElement.Title)} options={title} />}

      <RotatedGrid className={cn(RotatedGridWithElementsElement.Grid)} {...rotatedGridProps} />
    </section>
  );
};

export default RotatedGridWithElements;
