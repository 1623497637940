import { NewsFilterData } from '../interfaces/NewsFilterData';
import { NewsFilterResultDto } from '../interfaces/NewsFilterResultDto';
import { NewsFilterField } from '../enums/NewsFilterField';
import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import getTime from 'date-fns/getTime';
import last from 'lodash/last';
import pipe from 'lodash/fp/pipe';
import { IsoDate } from '../../shared/types';

const decrementIsoDatetime: (date: IsoDate) => IsoDate = pipe(parseISO, getTime, time => time - 1, formatISO);

export function updateNewsFilterEndDate(
  filterData: NewsFilterData,
  displayedNews: NewsFilterResultDto[],
): NewsFilterData {
  const lastDisplayedNews = last(displayedNews);

  if (lastDisplayedNews) {
    return {
      ...filterData,
      // Set end date value to 1 second lower than the lowest date of the displayed news.
      // This is the most important part of the news infinite scroll implementation.
      [NewsFilterField.EndDate]: decrementIsoDatetime(lastDisplayedNews.breakDate),
    };
  }

  return filterData;
}
