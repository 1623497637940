import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { ViewColumnSystemName } from '../../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { ViewOptionsService } from '../../../../../shared/entities/view/components/view/services/ViewOptionsService';
import { screensUnderwriterSharedOptions } from './screensUnderwriterSharedOptions';
import { ViewFilterName } from '../../../../../shared/entities/filters/enum/types/ViewFilterName';

export const screensUnderwriterLandingOptions: Partial<ScreenOptions> = ViewOptionsService.merge<ScreenOptions>(
  screensUnderwriterSharedOptions,
  {
    grid: {
      title: 'ALL UNDERWRITER ACTIVITY',
      idCols: [ViewColumnSystemName.Underwriter],
    },
    screenTriggerOptions: {
      columnName: ViewColumnSystemName.Underwriter,
      filterName: ViewFilterName.Underwriter,
      filterValueColumnName: ViewColumnSystemName.UnderwriterKey,
    },
  },
);
