import React, { useContext } from 'react';
import FilterControl from '../FilterControl';
import { ViewFilterName } from '../../../../enum/types/ViewFilterName';
import InsiderSellingControls from '../controls/deal-info/InsiderSellingControls';
import FilterSectionsAdvancedControls from '../FilterSectionsAdvancedControls';
import { FilterSectionsFormContext } from '../../context/FilterSectionsFormContext';
import UnderwriterControls from '../controls/deal-info/UnderwriterControls';
import { useExchangeDependencies } from '../../../../hooks/useExchangeDependencies';

const DealInfoFilterSection: React.FC = () => {
  const { dictionaries } = useContext(FilterSectionsFormContext);

  useExchangeDependencies();

  return (
    <>
      <FilterControl name={ViewFilterName._DealSize} />

      <FilterControl name={ViewFilterName.Withdrawn} />

      <FilterControl name={ViewFilterName.Postponed} />

      <FilterControl name={ViewFilterName.Acquired} />

      <UnderwriterControls />

      <FilterControl
        name={ViewFilterName.DealInfoPreIpoExchange}
        controlProps={{
          options: dictionaries?.[ViewFilterName.DealInfoPreIpoExchange],
        }}
      />

      <FilterControl name={ViewFilterName.IsCrossListing} />

      <FilterControl name={ViewFilterName.IsUpListing} />

      <FilterControl name={ViewFilterName.IsDualListing} />

      <FilterControl name={ViewFilterName.InsiderBuying} />

      <InsiderSellingControls />

      <FilterSectionsAdvancedControls>
        <FilterControl name={ViewFilterName.TermsChange} />
      </FilterSectionsAdvancedControls>

      <FilterControl name={ViewFilterName.IPOPriceRange} />

      <FilterSectionsAdvancedControls>
        <FilterControl name={ViewFilterName._IPOPricedRange} />

        <FilterControl name={ViewFilterName._FloatToMarketCap} />

        <FilterControl name={ViewFilterName._DealSizeVsExpected} />
      </FilterSectionsAdvancedControls>

      <FilterControl name={ViewFilterName._DealSizeMarketCapVsExpected} />

      <FilterControl name={ViewFilterName.DealInfoDualClassSharesOnly} />
    </>
  );
};

export default DealInfoFilterSection;
