import React from 'react';
import './PrimarySectionCol.scss';
import { useClassName } from '../../hooks/useClassName';
import { PrimarySectionColBlock, PrimarySectionColModifier } from './primarySectionColBem';

interface Props {
  isFullWidth?: boolean;
  className?: string;
}

const PrimarySectionCol: React.FC<Props> = props => {
  const { isFullWidth = true } = props;

  const cn = useClassName(PrimarySectionColBlock.Root, props.className);

  return (
    <div
      className={cn({
        [PrimarySectionColModifier.FullWidth]: isFullWidth,
      })}
    >
      {props.children}
    </div>
  );
};

export default PrimarySectionCol;
