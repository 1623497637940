import React from 'react';
import './Control.scss';
import { BlockOrientation } from '../../enums/BlockOrientation';
import { useClassName } from '../../hooks/useClassName';
import { ControlBlock } from './ControlBem';
import { ControlSize } from '../../enums/ControlSize';

export interface ControlCommonProps {
  orientation?: BlockOrientation;
  width?: ControlSize;
}

interface Props extends ControlCommonProps {
  className?: string;
}

const Control: React.FC<Props> = props => {
  const { orientation = BlockOrientation.Column } = props;

  const cn = useClassName(ControlBlock.Root, props.className);

  return (
    <div
      className={cn({
        [orientation]: orientation,
        [`${props.width}Width`]: props.width,
      })}
    >
      {props.children}
    </div>
  );
};

export default Control;
