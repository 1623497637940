import React from 'react';
import DocumentHead from '../../shared/components/DocumentHead';
import { RoutePath } from '../../shared/enums/RoutePath';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import OrderedList from '../../shared/components/ordered-list/OrderedList';
import { loginHelpInfo } from './loginHelpInfo';
import { ControlSize } from '../../shared/enums/ControlSize';

const LoginHelp: React.FC = () => {
  return (
    <div className="LoginHelp">
      <DocumentHead path={RoutePath.LoginHelp} />

      <PrimarySection header="Log In Frequently Asked Questions">
        <OrderedList items={loginHelpInfo} size={ControlSize.Large} isItemTitleHighlighted />
      </PrimarySection>
    </div>
  );
};

export default LoginHelp;
