import { ViewType } from '../enums/ViewType';
import { viewTypeScreens } from '../constants/viewTypeScreens';
import { ViewTypeScreen } from '../types/screen/ViewTypeScreen';
import { screenEntity, viewEntity } from '../constants/viewsTypeTexts';

export class ViewTypeService {
  static isScreen(viewType: ViewType): viewType is ViewTypeScreen {
    return viewTypeScreens.includes(viewType as ViewTypeScreen);
  }

  static getEntityName(viewType: ViewType): string {
    return this.isScreen(viewType) ? screenEntity : viewEntity;
  }
}
