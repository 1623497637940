import React from 'react';
import { useEmailPreferencesContextValue } from './hooks/useEmailPreferencesContextValue';
import { EmailPreferencesContext } from './context/EmailPreferencesContext';
import EmailPreferencesContent from './EmailPreferencesContent';

const EmailPreferences: React.FC = () => {
  return (
    <EmailPreferencesContext.Provider value={useEmailPreferencesContextValue()}>
      <EmailPreferencesContent />
    </EmailPreferencesContext.Provider>
  );
};

export default EmailPreferences;
