import React, { useMemo } from 'react';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import { ResearchTypeTitle } from '../../../../../filters/enum/types/ResearchTypeTitle';
import Hyperlink from '../../../../../../components/typography/Hyperlink';
import { NewsLocationService } from '../../../../../../../news/shared/services/NewsLocationService';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';
import FileActions from '../../../../../../components/file-actions/FileActions';
import { FileActionType } from '../../../../../../components/file-actions/enums/FileActionType';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { ResearchTypeColumnValue } from '../../interfaces/column-values/ResearchTypeColumnValue';
import CompanyLink from '../../../../../../../company/components/company-link/CompanyLink';
import { ViewGridCellValueService } from '../../services/ViewGridCellValueService';
import { FileActionProp } from '../../../../../../components/file-actions/interfaces/FileActionProp';

type Props = GridCellFormatterCommonProps;

const ResearchTypeCell: React.FC<Props> = props => {
  const value: ResearchTypeColumnValue = props.value;
  const title = value.title;
  const link = value.link;

  const reportKey = ViewGridCellValueService.getRaw(props.data, ViewColumnSystemName.ReportKey);
  const ticker = ViewGridCellValueService.getRaw(props.data, ViewColumnSystemName.Ticker);
  const isNews = title === ResearchTypeTitle.AnalystNote;

  const fileActions = useMemo<FileActionProp[]>(() => {
    const actions = [];

    if (link) {
      switch (title) {
        case 'Mgmt Qs':
          actions.push({
            type: FileActionType.MGMTQ,
            externalDownloadOptions: {
              url: link,
            },
            tooltipTitle: 'Mgmt. Questions',
            title,
          });
          break;
        case 'Report':
          actions.push({
            type: FileActionType.Pdf,
            externalDownloadOptions: {
              url: link,
            },
            tooltipTitle: 'Pdf Report',
            title,
          });
          break;
        default:
          actions.push({
            type: FileActionType.Pdf,
            externalDownloadOptions: {
              url: link,
            },
            tooltipTitle: 'Pdf Report',
            title: 'Report',
          });
          break;
      }
    }

    return actions;
  }, [link, title]);

  return (
    <CustomCellFormatterWrapper value={value}>
      {isNews ? (
        <Hyperlink to={NewsLocationService.getRoutePath(reportKey)}>{title}</Hyperlink>
      ) : fileActions ? (
        <FileActions fileActions={fileActions} size={ControlSize.Small} />
      ) : (
        <CompanyLink ticker={ticker} visibleValue={title} />
      )}
    </CustomCellFormatterWrapper>
  );
};

export default ResearchTypeCell;
