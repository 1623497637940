import React, { useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import './DropdownButton.scss';
import { SelectCallback } from 'react-bootstrap/helpers';
import uniqueId from 'lodash.uniqueid';
import { useClassName } from '../../hooks/useClassName';
import { DropdownButtonBlock } from './DropdownButtonBem';

export interface DropdownButtonProps {
  content: React.ReactNode;
  onSelect: SelectCallback;
  variant?: DropDownVariants;
}

export enum DropDownVariants {
  Primary = 'primary',
  OutlinePrimary = 'outline-primary',
}

const DropdownButton: React.FC<DropdownButtonProps> = props => {
  const id: string = useMemo(() => uniqueId('DropdownButton-'), []);
  const cn = useClassName(DropdownButtonBlock.Root);

  return (
    <Dropdown className={cn()} onSelect={props.onSelect}>
      <Dropdown.Toggle variant={props.variant} id={id}>
        {props.content}
      </Dropdown.Toggle>

      <Dropdown.Menu>{props.children}</Dropdown.Menu>
    </Dropdown>
  );
};

DropdownButton.defaultProps = {
  variant: DropDownVariants.OutlinePrimary,
};

export default DropdownButton;
