import { FilterControlSettings } from '../../../types/FilterControlSettings';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { ControlSize } from '../../../../../enums/ControlSize';

export const favouriteControls: FilterControlSettings = {
  [ViewFilterName.Favorite]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.Favorite,
    props: {
      name: ViewFilterName.Favorite,
      label: 'Only Show Favorites',
      size: ControlSize.Small,
    },
  },
};
