import { FileActionType } from '../enums/FileActionType';
import { IconName } from '../../icon/IconName';
import { FileActionsSettings } from '../types/FileActionsSettings';
import { ColorScheme } from '../../../enums/ColorScheme';

export const FILE_ACTIONS_SETTINGS: FileActionsSettings = {
  [FileActionType.Print]: {
    icon: IconName.Printing,
    colorScheme: ColorScheme.Primary,
  },
  [FileActionType.Excel]: {
    icon: IconName.Excel,
  },
  [FileActionType.MGMTQ]: {
    icon: IconName.MgmtQ,
  },
  [FileActionType.Pdf]: {
    icon: IconName.Pdf,
  },
  [FileActionType.Save]: {
    icon: IconName.Save,
    colorScheme: ColorScheme.Primary,
  },
  [FileActionType.Link]: {
    icon: IconName.Link,
    colorScheme: ColorScheme.Primary,
  },
  [FileActionType.Document]: {
    icon: IconName.Paper,
    colorScheme: ColorScheme.Primary,
  },
  [FileActionType.Insight]: {
    icon: IconName.StickyNote,
    colorScheme: ColorScheme.Primary,
  },
};
