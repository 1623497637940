export enum TextSelectBlock {
  Root = 'TextSelect',
}

export enum TextSelectElement {
  Postfix = 'postfix',
  List = 'list',
  Item = 'item',
  Divider = 'divider',
  TextButton = 'textButton',
  Button = 'button',
}

export enum TextSelectModifier {
  Selected = 'selected',
}
