import React from 'react';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { useClassName } from '../../../../../../hooks/useClassName';
import { EstValuationCellBlock } from './EstValuationCellBem';
import Tooltip from '../../../../../../components/tooltip/Tooltip';
import useBreakpointAvailable from '../../../../../../effects/useBreakpointAvailable';
import { profileMobileBreakpoints } from '../../../../../../../company/components/profile/shared/constants/profileMobileBreakpoints';

type Props = GridCellFormatterCommonProps;

const EstValuationCell: React.FC<Props> = props => {
  const cn = useClassName(EstValuationCellBlock.Root);
  const isMobile = useBreakpointAvailable(profileMobileBreakpoints);

  const EstValuationSource = props.data.valuationSource;

  return (
    <CustomCellFormatterWrapper className={cn()} value={props.valueFormatted}>
      {EstValuationSource && !isMobile ? (
        <Tooltip
          className={cn()}
          isTitleHighlighted
          isUnderFixed
          //isUnderFixed={true}
          content={`Source: ` + props.data.valuationSourceVisible}
          title={props.valueFormatted}
        />
      ) : (
        props.valueFormatted
      )}
    </CustomCellFormatterWrapper>
  );
};

export default EstValuationCell;
export {};
