import React, { ReactNode, useMemo } from 'react';
import './ActionListNav.scss';
import ActionListNavService from './services/ActionListNavService';
import ActionListNavItem from './ActionListNavItem';
import { ActionListNavItem as ActionListNavItemInterface } from './interfaces/ActionListNavItem';
import { OnActionListNavItemDeleteFn } from './types/OnActionListNavItemDeleteFn';
import { ActionListNavBlock, ActionListNavElement } from './actionListNavBem';
import { ControlSize } from '../../../../enums/ControlSize';
import { useClassName } from '../../../../hooks/useClassName';
import AccordionPrimary from '../../../accordion/AccordionPrimary';
import { mobileBreakpoints } from '../../../../constants/breakpoints/mobileBreakpoints';
import SimpleGrid from '../../../grid/simple-grid/SimpleGrid';
import { GridColumn } from '../../../grid/shared/interfaces/GridColumn';
import { SimpleGridType } from '../../../grid/simple-grid/enums/SimpleGridType';
import { AlignTypesHorizontal } from '../../../../enums/AlignTypesHorizontal';

interface Props {
  className?: string;
  items: ActionListNavItemInterface[];
  noData: ReactNode;
  header?: ReactNode;
  size?: ControlSize;
  isFullHeight?: boolean;
  hasOrderItem?: boolean;
  isGrid?: boolean;
  noDataAlignTypeHorizontal?: AlignTypesHorizontal;
  onDeleteClick?: OnActionListNavItemDeleteFn;
}

const ActionListNav: React.FC<Props> = props => {
  const { noDataAlignTypeHorizontal = AlignTypesHorizontal.Center } = props;

  const cn = useClassName(ActionListNavBlock.Root, props.className);

  const hasItems = useMemo<boolean>(() => ActionListNavService.hasItems(props.items), [props.items]);

  const listComponent = useMemo(
    () =>
      props.items.map((item: ActionListNavItemInterface, index: number) => (
        <ActionListNavItem
          key={index}
          item={item}
          orderNumber={props.hasOrderItem ? index + 1 : undefined}
          isTitleNoWrap={!props.isGrid}
          onDelete={props.onDeleteClick}
        />
      )),
    [props.items, props.hasOrderItem, props.isGrid, props.onDeleteClick],
  );

  const gridComponent = useMemo(() => {
    const column = 'column';

    const rows = listComponent.map(component => ({
      [column]: component,
    }));
    const columns: GridColumn[] = [
      {
        field: column,
      },
    ];
    return (
      <SimpleGrid
        columns={columns}
        rows={rows}
        styleType={SimpleGridType.Grid}
        isHeaderHidden
        hasAroundBorder={false}
        size={ControlSize.Small}
        isResponsive={false}
        isFullWidth
      />
    );
  }, [listComponent]);

  const content = useMemo(() => {
    if (!hasItems) {
      return (
        <div className={cn(ActionListNavElement.NoData, { [noDataAlignTypeHorizontal]: noDataAlignTypeHorizontal })}>
          {props.noData}
        </div>
      );
    }

    return props.isGrid ? gridComponent : listComponent;
  }, [hasItems, props.isGrid, gridComponent, listComponent, cn, props.noData, noDataAlignTypeHorizontal]);

  return (
    <AccordionPrimary
      classNames={cn()}
      title={props.header}
      hasHeader={!!props.header}
      isFullHeight={props.isFullHeight}
      size={ControlSize.Medium}
      breakpoints={mobileBreakpoints}
    >
      {content}
    </AccordionPrimary>
  );
};

export default ActionListNav;
