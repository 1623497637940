import React, { useContext } from 'react';
import CompanyDesktopProfile from './desktop/CompanyDesktopProfile';
import CompanyMobileProfile from './mobile/CompanyMobileProfile';
import './CompanyProfile.scss';
import useBreakpointAvailable from '../../../shared/effects/useBreakpointAvailable';
import { profileMobileBreakpoints } from './shared/constants/profileMobileBreakpoints';
import CompanySection from '../shared/CompanySection';
import { CompanyContext } from '../context/CompanyContext';
import { Nullable } from '../../../shared/types/generics';
import { ProfileData } from '../../interfaces/ProfileData';
import { CompanyTab } from '../../enums/CompanyTab';
import { ControlSize } from '../../../shared/enums/ControlSize';
import CompanyTabsPrint from '../CompanyTabsPrint';
import CompanyInvestmentRationale from '../investment-rationale/CompanyInvestmentRationale';
import CompanyFundamentalInsights from '../fundamental-insights/CompanyFundamentalInsights';
import CompanyMgmtOwners from '../mgmt-owners/CompanyMgmtOwners';
import CompanyPeerTrading from '../peer-trading/CompanyPeerTrading';
import CompanyRelativeValuation from '../relative-valuation/CompanyRelativeValuation';
import CompanyFinancials from '../financials/CompanyFinancials';
import CompanyMgmtQs from '../mgmt-qs/CompanyMgmtQs';
import CompanyMilestones from '../milestones/CompanyMilestones';

const CompanyProfile: React.FC = () => {
  const isMobile: boolean = useBreakpointAvailable(profileMobileBreakpoints);

  const { getTabData } = useContext(CompanyContext);
  const data: Nullable<ProfileData> = getTabData(CompanyTab.Profile);

  const content: React.ReactNode = isMobile ? <CompanyMobileProfile /> : <CompanyDesktopProfile />;

  return (
    <>
      <CompanySection className="CompanyProfile" data={data} maxContentSize={ControlSize.ExtraLarge}>
        {content}
      </CompanySection>

      <CompanyInvestmentRationale />
      <CompanyFundamentalInsights />
      <CompanyMgmtOwners />
      <CompanyPeerTrading />
      <CompanyRelativeValuation />
      <CompanyFinancials />
      <CompanyMgmtQs />
      <CompanyMilestones />

      <CompanyTabsPrint />
    </>
  );
};

export default CompanyProfile;
