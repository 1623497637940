import { AxiosInstance, AxiosRequestConfig } from 'axios';
import axiosInstance from './AxiosInstance';
import { CustomAny } from '../types/generics';
import {
  ApiResponseError,
  ApiResponseSuccess,
  ApiResponseSuccessFile,
  ApiResponseSuccessFileData,
} from '../types/api/ApiResponse';
import { HttpHeader } from '../services/http-header/enums/HttpHeader';

export default class ApiService {
  static instance: AxiosInstance = axiosInstance;

  static get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.wrapper<T>(this.instance.get.bind(this.instance, url, config));
  }

  static post<T>(url: string, data?: CustomAny, config?: AxiosRequestConfig): Promise<T> {
    return this.wrapper<T>(this.instance.post.bind(this.instance, url, data, config));
  }

  static delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.wrapper(this.instance.delete.bind(this.instance, url, config));
  }

  static getFile(url: string): Promise<ApiResponseSuccessFileData> {
    return this.wrapperFile(this.instance.get.bind(this.instance, url));
  }

  static getFilePost(url: string, data?: CustomAny): Promise<ApiResponseSuccessFileData> {
    return this.wrapperFile(this.instance.post.bind(this.instance, url, data));
  }

  private static wrapper<T>(fn: () => Promise<CustomAny>): Promise<T> {
    return fn()
      .then((response: ApiResponseSuccess) => response.data.data)
      .catch((error: ApiResponseError) => Promise.reject(error));
  }

  private static wrapperFile(
    fn: (config?: AxiosRequestConfig) => Promise<CustomAny>,
  ): Promise<ApiResponseSuccessFileData> {
    return fn({ responseType: 'blob' })
      .then((response: ApiResponseSuccessFile) => ({
        contentDisposition: response.request.getResponseHeader(HttpHeader.ContentDisposition),
        blob: response.data,
      }))
      .catch((error: ApiResponseError) => Promise.reject(error));
  }
}
