import React from 'react';
import './NewsLandingMostItem.scss';
import { NewsMostViewedDto } from '../../interfaces/NewsMostViewedDto';
import { Link } from 'react-router-dom';
import { NewsLocationService } from '../../shared/services/NewsLocationService';
import { useClassName } from '../../../shared/hooks/useClassName';
import { useNewsTags } from '../../hooks/useNewsTags';
import NewsItemTags from '../../components/NewsItemTags';

interface Props {
  data: NewsMostViewedDto;
}

const NewsLandingMostItem: React.FC<Props> = props => {
  const url = NewsLocationService.getRoutePath(props.data.newsKey);
  const tags = useNewsTags(props.data);

  const cn = useClassName('NewsLandingMostItem');

  return (
    <div className={cn()}>
      <Link className={cn('link')} to={url}>
        {props.data.headline}
      </Link>

      <NewsItemTags tags={tags} />
    </div>
  );
};

export default NewsLandingMostItem;
