import { ViewOptions } from '../../../shared/entities/view/components/view/interfaces/options/ViewOptions';
import { FilterSectionType } from '../../../shared/entities/filters/enum/FilterSectionType';
import { ViewFilterName } from '../../../shared/entities/filters/enum/types/ViewFilterName';
import { TimeFrame } from '../../../shared/entities/filters/enum/TimeFrame';
import { FileActionType } from '../../../shared/components/file-actions/enums/FileActionType';
import { ViewSummaryAlignType } from '../../../shared/entities/view/components/view/enums/ViewSummaryAlignType';

export const scheduledStaticViewOptions: Partial<ViewOptions> = {
  header: 'Scheduled IPOs',
  toolbar: {
    modalFilters: {
      sections: [
        {
          type: FilterSectionType.OfferDate,
          controls: [
            {
              name: ViewFilterName._OfferDate,
              props: {
                timeFrames: [TimeFrame.ThisWeek, TimeFrame.NextWeek, TimeFrame.All],
                hasDateRange: false,
              },
            },
          ],
        },
        {
          type: FilterSectionType.CompanyInfo,
          controls: [{ name: ViewFilterName.SectorIndustry }, { name: ViewFilterName._Headquarters }],
        },
        {
          type: FilterSectionType.Investors,
          controls: [
            { name: ViewFilterName.SpinOff },
            { name: ViewFilterName.MgmtOwned },
            { name: ViewFilterName.PE },
            { name: ViewFilterName.PeGrowthOther },
            { name: ViewFilterName.VentureCapital },
          ],
        },
        {
          type: FilterSectionType.SecurityType,
          controls: [
            { name: ViewFilterName.SecurityTypeCommonSharesList },
            { name: ViewFilterName.SecurityTypeADRsList },
            { name: ViewFilterName.SecurityTypeREITList },
            { name: ViewFilterName.SecurityTypeMLPList },
            { name: ViewFilterName.SecurityTypeBlankCheckList },
            { name: ViewFilterName.SecurityTypeRegAList },
            { name: ViewFilterName.SecurityTypeDirectListingList },
          ],
        },
        {
          type: FilterSectionType.Location,
          controls: [
            { name: ViewFilterName.LocationListingLocation },
            { name: ViewFilterName.ExchangeCountry },
            { name: ViewFilterName.ExchangeRegion },
            { name: ViewFilterName.IncludeChina },
            { name: ViewFilterName.LocationExchangeList },
          ],
        },
        {
          type: FilterSectionType.DealInfo,
          controls: [{ name: ViewFilterName._DealSize }, { name: ViewFilterName.UnderwriterKeyList }],
        },
        {
          type: FilterSectionType.Favorite,
          controls: [{ name: ViewFilterName.Favorite }],
        },
        {
          type: FilterSectionType.RenaissanceRating,
          controls: [{ name: ViewFilterName.RcRating }],
        },
        {
          type: FilterSectionType.Valuation,
          controls: [{ name: ViewFilterName._MarketCapAtIPO }],
        },
      ],
    },
    quickFilter: {
      hasLocation: true,
      hasDateRange: false,
      hasTimeFrameRange: true,
      hideDateRange: true,
      timeFrameOptions: [TimeFrame.ThisWeek, TimeFrame.NextWeek, TimeFrame.All],
      hasIncludeSPACtextFilter: true,
    },
    fileActionTypes: [FileActionType.Save, FileActionType.Excel, FileActionType.Print],
  },
  summary: {
    align: ViewSummaryAlignType.Simple,
  },
  grid: {
    isDateColumnSortingInverse: false,
    isFixedHeight: false,
  },
  apiFooter: true,
};
