import React from 'react';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import './NewsSearch.scss';
import Helmet from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NewsOpenableFilter from '../components/NewsOpenableFilter';
import { NewsFilterData } from '../interfaces/NewsFilterData';
import PageLoader from '../../shared/components/loader/PageLoader';
import NewsSearchList from './NewsSearchList';
import InfiniteScroll from '../../shared/components/InfiniteScroll';
import { useNewsSearchData } from './hooks/useNewsSearchData';
import { useLocationSearchData } from '../../shared/hooks/useLocationSearchData';

const NewsSearch: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const filterData: NewsFilterData = useLocationSearchData(props.location.search);
  const [news, loadMore, isInitialLoading, hasMore] = useNewsSearchData(filterData);

  return (
    <article className="NewsSearch">
      <Helmet>
        <title>IPO News Search</title>
      </Helmet>

      <PrimarySection header="IPO News Search">
        <NewsOpenableFilter defaultValues={filterData} isInitialFilterVisible />

        <PageLoader
          loading={isInitialLoading}
          otherwise={news.length}
          otherwiseContent="No news found. Change your search criteria."
        >
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
            <NewsSearchList items={news} />
          </InfiniteScroll>
        </PageLoader>
      </PrimarySection>
    </article>
  );
};

export default withRouter(NewsSearch);
