import { isNil } from 'lodash';
import { Nullable } from '../types/generics';
import { DateHelper } from '../helpers/date/DateHelper';

export class SortService {
  static sortDate(valueA?: Nullable<string | Date>, valueB?: Nullable<string | Date>): number {
    return this.getDateSortValue(valueA) - this.getDateSortValue(valueB);
  }

  static sortNumber(valueA?: Nullable<number>, valueB?: Nullable<number>): number {
    return this.getNumberSortValue(valueA) - this.getNumberSortValue(valueB);
  }

  static sortText(valueA?: Nullable<string>, valueB?: Nullable<string>): number {
    const valueALower = this.getTextSortValue(valueA);
    const valueBLower = this.getTextSortValue(valueB);

    if (valueALower > valueBLower) {
      return 1;
    } else if (valueALower < valueBLower) {
      return -1;
    } else {
      return 0;
    }
  }

  static sortBoolean(valueA: boolean, valueB: boolean): number {
    return valueA === valueB ? 0 : valueA ? -1 : 1;
  }

  private static getDateSortValue(value?: Nullable<string | Date>): number {
    const date = DateHelper.parseISODate(value);

    if (date) {
      return date.getTime();
    }

    return -1;
  }

  private static getNumberSortValue(value?: Nullable<number>): number {
    if (!isNil(value)) {
      return value;
    }

    return -1;
  }

  private static getTextSortValue(value?: Nullable<string>): string {
    if (value) {
      value = '' + value;
      return value.toLowerCase();
    }
    return '';
  }
}
