import { AxiosRequestConfig } from 'axios';
import TokenStorageService from '../services/token/TokenStorageService';
import { TokenHeaderService } from '../services/token/TokenHeaderService';

export function tokenInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
  const token = TokenStorageService.getAccessToken();

  if (token) {
    TokenHeaderService.setConfigTokenHeader(config);
  }

  return config;
}
