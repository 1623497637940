import { NewsItemTag } from '../interfaces/NewsItemTag';
import { NewsType } from '../enums/NewsType';
import { NewsTagModifier } from '../enums/NewsTagModifier';

export function getNewsSourceTag(item: { type: NewsType; source?: string }): NewsItemTag | undefined {
  // In accordance with https://wiki.itransition.com/pages/viewpage.action?pageId=606978107
  if (item.type === NewsType.External && item.source) {
    return {
      title: item.source,
      modifier: NewsTagModifier.Gray,
    };
  }
}
