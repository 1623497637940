import React from 'react';
import { memoize } from 'lodash';
import { PcmlApiService } from '../../../../../api/PcmlApiService';
import ArticlesTooltipContent from './ArticlesTooltipContent';
import TooltipAsync from '../../../../../components/tooltip/TooltipAsync';
import { ControlSize } from '../../../../../enums/ControlSize';
import './ArticlesTooltipContent.scss';

export interface PcmlTooltipProps {
  articlesId: any;
}

const ArticlesTooltip: React.FC<PcmlTooltipProps> = props => {
  return (
    <TooltipAsync
      title={'All'}
      component={ArticlesTooltipContent}
      isTitleHighlighted={true}
      size={ControlSize.Medium}
      hasIndentHorizontal={false}
      getDataFn={memoize(PcmlApiService.getTooltip.bind(PcmlApiService, props.articlesId))}
    />
  );
};

export default ArticlesTooltip;
