import React from 'react';
import ScreensLanding from '../../shared/pages/components/landing/ScreensLanding';
import { screensUnderwriterLandingOptions } from './constants/screensUnderwriterLandingOptions';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';

const ScreensUnderwriterLanding: React.FC = () => {
  return <ScreensLanding landingType={ViewType.UnderwriterLanding} options={screensUnderwriterLandingOptions} />;
};

export default ScreensUnderwriterLanding;
