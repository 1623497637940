import React, { useContext, useCallback } from 'react';
import { RoutesDescriptions } from '../../../shared/types/RoutesDescriptions';
import CompanyTeamNotesTitle from '../team-notes/CompanyTeamNotesTitle';
import { CompanyTabsPath } from '../../enums/CompanyTabsPath';
import { useRelativeTabPath } from './useRelativeTabPath';
import { CompanyContext } from '../context/CompanyContext';
import { CompanyTab } from '../../enums/CompanyTab';
import { companyTabsTitles } from '../../constants/companyTabsTitles';

export const useCompanyRoutesTitles = (): RoutesDescriptions => {
  const { tabsData } = useContext(CompanyContext);
  const [data] = tabsData;

  const getTabTitle = useCallback((tab: CompanyTab, title: React.ReactNode) => data?.[tab] && title, [data]);

  const getMgmtQsTabTitle = useCallback(
    () =>
      data?.[CompanyTab.MgmtQs]?.questions?.length &&
      data?.[CompanyTab.MgmtQs]?.summary &&
      companyTabsTitles[CompanyTab.MgmtQs],
    [data],
  );

  return {
    [useRelativeTabPath(CompanyTabsPath.Profile)]: getTabTitle(
      CompanyTab.Profile,
      companyTabsTitles[CompanyTab.Profile],
    ),
    [useRelativeTabPath(CompanyTabsPath.InvestmentRationale)]: getTabTitle(
      CompanyTab.InvestmentRationale,
      companyTabsTitles[CompanyTab.InvestmentRationale],
    ),
    [useRelativeTabPath(CompanyTabsPath.FundamentalInsights)]: getTabTitle(
      CompanyTab.FundamentalInsights,
      companyTabsTitles[CompanyTab.FundamentalInsights],
    ),
    [useRelativeTabPath(CompanyTabsPath.Financials)]: getTabTitle(
      CompanyTab.Financials,
      companyTabsTitles[CompanyTab.Financials],
    ),
    [useRelativeTabPath(CompanyTabsPath.MgmtQs)]: getMgmtQsTabTitle(),
    [useRelativeTabPath(CompanyTabsPath.MgmtOwners)]: getTabTitle(
      CompanyTab.MgmtOwners,
      companyTabsTitles[CompanyTab.MgmtOwners],
    ),
    [useRelativeTabPath(CompanyTabsPath.RelativeValuation)]: getTabTitle(
      CompanyTab.RelativeValuation,
      companyTabsTitles[CompanyTab.RelativeValuation],
    ),
    [useRelativeTabPath(CompanyTabsPath.PeerTrading)]: getTabTitle(
      CompanyTab.PeerTrading,
      companyTabsTitles[CompanyTab.PeerTrading],
    ),
    [useRelativeTabPath(CompanyTabsPath.Milestones)]: getTabTitle(
      CompanyTab.Milestones,
      companyTabsTitles[CompanyTab.Milestones],
    ),
    [useRelativeTabPath(CompanyTabsPath.TeamNotes)]: <CompanyTeamNotesTitle />,
  };
};
