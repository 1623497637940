import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import ButtonCheckbox, { ButtonCheckboxCommonProps } from './ButtonCheckbox';
import { FormControlCommonProps } from './interfaces/FormControlCommonProps';

export interface FormButtonTextValuedCheckboxProps extends ButtonCheckboxCommonProps, FormControlCommonProps {
  textValue: string;
}

const FormButtonTextValuedCheckbox: React.FC<FormButtonTextValuedCheckboxProps> = props => {
  const { register, setValue, watch } = useFormContext();

  const stringValue: string = watch && watch(props.name);

  const value = stringValue === props.textValue ? true : false;

  useEffect(() => register?.({ name: props.name }), [register, props.name]);

  const onChangeValue = useCallback(
    (value: boolean) => {
      if (value) {
        setValue?.(props.name, props.textValue);
      } else {
        setValue?.(props.name, '');
      }
    },
    [props.name, setValue],
  );

  return <ButtonCheckbox {...props} value={value} onChangeValue={onChangeValue} />;
};

export default FormButtonTextValuedCheckbox;
