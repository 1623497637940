export const API_URL = process.env.REACT_APP_API_URL as string;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL as string;
export const RECAPTCHA_SITE_ID = process.env.REACT_APP_RECAPTCHA_SITE_ID as string;
export const AG_GRID_LICENSE_KEY = process.env.REACT_APP_AG_GRID_LICENSE_KEY as string;
export const LOCK_UPS_VIEW_ID = process.env.REACT_APP_LOCK_UPS_VIEW_ID as string;
export const EXPIRED_LOCK_UPS_VIEW_ID = process.env.REACT_APP_EXPIRED_LOCK_UPS_VIEW_ID as string;
export const MARKETING_SITE_URL = process.env.REACT_APP_MARKETING_SITE_URL as string;
export const VIDEO_BANNER_IMAGE_LOCATION = process.env.REACT_APP_VIDEO_BANNER_IMAGE_LOCATION as string;
export const EMAILS_WITH_GA_ACCESS_LIST = (process.env.REACT_APP_EMAILS_WITH_GA_ACCESS_LIST as string) || '';
export const EMAILS_WITH_GA_ANALYTICS_LIST = (process.env.REACT_APP_EMAILS_WITH_GA_ANALYTICS_LIST as string) || '';
