import PrimarySection from '../shared/components/primary-section/PrimarySection';
import React from 'react';
import PrimarySectionRow from '../shared/components/primary-section/PrimarySectionRow';
import SimpleGrid from '../shared/components/grid/simple-grid/SimpleGrid';
import { tablesData, CustomSimpleGridProps } from './constants/tablesData';
import './Tables.scss';
import { useClassName } from '../shared/hooks/useClassName';

const Tables: React.FC = () => {
  const cn = useClassName('Tables');

  return (
    <PrimarySection className={cn()} header="Tables">
      {tablesData.map((tableProps: CustomSimpleGridProps, index: number) => (
        <PrimarySectionRow header={tableProps.title} key={index}>
          <SimpleGrid
            className={cn('customTable', { [tableProps.color]: tableProps.color })}
            isResponsive
            {...tableProps}
            title="Table title"
          />
        </PrimarySectionRow>
      ))}
    </PrimarySection>
  );
};

export default Tables;
