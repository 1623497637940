import { screensSpacsSharedOptions } from './screensSpacsSharedOptions';
import { ViewOptionsService } from '../../../../../shared/entities/view/components/view/services/ViewOptionsService';
import { ViewColumnSystemName } from '../../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { ViewSummaryAlignType } from '../../../../../shared/entities/view/components/view/enums/ViewSummaryAlignType';
import { ViewGridColumnWidth } from '../../../../../shared/entities/view/components/view-grid/enums/ViewGridColumnWidth';

export const screensSpacsInUseOptions: Partial<ScreenOptions> = ViewOptionsService.merge<ScreenOptions>(
  screensSpacsSharedOptions,
  {
    summary: {
      align: ViewSummaryAlignType.Grid,
    },
    grid: {
      idCols: [ViewColumnSystemName.Id],
      customColumnsSettings: [
        {
          field: ViewColumnSystemName.LastUpdateDays,
          isSortingInverse: false,
        },
        {
          field: ViewColumnSystemName.TargetBusiness,
          width: ViewGridColumnWidth.XXXl,
        },
      ],
    },
  },
);
