import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ControlDivider } from '../../../../components/forms/services/control-divider/enums/ControlDivider';
import SecondarySection from '../../../../components/secondary-section/SecondarySection';
import { StatsElementFiltersInternalService } from '../shared/filters-internal/services/StatsElementFiltersInternalService';
import StatsElementFilterInternalTextSelect from '../shared/filters-internal/components/StatsElementFilterInternalTextSelect';
import { StatsElementViewGridDto } from './interfaces/StatsElementViewGridDto';
import ViewGrid, { ViewGridProps } from '../../../view/components/view-grid/ViewGrid';
import { PortionSize } from '../../../../enums/PortionSize';
import { GridCountReducerOptions } from '../../../../components/grid/grid/interfaces/GridCountReducerOptions';
import { StatsElementGridContext } from '../simple-grid/interfaces/StatsElementGridContext';
import { StatsElementGridOptions } from '../simple-grid/interfaces/StatsElementGridOptions';
import { useClassName } from '../../../../hooks/useClassName';
import { StatsElementViewGridBlock, StatsElementViewGridElement } from './statsElementViewGridBem';
import { StatsElementContext } from '../context/StatsElementContext';

interface Props {
  index: number;
  viewGrid: StatsElementViewGridDto;
  gridContext: StatsElementGridContext;
  gridOptions?: StatsElementGridOptions;
}

const StatsElementViewGrid: React.FC<Props> = props => {
  const { gridOptions } = props;

  const cn = useClassName(StatsElementViewGridBlock.Root);

  const { addGridToGridsToExcel, grids } = useContext(StatsElementContext);

  const initialFilterValue = useMemo(() => StatsElementFiltersInternalService.getFirstFilterName(props.viewGrid), [
    props,
  ]);
  const [filterValue, setFilterValue] = useState(initialFilterValue);

  const filtersOptions = useMemo(
    () => StatsElementFiltersInternalService.getTextSelectOptionsFromFilters(props.viewGrid),
    [props],
  );
  const viewGridProps = useMemo(
    () => StatsElementFiltersInternalService.getFilterValue<ViewGridProps>(props.viewGrid, filterValue),
    [props, filterValue],
  );

  const viewGridPortions: GridCountReducerOptions = useMemo(
    () => ({ sizes: [PortionSize.Ten, PortionSize.TwentyFive, PortionSize.Fifty, PortionSize.OneHundred] }),
    [],
  );

  const gridIndex = useMemo(() => (grids ? grids.length + props.index + 1 : 0), [grids, props.index]);

  useEffect(() => {
    if (viewGridProps) {
      addGridToGridsToExcel(viewGridProps, gridIndex);
    }
  }, [viewGridProps, addGridToGridsToExcel, gridIndex]);

  return (
    <SecondarySection
      className={cn()}
      hasShiftHorizontal={gridOptions?.hasShiftHorizontal}
      title={
        <StatsElementFilterInternalTextSelect
          divider={ControlDivider.Slash}
          value={filterValue}
          options={filtersOptions}
          onChangeValue={setFilterValue}
        />
      }
    >
      {viewGridProps && (
        <ViewGrid
          className={cn(StatsElementViewGridElement.Grid)}
          hasCountReducing={!gridOptions?.hasNoCountReducing}
          idCols={gridOptions?.idCols}
          countReducingOptions={viewGridPortions}
          isFixedHeight={false}
          mobilePinColumns={gridOptions?.mobilePinColumns}
          onColumnMapReady={gridOptions?.onColumnMapReady}
          {...viewGridProps}
        />
      )}
    </SecondarySection>
  );
};

export default StatsElementViewGrid;
