import { Location } from '../../filters/enum/Location';
import { CalendarEventGroup } from '../interfaces/CalendarEventGroup';
import { CalendarEventType } from '../enums/CalendarEventType';
import { CalendarEvent } from '../interfaces/CalendarEvent';
import { ResponsiveCalendarEvent } from '../../../components/responsive-calendar/interfaces/ResponsiveCalendarEvent';
import { CalendarEventExtendedProps } from '../interfaces/CalendarEventExtendedProps';
import { SortService } from '../../../services/SortService';
import { HtmlService } from '../../../services/HtmlService';

export class CalendarFactory {
  static getEvents(events: CalendarEvent[]): ResponsiveCalendarEvent<CalendarEventExtendedProps>[] {
    return events.map(event => ({
      title: event.title,
      start: event.start,
      extendedProps: {
        type: event.type,
        name: event.title,
        ticker: event.title,
        favouriteProps: {
          companyName: event.title,
          isFavourite: event.isFavorite,
          companyKey: event.companyKey,
        },
        ratingText: event.ratingText,
        span: HtmlService.clearEscapeSymbols(event.span),
      },
    }));
  }

  static getEventGroups(headquarter: Location): CalendarEventGroup[] {
    return this.getEventTypes(headquarter)
      .map(type => this.getEventGroup(type, headquarter))
      .sort((eventGroupA: CalendarEventGroup, eventGroupB: CalendarEventGroup) =>
        this.sortByEventType(eventGroupA.type, eventGroupB.type),
      );
  }

  static getEventGroup(type: CalendarEventType, headquarter?: Location): CalendarEventGroup {
    switch (type) {
      case CalendarEventType.FileDate:
        return {
          type,
          title: headquarter === Location.US ? 'File Date' : 'Date Added',
          titleShort: headquarter === Location.US ? 'Filed' : 'Added',
          weight: 0,
        };
      case CalendarEventType.OfferDate:
        return { type, title: 'Offer Date (Non-US)', titleShort: 'Priced (Non-US)', weight: 1 };
      case CalendarEventType.TradeDate:
        return { type, title: 'Trade Date', titleShort: 'Traded', weight: 2 };
      case CalendarEventType.StreetResearchBegins:
        return { type, title: 'Street Research Begins', titleShort: 'Street Research', weight: 3 };
      case CalendarEventType.LockupDate:
        return { type, title: 'Lock-up Date', titleShort: 'Lock-up', weight: 4 };
      case CalendarEventType.PostponedWithdrawn:
        return { type, title: 'Postponed/Withdrawn', titleShort: 'PPD/WD', weight: 5 };
    }
  }

  static getEventTypes(headquarter: Location): CalendarEventType[] {
    switch (headquarter) {
      case Location.US:
        return [
          CalendarEventType.FileDate,
          CalendarEventType.TradeDate,
          CalendarEventType.StreetResearchBegins,
          CalendarEventType.LockupDate,
          CalendarEventType.PostponedWithdrawn,
        ];
      case Location.NonUS:
        return [
          CalendarEventType.FileDate,
          CalendarEventType.OfferDate,
          CalendarEventType.TradeDate,
          CalendarEventType.PostponedWithdrawn,
        ];
      case Location.Global:
      default:
        return [
          CalendarEventType.FileDate,
          CalendarEventType.OfferDate,
          CalendarEventType.TradeDate,
          CalendarEventType.StreetResearchBegins,
          CalendarEventType.LockupDate,
          CalendarEventType.PostponedWithdrawn,
        ];
    }
  }

  static getActiveEventTypes(groups: CalendarEventGroup[]): CalendarEventType[] {
    return groups.map(group => group.type);
  }

  static sortEvents(
    eventA: ResponsiveCalendarEvent<CalendarEventExtendedProps>,
    eventB: ResponsiveCalendarEvent<CalendarEventExtendedProps>,
  ): number {
    const typeA = eventA.extendedProps.type;
    const typeB = eventB.extendedProps.type;
    const typeATicker = eventA.extendedProps.ticker;
    const typeBTicker = eventB.extendedProps.ticker;

    if (typeA === typeB) {
      return SortService.sortText(typeATicker, typeBTicker);
    } else {
      return this.sortByEventType(typeA, typeB);
    }
  }

  static sortByEventType(typeA: CalendarEventType, typeB: CalendarEventType): number {
    return SortService.sortNumber(this.getEventGroup(typeA).weight, this.getEventGroup(typeB).weight);
  }
}
