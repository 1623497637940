import { useCallback, useState, useMemo } from 'react';
import { useHandleFn } from '../handle-fn/useHandleFn';
import { HandleFnOptions } from '../handle-fn/interfaces/HandleFnOptions';
import { CustomAny } from '../../types/generics';
import { AsyncFnData } from './interfaces/AsyncFnData';

export function useAsyncFn<T = CustomAny>(
  fn: (...args: CustomAny[]) => Promise<T> | T,
  options?: HandleFnOptions,
  initialValue?: T,
): AsyncFnData<T> {
  const [isLoading, setIsLoading] = useState(false);
  const [isDataInitialized, setIsDataInitialized] = useState(false);
  const [data, setData] = useState(initialValue);

  const wrappedFn = useCallback(
    async (...args: CustomAny[]) => {
      setIsLoading(true);

      const data = await fn(...args);

      setData(data);
    },
    [setIsLoading, fn],
  );

  const onFinally = useCallback(() => {
    if (options?.onFinally) {
      options.onFinally();
    }

    setIsLoading(false);
    setIsDataInitialized(true);
  }, [options, setIsLoading]);

  const handledFn = useHandleFn((...args: CustomAny[]) => wrappedFn(...args), {
    ...options,
    onFinally,
  });

  return useMemo(() => [handledFn, isLoading, data, setData, isDataInitialized], [
    handledFn,
    isLoading,
    data,
    isDataInitialized,
    setData,
  ]);
}
