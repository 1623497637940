import React, { memo, useContext } from 'react';
import './FilterSectionsForm.scss';
import FilterSectionsToolbar from './components/toolbar/FilterSectionsToolbar';
import { FilterSectionsFormBlock } from './filterSectionsFormBem';
import { useClassName } from '../../../../hooks/useClassName';
import Form from '../../../../components/forms/Form';
import { FilterSectionsFormContext } from './context/FilterSectionsFormContext';
import FilterSectionsLayout from './components/FilterSectionsLayout';
import { filterSectionsFormValidationSchema } from '../../constants/filter-controls/allViewFilterControlsValidationSchema';

const FilterSectionsForm: React.FC = () => {
  const context = useContext(FilterSectionsFormContext);
  const { settings, defaultValues, sectionsStates, onFormChange, onValidationStateChange } = context;

  const cn = useClassName(FilterSectionsFormBlock.Root);

  return (
    <Form
      className={cn()}
      defaultValues={defaultValues}
      validationSchema={filterSectionsFormValidationSchema}
      onChange={onFormChange}
      onValidationStateChange={onValidationStateChange}
    >
      {settings.hasToolbar && <FilterSectionsToolbar />}

      <FilterSectionsLayout sectionsStates={sectionsStates} />
    </Form>
  );
};

export default memo(FilterSectionsForm);
