import { ReducerAction } from '../../../../../interfaces/ReducerAction';
import { GridSort } from '../../../../../components/grid/grid/interfaces/GridSort';
import { ViewDto } from '../interfaces/api/ViewDto';
import { GridRow } from '../../../../../components/grid/shared/types/GridRow';
import { ViewAppliedFilters } from '../interfaces/ViewAppliedFilters';
import { ViewSummary } from '../interfaces/api/ViewSummary';
import { ViewType } from '../enums/ViewType';
import { ViewId } from '../types/ViewId';
import { GridPublicApi } from '../../../../../components/grid/grid/interfaces/GridPublicApi';
import { ViewOptions } from '../interfaces/options/ViewOptions';
import { ViewSharedLink } from '../interfaces/api/ViewSharedLink';
import { Nullable } from '../../../../../types/generics';
import { ViewFiltersDictionaries } from '../../../../filters/interfaces/ViewFiltersDictionaries';
import { SocketService } from '../../../../../services/socket/SocketService';
import { SocketEvent } from '../socket/enums/SocketEvent';
import { SocketAction } from '../socket/enums/SocketAction';
import { ViewNote } from '../interfaces/api/ViewNote';
import { ViewAdditionalData } from '../interfaces/ViewAdditionalData';
import { ViewGridColumnGroupDto } from '../../view-grid/interfaces/ViewGridColumnGroupDto';
import { GlobalError } from '../../../../../services/error/types/GlobalError';
import { NotificationSuccessMessage } from '../../../../../../store/notification/types';
import { ViewSelectedColumnsDto } from '../interfaces/ViewSelectedColumnsDto';
import { ViewSaved } from '../interfaces/api/ViewSaved';
import { ViewColumnsOptionsDto } from '../components/view-columns/add/interfaces/dto/ViewColumnsOptionsDto';

export const SET_VIEW_OPTIONS = 'SET_VIEW_OPTIONS';
export const SET_VIEW_LOADING = 'SET_VIEW_LOADING';
export const SET_VIEW_DATA = 'SET_VIEW_DATA';
export const SET_VIEW_ERROR = 'SET_VIEW_ERROR';
export const SET_VIEW_SUCCESS = 'SET_VIEW_SUCCESS';
export const SET_VIEW_SORT = 'SET_VIEW_SORT';
export const SET_GRID_PUBLIC_API = 'SET_PUBLIC_API';
export const SET_VIEW_FILTERS = 'SET_VIEW_FILTERS';
export const SET_VIEW_SELECTED_COLUMNS = 'SET_VIEW_SELECTED_COLUMNS';
export const SET_VIEW_SAVED_VIEWS = 'SET_VIEW_SAVED_VIEWS';
export const SET_VIEW_SHARED_LINKS = 'SET_VIEW_SHARED_LINKS';
export const SET_VIEW_ADDITIONAL_DATA = 'SET_VIEW_ADDITIONAL_DATA';
export const SET_VIEW_COLUMNS_SETTINGS = 'SET_VIEW_COLUMNS_SETTINGS';
export const SET_VIEW_IS_SOCKET_WORK = 'SET_VIEW_IS_SOCKET_WORK';

export interface ViewState {
  gridPublicApi: GridPublicApi | null;
  options: ViewOptions;
  socket: SocketService<SocketEvent, SocketAction>;
  isSocketWork: boolean;
  viewType: ViewType;
  isScreen: boolean;
  viewId: ViewId;
  viewData: ViewDto | null;
  rows?: GridRow[];
  columnsGroups?: ViewGridColumnGroupDto[];
  filters: ViewAppliedFilters;
  selectedColumns: ViewSelectedColumnsDto;
  defaultFilters: ViewAppliedFilters;
  savedViews: ViewSaved[];
  sharedLinks: ViewSharedLink[];
  summary: ViewSummary;
  notes: ViewNote[];
  footnote?: string;
  isLoading: boolean;
  success?: NotificationSuccessMessage;
  error?: GlobalError;
  sort: Nullable<GridSort>;
  filtersDictionaries: ViewFiltersDictionaries;
  additionalData: Nullable<ViewAdditionalData>;
  columnsOptions?: Nullable<ViewColumnsOptionsDto>;
}

export type SetViewOptions = ReducerAction<typeof SET_VIEW_OPTIONS, { options: ViewOptions }>;
export type SetGridPublicApi = ReducerAction<typeof SET_GRID_PUBLIC_API, { gridPublicApi: GridPublicApi }>;
export type SetViewLoading = ReducerAction<typeof SET_VIEW_LOADING, { isLoading: boolean }>;
export type SetViewData = ReducerAction<
  typeof SET_VIEW_DATA,
  {
    viewData: ViewDto;
    rows: GridRow[];
    columnsGroups: ViewGridColumnGroupDto[];
    filters: ViewAppliedFilters;
    selectedColumns: ViewSelectedColumnsDto;
    summary: ViewSummary;
    defaultFilters: ViewAppliedFilters;
    savedViews: ViewSaved[];
    sharedLinks: ViewSharedLink[];
    notes: ViewNote[];
    footnote?: string;
    sort: Nullable<GridSort>;
    filtersDictionaries: ViewFiltersDictionaries;
  }
>;
export type SetViewSort = ReducerAction<typeof SET_VIEW_SORT, { sort: Nullable<GridSort> }>;
export type SetViewFilters = ReducerAction<typeof SET_VIEW_FILTERS, { filters: ViewAppliedFilters }>;
export type SetViewSelectedColumns = ReducerAction<
  typeof SET_VIEW_SELECTED_COLUMNS,
  { selectedColumns: ViewSelectedColumnsDto }
>;
export type SetViewSavedViews = ReducerAction<typeof SET_VIEW_SAVED_VIEWS, { savedViews: ViewSaved[] }>;
export type SetViewSharedLinks = ReducerAction<typeof SET_VIEW_SHARED_LINKS, { sharedLinks: ViewSharedLink[] }>;
export type SetViewAdditionalData = ReducerAction<typeof SET_VIEW_ADDITIONAL_DATA, { data: ViewAdditionalData }>;
export type SetViewError = ReducerAction<
  typeof SET_VIEW_ERROR,
  {
    error: GlobalError;
  }
>;
export type SetViewSuccess = ReducerAction<
  typeof SET_VIEW_SUCCESS,
  {
    success: NotificationSuccessMessage;
  }
>;
export type SetViewColumnsSettings = ReducerAction<
  typeof SET_VIEW_COLUMNS_SETTINGS,
  {
    columnsOptions: Nullable<ViewColumnsOptionsDto>;
  }
>;
export type SetViewIsSocketWork = ReducerAction<
  typeof SET_VIEW_IS_SOCKET_WORK,
  {
    isSocketWork: boolean;
  }
>;

export type ActionTypes =
  | SetViewOptions
  | SetViewLoading
  | SetViewData
  | SetViewSort
  | SetGridPublicApi
  | SetViewFilters
  | SetViewSelectedColumns
  | SetViewSavedViews
  | SetViewSharedLinks
  | SetViewAdditionalData
  | SetViewError
  | SetViewSuccess
  | SetViewColumnsSettings
  | SetViewIsSocketWork;
