import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import './FormAccordionCheckbox.scss';
import { useFormContext } from 'react-hook-form';
import { AccordionProps } from '../accordion/Accordion';
import AccordionPrimary from '../accordion/AccordionPrimary';
import { useDeepEffect } from '../../effects/useDeepEffect';
import uniqueId from 'lodash.uniqueid';
import Checkbox from './Checkbox';
import { BreakpointName } from '../../enums/BreakpointName';

interface Props extends AccordionProps {
  controlNames: string[];
  isAccordionNotAvailable?: boolean;
}

const FormAccordionCheckbox: React.FC<Props> = props => {
  const checkboxName = useMemo<string>(() => uniqueId('FormAccordionCheckbox-'), []);
  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
  const formContext = useFormContext();
  const { watch, setValue, getValues } = formContext;

  const childControls = watch(props.controlNames);

  const formValues = getValues();

  const setChildCheckboxState = useCallback(
    (isCheck: boolean): void => {
      props.controlNames.forEach((controlName: string) => setValue(controlName, isCheck));
    },
    [props.controlNames, setValue],
  );

  const setComponentState = useCallback(
    (isCheck: boolean): void => {
      setChildCheckboxState(isCheck);
      setCheckboxValue(isCheck);
    },
    [setChildCheckboxState, setCheckboxValue],
  );

  const onCheckboxClick = useCallback(
    (isCheck: boolean, event?: MouseEvent): void => {
      setComponentState(isCheck);

      event?.stopPropagation();
    },
    [setComponentState],
  );

  const onAccordionHeaderClick = useCallback(
    (isAvailable: boolean): void => {
      if (!isAvailable) {
        setComponentState(!checkboxValue);
      }
    },
    [setComponentState, checkboxValue],
  );

  useDeepEffect(() => {
    const isAllChecked: boolean = props.controlNames.every((name: string) => formValues[name]);
    setCheckboxValue(isAllChecked);
  }, [childControls, setValue, props.controlNames]);

  return (
    <div className="FormAccordionCheckbox">
      <AccordionPrimary
        breakpoints={props.breakpoints}
        isAvailable={!props.isAccordionNotAvailable}
        title={
          <div className="FormAccordionCheckbox__title">
            <Checkbox name={checkboxName} onClick={onCheckboxClick} isCheck={checkboxValue} isRaw={true} />
            {props.title}
          </div>
        }
        onHeaderClick={onAccordionHeaderClick}
      >
        {props.children}
      </AccordionPrimary>
    </div>
  );
};

FormAccordionCheckbox.defaultProps = {
  breakpoints: [BreakpointName.ExtraSmall],
};

export default FormAccordionCheckbox;
