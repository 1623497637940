import {
  SET_ACCOUNT_DATA,
  SET_RETURN_LOCATION,
  SET_IS_AUTHORIZED,
  SetAccountData,
  SetReturnLocation,
  SetIsAuthorizedAction,
} from './types';
import { Location } from 'history';
import { AccountData } from '../../account-settings/interfaces/AccountData';

export function setIsAuthorized(isAuthorized: boolean): SetIsAuthorizedAction {
  return {
    type: SET_IS_AUTHORIZED,
    isAuthorized,
  };
}

export function setReturnLocation(location: Location): SetReturnLocation {
  return {
    type: SET_RETURN_LOCATION,
    location,
  };
}

export function setAccountData(accountData: AccountData): SetAccountData {
  return {
    type: SET_ACCOUNT_DATA,
    accountData,
  };
}
