import { isBoolean, isNil, isNumber, isString } from 'lodash';
import { GridAgColumn } from '../../../../../components/grid/grid/interfaces/GridAgColumn';
import { ViewGridColumnFactory } from '../factories/ViewGridColumnFactory';
import { ViewGridColumnGroupDto } from '../interfaces/ViewGridColumnGroupDto';
import { ViewGridColumnDto } from '../interfaces/ViewGridColumnDto';
import { ViewGridColumnOptions } from '../types/ViewGridColumnOptions';
import { ViewGridColumnsSetupOptions } from '../interfaces/ViewGridColumnsSetupOptions';
import { GridColumn } from '../../../../../components/grid/shared/interfaces/GridColumn';
import { ViewColumnDataType } from '../enums/ViewColumnDataType';
import { GridRow } from '../../../../../components/grid/shared/types/GridRow';
import { ViewGridColumnService } from './ViewGridColumnService';
import { ViewGridCellValueService } from './ViewGridCellValueService';
import { ViewColumnSystemName } from '../enums/ViewColumnSystemName';
import { ViewGridColumnNameService } from './ViewGridColumnNameService';
import { GridCellValue } from '../../../../../components/grid/shared/types/GridCellValue';
import { DateHelper } from '../../../../../helpers/date/DateHelper';
import { MONEY_REG_EXP, PERCENTAGE_REG_EXP } from '../../../../../constants/regExp';
import { columnTypeToCellFormatter } from '../constants/viewGridColumnTypeToCellFormatter';

export class ViewGridDataService {
  static getColumnsFromViewColumnGroups(
    columnsGroups: ViewGridColumnGroupDto[],
    setupOptions?: ViewGridColumnsSetupOptions,
  ): GridAgColumn[] {
    return columnsGroups.map((columnGroup: ViewGridColumnGroupDto) =>
      ViewGridColumnFactory.getColumn(columnGroup, setupOptions),
    );
  }

  static getColumnsFromViewColumns(
    viewColumns: ViewGridColumnDto[],
    setupOptions?: ViewGridColumnsSetupOptions,
  ): GridAgColumn[] {
    const columnGroups = this.getColumnGroupFromViewColumns(viewColumns);

    return this.getColumnsFromViewColumnGroups(columnGroups, setupOptions);
  }

  static getColumnsFromViewColumnOptions(
    columnsOptions: ViewGridColumnOptions[],
    rows: GridRow[],
    setupOptions?: ViewGridColumnsSetupOptions,
  ): GridAgColumn[] {
    const firstCol = columnsOptions[0];

    if (!firstCol) {
      return [];
    }

    let columns;

    if (this.isGridColumnOptions(firstCol)) {
      columns = this.getColumnsFromGridColumns(columnsOptions as GridColumn[], rows, setupOptions);
    } else if (this.isColumnGroup(firstCol)) {
      columns = this.getColumnsFromViewColumnGroups(columnsOptions as ViewGridColumnGroupDto[], setupOptions);
    } else {
      columns = this.getColumnsFromViewColumns(columnsOptions as ViewGridColumnDto[], setupOptions);
    }

    return columns.map((column, index) =>
      setupOptions?.onColumnMapReady ? setupOptions?.onColumnMapReady(column, index, setupOptions.context) : column,
    );
  }

  static getViewRowsFromRows(rows: GridRow[], columnsOptions: ViewGridColumnOptions[]): GridRow[] {
    const firstCol = columnsOptions[0];

    if (!firstCol) {
      return rows;
    }

    if (this.isGridColumnOptions(firstCol)) {
      return this.mapViewRowsToRows(rows);
    }

    return rows;
  }

  static mapViewRowsToRows(rows: GridRow[]): GridRow[] {
    return rows.map(row => {
      const columnNames = Object.keys(row);

      return columnNames.reduce(
        (currentRow, columnName) => ({
          ...currentRow,
          [columnName]: ViewGridCellValueService.getRaw(row, columnName),
          [ViewGridColumnService.getVisibleColumnName(columnName)]: ViewGridCellValueService.getRaw(row, columnName),
        }),
        {},
      );
    });
  }

  private static isColumnGroup(columnsOptions: ViewGridColumnOptions): columnsOptions is ViewGridColumnGroupDto {
    return !!(columnsOptions as ViewGridColumnGroupDto).nestedColumns;
  }

  private static getColumnGroupFromViewColumns(viewColumns: ViewGridColumnDto[]): ViewGridColumnGroupDto[] {
    return viewColumns.map(viewColumn => ({
      name: ViewGridColumnNameService.getNestedColumnNameFromNestedColumns([viewColumn]),
      nestedColumns: [viewColumn],
    }));
  }

  private static isGridColumnOptions(columnsOptions: ViewGridColumnOptions): columnsOptions is GridColumn {
    return !!(columnsOptions as GridColumn).field;
  }

  private static getColumnsFromGridColumns(
    gridColumns: GridColumn[],
    rows: GridRow[],
    setupOptions?: ViewGridColumnsSetupOptions,
  ): GridAgColumn[] {
    const viewGridColumns = this.getViewGridColumnsFromGridColumns(gridColumns, rows);
    const columns = this.getColumnsFromViewColumns(viewGridColumns as ViewGridColumnDto[], setupOptions);

    return columns.map(column => ({
      ...column,
      ...(gridColumns.find(gridColumn => gridColumn.field === column.field) as GridColumn),
    }));
  }

  private static getViewGridColumnsFromGridColumns(columns: GridColumn[], rows: GridRow[]): ViewGridColumnDto[] {
    return columns.map(column => ({
      name: column.field as ViewColumnSystemName,
      title: column.headerName as string,
      type: this.getViewColumnDataTypeByData(column.field, rows),
      ...(column.columnType &&
        columnTypeToCellFormatter[column.columnType] && {
          cellFormatter: columnTypeToCellFormatter[column.columnType] as ViewColumnSystemName,
        }),
    }));
  }

  private static getCellDataForColumnType(columnName: string, rows: GridRow[]): GridCellValue | undefined {
    return rows.map(row => ViewGridCellValueService.getRaw(row, columnName)).find(cellData => !isNil(cellData));
  }

  private static getViewColumnDataTypeByData(columnName: string, rows: GridRow[]): ViewColumnDataType {
    const cellData = this.getCellDataForColumnType(columnName, rows);

    if (!cellData) {
      return ViewColumnDataType.Complex;
    }

    if (isString(cellData)) {
      if (PERCENTAGE_REG_EXP.test(cellData)) {
        return ViewColumnDataType.Percentage;
      } else if (MONEY_REG_EXP.test(cellData)) {
        return ViewColumnDataType.Money;
      } else if (DateHelper.isValidIso(cellData)) {
        return ViewColumnDataType.Date;
      } else {
        return ViewColumnDataType.Text;
      }
    } else if (isNumber(cellData)) {
      return ViewColumnDataType.Number;
    } else if (isBoolean(cellData)) {
      return ViewColumnDataType.Boolean;
    } else if (isString(cellData)) {
      return ViewColumnDataType.Text;
    } else {
      return ViewColumnDataType.Complex;
    }
  }
}
