import { FilterControlSettings } from '../../types/FilterControlSettings';
import { companyInfoControls } from './view-filter-sections/companyInfoControls';
import { offerDateControls } from './view-filter-sections/offerDateControls';
import { securityTypeControls } from './view-filter-sections/securityTypeControls';
import { returnsControls } from './view-filter-sections/returnsControls';
import { dealInfoControls } from './view-filter-sections/dealInfoControls';
import { favouriteControls } from './view-filter-sections/favouriteControls';
import { renaissanceRatingControls } from './view-filter-sections/renaissanceRatingControls';
import { financialsControls } from './view-filter-sections/financialsControls';
import { valuationControls } from './view-filter-sections/valuationControls';
import { dealTimingControls } from './view-filter-sections/dealTimingControls';
import { dealStatusControls } from './view-filter-sections/dealStatusControls';
import { dateControls } from './view-filter-sections/dateControls';
import { locationControls } from './view-filter-sections/locationControls';
import { investorsControls } from './view-filter-sections/investorsControls';
import { fileDateControls } from './view-filter-sections/fileDateControls';

export const allViewFilterControls: FilterControlSettings = {
  ...dateControls,
  ...offerDateControls,
  ...fileDateControls,
  ...dealTimingControls,
  ...companyInfoControls,
  ...investorsControls,
  ...securityTypeControls,
  ...returnsControls,
  ...dealInfoControls,
  ...locationControls,
  ...favouriteControls,
  ...dealStatusControls,
  ...renaissanceRatingControls,
  ...financialsControls,
  ...valuationControls,
};
