import React, { useMemo } from 'react';
import Highcharts from 'highcharts/highstock';
import DashboardChartWidget from '../shared/DashboardChartWidget';
import { DashboardWidgetComponent } from '../../../interfaces/DashboardWidgetComponent';
import { ChartType } from '../../../../shared/components/chart/enums/ChartType';
import { recentIpoPerfomanceChartOptions } from '../../../../stats/stats/main-dashboard/components/recent-ipo-performance-chart/constants/recentIpoPerfomanceStatsElementOptions';
import { OptionsListProp } from '../../../../shared/components/chart/types/OptionsListProp';

const DashboardRenaissanceUSIPOIndexWidget: DashboardWidgetComponent<Highcharts.Options> = props => {
  const { data } = props;

  const optionsList = useMemo(() => [...(recentIpoPerfomanceChartOptions.optionsList as OptionsListProp), data], [
    data,
  ]);

  return (
    <DashboardChartWidget
      type={recentIpoPerfomanceChartOptions.type as ChartType}
      optionsList={optionsList}
      dimensions={props.dimensions}
    />
  );
};

export default DashboardRenaissanceUSIPOIndexWidget;
