import { FilterSectionType } from '../../../../../shared/entities/filters/enum/FilterSectionType';
import { ViewFilterName } from '../../../../../shared/entities/filters/enum/types/ViewFilterName';
import { TimeFrame } from '../../../../../shared/entities/filters/enum/TimeFrame';
import { FilterSection } from '../../../../../shared/entities/filters/interfaces/FilterSection';

export const screensCustomFilterSections: FilterSection[] = [
  {
    type: FilterSectionType.DealStatus,
    controls: [{ name: ViewFilterName.DealStatusIn }],
  },
  {
    type: FilterSectionType.OfferDate,
    controls: [
      {
        name: ViewFilterName._OfferDate,
        props: {
          timeFrames: [
            TimeFrame.Week,
            TimeFrame.Month,
            TimeFrame.Quarter,
            TimeFrame.YTD,
            TimeFrame.Year,
            TimeFrame.TwoYears,
            TimeFrame.FiveYears,
          ],
        },
      },
    ],
  },
  {
    type: FilterSectionType.FileDate,
    controls: [{ name: ViewFilterName._FileDate }],
  },
  {
    type: FilterSectionType.CompanyInfo,
    controls: [
      { name: ViewFilterName.SectorIndustry },
      { name: ViewFilterName._Headquarters },
      { name: ViewFilterName.EmergingGrowth },
    ],
  },
  {
    type: FilterSectionType.Investors,
    controls: [
      { name: ViewFilterName.Investor },
      { name: ViewFilterName.TopShareholder },
      { name: ViewFilterName.Ownership },
      { name: ViewFilterName.SpinOff },
      { name: ViewFilterName.MgmtOwned },
      { name: ViewFilterName.PE },
      { name: ViewFilterName.PeGrowthOther },
      { name: ViewFilterName.VentureCapital },
    ],
  },
  {
    type: FilterSectionType.SecurityType,
    controls: [
      { name: ViewFilterName.SecurityTypeCommonSharesList },
      { name: ViewFilterName.SecurityTypeADRsList },
      { name: ViewFilterName.SecurityTypeREITList },
      { name: ViewFilterName.SecurityTypeMLPList },
      { name: ViewFilterName.SecurityTypeBlankCheckList },
      { name: ViewFilterName.SecurityTypeRegAList },
      { name: ViewFilterName.SecurityTypeDirectListingList },
    ],
  },
  {
    type: FilterSectionType.Returns,
    controls: [
      { name: ViewFilterName._FirstDayReturn },
      { name: ViewFilterName._AfterMarketReturn },
      { name: ViewFilterName._TotalReturn },
      { name: ViewFilterName._ReturnsAnnualizedReturn },
      { name: ViewFilterName._InitialPerformance },
      { name: ViewFilterName._RecentPerformance },
      { name: ViewFilterName.ReturnsCurrentPrice },
      { name: ViewFilterName.ReturnsCompTicker },
    ],
  },
  {
    type: FilterSectionType.DealInfo,
    controls: [
      { name: ViewFilterName.DealInfoPreIpoExchange },
      { name: ViewFilterName._DealSize },
      { name: ViewFilterName.UnderwriterKeyList },
      { name: ViewFilterName.UnderwriterRole },
      { name: ViewFilterName.InsiderBuying },
      { name: ViewFilterName.InsiderSelling },
      { name: ViewFilterName.InsiderSellingPct },
      { name: ViewFilterName.IsCrossListing },
      { name: ViewFilterName.IsUpListing },
      { name: ViewFilterName.IsDualListing },
      { name: ViewFilterName._DealSizeVsExpected },
      { name: ViewFilterName._DealSizeMarketCapVsExpected },
      { name: ViewFilterName.TermsChange },
      { name: ViewFilterName.IPOPriceRange },
      { name: ViewFilterName._IPOPricedRange },
      { name: ViewFilterName._FloatToMarketCap },
      { name: ViewFilterName.DealInfoDualClassSharesOnly },
    ],
  },
  {
    type: FilterSectionType.Location,
    controls: [
      { name: ViewFilterName.LocationListingLocation },
      { name: ViewFilterName.ExchangeCountry },
      { name: ViewFilterName.ExchangeRegion },
      { name: ViewFilterName.IncludeChina },
      { name: ViewFilterName.LocationExchangeList },
    ],
  },
  {
    type: FilterSectionType.Favorite,
    controls: [{ name: ViewFilterName.Favorite }],
  },
  {
    type: FilterSectionType.RenaissanceRating,
    controls: [
      { name: ViewFilterName.RcRating },
      { name: ViewFilterName._RatingFundamentals },
      { name: ViewFilterName._RatingCorpGov },
      { name: ViewFilterName._RatingValuation },
      { name: ViewFilterName._RatingTechnicals },
    ],
  },
  {
    type: FilterSectionType.Financials,
    controls: [
      { name: ViewFilterName._RevenueGrowth },
      { name: ViewFilterName.RevenueGrowthPeriod },
      { name: ViewFilterName._LtmLatestSales },
      { name: ViewFilterName._LtmGrossMargin },
      { name: ViewFilterName._LtmEbitdaMargin },
      { name: ViewFilterName._LtmNetMargin },
      { name: ViewFilterName._FinancialsPostIpoCash },
      { name: ViewFilterName._FinancialsPostIpTotalAssets },
      { name: ViewFilterName._FinancialsPostIpoTotalDebt },
      { name: ViewFilterName._FinancialsPostIpoEquity },
      { name: ViewFilterName._FinancialsLTMCashFlowFromOperation },
      { name: ViewFilterName._FinancialsLTMCapex },
      { name: ViewFilterName._FinancialsLTMFreeCashFlow },
      { name: ViewFilterName._IpoDebtToEbitda },
      { name: ViewFilterName.MostRecentFinancialsDateMin },
      { name: ViewFilterName.Dividend },
    ],
  },
  {
    type: FilterSectionType.Valuation,
    controls: [
      { name: ViewFilterName._MarketCapAtIPO },
      { name: ViewFilterName._ValuationEnterpriseValueAtIPO },
      { name: ViewFilterName._IpoEvToLtmSales },
      { name: ViewFilterName._IpoEvToEbitda },
      { name: ViewFilterName._IpoPe },
      { name: ViewFilterName._IpoPb },
    ],
  },
];
