import React, { ReactNode } from 'react';
import ViewModalActions from './ViewFilterActions';
import './ViewModalFooter.scss';
import Spacer from '../../../../../../../components/Spacer';
import { ViewModalActionFn } from '../types/ViewModalActionFn';
import { useClassName } from '../../../../../../../hooks/useClassName';
import { ViewModalFooterBlock } from './viewModalFooterBem';

interface Props {
  additionalAction: ReactNode;
  isResetDisabled: boolean;
  isSaveAndRunDisabled?: boolean;
  isRunDisabled?: boolean;
  onAction: ViewModalActionFn;
  footerNote?: ReactNode;
  hideSaveAndRun?: boolean;
}

const ViewModalFooter: React.FC<Props> = props => {
  const cn = useClassName(ViewModalFooterBlock.Root);

  return (
    <footer className={cn()}>
      {props.additionalAction ? props.additionalAction : <Spacer />}

      <ViewModalActions
        isResetDisabled={props.isResetDisabled}
        isSaveAndRunDisabled={props.isSaveAndRunDisabled}
        isRunDisabled={props.isRunDisabled}
        onAction={props.onAction}
        hideSaveAndRun={props.hideSaveAndRun}
      />
      {props.footerNote}
    </footer>
  );
};

export default ViewModalFooter;
