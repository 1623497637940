import React from 'react';

import LocationService from '../services/LocationService';
import PrimarySection from './primary-section/PrimarySection';
import EmptyDataBlock from './EmptyDataBlock';

interface Props {
  redirectLocation: string;
  self?: boolean;
}

const RedirectToExternalLink: React.FC<Props> = props => {
  LocationService.redirectExternalLink(props.redirectLocation, props.self);
  return (
    <PrimarySection>
      <EmptyDataBlock content={'   '} addHeight />
    </PrimarySection>
  );
};

export default RedirectToExternalLink;
