import React, { useCallback, useContext, useState } from 'react';
import { FileActionType } from '../../../../../../components/file-actions/enums/FileActionType';
import { ViewContext } from '../../ViewContext';
import ViewShareLinkModal from '../view-share-link-modal/ViewShareLinkModal';
import ViewGridToolbarGroupFileActions from '../../../view-grid-toolbar-groups/view-grid-toolbar-group-file-actions/ViewGridToolbarGroupFileActions';
import SaveViewModalForm from './save-view/form/SaveViewModalForm';
import { viewReqParamsBaseSelector } from '../../store/selectors';
import { ViewApiService } from '../../../../../../api/ViewApiService';

const ViewToolbarFileActions: React.FC = () => {
  const { state } = useContext(ViewContext);

  const isSaveViewModalShowState = useState(false);
  const [, setIsSaveViewModalShow] = isSaveViewModalShowState;
  const [isShareLinkModalShow, setIsShareLinkModalShow] = useState(false);

  const viewSaveData = viewReqParamsBaseSelector(state);

  const onSaveViewAction = useCallback(() => setIsSaveViewModalShow(true), [setIsSaveViewModalShow]);
  const onShareLinkClick = useCallback(() => setIsShareLinkModalShow(true), []);
  const onShareLink = useCallback(() => setIsShareLinkModalShow(false), []);

  const excelFn = useCallback(async () => {
    return ViewApiService.exportById(state.viewData?.id as number);
  }, [state.viewData]);

  const onFileActionClick = useCallback(
    (actionType: FileActionType) => {
      if (actionType === FileActionType.Save) {
        onSaveViewAction();
      }

      if (actionType === FileActionType.Link) {
        onShareLinkClick();
      }
    },
    [onSaveViewAction, onShareLinkClick],
  );

  return (
    <ViewGridToolbarGroupFileActions
      fileActionTypes={state.options.toolbar?.fileActionTypes}
      excelFn={excelFn}
      onActionClick={onFileActionClick}
    >
      <SaveViewModalForm showState={isSaveViewModalShowState} viewSaveData={viewSaveData} />

      <ViewShareLinkModal
        show={isShareLinkModalShow}
        onHide={(): void => setIsShareLinkModalShow(false)}
        onAction={onShareLink}
      />
    </ViewGridToolbarGroupFileActions>
  );
};

export default ViewToolbarFileActions;
