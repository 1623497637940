import React, { memo, useMemo } from 'react';
import './TimeFrameSelect.scss';
import { TimeFrame } from '../../entities/filters/enum/TimeFrame';
import DateRangeSelect from './components/date/DateRangeSelect';
import TextSelect from './components/text-select/TextSelect';
import { timeFramesOptions } from '../../entities/filters/constants/options/timeFramesOptions';
import { DateSelectValue } from './components/date/types/DateSelectValue';
import { OnChangeDateRangeFn } from './components/date/types/OnChangeDateRangeFn';
import { useClassName } from '../../hooks/useClassName';
import { SelectOption } from '../../services/select/interfaces/SelectOption';
import FormControlsList, { FormControlsListProps } from './FormControlsList';
import { TimeFrameSelectBlock } from './TimeFrameSelectBem';
import { ControlSize } from '../../enums/ControlSize';
import { SelectService } from '../../services/select/SelectService';
import { ControlDivider } from './services/control-divider/enums/ControlDivider';
import { TextSelectElementType } from './components/text-select/enums/TextSelectElementType';
import { DateSelectRestrictionValue } from './components/date/types/DateSelectRestrictionValue';

export interface TimeFrameSelectProps extends FormControlsListProps {
  timeFrames?: TimeFrame[];
  elementType?: TextSelectElementType;
  minValue?: DateSelectValue;
  maxValue?: DateSelectValue;
  minDate?: DateSelectRestrictionValue;
  maxDate?: DateSelectRestrictionValue;
  timeFrameValue?: TimeFrame;
  timeFrameLabel?: string;
  timeFrameDivider?: ControlDivider;
  hasDateRange?: boolean;
  hideDateRange?: boolean;
  hasTimeFrame?: boolean;
  minPlaceholder?: string;
  maxPlaceholder?: string;
  size?: ControlSize;
  isUnselectAvailable?: boolean;
  onDateRangeChange: OnChangeDateRangeFn;
  onTimeFrameChange: (value: TimeFrame) => void;
  className?: string;
}

const TimeFrameSelect: React.FC<TimeFrameSelectProps> = props => {
  const { hasDateRange = true, hasTimeFrame = true, hideDateRange = false } = props;
  let AdjustedTimeFrameValue;

  const cn = useClassName(TimeFrameSelectBlock.Root, props.className);

  const timeFrameOptions = useMemo<SelectOption[]>(
    () => SelectService.getOptionsByValues(timeFramesOptions, props.timeFrames),
    [props.timeFrames],
  );

  if (props.minValue && props.maxValue) {
    AdjustedTimeFrameValue = null;
  } else {
    AdjustedTimeFrameValue = props.timeFrameValue;
  }

  return (
    <FormControlsList className={cn()} isInline={props.isInline}>
      <TextSelect
        className={hasTimeFrame ? '' : 'Disable-Select'}
        label={props.timeFrameLabel}
        options={timeFrameOptions}
        value={AdjustedTimeFrameValue}
        size={props.size}
        divider={props.timeFrameDivider}
        onChangeValue={props.onTimeFrameChange}
        elementType={props.elementType}
        isUnselectAvailable={props.isUnselectAvailable}
      />

      {!hideDateRange && (
        <DateRangeSelect
          className={hasDateRange ? '' : 'Disable-Select'}
          minValue={props.minValue}
          maxValue={props.maxValue}
          minPlaceholder={props.minPlaceholder}
          maxPlaceholder={props.maxPlaceholder}
          minDateRestrictionValue={props.minDate}
          maxDateRestrictionValue={props.maxDate}
          size={props.size}
          onChange={props.onDateRangeChange}
        />
      )}
    </FormControlsList>
  );
};

TimeFrameSelect.defaultProps = {
  minPlaceholder: 'Start Date',
  maxPlaceholder: 'End Date',
};

export default memo(TimeFrameSelect);
