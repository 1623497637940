import React from 'react';
import './NotificationToast.scss';
import { Notification } from './interfaces/Notification';
import Toast from '../toast/Toast';
import { useClassName } from '../../hooks/useClassName';
import NotificationToastHeader from './NotificationToastHeader';
import NotificationToastBody from './NotificationToastBody';
import { NotificationToastBlock } from './notificationToastBem';

interface Props extends Notification {
  onClose: () => void;
}

const NotificationToast: React.FC<Props> = props => {
  const cn = useClassName(NotificationToastBlock.Root);

  return (
    <Toast
      className={cn({
        [props.type]: props.type,
      })}
      autohide
      delay={props.showTime}
      onClose={props.onClose}
    >
      <NotificationToastHeader type={props.type} />
      <NotificationToastBody type={props.type} text={props.text} />
    </Toast>
  );
};

export default NotificationToast;
