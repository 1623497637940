import React, { useContext, useMemo } from 'react';
import { StatsElementContext } from '../context/StatsElementContext';
import StatsElementChart from './StatsElementChart';
import SecondarySectionsGroup from '../../../../components/secondary-section/SecondarySectionsGroup';
import { StatsElementChartDto } from './types/StatsElementChartDto';
import { BlockOrientation } from '../../../../enums/BlockOrientation';
import { useClassName } from '../../../../hooks/useClassName';
import { StatsElementMapChartsBlock } from './statsElementMapChartsBem';
import './StatsElementMapCharts.scss';

export const StatsElementMapCharts: React.FC = () => {
  const cn = useClassName(StatsElementMapChartsBlock.Root);

  const { options, chartsContextValue } = useContext(StatsElementContext);
  const { highCharts } = chartsContextValue;

  const chartsOrientation = useMemo(() => options?.pageOptions?.chartsOrientation || BlockOrientation.Column, [
    options,
  ]);

  const isChartsOrientationColumn = useMemo(() => chartsOrientation === BlockOrientation.Column, [chartsOrientation]);

  return (
    <SecondarySectionsGroup
      className={cn({
        [BlockOrientation.Column]: isChartsOrientationColumn,
        [BlockOrientation.Line]: !isChartsOrientationColumn,
      })}
    >
      {highCharts?.map((highChart: StatsElementChartDto, index: number) => (
        <StatsElementChart key={index} index={index} data={highChart} />
      ))}
    </SecondarySectionsGroup>
  );
};

export default StatsElementMapCharts;
