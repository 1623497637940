export enum FormGroupBlock {
  Root = 'FormGroup',
}

export enum FormGroupExternalClassName {
  FormGroup = 'form-group',
}

export enum FormGroupModifier {
  FullWidth = 'fullWidth',
  NoIndentVertical = 'noIndentVertical',
  ShiftHorizontal = 'shiftHorizontal',
  IsDisabled = 'isdisabled',
}
