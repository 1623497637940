import React, { useMemo } from 'react';
import { ControlSize } from '../../../shared/enums/ControlSize';
import RotatedGridWithElements from '../../../shared/components/rotated-grid/RotatedGridWithElements';
import { InnerComponentOptions } from '../../../shared/components/inner-component/interfaces/InnerComponentOptions';
import { RotatedGridRowProps } from '../../../shared/components/rotated-grid/RotatedGridRow';

interface Props {
  className?: string;
  rows: RotatedGridRowProps[];
  title?: InnerComponentOptions;
}

const CompanyTooltipContentGrid: React.FC<Props> = props => {
  const isVisible = useMemo(() => props.rows.some(row => row.data), [props.rows]);

  return (
    <>
      {isVisible && (
        <RotatedGridWithElements
          className={props.className}
          title={props.title}
          rows={props.rows}
          size={ControlSize.Small}
        />
      )}
    </>
  );
};

export default CompanyTooltipContentGrid;
