export enum SimpleGridBlock {
  Root = 'SimpleGrid',
}

export enum SimpleGridElement {
  Title = 'title',
}

export enum SimpleGridModifier {
  AroundBorder = 'aroundBorder',
  HeaderHidden = 'headerHidden',
  HighlightLastRow = 'highlightLastRow',
  BackgroundColorScheme = 'BackgroundColorScheme',
  AutoLayout = 'autoLayout',
  FullWidth = 'fullWidth',
}
