export enum StatsElementFilterInternalName {
  Annual = 'annual',
  Monthly = 'monthly',
  NoFilter = 'noFilter',
  Percentages = 'percentages',
  Totals = 'totals',
  Total = 'total',
  TotalReturn = 'totalReturn',
  FirstDay = 'firstDay',
  Aftermarket = 'aftermarket',
  AboveIssue = 'aboveIssue',
  DollarsRaised = 'dollarsRaised',
  Returns = 'returns',
  ReturnsPct = 'returnsPct',
  DollarsRaisedPct = 'dollarsRaisedPct',
  TotalsPct = 'totalsPct',
  Default = 'default',
  ProfitableEbitda = 'profitableEbitda',
  ProfitableOperatingIncome = 'profitableOperatingIncome',
  ProfitableNetIncome = 'profitableNetIncome',
  ProfitableFreeCashFlow = 'profitableFreeCashFlow',
  NumberAndProceeds = 'numberAndProceeds',
  PercentOfTotal = 'percentOfTotal',
  Decade = 'decade',
  Quarter = 'quarter',
  FromIpo = 'fromIpo',
  NumberAndPercentOfTotal = 'iposAndPct',
  ProceedsAndPercentOfTotal = 'proceedsAndPct',
  Past1Mo = 'past1Mo',
}
