import { TextSelectElementType } from '../enums/TextSelectElementType';
import { TextSelectElementProps } from '../types/TextSelectElementProps';
import React from 'react';
import TextSelectElementButton from '../TextSelectElementButton';

export class TextSelectElementFactory {
  static getElement(type: TextSelectElementType, props: TextSelectElementProps, isSelected: boolean) {
    switch (type) {
      case TextSelectElementType.Text:
        return <button {...props} type="button" />;
      case TextSelectElementType.Button:
        return <TextSelectElementButton isSelected={isSelected} {...props} />;
    }
  }
}
