import ApiService from './ApiService';
import { ApiPath } from './enums/ApiPath';
import { DashboardWidgetConfig } from '../../dashboard/interfaces/DashboardWidgetConfig';
import { DashboardNewsDto } from '../../dashboard/interfaces/DashboardNewsDto';
import { MasterCalendarDataService } from '../../master-calendar/services/MasterCalendarDataService';
import { CalendarEvent } from '../entities/calendar/interfaces/CalendarEvent';
import { viewStab } from './viewStab';
import { ViewDataTable } from '../entities/view/components/view/interfaces/api/ViewDataTable';
import { DashboardIPOsOnMoveDto } from '../../dashboard/types/DashboardIPOsOnMoveDto';
import { DashboardMyFavouritesDto } from '../../dashboard/interfaces/DashboardMyFavouritesDto';
import { ReportDto } from '../../special-reports/interfaces/ReportDto';
import { ViewSaved } from '../entities/view/components/view/interfaces/api/ViewSaved';
import Highcharts from 'highcharts/highstock';

export default class DashboardApiService {
  static getNews(): Promise<DashboardNewsDto[]> {
    return ApiService.get(ApiPath.DashboardNews);
  }

  static getCalendar(): Promise<CalendarEvent[]> {
    return MasterCalendarDataService.getEvents();
  }

  static getPriced(): Promise<ViewDataTable> {
    return ApiService.get(ApiPath.DashboardPriced);
  }

  static getUpcomingIPOs(): Promise<ViewDataTable> {
    return ApiService.get(ApiPath.DashboardUpcomingIPOs);
  }

  static getRecentResearch(): Promise<ViewDataTable> {
    return ApiService.get(ApiPath.DashboardRecentResearch);
  }

  static getMostViewedIPOs(): Promise<ViewDataTable> {
    return ApiService.get(ApiPath.DashboardMostViewedIPOs);
  }

  static getNewFilings(): Promise<ViewDataTable> {
    return ApiService.get(ApiPath.DashboardNewFilings);
  }

  static getIPOsOnMove(): Promise<DashboardIPOsOnMoveDto> {
    return ApiService.get(ApiPath.DashboardIPOsOnMove);
  }

  static getIPOPoll(): Promise<ViewDataTable> {
    return Promise.resolve(viewStab.data.dataTable as ViewDataTable);
  }

  static getMyFavourites(): Promise<DashboardMyFavouritesDto> {
    return ApiService.get(ApiPath.DashboardMyFavourites);
  }

  static getMyViews(): Promise<ViewSaved[]> {
    return ApiService.get(ApiPath.DashboardSavedViews);
  }

  static getMyScreens(): Promise<ViewSaved[]> {
    return ApiService.get(ApiPath.DashboardSavedScreens);
  }

  static getSpecialReports(): Promise<ReportDto[]> {
    return ApiService.get(ApiPath.DashboardSpecialReports);
  }

  static getRenaissanceUSIPOIndex(): Promise<Highcharts.Options> {
    return ApiService.get(ApiPath.DashboardUSIPOIndex);
  }

  static getUSIPOMarketIndex(): Promise<Highcharts.Options> {
    return ApiService.get(ApiPath.DashboardUSIPOMarketIndex);
  }

  static getWidgets(): Promise<DashboardWidgetConfig[] | null> {
    return ApiService.get<string | null>(ApiPath.Dashboard)
      .then(response => (response ? JSON.parse(response) : null))
      .catch(() => null);
  }

  static saveWidgets(configuration: DashboardWidgetConfig[] | null): Promise<void> {
    return ApiService.post(ApiPath.Dashboard, JSON.stringify(JSON.stringify(configuration)));
  }
}
