import { SelectOption } from './interfaces/SelectOption';
import { isArray, isObject } from 'lodash';
import { RawSelectOption } from './types/RawSelectOption';
import { SelectOptionProp } from '../../components/forms/components/select/types/SelectOptionProp';
import { SelectOptionValue } from './types/SelectOptionValue';

export class SelectService {
  static getOptionsByType<T extends SelectOption>(options: SelectOptionProp[] = []): SelectOption[] {
    if (!this.hasOptions(options)) {
      return [];
    }

    return options.map(option => this.getOptionByType(option));
  }

  static getOptionByType(option: SelectOptionProp): SelectOption {
    if (this.isRawOption(option)) {
      return {
        value: option,
        title: String(option),
      };
    }

    return option;
  }

  static isRawOption(option: SelectOptionProp): option is RawSelectOption {
    return !isObject(option);
  }

  static hasOptions(options: SelectOptionProp[] | undefined): boolean {
    return !!options && options.length > 0;
  }

  static getOptionByValue(optionsProp: SelectOptionProp[] = [], value: SelectOptionValue): SelectOption | undefined {
    if (optionsProp.length === 0 || !value) {
      return;
    }

    const options = this.getOptionsByType(optionsProp);

    return options.find(option => option.value === value);
  }

  static getOptionsByValues(options: SelectOptionProp[] = [], values: SelectOptionValue[] = []): SelectOption[] {
    return this.getOptionsByType(options).filter((option: SelectOption) => values.includes(option.value));
  }

  static getOptionsTitlesByValues(options: SelectOptionProp[] = [], values: SelectOptionValue[] = []): string[] {
    return this.getOptionsByValues(options, values).map(option => option.title);
  }

  static getMultiSelectValue(value: SelectOptionValue[] | SelectOptionValue): SelectOptionValue[] {
    if (!value) {
      return [];
    }

    return isArray(value) ? value : [value];
  }

  static getSingleSelectValue(value: SelectOptionValue[] = []): SelectOptionValue | null {
    const firstValue = value[0];
    return firstValue || null;
  }

  static hasMultiSelectValue(value: SelectOptionValue[] | SelectOptionValue): boolean {
    return this.getMultiSelectValue(value).length > 0;
  }

  static getSelectOptionKey(option: SelectOption): string | number | undefined {
    return option.value || option.title;
  }
}
