import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GetOptionsFn } from '../../../../services/select/types/GetOptionsFn';
import { AutocompleteItem } from '../../../autocomplete/interfaces/AutocompleteItem';
import { AutocompleteProps } from '../../../autocomplete/Autocomplete';
import { SelectOption } from '../../../../services/select/interfaces/SelectOption';
import { useDebounce } from 'react-use';
import { usePromiseState } from '../../../../hooks/promise-state/usePromiseState';
import { Nullable } from '../../../../types/generics';
import Input, { InputProps } from '../../Input';
import { SelectOptionValue } from '../../../../services/select/types/SelectOptionValue';
import { OnRenderAutocompleteItem } from '../../../autocomplete/types/OnRenderAutocompleteItem';
import { SearchInputSymbolsCount } from './enums/SearchInputSymbolsCount';

export interface SearchInputProps extends InputProps {
  getOptionsFn: GetOptionsFn;
  searchSymbolsCount?: SearchInputSymbolsCount;
  shouldClearOnSelect?: boolean;
  onRenderItem?: OnRenderAutocompleteItem;
  onSearchValueChange?: (searchValue?: string) => void;
  onSelectItem?: (item: AutocompleteItem) => void;
}

interface Props extends SearchInputProps {
  value: SelectOptionValue;
  onChangeValue: (value: SelectOptionValue) => void;
}

const SEARCH_DELAY = 500;
const NO_OPTIONS: SelectOption[] = [];

const SearchInput: React.FC<Props> = props => {
  const {
    value,
    getOptionsFn,
    onChangeValue,
    onSearchValueChange,
    onSelectItem,
    searchSymbolsCount = SearchInputSymbolsCount.Default,
  } = props;

  const ref = useRef();

  const [searchValue, setSearchValue] = useState<string>();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>();
  const [isTyping, setIsTyping] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Nullable<AutocompleteItem>>();

  const canLoadStart = useCallback(searchValue => !!searchValue && searchValue.length >= searchSymbolsCount, [
    searchSymbolsCount,
  ]);

  const shouldLoad = useCallback(
    (debouncedSearchValue, selectedItem) => canLoadStart(debouncedSearchValue) && !selectedItem,
    [canLoadStart],
  );

  const [options, isLoadingOptions] = usePromiseState<SelectOption[]>(
    useCallback(async () => {
      let newOptions = NO_OPTIONS;

      if (shouldLoad(debouncedSearchValue, selectedItem)) {
        newOptions = await getOptionsFn(debouncedSearchValue as string);
      }

      return newOptions;
    }, [debouncedSearchValue, selectedItem, shouldLoad, getOptionsFn]),
    NO_OPTIONS,
  );

  const isBeforeSearchPlaceholder = useMemo(() => !canLoadStart(searchValue), [searchValue, canLoadStart]);

  const isLoading = useMemo(() => (isLoadingOptions || isTyping) && canLoadStart(searchValue), [
    isLoadingOptions,
    searchValue,
    isTyping,
    canLoadStart,
  ]);

  const isNoData = useMemo(() => canLoadStart(searchValue) && options.length === 0 && !selectedItem, [
    searchValue,
    options,
    selectedItem,
    canLoadStart,
  ]);

  const onSelect = useCallback(
    (value: string, item: AutocompleteItem) => {
      if (!props.shouldClearOnSelect) {
        setSearchValue(value);
      }

      setSelectedItem(item);
      onSelectItem?.(item);
      onChangeValue(item.value);
    },
    [props.shouldClearOnSelect, setSearchValue, onChangeValue, onSelectItem],
  );
  const onChange = useCallback((value: string) => setSearchValue(value), [setSearchValue]);

  const autocompleteOptions = useMemo<AutocompleteProps>(
    () => ({
      items: options as SelectOption[],
      displayValue: searchValue,
      onRenderItem: props.onRenderItem,
      selectedItem,
      isLoading,
      isNoData,
      isBeforeSearchPlaceholder,
      onChange,
      onSelect,
    }),
    [
      searchValue,
      isLoading,
      selectedItem,
      options,
      isNoData,
      onChange,
      onSelect,
      isBeforeSearchPlaceholder,
      props.onRenderItem,
    ],
  );

  useDebounce(
    () => {
      setDebouncedSearchValue(searchValue);
      setIsTyping(false);
    },
    SEARCH_DELAY,
    [searchValue],
  );
  useEffect(() => {
    setIsTyping(true);

    if (searchValue !== selectedItem?.title) {
      setSelectedItem(null);
      onChangeValue(undefined);
    }

    if (canLoadStart(searchValue)) {
      onSearchValueChange?.(searchValue);
    }
  }, [searchValue, selectedItem, canLoadStart, onSearchValueChange, onChangeValue]);

  // if there is no value, then clear
  useEffect(() => {
    if (!value) {
      setSearchValue(undefined);
      setDebouncedSearchValue(undefined);
      setSelectedItem(undefined);
    }
  }, [value]);

  return <Input {...props} register={ref} autocompleteOptions={autocompleteOptions} />;
};

export default SearchInput;
