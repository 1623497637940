import React, { useContext, useMemo } from 'react';
import './CompanySecondaryOfferingData.scss';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import { CompanySecondaryOfferingRowsDto } from './interfaces/CompanySecondaryOfferingRowsDto';
import { useClassName } from '../../../../../../shared/hooks/useClassName';
import CompanyProfileSidebarTable from '../company-profile-table/CompanyProfileSidebarTable';
import Hyperlink from '../../../../../../shared/components/typography/Hyperlink';

const CompanySecondaryOfferingData: React.FC = () => {
  const cn = useClassName('CompanySecondaryOfferingData');

  const { getTabData } = useContext(CompanyContext);

  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const data = useMemo(() => profile.secondary, [profile]);
  const note = useMemo(() => data?.note, [data]);

  const filteredEmptyRows = useMemo(() => data?.rows.filter((row: CompanySecondaryOfferingRowsDto) => !!row.data), [
    data,
  ]);

  return (
    <>
      {data && (
        <CompanyProfileSideItem className={cn()} title="Secondary Offering">
          {data.link && (
            <div className={cn('table-link')}>
              <Hyperlink to={data.link.linkUrl} isExternal isBlank>
                {data.link.linkName}
              </Hyperlink>
            </div>
          )}

          <CompanyProfileSidebarTable
            {...data}
            expandedData={filteredEmptyRows as CompanySecondaryOfferingRowsDto[]}
            isHeaderHidden
            hasAroundBorder={false}
          />

          {note && (
            <div className={cn('note')}>
              Note: <i>{note}</i>
            </div>
          )}
        </CompanyProfileSideItem>
      )}
    </>
  );
};

export default CompanySecondaryOfferingData;
