import { StatsElementOptions } from '../../../../../../shared/entities/stats-element/interfaces/StatsElementOptions';
import { ChartType } from '../../../../../../shared/components/chart/enums/ChartType';
import { NumberHelper } from '../../../../../../shared/helpers/NumberHelper';
import { ChartTooltipFormatterService } from '../../../../../../shared/components/chart/services/ChartTooltipFormatterService';
import { CustomAny } from '../../../../../../shared/types/generics';
import { ChartValuesType } from '../../../../../../shared/components/chart/enums/ChartValuesType';
import { NumberFormat } from '../../../../../../shared/enums/NumberFormat';
import { getStatsElementSimpleGridColumnYearToViewMapper } from '../../../../../../shared/entities/stats-element/components/simple-grid/constants/statsElementSimpleGridColumnMappers';
import { ViewType } from '../../../../../../shared/entities/view/components/view/enums/ViewType';
import { ViewFilterName } from '../../../../../../shared/entities/filters/enum/types/ViewFilterName';
import { Location } from '../../../../../../shared/entities/filters/enum/Location';
import { ViewColumnSystemName } from '../../../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { StatsFilterName } from '../../../../../stats/enum/StatsFilterName';

const US_LISTINGS_FROM_NON_US_ISSUERS_BY_YEAR_GRID_INDEX = 0;

export const usLisingsStatsElementOptions: StatsElementOptions = {
  chartsOptions: [
    {
      type: ChartType.Base,
      dynamicFilters: [StatsFilterName.CountryRegion, StatsFilterName.ADRsOnly],
      optionsList: [
        {
          yAxis: [
            {
              title: {
                text: 'US Listings from Non-US Issuers',
              },
            },
            {
              title: {
                text: 'Proceeds in Billions (US$)',
              },
              labels: {
                formatter() {
                  return NumberHelper.getFormatValue(this.value, NumberFormat.CurrencyIntegerWithLetter);
                },
              },
              opposite: true,
            },
          ],
          tooltip: {
            formatter() {
              return ChartTooltipFormatterService.getTooltip({
                points: this.points as CustomAny[],
                title: `${this.x}`,
                pointsValueType: [ChartValuesType.Integer, ChartValuesType.Currency],
              });
            },
          },
          series: [
            {
              type: 'spline',
            },
            {
              type: 'column',
              yAxis: 1,
              dataLabels: {
                enabled: true,
                formatter() {
                  return NumberHelper.getFormatValue(this.y, NumberFormat.CurrencyFloatWithLetter);
                },
              },
            },
          ],
        },
      ],
    },
  ],
  gridOptions: {
    [US_LISTINGS_FROM_NON_US_ISSUERS_BY_YEAR_GRID_INDEX]: {
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(() => ({
        viewType: ViewType.Priced,
        startDateFilterName: ViewFilterName.OfferStartDate,
        endDateFilterName: ViewFilterName.OfferEndDate,
        optionsList: [
          {
            getFilterName: ViewFilterName.Headquarter,
            getFilterValue: () => Location.NonUS,
          },
          {
            getFilterName: ViewFilterName.HeadquartersCityStateCountry,
            getFilterValue: ViewColumnSystemName.StatsCountryKey,
            isMultiValue: true,
          },
        ],
      })),
    },
  },
};
