import React from 'react';
import './AccordionFormGroup.scss';
import AccordionSecondary from '../accordion/AccordionSecondary';
import { AccordionProps } from '../accordion/Accordion';
import { useClassName } from '../../hooks/useClassName';
import { AccordionFormGroupBlock } from './accordionFormGroupBem';

type Props = AccordionProps;

const AccordionFormGroup: React.FC<Props> = props => {
  const cn = useClassName(AccordionFormGroupBlock.Root, props.classNames);

  return <AccordionSecondary {...props} classNames={cn()} />;
};

export default AccordionFormGroup;
