import React from 'react';
import Toast from 'react-bootstrap/Toast';
import './ToastBody.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
}

const ToastBody: React.FC<Props> = props => {
  return <Toast.Body className={classNames('ToastBody', props.className)}>{props.children}</Toast.Body>;
};

export default ToastBody;
