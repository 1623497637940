import React, { useContext, useMemo } from 'react';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProfileDataService } from '../../services/ProfileDataService';
import { profileDefaultExpandingLength } from '../../constants/profileDefaultExpandingLength';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import CompanyProfileSidebarTable from '../company-profile-table/CompanyProfileSidebarTable';
import { GridRow } from '../../../../../../shared/components/grid/shared/types/GridRow';

const CompanyInvestors: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const expandData = useMemo(() => ProfileDataService.getCompanyTableRows(profile.investors) as GridRow[], [
    profile.investors,
  ]);

  const investorsNames = useMemo(() => ProfileDataService.getInvestorsFromRows(expandData) as GridRow[], [expandData]);

  return (
    <>
      {profile.investors && profile.investors.columns?.length > 0 && (
        <CompanyProfileSideItem
          title="Investors"
          component={CompanyProfileSidebarTable}
          componentProps={{
            columns: [profile.investors.columns[0]],
            isHeaderHidden: true,
            hasAroundBorder: false,
          }}
          expandData={investorsNames}
          expandingLength={profileDefaultExpandingLength}
        />
      )}
    </>
  );
};

export default CompanyInvestors;
