import React, { useContext, useMemo } from 'react';
import { CompanyContext } from '../context/CompanyContext';
import './CompanyInvestmentRationaleDescription.scss';
import CompanyParagraph from '../shared/CompanyParagraph';
import { Nullable } from '../../../shared/types/generics';
import { InvestmentRationaleDto } from '../../interfaces/InvestmentRationaleDto';
import { CompanyTab } from '../../enums/CompanyTab';
import HtmlStringFormatterJustified from '../../../shared/components/HtmlStringFormatterJustified';

const CompanyInvestmentRationaleDescription: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const data: Nullable<InvestmentRationaleDto> = getTabData(CompanyTab.InvestmentRationale);

  const paragraphsData = useMemo(() => data?.paragraphs, [data]);

  return (
    <div className="CompanyInvestmentRationaleDescription">
      {paragraphsData?.map((row: string, index: number) => (
        <CompanyParagraph key={index} text={<HtmlStringFormatterJustified value={row as string} />} />
      ))}
    </div>
  );
};

export default CompanyInvestmentRationaleDescription;
