import React, { useState } from 'react';
import './App.scss';
import MainMenu from './shared/components/main-menu/MainMenu';
import Header from './shared/components/header/Header';
import Routes from './routes/Routes';
import { Router } from 'react-router-dom';
import { history } from './shared/history';
import classNames from 'classnames';
import PrimarySection from './shared/components/primary-section/PrimarySection';
import { ColorScheme } from './shared/enums/ColorScheme';
import { PrimarySectionIndent } from './shared/components/primary-section/PrimarySectionIndent';
import Footer from './shared/components/footer/Footer';
import { useResize } from './shared/effects/useResize';
import { useHistoryChange } from './shared/effects/useHistoryChange';
import { ElementSelectors } from './shared/enums/ElementSelectors';
import AdditionalMenu from './shared/components/additional-menu/AdditionalMenu';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store';
import { ScrollService } from './shared/services/ScrollService';
import { AppInitService } from './AppInitService';
import Notification from './shared/components/notification/Notification';
import { useClassName } from './shared/hooks/useClassName';
import { AppBlock, AppElement } from './AppBem';
import { useAsyncFnEffect } from './shared/hooks/async-fn/useAsyncFnEffect';
import { clearNotifications } from './store/notification/actions';
import { usePathnameChange } from './shared/hooks/usePathnameChange';
import LocationService from './shared/services/LocationService';
import { setIsAuthorized } from './store/auth/actions';
import ReactGA from 'react-ga';
import { DateHelper } from './shared/helpers/date/DateHelper';
import { DateFormat } from './shared/helpers/date/enums/DateFormat';
import StorageService from './shared/services/storage/StorageService';
import { TokenStorageKey } from './shared/services/token/enums/TokenStorageKey';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const App: React.FC = () => {
  const cn = useClassName(AppBlock.Root);

  const dispatch = useDispatch();
  const [isAppInit, setIsAppInit] = useState<boolean>(false);
  const isAdditionalMenuVisible = useSelector<RootState, boolean>(state => state.header.isAdditionalMenuVisible);

  // App initialization
  useAsyncFnEffect(() => {
    (async (): Promise<void> => {
      try {
        await AppInitService.init();
      } catch (e) {
      } finally {
        setIsAppInit(true);
      }
    })();
  }, []);

  React.useEffect(() => {
    const trackingId = 'UA-49249493-2';

    ReactGA.initialize(trackingId);

    // Initialize google analytics page view tracking
    history.listen(location => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
  }, []);

  // const gaUserId = sessionStorage.getItem('gaUserId');
  // const gaAccountId = sessionStorage.getItem('gaAccountId');

  // When a user's login is remembered and they return to the page without having to log in again,
  // there are no ID variables in sessionStorage, so we are pulling directly from StorageService instead,
  // above is the old code
  let gaUserIdTemp: any = null;
  let gaAccountIdTemp: any = null;

  if (sessionStorage.getItem('gaAccountId') && sessionStorage.getItem('gaUserId')) {
    gaUserIdTemp = sessionStorage.getItem('gaUserId');
    gaAccountIdTemp = sessionStorage.getItem('gaAccountId');
  } else {
    gaUserIdTemp = StorageService.getItem(TokenStorageKey.GaUserId);
    gaAccountIdTemp = StorageService.getItem(TokenStorageKey.GaAccountId);
  }

  const gaUserId = gaUserIdTemp;
  const gaAccountId = gaAccountIdTemp;

  React.useEffect(() => {
    const dTime = DateHelper.formatISODateStr(new Date().toISOString(), DateFormat.MonthYearDayTime);

    ReactGA.set({
      dimension3: gaAccountId,
      dimension5: gaUserId,
      dimension4: dTime,
    });

    window.dataLayer.push({ accountId: gaAccountId, aspnetuserid: gaUserId });

    const loc: string = window.location.pathname + window.location.search;
    ReactGA.set({ page: loc }); // Update the user's current page
    ReactGA.pageview(loc);
  }, [gaUserId, gaAccountId]);

  const localRefresh = localStorage.getItem('RefreshToken');
  const localAccess = localStorage.getItem('AccessToken');
  const sessRefresh = sessionStorage.getItem('RefreshToken');
  const sessAccess = sessionStorage.getItem('AccessToken');

  React.useEffect(() => {
    // Often times, localStorage goes missing, at that point we fetch tokens from session
    // storage, and add them to the localStorage
    if (sessAccess && !localAccess) {
      localStorage.setItem('AccessToken', sessAccess);
    }
    if (sessRefresh && !localRefresh) {
      localStorage.setItem('RefreshToken', sessRefresh);
    }

    window.addEventListener('storage', event => {
      const logoutValue = localStorage.getItem('logout');
      const localRefresh = localStorage.getItem('RefreshToken');
      const localAccess = localStorage.getItem('AccessToken');

      // Whenever localStorage updates, update the sessionStorage for each tab
      if (localRefresh) {
        sessionStorage.setItem('RefreshToken', localRefresh);
      }
      if (localAccess) {
        sessionStorage.setItem('AccessToken', localAccess);
      }

      // Whenever user logs out, remove tokens
      if (logoutValue === 'logout') {
        sessionStorage.removeItem('RefreshToken');
        sessionStorage.removeItem('AccessToken');
      }
    });

    // Next code block is for situations when localStorage is deleted and a new tab is opened from that situation
    // In this case, we directly copy auth tokens from the session storage of the active tab
    const sessionStorageTransfer = function(event: any) {
      if (!event) {
        event = window.event;
      }
      if (!event.newValue) return;
      if (event.key === 'getSessionStorage') {
        // another tab asked for the sessionStorage -> send it
        localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
        // the other tab should now have it, so we're done with it.
        localStorage.removeItem('sessionStorage');
      } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
        // another tab sent data <- get it
        let flag = false;
        if (localStorage.getItem('AccessToken') === null) {
          flag = true;
        }
        const data = JSON.parse(event.newValue);
        for (const key in data) {
          sessionStorage.setItem(key, data[key]);
          localStorage.setItem(key, data[key]);
        }
        // When initially, localStorage and sessionStorage were empty,
        // the page landed on login screen, but after this else if block
        // localStorage, and sessionStorage got populated with auth tokens
        // so, we should redirect the user to the returnLocation
        if (flag && localStorage.getItem('AccessToken') !== null) {
          dispatch(setIsAuthorized(true));
          LocationService.redirectToReturnLocation();
        }
      }
    };

    // listen for changes to localStorage
    if (window.addEventListener) {
      window.addEventListener('storage', sessionStorageTransfer, false);
    }

    // Ask other tabs for session storage (this is ONLY to trigger event)
    if (!sessionStorage.length) {
      localStorage.setItem('getSessionStorage', 'foobar');
      localStorage.removeItem('getSessionStorage');
    }
  }, [localAccess, localRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

  useHistoryChange((prevLocation, location) => {
    ScrollService.scrollToTop();
    LocationService.reloadPageIfSameLocation(prevLocation, location);
  });

  usePathnameChange(() => {
    dispatch(clearNotifications());
  });

  useResize(() => {
    const pinFooter = (): void => {
      const content: HTMLElement = document.querySelector(ElementSelectors.AppContent) as HTMLElement;
      const footer: HTMLElement = document.querySelector(ElementSelectors.AppFooter) as HTMLElement;
      if (content) {
        content.style.minHeight = `calc(100vh - ${footer.offsetHeight}px)`;
      }
    };

    if (isAppInit) {
      pinFooter();
    }
  }, [isAppInit]);

  return (
    <div className={cn()}>
      <Notification />
      {isAppInit && (
        <Router history={history}>
          <section className={cn(AppElement.Header)}>
            <PrimarySection indent={PrimarySectionIndent.Compact}>
              <Header />
            </PrimarySection>

            <PrimarySection colorScheme={ColorScheme.Primary} indent={PrimarySectionIndent.None}>
              <MainMenu />
            </PrimarySection>

            <AdditionalMenu />
          </section>

          <section className={classNames('App__content', { 'App__content--additional-menu': isAdditionalMenuVisible })}>
            <Routes />
          </section>

          <section className="App__footer">
            <PrimarySection colorScheme={ColorScheme.Primary} indent={PrimarySectionIndent.Internal}>
              <Footer />
            </PrimarySection>
          </section>
        </Router>
      )}
    </div>
  );
};

export default App;
