import React, { useMemo } from 'react';
import './ExpandButton.scss';
import ButtonIcon, { ButtonIconProps } from '../forms/ButtonIcon';
import { ExpandButtonOptions } from './interfaces/ExpandButtonOptions';
import { useClassName } from '../../hooks/useClassName';
import { ExpandButtonBlock } from './ExpandButtonBem';

interface Props extends ExpandButtonOptions {
  expandViewOptions: ButtonIconProps;
  collapseViewOptions: ButtonIconProps;
  hasIndent?: boolean;
}

const ExpandButton: React.FC<Props> = props => {
  const { hasIndent = true } = props;

  const cn = useClassName(ExpandButtonBlock.Root, props.className);

  const options = useMemo(() => (props.isExpanded ? props.collapseViewOptions : props.expandViewOptions), [props]);

  return (
    <ButtonIcon
      className={cn({
        indent: hasIndent,
      })}
      onClick={props.onExpand}
      {...options}
    />
  );
};

export default ExpandButton;
