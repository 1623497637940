import React from 'react';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import './TickerCell.scss';
import { TickerCellBlock } from './TickerCellBem';
import { useClassName } from '../../../../../../hooks/useClassName';
import CompanyTooltip from '../../../../../../../company/components/company-tooltip/CompanyTooltip';

type Props = GridCellFormatterCommonProps;

const TickerCell: React.FC<Props> = props => {
  const cn = useClassName(TickerCellBlock.Root);

  return (
    <CustomCellFormatterWrapper className={cn()} value={props.value}>
      {props.data?.isCompanyNotPresent ? (
        props.value
      ) : (
        <CompanyTooltip name={props.value} ticker={props.data[ViewColumnSystemName.Ticker]} />
      )}
    </CustomCellFormatterWrapper>
  );
};

export default TickerCell;
