import React, { ReactNode, useMemo } from 'react';
import './TooltipContentList.scss';
import TooltipContentItem from './TooltipContentItem';
import { TooltipContentItem as TooltipContentItemInterface } from './interface/TooltipContentItem';
import TooltipContentHeaderItem from './TooltipContentHeaderItem';
import { ComponentMessage } from '../../enums/ComponentMessage';

interface Props {
  items: TooltipContentItemInterface[];
  noData?: ReactNode;
}

const TooltipContentList: React.FC<Props> = props => {
  const { noData = ComponentMessage.NoData } = props;

  const hasItems = useMemo(() => props.items && props.items.length > 0, [props.items]);

  return (
    <div className="TooltipContentList">
      {hasItems
        ? props.items.map((item, index) =>
            item.isHeader ? (
              <TooltipContentHeaderItem key={index}>{item.component}</TooltipContentHeaderItem>
            ) : (
              <TooltipContentItem key={index}>{item.component}</TooltipContentItem>
            ),
          )
        : noData}
    </div>
  );
};

export default TooltipContentList;
