import { StatsElementSimpleGridFiltersTitles } from '../../../simple-grid/types/StatsElementSimpleGridFiltersTitles';
import { StatsElementFilterInternalName } from '../../../../enums/StatsElementGridFilterName';

export const statsElementFiltersInternalTitles: StatsElementSimpleGridFiltersTitles = {
  [StatsElementFilterInternalName.NoFilter]: '',
  [StatsElementFilterInternalName.Default]: '',
  [StatsElementFilterInternalName.Aftermarket]: 'Aftermarket',
  [StatsElementFilterInternalName.DollarsRaised]: 'Dollars Raised ($B)',
  [StatsElementFilterInternalName.DollarsRaisedPct]: 'Dollars Raised Percentage',
  [StatsElementFilterInternalName.FirstDay]: 'First Day',
  [StatsElementFilterInternalName.Percentages]: 'Percentages',
  [StatsElementFilterInternalName.Returns]: 'Returns',
  [StatsElementFilterInternalName.ReturnsPct]: 'Returns',
  [StatsElementFilterInternalName.Total]: 'Total',
  [StatsElementFilterInternalName.TotalReturn]: 'Total Return',
  [StatsElementFilterInternalName.Annual]: 'Annual',
  [StatsElementFilterInternalName.AboveIssue]: 'Above Issue',
  [StatsElementFilterInternalName.Monthly]: 'Monthly',
  [StatsElementFilterInternalName.Past1Mo]: 'Past 1Mo',
  [StatsElementFilterInternalName.Totals]: 'Totals',
  [StatsElementFilterInternalName.TotalsPct]: 'Totals Percentages',
  [StatsElementFilterInternalName.ProfitableEbitda]: ' Adj. EBITDA',
  [StatsElementFilterInternalName.ProfitableFreeCashFlow]: 'Free Cash Flow',
  [StatsElementFilterInternalName.ProfitableNetIncome]: 'Net Income',
  [StatsElementFilterInternalName.ProfitableOperatingIncome]: 'Operating Income',
  [StatsElementFilterInternalName.NumberAndProceeds]: 'Number And Proceeds',
  [StatsElementFilterInternalName.PercentOfTotal]: 'Percent Of Total',
  [StatsElementFilterInternalName.Decade]: 'Past 10D',
  [StatsElementFilterInternalName.Quarter]: 'Past 3Mo',
  [StatsElementFilterInternalName.FromIpo]: 'IPO',
  [StatsElementFilterInternalName.NumberAndPercentOfTotal]: 'Number and Percent of Total',
  [StatsElementFilterInternalName.ProceedsAndPercentOfTotal]: 'Proceeds and Percent of Total',
};
