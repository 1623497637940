import { ViewSummaryField } from '../../../enums/ViewSummaryField';

export const viewSummaryItems = [
  {
    field: ViewSummaryField.Companies,
    title: 'IPOs',
    hasFormatter: false,
  },
  {
    field: ViewSummaryField.PcmlCompanies,
    title: 'Companies',
    hasFormatter: false,
  },
  {
    field: ViewSummaryField.Proceeds,
    title: 'Proceeds',
    hasFormatter: false,
  },
  {
    field: ViewSummaryField.PercentTradingAboveIPOPrice,
    title: '% Above IPO',
    hasFormatter: true,
    formatterBaseValue: 0.5,
  },
  {
    field: ViewSummaryField.AvgPriceVsMidpoint,
    title: 'Avg Price vs Midpt',
    hasFormatter: true,
  },
  {
    field: ViewSummaryField.AvgFirstDayPop,
    title: 'Avg 1st Day',
    hasFormatter: true,
  },
  {
    field: ViewSummaryField.AvgFirstThreeMo,
    title: 'Avg 1st 3 Mo',
    hasFormatter: true,
  },
  {
    field: ViewSummaryField.AvgAftermarketReturn,
    title: 'Avg Aftermkt',
    hasFormatter: true,
  },
  {
    field: ViewSummaryField.AvgReturnFromIPO,
    title: 'Avg Return',
    hasFormatter: true,
  },
];
