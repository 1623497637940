export enum MasterCalendarLegendBlock {
  Root = 'MasterCalendarLegend',
}

export enum MasterCalendarLegendElement {
  Item = 'item',
  Button = 'button',
  Title = 'title',
}

export enum MasterCalendarLegendModifiers {
  Disabled = 'disabled',
}
