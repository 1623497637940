import { searchFilterControls } from '../../../../../shared/entities/filters/constants/filter-controls/searchFilterControls';
import { ViewFilterName } from '../../../../../shared/entities/filters/enum/types/ViewFilterName';
import { FiltersMapValue } from '../../../../../shared/entities/filters/components/filterable-component/interfaces/FiltersMapValue';
import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { TimeFrame } from '../../../../../shared/entities/filters/enum/TimeFrame';

export const screensSpacsSharedOptions: Partial<ScreenOptions> = {
  header: 'SPAC Screen',
  search: {
    controls: [searchFilterControls[ViewFilterName.DealStatusSelect] as FiltersMapValue],
  },
  toolbar: {
    quickFilter: {
      timeFrameOptions: [TimeFrame.Month, TimeFrame.Quarter, TimeFrame.YTD, TimeFrame.Year, TimeFrame.FiveYears],
      hasIncludeSPAC: false,
      hasIncludeAShares: false,
      hasUpcomingTimeFrame: true,
    },
  },
  screenTriggerOptions: {
    isInUseAvailable: filtersValues => !!filtersValues[ViewFilterName.DealStatusSelect],
  },
};
