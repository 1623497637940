export enum ViewColumnSystemName {
  ActionName = 'actionName',
  Id = 'id',
  Company = 'company',
  CompanyKey = 'companyKey',
  CompanyName = 'companyName',
  CompanyTicker = 'companyTicker',
  CompanyWithDescription = 'companyWithDescription',
  CompanyWithNotePopUp = 'CompanyWithNotePopup',
  DealCode = 'dealCode',
  DealSize = 'dealSize',
  DealStatusSelectKey = 'dealStatusSelectKey',
  Data = 'data',
  Favorite = 'favorite',
  FirstDayReturn = 'firstDayReturn',
  Label = 'label',
  NewsLink = 'analystNote',
  NoteNumber = 'noteNumber',
  PriceRange = 'priceRange',
  ReportDate = 'reportDate',
  ReportLink = 'reportLink',
  ReportTitle = 'reportTitle',
  Research = 'research',
  SpecialReportsDate = 'specialReportsDate',
  TearSheet = 'tearSheet',
  Ticker = 'ticker',
  // TODO: synchronize naming for My Favorites and Views
  MarketCap = 'marketCap',
  MktCap = 'mktCap',
  AfterMarket = 'afterMarket',
  BusinessOverview = 'businessOverview',
  DateIssued = 'dateIssued',
  DateUpdated = 'dateUpdated',
  DealStatus = 'dealStatus',
  Employees = 'employees',
  EstSales = 'EstSales',
  EstSalesSource = 'estSalesSource',
  EstTiming = 'estTiming',
  EstTimingSort = 'estTimingSort',
  EstTimingSource = 'estTimingSource',
  EV = 'EV',
  Exchange = 'exchange',
  ExchangeName = 'exchangeName',
  ExpPricingDate = 'expPricingDate',
  FileDate = 'fileDate',
  FirstDayPop = 'firstDayPop',
  Footnote = 'footnote',
  Headquarters = 'headquarters',
  HeadquartersKey = 'headquatersKey',
  IndustryKey = 'industryKey',
  Industry = 'industry',
  AnnouncedDate = 'announcedDate',
  Status = 'status',
  TargetCompany = 'targetCompany',
  ProposedMVDeSPAC = 'proposedMVDeSPAC',
  VoteDate = 'voteDate',
  CompletionDate = 'completionDate',
  LiquidationDate = 'liquidationDate',
  Investor = 'investor',
  InvestorKey = 'investorKey',
  InvestorsList = 'InvestorsList',
  IsInvestorScreenLink = 'IsInvestorScreenLink',
  LastClose = 'lastClose',
  Last = 'last',
  LastUpdateDays = 'LastUpdateDays',
  LastUpdateDaysPCW = 'NumDaysEdited',
  LastUpdateDaysPipeline = 'daysSinceLastMilestoneDate',
  Latest = 'latest',
  ListingLocation = 'listingLocation',
  LockupExpDate = 'lockupExpDate',
  LockupNumber = 'lockupNumber',
  DaysToExp = 'daysToExp',
  LockupSharesMil = 'lockupSharesMil',
  PercentSharesOut = 'percentSharesOut',
  numSharesOffered = 'numSharesOffered',
  MV = 'MV',
  NewsHeadline = 'headline',
  NoMilestoneLink = 'noMilestoneLink',
  NumIPOs = 'numIPOs',
  NumSPACs = 'numSPACs',
  OfferDate = 'offerDate',
  OfferMonthTiming = 'offerMonthTiming',
  OfferMonthTimingSort = 'offerMonthTimingSort',
  OfferPrice = 'offerPrice',
  OwnershipPct = 'ownershipPct',
  PastMonthReturn = 'pastMonthReturn',
  PriceChange = 'priceChange',
  PriceMidpoint = 'priceMidpoint',
  IpoDiscount = 'ipoDiscount',
  IpoPrice = 'ipoPrice',
  Proceeds = 'proceeds',
  RegionCountry = 'regionCountry',
  ReportKey = 'reportKey',
  ReportRating = 'reportRating',
  ResearchKeyword = 'keyword',
  ResearchRating = 'researchRatingText',
  ProfileRating = 'profileRating',
  ResearchType = 'researchType',
  ResearchTypeWidget = 'type',
  ResultsCount = 'resultsCount',
  ReturnFromIpo = 'returnFromIpo',
  RowNum = 'rowNum',
  Sector = 'sector',
  SectorIndustry = 'sectorIndustry',
  Shares = 'shares',
  SharesCapitalized = 'Shares',
  StockPrice = 'stockPrice',
  TodaysPriceChange = 'todaysPriceChange',
  TradeDate = 'tradeDate',
  Type = 'type',
  Underwriter = 'underwriter',
  UnderwriterKey = 'underwriterKey',
  UnderwriterName = 'underwriterName',
  Underwriters = 'underwriters',
  WeakStrong = 'weakStrong',
  EstValuation = 'estValuation',
  EstValuationSource = 'estValuatinSource',
  FundingToDate = 'FundingToDate',
  FundingSource = 'fundingDataNote',
  // Company Profile
  EVSales = 'EV/Sales',
  EVEBITDA = 'EV/EBITDA',
  PE = 'P/E',
  Ownership = 'Ownership',
  // eslint-disable-next-line @typescript-eslint/camelcase
  KeyShareholder = 'Key Shareholder',
  // eslint-disable-next-line @typescript-eslint/camelcase
  Investor_Key = 'Investor Key',
  // eslint-disable-next-line @typescript-eslint/camelcase
  Corporate_Background = 'Corporate Background',
  // eslint-disable-next-line @typescript-eslint/camelcase
  Additional_Details = 'Additional Details',
  Age = 'Age',
  CompanyData = 'companyData',
  Date = 'Date',
  Milestone = 'Milestone',
  Name = 'Name',
  Actual = 'Actual',
  // TODO for the backend: synchronize names between view pages
  TotalReturn = 'totalReturn',
  IpoReturn = 'ipoReturn',
  Return = 'return',
  ThreeMonthReturn = 'threeMonthReturn',
  ThreeMoReturn = 'threeMoReturn',
  ThreeMoReturnAvg = 'threeMoReturnAvg',
  TotalReturnAvg = 'totalReturnAvg',
  // Stats Pages
  // TODO: synchronize with api view and stats names
  Statement = 'Statement',
  StatsSectorKey = 'SectorKey',
  StatsMonth = 'Month',
  StatsCountryKey = 'CountryKey',
  StatsIpoType = 'IpoType',
  Stubs = 'Stub',
  Position = 'Position',
  KeyExecutive = 'Key Executive',
  SalesLtmebitPercent = 'salesLtmebitPercent',
  SalesLtm = 'salesLtm',
  EbitPercent = 'ebitPercent',
  LatestSales = 'LatestSales',
  LtmEbitMargin = 'LtmEbitMargin',
  Sales = 'Sales',
  GMPct = 'GM%',
  EBITDAPct = 'EBITDA%',
  YOYGrowth = 'YOY Growth',
  EBITPct = 'EBIT%',
  StatsYear = 'StatsYear',
  TargetBusiness = 'targetBusiness',
  TargetSector = 'targetSector',
  ProposedEVDeSPAC = 'proposedEVDeSPAC',
  VideoTitle = 'title',
  VideoType = 'videoTypeName',
  PublishDate = 'publishDate',
  AccountName = 'accountName',
  PagePathUrl = 'pagePathUrl',
  PagePath = 'pagePath',
  FileName = 'fileName',
  Email = 'email',
  IsActive = 'isActive',
  AccountId = 'accountId',
  FirstName = 'firstName',
  LastName = 'lastName',
  LatestTokenDate = 'latestTokenDate',
  AccountIdUrl = 'accountIdUrl',
  AccountDownloadsUrl = 'accountDownloadsUrl',
  UserActivityUrl = 'userIdUrl',
  FileActivityUrl = 'fileNameUrl',
  FileActivityExcelUrl = 'fileNameExcelUrl',
  // Diluted Shares tooltip
  CapitalizationCell = 'capitalizationData',
  Count = 'count',
  // Private Company Monitor List
  LastUpdateDaysPcml = 'lastUpdateDaysPcml',
  CFO = 'cfo',
  TypeOfFunding = 'typeOfFunding',
  LastFundingDate = 'lastFundingDate',
  PcmlNote = 'pcmlNote',
  PcmlArticles = 'pcmlArticles',
  PcmlInvestorsList = 'pcmlInvestorsList',
  PcmlFundingToDate = 'pcmlFundingToDate',
  PcmlEstValuation = 'pcmlEstValuation',
  PcmlCompanyName = 'CompanyNamePcml',
}
