import { ErrorFn } from './types/ErrorFn';
import { ErrorTypeService } from './ErrorTypeService';
import { ErrorFormatService } from './ErrorFormatService';
import { GlobalError } from './types/GlobalError';
import { ErrorData } from './interfaces/ErrorData';

export class ErrorHandlerService {
  static handle(fn: ErrorFn, error: GlobalError): void {
    // Now handle only api errors
    if (ErrorTypeService.isApiError(error)) {
      const errorData: ErrorData = ErrorFormatService.getErrorDataFromApi(error);
      fn(errorData);
    } else {
      // Not handle client js errors, throw them further
      throw error;
    }
  }
}
