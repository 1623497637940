import { ViewTriggerCellOptions } from '../../../../view/components/view-grid/components/cells/view-trigger-cell/interface/ViewTriggerCellOptions';
import { ViewGridCellValueService } from '../../../../view/components/view-grid/services/ViewGridCellValueService';
import { StatsElementSimpleGridMonthColumnValue } from '../enums/StatsElementSimpleGridMonthColumnValue';
import { DateHelper } from '../../../../../helpers/date/DateHelper';
import { StatsElementSimpleGridViewTriggerCellProps } from '../components/cells/StatsElementSimpleGridViewTriggerCell';
import { ViewColumnSystemName } from '../../../../view/components/view-grid/enums/ViewColumnSystemName';
import { GridAgColumn } from '../../../../../components/grid/grid/interfaces/GridAgColumn';
import { YEAR_REG_EXP } from '../../../../../constants/regExp';
import { STATS_ELEMENT_SIMPLE_GRID_VIEW_TRIGGER_PAST_YEARS } from '../constants/statsElementSimpleGridViewTriggerSettings';
import { DateFormat } from '../../../../../helpers/date/enums/DateFormat';

export class StatsElementSimpleGridViewTriggerService {
  static getFilterOptionsList(props: StatsElementSimpleGridViewTriggerCellProps): ViewTriggerCellOptions[] {
    let startDateStr: string | undefined;
    let endDateStr: string | undefined;

    const yearSrt = props.field;
    const monthStr = ViewGridCellValueService.getRaw(props.data, ViewColumnSystemName.StatsMonth);
    const isWholeYear = !monthStr || monthStr === StatsElementSimpleGridMonthColumnValue.Total;

    if (isWholeYear) {
      const date = DateHelper.getYearDate(parseInt(yearSrt));
      startDateStr = DateHelper.formatDate(DateHelper.getStartOfYear(date), DateFormat.YearMonthDayISO);
      endDateStr = DateHelper.formatDate(DateHelper.getEndOfYear(date), DateFormat.YearMonthDayISO);
    } else {
      const dateStr = `${yearSrt} ${monthStr}`;
      const date = DateHelper.parseDateByFormat(dateStr, DateFormat.YearMonthTextSort);

      startDateStr = date && DateHelper.formatDate(DateHelper.getStartOfMonth(date), DateFormat.YearMonthDayISO);
      endDateStr = date && DateHelper.formatDate(DateHelper.getEndOfMonth(date), DateFormat.YearMonthDayISO);
    }

    return [
      {
        getFilterName: props.startDateFilterName,
        getFilterValue: () => startDateStr,
      },
      {
        getFilterName: props.endDateFilterName,
        getFilterValue: () => endDateStr,
      },
      ...(props.optionsList || []),
    ];
  }

  static isColumnViewTriggerCell(column: GridAgColumn): boolean {
    const field = column.field;

    return this.isYearColumn(field) && this.isYearInPeriodFromToday(field);
  }

  private static isYearColumn(columnName: string): boolean {
    return YEAR_REG_EXP.test(columnName);
  }

  private static isYearInPeriodFromToday(yearStr: string): boolean {
    const year = parseInt(yearStr);
    const currentYear = DateHelper.getYear(DateHelper.getToday());
    const lastYear = DateHelper.getStartYearPassPeriod(currentYear, STATS_ELEMENT_SIMPLE_GRID_VIEW_TRIGGER_PAST_YEARS);

    return year >= lastYear;
  }
}
