import { searchFilterControls } from '../../../../../shared/entities/filters/constants/filter-controls/searchFilterControls';
import { ViewFilterName } from '../../../../../shared/entities/filters/enum/types/ViewFilterName';
import { FiltersMapValue } from '../../../../../shared/entities/filters/components/filterable-component/interfaces/FiltersMapValue';
import { screensDealStatusPricedUpcoming } from '../../../../constants/controls/screensDealStatusControls';
import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { TimeFrame } from '../../../../../shared/entities/filters/enum/TimeFrame';
import { UnderwriterOptionValue } from '../../../../../shared/entities/filters/enum/UnderwriterOptionValue';

export const screensUnderwriterSharedOptions: Partial<ScreenOptions> = {
  header: 'Underwriter Screen',
  search: {
    controls: [
      screensDealStatusPricedUpcoming,
      searchFilterControls[ViewFilterName.Underwriter] as FiltersMapValue,
      searchFilterControls[ViewFilterName.UnderwriterTypeKey] as FiltersMapValue,
    ],
  },
  toolbar: {
    quickFilter: {
      hasLocation: true,
      hasIncludeSPAC: true,
      hasIncludeAShares: true,
      timeFrameOptions: [
        TimeFrame.Month,
        TimeFrame.Quarter,
        TimeFrame.YTD,
        TimeFrame.Year,
        TimeFrame.TwoYears,
        TimeFrame.FiveYears,
      ],
    },
  },
  screenTriggerOptions: {
    isInUseAvailable: filtersValues => {
      const value = filtersValues[ViewFilterName.Underwriter];

      return !!value && value !== UnderwriterOptionValue.All;
    },
  },
};
