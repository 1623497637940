import { ViewColumnSystemName } from '../../view-grid/enums/ViewColumnSystemName';

export const onFileSPACIPOs = [
  ViewColumnSystemName.Favorite,
  ViewColumnSystemName.CompanyTicker,
  ViewColumnSystemName.Industry,
  ViewColumnSystemName.DealSize,
  ViewColumnSystemName.UnderwriterName,
];

export const pricedSPACIPOs: ViewColumnSystemName[] = [
  ViewColumnSystemName.Favorite,
  ViewColumnSystemName.CompanyTicker,
  ViewColumnSystemName.OfferDate,
  ViewColumnSystemName.Status,
  ViewColumnSystemName.DealSize,
  ViewColumnSystemName.UnderwriterName,
  ViewColumnSystemName.TotalReturn,
];

export const seekingMergerTargets = [
  ViewColumnSystemName.Favorite,
  ViewColumnSystemName.CompanyTicker,
  ViewColumnSystemName.OfferDate,
  ViewColumnSystemName.Industry,
  ViewColumnSystemName.Status,
  ViewColumnSystemName.DealSize,
  ViewColumnSystemName.UnderwriterName,
  ViewColumnSystemName.TotalReturn,
];

export const pendingSPACMergers = [
  ViewColumnSystemName.Favorite,
  ViewColumnSystemName.CompanyTicker,
  ViewColumnSystemName.OfferDate,
  ViewColumnSystemName.DealSize,
  ViewColumnSystemName.TargetCompany,
  //  ViewColumnSystemName.ProposedMVDeSPAC,
  ViewColumnSystemName.AnnouncedDate,
  ViewColumnSystemName.TotalReturn,
  ViewColumnSystemName.TargetSector,
  ViewColumnSystemName.ProposedEVDeSPAC,
];

export const scheduledVotes = [
  ViewColumnSystemName.Favorite,
  ViewColumnSystemName.CompanyTicker,
  ViewColumnSystemName.OfferDate,
  ViewColumnSystemName.TargetCompany,
  //  ViewColumnSystemName.ProposedMVDeSPAC,
  ViewColumnSystemName.AnnouncedDate,
  ViewColumnSystemName.VoteDate,
  ViewColumnSystemName.TotalReturn,
  ViewColumnSystemName.TargetSector,
  ViewColumnSystemName.ProposedEVDeSPAC,
];

export const completedDeSPACs = [
  ViewColumnSystemName.Favorite,
  ViewColumnSystemName.CompanyTicker,
  ViewColumnSystemName.OfferDate,
  //  ViewColumnSystemName.ProposedMVDeSPAC,
  ViewColumnSystemName.CompletionDate,
  ViewColumnSystemName.TotalReturn,
  ViewColumnSystemName.TargetSector,
  ViewColumnSystemName.TargetBusiness,
  ViewColumnSystemName.ProposedEVDeSPAC,
];

export const liquidatedSPACs = [
  ViewColumnSystemName.Favorite,
  ViewColumnSystemName.CompanyTicker,
  ViewColumnSystemName.OfferDate,
  ViewColumnSystemName.Status,
  ViewColumnSystemName.DealSize,
  ViewColumnSystemName.UnderwriterName,
  ViewColumnSystemName.LiquidationDate,
];
