import React, { useMemo } from 'react';
import './FiltersMap.scss';
import { FiltersMapValue } from './interfaces/FiltersMapValue';
import FormGroup from '../../../../components/forms/FormGroup';
import FormControlSwitch from '../../../../components/forms/components/form-builder/FormControlSwitch';
import { FormControl } from '../../../../components/forms/components/form-builder/types/formControlsTypes';
import { FiltersMapService } from './services/FiltersMapService';
import { ViewFiltersDictionaries } from '../../interfaces/ViewFiltersDictionaries';
import { useClassName } from '../../../../hooks/useClassName';
import { FiltersMapItemBlock, FiltersMapItemElement } from './filtersMapBem';

export interface FiltersMapProps {
  filterControls: FiltersMapValue[];
  dictionaries?: ViewFiltersDictionaries;
}

const FiltersMap: React.FC<FiltersMapProps> = props => {
  const cn = useClassName(FiltersMapItemBlock.Root);

  const controls = useMemo(() => FiltersMapService.getControls(props.filterControls, props.dictionaries), [
    props.filterControls,
    props.dictionaries,
  ]);

  return (
    <>
      {controls.map((control: FiltersMapValue, index: number) => (
        <FormGroup className={cn()} key={index}>
          {control.title && (
            <div className={cn(FiltersMapItemElement.Title)}>
              <h6>{control.title}</h6>
            </div>
          )}

          <FormControlSwitch control={control as FormControl} />
        </FormGroup>
      ))}
    </>
  );
};

export default FiltersMap;
