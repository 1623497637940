export enum TabsContainerBlock {
  Root = 'TabsContainer',
}

export enum TabsContainerElement {
  NavNavTabs = 'nav nav-tabs',
}

export enum TabsContainerModifier {
  TabContentGutter = 'TabContentGutter',
}
