import React from 'react';
import './BackNavButton.scss';
import Button from '../../forms/Button';
import Icon from '../../icon/Icon';
import { RoutePath } from '../../../enums/RoutePath';
import { ControlSize } from '../../../enums/ControlSize';
import { IconName } from '../../icon/IconName';

interface Props {
  routePath: RoutePath;
}

const BackNavButton: React.FC<Props> = props => {
  return (
    <Button
      className="BackNavButton"
      buttonLink={{
        routePath: props.routePath,
      }}
      isOutline={true}
    >
      <Icon name={IconName.LeftArrow} size={ControlSize.Small} />
      <span className="BackNavButton__title">Back to {props.children}</span>
    </Button>
  );
};

export default BackNavButton;
