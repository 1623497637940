import React, { useContext, useMemo } from 'react';
import './ScheduledCalendar.scss';
import { ResponsiveCalendarMode } from '../../../shared/components/responsive-calendar/enums/ResponsiveCalendarMode';
import { RoutePath } from '../../../shared/enums/RoutePath';
import { ControlSize } from '../../../shared/enums/ControlSize';
import IconWithContent from '../../../shared/components/icon/IconWithContent';
import { ViewContext } from '../../../shared/entities/view/components/view/ViewContext';
import { Link } from 'react-router-dom';
import { IconName } from '../../../shared/components/icon/IconName';
import Calendar from '../../../shared/entities/calendar/Calendar';
import { ScheduledAdditionalDataProp } from '../enums/ScheduledAdditionalDataProp';
import { ViewFilterName } from '../../../shared/entities/filters/enum/types/ViewFilterName';
import { ScheduledCalendarFilterService } from '../services/ScheduledCalendarFilterService';

const ScheduledCalendar: React.FC = () => {
  const { state } = useContext(ViewContext);

  const timeFrame = state.filters[ViewFilterName.OfferTimeFrame];
  const headquarter = state.filters[ViewFilterName.LocationListingLocation];

  const startDate = useMemo(() => ScheduledCalendarFilterService.getStartDate(timeFrame), [timeFrame]);

  return (
    <Calendar
      className="ScheduledCalendar"
      mode={ResponsiveCalendarMode.WeekHorizontal}
      events={state.additionalData?.[ScheduledAdditionalDataProp.CalendarEvents]}
      hasHeader={false}
      hasAccordion={true}
      onScheduled={true}
      startDate={startDate}
      headquarter={headquarter}
      headerAction={
        <Link to={RoutePath.MasterCalendar}>
          <IconWithContent name={IconName.Calendar} size={ControlSize.Small} isReversed>
            Master Calendar
          </IconWithContent>
        </Link>
      }
    />
  );
};

export default ScheduledCalendar;
