import React, { Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import './StatsFilters.scss';
import { IconName } from '../../shared/components/icon/IconName';
import { ControlSize } from '../../shared/enums/ControlSize';
import StatsFilterPopup from './shared/filter/StatsFilterPopup';
import StatsPageFiltersControls from './shared/page-filters/StatsPageFiltersControls';
import { StatsGlobalFilter } from './types/StatsGlobalFilter';
import FileActions from '../../shared/components/file-actions/FileActions';
import { FileActionProp } from '../../shared/components/file-actions/interfaces/FileActionProp';
import { FileActionType } from '../../shared/components/file-actions/enums/FileActionType';
import Form from '../../shared/components/forms/Form';
import { BlockOrientation } from '../../shared/enums/BlockOrientation';
import FormGroup from '../../shared/components/forms/FormGroup';
import IconWithContent from '../../shared/components/icon/IconWithContent';
import { StatsPageContext } from './context/StatsPageContext';
import Button from '../../shared/components/forms/Button';
import { FormControlCounterService } from '../../shared/components/forms/components/form-builder/services/FormControlCounterService';
import { statsPageFiltersControls } from './shared/filter/constants/statsPageFiltersControls';
import { FormControl } from '../../shared/components/forms/components/form-builder/types/formControlsTypes';

export interface StatsFiltersProps {
  filtersNames: StatsGlobalFilter[];
  hasFileActions?: boolean;
  excelOptions?: FileActionProp;
}

const StatsFilters: React.FC<StatsFiltersProps> = props => {
  const { hasFileActions = true } = props;

  const { pageFilters, isExcelActionAvailable, pageFiltersSettings, onInlineFiltersChange, onExcelAction } = useContext(
    StatsPageContext,
  );

  const [isFiltersOpen, setIsFilterOpen] = useState<boolean>(false);
  const openFilter = useCallback(() => setIsFilterOpen(true), [setIsFilterOpen]);

  const filtersShowState = useMemo(() => [isFiltersOpen, setIsFilterOpen], [isFiltersOpen, setIsFilterOpen]) as [
    boolean,
    Dispatch<SetStateAction<boolean>>,
  ];

  const activePopupFiltersCount = useMemo(
    () =>
      FormControlCounterService.getActiveControlsCountsTitle(statsPageFiltersControls as FormControl[], pageFilters),
    [pageFilters],
  );

  const fileActions: FileActionProp[] | null = useMemo(() => {
    if (hasFileActions) {
      const actions: FileActionProp[] = [
        {
          type: FileActionType.Print,
          hasWrapper: true,
        },
      ];

      if (isExcelActionAvailable && onExcelAction) {
        actions.push({
          ...props.excelOptions,
          type: FileActionType.Excel,
          hasWrapper: true,
          externalDownloadOptions: {
            fn: onExcelAction,
          },
        });
      }

      return actions;
    } else {
      return null;
    }
  }, [hasFileActions, props.excelOptions, isExcelActionAvailable, onExcelAction]);

  return (
    <section className="StatsFilters">
      <Form
        className="StatsFilters__filters"
        defaultValues={pageFilters}
        orientation={BlockOrientation.Line}
        onChange={onInlineFiltersChange}
      >
        {pageFiltersSettings?.filtersDictionaries && (
          <FormGroup>
            <Button className="StatsFilters__title" onClick={openFilter}>
              <IconWithContent name={IconName.Filter} size={ControlSize.Small}>
                Filter <span className="StatsFilters__activePopupFiltersCount">{activePopupFiltersCount}</span>
              </IconWithContent>
            </Button>
          </FormGroup>
        )}

        <StatsPageFiltersControls filtersNames={props.filtersNames} />
      </Form>

      {fileActions && <FileActions className="StatsFilters__fileActions" fileActions={fileActions} />}

      <StatsFilterPopup showState={filtersShowState} />
    </section>
  );
};

export default StatsFilters;
