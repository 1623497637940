import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './TooltipAsync.scss';
import Tooltip from './Tooltip';
import { CustomAny } from '../../types/generics';
import { TooltipContentProps } from './interface/TooltipContentProps';
import { useClassName } from '../../hooks/useClassName';
import { ControlSize } from '../../enums/ControlSize';
import { TooltipTriggerType } from './enums/TooltipTriggerType';
import { useAsyncFn } from '../../hooks/async-fn/useAsyncFn';
import PageLoader from '../loader/PageLoader';
import { ObjectHelper } from '../../helpers/ObjectHelper';

interface Props {
  getDataFn: Function;
  title: React.ReactNode;
  component: React.FC<TooltipContentProps<CustomAny>>;
  size?: ControlSize;
  isTitleHighlighted?: boolean;
  triggerType?: TooltipTriggerType;
  hasIndentHorizontal?: boolean;
  hasIndentVertical?: boolean;
  isUnderFixed?: boolean;
  hasMaxHeight?: boolean;
}

const TooltipAsync: React.FC<Props> = props => {
  const ref = useRef<CustomAny>(null);
  const [data, setData] = useState<CustomAny>();

  const [loadDataFn, isLoading] = useAsyncFn(async () => {
    const data: CustomAny = await props.getDataFn();

    setData(data);
  });

  const isOtherwise = useMemo(() => !ObjectHelper.arePropsEmpty(data), [data]);

  const cn = useClassName('TooltipAsync');

  const onToggle = useCallback(
    (isShow: boolean): void => {
      if (isShow) {
        loadDataFn();
      }
    },
    [loadDataFn],
  );

  useEffect(() => {
    if (!isLoading) {
      ref.current?.handleResizeScroll?.();
    }
  }, [isLoading]);

  return (
    <Tooltip
      ref={ref}
      className={cn({
        loading: isLoading,
      })}
      title={props.title}
      size={props.size}
      triggerType={props.triggerType}
      isTitleHighlighted={props.isTitleHighlighted}
      hasIndentHorizontal={props.hasIndentHorizontal}
      hasIndentVertical={props.hasIndentVertical}
      isUnderFixed={props.isUnderFixed}
      hasMaxHeight={props.hasMaxHeight}
      content={
        <PageLoader otherwise={isOtherwise} loading={isLoading}>
          {data &&
            React.createElement(props.component, {
              data,
            })}
        </PageLoader>
      }
      onToggle={onToggle}
    />
  );
};

export default TooltipAsync;
