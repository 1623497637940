import React, { useCallback } from 'react';
import './MainMenuList.scss';
import { RootState } from '../../../store';
import { closeMobileMenu } from '../../../store/header/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RoutePath } from '../../enums/RoutePath';
import {
  NEWSLETTER_URL,
  IPO_INTELLIGENCE_MARKETING_SITE_OVERVIEW_URL,
  IPO_INTELLIGENCE_MARKETING_SITE_FEATURES_URL,
  IPO_INTELLIGENCE_MARKETING_SITE_WHO_USES_US_URL,
  IPO_INTELLIGENCE_MARKETING_SITE_PRICING_URL,
  IPO_INTELLIGENCE_MARKETING_SITE_ABOUT_URL,
} from '../../constants/urls';
import { Link, NavLink, useLocation } from 'react-router-dom';
import SubMenu from './sub-menu/SubMenu';
import { NavUrlService } from '../../services/NavUrlService';
import { researchPaths } from '../../constants/routePaths/researchPaths';
import { userAccountNavItems } from '../../entities/navigation/constants/userAccountNavItems';
import { statsNavItems } from '../../entities/navigation/constants/statsNavItems';
import { screensNavItems } from '../../entities/navigation/constants/screensNavItems';
import { scheduledPaths } from '../../constants/routePaths/scheduledPaths';
import { AuthService } from '../../services/AuthService';
import isTouchEnabled from '../../helpers/isTouchEnabled';
import useBreakpointAvailable from '../../effects/useBreakpointAvailable';
import { mobileBreakpoints } from '../../constants/breakpoints/mobileBreakpoints';
import { screensPaths } from '../../constants/routePaths/screensPath';
import { statsPaths } from '../../constants/routePaths/statsPath';
import LocationService from '../../services/LocationService';
import { pcwPaths } from '../../constants/routePaths/pcwPaths';

const MainMenuList: React.FC = () => {
  const dispatch = useDispatch();
  const isAuthorized = useSelector<RootState, boolean>(state => state.auth.isAuthorized);
  const open = useSelector<RootState, boolean>(state => state.header.isMobileMenuOpen);
  const [showStatsItems, setShowStatsItems] = React.useState(false);
  const [showScreensItems, setShowScreensItems] = React.useState(false);

  const location = useLocation();

  const onLinkClick = useCallback((): void => {
    if (open) {
      dispatch(closeMobileMenu());
    }
  }, [dispatch, open]);

  const onLogoutClick = useCallback((): void => {
    AuthService.logout();
    onLinkClick();
  }, [onLinkClick]);

  const isMobileMenu = useBreakpointAvailable(mobileBreakpoints);
  const isWideTouchScreen = isTouchEnabled() && !isMobileMenu;

  const isScreensActive = LocationService.hasCurrentPath(screensPaths, location);
  const isStatsActive = LocationService.hasCurrentPath(statsPaths, location);

  const statsSubMenuTitleProp = isMobileMenu ? (
    <span className="MainMenuList__title">Stats</span>
  ) : (
    <NavLink
      className="MainMenuList__link"
      to={RoutePath.Stats}
      onClick={onLinkClick}
      isActive={NavUrlService.isActiveInPaths(statsPaths)}
    >
      Stats
    </NavLink>
  );

  const screensSubMenuTitleProp = isMobileMenu ? (
    <span className="MainMenuList__title">IPO Screens</span>
  ) : (
    <NavLink
      className="MainMenuList__link"
      to={RoutePath.ScreensAdd}
      onClick={onLinkClick}
      isActive={NavUrlService.isActiveInPaths(screensPaths)}
    >
      IPO Screens
    </NavLink>
  );

  const conditionalSubMenuItems = isWideTouchScreen ? (
    <>
      <li className="MainMenuList__item">
        <SubMenu
          title={
            <span
              className={`MainMenuList__link ${isStatsActive ? 'active' : ''}`}
              onClick={() => {
                onLinkClick();
                setShowStatsItems(true);
              }}
            >
              Stats
            </span>
          }
          setShow={setShowStatsItems}
          show={showStatsItems}
          items={statsNavItems}
          onItemClick={() => {
            onLinkClick();
            setShowStatsItems(false);
          }}
          isClickForced
        />
      </li>
      <li className="MainMenuList__item">
        <SubMenu
          title={
            <span
              className={`MainMenuList__link ${isScreensActive ? 'active' : ''}`}
              onClick={() => {
                onLinkClick();
                setShowScreensItems(true);
              }}
            >
              IPO Screens
            </span>
          }
          setShow={setShowScreensItems}
          show={showScreensItems}
          items={screensNavItems}
          onItemClick={() => {
            onLinkClick();
            setShowScreensItems(false);
          }}
          isClickForced
        />
      </li>
    </>
  ) : (
    <>
      <li className="MainMenuList__item">
        <SubMenu title={statsSubMenuTitleProp} items={statsNavItems} onItemClick={onLinkClick} />{' '}
      </li>
      <li className="MainMenuList__item">
        <SubMenu title={screensSubMenuTitleProp} items={screensNavItems} onItemClick={onLinkClick} />
      </li>
    </>
  );

  if (isAuthorized) {
    return (
      <ul className="MainMenuList">
        <li className="MainMenuList__item">
          <NavLink className="MainMenuList__link" to={RoutePath.Dashboard} onClick={onLinkClick}>
            Dashboard
          </NavLink>
        </li>
        <li className="MainMenuList__item">
          <NavLink
            className="MainMenuList__link"
            to={RoutePath.Scheduled}
            isActive={NavUrlService.isActiveInPaths(scheduledPaths)}
            onClick={onLinkClick}
          >
            Scheduled
          </NavLink>
        </li>
        <li className="MainMenuList__item">
          <NavLink
            className="MainMenuList__link"
            to={RoutePath.ResearchDashboard}
            isActive={NavUrlService.isActiveInPaths(researchPaths)}
            onClick={onLinkClick}
          >
            Research
          </NavLink>
        </li>
        <li className="MainMenuList__item">
          <NavLink className="MainMenuList__link" to={RoutePath.Priced} onClick={onLinkClick}>
            Priced
          </NavLink>
        </li>
        <li className="MainMenuList__item">
          <NavLink className="MainMenuList__link" to={RoutePath.Pipeline} onClick={onLinkClick}>
            Pipeline
          </NavLink>
        </li>
        <li className="MainMenuList__item">
          <NavLink
            className="MainMenuList__link"
            to={RoutePath.PrivateCompanyWatchlist}
            isActive={NavUrlService.isActiveInPaths(pcwPaths)}
            onClick={onLinkClick}
          >
            Private Co
          </NavLink>
        </li>
        <li className="MainMenuList__item">
          <NavLink className="MainMenuList__link" to={RoutePath.News} onClick={onLinkClick}>
            News
          </NavLink>
        </li>
        {conditionalSubMenuItems}
        <li className="MainMenuList__item MainMenuList__item--divider" />
        <li className="MainMenuList__item MainMenuList__item--list MainMenuList__item--hamburger">
          <SubMenu
            title={<span className="MainMenuList__title">Account</span>}
            items={userAccountNavItems}
            onItemClick={onLinkClick}
          />
        </li>
        <li className="MainMenuList__item MainMenuList__item--hamburger">
          <NavLink
            className={`MainMenuList__link${location.pathname.indexOf('/latest-videos') > -1 ? ' active' : ''}`}
            to={RoutePath.LatestVideos}
            onClick={onLinkClick}
            title="Latest Videos"
          >
            Latest Videos
          </NavLink>
        </li>

        <li className="MainMenuList__item MainMenuList__item--hamburger">
          <NavLink className="MainMenuList__link" to={RoutePath.ContactUs} onClick={onLinkClick}>
            Contact Us
          </NavLink>
        </li>
        {/* <li className="MainMenuList__item MainMenuList__item--hamburger">
          <NavLink className="MainMenuList__link" to={RoutePath.Help} onClick={onLinkClick}>
            Help
          </NavLink>
        </li> */}
        <li className="MainMenuList__item MainMenuList__item--hamburger">
          <Link className="MainMenuList__link" to={RoutePath.Login} onClick={onLogoutClick}>
            Log Out
          </Link>
        </li>
      </ul>
    );
  }

  return (
    <ul className="MainMenuList">
      <li className="MainMenuList__item">
        <a
          className="MainMenuList__link"
          href={IPO_INTELLIGENCE_MARKETING_SITE_OVERVIEW_URL}
          onClick={onLinkClick}
          rel={'canonical'}
        >
          {' '}
          Overview
        </a>
      </li>
      {/* <li className="MainMenuList__item">
        <a
          className="MainMenuList__link"
          href={IPO_INTELLIGENCE_MARKETING_SITE_RESEARCH_URL}
          onClick={onLinkClick}
          rel={'canonical'}
        >
          {' '}
          Research
        </a>
      </li> */}
      <li className="MainMenuList__item">
        <a
          className="MainMenuList__link"
          href={IPO_INTELLIGENCE_MARKETING_SITE_FEATURES_URL}
          onClick={onLinkClick}
          rel={'canonical'}
        >
          {' '}
          Features
        </a>
      </li>
      <li className="MainMenuList__item">
        <a
          className="MainMenuList__link"
          href={IPO_INTELLIGENCE_MARKETING_SITE_WHO_USES_US_URL}
          onClick={onLinkClick}
          rel={'canonical'}
        >
          {' '}
          Who Uses Us
        </a>
      </li>
      <li className="MainMenuList__item">
        <a
          className="MainMenuList__link"
          href={IPO_INTELLIGENCE_MARKETING_SITE_PRICING_URL}
          onClick={onLinkClick}
          rel={'canonical'}
        >
          {' '}
          Pricing
        </a>
      </li>
      <li className="MainMenuList__item">
        <a
          className="MainMenuList__link"
          href={NEWSLETTER_URL}
          target="_blank"
          rel="nofollow noopener noreferrer"
          onClick={onLinkClick}
        >
          Weekly IPO Updates
        </a>
      </li>
      <li className="MainMenuList__item">
        <a
          className="MainMenuList__link"
          href={IPO_INTELLIGENCE_MARKETING_SITE_ABOUT_URL}
          onClick={onLinkClick}
          rel={'canonical'}
        >
          {' '}
          About
        </a>
      </li>
      <li className="MainMenuList__item MainMenuList__item--divider" />
      <li className="MainMenuList__item MainMenuList__item--hamburger">
        <NavLink className="MainMenuList__link" to={RoutePath.Contact} onClick={onLinkClick}>
          Contact Us &gt;
        </NavLink>
      </li>
    </ul>
  );
};

export default MainMenuList;
