import { GridScrollOptions } from '../interfaces/GridScrollOptions';
import { GridScrollDirection } from '../enums/GridScrollDirection';
import { scrollDistance, scrollSpeed, scrollStep } from '../constants/scroll';

export class GridScrollService {
  static isAvailable(container: Element, direction: GridScrollDirection): boolean {
    return direction === GridScrollDirection.Right ? this.isRightAvailable(container) : this.isLeftAvailable(container);
  }

  static isRightAvailable(container: Element): boolean {
    const scrollLeft: number = container.scrollLeft;
    const scrollWidth: number = container.scrollWidth;
    const containerWidth: number = container.clientWidth;

    return scrollWidth - containerWidth > scrollLeft;
  }

  static isLeftAvailable(container: Element): boolean {
    const scrollLeft: number = container.scrollLeft;
    return scrollLeft !== 0;
  }

  static scroll(options: GridScrollOptions): Promise<void> {
    return new Promise<void>((resolve): void => {
      const step = options.step || scrollStep;
      const speed = options.speed || scrollSpeed;
      const distance = options.distance || scrollDistance;

      let scrollAmount = 0;

      const slideTimer = setInterval(() => {
        if (options.direction === GridScrollDirection.Left) {
          options.element.scrollLeft -= step;
        } else {
          options.element.scrollLeft += step;
        }

        scrollAmount += step;

        if (scrollAmount >= distance) {
          clearInterval(slideTimer);

          resolve();
        }
      }, speed);
    });
  }
}
