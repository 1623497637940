import { FilterControlSettings } from '../../../types/FilterControlSettings';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { ControlSize } from '../../../../../enums/ControlSize';
import { PrependValueType } from '../../../../../components/forms/enums/PrependValueType';

export const valuationControls: FilterControlSettings = {
  [ViewFilterName._MarketCapAtIPO]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._MarketCapAtIPO,
    props: {
      label: 'Market Cap at IPO ($mm)',
      minName: ViewFilterName.MarketCapAtIPOMin,
      maxName: ViewFilterName.MarketCapAtIPOMax,
      minPrepend: [PrependValueType.Min],
      maxPrepend: [PrependValueType.Max],
      size: ControlSize.Small,
      width: ControlSize.SmallerExtraSmall,
      maxPlaceholder: '$',
      minPlaceholder: '$',
    },
  },
  [ViewFilterName._Valuation]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._Valuation,
    props: {
      label: 'Estimated Valuation ($mm)',
      minName: ViewFilterName.ValuationMin,
      maxName: ViewFilterName.ValuationMax,
      minPrepend: [PrependValueType.Min],
      maxPrepend: [PrependValueType.Max],
      size: ControlSize.Small,
      width: ControlSize.SmallerExtraSmall,
      maxPlaceholder: '$',
      minPlaceholder: '$',
    },
  },
  [ViewFilterName._ValuationEnterpriseValueAtIPO]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._ValuationEnterpriseValueAtIPO,
    props: {
      label: 'Enterprise Value at IPO',
      minName: ViewFilterName.ValuationEnterpriseValueAtIPOMin,
      maxName: ViewFilterName.ValuationEnterpriseValueAtIPOMax,
      minPrepend: [PrependValueType.Min],
      maxPrepend: [PrependValueType.Max],
      size: ControlSize.Small,
      width: ControlSize.Small,
      maxPlaceholder: '$',
      minPlaceholder: '$',
    },
  },
  [ViewFilterName._IpoEvToLtmSales]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._IpoEvToLtmSales,
    props: {
      label: 'IPO EV/LTM Sales',
      minName: ViewFilterName.IpoEvToLtmSalesMin,
      maxName: ViewFilterName.IpoEvToLtmSalesMax,
      minPrepend: [PrependValueType.Min],
      maxPrepend: [PrependValueType.Max],
      size: ControlSize.Small,
      width: ControlSize.Small,
    },
  },
  [ViewFilterName._IpoEvToEbitda]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._IpoEvToEbitda,
    props: {
      label: 'IPO EV/EBITDA',
      minName: ViewFilterName.IpoEvToEbitdaMin,
      maxName: ViewFilterName.IpoEvToEbitdaMax,
      minPrepend: [PrependValueType.Min],
      maxPrepend: [PrependValueType.Max],
      size: ControlSize.Small,
      width: ControlSize.Small,
    },
  },
  [ViewFilterName._IpoPe]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._IpoPe,
    props: {
      label: 'IPO P/E',
      minName: ViewFilterName.IpoPeMin,
      maxName: ViewFilterName.IpoPeMax,
      minPrepend: [PrependValueType.Min],
      maxPrepend: [PrependValueType.Max],
      size: ControlSize.Small,
      width: ControlSize.Small,
    },
  },
  [ViewFilterName._IpoPb]: {
    type: FormControlType.ValueRange,
    name: ViewFilterName._IpoPb,
    props: {
      label: 'IPO P/B',
      minName: ViewFilterName.IpoPbMin,
      maxName: ViewFilterName.IpoPbMax,
      minPrepend: [PrependValueType.Min],
      maxPrepend: [PrependValueType.Max],
      size: ControlSize.Small,
      width: ControlSize.Small,
    },
  },
};
