import React, { useContext, useMemo } from 'react';
import './CompanyOfferingData.scss';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import CompanyOfferingDataActions from './CompanyOfferingDataActions';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import { CompanyOfferingRowsDto } from './interfaces/CompanyOfferingRowsDto';
import Spacer from '../../../../../../shared/components/Spacer';
import { useClassName } from '../../../../../../shared/hooks/useClassName';
import CompanyProfileSidebarTable from '../company-profile-table/CompanyProfileSidebarTable';

const CompanyOfferingData: React.FC = () => {
  const cn = useClassName('CompanyOfferingData');

  const { getTabData } = useContext(CompanyContext);

  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const data = useMemo(() => profile.offeringData, [profile]);
  const filteredEmptyRows = useMemo(() => data?.rows.filter((row: CompanyOfferingRowsDto) => !!row.data), [data]);
  const note = useMemo(() => data?.note, [data]);

  return (
    <>
      {data && (
        <CompanyProfileSideItem
          className={cn()}
          title="Offering Data"
          actions={
            <>
              <Spacer />
              <CompanyOfferingDataActions />
            </>
          }
        >
          <CompanyProfileSidebarTable
            {...data}
            expandedData={filteredEmptyRows as CompanyOfferingRowsDto[]}
            isHeaderHidden
            hasAroundBorder={false}
          />

          {note && (
            <div className={cn('note')}>
              Note: <i>{note}</i>
            </div>
          )}
        </CompanyProfileSideItem>
      )}
    </>
  );
};

export default CompanyOfferingData;
