import { ViewColumnSystemName } from '../../view/types/ViewColumnSystemName';
import { GridRow } from '../../../../../components/grid/shared/types/GridRow';
import { GridCellValue } from '../../../../../components/grid/shared/types/GridCellValue';
import { ViewGridColumnService } from './ViewGridColumnService';
import { isNil } from 'lodash';

export class ViewGridCellValueService {
  static getVisible(row: GridRow, columnSystemName: ViewColumnSystemName): GridCellValue {
    return this.getRaw(row, ViewGridColumnService.getVisibleColumnName(columnSystemName));
  }

  static getRaw(row: GridRow, columnSystemName: ViewColumnSystemName): GridCellValue {
    return row[columnSystemName];
  }

  static getVisibleIfExists(row: GridRow, columnSystemName: ViewColumnSystemName): GridCellValue {
    const visible = this.getVisible(row, columnSystemName);
    const raw = this.getRaw(row, columnSystemName);

    return isNil(visible) ? raw : visible;
  }
}
