import React, { useCallback, useMemo } from 'react';
import './StatsElementChartFilterDynamic.scss';
import Form from '../../../../../components/forms/Form';
import { FiltersValues } from '../../../../filters/types/FiltersValues';
import FormGroup from '../../../../../components/forms/FormGroup';
import { SelectOption } from '../../../../../services/select/interfaces/SelectOption';
import { useClassName } from '../../../../../hooks/useClassName';
import { StatsElementChartFormBlock, StatsElementChartFormElement } from './statsElementChartFilterDynamicBem';
import { BlockOrientation } from '../../../../../enums/BlockOrientation';
import FormSelect from '../../../../../components/forms/components/select/FormSelect';
import { ControlSize } from '../../../../../enums/ControlSize';
import { headquarterWorldRegionsCodes } from '../../../../filters/constants/headquarterWorldRegionsCodes';
import { StatsElementChartFilterDynamic as StatsElementChartFilterDynamicType } from '../types/StatsElementChartFilterDynamic';
import { StatsFilterName } from '../../../../../../stats/stats/enum/StatsFilterName';
import FormCheckbox from '../../../../../components/forms/FormCheckbox';
import FormTimeFrameSelect from '../../../../../components/forms/FormTimeFrameSelect';
import { TimeFrame } from '../../../../filters/enum/TimeFrame';
import { TextSelectElementType } from '../../../../../components/forms/components/text-select/enums/TextSelectElementType';

interface Props {
  className?: string;
  options?: SelectOption[];
  dynamicFilters?: StatsElementChartFilterDynamicType[];
  onChange: (filters: FiltersValues) => void;
}

const DATE_RANGE_TIME_FRAMES = [TimeFrame.YTD, TimeFrame.Quarter, TimeFrame.LTM];

export const StatsElementChartFilterDynamic: React.FC<Props> = props => {
  const cn = useClassName(StatsElementChartFormBlock.Root, props.className);

  const getSelectOptionViewOptionsFn = useCallback(
    option => ({
      isHighlighted: headquarterWorldRegionsCodes.includes(option.value),
    }),
    [],
  );

  const hasDynamicFilter = useCallback(
    (filterName: StatsElementChartFilterDynamicType) =>
      props.dynamicFilters && props.dynamicFilters.includes(filterName),
    [props.dynamicFilters],
  );

  const hasReturnsFilter = hasDynamicFilter(StatsFilterName._IPOsFromDateRange);
  const hasIndustryFilter = hasDynamicFilter(StatsFilterName.Industry);
  const hasCountryRegionFilter = hasDynamicFilter(StatsFilterName.CountryRegion);

  const formDefaultValues = useMemo(() => {
    const values: FiltersValues = {};

    if (hasReturnsFilter) {
      values[StatsFilterName.IPOsFrom] = TimeFrame.LTM;
      values[StatsFilterName.IPOsFromMax] = undefined;
      values[StatsFilterName.IPOsFromMin] = undefined;
    }

    if (hasIndustryFilter) {
      values[StatsFilterName.Industry] = undefined;
    }

    if (hasCountryRegionFilter) {
      values[StatsFilterName.CountryRegion] = undefined;
    }

    return values;
  }, [hasReturnsFilter, hasIndustryFilter, hasCountryRegionFilter]);

  return (
    <Form
      className={cn()}
      defaultValues={formDefaultValues}
      orientation={BlockOrientation.Line}
      onChange={props.onChange}
    >
      {hasReturnsFilter && (
        <FormGroup>
          <FormTimeFrameSelect
            timeFrameLabel="IPOs From"
            minName={StatsFilterName.IPOsFromMin}
            maxName={StatsFilterName.IPOsFromMax}
            timeFrameName={StatsFilterName.IPOsFrom}
            timeFrames={DATE_RANGE_TIME_FRAMES}
            size={ControlSize.Small}
            elementType={TextSelectElementType.Button}
            isInline
          />
        </FormGroup>
      )}

      {hasIndustryFilter && (
        <FormGroup className={cn(StatsElementChartFormElement.Select)}>
          <FormSelect
            name={StatsFilterName.Industry}
            options={props.options}
            width={ControlSize.Medium}
            isMultiSelect={false}
            placeholder="Industry"
            size={ControlSize.Small}
          />
        </FormGroup>
      )}

      {hasCountryRegionFilter && (
        <FormGroup className={cn(StatsElementChartFormElement.Select)}>
          <FormSelect
            name={StatsFilterName.CountryRegion}
            options={props.options}
            width={ControlSize.Medium}
            isMultiSelect={false}
            placeholder="Region"
            size={ControlSize.Small}
            getSelectOptionViewOptionsFn={getSelectOptionViewOptionsFn}
          />
        </FormGroup>
      )}

      {hasDynamicFilter(StatsFilterName.ADRsOnly) && (
        <FormGroup>
          <FormCheckbox name={StatsFilterName.ADRsOnly} label="ADRs only" />
        </FormGroup>
      )}
    </Form>
  );
};
