import { FileActionProp } from '../../../../../../components/file-actions/interfaces/FileActionProp';
import { FileActionType } from '../../../../../../components/file-actions/enums/FileActionType';
import { GetFileFn } from '../../../../../../services/file/interfaces/GetAndDownloadFileOptions';

export class ViewGridToolbarGroupFileActionsFactory {
  static getActions({
    fileActionTypes = [],
    excelFn,
  }: {
    fileActionTypes?: FileActionType[];
    excelFn?: GetFileFn;
  }): FileActionProp[] {
    return fileActionTypes?.map(fileActionType => this.getAction({ type: fileActionType, excelFn }));
  }

  private static getAction({ type, excelFn }: { type: FileActionType; excelFn?: GetFileFn }): FileActionProp {
    let options: Partial<FileActionProp> = {};

    switch (type) {
      case FileActionType.Excel:
        options = {
          tooltipTitle: 'Export to Excel',
        };

        if (excelFn) {
          options.externalDownloadOptions = {
            fn: excelFn,
          };
        }

        break;
      case FileActionType.Link:
        options = {
          tooltipTitle: 'Create and Share Link',
        };
        break;
      case FileActionType.Save:
        options = {
          type: FileActionType.Save,
          tooltipTitle: 'Save View',
        };
        break;
      case FileActionType.Print:
        options = {
          type: FileActionType.Print,
          tooltipTitle: 'Print Page',
        };
        break;
      case FileActionType.Insight:
        options = {
          type: FileActionType.Insight,
          tooltipTitle: 'View Enhanced Profile',
        };
        break;
    }

    return {
      type,
      ...options,
    };
  }
}
