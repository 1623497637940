import { GridLayoutBreakpoint } from '../enums/GridLayoutBreakpoint';
import { ContainerMaxWidth } from '../../../enums/ContainerMaxWidth';

export const gridLayoutBreakpointSizes = {
  [GridLayoutBreakpoint.Large]: ContainerMaxWidth.ExtraLarge,
  [GridLayoutBreakpoint.Medium]: ContainerMaxWidth.Large,
  [GridLayoutBreakpoint.Small]: ContainerMaxWidth.Medium,
  [GridLayoutBreakpoint.ExtraSmall]: ContainerMaxWidth.Small,
  [GridLayoutBreakpoint.ExtraExtraSmall]: 0,
};
