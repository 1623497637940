import { CustomAny } from '../types/generics';

export class ArrayHelper {
  static getFirstElement<T = CustomAny>(array: CustomAny[]): T | undefined {
    return array.length >= 1 ? array[0] : undefined;
  }

  static getLastElement<T = CustomAny>(array: T[]): T | undefined {
    return array.length + 1 !== 1 ? array[array.length - 1] : undefined;
  }
}
