import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import './ActionModal.scss';
import Modal, { ModalProps, ModalSize } from './Modal';
import ButtonsList from '../forms/ButtonsList';
import Button from '../forms/Button';
import { ColorScheme } from '../../enums/ColorScheme';
import { ControlSize } from '../../enums/ControlSize';
import { useClassName } from '../../hooks/useClassName';
import { NotificationSuccessMessage } from '../../../store/notification/types';
import { useDispatch } from 'react-redux';
import { handleAction } from '../../../store/notification/actions';
import { ModalShowState } from './types/ModalShowState';
import { ActionModalBlock, ActionModalElement } from './actionModalBem';
import Form, { FormProps } from '../forms/Form';
import { CustomAny } from '../../types/generics';
import ButtonSubmit from '../forms/ButtonSubmit';
import { ButtonType } from '../../enums/ButtonType';

export interface Props extends ModalProps {
  body: ReactNode;
  header: ReactNode;
  action: ReactNode;
  showState: ModalShowState;
  successMessage?: NotificationSuccessMessage;
  form?: FormProps;
  onAction: (data?: CustomAny) => Promise<CustomAny>;
  onHide?: () => void;
}

const ActionModal: React.FC<Props> = props => {
  const { onAction, onHide } = props;

  const cn = useClassName(ActionModalBlock.Root, props.className);

  const dispatch = useDispatch();

  const [show, setShow] = props.showState;
  const [isLoading, setIsLoading] = useState(false);

  const onModalAction = useCallback(
    async (data?) => {
      await onAction(data);
      setShow(false);
    },
    [onAction, setShow],
  );

  const onActionClick = useCallback(async () => {
    setIsLoading(true);

    dispatch(
      handleAction(
        async () => {
          return onModalAction();
        },
        {
          message: props.successMessage,
          onFinally: () => {
            setIsLoading(false);
          },
        },
      ),
    );
  }, [onModalAction, setIsLoading, props.successMessage, dispatch]);

  const onModalHide = useCallback(() => {
    setShow(false);
    onHide?.();
  }, [setShow, onHide]);

  const onModalSubmit = useCallback(
    async data => {
      return onModalAction(data);
    },
    [onModalAction],
  );

  const content = useMemo(
    () => (
      <>
        <div className={cn(ActionModalElement.Body)}>{props.body}</div>

        <ButtonsList className={cn(ActionModalElement.Actions)}>
          {props.form ? (
            <ButtonSubmit submittedMessage="" isSubmitted={false} message={props.action} size={ControlSize.Small} />
          ) : (
            <Button
              className={cn(ActionModalElement.Submit)}
              colorScheme={ColorScheme.Primary}
              block
              size={ControlSize.Small}
              onClick={onActionClick}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              {props.action}
            </Button>
          )}

          <Button
            className={cn(ActionModalElement.Cancel)}
            colorScheme={ColorScheme.Primary}
            type={ButtonType.Button}
            block
            size={ControlSize.Small}
            isOutline
            onClick={onModalHide}
            isDisabled={isLoading}
          >
            Cancel
          </Button>
        </ButtonsList>
      </>
    ),
    [cn, isLoading, onActionClick, onModalHide, props.action, props.body, props.form],
  );

  return (
    <Modal
      {...props}
      className={cn()}
      size={ModalSize.Small}
      header={props.header}
      body={
        props.form ? (
          <Form {...props.form} onSubmit={onModalSubmit}>
            {content}
          </Form>
        ) : (
          <>{content}</>
        )
      }
      show={show}
      isCenter
      onHide={onModalHide}
    />
  );
};

export default ActionModal;
