import React, { Component } from 'react';
import { GridCellFormatterCommonProps, GridCellFormatterFavouriteProps } from '../../../interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from './CustomCellFormatterWrapper';
import Favourite from '../../../../../favourites/Favourite';
import { ViewColumnSystemName } from '../../../../../../entities/view/components/view-grid/enums/ViewColumnSystemName';

interface Props extends GridCellFormatterFavouriteProps, GridCellFormatterCommonProps {}

class FavouriteCellFormatter extends Component<Props> {
  render() {
    return (
      <CustomCellFormatterWrapper value={this.props.value}>
        <Favourite
          companyKey={this.props.data[ViewColumnSystemName.CompanyKey]}
          companyName={this.props.data[ViewColumnSystemName.CompanyTicker]}
        />
      </CustomCellFormatterWrapper>
    );
  }
}

export default FavouriteCellFormatter;
