import React, { memo, ReactNode, useCallback, useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import Label from '../../Label';
import Error from '../../Error';
import { ControlSize } from '../../../../enums/ControlSize';
import { CustomAny } from '../../../../types/generics';
import './TextSelect.scss';
import { ControlDivider } from '../../services/control-divider/enums/ControlDivider';
import { SelectOption } from '../../../../services/select/interfaces/SelectOption';
import { SelectOptionValue } from '../../../../services/select/types/SelectOptionValue';
import { useClassName } from '../../../../hooks/useClassName';
import { TextSelectBlock, TextSelectElement, TextSelectModifier } from './TextSelectBem';
import { DictionaryControlCommonProps } from '../../interfaces/DictionaryControlCommonProps';
import { TextSelectElementFactory } from './factories/TextSelectElementFactory';
import { TextSelectElementType } from './enums/TextSelectElementType';
import { TextSelectElementClassNameFactory } from './factories/TextSelectElementClassNameFactory';
import { ColorScheme } from '../../../../enums/ColorScheme';
import { ControlDividerFactory } from '../../services/control-divider/ControlDividerFactory';
import { SelectService } from '../../../../services/select/SelectService';

export interface TextSelectCommonProps extends DictionaryControlCommonProps {
  className?: string;
  divider?: ControlDivider;
  label?: string | ReactNode;
  elementType?: TextSelectElementType;
  isDisabled?: boolean;
  size?: ControlSize;
  isUnselectAvailable?: boolean;
}

export interface TextSelectProps extends TextSelectCommonProps {
  value: SelectOptionValue | undefined;
  error?: FieldError | undefined;
  onChangeValue: (value: CustomAny) => void;
}

const TextSelect: React.FC<TextSelectProps> = props => {
  const cn = useClassName(TextSelectBlock.Root, props.className);

  const {
    onChangeValue,
    value,
    divider = ControlDivider.Comma,
    elementType = TextSelectElementType.Text,
    isUnselectAvailable,
  } = props;

  const options = useMemo(() => SelectService.getOptionsByType(props.options), [props.options]);

  const dividerComponent = useMemo(() => ControlDividerFactory.getComponent(divider), [divider]);

  const isLastOptions = (index: number): boolean => options.length === index + 1;

  const onOptionClick = useCallback(
    (option: SelectOption) => {
      if (value !== option.value) {
        onChangeValue(option.value);
      } else if (isUnselectAvailable) {
        onChangeValue(null);
      }
    },
    [onChangeValue, value, isUnselectAvailable],
  );

  const isElementTypeText = useMemo(() => elementType === TextSelectElementType.Text, [elementType]);

  const getIsSelected = useCallback((option: SelectOption) => option.value === value, [value]);

  // converts HTML entities to corresponding character. Ex: converts: &yen; => ¥
  function normalize(html: string) {
    const doc = document.createElement('span');
    doc.innerHTML = html;
    return doc.innerHTML;
  }

  const getButtonProps = useCallback(
    (option: SelectOption) => ({
      disabled: props.isDisabled,
      className: TextSelectElementClassNameFactory.getElementClassName(elementType, cn.bind(TextSelect)),
      children: <>{normalize(option.title)}</>,
      onClick: (): void => onOptionClick(option),
    }),
    [cn, props.isDisabled, onOptionClick, elementType],
  );

  const getOptionElement = useCallback(
    (option: SelectOption) =>
      TextSelectElementFactory.getElement(elementType, getButtonProps(option), getIsSelected(option)),
    [elementType, getButtonProps, getIsSelected],
  );

  return (
    <div
      className={cn({
        [`${props.size}`]: props.size,
        [elementType]: elementType,
      })}
    >
      {props.label && (
        <Label error={props.error}>
          {props.label}
          <span className={cn(TextSelectElement.Postfix)}>:</span>
        </Label>
      )}

      <ul className={cn(TextSelectElement.List)}>
        {options.map((option: SelectOption, index: number) => (
          <li
            className={cn(TextSelectElement.Item, {
              [ColorScheme.Primary]: ColorScheme.Primary,
              [`${option.value}`]: option.value,
              [TextSelectModifier.Selected]: getIsSelected(option),
            })}
            key={index}
          >
            {getOptionElement(option)}

            {isElementTypeText && !isLastOptions(index) && (
              <span
                className={cn(TextSelectElement.Divider, {
                  [divider]: divider,
                })}
              >
                {dividerComponent}
              </span>
            )}
          </li>
        ))}
      </ul>

      {props.error && <Error>{props.error}</Error>}
    </div>
  );
};

export default memo(TextSelect);
