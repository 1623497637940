import React, { useContext, useMemo } from 'react';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import { Link } from 'react-router-dom';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import { CompanyTabsPath } from '../../../../../enums/CompanyTabsPath';
import { CompanyLocationService } from '../../../../../services/CompanyLocationService';
import CompanyProfileSidebarTable from '../company-profile-table/CompanyProfileSidebarTable';
import { GridAgColumn } from '../../../../../../shared/components/grid/grid/interfaces/GridAgColumn';
import { ViewColumnSystemName } from '../../../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { ViewGridColumnWidth } from '../../../../../../shared/entities/view/components/view-grid/enums/ViewGridColumnWidth';

const CUSTOM_COLUMNS_SETTINGS: Partial<GridAgColumn>[] = [
  {
    field: ViewColumnSystemName.Name,
    minWidth: ViewGridColumnWidth.Sm,
    width: ViewGridColumnWidth.Sm,
  },
  {
    field: ViewColumnSystemName.Actual,
    minWidth: ViewGridColumnWidth.Sm,
    width: ViewGridColumnWidth.Sm,
  },
];

const CompanyBalanceSheet: React.FC = () => {
  const { getTabData, ticker } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const data = useMemo(() => profile.balanceSheet, [profile]);
  const linkPath = useMemo(() => CompanyLocationService.getRelativeTabPath(CompanyTabsPath.Financials, ticker), [
    ticker,
  ]);

  return (
    <>
      {data && (
        <CompanyProfileSideItem title={<Link to={linkPath}>Balance Sheet</Link>}>
          <CompanyProfileSidebarTable
            {...data}
            expandedData={data.rows}
            customColumnsSettings={CUSTOM_COLUMNS_SETTINGS}
          />
        </CompanyProfileSideItem>
      )}
    </>
  );
};

export default CompanyBalanceSheet;
