import { ViewType } from '../enums/ViewType';
import { RoutePath } from '../../../../../enums/RoutePath';
import { ViewQueryParam } from '../enums/ViewQueryParam';
import { Url } from '../../../../../types';
import LocationService from '../../../../../services/LocationService';
import { ViewId } from '../types/ViewId';
import { ViewIdType } from '../enums/ViewIdType';
import { LocationSearchData } from '../../../../../interfaces/LocationSearchData';
import { viewTypeRoutes } from '../constants/viewTypeRoutes';

export class ViewLocationService {
  static updateViewUrl(viewId: ViewId): void {
    LocationService.updateQueryParams({
      [ViewQueryParam.ViewId]: viewId.toString(),
    });
  }

  static navigateToView(viewType: ViewType, viewId?: ViewId): void {
    LocationService.redirect(viewTypeRoutes[viewType], this.getSearchData(viewId));
  }

  static shouldUpdateByLocationChange(newViewId: ViewId, currentViewId: ViewId, isViewDataInit: boolean): boolean {
    return newViewId !== currentViewId && isViewDataInit;
  }

  static getRoutePath(viewType: ViewType, viewId?: ViewId): RoutePath {
    return LocationService.getRelativePath(viewTypeRoutes[viewType], this.getSearchData(viewId)) as RoutePath;
  }

  static getUrl(viewType: ViewType, viewId?: ViewId): Url {
    return LocationService.getUrl(this.getRoutePath(viewType, viewId));
  }

  static getViewIdFromQueryParams(viewId: string | null): ViewId {
    return viewId ? parseInt(viewId) : ViewIdType.DefaultView;
  }

  static getSearchData(viewId?: ViewId): LocationSearchData {
    const searchData: LocationSearchData = {};

    if (viewId && viewId !== ViewIdType.DefaultView) {
      searchData[ViewQueryParam.ViewId] = viewId.toString();
    }

    return searchData;
  }
}
