import React, { ReactNode } from 'react';
import './Feature.scss';
import RequestTrialForm from './request-trial-form/RequestTrialForm';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import PrimarySectionRow from '../../shared/components/primary-section/PrimarySectionRow';
import { PrimarySectionIndent } from '../../shared/components/primary-section/PrimarySectionIndent';
import { OptimizedPictureType } from './optimized-picture/enums/OptimizedPictureType';
import OptimizedPicture from './optimized-picture/OptimizedPicture';

interface Props {
  header: string | ReactNode;
  optimizedPictureType: OptimizedPictureType;
}

const FeatureNoTrialButton: React.FC<Props> = props => {
  return (
    <div className="Feature">
      <PrimarySection header={props.header} indent={PrimarySectionIndent.Xxl}>
        <PrimarySectionRow>
          <div className="Feature__description">{props.children}</div>
        </PrimarySectionRow>

        <div className="Feature__overflow" />

        <OptimizedPicture type={props.optimizedPictureType} />
      </PrimarySection>
    </div>
  );
};

export default FeatureNoTrialButton;
