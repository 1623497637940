import React, { useContext, useMemo } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import CompanyProfileSideItem from '../../profile/shared/components/CompanyProfileSideItem';
import CompanyProfileTable from '../../profile/shared/components/company-profile-table/CompanyProfileTable';
import { Link } from 'react-router-dom';
import { CompanyTabsPath } from '../../../enums/CompanyTabsPath';
import { ProfileData } from '../../../interfaces/ProfileData';
import { CompanyTab } from '../../../enums/CompanyTab';
import { CompanyLocationService } from '../../../services/CompanyLocationService';
import PageLoader from '../../../../shared/components/loader/PageLoader';

const CompanyTechnicalComparisonTable: React.FC = () => {
  const { getTabData, ticker, technicalComparisonData } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  // const data: SimpleGridProps = useMemo(() => profile.technicalComparison as SimpleGridProps, [profile]);

  const linkPath = useMemo(() => CompanyLocationService.getRelativeTabPath(CompanyTabsPath.PeerTrading, ticker), [
    ticker,
  ]);

  const data = useMemo(() => profile.description, [profile]);
  let title = 'Technical Comparison';
  if (data?.isPreliminaryComps) {
    title = 'Technical Comparison (Preliminary)';
  }

  return (
    <>
      <PageLoader
        loading={
          !!!technicalComparisonData ||
          technicalComparisonData?.rows[0]?.ticker?.toUpperCase() !== ticker?.toUpperCase()
        }
      >
        {technicalComparisonData && (
          <CompanyProfileSideItem title={<Link to={linkPath}>{title}</Link>}>
            <CompanyProfileTable
              columns={technicalComparisonData.columns}
              rows={technicalComparisonData.rows?.slice(0, 3)}
              expandedData={technicalComparisonData.rows?.slice(0, 3)}
              isFullWidth
              isColumnsAlignEnd
            />
          </CompanyProfileSideItem>
        )}
      </PageLoader>
    </>
  );
};

export default CompanyTechnicalComparisonTable;
