import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './DropdownNav.scss';
import { RoutePath } from '../../../enums/RoutePath';
import BackNavButton from './BackNavButton';
import { history } from '../../../history';
import LocationService from '../../../services/LocationService';
import { RouteService } from '../../../services/RouteService';
import DropdownItem from '../../dropdown/DropdownItem';
import DropdownButton from '../../dropdown/DropdownButton';
import { NavProps } from '../interfaces/NavProps';

const DropdownNav: React.FC<NavProps> = props => {
  const routesPaths = useMemo<RoutePath[]>(() => RouteService.getRoutePathsFromDescriptions(props.routesTitles), [
    props.routesTitles,
  ]);
  const initialRoute = useMemo<RoutePath>(() => routesPaths[0], [routesPaths]);
  const [activeKey, setActiveKey] = useState<RoutePath>(initialRoute);

  const onSelectItem = useCallback((key: string) => {
    setActiveKey(key as RoutePath);
    LocationService.redirect(key as RoutePath);
  }, []);

  useEffect(() => setActiveKey(history.location.pathname as RoutePath), [history.location.pathname]);

  return (
    <section className="DropdownNav">
      {props.backRoutePath && <BackNavButton routePath={props.backRoutePath}>{props.backRouteTitle}</BackNavButton>}

      <DropdownButton onSelect={onSelectItem} content={props.routesTitles[activeKey]}>
        {routesPaths.map((path: RoutePath, index: number) => (
          <DropdownItem key={index} active={LocationService.isCurrentPath(path)} eventKey={path}>
            {props.routesTitles[path]}
          </DropdownItem>
        ))}
      </DropdownButton>

      <div className="DropdownNav__content">{props.children}</div>
    </section>
  );
};

export default DropdownNav;
