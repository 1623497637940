import { useWindowScroll, useWindowSize } from 'react-use';
import { useMemo } from 'react';
import { CustomAny, Nullable } from '../types/generics';

export function useAbsoluteElementCenter(
  el?: Nullable<HTMLElement>,
  stateChange?: CustomAny,
): { top: number | string } {
  const { y: scrollY } = useWindowScroll();
  const { height: windowHeight } = useWindowSize();

  let top: number | string;

  if (!el) {
    top = 'inherit';
  } else {
    const elRect = el.getBoundingClientRect();
    const elHeight = el.offsetHeight;
    const elBottomRelative = elRect.top + elHeight;
    let elVisiblePartHeight;

    const isFitScreen = elHeight <= windowHeight;
    const isTopVisible = elRect.top >= 0;
    const isBottomVisible = elBottomRelative <= windowHeight;
    const isMiddleVisible = !isTopVisible && !isBottomVisible;

    if (isFitScreen) {
      top = '50%';
    } else if (isTopVisible) {
      elVisiblePartHeight = windowHeight - elRect.top;
      top = elVisiblePartHeight / 2;
    } else if (isMiddleVisible) {
      elVisiblePartHeight = windowHeight;
      top = elVisiblePartHeight / 2 - elRect.top;
    } else {
      elVisiblePartHeight = elHeight - elRect.top;
      top = elVisiblePartHeight / 2;
    }
  }

  // should update value on scroll change
  // eslint-disable-next-line
  return useMemo(() => ({ top }), [scrollY, el, stateChange]);
}
