import React from 'react';
import './EmailPreferencesFormSectionItem.scss';
import EmailPreferencesFormSectionPreference from './EmailPreferencesFormSectionPreference';
import FormAccordionCheckbox from '../../../shared/components/forms/FormAccordionCheckbox';
import { EmailPreferenceSectionItem } from '../interfaces/EmailPreferenceSectionItem';
import { EmailPreferenceSection } from '../interfaces/EmailPreferenceSection';

interface Props {
  item: EmailPreferenceSectionItem;
}

const EmailPreferencesFormSectionItem: React.FC<Props> = props => {
  return (
    <div className="EmailPreferencesFormSectionItem">
      <FormAccordionCheckbox title={props.item.title} controlNames={props.item.controls || []}>
        {props.item.preferences.map((preference: EmailPreferenceSection) => (
          <EmailPreferencesFormSectionPreference key={preference.name} preference={preference} />
        ))}
      </FormAccordionCheckbox>
    </div>
  );
};

export default EmailPreferencesFormSectionItem;
