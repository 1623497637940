import { merge } from 'lodash';
import { FilterSectionControl } from '../interfaces/FilterSectionControl';
import { FilterSection } from '../interfaces/FilterSection';
import { ViewFilterName } from '../enum/types/ViewFilterName';
import { FilterSectionType } from '../enum/FilterSectionType';
import { ArrayService } from '../../../services/ArrayService';
import { FormControlPartial } from '../../../components/forms/components/form-builder/types/formControlsTypes';

export class FilterSettingsService {
  static getControls(sections: FilterSection[]): FilterSectionControl[] {
    return sections.reduce(
      (controls: FilterSectionControl[], section: FilterSection) => controls.concat(section.controls),
      [],
    );
  }

  static getFiltersNames(sections: FilterSection[], excludeFilterNames: ViewFilterName[] = []): ViewFilterName[] {
    return this.getControls(sections)
      .map(filterSectionControl => filterSectionControl.name)
      .filter(filterName => !excludeFilterNames.includes(filterName));
  }

  static getFilterByName(sections: FilterSection[], name: ViewFilterName): FilterSectionControl | undefined {
    return this.getControls(sections).find(control => control.name === name);
  }

  static getSectionByType<T extends FilterSection>(sections: T[], type: FilterSectionType): T | undefined {
    return sections.find(section => section.type === type);
  }

  static getSectionTypes<T extends FilterSection>(sections: T[]): FilterSectionType[] {
    return sections.map(section => section.type);
  }

  static containFilters(sections: FilterSection[], filterNames: ViewFilterName[]): boolean {
    return ArrayService.containsSomeFromArray(this.getFiltersNames(sections), filterNames);
  }

  static mergeControlSettings(baseSettings: FormControlPartial, newSettings: FormControlPartial): FormControlPartial {
    return merge({}, baseSettings, newSettings);
  }
}
