export enum TooltipExpanderBlock {
  Root = 'TooltipExpander',
}

export enum TooltipExpanderElement {
  Content = 'content',
  Test = 'test',
}

export enum TooltipExpanderModifier {
  TooltipNotAvailable = 'tooltipNotAvailable',
}
