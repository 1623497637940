import React from 'react';
import './ViewGridToolbar.scss';
import { useClassName } from '../../../../../../hooks/useClassName';
import { ViewGridToolbarBlock } from './viewGridToolbarBem';
import { useLocation } from 'react-router-dom';
import LocationService from '../../../../../../services/LocationService';
import { RoutePath } from '../../../../../../enums/RoutePath';
import { earlyWrapPaths } from '../../../../../../constants/routePaths/earlyWrapViewGridToolBarPaths';

interface Props {
  className?: string;
}

const ViewGridToolbar: React.FC<Props> = props => {
  const cn = useClassName(ViewGridToolbarBlock.Root, props.className);
  const urlLocation = useLocation();
  const addPricedClass = LocationService.isCurrentPath(RoutePath.Priced) ? true : false;
  const addEarlyWrapClass = LocationService.hasCurrentPath(earlyWrapPaths, urlLocation) ? true : false;

  return (
    <div className={`${cn()}${addPricedClass ? ' priced' : ''}${addEarlyWrapClass ? ' early-wrap' : ''}`}>
      {props.children}
    </div>
  );
};

export default ViewGridToolbar;
