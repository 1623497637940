import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import Error from './Error';

interface Props {
  name: string;
}

const FormError: React.FC<Props> = ({ name }) => {
  const { errors } = useFormContext();
  const error: FieldError | undefined = errors[name] as FieldError;
  return error ? <Error>{error.message}</Error> : null;
};

export default FormError;
