export enum FileActionBlock {
  Root = 'FileAction',
  FileActionWrapper = 'FileActionWrapper',
}

export enum FileActionElement {
  Title = 'title',
  TitleContainer = 'titleContainer',
  Text = 'text',
  TitlePostfix = 'titlePostfix',
  Icon = 'icon',
}

export enum FileActionModifier {
  ReverseOrder = 'reverseOrder',
}
