import React from 'react';
import Button from '../../shared/components/forms/Button';
import Icon from '../../shared/components/icon/Icon';
import { IconName } from '../../shared/components/icon/IconName';
import './MasterCalendarToolbar.scss';
import MasterCalendarFilter from './MasterCalendarFilter';
import { ControlSize } from '../../shared/enums/ControlSize';
import PrintTrigger from '../../shared/components/print/PrintTrigger';
import { ColorScheme } from '../../shared/enums/ColorScheme';

interface Props {
  hasPrint?: boolean;
}

const MasterCalendarToolbar: React.FC<Props> = props => {
  return (
    <div className="MasterCalendarToolbar">
      <MasterCalendarFilter />

      {props.hasPrint && (
        <PrintTrigger
          trigger={
            <Button>
              <Icon name={IconName.Printing} size={ControlSize.Medium} colorScheme={ColorScheme.Primary} hasHover />
            </Button>
          }
        />
      )}
    </div>
  );
};

export default MasterCalendarToolbar;
