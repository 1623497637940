import { NewsLandingItemDto } from '../../interfaces/NewsLandingItemDto';
import { useMemo } from 'react';
import parseISO from 'date-fns/parseISO';
import startOfDay from 'date-fns/startOfDay';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { IsoDate } from '../../../shared/types';
import { DateHelper } from '../../../shared/helpers/date/DateHelper';
import { NewsLandingItemsGroup } from '../interfaces/NewsLandingItemsGroup';
import { DateFormat } from '../../../shared/helpers/date/enums/DateFormat';

const formatDate = (date: IsoDate): string => DateHelper.formatDate(startOfDay(parseISO(date)), DateFormat.MonthDayTh);

export function useGroupNewsLandingItems(items: NewsLandingItemDto[]): NewsLandingItemsGroup[] {
  return useMemo(
    () =>
      map(
        groupBy(items, item => formatDate(item.breakDate)),
        (items: NewsLandingItemDto[], date: string) => ({ items, date }),
      ),
    [items],
  );
}
