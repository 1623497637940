export enum SearchAutocompleteItemBlock {
  Root = 'SearchAutocompleteItem',
}

export enum SearchAutocompleteItemElement {
  Title = 'title',
  Date = 'date',
  Icon = 'icon',
  Column = 'column',
  Tag = 'tag',
  ExtraIcon = 'extra-icon',
}

export enum SearchAutocompleteItemModifier {
  Heading = 'heading',
  AdditionalInfo = 'additionalInfo',
}
