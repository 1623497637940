import React, { useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_SITE_ID } from '../../constants/environment';
import { useFormContext } from 'react-hook-form';
import FormError from './FormError';

interface CaptchaProps {
  name: string;
}

const FormCaptcha: React.FC<CaptchaProps> = ({ name }) => {
  const { setValue, register } = useFormContext();
  const onChange = useCallback(
    (token: string | null) => {
      register({
        name,
      });

      token === null ? setValue(name, undefined, true) : setValue(name, token, true);
    },
    [name, setValue, register],
  );

  return (
    <div className={`Captcha Captcha--${name}`}>
      <ReCAPTCHA sitekey={RECAPTCHA_SITE_ID} onChange={onChange} />
      <FormError name={name} />
    </div>
  );
};

export default FormCaptcha;
