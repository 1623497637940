import { SimpleGridProps } from '../../shared/components/grid/simple-grid/SimpleGrid';
import { tableData } from './tableData';
import { ControlSize } from '../../shared/enums/ControlSize';

const MEDIUM_WIDTH = 10;
const MEDIUM_HEIGHT = 10;

const SMALL_WIDTH = 14;
const SMALL_HEIGHT = MEDIUM_HEIGHT;

export enum CustomTableColorScheme {
  Black = 'black',
  Primary = 'primary',
  BlackHeaderPrimaryBody = 'blackHeaderPrimaryBody',
  PrimaryHeaderBlackBody = 'primaryHeaderBlackBody',
}

export interface CustomSimpleGridProps extends SimpleGridProps {
  color: CustomTableColorScheme;
}

export const tablesData: CustomSimpleGridProps[] = [
  {
    title: 'Small Dark Blue Table',
    color: CustomTableColorScheme.Primary,
    size: ControlSize.Small,
    ...tableData(SMALL_WIDTH, SMALL_HEIGHT),
  },
  {
    title: 'Large Dark Blue Table',
    color: CustomTableColorScheme.Primary,
    size: ControlSize.Medium,
    ...tableData(MEDIUM_WIDTH, MEDIUM_HEIGHT),
  },
  {
    title: 'Small Black Table',
    color: CustomTableColorScheme.Black,
    size: ControlSize.Small,
    ...tableData(SMALL_WIDTH, SMALL_HEIGHT),
  },
  {
    title: 'Large Black Table',
    color: CustomTableColorScheme.Black,
    size: ControlSize.Medium,
    ...tableData(MEDIUM_WIDTH, MEDIUM_HEIGHT),
  },
  {
    title: 'Small Black(Header)-Dark Blue(Body) Table',
    color: CustomTableColorScheme.BlackHeaderPrimaryBody,
    size: ControlSize.Small,
    ...tableData(SMALL_WIDTH, SMALL_HEIGHT),
  },
  {
    title: 'Large Black(Header)-Dark Blue(Body) Table',
    color: CustomTableColorScheme.BlackHeaderPrimaryBody,
    size: ControlSize.Medium,
    ...tableData(MEDIUM_WIDTH, MEDIUM_HEIGHT),
  },
  {
    title: 'Small Dark Blue(Header)-Black(Body) Table',
    color: CustomTableColorScheme.PrimaryHeaderBlackBody,
    size: ControlSize.Small,
    ...tableData(SMALL_WIDTH, SMALL_HEIGHT),
  },
  {
    title: 'Large Dark Blue(Header)-Black(Body) Table',
    color: CustomTableColorScheme.PrimaryHeaderBlackBody,
    size: ControlSize.Medium,
    ...tableData(MEDIUM_WIDTH, MEDIUM_HEIGHT),
  },
];
