import React, { useMemo } from 'react';
import CompanyTooltip from '../../../../../../../company/components/company-tooltip/CompanyTooltip';
import './CompanyWithDescriptionCell.scss';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';
import { useClassName } from '../../../../../../hooks/useClassName';
import { useExpand } from '../../../../../../hooks/expand/useExpand';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import { CompanyWithDescriptionColumn } from '../../interfaces/column-values/CompanyWithDescriptionColumn';
import { CompanyWithDescriptionCellBlock, CompanyWithDescriptionCellElement } from './CompanyWithDescriptionCellBem';

interface Props extends GridCellFormatterCommonProps {
  hasFullBusinessDescription?: boolean;
}

const DESCRIPTION_LENGTH = 155;

const CompanyWithDescriptionCell: React.FC<Props> = props => {
  const cn = useClassName(CompanyWithDescriptionCellBlock.Root);

  const value = props.data[ViewColumnSystemName.CompanyWithDescription] as CompanyWithDescriptionColumn;
  const { expandedData: descriptionShort } = useExpand(value.description, DESCRIPTION_LENGTH);
  const noteNumber = props.data[ViewColumnSystemName.NoteNumber];

  const description = useMemo(() => (props.hasFullBusinessDescription ? value.description : descriptionShort), [
    props.hasFullBusinessDescription,
    value.description,
    descriptionShort,
  ]);

  return (
    <CustomCellFormatterWrapper className={cn()} value={value}>
      <CompanyTooltip
        name={value.companyTicker}
        ticker={props.data[ViewColumnSystemName.Ticker]}
        reference={noteNumber}
      />

      <div className={cn(CompanyWithDescriptionCellElement.Description)}>{description}</div>
    </CustomCellFormatterWrapper>
  );
};

export default CompanyWithDescriptionCell;
