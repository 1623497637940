import React from 'react';
import './MVCell.scss';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { useClassName } from '../../../../../../hooks/useClassName';
import { MVCellBlock } from './MVCellBem';
import Tooltip from '../../../../../../components/tooltip/Tooltip';

type Props = GridCellFormatterCommonProps;

const MVCell: React.FC<Props> = props => {
  const cn = useClassName(MVCellBlock.Root);

  const MVSource = props.data.mvSource;

  return (
    <CustomCellFormatterWrapper className={cn()} value={props.data.MV}>
      {MVSource ? (
        <Tooltip
          className={cn()}
          hasTitleLinkStyle
          content={
            <span className={cn('source')}>
              <b>Source:</b> {props.data.mvSource}
            </span>
          }
          title={props.data.MV}
        />
      ) : (
        props.data.MV
      )}
    </CustomCellFormatterWrapper>
  );
};

export default MVCell;
