import React from 'react';
import './ViewColumnsAddRow.scss';
import { useClassName } from '../../../../../../../../../hooks/useClassName';
import { ViewColumnsAddRowBlock, ViewColumnsAddRowElement } from './viewColumnsAddRowBem';

interface Props {
  title?: string;
  content: React.ReactNode;
}

const ViewColumnsAddRow: React.FC<Props> = props => {
  const cn = useClassName(ViewColumnsAddRowBlock.Root);

  return (
    <section className={cn()}>
      {props.title && <h3 className={cn(ViewColumnsAddRowElement.Title)}>{props.title}</h3>}
      <div className={cn(ViewColumnsAddRowElement.Content)}>{props.content}</div>
    </section>
  );
};

export default ViewColumnsAddRow;
