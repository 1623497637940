export enum DashboardWidgetType {
  Calendar = 'Calendar',
  News = 'NewsAndCommentary',
  Priced = 'Priced',
  UpcomingIPOs = 'UpcomingIPOs',
  RecentResearch = 'RecentResearch',
  MostViewedIPOs = 'MostViewedIPOs',
  NewFilings = 'NewFilings',
  IposOnMove = 'IPOsOnMove',
  MyFavourites = 'MyFavourites',
  IPOPoll = 'IPOPoll',
  RenaissanceUSIPOIndex = 'RenaissanceUSIPOIndex',
  USIPOMarketIndex = 'USIPOMarketIndex',
  MyViews = 'MyViews',
  MyScreens = 'MyScreens',
  SpecialReports = 'SpecialReports',
}
