import React from 'react';
import NewsOpenableFilter from '../components/NewsOpenableFilter';
import NewsLandingRecap from './components/NewsLandingRecap';
import NewsLandingSections from './components/NewsLandingSections';
import NewsLandingMost from './components/NewsLandingMost';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import PrimarySectionRow from '../../shared/components/primary-section/PrimarySectionRow';
import './NewsLanding.scss';
import Helmet from 'react-helmet';
import { PrimarySectionIndent } from '../../shared/components/primary-section/PrimarySectionIndent';

const NewsLanding: React.FC = () => {
  return (
    <div className="NewsLanding">
      <Helmet>
        <title>IPO News</title>
      </Helmet>

      <PrimarySection header="IPO News" indent={PrimarySectionIndent.Internal}>
        <PrimarySectionRow>
          <NewsOpenableFilter />
        </PrimarySectionRow>

        <PrimarySectionRow>
          <NewsLandingRecap />
        </PrimarySectionRow>

        <PrimarySectionRow>
          <NewsLandingSections />
        </PrimarySectionRow>

        <PrimarySectionRow>
          <NewsLandingMost />
        </PrimarySectionRow>
      </PrimarySection>
    </div>
  );
};

export default NewsLanding;
