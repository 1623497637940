import React, { memo, useCallback, useMemo } from 'react';
import Button, { ButtonProps } from './Button';
import { useClassName } from '../../hooks/useClassName';
import { ButtonCheckboxBlock } from './ButtonCheckboxBem';
import { ControlCommonProps } from './interfaces/ControlCommonProps';
import { ColorScheme } from '../../enums/ColorScheme';

export type ButtonCheckboxCommonProps = ButtonProps;

interface Props extends ControlCommonProps<boolean>, ButtonCheckboxCommonProps {}

const ButtonCheckbox: React.FC<Props> = props => {
  const { onChangeValue } = props;
  const cn = useClassName(ButtonCheckboxBlock.Root, props.className);

  const btnProps = useMemo<ButtonProps>(
    () =>
      props.value
        ? {
            colorScheme: ColorScheme.Info,
          }
        : {
            colorScheme: ColorScheme.Secondary,
            isOutline: true,
          },
    [props.value],
  );

  const onClick = useCallback(() => onChangeValue(!props.value), [onChangeValue, props.value]);

  return (
    <Button {...props} {...btnProps} className={cn({ [props.name]: props.name })} onClick={onClick}>
      {props.label}
    </Button>
  );
};

export default memo(ButtonCheckbox);
