import React, { useEffect, useState } from 'react';
import './GoogleAnalytics.scss';
import Helmet from 'react-helmet';
import PrimarySection from '../shared/components/primary-section/PrimarySection';
import BasicGrid from '../shared/components/basic-grid/BasicGrid';
import { GoogleAnalyticsApiService } from '../shared/api/GoogleAnalyticsApiService';
import { useParams } from 'react-router-dom';
import SecondarySection from '../shared/components/secondary-section/SecondarySection';
import { RoutePath } from '../shared/enums/RoutePath';
import { Link } from 'react-router-dom';
import GaBuilders from './builders/GaBuilders';
import ExhaustedResources from './ExhaustedResources';

const GoogleAnalytics: React.FC = () => {
  // States
  const [activityPerAccountData, setActivityPerAccountData] = useState<any>(null);
  const [downloadsPerAccountData, setDownloadsPerAccountData] = useState<any>(null);
  const [accountHeaderData, setAccountHeaderData] = useState<any>(null);
  const [pageViewData, setPageViewData] = useState<any>(null);

  // Landing Page data States
  const [activePageData, setActivePageData] = useState<any>(null);
  const [activePdfData, setActivePdfData] = useState<any>(null);
  const [activeExcelData, setActiveExcelData] = useState<any>(null);
  const [activeAccountData, setActiveAccountData] = useState<any>(null);
  const [downloadsByAccountData, setDownloadsByAccountData] = useState<any>(null);
  const [activeUserData, setActiveUserData] = useState<any>(null);

  // User Activity data States
  const [userActivityPagesData, setUserActivityPagesData] = useState<any>(null);
  const [userActivityFilesData, setUserActivityFilesData] = useState<any>(null);
  const [userActivityTechData, setUserActivityTechData] = useState<any>(null);
  const [userActivityHeaderData, setUserActivityHeaderData] = useState<any>(null);

  // User Activity data States
  const [resourceActivityData, setResourceActivityData] = useState<any>(null);
  const [resourceActivityHeaderData, setResourceActivityHeaderData] = useState<any>(null);

  // Is a grid loading?
  const [landingLoadingGrid, setLandingLoadingGrid] = useState<any>(false);
  const [userLoadingGrid, setUserLoadingGrid] = useState<any>(false);
  const [activityLoadingGrid, setActivityLoadingGrid] = useState<any>(false);
  const [pageviewLoadingGrid, setPageviewLoadingGrid] = useState<any>(false);
  const [resourceLoadingGrid, setResourceLoadingGrid] = useState<any>(false);

  const [isExhausted, setExhausted] = useState<any>(false);

  // Used to grab the active URL route
  const { pageUrl } = useParams<any>();

  // Variable States, holds data sent in API req, default 7 days
  const [startDate, setStartDate] = useState<any>(() => {
    const today = new Date();
    today.setDate(today.getDate() - 7);
    return today.toISOString().substring(0, 10);
  });
  const [endDate, setEndDate] = useState<any>(() => {
    const today = new Date();
    return today.toISOString().substring(0, 10);
  });

  // Date range check for rerender on button press
  const [drCheck, setDrCheck] = useState<any>(null);

  // API Request for Page Views by URL Table
  async function getPageUrlDataHelper() {
    setPageviewLoadingGrid(true);
    const logs = await GoogleAnalyticsApiService.getResourceActivityPageData('PagePath', pageUrl, startDate, endDate);
    if (typeof logs === 'string') {
      setLandingLoadingGrid(false);
      if (logs.includes('ResourceExhausted')) {
        setExhausted(true);
      }
    } else {
      setExhausted(false);
      const builtResourceActivityPageData: any = GaBuilders.buildPageResourceActivityData(logs);
      setPageViewData(builtResourceActivityPageData);
      setPageviewLoadingGrid(false);
    }
  }

  // API Request for Individual Users Data Page Tables
  async function getUserIdDataHelper() {
    const pageViews = await GoogleAnalyticsApiService.getUserActivityData(pageUrl.split('=')[1], startDate, endDate);
    if (typeof pageViews === 'string') {
      setLandingLoadingGrid(false);
      if (pageViews.includes('ResourceExhausted')) {
        setExhausted(true);
      }
    } else {
      setExhausted(false);
      const builtAnalyticsDataPages: any = GaBuilders.buildUserActivityDataPages(pageViews);
      const builtAnalyticsDataFiles: any = GaBuilders.buildUserActivityDataFiles(pageViews);
      const builtAnalyticsDataTech: any = GaBuilders.buildUserActivityDataTech(pageViews);
      const builtAnalyticsHeaderData: any = GaBuilders.buildUserActivityHeaderData(pageViews);
      setUserActivityPagesData(builtAnalyticsDataPages);
      setUserActivityFilesData(builtAnalyticsDataFiles);
      setUserActivityTechData(builtAnalyticsDataTech);
      setUserActivityHeaderData(builtAnalyticsHeaderData);
    }
  }

  // API request for Activity by Account
  async function getAccountIdDataHelper() {
    setActivityLoadingGrid(true);
    const pageViews = await GoogleAnalyticsApiService.getActivityPerAccount(pageUrl, startDate, endDate);
    if (typeof pageViews === 'string') {
      setLandingLoadingGrid(false);
      if (pageViews.includes('ResourceExhausted')) {
        setExhausted(true);
      }
    } else {
      setExhausted(false);
      const builtActivityData: any = GaBuilders.buildActivityPerAccountData(pageViews);
      const builtDownloadsData: any = GaBuilders.buildDownloadsPerAccountData(pageViews);
      const builtAccountHeaderData: any = GaBuilders.buildAccNameHeaderData(pageViews);
      setActivityPerAccountData(builtActivityData);
      setDownloadsPerAccountData(builtDownloadsData);
      setAccountHeaderData(builtAccountHeaderData);
      setActivityLoadingGrid(false);
    }
  }

  // API request for Landing page Data
  async function getLandingDataHelper(reportName: any) {
    let builtActivePageData: any;
    let builtPdfDownloadsData: any;
    let builtExcelDownloadsData: any;
    let builtActiveAccountsData: any;
    let builtDownloadsByAccountData: any;
    let builtActiveUsersData: any;
    setLandingLoadingGrid(true);
    const logs = await GoogleAnalyticsApiService.getLandingData(startDate, endDate, reportName);
    if (typeof logs === 'string') {
      setLandingLoadingGrid(false);
      if (logs.includes('ResourceExhausted')) {
        setExhausted(true);
      }
    } else {
      setExhausted(false);
      switch (reportName) {
        case 'MostActiveDownloads':
          builtPdfDownloadsData = GaBuilders.buildActivePdfData(logs);
          builtExcelDownloadsData = GaBuilders.buildActiveExcelData(logs);
          setActivePdfData(builtPdfDownloadsData);
          setActiveExcelData(builtExcelDownloadsData);
          break;
        case 'MostActivePages':
          builtActivePageData = GaBuilders.buildActivePagesData(logs);
          setActivePageData(builtActivePageData);
          break;
        case 'MostActiveUsers':
          builtActiveUsersData = GaBuilders.buildActiveUsersData(logs);
          setActiveUserData(builtActiveUsersData);
          break;
        default:
          builtActivePageData = GaBuilders.buildActivePagesData(logs);
          builtPdfDownloadsData = GaBuilders.buildActivePdfData(logs);
          builtExcelDownloadsData = GaBuilders.buildActiveExcelData(logs);
          builtActiveAccountsData = GaBuilders.buildActiveAccountsData(logs);
          builtDownloadsByAccountData = GaBuilders.buildDownloadsByAccountData(logs);
          builtActiveUsersData = GaBuilders.buildActiveUsersData(logs);
          setActivePageData(builtActivePageData);
          setActivePdfData(builtPdfDownloadsData);
          setActiveExcelData(builtExcelDownloadsData);
          setActiveAccountData(builtActiveAccountsData);
          setDownloadsByAccountData(builtDownloadsByAccountData);
          setActiveUserData(builtActiveUsersData);
          break;
      }
    }
    setLandingLoadingGrid(false);
  }

  async function getResourceDataHelper() {
    setResourceLoadingGrid(true);
    const logs: any = await GoogleAnalyticsApiService.getResourceActivityData('FileName', pageUrl, startDate, endDate);
    if (typeof logs === 'string') {
      setLandingLoadingGrid(false);
      if (logs.includes('ResourceExhausted')) {
        setExhausted(true);
      }
    } else {
      setExhausted(false);
      const builtResourceActivityData: any = GaBuilders.buildFileResourceActivityData(logs);
      const builtResourceHeaderData: any = GaBuilders.buildFileResourceHeaderData(logs);
      setResourceActivityHeaderData(builtResourceHeaderData);
      setResourceActivityData(builtResourceActivityData);
    }
    setResourceLoadingGrid(false);
  }

  function setDates(e: any) {
    const days = Number(e.target.value);
    const prevDate = new Date();
    const currDate = prevDate.toISOString().split('T')[0];
    prevDate.setDate(prevDate.getDate() - days);
    setStartDate(prevDate.toISOString().split('T')[0]);
    setEndDate(currDate);
    setDrCheck(e.target.value);
  }

  function getData() {
    if (startDate <= endDate) {
      if (pageUrl) {
        switch (pageUrl.split('=')[0]) {
          case 'pageUrl':
            getPageUrlDataHelper();
            getLandingDataHelper('MostActivePages');
            break;

          case 'userId':
            getUserIdDataHelper();
            getLandingDataHelper('MostActiveUsers');
            break;

          case 'accountId':
            getAccountIdDataHelper();
            break;

          case 'fileName':
            getResourceDataHelper();
            getLandingDataHelper('MostActiveDownloads');
            break;

          default:
            break;
        }
      } else {
        getLandingDataHelper('');
      }
    } else {
      alert('Please enter a Start Date that is before the End Date.');
    }
  }

  function onStartDateChange(e: any) {
    setStartDate(e.target.value);
  }
  function onEndDateChange(e: any) {
    setEndDate(e.target.value);
  }

  const dateFilters = (
    <>
      <div className="dateFilter">
        <label>Date (From/To):&nbsp;</label>
        <input value={startDate} onChange={onStartDateChange} type="date" />
        &nbsp;
        <input value={endDate} onChange={onEndDateChange} type="date" />
        &nbsp;
        <button className="gaButtons" type="button" onClick={getData}>
          Get Data
        </button>
      </div>
    </>
  );

  const dateRanges = (
    <>
      <div>
        <button className="gaButtons" type="button" onClick={setDates} value="7">
          1 Week
        </button>
        <button className="gaButtons" type="button" onClick={setDates} value="30">
          1 Month
        </button>
        <button className="gaButtons" type="button" onClick={setDates} value="90">
          3 Months
        </button>
        <button className="gaButtons" type="button" onClick={setDates} value="180">
          6 Months
        </button>
        <button className="gaButtons" type="button" onClick={setDates} value="365">
          1 Year
        </button>
      </div>
    </>
  );

  // Chooses which API function to run based on URL
  useEffect(() => {
    getData();
  }, [pageUrl, drCheck]);

  let GaView;

  if (pageUrl) {
    GaView = pageUrl.split('=')[0];
  }

  // Switches the Page View based on the Page URL
  switch (GaView) {
    case 'pageUrl':
      return isExhausted ? (
        <ExhaustedResources />
      ) : (
        <>
          <Helmet>
            <title>Google Analytics</title>
          </Helmet>

          <PrimarySection
            header="Google Analytics"
            isLoading={!!!activePageData ? pageviewLoadingGrid : false}
            className="analytics-container"
          >
            <>
              {/** Adds breadcrumbs to return to GA home page */}
              <Link to={RoutePath.GoogleAnalytics}>
                <strong>&lt;- Return to Google Analytics Home</strong>
              </Link>

              {dateFilters}
              {dateRanges}

              <PrimarySection className="analytics-row">
                <>
                  {/** Landing Table 1 */}
                  <SecondarySection title="Page Views by URL" className="analytics-column">
                    <BasicGrid loadingGrid={landingLoadingGrid} dataTable={activePageData} hasSearchBox={true} />
                  </SecondarySection>

                  {/** Linked from Page Views by URL Table */}
                  <SecondarySection
                    title={
                      pageViewData?.columns.length !== 0 ? (
                        `Events on - ${pageUrl.split('=')[1].replace(/\*/g, '/')}`
                      ) : (
                        <strong style={{ color: 'red' }}>
                          Page - {pageUrl.split('=')[1].replace(/\*/g, '/')} - does not exist{' '}
                        </strong>
                      )
                    }
                    className="analytics-column"
                  >
                    <BasicGrid loadingGrid={pageviewLoadingGrid} dataTable={pageViewData} hasSearchBox={true} />
                  </SecondarySection>
                </>
              </PrimarySection>
            </>
          </PrimarySection>
        </>
      );

    case 'accountId':
      return isExhausted ? (
        <ExhaustedResources />
      ) : (
        <>
          <Helmet>
            <title>Google Analytics</title>
          </Helmet>

          <PrimarySection
            header="Google Analytics"
            isLoading={!!!activityPerAccountData ? activityLoadingGrid : false}
            className="analytics-container"
          >
            <>
              {/** Adds breadcrumbs to return to GA home page */}
              <Link to={RoutePath.GoogleAnalytics}>
                <strong>&lt;- Return to Google Analytics Home</strong>
              </Link>
              {dateFilters}
              {dateRanges}
              <br /> <br />
              <p>
                <strong>
                  Account Name:{' '}
                  {accountHeaderData?.accName ? (
                    accountHeaderData?.accName
                  ) : (
                    <strong style={{ color: 'red' }}> Not Found (Invalid Account ID) </strong>
                  )}
                </strong>
              </p>
              <PrimarySection className="analytics-row">
                <>
                  {/** Linked from Most Active Accounts Table */}
                  <SecondarySection title={'Most Active Users'}>
                    <BasicGrid
                      loadingGrid={activityLoadingGrid}
                      dataTable={activityPerAccountData}
                      hasSearchBox={true}
                    />
                  </SecondarySection>
                </>
              </PrimarySection>
              <PrimarySection className="analytics-row">
                {/** Linked from Downloads By Account Table */}
                <SecondarySection title={'Account Downloads'}>
                  <BasicGrid
                    loadingGrid={activityLoadingGrid}
                    dataTable={downloadsPerAccountData}
                    hasSearchBox={true}
                  />
                </SecondarySection>
              </PrimarySection>
            </>
          </PrimarySection>
        </>
      );

    case 'userId':
      return isExhausted ? (
        <ExhaustedResources />
      ) : (
        <>
          <Helmet>
            <title>Google Analytics</title>
          </Helmet>

          <PrimarySection
            header="Google Analytics"
            isLoading={!!!userActivityPagesData ? userLoadingGrid : false}
            className="analytics-container"
          >
            <>
              <Link to={RoutePath.GoogleAnalytics}>
                <strong>&lt;- Return to Google Analytics Home</strong>
              </Link>
              {dateFilters}
              {dateRanges}
              <br /> <br />
              <p>
                <strong>
                  Viewing User:{' '}
                  {userActivityHeaderData?.firstName ? (
                    userActivityHeaderData?.firstName + ' '
                  ) : (
                    <strong style={{ color: 'red' }}>User Not Found (Invalid Email / User ID)</strong>
                  )}
                  {userActivityHeaderData?.lastName} <br />
                  Email: {userActivityHeaderData?.email} <br />
                  User ID: {userActivityHeaderData?.userId} <br />
                  Account Name:{' '}
                  <Link to={`/google-analytics/accountId/accountId=${userActivityHeaderData?.accountId}`}>
                    {' '}
                    {userActivityHeaderData?.accountName}{' '}
                  </Link>
                </strong>
              </p>
              <PrimarySection className="analytics-row">
                <>
                  {/** Landing Table 4 */}
                  <SecondarySection title="Most Active Users" className="analytics-column">
                    <BasicGrid loadingGrid={landingLoadingGrid} dataTable={activeUserData} hasSearchBox={true} />
                  </SecondarySection>

                  {/** Linked from Most Active Users */}
                  <SecondarySection
                    title={
                      userActivityHeaderData?.firstName
                        ? `Pages Visited by - ${userActivityHeaderData?.firstName}`
                        : 'User Not Found (Invalid Email / User ID)'
                    }
                    className="analytics-column"
                  >
                    <BasicGrid loadingGrid={userLoadingGrid} dataTable={userActivityPagesData} hasSearchBox={true} />
                  </SecondarySection>
                </>
              </PrimarySection>
              <PrimarySection className="analytics-row">
                <>
                  {/** Linked from Most Active Users */}
                  <SecondarySection
                    title={
                      userActivityHeaderData?.firstName
                        ? `Files Downloaded by - ${userActivityHeaderData?.firstName}`
                        : 'User Not Found (Invalid Email / User ID)'
                    }
                    className="analytics-column"
                  >
                    <BasicGrid loadingGrid={userLoadingGrid} dataTable={userActivityFilesData} hasSearchBox={true} />
                  </SecondarySection>

                  {/** Linked from Most Active Users */}
                  <SecondarySection
                    title={
                      userActivityHeaderData?.firstName
                        ? `Technology Used by - ${userActivityHeaderData?.firstName}`
                        : 'User Not Found (Invalid Email / User ID)'
                    }
                    className="analytics-column"
                  >
                    <BasicGrid loadingGrid={userLoadingGrid} dataTable={userActivityTechData} hasSearchBox={true} />
                  </SecondarySection>
                </>
              </PrimarySection>
            </>
          </PrimarySection>
        </>
      );

    case 'fileName':
      return isExhausted ? (
        <ExhaustedResources />
      ) : (
        <>
          <Helmet>
            <title>Google Analytics</title>
          </Helmet>

          <PrimarySection
            header="Google Analytics"
            isLoading={!!!resourceActivityData ? resourceLoadingGrid : false}
            className="analytics-container"
          >
            <>
              {/** Adds breadcrumbs to return to GA home page */}
              <Link to={RoutePath.GoogleAnalytics}>
                <strong>&lt;- Return to Google Analytics Home</strong>
              </Link>
              {dateFilters}
              {dateRanges}
              <br /> <br />
              <p>
                <strong>
                  Viewing File:{' '}
                  {resourceActivityHeaderData?.fileName ? (
                    resourceActivityHeaderData?.fileName
                  ) : (
                    <strong style={{ color: 'red' }}>File Not Found (Invalid File Name)</strong>
                  )}{' '}
                  <br />
                  Report Type: {resourceActivityHeaderData?.reportType}
                </strong>
              </p>
              <PrimarySection className="analytics-row">
                <>
                  {/** Deciding which table to display depending on file type */}
                  {pageUrl.includes('DCF') && (
                    <SecondarySection title="Excel Downloads" className="analytics-column">
                      <BasicGrid loadingGrid={landingLoadingGrid} dataTable={activeExcelData} hasSearchBox={true} />
                    </SecondarySection>
                  )}

                  {!pageUrl.includes('DCF') && (
                    <SecondarySection title="PDF Downloads" className="analytics-column">
                      <BasicGrid loadingGrid={landingLoadingGrid} dataTable={activePdfData} hasSearchBox={true} />
                    </SecondarySection>
                  )}

                  <SecondarySection title="Downloads" className="analytics-column">
                    <BasicGrid loadingGrid={resourceLoadingGrid} dataTable={resourceActivityData} hasSearchBox={true} />
                  </SecondarySection>
                </>
              </PrimarySection>
            </>
          </PrimarySection>
        </>
      );

    default:
      return isExhausted ? (
        <ExhaustedResources />
      ) : (
        <>
          <Helmet>
            <title>Google Analytics</title>
          </Helmet>

          <PrimarySection
            header="Google Analytics"
            isLoading={!!!activePageData ? landingLoadingGrid : false}
            className="analytics-container"
          >
            <>
              {dateFilters}
              {dateRanges}

              <PrimarySection className="analytics-row">
                <>
                  {/** Landing Table 1 */}
                  <SecondarySection title="Page Views by URL" className="analytics-column">
                    <BasicGrid loadingGrid={landingLoadingGrid} dataTable={activePageData} hasSearchBox={true} />
                  </SecondarySection>

                  {/** Landing Table 2 */}
                  <SecondarySection title="Most Active Accounts" className="analytics-column">
                    <BasicGrid loadingGrid={landingLoadingGrid} dataTable={activeAccountData} hasSearchBox={true} />
                  </SecondarySection>
                </>
              </PrimarySection>

              <PrimarySection className="analytics-row">
                <>
                  {/** Landing Table 3 */}
                  <SecondarySection title="Downloads By Account" className="analytics-column">
                    <BasicGrid
                      loadingGrid={landingLoadingGrid}
                      dataTable={downloadsByAccountData}
                      hasSearchBox={true}
                    />
                  </SecondarySection>

                  {/** Landing Table 4 */}
                  <SecondarySection title="Most Active Users" className="analytics-column">
                    <BasicGrid loadingGrid={landingLoadingGrid} dataTable={activeUserData} hasSearchBox={true} />
                  </SecondarySection>
                </>
              </PrimarySection>

              <PrimarySection className="analytics-row">
                <>
                  {/** Landing Table 5 */}
                  <SecondarySection title="PDF Downloads" className="analytics-column">
                    <BasicGrid loadingGrid={landingLoadingGrid} dataTable={activePdfData} hasSearchBox={true} />
                  </SecondarySection>

                  {/** Landing Table 6 */}
                  <SecondarySection title="Excel Downloads" className="analytics-column">
                    <BasicGrid loadingGrid={landingLoadingGrid} dataTable={activeExcelData} hasSearchBox={true} />
                  </SecondarySection>
                </>
              </PrimarySection>
            </>
          </PrimarySection>
        </>
      );
  }
};

export default GoogleAnalytics;
