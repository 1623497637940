import React from 'react';
import './DashboardSpecialReportsWidget.scss';
import DashboardSpecialReportsWidgetList from './DashboardSpecialReportsWidgetList';
import { ReportDto } from '../../../../special-reports/interfaces/ReportDto';
import { DashboardWidgetComponent } from '../../../interfaces/DashboardWidgetComponent';

const DashboardSpecialReportsWidget: DashboardWidgetComponent<ReportDto[]> = props => {
  return (
    <section className="DashboardSpecialReportsWidget">
      <div className="DashboardSpecialReportsWidget__left">
        <figure className="DashboardSpecialReportsWidget__figure">
          <img
            className="DashboardSpecialReportsWidget__image"
            // src={DashboardSpecialReportUrl.WidgetImage}
            src={props.data[0].icon}
            alt={'Special Reports'}
          />
        </figure>
      </div>

      <div className="DashboardSpecialReportsWidget__right">
        <DashboardSpecialReportsWidgetList specialReports={props.data} />
      </div>
    </section>
  );
};

export default DashboardSpecialReportsWidget;
