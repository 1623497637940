import { useFormContext } from 'react-hook-form';
import { without } from 'lodash';
import { useEffect } from 'react';
import { ViewFilterName } from '../enum/types/ViewFilterName';
import { StatusAvailability } from '../enum/StatusAvailability';
import { AnyObject, CustomAny } from '../../../types/generics';

const getValues = (filterNames: ViewFilterName[], watch: (filterName: string) => CustomAny): AnyObject => {
  return filterNames.reduce((newValues, filterName) => {
    return {
      ...newValues,
      [filterName]: watch(filterName),
    };
  }, {});
};

const getOnlyFilterName = (values: AnyObject) => {
  return Object.keys(values).find(filterName => values[filterName] === StatusAvailability.Only);
};

const isOnly = (value: StatusAvailability): boolean => {
  return value === StatusAvailability.Only;
};

const getDifferentFilterNames = (filterNames: ViewFilterName[], filterName: ViewFilterName): ViewFilterName[] => {
  return without(filterNames, filterName);
};

export const useStatusAvailabilityDependencies = (filterNames: ViewFilterName[]): void => {
  const { watch /*, setValue */ } = useFormContext();
  const values: AnyObject = getValues(filterNames, watch);

  const useFilterDependencies = (filterName: ViewFilterName): void => {
    const value = values[filterName];

    useEffect(() => {
      if (isOnly(value)) {
        const differentFilterNames = getDifferentFilterNames(filterNames, filterName);

        differentFilterNames.forEach(name => {
          // setValue(name, StatusAvailability.Exclude);
        });
      } else if (value === StatusAvailability.Include) {
        const onlyFilterName = getOnlyFilterName(values);

        if (onlyFilterName) {
          // setValue(onlyFilterName, StatusAvailability.Include);
        }
      }
    }, [value, filterName]);
  };

  const useFiltersDependencies = (): void => {
    filterNames.forEach(filterName => {
      // eslint-disable-next-line
      useFilterDependencies(filterName);
    });
  };

  useFiltersDependencies();
};
