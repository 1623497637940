export class ValueStorageService<T> {
  constructor(public value?: T | undefined) {}

  setValue(value: T): void {
    this.value = value;
  }

  getValue(): T | undefined {
    return this.value;
  }

  removeValue(): void {
    delete this.value;
  }

  extractValue(): T | undefined {
    const value = this.value;

    if (value) {
      this.removeValue();
    }

    return value;
  }
}
