import { TickerSymbol } from '../../shared/types';
import LocationService from '../../shared/services/LocationService';
import { RoutePath } from '../../shared/enums/RoutePath';
import { CompanyTabsPath } from '../enums/CompanyTabsPath';
import { CompanyProfileQueryParam } from '../interfaces/CompanyProfileQueryParam';
import { LocationSearchData } from '../../shared/interfaces/LocationSearchData';
import { CompanyProfileActionType } from '../enums/CompanyProfileActionType';
import { CompanyPageQueryParam } from '../enums/CompanyPageQueryParam';
import { CompanyTemplateService } from './CompanyTemplateService';

export class CompanyLocationService {
  static getRelativePath(ticker: TickerSymbol, searchData?: CompanyProfileQueryParam): string {
    return LocationService.getRelativePath(`${RoutePath.Company}/${ticker}`, searchData as LocationSearchData);
  }

  static getRelativeTabPath(tapPath: CompanyTabsPath, ticker: TickerSymbol): string {
    return LocationService.getRelativePath(`${RoutePath.Company}/${ticker}${tapPath}`);
  }

  static getPrintCompanyPath(ticker: TickerSymbol): string {
    return this.getRelativePath(ticker, {
      [CompanyPageQueryParam.Action]: CompanyProfileActionType.Print,
    });
  }

  static navigateByTickerInText(tickerInText: string): void {
    const ticker = CompanyTemplateService.getTickerFromText(tickerInText);
    LocationService.redirect(CompanyLocationService.getRelativePath(ticker) as RoutePath);
  }

  static isCurrentCompanyProfilePage(pageRoutePath: RoutePath, ticker: TickerSymbol): boolean {
    const tickerRelativePath = this.getRelativePath(ticker);

    return pageRoutePath.includes(tickerRelativePath);
  }
}
