import { ViewColumnSystemName } from '../enums/ViewColumnSystemName';

export const colorNumberCellFormatterColumns: Partial<ViewColumnSystemName>[] = [
  ViewColumnSystemName.FirstDayReturn,
  ViewColumnSystemName.PriceMidpoint,
  ViewColumnSystemName.ThreeMonthReturn,
  ViewColumnSystemName.ThreeMoReturn,
  ViewColumnSystemName.ThreeMoReturnAvg,
  ViewColumnSystemName.PastMonthReturn,
  ViewColumnSystemName.TotalReturnAvg,
  ViewColumnSystemName.TotalReturn,
  ViewColumnSystemName.Return,
  ViewColumnSystemName.FirstDayPop,
  ViewColumnSystemName.IpoReturn,
];
