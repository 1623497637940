import { useMemo } from 'react';
import { StatsContextValue } from './StatsContext';
import { StatsPagesFiltersSettings } from '../interfaces/StatsPagesFiltersSettings';

export function useStatsContext(pagesFiltersSettings: StatsPagesFiltersSettings): StatsContextValue {
  return useMemo(
    () => ({
      pagesFiltersSettings,
    }),
    [pagesFiltersSettings],
  );
}
