import { SelectOption } from '../../../../shared/services/select/interfaces/SelectOption';
import { CompanyFinancialsLocationFilterName } from '../enums/CompanyFinancialsLocationFilterName';

export const getCompanyFinancialsLocationOptions: (
  localCurrencyTitle: string,
) => SelectOption[] = localCurrencyTitle => [
  {
    title: 'USD$',
    value: CompanyFinancialsLocationFilterName.UsGrid,
  },
  {
    title: localCurrencyTitle,
    value: CompanyFinancialsLocationFilterName.LocalGrid,
  },
];
