import React, { useCallback, useContext } from 'react';
import { StatsPageContext } from '../../context/StatsPageContext';
import { ViewFilterName } from '../../../../shared/entities/filters/enum/types/ViewFilterName';
import { Location } from '../../../../shared/entities/filters/enum/Location';
import StatsVentureCapital from './venture-capital/StatsVentureCapital';
import StatsIposByRegion from './ipos-by-region/StatsIposByRegion';

const StatsDashboardChartsSwitchByLocation: React.FC = () => {
  const { pageFilters } = useContext(StatsPageContext);

  const location = pageFilters[ViewFilterName.Headquarter];

  const getIsHeadquarter = useCallback((headquarter: Location) => location === headquarter, [location]);

  return <>{getIsHeadquarter(Location.US) ? <StatsVentureCapital /> : <StatsIposByRegion />}</>;
};

export default StatsDashboardChartsSwitchByLocation;
