import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { ViewColumnSystemName } from '../../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { ViewOptionsService } from '../../../../../shared/entities/view/components/view/services/ViewOptionsService';
import { screensSpacsSharedOptions } from './screensSpacsSharedOptions';
import { ViewFilterName } from '../../../../../shared/entities/filters/enum/types/ViewFilterName';

export const screensSpacsLandingOptions: Partial<ScreenOptions> = ViewOptionsService.merge<ScreenOptions>(
  screensSpacsSharedOptions,
  {
    grid: {
      title: 'ALL SPAC ACTIVITY',
      idCols: [ViewColumnSystemName.DealStatus],
    },
    screenTriggerOptions: {
      columnName: ViewColumnSystemName.Label,
      filterName: ViewFilterName.DealStatusSelect,
      filterValueColumnName: ViewColumnSystemName.DealStatusSelectKey,
    },
  },
);
