export enum ViewGridColumnWidth {
  IconWithSort = 45,
  Icon = 30,
  Xxs = 50,
  Xs = 75,
  Sx = 85,
  Sm = 100,
  Ms = 115,
  Md = 125,
  Lg = 150,
  Lgx = 175,
  Xl = 200,
  Xld = 225,
  Xll = 250,
  XXl = 300,
  XXXl = 400,
}
