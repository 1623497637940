import React from 'react';
import './EVCell.scss';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { useClassName } from '../../../../../../hooks/useClassName';
import { EVCellBlock } from './EVCellBem';
import Tooltip from '../../../../../../components/tooltip/Tooltip';

type Props = GridCellFormatterCommonProps;

const EVCell: React.FC<Props> = props => {
  const cn = useClassName(EVCellBlock.Root);

  const EVSource = props.data.evSource;

  return (
    <CustomCellFormatterWrapper className={cn()} value={props.data.EV}>
      {EVSource ? (
        <Tooltip
          className={cn()}
          hasTitleLinkStyle
          content={
            <span className={cn('source')}>
              <b>Source:</b> {props.data.evSource}
            </span>
          }
          title={props.data.EV}
        />
      ) : (
        props.data.EV
      )}
    </CustomCellFormatterWrapper>
  );
};

export default EVCell;
