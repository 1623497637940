import React from 'react';
import { VideosApiService } from '../../shared/api/VideosApiService';
import { useMemoizedCallback } from '../../shared/hooks/useMemoizedCallback';
import LatestVideosContent from './LatestVideosContent';

const LatestVideos: React.FC = () => {
  const getMemoizedLatestVideos = useMemoizedCallback(() => VideosApiService.getLatestVideos());

  return <LatestVideosContent getMemoizedLatestVideos={getMemoizedLatestVideos} />;
};

export default LatestVideos;
