import { useContext, useMemo } from 'react';
import { CompanyTabsPath } from '../../enums/CompanyTabsPath';
import { CompanyLocationService } from '../../services/CompanyLocationService';
import { CompanyContext } from '../context/CompanyContext';

export function useRelativeTabPath(tabPath: CompanyTabsPath): string {
  const { ticker } = useContext(CompanyContext);

  return useMemo(() => CompanyLocationService.getRelativeTabPath(tabPath, ticker), [ticker, tabPath]);
}
