import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import Input, { InputProps } from './Input';

export type FormInputProps = InputProps;

const FormInput: React.FC<FormInputProps> = props => {
  const { errors, register } = useFormContext();

  const error: FieldError | undefined = errors?.[props?.name] as FieldError;

  return <Input {...props} register={register} error={error} />;
};

export default FormInput;
