import React, { memo } from 'react';
import './NewsSearchList.scss';
import { NewsFilterResultDto } from '../interfaces/NewsFilterResultDto';
import NewsSearchItem from './NewsSearchItem';

interface Props {
  items: NewsFilterResultDto[];
}

const NewsSearchList: React.FC<Props> = ({ items }) => {
  return (
    <div className="NewsSearchList">
      <ul className="NewsSearchList__list">
        {items.map((item: NewsFilterResultDto) => (
          <li className="NewsSearchList__item" key={item.newsKey}>
            <NewsSearchItem data={item} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(NewsSearchList);
