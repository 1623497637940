import { ViewGridColumnDto } from '../interfaces/ViewGridColumnDto';
import { ViewColumnSystemName } from '../enums/ViewColumnSystemName';

export class ViewGridColumnNameService {
  static getNestedColumnNameFromColumnNames(columnNames: ViewColumnSystemName[]): string {
    return columnNames.map(name => name).join('');
  }

  public static getNestedColumnNameFromNestedColumns(nestedColumns: ViewGridColumnDto[]): string {
    const names = nestedColumns.map(nestedColumn => nestedColumn.name);

    return this.getNestedColumnNameFromColumnNames(names);
  }
}
