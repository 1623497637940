import React, { useContext, useMemo } from 'react';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import { Link } from 'react-router-dom';
import { CompanyContext } from '../../../../context/CompanyContext';
import './CompanyMilestones.scss';
import { ProfileDataService } from '../../services/ProfileDataService';
import { profileDefaultExpandingLength } from '../../constants/profileDefaultExpandingLength';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import { useRelativeTabPath } from '../../../../hooks/useRelativeTabPath';
import { CompanyTabsPath } from '../../../../../enums/CompanyTabsPath';
import { CompanyMilestonesBlock } from './CompanyMilestonesBem';
import { useClassName } from '../../../../../../shared/hooks/useClassName';
import { GridRow } from '../../../../../../shared/components/grid/shared/types/GridRow';
import CompanyProfileSidebarTable from '../company-profile-table/CompanyProfileSidebarTable';

const CompanyMilestones: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);
  const path = useRelativeTabPath(CompanyTabsPath.Milestones);
  const cn = useClassName(CompanyMilestonesBlock.Root);

  const expandData = useMemo(() => ProfileDataService.getCompanyTableRows(profile.milestones) as GridRow[], [
    profile.milestones,
  ]);

  const title =
    profile?.description?.dealStatus === 'Private Company Watchlist' ? <Link to={path}>Milestones</Link> : 'Milestones';

  return (
    <>
      {profile.milestones && (
        <CompanyProfileSideItem
          className={cn()}
          title={title}
          component={CompanyProfileSidebarTable}
          expandData={expandData}
          componentProps={{
            columns: profile.milestones.columns,
            isHeaderHidden: true,
            hasAroundBorder: false,
            isColumnsAlignEnd: false,
          }}
          expandingLength={profileDefaultExpandingLength}
        />
      )}
    </>
  );
};

export default CompanyMilestones;
