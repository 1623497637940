import { FilterControlSettings } from '../../../types/FilterControlSettings';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { ControlSize } from '../../../../../enums/ControlSize';
import { locationOptions } from '../../options/locationOptions';

export const companyInfoControls: FilterControlSettings = {
  [ViewFilterName.Industry]: {
    type: FormControlType.Select,
    name: ViewFilterName.Industry,
    props: {
      name: ViewFilterName.Industry,
      label: 'Sector',
      placeholder: 'Choose Sector',
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isMultiSelect: false,
    },
  },
  [ViewFilterName.SectorIndustry]: {
    type: FormControlType.Select,
    name: ViewFilterName.SectorIndustry,
    props: {
      name: ViewFilterName.SectorIndustry,
      label: 'Sector/Industry',
      placeholder: 'Choose Sector/Industry',
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isMultiSelect: true,
    },
  },
  [ViewFilterName.EstimatedTiming]: {
    type: FormControlType.Select,
    name: ViewFilterName.EstimatedTiming,
    props: {
      name: ViewFilterName.EstimatedTiming,
      label: 'Estimated Timing',
      placeholder: 'Choose Estimated Timing',
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isMultiSelect: false,
    },
  },
  [ViewFilterName._Headquarters]: {
    type: FormControlType.SelectSwitchOptions,
    name: ViewFilterName._Headquarters,
    props: {
      textSelectsProps: [
        {
          name: ViewFilterName.Headquarter,
          label: 'Headquarters',
          options: locationOptions,
          size: ControlSize.Small,
          isUnselectAvailable: true,
        },
      ],
      selectProps: {
        name: ViewFilterName.HeadquartersCityStateCountry,
        placeholder: 'Choose City/State/Country',
        isMultiSelect: true,
        size: ControlSize.Small,
        width: ControlSize.ExtraLarge,
      },
    },
  },
  [ViewFilterName.FoundedSince]: {
    type: FormControlType.Input,
    name: ViewFilterName.FoundedSince,
    props: {
      name: ViewFilterName.FoundedSince,
      label: 'Founded Since',
      placeholder: 'Year',
      size: ControlSize.Small,
      width: ControlSize.Small,
    },
  },
  [ViewFilterName.EmergingGrowth]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.EmergingGrowth,
    props: {
      name: ViewFilterName.EmergingGrowth,
      label: 'Emerging Growth',
      size: ControlSize.Small,
    },
  },
};
