import { TokenDto } from '../interfaces/TokenDto';
import TokenStorageService from './token/TokenStorageService';
import { CustomAny } from '../types/generics';
import { store } from '../../store';
import { setAccountData, setIsAuthorized } from '../../store/auth/actions';
import AuthApiService from '../api/AuthApiService';
import { FavoritesApiService } from '../api/FavoritesApiService';
import { setFavouriteCompanies } from '../../store/favourite-company/actions';
import { TokenStorageKey } from './token/enums/TokenStorageKey';

export class AuthService {
  static async authorize(getTokenFn: CustomAny, shouldRemember = false): Promise<void> {
    const token: TokenDto = await getTokenFn();

    TokenStorageService.setTokens(token, shouldRemember);
    localStorage.removeItem('logout');

    store.dispatch(setIsAuthorized(true));

    await this.setAuthorizedData();

    // Moved the following piece of auth logic to Login page
    // LocationService.redirectToReturnLocation();
  }

  static logout(): void {
    TokenStorageService.removeTokens();
    localStorage.setItem('logout', 'logout');
    localStorage.setItem(TokenStorageKey.GaAccountId, '');
    localStorage.setItem(TokenStorageKey.GaUserId, '');
    store.dispatch(setIsAuthorized(false));
  }

  static async refresh(): Promise<void> {
    const refreshToken = TokenStorageService.getRefreshToken();

    if (refreshToken) {
      try {
        const tokenDto = await AuthApiService.refreshToken(refreshToken);

        TokenStorageService.updateTokens(tokenDto);
      } catch (e) {
        AuthService.logout();

        throw e;
      }
    } else {
      const logout = localStorage.getItem('logout');
      if (logout === 'logout') {
        AuthService.logout();
      }
    }
  }

  static async setAuthorizedData(): Promise<void> {
    await Promise.all([this.setFavourites(), this.setAccountData()]).catch(err => {
      if (err.message === 'Request failed with status code 401') {
        AuthService.logout();
      }
    });
  }

  private static async setFavourites(): Promise<void> {
    const companies = await FavoritesApiService.getAll();

    store.dispatch(setFavouriteCompanies(companies));
  }

  private static async setAccountData(): Promise<void> {
    const accountData = await AuthApiService.getAccountSettingsData();

    store.dispatch(setAccountData(accountData));
  }
}
