import { FileActionType } from '../../../../../../../shared/components/file-actions/enums/FileActionType';
import { TimeFrame } from '../../../../../../../shared/entities/filters/enum/TimeFrame';
import { ScreenOptions } from '../../../interfaces/ScreenOptions';
import { screensPageAdditionalAction } from '../../../constants/screensPageAdditionalAction';

export const screenLandingSharedOptions: Partial<ScreenOptions> = {
  isLanding: true,
  toolbar: {
    fileActionTypes: [FileActionType.Excel, FileActionType.Print],
    quickFilter: {
      timeFrameOptions: [TimeFrame.Month, TimeFrame.Quarter, TimeFrame.YTD, TimeFrame.Year, TimeFrame.All],
      hasTimeFrameRange: true,
      hasDateRange: true,
    },
  },
  grid: {
    isColumnsFitGrid: true,
    isFixedHeight: false,
  },
  additionalAction: screensPageAdditionalAction,
};
