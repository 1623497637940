import React from 'react';
import BootstrapBadge from 'react-bootstrap/Badge';

interface Props {
  variant?: BadgeVariants;
  pill?: boolean;
}

export enum BadgeVariants {
  Primary = 'primary',
  Info = 'info',
  Secondary = 'secondary',
}

const Badge: React.FC<Props> = props => (
  <BootstrapBadge pill variant={props.variant}>
    {props.children}
  </BootstrapBadge>
);

Badge.defaultProps = {
  variant: BadgeVariants.Primary,
};

export default Badge;
