import React from 'react';
import './SecondarySectionsGroup.scss';
import { useClassName } from '../../hooks/useClassName';
import { SecondarySectionsGroupBlock } from './SecondarySectionsGroupBem';

interface Props {
  className?: string;
}

const SecondarySectionsGroup: React.FC<Props> = props => {
  const cn = useClassName(SecondarySectionsGroupBlock.Root, props.className);

  return <section className={cn()}>{props.children}</section>;
};

export default SecondarySectionsGroup;
