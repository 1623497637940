import React, { cloneElement, ReactElement, useCallback } from 'react';
import './PrintTrigger.scss';
import classNames from 'classnames';
import { PrintService } from '../../services/PrintService';

interface Props {
  trigger: ReactElement;
  className?: string;
}

const PrintTrigger: React.FC<Props> = props => {
  const onClick = useCallback(() => PrintService.print(), []);

  return <div className={classNames('PrintTrigger', props.className)}>{cloneElement(props.trigger, { onClick })}</div>;
};

export default PrintTrigger;
