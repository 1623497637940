import React from 'react';
import './WeekStrongFormatter.scss';
import { useClassName } from '../../hooks/useClassName';
import { WeekStrongFormatterBlock, WeekStrongFormatterElement } from './weekStrongFormatterBem';
import { WeekStrongType } from './types/WeekStrongType';
import { RatingType } from '../../enums/RatingType';

interface Props {
  type: WeekStrongType;
}

const WeekStrongFormatter: React.FC<Props> = props => {
  const { type } = props;

  const cn = useClassName(WeekStrongFormatterBlock.Root);

  return (
    <div className={cn()}>
      <span
        className={cn(WeekStrongFormatterElement.Point, {
          [type]: type === RatingType.Weak,
        })}
      />

      <span
        className={cn(WeekStrongFormatterElement.Point, {
          [type]: type === RatingType.Neutral,
        })}
      />

      <span
        className={cn(WeekStrongFormatterElement.Point, {
          [type]: type === RatingType.Strong,
        })}
      />
    </div>
  );
};

export default WeekStrongFormatter;
