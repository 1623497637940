import React, { useState } from 'react';
import { ContactFormField } from './ContactFormField';
import { validationSchema } from './validationSchema';
import { InputType } from '../../../shared/components/forms/Input';
import { ContactFormData } from './ContactFormData';
import Form from '../../../shared/components/forms/Form';
import FormGroup from '../../../shared/components/forms/FormGroup';
import ApiContactService from '../../../shared/api/ContactApiService';
import ButtonSubmit from '../../../shared/components/forms/ButtonSubmit';
import FormCaptcha from '../../../shared/components/forms/FormCaptcha';
import FormTextarea from '../../../shared/components/forms/FormTextarea';
import { SubmitFormFn } from '../../../shared/components/forms/types/SubmitFormFn';
import { ControlSize } from '../../../shared/enums/ControlSize';
import { AlignTypesHorizontal } from '../../../shared/enums/AlignTypesHorizontal';
import FormInput from '../../../shared/components/forms/FormInput';
import { useQueryParams } from '../../../shared/effects/useQueryParams';
import { PricingTextElement } from '../enum/PricingTextElement';

const ContactForm: React.FC = () => {
  const pricingParam = useQueryParams().get('pricing') as string | undefined;

  const comment: string | undefined = pricingParam === 'true' ? PricingTextElement.Comment : '';
  const defaultValues: Partial<ContactFormData> = {
    [ContactFormField.Comment]: comment,
  };
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const onSubmit: SubmitFormFn<ContactFormData> = data => {
    return ApiContactService.requestContactUs(data).then(() => setIsSubmitted(true));
  };

  return (
    <Form
      validationSchema={validationSchema}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      size={ControlSize.Medium}
      alignHorizontal={AlignTypesHorizontal.Center}
    >
      <FormGroup>
        <FormInput name={ContactFormField.FirstName} label="First Name" isDisabled={isSubmitted} />
      </FormGroup>

      <FormGroup>
        <FormInput name={ContactFormField.LastName} label="Last Name" isDisabled={isSubmitted} />
      </FormGroup>

      <FormGroup>
        <FormInput
          name={ContactFormField.Email}
          label="Email"
          type={InputType.Email}
          autoComplete="email"
          isDisabled={isSubmitted}
        />
      </FormGroup>

      <FormGroup>
        <FormInput
          name={ContactFormField.PhoneNumber}
          label="Phone Number"
          type={InputType.Tel}
          isDisabled={isSubmitted}
        />
      </FormGroup>

      <FormGroup>
        <FormInput name={ContactFormField.Company} label="Company" isDisabled={isSubmitted} />
      </FormGroup>

      <FormGroup>
        <FormInput name={ContactFormField.JobTitle} label="Job Title" isDisabled={isSubmitted} />
      </FormGroup>

      <FormGroup>
        <FormTextarea name={ContactFormField.Comment} label="Comment" isDisabled={isSubmitted} />
      </FormGroup>

      {/* <FormGroup>
        <FormCheckbox name={ContactFormField.Agree} label={<Agreement />} isDisabled={isSubmitted} />
      </FormGroup> */}

      {!isSubmitted && (
        <FormGroup>
          <FormCaptcha name={ContactFormField.Captcha} />
        </FormGroup>
      )}

      <ButtonSubmit message="Submit" submittedMessage="Your message is sent" isSubmitted={isSubmitted} />
    </Form>
  );
};

export default ContactForm;
