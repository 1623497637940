import React, { ReactNode } from 'react';
import CustomGridHeader from '../../shared/components/formatters/header/CustomGridHeader';
import { GridColumn } from '../../shared/interfaces/GridColumn';

export class SimpleGridHeaderCellFactory {
  static getHeaderCell(column: GridColumn, isNoWrap?: boolean): ReactNode {
    const gridColumn = {
      ...column,
      sortable: false,
    };

    return <CustomGridHeader gridColumn={gridColumn} isNoWrap={isNoWrap} />;
  }
}
