import React, { useContext } from 'react';
import './ViewShareLinks.scss';
import ViewShareLink from './ViewShareLink';
import { ViewContext } from '../../ViewContext';
import { ViewDataService } from '../../services/ViewDataService';
import { ViewSharedLink } from '../../interfaces/api/ViewSharedLink';

interface Props {
  onAction: () => void;
}

const ViewShareLinks: React.FC<Props> = props => {
  const { state } = useContext(ViewContext);

  return (
    <div className="ViewShareLinks">
      {ViewDataService.hasSharedLinks(state.sharedLinks) ? (
        state.sharedLinks.map((sharedLink: ViewSharedLink, index: number) => (
          <ViewShareLink key={index} {...sharedLink} onAction={props.onAction} />
        ))
      ) : (
        <div className="ViewShareLinks__no-links">You haven&lsquo;t shared any links yet</div>
      )}
    </div>
  );
};

export default ViewShareLinks;
