import { ViewTypeScreenLanding } from '../../shared/entities/view/components/view/types/screen/ViewTypeScreenLanding';
import { ViewTypeScreenInUse } from '../../shared/entities/view/components/view/types/screen/ViewTypeScreenInUse';
import { ViewType } from '../../shared/entities/view/components/view/enums/ViewType';

export const screensLandingToInUse: { [key in ViewTypeScreenLanding]: ViewTypeScreenInUse } = {
  [ViewType.InvestorsLanding]: ViewType.InvestorsInUse,
  [ViewType.LocationLanding]: ViewType.LocationInUse,
  [ViewType.IndustryLanding]: ViewType.IndustryInUse,
  [ViewType.UnderwriterLanding]: ViewType.UnderwriterInUse,
  [ViewType.SpacsLanding]: ViewType.SpacsInUse,
};
