import React, { useCallback, useContext, useMemo } from 'react';
import '../ViewToolbar.scss';
import { useClassName } from '../../../../../../../hooks/useClassName';
import { SavedFiltersPopoverElement, SavedViewsPopoverBlock } from './viewToolbarSavedFiltersPopoverBem';
import { ViewContext } from '../../../ViewContext';
import IconWithContent from '../../../../../../../components/icon/IconWithContent';
import PopoverOverlay from '../../../../../../../components/PopoverOverlay';
import { ControlSize } from '../../../../../../../enums/ControlSize';
import { IconName } from '../../../../../../../components/icon/IconName';
import Button from '../../../../../../../components/forms/Button';
import SavedViewsSection from '../../view-saved/SavedViewsSection';
import { SavedViewsType } from '../../view-saved/enums/SavedViewsType';
import { setViewSavedViews } from '../../../store/actions/view';
import { savedScreens, savedViews } from '../../../constants/viewsTypeTexts';

const ViewToolbarSavedViewsPopover: React.FC = () => {
  const cn = useClassName(SavedViewsPopoverBlock.Root);

  const { state, dispatch } = useContext(ViewContext);

  const entityOptions = useMemo(() => {
    if (state.isScreen) {
      return {
        type: SavedViewsType.Screens,
        title: savedScreens,
      };
    }

    return {
      type: SavedViewsType.Views,
      title: savedViews,
    };
  }, [state.isScreen]);

  const afterDeleteView = useCallback(
    savedViews => {
      dispatch(setViewSavedViews(savedViews));
    },
    [dispatch],
  );

  return (
    <PopoverOverlay
      isBelowAll
      trigger={
        <Button size={ControlSize.Small} className={cn(SavedFiltersPopoverElement.SavedViews)}>
          <IconWithContent name={IconName.DownArrow} size={ControlSize.ExtraSmall} isReversed>
            {entityOptions.title}
          </IconWithContent>
        </Button>
      }
    >
      <SavedViewsSection
        type={entityOptions.type}
        items={state.savedViews}
        hasNoHeader
        width={ControlSize.Small}
        afterDeleteView={afterDeleteView}
      />
    </PopoverOverlay>
  );
};

export default ViewToolbarSavedViewsPopover;
