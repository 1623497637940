import React, { useCallback, useContext, useState } from 'react';
import GridLayout from '../../shared/components/grid-layout/GridLayout';
import DashboardWidget from './DashboardWidget';
import { DashboardContext } from '../DashboardContext';
import { DashboardWidgetConfig } from '../interfaces/DashboardWidgetConfig';
import { GridLayoutBreakpoint } from '../../shared/components/grid-layout/enums/GridLayoutBreakpoint';
import { Pixels } from '../../shared/types';

const DashboardWidgets: React.FC = () => {
  const [resizedFlag, setResizedFlag] = useState(0);
  const { widgets, onWidgetsChange } = useContext(DashboardContext);
  const [breakpoint, setBreakpoint] = useState<GridLayoutBreakpoint>(GridLayoutBreakpoint.Large);
  const [width, setWidth] = useState<Pixels | null>(null);
  const onChange = useCallback(widgets => onWidgetsChange(widgets as DashboardWidgetConfig[]), [onWidgetsChange]);

  return (
    // <PageLoader loading={widgets.length === 0}>
    <GridLayout
      className="DashboardWidgets"
      widgets={widgets}
      width={width}
      onWidgetsChange={onChange}
      onBreakpointChange={setBreakpoint}
      onWidthChange={setWidth}
      updateResizedFlag={() => {
        setResizedFlag(Date.now());
      }}
    >
      {widgets.map((widget: DashboardWidgetConfig) => (
        <DashboardWidget
          resizedFlag={resizedFlag}
          key={widget.type}
          widget={widget}
          breakpoint={breakpoint}
          width={width}
        />
      ))}
    </GridLayout>
    // </PageLoader>
  );
};

export default DashboardWidgets;
