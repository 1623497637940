import React from 'react';
import './EmailPreferencesFormSection.scss';
import EmailPreferencesFormSectionItem from './EmailPreferencesFormSectionItem';
import { EmailPreferenceSectionItem } from '../interfaces/EmailPreferenceSectionItem';

interface Props {
  title: string;
  sectionItems: EmailPreferenceSectionItem[];
}

const EmailPreferencesFormSection: React.FC<Props> = props => {
  return (
    <div className="EmailPreferencesFormSection">
      <h4 className="EmailPreferencesFormSection__title">{props.title}</h4>
      <div className="EmailPreferencesFormSection__row">
        {props.sectionItems.map((sectionItem: EmailPreferenceSectionItem) => {
          return (
            <div key={sectionItem.title} className="EmailPreferencesFormSection__col">
              {<EmailPreferencesFormSectionItem item={sectionItem} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EmailPreferencesFormSection;
