import React from 'react';
import './ActionListNavGroup.scss';
import { useClassName } from '../../../../hooks/useClassName';
import { ActionListNavGroupBlock } from './actionListNavGroupBem';
import { BlockOrientation } from '../../../../enums/BlockOrientation';

interface Props {
  orientation: BlockOrientation;
  className?: string;
}

const ActionListNavGroup: React.FC<Props> = props => {
  const cn = useClassName(ActionListNavGroupBlock.Root, props.className);

  return (
    <div
      className={cn({
        [props.orientation]: props.orientation,
      })}
    >
      {props.children}
    </div>
  );
};

export default ActionListNavGroup;
