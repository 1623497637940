import { RotatedGridRowProps } from '../../../shared/components/rotated-grid/RotatedGridRow';
import { CompanyTooltipDto } from '../../interfaces/CompanyTooltipDto';
import { CompanyDataService } from '../../services/CompanyDataService';

export const getFirstTable = (data: CompanyTooltipDto): RotatedGridRowProps[] => [
  {
    title: 'Trade Date',
    data: data.tradeDate,
  },
  {
    isEmpty: true,
  },
  {
    title: 'Sector',
    data: data.sector,
  },
  {
    title: 'Underwriters',
    data: data.underwriters,
  },
  {
    title: 'Deal Size ($mm)',
    data: data.dealSize,
  },
  {
    ...(CompanyDataService.isPriced(data.companyStatus) && {
      title: 'IPO Price (Range)',
      data: data.ipoPriceAndPriceRange,
    }),
  },

  ((): RotatedGridRowProps => {
    const isPriced = CompanyDataService.isPriced(data.companyStatus);

    return {
      title: isPriced ? 'Last Close' : 'Price Range',
      data: isPriced ? data.stockPriceAndTotalReturn : data.priceRange,
    };
  })(),
  {
    ...(!CompanyDataService.isPriced(data.companyStatus) && {
      title: 'Total Return',
      data: data.totalReturn,
    }),
  },

  {
    isEmpty: true,
  },
];

export const getSecondTable = (data: CompanyTooltipDto): RotatedGridRowProps[] => [
  {
    title: 'MV ($mm)',
    data: data.mv,
  },
  {
    title: 'EV ($mm)',
    data: data.ev,
  },
  {
    title: 'LTM sales ($mm)',
    data: data.ltmSales,
  },
  {
    title: 'GM',
    data: data.gmPct,
  },
  {
    title: 'EBITDA',
    data: data.ebitdaPct,
  },
  {
    title: 'Sales Growth',
    data: data.salesGrowth,
  },
];

export const getThirdTable = (data: CompanyTooltipDto): RotatedGridRowProps[] => [
  {
    isEmpty: true,
  },
  {
    title: 'Comps',
    data: data.comps,
  },
  {
    title: 'Exchange',
    data: data.exchange,
  },
  {
    title: 'Shareholders',
    data: data.shareholders,
  },
  {
    title: 'Headquarters',
    data: data.headquarters,
  },
];
