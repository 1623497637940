import React from 'react';
import './DashboardWidgetHeader.scss';
import { Link } from 'react-router-dom';
import Button from '../../shared/components/forms/Button';
import Icon from '../../shared/components/icon/Icon';
import { IconName } from '../../shared/components/icon/IconName';
import { ControlSize } from '../../shared/enums/ControlSize';
import { stopPropagation } from '../../shared/constants/stopPropagation';
import { useDashboardRemoveWidget } from '../hooks/useDashboardRemoveWidget';
import { DashboardWidgetConfig } from '../interfaces/DashboardWidgetConfig';
import { useDashboardWidgetData } from '../hooks/useDashboardWidgetData';
import { useClassName } from '../../shared/hooks/useClassName';
import { DashboardWidgetHeaderBlock, DashboardWidgetHeaderElement } from './dashboardWidgetHeaderBem';

interface Props {
  widget: DashboardWidgetConfig;
}

const DashboardWidgetHeader: React.FC<Props> = props => {
  const cn = useClassName(DashboardWidgetHeaderBlock.Root);

  const { title, route } = useDashboardWidgetData(props.widget.type);

  const onCloseButtonClick = useDashboardRemoveWidget(props.widget);

  return (
    <header className={cn()}>
      {route ? (
        <Link
          className={cn(DashboardWidgetHeaderElement.Link)}
          to={route}
          onMouseDown={stopPropagation}
          onTouchStart={stopPropagation}
        >
          {title}
        </Link>
      ) : (
        <div className={cn(DashboardWidgetHeaderElement.Title)}>{title}</div>
      )}

      <Button
        className="DashboardWidgetHeader__button"
        size={ControlSize.Small}
        onClick={onCloseButtonClick}
        onMouseDown={stopPropagation}
        onTouchStart={stopPropagation}
      >
        <Icon name={IconName.Close} size={ControlSize.ExtraSmall} />
      </Button>
    </header>
  );
};

export default DashboardWidgetHeader;
