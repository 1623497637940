import PrimarySectionRow from '../../../../../shared/components/primary-section/PrimarySectionRow';
import React, { useContext } from 'react';
import StatsElement from '../../../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../../../shared/entities/stats-element/enums/StatsElementType';
import { useStatsTitle } from '../../../hooks/useStatsTitle';
import { getMarketByMonthStatsElementOptions } from './constants/getMarketByMonthStatsElementOptions';
import { StatsPageContext } from '../../../context/StatsPageContext';
import { StatsFilingsChartService } from '../../../filings/services/StatsFilingsChartService';

const StatsMarketByMonth: React.FC = () => {
  const { pageFilters } = useContext(StatsPageContext);

  return (
    <PrimarySectionRow header={useStatsTitle('IPO Market By Month', 'The')}>
      <StatsElement
        type={StatsElementType.DashboardIpoMarketByMonth}
        options={getMarketByMonthStatsElementOptions(
          StatsFilingsChartService.getBlockTitle(pageFilters),
          StatsFilingsChartService.getChartTitle(pageFilters),
        )}
      />
    </PrimarySectionRow>
  );
};

export default StatsMarketByMonth;
