import React from 'react';
import ExpandButton from './ExpandButton';
import { IconName } from '../icon/IconName';
import { ExpandButtonOptions } from './interfaces/ExpandButtonOptions';
import { useClassName } from '../../hooks/useClassName';

type Props = ExpandButtonOptions;

const ElementsExpanderButton: React.FC<Props> = props => {
  const cn = useClassName('ElementsExpanderButton', props.className);

  return (
    <ExpandButton
      className={cn()}
      isExpanded={props.isExpanded}
      expandViewOptions={{ description: 'Show more', iconName: IconName.DownArrow }}
      collapseViewOptions={{ description: 'Show less', iconName: IconName.UpArrow }}
      onExpand={props.onExpand}
    />
  );
};

export default ElementsExpanderButton;
