import React, { useMemo, useState, useCallback, useContext } from 'react';
import { ChartProps } from '../../../../components/chart/Chart';
import { ChartFactory } from '../../../../components/chart/factories/ChartFactory';
import { OptionsListProp } from '../../../../components/chart/types/OptionsListProp';
import SecondarySection from '../../../../components/secondary-section/SecondarySection';
import { StatsElementChartDto } from './types/StatsElementChartDto';
import { StatsElementFiltersInternalService } from '../shared/filters-internal/services/StatsElementFiltersInternalService';
import { StatsElementChartData } from './interfaces/StatsElementChartData';
import { FiltersValues } from '../../../filters/types/FiltersValues';
import { ObjectHelper } from '../../../../helpers/ObjectHelper';
import { StatsElementContext } from '../context/StatsElementContext';
import StatsElementChartFilter from './components/StatsElementChartFilter';

interface Props {
  index: number;
  data: StatsElementChartDto;
}

const StatsElementChart: React.FC<Props> = props => {
  const { index, data } = props;

  const { chartsContextValue } = useContext(StatsElementContext);
  const { updateChartData, getIsChartLoading, getChartOptions } = chartsContextValue;
  const [updateChartDataFn] = updateChartData;

  let options = useMemo(() => getChartOptions(index), [index, getChartOptions]);
  const isLoading = useMemo(() => getIsChartLoading(index), [index, getIsChartLoading]);

  const initialInternalFilterValue = useMemo(() => StatsElementFiltersInternalService.getFirstFilterName(data), [data]);
  const [internalFilterValue, setInternalFilterValue] = useState(initialInternalFilterValue);

  if (
    (internalFilterValue === 'aftermarket' || internalFilterValue === 'aboveIssue') &&
    options?.title === 'Avg IPO Returns at Year-End'
  ) {
    options = Object.assign({}, options, {
      optionsList: [
        {
          yAxis: {
            title: {
              text: internalFilterValue === 'aftermarket' ? 'Aftermarket (%)' : 'Above Issue (%)',
            },
          },
          legend: {
            enabled: false,
          },
          series: [
            {
              type: 'column',
              name: 'Return From IPO Price',
            },
          ],
        },
      ],
    });
  }

  const chartValue = useMemo(
    () => StatsElementFiltersInternalService.getFilterValue<StatsElementChartData>(data, internalFilterValue),
    [data, internalFilterValue],
  );

  const chartOptionsList: OptionsListProp = useMemo(
    () => (options?.optionsList ? [chartValue, ...options.optionsList] : [chartValue]),
    [chartValue, options],
  );

  const onChangeDynamicFilter = useCallback(
    (filters: FiltersValues) => {
      updateChartDataFn(index, ObjectHelper.deleteEmptyProps(filters));
    },
    [updateChartDataFn, index],
  );

  const chartProps: ChartProps = useMemo(
    () => ({
      ...options,
      isLoading,
      filters: (
        <StatsElementChartFilter
          data={data}
          chartValue={chartValue}
          chartOptions={options}
          internalFilterValue={internalFilterValue}
          onChangeDynamicFilter={onChangeDynamicFilter}
          onChangeInternalFilter={setInternalFilterValue}
        />
      ),
      optionsList: chartOptionsList,
      description: chartValue?.summary,
    }),
    [isLoading, chartOptionsList, chartValue, options, data, internalFilterValue, onChangeDynamicFilter],
  );

  const chart: React.ReactNode = useMemo(() => ChartFactory.getChart(chartProps, options?.type), [chartProps, options]);

  return <SecondarySection>{chart}</SecondarySection>;
};

export default StatsElementChart;
