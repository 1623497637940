import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export function useSelectedTimeFrame(): string | null {
  const urlLocation = useLocation();
  const pathName = urlLocation.pathname;
  const selectedTimeFrameKey = `${pathName}-selected-time-frame`;
  const selectedTimeFrame = sessionStorage.getItem(selectedTimeFrameKey);

  return useMemo(() => selectedTimeFrame, [selectedTimeFrame]);
}
