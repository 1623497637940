import React from 'react';
import Table, { TableProps } from '../../table/Table';
import { GridColumn } from '../shared/interfaces/GridColumn';
import './SimpleGrid.scss';
import { GridRow } from '../shared/types/GridRow';
import { SimpleGridType } from './enums/SimpleGridType';
import { useClassName } from '../../../hooks/useClassName';
import { SimpleGridBlock, SimpleGridElement, SimpleGridModifier } from './SimpleGridBem';
import { ControlSize } from '../../../enums/ControlSize';
import PageLoader from '../../loader/PageLoader';
import { ColorScheme } from '../../../enums/ColorScheme';
import SimpleGridHeader from './components/SimpleGridHeader';
import SimpleGridBody from './components/SimpleGridBody';
import { SimpleGridGetRowStyleFn } from './types/SimpleGridGetRowStyleFn';
import HtmlStringFormatter from '../../HtmlStringFormatter';

export interface SimpleGridPropsCommon extends TableProps {
  className?: string;
  rows: GridRow[];
  isHeaderHidden?: boolean;
  styleType?: SimpleGridType;
  functionalType?: SimpleGridType;
  size?: ControlSize;
  isLoading?: boolean;
  hasAroundBorder?: boolean;
  hasHtmlFormatter?: boolean;
  isResponsive?: boolean;
  colorScheme?: ColorScheme;
  isHighlightLastRow?: boolean;
  isStickyFirstColumn?: boolean;
  backgroundColorScheme?: ColorScheme;
  isColumnHeaderNoWrap?: boolean;
  isAutoLayout?: boolean;
  isFullWidth?: boolean;
  isAutoCellWidth?: boolean;
  isTickerPinned?: boolean;
  getRowStyle?: SimpleGridGetRowStyleFn;
}

export interface SimpleGridProps extends SimpleGridPropsCommon {
  columns: GridColumn[];
}

const SimpleGrid: React.FC<SimpleGridProps> = props => {
  const {
    columns,
    rows,
    styleType = SimpleGridType.Default,
    functionalType = SimpleGridType.Default,
    hasAroundBorder = true,
    isResponsive = true,
    size = ControlSize.Medium,
    colorScheme = ColorScheme.Gray,
    isAutoCellWidth,
  } = props;

  const cn = useClassName(SimpleGridBlock.Root, props.className);

  return (
    <PageLoader loading={props.isLoading} isOverlay>
      <div
        className={cn({
          [styleType]: styleType,
          [size]: size,
          [SimpleGridModifier.AroundBorder]: hasAroundBorder,
          [SimpleGridModifier.HeaderHidden]: props.isHeaderHidden,
          [SimpleGridModifier.HighlightLastRow]: props.isHighlightLastRow,
          [`${props.backgroundColorScheme}${SimpleGridModifier.BackgroundColorScheme}`]: props.backgroundColorScheme,
          [colorScheme]: colorScheme,
          [SimpleGridModifier.AutoLayout]: props.isAutoLayout,
          [SimpleGridModifier.FullWidth]: props.isFullWidth,
          [`isTickerPinned`]: props.isTickerPinned,
        })}
      >
        {props.title && (
          <h6 className={cn(SimpleGridElement.Title)}>
            <HtmlStringFormatter content={props.title as string} />
          </h6>
        )}

        <Table {...props} isResponsive={isResponsive} isStickyFirstColumn={props.isStickyFirstColumn}>
          {!props.isHeaderHidden && (
            <SimpleGridHeader
              columns={columns}
              functionalType={functionalType}
              isNoWrap={props.isColumnHeaderNoWrap}
              isAutoCellWidth={isAutoCellWidth}
            />
          )}

          <SimpleGridBody
            hasHtmlFormatter={props.hasHtmlFormatter}
            columns={columns}
            rows={rows}
            functionalType={functionalType}
            isAutoCellWidth={isAutoCellWidth}
            getRowStyle={props.getRowStyle}
          />
        </Table>
      </div>
    </PageLoader>
  );
};

export default SimpleGrid;
