import { RoutePath } from '../enums/RoutePath';
import { ViewRoutePath } from '../entities/view/components/view/types/ViewRoutePath';

export type DocumentHeadRoutePath =
  | RoutePath.Home
  | RoutePath.Research
  | RoutePath.Features
  | RoutePath.WhoUsesUs
  | RoutePath.Pricing
  | RoutePath.About
  | RoutePath.Login
  | RoutePath.MyFavourites
  | RoutePath.LoginHelp
  | RoutePath.UserPrivacy
  | RoutePath.ResetPassword
  | RoutePath.ScreensAdd
  | RoutePath.NotFound
  | RoutePath.NotAuthorized
  | RoutePath.MasterCalendar
  | RoutePath.ResearchDashboard
  | RoutePath.SearchResults
  | RoutePath.Stats
  | RoutePath.StatsPricing
  | RoutePath.StatsProceeds
  | RoutePath.StatsFilings
  | RoutePath.StatsIndustry
  | RoutePath.StatsGlobalBreakdown
  | RoutePath.StatsRecentIposReturns
  | RoutePath.StatsHistoricalIPOReturns
  | RoutePath.StatsBacked
  | RoutePath.AdditionalStats
  | RoutePath.AdditionalStatsLargestUSIPOs
  | RoutePath.AdditionalStatsLargestGlobalIPOs
  | RoutePath.AdditionalStatsPricingsComparedToRange
  | RoutePath.AdditionalStatsUSListingsFromNonUSIssuers
  | RoutePath.AdditionalStatsProfitableVsUnprofitable
  | RoutePath.AdditionalStatsWithdrawals
  | RoutePath.AdditionalStatsAverageAge
  | RoutePath.AdditionalStatsMedianAge
  | RoutePath.ResearchSpecialReports
  | ViewRoutePath;

export interface DocumentHeadData {
  title: string;
  description: string;
}

type DocumentHead = {
  [key in DocumentHeadRoutePath]: DocumentHeadData;
};

export const DOCUMENT_HEAD: DocumentHead = {
  [RoutePath.Home]: {
    title: 'Pre-IPO Research Service',
    description:
      'Pre-IPO Institutional Research Service offering IPO ratings, ' +
      'IPO Reports, IPO Alerts, DCF Models and IPO Insights',
  },

  [RoutePath.Research]: {
    title: 'Pre-IPO Research Reports & Ratings',
    description: 'Pre-IPO Institutional Research Reports, IPO Ratings, DCF Models, and Opinions',
  },

  [RoutePath.Features]: {
    title: 'IPO Alerts, Models & Screening',
    description: 'Pre-IPO Reports with Investment Ratings, Financial Models, IPO Email Alerts, IPO Screening',
  },

  [RoutePath.WhoUsesUs]: {
    title: 'Our Clients',
    description:
      'Pre-IPO Research Platform used by Hedge Funds, Investment Banks, Asset Managers and VC/Private Equity Firms',
  },

  [RoutePath.Pricing]: {
    title: 'Pricing',
    description: 'Valuable Pre-IPO Research, IPO Insights, IPO Ratings, IPO Calendars, IPO News and Commentaries',
  },

  [RoutePath.About]: {
    title: 'Pre-IPO Research',
    description:
      'We provide pre-IPO institutional research with our IPO Intelligence paltform, ' +
      'IPO-focused investment products and IPO Pro - an online portal for retail investors. ' +
      'Our mission is to help investors uncover and profit from IPOs. - Renaissance Capital',
  },

  [RoutePath.ResetPassword]: {
    title: 'Reset Password',
    description: 'Pre-IPO Form of Reset a Password',
  },

  [RoutePath.MyFavourites]: {
    title: 'My Favorites',
    description: 'Pre-IPO My Favorites Page',
  },

  [RoutePath.ScreensAdd]: {
    title: 'IPO Screens - New screen',
    description: 'New screen',
  },

  [RoutePath.Login]: {
    title: 'Login',
    description: 'Pre-IPO Login Form',
  },

  [RoutePath.LoginHelp]: {
    title: 'Login Help',
    description: 'Pre-IPO Login Help',
  },

  [RoutePath.UserPrivacy]: {
    title: 'User Privacy',
    description: 'Pre-IPO User Privacy',
  },

  [RoutePath.MasterCalendar]: {
    title: 'MasterCalendar',
    description: 'MasterCalendar',
  },

  [RoutePath.NotFound]: {
    title: 'Not found (404)',
    description: '',
  },

  [RoutePath.NotAuthorized]: {
    title: 'Not authorized (401)',
    description: '',
  },

  [RoutePath.ResearchDashboard]: {
    title: 'Research Dashboard',
    description: 'Research Dashboard',
  },

  [RoutePath.ResearchSpecialReports]: {
    title: 'Special Reports',
    description: 'Special Reports',
  },

  [RoutePath.Priced]: {
    title: 'Recently Priced IPOs',
    description: 'Recently Priced IPOs',
  },

  [RoutePath.Scheduled]: {
    title: 'Scheduled IPOs',
    description: 'Scheduled IPOs',
  },

  [RoutePath.ResearchRecent]: {
    title: 'Recent IPO Research',
    description: 'Recent IPO Research',
  },

  [RoutePath.Pipeline]: {
    title: 'IPO Pipeline',
    description: 'IPO Pipeline',
  },

  [RoutePath.PrivateCompanyWatchlist]: {
    title: 'Private Company Watchlist',
    description: 'Private Company Watchlist',
  },

  [RoutePath.PrivateCompanyMonitorList]: {
    title: 'Private Company Monitor List',
    description: 'Private Company Monitor List',
  },

  [RoutePath.ScreensUnderwriterLanding]: {
    title: 'Underwriter',
    description: 'Underwriter',
  },

  [RoutePath.ScreensUnderwriterInUse]: {
    title: 'Underwriter',
    description: 'Underwriter',
  },

  [RoutePath.ScreensIndustryLanding]: {
    title: 'Industry',
    description: 'Industry',
  },

  [RoutePath.ScreensIndustryInUse]: {
    title: 'Industry',
    description: 'Industry',
  },

  [RoutePath.ScreensLocationLanding]: {
    title: 'Location',
    description: 'Location',
  },

  [RoutePath.ScreensLocationInUse]: {
    title: 'Location',
    description: 'Location',
  },

  [RoutePath.ScreensSpacsLanding]: {
    title: 'SPACs',
    description: 'SPACs',
  },

  [RoutePath.ScreensSpacsInUse]: {
    title: 'SPACs',
    description: 'SPACs',
  },
  [RoutePath.ScreensLockUpsInUse]: {
    title: 'Lock Ups',
    description: 'Lock Ups',
  },

  [RoutePath.ScreensIndustryLanding]: {
    title: 'Industry',
    description: 'Industry',
  },

  [RoutePath.ScreensInvestorsInUse]: {
    title: 'Investors',
    description: 'Investors',
  },

  [RoutePath.ScreensInvestorsLanding]: {
    title: 'Investors',
    description: 'Investors',
  },

  [RoutePath.ScreensEstimatesInUse]: {
    title: 'Estimates',
    description: 'Estimates',
  },

  [RoutePath.ScreensWithdrawnPostponedInUse]: {
    title: 'Withdrawn and Postponed',
    description: 'Withdrawn and Postponed',
  },

  [RoutePath.ScreensCustomInUse]: {
    title: 'Custom Screen',
    description: 'Custom Screen',
  },

  [RoutePath.SearchResults]: {
    title: 'Search Results',
    description: 'Search Results',
  },

  //Stats
  [RoutePath.Stats]: {
    title: 'IPO Stats',
    description: 'IPO Stats',
  },

  [RoutePath.StatsPricing]: {
    title: 'Pricing Activity',
    description: 'Pricing Activity',
  },

  [RoutePath.StatsProceeds]: {
    title: 'Proceeds Raised',
    description: 'Proceeds Raised',
  },

  [RoutePath.StatsFilings]: {
    title: 'IPO Filing Activity',
    description: 'IPO Filing Activity',
  },

  [RoutePath.StatsIndustry]: {
    title: 'IPO Industry Breakdown',
    description: 'IPO Industry Breakdown',
  },

  [RoutePath.StatsGlobalBreakdown]: {
    title: 'Global IPOs By Region',
    description: 'Global IPOs By Region',
  },

  [RoutePath.StatsRecentIposReturns]: {
    title: 'Recent IPO Performance',
    description: 'Recent IPO Performance',
  },

  [RoutePath.StatsHistoricalIPOReturns]: {
    title: 'Historical IPO Returns',
    description: 'Historical IPO Returns',
  },

  [RoutePath.StatsBacked]: {
    title: 'Venture Capital & Private Equity Backed IPOs',
    description: 'Venture Capital & Private Equity Backed IPOs  ',
  },

  // End Stats

  // Additional Stats
  [RoutePath.AdditionalStats]: {
    title: '',
    description: '',
  },

  [RoutePath.AdditionalStatsLargestUSIPOs]: {
    title: 'Largest US IPOs',
    description: 'Largest US IPOs',
  },

  [RoutePath.AdditionalStatsLargestGlobalIPOs]: {
    title: 'Largest Global IPOs',
    description: 'Largest Global IPOs',
  },

  [RoutePath.AdditionalStatsPricingsComparedToRange]: {
    title: 'IPO Pricings Compared To Range',
    description: 'IPO Pricings Compared To Range',
  },

  [RoutePath.AdditionalStatsUSListingsFromNonUSIssuers]: {
    title: 'US Listings from Non-US Issuers',
    description: 'US Listings from Non-US Issuers',
  },

  [RoutePath.AdditionalStatsProfitableVsUnprofitable]: {
    title: 'IPO Profitability by Year',
    description: 'IPO Profitability by Year',
  },

  [RoutePath.AdditionalStatsWithdrawals]: {
    title: 'Withdrawal Activity',
    description: 'Withdrawal Activity',
  },

  [RoutePath.AdditionalStatsAverageAge]: {
    title: 'Average Age of IPOs',
    description: 'Average Age of IPOs',
  },

  [RoutePath.AdditionalStatsMedianAge]: {
    title: 'Median Age of IPOs',
    description: 'Median Age of IPOs',
  },
};
