import React from 'react';
import CompanyProfileSteps from '../shared/components/steps/CompanyProfileSteps';
import './CompanyProfileContent.scss';
import CompanyOverview from '../shared/components/overview/CompanyOverview';
import CompanyAnalystNotesAndNews from '../shared/components/analyst-notes-and-news/CompanyAnalystNotesAndNews';
import CompanyFundamentalComparisonTable from '../../shared/fundamental-comparison-table/CompanyFundamentalComparisonTable';
import CompanyValuationComparisonTable from '../../shared/valuation-comparison-table/CompanyValuationComparisonTable';
import CompanyTechnicalComparisonTable from '../../shared/technical-comparison-table/CompanyTechnicalComparisonTable';
import ProfileInsights from '../shared/components/profile-insights/ProfileInsights';
import CompanyPerfomanceVsIpoIndexChart from '../../shared/performance-vs-ipo-Index-chart/CompanyPerfomanceVsIpoIndexChart';

const CompanyProfileContent: React.FC = () => {
  return (
    <div className="CompanyProfileContent">
      <CompanyProfileSteps />

      <CompanyOverview />

      <CompanyAnalystNotesAndNews />

      <ProfileInsights />

      <CompanyFundamentalComparisonTable />

      <CompanyValuationComparisonTable />

      <CompanyTechnicalComparisonTable />

      <CompanyPerfomanceVsIpoIndexChart hasActions={false} />
    </div>
  );
};

export default CompanyProfileContent;
