import React from 'react';
import { NavItemLink } from '../../../components/nav/interfaces/NavItemLink';
import { RoutePath } from '../../../enums/RoutePath';
import { IconName } from '../../../components/icon/IconName';
import { ColorScheme } from '../../../enums/ColorScheme';
import { ControlSize } from '../../../enums/ControlSize';
import IconWithContent from '../../../components/icon/IconWithContent';
import { AuthService } from '../../../services/AuthService';

export const userAccountNavItems: NavItemLink[] = [
  {
    text: 'Account Settings',
    path: RoutePath.AccountSettings,
  },
  {
    text: 'Email Preferences',
    path: RoutePath.EmailPreferences,
  },
  {
    text: (
      <IconWithContent
        className="UserAccountSubMenu__favourites"
        name={IconName.StarFilled}
        colorScheme={ColorScheme.Attention}
        size={ControlSize.Small}
        isReversed
      >
        My Favorites
      </IconWithContent>
    ),
    path: RoutePath.MyFavourites,
  },
  {
    text: 'Log Out',
    path: RoutePath.Login,
    onClick: (): void => AuthService.logout(),
  },
];
