export const MIN_PASSWORD_LENGTH = 4;
export const MAX_PASSWORD_LENGTH = 30;
//export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])./;
//export const PASSWORD_REGEX = /^(?=.*?[a-z])(?=.*?[0-9])./;
export const PASSWORD_REGEX = /^(?=.*?[a-z])./;
export const EMAIL_MIN_LENGTH = 7;
export const INPUT_MAX_LENGTH = 256;
export const EMAIL_MAX_LENGTH = INPUT_MAX_LENGTH;
export const MIN_PHONE_NUMBER_LENGTH = 8;
export const MAX_PHONE_NUMBER_LENGTH = 15;
export const PHONE_NUMBER_REGEX = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
