import { useMemo } from 'react';
import { useScrollbarWidth } from 'react-use';
import { DomService } from '../services/dom/DomService';
import { DomSearchSelector } from '../services/dom/interfaces/DomSearchSelector';

export function useScrollbarInfo(
  searchSelector: DomSearchSelector,
): { hasScrollbar: boolean; scrollbarWidth: number | undefined } {
  const hasScrollbar = DomService.hasElementScrollbar(searchSelector.context, searchSelector.selector);
  const scrollbarWidth = useScrollbarWidth();

  return useMemo(() => ({ scrollbarWidth, hasScrollbar }), [scrollbarWidth, hasScrollbar]);
}
