import React, { useMemo } from 'react';
import './CompanyHeaderTrading.scss';
// import RotatedGridWithElements from '../../../../../shared/components/rotated-grid/RotatedGridWithElements';
// import { ControlSize } from '../../../../../shared/enums/ControlSize';
import { useClassName } from '../../../../../shared/hooks/useClassName';
import { getCompanyHeaderTradingTable } from './constants/getCompanyHeaderTradingTable';
import { CompanyTradingDto } from '../../../../interfaces/CompanyTradingDto';

interface Props {
  data: CompanyTradingDto;
}

const CompanyHeaderTrading: React.FC<Props> = props => {
  const cn = useClassName('CompanyHeaderTradingBlock');

  const rows = useMemo(() => getCompanyHeaderTradingTable(props.data), [props.data]);
  return (
    <div className={cn()}>
      {rows.map((item, index) => {
        return (
          <>
            {item.data && (
              <div key={index} className={'CompanyHeaderTradingRow'}>
                <span className={'CompanyHeaderTradingTitle'}>{`${item.title}: `}&nbsp;</span>
                <span className={'CompanyHeaderTradingData'}>{item.data}</span>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
  // return (
  //   <RotatedGridWithElements
  //     className={cn()}
  //     rows={rows}
  //     size={ControlSize.Small}
  //     isGridAutoWidth
  //     hasNoTitleMaxWidth
  //     hasTitleNoWrap
  //   />
  // );
};

export default CompanyHeaderTrading;
