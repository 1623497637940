import React from 'react';
import { NewsSectionType } from '../../enums/NewsSectionType';
import './NewsLandingSection.scss';
import NewsTitle from '../../components/NewsTitle';
import NewsLandingList from './NewsLandingList';
import LoaderContainer from '../../../shared/components/loader/LoaderContainer';
import { newsSectionData } from '../../constants/newsSectionData';
import { NewsLandingItemDto } from '../../interfaces/NewsLandingItemDto';

interface Props {
  type: NewsSectionType;
  isLoading: boolean;
  items: NewsLandingItemDto[];
}

const NewsLandingSection: React.FC<Props> = props => {
  const { title, url } = newsSectionData[props.type];

  const isNonUs = props.type === NewsSectionType.NonUsExternalNews;

  return (
    <section className={`NewsLandingSection NewsLandingSection--${props.type}`}>
      <NewsTitle url={url}>{title}</NewsTitle>

      <div className="NewsLandingSection__content">
        {props.isLoading ? <LoaderContainer /> : <NewsLandingList items={props.items} isNonUs={isNonUs} />}
      </div>
    </section>
  );
};

export default NewsLandingSection;
