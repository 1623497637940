import { parse as parseContentDisposition } from '@tinyhttp/content-disposition';
import { ContentDispositionHeaderData } from './interfaces/ContentDispositionHeaderData';

export class HttpHeaderService {
  public static parseContentDisposition(header: string): ContentDispositionHeaderData | null {
    try {
      const result = parseContentDisposition(header);
      const fullName = result.parameters.filename;

      const fileName = fullName?.substring(0, fullName.lastIndexOf('.'));
      const extension = fullName?.substring(fullName.lastIndexOf('.') + 1, fullName.length);

      return {
        fileName,
        extension,
        fullName,
      };
    } catch (e) {
      return null;
    }
  }
}
