import { StatsContext } from './stats/context/StatsContext';
import React, { useCallback, useMemo } from 'react';
import { useStatsContext } from './stats/context/useStatsContext';
import PageLoader from '../shared/components/loader/PageLoader';
import StatsRoutes from '../routes/StatsRoutes';
import { StatsApiService } from '../shared/api/StatsApiService';
import { StatsPagesFiltersSettings } from './stats/interfaces/StatsPagesFiltersSettings';
import { usePromiseState } from '../shared/hooks/promise-state/usePromiseState';
import AdditionalStatsRoutes from '../routes/AdditionalStatsRoutes';

const Stats: React.FC = () => {
  const [data, isLoading] = usePromiseState(
    useCallback(() => StatsApiService.getFiltersSettings(), []),
    null,
  );
  const isRoutesAvailable = useMemo(() => data && !isLoading, [data, isLoading]);

  return (
    <PageLoader loading={!isRoutesAvailable}>
      <StatsContext.Provider value={useStatsContext(data as StatsPagesFiltersSettings)}>
        <StatsRoutes />

        <AdditionalStatsRoutes />
      </StatsContext.Provider>
    </PageLoader>
  );
};

export default Stats;
