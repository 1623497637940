import React from 'react';
import FilterControl from '../../FilterControl';
import { ViewFilterName } from '../../../../../enum/types/ViewFilterName';
import FilterSectionsAdvancedControls from '../../FilterSectionsAdvancedControls';

export const RenaissanceRatingDetails: React.FC = () => {
  return (
    <>
      <FilterSectionsAdvancedControls>
        <FilterControl name={ViewFilterName._RatingFundamentals} />

        <FilterControl name={ViewFilterName._RatingCorpGov} />

        <FilterControl name={ViewFilterName._RatingValuation} />

        <FilterControl name={ViewFilterName._RatingTechnicals} />
      </FilterSectionsAdvancedControls>
    </>
  );
};

export default RenaissanceRatingDetails;
