import React from 'react';
import { links } from './links';
import FooterMenuItem, { FooterMenuItemProps } from './FooterMenuItem';
import './FooterMenu.scss';

const FooterMenu: React.FC = () => {
  return (
    <nav className="FooterMenu">
      <ul className="FooterMenu__list">
        {links.map((link: FooterMenuItemProps, index: number) => (
          <FooterMenuItem {...link} key={index} />
        ))}
      </ul>
    </nav>
  );
};

export default FooterMenu;
