import React from 'react';
import './ViewGridToolbarGroup.scss';
import { useClassName } from '../../../../../../hooks/useClassName';
import { ViewGridToolbarGroupBem } from './viewGridToolbarGroupBem';

interface Props {
  className?: string;
}

const ViewGridToolbarGroup: React.FC<Props> = props => {
  const cn = useClassName(ViewGridToolbarGroupBem.Root, props.className);

  return <div className={cn()}>{props.children}</div>;
};

export default ViewGridToolbarGroup;
