import { BreakpointName } from '../../../../../shared/enums/BreakpointName';

export const profileMobileBreakpoints: BreakpointName[] = [
  BreakpointName.Medium,
  BreakpointName.Small,
  BreakpointName.ExtraSmall,
];

export const mgmtTableMobileBreakpoints: BreakpointName[] = [
  BreakpointName.Large,
  BreakpointName.Medium,
  BreakpointName.Small,
  BreakpointName.ExtraSmall,
];

export const mobileMenuBreakpoints: BreakpointName[] = [BreakpointName.Small, BreakpointName.ExtraSmall];
