import React from 'react';
import { DashboardWidgetComponent } from '../../interfaces/DashboardWidgetComponent';
import { ControlSize } from '../../../shared/enums/ControlSize';
import './DashboardMyFavouritesWidget.scss';
import DashboardTable from './shared/DashboardTable';
import TabsContainer, { TabsType } from '../../../shared/components/tabs/TabsContainer';
import Tab from '../../../shared/components/tabs/Tab';
import { useClassName } from '../../../shared/hooks/useClassName';
import { DashboardMyFavouritesWidgetBlock } from './dashboardMyFavouritesWidgetBem';
import { DashboardMyFavouritesDto } from '../../interfaces/DashboardMyFavouritesDto';
import DashboardTabsWidget from './shared/DashboardTabsWidget';

const DashboardMyFavouritesWidget: DashboardWidgetComponent<DashboardMyFavouritesDto> = props => {
  const { data } = props;

  const cn = useClassName(DashboardMyFavouritesWidgetBlock.Root);

  return (
    <DashboardTabsWidget className={cn()}>
      <TabsContainer type={TabsType.Tab} size={ControlSize.ExtraSmall}>
        <Tab eventKey="1" title="Priced">
          <DashboardTable width={props.dimensions?.width} dataTable={data.priced} pinTickerColumn />
        </Tab>

        <Tab eventKey="2" title="Upcoming">
          <DashboardTable width={props.dimensions?.width} dataTable={data.upcoming} pinTickerColumn />
        </Tab>
      </TabsContainer>
    </DashboardTabsWidget>
  );
};

export default DashboardMyFavouritesWidget;
