import React from 'react';
import { Route } from 'react-router';
import { RoutePath } from '../shared/enums/RoutePath';
import ScreensAdd from '../screens/components/screens/custom/components/add/ScreensAdd';
import ScreensUnderwriterLanding from '../screens/components/screens/underwriter/ScreensUnderwriterLanding';
import ScreensUnderwriterInUse from '../screens/components/screens/underwriter/ScreensUnderwriterInUse';
import ScreensIndustryLanding from '../screens/components/screens/industry/ScreensIndustryLanding';
import ScreensIndustryInUse from '../screens/components/screens/industry/ScreensIndustryInUse';
import ScreensInvestorsLanding from '../screens/components/screens/investors/ScreensInvestorsLanding';
import ScreensInvestorsInUse from '../screens/components/screens/investors/ScreensInvestorsInUse';
import ScreensLocationLanding from '../screens/components/screens/location/ScreensLocationLanding';
import ScreensLocationInUse from '../screens/components/screens/location/ScreensLocationInUse';
import ScreensLockUpsInUse from '../screens/components/screens/lockups/ScreensLockUpsInUse';
import ScreensExpiredLockUpsInUse from '../screens/components/screens/expired-lockups/ScreensExpiredLockUpsInUse';
import ScreensEstimatesInUse from '../screens/components/screens/estimates/ScreensEstimatesInUse';
import ScreensCustomInUse from '../screens/components/screens/custom/components/in-use/ScreensCustomInUse';
import { useScreensContextValue } from '../screens/contexts/useScreensContextValue';
import { ScreensContext } from '../screens/contexts/screensContext';
import ScreensWithdrawnPostponedInUse from '../screens/components/screens/withdrawn-postponed/ScreensWithdrawnPostponedInUse';
import ScreensSpacsLanding from '../screens/components/screens/spacs/ScreensSpacsLanding';
import ScreensSpacsInUse from '../screens/components/screens/spacs/ScreensSpacsInUse';

const ScreensRoutes: React.FC = () => {
  return (
    <ScreensContext.Provider value={useScreensContextValue()}>
      <Route path={RoutePath.ScreensAdd}>
        <ScreensAdd />
      </Route>

      <Route path={RoutePath.ScreensUnderwriterLanding}>
        <ScreensUnderwriterLanding />
      </Route>

      <Route path={RoutePath.ScreensUnderwriterInUse}>
        <ScreensUnderwriterInUse />
      </Route>

      <Route path={RoutePath.ScreensIndustryLanding}>
        <ScreensIndustryLanding />
      </Route>

      <Route path={RoutePath.ScreensIndustryInUse}>
        <ScreensIndustryInUse />
      </Route>

      <Route path={RoutePath.ScreensInvestorsLanding}>
        <ScreensInvestorsLanding />
      </Route>

      <Route path={RoutePath.ScreensSpacsLanding}>
        <ScreensSpacsLanding />
      </Route>

      <Route path={RoutePath.ScreensInvestorsInUse}>
        <ScreensInvestorsInUse />
      </Route>

      <Route path={RoutePath.ScreensLocationLanding}>
        <ScreensLocationLanding />
      </Route>

      <Route path={RoutePath.ScreensLocationInUse}>
        <ScreensLocationInUse />
      </Route>

      <Route path={RoutePath.ScreensEstimatesInUse}>
        <ScreensEstimatesInUse />
      </Route>

      <Route path={RoutePath.ScreensLockUpsInUse}>
        <ScreensLockUpsInUse />
      </Route>

      <Route path={RoutePath.ScreensExpiredLockUpsInUse}>
        <ScreensExpiredLockUpsInUse />
      </Route>

      <Route path={RoutePath.ScreensSpacsInUse}>
        <ScreensSpacsInUse />
      </Route>

      <Route path={RoutePath.ScreensCustomInUse}>
        <ScreensCustomInUse />
      </Route>

      <Route path={RoutePath.ScreensWithdrawnPostponedInUse}>
        <ScreensWithdrawnPostponedInUse />
      </Route>
    </ScreensContext.Provider>
  );
};

export default ScreensRoutes;
