import React from 'react';
import { CustomAny } from '../../../../../../types/generics';
import { NO_CELL_VALUE_MESSAGE } from '../../../constants/cell';
import { isNil } from 'lodash';
import { useClassName } from '../../../../../../hooks/useClassName';
import { CustomCellFormatterWrapperBlock } from './CustomCellFormatterWrapperBem';

interface Props {
  value: CustomAny | undefined;
  className?: string;
  onClick?: () => void;
}

const CustomCellFormatterWrapper: React.FC<Props> = props => {
  const cn = useClassName(CustomCellFormatterWrapperBlock.Root, props.className);

  return (
    <>
      {!isNil(props.value) ? (
        <div className={cn()} onClick={props.onClick}>
          {props.children}
        </div>
      ) : (
        NO_CELL_VALUE_MESSAGE
      )}
    </>
  );
};

export default CustomCellFormatterWrapper;
