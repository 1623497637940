import { backedIposStatsElementOptions } from '../../../../backed-ipos/constants/backedIposStatsElementOptions';
import { StatsElementOptions } from '../../../../../../shared/entities/stats-element/interfaces/StatsElementOptions';
import { BlockOrientation } from '../../../../../../shared/enums/BlockOrientation';

export const ventureCapitalStatsElementOptions: StatsElementOptions = {
  ...backedIposStatsElementOptions,
  pageOptions: {
    chartsOrientation: BlockOrientation.Line,
  },
};
