import React from 'react';
import './DashboardTabsWidget.scss';
import { DashboardTabsWidgetBlock } from './dashboardTabsWidgetBem';
import { useClassName } from '../../../../shared/hooks/useClassName';

interface Props {
  className?: string;
}

const DashboardTabsWidget: React.FC<Props> = props => {
  const cn = useClassName(DashboardTabsWidgetBlock.Root, props.className);

  return <div className={cn()}>{props.children}</div>;
};

export default DashboardTabsWidget;
