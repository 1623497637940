import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import './HeaderPublicMenu.scss';
import { RoutePath } from '../../enums/RoutePath';

const HeaderPublicMenu: FunctionComponent = () => {
  return (
    <nav className="HeaderPublicMenu">
      <ul className="HeaderPublicMenu__list">
        <li className="HeaderPublicMenu__item HeaderPublicMenu__item--contact">
          <Link to={RoutePath.Contact}>Contact Us &gt;</Link>
        </li>

        <li className="HeaderPublicMenu__item">
          <Link to={RoutePath.Login} className="btn btn-sm btn-outline-primary text-uppercase">
            Login
          </Link>
        </li>

        <li className="HeaderPublicMenu__item">
          <Link to={RoutePath.RequestTrial} className="HeaderPublicMenu__request btn btn-sm text-uppercase">
            Free trial
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default HeaderPublicMenu;
