import React from 'react';
import { useClassName } from '../../../../../../hooks/useClassName';
import { ViewGridFooterBlock } from './viewGridFooterBem';

const ViewGridFooter: React.FC = props => {
  const cn = useClassName(ViewGridFooterBlock.Root);

  return <div className={cn()}>{props.children}</div>;
};

export default ViewGridFooter;
