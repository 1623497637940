import React from 'react';
import BootstrapTable from 'react-bootstrap/Table';
import './Table.scss';
import { ControlSize } from '../../enums/ControlSize';
import { useClassName } from '../../hooks/useClassName';
import { TableBlock, TableModifier } from './TableBem';

export interface TableProps {
  className?: string;
  title?: React.ReactNode;
  size?: ControlSize;
  isResponsive?: boolean;
  isStickyFirstColumn?: boolean;
}

const Table: React.FC<TableProps> = props => {
  const cn = useClassName(TableBlock.Root, props.className);

  return (
    <BootstrapTable
      size={props.size}
      responsive={props.isResponsive}
      className={cn({
        [TableModifier.StickyFirstColumn]: props.isStickyFirstColumn,
      })}
    >
      {props.children}
    </BootstrapTable>
  );
};

Table.defaultProps = {
  size: ControlSize.Medium,
};

export default Table;
