import React, { useCallback, useContext, useMemo } from 'react';
import PrimarySection from '../../../../../../shared/components/primary-section/PrimarySection';
import { useClassName } from '../../../../../../shared/hooks/useClassName';
import './ScreensAdd.scss';
import { ScreensAddBlock } from './ScreensAddBem';
import ScreensAddWizard from './components/ScreensAddWizard';
import { RoutePath } from '../../../../../../shared/enums/RoutePath';
import DocumentHead from '../../../../../../shared/components/DocumentHead';
import { ScreensContext } from '../../../../../contexts/screensContext';
import { usePromiseState } from '../../../../../../shared/hooks/promise-state/usePromiseState';
import { ViewApiService } from '../../../../../../shared/api/ViewApiService';
import { ViewFiltersDictionaries } from '../../../../../../shared/entities/filters/interfaces/ViewFiltersDictionaries';
import { ViewColumnsOptionsDto } from '../../../../../../shared/entities/view/components/view/components/view-columns/add/interfaces/dto/ViewColumnsOptionsDto';
import { screensPageAdditionalAction } from '../../../../shared/pages/constants/screensPageAdditionalAction';

const ScreensAdd: React.FC = () => {
  const cn = useClassName(ScreensAddBlock.Root);

  const { isContextDataLoading } = useContext(ScreensContext);
  const [columnsOptions, isColumnsOptionsLoading] = usePromiseState<ViewColumnsOptionsDto>(
    useCallback(() => ViewApiService.getColumns(), []),
  );
  const [filtersDictionaries, isFiltersDictionariesLoading] = usePromiseState<ViewFiltersDictionaries>(
    useCallback(() => ViewApiService.getCustomScreenFiltersDictionaries(), []),
  );

  const isLoading = useMemo(() => isContextDataLoading || isColumnsOptionsLoading || isFiltersDictionariesLoading, [
    isContextDataLoading,
    isColumnsOptionsLoading,
    isFiltersDictionariesLoading,
  ]);

  return (
    <PrimarySection className={cn()} isLoading={isLoading} additionalAction={screensPageAdditionalAction}>
      <DocumentHead path={RoutePath.ScreensAdd} />

      {filtersDictionaries && columnsOptions && (
        <ScreensAddWizard filtersDictionaries={filtersDictionaries} columnsOptions={columnsOptions} />
      )}
    </PrimarySection>
  );
};

export default ScreensAdd;
