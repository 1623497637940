import { cloneDeep } from 'lodash';
import { FilterControlSettings } from '../../types/FilterControlSettings';
import { ViewFilterName } from '../../enum/types/ViewFilterName';
import { CustomAny } from '../../../../types/generics';
import { BlockOrientation } from '../../../../enums/BlockOrientation';
import { investorsControls } from './view-filter-sections/investorsControls';
import { dealInfoControls } from './view-filter-sections/dealInfoControls';
import { dealStatusControls } from './view-filter-sections/dealStatusControls';
import { companyInfoControls } from './view-filter-sections/companyInfoControls';
import { ResearchType } from '../../enum/types/ResearchType';
import { researchControls } from './researchControls';
import { NewsFilterField } from '../../../../../news/enums/NewsFilterField';
import { newsFilterControls } from './newsFilterControls';

export const searchFilterControls: FilterControlSettings = cloneDeep({
  [ViewFilterName.Underwriter]: dealInfoControls[ViewFilterName.Underwriter],
  [ViewFilterName.UnderwriterTypeKey]: dealInfoControls[ViewFilterName.UnderwriterTypeKey],
  [ViewFilterName.UnderwriterRole]: dealInfoControls[ViewFilterName.UnderwriterRole],
  [ViewFilterName.DealStatusDealStatusList]: dealStatusControls[ViewFilterName.DealStatusDealStatusList],
  [ViewFilterName.DealStatusSelect]: dealStatusControls[ViewFilterName.DealStatusSelect],
  [ViewFilterName.Industry]: companyInfoControls[ViewFilterName.Industry],
  [ViewFilterName.SectorIndustry]: companyInfoControls[ViewFilterName.SectorIndustry],
  [ViewFilterName.Investor]: investorsControls[ViewFilterName.Investor],
  [ViewFilterName.Ownership]: investorsControls[ViewFilterName.Ownership],
  [ViewFilterName.TopShareholder]: investorsControls[ViewFilterName.TopShareholder],
  [ViewFilterName.HistoryShareholders]: investorsControls[ViewFilterName.HistoryShareholders],

  [ResearchType.ReportProfile]: researchControls[ResearchType.ReportProfile],
  [ResearchType.ManagementQuestions]: researchControls[ResearchType.ManagementQuestions],
  [ResearchType.AnalystNote]: researchControls[ResearchType.AnalystNote],

  [NewsFilterField.NewsType]: newsFilterControls[NewsFilterField.NewsType],
  [NewsFilterField.StoryType]: newsFilterControls[NewsFilterField.StoryType],
});

// Add default fields to search filter controls
Object.keys(searchFilterControls).forEach(controlName => {
  // TODO: Fix Custom any with strict types for filter control settings
  (searchFilterControls as CustomAny)[controlName as ViewFilterName].props.orientation = BlockOrientation.Line;
});
