import React from 'react';
import FilterControl from '../../FilterControl';
import { ViewFilterName } from '../../../../../enum/types/ViewFilterName';
import FilerControlChildren from '../../FilerControlChildren';
import { useFormContext } from 'react-hook-form';

const InsiderSellingControls: React.FC = () => {
  const formContext = useFormContext();
  const { watch } = formContext;

  const isInsiderSelling: boolean | undefined = watch(ViewFilterName.InsiderSelling);

  return (
    <>
      <FilterControl name={ViewFilterName.InsiderSelling} />

      <FilerControlChildren hasChildren={isInsiderSelling}>
        <FilterControl name={ViewFilterName.InsiderSellingPct} />
      </FilerControlChildren>
    </>
  );
};

export default InsiderSellingControls;
