import React from 'react';
import SecondarySectionExpandable from '../shared/components/secondary-section/SecondarySectionExpandable';
import ReportsGrid from '../shared/entities/view/components/view-grid/components/grids/ReportsGrid';
import { ReportDto } from './interfaces/ReportDto';

interface Props {
  title: string;
  reports: ReportDto[];
}

const expandingLength = 5;

const SpecialReportSection: React.FC<Props> = props => {
  return (
    <SecondarySectionExpandable
      title={props.title}
      component={ReportsGrid}
      expandData={props.reports}
      expandingLength={expandingLength}
      isFullWidthTitle={false}
    />
  );
};

export default SpecialReportSection;
