import React from 'react';
import Helmet from 'react-helmet';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import PageLoader from '../../shared/components/loader/PageLoader';
import { VideosDto } from '../shared/interfaces/VideosDto';
import { usePromiseState } from '../../shared/hooks/promise-state/usePromiseState';
import VideoCard from '../shared/components/video-card/VideoCard';

interface Props {
  getMemoizedVideoTutorials: () => Promise<VideosDto[]>;
  videoId?: string;
}

// Need to change videoLocation to embedVideo when added to API

const VideoTutorialsContent: React.FC<Props> = props => {
  const [videoTutorials, areVideoTutorialsLoading] = usePromiseState(props.getMemoizedVideoTutorials, []);
  const onClick = () => {};

  return (
    <>
      <Helmet>
        <title>Video Tutorials</title>
      </Helmet>

      <PrimarySection className={'videos-section'} header="Video Tutorials">
        <PageLoader loading={areVideoTutorialsLoading}>
          {videoTutorials.map((item, index) => {
            return (
              <VideoCard
                key={index}
                title={item.title}
                videoTypeKey={item.videoTypeKey}
                videoLocation={item.videoLocation}
                embedCode={item.embedCode}
                imageLocation={item.imageLocation}
                imageLocationTypeKey={item.imageLocationTypeKey}
                videoKey={item.videoKey}
                videoId={props.videoId}
                descript={item.descript}
                onClick={onClick}
              />
            );
          })}
        </PageLoader>
      </PrimarySection>
    </>
  );
};

export default VideoTutorialsContent;
