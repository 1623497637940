import React, { useCallback, useState } from 'react';
import Button from '../../../shared/components/forms/Button';
import { ColorScheme } from '../../../shared/enums/ColorScheme';
import './DashboardEdit.scss';
import DashboardEditModal from './DashboardEditModal';
import DashboardEditDefaults from './DashboardEditDefaults';
import { ControlSize } from '../../../shared/enums/ControlSize';
import { useClassName } from '../../../shared/hooks/useClassName';
import { DashboardEditBlock } from './DashboardEditBem';

const DashboardEdit: React.FC = () => {
  const showState = useState(false);
  const [, setIsShow] = showState;
  const cn = useClassName(DashboardEditBlock.Root);

  const onEditClick = useCallback(() => setIsShow(true), [setIsShow]);

  return (
    <div className={cn()}>
      <Button onClick={onEditClick} colorScheme={ColorScheme.Primary} size={ControlSize.Small} isOutline={true}>
        Customize Dashboard
      </Button>
      <DashboardEditDefaults />

      <DashboardEditModal showState={showState} />
    </div>
  );
};

export default DashboardEdit;
