import { Context, createContext } from 'react';
import { DashboardWidgetConfig } from './interfaces/DashboardWidgetConfig';
import { DashboardWidgetType } from './enums/DashboardWidgetType';

export interface DashboardContextValue {
  isDefaults: boolean;
  widgets: DashboardWidgetConfig[];
  setWidgets: (widgets: DashboardWidgetConfig[]) => void;
  setDefaultWidgets: () => void;
  setWidgetTypes: (types: DashboardWidgetType[]) => void;
  onWidgetsChange: (widgets: DashboardWidgetConfig[]) => void;
}

export const DashboardContext: Context<DashboardContextValue> = createContext<DashboardContextValue>({
  widgets: [],
  isDefaults: true,
  setWidgets() {},
  setDefaultWidgets() {},
  setWidgetTypes() {},
  onWidgetsChange() {},
});
