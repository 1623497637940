export enum TimeFrame {
  Day = 'day',
  Week = 'Week',
  altWeek = 'Wk',
  altMonth = 'Mo',
  Month = '30Days',
  Quarter = '90Days',
  YTD = 'ytd',
  LastFiscalYear = 'lastFiscalYear',
  UpcomingFiscalYear = 'upcomingFiscalYear',
  Year = 'year',
  TwoYears = '2years',
  FiveYears = '5years',
  ThisWeek = 'thisWeek',
  NextWeek = 'nextWeek',
  All = 'all',
  FromIPO = 'fromIpo',
  FiveDays = '5Days',
  TenDays = '10Days',
  LTM = 'lastTwelveMonth',
  Total = 'total',
  FirstDay = 'firstDay',
  Aftermarket = 'aftermarket',
  AboveIssue = 'aboveIssue',
}
