import React from 'react';
import { privateCompanyWatchlistViewOptions } from './constants/privateCompanyWatchlistViewOptions';
import { ViewType } from '../../shared/entities/view/components/view/enums/ViewType';
import View from '../../shared/entities/view/components/view/View';

const PrivateCompanyWatchlist: React.FC = () => {
  return <View options={privateCompanyWatchlistViewOptions} viewType={ViewType.PrivateCompanyWatchlist} />;
};

export default PrivateCompanyWatchlist;
