import React from 'react';
import { useClassName } from '../../../../../../hooks/useClassName';
import { ViewGridTopContentBlock } from './viewGridTopContentBem';

const ViewGridTopContent: React.FC = props => {
  const cn = useClassName(ViewGridTopContentBlock.Root);

  return <div className={cn()}>{props.children}</div>;
};

export default ViewGridTopContent;
