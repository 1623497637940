import React, { useCallback, useEffect } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import Select, { SelectCommonProps } from './Select';
import { SelectOptionValue } from '../../../../services/select/types/SelectOptionValue';

export interface FormSelectProps extends SelectCommonProps {
  name: string;
}

const FormSelect: React.FC<FormSelectProps> = props => {
  const { register, setValue, watch, errors, triggerValidation } = useFormContext();
  const value: SelectOptionValue | SelectOptionValue[] = watch && watch(props.name);

  // TODO: Move logic to form or error component
  const error: FieldError | void = errors?.[props.name] as FieldError;

  useEffect(() => register?.({ name: props.name }), [register, props.name]);

  const onChange = useCallback(
    value => {
      setValue?.(props.name, value);
      triggerValidation(props.name);
    },
    [props.name, setValue, triggerValidation],
  );

  return <Select {...props} values={value} onChange={onChange} error={error} />;
};

export default FormSelect;
