import React, { useCallback } from 'react';
import './SimpleGridBody.scss';
import TableBody from '../../../table/TableBody';
import { GridRow } from '../../shared/types/GridRow';
import TableRow from '../../../table/TableRow';
import { GridColumn } from '../../shared/interfaces/GridColumn';
import SimpleGridRowNoData from './SimpleGridRowNoData';
import GridCell from '../../shared/components/cell/GridCell';
import { GridCellDataService } from '../../shared/services/GridCellDataService';
import { SimpleGridType } from '../enums/SimpleGridType';
import { useClassName } from '../../../../hooks/useClassName';
import { SimpleGridGetRowStyleFn } from '../types/SimpleGridGetRowStyleFn';
import { SimpleGridRowStyleType } from '../enums/SimpleGridRowStyleType';

interface Props {
  rows: GridRow[];
  columns: GridColumn[];
  functionalType: SimpleGridType;
  hasHtmlFormatter?: boolean;
  isAutoCellWidth?: boolean;
  getRowStyle?: SimpleGridGetRowStyleFn;
}

const SimpleGridBody: React.FC<Props> = props => {
  const { getRowStyle, isAutoCellWidth } = props;

  const isGridFunctionalType = props.functionalType === SimpleGridType.Grid;
  const hasRows = props.rows.length > 0;
  const columnsCount = props.columns.length;

  const cn = useClassName('SimpleGridBody');

  const getTableCell = useCallback(
    (row: GridRow, column: GridColumn) => {
      if (isGridFunctionalType) {
        return <GridCell hasHtmlFormatter={props.hasHtmlFormatter} row={row} column={column} />;
      }

      return GridCellDataService.getValue(row, column);
    },
    [isGridFunctionalType, props.hasHtmlFormatter],
  );

  const getRowStyleModifiers = useCallback(
    (row: GridRow, index: number) => {
      if (!getRowStyle) {
        return [];
      }

      const styleTypes = getRowStyle(index, row) || [];
      const hasModifier = (styleType: SimpleGridRowStyleType) => styleTypes.includes(styleType);

      return {
        // [SimpleGridRowStyleType.Bold]: hasModifier(SimpleGridRowStyleType.Bold),
        [SimpleGridRowStyleType.Italic]: hasModifier(SimpleGridRowStyleType.Italic),
      };
    },
    [getRowStyle],
  );

  return (
    <TableBody className={cn()}>
      {hasRows ? (
        props.rows.map((row: GridRow, index: number) => (
          <TableRow
            className={cn('row', {
              ...getRowStyleModifiers(row, index),
            })}
            key={index}
            isAutoCellWidth={isAutoCellWidth}
            cells={props.columns.map((column: GridColumn) => ({ value: getTableCell(row, column), column }))}
          />
        ))
      ) : (
        <SimpleGridRowNoData columnsCount={columnsCount} />
      )}
    </TableBody>
  );
};

export default SimpleGridBody;
