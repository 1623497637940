import React from 'react';
import FilterControl from '../FilterControl';
import { ViewFilterName } from '../../../../enum/types/ViewFilterName';

const DealTimingFilterSection: React.FC = () => {
  return (
    <>
      <FilterControl name={ViewFilterName._DealTimingFiledRange} />

      <FilterControl name={ViewFilterName.DealTimingUpdatedInPast} />

      <FilterControl name={ViewFilterName._DealTimingExpectedOfferTiming} />
    </>
  );
};

export default DealTimingFilterSection;
