import { Context, createContext } from 'react';
import { StatsElementType } from '../../enums/StatsElementType';
import { Nullable } from '../../../../types/generics';
import { StatsElementOptions } from '../../interfaces/StatsElementOptions';
import { FiltersValues } from '../../../filters/types/FiltersValues';
import { StatsElementData } from '../../interfaces/StatsElementData';
import { StatsElementDataFilterType } from '../../types/StatsElementDataFilterType';
import { StatsElementFilterInternalName } from '../../enums/StatsElementGridFilterName';
import { AsyncFnData } from '../../../../hooks/async-fn/interfaces/AsyncFnData';
import { StatsElementChartProps } from '../chart/interfaces/StatsElementChartProps';
import { StatsElementChartDto } from '../chart/types/StatsElementChartDto';
import { SimpleGridProps } from '../../../../components/grid/simple-grid/SimpleGrid';
import { ViewGridProps } from '../../../view/components/view-grid/ViewGrid';

export interface StatsElementChartsContextValue {
  updateChartData: AsyncFnData;
  getIsChartLoading: (index: number) => boolean;
  getChartDataType: (index: number) => string;
  getChartOptions: (index: number) => Partial<StatsElementChartProps> | undefined;
  highCharts?: StatsElementChartDto[];
}

export interface StatsElementContextValue extends StatsElementData {
  type: Nullable<StatsElementType>;
  dataTypes: StatsElementDataFilterType[];
  dataType: StatsElementDataFilterType;
  setDataType: (type: StatsElementDataFilterType) => void;
  loadData: () => void;
  gridsToExcel: { [key: number]: Partial<SimpleGridProps | ViewGridProps> };
  addGridToGridsToExcel: (grid: Partial<SimpleGridProps | ViewGridProps>, index: number) => void;
  clearGridsToExcel: () => void;
  options?: StatsElementOptions;
  pageFilters: FiltersValues;
  isLoading: boolean;
  isContent: boolean;
  chartsContextValue: StatsElementChartsContextValue;
}

export const StatsElementContext: Context<StatsElementContextValue> = createContext<StatsElementContextValue>({
  type: null,
  dataTypes: [],
  dataType: StatsElementFilterInternalName.Default,
  setDataType() {},
  loadData() {},
  pageFilters: {},
  isLoading: false,
  isContent: false,
  grids: [],
  gridsToExcel: [],
  addGridToGridsToExcel() {},
  clearGridsToExcel() {},
  info: null,
  chartsContextValue: {
    updateChartData: [() => {}, false, undefined, () => {}, false],
    getIsChartLoading() {
      return false;
    },
    getChartDataType() {
      return '';
    },
    getChartOptions() {
      return undefined;
    },
    highCharts: [],
  },
});
