import React, { useCallback, useState } from 'react';
import './HeaderUserMenu.scss';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { RoutePath } from '../../enums/RoutePath';
import Icon from '../icon/Icon';
import { IconName } from '../icon/IconName';
import { ControlSize } from '../../enums/ControlSize';
import SubMenu from '../main-menu/sub-menu/SubMenu';
import { userAccountNavItems } from '../../entities/navigation/constants/userAccountNavItems';
import isTouchEnabled from '../../helpers/isTouchEnabled';

const HeaderUserMenu: React.FC = () => {
  const [isUserAccountShow, setIsUserAccountShow] = useState<boolean>(false);
  const [showAccountMenu, setShowAccountMenu] = useState<boolean>(false);
  const urlLocation = useLocation();
  const pathName = urlLocation.pathname;
  const isAccountPageActive =
    pathName === '/account-settings' || pathName === '/email-preferences' || pathName === '/favorites' ? true : false;
  const onUserAccountToggle = useCallback((isShow: boolean) => setIsUserAccountShow(isShow), []);

  const touchEnabledSubMenuProps = {
    title: (
      <span
        className={classNames('HeaderUserMenu__link', {
          'HeaderUserMenu__link--active': showAccountMenu || isAccountPageActive,
        })}
        title="Account Settings"
        onClick={() => setShowAccountMenu(true)}
      >
        <Icon name={IconName.User} size={ControlSize.Medium} />
      </span>
    ),
    isInUserMenu: true,
    setShow: setShowAccountMenu,
    show: showAccountMenu,
    onItemClick: () => setShowAccountMenu(false),
    items: userAccountNavItems,
    isClickForced: true,
    onToggle: onUserAccountToggle,
  };

  const subMenuProps = {
    title: (
      <NavLink
        className={classNames('HeaderUserMenu__link', {
          'HeaderUserMenu__link--active': isUserAccountShow || isAccountPageActive,
        })}
        to={RoutePath.AccountSettings}
        title="Account Settings"
      >
        <Icon name={IconName.User} size={ControlSize.Medium} />
      </NavLink>
    ),
    isInUserMenu: true,
    items: userAccountNavItems,
    onToggle: onUserAccountToggle,
  };

  return (
    <nav className="HeaderUserMenu">
      <ul className="HeaderUserMenu__list">
        <li className="HeaderUserMenu__item">
          <NavLink className="HeaderUserMenu__link" to={RoutePath.ContactUs} title="Contact Us">
            <Icon name={IconName.Chat} size={ControlSize.Medium} />
          </NavLink>
        </li>
        <li className="HeaderUserMenu__item">
          <NavLink
            className={`HeaderUserMenu__link${
              urlLocation.pathname.indexOf('/latest-videos') > -1 ||
              urlLocation.pathname.indexOf('/video-tutorials') > -1
                ? ' active'
                : ''
            }`}
            to={RoutePath.LatestVideos}
            title="Latest Videos"
          >
            <Icon name={IconName.Question} size={ControlSize.Medium} />
          </NavLink>
        </li>
        <li className="HeaderUserMenu__item">
          {<SubMenu {...(isTouchEnabled() ? touchEnabledSubMenuProps : subMenuProps)} />}
        </li>
      </ul>
    </nav>
  );
};

export default HeaderUserMenu;
