import React, { ReactNode, useMemo, useCallback } from 'react';
import { ModalEntityActionType } from './enums/ModalEntityActionType';
import { useClassName } from '../../hooks/useClassName';
import ActionModal from './ActionModal';
import { EntityActionModalBlock } from './entityActionModalBem';
import ConfirmationActionModal from './ConfirmationActionModal';
import { NotificationSuccessMessage } from '../../../store/notification/types';
import { ModalShowState } from './types/ModalShowState';
import { FormProps } from '../forms/Form';
import { CustomAny } from '../../types/generics';

export interface EntityActionModalCommonProps {
  showState: ModalShowState;
  entityName: string;
  actionType: ModalEntityActionType;
  body?: ReactNode;
  successMessage?: NotificationSuccessMessage;
  modalTrigger?: ReactNode;
  form?: FormProps;
  className?: string;
  onHide?: () => void;
}

interface Props extends EntityActionModalCommonProps {
  onAction: (data?: CustomAny) => Promise<void>;
}

const EntityActionModal: React.FC<Props> = props => {
  const { onAction } = props;

  const cn = useClassName(EntityActionModalBlock.Root, props.className);

  const getIsModalType = useCallback((type: ModalEntityActionType) => props.actionType === type, [props.actionType]);

  const isDelete = useMemo(() => getIsModalType(ModalEntityActionType.Delete), [getIsModalType]);
  const isCustom = useMemo(() => getIsModalType(ModalEntityActionType.Custom), [getIsModalType]);

  const header = useMemo(() => (isCustom ? props.entityName : `${props.actionType} ${props.entityName}`), [
    props.actionType,
    props.entityName,
    isCustom,
  ]);
  const action = useMemo(() => (isCustom ? props.entityName : props.actionType), [
    isCustom,
    props.entityName,
    props.actionType,
  ]);

  return (
    <>
      {isDelete ? (
        <ConfirmationActionModal
          className={cn()}
          showState={props.showState}
          header={header}
          bodyAction={props.actionType.toLowerCase()}
          successMessage={props.successMessage}
          modalTrigger={props.modalTrigger}
          form={props.form}
          onConfirm={onAction}
          onHide={props.onHide}
        />
      ) : (
        <ActionModal
          className={cn()}
          header={header}
          body={props.body}
          action={action}
          showState={props.showState}
          successMessage={props.successMessage}
          form={props.form}
          onAction={onAction}
          onHide={props.onHide}
        />
      )}
    </>
  );
};

export default EntityActionModal;
