import { DateRange } from '../types';
import { TimeFrame } from '../entities/filters/enum/TimeFrame';
import { DateHelper } from './date/DateHelper';

export class TimeFrameHelper {
  static getDateRangeByTimeFrame(timeFrame: TimeFrame): DateRange {
    switch (timeFrame) {
      case TimeFrame.ThisWeek: {
        const date = DateHelper.getToday();
        return [DateHelper.getStartOfWeek(date), DateHelper.getEndOfWeek(date)];
      }
      case TimeFrame.NextWeek: {
        const date = DateHelper.addWeeks(DateHelper.getToday(), 1);
        return [DateHelper.getStartOfWeek(date), DateHelper.getEndOfWeek(date)];
      }
      default:
        return [DateHelper.getToday(), DateHelper.getToday()];
    }
  }
}
