import { Reducer } from 'redux';
import { ActionTypes, State, SET_IS_PRINT } from './types';

const initialState: State = {
  isPrint: false,
};

export const reducer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_PRINT:
      return {
        ...state,
        isPrint: action.payload.isPrint,
      };
    default:
      return state;
  }
};
