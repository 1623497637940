import React from 'react';
import './AccordionHeader.scss';
import AccordionActionIcon from './AccordionActionIcon';
import { useClassName } from '../../hooks/useClassName';
import { AccordionHeaderBlock, AccordionHeaderElement, AccordionHeaderModifier } from './AccordionHeaderBem';

interface Props {
  title: React.ReactNode;
  isClose: boolean;
  isAccordionAvailable: boolean;
  isIconPin?: boolean;
  onClick: () => void;
}

const AccordionHeader: React.FC<Props> = props => {
  const cn = useClassName(AccordionHeaderBlock.Root);

  return (
    <section
      className={cn({
        [AccordionHeaderModifier.Available]: props.isAccordionAvailable,
        [AccordionHeaderElement.IconPin]: props.isIconPin,
      })}
      onClick={props.onClick}
    >
      <h5 className={cn(AccordionHeaderElement.Title)}>{props.title}</h5>

      <AccordionActionIcon isAccordionAvailable={props.isAccordionAvailable} isClose={props.isClose} />
    </section>
  );
};

AccordionHeader.defaultProps = {
  isIconPin: true,
};

export default AccordionHeader;
