import { AccessLogDto } from '../../clients-access-log/interfaces/AccessLogDto';
import ApiService from './ApiService';
import { ApiPath } from './enums/ApiPath';

export class GoogleAnalyticsApiService {
  static getAccessLog(): Promise<AccessLogDto[]> {
    return ApiService.get(ApiPath.AccessLog);
  }

  static getLandingData(startDate: string, endDate: string, reportName: string): Promise<any> {
    return ApiService.get(ApiPath.MostActiveLanding, {
      params: {
        Filter: reportName,
        StartDate: startDate,
        EndDate: endDate,
      },
    });
  }

  static getActivityPerAccount(accountId: string, startDate: string, endDate: string): Promise<any> {
    return ApiService.get(ApiPath.UsersActivityPerAccount, {
      params: {
        AccountId: accountId.split('=')[1],
        StartDate: startDate,
        EndDate: endDate,
      },
    });
  }

  static getUserActivityData(email: string, startDate: string, endDate: string): Promise<any> {
    return ApiService.get(ApiPath.UserActivityInfo, {
      params: {
        Filter: email,
        StartDate: startDate,
        EndDate: endDate,
      },
    });
  }

  static getResourceActivityData(rt: string, rv: string, startDate: string, endDate: string): Promise<any> {
    return ApiService.get(ApiPath.ResourceActivityInfo, {
      params: {
        ResourceType: rt,
        ResourceValue: rv.split('=')[1],
        StartDate: startDate,
        EndDate: endDate,
      },
    });
  }

  static getResourceActivityPageData(rt: string, rv: string, startDate: string, endDate: string): Promise<any> {
    return ApiService.get(ApiPath.ResourceActivityInfo, {
      params: {
        ResourceType: rt,
        ResourceValue: rv.replace(/\*/g, '/').split('=')[1],
        StartDate: startDate,
        EndDate: endDate,
      },
    });
  }
}
