import React, { useCallback, useMemo, useState } from 'react';
import './Wizard.scss';
import { useClassName } from '../../../../hooks/useClassName';
import { WizardBlock, WizardElement } from './wizardBem';
import Stepper from '../../../stepper/Stepper';
import ButtonsList from '../../ButtonsList';
import Button, { ButtonProps } from '../../Button';
import { ControlSize } from '../../../../enums/ControlSize';
import { ColorScheme } from '../../../../enums/ColorScheme';
import { minStepIndex } from './constants/defaults';
import { WizardService } from './services/WizardService';
import { WizardStep } from './interfaces/WizardStep';
import { ScrollService } from '../../../../services/ScrollService';
import PrimarySectionRow from '../../../primary-section/PrimarySectionRow';

interface Props {
  steps: WizardStep[];
  finishActionsProps: ButtonProps[];
  className?: string;
  isNextDisabled?: boolean;
  isAbsoluteStepTitles?: boolean;
  onFinish: () => void;
}

const initialStep = minStepIndex;

const Wizard: React.FC<Props> = props => {
  const { finishActionsProps } = props;

  const cn = useClassName(WizardBlock.Root, props.className);

  const [activeStep, setActiveStep] = useState(initialStep);

  const stepComponent = useMemo(() => WizardService.getComponent(props.steps, activeStep), [props.steps, activeStep]);

  const isPrevAvailable = useMemo(() => WizardService.isPrevAvailable(activeStep), [activeStep]);
  const isNextAvailable = useMemo(() => WizardService.isNextAvailable(activeStep, props.steps), [
    activeStep,
    props.steps,
  ]);
  const isFinishAvailable = useMemo(() => WizardService.isFinishAvailable(activeStep, props.steps), [
    activeStep,
    props.steps,
  ]);

  const finishActions = useMemo(
    () =>
      finishActionsProps.map((finishActionProps, index) => (
        <Button key={index} {...finishActionProps} size={ControlSize.Small} colorScheme={ColorScheme.Success} />
      )),
    [finishActionsProps],
  );

  const onStep = useCallback(stepFn => {
    stepFn();
    ScrollService.scrollToTop();
  }, []);

  const onPrev = useCallback(() => {
    onStep(() => setActiveStep(WizardService.getPrev(activeStep)));
  }, [activeStep, onStep]);

  const onNext = useCallback(() => {
    onStep(() => setActiveStep(WizardService.getNext(activeStep)));
  }, [activeStep, onStep]);

  return (
    <section className={cn()}>
      <PrimarySectionRow className="directionMdDownColumn">
        {/* <Tooltip
          className="myScreensToolTip"
          title="My Screens"
          content={screensPageAdditionalAction}
          position={TooltipPosition.Bottom}
          isTitleHighlighted
        /> */}

        <ButtonsList className={cn(WizardElement.Actions)} position="header">
          {isPrevAvailable && (
            <Button size={ControlSize.Small} colorScheme={ColorScheme.Primary} onClick={onPrev}>
              Previous
            </Button>
          )}

          {isNextAvailable && (
            <Button
              size={ControlSize.Small}
              isDisabled={props.isNextDisabled}
              colorScheme={ColorScheme.Primary}
              onClick={onNext}
            >
              Next
            </Button>
          )}

          {isFinishAvailable && finishActions}
        </ButtonsList>
      </PrimarySectionRow>

      <div className={cn(WizardElement.Stepper)}>
        <Stepper steps={props.steps} activeStep={activeStep} isAbsoluteStepTitles={props.isAbsoluteStepTitles} />
      </div>

      <div className={cn(WizardElement.Component)}>{stepComponent}</div>

      <ButtonsList className={cn(WizardElement.Actions)}>
        {isPrevAvailable && (
          <Button size={ControlSize.Small} colorScheme={ColorScheme.Primary} onClick={onPrev}>
            Previous
          </Button>
        )}

        {isNextAvailable && (
          <Button
            size={ControlSize.Small}
            isDisabled={props.isNextDisabled}
            colorScheme={ColorScheme.Primary}
            onClick={onNext}
          >
            Next
          </Button>
        )}

        {isFinishAvailable && finishActions}
      </ButtonsList>
    </section>
  );
};

export default Wizard;
