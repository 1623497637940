import * as yup from 'yup';
import { ScreenAddFormFields } from './ScreenAddFormFields';
import { INPUT_MAX_LENGTH } from '../../../../../../../../shared/constants/validation';

export const validationSchema = yup.object().shape({
  [ScreenAddFormFields.Name]: yup
    .string()
    .required()
    .max(INPUT_MAX_LENGTH)
    .label('Screen Name'),
});
