import React, { useCallback, useEffect, useState } from 'react';
import './CustomGridHeader.scss';
import CustomGridHeaderSort from './CustomGridHeaderSort';
import { useClassName } from '../../../../../../hooks/useClassName';
import { CustomGridHeaderBlock, CustomGridHeaderElement } from './customGridHeaderBem';
import { GridColumn } from '../../../interfaces/GridColumn';
import { GridColumnEvent } from '../../../../grid/enums/GridColumnEvent';

interface CustomGridHeaderProps {
  gridColumn: GridColumn;
  progressSort?: () => void;
  column?: {
    isSortAscending: () => boolean;
    isSortDescending: () => boolean;
    isSortNone: () => boolean;
    addEventListener: (event: GridColumnEvent, cb: () => void) => void;
    removeEventListener: (event: GridColumnEvent, cb: () => void) => void;
  };
  isNoWrap?: boolean;
}

const CustomGridHeader: React.FC<CustomGridHeaderProps> = props => {
  const { progressSort, column, gridColumn } = props;
  const { headerName, sortable, alignHorizontal, headerFormatter, hasSortIcon = true } = gridColumn;

  const cn = useClassName(CustomGridHeaderBlock.Root);

  const [isAsc, setIsAsc] = useState();
  const [isDesc, setIsDesc] = useState();
  const [isNone, setIsNone] = useState();

  const setSort = useCallback((): void => {
    if (column) {
      setIsAsc(column.isSortAscending());
      setIsDesc(column.isSortDescending());
      setIsNone(column.isSortNone());
    }
  }, [column]);

  const onSort = useCallback(() => {
    if (column && progressSort && sortable) {
      progressSort();
    }
  }, [progressSort, column, sortable]);

  useEffect(() => setSort(), [setSort]);
  useEffect(() => {
    const onSortChanged = (): void => setSort();

    if (column) {
      column.addEventListener(GridColumnEvent.SortChanged, onSortChanged);

      return (): void => {
        column.removeEventListener(GridColumnEvent.SortChanged, onSortChanged);
      };
    }
  }, [column, setSort]);

  let customCellFormatterName = '';
  if (gridColumn?.cellFormatter?.type === 'CustomCellFormatter') {
    customCellFormatterName = ` ${gridColumn?.cellFormatter?.props?.component?.name}`;
  }

  return (
    <div
      className={cn({
        [`${alignHorizontal}`]: alignHorizontal,
      })}
      onClick={onSort}
    >
      <div
        className={`${cn(CustomGridHeaderElement.Label, {
          [`${alignHorizontal}`]: alignHorizontal,
          noWrap: props.isNoWrap,
        })}${customCellFormatterName}`}
      >
        {headerFormatter?.component
          ? React.createElement(headerFormatter.component, { ...headerFormatter.props, isSortable: sortable })
          : headerName}
      </div>

      {sortable && hasSortIcon && <CustomGridHeaderSort isAsc={isAsc} isDesc={isDesc} isNone={isNone} />}
    </div>
  );
};

export default CustomGridHeader;
