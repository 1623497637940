import React from 'react';
import { useClassName } from '../../hooks/useClassName';

interface Props {
  className?: string;
}

const TableBody: React.FC<Props> = props => {
  const cn = useClassName('TableBody', props.className);

  return <tbody className={cn()}>{props.children}</tbody>;
};

export default TableBody;
