import React, { useContext, useMemo } from 'react';
import ViewColumnsAddSelectedItem from './ViewColumnsAddSelectedItem';
import { ViewColumnsAddItem } from '../../interfaces/ViewColumnsAddItem';
import { ViewColumnsAddContextProps, ViewColumnsAddContext } from '../../viewColumnsAddContext';
import DragDropItem from '../../../../../../../../../components/drag-drop/drag-drop-grid/DragDropItem';
import DragDropGrid from '../../../../../../../../../components/drag-drop/drag-drop-grid/DragDropGrid';

interface Props {
  selectedColumns: ViewColumnsAddItem[];
}

const SELECT_COLUMN_KEY = 'SELECT_COLUMN_KEY';

function useItemsComponents(columnsSelected: ViewColumnsAddItem[], isInModal?: boolean): React.ReactNode[] {
  return useMemo<React.ReactNode[]>(() => {
    const itemsComponentsNew: React.ReactNode[] = columnsSelected.map((columnItem: ViewColumnsAddItem, i) => (
      <DragDropItem
        isInModal={isInModal}
        key={columnItem.id}
        index={i}
        itemComponent={<ViewColumnsAddSelectedItem label={columnItem.label} />}
      />
    ));

    itemsComponentsNew.push(
      <DragDropItem
        isStatic={true}
        index={itemsComponentsNew.length}
        key={SELECT_COLUMN_KEY}
        itemComponent={<ViewColumnsAddSelectedItem />}
      />,
    );

    return itemsComponentsNew;
  }, [columnsSelected, isInModal]);
}

const ViewColumnsAddSelected: React.FC<Props> = (props: Props) => {
  const columnsContextValue: ViewColumnsAddContextProps = useContext<ViewColumnsAddContextProps>(ViewColumnsAddContext);

  const itemsComponents: React.ReactNode[] = useItemsComponents(props.selectedColumns, columnsContextValue.isInModal);

  return (
    <DragDropGrid
      itemsComponents={itemsComponents}
      items={props.selectedColumns}
      onSortEnd={columnsContextValue.onSortEnd}
    />
  );
};

export default ViewColumnsAddSelected;
