import React, { useMemo } from 'react';
import { SimpleGridProps } from '../../../shared/components/grid/simple-grid/SimpleGrid';
import CompanyProfileTable, {
  CompanyProfileTableProps,
} from '../profile/shared/components/company-profile-table/CompanyProfileTable';
import CompanySectionRow from './CompanySectionRow';
import { GridColumn } from '../../../shared/components/grid/shared/interfaces/GridColumn';
import { ViewGridColumnWidth } from '../../../shared/entities/view/components/view-grid/enums/ViewGridColumnWidth';
import { ViewColumnSystemName } from '../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { AlignTypesHorizontal } from '../../../shared/enums/AlignTypesHorizontal';
import useBreakpointAvailable from '../../../shared/effects/useBreakpointAvailable';
import { mgmtTableMobileBreakpoints } from '../profile/shared/constants/profileMobileBreakpoints';

interface Props {
  tables: (SimpleGridProps | null)[];
  isEqualSizes?: boolean;
}

const CompanyMapTables: React.FC<Props> = props => {
  const { isEqualSizes } = props;
  const isMobile: boolean = useBreakpointAvailable(mgmtTableMobileBreakpoints);

  const tables = useMemo(
    () =>
      props.tables
        .filter(table => !!table)
        .map(table => {
          const castedTable = table as SimpleGridProps;
          let tableProps: CompanyProfileTableProps = { ...castedTable, expandedData: castedTable.rows };

          if (isEqualSizes) {
            tableProps = {
              ...tableProps,
              isTitleDefaultWidth: true,
              isColumnsAlignEnd: true,
              onColumnMapReady: (column: GridColumn) => {
                return {
                  ...column,
                  minWidth: ViewGridColumnWidth.Xs,
                  width: ViewGridColumnWidth.Xs,
                };
              },
            };
          }
          tableProps = {
            ...tableProps,

            // TODO REFACTOR
            // Fix resize flow on MGMT/Owners
            // Also refactor similar logic applied to other tables
            onColumnMapReady: (column: GridColumn) => {
              if (isMobile) {
                if (column.field === ViewColumnSystemName.KeyShareholder) {
                  return {
                    ...column,
                    minWidth: ViewGridColumnWidth.Sm,
                    width: ViewGridColumnWidth.Sm,
                  };
                }
                if (column.field === ViewColumnSystemName.Ownership) {
                  return {
                    ...column,
                    minWidth: ViewGridColumnWidth.Md,
                    width: ViewGridColumnWidth.Md,
                    alignHorizontal: AlignTypesHorizontal.Center,
                  };
                }
              } else {
                if (column.field === ViewColumnSystemName.KeyShareholder) {
                  return {
                    ...column,
                    minWidth: ViewGridColumnWidth.Xl,
                    width: ViewGridColumnWidth.Xl,
                  };
                }
                if (column.field === ViewColumnSystemName.Ownership) {
                  return {
                    ...column,
                    minWidth: ViewGridColumnWidth.Xld,
                    width: ViewGridColumnWidth.Xld,
                    alignHorizontal: AlignTypesHorizontal.Center,
                  };
                }
              }

              return {
                ...column,
              };
            },
          };

          return tableProps;
        }),
    [props.tables, isEqualSizes],
  );

  return (
    <>
      {tables.map((table: CompanyProfileTableProps, index: number) =>
        table && table.rows && table.rows.length > 0 ? (
          <CompanySectionRow key={index}>
            <CompanyProfileTable {...table} />
          </CompanySectionRow>
        ) : null,
      )}
    </>
  );
};

export default CompanyMapTables;
