import React, { memo } from 'react';
import { isEqual } from 'lodash';
import { FormControl } from './types/formControlsTypes';
import { FormControlType } from './enums/FormControlType';
import FormCheckbox, { FormCheckboxProps } from '../../FormCheckbox';
import FormDateRangeSelect, { FormDateRangeSelectProps } from '../date/FormDateRangeSelect';
import FormTimeFrameSelect, { FormTimeFrameSelectProps } from '../../FormTimeFrameSelect';
import FormSelect, { FormSelectProps } from '../select/FormSelect';
import FormTextSelect, { FormTextSelectProps } from '../../FormTextSelect';
import FormSelectSwitchOptions, { FormSelectSwitchOptionsProps } from '../select/FormSelectOptionsSwitch';
import FormValueRange, { FormValueRangeProps } from '../../FormValueRange';
import FormCheckboxList, { FormCheckboxListProps } from '../../FormCheckboxList';
import FormInput, { FormInputProps } from '../../FormInput';
import FormDateSelect, { FormDateSelectProps } from '../date/FormDateSelect';
import FormSelectWithValueRange, { FormSelectWithValueRangeProps } from '../select/FormSelectWithValueRange';
import FormTextSelectWithInput, { FormTextSelectWithInputProps } from '../../FormTextSelectWithInput';
import FormButtonCheckbox, { FormButtonCheckboxProps } from '../../FormButtonCheckbox';

interface Props {
  control: FormControl;
}

const FormControlSwitch: React.FC<Props> = props => {
  const { control } = props;
  const controlProps = control.props;

  switch (control.type) {
    case FormControlType.Checkbox: {
      return <FormCheckbox {...(controlProps as FormCheckboxProps)} />;
    }
    case FormControlType.DateRangeSelect: {
      return <FormDateRangeSelect {...(controlProps as FormDateRangeSelectProps)} />;
    }
    case FormControlType.TimeFrameSelect: {
      return <FormTimeFrameSelect {...(controlProps as FormTimeFrameSelectProps)} />;
    }
    case FormControlType.Select: {
      return <FormSelect {...(controlProps as FormSelectProps)} />;
    }
    case FormControlType.TextSelect: {
      return <FormTextSelect {...(controlProps as FormTextSelectProps)} />;
    }
    case FormControlType.SelectSwitchOptions: {
      return <FormSelectSwitchOptions {...(controlProps as FormSelectSwitchOptionsProps)} />;
    }
    case FormControlType.ValueRange: {
      return <FormValueRange {...(controlProps as FormValueRangeProps)} />;
    }
    case FormControlType.CheckboxList: {
      return <FormCheckboxList {...(controlProps as FormCheckboxListProps)} />;
    }
    case FormControlType.Input: {
      return <FormInput {...(controlProps as FormInputProps)} />;
    }
    case FormControlType.DateSelect: {
      return <FormDateSelect {...(controlProps as FormDateSelectProps)} />;
    }
    case FormControlType.SelectWithValueRange: {
      return <FormSelectWithValueRange {...(controlProps as FormSelectWithValueRangeProps)} />;
    }
    case FormControlType.TextSelectWithInput: {
      return <FormTextSelectWithInput {...(controlProps as FormTextSelectWithInputProps)} />;
    }
    case FormControlType.ButtonCheckbox: {
      return <FormButtonCheckbox {...(controlProps as FormButtonCheckboxProps)} />;
    }
  }
};

export default memo(FormControlSwitch, isEqual);
