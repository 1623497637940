import React from 'react';
import AdditionalMenuList from './AdditionalMenuList';
import AdditionalMenuListItem from './AdditionalMenuListItem';
import { RoutePath } from '../../enums/RoutePath';

const AdditionalMenuVideos: React.FC = () => {
  return (
    <AdditionalMenuList>
      <AdditionalMenuListItem title="Latest Videos" routePath={RoutePath.LatestVideos} />
      <AdditionalMenuListItem title="Video Tutorials" routePath={RoutePath.VideoTutorials} />
    </AdditionalMenuList>
  );
};

export default AdditionalMenuVideos;
