import React from 'react';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { useClassName } from '../../../../../../hooks/useClassName';
import { LastUpdateDaysCellBlock } from './LastUpdateDaysCellBem';
import CompanyMilestonesTooltip from '../../../../../../../company/components/company-milestones-tooltip/CompanyMilestonesTooltip';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';
import { ViewGridCellValueService } from '../../services/ViewGridCellValueService';
import useBreakpointAvailable from '../../../../../../effects/useBreakpointAvailable';
import { profileMobileBreakpoints } from '../../../../../../../company/components/profile/shared/constants/profileMobileBreakpoints';

type Props = GridCellFormatterCommonProps;

const LastUpdateDaysCell: React.FC<Props> = props => {
  const cn = useClassName(LastUpdateDaysCellBlock.Root);

  const noMilestoneLink = ViewGridCellValueService.getRaw(props.data, ViewColumnSystemName.NoMilestoneLink);
  const ticker = ViewGridCellValueService.getRaw(props.data, ViewColumnSystemName.Ticker);
  const isMobile = useBreakpointAvailable(profileMobileBreakpoints);

  return (
    <CustomCellFormatterWrapper className={cn()} value={props.value}>
      {noMilestoneLink || isMobile ? props.value : <CompanyMilestonesTooltip title={props.value} ticker={ticker} />}
    </CustomCellFormatterWrapper>
  );
};

export default LastUpdateDaysCell;
