import React, { useCallback } from 'react';
import './FileActions.scss';
import classNames from 'classnames';
import FileAction from './FileAction';
import { FileActionType } from './enums/FileActionType';
import { ControlSize } from '../../enums/ControlSize';
import { FileActionProp } from './interfaces/FileActionProp';
import { FILE_ACTIONS_SETTINGS } from './constants/fileActionsSettings';
import { ActionClickFn } from './types/actions';

export interface FileActionsProps {
  fileActions: FileActionProp[];
  size?: ControlSize;
  className?: string;
  onActionClick?: ActionClickFn;
}

const FileActions: React.FC<FileActionsProps> = props => {
  const onActionClick = useCallback(
    (actionType: FileActionType) => {
      props.onActionClick?.(actionType);
    },
    [props.onActionClick],
  );

  return (
    <div className={classNames('FileActions', props.className)}>
      {props.fileActions.map((fileAction: FileActionProp, index: number) => (
        <FileAction
          key={index}
          type={fileAction.type}
          icon={FILE_ACTIONS_SETTINGS[fileAction.type].icon}
          isIconVisible={fileAction.isIconVisible}
          colorScheme={FILE_ACTIONS_SETTINGS[fileAction.type].colorScheme}
          size={props.size}
          title={fileAction.title}
          titlePostfix={fileAction.titlePostfix}
          tooltipTitle={fileAction.tooltipTitle}
          href={fileAction.href}
          routePath={fileAction.routePath}
          hasWrapper={fileAction.hasWrapper}
          hasHover={true}
          isReverseOrder={fileAction.isReverseOrder}
          externalDownloadOptions={fileAction.externalDownloadOptions}
          onClick={onActionClick.bind(onActionClick, fileAction.type)}
        />
      ))}
    </div>
  );
};

FileActions.defaultProps = {
  size: ControlSize.Medium,
};

export default FileActions;
