import React from 'react';
import Icon from './Icon';
import { IconName } from './IconName';

interface Props {
  isClose: boolean;
}

const ToggleIcon: React.FC<Props> = props => {
  return <>{props.isClose ? <Icon name={IconName.DownArrow} /> : <Icon name={IconName.UpArrow} />}</>;
};

export default ToggleIcon;
