import React from 'react';
import './CustomGridHeaderSort.scss';
import { useClassName } from '../../../../../../hooks/useClassName';
import { CustomGridHeaderSortBlock, CustomGridHeaderSortElement } from './customGridHeaderSortBem';
import Icon from '../../../../../icon/Icon';
import { IconName } from '../../../../../icon/IconName';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { ColorScheme } from '../../../../../../enums/ColorScheme';

interface Props {
  isAsc: boolean;
  isDesc: boolean;
  isNone: boolean;
}

const iconProps = {
  colorScheme: ColorScheme.Gray,
  size: ControlSize.ExtraExtraSmall,
};

const CustomGridHeaderSort: React.FC<Props> = props => {
  const cn = useClassName(CustomGridHeaderSortBlock.Root);

  const { isAsc, isDesc, isNone } = props;

  return (
    <div className={cn()}>
      {(isNone || isDesc) && (
        <Icon className={cn(CustomGridHeaderSortElement.Desc)} name={IconName.Increase} {...iconProps} />
      )}
      {(isNone || isAsc) && (
        <Icon className={cn(CustomGridHeaderSortElement.Asc)} name={IconName.Decrease} {...iconProps} />
      )}
    </div>
  );
};

export default CustomGridHeaderSort;
