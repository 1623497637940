import React, { useContext, useMemo } from 'react';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProfileDataService } from '../../services/ProfileDataService';
import { profileDefaultExpandingLength } from '../../constants/profileDefaultExpandingLength';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import CompanyProfileSidebarTable from '../company-profile-table/CompanyProfileSidebarTable';
import { GridRow } from '../../../../../../shared/components/grid/shared/types/GridRow';

const CompanyUnderwriters: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const expandData = useMemo(() => ProfileDataService.getCompanyTableRows(profile.underwriters) as GridRow[], [
    profile.underwriters,
  ]);

  return (
    <>
      {profile.underwriters && (
        <CompanyProfileSideItem
          title="Underwriters"
          component={CompanyProfileSidebarTable}
          componentProps={{
            columns: profile.underwriters.columns,
            isHeaderHidden: true,
            hasAroundBorder: false,
          }}
          expandData={expandData}
          expandingLength={profileDefaultExpandingLength}
        />
      )}
    </>
  );
};

export default CompanyUnderwriters;
