import React from 'react';
import Helmet from 'react-helmet';
import HelpList from './HelpList';
import PrimarySection from '../shared/components/primary-section/PrimarySection';

const Help: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Questions</title>
      </Helmet>

      <PrimarySection header="Questions?">
        <HelpList />
      </PrimarySection>
    </>
  );
};

export default Help;
