import { Nullable } from '../../types/generics';
import { ErrorInvalidParam } from './interfaces/ErrorInvalidParam';
import { ErrorDataProps } from './enum/ErrorDataProps';
import { AxiosError } from 'axios';
import { ErrorTypeService } from './ErrorTypeService';
import { ErrorData } from './interfaces/ErrorData';
import { ErrorMessage } from './enum/ErrorMessage';
import { ApiResponseError } from '../../types/api/ApiResponse';
import { HttpCode } from '../../enums/HttpCode';

export class ErrorFormatService {
  static getErrorDataFromApi(error: ApiResponseError): ErrorData {
    const code = this.getErrorCode(error);

    if (this.isGetErrorByCode(error)) {
      return this.getErrorByCode(error);
    } else {
      return this.getApiErrorData(error, code);
    }
  }

  static getErrorData(
    summary: Nullable<string[]> = null,
    params: Nullable<ErrorInvalidParam[]> = null,
    code: HttpCode,
  ): ErrorData {
    return {
      [ErrorDataProps.InvalidParams]: params,
      [ErrorDataProps.SummaryErrors]: summary,
      [ErrorDataProps.Code]: code,
    };
  }

  static getSummary(messages: Nullable<string[]>): Nullable<string[]> {
    return messages;
  }

  static getSummaryErrorData(messages: Nullable<string[]>, code: HttpCode): ErrorData {
    return this.getErrorData(this.getSummary(messages), null, code);
  }

  static getErrorByCode(error: AxiosError): ErrorData {
    const code = this.getErrorCode(error);

    switch (code) {
      case HttpCode.NotFound:
        return ErrorFormatService.getSummaryErrorData([ErrorMessage.NotFound], code);
      default:
        return ErrorFormatService.getSummaryErrorData([ErrorMessage.ServerError], code);
    }
  }

  static getApiErrorData(error: AxiosError, code: HttpCode): ErrorData {
    // TODO: backend issue, there is no single format for error response
    const errorData = error?.response?.data?.errors || error?.response?.data?.data?.errors;
    return {
      ...errorData,
      [ErrorDataProps.Code]: code,
    };
  }

  private static isGetErrorByCode(error: ApiResponseError): boolean {
    return (
      ErrorTypeService.isNoResponse(error) ||
      ErrorTypeService.isNoResponseData(error) ||
      ErrorTypeService.isServerError(error)
    );
  }

  private static getErrorCode(error: ApiResponseError): HttpCode {
    return error?.response?.status || HttpCode.InternalServerError;
  }
}
