import React, { useMemo } from 'react';
import { CompanyStagesTooltipDto } from '../../../../../../interfaces/CompanyStagesTooltipDto';
import {
  CompanyProfileStepsTooltipContentItemBlock,
  CompanyProfileStepsTooltipContentItemElement,
} from './companyProfileStepsTooltipContentItemBem';
import { useClassName } from '../../../../../../../shared/hooks/useClassName';
import { NumberFormat } from '../../../../../../../shared/enums/NumberFormat';
import './CompanyProfileStepsTooltipContentItem.scss';
import { DateHelper } from '../../../../../../../shared/helpers/date/DateHelper';
import { DateFormat } from '../../../../../../../shared/helpers/date/enums/DateFormat';
import { NumberHelper } from '../../../../../../../shared/helpers/NumberHelper';

interface Props extends CompanyStagesTooltipDto {
  sequenceNumber: number;
}

const CompanyProfileStepsTooltipContentItem: React.FC<Props> = props => {
  const cn = useClassName(CompanyProfileStepsTooltipContentItemBlock.Root);

  const shares = useMemo(() => NumberHelper.getFormatValue(props.lockupShares, NumberFormat.WithLetter), [
    props.lockupShares,
  ]);
  const formattedSequenceNumber = useMemo(
    () => NumberHelper.getFormatValue(props.sequenceNumber, NumberFormat.Sequence),
    [props.sequenceNumber],
  );
  const formattedDate = useMemo(() => DateHelper.formatISODateStr(props.lockupDate, DateFormat.MonthDayYear), [
    props.lockupDate,
  ]);

  return (
    <div className={cn()}>
      <div className={cn(CompanyProfileStepsTooltipContentItemElement.content)}>
        <div className={cn(CompanyProfileStepsTooltipContentItemElement.sequenceNumber)}>
          {formattedSequenceNumber}:
        </div>

        <div className={cn(CompanyProfileStepsTooltipContentItemElement.text)}>
          {shares}&nbsp;shares on&nbsp;
          <b className={cn(CompanyProfileStepsTooltipContentItemElement.date)}>{formattedDate}</b>
        </div>
      </div>

      <div className={cn(CompanyProfileStepsTooltipContentItemElement.description)}>{props.lockupNote}</div>
    </div>
  );
};

export default CompanyProfileStepsTooltipContentItem;
