import React from 'react';
import ScreensLanding from '../../shared/pages/components/landing/ScreensLanding';
import { screensSpacsLandingOptions } from './constants/screensSpacsLandingOptions';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';

const ScreensSpacsLanding: React.FC = () => {
  return <ScreensLanding landingType={ViewType.SpacsLanding} options={screensSpacsLandingOptions} />;
};

export default ScreensSpacsLanding;
