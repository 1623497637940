import { ViewColumnSystemName } from '../enums/ViewColumnSystemName';
import { ViewGridColumnNameService } from '../services/ViewGridColumnNameService';

export const viewGridColumnGroupsNames = {
  dealSizeMarketCup: ViewGridColumnNameService.getNestedColumnNameFromColumnNames([
    ViewColumnSystemName.DealSize,
    ViewColumnSystemName.MarketCap,
  ]),
  dealSizeMktCap: ViewGridColumnNameService.getNestedColumnNameFromColumnNames([
    ViewColumnSystemName.DealSize,
    ViewColumnSystemName.MktCap,
  ]),
  exchangeheadquarters: ViewGridColumnNameService.getNestedColumnNameFromColumnNames([
    ViewColumnSystemName.Exchange,
    ViewColumnSystemName.Headquarters,
  ]),
};
