import React, { useContext, useMemo } from 'react';
import FilterControl from '../FilterControl';
import { ViewFilterName } from '../../../../enum/types/ViewFilterName';
import { FilterSectionsFormContext } from '../../context/FilterSectionsFormContext';
import { useFormContext } from 'react-hook-form';
import { Location } from '../../../../enum/Location';

const LocationFilterSection: React.FC = () => {
  const { dictionaries } = useContext(FilterSectionsFormContext);
  const { watch } = useFormContext();

  const listingLocation = watch(ViewFilterName.LocationListingLocation);

  const isExchangeVisible = useMemo(() => listingLocation !== Location.US, [listingLocation]);

  return (
    <>
      <FilterControl name={ViewFilterName.LocationListingLocation} />
      <FilterControl
        name={ViewFilterName.LocationExchangeList}
        controlProps={{
          options: dictionaries?.[ViewFilterName.LocationExchangeList],
          isMultiSelect: true,
        }}
        isDisabled={!isExchangeVisible}
      />
      <FilterControl
        name={ViewFilterName.ExchangeCountry}
        controlProps={{
          options: dictionaries?.[ViewFilterName.ExchangeCountry],
        }}
        isDisabled={!isExchangeVisible}
      />

      <FilterControl
        name={ViewFilterName.ExchangeRegion}
        controlProps={{
          options: dictionaries?.[ViewFilterName.ExchangeRegion],
        }}
        isDisabled={!isExchangeVisible}
      />

      {/* {isExchangeVisible && (
        <FilterSectionsAdvancedControls>
          <FilterControl
            name={ViewFilterName.LocationExchange}
            controlProps={{
              options: dictionaries?.[ViewFilterName.LocationExchange],
            }}
          />
          <FilterControl
            name={ViewFilterName.ExchangeCountry}
            controlProps={{
              options: dictionaries?.[ViewFilterName.ExchangeCountry],
            }}
          />

          <FilterControl
            name={ViewFilterName.ExchangeRegion}
            controlProps={{
              options: dictionaries?.[ViewFilterName.ExchangeRegion],
            }}
          />
        </FilterSectionsAdvancedControls>
      )} */}

      <FilterControl name={ViewFilterName.IncludeChina} />
    </>
  );
};

export default LocationFilterSection;
