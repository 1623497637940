import React from 'react';
import './FormControlVisibleValueList.scss';
import { AnyObject } from '../../../../types/generics';
import FormControlVisibleValue from './FormControlVisibleValue';
import { useClassName } from '../../../../hooks/useClassName';
import { FormControlVisibleValueListBlock } from './formControlVisibleValueListBem';
import { FormControlDeleteVisibleValueOptions } from './interfaces/FormControlDeleteVisibleValueOptions';
import { FormControl } from '../form-builder/types/formControlsTypes';

interface Props {
  className?: string;
  controls: FormControl[];
  values: AnyObject;
  dictionaries?: AnyObject;
  onDelete?: (deleteValueOptions: FormControlDeleteVisibleValueOptions) => void;
}

const FormControlVisibleValueList: React.FC<Props> = props => {
  const cn = useClassName(FormControlVisibleValueListBlock.Root, props.className);

  return (
    <div className={cn()}>
      {props.controls.map((control, index) => (
        <FormControlVisibleValue
          key={index}
          control={control}
          values={props.values}
          dictionaries={props.dictionaries}
          onDelete={props.onDelete}
        />
      ))}
    </div>
  );
};

export default FormControlVisibleValueList;
