import React, { useState, useMemo, useCallback, useEffect } from 'react';
import Icon from '../icon/Icon';
import { IconName } from '../icon/IconName';
import './TreeNode.scss';
import ButtonIcon from '../forms/ButtonIcon';
import { ControlSize } from '../../enums/ControlSize';

export interface TreeNodeCommonProps {
  content: React.ReactNode;
  isUsable?: boolean;
  childrenNodes?: TreeNodeCommonProps[];
}

interface TreeNodeProps extends TreeNodeCommonProps {
  isOpen?: boolean;
}

const TreeNode: React.FC<TreeNodeProps> = props => {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const isUsable = useMemo(() => !!(props.childrenNodes && props.isUsable), [props.childrenNodes, props.isUsable]);
  const isAbleToOpen = useMemo(() => !!(isOpen && props.childrenNodes), [isOpen, props.childrenNodes]);
  const onToggle = useCallback((): void => setIsOpen(!isOpen), [isOpen]);

  useEffect(() => setIsOpen(props.isOpen), [props.isOpen]);

  return (
    <div className="TreeNode">
      <div className="TreeNode__content">
        {props.content}

        {isUsable && (
          <ButtonIcon
            onClick={onToggle}
            size={ControlSize.Small}
            iconName={isOpen ? IconName.UpArrow : IconName.DownArrow}
          />
        )}
      </div>

      {isAbleToOpen && (
        <div className="TreeNode-children">
          <Icon name={IconName.TransitionArrow} size={ControlSize.ExtraSmall} className="TreeNode-children__icon" />

          {props.childrenNodes?.map((item: TreeNodeCommonProps, index: number) => (
            <TreeNode key={index} {...item} isOpen={isOpen} />
          ))}
        </div>
      )}
    </div>
  );
};

TreeNode.defaultProps = {
  isUsable: true,
};

export default TreeNode;
