import React, { useMemo } from 'react';
import { useClassName } from '../../hooks/useClassName';
import { SecondarySectionExpandableBlock } from './SecondarySectionExpandableBem';
import SecondarySection, { SecondarySectionProps } from './SecondarySection';
import { ExpandHookOptions } from '../../hooks/expand/interfaces/ExpandHookOptions';
import { useExpand } from '../../hooks/expand/useExpand';
import RowsExpanderButton from '../expander/RowsExpanderButton';
import { ComponentWithExpandDataListProps } from '../expander/interfaces/ComponentWithExpandDataListProps';
import { ExpandListComponentCommonProps } from '../expander/interfaces/ExpandListComponentCommonProps';

export interface SecondarySectionExpandableProps extends SecondarySectionProps, ExpandListComponentCommonProps {
  component: React.FC<ComponentWithExpandDataListProps>;
  componentProps?: ComponentWithExpandDataListProps;
}

const SecondarySectionExpandable: React.FC<SecondarySectionExpandableProps> = props => {
  const cn = useClassName(SecondarySectionExpandableBlock.Root, props.className);

  const { expandedData, ...options }: ExpandHookOptions = useExpand(props.expandData, props.expandingLength);

  const content = useMemo(() => React.createElement(props.component, { ...props.componentProps, expandedData }), [
    props.component,
    props.componentProps,
    expandedData,
  ]);

  const hasActions = useMemo(() => props.expandData.length > props.expandingLength, [
    props.expandData,
    props.expandingLength,
  ]);

  return (
    <SecondarySection {...props} className={cn()} actions={hasActions && <RowsExpanderButton {...options} />}>
      {content}
    </SecondarySection>
  );
};

export default SecondarySectionExpandable;
