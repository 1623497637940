import React from 'react';
import View from '../../shared/entities/view/components/view/View';
import { pipelineViewOptions } from './constants/pipelineViewOptions';
import { ViewType } from '../../shared/entities/view/components/view/enums/ViewType';

const Pipeline: React.FC = () => {
  return <View options={pipelineViewOptions} viewType={ViewType.Pipeline} />;
};

export default Pipeline;
