import React, { useCallback, useContext, useMemo } from 'react';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import { CompanyContext } from '../../../../context/CompanyContext';
import './CompanyPrivateCompanyData.scss';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import CompanyProfileSidebarTable from '../company-profile-table/CompanyProfileSidebarTable';
import { DateHelper } from '../../../../../../shared/helpers/date/DateHelper';
import { DateFormat } from '../../../../../../shared/helpers/date/enums/DateFormat';
import { useClassName } from '../../../../../../shared/hooks/useClassName';
import { OnViewGridColumnMapReady } from '../../../../../../shared/entities/view/components/view-grid/types/OnViewGridColumnMapReady';
import { GridCellFormatterType } from '../../../../../../shared/components/grid/shared/enums/GridCellFormatterType';
import PcwSourceCell from '../../../../../../shared/entities/view/components/view-grid/components/cells/PcwSourceCell';

const CompanyPrivateCompanyData: React.FC = () => {
  const cn = useClassName('CompanyPrivateCompanyData');

  const { getTabData } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const data = useMemo(() => profile.privateCompanyData, [profile]);
  const lastUpdate = useMemo(() => {
    const date = data?.lastUpdate;

    if (!date) {
      return;
    }

    return DateHelper.formatISODateStr(date, DateFormat.MonthDayYearShort);
  }, [data]);

  const onColumnMapReady = useCallback<OnViewGridColumnMapReady>((column, index) => {
    const hasNoSourceTooltip = index === 0;

    if (hasNoSourceTooltip) {
      return column;
    }

    return {
      ...column,
      cellFormatter: {
        type: GridCellFormatterType.Custom,
        props: {
          component: PcwSourceCell,
          tableData: data,
        },
      },
    };
  }, []);

  return (
    <>
      {data && (
        <CompanyProfileSideItem className={cn()} title="Private Company Data">
          <CompanyProfileSidebarTable
            {...data}
            expandedData={data.rows}
            hasAroundBorder={false}
            isHeaderHidden
            onColumnMapReady={onColumnMapReady}
          />
          {data.footNote && <div className={cn('additionalInfo')}>Note: {data.footNote}</div>}
          {lastUpdate && <div className={cn('additionalInfo')}>Last Updated: {lastUpdate}</div>}
        </CompanyProfileSideItem>
      )}
    </>
  );
};

export default CompanyPrivateCompanyData;
