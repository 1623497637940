import React, { useContext } from 'react';
import { ViewDashboardContext } from '../../../shared/entities/view/components/view-dashboard/ViewDashboardContext';
import { SearchResultsFilterGroupName } from '../../enums/SearchResultsFilterGroupName';
import { ViewDataTable } from '../../../shared/entities/view/components/view/interfaces/api/ViewDataTable';
import SearchResultsGrid from './SearchResultsGrid';
import { researchFiltersControls } from '../../shared/constants/controls/researchFiltersControls';
import { researchCustomColumnsSettings } from '../../shared/constants/custom-columns-settings/researchCustomColumnsSettings';
interface Props {
  onChange?: () => void;
}

const SearchResultsResearchGrid: React.FC<Props> = props => {
  const data = useContext(ViewDashboardContext)?.data?.[SearchResultsFilterGroupName.Research] as ViewDataTable;

  return (
    <SearchResultsGrid
      gridTitle={'Research'}
      dataTable={data}
      searchControls={researchFiltersControls}
      filterGroupName={SearchResultsFilterGroupName.Research}
      customColumnsSettings={researchCustomColumnsSettings}
      onChange={props.onChange}
    />
  );
};

export default SearchResultsResearchGrid;
