import { FiltersMapValue } from '../../../../shared/entities/filters/components/filterable-component/interfaces/FiltersMapValue';
import { searchFilterControls } from '../../../../shared/entities/filters/constants/filter-controls/searchFilterControls';
import { NewsFilterField } from '../../../../news/enums/NewsFilterField';
import { SearchResultsFilterName } from '../../../enums/SearchResultsFilterName';
import { FilterSettingsService } from '../../../../shared/entities/filters/services/FilterSettingsService';

export const newsFiltersControls: FiltersMapValue[] = [
  FilterSettingsService.mergeControlSettings(searchFilterControls[NewsFilterField.NewsType] as FiltersMapValue, {
    props: {
      name: SearchResultsFilterName.NewsTypes,
    },
  }),
  FilterSettingsService.mergeControlSettings(searchFilterControls[NewsFilterField.StoryType] as FiltersMapValue, {
    props: {
      name: SearchResultsFilterName.StoryTypes,
    },
  }),
];
