import React from 'react';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { useClassName } from '../../../../../../hooks/useClassName';
import DilutedSharesToolTip from '../../../../../../../company/components/profile/shared/components/diluted-shares-tooltip/DilutedSharesToolTip';
import { CapitalizationDataCellBlock } from './CapitalizationDataCellBem';
import './CapitalizationDataCell.scss';

type Props = GridCellFormatterCommonProps;

const CapitalizationDataCell: React.FC<Props> = props => {
  const cn = useClassName(CapitalizationDataCellBlock.Root);
  const ttCn = useClassName(CapitalizationDataCellBlock.TooltipText);

  const CapData = props.data;

  return (
    <CustomCellFormatterWrapper className={cn()} value={props.data?.capitalizationData}>
      {CapData.IsDilutedSharesLink === true ? (
        <span className={ttCn()}>
          <DilutedSharesToolTip dataValue={props.data?.capitalizationData} />
        </span>
      ) : (
        props.data?.capitalizationData
      )}
    </CustomCellFormatterWrapper>
  );
};

export default CapitalizationDataCell;
