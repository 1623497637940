import { FilterSectionType } from '../enum/FilterSectionType';

export const filterSectionsOrder: FilterSectionType[] = [
  FilterSectionType.Date,
  FilterSectionType.DealStatus,
  FilterSectionType.OfferDate,
  FilterSectionType.ActionDate,
  FilterSectionType.FileDate,
  FilterSectionType.DealTiming,
  FilterSectionType.CompanyInfo,
  FilterSectionType.Investors,
  FilterSectionType.Location,
  FilterSectionType.Returns,
  FilterSectionType.DealInfo,
  FilterSectionType.SecurityType,
  FilterSectionType.Favorite,
  FilterSectionType.RenaissanceRating,
  FilterSectionType.Financials,
  FilterSectionType.Valuation,
];
