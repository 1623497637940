import React, { useMemo, useState } from 'react';
import './SubMenuAccordion.scss';
import AccordionSecondary from '../../accordion/AccordionSecondary';
import { NavItem } from '../../nav/types/NavItem';
import { NavItemFactory } from '../../nav/factories/NavItemFactory';
import { TooltipContentItem } from '../../tooltip/interface/TooltipContentItem';
import { useClassName } from '../../../hooks/useClassName';
import { SubMenuAccordionBlock, SubMenuAccordionElement } from './SubMenuAccordionBem';

interface Props {
  title: React.ReactNode;
  items: NavItem[];
  className?: string;
}

const SubMenuAccordion: React.FC<Props> = props => {
  const cn = useClassName(SubMenuAccordionBlock.Root, props.className);

  const contentItems = useMemo<TooltipContentItem[]>(() => NavItemFactory.getNavItemControls(props.items), [
    props.items,
  ]);

  const closeState = useState(true);

  return (
    <AccordionSecondary title={props.title} closeState={closeState} classNames={cn()}>
      {contentItems.map((item, index) => (
        <div className={cn(SubMenuAccordionElement.Item)} key={index}>
          {item.component}
        </div>
      ))}
    </AccordionSecondary>
  );
};

export default SubMenuAccordion;
