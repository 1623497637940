import React, { useMemo } from 'react';
import './DashboardSpecialReportsWidgetItem.scss';
import FileActions from '../../../../shared/components/file-actions/FileActions';
import { FileActionType } from '../../../../shared/components/file-actions/enums/FileActionType';
import { DateHelper } from '../../../../shared/helpers/date/DateHelper';

import { DASHBOARD_WIDGET_DATE_FORMAT } from '../../../constants/dashboardWidgetDefaultOptions';
import { FileActionProp } from '../../../../shared/components/file-actions/interfaces/FileActionProp';

interface Props {
  title: string;
  date: string;
  filePath: string;
}

const DashboardSpecialReportsWidgetItem: React.FC<Props> = props => {
  const { filePath, title } = props;

  const fileActionCommon = useMemo(
    () => ({
      type: FileActionType.Pdf,
      externalDownloadOptions: {
        url: filePath,
      },
    }),
    [filePath],
  );

  const fileActionsHeader = useMemo<FileActionProp[]>(
    () => [
      {
        ...fileActionCommon,
        isIconVisible: false,
        title: <span className="DashboardSpecialReportsWidgetItem__header">{title}</span>,
      },
    ],
    [fileActionCommon, title],
  );
  const fileActionsFooter = useMemo<FileActionProp[]>(
    () => [
      {
        ...fileActionCommon,
      },
    ],
    [fileActionCommon],
  );

  return (
    <li className="DashboardSpecialReportsWidgetItem">
      <FileActions fileActions={fileActionsHeader} />

      <div className="DashboardSpecialReportsWidgetItem__footer">
        <div>{DateHelper.formatISODateStr(props.date, DASHBOARD_WIDGET_DATE_FORMAT)}</div>

        <FileActions fileActions={fileActionsFooter} />
      </div>
    </li>
  );
};

export default DashboardSpecialReportsWidgetItem;
