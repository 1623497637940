import React from 'react';
import AdditionalStatsContentContainer from '../../shared/components/AdditionalStatsContentContainer';
import StatsElement from '../../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../../shared/entities/stats-element/enums/StatsElementType';
import { averageAgeStatsElementOptions } from './constants/averageAgeStatsElementOptions';
import AdditionalStatsPage from '../AdditionalStatsPage';

const AverageAge: React.FC = () => {
  return (
    <AdditionalStatsPage>
      <AdditionalStatsContentContainer title="Average Age of IPOs">
        <StatsElement type={StatsElementType.AverageAge} options={averageAgeStatsElementOptions} />
      </AdditionalStatsContentContainer>
    </AdditionalStatsPage>
  );
};

export default AverageAge;
