import React, { useMemo } from 'react';
import { ChartFactory } from '../../../../shared/components/chart/factories/ChartFactory';
import { ChartType } from '../../../../shared/components/chart/enums/ChartType';
import { Dimensions } from '../../../../shared/interfaces/Dimensions';
import { useClassName } from '../../../../shared/hooks/useClassName';
import { DashboardChartWidgetBlock } from './dashboardChartWidgetBem';
import { OptionsListProp } from '../../../../shared/components/chart/types/OptionsListProp';

interface Props {
  optionsList: OptionsListProp;
  type: ChartType;
  dimensions: Dimensions;
}

// Hightcharts couldn't fit correctly the container size
// So this help to fix this issue
const HEIGHT_FIX = 10;

const DashboardChartWidget: React.FC<Props> = props => {
  const cn = useClassName(DashboardChartWidgetBlock.Root);

  const chart = useMemo(
    () =>
      ChartFactory.getChart(
        {
          className: cn(),
          hasNoIndents: true,
          optionsList: [
            ...props.optionsList,
            {
              chart: {
                height: props.dimensions.height - HEIGHT_FIX,
              },
            },
          ],
        },
        props.type,
      ),
    [props.type, props.optionsList, props.dimensions, cn],
  );

  return <>{chart}</>;
};

export default DashboardChartWidget;
