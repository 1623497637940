import React from 'react';
import './CompanySection.scss';
import { CustomAny } from '../../../shared/types/generics';
import { useClassName } from '../../../shared/hooks/useClassName';
import { CompanySectionBlock } from './companySectionBem';
import CompanySectionTitle, { CompanySectionTitleProps } from './CompanySectionTitle';
import { ControlSize } from '../../../shared/enums/ControlSize';

interface Props {
  data: CustomAny;
  className?: string;
  titleProps?: CompanySectionTitleProps;
  maxContentSize?: ControlSize;
}

const CompanySection: React.FC<Props> = props => {
  const { titleProps, maxContentSize = ControlSize.Large } = props;

  const cn = useClassName(CompanySectionBlock.Root, props.className);

  return (
    props.data && (
      <section className={cn({ [`${maxContentSize}`]: maxContentSize })}>
        {titleProps && <CompanySectionTitle {...titleProps} />}

        <div className={cn('content')}>{props.children}</div>
      </section>
    )
  );
};

export default CompanySection;
