import { StatsElementContextValue } from './StatsElementContext';
import { useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { StatsElementDto } from '../../interfaces/StatsElementDto';
import { StatsApiService } from '../../../../api/StatsApiService';
import { FiltersValues } from '../../../filters/types/FiltersValues';
import { StatsElementType } from '../../enums/StatsElementType';
import { StatsElementOptions } from '../../interfaces/StatsElementOptions';
import { useAsyncFn } from '../../../../hooks/async-fn/useAsyncFn';
import { initialStatsElementData } from '../../constants/initialStatsElementData';
import { StatsElementDataFilterType } from '../../types/StatsElementDataFilterType';
import { StatsElementFiltersInternalService } from '../shared/filters-internal/services/StatsElementFiltersInternalService';
import { StatsElementFilterInternalName } from '../../enums/StatsElementGridFilterName';
import { initialStatsElementDto } from '../../constants/initialStatsElementDto';
import { StatsElementData } from '../../interfaces/StatsElementData';
import { useStatsElementChartsContextValue } from './useStatsElementChartsContextValue';
import { SimpleGridProps } from '../../../../components/grid/simple-grid/SimpleGrid';
import { isEqual } from 'lodash';
import { ViewGridProps } from '../../../view/components/view-grid/ViewGrid';
import { StatsPageContext } from '../../../../../stats/stats/context/StatsPageContext';
import { StatsGridsToExcel } from '../../interfaces/StatsGridsToExcel';

export function useStatsElementContextValue(
  initialType: StatsElementType,
  pageFilters: FiltersValues,
  options?: StatsElementOptions,
): StatsElementContextValue {
  const { onGridsToExcelChange } = useContext(StatsPageContext);

  const [dataType, setDataType] = useState<StatsElementDataFilterType>(StatsElementFilterInternalName.Default);
  const [data, setData] = useState<StatsElementDto>(initialStatsElementDto);
  const [gridsToExcel, setGridsToExcel] = useState<StatsGridsToExcel>({});

  const addGridToGridsToExcel = useCallback(
    (grid: Partial<SimpleGridProps | ViewGridProps>, index: number) => {
      const newGridsToExcel = { ...gridsToExcel, [index]: grid };
      const areSameGrids = isEqual(gridsToExcel, newGridsToExcel);

      if (areSameGrids) {
        return;
      }

      setGridsToExcel({ ...gridsToExcel, [index]: grid });
    },
    [gridsToExcel, setGridsToExcel],
  );

  const clearGridsToExcel = useCallback(() => setGridsToExcel({}), [setGridsToExcel]);

  const loadDataAsyncFn = useCallback(async () => {
    clearGridsToExcel();
    const data: StatsElementDto = await StatsApiService.getStatsElementData(initialType, pageFilters);

    setData(data);
  }, [pageFilters, setData, initialType, clearGridsToExcel]);

  const { grids, highCharts, info, viewGrids } = useMemo(() => data[dataType] || initialStatsElementData, [
    data,
    dataType,
  ]);

  const dataTypes: StatsElementDataFilterType[] = useMemo(
    () => (data ? (StatsElementFiltersInternalService.getFiltersNames(data) as StatsElementDataFilterType[]) : []),
    [data],
  );

  const isContent = useMemo(() => !!(grids?.length || highCharts?.length || info), [grids, highCharts, info]);

  const updateCurrentData = useCallback(
    (value: StatsElementData | undefined) => setData({ ...data, [dataType]: value }),
    [data, dataType],
  );

  const currentData = useMemo(() => data[dataType], [data, dataType]);

  const [loadData, isLoading] = useAsyncFn(loadDataAsyncFn);

  useEffect(() => {
    if (!dataTypes.includes(dataType)) {
      const [firstAvailableDataType] = dataTypes;

      setDataType(firstAvailableDataType);
    }
  }, [dataTypes, dataType]);

  useEffect(() => onGridsToExcelChange(gridsToExcel), [gridsToExcel, onGridsToExcelChange]);

  const chartsContextValue = useStatsElementChartsContextValue({
    options,
    currentData,
    updateCurrentData,
    highCharts,
    pageFilters,
  });

  return useMemo(
    () => ({
      type: initialType,
      grids,
      viewGrids,
      info,
      dataTypes,
      dataType,
      setDataType,
      loadData,
      isLoading,
      pageFilters,
      isContent,
      options,
      chartsContextValue,
      gridsToExcel,
      addGridToGridsToExcel,
      clearGridsToExcel,
    }),
    [
      initialType,
      isContent,
      dataType,
      dataTypes,
      setDataType,
      grids,
      viewGrids,
      info,
      pageFilters,
      loadData,
      isLoading,
      options,
      chartsContextValue,
      gridsToExcel,
      addGridToGridsToExcel,
      clearGridsToExcel,
    ],
  );
}
