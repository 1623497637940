import * as yup from 'yup';
import { SaveViewFormFields } from './SaveViewFormFields';
import { INPUT_MAX_LENGTH } from '../../../../../../../../constants/validation';

export const validationSchema = yup.object().shape({
  [SaveViewFormFields.Name]: yup
    .string()
    .required()
    .max(INPUT_MAX_LENGTH)
    .label('View Name'),
});
