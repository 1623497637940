import { ViewColumnSystemName } from '../../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { DealStatus } from '../../../../../shared/entities/filters/enum/DealStatus';

export class ScreensDefaultFiltersService {
  static useDealStatusDependecy(
    setColumnsWeb: React.Dispatch<React.SetStateAction<string[]>>,
    setColumnsExcel: React.Dispatch<React.SetStateAction<string[]>>,
    currentDealStatuses: string[],
  ): void {
    if (currentDealStatuses?.includes(DealStatus.Priced) || currentDealStatuses?.includes(DealStatus.Scheduled)) {
      setColumnsWeb([ViewColumnSystemName.CompanyName, ViewColumnSystemName.Ticker, ViewColumnSystemName.OfferDate]);
      setColumnsExcel([ViewColumnSystemName.CompanyName, ViewColumnSystemName.Ticker, ViewColumnSystemName.OfferDate]);
    }
  }
}
