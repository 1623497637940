import React, { useMemo } from 'react';
import classNames from 'classnames';
import DropdownNav from './DropdownNav';
import TabsNav from './TabsNav';
import useBreakpointAvailable from '../../../effects/useBreakpointAvailable';
import { BreakpointName } from '../../../enums/BreakpointName';
import { InnerNavPositions } from '../enums/InnerNavPositions';
import ButtonsNav from './ButtonsNav';
import { NavProps } from '../interfaces/NavProps';
import { mobileBreakpoints as mobileBreakpointsConst } from '../../../constants/breakpoints/mobileBreakpoints';
import './InnerNav.scss';

interface Props extends NavProps {
  position?: InnerNavPositions;
  className?: string;
  mobileBreakpoints?: BreakpointName[];
}

const InnerNav: React.FC<Props> = props => {
  const { mobileBreakpoints = mobileBreakpointsConst } = props;

  const isMobile = useBreakpointAvailable(mobileBreakpoints);

  const desktopNav = useMemo<React.ReactNode>(
    () =>
      props.position === InnerNavPositions.Top ? (
        <TabsNav routesTitles={props.routesTitles}>{props.children}</TabsNav>
      ) : (
        <ButtonsNav
          routesTitles={props.routesTitles}
          backRoutePath={props.backRoutePath}
          backRouteTitle={props.backRouteTitle}
        >
          {props.children}
        </ButtonsNav>
      ),
    [props.position, props.routesTitles, props.children, props.backRouteTitle, props.backRoutePath],
  );

  return (
    <section className={classNames('InnerNav', props.className)}>
      {isMobile ? (
        <DropdownNav
          routesTitles={props.routesTitles}
          backRoutePath={props.backRoutePath}
          backRouteTitle={props.backRouteTitle}
        >
          {props.children}
        </DropdownNav>
      ) : (
        desktopNav
      )}
    </section>
  );
};

InnerNav.defaultProps = {
  position: InnerNavPositions.Top,
};

export default InnerNav;
