import React from 'react';
import { NewsArticleDto } from '../interfaces/NewsArticleDto';
import FormattedDate from '../../shared/components/FormattedDate';
import { DateFormat } from '../../shared/helpers/date/enums/DateFormat';
import { useClassName } from '../../shared/hooks/useClassName';
import { getNewsSourceTag } from '../helpers/getNewsSourceTag';
import { NewsItemTag } from '../interfaces/NewsItemTag';
import { getNewsTypeTag } from '../helpers/getNewsTypeTag';
import './NewsArticleInfo.scss';

interface Props {
  article: NewsArticleDto;
}

const NewsArticleInfo: React.FC<Props> = props => {
  const { article } = props;
  const cn = useClassName('NewsArticleInfo');
  const sourceTag: NewsItemTag | undefined = getNewsSourceTag(article);
  const typeTag: NewsItemTag | undefined = getNewsTypeTag(article);

  return (
    <div className={cn()}>
      {article.breakDate && (
        <span className={cn('item')}>
          <FormattedDate date={article.breakDate} format={DateFormat.LongMonthDayYear} />
        </span>
      )}

      {sourceTag && <span className={cn('item')}>{sourceTag.title}</span>}

      {typeTag && <span className={cn('item')}>{typeTag.title}</span>}
    </div>
  );
};

export default NewsArticleInfo;
