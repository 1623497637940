import React, { useCallback, useMemo } from 'react';
import './FileAction.scss';
import { IconName } from '../icon/IconName';
import { ControlSize } from '../../enums/ControlSize';
import Icon from '../icon/Icon';
import { FileActionsService } from './services/FileActionsService';
import PrintTrigger from '../print/PrintTrigger';
import { useClassName } from '../../hooks/useClassName';
import { FileActionBlock, FileActionElement, FileActionModifier } from './fileActionBem';
import Hyperlink from '../typography/Hyperlink';
import { ColorScheme } from '../../enums/ColorScheme';
import { FileService } from '../../services/file/FileService';
import { FileActionProp } from './interfaces/FileActionProp';
import Loader from '../loader/Loader';
import { useAsyncFn } from '../../hooks/async-fn/useAsyncFn';
import ReactGA from 'react-ga';
import { GetAndDownloadOptionsByUrl } from '../../services/file/interfaces/GetAndDownloadFileOptions';
import { DateFormat } from '../../helpers/date/enums/DateFormat';
import { DateHelper } from '../../helpers/date/DateHelper';

type Props = {
  icon: IconName;
  colorScheme?: ColorScheme;
  size?: ControlSize;
  hasHover?: boolean;
  onClick: () => void;
} & FileActionProp;

const FileAction: React.FC<Props> = props => {
  const cn = useClassName(FileActionBlock.Root);

  const {
    href,
    routePath,
    type,
    isReverseOrder,
    titlePostfix,
    isIconVisible = true,
    externalDownloadOptions,
    onClick,
  } = props;

  const to = useMemo(() => href || routePath, [href, routePath]);
  const [getData, isLoading] = useAsyncFn(
    useCallback(() => {
      if (externalDownloadOptions) {
        if ((externalDownloadOptions as GetAndDownloadOptionsByUrl).url?.includes('show')) {
          const loc: string = (externalDownloadOptions as GetAndDownloadOptionsByUrl).url;

          const dTime = DateHelper.formatISODateStr(new Date().toISOString(), DateFormat.MonthYearDayTime);
          ReactGA.set({ dimension4: dTime });
          ReactGA.set({ page: `/${loc}` });
          ReactGA.pageview(`/${loc}`);

          // Added info logs
          if (loc?.includes('PDF')) {
            window.dataLayer?.push({ showExcel: '' });
            window.dataLayer?.push({
              showPdf: loc.split('filename=')[1],
              event: 'virtualPageview',
            });
          } else if (loc?.includes('Excel')) {
            window.dataLayer?.push({ showPdf: '' });
            window.dataLayer?.push({
              showExcel: loc.split('filename=')[1],
              event: 'virtualPageview',
            });
          }
        }

        return FileService.getAndDownload(externalDownloadOptions);
      }
    }, [externalDownloadOptions]),
  );

  const onClickAction = useCallback(async () => {
    if (externalDownloadOptions) {
      getData();
    }

    onClick();
  }, [externalDownloadOptions, getData, onClick]);

  const innerContent = useMemo(
    () => (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {props.title && (
              <span className={cn(FileActionElement.TitleContainer)}>
                <span className={cn(FileActionElement.Title)}>{props.title} </span>
                {titlePostfix && <span className={cn(FileActionElement.TitlePostfix)}>{titlePostfix}</span>}
              </span>
            )}

            {isIconVisible && (
              <Icon
                className={cn(FileActionElement.Icon)}
                name={props.icon}
                size={props.size}
                hasHover={props.hasHover}
                colorScheme={props.colorScheme}
              />
            )}
          </>
        )}
      </>
    ),
    [
      props.title,
      props.icon,
      props.size,
      props.hasHover,
      cn,
      props.colorScheme,
      titlePostfix,
      isIconVisible,
      isLoading,
    ],
  );

  const outerContent = useMemo(
    () =>
      to ? (
        <Hyperlink
          className={cn({
            [FileActionModifier.ReverseOrder]: isReverseOrder,
          })}
          to={to}
          title={props.tooltipTitle}
        >
          {innerContent}
        </Hyperlink>
      ) : (
        <button
          className={`${cn({
            [FileActionModifier.ReverseOrder]: isReverseOrder,
          })} ${cn(type)}`}
          title={props.tooltipTitle}
          onClick={onClickAction}
        >
          {innerContent}
        </button>
      ),
    [innerContent, onClickAction, cn, type, to, props.tooltipTitle, isReverseOrder],
  );

  return useMemo(() => {
    if (props.hasWrapper) {
      if (FileActionsService.isPrint(type)) {
        return <PrintTrigger className={FileActionBlock.FileActionWrapper} trigger={outerContent} />;
      }
    }

    return outerContent;
  }, [outerContent, type, props.hasWrapper]);
};

FileAction.defaultProps = {
  size: ControlSize.Medium,
};

export default FileAction;
