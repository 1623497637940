import { Context, createContext } from 'react';
import { CalendarEventGroup } from '../shared/entities/calendar/interfaces/CalendarEventGroup';
import { CalendarEventType } from '../shared/entities/calendar/enums/CalendarEventType';
import { Location } from '../shared/entities/filters/enum/Location';
import { CalendarEvent } from '../shared/entities/calendar/interfaces/CalendarEvent';

export interface MasterCalendarContextValue {
  headquarter: Location;
  groups: CalendarEventGroup[];
  activeEventTypes: CalendarEventType[];
  filteredEvents: CalendarEvent[];
  allEvents: CalendarEvent[];
  isSpac: boolean;

  setActiveEventTypes(types: CalendarEventType[]): void;
  setHeadquarter(headquarter: Location): void;
  setIsSpac(isSpac: boolean): void;
}

export const MasterCalendarContext: Context<MasterCalendarContextValue> = createContext<MasterCalendarContextValue>({
  groups: [],
  activeEventTypes: [],
  filteredEvents: [],
  headquarter: Location.Global,
  allEvents: [],
  isSpac: false,
  setIsSpac: () => {},
  setHeadquarter: () => {},
  setActiveEventTypes: () => {},
});
