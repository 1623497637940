import React, { useContext, useMemo } from 'react';
import './CompanyHeader.scss';
import { CompanyContext } from '../context/CompanyContext';
import { CompanyTab } from '../../enums/CompanyTab';
import { ProfileData } from '../../interfaces/ProfileData';
import TreeView from '../../../shared/components/tree-view/TreeView';
import { CompanyHeaderService } from './services/CompanyHeaderService';
import { useClassName } from '../../../shared/hooks/useClassName';
import { CompanyHeaderBlock, CompanyHeaderElement } from './companyHeaderBem';
import CompanyHeaderFavourites from './components/CompanyHeaderFavourites';
import CompanyHeaderTrading from './components/company-header-trading/CompanyHeaderTrading';
import { ProfileDataElements } from '../../enums/ProfileDataElements';
import ViewLink from '../../../shared/entities/view/components/view-link/ViewLink';
import { getExchangeViewLinkData } from '../../../shared/entities/view/components/view-link/constants/viewLinks';

const CompanyHeader: React.FC = () => {
  const cn = useClassName(CompanyHeaderBlock.Root);

  const { getTabData } = useContext(CompanyContext);

  const profileData = getTabData(CompanyTab.Profile) as ProfileData;
  const infoData = useMemo(() => profileData?.[ProfileDataElements.Description], [profileData]);
  const tradingData = useMemo(() => profileData?.[ProfileDataElements.Trading], [profileData]);

  const exchange = useMemo(() => {
    const name = infoData?.exchangeName;
    const key = infoData?.exchangeCountryCode;

    if (!name) {
      return;
    }

    if (key) {
      return <ViewLink title={name} hasLinkStyle data={getExchangeViewLinkData(key)} />;
    } else {
      return `${name}`;
    }
  }, [infoData]);

  return (
    <>
      {infoData && (
        <div className={cn()}>
          <div className={cn(CompanyHeaderElement.Description)}>
            <div className={cn(CompanyHeaderElement.Star)}>
              <CompanyHeaderFavourites />
            </div>

            <div>
              <div className={cn(CompanyHeaderElement.Title)}>
                <h4>
                  <b>
                    {infoData.companyName} ({infoData.tickerSymbol})
                  </b>
                </h4>

                <span className={cn(CompanyHeaderElement.Subtitle)}>
                  {infoData.dealStatus && <>{infoData.dealStatus}</>}
                  {infoData.dealStatus && exchange && <>, </>}
                  {exchange && <>{exchange}</>}
                </span>
              </div>

              <div className={cn(CompanyHeaderElement.Sector)}>
                Sector:{' '}
                <TreeView
                  className={cn(CompanyHeaderElement.SectorTreeView)}
                  nodes={CompanyHeaderService.getTreeViewNodesFromInfo(infoData)}
                />
              </div>

              <div className={cn(CompanyHeaderElement.Node)}>{infoData.busDescript}</div>
            </div>
          </div>

          {tradingData && <CompanyHeaderTrading data={tradingData} />}
        </div>
      )}
    </>
  );
};

export default CompanyHeader;
