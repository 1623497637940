import React, { useCallback, useContext, useMemo, useState } from 'react';
import { validationSchema } from './validationSchema';
import Form from '../../../../../shared/components/forms/Form';
import FormGroup from '../../../../../shared/components/forms/FormGroup';
import ButtonSubmit from '../../../../../shared/components/forms/ButtonSubmit';
import { AlignTypesHorizontal } from '../../../../../shared/enums/AlignTypesHorizontal';
import { FavoriteIposFormField } from './FavoriteIposFormField';
import { InputType } from '../../../../../shared/components/forms/Input';
import { useClassName } from '../../../../../shared/hooks/useClassName';
import { FavoriteIposFormBlock } from './FavoriteIposFormBem';
import { BlockOrientation } from '../../../../../shared/enums/BlockOrientation';
import { ControlSize } from '../../../../../shared/enums/ControlSize';
import FormSearchInput from '../../../../../shared/components/forms/components/search-input/FormSearchInput';
import { CompanySearchDataService } from '../../../../../search/components/search/services/CompanySearchDataService';
import { AutocompleteItem } from '../../../../../shared/components/autocomplete/interfaces/AutocompleteItem';
import { ViewDashboardContext } from '../../../../../shared/entities/view/components/view-dashboard/ViewDashboardContext';
import { useDispatch } from 'react-redux';
import { addFavouriteCompany } from '../../../../../store/favourite-company/actions';
import { SearchItemCompanyDtoProps } from '../../../../../search/components/search/interfaces/dto/enums/SearchItemCompanyDtoProps';
import { SearchInputSymbolsCount } from '../../../../../shared/components/forms/components/search-input/enums/SearchInputSymbolsCount';

const FavoriteIposForm: React.FC = () => {
  const cn = useClassName(FavoriteIposFormBlock.Root);

  const dispatch = useDispatch();

  const { onDataChange } = useContext(ViewDashboardContext);

  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedItem, setSelectedItem] = useState();
  const [defaultValues, setDefaultValues] = useState({});

  const getOptionsFn = useMemo(
    () => (searchTerm: string) =>
      CompanySearchDataService.getNotFavorite(searchTerm, SearchItemCompanyDtoProps.CompanyKey),
    [],
  );

  const onSubmit = useCallback(async () => {
    if (selectedItem) {
      setIsDisabled(true);

      dispatch(
        addFavouriteCompany(
          {
            companyKey: selectedItem.companyKey,
            companyName: selectedItem.companyName,
          },
          () => {
            // Reset form value to not display added ticker
            setDefaultValues({});
            onDataChange();
          },
        ),
      );
    }
  }, [dispatch, selectedItem, onDataChange]);

  const onChange = useCallback(data => {
    setIsDisabled(!data[FavoriteIposFormField.Ticker]);
  }, []);

  const onSelectItem = useCallback((item: AutocompleteItem) => setSelectedItem(item), []);

  return (
    <Form
      className={cn()}
      validationSchema={validationSchema}
      orientation={BlockOrientation.Line}
      alignHorizontal={AlignTypesHorizontal.Center}
      defaultValues={defaultValues}
      onChange={onChange}
      onSubmit={onSubmit}
    >
      <FormGroup>
        <FormSearchInput
          name={FavoriteIposFormField.Ticker}
          placeholder="Add company"
          type={InputType.Text}
          size={ControlSize.Small}
          width={ControlSize.Medium}
          searchSymbolsCount={SearchInputSymbolsCount.WithTicker}
          getOptionsFn={getOptionsFn}
          onSelectItem={onSelectItem}
        />
      </FormGroup>

      <ButtonSubmit
        submittedMessage="Added"
        message="Add"
        isSubmitted={false}
        isDisabled={isDisabled}
        size={ControlSize.Small}
      />
    </Form>
  );
};

export default FavoriteIposForm;
