export enum SpecialReportsBlock {
  Root = 'SpecialReports',
}

export enum SpecialReportsElement {
  MoreRecentReports = 'MoreRecentReports',
  AnnualReports = 'AnnualReports',
  QuarterlyReports = 'QuarterlyReports',
  SpecialReports = 'SpecialReports',
}
