import React, { useCallback, useEffect, useMemo } from 'react';
import './View.scss';
import useThunkReducer from 'react-hook-thunk-reducer';
import { OnGridSortFn } from './types/actionFunctions';
import { createInitialState, reducer } from './store/reducer';
import { updateTodaysPriceChangeByAvailability, UnSubscribePriceChange } from './store/actions/todaysPriceChange';
import { useQueryParams } from '../../../../effects/useQueryParams';
import ViewSummary from './components/view-summary/ViewSummary';
import {
  destroyView,
  initView,
  setGridPublicApi,
  setViewOptions,
  updateViewByLocationChange,
} from './store/actions/view';
import { updateViewSort } from './store/actions/sort';
import { initSocketEvents } from './store/actions/socket';
import ViewToolbar from './components/view-toolbar/ViewToolbar';
import { useClassName } from '../../../../hooks/useClassName';
import { ViewQueryParam } from './enums/ViewQueryParam';
import {
  canUpdatePriceChangeSelector,
  hasFooterSelector,
  hasViewFilterValuesControlsSelector,
  isInitSelector,
} from './store/selectors';
import { ViewLocationService } from './services/ViewLocationService';
import { GridSort } from '../../../../components/grid/grid/interfaces/GridSort';
import { ViewOptions } from './interfaces/options/ViewOptions';
import { GridPublicApi } from '../../../../components/grid/grid/interfaces/GridPublicApi';
import { ViewType } from './enums/ViewType';
import { Nullable } from '../../../../types/generics';
import ViewGrid from '../view-grid/ViewGrid';
import { ViewContext } from './ViewContext';
import { ViewBlock, ViewElement } from './viewBem';
import PrimarySection from '../../../../components/primary-section/PrimarySection';
import DocumentHead from '../../../../components/DocumentHead';
import { viewTypeRoutes } from './constants/viewTypeRoutes';
import { FiltersValues } from '../../../filters/types/FiltersValues';
import { searchByFilters, updateSearchFilters } from './store/actions/filter';
import ViewFooter from './components/view-footer/ViewFooter';
import { useDeepEffect } from '../../../../effects/useDeepEffect';
import { ViewEvents } from './interfaces/options/ViewEvents';
import ViewFilterValues from './components/view-applied-filters/ViewFilterValues';
import { ControlSize } from '../../../../enums/ControlSize';
import { ViewDefaultValuesService } from './services/ViewDefaultValuesService';
import { useLocation } from 'react-router-dom';
import { oneYearPricedDefaultLocations } from '../../../../../screens/constants/screensLocationGroups';
import { DealStatus } from '../../../filters/enum/DealStatus';
import { TimeFrame } from '../../../filters/enum/TimeFrame';
import { useSelectedTimeFrame } from '../../../../hooks/useSelectedTimeFrame';
import { isDateExceedingLimit, shouldInitSocketEvents } from './socket/helpers';

interface Props {
  className?: string;
  viewType: ViewType;
  options: ViewOptions;
  events?: ViewEvents;
  maxGridWidth?: ControlSize;
  viewId?: number;
}

const View: React.FC<Props> = props => {
  const cn = useClassName(ViewBlock.Root, props.className);

  const { events = {} } = props;
  const { onFiltersChange, onNewView, onSavedViewsChange } = events;

  const paramViewId = ViewLocationService.getViewIdFromQueryParams(useQueryParams().get(ViewQueryParam.ViewId));
  const viewId = props.viewId ? (paramViewId !== -1 ? paramViewId : props.viewId) : paramViewId;
  const urlLocation = useLocation();
  const pathName = urlLocation.pathname;
  const selectedTimeFrame = useSelectedTimeFrame();
  const [state, dispatch] = useThunkReducer(reducer, createInitialState(props.viewType, viewId, props.options));
  const canUpdatePriceChange = canUpdatePriceChangeSelector(state);
  const defaultValues = useMemo(() => ViewDefaultValuesService.getDefaultValues(props.options), [props.options]);
  const onGridSort: OnGridSortFn = useCallback(
    (sort: Nullable<GridSort>): void => {
      dispatch(updateViewSort(sort, viewId));
    },
    [dispatch, viewId],
  );

  const onGridReady = useCallback(
    (gridPublicApi: GridPublicApi) => {
      dispatch(setGridPublicApi(gridPublicApi));
      dispatch(initSocketEvents(gridPublicApi));
    },
    [dispatch],
  );

  const onSearchChange = useCallback(
    (filters: FiltersValues) => {
      if (filters.DealStatus === DealStatus.Priced) {
        if (oneYearPricedDefaultLocations.includes(pathName)) {
          if (selectedTimeFrame) {
            dispatch(updateSearchFilters({ timeframe: selectedTimeFrame }));
          } else {
            dispatch(updateSearchFilters({ timeframe: TimeFrame.Year }));
          }
        }
      }

      dispatch(updateSearchFilters(filters));
    },
    [dispatch, state.filters],
  );

  const onSearch = useCallback(() => {
    dispatch(searchByFilters());
  }, [dispatch, state?.filters?.DealStatus]);

  // View data initialization/destroying
  useEffect(() => {
    dispatch(initView());

    return (): void => {
      dispatch(destroyView());
    };
  }, [dispatch]);

  // On location change
  useEffect(() => {
    dispatch(updateViewByLocationChange(viewId));
  }, [viewId, dispatch]);

  // When socket and grid initialized or rows are updated
  useEffect(() => {
    dispatch(updateTodaysPriceChangeByAvailability());
  }, [dispatch, canUpdatePriceChange, state.rows]);

  useEffect(() => {
    const isDateExceedingLimitFlag = isDateExceedingLimit(state);
    const shouldInitSocketEventsFlag = shouldInitSocketEvents(state);
    if (isDateExceedingLimitFlag) {
      dispatch(UnSubscribePriceChange());
    } else if (shouldInitSocketEventsFlag) {
      state?.gridPublicApi && dispatch(initSocketEvents(state?.gridPublicApi));
    }
  }, [dispatch, state]);

  useDeepEffect(() => onNewView?.(state), [onNewView, state.viewData]);
  useDeepEffect(() => {
    onFiltersChange?.(state);
  }, [onFiltersChange, state.filters]);

  useDeepEffect(() => onSavedViewsChange?.(state.savedViews), [onSavedViewsChange, state.savedViews]);

  useEffect(() => {
    dispatch(setViewOptions(props.options));
  }, [props.options, dispatch]);

  const search = useMemo(
    () =>
      state.options.search && {
        defaultValues: state.filters,
        controls: state.options.search.controls,
        additionalControls: state.options.search.additionalControls,
        additionalControlsTitle: state.options.search.additionalControlsTitle,
        dictionaries: state.filtersDictionaries,
        onChange: onSearchChange,
        onSearch: onSearch,
      },
    [onSearch, onSearchChange, state.filters, state.filtersDictionaries, state.options.search],
  );
  // const columns = useMemo(() => state.columnsGroups, [state.columnsGroups]);

  return (
    <ViewContext.Provider value={{ dispatch, state }}>
      <PrimarySection
        className={cn()}
        additionalAction={state.options.additionalAction}
        header={state.options.header}
        subHeader={state.options.subHeader}
      >
        <DocumentHead path={viewTypeRoutes[state.viewType]} />

        {state.options.topContent && <div className={cn(ViewElement.TopContent)}>{state.options.topContent}</div>}

        <ViewGrid
          idCols={state.options.grid?.idCols}
          hasCountReducing={state.options.grid?.hasCountReducing}
          search={search}
          topContent={hasViewFilterValuesControlsSelector(state) ? <ViewFilterValues /> : undefined}
          toolbar={state.options.toolbar && <ViewToolbar />}
          summary={state.options.summary && <ViewSummary />}
          title={state.options?.grid?.title}
          footer={hasFooterSelector(state) && <ViewFooter />}
          columns={state.columnsGroups}
          customColumnsSettings={state.options.grid?.customColumnsSettings}
          rows={state.rows}
          sort={state.sort}
          isLoading={state.isLoading}
          isInit={isInitSelector(state)}
          shouldPinColumnsMobile={true}
          mobilePinColumns={state.options.grid?.pinColumns}
          isColumnsFitGrid={state.options.grid?.isColumnsFitGrid}
          isDateColumnSortingInverse={state.options?.grid?.isDateColumnSortingInverse}
          isFixedHeight={defaultValues.isFixedHeight}
          success={state.success}
          error={state.error}
          maxGridWidth={props.maxGridWidth}
          onSort={onGridSort}
          onReady={onGridReady}
          viewGridType={props.viewType}
          apiFooter={props.options.apiFooter}
        />
      </PrimarySection>
    </ViewContext.Provider>
  );
};

export default View;
