import React, { useContext, useMemo } from 'react';
import { StatsElementMapCharts } from './chart/StatsElementMapCharts';
import { StatsElementContext } from './context/StatsElementContext';
import PageLoader from '../../../components/loader/PageLoader';
import { useAsyncFnEffect } from '../../../hooks/async-fn/useAsyncFnEffect';
import StatsElementMapSimpleGrid from './simple-grid/StatsElementMapSimpleGrid';
import SecondarySection from '../../../components/secondary-section/SecondarySection';
import SecondarySectionsGroup from '../../../components/secondary-section/SecondarySectionsGroup';
import StatsElementInfo from './StatsElementInfo';
import { StatsElementInfoType } from '../enums/StatsElementInfoType';
import { StatsElementContentBlock } from './statsElementContentBem';
import { useClassName } from '../../../hooks/useClassName';
import StatsElementCommonFilters from './StatsElementCommonFilters';
import StatsElementViewGridMap from './view-grid/StatsElementViewGridMap';

const StatsElementContent: React.FC = () => {
  const cn = useClassName(StatsElementContentBlock.Root);

  const { dataType, loadData, pageFilters, isLoading } = useContext(StatsElementContext);

  const gridContext = useMemo(() => ({ dataType, pageFilters }), [dataType, pageFilters]);

  useAsyncFnEffect(() => {
    loadData();
  }, [pageFilters]);

  return (
    <SecondarySectionsGroup className={cn()}>
      <PageLoader loading={isLoading}>
        <StatsElementCommonFilters />

        <SecondarySection>
          <StatsElementMapCharts />
        </SecondarySection>

        <SecondarySection>
          <StatsElementInfo type={StatsElementInfoType.NumIpoText} />
        </SecondarySection>

        <SecondarySection>
          <StatsElementMapSimpleGrid gridContext={gridContext} />
        </SecondarySection>

        <SecondarySection>
          <StatsElementViewGridMap gridContext={gridContext} />
        </SecondarySection>

        <SecondarySection>
          <StatsElementInfo type={StatsElementInfoType.Footnote} />
        </SecondarySection>
      </PageLoader>
    </SecondarySectionsGroup>
  );
};

export default StatsElementContent;
