import { chunk } from 'lodash';
import { BreakpointName } from '../../../../../enums/BreakpointName';
import { FilterSectionsFormState } from '../interfaces/FilterSectionsFormState';
import { FilterSectionsLayoutOptions } from '../interfaces/FilterSectionsLayoutOptions';

export class FilterSectionsLayoutFactory {
  static getOptions(
    breakpoint: BreakpointName,
    sectionsStates: FilterSectionsFormState[],
  ): FilterSectionsLayoutOptions {
    let columns;

    switch (breakpoint) {
      case BreakpointName.ExtraLarge:
      case BreakpointName.Large:
        columns = 3;
        break;
      case BreakpointName.Medium:
      case BreakpointName.Small:
        columns = 2;
        break;
      case BreakpointName.ExtraSmall:
        columns = 1;
        break;
    }

    const chunks = chunk(sectionsStates, Math.ceil(sectionsStates.length / columns));

    return {
      style: {
        flex: `1 1 ${100 / columns}%`,
      },
      chunks,
    };
  }
}
