import React, { useContext } from 'react';
import { DashboardContext } from '../../DashboardContext';
import Button from '../../../shared/components/forms/Button';
import { ControlSize } from '../../../shared/enums/ControlSize';
import { useClassName } from '../../../shared/hooks/useClassName';
import { DashboardEditDefaultBlock } from './DashboardEditDefaultBem';

const DashboardEditDefaults: React.FC = () => {
  const { isDefaults, setDefaultWidgets } = useContext(DashboardContext);
  const cn = useClassName(DashboardEditDefaultBlock.Root);

  if (isDefaults) {
    return null;
  }

  return (
    <Button className={cn()} onClick={setDefaultWidgets} size={ControlSize.Small} isOutline={true}>
      Reset to Defaults
    </Button>
  );
};

export default DashboardEditDefaults;
