export enum ViewColumnDataType {
  Date = 'Date',
  Money = 'Money',
  Number = 'Number',
  Text = 'Text',
  Percentage = 'Percentage',
  Complex = 'Complex',
  Boolean = 'Boolean',
  Link = 'Link',
  Tree = 'Tree',
}
