import React from 'react';
import './ViewColumnsAddSections.scss';
import ViewColumnsAddSection from './ViewColumnsAddSection';
import { ViewColumnsAddSettings } from '../../interfaces/ViewColumnsAddSettings';
import { ViewColumnsAddSection as IColumnsSection } from '../../interfaces/ViewColumnsAddSection';
import { useClassName } from '../../../../../../../../../hooks/useClassName';
import {
  ViewColumnsAddSectionsBlock,
  ViewColumnsAddSectionsElement,
  ViewColumnsAddSectionsModifiers,
} from './viewColumnsAddSectionsBem';

interface Props {
  settings: ViewColumnsAddSettings[];
}

const ViewColumnsAddSections: React.FC<Props> = props => {
  const cn = useClassName(ViewColumnsAddSectionsBlock.Root);

  const getColClasses = (isTwoColumn = false): string => {
    return cn(ViewColumnsAddSectionsElement.Col, {
      [ViewColumnsAddSectionsModifiers.Two]: isTwoColumn,
    });
  };

  return (
    <section className={cn()}>
      <div className={cn(ViewColumnsAddSectionsElement.Row)}>
        {props.settings.map((columnsSettings: ViewColumnsAddSettings, settingsIndex: number) => (
          <div key={settingsIndex} className={getColClasses(columnsSettings.isTwoColumn)}>
            {columnsSettings.items.map((section: IColumnsSection, sectionIndex: number) => (
              <ViewColumnsAddSection
                key={sectionIndex}
                title={section.title}
                items={section.items}
                isHighlighted={section.isHighlighted}
                isClose={section.isClose}
              />
            ))}
          </div>
        ))}
      </div>
    </section>
  );
};

export default ViewColumnsAddSections;
