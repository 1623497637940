import React, { memo, ReactNode, useMemo } from 'react';
import Label from './Label';
import { ControlSize } from '../../enums/ControlSize';
import FormInput from './FormInput';
import './ValueRange.scss';
import { FieldError } from 'react-hook-form';
import uniqueId from 'lodash.uniqueid';
import { InputType } from './Input';
import { PrependValue } from './types/PrependValue';
import { AppendValue } from './types/AppendValue';
import { useClassName } from '../../hooks/useClassName';

export interface ValueRangeProps {
  label?: string | ReactNode;
  minName: string;
  maxName: string;
  min?: number;
  max?: number;
  minPlaceholder?: string | number;
  maxPlaceholder?: string | number;
  minError?: FieldError;
  maxError?: FieldError;
  minPrepend?: PrependValue[];
  maxPrepend?: PrependValue[];
  minAppend?: AppendValue[];
  maxAppend?: AppendValue[];
  isDisabled?: boolean;
  size?: ControlSize;
  width?: ControlSize;
}

const ValueRange: React.FC<ValueRangeProps> = props => {
  const { minError, maxError, width = ControlSize.ExtraSmall } = props;

  const cn = useClassName('ValueRange');

  const id = useMemo(() => uniqueId('ValueRange-'), []);

  const minPlaceholder = props.minPlaceholder
    ? `${props.minPlaceholder}`
    : typeof props.min === 'undefined'
    ? ''
    : `${props.min}`;
  const maxPlaceholder = props.maxPlaceholder
    ? `${props.maxPlaceholder}`
    : typeof props.max === 'undefined'
    ? ''
    : `${props.max}`;

  return (
    <div
      className={cn({
        [`${props.width}Width`]: props.width,
      })}
    >
      {props.label && (
        <Label error={minError || maxError} htmlFor={id}>
          {props.label}
        </Label>
      )}

      <div className="ValueRange__inputs">
        <div className="ValueRange__input">
          <FormInput
            type={InputType.Number}
            name={props.minName}
            min={props.min}
            max={props.max}
            size={props.size}
            width={width}
            isDisabled={props.isDisabled}
            prepend={props.minPrepend}
            append={props.minAppend}
            id={id}
            placeholder={minPlaceholder}
          />
        </div>

        <div className="ValueRange__input">
          <FormInput
            type={InputType.Number}
            name={props.maxName}
            min={props.min}
            max={props.max}
            size={props.size}
            width={width}
            isDisabled={props.isDisabled}
            prepend={props.maxPrepend}
            append={props.maxAppend}
            placeholder={maxPlaceholder}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ValueRange);
