import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import PrimarySectionRow from '../shared/components/primary-section/PrimarySectionRow';
import PrimarySection from '../shared/components/primary-section/PrimarySection';
import EmailPreferencesForm from './form/EmailPreferencesForm';
import { EmailPreferencesContext } from './context/EmailPreferencesContext';
import PageLoader from '../shared/components/loader/PageLoader';
import { useAsyncFn } from '../shared/hooks/async-fn/useAsyncFn';
import { useAsyncFnEffect } from '../shared/hooks/async-fn/useAsyncFnEffect';

const EmailPreferencesContent: React.FC = () => {
  const { loadAllSections } = useContext(EmailPreferencesContext);

  const [loadDataFn, isLoading] = useAsyncFn(loadAllSections);

  useAsyncFnEffect(loadDataFn);

  return (
    <PageLoader loading={isLoading}>
      <Helmet>
        <title>Email Preferences</title>
      </Helmet>

      <PrimarySection header="Email Preferences">
        <PrimarySectionRow>
          <EmailPreferencesForm />
        </PrimarySectionRow>
      </PrimarySection>
    </PageLoader>
  );
};

export default EmailPreferencesContent;
