import React from 'react';
import Button from '../../../../../../components/forms/Button';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { useClassName } from '../../../../../../hooks/useClassName';
import { FilterSectionFormToolbarActionBlock } from './filterSectionToolbarActionBem';

interface Props {
  onClick: () => void;
}

const FilterSectionToolbarAction: React.FC<Props> = props => {
  const cn = useClassName(FilterSectionFormToolbarActionBlock.Root);

  return (
    <Button className={cn()} size={ControlSize.Small} onClick={props.onClick}>
      {props.children}
    </Button>
  );
};

export default FilterSectionToolbarAction;
