import React from 'react';
import { IconName } from '../icon/IconName';
import ExpandButton from './ExpandButton';
import { ExpandButtonOptions } from './interfaces/ExpandButtonOptions';

const RowsExpanderButton: React.FC<ExpandButtonOptions> = props => {
  return (
    <ExpandButton
      expandViewOptions={{ iconName: IconName.Plus }}
      collapseViewOptions={{ iconName: IconName.Minus }}
      {...props}
    />
  );
};

export default RowsExpanderButton;
