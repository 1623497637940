import { ReducerAction } from '../../shared/interfaces/ReducerAction';

export const SET_IS_PRINT = 'SET_IS_PRINT';

export type State = {
  isPrint: boolean;
};

export type SetIsPrintAction = ReducerAction<typeof SET_IS_PRINT, { isPrint: boolean }>;

export type ActionTypes = SetIsPrintAction;
