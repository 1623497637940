import React, { ReactNode, useCallback, useRef } from 'react';
import './ViewGrid.scss';
import { GridRow } from '../../../../components/grid/shared/types/GridRow';
import Grid from '../../../../components/grid/grid/Grid';
import { ViewColumnSystemName } from './enums/ViewColumnSystemName';
import { useClassName } from '../../../../hooks/useClassName';
import { ViewGridBlock, ViewGridElement } from './viewGridBem';
import { OnGridSortFn } from '../view/types/actionFunctions';
import { OnGridReadyFn } from '../../../../components/grid/grid/types/OnGridReadyFn';
import { OnGridViewportChangedFn } from '../../../../components/grid/grid/types/OnGridViewportChangedFn';
import ViewGridToolbar from './components/parts/ViewGridToolbar';
import ViewGridSummary from './components/parts/ViewGridSummary';
import ViewGridFooter from './components/parts/ViewGridFooter';
import { ViewGridContext } from './context/view-grid/ViewGridContext';
import { useViewGridContextValue } from './context/view-grid/useViewGridContextValue';
import { ViewGridColumnOptions } from './types/ViewGridColumnOptions';
import { GlobalError } from '../../../../services/error/types/GlobalError';
import LoaderContainer from '../../../../components/loader/LoaderContainer';
import { NotificationSuccessMessage } from '../../../../../store/notification/types';
import ViewGridSearch from './components/parts/ViewGridSearch';
import { ViewGridSearchProp } from './interfaces/ViewGridSearchProp';
import { GridPaginationOptions } from '../../../../components/grid/grid/interfaces/GridPaginationOptions';
import { GridCountReducerOptions } from '../../../../components/grid/grid/interfaces/GridCountReducerOptions';
import ViewGridTopContent from './components/parts/ViewGridTopContent';
import { useAbsoluteElementCenter } from '../../../../hooks/useAbsoluteElementCenter';
import { ViewGridColumnsSetupOptions } from './interfaces/ViewGridColumnsSetupOptions';
import { ControlSize } from '../../../../enums/ControlSize';
import { ComponentMessage } from '../../../../enums/ComponentMessage';
import classNames from 'classnames';
import ViewApiFootnote from '../view/components/view-footer/ViewApiFootnote';

export interface ViewGridCommonProps extends ViewGridColumnsSetupOptions {
  idCols?: ViewColumnSystemName[];

  columns?: ViewGridColumnOptions[];

  isHeaderHidden?: boolean;
  isFixedHeight?: boolean;

  shouldPinColumnsMobile?: boolean;
  isColumnsFitGrid?: boolean;
  mobilePinColumns?: ViewColumnSystemName[];

  hasCountReducing?: boolean;
  countReducingOptions?: GridCountReducerOptions;

  hasPagination?: boolean;
  paginationOptions?: GridPaginationOptions;

  topContent?: ReactNode;
  search?: ViewGridSearchProp;
  toolbar?: ReactNode;
  summary?: ReactNode;
  title?: ReactNode;
  footer?: ReactNode;
  footerApi?: ReactNode;

  success?: NotificationSuccessMessage;
  error?: GlobalError;

  maxGridWidth?: ControlSize;

  apiFooter?: boolean;

  onSort?: OnGridSortFn;
  onReady?: OnGridReadyFn;
  onViewportChanged?: OnGridViewportChangedFn;
  overlayNoRowsTemplate?: ComponentMessage;
}

export interface ViewGridProps extends ViewGridCommonProps {
  rows?: GridRow[];
  isInit?: boolean;
  isLoading: boolean;
  className?: string;
  onChange?: () => void;
  viewGridType?: string;
}

const ViewGrid: React.FC<ViewGridProps> = props => {
  const { onReady } = props;

  const ref = useRef<HTMLElement>(null);

  const cn = useClassName(ViewGridBlock.Root, props.className);

  const contextValue = useViewGridContextValue({ ...props });
  const centerStyle = useAbsoluteElementCenter(ref.current, props.isLoading);

  const onGridReady = useCallback(
    gridPublicApi => {
      contextValue.setGridPublicApi(gridPublicApi);
      onReady?.(gridPublicApi);
    },
    [onReady, contextValue],
  );

  return (
    <ViewGridContext.Provider value={contextValue}>
      <section ref={ref} className={cn()}>
        {contextValue.isGridVisible ? (
          <>
            {props.topContent && <ViewGridTopContent>{props.topContent}</ViewGridTopContent>}

            {props.search && <ViewGridSearch />}

            {contextValue.hasHeader && (
              <section className={cn(ViewGridElement.Header)}>
                {props.toolbar && <ViewGridToolbar>{props.toolbar}</ViewGridToolbar>}

                {props.summary && <ViewGridSummary>{props.summary}</ViewGridSummary>}
              </section>
            )}

            {props.title && <div className={cn(ViewGridElement.Title)}>{props.title}</div>}

            <Grid
              className={classNames(`${cn(ViewGridElement.Grid)}`, props.viewGridType ? props.viewGridType : null)}
              idCols={contextValue.idCols}
              columns={contextValue.columns}
              isLoading={false}
              rows={contextValue.rows}
              mobilePinColumns={contextValue.mobilePinColumns}
              isHeaderHidden={props.isHeaderHidden}
              isFixedHeight={props.isFixedHeight}
              isColumnsFitGrid={props.isColumnsFitGrid}
              hasPagination={props.hasPagination}
              paginationOptions={props.paginationOptions}
              hasCountReducer={props.hasCountReducing}
              countReducerOptions={props.countReducingOptions}
              maxGridWidth={props.maxGridWidth}
              onGridSort={props.onSort}
              onGridReady={onGridReady}
              onViewportChanged={props.onViewportChanged}
              overlayNoRowsTemplate={props.overlayNoRowsTemplate}
              onChange={props.onChange}
            />

            {props.footer && <ViewGridFooter>{props.footer}</ViewGridFooter>}

            {props.apiFooter && <ViewApiFootnote />}

            {props.isLoading && <LoaderContainer isAbsolute overlayContentStyle={centerStyle} />}
          </>
        ) : (
          <LoaderContainer hasBackground={false} />
        )}
      </section>
    </ViewGridContext.Provider>
  );
};

export default ViewGrid;
