import { ExchangeOption } from '../../../enums/ExchangeOption';
import { DealStatus } from '../enum/DealStatus';
import { Location } from '../enum/Location';

export class FilterSectionsValueService {
  static readonly usExchanges: string[] = [ExchangeOption.Nyse, ExchangeOption.Nasdaq, ExchangeOption.NyseAmerican];

  static readonly nonUsChinaASharesExchanges: string[] = [
    ExchangeOption.ShanghaiStockExchange,
    ExchangeOption.ShanghaiTech,
    ExchangeOption.ShenzhenStockExchange,
    // Adding key codes for Shanghai Stock Exchange, Shanghai Tech, and Shenzhen Stock Exchange as some pages have key codes as values, and not names.
    '16',
    '26',
    '108',
  ];

  static isUsExchange(exchange: string): boolean {
    return this.usExchanges.includes(exchange);
  }

  static isNonUsChinaASharesExchange(exchange: string): boolean {
    return this.nonUsChinaASharesExchanges.includes(exchange);
  }

  static isTimeFrameAvailable(dealStatus?: DealStatus): boolean {
    return dealStatus !== DealStatus.Upcoming;
  }

  static isIncludeSpacAvailable(location: Location): boolean {
    return location !== Location.NonUS;
  }

  static isIncludeAsharesAvailable(location: Location): boolean {
    return location !== Location.US;
  }

  static isInvestorsFounderManagementOwnedAvailable(investorsPeBackedLBO: boolean | undefined): boolean {
    return investorsPeBackedLBO !== true;
  }

  static isInvestorsPeBackedLBOAvailable(investorsFounderManagementOwned: boolean | undefined): boolean {
    return investorsFounderManagementOwned !== true;
  }
}
