import React, { useContext, useMemo } from 'react';
import './ViewNotes.scss';
import OrderedList from '../../../../../../components/ordered-list/OrderedList';
import { ViewContext } from '../../ViewContext';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { OrderedListItem } from '../../../../../../components/ordered-list/interfaces/OrderedListItem';

const ViewNotes: React.FC = () => {
  const { state } = useContext(ViewContext);
  const notes = state.notes;

  const orderedItems = useMemo<OrderedListItem[]>(
    () =>
      notes.map(note => ({
        description: (
          <>
            <b>{note.companyTicker}:</b> {note.note}
          </>
        ),
      })),
    [notes],
  );

  return (
    <>
      <OrderedList
        className="ViewNotes"
        title="Notes:"
        items={orderedItems}
        size={ControlSize.ExtraSmall}
        isTitleSizeAsItem
      />
    </>
  );
};

export default ViewNotes;
