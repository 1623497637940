import { isArray } from 'lodash';
import { FormControlVisibleValueOptions } from '../interfaces/FormControlVisibleValueOptions';
import { FormControlType } from '../../form-builder/enums/FormControlType';
import { FormControl } from '../../form-builder/types/formControlsTypes';
import { AnyObject } from '../../../../../types/generics';
import { SelectService } from '../../../../../services/select/SelectService';
import { FormSelectOptionsSwitchService } from '../../select/services/FormSelectOptionsSwitchService';
import { SelectOptionValue } from '../../../../../services/select/types/SelectOptionValue';
import { SelectOptionProp } from '../../select/types/SelectOptionProp';
import { ReactNode } from 'react';
import { PrependValue } from '../../../types/PrependValue';
import { AppendValue } from '../../../types/AppendValue';
import { PrependValueType } from '../../../enums/PrependValueType';
import { AppendValueType } from '../../../enums/AppendValueType';
import { FormControlInputDisplayValueOptions } from '../interfaces/FormControlInputDisplayValueOptions';
import { SelectOption } from '../../../../../services/select/interfaces/SelectOption';
import { SelectOptionsData } from '../../select/types/SelectOptionsData';
import { NumberHelper } from '../../../../../helpers/NumberHelper';
import { NumberFormat } from '../../../../../enums/NumberFormat';
import { CheckboxType } from '../../../Checkbox';
import { CheckboxOptions } from '../../../interfaces/CheckboxOptions';
import { FormControlValueService } from '../../form-builder/services/FormControlValueService';
import { timeFramesOptions } from '../../../../../entities/filters/constants/options/timeFramesOptions';
import { DateHelper } from '../../../../../helpers/date/DateHelper';
import { IsoDate } from '../../../../../types';

export class FormControlVisibleValueFactory {
  public static get(
    control: FormControl,
    values: AnyObject,
    dictionaries?: AnyObject,
  ): FormControlVisibleValueOptions[] {
    const rawValue = this.getRaw(control, values, dictionaries);

    if (!rawValue) {
      return [];
    }

    if (isArray(rawValue)) {
      return rawValue;
    } else {
      return [rawValue];
    }
  }

  private static getRaw(
    control: FormControl,
    values: AnyObject,
    dictionaries?: AnyObject,
  ): FormControlVisibleValueOptions | FormControlVisibleValueOptions[] | undefined {
    switch (control.type) {
      case FormControlType.Checkbox: {
        const value = values[control.props.name];

        const valuesOptionsValues = {
          [control.props.name]: value,
        };

        if (FormControlValueService.hasNoValue(value)) {
          return;
        }

        return this.getCheckboxOptions(value, control.props.label, valuesOptionsValues);
      }
      case FormControlType.DateRangeSelect: {
        const from = values[control.props.minName];
        const to = values[control.props.maxName];

        const valuesOptionsValues = {
          [control.props.minName]: from,
          [control.props.maxName]: to,
        };

        return this.getValueRangeOptions(
          { value: from, isDate: true },
          { value: to, isDate: true },
          valuesOptionsValues,
          control.props.label,
        );
      }
      case FormControlType.TimeFrameSelect: {
        const from = control.props.minName && values[control.props.minName];
        const to = control.props.maxName && values[control.props.maxName];
        const timeFrame = control.props.timeFrameName && values[control.props.timeFrameName];
        const label = control.props.timeFrameLabel;

        const valuesOptionsValues = {
          [`${control.props.minName}`]: from,
          [`${control.props.maxName}`]: to,
          [`${control.props.timeFrameName}`]: timeFrame,
        };

        if (
          FormControlValueService.hasNoValue(from) &&
          FormControlValueService.hasNoValue(to) &&
          FormControlValueService.hasNoValue(timeFrame)
        ) {
          return;
        }

        if (timeFrame) {
          return this.getSelectOptions(timeFramesOptions, timeFrame, valuesOptionsValues, label);
        } else {
          return this.getValueRangeOptions(
            { value: from, prepend: [], append: [], isDate: true },
            { value: to, prepend: [], append: [], isDate: true },
            valuesOptionsValues,
            label,
          );
        }
      }
      case FormControlType.Select: {
        const value = values[control.props.name];
        const options = this.getOptions(control.props.name, control.props.options, dictionaries);
        const valuesOptionsValues = {
          [control.props.name]: value,
        };

        return control.props.isMultiSelect
          ? this.getMultiSelectOptions(options, value, valuesOptionsValues, control.props.label)
          : this.getSelectOptions(options, value, valuesOptionsValues, control.props.label);
      }
      case FormControlType.TextSelect: {
        const value = values[control.props.name];
        const options = this.getOptions(control.props.name, control.props.options, dictionaries);
        const valuesOptionsValues = {
          [control.props.name]: value,
        };

        return this.getSelectOptions(options, value, valuesOptionsValues, control.props.label);
      }
      case FormControlType.SelectSwitchOptions: {
        const switchValues = FormSelectOptionsSwitchService.getSwitchValuesByProps(
          control.props.textSelectsProps,
          values,
        );

        const selectProps = control.props.selectProps;
        const selectName = selectProps.name;
        const selectValue = values[selectName];

        const selectValuesOptionsValues = {
          [selectName]: selectValue,
        };

        const optionsData = this.getOptions<SelectOptionsData, SelectOptionsData>(
          selectName,
          control.props.optionsData,
          dictionaries,
        );
        const options = FormSelectOptionsSwitchService.getOptions(optionsData, switchValues);

        const textSelectsOptions = control.props.textSelectsProps.map(textSelectProp => {
          const name = textSelectProp.name;
          const value = values[name];

          return this.getSelectOptions(
            textSelectProp.options,
            value,
            {
              ...selectValuesOptionsValues,
              [name]: value,
            },
            textSelectProp.label,
          );
        });

        let selectControlOptions;
        if (control.props.selectProps.isMultiSelect) {
          selectControlOptions = this.getMultiSelectOptions(options, selectValue, selectValuesOptionsValues);
        } else {
          selectControlOptions = this.getSelectOptions(options, selectValue, selectValuesOptionsValues);
        }

        return [...textSelectsOptions, selectControlOptions].filter(
          options => !!options,
        ) as FormControlVisibleValueOptions[];
      }
      case FormControlType.ValueRange: {
        const from = values[control.props.minName];
        const to = values[control.props.maxName];

        const valuesOptionsValues = {
          [control.props.minName]: from,
          [control.props.maxName]: to,
        };

        return this.getValueRangeOptions(
          { value: from, prepend: control.props.minPrepend, append: control.props.minAppend },
          { value: to, prepend: control.props.maxPrepend, append: control.props.maxAppend },
          valuesOptionsValues,
          control.props.label,
        );
      }
      case FormControlType.CheckboxList: {
        const value = values[control.props.name];
        const name = control.props.name;
        const options = control.props.options;

        if (FormControlValueService.hasNoValue(value)) {
          return;
        }

        if (control.props.type === CheckboxType.Radio) {
          return this.getCheckboxListOption(options, value, name);
        }

        return value.map((valueItem: SelectOptionValue) => this.getCheckboxListOption(options, valueItem, name));
      }
      case FormControlType.Input: {
        const value = values[control.props.name];

        const valuesOptionsValues = {
          [control.props.name]: value,
        };

        if (FormControlValueService.hasNoValue(value)) {
          return;
        }

        return this.getInputOptions(
          { value, prepend: control.props.prepend, append: control.props.append },
          valuesOptionsValues,
          control.props.label,
        );
      }
      case FormControlType.DateSelect: {
        const value = values[control.props.name];

        if (FormControlValueService.hasNoValue(value)) {
          return;
        }

        return {
          title: control.props.label,
          displayValue: this.getDateSelectDisplayValue(value),
          values: {
            [control.props.name]: value,
          },
        };
      }
      case FormControlType.SelectWithValueRange: {
        const select = values[control.props.selectName];
        const selectOptions = this.getOptions(control.props.selectName, control.props.selectOptions, dictionaries);
        const from = values[control.props.rangeMinName];
        const to = values[control.props.rangeMaxName];
        const valuesOptionsValues = {
          [control.props.selectName]: select,
          [control.props.rangeMinName]: from,
          [control.props.rangeMaxName]: to,
        };

        const selectVisibleValue = this.getSelectOptions(
          selectOptions,
          select,
          valuesOptionsValues,
          control.props.selectLabel,
        );
        const valueRangeVisibleValue = this.getValueRangeOptions(
          { value: from, prepend: control.props.rangeMinPrepend, append: control.props.rangeMinAppend },
          { value: to, prepend: control.props.rangeMaxPrepend, append: control.props.rangeMaxAppend },
          valuesOptionsValues,
        );

        if (
          FormControlValueService.hasNoValue(selectVisibleValue) &&
          FormControlValueService.hasNoValue(valueRangeVisibleValue)
        ) {
          return;
        }

        if (FormControlValueService.hasNoValue(selectVisibleValue) && valueRangeVisibleValue) {
          return {
            title: control.props.selectLabel,
            displayValue: valueRangeVisibleValue.displayValue,
            values: valuesOptionsValues,
          };
        }

        if (FormControlValueService.hasNoValue(valueRangeVisibleValue) && selectVisibleValue) {
          return selectVisibleValue;
        }

        return {
          title: `${selectVisibleValue?.title} ${selectVisibleValue?.displayValue}`,
          displayValue: valueRangeVisibleValue?.displayValue,
          values: valuesOptionsValues,
        };
      }
      case FormControlType.TextSelectWithInput: {
        const value = values[control.props.name];

        const valuesOptionsValues = {
          [control.props.name]: value,
        };

        return this.getInputOptions({ value }, valuesOptionsValues, control.props.textSelectLabel);
      }
      case FormControlType.ButtonCheckbox: {
        const value = values[control.props.name];

        const valuesOptionsValues = {
          [control.props.name]: value,
        };

        return this.getCheckboxOptions(value, control.props.label, valuesOptionsValues);
      }
    }
  }

  private static getRangeDisplayValue(
    from: FormControlInputDisplayValueOptions,
    to: FormControlInputDisplayValueOptions,
  ): string {
    const fromDisplayValue = this.getInputDisplayValue(from);
    const toDisplayValue = this.getInputDisplayValue(to);

    let displayValue = fromDisplayValue ? `${fromDisplayValue} ` : '';

    if (toDisplayValue) {
      displayValue = `${displayValue}to ${toDisplayValue}`;
    }

    return displayValue;
  }

  private static getSelectOptions(
    options: SelectOptionProp[] = [],
    value: SelectOptionValue,
    valuesOptionsValues: AnyObject,
    title?: ReactNode,
  ): FormControlVisibleValueOptions | undefined {
    if (FormControlValueService.hasNoValue(value)) {
      return;
    }

    const optionTitle = SelectService.getOptionByValue(options, value)?.title;

    return {
      displayValue: optionTitle,
      values: valuesOptionsValues,
      title,
    };
  }

  private static getMultiSelectOptions(
    options: SelectOptionProp[],
    value: SelectOptionValue[],
    valuesOptionsValues: AnyObject,
    title?: ReactNode,
  ): FormControlVisibleValueOptions | undefined {
    if (!SelectService.hasMultiSelectValue(value)) {
      return;
    }

    const optionsTitles = SelectService.getOptionsTitlesByValues(options, value);

    return {
      displayValue: this.getMultiSelectDisplayValue(optionsTitles),
      values: valuesOptionsValues,
      title,
    };
  }

  private static getMultiSelectDisplayValue(values: (string | number)[]): string {
    return values.join(', ');
  }

  private static getValueRangeOptions(
    from: FormControlInputDisplayValueOptions,
    to: FormControlInputDisplayValueOptions,
    valuesOptionsValues: AnyObject,
    title?: ReactNode,
  ): FormControlVisibleValueOptions | undefined {
    if (FormControlValueService.hasNoValue(from.value) && FormControlValueService.hasNoValue(to.value)) {
      return;
    }

    return {
      displayValue: this.getRangeDisplayValue(from, to),
      values: valuesOptionsValues,
      title,
    };
  }

  private static getCheckboxOptions(
    controlValue: boolean | undefined,
    value: ReactNode,
    valuesOptionsValues: AnyObject,
  ): FormControlVisibleValueOptions | undefined {
    if (!controlValue) {
      return;
    }

    return {
      title: '',
      displayValue: value,
      values: valuesOptionsValues,
    };
  }

  private static getInputOptions(
    displayValueOptions: FormControlInputDisplayValueOptions,
    valuesOptionsValues: AnyObject,
    title?: ReactNode,
  ): FormControlVisibleValueOptions | undefined {
    if (!displayValueOptions.value) {
      return;
    }

    return {
      title,
      displayValue: this.getInputDisplayValue(displayValueOptions),
      values: valuesOptionsValues,
    };
  }

  private static getInputDisplayValue(options: FormControlInputDisplayValueOptions): string | undefined {
    if (FormControlValueService.hasNoValue(options.value)) {
      return;
    }

    let value;

    if (options.isDate) {
      value = this.getDateSelectDisplayValue(options.value as IsoDate);
    } else {
      value = NumberHelper.getFormatByType(options.value, NumberFormat.WithComma);
    }

    const prependDisplayValue = this.getPrependDisplayValue(options.prepend);
    const appendDisplayValue = this.getAppendDisplayValue(options.append);

    return `${prependDisplayValue}${value}${appendDisplayValue}`;
  }

  private static getPrependDisplayValue(prependValues: PrependValue[] = []): string {
    return prependValues.reduce((displayValue: string, prependValue: PrependValue) => {
      let newDisplayValue = displayValue;

      if (prependValue === PrependValueType.GreaterEqual) {
        newDisplayValue = `${displayValue}${prependValue} `;
      }

      if (prependValue === PrependValueType.Money) {
        newDisplayValue = `${displayValue}${prependValue}`;
      }

      return newDisplayValue;
    }, '');
  }

  private static getAppendDisplayValue(appendValues: AppendValue[] = []): string {
    return appendValues.reduce((displayValue: string, appendValues: AppendValue) => {
      let newDisplayValue = displayValue;

      if (appendValues === AppendValueType.Percentage) {
        newDisplayValue = `${displayValue}${appendValues} `;
      }

      return newDisplayValue;
    }, '');
  }

  private static getOptions<T = SelectOptionProp[], K = SelectOption[]>(
    name: string,
    options?: T,
    dictionaries?: AnyObject,
  ): K {
    return options || (dictionaries && dictionaries[name]) || [];
  }

  private static getCheckboxListOption(
    options: CheckboxOptions[],
    value: SelectOptionValue,
    valueName: string,
  ): FormControlVisibleValueOptions {
    const optionTitle = SelectService.getOptionByValue(options, value)?.title;

    return {
      title: '',
      displayValue: optionTitle,
      values: {
        [valueName]: value,
      },
    };
  }

  private static getDateSelectDisplayValue(dateStr: IsoDate): string {
    return DateHelper.formatISODateStr(dateStr);
  }
}
