import { GridCellValue } from '../types/GridCellValue';
import { GridRow } from '../types/GridRow';
import { GridColumn } from '../interfaces/GridColumn';
import { isNil } from 'lodash';

export class GridCellDataService {
  static getValue(row: GridRow, column: GridColumn): GridCellValue {
    return row[column.field];
  }

  static hasNoValue(value: GridCellValue): boolean {
    return isNil(value) || value === '';
  }
}
