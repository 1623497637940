import React, { useContext, useMemo } from 'react';
import './CompanyMgmtOwners.scss';
import { CompanyContext } from '../context/CompanyContext';
import CompanyMapTables from '../shared/CompanyMapTables';
import CompanySectionRow from '../shared/CompanySectionRow';
import CompanyParagraph from '../shared/CompanyParagraph';
import CompanySection from '../shared/CompanySection';
import { Nullable } from '../../../shared/types/generics';
import { CompanyMgmtOwnersDto } from '../../interfaces/CompanyMgmtOwnersDto';
import { CompanyTab } from '../../enums/CompanyTab';
import HtmlStringFormatterJustified from '../../../shared/components/HtmlStringFormatterJustified';

const CompanyMgmtOwners: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const data: Nullable<CompanyMgmtOwnersDto> = getTabData(CompanyTab.MgmtOwners);

  const titleProps = useMemo(() => {
    const ratingValue = data?.rating;

    return {
      tab: CompanyTab.MgmtOwners,
      ratingTitle: 'Governance Rating',
      defaultTitle: 'Corporate Governance',
      ratingValue,
      lastUpdated: data?.govInsightsPostDate,
    };
  }, [data]);

  const tables = useMemo(
    () =>
      data?.grids.map(grid => ({
        ...grid,
        isFullWidth: true,
      })),
    [data],
  );

  return (
    <>
      {data && <h3 className="printHead">Mgmt/Owners</h3>}

      <CompanySection className="CompanyMgmtOwners" data={data} titleProps={titleProps}>
        {data && (
          <>
            <CompanySectionRow>
              {data.paragraph && (
                <CompanyParagraph
                  label={data.paragraph.label}
                  text={<HtmlStringFormatterJustified value={data.paragraph.text as string} />}
                />
              )}
            </CompanySectionRow>
            {tables && <CompanyMapTables tables={tables} />}
          </>
        )}
      </CompanySection>
    </>
  );
};

export default CompanyMgmtOwners;
