import React, { Component } from 'react';
import './FileActionsCellFormatter.scss';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { FileActionProp } from '../../../../../file-actions/interfaces/FileActionProp';
import FileActions from '../../../../../file-actions/FileActions';
import { FileActionType } from '../../../../../file-actions/enums/FileActionType';
import {
  GridCellFormatterCommonProps,
  GridCellFormatterFileActionsFormatterProp,
  GridCellFormatterFileActionsProps,
} from '../../../interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from './CustomCellFormatterWrapper';

interface Props extends GridCellFormatterFileActionsProps, GridCellFormatterCommonProps {}

class FileActionsCellFormatter extends Component<Props, { fileActions: FileActionProp[] }> {
  constructor(props: Props) {
    super(props);

    this.state = { fileActions: this.getFileActions(props) };
  }

  getFileActions(props: Props) {
    return props.fileActionsFormatterProps.map((fileAction: GridCellFormatterFileActionsFormatterProp) => {
      const mappedAction = {
        ...fileAction,
        href: fileAction.hrefColumn ? props.data[fileAction.hrefColumn] : fileAction.href,
        title: fileAction.titleColumn ? props.data[fileAction.titleColumn] : fileAction.title,
      };

      return fileAction.mapFn ? fileAction.mapFn(mappedAction, props.data) : mappedAction;
    });
  }

  onActionClick(actionType: FileActionType): void {
    this.props.actionClick?.(actionType, this.props.data);
  }

  render() {
    return (
      <CustomCellFormatterWrapper value={this.props.value}>
        <FileActions
          fileActions={this.state.fileActions}
          size={ControlSize.Small}
          onActionClick={this.onActionClick}
          className="FileActionsCellFormatter"
        />
      </CustomCellFormatterWrapper>
    );
  }
}

export default FileActionsCellFormatter;
