import React, { memo, ReactNode } from 'react';
import './FormSelectWithValueRange.scss';
import FormValueRange from '../../FormValueRange';
import { SelectOptionProp } from './types/SelectOptionProp';
import { ControlSize } from '../../../../enums/ControlSize';
import FormSelect from './FormSelect';

export interface FormSelectWithValueRangeProps {
  selectName: string;
  selectOptions?: SelectOptionProp[];
  selectLabel?: string | ReactNode;
  selectPlaceholder?: string;
  selectWidth?: ControlSize;
  rangeMinName: string;
  rangeMaxName: string;
  rangeMinPrepend?: string[];
  rangeMaxPrepend?: string[];
  rangeMinAppend?: string[];
  rangeMaxAppend?: string[];
  isDisabled?: boolean;
  size?: ControlSize;
}

const FormSelectWithValueRange: React.FC<FormSelectWithValueRangeProps> = props => {
  return (
    <div className="FormSelectWithValueRange">
      <FormSelect
        name={props.selectName}
        options={props.selectOptions}
        label={props.selectLabel}
        isDisabled={props.isDisabled}
        placeholder={props.selectPlaceholder}
        size={props.size}
        width={props.selectWidth}
        isMultiSelect={false}
      />

      <FormValueRange
        minName={props.rangeMinName}
        maxName={props.rangeMaxName}
        minPrepend={props.rangeMinPrepend}
        maxPrepend={props.rangeMaxPrepend}
        minAppend={props.rangeMinAppend}
        maxAppend={props.rangeMaxAppend}
        size={props.size}
        isDisabled={props.isDisabled}
      />
    </div>
  );
};

export default memo(FormSelectWithValueRange);
