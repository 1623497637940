import React from 'react';
import './ButtonNav.scss';
import Button from '../../forms/Button';
import { ColorScheme } from '../../../enums/ColorScheme';
import { RoutePath } from '../../../enums/RoutePath';

interface Props {
  isActive: boolean;
  title: RoutePath;
  routePath: RoutePath;
}

const ButtonNav: React.FC<Props> = props => {
  return (
    <Button
      buttonLink={{
        routePath: props.routePath,
      }}
      colorScheme={ColorScheme.Primary}
      isOutline={!props.isActive}
      className="ButtonNav"
    >
      {props.title}
    </Button>
  );
};

export default ButtonNav;
