import React, { useCallback, useState } from 'react';
import Button from '../../../../../../components/forms/Button';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { IconName } from '../../../../../../components/icon/IconName';
import ViewGridToolbarGroup from '../../../view-grid/components/parts/ViewGridToolbarGroup';
import IconWithContent from '../../../../../../components/icon/IconWithContent';
import ViewColumnsModal from '../view-columns/ViewColumnsModal';

const ViewToolbarGroupAddColumns: React.FC = () => {
  const [isModalShow, setIsModalShow] = useState(false);

  const onAddColumnClick = useCallback(() => setIsModalShow(true), []);

  return (
    <ViewGridToolbarGroup className="ViewGridToolbarGroupAddColumns">
      <Button size={ControlSize.Small} onClick={onAddColumnClick}>
        <IconWithContent name={IconName.Plus} size={ControlSize.ExtraSmall}>
          Add Column
        </IconWithContent>
      </Button>

      <ViewColumnsModal modalShowState={[isModalShow, setIsModalShow]} />
    </ViewGridToolbarGroup>
  );
};

export default ViewToolbarGroupAddColumns;
