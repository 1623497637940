import React, { useContext } from 'react';
import { ViewDashboardContext } from '../../../shared/entities/view/components/view-dashboard/ViewDashboardContext';
import { SearchResultsFilterGroupName } from '../../enums/SearchResultsFilterGroupName';
import { ViewDataTable } from '../../../shared/entities/view/components/view/interfaces/api/ViewDataTable';
import SearchResultsGrid from './SearchResultsGrid';
import { ComponentMessage } from '../../../shared/enums/ComponentMessage';
import { videosCustomColumnsSettings } from '../../shared/constants/custom-columns-settings/videosCustomColumnsSettings';

interface Props {
  onChange?: () => void;
}

const SearchResultsVideosGrid: React.FC<Props> = props => {
  const data = useContext(ViewDashboardContext)?.data?.[SearchResultsFilterGroupName.Videos] as ViewDataTable;

  return (
    <SearchResultsGrid
      gridTitle={'Videos'}
      dataTable={data}
      customColumnsSettings={videosCustomColumnsSettings}
      overlayNoRowsTemplate={ComponentMessage.NoData}
      onChange={props.onChange}
    />
  );
};

export default SearchResultsVideosGrid;
