import { ViewAppliedFilters } from '../../interfaces/ViewAppliedFilters';
import { Thunk } from 'react-hook-thunk-reducer';
import { ActionTypes, SET_VIEW_FILTERS, SetViewFilters, ViewState } from '../types';
import { ViewFilterService } from '../../services/ViewFilterService';
import { updateViewByCurrentState, updateViewByDataChange } from './view';
import { FormControlDeleteVisibleValueOptions } from '../../../../../../components/forms/components/form-control-visible-value/interfaces/FormControlDeleteVisibleValueOptions';
import { FormControlVisibleValueService } from '../../../../../../components/forms/components/form-control-visible-value/services/FormControlVisibleValueService';

export const setViewFilters = (filters: ViewAppliedFilters): SetViewFilters => {
  if (filters?.endDate) {
    if ('timeframe' in filters) {
      delete filters.timeframe;
    }
  }
  return {
    type: SET_VIEW_FILTERS,
    payload: {
      filters,
    },
  };
};

export const updateQuickViewFilters = (quickFilters: ViewAppliedFilters): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state: ViewState = getState();
    if (!ViewFilterService.shouldUpdateQuickFilters(quickFilters, state.filters, state.options)) {
      return;
    }

    const newFilters = ViewFilterService.getNewFilters(quickFilters, state.filters);

    dispatch(
      updateViewByDataChange({
        filters: newFilters,
      }),
    );
  };
};

export const updateSearchFilters = (searchFilters: ViewAppliedFilters): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state: ViewState = getState();
    const newFilters = ViewFilterService.getNewFilters(searchFilters, state.filters);

    dispatch(setViewFilters(newFilters));
  };
};

export const searchByFilters = (): Thunk<ViewState, ActionTypes> => {
  return async (dispatch): Promise<void> => {
    dispatch(updateViewByCurrentState());
  };
};

export const deleteFilters = (
  deleteValueOptions: FormControlDeleteVisibleValueOptions,
): Thunk<ViewState, ActionTypes> => {
  return async (dispatch, getState): Promise<void> => {
    const state = getState();
    const filters = ViewFilterService.deleteEmptyFilters(
      FormControlVisibleValueService.deleteControlValues(deleteValueOptions, state.filters),
    );

    dispatch(
      updateViewByDataChange({
        filters,
      }),
    );
  };
};
