import { SelectOption } from '../../../../../../services/select/interfaces/SelectOption';
import { StatsElementFilterInternalName } from '../../../../enums/StatsElementGridFilterName';
import { StatsElementInternalFiltersValue } from '../interfaces/StatsElementInternalFiltersValue';
import { statsElementFiltersInternalTitles } from '../constants/statsElementSimpleGridFiltersTitles';
import { statsElementFilterInternalDefaultFilters } from '../constants/statsElementFilterInternalDefaultFilters';
import { StatsInternalFilterFactory } from '../../../../factories/StatsInternalFilterFactory';

export class StatsElementFiltersInternalService {
  static getIsTextSelectVisible(filterNames: StatsElementFilterInternalName[]): boolean {
    return !!filterNames.filter(
      (filterName: StatsElementFilterInternalName) => !statsElementFilterInternalDefaultFilters.includes(filterName),
    ).length;
  }

  static getFiltersNames(filters: StatsElementInternalFiltersValue): StatsElementFilterInternalName[] {
    return StatsInternalFilterFactory.sortByWeight(Object.keys(filters) as StatsElementFilterInternalName[]);
  }

  static getTextSelectOptions(filterNames: StatsElementFilterInternalName[]): SelectOption[] {
    return filterNames.map((filterName: StatsElementFilterInternalName) => ({
      title: statsElementFiltersInternalTitles[filterName],
      value: filterName,
    }));
  }

  static getTextSelectOptionsFromFilters(filters: StatsElementInternalFiltersValue): SelectOption[] {
    const filterNames = this.getFiltersNames(filters);

    return this.getTextSelectOptions(filterNames);
  }

  static getFirstFilterName(filters: StatsElementInternalFiltersValue): StatsElementFilterInternalName {
    const [firstValue] = this.getFiltersNames(filters);

    return firstValue;
  }

  static getFilterValue<T>(
    filters: StatsElementInternalFiltersValue,
    filterName: StatsElementFilterInternalName,
  ): T | undefined {
    return filters[filterName];
  }
}
