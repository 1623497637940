import React, { useMemo } from 'react';
import './SearchAutocompleteItem.scss';
import { useClassName } from '../../../shared/hooks/useClassName';
import {
  SearchAutocompleteItemBlock,
  SearchAutocompleteItemElement,
  SearchAutocompleteItemModifier,
} from './searchAutocompleteItemBem';
import Icon from '../../../shared/components/icon/Icon';
import { SearchAutocompleteItem as SearchAutocompleteItemInterface } from './types/SearchAutocompleteItem';
import { SearchAutocompleteFactory } from './factories/SearchAutocompleteFactory';
import { ControlSize } from '../../../shared/enums/ControlSize';
import { ColorScheme } from '../../../shared/enums/ColorScheme';

interface Props {
  item: SearchAutocompleteItemInterface;
}

const SearchAutocompleteItem: React.FC<Props> = props => {
  const { item } = props;

  const cn = useClassName(SearchAutocompleteItemBlock.Root);

  const { icon, tag, date, matchType, extraIcon } = useMemo(() => SearchAutocompleteFactory.getViewItemOptions(item), [
    item,
  ]);

  return (
    <div className={cn({ [item.type]: item.type, [`${matchType}`]: matchType })}>
      <div
        className={cn(SearchAutocompleteItemElement.Column, {
          [SearchAutocompleteItemModifier.Heading]: true,
        })}
      >
        <Icon className={cn(SearchAutocompleteItemElement.Icon)} name={icon} size={ControlSize.ExtraLarge} />

        <h6 className={cn(SearchAutocompleteItemElement.Title)}>{item.title}</h6>
      </div>

      <div
        className={cn(SearchAutocompleteItemElement.Column, {
          [SearchAutocompleteItemModifier.AdditionalInfo]: true,
        })}
      >
        {extraIcon && (
          <span className={cn(SearchAutocompleteItemElement.ExtraIcon)}>
            <Icon
              colorScheme={ColorScheme.Attention}
              className={cn(SearchAutocompleteItemElement.Icon)}
              name={extraIcon}
              size={ControlSize.ExtraSmall}
            />
          </span>
        )}
        <span className={cn(SearchAutocompleteItemElement.Tag)}>{tag}</span>

        {date && <span className={cn(SearchAutocompleteItemElement.Date)}>{date}</span>}
      </div>
    </div>
  );
};

export default SearchAutocompleteItem;
