import { MutableRefObject, useLayoutEffect, useState } from 'react';
import { DashboardWidgetProps } from '../components/DashboardWidget';
import { GridLayoutWidgetLayout } from '../../shared/components/grid-layout/interfaces/GridLayoutWidgetLayout';
import { Dimensions } from '../../shared/interfaces/Dimensions';

export function useDashboardWidgetDimensions(
  props: DashboardWidgetProps,
  { current }: MutableRefObject<HTMLDivElement | null>,
  resizedFlag?: number,
): Dimensions | null {
  const { breakpoint, width, widget } = props;
  const layout: GridLayoutWidgetLayout = widget.layouts[breakpoint];
  const [dimensions, setDimensions] = useState<Dimensions | null>(null);

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      if (current) {
        setDimensions({
          width: current.clientWidth,
          height: current.clientHeight,
        });
      }
    });

    return (): void => clearTimeout(timeout);
  }, [width, breakpoint, layout.width, layout.height, current, setDimensions, resizedFlag]);

  return dimensions;
}
