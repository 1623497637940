import ApiService from './ApiService';
import { TokenDto } from '../interfaces/TokenDto';
import { ApiPath } from './enums/ApiPath';
import { LoginFormData } from '../../marketing/login/form/LoginFormData';
import { LoginFormField } from '../../marketing/login/form/LoginFormField';
import { ResetPasswordFormData } from '../../marketing/resetPassword/form/ResetPasswordFormData';
import { ResetPasswordFormFields } from '../../marketing/resetPassword/form/ResetPasswordFormFields';
import { PasswordFormData } from '../../marketing/password/form/password-form-data';
import { ChangePasswordFormData } from '../../account-settings/form/change-password-form-data';
import { ChangePasswordFormFields } from '../../account-settings/form/change-password-form-fields';
import { AccountData } from '../../account-settings/interfaces/AccountData';
import { EmailPreferenceDto } from '../../email-preferences/form/interfaces/EmailPreferenceDto';
import { Token } from '../types';

export default class AuthApiService {
  static recoverPassword(passwordFormData: PasswordFormData): Promise<void> {
    return ApiService.post(ApiPath.PasswordRecovery, passwordFormData);
  }

  static changePassword(data: ChangePasswordFormData): Promise<ChangePasswordFormData> {
    const changePasswordPost: Partial<ChangePasswordFormData> = {
      [ChangePasswordFormFields.OldPassword]: data[ChangePasswordFormFields.OldPassword],
      [ChangePasswordFormFields.NewPassword]: data[ChangePasswordFormFields.NewPassword],
    };

    return ApiService.post(ApiPath.PasswordChanging, changePasswordPost);
  }

  static refreshToken(refreshToken: Token): Promise<TokenDto> {
    return ApiService.post(ApiPath.RefreshToken, { refreshToken });
  }

  static login(loginFormData: LoginFormData): Promise<TokenDto> {
    const data: Partial<LoginFormData> = {
      email: loginFormData[LoginFormField.Email],
      password: loginFormData[LoginFormField.Password],
    };

    return ApiService.post<TokenDto>(ApiPath.Login, data);
  }

  static reset(resetPasswordFormData: ResetPasswordFormData, token: string): Promise<TokenDto> {
    const data: Partial<ResetPasswordFormData> = {
      email: resetPasswordFormData[ResetPasswordFormFields.Email],
      password: resetPasswordFormData[ResetPasswordFormFields.Password],
      confirmPassword: resetPasswordFormData[ResetPasswordFormFields.ConfirmPassword],
      token: token,
    };

    return ApiService.post<TokenDto>(ApiPath.ResetPassword, data);
  }

  static putEmailPreferences(data: EmailPreferenceDto): Promise<void> {
    return ApiService.post<void>(ApiPath.EmailPreferences, data);
  }

  static getEmailPreferences(): Promise<EmailPreferenceDto> {
    return ApiService.get<EmailPreferenceDto>(ApiPath.EmailPreferences);
  }

  static getAccountSettingsData(): Promise<AccountData> {
    return ApiService.get<AccountData>(ApiPath.AccountSettings);
  }
}
