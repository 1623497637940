export enum ActionListNavItemBlock {
  Root = 'ActionListNavItem',
}

export enum ActionListNavItemElement {
  TitleContainer = 'titleContainer',
  Title = 'title',
  Order = 'order',
  Delete = 'delete',
}

export enum ActionListNavItemModifier {
  TitleNoWrap = 'titleNoWrap',
}
