import React from 'react';
import './HelpList.scss';
import HelpListItem from './HelpListItem';
import { IconName } from '../shared/components/icon/IconName';
import { RoutePath } from '../shared/enums/RoutePath';

const HelpList: React.FC = () => {
  return (
    <section className="HelpList">
      <div className="HelpList__row">
        <div className="HelpList__col">
          <HelpListItem
            icon={IconName.Check}
            title={'Welcome'}
            buttonTitle={'Getting Started'}
            buttonRoutePath={RoutePath.GettingStarted}
          />
        </div>

        <div className="HelpList__col">
          <HelpListItem
            icon={IconName.VideoCamera}
            title={'Video Tutorials'}
            buttonTitle={'Browse Videos'}
            buttonRoutePath={RoutePath.Videos}
          />
        </div>

        <div className="HelpList__col">
          <HelpListItem
            icon={IconName.Question}
            title={'FAQs'}
            buttonTitle={'Browse Top Questions'}
            buttonRoutePath={RoutePath.Faq}
          />
        </div>

        <div className="HelpList__col">
          <HelpListItem
            icon={IconName.Chat}
            title={'Contact Us'}
            buttonTitle={'Talk to a Human!'}
            buttonRoutePath={RoutePath.Chat}
          />
        </div>
      </div>
    </section>
  );
};

export default HelpList;
