import { TreeSelectLibProp } from '../SelectLib';
import { DropdownTreeSelectProps } from 'react-dropdown-tree-select';

export class SelectLibFactory {
  static getProps(props: TreeSelectLibProp): DropdownTreeSelectProps {
    const libProps: DropdownTreeSelectProps = {
      data: props.data,
      texts: {
        placeholder: props.placeholder,
      },
      disabled: props.isDisabled,
      inlineSearchInput: true,
      keepOpenOnSelect: false,
      showPartiallySelected: true,
      onChange: props.onChangeValue,
      onBlur: props.onBlur,
    };

    if (props.isMultiSelect) {
      libProps.mode = 'hierarchical';
    } else {
      libProps.mode = 'radioSelect';
    }

    return libProps;
  }
}
