import React, { useCallback, useContext, useMemo } from 'react';
import Toast from '../../../../shared/components/toast/Toast';
import ToastHeader from '../../../../shared/components/toast/ToastHeader';
import ToastBody from '../../../../shared/components/toast/ToastBody';
import './CompanyTeamNotesToast.scss';
import { TeamNote } from '../../../interfaces/TeamNote';
import { CompanyContext } from '../../context/CompanyContext';
import { useClassName } from '../../../../shared/hooks/useClassName';
import { CompanyTeamNotesToastBlock, CompanyTeamNotesToastModificator } from './CompanyTeamNotesToastBem';

type CompanyTeamNotesToastProps = TeamNote;

const TEAM_NOTE_OWNER = 'Me';

const CompanyTeamNotesToast: React.FC<CompanyTeamNotesToastProps> = props => {
  const cn = useClassName(CompanyTeamNotesToastBlock.Root);

  const { removeTeamNote } = useContext(CompanyContext);
  const isToastOwner = useMemo(() => props.author === TEAM_NOTE_OWNER, [props.author]);

  const onClose = useCallback(() => {
    if (isToastOwner) {
      removeTeamNote(props.id);
    }
  }, [removeTeamNote, props.id, isToastOwner]);

  return (
    <Toast className={cn({ [CompanyTeamNotesToastModificator.Owner]: isToastOwner })} onClose={onClose}>
      <ToastHeader title={props.author} createdAt={props.createdAt} closeButton={isToastOwner ? true : false} />

      <ToastBody>{props.text}</ToastBody>
    </Toast>
  );
};

export default CompanyTeamNotesToast;
