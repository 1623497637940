import { FilterControlSettings } from '../../../types/FilterControlSettings';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { ControlSize } from '../../../../../enums/ControlSize';
import { getStatusAvailabilityOptions } from '../../options/statusAvailabilityOptions';

export const securityTypeControls: FilterControlSettings = {
  [ViewFilterName.SecurityTypeCommonSharesList]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName.SecurityTypeCommonSharesList,
    props: {
      name: ViewFilterName.SecurityTypeCommonSharesList,
      label: 'Common Shares',
      options: getStatusAvailabilityOptions(),
      size: ControlSize.Small,
      isUnselectAvailable: true,
    },
  },
  [ViewFilterName.SecurityTypeADRsList]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName.SecurityTypeADRsList,
    props: {
      name: ViewFilterName.SecurityTypeADRsList,
      label: 'ADRs',
      options: getStatusAvailabilityOptions(),
      size: ControlSize.Small,
      isUnselectAvailable: true,
    },
  },
  [ViewFilterName.SecurityTypeREITList]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName.SecurityTypeREITList,
    props: {
      name: ViewFilterName.SecurityTypeREITList,
      label: 'REIT',
      options: getStatusAvailabilityOptions(),
      size: ControlSize.Small,
      isUnselectAvailable: true,
    },
  },
  [ViewFilterName.SecurityTypeMLPList]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName.SecurityTypeMLPList,
    props: {
      name: ViewFilterName.SecurityTypeMLPList,
      label: 'MLP',
      options: getStatusAvailabilityOptions(),
      size: ControlSize.Small,
      isUnselectAvailable: true,
    },
  },
  [ViewFilterName.IncludeSpac]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.IncludeSpac,
    props: {
      name: ViewFilterName.IncludeSpac,
      label: 'Include SPAC',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.SecurityTypeBlankCheckList]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName.SecurityTypeBlankCheckList,
    props: {
      name: ViewFilterName.SecurityTypeBlankCheckList,
      label: 'SPACs',
      options: getStatusAvailabilityOptions(),
      size: ControlSize.Small,
      isUnselectAvailable: true,
    },
  },
  [ViewFilterName.SecurityTypeRegAList]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName.SecurityTypeRegAList,
    props: {
      name: ViewFilterName.SecurityTypeRegAList,
      label: 'Reg A+',
      options: getStatusAvailabilityOptions(),
      size: ControlSize.Small,
      isUnselectAvailable: true,
    },
  },
  [ViewFilterName.SecurityTypeDirectListingList]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName.SecurityTypeDirectListingList,
    props: {
      name: ViewFilterName.SecurityTypeDirectListingList,
      label: 'Direct Listings',
      options: getStatusAvailabilityOptions(),
      size: ControlSize.Small,
      isUnselectAvailable: true,
    },
  },
};
