import ApiService from './ApiService';
import { ApiPath } from './enums/ApiPath';
import { PcmlTooltipArticlesDto } from '../../company/interfaces/PcmlTooltipArticlesDto';

export class PcmlApiService {
  static getTooltip(monitorListId: number): Promise<PcmlTooltipArticlesDto[]> {
    return ApiService.get<PcmlTooltipArticlesDto[]>(`${ApiPath.PcmlTooltip}`, {
      params: { monitorListId: monitorListId },
    });
  }
}
