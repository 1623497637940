import LocationService from '../../../shared/services/LocationService';
import { RoutePath } from '../../../shared/enums/RoutePath';
import { NumberId, Url } from '../../../shared/types';

export class VideoLocationService {
  static getVideoTutorialPath(id: number | undefined) {
    return LocationService.getRelativePath(`${RoutePath.VideoTutorials}/${id}`);
  }
  static getRoutePath(id?: NumberId, videoTypeName?: string): Url {
    return LocationService.getRelativePath(
      `${videoTypeName === 'Video Tutorials' ? RoutePath.VideoTutorials : RoutePath.LatestVideos}/${id}`,
    );
  }
  static getYoutubeVideoLocation(youtubeLink: string): Url {
    if (youtubeLink.indexOf('youtu.be') > -1) {
      return `https://www.youtube.com/embed/${youtubeLink.split('youtu.be/')[1]}`;
    }
    if (youtubeLink.indexOf('www.loom.com') > -1) {
      return youtubeLink;
    } else {
      return `https://www.youtube.com/embed/${youtubeLink.split('=')[1]}`;
    }
  }
}
