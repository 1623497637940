import { SelectOption } from '../../../../shared/services/select/interfaces/SelectOption';
import { CompanyFinancialsTermFilterName } from '../enums/CompanyFinancialsTermFilterName';

export const companyFinancialsTermOptions: SelectOption[] = [
  {
    title: 'Quarter',
    value: CompanyFinancialsTermFilterName.Quarter,
  },
  {
    title: 'Annual',
    value: CompanyFinancialsTermFilterName.Annual,
  },
];
