import React from 'react';
import BootstrapToast from 'react-bootstrap/Toast';
import './Toast.scss';
import classNames from 'classnames';

interface Props {
  onClose?: () => void;
  isShow?: boolean;
  delay?: number;
  autohide?: boolean;
  className?: string;
}

const Toast: React.FC<Props> = props => {
  return (
    <BootstrapToast
      className={classNames('Toast', props.className)}
      show={props.isShow}
      onClose={props.onClose}
      delay={props.delay}
      autohide={props.autohide}
    >
      {props.children}
    </BootstrapToast>
  );
};

export default Toast;
