import { defaultsDeep, isNil } from 'lodash';
import { GridAgColumn } from '../../../../../components/grid/grid/interfaces/GridAgColumn';
import { ViewColumnDataType } from '../enums/ViewColumnDataType';
import { AlignTypesHorizontal } from '../../../../../enums/AlignTypesHorizontal';
import { ViewGridSortService } from '../services/ViewGridSortService';
import { LinkColumnValue } from '../interfaces/column-values/LinkColumnValue';
import {
  GridCellFormatterType as GridCellFormatterEnum,
  GridCellFormatterType,
} from '../../../../../components/grid/shared/enums/GridCellFormatterType';
import { GridCellFormatter } from '../../../../../components/grid/shared/types/GridCellFormatter';
import { colorNumberCellFormatterColumns } from '../constants/viewGridCellFormatterColumns';
import { ViewGridColumnService } from '../services/ViewGridColumnService';
import { GridValueFormatterFn } from '../../../../../components/grid/shared/types/GridValueFormatterFn';
import { GridValueFormatterParams } from '../../../../../components/grid/shared/interfaces/GridValueFormatterParams';
import { ViewGridCellValueService } from '../services/ViewGridCellValueService';
import { viewGridColumnsSettings } from '../constants/viewGridColumnsSettings';
import { ViewGridColumnDto } from '../interfaces/ViewGridColumnDto';
import { GridCellDataService } from '../../../../../components/grid/shared/services/GridCellDataService';
import { NO_CELL_VALUE_MESSAGE } from '../../../../../components/grid/shared/constants/cell';
import { ViewColumnSystemName } from '../enums/ViewColumnSystemName';
import { ViewGridColumnWidth } from '../enums/ViewGridColumnWidth';
import { ViewGridColumnsSetupOptions } from '../interfaces/ViewGridColumnsSetupOptions';
import { GridHeightMultiplier } from '../../../../../components/grid/grid/enums/GridHeightMultiplier';

export class ViewGridSingleColumnFactory {
  static getColumn(column: ViewGridColumnDto, setupOptions?: ViewGridColumnsSetupOptions): GridAgColumn {
    const gridColumn = {
      field: column.name,
      headerName: column.title,
      cellFormatter: this.getCellFormatterOptions(column.name as ViewColumnSystemName),
      valueFormatter: this.getValueFormatter(column.name as ViewColumnSystemName),
      isTree: column.type === ViewColumnDataType.Tree,
      heightMultiplier: GridHeightMultiplier.Xs,
      ...this.getOptionsByDataType(
        column.name as ViewColumnSystemName,
        column.type,
        setupOptions?.isDateColumnSortingInverse,
      ),
      ...this.getCustomOptions(
        column.cellFormatter ? (column.cellFormatter as ViewColumnSystemName) : (column.name as ViewColumnSystemName),
        setupOptions?.customColumnsSettings,
      ),
    };

    this.setWrapOptions(gridColumn, setupOptions?.shouldWrapCellContainer);

    return gridColumn;
  }

  static getOptionsByDataType(
    columnName: ViewColumnSystemName,
    columnDataType: ViewColumnDataType,
    isDateColumnSortingInverse?: boolean,
  ): Partial<GridAgColumn> {
    switch (columnDataType) {
      case ViewColumnDataType.Date:
        return {
          alignHorizontal: AlignTypesHorizontal.Center,
          comparator: ViewGridSortService.sortDate.bind(ViewGridSortService),
          isSortingInverse: isNil(isDateColumnSortingInverse) ? true : isDateColumnSortingInverse,
          minWidth: ViewGridColumnWidth.Ms,
          width: ViewGridColumnWidth.Ms,
        };
      case ViewColumnDataType.Money:
        return {
          alignHorizontal: AlignTypesHorizontal.End,
          comparator: ViewGridSortService.sortMoney.bind(ViewGridSortService),
          isSortingInverse: true,
          minWidth: ViewGridColumnWidth.Sm,
          width: ViewGridColumnWidth.Sm,
        };
      case ViewColumnDataType.Number:
        return {
          alignHorizontal: AlignTypesHorizontal.End,
          comparator: ViewGridSortService.sortNumber.bind(ViewGridSortService),
          isSortingInverse: true,
          minWidth: ViewGridColumnWidth.Sm,
          width: ViewGridColumnWidth.Sm,
        };
      case ViewColumnDataType.Text:
        return {
          alignHorizontal: AlignTypesHorizontal.Start,
          comparator: ViewGridSortService.sortText.bind(ViewGridSortService),
          minWidth: ViewGridColumnWidth.Md,
          width: ViewGridColumnWidth.Md,
        };
      case ViewColumnDataType.Percentage:
        return {
          alignHorizontal: AlignTypesHorizontal.End,
          comparator: ViewGridSortService.sortPercentage.bind(ViewGridSortService),
          isSortingInverse: true,
          minWidth: ViewGridColumnWidth.Xs,
          width: ViewGridColumnWidth.Xs,
        };
      case ViewColumnDataType.Boolean:
        return {
          alignHorizontal: AlignTypesHorizontal.Center,
          comparator: ViewGridSortService.sortBoolean.bind(ViewGridSortService),
          minWidth: ViewGridColumnWidth.Xs,
          width: ViewGridColumnWidth.Xs,
        };
      case ViewColumnDataType.Link:
        return {
          alignHorizontal: AlignTypesHorizontal.Start,
          comparator: (valueA: LinkColumnValue, valueB: LinkColumnValue) =>
            ViewGridSortService.sortText(valueA.title, valueB.title),
          cellFormatter: {
            type: GridCellFormatterType.Link,
          },
          minWidth: ViewGridColumnWidth.Sm,
          width: ViewGridColumnWidth.Sm,
        };
      case ViewColumnDataType.Tree:
        return {
          sortable: true,
        };
    }

    return {};
  }

  private static getCellFormatterOptions(columnName: ViewColumnSystemName): GridCellFormatter | undefined {
    if (colorNumberCellFormatterColumns.includes(columnName)) {
      return {
        type: GridCellFormatterEnum.ColorNumber,
        props: { displayValueColumn: ViewGridColumnService.getVisibleColumnName(columnName) },
      };
    }

    return undefined;
  }

  private static getValueFormatter(columnSystemName: ViewColumnSystemName): GridValueFormatterFn {
    return (params: GridValueFormatterParams): string =>
      GridCellDataService.hasNoValue(params.value)
        ? NO_CELL_VALUE_MESSAGE
        : ViewGridCellValueService.getVisible(params.data, columnSystemName);
  }

  private static getCustomOptions(
    columnSystemName: ViewColumnSystemName,
    customColumnsSettings?: Partial<GridAgColumn>[],
  ): Partial<GridAgColumn> {
    const customColumn = customColumnsSettings?.find(column => column.field === columnSystemName);

    return defaultsDeep({}, customColumn, viewGridColumnsSettings[columnSystemName] as Partial<GridAgColumn>);
  }

  private static setWrapOptions(gridColumn: GridAgColumn, shouldWrapCellContainer?: boolean): void {
    if (shouldWrapCellContainer && !gridColumn.cellFormatter) {
      gridColumn.cellFormatter = {
        type: GridCellFormatterType.Container,
        props: {
          valueFormatter: gridColumn.valueFormatter,
        },
      };
    }
  }
}
