import React, { useCallback } from 'react';
import './ActionListNavItem.scss';
import { Link } from 'react-router-dom';
import { ActionListNavItem as ActionListNavItemInterface } from './interfaces/ActionListNavItem';
import { OnActionListNavItemDeleteFn } from './types/OnActionListNavItemDeleteFn';
import Icon from '../../../icon/Icon';
import { ControlSize } from '../../../../enums/ControlSize';
import { ColorScheme } from '../../../../enums/ColorScheme';
import { IconName } from '../../../icon/IconName';
import { useClassName } from '../../../../hooks/useClassName';
import { ActionListNavItemBlock, ActionListNavItemElement, ActionListNavItemModifier } from './actionListNavItemBem';

interface Props {
  item: ActionListNavItemInterface;
  orderNumber?: number;
  isTitleNoWrap?: boolean;
  onDelete?: OnActionListNavItemDeleteFn;
}

const ActionListNavItem: React.FC<Props> = props => {
  const cn = useClassName(ActionListNavItemBlock.Root);

  const onItemDelete = useCallback(() => {
    props.onDelete?.(props.item.id);
  }, [props.item, props.onDelete]);

  return (
    <div className={cn()}>
      <div
        className={cn(ActionListNavItemElement.TitleContainer, {
          [ActionListNavItemModifier.TitleNoWrap]: props.isTitleNoWrap,
        })}
      >
        {props.orderNumber && <span className={cn(ActionListNavItemElement.Order)}>{props.orderNumber}. </span>}

        <Link className={cn(ActionListNavItemElement.Title)} to={props.item.path}>
          {props.item.text}
        </Link>
      </div>

      {props.onDelete && (
        <div className={cn(ActionListNavItemElement.Delete)}>
          <Icon
            name={IconName.Close}
            size={ControlSize.ExtraSmall}
            colorScheme={ColorScheme.Primary}
            hasHover
            onClick={onItemDelete}
          />
        </div>
      )}
    </div>
  );
};

export default ActionListNavItem;
