import React, { useCallback, useContext } from 'react';
import './MasterCalendarLegend.scss';
import { MasterCalendarContext } from '../MasterCalendarContext';
import { CalendarEventType } from '../../shared/entities/calendar/enums/CalendarEventType';
import xor from 'lodash/xor';
import { useClassName } from '../../shared/hooks/useClassName';
import {
  MasterCalendarLegendBlock,
  MasterCalendarLegendElement,
  MasterCalendarLegendModifiers,
} from './MasterCalendarLegendBem';
import MasterCalendarLegendItemSymbol from './MasterCalendarLegendItemSymbol';
import { ControlSize } from '../../shared/enums/ControlSize';
import { ColorScheme } from '../../shared/enums/ColorScheme';

interface Props {
  size?: ControlSize;
  isShortLegend?: boolean;
}

const MasterCalendarLegend: React.FC<Props> = props => {
  const cn = useClassName(MasterCalendarLegendBlock.Root);

  const { groups, setActiveEventTypes, activeEventTypes } = useContext(MasterCalendarContext);

  const onGroupClick = useCallback((type: CalendarEventType) => setActiveEventTypes(xor(activeEventTypes, [type])), [
    activeEventTypes,
    setActiveEventTypes,
  ]);

  return (
    <ul
      className={cn({
        [`${props.size}`]: props.size,
      })}
    >
      {groups.map((group, index) => (
        <li
          key={index}
          className={cn(MasterCalendarLegendElement.Item, {
            [MasterCalendarLegendModifiers.Disabled]: !activeEventTypes.includes(group.type),
            [group.type]: group.type,
            [`${props.size}`]: props.size,
          })}
        >
          <button
            className={cn(MasterCalendarLegendElement.Button, {
              [ColorScheme.Primary]: true,
            })}
            title={props.isShortLegend ? group.title : undefined}
            onClick={(): void => onGroupClick(group.type)}
          >
            <MasterCalendarLegendItemSymbol type={group.type} />
            <span className={cn(MasterCalendarLegendElement.Title)}>
              {props.isShortLegend ? group.titleShort : group.title}
            </span>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default MasterCalendarLegend;
