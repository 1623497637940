import { FilterControlSettings } from '../../../types/FilterControlSettings';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { ControlSize } from '../../../../../enums/ControlSize';
import { getStatusAvailabilityOptions } from '../../options/statusAvailabilityOptions';
import { CheckboxType } from '../../../../../components/forms/Checkbox';
import { dealStatusInOptions } from '../../options/dealStatusInOptions';

export const dealStatusControls: FilterControlSettings = {
  [ViewFilterName.DealStatusSelect]: {
    type: FormControlType.Select,
    name: ViewFilterName.DealStatusSelect,
    props: {
      name: ViewFilterName.DealStatusSelect,
      label: 'Deal Status',
      placeholder: 'Choose Deal Status',
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isMultiSelect: false,
    },
  },
  [ViewFilterName.DealStatusDealStatusList]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName.DealStatusDealStatusList,
    props: {
      name: ViewFilterName.DealStatusDealStatusList,
      label: 'Deal Status',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._DealStatusScheduledList]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName._DealStatusScheduledList,
    props: {
      name: ViewFilterName._DealStatusScheduledList,
      label: 'Scheduled',
      options: getStatusAvailabilityOptions('Scheduled'),
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._DealStatusFiledTermsList]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName._DealStatusFiledTermsList,
    props: {
      name: ViewFilterName._DealStatusFiledTermsList,
      label: 'Filed Terms',
      options: getStatusAvailabilityOptions('Filed Terms'),
      size: ControlSize.Small,
    },
  },
  [ViewFilterName._DealStatusPostponedList]: {
    type: FormControlType.TextSelect,
    name: ViewFilterName._DealStatusPostponedList,
    props: {
      name: ViewFilterName._DealStatusPostponedList,
      label: 'Postponed',
      options: getStatusAvailabilityOptions('Postponed'),
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.DealStatusIn]: {
    type: FormControlType.CheckboxList,
    name: ViewFilterName.DealStatusIn,
    props: {
      name: ViewFilterName.DealStatusIn,
      type: CheckboxType.Checkbox,
      options: dealStatusInOptions,
    },
  },

  [ViewFilterName.WithdrawnPostponed]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.DealStatusWithdrawnAcquiredPostponed,
    props: {
      name: ViewFilterName.DealStatusWithdrawnAcquiredPostponed,
      //label: 'Withdrawn/Acquired/Postponed IPOs Only', TODO: Change back label to this text
      label: 'Withdrawn',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.DealStatusWithdrawnAcquiredPostponed]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.DealStatusWithdrawnAcquiredPostponed,
    props: {
      name: ViewFilterName.DealStatusWithdrawnAcquiredPostponed,
      label: 'Withdrawn/Acquired/Postponed IPOs Only',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.DealStatusAllActiveCompanies]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.DealStatusAllActiveCompanies,
    props: {
      name: ViewFilterName.DealStatusAllActiveCompanies,
      label: 'All Active Companies',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.DealStatusConfidentialFilers]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.DealStatusConfidentialFilers,
    props: {
      name: ViewFilterName.DealStatusConfidentialFilers,
      label: 'Confidential Filers',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.DealStatusSelectedBanks]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.DealStatusSelectedBanks,
    props: {
      name: ViewFilterName.DealStatusSelectedBanks,
      label: 'Selected Banks',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.DealStatusAcquiredOffPWC]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.DealStatusAcquiredOffPWC,
    props: {
      name: ViewFilterName.DealStatusAcquiredOffPWC,
      label: 'Acquired off PWC',
      size: ControlSize.Small,
    },
  },
};
