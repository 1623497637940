import { AnyObject } from '../../../types/generics';

export class FormValuesService {
  static getValuesFromControlsNames(formValues: AnyObject = {}, controlNames: string[]): AnyObject {
    const newFormValues: AnyObject = {};

    controlNames.forEach(controlName => (newFormValues[controlName] = formValues[controlName]));

    return newFormValues;
  }
}
