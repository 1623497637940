export enum ApiPath {
  ProfileAll = 'Profile/All',
  ProfileChart = 'Profile/Chart',
  ProfileManagementOwners = 'Profile/ManagementOwners',
  ProfileManagementQuestions = 'Profile/ManagementQuestions',
  ProfileFinancial = 'Profile/Financials',
  ProfileFundamentalComparison = 'Profile/FundamentalComparison',
  ProfileFundamentalInsights = 'Profile/FundamentalInsights',
  ProfileInvestmentRationale = 'Profile/InvestmentRationale',
  ProfileRelativeValuation = 'Profile/RelativeValuation',
  ProfileTechnicalComparison = 'Profile/TechnicalComparison',
  ProfileValuationComparison = 'Profile/ValuationComparison',
  ProfileMilestones = 'Profile/Milestones',
  AccountSettings = 'Account/AccountSettings',
  PasswordRecovery = 'Account/recoverpassword',
  ResetPassword = 'Account/ResetPassword',
  PasswordChanging = 'Account/changepassword',
  Trialrequest = 'Account/trial',
  TalkToAnalyst = 'Account/TalkToAnAnalyst',
  Contact = 'Account/contactus',
  Login = 'Account/login',
  RefreshToken = 'Account/TokenRefresh',
  EmailPreferences = 'Account/EmailPreferences',
  ChartAnnualRatingsPerf = 'ChartAnnualRatingsPerf',
  Priced = 'Priced',
  SpecialReports = 'SpecialReports',
  TeamNotes = 'TeamNotes',
  TeamNotesUnreadCount = 'TeamNotes/UnreadCount',
  Profile = 'Profile',
  NewsLanding = 'News/NewsLanding',
  NewsArticle = 'News',
  NewsFilter = 'News/Filter',
  NewsConfig = 'News/Config',
  NewsRecapScheduled = 'News/NewsMRecapScheduledTonight',
  NewsRecapRecapPcwAdditions = 'News/NewsMRecapPCW',
  NewsRecapResearch = 'News/NewsMRecapResearch',
  NewsMostViewed = 'News/MostViewedNews',
  NewsRecapHeadlines = 'News/NewsMRecapHeadlines',
  NewsRecapDate = 'News/RecapDate',
  Favorites = '/Favorites',
  View = 'DataView',
  Dashboard = 'Dashboard',
  CompanyTooltip = 'Company/Tooltip',
  PcmlTooltip = 'Pcml/Tooltip',
  DashboardNews = 'Dashboard/News',
  DashboardPriced = 'Dashboard/Priced',
  DashboardUpcomingIPOs = 'Dashboard/Scheduled',
  DashboardRecentResearch = 'Dashboard/RecentResearch',
  DashboardMostViewedIPOs = 'Dashboard/MostViewedIpos',
  DashboardNewFilings = 'Dashboard/NewFilings',
  DashboardIPOsOnMove = 'Dashboard/IpoOnTheMove',
  DashboardMyFavourites = 'Dashboard/Favorites',
  DashboardSavedViews = 'Dashboard/SavedViews',
  DashboardSavedScreens = 'Dashboard/SavedScreens',
  DashboardSpecialReports = 'Dashboard/SpecialReports',
  DashboardUSIPOIndex = 'Dashboard/UsIpoIndexChart',
  DashboardUSIPOMarketIndex = 'Dashboard/UsIpoMarketChart',
  Stats = 'Stats',
  StatsFiltersInfo = 'Stats/FiltersInfo',
  StatsChart = 'Stats/Chart',
  StatsExcel = 'Stats/Excel',
  CalendarMaster = 'Calendar/Master',
  CalendarScheduled = 'Calendar/Scheduled',
  ResearchDashboard = 'Research/Dashboard',
  Search = 'Search',
  Underwriters = 'Company/UnderwritersTooltip',
  VideosByType = 'Video/VideosByType',
  VideoById = 'Video/VideoById',
  AccessLog = '/GA4/AccessLog',
  RunReport = '/GA4/RunReport',
  MostViewedPages = 'GA4/MostViewedPages',
  MostActiveUsersOnThePage = 'GA4/MostActiveUsersOnThePage',
  MostActiveAccounts = 'GA4/MostActive',
  UsersActivityPerAccount = 'GA4/UsersActivityPerAccount',
  MostActiveLanding = 'GA4/MostActiveLanding',
  UserActivityInfo = 'GA4/UserActivityInfo',
  ResourceActivityInfo = 'GA4/ResourceActivityInfo',
  MostViewedIntlIpos = 'Dashboard/MostViewedIntlIpos',
}
