import React, { useMemo } from 'react';
import './NotificationToastHeader.scss';
import { ColorScheme } from '../../enums/ColorScheme';
import ToastHeader from '../toast/ToastHeader';
import { useClassName } from '../../hooks/useClassName';
import { NotificationService } from './NotificationService';

interface Props {
  type: ColorScheme;
}

const NotificationToastHeader: React.FC<Props> = props => {
  const cn = useClassName('NotificationToastHeader');
  const title = useMemo(() => NotificationService.getTitle(props.type), [props.type]);

  return (
    <ToastHeader
      title={title}
      className={cn({
        [props.type]: props.type,
      })}
    />
  );
};

export default NotificationToastHeader;
