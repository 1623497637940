import ApiService from './ApiService';
import { TalkToAnalystData } from '../../talk-to-analyst/form/TalkToAnalystData';

import { TrialRequestFormData } from '../../marketing/request/form/trial-request-form-data';

import { ContactFormData } from '../../marketing/contact/form/ContactFormData';
import { ApiPath } from './enums/ApiPath';

export default class ApiContact {
  static requestTalkToAnalyst(talkToAnalystData: TalkToAnalystData): Promise<void> {
    return ApiService.post(ApiPath.TalkToAnalyst, talkToAnalystData);
  }

  static requestTrialRequest(trialRequestFormData: TrialRequestFormData): Promise<void> {
    return ApiService.post(ApiPath.Trialrequest, trialRequestFormData);
  }

  static requestContactUs(contactUsFormData: ContactFormData): Promise<void> {
    return ApiService.post(ApiPath.Contact, contactUsFormData);
  }
}
