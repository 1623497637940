import React from 'react';
import FilterControl from '../FilterControl';
import { ViewFilterName } from '../../../../enum/types/ViewFilterName';
import DealStatusInControl from '../controls/deal-status/DealStatusInControl';
import DealStatusStatusAvailabilityControls from '../controls/deal-status/DealStatusStatusAvailabilityControls';

const DealStatusFilterSection: React.FC = () => {
  return (
    <>
      <FilterControl name={ViewFilterName.DealStatusDealStatusList} />

      <DealStatusStatusAvailabilityControls />

      <DealStatusInControl />

      <FilterControl name={ViewFilterName.DealStatusWithdrawnAcquiredPostponed} />

      <FilterControl name={ViewFilterName.DealStatusAllActiveCompanies} />

      <FilterControl name={ViewFilterName.DealStatusConfidentialFilers} />

      <FilterControl name={ViewFilterName.DealStatusSelectedBanks} />

      <FilterControl name={ViewFilterName.DealStatusAcquiredOffPWC} />
    </>
  );
};

export default DealStatusFilterSection;
