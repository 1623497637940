import React from 'react';
import { useClassName } from '../../../../../../hooks/useClassName';
import { WeakStrongCellBlock } from './weakStrongCellBem';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import WeekStrongFormatter from '../../../../../../components/formatters/WeekStrongFormatter';

type Props = GridCellFormatterCommonProps;

const WeakStrongCell: React.FC<Props> = props => {
  const cn = useClassName(WeakStrongCellBlock.Root);

  return (
    <CustomCellFormatterWrapper value={props.value} className={cn()}>
      <WeekStrongFormatter type={props.value} />
    </CustomCellFormatterWrapper>
  );
};

export default WeakStrongCell;
