import { NewsType } from '../../enums/NewsType';

export class NewsTypeService {
  static isRC(type: NewsType): boolean {
    return type !== NewsType.External;
  }

  static isExternal(type: NewsType): boolean {
    return type === NewsType.External;
  }
}
