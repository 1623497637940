import React, { useMemo } from 'react';
import './ResearchCell.scss';
import FileActions from '../../../../../../components/file-actions/FileActions';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { FileActionType } from '../../../../../../components/file-actions/enums/FileActionType';
import { ResearchColumnValue } from '../../interfaces/column-values/ResearchColumnValue';
import { ResearchTypeColumnValue } from '../../interfaces/column-values/ResearchTypeColumnValue';
import { FileActionProp } from '../../../../../../components/file-actions/interfaces/FileActionProp';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import CompanyRating from '../../../../../../../company/components/company-rating/CompanyRating';
import { useClassName } from '../../../../../../hooks/useClassName';
import { ResearchCellBlock } from './researchCellBem';

type Props = GridCellFormatterCommonProps;

const ResearchCell: React.FC<Props> = props => {
  const cn = useClassName(ResearchCellBlock.Root);
  const researchColumn: ResearchColumnValue = props.value;
  const researchTypeColumn: ResearchTypeColumnValue = props.value;
  const fileActions = useMemo<FileActionProp[]>(() => {
    const actions: FileActionProp[] = [];

    if (researchColumn && researchColumn.reportLink) {
      if (researchColumn.reportLinkIcon === 'q' && researchTypeColumn.link) {
        actions.push({
          type: FileActionType.MGMTQ,
          title: <CompanyRating ratingValue={researchColumn.rating} />,
          externalDownloadOptions: {
            url: researchTypeColumn.link!,
          },
          tooltipTitle: 'Mgmt. Questions',
        });
      } else if (researchColumn.reportLinkIcon === 'q') {
        actions.push({
          type: FileActionType.MGMTQ,
          title: <CompanyRating ratingValue={researchColumn.rating} />,
          externalDownloadOptions: {
            url: researchColumn.reportLink,
          },
          tooltipTitle: 'Mgmt. Questions',
        });
      } else {
        actions.push({
          type: FileActionType.Pdf,
          title: <CompanyRating ratingValue={researchColumn.rating} />,
          externalDownloadOptions: {
            url: researchColumn.reportLink,
          },
          tooltipTitle: 'Pdf Report',
        });
      }
    }

    if (researchColumn && researchColumn.dcfLink) {
      actions.push({
        type: FileActionType.Excel,
        externalDownloadOptions: { url: researchColumn.dcfLink },
        tooltipTitle: 'Excel DCF',
      });
    }

    if (researchColumn && researchColumn.insightsLink) {
      // Because we are using the same file action for linking to both fundamental insights and profile, manually change the link here so no backend changes are needed
      // TODO: Change the data.ipointelligence.com from being appended to end of the researchColumn url
      let colTitle = researchColumn.rating;
      if (researchColumn.rating == 'Fund') {
        colTitle = 'Fundamental';
      } else if (researchColumn.rating == 'Fin') {
        colTitle = 'Financial';
      } else if (researchColumn.rating == 'Gov') {
        colTitle = 'Governance';
      }

      actions.push({
        type: FileActionType.Insight,
        title: colTitle,
        routePath: '/company' + researchColumn.insightsLink.split('company')[1],
        tooltipTitle: 'Enhanced Profile',
      });
    }

    return actions;
  }, [researchColumn, researchTypeColumn]);

  return (
    <CustomCellFormatterWrapper className={cn()} value={props.value}>
      <div className={'research-files'}>
        {/* props.value?.notDoingResearch ? <span className={'research-text'}> N/R </span> : null */}
        <FileActions size={ControlSize.Small} fileActions={fileActions} />
      </div>
    </CustomCellFormatterWrapper>
  );
};

export default ResearchCell;
