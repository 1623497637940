import { isFunction } from 'lodash';
import { ViewReqParamsBase } from '../../../../../view/interfaces/api/ViewReqParamsBase';
import { ViewType } from '../../../../../view/enums/ViewType';
import { ViewTriggerCellOptions, ViewTriggerCellOptionsCustom } from '../interface/ViewTriggerCellOptions';
import { ViewAppliedFilters } from '../../../../../view/interfaces/ViewAppliedFilters';
import { ViewGridCellValueService } from '../../../../services/ViewGridCellValueService';
import { GridRow } from '../../../../../../../../components/grid/shared/types/GridRow';
import { GridCellValue } from '../../../../../../../../components/grid/shared/types/GridCellValue';
import { ViewColumnSystemName } from '../../../../enums/ViewColumnSystemName';
import { SelectService } from '../../../../../../../../services/select/SelectService';
import { ViewTriggerCellGetFilterFn } from '../types/ViewTriggerCellGetFilterFn';
import { ViewSelectedColumnsDto } from '../../../../../view/interfaces/ViewSelectedColumnsDto';

export class ViewTriggerCellDataService {
  static getViewData(
    row: GridRow,
    value: GridCellValue,
    columnName: ViewColumnSystemName,
    viewType: ViewType,
    optionsList: ViewTriggerCellOptions[],
    getIsLinkAvailable?: ViewTriggerCellGetFilterFn,
    columns?: ViewSelectedColumnsDto,
  ): ViewReqParamsBase | undefined {
    if (!this.getIsAvailable(row, value, columnName, getIsLinkAvailable)) {
      return;
    }

    return {
      sort: null,
      filters: this.getFiltersFromOptionsList(row, value, columnName, optionsList),
      viewType,
      ...(columns && { columns }),
    };
  }

  static getIsAvailable(
    row: GridRow,
    value: GridCellValue,
    columnName: ViewColumnSystemName,
    getIsLinkAvailable?: ViewTriggerCellGetFilterFn,
  ): boolean {
    return getIsLinkAvailable ? getIsLinkAvailable(row, value, columnName) : true;
  }

  private static getFiltersFromOptionsList(
    row: GridRow,
    value: GridCellValue,
    columnName: ViewColumnSystemName,
    optionsList: ViewTriggerCellOptions[],
  ): ViewAppliedFilters {
    return optionsList.reduce(
      (currentFilters, options) => ({
        ...currentFilters,
        ...this.getFilterFromOptions(row, value, columnName, options),
      }),
      {},
    );
  }

  private static getFilterFromOptions(
    row: GridRow,
    value: GridCellValue,
    columnName: ViewColumnSystemName,
    options: ViewTriggerCellOptions,
  ): ViewAppliedFilters {
    if (this.isCustomOptions(options)) {
      return options.getCustom(row, value, columnName);
    }

    const filterName = isFunction(options.getFilterName)
      ? options.getFilterName(row, value, columnName)
      : options.getFilterName;

    let filterValue = isFunction(options.getFilterValue)
      ? options.getFilterValue(row, value, columnName)
      : ViewGridCellValueService.getRaw(row, options.getFilterValue);

    if (options.isMultiValue) {
      filterValue = SelectService.getMultiSelectValue(filterValue);
    }

    return {
      [filterName]: filterValue,
    };
  }

  private static isCustomOptions(options: ViewTriggerCellOptions): options is ViewTriggerCellOptionsCustom {
    return !!(options as ViewTriggerCellOptionsCustom).getCustom;
  }
}
