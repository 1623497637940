import React, { useCallback } from 'react';
import './FilterSectionsToolbar.scss';
import { FilterSectionsFormToolbarBlock } from './filterSectionsToolbarBem';
import { useClassName } from '../../../../../../hooks/useClassName';
import FilterSectionToolbarAction from './FilterSectionToolbarAction';
import FilterSectionToolbarExpandCollapseAction from './FilterSectionToolbarExpandCollapseAction';
import ButtonsList from '../../../../../../components/forms/ButtonsList';
import { AlignTypesHorizontal } from '../../../../../../enums/AlignTypesHorizontal';
import { useFormContext } from 'react-hook-form';

interface Props {
  className?: string;
}

const FilterSectionsToolbar: React.FC<Props> = props => {
  const cn = useClassName(FilterSectionsFormToolbarBlock.Root, props.className);

  const { reset } = useFormContext();

  const onClearAllClick = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <ButtonsList className={cn()} alignTypeHorizontal={AlignTypesHorizontal.Start}>
      <FilterSectionToolbarExpandCollapseAction />

      <FilterSectionToolbarAction onClick={onClearAllClick}>Clear All</FilterSectionToolbarAction>
    </ButtonsList>
  );
};

export default FilterSectionsToolbar;
