import React, { useCallback, useContext } from 'react';
import FilterSectionToolbarAction from './FilterSectionToolbarAction';
import { FilterSectionsFormContext } from '../../context/FilterSectionsFormContext';

const FilterSectionToolbarExpandCollapseAction: React.FC = () => {
  const { sectionsTypes, onCloseChange } = useContext(FilterSectionsFormContext);

  const onExpandClick = useCallback(() => onCloseChange(sectionsTypes, false), [sectionsTypes, onCloseChange]);
  const onCollapseClick = useCallback(() => onCloseChange(sectionsTypes, true), [sectionsTypes, onCloseChange]);

  return (
    <>
      <FilterSectionToolbarAction onClick={onExpandClick}>Expand All</FilterSectionToolbarAction>

      <FilterSectionToolbarAction onClick={onCollapseClick}>Collapse All</FilterSectionToolbarAction>
    </>
  );
};

export default FilterSectionToolbarExpandCollapseAction;
