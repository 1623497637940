import React, { useContext, useMemo } from 'react';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import CompanyProfileSidebarTable from '../company-profile-table/CompanyProfileSidebarTable';
import { DealStatus } from '../../../../../../shared/entities/filters/enum/DealStatus';
import { GridRow } from '../../../../../../shared/components/grid/shared/types/GridRow';

const CompanyCapitalization: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const data = useMemo(() => profile.capitalization, [profile]);
  const dealStatus = useMemo(() => profile?.description?.dealStatus, [profile]);
  const title = useMemo(() => {
    const postfix = dealStatus === DealStatus.Priced ? ' (at IPO)' : '';

    return `Capitalization${postfix}`;
  }, [dealStatus]);

  const filterRows = (rows: GridRow[]) => {
    return rows.filter(row => {
      return !Object.values(row).includes(null);
    });
  };

  return (
    <>
      {data && (
        <CompanyProfileSideItem title={title}>
          <CompanyProfileSidebarTable
            {...data}
            expandedData={filterRows(data.rows)}
            isHeaderHidden
            hasAroundBorder={false}
          />
        </CompanyProfileSideItem>
      )}
    </>
  );
};

export default CompanyCapitalization;
