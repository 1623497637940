import React from 'react';
import NewsItem from './NewsItem';
import './NewsList.scss';
import { NewsType } from '../enums/NewsType';
import { NewsListItem } from './interfaces/NewsListItem';
import { useClassName } from '../../shared/hooks/useClassName';
import { ComponentWithExpandDataListProps } from '../../shared/components/expander/interfaces/ComponentWithExpandDataListProps';
import { DateFormat } from '../../shared/helpers/date/enums/DateFormat';

interface Props extends ComponentWithExpandDataListProps {
  expandedData: NewsListItem[];
  hasEmoji?: boolean;
  hasDateTag?: boolean;
  dateTagFormat?: DateFormat;
  hasProfileLayout?: boolean;
}

const NewsList: React.FC<Props> = props => {
  const cn = useClassName('NewsList', props.className);

  return (
    <ul className={cn()}>
      {props.expandedData.map((item: NewsListItem, index: number) => {
        const isHighlighted =
          item.type === NewsType.AnalystNote || item.type === NewsType.Blog || props.hasProfileLayout;

        return (
          <li className={cn('item', { highlighted: isHighlighted })} key={index}>
            <NewsItem
              data={item}
              hasDateTag={props.hasDateTag}
              hasProfileLayout={props.hasProfileLayout}
              dateTagFormat={props.dateTagFormat}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default NewsList;
