import React from 'react';
import './ViewApiFootnote.scss';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../../../../../enums/RoutePath';

const ViewApiFootnote: React.FC = () => {
  return (
    <>
      <br />
      <div className="ViewApiFootnote">
        Want this data in a real-time data feed?{' '}
        <Link to={RoutePath.ContactUs}>Contact us about our API offerings.</Link>
      </div>
    </>
  );
};

export default ViewApiFootnote;
