import { useEffect, useState, useMemo } from 'react';
import { CustomAny } from '../../../shared/types/generics';
import { ObjectHelper } from '../../../shared/helpers/ObjectHelper';

export function useLoadDataEffect(data: CustomAny, loadDataFn: () => void, deps?: CustomAny[]): void {
  const [isLoaded, setIsLoaded] = useState(false);

  const isLoadingAlowed = useMemo(() => deps || ObjectHelper.arePropsEmpty(data), [data, deps]);
  const dependencyArray = useMemo(() => deps || [], [deps]);

  useEffect(() => {
    setIsLoaded(false);
    // eslint-disable-next-line
  }, [...dependencyArray]);

  useEffect(() => {
    if (isLoadingAlowed && !isLoaded) {
      loadDataFn();

      setIsLoaded(true);
    }
    // eslint-disable-next-line
  }, [data, loadDataFn, isLoaded, ...dependencyArray, isLoadingAlowed]);
}
