import React, { useContext, useMemo } from 'react';
import { CompanyContext } from '../context/CompanyContext';
import './CompanyRelativeValuationTables.scss';
import CompanyMapTables from '../shared/CompanyMapTables';
import { Nullable } from '../../../shared/types/generics';
import { CompanyTab } from '../../enums/CompanyTab';
import { SimpleGridProps } from '../../../shared/components/grid/simple-grid/SimpleGrid';
import PageLoader from '../../../shared/components/loader/PageLoader';
import { ProfileData } from '../../interfaces/ProfileData';

const CompanyRelativeValuationTables: React.FC = () => {
  const { getTabData, valuationComparisonData, fundamentalComparisonData } = useContext(CompanyContext);

  // const data: Nullable<CompanyRelativeValuationDto> = getTabData(CompanyTab.RelativeValuation);

  const profile: ProfileData = getTabData(CompanyTab.Profile);
  const data = useMemo(() => profile.description, [profile]);
  if (data?.isPreliminaryComps && valuationComparisonData && fundamentalComparisonData) {
    fundamentalComparisonData.title = 'Fundamental Comparison (Preliminary)';
    valuationComparisonData.title = 'Valuation Comparison (Preliminary)';
  }

  const grids: Nullable<SimpleGridProps>[] = [fundamentalComparisonData, valuationComparisonData];

  const tables: (SimpleGridProps | null)[] = useMemo(
    () =>
      grids.map(grid => {
        if (grid) {
          return {
            ...grid,
            isFullWidth: true,
          };
        } else {
          return null;
        }
      }),
    [grids],
  );

  return (
    <div className="CompanyRelativeValuationTables">
      <PageLoader loading={!!!valuationComparisonData || !!!fundamentalComparisonData}>
        {tables && <CompanyMapTables isEqualSizes={true} tables={tables} />}
      </PageLoader>
    </div>
  );
};

export default CompanyRelativeValuationTables;
