import { useEffect, useRef } from 'react';
import isEqual from 'lodash.isequal';
import { CustomAny } from '../types/generics';

export const useDeepEffect = (fn: Function, deps: CustomAny): void => {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isSame = prevDeps.current.every((obj: CustomAny, index: number) => isEqual(obj, deps[index]));

    if (isFirst.current || !isSame) {
      fn();
    }

    isFirst.current = false;
    prevDeps.current = deps;
    // eslint-disable-next-line
  }, deps);
};
