import { useState, useMemo } from 'react';
import { RoutePath } from '../enums/RoutePath';
import LocationService from '../services/LocationService';
import { useHistoryChange } from '../effects/useHistoryChange';

export function useCurrentRoutePath(): RoutePath {
  const [currentRoutePath, setCurrentRoutePath] = useState(LocationService.currentPath);

  useHistoryChange(() => {
    setCurrentRoutePath(LocationService.currentPath);
  });

  return useMemo(() => currentRoutePath, [currentRoutePath]);
}
