import { StatsElementOptions } from '../../../../../shared/entities/stats-element/interfaces/StatsElementOptions';
import { ChartType } from '../../../../../shared/components/chart/enums/ChartType';
import { getStatsElementSimpleGridColumnYearToViewMapper } from '../../../../../shared/entities/stats-element/components/simple-grid/constants/statsElementSimpleGridColumnMappers';
import { ProfitableVsUnprofitableLinkFiltersFactory } from '../factories/ProfitableVsUnprofitableLinkFiltersFactory';
import { BlockOrientation } from '../../../../../shared/enums/BlockOrientation';

const PROFITABLE_UNPROFITABLE_IPO_GRID_INDEX = 0;

export const profitabilityStatsElementOptions: StatsElementOptions = {
  pageOptions: {
    internalFilter: {
      label: 'Based on LTMs',
    },
    chartsOrientation: BlockOrientation.Line,
  },
  chartsOptions: [
    {
      type: ChartType.VerticalColumn,
      title: 'IPO Profitability by Year',
      optionsList: [
        {
          yAxis: {
            title: {
              text: 'Total IPOs',
            },
          },
        },
      ],
    },
    {
      type: ChartType.VerticalPercentageColumn,
      title: 'Percent Profitable',
      optionsList: [
        {
          yAxis: {
            title: {
              text: 'Percentage of Total IPOs',
            },
          },
        },
      ],
    },
  ],
  gridOptions: {
    [PROFITABLE_UNPROFITABLE_IPO_GRID_INDEX]: {
      isHighlightLastRow: true,
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(context => {
        return ProfitableVsUnprofitableLinkFiltersFactory.getProps(context.dataType);
      }),
    },
  },
};
