import React from 'react';
import { StatsGlobalFilter } from '../../types/StatsGlobalFilter';
import StatsPageFiltersFormHeader from './StatsPageFiltersFormHeader';

export interface StatsPageFiltersControlsProps {
  className?: string;
  filtersNames?: StatsGlobalFilter[];
}

const StatsPageFiltersControls: React.FC<StatsPageFiltersControlsProps> = props => {
  return <StatsPageFiltersFormHeader filtersNames={props.filtersNames} />;
};

export default StatsPageFiltersControls;
