import React from 'react';
import './ViewGridToolbarGroupFilterQuick.scss';
import ViewGridToolbarGroup from '../../view-grid/components/parts/ViewGridToolbarGroup';
import ViewGridFilterQuickForm, { ViewGridFilterQuickFormProps } from './ViewGridFilterQuickForm';
import { useClassName } from '../../../../../hooks/useClassName';
import { ViewGridToolbarGroupFilterQuickBlock } from './viewGridToolbarGroupFilterQuickBem';
import classNames from 'classnames';

type Props = ViewGridFilterQuickFormProps;

const ViewGridToolbarGroupFilterQuick: React.FC<Props> = props => {
  const cn = useClassName(ViewGridToolbarGroupFilterQuickBlock.Root, props.className);

  return (
    <ViewGridToolbarGroup className={classNames(cn(), { 'lg-down-margin': props.hasPostponed })}>
      <ViewGridFilterQuickForm {...props} />
    </ViewGridToolbarGroup>
  );
};

export default ViewGridToolbarGroupFilterQuick;
