import React from 'react';
import './DashboardSavedViewsWidget.scss';
import { ViewSaved } from '../../../../shared/entities/view/components/view/interfaces/api/ViewSaved';
import { SavedViewsType } from '../../../../shared/entities/view/components/view/components/view-saved/enums/SavedViewsType';
import SavedViewsSection from '../../../../shared/entities/view/components/view/components/view-saved/SavedViewsSection';
import { useClassName } from '../../../../shared/hooks/useClassName';
import { DashboardSavedViewsWidgetBlock } from './dashboardSavedViewsWidgetBem';

interface Props {
  type: SavedViewsType;
  savedViews: ViewSaved[];
}

const DashboardSavedViewsWidget: React.FC<Props> = props => {
  const cn = useClassName(DashboardSavedViewsWidgetBlock.Root);

  return (
    <SavedViewsSection
      className={cn()}
      type={props.type}
      items={props.savedViews}
      hasNoHeader
      hasDelete={false}
      isNoDataLink
      isFullHeight
      isGrid
      hasOrderItem
    />
  );
};

export default DashboardSavedViewsWidget;
