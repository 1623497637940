import axios from 'axios';
import { API_URL } from '../constants/environment';
import { tokenInterceptor } from '../interceptors/tokenInterceptor';
import { setAuthRefreshInterceptor } from '../interceptors/authRefreshInterceptor';

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
setAuthRefreshInterceptor(axiosInstance);
axiosInstance.interceptors.request.use(tokenInterceptor);

export default axiosInstance;
