import React from 'react';
import { Url } from '../../shared/types';
import { Link } from 'react-router-dom';
import Icon from '../../shared/components/icon/Icon';
import { IconName } from '../../shared/components/icon/IconName';
import './NewsTitle.scss';

interface Props {
  url?: Url;
}

const NewsTitle: React.FC<Props> = props => {
  return (
    <div className="NewsTitle">
      <div className="NewsTitle__title">{props.children}</div>

      {props.url && (
        <Link className="NewsTitle__link" to={props.url}>
          <Icon name={IconName.RightArrow} />
        </Link>
      )}
    </div>
  );
};

export default NewsTitle;
