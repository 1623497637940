import sub from 'date-fns/sub';
import {
  endOfWeek,
  startOfWeek,
  addWeeks,
  isAfter,
  startOfMonth,
  endOfMonth,
  startOfYear,
  getTime,
  endOfYear,
  getYear,
  isValid,
  parseISO,
  parse,
  format,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { CustomAny, Nullable } from '../../types/generics';
import { DateFormat } from './enums/DateFormat';
import { DateSelectValue } from '../../components/forms/components/date/types/DateSelectValue';
import { WeekDay } from '../../enums/WeekDay';
import { IsoDate } from '../../types';

export class DateHelper {
  static formatISODateStr(isoDate: IsoDate | undefined, dateFormat: DateFormat = DateFormat.MonthDayYear): string {
    if (!isoDate) {
      return '';
    }

    return this.formatDate(this.parseISO(isoDate), dateFormat);
  }

  static formatNumberDate(numDate: number | undefined, dateFormat: DateFormat = DateFormat.MonthDayYear): string {
    if (!numDate) {
      return '';
    }

    return this.formatDate(this.getNewDate(numDate), dateFormat);
  }

  static formatDate(date?: Date, dateFormat: DateFormat = DateFormat.MonthDayYear): string {
    if (!date) {
      return '';
    }

    return format(date, dateFormat);
  }

  static sub(date: Date, duration: Duration): Date {
    return sub(date, duration);
  }

  static getStartOfYear(date: Date): Date {
    return startOfYear(date);
  }

  static getEndOfYear(date: Date): Date {
    return endOfYear(date);
  }

  static parseISODate(date: Nullable<Date | DateSelectValue>): Date | undefined {
    if (!date) {
      return;
    }

    return date instanceof Date ? date : this.parseISO(date);
  }

  static getTime(date: Date | DateSelectValue): number {
    return getTime(this.parseISODate(date) || 0);
  }

  static getStartOfWeek(date: Date, weekStartsOn = WeekDay.Monday): Date {
    return startOfWeek(date, { weekStartsOn });
  }

  static getEndOfWeek(date: Date, weekStartsOn = WeekDay.Monday): Date {
    return endOfWeek(date, { weekStartsOn });
  }

  static getStartOfMonth(date: Date): Date {
    return startOfMonth(date);
  }

  static getEndOfMonth(date: Date): Date {
    return endOfMonth(date);
  }

  static addWeeks(date: Date, weeks: number): Date {
    return addWeeks(date, weeks);
  }

  static getToday(): Date {
    return this.getNewDate();
  }

  static isAfter(dateA: Date | DateSelectValue, dateB: Date | DateSelectValue): boolean {
    return isAfter(this.getTime(dateA), this.getTime(dateB));
  }

  static getYear(date: Date): number {
    return getYear(date);
  }

  static getStartYearPassPeriod(currentYear: number, pastBackYears: number): number {
    // Need to (passBackYears - 1) to include current year
    return currentYear - (pastBackYears - 1);
  }

  static isValid(date: CustomAny): boolean {
    return isValid(date);
  }

  static isValidIso(value: CustomAny): boolean {
    return isValid(this.parseISO(value));
  }

  static getYearDate(year: number): Date {
    return this.parseISO(`${year}-01-01`);
  }

  static parseDateByFormat(dateStr: string, format: DateFormat): Date {
    return parse(dateStr, format, this.getToday());
  }

  static getLocalDateFromISODateStr(isoDate: IsoDate): Date {
    return zonedTimeToUtc(isoDate, 'local');
  }

  static getNewDate(date?: string | number): Date {
    if (!date) {
      return new Date();
    }

    return new Date(date);
  }

  // https://github.com/date-fns/date-fns/issues/489
  // have to parse all date by this
  private static parseISO(isoDate: IsoDate): Date {
    return parseISO(isoDate);
  }
}
