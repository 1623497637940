import React from 'react';
import { DashboardWidgetComponent } from '../../interfaces/DashboardWidgetComponent';
import DashboardTable from './shared/DashboardTable';
import { ViewDataTable } from '../../../shared/entities/view/components/view/interfaces/api/ViewDataTable';

const DashboardPricedWidget: DashboardWidgetComponent<ViewDataTable> = props => {
  return (
    <DashboardTable dataTable={props.data} width={props.dimensions?.width} widgetType={'Priced'} pinTickerColumn />
  );
};

export default DashboardPricedWidget;
