import { ViewColumnSystemName } from '../enums/ViewColumnSystemName';
import { GridCellFormatterType } from '../../../../../components/grid/shared/enums/GridCellFormatterType';
import { GridAgColumn } from '../../../../../components/grid/grid/interfaces/GridAgColumn';
import CompanyCell from '../components/cells/CompanyCell';
import { AlignTypesHorizontal } from '../../../../../enums/AlignTypesHorizontal';
import FavouriteHeaderFormatter from '../../../../../components/grid/shared/components/formatters/header/FavouriteHeaderFormatter';
import ResearchCell from '../components/cells/ResearchCell';
import TodaysPriceChangeCell from '../components/cells/TodaysPriceChangeCell';
import NewsLinkCell from '../components/cells/NewsLinkCell';
import CompanyWithDescriptionCell from '../components/cells/CompanyWithDescriptionCell';
import {
  companyNameComparator,
  companyTickerNameComparator,
  companyWithNotePopupComparator,
  favouriteComparator,
  researchComparator,
  researchRatingComparator,
  todaysPriceChangeComparator,
  EstTimingComparator,
  OfferMonthTimingComparator,
  DateIssuedComparator,
  latestTokenDateComparator,
  doubleComparator,
} from './viewGridComparators';
import { printCompanyFileAction } from './viewGridFileActions';
import PriceRangeCell from '../components/cells/PriceRangeCell';
import DealSizeCell from '../components/cells/DealSizeCell';
import { ViewGridColumnWidth } from '../enums/ViewGridColumnWidth';
import LastUpdateDaysCell from '../components/cells/LastUpdateDaysCell';
import EstSalesCell from '../components/cells/EstSalesCell';
import EstValuationCell from '../components/cells/EstValuationCell';
import FundingToDateCell from '../components/cells/FundingToDateCell';
import InvestorsCell from '../components/cells/InvestorsCell';
import ResearchRatingCell from '../components/cells/ResearchRatingCell';
import WeakStrongCell from '../components/cells/WeakStrongCell';
import PriceChangeCell from '../components/cells/PriceChangeCell';
import NewsHeadlineCell from '../components/cells/NewsHeadlineCell';
import ReportTitleCell from '../components/cells/ReportTitleCell';
import ResearchKeywordCell from '../components/cells/ResearchKeywordCell';
import ResearchTypeCell from '../components/cells/ResearchTypeCell';
import { ViewGridSortService } from '../services/ViewGridSortService';
import ResearchTypeWidgetCell from '../components/cells/ResearchTypeWidgetCell';
import SpecialReportsDateCell from '../components/cells/SpecialReportsDateCell';
import TickerCell from '../components/cells/TickerCell';
import { GridHeightMultiplier } from '../../../../../components/grid/grid/enums/GridHeightMultiplier';
import UnderwritersCell from '../components/cells/UnderwritersCell';
import ViewTriggerCell from '../components/cells/view-trigger-cell/ViewTriggerCell';
import { ViewType } from '../../view/enums/ViewType';
import { ViewFilterName } from '../../../../filters/enum/types/ViewFilterName';
import { ViewGridCellValueService } from '../services/ViewGridCellValueService';
import { GridRow } from '../../../../../components/grid/shared/types/GridRow';
import { GridCellValue } from '../../../../../components/grid/shared/types/GridCellValue';
import MVCell from '../components/cells/MVCell';
import EVCell from '../components/cells/EVCell';
import HtmlStringFormatterJustified from '../../../../../components/HtmlStringFormatterJustified';
import StubsCell from '../../../../../components/StubsCell';
import CompanyWithNotePopUpCell from '../components/cells/CompanyWithNotePopUpCell';
import VideoTitleCell from '../components/cells/VideoTitleCell';
import PagePathCell from '../../../../../../google-analytics/link-urls/PagePathCell';
import AccountIdUrlCell from '../../../../../../google-analytics/link-urls/AccountIdUrlCell';
import UserActivityUrlCell from '../../../../../../google-analytics/link-urls/UserActivityUrlCell';
import FileNameUrlCell from '../../../../../../google-analytics/link-urls/FileNameUrlCell';
import FileNameExcelUrlCell from '../../../../../../google-analytics/link-urls/FileNameExcelUrlCell';
import CapitalizationDataCell from '../components/cells/CapitalizationDataCell';
import PcmlArticlesCell from '../components/cells/PcmlArticlesCell';
import PcmlNoteCell from '../components/cells/PcmlNoteCell';
import CfoCell from '../components/cells/CfoCell';
import PcmlInvestorsCell from '../components/cells/PcmlInvestorsCell';
import PcmlLastFundingDateCell from '../components/cells/PcmlLastFundingDateCell';

export const viewGridColumnsSettings: {
  [key in ViewColumnSystemName]?: Partial<GridAgColumn>;
} = {
  [ViewColumnSystemName.CompanyName]: {
    comparator: companyNameComparator,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: CompanyCell,
      },
    },
    width: ViewGridColumnWidth.Lg,
    minWidth: ViewGridColumnWidth.Lg,
    flex: 1,
  },
  [ViewColumnSystemName.CompanyTicker]: {
    comparator: companyTickerNameComparator,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: CompanyCell,
      },
    },
    width: ViewGridColumnWidth.Lg,
    minWidth: ViewGridColumnWidth.Lg,
    flex: 1,
  },
  [ViewColumnSystemName.MV]: {
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xs,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: MVCell,
      },
    },
  },
  [ViewColumnSystemName.EV]: {
    alignHorizontal: AlignTypesHorizontal.End,
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xs,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: EVCell,
      },
    },
  },
  [ViewColumnSystemName.Ticker]: {
    //sortable: false,
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xs,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: TickerCell,
      },
    },
  },
  [ViewColumnSystemName.CompanyWithDescription]: {
    comparator: companyNameComparator,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: CompanyWithDescriptionCell,
      },
    },
    width: ViewGridColumnWidth.XXl,
    minWidth: ViewGridColumnWidth.XXl,
    flex: 1,
    heightMultiplier: GridHeightMultiplier.Sm,
  },
  [ViewColumnSystemName.CompanyWithNotePopUp]: {
    comparator: companyWithNotePopupComparator,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: CompanyWithNotePopUpCell,
      },
    },
    width: ViewGridColumnWidth.XXl,
    minWidth: ViewGridColumnWidth.XXl,
    flex: 1,
  },
  [ViewColumnSystemName.LockupExpDate]: {
    width: 85,
    minWidth: 85,
  },
  [ViewColumnSystemName.DaysToExp]: {
    width: 67,
    minWidth: 67,
  },
  [ViewColumnSystemName.LockupSharesMil]: {
    comparator: doubleComparator,
    alignHorizontal: AlignTypesHorizontal.End,
    width: 115,
    minWidth: 115,
  },
  [ViewColumnSystemName.PercentSharesOut]: {
    width: 80,
    minWidth: 80,
  },
  [ViewColumnSystemName.numSharesOffered]: {
    comparator: doubleComparator,
    alignHorizontal: AlignTypesHorizontal.End,
    width: 92,
    minWidth: 92,
  },
  [ViewColumnSystemName.LockupNumber]: {
    alignHorizontal: AlignTypesHorizontal.End,
    width: 80,
    minWidth: 80,
  },
  [ViewColumnSystemName.StockPrice]: {
    alignHorizontal: AlignTypesHorizontal.End,
    width: 65,
    minWidth: 65,
  },
  [ViewColumnSystemName.DealSize]: {
    alignHorizontal: AlignTypesHorizontal.End,
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xxs,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: DealSizeCell,
      },
    },
  },
  [ViewColumnSystemName.Favorite]: {
    alignHorizontal: AlignTypesHorizontal.Center,
    headerFormatter: {
      component: FavouriteHeaderFormatter,
    },
    cellFormatter: {
      type: GridCellFormatterType.Favourite,
      props: {
        nameColumn: ViewColumnSystemName.CompanyTicker,
      },
    },
    width: ViewGridColumnWidth.Icon,
    minWidth: ViewGridColumnWidth.Icon,
    maxWidth: ViewGridColumnWidth.Icon,
    hasSortIcon: false,
    comparator: favouriteComparator,
  },
  [ViewColumnSystemName.IsInvestorScreenLink]: {
    hide: true,
  },
  [ViewColumnSystemName.Research]: {
    comparator: researchComparator,
    isSortingInverse: true,
    alignHorizontal: AlignTypesHorizontal.End,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: ResearchCell,
      },
    },
    minWidth: 105,
    width: 105,
  },
  [ViewColumnSystemName.ResearchRating]: {
    comparator: researchComparator,
    isSortingInverse: true,
    minWidth: ViewGridColumnWidth.Md,
    width: ViewGridColumnWidth.Md,
    alignHorizontal: AlignTypesHorizontal.Start,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: ResearchRatingCell,
      },
    },
  },
  [ViewColumnSystemName.ProfileRating]: {
    comparator: researchRatingComparator,
    isSortingInverse: true,
    minWidth: ViewGridColumnWidth.Xxs,
    width: ViewGridColumnWidth.Xxs,
    alignHorizontal: AlignTypesHorizontal.Start,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: ResearchRatingCell,
      },
    },
  },
  [ViewColumnSystemName.TodaysPriceChange]: {
    comparator: todaysPriceChangeComparator,
    isSortingInverse: true,
    alignHorizontal: AlignTypesHorizontal.End,
    enableCellChangeFlash: true,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: TodaysPriceChangeCell,
      },
    },
    minWidth: ViewGridColumnWidth.Ms,
    width: 135,
  },
  [ViewColumnSystemName.FileDate]: {
    minWidth: ViewGridColumnWidth.Xs,
    width: ViewGridColumnWidth.Xs,
    alignHorizontal: AlignTypesHorizontal.Start,
  },
  [ViewColumnSystemName.ReportDate]: {
    minWidth: ViewGridColumnWidth.Xs,
    width: ViewGridColumnWidth.Xs,
    alignHorizontal: AlignTypesHorizontal.Start,
    // valueFormatter: (params: GridValueFormatterParams): string =>
    //   DateHelper.formatISODateStr(params.value, DateFormat.MonthDayYearShort),
  },
  [ViewColumnSystemName.ReportTitle]: {
    sortable: false,
    minWidth: ViewGridColumnWidth.Lg,
    width: ViewGridColumnWidth.Lg,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: ReportTitleCell,
      },
    },
  },

  [ViewColumnSystemName.VideoTitle]: {
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: VideoTitleCell,
      },
    },
  },
  [ViewColumnSystemName.SpecialReportsDate]: {
    sortable: false,
    width: ViewGridColumnWidth.Xs,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: SpecialReportsDateCell,
      },
    },
  },
  [ViewColumnSystemName.NewsLink]: {
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: NewsLinkCell,
      },
    },
  },
  [ViewColumnSystemName.TearSheet]: {
    field: ViewColumnSystemName.TearSheet,
    alignHorizontal: AlignTypesHorizontal.Center,
    sortable: false,
    cellFormatter: {
      type: GridCellFormatterType.FileActions,
      props: {
        fileActionsFormatterProps: [printCompanyFileAction],
      },
    },
  },
  [ViewColumnSystemName.PriceRange]: {
    alignHorizontal: AlignTypesHorizontal.Center,
    sortable: false,
    minWidth: 110,
    width: 110,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: PriceRangeCell,
      },
    },
  },
  [ViewColumnSystemName.LastUpdateDaysPipeline]: {
    alignHorizontal: AlignTypesHorizontal.Center,
    isSortingInverse: false,
    width: ViewGridColumnWidth.Ms,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: LastUpdateDaysCell,
      },
    },
  },
  [ViewColumnSystemName.LastUpdateDaysPCW]: {
    alignHorizontal: AlignTypesHorizontal.Center,
    isSortingInverse: false,
    width: ViewGridColumnWidth.Ms,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: LastUpdateDaysCell,
      },
    },
  },
  [ViewColumnSystemName.EstSales]: {
    alignHorizontal: AlignTypesHorizontal.Center,
    isSortingInverse: false,
    width: ViewGridColumnWidth.Xs,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: EstSalesCell,
      },
    },
  },
  [ViewColumnSystemName.EstValuation]: {
    alignHorizontal: AlignTypesHorizontal.Center,
    isSortingInverse: false,
    width: ViewGridColumnWidth.Ms,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: EstValuationCell,
      },
    },
  },
  [ViewColumnSystemName.FundingToDate]: {
    alignHorizontal: AlignTypesHorizontal.Center,
    isSortingInverse: false,
    width: ViewGridColumnWidth.Ms,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: FundingToDateCell,
      },
    },
  },
  [ViewColumnSystemName.InvestorsList]: {
    comparator: ViewGridSortService.sortTextArray.bind(ViewGridSortService),
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: InvestorsCell,
      },
    },
  },
  [ViewColumnSystemName.WeakStrong]: {
    sortable: false,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: WeakStrongCell,
      },
    },
  },
  [ViewColumnSystemName.PriceChange]: {
    alignHorizontal: AlignTypesHorizontal.End,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: PriceChangeCell,
      },
    },
  },

  [ViewColumnSystemName.BusinessOverview]: {
    width: ViewGridColumnWidth.Xl,
    minWidth: ViewGridColumnWidth.Xl,
  },
  [ViewColumnSystemName.DateIssued]: {
    comparator: DateIssuedComparator,
    width: ViewGridColumnWidth.Xs,
    maxWidth: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.DateUpdated]: {
    width: ViewGridColumnWidth.Xs,
    maxWidth: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.NewsHeadline]: {
    flex: 1,
    minWidth: ViewGridColumnWidth.Lg,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: NewsHeadlineCell,
      },
    },
  },
  [ViewColumnSystemName.ResearchKeyword]: {
    flex: 1,
    minWidth: ViewGridColumnWidth.Xl,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: ResearchKeywordCell,
      },
    },
  },
  [ViewColumnSystemName.ResearchType]: {
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: ResearchTypeCell,
      },
    },
  },
  [ViewColumnSystemName.UnderwriterName]: {
    alignHorizontal: AlignTypesHorizontal.End,
  },
  [ViewColumnSystemName.Underwriter]: {
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: UnderwritersCell,
      },
    },
    minWidth: 95,
    width: 95,
  },
  [ViewColumnSystemName.Underwriters]: {
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: UnderwritersCell,
      },
    },
    minWidth: 95,
    width: 95,
  },
  [ViewColumnSystemName.EstTiming]: {
    comparator: EstTimingComparator,
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xxs,
  },
  [ViewColumnSystemName.Sector]: {
    minWidth: ViewGridColumnWidth.Xs,
    width: ViewGridColumnWidth.Sx,
  },
  [ViewColumnSystemName.TradeDate]: {
    isSortingInverse: false,
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xxs,
    alignHorizontal: AlignTypesHorizontal.Start,
  },
  [ViewColumnSystemName.SectorIndustry]: {
    minWidth: ViewGridColumnWidth.XXl,
  },
  [ViewColumnSystemName.Industry]: {
    minWidth: ViewGridColumnWidth.Lg,
  },
  [ViewColumnSystemName.TargetCompany]: {
    minWidth: ViewGridColumnWidth.Lgx,
  },

  [ViewColumnSystemName.DealStatus]: {
    minWidth: ViewGridColumnWidth.XXl,
  },
  [ViewColumnSystemName.RegionCountry]: {
    minWidth: ViewGridColumnWidth.Lg,
  },
  [ViewColumnSystemName.ResearchTypeWidget]: {
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: ResearchTypeWidgetCell,
      },
    },
  },
  [ViewColumnSystemName.MarketCap]: {
    width: ViewGridColumnWidth.Sx,
    minWidth: ViewGridColumnWidth.Sx,
  },
  [ViewColumnSystemName.Shares]: {
    alignHorizontal: AlignTypesHorizontal.Center,
    minWidth: ViewGridColumnWidth.Xs,
    width: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.SharesCapitalized]: {
    alignHorizontal: AlignTypesHorizontal.Center,
    minWidth: ViewGridColumnWidth.Xs,
    width: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.EVSales]: {
    alignHorizontal: AlignTypesHorizontal.End,
    minWidth: ViewGridColumnWidth.Xs,
    width: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.EVEBITDA]: {
    alignHorizontal: AlignTypesHorizontal.End,
    minWidth: ViewGridColumnWidth.Xs,
    width: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.PE]: {
    alignHorizontal: AlignTypesHorizontal.End,
    minWidth: ViewGridColumnWidth.Xs,
    width: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.KeyExecutive]: {
    minWidth: ViewGridColumnWidth.Xl,
    width: ViewGridColumnWidth.Xl,
  },
  [ViewColumnSystemName.Age]: {
    minWidth: ViewGridColumnWidth.Sm,
    width: ViewGridColumnWidth.Sm,
    alignHorizontal: AlignTypesHorizontal.Start,
  },
  [ViewColumnSystemName.Position]: {
    minWidth: ViewGridColumnWidth.Md,
    width: ViewGridColumnWidth.Md,
  },
  [ViewColumnSystemName.Corporate_Background]: {
    minWidth: ViewGridColumnWidth.XXl,
    width: ViewGridColumnWidth.XXl,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: HtmlStringFormatterJustified,
      },
    },
    isAutoWidth: true,
  },
  [ViewColumnSystemName.KeyShareholder]: {
    minWidth: ViewGridColumnWidth.Xl,
    width: ViewGridColumnWidth.Xl,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: ViewTriggerCell,
        viewType: ViewType.InvestorsInUse,
        hasLinkStyle: true,
        getIsLinkAvailable: (row: GridRow, value: GridCellValue, columnName: ViewColumnSystemName) =>
          !!ViewGridCellValueService.getRaw(row, columnName),
        optionsList: [
          {
            getFilterName: () => ViewFilterName.Investor,
            getFilterValue: ViewColumnSystemName.Investor_Key,
          },
        ],
      },
    },
  },
  // [ViewColumnSystemName.Ownership]: {
  //   minWidth: ViewGridColumnWidth.Xld,
  //   width: ViewGridColumnWidth.Xld,
  //   alignHorizontal: AlignTypesHorizontal.Center,
  // },
  [ViewColumnSystemName.Additional_Details]: {
    minWidth: ViewGridColumnWidth.XXl,
    width: ViewGridColumnWidth.XXl,
    isAutoWidth: true,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: HtmlStringFormatterJustified,
      },
    },
  },
  [ViewColumnSystemName.Milestone]: {
    isAutoWidth: true,
  },
  [ViewColumnSystemName.RowNum]: {
    minWidth: ViewGridColumnWidth.Icon,
    width: ViewGridColumnWidth.Icon,
    alignHorizontal: AlignTypesHorizontal.Start,
  },
  [ViewColumnSystemName.AnnouncedDate]: {
    alignHorizontal: AlignTypesHorizontal.End,
  },
  [ViewColumnSystemName.Status]: {
    alignHorizontal: AlignTypesHorizontal.End,

    minWidth: ViewGridColumnWidth.Xs,
    width: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.Name]: {
    minWidth: ViewGridColumnWidth.Lg,
    width: ViewGridColumnWidth.Lg,
  },
  [ViewColumnSystemName.Data]: {
    minWidth: 128,
    width: 128,
  },
  [ViewColumnSystemName.OwnershipPct]: {
    minWidth: ViewGridColumnWidth.Ms,
  },
  [ViewColumnSystemName.NumIPOs]: {
    minWidth: ViewGridColumnWidth.Xs,
    width: ViewGridColumnWidth.Xs,
    maxWidth: ViewGridColumnWidth.Xxs,
  },
  [ViewColumnSystemName.NumSPACs]: {
    minWidth: ViewGridColumnWidth.Sm,
    width: ViewGridColumnWidth.Sm,
    maxWidth: ViewGridColumnWidth.Sm,
  },
  [ViewColumnSystemName.FirstDayPop]: {
    minWidth: ViewGridColumnWidth.Sx,
  },
  [ViewColumnSystemName.IpoReturn]: {
    minWidth: ViewGridColumnWidth.Sx,
  },
  [ViewColumnSystemName.PastMonthReturn]: {
    minWidth: ViewGridColumnWidth.Sx,
  },
  [ViewColumnSystemName.Proceeds]: {
    minWidth: ViewGridColumnWidth.Sx,
  },
  [ViewColumnSystemName.OfferPrice]: {
    minWidth: ViewGridColumnWidth.Xs,
    width: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.OfferDate]: {
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xxs,
  },
  [ViewColumnSystemName.OfferMonthTiming]: {
    comparator: OfferMonthTimingComparator,
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xxs,
  },
  [ViewColumnSystemName.Exchange]: {
    minWidth: ViewGridColumnWidth.Xxs,
    width: ViewGridColumnWidth.Sm,
  },
  [ViewColumnSystemName.ExchangeName]: {
    minWidth: ViewGridColumnWidth.Xxs,
    width: ViewGridColumnWidth.Sm,
  },
  [ViewColumnSystemName.IpoDiscount]: {
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xxs,
  },
  [ViewColumnSystemName.FirstDayReturn]: {
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xxs,
  },
  [ViewColumnSystemName.TotalReturn]: {
    width: 70,
    minWidth: 70,
  },
  [ViewColumnSystemName.EbitPercent]: {
    width: ViewGridColumnWidth.Ms,
    minWidth: ViewGridColumnWidth.Ms,
  },
  [ViewColumnSystemName.SalesLtm]: {
    width: ViewGridColumnWidth.Ms,
    minWidth: ViewGridColumnWidth.Ms,
  },
  [ViewColumnSystemName.LatestSales]: {
    width: ViewGridColumnWidth.Sx,
    minWidth: ViewGridColumnWidth.Sx,
  },
  [ViewColumnSystemName.Label]: {
    width: ViewGridColumnWidth.Lg,
    minWidth: ViewGridColumnWidth.Lg,
  },
  [ViewColumnSystemName.LtmEbitMargin]: {
    width: ViewGridColumnWidth.Sx,
    minWidth: ViewGridColumnWidth.Sx,
  },
  [ViewColumnSystemName.MktCap]: {
    width: ViewGridColumnWidth.Sx,
    minWidth: ViewGridColumnWidth.Sx,
  },
  [ViewColumnSystemName.Sales]: {
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.GMPct]: {
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.EBITPct]: {
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.EBITDAPct]: {
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.YOYGrowth]: {
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.Statement]: {
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.Stubs]: {
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: StubsCell,
      },
    },
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.StatsYear]: {
    alignHorizontal: AlignTypesHorizontal.End,
    width: ViewGridColumnWidth.Xs,
    minWidth: ViewGridColumnWidth.Xs,
  },
  [ViewColumnSystemName.AccountName]: {
    minWidth: ViewGridColumnWidth.Lg,
    flex: 1,
  },
  [ViewColumnSystemName.PagePathUrl]: {
    minWidth: ViewGridColumnWidth.Lg,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: PagePathCell,
      },
    },
    flex: 1,
  },
  [ViewColumnSystemName.PagePath]: {
    minWidth: ViewGridColumnWidth.Lg,
    flex: 1,
  },
  [ViewColumnSystemName.FileName]: {
    minWidth: ViewGridColumnWidth.Lg,
    flex: 1,
  },
  [ViewColumnSystemName.Email]: {
    minWidth: ViewGridColumnWidth.Lg,
    flex: 1,
  },
  [ViewColumnSystemName.IsActive]: {
    width: ViewGridColumnWidth.Sm,
    alignHorizontal: AlignTypesHorizontal.Center,
  },
  [ViewColumnSystemName.AccountId]: {
    width: ViewGridColumnWidth.Sm,
    alignHorizontal: AlignTypesHorizontal.Center,
  },
  [ViewColumnSystemName.FirstName]: {
    minWidth: ViewGridColumnWidth.Sm,
  },
  [ViewColumnSystemName.LastName]: {
    minWidth: ViewGridColumnWidth.Sm,
  },
  [ViewColumnSystemName.LatestTokenDate]: {
    comparator: latestTokenDateComparator,
  },

  // Google Analytics 4
  [ViewColumnSystemName.AccountIdUrl]: {
    flex: 1,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: AccountIdUrlCell,
      },
    },
    minWidth: ViewGridColumnWidth.Xl,
  },
  [ViewColumnSystemName.UserActivityUrl]: {
    flex: 1,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: UserActivityUrlCell,
      },
    },
    minWidth: ViewGridColumnWidth.Xl,
  },
  [ViewColumnSystemName.FileActivityUrl]: {
    flex: 1,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: FileNameUrlCell,
      },
    },
    minWidth: ViewGridColumnWidth.Xl,
  },
  [ViewColumnSystemName.FileActivityExcelUrl]: {
    flex: 1,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: FileNameExcelUrlCell,
      },
    },
    minWidth: ViewGridColumnWidth.Xl,
  },
  [ViewColumnSystemName.Count]: {
    comparator: doubleComparator,
  },

  // Diluted Shares Tooltip
  [ViewColumnSystemName.CapitalizationCell]: {
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: CapitalizationDataCell,
      },
    },
  },

  // PCML Columns
  [ViewColumnSystemName.PcmlArticles]: {
    width: ViewGridColumnWidth.Md,
    maxWidth: ViewGridColumnWidth.Md,
    isSortingInverse: true,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: PcmlArticlesCell,
      },
    },
  },
  [ViewColumnSystemName.CFO]: {
    width: ViewGridColumnWidth.Xs,
    maxWidth: ViewGridColumnWidth.Xs,
    isSortingInverse: true,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: CfoCell,
      },
    },
  },
  [ViewColumnSystemName.TypeOfFunding]: {
    width: ViewGridColumnWidth.Sx,
    maxWidth: ViewGridColumnWidth.Sx,
    isSortingInverse: true,
  },
  [ViewColumnSystemName.PcmlNote]: {
    width: ViewGridColumnWidth.Sm,
    maxWidth: ViewGridColumnWidth.Sm,
    isSortingInverse: true,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: PcmlNoteCell,
      },
    },
  },
  [ViewColumnSystemName.PcmlInvestorsList]: {
    isSortingInverse: true,
    width: ViewGridColumnWidth.Md,
    maxWidth: ViewGridColumnWidth.Md,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: PcmlInvestorsCell,
      },
    },
  },
  [ViewColumnSystemName.LastUpdateDaysPcml]: {
    alignHorizontal: AlignTypesHorizontal.Center,
    isSortingInverse: false,
    width: ViewGridColumnWidth.Sm,
    maxWidth: ViewGridColumnWidth.Sm,
  },
  [ViewColumnSystemName.PcmlEstValuation]: {
    alignHorizontal: AlignTypesHorizontal.Center,
    width: ViewGridColumnWidth.Sx,
    maxWidth: ViewGridColumnWidth.Sx,
  },
  [ViewColumnSystemName.PcmlFundingToDate]: {
    alignHorizontal: AlignTypesHorizontal.Center,
    width: ViewGridColumnWidth.Sm,
  },
  [ViewColumnSystemName.LastFundingDate]: {
    alignHorizontal: AlignTypesHorizontal.Center,
    width: ViewGridColumnWidth.Sx,
    cellFormatter: {
      type: GridCellFormatterType.Custom,
      props: {
        component: PcmlLastFundingDateCell,
      },
    },
  },
  [ViewColumnSystemName.PcmlCompanyName]: {
    width: 160,
    maxWidth: 160,
  },
};
