import { ReducerAction } from '../../shared/interfaces/ReducerAction';
import { CompanyFavouriteDto } from '../../company/interfaces/CompanyFavouriteDto';

export const SET_FAVOURITE_COMPANIES = 'SET_FAVOURITE_COMPANIES';
export const ADD_FAVOURITE_COMPANY = 'ADD_FAVOURITE_COMPANY';
export const REMOVE_FAVOURITE_COMPANY = 'REMOVE_FAVOURITE_COMPANY';

export type State = {
  companies: CompanyFavouriteDto[];
};

export type SetFavouriteCompanies = ReducerAction<
  typeof SET_FAVOURITE_COMPANIES,
  {
    companies: CompanyFavouriteDto[];
  }
>;

export type AddFavouriteCompany = ReducerAction<typeof ADD_FAVOURITE_COMPANY, { company: CompanyFavouriteDto }>;

export type RemoveFavouriteCompanies = ReducerAction<
  typeof REMOVE_FAVOURITE_COMPANY,
  {
    companyKey: number;
  }
>;

export type ActionTypes = SetFavouriteCompanies | AddFavouriteCompany | RemoveFavouriteCompanies;
