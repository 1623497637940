import React, { useCallback, useMemo, useState } from 'react';
import Form from '../../../../shared/components/forms/Form';
import { validationSchema } from '../../../../my-favorites/components/favorite-ipos/components/form/validationSchema';
import { AlignTypesHorizontal } from '../../../../shared/enums/AlignTypesHorizontal';
import FormGroup from '../../../../shared/components/forms/FormGroup';
import FormSearchInput from '../../../../shared/components/forms/components/search-input/FormSearchInput';
import { InputType } from '../../../../shared/components/forms/Input';
import { ControlSize } from '../../../../shared/enums/ControlSize';
import { SearchApiService } from '../../../../shared/api/SearchApiService';
import { SearchDataServices } from '../services/SearchDataServices';
import { SearchAutocompleteItem as SearchAutocompleteItemType } from '../types/SearchAutocompleteItem';
import SearchAutocompleteItem from '../SearchAutocompleteItem';
import ButtonSubmit from '../../../../shared/components/forms/ButtonSubmit';
import { BlockOrientation } from '../../../../shared/enums/BlockOrientation';
import Icon from '../../../../shared/components/icon/Icon';
import { IconName } from '../../../../shared/components/icon/IconName';
import { ColorScheme } from '../../../../shared/enums/ColorScheme';
import { SearchResultsLocationService } from '../../../services/SearchResultsLocationService';
import { SearchField } from './SearchField';
import { SearchInputSymbolsCount } from '../../../../shared/components/forms/components/search-input/enums/SearchInputSymbolsCount';
import { SearchAutocompleteFactory } from '../factories/SearchAutocompleteFactory';
import LocationService from '../../../../shared/services/LocationService';
import { RoutePath } from '../../../../shared/enums/RoutePath';
import { FileService } from '../../../../shared/services/file/FileService';

interface SearchFormProps {
  onSubmit?: () => void;
}

const SearchForm: React.FC<SearchFormProps> = props => {
  const [searchValue, setSearchValue] = useState();

  const getOptionsFn = useMemo(() => SearchDataServices.getAutocompleteItems.bind(SearchApiService), []);

  const onSubmit = useCallback(() => {
    if (props.onSubmit) {
      props.onSubmit();
    }
    searchValue && SearchResultsLocationService.navigate(searchValue);
  }, [searchValue]);

  const onRenderItem = useCallback(item => <SearchAutocompleteItem item={item as SearchAutocompleteItemType} />, []);

  const onSearchValueChange = useCallback(searchValue => setSearchValue(searchValue), []);

  const onSelectItem = useCallback(async item => {
    const options = SearchAutocompleteFactory.getViewItemOptions(item);

    if (options?.isBlank) {
      LocationService.redirectExternalLink(options?.routePath);
    } else {
      if (options?.tag && options.tag === 'Research') {
        try {
          await FileService.getAndDownload({ url: item.data?.link });
        } catch (e) {
          throw e;
        }
        if (props.onSubmit) {
          // calling this fn twice since we want a different
          // order in which this fn is called
          props.onSubmit();
        }
      } else {
        if (props.onSubmit) {
          props.onSubmit();
        }
        LocationService.redirect(options?.routePath as RoutePath);
      }
    }
  }, []);

  return (
    <Form
      validationSchema={validationSchema}
      alignHorizontal={AlignTypesHorizontal.Center}
      size={ControlSize.ExtraLarge}
      orientation={BlockOrientation.Line}
      onSubmit={onSubmit}
    >
      <FormGroup isFullWidth>
        <FormSearchInput
          name={SearchField.SearchValue}
          placeholder="Search"
          type={InputType.Text}
          size={ControlSize.Medium}
          width={ControlSize.ExtraLarge}
          searchSymbolsCount={SearchInputSymbolsCount.WithTicker}
          shouldClearOnSelect
          getOptionsFn={getOptionsFn}
          onRenderItem={onRenderItem}
          onSearchValueChange={onSearchValueChange}
          onSelectItem={onSelectItem}
        />
      </FormGroup>

      <ButtonSubmit
        isSimple
        responsiveNoLeftMargin
        isSubmitted={false}
        message={
          <Icon name={IconName.BoldMagnifier} size={ControlSize.Large} colorScheme={ColorScheme.Primary} hasHover />
        }
        submittedMessage=""
      />
    </Form>
  );
};

export default SearchForm;
