import React from 'react';
import './MasterCalendarLegendItemSymbol.scss';
import { useClassName } from '../../shared/hooks/useClassName';
import {
  MasterCalendarLegendItemSymbolBlock,
  MasterCalendarLegendItemSymbolElement,
} from './masterCalendarLegendItemSymbolBem';
import { CalendarEventType } from '../../shared/entities/calendar/enums/CalendarEventType';
import MasterCalendarLegendItemWrapper from './MasterCalendarLegendItemWrapper';

interface Props {
  type: CalendarEventType;
}

const MasterCalendarLegendItemSymbol: React.FC<Props> = props => {
  const cn = useClassName(MasterCalendarLegendItemSymbolBlock.Root);

  return (
    <MasterCalendarLegendItemWrapper type={props.type}>
      <div
        className={cn({
          [props.type]: props.type,
        })}
      >
        <div className={cn(MasterCalendarLegendItemSymbolElement.Crossing)} />
      </div>
    </MasterCalendarLegendItemWrapper>
  );
};

export default MasterCalendarLegendItemSymbol;
