import React from 'react';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import './VideoTitleCell.scss';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import { useClassName } from '../../../../../../../shared/hooks/useClassName';
import { VideoTitleBlock } from './VideoTitleBem';
import Hyperlink from '../../../../../../components/typography/Hyperlink';
import { VideoLocationService } from '../../../../../../../videos/shared/services/videoLocationService';
import Icon from '../../../../../../components/icon/Icon';
import { IconName } from '../../../../../../components/icon/IconName';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { ColorScheme } from '../../../../../../enums/ColorScheme';

type Props = GridCellFormatterCommonProps;

const VideoTitleCell: React.FC<Props> = props => {
  const cn = useClassName(VideoTitleBlock.Root);

  // const title = ViewGridCellValueService.getRaw(props.data, ViewColumnSystemName.VideoTitle);
  // const url = ViewGridCellValueService.getRaw(props.data, ViewColumnSystemName.ReportLink);

  return (
    <CustomCellFormatterWrapper className={cn()} value={'video-title'}>
      <Hyperlink to={VideoLocationService.getRoutePath(props.data.videoKey, props.data.videoTypeName)} isExternal>
        {props.data?.videoKey && (
          <Icon
            className={`${IconName.PlayCircle} inline-icon`}
            name={IconName.PlayCircle}
            size={ControlSize.Small}
            colorScheme={ColorScheme.Attention}
          />
        )}
        {props.value}
      </Hyperlink>
    </CustomCellFormatterWrapper>
  );
};

export default VideoTitleCell;
