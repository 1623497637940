import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ViewFilterName } from '../enum/types/ViewFilterName';
import { FilterSectionsValueService } from '../services/FilterSectionsValueService';

export function useExchangeDependencies(): void {
  const { watch, setValue } = useFormContext();

  const exchange = watch(ViewFilterName.LocationExchange);
  const listingLocation = watch(ViewFilterName.LocationListingLocation);

  useEffect(() => {
    if (!exchange) {
      return;
    }
    setValue(ViewFilterName.LocationListingLocation, '');

    // if (FilterSectionsValueService.isUsExchange(exchange)) {
    //   setValue(ViewFilterName.LocationListingLocation, Location.US);
    // } else {
    //   setValue(ViewFilterName.LocationListingLocation, Location.NonUS);
    // }

    if (FilterSectionsValueService.isNonUsChinaASharesExchange(exchange)) {
      setValue(ViewFilterName.IncludeChina, true);
    }
  }, [exchange, listingLocation, setValue]);
}
