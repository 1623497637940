import React, { useContext, useMemo } from 'react';
import { CompanyContext } from '../context/CompanyContext';
import { CompanyParagraphItem } from '../../interfaces/CompanyParagraphItem';
import CompanyParagraph from '../shared/CompanyParagraph';
import CompanySection from '../shared/CompanySection';
import { CompanyTab } from '../../enums/CompanyTab';
import { CompanyMgmtQsDto } from '../../interfaces/CompanyMgmtQsDto';
import { Nullable } from '../../../shared/types/generics';
import { ProfileData } from '../../interfaces/ProfileData';
import HtmlStringFormatterJustified from '../../../shared/components/HtmlStringFormatterJustified';

const CompanyMgmtQs: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const data: Nullable<CompanyMgmtQsDto> = getTabData(CompanyTab.MgmtQs);
  const pdfLink = data?.pdfDownloadLink;

  const titleProps = useMemo(() => {
    const profileData: ProfileData = getTabData(CompanyTab.Profile);
    const companyName = profileData.description?.companyName;

    const defaultTitle = `Questions to ask ${companyName || ''} management`;

    return {
      tab: CompanyTab.MgmtQs,
      defaultTitle,
      pdfLink,
    };
  }, [pdfLink, getTabData]);

  return (
    <>
      {data && <h3 className="printHead">Mgmt Qs</h3>}
      <CompanySection className="CompanyMgmtQs" data={data} titleProps={titleProps}>
        {data?.summary && (
          <CompanyParagraph
            label={data.summary.label}
            text={<HtmlStringFormatterJustified value={data.summary.text as string} />}
          />
        )}
        {data?.questions.map((question: CompanyParagraphItem, index: number) => (
          <CompanyParagraph
            key={index}
            label={question.label}
            text={<HtmlStringFormatterJustified value={question.text as string} />}
          />
        ))}
      </CompanySection>
    </>
  );
};

export default CompanyMgmtQs;
