import React, { useMemo } from 'react';
import { ColorScheme } from '../../enums/ColorScheme';
import ColorSchemeFormatter from './ColorSchemeFormatter';
import { Nullable } from '../../types/generics';

interface Props {
  value: Nullable<number>;
  displayValue?: string;
  formatterBaseValue?: number;
}

const ColorNumberFormatter: React.FC<Props> = props => {
  const displayValue = useMemo<string>(() => props.displayValue || (props.value ? props.value.toString() : ''), [
    props.value,
    props.displayValue,
  ]);

  const baseValue = props.formatterBaseValue ? props.formatterBaseValue : 0;

  const colorScheme = useMemo<ColorScheme>(() => {
    if (!props.value) {
      return ColorScheme.Primary;
    } else if (props.value > baseValue) {
      return ColorScheme.Success;
    } else if (props.value < baseValue) {
      return ColorScheme.Danger;
    } else {
      return ColorScheme.Primary;
    }
  }, [props.value]);

  return <ColorSchemeFormatter colorScheme={colorScheme} value={displayValue} />;
};

export default ColorNumberFormatter;
