import React from 'react';
import './AutocompleteItem.scss';
import { useClassName } from '../../hooks/useClassName';
import { AutocompleteItemBlock, AutocompleteItemModifier } from './autocompleteItemBem';
import { AlignTypesHorizontal } from '../../enums/AlignTypesHorizontal';

interface Props {
  isHoverable?: boolean;
  alignHorizontal?: AlignTypesHorizontal;
  isHighlighted?: boolean;
}

export const AutocompleteItem: React.FC<Props> = props => {
  const { isHoverable = true } = props;

  const cn = useClassName(AutocompleteItemBlock.Root);

  return (
    <div
      className={cn({
        [AutocompleteItemModifier.Highlighted]: props.isHighlighted,
        [AutocompleteItemModifier.Hoverable]: isHoverable,
        [`${props.alignHorizontal}`]: props.alignHorizontal,
      })}
    >
      {props.children}
    </div>
  );
};

export default AutocompleteItem;
