import ApiService from './ApiService';
import { ApiPath } from './enums/ApiPath';
import { CompanyTooltipDto } from '../../company/interfaces/CompanyTooltipDto';
import { CompanyReqQueryParam } from '../../company/enums/CompanyReqQueryParam';
import { InvestmentRationaleDto } from '../../company/interfaces/InvestmentRationaleDto';
import { TickerSymbol } from '../types';
import { CompanyFinancialsDto } from '../../company/interfaces/CompanyFinancialsDto';
import { CompanyFundamentalInsightsDto } from '../../company/interfaces/CompanyFundamentalInsightsDto';
import { CompanyMgmtQsDto } from '../../company/interfaces/CompanyMgmtQsDto';
import { CompanyMgmtOwnersDto } from '../../company/interfaces/CompanyMgmtOwnersDto';
import { CompanyRelativeValuationDto } from '../../company/interfaces/CompanyRelativeValuationDto';
import { CompanyPerfomanceVsIpoIndexTerm } from '../../company/components/shared/performance-vs-ipo-Index-chart/interfaces/CompanyPerfomanceVsIpoIndexTerm';
import { Nullable } from '../types/generics';
import { CompanyPerfomanceVsIpoIndexChartDto } from '../../company/types/CompanyPerfomanceVsIpoIndexChartDto';
import { CompanyTabsDto } from '../../company/interfaces/CompanyTabsDto';
import { CompanyMilestoneDto } from '../../company/interfaces/CompanyMilestoneDto';
import { UnderwritersTooltipDto } from '../../company/interfaces/UnderwritersTooltipDto';
import { SimpleGridProps } from '../components/grid/simple-grid/SimpleGrid';

export class CompanyApiService {
  static getTooltip(companyTicker: TickerSymbol): Promise<CompanyTooltipDto> {
    return ApiService.get<CompanyTooltipDto>(`${ApiPath.CompanyTooltip}`, {
      params: { [CompanyReqQueryParam.CompanyTicker]: companyTicker },
    });
  }

  static getUnderwriters(companyTicker: TickerSymbol): Promise<any> {
    return ApiService.get<UnderwritersTooltipDto>(`${ApiPath.Underwriters}`, {
      params: { [CompanyReqQueryParam.CompanyTicker]: companyTicker },
    });
  }

  static getInvestmentRationaleData(companyTicker: TickerSymbol): Promise<InvestmentRationaleDto> {
    return ApiService.get<InvestmentRationaleDto>(`${ApiPath.ProfileInvestmentRationale}/${companyTicker}`);
  }

  static getFundamentalInsightsData(companyTicker: TickerSymbol): Promise<CompanyFundamentalInsightsDto> {
    return ApiService.get<CompanyFundamentalInsightsDto>(`${ApiPath.ProfileFundamentalInsights}/${companyTicker}`);
  }

  static getFinancialData(companyTicker: TickerSymbol): Promise<CompanyFinancialsDto> {
    return ApiService.get<CompanyFinancialsDto>(`${ApiPath.ProfileFinancial}/${companyTicker}`);
  }

  static getMgmtQsData(companyTicker: TickerSymbol): Promise<CompanyMgmtQsDto> {
    return ApiService.get<CompanyMgmtQsDto>(`${ApiPath.ProfileManagementQuestions}/${companyTicker}`);
  }

  static getMgmtOwnersData(companyTicker: TickerSymbol): Promise<CompanyMgmtOwnersDto> {
    return ApiService.get<CompanyMgmtOwnersDto>(`${ApiPath.ProfileManagementOwners}/${companyTicker}`);
  }

  static getRelativeValuationData(companyTicker: TickerSymbol): Promise<CompanyRelativeValuationDto> {
    return ApiService.get<CompanyRelativeValuationDto>(`${ApiPath.ProfileRelativeValuation}/${companyTicker}`);
  }

  static getPerfomanceVsIpoIndexChart(
    companyTickers: TickerSymbol[],
    params: Nullable<CompanyPerfomanceVsIpoIndexTerm>,
  ): Promise<CompanyPerfomanceVsIpoIndexChartDto[]> {
    return ApiService.post<CompanyPerfomanceVsIpoIndexChartDto[]>(ApiPath.ProfileChart, companyTickers, { params });
  }

  static getAllData(companyTicker: TickerSymbol): Promise<CompanyTabsDto> {
    return ApiService.get<CompanyTabsDto>(`${ApiPath.ProfileAll}/${companyTicker}`);
  }

  static getFundamentalComparisonData(companyTicker: TickerSymbol): Promise<SimpleGridProps> {
    return ApiService.get<SimpleGridProps>(`${ApiPath.ProfileFundamentalComparison}/${companyTicker}`);
  }

  static getValuationComparisonData(companyTicker: TickerSymbol): Promise<SimpleGridProps> {
    return ApiService.get<SimpleGridProps>(`${ApiPath.ProfileValuationComparison}/${companyTicker}`);
  }

  static getTechnicalComparisonData(companyTicker: TickerSymbol): Promise<SimpleGridProps> {
    return ApiService.get<SimpleGridProps>(`${ApiPath.ProfileTechnicalComparison}/${companyTicker}`);
  }

  static getMilestones(companyTicker: TickerSymbol): Promise<CompanyMilestoneDto[]> {
    return ApiService.get<CompanyMilestoneDto[]>(`${ApiPath.ProfileMilestones}/${companyTicker}`);
  }
}
