import React, { ReactElement } from 'react';
import './NewsRecap.scss';
import NewsRecapResearches from './NewsRecapResearches';
import NewsList from './NewsList';
import NewsRecapScheduled from './NewsRecapScheduled';
import { NewsRecapScheduledDto } from '../interfaces/NewsRecapScheduledDto';
import { NewsRecapPcwAdditionsDto } from '../interfaces/NewsRecapPcwAdditionsDto';
import NewsRecapPcwAdditions from './NewsRecapPcwAdditions';
import { usePromiseState } from '../../shared/hooks/promise-state/usePromiseState';
import { NewsRecapResearchDto } from '../interfaces/NewsRecapResearchDto';
import { NewsRecapHeadlineDto } from '../interfaces/NewsRecapHeadlineDto';
import NewsRecapSection from './NewsRecapSection';
import { NewsRecapSectionType } from '../enums/NewsRecapSectionType';
import PageLoader from '../../shared/components/loader/PageLoader';

interface Props {
  getMemoizedScheduledNews: () => Promise<NewsRecapScheduledDto[]>;
  getMemoizedPcwAdditions: () => Promise<NewsRecapPcwAdditionsDto[]>;
  getMemoizedResearches: () => Promise<NewsRecapResearchDto[]>;
  getMemoizedAnalystNotes: () => Promise<NewsRecapHeadlineDto[]>;
  getMemoizedUsExternalNews: () => Promise<NewsRecapHeadlineDto[]>;
  getMemoizedNonUsExternalNews: () => Promise<NewsRecapHeadlineDto[]>;
}

const NewsRecap: React.FC<Props> = props => {
  const [scheduledNews, areScheduledNewsLoading] = usePromiseState(props.getMemoizedScheduledNews, []);
  const [pcwAdditions, arePcwAdditionsLoading] = usePromiseState(props.getMemoizedPcwAdditions, []);
  const [researches, areResearchesLoading] = usePromiseState(props.getMemoizedResearches, []);
  const [analystNotes, areAnalystNotesLoading] = usePromiseState(props.getMemoizedAnalystNotes, []);
  const [usExternalNews, areUsExternalNewsLoading] = usePromiseState(props.getMemoizedUsExternalNews, []);
  const [nonUsExternalNews, areNonUsExternalLoading] = usePromiseState(props.getMemoizedNonUsExternalNews, []);

  const isLoading: boolean =
    areScheduledNewsLoading ||
    areResearchesLoading ||
    areAnalystNotesLoading ||
    areUsExternalNewsLoading ||
    arePcwAdditionsLoading ||
    areNonUsExternalLoading;

  return (
    <PageLoader loading={isLoading}>
      <div className="NewsRecap">
        <div className="NewsRecap__group">
          <NewsRecapSection
            type={NewsRecapSectionType.Research}
            title="IPO Research"
            isLoading={areResearchesLoading}
            items={researches}
            content={(): ReactElement => <NewsRecapResearches researches={researches} />}
          />

          {pcwAdditions && (
            <NewsRecapSection
              type={NewsRecapSectionType.PcwAdditions}
              title="Private Company Watchlist Additions"
              url={'/private-company-watchlist'}
              isLoading={arePcwAdditionsLoading}
              items={pcwAdditions}
              content={(): ReactElement => <NewsRecapPcwAdditions news={pcwAdditions} />}
            />
          )}

          <NewsRecapSection
            type={NewsRecapSectionType.IPOsScheduled}
            title="US IPOs Scheduled for Tonight"
            isLoading={areScheduledNewsLoading}
            items={scheduledNews}
            content={(): ReactElement => <NewsRecapScheduled news={scheduledNews} />}
          />
        </div>

        <div className="NewsRecap__group">
          <NewsRecapSection
            type={NewsRecapSectionType.AnalystNotes}
            title="Analyst Notes and IPO Alerts"
            isLoading={areAnalystNotesLoading}
            items={analystNotes}
            content={(): ReactElement => <NewsList expandedData={analystNotes} hasEmoji={false} />}
          />
        </div>

        <div className="NewsRecap__group">
          <NewsRecapSection
            type={NewsRecapSectionType.UsExternalNews}
            title="US External News"
            isLoading={areUsExternalNewsLoading}
            items={usExternalNews}
            content={(): ReactElement => <NewsList expandedData={usExternalNews} hasEmoji={false} />}
          />

          <NewsRecapSection
            type={NewsRecapSectionType.NonUsExternalNews}
            title="Non-US External News"
            isLoading={areNonUsExternalLoading}
            items={nonUsExternalNews}
            content={(): ReactElement => <NewsList expandedData={nonUsExternalNews} hasEmoji={false} />}
          />
        </div>
      </div>
    </PageLoader>
  );
};

export default NewsRecap;
