import React, { useContext, useMemo } from 'react';
import { StatsElementContext } from './context/StatsElementContext';
import StatsElementFilterInternalTextSelect from './shared/filters-internal/components/StatsElementFilterInternalTextSelect';
import { StatsElementFiltersInternalService } from './shared/filters-internal/services/StatsElementFiltersInternalService';
import { ControlDivider } from '../../../components/forms/services/control-divider/enums/ControlDivider';
import SecondarySection from '../../../components/secondary-section/SecondarySection';

const StatsElementCommonFilters: React.FC = () => {
  const { dataTypes, setDataType, dataType, options } = useContext(StatsElementContext);

  const textSelectOptions = useMemo(() => StatsElementFiltersInternalService.getTextSelectOptions(dataTypes), [
    dataTypes,
  ]);

  const internalFilterOptions = useMemo(() => options?.pageOptions?.internalFilter, [options]);

  const isTextSelectVisible = useMemo(() => StatsElementFiltersInternalService.getIsTextSelectVisible(dataTypes), [
    dataTypes,
  ]);

  return (
    <>
      {isTextSelectVisible && (
        <SecondarySection>
          <StatsElementFilterInternalTextSelect
            {...internalFilterOptions}
            divider={ControlDivider.Slash}
            value={dataType}
            options={textSelectOptions}
            onChangeValue={setDataType}
          />
        </SecondarySection>
      )}
    </>
  );
};

export default StatsElementCommonFilters;
