import React from 'react';
import './TooltipTitle.scss';
import { useClassName } from '../../hooks/useClassName';
import { TooltipTitleBlock, TooltipTitleModifier } from './TooltipTitleBem';

interface Props {
  isHighlighted?: boolean;
  hasLinkStyle?: boolean;
  className?: string;
}

const TooltipTitle: React.FC<Props> = props => {
  const cn = useClassName(TooltipTitleBlock.Root, props.className);

  return (
    <div
      className={cn({
        [TooltipTitleModifier.Highlighted]: props.isHighlighted,
        [TooltipTitleModifier.LinkStyle]: props.hasLinkStyle,
      })}
    >
      {props.children}
    </div>
  );
};

export default TooltipTitle;
