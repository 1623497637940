import PrimarySectionRow from '../../../../../shared/components/primary-section/PrimarySectionRow';
import React, { useContext, useMemo } from 'react';
import StatsElement from '../../../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../../../shared/entities/stats-element/enums/StatsElementType';
import {
  getRecentIpoPerfomanceStatsElementOptions,
  NONUS_GLOBAL_IPO_MARKET_CHART_CREDITS_TEXT,
  US_IPO_MARKET_CHART_CREDITS_TEXT,
} from './constants/recentIpoPerfomanceStatsElementOptions';
import { useStatsTitle } from '../../../hooks/useStatsTitle';
import { StatsPageContext } from '../../../context/StatsPageContext';
import { ViewFilterName } from '../../../../../shared/entities/filters/enum/types/ViewFilterName';
import { Location } from '../../../../../shared/entities/filters/enum/Location';

const StatsRecentIpoPerfomance: React.FC = () => {
  const { pageFilters } = useContext(StatsPageContext);

  const headquarter = useMemo(() => pageFilters?.[ViewFilterName.Headquarter], [pageFilters]);
  const isUsHeadquarter = useMemo(() => headquarter === Location.US, [headquarter]);

  const creditsText = useMemo(
    () => (isUsHeadquarter ? US_IPO_MARKET_CHART_CREDITS_TEXT : NONUS_GLOBAL_IPO_MARKET_CHART_CREDITS_TEXT),
    [isUsHeadquarter],
  );

  return (
    <PrimarySectionRow header={useStatsTitle('IPO Performance', 'Recent')}>
      <StatsElement
        type={StatsElementType.DashboardRecentIPOPerformance}
        options={getRecentIpoPerfomanceStatsElementOptions(creditsText)}
      />
    </PrimarySectionRow>
  );
};

export default StatsRecentIpoPerfomance;
