import { useEffect } from 'react';
import { ViewFilterName } from '../enum/types/ViewFilterName';
import { useFormContext } from 'react-hook-form';
import { FilterSectionsValueService } from '../services/FilterSectionsValueService';

export const useFilterSectionsDependencies = (): void => {
  const { watch, setValue } = useFormContext();

  const investorsPeBackedLBO: boolean | undefined = watch(ViewFilterName.PE);
  const investorsFounderManagementOwned: boolean | undefined = watch(ViewFilterName.MgmtOwned);

  const isInvestorsPeBackedLBOAvailable = FilterSectionsValueService.isInvestorsPeBackedLBOAvailable(
    investorsFounderManagementOwned,
  );
  const isInvestorsFounderManagementOwnedAvailable = FilterSectionsValueService.isInvestorsFounderManagementOwnedAvailable(
    investorsPeBackedLBO,
  );

  useEffect(() => {
    if (!isInvestorsPeBackedLBOAvailable) {
      setValue(ViewFilterName.PE, false);
    }
  }, [isInvestorsPeBackedLBOAvailable, setValue]);

  useEffect(() => {
    if (!isInvestorsFounderManagementOwnedAvailable) {
      setValue(ViewFilterName.MgmtOwned, false);
    }
  }, [isInvestorsFounderManagementOwnedAvailable, setValue]);
};
