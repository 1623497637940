import React from 'react';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { screensEstimatesInUseOptions } from './constants/screensEstimatesInUseOptions';
import ScreensInUse from '../../shared/pages/components/in-use/ScreensInUse';

const ScreensEstimatesInUse: React.FC = () => {
  return <ScreensInUse inUseType={ViewType.EstimatesInUse} options={screensEstimatesInUseOptions} />;
};

export default ScreensEstimatesInUse;
