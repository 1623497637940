import { useState, useCallback } from 'react';
import { PromiseStateData } from './types/PromiseStateData';
import { HandleFnOptions } from '../handle-fn/interfaces/HandleFnOptions';
import { useAsyncFn } from '../async-fn/useAsyncFn';
import { useAsyncFnEffect } from '../async-fn/useAsyncFnEffect';

export function usePromiseState<T>(callback: () => Promise<T>): PromiseStateData<T | undefined>;
export function usePromiseState<T>(callback: () => Promise<T>, defaultValue: T): PromiseStateData<T>;
export function usePromiseState<T>(
  callback: () => Promise<T>,
  defaultValue?: T,
  options?: HandleFnOptions,
): PromiseStateData<T | undefined> {
  const [otherwise, setOtherwise] = useState<T | undefined>(defaultValue);

  const loadDataFn = useCallback(async () => {
    const newData = await callback();
    setOtherwise(newData);
  }, [setOtherwise, callback]);

  const [handledLoadDataFn, isLoading] = useAsyncFn(loadDataFn, options);

  useAsyncFnEffect(handledLoadDataFn, [callback]);

  return [otherwise, isLoading];
}
