import React from 'react';
import Icon from '../../shared/components/icon/Icon';
import { IconName } from '../../shared/components/icon/IconName';
import { ControlSize } from '../../shared/enums/ControlSize';
import './NewsRecapResearches.scss';
import { NewsRecapResearchDto } from '../interfaces/NewsRecapResearchDto';
import { useClassName } from '../../shared/hooks/useClassName';
import DescriptionList, { DescriptionListItem } from '../../shared/components/DescriptionList';
import CompanyLink from '../../company/components/company-link/CompanyLink';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../shared/enums/RoutePath';

interface Props {
  researches: NewsRecapResearchDto[];
}

const NewsRecapResearches: React.FC<Props> = props => {
  const cn = useClassName('NewsRecapResearches');

  const hasResearch = (
    <ul className={cn()}>
      {props.researches ? (
        props.researches.map((research: NewsRecapResearchDto, index: number) => {
          const items: DescriptionListItem[] = [
            { term: 'Deal Size', definition: research.dealSize },
            { term: 'Market Cap', definition: research.marketCap },
            { term: 'Bookrunners', definition: research.bookrunners },
          ];

          return (
            <li className={cn('item')} key={index}>
              <CompanyLink className={cn('link')} ticker={research.tickerSymbol} visibleValue={research.companyName} />
              <CompanyLink
                className={cn('link')}
                ticker={research.tickerSymbol}
                visibleValue={
                  <>
                    {research.displayName}
                    <Icon name={IconName.Pdf} size={ControlSize.Small} />
                  </>
                }
              />

              <div className={cn('description')}>{research.busDescript}</div>

              <DescriptionList items={items} />
            </li>
          );
        })
      ) : (
        <li className={cn('item')} />
      )}
    </ul>
  );

  const noResearch = (
    <div className={cn('item')}>
      View our<Link to={RoutePath.ResearchDashboard}> Latest Research</Link>
    </div>
  );

  return <>{props.researches ? hasResearch : noResearch}</>;
};

export default NewsRecapResearches;
