import React from 'react';
import AdditionalStatsContentContainer from '../../shared/components/AdditionalStatsContentContainer';
import StatsElement from '../../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../../shared/entities/stats-element/enums/StatsElementType';
import { medianAgeStatsElementOptions } from './constants/medianAgeStatsElementOptions';
import AdditionalStatsPage from '../AdditionalStatsPage';

const MedianAge: React.FC = () => {
  return (
    <AdditionalStatsPage>
      <AdditionalStatsContentContainer title="Median Age of IPOs">
        <StatsElement type={StatsElementType.MedianAge} options={medianAgeStatsElementOptions} />
      </AdditionalStatsContentContainer>
    </AdditionalStatsPage>
  );
};

export default MedianAge;
