import { ViewOptions } from '../../../shared/entities/view/components/view/interfaces/options/ViewOptions';
import { FilterSectionType } from '../../../shared/entities/filters/enum/FilterSectionType';
import { ViewFilterName } from '../../../shared/entities/filters/enum/types/ViewFilterName';
import { FileActionType } from '../../../shared/components/file-actions/enums/FileActionType';
import { ViewSummaryAlignType } from '../../../shared/entities/view/components/view/enums/ViewSummaryAlignType';
import { ViewColumnSystemName } from '../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { GridHeightMultiplier } from '../../../shared/components/grid/grid/enums/GridHeightMultiplier';
import { pcwSubheaderHelper } from '../../constants/subHeaderHelpers';

export const privateCompanyWatchlistViewOptions: ViewOptions = {
  header: 'Private Company Watchlist',
  subHeader: pcwSubheaderHelper,
  toolbar: {
    modalFilters: {
      sections: [
        {
          type: FilterSectionType.Date,
          controls: [{ name: ViewFilterName.DateAddedToPCW }, { name: ViewFilterName.DateUpdateInPast }],
        },
        {
          type: FilterSectionType.DealStatus,
          controls: [
            { name: ViewFilterName.DealStatusAllActiveCompanies },
            { name: ViewFilterName.DealStatusConfidentialFilers },
            { name: ViewFilterName.DealStatusSelectedBanks },
            { name: ViewFilterName.DealStatusAcquiredOffPWC },
          ],
        },
        {
          type: FilterSectionType.CompanyInfo,
          controls: [
            { name: ViewFilterName.SectorIndustry },
            { name: ViewFilterName.EstimatedTiming },
            { name: ViewFilterName._Headquarters },
          ],
        },
        {
          type: FilterSectionType.Investors,
          controls: [
            { name: ViewFilterName.Investor },
            { name: ViewFilterName.SpinOff },
            { name: ViewFilterName.MgmtOwned },
            { name: ViewFilterName.PE },
            { name: ViewFilterName.PeGrowthOther },
            { name: ViewFilterName.VentureCapital },
          ],
        },
        {
          type: FilterSectionType.Favorite,
          controls: [{ name: ViewFilterName.Favorite }],
        },
        {
          type: FilterSectionType.Financials,
          controls: [{ name: ViewFilterName._EstimatedSales }],
        },
      ],
    },
    quickFilter: {
      hasPcmlNav: true,
    },
    fileActionTypes: [FileActionType.Save, FileActionType.Excel, FileActionType.Print],
  },
  grid: {
    idCols: [ViewColumnSystemName.CompanyKey],
    isFixedHeight: false,
    customColumnsSettings: [
      {
        field: ViewColumnSystemName.CompanyWithDescription,
        heightMultiplier: GridHeightMultiplier.Sx,
      },
    ],
  },
  summary: {
    align: ViewSummaryAlignType.Simple,
  },
};
