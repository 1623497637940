import { Context, createContext } from 'react';
import { EmailPreferenceDto } from '../form/interfaces/EmailPreferenceDto';
import { INITIAL_SECTIONS } from '../form/constants/sections';

export interface EmailPreferencesContextValue {
  sectionsData: EmailPreferenceDto;
  initialLoadedData: EmailPreferenceDto;
  loadAllSections: () => void;
  updateAllSections: () => void;
  setSectionsData: (value: EmailPreferenceDto) => void;
}

export const EmailPreferencesContext: Context<EmailPreferencesContextValue> = createContext<
  EmailPreferencesContextValue
>({
  initialLoadedData: INITIAL_SECTIONS,
  sectionsData: INITIAL_SECTIONS,
  setSectionsData: () => {},
  loadAllSections: () => {},
  updateAllSections: () => {},
});
