import React, { useState, useCallback, useMemo } from 'react';
import { PortionSize } from '../../../../enums/PortionSize';
import TextSelect, { TextSelectProps } from '../text-select/TextSelect';
import { CountControlService } from './services/CountControlService';
import { ControlDivider } from '../../services/control-divider/enums/ControlDivider';

interface Props extends Partial<TextSelectProps> {
  sizes: PortionSize[];
  initialValue?: PortionSize;
  onChange?: (size: PortionSize) => void;
}

const CountReducerControl: React.FC<Props> = props => {
  // eslint-disable-next-line
  const initialValue = useMemo(() => props.initialValue || CountControlService.getInitialValue(props.sizes), []);

  const [value, setValue] = useState(initialValue);
  const options = useMemo(() => CountControlService.getOptions(props.sizes), [props.sizes]);

  const onChange = useCallback(
    (value: PortionSize) => {
      setValue(value);
      props.onChange?.(value);
    },
    [props],
  );

  return (
    <TextSelect
      className={props.className}
      label="Top"
      value={value}
      divider={ControlDivider.Slash}
      options={options}
      onChangeValue={onChange}
    />
  );
};

export default CountReducerControl;
