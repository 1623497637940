import React from 'react';
import CompanyTooltip from '../../../../../../../company/components/company-tooltip/CompanyTooltip';
import './CompanyWithNotePopUpCell.scss';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';
import { useClassName } from '../../../../../../hooks/useClassName';
import CustomCellFormatterWrapper from '../../../../../../components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import { CompanyWithNoteColumn } from '../../interfaces/column-values/CompanyWithNoteColumn';
import { CompanyWithNotePopUpCellBlock, CompanyWithNotePopUpCellElement } from './CompanyWithNotePopUpCellBem';
import Tooltip from '../../../../../../components/tooltip/Tooltip';
import { ControlSize } from '../../../../../../enums/ControlSize';

type Props = GridCellFormatterCommonProps;

const CompanyWithNotePopUpCell: React.FC<Props> = props => {
  const cn = useClassName(CompanyWithNotePopUpCellBlock.Root);

  const value = props.data[ViewColumnSystemName.CompanyWithNotePopUp] as CompanyWithNoteColumn;
  const noteNumber = props.data[ViewColumnSystemName.NoteNumber];

  return (
    <CustomCellFormatterWrapper className={cn()} value={value}>
      <CompanyTooltip
        name={value.companyTicker}
        ticker={props.data[ViewColumnSystemName.Ticker]}
        reference={noteNumber}
      />
      {value.note && (
        <Tooltip
          className={cn()}
          isTitleHighlighted
          isUnderFixed={true}
          content={value.note}
          title={CompanyWithNotePopUpCellElement.Note}
          size={ControlSize.Large}
        />
      )}
    </CustomCellFormatterWrapper>
  );
};

export default CompanyWithNotePopUpCell;
