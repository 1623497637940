import React, { memo, useCallback, useEffect, useMemo } from 'react';
import './FormSelectOptionsSwitch.scss';
import { isEqual, isNil } from 'lodash';
import { CustomAny } from '../../../../types/generics';
import { useFormContext } from 'react-hook-form';
import { FormSelectOptionsSwitchService } from './services/FormSelectOptionsSwitchService';
import TextSelect from '../text-select/TextSelect';
import Select from './Select';
import { FormTextSelectProps } from '../../FormTextSelect';
import { FormSelectProps } from './FormSelect';
import { SelectOptionsData } from './types/SelectOptionsData';
import { BlockOrientation } from '../../../../enums/BlockOrientation';
import FormGroup from '../../FormGroup';
import { useClassName } from '../../../../hooks/useClassName';
import { FormSelectOptionsSwitchBlock, FormSelectOptionsSwitchElement } from '../formSelectOptionsSwitchBem';

export type FormSelectSwitchOptionsProps = {
  selectProps: FormSelectProps;
  textSelectsProps: FormTextSelectProps[];
  optionsData: SelectOptionsData;
  orientation?: BlockOrientation;
};

const FormSelectOptionsSwitch: React.FC<FormSelectSwitchOptionsProps> = props => {
  const { textSelectsProps = [], selectProps } = props;

  const cn = useClassName(FormSelectOptionsSwitchBlock.Root);

  const { setValue, watch, register } = useFormContext();

  const selectName = selectProps.name;
  const selectValue = watch(selectName);

  const selectSwitchValues: string[] = [];
  textSelectsProps.forEach(textSelectsProp => selectSwitchValues.push(watch(textSelectsProp.name)));
  const isSelectAvailable =
    textSelectsProps.length === selectSwitchValues.filter(selectSwitchValue => !isNil(selectSwitchValue)).length;

  const controlOptions = useMemo<CustomAny>(
    () => FormSelectOptionsSwitchService.getOptions(props.optionsData, selectSwitchValues),
    [selectSwitchValues, props.optionsData],
  );

  const onChangeTextSelect = useCallback(
    (index, value) => {
      setValue(textSelectsProps[index].name, value);
      setValue(selectName, null);
    },
    [setValue, textSelectsProps, selectName],
  );

  const onChangeSelect = useCallback(
    value => {
      setValue(selectName, value);
    },
    [setValue, selectName],
  );

  useEffect(() => {
    textSelectsProps.forEach(textSelectProps => register({ name: textSelectProps.name }));

    register({ name: selectName });
  }, [register, textSelectsProps, selectName]);

  return (
    <>
      {textSelectsProps.map((textSelectProps, index) => (
        <FormGroup key={textSelectProps.name} className={cn(FormSelectOptionsSwitchElement.Control)}>
          <TextSelect
            {...textSelectProps}
            value={selectSwitchValues[index]}
            onChangeValue={onChangeTextSelect.bind(onChangeTextSelect, index)}
          />
        </FormGroup>
      ))}

      {isSelectAvailable && (
        <FormGroup className={cn(FormSelectOptionsSwitchElement.Select)} hasNoIndentVertical>
          <Select
            {...props.selectProps}
            orientation={props.orientation}
            options={controlOptions}
            values={selectValue}
            onChange={onChangeSelect}
          />
        </FormGroup>
      )}
    </>
  );
};

export default memo(FormSelectOptionsSwitch, isEqual);
