import { memoize } from 'lodash';
import ApiService from './ApiService';
import { ApiPath } from './enums/ApiPath';
import { FiltersValues } from '../entities/filters/types/FiltersValues';
import { StatsElementDto } from '../entities/stats-element/interfaces/StatsElementDto';
import { StatsElementType } from '../entities/stats-element/enums/StatsElementType';
import { StatsPagesFiltersSettings } from '../../stats/stats/interfaces/StatsPagesFiltersSettings';
import { StatsElementChartDynamicCharts } from '../entities/stats-element/components/chart/enums/StatsElementChartDynamicCharts';
import { StatsElementChartDto } from '../entities/stats-element/components/chart/types/StatsElementChartDto';
import { SimpleGridProps } from '../components/grid/simple-grid/SimpleGrid';
import { ViewGridProps } from '../entities/view/components/view-grid/ViewGrid';
import { ApiResponseSuccessFileData } from '../types/api/ApiResponse';

export class StatsApiService {
  static getFiltersSettings(): Promise<StatsPagesFiltersSettings> {
    return ApiService.post(ApiPath.StatsFiltersInfo);
  }

  static getStatsChartData(
    chartName: StatsElementChartDynamicCharts,
    filters: FiltersValues,
  ): Promise<StatsElementChartDto> {
    return ApiService.post<StatsElementChartDto>(`${ApiPath.StatsChart}/${chartName}`, filters);
  }

  static getStatsElementData(statsType: StatsElementType, filters: FiltersValues): Promise<StatsElementDto> {
    return ApiService.post<StatsElementDto>(`${ApiPath.Stats}/${statsType}`, filters);
  }

  static async getExcelGrids(
    grids: Partial<SimpleGridProps | ViewGridProps>[],
    statsElementType: StatsElementType,
  ): Promise<ApiResponseSuccessFileData> {
    return ApiService.getFilePost(`${ApiPath.StatsExcel}/${statsElementType}`, grids);
  }
}

// Cached dictionaries
StatsApiService.getFiltersSettings = memoize(StatsApiService.getFiltersSettings.bind(StatsApiService));
