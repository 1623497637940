import { StepStatus } from '../enums/StepStatus';

export class StepperService {
  static getStepStatus(activeStep: number, index: number): StepStatus {
    if (index < activeStep) {
      return StepStatus.Passed;
    }

    if (index === activeStep) {
      return StepStatus.Active;
    }

    return StepStatus.NotPassed;
  }
}
