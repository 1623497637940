import * as yup from 'yup';
import { TalkToAnalystFields } from './TalkToAnalystFields';

export const validationSchema = yup.object().shape({
  [TalkToAnalystFields.QuestionAbout]: yup
    .string()
    .required()
    .nullable()
    .label('Question type'),
  [TalkToAnalystFields.QuestionToAnalyst]: yup
    .string()
    .required()
    .label('Comment'),
});
