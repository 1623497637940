import { NewsRecapDate } from '../../interfaces/NewsRecapDate';
import { usePromiseState } from '../../../shared/hooks/promise-state/usePromiseState';
import { NewsApiService } from '../../../shared/api/NewsApiService';
import { useCallback } from 'react';

export function useNewsRecapDate(): NewsRecapDate {
  const callback = useCallback(() => NewsApiService.getNewsRecapDate(), []);
  const [recapDate] = usePromiseState<NewsRecapDate>(callback, 'Read the Morning Recap');

  return recapDate;
}
