import { searchFilterControls } from '../../../../../shared/entities/filters/constants/filter-controls/searchFilterControls';
import { ViewFilterName } from '../../../../../shared/entities/filters/enum/types/ViewFilterName';
import { FiltersMapValue } from '../../../../../shared/entities/filters/components/filterable-component/interfaces/FiltersMapValue';
import { screensDealStatusPricedUpcoming } from '../../../../constants/controls/screensDealStatusControls';
import { SelectService } from '../../../../../shared/services/select/SelectService';
import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { TimeFrame } from '../../../../../shared/entities/filters/enum/TimeFrame';

export const screensIndustrySharedOptions: Partial<ScreenOptions> = {
  header: 'Industry Screen',
  search: {
    controls: [screensDealStatusPricedUpcoming, searchFilterControls[ViewFilterName.Industry] as FiltersMapValue],
  },
  toolbar: {
    quickFilter: {
      timeFrameOptions: [
        TimeFrame.Month,
        TimeFrame.Quarter,
        TimeFrame.YTD,
        TimeFrame.Year,
        TimeFrame.TwoYears,
        TimeFrame.FiveYears,
      ],
      hasTimeFrameRange: true,
      hasDateRange: true,
      hasLocation: true,
      hasIncludeAShares: true,
    },
  },
  screenTriggerOptions: {
    isInUseAvailable: filtersValues =>
      SelectService.hasMultiSelectValue(filtersValues[ViewFilterName.Industry] as string),
  },
};
