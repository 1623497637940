import React from 'react';
import classNames from 'classnames';
import './TooltipContentHeaderItem.scss';

interface Props {
  className?: string;
}

const TooltipContentHeaderItem: React.FC<Props> = props => {
  return <div className={classNames('TooltipContentHeaderItem', props.className)}>{props.children}</div>;
};

export default TooltipContentHeaderItem;
