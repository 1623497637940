// Private filter variable is started from _, used only for settings
export enum ViewFilterName {
  // Date
  DateAddedToPCW = 'NumDaysAdded_Max',
  DateUpdateInPast = 'NumDaysEdited_Max',
  //Deal status
  DealStatusDealStatusList = 'DealStatus',
  _DealStatusScheduledList = '_DealStatusScheduledList',
  _DealStatusFiledTermsList = '_DealStatusFiledTermsList',
  _DealStatusPostponedList = '_DealStatusPostponedList',
  DealStatusIn = 'DealStatus_In',
  DealStatusWithdrawnAcquiredPostponed = 'StatusWithAcqPos',
  DealStatusAllActiveCompanies = 'IsActive',
  DealStatusConfidentialFilers = 'IsConfFiled',
  DealStatusSelectedBanks = 'IsSelectedBanks',
  DealStatusAcquiredOffPWC = 'IsAcquired',
  DealStatusSelect = 'DealStatusSelect',
  // OfferDate
  _OfferDate = 'offerDate',
  OfferTimeFrame = 'timeframe',
  OfferStartDate = 'startDate',
  OfferEndDate = 'endDate',
  // File Date
  _FileDate = '_FileDate',
  FileDateTimeFrame = 'fileDateRange',
  FileDateStartDate = 'FileDate_Min',
  FileDateEndDate = 'FileDate_Max',
  // Deal timing
  _DealTimingFiledRange = '_DealTimingFiledRange',
  DealTimingFiledRangeStartDate = 'StartDate',
  DealTimingFiledRangeEndDate = 'EndDate',
  DealTimingFiledRangeTimeFrame = 'DateRange',
  DealTimingUpdatedInPast = 'DaysSinceLastMilestoneDate_Max',
  _DealTimingExpectedOfferTiming = '_DealTimingExpectedOfferTiming',
  DealTimingExpectedOfferStartDate = 'OfferMonthTimingSort_Min',
  DealTimingExpectedOfferEndDate = 'OfferMonthTimingSort_Max',
  // Company info
  Industry = 'Industry',
  SectorIndustry = 'GICS_List',
  EstimatedTiming = 'EstTiming',
  _Headquarters = '_Headquarters',
  Headquarter = 'Headquarters',
  HeadquartersCityStateCountry = 'HeadquartersCode',
  FoundedSince = 'YearFounded',
  EmergingGrowth = 'emergingGrowth',
  // Investors
  Investor = 'Investor',
  Ownership = 'OwnershipPct_Min',
  TopShareholder = 'TopShareholder',
  HistoryShareholders = 'ShareholderSearch',
  SpinOff = 'SpinOff',
  MgmtOwned = 'MgmtOwned',
  PE = 'PE',
  PeGrowthOther = 'PeGrowthOther',
  VentureCapital = 'VC',
  // Security Type
  SecurityTypeCommonSharesList = 'SecurityTypeCommonSharesList',
  SecurityTypeADRsList = 'SecurityTypeADRsList',
  SecurityTypeREITList = 'SecurityTypeREITList',
  SecurityTypeMLPList = 'SecurityTypeMLPList',
  SecurityTypeBlankCheckList = 'SecurityTypeBlankCheckList',
  SecurityTypeRegAList = 'SecurityTypeRegAList',
  SecurityTypeDirectListingList = 'SecurityTypeDirectListingList',
  // Returns
  _FirstDayReturn = 'FirstDayReturn',
  FirstDayReturnMax = 'FirstDayReturn_Max',
  FirstDayReturnMin = 'FirstDayReturn_Min',
  _AfterMarketReturn = 'AfterMarketReturn',
  AfterMarketReturnMax = 'AfterMarketReturn_Max',
  AfterMarketReturnMin = 'AfterMarketReturn_Min',
  _TotalReturn = 'TotalReturn',
  TotalReturnMax = 'TotalReturn_Max',
  TotalReturnMin = 'TotalReturn_Min',
  _ReturnsAnnualizedReturn = '_ReturnsAnnualizedReturn',
  ReturnsAnnualizedReturnMax = 'AnnualizedReturn_Max',
  ReturnsAnnualizedReturnMin = 'AnnualizedReturn_Min',
  // handled by backend side
  _InitialPerformance = '_InitialPerformance',
  InitialPerformancePeriod = 'Initial_Performance_Period',
  InitialPerformanceMin = 'Initial_Performance_Period_Min',
  InitialPerformanceMax = 'Initial_Performance_Period_Max',
  _RecentPerformance = '_RecentPerformance',
  RecentPerformancePeriod = 'Recent_Performance_Period',
  RecentPerformanceMin = 'Recent_Performance_Period_Min',
  RecentPerformanceMax = 'Recent_Performance_Period_Max',
  ReturnsCurrentPrice = 'stockPrice_Min',
  ReturnsCompTicker = 'compTicker',
  // Deal info
  _DealSize = 'DealSize',
  DealSizeMax = 'dealSize_Max',
  DealSizeMin = 'dealSize_Min',
  DealInfoPreIpoExchange = 'CurrentExchangeName',
  Underwriter = 'UnderwriterKey',
  UnderwriterKeyList = 'UnderwriterKey_List',
  UnderwriterRole = 'UnderwriterRole',
  UnderwriterTypeKey = 'UnderwriterTypeKey',
  InsiderBuying = 'InsiderBuying',
  IsCrossListing = 'IsCrossListing',
  IsUpListing = 'IsUpListing',
  IsDualListing = 'IsDualListing',
  InsiderSelling = 'InsiderSelling',
  InsiderSellingPct = 'InsiderSellingPct',
  TermsChange = 'IPOPriceVsRange',
  IPOPriceRange = 'IPOPriced',
  _IPOPricedRange = 'IPOPricedRange',
  IPOPricedMin = 'IPODiscount_Min',
  IPOPricedMax = 'IPODiscount_Max',
  _DealSizeVsExpected = 'DealSizeVsExpected',
  DealSizeVsExpectedMax = 'DealSizeVsExpected_Max',
  DealSizeVsExpectedMin = 'DealSizeVsExpected_Min',
  _DealSizeMarketCapVsExpected = '_DealSizeMarketVsExpected',
  DealSizeMarketCapVsExpectedMax = 'DealSizeMarketVsExpectedMax',
  DealSizeMarketCapVsExpectedMin = 'DealSizeMarketVsExpectedMin',
  _FloatToMarketCap = 'FloatToMarketCap',
  FloatToMarketCapMax = 'FloatToMarketCap_Max',
  FloatToMarketCapMin = 'FloatToMarketCap_Min',
  DealInfoDualClassSharesOnly = 'dualShareClass',
  Withdrawn = 'Withdrawn',
  Postponed = 'Postponed',
  Acquired = 'Acquired',
  // Location
  LocationListingLocation = 'LocationListingLocation',
  ExchangeCountry = 'ExchangeCountry',
  ExchangeRegion = 'ExchangeRegion',
  IncludeChina = 'IsChinaAShares',
  IncludeSpac = 'includeSpacs',
  LocationExchange = 'ExchangeName',
  LocationExchangeList = 'ExchangeName_List',
  // Favorite
  Favorite = 'FavoritesOnly',
  // Renaissance rating
  RcRating = 'RcRating',
  _RatingFundamentals = 'RatingFundamentals',
  RatingFundamentalsMax = 'RatingFundamentals_Max',
  RatingFundamentalsMin = 'RatingFundamentals_Min',
  _RatingCorpGov = 'RatingCorpGov',
  RatingCorpGovMax = 'RatingCorpGov_Max',
  RatingCorpGovMin = 'RatingCorpGov_Min',
  _RatingValuation = 'RatingValuation',
  RatingValuationMax = 'RatingValuation_Max',
  RatingValuationMin = 'RatingValuation_Min',
  _RatingTechnicals = 'RatingTechnicals',
  RatingTechnicalsMax = 'RatingTechnicals_Max',
  RatingTechnicalsMin = 'RatingTechnicals_Min',
  // Financials
  _RevenueGrowth = 'RevenueGrowth',
  RevenueGrowthMin = 'RevenueGrowth_Min',
  RevenueGrowthMax = 'RevenueGrowth_Max',
  RevenueGrowthPeriod = 'RevenueGrowthPeriod',
  _EstimatedSales = '_EstimatedSales',
  EstimatedSalesMax = 'EstSales_Max',
  EstimatedSalesMin = 'EstSales_Min',
  _LtmLatestSales = 'LtmLatestSales',
  LtmLatestSalesMax = 'LtmLatestSales_Max',
  LtmLatestSalesMin = 'LtmLatestSales_Min',
  _LtmGrossMargin = 'LtmGrossMargin',
  LtmGrossMarginMax = 'LtmGrossMargin_Max',
  LtmGrossMarginMin = 'LtmGrossMargin_Min',
  _LtmEbitdaMargin = 'LtmEbitdaMargin',
  LtmEbitdaMarginMax = 'LtmEbitdaMargin_Max',
  LtmEbitdaMarginMin = 'LtmEbitdaMargin_Min',
  _LtmNetMargin = 'LtmNetMargin',
  LtmNetMarginMax = 'LtmNetMargin_Max',
  LtmNetMarginMin = 'LtmNetMargin_Min',
  _FinancialsLTMCashFlowFromOperation = '_FinancialsLTMCashFlowFromOperation',
  FinancialsLTMCashFlowFromOperationMin = 'LtmCashFlowFromOps_Min',
  FinancialsLTMCashFlowFromOperationMax = 'LtmCashFlowFromOps_Max',
  _FinancialsLTMCapex = '_FinancialsLTMCapex',
  FinancialsLTMCapexMin = 'LtmCapEx_Min',
  FinancialsLTMCapexMax = 'LtmCapEx_Max',
  _FinancialsLTMFreeCashFlow = '_FinancialsLTMFreeCashFlow',
  FinancialsLTMFreeCashFlowMin = 'LtmFreeCashFlow_Min',
  FinancialsLTMFreeCashFlowMax = 'LtmFreeCashFlow_Max',
  _IpoDebtToEbitda = 'IpoDebtToEbitda',
  IpoDebtToEbitdaMax = 'IpoDebtToEbitda_Max',
  IpoDebtToEbitdaMin = 'IpoDebtToEbitda_Min',
  MostRecentFinancialsDateMin = 'MostRecentFinancialsDate_Min',
  MostRecentFinancialsDateMax = 'MostRecentFinancialsDate_Max',
  Dividend = 'Dividend',
  _FinancialsPostIpoCash = '_FinancialsPostIpoCash',
  FinancialsPostIpoCashMin = 'PostIpoCash_Min',
  FinancialsPostIpoCashMax = 'PostIpoCash_Max',
  _FinancialsPostIpTotalAssets = '_FinancialsPostIpoTotalAssets',
  FinancialsPostIpoTotalAssetsMin = 'PostIpoTotalAssets_Min',
  FinancialsPostIpoTotalAssetsMax = 'PostIpoTotalAssets_Max',
  _FinancialsPostIpoTotalDebt = '_FinancialsPostIpoTotalDebt',
  FinancialsPostIpoTotalDebtMin = 'PostIpoTotalDebt_Min',
  FinancialsPostIpoTotalDebtMax = 'PostIpoTotalDebt_Max',
  _FinancialsPostIpoEquity = '_FinancialsPostIpoEquity',
  FinancialsPostIpoEquityMin = 'PostIpoEquity_Min',
  FinancialsPostIpoEquityMax = 'PostIpoEquity_Max',
  // Valuation
  _MarketCapAtIPO = 'MarketCapAtIPO',
  MarketCapAtIPOMax = 'MarketCapAtIPO_Max',
  MarketCapAtIPOMin = 'MarketCapAtIPO_Min',
  _Valuation = 'Valuation',
  ValuationMax = 'Valuation_Max',
  ValuationMin = 'Valuation_Min',
  _ValuationEnterpriseValueAtIPO = '_ValuationEnterpriseValueAtIPO',
  ValuationEnterpriseValueAtIPOMax = 'EvAtIpo_Max',
  ValuationEnterpriseValueAtIPOMin = 'EvAtIpo_Min',
  _IpoEvToLtmSales = 'IpoEvToLtmSales',
  IpoEvToLtmSalesMax = 'IpoEvToLtmSales_Max',
  IpoEvToLtmSalesMin = 'IpoEvToLtmSales_Min',
  _IpoEvToEbitda = 'IpoEvToEbitda',
  IpoEvToEbitdaMax = 'IpoEvToEbitda_Max',
  IpoEvToEbitdaMin = 'IpoEvToEbitda_Min',
  _IpoPe = 'IpoPe',
  IpoPeMax = 'IpoPe_Max',
  IpoPeMin = 'IpoPe_Min',
  _IpoPb = 'IpoPb',
  IpoPbMax = 'IpoPb_Max',
  IpoPbMin = 'IpoPb_Min',
  //WithdrawnPostponed
  WithdrawnPostponed = 'WithdrawnPostponed',
  Type = 'type',
  ViewType = 'ViewType',
}
