import React from 'react';
import './AnimatedList.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { CustomAny } from '../../../types/generics';

// Exist on page for `TIME_TO_STAY` before it finally show/hide
// Should be bigger than animation time
const TIME_TO_STAY = 500;

const AnimatedList: React.FC = props => {
  return (
    <TransitionGroup className="AnimatedList">
      {React.Children.map<CustomAny, CustomAny>(props.children, child => (
        <CSSTransition key={child.key} timeout={TIME_TO_STAY} classNames="AnimatedList__item">
          {child}
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default AnimatedList;
