import { TimeFrame } from '../../enum/TimeFrame';
import { SelectOption } from '../../../../services/select/interfaces/SelectOption';

export const timeFramesOptions: SelectOption[] = [
  { title: 'Day', value: TimeFrame.Day },
  { title: 'Week', value: TimeFrame.Week },
  { title: 'Month', value: TimeFrame.Month },
  { title: 'Wk', value: TimeFrame.altWeek },
  { title: 'Mo', value: TimeFrame.altMonth },
  { title: '90 Days', value: TimeFrame.Quarter },
  { title: 'YTD', value: TimeFrame.YTD },
  { title: 'Last Fiscal Year', value: TimeFrame.LastFiscalYear },
  { title: 'Upcoming Fiscal Year', value: TimeFrame.UpcomingFiscalYear },
  { title: '1 Year', value: TimeFrame.Year },
  { title: '2 Years', value: TimeFrame.TwoYears },
  { title: '5 Years', value: TimeFrame.FiveYears },
  { title: 'This Week', value: TimeFrame.ThisWeek },
  { title: 'Next Week', value: TimeFrame.NextWeek },
  { title: 'All', value: TimeFrame.All },
  { title: 'From IPO', value: TimeFrame.FromIPO },
  { title: '5 Days', value: TimeFrame.FiveDays },
  { title: 'Total', value: TimeFrame.Total },
  { title: 'First Day', value: TimeFrame.FirstDay },
  { title: 'Aftermarket', value: TimeFrame.Aftermarket },
  { title: 'Above Issue', value: TimeFrame.AboveIssue },
  { title: 'LTM', value: TimeFrame.LTM },
  { title: '10 Days', value: TimeFrame.TenDays },
];
