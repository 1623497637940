import { createContext, Dispatch } from 'react';
import { ActionTypes, ViewState } from './store/types';
import { Thunk } from 'react-hook-thunk-reducer';
import { createInitialState } from './store/reducer';
import { ViewType } from './enums/ViewType';
import { pricedViewOptions } from '../../../../../views/priced/constants/pricedViewOptions';

export interface ViewContextValue {
  state: ViewState;
  dispatch: Dispatch<ActionTypes | Thunk<ViewState, ActionTypes>>;
}

export const ViewContext = createContext<ViewContextValue>({
  dispatch: () => {},
  state: createInitialState(ViewType.Priced, -1, pricedViewOptions),
});
