import React from 'react';
import './RatingsSystemColumns.scss';

interface Props {
  firstColumn: string[];
  secondColumn: string[];
}

const RatingsSystemColumns: React.FC<Props> = props => {
  const getColumn = (items: string[]): React.ReactNode => {
    return items.map((item: string, index: number) => (
      <div key={index} className="RatingsSystemColumns__row">
        {item}
      </div>
    ));
  };

  return (
    <div className="RatingsSystemColumns">
      <div className="RatingsSystemColumns__column">{getColumn(props.firstColumn)}</div>

      <div className="RatingsSystemColumns__column">{getColumn(props.secondColumn)}</div>
    </div>
  );
};

export default RatingsSystemColumns;
