import React from 'react';
import FilterControl from '../FilterControl';
import { ViewFilterName } from '../../../../enum/types/ViewFilterName';
import FilterSectionsAdvancedControls from '../FilterSectionsAdvancedControls';

const ValuationFilterSection: React.FC = () => {
  return (
    <>
      <FilterControl name={ViewFilterName._MarketCapAtIPO} />

      <FilterControl name={ViewFilterName._Valuation} />

      <FilterControl name={ViewFilterName._ValuationEnterpriseValueAtIPO} />

      <FilterSectionsAdvancedControls>
        <FilterControl name={ViewFilterName._IpoEvToLtmSales} />

        <FilterControl name={ViewFilterName._IpoEvToEbitda} />

        <FilterControl name={ViewFilterName._IpoPe} />

        <FilterControl name={ViewFilterName._IpoPb} />
      </FilterSectionsAdvancedControls>
    </>
  );
};

export default ValuationFilterSection;
