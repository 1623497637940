import React from 'react';
import { useClassName } from '../../../../../../shared/hooks/useClassName';
import { DilutedSharesTitleWrapperBlock, DilutedSharesTitleWrapperElement } from './DilutedSharesTitleWrapperBem';
import './DilutedSharesTitleWrapper.scss';

const DilutedSharesTitleWrapper: React.FC = props => {
  const cn = useClassName(DilutedSharesTitleWrapperBlock.Root);

  return (
    <div>
      <div className={cn(DilutedSharesTitleWrapperElement.Content)}>{props.children}</div>
    </div>
  );
};

export default DilutedSharesTitleWrapper;
