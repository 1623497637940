import React from 'react';
import './HelpListItem.scss';
import { IconName } from '../shared/components/icon/IconName';
import { RoutePath } from '../shared/enums/RoutePath';
import Icon from '../shared/components/icon/Icon';
import Button from '../shared/components/forms/Button';
import { ColorScheme } from '../shared/enums/ColorScheme';
import { ControlSize } from '../shared/enums/ControlSize';

interface Props {
  icon: IconName;
  title: string;
  buttonTitle: string;
  buttonRoutePath: RoutePath;
}

const HelpListItem: React.FC<Props> = props => {
  return (
    <div className="HelpListItem">
      <Icon name={props.icon} size={ControlSize.ExtraLarge} />

      <h4 className="HelpListItem__title">{props.title}</h4>

      <Button
        className="HelpListItem__button"
        buttonLink={{ routePath: props.buttonRoutePath }}
        colorScheme={ColorScheme.Primary}
      >
        {props.buttonTitle}
      </Button>
    </div>
  );
};

export default HelpListItem;
