import { NewsItemTag } from '../interfaces/NewsItemTag';
import { NewsTagModifier } from '../enums/NewsTagModifier';
import { NewsListItem } from '../components/interfaces/NewsListItem';
import { CompanyLocationService } from '../../company/services/CompanyLocationService';

export function getNewsCompanyProfileTag(item: NewsListItem): NewsItemTag | undefined {
  // In accordance with application mockup and task https://jira.itransition.com/browse/RENCAP-355

  if (item.tickerSymbol) {
    return {
      title: item.tickerSymbol,
      modifier: NewsTagModifier.Orange,
      url: CompanyLocationService.getRelativePath(item.tickerSymbol),
    };
  }
}
