import { ViewSharedLink } from '../../interfaces/api/ViewSharedLink';
import { SET_VIEW_SHARED_LINKS, SetViewSharedLinks } from '../types';

export const setViewSharedLinks = (sharedLinks: ViewSharedLink[]): SetViewSharedLinks => {
  return {
    type: SET_VIEW_SHARED_LINKS,
    payload: {
      sharedLinks,
    },
  };
};
