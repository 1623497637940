import React, { useContext, useMemo } from 'react';
import './CompanyOverview.scss';
import useBreakpointAvailable from '../../../../../../shared/effects/useBreakpointAvailable';
import { profileMobileBreakpoints } from '../../constants/profileMobileBreakpoints';
import { CompanyContext } from '../../../../context/CompanyContext';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import CompanyOverviewExtraHeader from './CompanyOverviewExtraHeader';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import CompanyParagraph from '../../../../shared/CompanyParagraph';
import ElementsHeightExpander from '../../../../../../shared/components/expander/ElementsHeightExpander';
import HtmlStringFormatter from '../../../../../../shared/components/HtmlStringFormatter';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import Loader from '../../../../../../shared/components/loader/Loader';

const VISIBLE_HIDDEN_HEIGHT = 100;

const CompanyOverview: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const isPrint = useSelector<RootState, boolean>(state => state.profilePrint.isPrint);

  const data = useMemo(() => profile.overview, [profile]);
  const companyLogo = useMemo(() => profile.description?.companyLogo, [profile]);

  const isMobile = useBreakpointAvailable(profileMobileBreakpoints);

  const companyOverview = data?.companyOverview;
  const overviewSource = data?.source;
  const content = useMemo(() => companyOverview && <HtmlStringFormatter content={companyOverview} />, [
    companyOverview,
  ]);

  /* const actions: FileActionProp[] = [
    {
      type: FileActionType.Print,
    },
  ];

  const ActionClickFn = useCallback(
    (actionType: FileActionType) => {
      // When called, it will print profile from CompanyTabsContent.tsx
      if (actionType === FileActionType.Print) {
        dispatch(setIsPrint(true));
      }
    },
    [dispatch],
  ); */

  return (
    <>
      {companyOverview && (
        <CompanyProfileSideItem
          className="CompanyOverview"
          title="Company Overview"
          actions={isPrint ? <Loader /> : <CompanyOverviewExtraHeader />}
        >
          {companyLogo && <img className="CompanyOverview__logo" src={companyLogo} alt="" />}

          <CompanyParagraph
            text={
              isMobile ? (
                <>
                  <ElementsHeightExpander visibleHiddenHeight={VISIBLE_HIDDEN_HEIGHT}>{content}</ElementsHeightExpander>
                  {overviewSource ? (
                    <>
                      <br />
                      <p className="OverviewSource">Source: {overviewSource} </p>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <>
                  {content}
                  {overviewSource ? <p className="OverviewSource">Source: {overviewSource} </p> : ''}
                </>
              )
            }
          />
        </CompanyProfileSideItem>
      )}
    </>
  );
};

export default CompanyOverview;
