import { ReactNode, ReactNodeArray } from 'react';
import reactStringReplace from 'react-string-replace';
import escapeStringRegexp from 'escape-string-regexp';

export class TemplateService {
  // For now working only with this template: {text}
  public static replace(str: string, values: (number | string)[]): string {
    return values.reduce<string>(
      (replacedStr: string, value: number | string) => this.replaceSingle(replacedStr, value),
      str,
    );
  }

  public static replaceSingle(str: string, value: number | string): string {
    return str.replace(/\{\S*\}/, value.toString());
  }

  public static replaceElementToReactElement(
    str: string,
    onMapElement: (elementValue: string) => ReactNode,
  ): ReactNodeArray {
    return this.replaceElements<ReactNodeArray>(str, (replacedData, regExp, elementValue) => {
      return reactStringReplace(replacedData, regExp, () => onMapElement(elementValue));
    });
  }

  public static replaceElementToString(str: string, onMapElement: (elementValue: string) => string): string {
    return this.replaceElements<string>(str, (replacedData, regExp, elementValue) => {
      return replacedData.replace(regExp, onMapElement(elementValue));
    });
  }

  private static replaceElements<T>(
    str: string,
    replaceFn: (replacedData: T, regExp: RegExp, elementValue: string) => T,
  ): T {
    const elements = this.getElements(str);
    let result: T = (str as unknown) as T;

    if (elements) {
      elements.forEach(element => {
        const regExp = new RegExp(`(${escapeStringRegexp(element)})`, 'g');
        const elementValue = this.removeElementWrap(element);

        result = replaceFn(result, regExp, elementValue);
      });
    }

    return result;
  }

  // For now working only with this template: [text]
  private static getElements(str: string): RegExpMatchArray | null {
    const regExp = /\[(.*?]*)\]/g;
    return str.match(regExp);
  }

  private static removeElementWrap(element: string): string {
    return element.replace(/\[|\]/g, '');
  }
}
