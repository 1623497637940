import React, { useCallback, useEffect } from 'react';
import './TimeFrameSelect.scss';
import { useFormContext } from 'react-hook-form';
import { TimeFrame } from '../../entities/filters/enum/TimeFrame';
import TimeFrameSelect, { TimeFrameSelectProps } from './TimeFrameSelect';
import { useLocation } from 'react-router-dom';

export interface FormTimeFrameSelectProps extends Partial<TimeFrameSelectProps> {
  timeFrameName?: string;
  minName?: string;
  maxName?: string;
}

const FormTimeFrameSelect: React.FC<FormTimeFrameSelectProps> = props => {
  const { setValue, register, watch } = useFormContext();
  const min = props.minName && watch(props.minName);
  const max = props.maxName && watch(props.maxName);
  const timeFrame = props.timeFrameName && watch(props.timeFrameName);
  const urlLocation = useLocation();
  const pathName = urlLocation.pathname;

  useEffect(() => {
    props.timeFrameName && register({ name: props.timeFrameName });
    props.minName && register({ name: props.minName });
    props.maxName && register({ name: props.maxName });
  }, [register, props.timeFrameName, props.minName, props.maxName]);

  const onTimeFrameChange = useCallback(
    (value: TimeFrame) => {
      // Updating value for Screens filters to not to fallback to default if timeframe is updated
      register({ name: 'timeFrameUpdated' });
      setValue('timeFrameUpdated', true);

      // Storing timeFrame value for Quick Filters when jumping back between Priced and Upcoming
      const selectedTimeFrameKey = `${pathName}-selected-time-frame`;
      sessionStorage.setItem(selectedTimeFrameKey, value);

      // For Scheduled Filter's we delete the timeframe key from filters when minDate and maxDate are entered
      // So, adding back timeframe for those cases
      if (props.timeFrameName && !watch(props.timeFrameName)) {
        register({ name: props.timeFrameName });
      }

      props.timeFrameName && setValue(props.timeFrameName, value);

      props.minName && setValue(props.minName, null);
      props.maxName && setValue(props.maxName, null);
    },
    [props.timeFrameName, props.minName, props.maxName, setValue],
  );

  const onDateRangeChange = useCallback(
    (minValue, maxValue) => {
      // When set undefined then watch return previous field value
      // Set as fix 'null'
      // Need to deep investigation
      props.timeFrameName && setValue(props.timeFrameName, null);

      if (props.minName && props.maxName) {
        setValue(props.minName, minValue);
        setValue(props.maxName, maxValue);
      }
    },
    [props.timeFrameName, props.minName, props.maxName, setValue],
  );

  return (
    <TimeFrameSelect
      maxValue={max}
      minValue={min}
      timeFrameValue={timeFrame}
      timeFrames={props.timeFrames as TimeFrame[]}
      onTimeFrameChange={onTimeFrameChange}
      onDateRangeChange={onDateRangeChange}
      {...props}
    />
  );
};

FormTimeFrameSelect.defaultProps = {
  minPlaceholder: 'Start Date',
  maxPlaceholder: 'End Date',
};

export default FormTimeFrameSelect;
