import React, { Component } from 'react';
import { GridCellFormatterCommonProps, GridCellFormatterContainerProps } from '../../../interfaces/gridCellFormatter';
import CustomCellFormatterWrapper from './CustomCellFormatterWrapper';

interface Props extends GridCellFormatterCommonProps, GridCellFormatterContainerProps {}

class ContainerCellFormatter extends Component<Props> {
  render() {
    return (
      <CustomCellFormatterWrapper value={this.props.value}>
        {this.props.valueFormatter
          ? this.props.valueFormatter({
              value: this.props.value,
              data: this.props.data,
            })
          : this.props.value}
      </CustomCellFormatterWrapper>
    );
  }
}

export default ContainerCellFormatter;
