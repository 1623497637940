import { useMemo } from 'react';
import { ViewGridDataService } from '../services/ViewGridDataService';
import { GridAgColumn } from '../../../../../components/grid/grid/interfaces/GridAgColumn';
import { ViewGridColumnsSetupOptions } from '../interfaces/ViewGridColumnsSetupOptions';
import { ViewGridColumnOptions } from '../types/ViewGridColumnOptions';
import { GridRow } from '../../../../../components/grid/shared/types/GridRow';
import useBreakpointAvailable from '../../../../../effects/useBreakpointAvailable';
import { GRID_COLUMNS_PIN_BREAKPOINTS } from '../../../../../components/grid/grid/constants/gridColumnsPinBreakpoints';
import { ViewColumnSystemName } from '../enums/ViewColumnSystemName';
import { ViewGridSingleColumnFactory } from '../factories/ViewGridSingleColumnFactory';
import { viewGridColumns } from '../constants/viewGridColumns';
import { ViewGridColumnDto } from '../interfaces/ViewGridColumnDto';

const tickerColumn = ViewGridSingleColumnFactory.getColumn(
  viewGridColumns[ViewColumnSystemName.Ticker] as ViewGridColumnDto,
);
const COLUMN_NAMES_TO_SHOW_TICKER = [
  ViewColumnSystemName.CompanyTicker,
  ViewColumnSystemName.CompanyWithDescription,
  ViewColumnSystemName.CompanyName,
];

function useColumnsWithPinTicker(
  columns: GridAgColumn[],
  width?: number,
  widgetType?: string,
): [GridAgColumn[], boolean] {
  let shouldPinColumns = useBreakpointAvailable(GRID_COLUMNS_PIN_BREAKPOINTS);
  if (widgetType === 'DashboardUpcomingIPOsWidget' || widgetType === 'Priced') {
    if (width && width < 400) {
      shouldPinColumns = true;
    }
  } else {
    if (width && width < 350) {
      shouldPinColumns = true;
    }
  }
  return useMemo(() => {
    const hasTickerPinColumn = columns.some(column =>
      COLUMN_NAMES_TO_SHOW_TICKER.includes(column.field as ViewColumnSystemName),
    );

    if (shouldPinColumns && hasTickerPinColumn) {
      const isTickerColumnExists = columns.some(column => column.headerName === ViewColumnSystemName.Ticker);

      if (isTickerColumnExists) {
        return [columns, shouldPinColumns];
      }

      return [[tickerColumn, ...columns], shouldPinColumns];
    }

    return [columns, shouldPinColumns && hasTickerPinColumn];
  }, [columns, shouldPinColumns]);
}

export function useViewSimpleGridColumns(
  columnsOptions: ViewGridColumnOptions[] = [],
  rows: GridRow[] = [],
  width?: number,
  widgetType?: string,
  setupOptions?: ViewGridColumnsSetupOptions,
): [GridAgColumn[], boolean] {
  let columns = useMemo(
    () => ViewGridDataService.getColumnsFromViewColumnOptions(columnsOptions, rows, setupOptions),
    // remove props.sort from dependency array due to ag-grid store this state
    // eslint-disable-next-line
    [columnsOptions, rows, window.innerWidth, width],
  );

  columns = useColumnsWithPinTicker(columns, width, widgetType)[0];
  const isTickerPinned = useColumnsWithPinTicker(columns, width, widgetType)[1];

  columns = columns.filter(column => {
    return column.hide !== true;
  });

  const filteredArr = columns.reduce(
    (acc, current) => {
      const x = acc.find(item => item.field === current.field);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    },
    [columns[0]],
  );

  return [filteredArr, isTickerPinned];
}
