import React, { useCallback, useState } from 'react';
import './DashboardIPOsOnMoveWidget.scss';
import { ControlSize } from '../../../../shared/enums/ControlSize';
import TabsContainer, { TabsType } from '../../../../shared/components/tabs/TabsContainer';
import Tab from '../../../../shared/components/tabs/Tab';
import { ColorScheme } from '../../../../shared/enums/ColorScheme';
import Icon from '../../../../shared/components/icon/Icon';
import { IconName } from '../../../../shared/components/icon/IconName';
import DashboardIPOsOnMoveWidgetToolbar from './DashboardIposOnMoveWidgetToolbar';
import { DashboardWidgetComponent } from '../../../interfaces/DashboardWidgetComponent';
import { DashboardIPOsOnMoveDto } from '../../../types/DashboardIPOsOnMoveDto';
import { DashboardIPOsOnMoveTimeFrame } from './types/DashboardIPOsOnMoveTimeFrame';
import { DashboardIPOsOnMoveStatusDto } from '../../../interfaces/DashboardIPOsOnMoveStatusDto';
import DashboardTable from '../shared/DashboardTable';
import { useClassName } from '../../../../shared/hooks/useClassName';
import { DashboardIPOsOnMoveWidgetBlock, DashboardIPOsOnMoveWidgetElement } from './dashboardIPOsOnMoveWidgetBem';
import DashboardTabsWidget from '../shared/DashboardTabsWidget';
import { TimeFrame } from '../../../../shared/entities/filters/enum/TimeFrame';

const DashboardIPOsOnMoveWidget: DashboardWidgetComponent<DashboardIPOsOnMoveDto> = props => {
  const { data } = props;

  const cn = useClassName(DashboardIPOsOnMoveWidgetBlock.Root);

  const [activeItem, setActiveItem] = useState(ColorScheme.Success);
  const [activeData, setActiveData] = useState<DashboardIPOsOnMoveStatusDto>();

  const onSelect = useCallback(activeItem => setActiveItem(activeItem as ColorScheme), []);

  const onFilter = useCallback(
    (timeFrame: DashboardIPOsOnMoveTimeFrame) => {
      if (timeFrame === TimeFrame.altMonth) {
        timeFrame = TimeFrame.Month;
      }
      if (timeFrame === TimeFrame.altWeek) {
        timeFrame = TimeFrame.Week;
      }
      setActiveData(data[timeFrame]);
    },
    [data],
  );
  return (
    <DashboardTabsWidget className={cn()}>
      <TabsContainer type={TabsType.Tab} colorScheme={activeItem} size={ControlSize.ExtraSmall} onSelect={onSelect}>
        <Tab
          eventKey={ColorScheme.Success}
          title={
            <>
              Winners <Icon name={IconName.Increase} size={ControlSize.ExtraSmall} />
            </>
          }
        >
          {activeData && (
            <DashboardTable
              width={props.dimensions?.width}
              dataTable={activeData.winners}
              colorScheme={ColorScheme.Success}
              widgetType={'IPOsOnMove'}
              pinTickerColumn
            />
          )}
        </Tab>

        <Tab
          eventKey={ColorScheme.Danger}
          title={
            <>
              Losers <Icon name={IconName.Decrease} size={ControlSize.ExtraSmall} />
            </>
          }
        >
          {activeData && (
            <DashboardTable
              width={props.dimensions?.width}
              dataTable={activeData.losers}
              colorScheme={ColorScheme.Danger}
              pinTickerColumn
            />
          )}
        </Tab>

        <Tab
          title={
            <DashboardIPOsOnMoveWidgetToolbar
              className={cn(DashboardIPOsOnMoveWidgetElement.Toolbar)}
              onFilter={onFilter}
              widgetWidth={props.dimensions?.width}
            />
          }
        />
      </TabsContainer>
    </DashboardTabsWidget>
  );
};

export default DashboardIPOsOnMoveWidget;
