import React, { useContext } from 'react';
import FilterControl from '../../FilterControl';
import { ViewFilterName } from '../../../../../enum/types/ViewFilterName';
import { useFormContext } from 'react-hook-form';
import FilerControlChildren from '../../FilerControlChildren';
import { FilterSectionsFormContext } from '../../../context/FilterSectionsFormContext';

const UnderwriterControls: React.FC = () => {
  const { dictionaries } = useContext(FilterSectionsFormContext);
  const formContext = useFormContext();
  const { watch } = formContext;

  const hasUnderwriter = !!watch(ViewFilterName.UnderwriterKeyList);

  return (
    <>
      <FilterControl
        name={ViewFilterName.UnderwriterKeyList}
        controlProps={{
          options: dictionaries?.[ViewFilterName.UnderwriterKeyList],
          isMultiSelect: true,
        }}
      />

      <FilerControlChildren hasChildren={hasUnderwriter}>
        <FilterControl name={ViewFilterName.UnderwriterRole} />

        <FilterControl
          name={ViewFilterName.UnderwriterTypeKey}
          controlProps={{
            options: dictionaries?.[ViewFilterName.UnderwriterTypeKey],
          }}
        />
      </FilerControlChildren>
    </>
  );
};

export default UnderwriterControls;
