import { StatsElementOptions } from '../../../../../shared/entities/stats-element/interfaces/StatsElementOptions';

const AVERAGE_AGE_TOTALS_GRID_INDEX = 0;
const AVERAGE_AGE_TOTAL_GRID_INDEX = 1;

export const averageAgeStatsElementOptions: StatsElementOptions = {
  chartsOptions: [
    {
      optionsList: [
        {
          yAxis: {
            title: {
              text: 'Average Company Age (years)',
            },
          },
        },
      ],
    },
  ],
  gridOptions: {
    [AVERAGE_AGE_TOTALS_GRID_INDEX]: {
      isHighlightLastRow: true,
      // Revert when filters will be integrated
      /*      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(() => ({
        viewType: ViewType.CustomInUse,
        startDateFilterName: ViewFilterName.OfferStartDate,
        endDateFilterName: ViewFilterName.OfferEndDate,
      })),*/
    },
    [AVERAGE_AGE_TOTAL_GRID_INDEX]: {},
  },
};
