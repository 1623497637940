import React, { useContext } from 'react';
import FilterControl from '../FilterControl';
import { ViewFilterName } from '../../../../enum/types/ViewFilterName';
import FilterSectionsAdvancedControls from '../FilterSectionsAdvancedControls';
import { FilterSectionsFormContext } from '../../context/FilterSectionsFormContext';

const ReturnsFilterSection: React.FC = () => {
  const { dictionaries } = useContext(FilterSectionsFormContext);

  return (
    <>
      <FilterControl name={ViewFilterName._FirstDayReturn} />

      <FilterControl name={ViewFilterName._AfterMarketReturn} />

      <FilterControl name={ViewFilterName._TotalReturn} />

      <FilterControl name={ViewFilterName._ReturnsAnnualizedReturn} />

      <FilterSectionsAdvancedControls>
        <FilterControl
          name={ViewFilterName._InitialPerformance}
          controlProps={{
            selectOptions: dictionaries?.[ViewFilterName.InitialPerformancePeriod],
          }}
        />

        <FilterControl
          name={ViewFilterName._RecentPerformance}
          controlProps={{
            selectOptions: dictionaries?.[ViewFilterName.RecentPerformancePeriod],
          }}
        />
      </FilterSectionsAdvancedControls>

      <FilterControl name={ViewFilterName.ReturnsCurrentPrice} />

      <FilterControl name={ViewFilterName.ReturnsCompTicker} />
    </>
  );
};

export default ReturnsFilterSection;
