import React, { ReactNode, useMemo } from 'react';
import { memoize } from 'lodash';
import CompanyTooltipContent from './CompanyTooltipContent';
import TooltipAsync from '../../../shared/components/tooltip/TooltipAsync';
import { CompanyApiService } from '../../../shared/api/CompanyApiService';
import Reference from '../../../shared/components/typography/Reference';
import { ControlSize } from '../../../shared/enums/ControlSize';
import CompanyLink from '../company-link/CompanyLink';
import CompanyTitleWrapper from './CompanyTitleWrapper';
import { FavouriteProps } from '../../../shared/components/favourites/Favourite';
import { CompanyRatingValueDto } from '../company-rating/types/CompanyRatingValueDto';
import { useExpand } from '../../../shared/hooks/expand/useExpand';

export interface CompanyTooltipProps {
  name: string;
  ticker: string;
  favouriteProps?: FavouriteProps;
  reference?: ReactNode;
  ratingText?: CompanyRatingValueDto;
  isNameShort?: boolean;
  isTitleEllipsis?: boolean;
  additionalInfoHtml?: string;
}

const NAME_LENGTH = 6;

const CompanyTooltip: React.FC<CompanyTooltipProps> = props => {
  const { expandedData: companyNameShorten } = useExpand(props.name, NAME_LENGTH);

  const companyName = useMemo(() => (props.isNameShort ? companyNameShorten : props.name), [
    companyNameShorten,
    props.name,
    props.isNameShort,
  ]);

  return (
    <CompanyTitleWrapper
      favouriteProps={props.favouriteProps}
      ratingText={props.ratingText}
      additionalInfoHtml={props.additionalInfoHtml}
      isEllipsis={props.isTitleEllipsis}
    >
      <TooltipAsync
        title={
          <CompanyLink
            ticker={props.ticker}
            visibleValue={
              <>
                {companyName}
                {props.reference && <Reference>{props.reference}</Reference>}
              </>
            }
          />
        }
        component={CompanyTooltipContent}
        size={ControlSize.Large}
        hasIndentHorizontal={false}
        getDataFn={memoize(CompanyApiService.getTooltip.bind(CompanyApiService, props.ticker))}
      />
    </CompanyTitleWrapper>
  );
};

export default CompanyTooltip;
