import React from 'react';
import './FormSearch.scss';
import Form, { FormProps } from './Form';
import { useClassName } from '../../hooks/useClassName';
import { ColorScheme } from '../../enums/ColorScheme';
import { ControlSize } from '../../enums/ControlSize';
import ButtonSubmit from './ButtonSubmit';
import { FormSearchBlock, FormSearchElement } from './formSearchBem';
import { BlockOrientation } from '../../enums/BlockOrientation';

type Props = FormProps;

const FormSearch: React.FC<Props> = props => {
  const cn = useClassName(FormSearchBlock.Root, props.className);

  return (
    <Form {...props} className={cn()} orientation={BlockOrientation.Line}>
      <div className={cn(FormSearchElement.Controls)}>{props.children}</div>

      <ButtonSubmit
        className={cn(FormSearchElement.Search)}
        colorScheme={ColorScheme.Attention}
        size={ControlSize.Small}
        isSubmitted={false}
        isBlock={false}
        message="Filter"
        submittedMessage=""
      />
    </Form>
  );
};

export default FormSearch;
