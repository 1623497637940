import { GridSort } from '../interfaces/GridSort';
import { SortDirection } from '../../../../enums/SortDirection';
import { Nullable } from '../../../../types/generics';
import { ColumnState } from '@ag-grid-community/core';

export class GridSortService {
  static getColumnSortFromGrid(columnsStates: ColumnState[]): Nullable<GridSort> {
    const columnStateWithSort = columnsStates.find(columnState => columnState.sort);

    if (columnStateWithSort) {
      return {
        column: columnStateWithSort.colId as string,
        direction: columnStateWithSort.sort as SortDirection,
      };
    }

    return null;
  }
}
