import React, { useContext } from 'react';
import { StatsElementContext } from '../context/StatsElementContext';
import { StatsElementGridDto } from './types/StatsElementGridDto';
import StatsElementSimpleGrid from './StatsElementSimpleGrid';
import { useClassName } from '../../../../hooks/useClassName';
import { StatsElementMapSimpleGridBlock } from './statsElementMapSimpleGridBem';
import './StatsElementMapSimpleGrid.scss';
import SecondarySectionsGroup from '../../../../components/secondary-section/SecondarySectionsGroup';
import { StatsElementGridContext } from './interfaces/StatsElementGridContext';

interface Props {
  gridContext: StatsElementGridContext;
}

const StatsElementMapSimpleGrid: React.FC<Props> = props => {
  const cn = useClassName(StatsElementMapSimpleGridBlock.Root);

  const { grids, options } = useContext(StatsElementContext);

  return (
    <SecondarySectionsGroup className={cn()}>
      {grids?.map((grid: StatsElementGridDto, index: number) => (
        <StatsElementSimpleGrid
          key={index}
          {...grid}
          gridOptions={options?.gridOptions?.[index]}
          gridContext={props.gridContext}
          index={index}
        />
      ))}
    </SecondarySectionsGroup>
  );
};

export default StatsElementMapSimpleGrid;
