import React, { useContext, useMemo } from 'react';
import './FilterSectionItemsGroup.scss';
import { FilterSectionItemsGroupBlock, FilterSectionItemsGroupElement } from './filterSectionItemsGroupBem';
import { useClassName } from '../../../../../hooks/useClassName';
import { FilterSectionsFormContext } from '../context/FilterSectionsFormContext';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FilterSettingsService } from '../../../services/FilterSettingsService';

interface Props {
  filterNames: ViewFilterName[];
}

const FilterSectionItemsGroup: React.FC<Props> = props => {
  const cn = useClassName(FilterSectionItemsGroupBlock.Root);

  const { settings } = useContext(FilterSectionsFormContext);

  const hasGroup = useMemo(
    () => props.filterNames.every(name => FilterSettingsService.getFilterByName(settings.sections, name)),
    [settings.sections, props.filterNames],
  );

  return hasGroup ? (
    <div className={cn()}>
      <div className={cn(FilterSectionItemsGroupElement.Divider)} />

      {props.children}
    </div>
  ) : (
    <>{props.children}</>
  );
};

export default FilterSectionItemsGroup;
