import React from 'react';
import './ViewFilterAdvanced.scss';
import Button from '../../../../../../components/forms/Button';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { ColorScheme } from '../../../../../../enums/ColorScheme';
import { ViewFilterAdvancedBlock, ViewFilterAdvancedElement } from './viewFilterAdvancedBem';
import { useClassName } from '../../../../../../hooks/useClassName';

interface Props {
  isAdvanced: boolean;
  onAdvancedClick: () => void;
}

const ViewFilterAdvanced: React.FC<Props> = props => {
  const cn = useClassName(ViewFilterAdvancedBlock.Root);

  return (
    <div className={cn()}>
      {props.isAdvanced ? (
        <div className={cn(ViewFilterAdvancedElement.Content)}>
          <Button
            size={ControlSize.Small}
            colorScheme={ColorScheme.Info}
            isOutline={true}
            onClick={props.onAdvancedClick}
          >
            Basic Filter
          </Button>

          {/* <div>
            Note: For more advanced filters, please use the{' '}
            <Link to={RoutePath.ScreensAdd} target="_blank">
              IPO screen
            </Link>
          </div> */}
        </div>
      ) : (
        <Button
          size={ControlSize.Small}
          colorScheme={ColorScheme.Info}
          isOutline={true}
          onClick={props.onAdvancedClick}
        >
          Advanced Filter
        </Button>
      )}
    </div>
  );
};

export default ViewFilterAdvanced;
