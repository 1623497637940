import React, { FunctionComponent } from 'react';
import SearchForm from './form/SearchForm';

interface SearchProps {
  onSubmit?: () => void;
}

const Search: FunctionComponent<SearchProps> = props => {
  return <SearchForm onSubmit={props.onSubmit} />;
};

export default Search;
