import React, { useContext } from 'react';
import { ViewDashboardContext } from '../../shared/entities/view/components/view-dashboard/ViewDashboardContext';
import { ResearchDashboardDto } from '../interfaces/ResearchDashboardDto';
import ReportsGrid from '../../shared/entities/view/components/view-grid/components/grids/ReportsGrid';
import { ResearchDashboardProp } from '../enums/ResearchDashboardProp';
import './ResearchDashboardSpecialReportsGrid.scss';
import { ResearchDashboardSpecialReportsGridBlock } from './ResearchDashboardSpecialReportsGridBem';
import { useClassName } from '../../shared/hooks/useClassName';

const ResearchDashboardSpecialReportsGrid: React.FC = () => {
  const context = useContext(ViewDashboardContext);
  const data = context.data as ResearchDashboardDto;
  const cn = useClassName(ResearchDashboardSpecialReportsGridBlock.Root);
  const arr = data[ResearchDashboardProp.SpecialReports];

  return <ReportsGrid className={cn()} expandedData={arr} />;
};

export default ResearchDashboardSpecialReportsGrid;
