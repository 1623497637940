export enum CompanyTabsPath {
  Profile = '/profile',
  InvestmentRationale = '/investment-rationale',
  FundamentalInsights = '/fundamental-insights',
  Financials = '/financials',
  MgmtQs = '/mgmt-qs',
  MgmtOwners = '/mgmt-owners',
  RelativeValuation = '/relative-valuation',
  PeerTrading = '/peer-trading',
  TeamNotes = '/team-notes',
  Milestones = '/milestones',
}
