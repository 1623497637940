import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import './TooltipExpander.scss';
import { CustomAny } from '../../types/generics';
import { useExpand } from '../../hooks/expand/useExpand';
import Tooltip from '../tooltip/Tooltip';
import { useClassName } from '../../hooks/useClassName';
import { TooltipExpanderBlock, TooltipExpanderElement, TooltipExpanderModifier } from './TooltipExpanderBem';
import { ExpandListComponentCommonProps } from './interfaces/ExpandListComponentCommonProps';
import { ControlSize } from '../../enums/ControlSize';

interface Props extends ExpandListComponentCommonProps {
  isUnderFixed?: boolean;
  size?: ControlSize;
  ttData: any;
}

const PcmlTooltipExpander: React.FC<Props> = props => {
  const cn = useClassName(TooltipExpanderBlock.Root);

  const [isTooltipAvailable, setIsTooltipAvailable] = useState(false);

  const { expandedData, isStringData } = useExpand(props.expandData, props.expandingLength);

  const getDisplayData = useCallback(
    (displayData: string | CustomAny): ReactNode =>
      isStringData
        ? `${displayData}`
        : displayData.map((element: CustomAny, index: number) => <div key={index}>{element}</div>),
    [isStringData],
  );

  const expandDataContent = useMemo(() => getDisplayData(expandedData), [expandedData, getDisplayData]);
  const tooltipContent = useMemo(() => getDisplayData(props.ttData), [props.ttData, getDisplayData]);

  const onTooltipAvailabilityChange = useCallback(
    isAvailable => {
      setIsTooltipAvailable(isAvailable);
    },
    [setIsTooltipAvailable],
  );

  return (
    <div
      className={cn({
        [TooltipExpanderModifier.TooltipNotAvailable]: !isTooltipAvailable,
      })}
    >
      <div className={cn(TooltipExpanderElement.Content)}>{expandDataContent}</div>

      <Tooltip
        title="All"
        content={tooltipContent}
        isTitleHighlighted
        isUnderFixed={props.isUnderFixed}
        size={props.size}
        onAvailabilityChange={onTooltipAvailabilityChange}
      />

      {!isTooltipAvailable && <div>...</div>}
    </div>
  );
};

export default PcmlTooltipExpander;
