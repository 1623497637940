import { searchFilterControls } from '../../../../../shared/entities/filters/constants/filter-controls/searchFilterControls';
import { ViewFilterName } from '../../../../../shared/entities/filters/enum/types/ViewFilterName';
import { FiltersMapValue } from '../../../../../shared/entities/filters/components/filterable-component/interfaces/FiltersMapValue';
import { screensDealStatusPricedUpcoming } from '../../../../constants/controls/screensDealStatusControls';
import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';

export const screensInvestorsSharedOptions: Partial<ScreenOptions> = {
  header: 'Investors Screen',
  search: {
    controls: [
      screensDealStatusPricedUpcoming,
      searchFilterControls[ViewFilterName.Investor] as FiltersMapValue,
      searchFilterControls[ViewFilterName.Ownership] as FiltersMapValue,
      searchFilterControls[ViewFilterName.TopShareholder] as FiltersMapValue,
    ],
    additionalControls: [searchFilterControls[ViewFilterName.HistoryShareholders] as FiltersMapValue],
    additionalControlsTitle: 'Search All Investors',
  },
  toolbar: {
    quickFilter: {
      hasLocation: true,
      hasIncludeSPAC: true,
      hasIncludeAShares: true,
    },
  },
  screenTriggerOptions: {
    isInUseAvailable: filtersValues => !!filtersValues[ViewFilterName.Investor],
  },
};
