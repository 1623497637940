import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ModalShowState } from '../../../../../../components/modal/types/ModalShowState';
import ViewModal from '../view-modal/ViewModal';
import { ViewContext } from '../../ViewContext';
import ViewColumnsChooser from './ViewColumnsChooser';
import { useDeepEffect } from '../../../../../../effects/useDeepEffect';
import { ViewColumnsService } from '../../services/ViewColumnsService';
import { ViewColumnsOptionsDto } from './add/interfaces/dto/ViewColumnsOptionsDto';

interface Props {
  modalShowState: ModalShowState;
}

const ViewColumnsModal: React.FC<Props> = props => {
  const { state } = useContext(ViewContext);

  const [selectedColumnsWeb, setSelectedColumnsWeb] = useState(state.selectedColumns.web);
  const [selectedColumnsExcel, setSelectedColumnsExcel] = useState(state.selectedColumns.excel);

  const viewSavedData = useMemo(
    () => ({
      columns: {
        web: selectedColumnsWeb,
        excel: selectedColumnsExcel,
      },
    }),
    [selectedColumnsWeb, selectedColumnsExcel],
  );

  const isResetDisabled = useMemo(
    () => ViewColumnsService.isResetDisabled(viewSavedData.columns, state.selectedColumns),
    [state.selectedColumns, viewSavedData.columns],
  );

  const onChangeWeb = useCallback(selectedColumns => setSelectedColumnsWeb(selectedColumns), []);

  const onChangeExcel = useCallback(selectedColumns => setSelectedColumnsExcel(selectedColumns), []);

  const onHide = useCallback(() => {
    setSelectedColumnsWeb(state.selectedColumns.web);
    setSelectedColumnsExcel(state.selectedColumns.excel);
  }, [state.selectedColumns.excel, state.selectedColumns.web]);

  // TODO: fix reset logic when api will be ready
  const onReset = useCallback(() => {
    setSelectedColumnsWeb(state.selectedColumns.web);
    setSelectedColumnsExcel(state.selectedColumns.excel);
  }, [state.selectedColumns.web, state.selectedColumns.excel]);

  useDeepEffect(() => setSelectedColumnsWeb(state.selectedColumns.web), [state.selectedColumns.web]);
  useDeepEffect(() => setSelectedColumnsExcel(state.selectedColumns.excel), [state.selectedColumns.excel]);

  return (
    <ViewModal
      modalShowState={props.modalShowState}
      header="Columns"
      body={
        <ViewColumnsChooser
          selectedColumnsWeb={selectedColumnsWeb}
          selectedColumnsExcel={selectedColumnsExcel}
          columnsOptions={state.columnsOptions as ViewColumnsOptionsDto}
          onChangeWeb={onChangeWeb}
          onChangeExcel={onChangeExcel}
        />
      }
      viewSaveData={viewSavedData}
      isResetDisabled={isResetDisabled}
      onReset={onReset}
      onHide={onHide}
      footerInHeader
    />
  );
};

export default ViewColumnsModal;
