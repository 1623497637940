import React from 'react';
import { Dropdown } from 'react-bootstrap';
import './DropdownItem.scss';
import { useClassName } from '../../hooks/useClassName';
import { DropdownItemBlock } from './DropdownItemBem';

interface Props {
  eventKey?: string;
  active?: boolean;
}

const DropdownItem: React.FC<Props> = props => {
  const cn = useClassName(DropdownItemBlock.Root);

  return (
    <div className={cn()}>
      <Dropdown.Item active={props.active} eventKey={props.eventKey}>
        {props.children}
      </Dropdown.Item>
    </div>
  );
};
export default DropdownItem;
