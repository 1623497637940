import { max } from 'lodash';
import { ViewGridColumnGroupDto } from '../interfaces/ViewGridColumnGroupDto';
import { GridAgColumn } from '../../../../../components/grid/grid/interfaces/GridAgColumn';
import { ViewGridColumnService } from '../services/ViewGridColumnService';
import FittedColumnsHeaderFormatter from '../../../../../components/grid/shared/components/formatters/header/FittedColumnsHeaderFormatter';
import { GridCellFormatterType } from '../../../../../components/grid/shared/enums/GridCellFormatterType';
import GridFittedCells from '../../../../../components/grid/shared/components/cell/GridFittedCells';
import { ViewGridSingleColumnFactory } from './ViewGridSingleColumnFactory';
import { AlignTypesHorizontal } from '../../../../../enums/AlignTypesHorizontal';
import { ViewGridColumnsSetupOptions } from '../interfaces/ViewGridColumnsSetupOptions';
import { ViewGridColumnWidth } from '../enums/ViewGridColumnWidth';
import { viewGridColumnsGroupsSettings } from '../constants/viewGridColumnsGroupsSettings';
import { ViewGridColumnGroupSettings } from '../interfaces/ViewGridColumnGroupSettings';
import { GridColumnService } from '../../../../../components/grid/shared/services/GridColumnFactory';
import { GridComparatorFn } from '../../../../../components/grid/grid/types/GridComparatorFn';
import { GridColumnPropName } from '../../../../../components/grid/shared/enums/GridColumnPropName';
import { GridHeightMultiplier } from '../../../../../components/grid/grid/enums/GridHeightMultiplier';

export class ViewGridFittedColumnFactory {
  static getColumn(columnGroup: ViewGridColumnGroupDto, setupOptions?: ViewGridColumnsSetupOptions): GridAgColumn {
    const nestedColumns = this.getNestedColumns(columnGroup);

    return {
      field: columnGroup.name,
      sortable: false,
      alignHorizontal: this.getAlignHorizontal(nestedColumns),
      minWidth: this.getWidth(nestedColumns, GridColumnPropName.MinWidth, true),
      width: this.getWidth(nestedColumns, GridColumnPropName.Width),
      maxWidth: this.getWidth(nestedColumns, GridColumnPropName.MaxWidth),
      headerFormatter: {
        component: FittedColumnsHeaderFormatter,
        props: {
          titles: ViewGridColumnService.getNestedColumnsTitles(columnGroup),
        },
      },
      cellFormatter: {
        type: GridCellFormatterType.Custom,
        props: {
          component: GridFittedCells,
          columns: nestedColumns,
        },
      },
      heightMultiplier: GridHeightMultiplier.Xs,
      ...this.getCustomOptions(columnGroup.name, nestedColumns),
    };
  }

  private static getNestedColumns(columnGroup: ViewGridColumnGroupDto): GridAgColumn[] {
    return columnGroup.nestedColumns.map(column => ViewGridSingleColumnFactory.getColumn(column));
  }

  private static getAlignHorizontal(nestedColumns: GridAgColumn[]): AlignTypesHorizontal | undefined {
    return nestedColumns?.[0].alignHorizontal;
  }

  private static getWidth(
    nestedColumns: GridAgColumn[],
    type: GridColumnPropName,
    hasDefaultWidth = false,
  ): number | undefined {
    const widths = nestedColumns.map(nestedColumn => nestedColumn[type]);
    const maxWidth = max(widths);

    if (hasDefaultWidth && !maxWidth) {
      return ViewGridColumnWidth.Sm;
    }

    return maxWidth;
  }

  private static getCustomOptions(columnGroupName: string, nestedColumns: GridAgColumn[]): Partial<GridAgColumn> {
    const columnGroupSettings = viewGridColumnsGroupsSettings[columnGroupName];

    if (!columnGroupSettings) {
      return {};
    }

    return {
      ...this.getSortOptions(columnGroupSettings, nestedColumns),
    };
  }

  private static getSortOptions(
    columnGroupSettings: ViewGridColumnGroupSettings,
    nestedColumns: GridAgColumn[],
  ): Partial<GridAgColumn> {
    if (!columnGroupSettings.sortField) {
      return {};
    }

    const sortColumn = GridColumnService.getColumnByName(columnGroupSettings.sortField, nestedColumns);

    if (!sortColumn) {
      return {};
    }

    return {
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        const fieldValueA = nodeA.data[columnGroupSettings.sortField as string];
        const fieldValueB = nodeB.data[columnGroupSettings.sortField as string];

        return (sortColumn.comparator as GridComparatorFn)(fieldValueA, fieldValueB, nodeA, nodeB, isInverted);
      },
      isSortingInverse: sortColumn.isSortingInverse,
      sort: sortColumn.sort,
      sortable: true,
    };
  }
}
