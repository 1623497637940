import { useCallback } from 'react';
import { FavoritesApiService } from '../../../../shared/api/FavoritesApiService';
import ViewDashboard from '../../../../shared/entities/view/components/view-dashboard/ViewDashboard';
import React from 'react';
import SavedViews from '../../../../my-favorites/components/saved-views/SavedViews';
import { BlockOrientation } from '../../../../shared/enums/BlockOrientation';

const AdditionalActionTooltip: React.FC = () => {
  const getDataFn = useCallback(() => FavoritesApiService.getDashboard(), []);

  return (
    <ViewDashboard getDataFn={getDataFn}>
      <SavedViews orientation={BlockOrientation.Column} />
    </ViewDashboard>
  );
};

export default AdditionalActionTooltip;
