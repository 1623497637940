import React, { useMemo } from 'react';
import './AccountSettings.scss';
import Helmet from 'react-helmet';
import { Email } from '../shared/enums/Email';
import { SettingsTextElement } from './enum/SettingsTextElement';
import ChangePasswordForm from './form/ChangePasswordForm';
import PrimarySectionRow from '../shared/components/primary-section/PrimarySectionRow';
import PrimarySection from '../shared/components/primary-section/PrimarySection';
import { AccountUser } from './interfaces/AccountUser';
import Hyperlink from '../shared/components/typography/Hyperlink';
import { useSelector } from 'react-redux';
import { accountDataSelector, userNameSelector } from '../store/auth/selectors';
import { useClassName } from '../shared/hooks/useClassName';
import { EMAILS_WITH_GA_ACCESS_LIST, EMAILS_WITH_GA_ANALYTICS_LIST } from '../shared/constants/environment';
import { RoutePath } from '../shared/enums/RoutePath';
import { Link } from 'react-router-dom';
import DownloadCsvButton from '../shared/components/DownloadCsv';
import { ApiPath } from '../shared/api/enums/ApiPath';

const MAX_AVAILABLE_SEATS = 6;

const AccountSettings: React.FC = () => {
  const cn = useClassName('AccountSettings');
  const emailsWithGaAccessList = EMAILS_WITH_GA_ACCESS_LIST.split(',');
  const emailsWithGaAnalyticsList = EMAILS_WITH_GA_ANALYTICS_LIST.split(',');
  const accountData = useSelector(accountDataSelector);
  const userName = useSelector(userNameSelector);

  /* const subscriptionDeadline = useMemo(
    () => DateHelper.formatISODateStr(accountData?.subsription, DateFormat.YearMonthDayISO) || '----/--/--',
    [accountData],
  ); */
  const availableSeats: number = useMemo(() => {
    return accountData ? MAX_AVAILABLE_SEATS - accountData.accountUsers.length : MAX_AVAILABLE_SEATS;
  }, [accountData]);

  return (
    <>
      <Helmet>
        <title>Account Settings</title>
      </Helmet>

      <PrimarySection header="Account Settings" isLoading={!accountData}>
        {accountData && (
          <>
            <PrimarySectionRow>
              <div>
                <div>
                  <b>Name: {accountData.user.firstName}</b>
                </div>

                <div>
                  <b>Username: {userName}</b>
                </div>
                {emailsWithGaAnalyticsList.includes(accountData.user.email) && (
                  <div>
                    <b>
                      <Link to={RoutePath.GoogleAnalytics}>Google Analytics</Link>
                    </b>
                  </div>
                )}
                {emailsWithGaAccessList.includes(accountData.user.email) && (
                  <div>
                    <b>
                      {' '}
                      <Link to={RoutePath.AccessLogs}>Access Logs</Link>
                    </b>
                  </div>
                )}
                {emailsWithGaAccessList.includes(accountData.user.email) && (
                  <div>
                    <b>
                      {' '}
                      <DownloadCsvButton title={"Most Viewed Int'l CSV"} endpoint={ApiPath.MostViewedIntlIpos} />
                    </b>
                  </div>
                )}
              </div>
            </PrimarySectionRow>

            <PrimarySectionRow className={cn('form')}>
              <ChangePasswordForm />

              <div>
                <b>
                  Account Users ({availableSeats}/{MAX_AVAILABLE_SEATS} seats available)
                </b>
              </div>

              {accountData?.accountUsers.map((user: AccountUser) => (
                <div>
                  {user.firstName} {user.lastName} {user.isAccountAdmin && '(Admin)'}
                </div>
              ))}

              {/* <div>To add or remove a user please contact your account admin, Deborah Iobbi.</div>

              <div>
                <b>IPO Intelligence Subscription set to renew {subscriptionDeadline}.</b>
              </div> */}
            </PrimarySectionRow>
            <PrimarySectionRow>
              <div>
                For questions about your subscription, please email&nbsp;
                <Hyperlink to={`mailto:${Email.Contact1};${Email.Contact2};${Email.Contact3}`} isBlank isExternal>
                  {SettingsTextElement.Contact}
                </Hyperlink>
                .
              </div>
            </PrimarySectionRow>
          </>
        )}
      </PrimarySection>
    </>
  );
};

export default AccountSettings;
