export enum NewsStoryType {
  SetTerms = 'SetTerms',
  IpoPricing = 'IPOPricing',
  NewIpoFiling = 'NewIPOFiling',
  OtherAnnouncement = 'Other',
  Postponed = 'Postponed',
  TermsDown = 'TermsDown',
  TermsUp = 'TermsUp',
  UpdatedFinancials = 'UpdatedFinancials',
  Withdrawn = 'Withdrawn',
}
