import { useEffect } from 'react';
import { CustomAny } from '../../types/generics';

export function useAsyncFnEffect(fn: () => void, dependency?: CustomAny[]) {
  const deps = dependency || [];

  useEffect(() => {
    fn();
    // eslint-disable-next-line
  }, deps);
}
