import React, { memo, useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import './FormControlVisibleValue.scss';
import { useClassName } from '../../../../hooks/useClassName';
import { FormControlVisibleValueBlock, FormControlVisibleValueElement } from './formControlVisibleValueBem';
import Icon from '../../../icon/Icon';
import { IconName } from '../../../icon/IconName';
import { ControlSize } from '../../../../enums/ControlSize';
import { ColorScheme } from '../../../../enums/ColorScheme';
import { AnyObject } from '../../../../types/generics';
import { FormControlVisibleValueFactory } from './factories/FormControlVisibleValueFactory';
import { FormControlDeleteVisibleValueOptions } from './interfaces/FormControlDeleteVisibleValueOptions';
import { FormControl } from '../form-builder/types/formControlsTypes';

interface Props {
  control: FormControl;
  values: AnyObject;
  dictionaries?: AnyObject;
  onDelete?: (deleteValueOptions: FormControlDeleteVisibleValueOptions) => void;
}

const FormControlVisibleValue: React.FC<Props> = props => {
  const { control, values, dictionaries, onDelete } = props;

  const cn = useClassName(FormControlVisibleValueBlock.Root);

  const controlValueOptionsList = useMemo(() => FormControlVisibleValueFactory.get(control, values, dictionaries), [
    control,
    values,
    dictionaries,
  ]);

  const onDeleteClick = useCallback(
    (valueOptionsValues: AnyObject) => {
      onDelete?.({
        control,
        values: valueOptionsValues,
      });
    },
    [control, onDelete],
  );

  if (isEmpty(controlValueOptionsList)) {
    return <></>;
  }

  return (
    <>
      {controlValueOptionsList.map((controlValueOptions, index) => (
        <div className={cn()} key={index}>
          {controlValueOptions.title && (
            <span className={cn(FormControlVisibleValueElement.Title)}>{controlValueOptions.title}:</span>
          )}

          <span className={cn(FormControlVisibleValueElement.DisplayValue)}>{controlValueOptions.displayValue}</span>

          <Icon
            className={cn(FormControlVisibleValueElement.Delete)}
            name={IconName.Close}
            size={ControlSize.ExtraExtraSmall}
            colorScheme={ColorScheme.Primary}
            hasHover
            onClick={onDeleteClick.bind(onDeleteClick, controlValueOptions.values)}
          />
        </div>
      ))}
    </>
  );
};

export default memo(FormControlVisibleValue);
