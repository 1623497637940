import React from 'react';

interface Props {
  titles: string[];
}

const FittedColumnsHeaderFormatter: React.FC<Props> = props => {
  return (
    <>
      {props.titles.map(title => (
        <div key={title}>{title}</div>
      ))}
    </>
  );
};

export default FittedColumnsHeaderFormatter;
