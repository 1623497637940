import { cloneDeep } from 'lodash';
import { FiltersValues } from '../../../types/FiltersValues';
import { FiltersMapValue } from '../interfaces/FiltersMapValue';
import { AnyObject } from '../../../../../types/generics';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { FormSelectSwitchOptionsProps } from '../../../../../components/forms/components/select/FormSelectOptionsSwitch';

export class FiltersMapService {
  static getInitialFilters(filterControls: FiltersMapValue[], defaultValues: FiltersValues = {}): FiltersValues {
    return filterControls.reduce(
      (accum: FiltersValues, filter: FiltersMapValue) => ({ ...accum, ...defaultValues, ...filter.defaultValues }),
      {},
    );
  }

  static getControls(filterControls: FiltersMapValue[], dictionaries: AnyObject = {}): FiltersMapValue[] {
    return filterControls.map(filerControl => {
      const updateControl = cloneDeep(filerControl);
      const dictionary = dictionaries[filerControl.name as string];

      if (dictionary) {
        // TODO: fix types for all filters controls
        if (updateControl.type === FormControlType.SelectSwitchOptions) {
          (updateControl.props as FormSelectSwitchOptionsProps).optionsData = dictionary;
        } else {
          (updateControl.props as AnyObject).options = dictionary;
        }
      }

      return updateControl;
    });
  }
}
