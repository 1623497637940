import React, { useState } from 'react';
import NewsFilter from './NewsFilter';
import './NewsOpenableFilter.scss';
import { NewsFilterData } from '../interfaces/NewsFilterData';
import AccordionSecondary from '../../shared/components/accordion/AccordionSecondary';
import IconWithContent from '../../shared/components/icon/IconWithContent';
import { IconName } from '../../shared/components/icon/IconName';
import { ControlSize } from '../../shared/enums/ControlSize';
import { mobileBreakpoints } from '../../shared/constants/breakpoints/mobileBreakpoints';
import useBreakpointAvailable from '../../shared/effects/useBreakpointAvailable';

interface Props {
  defaultValues?: NewsFilterData;
  isInitialFilterVisible?: boolean;
}

const NewsOpenableFilter: React.FC<Props> = props => {
  const isMobile = useBreakpointAvailable(mobileBreakpoints);
  const closeState = useState(!props.isInitialFilterVisible || isMobile);

  return (
    <AccordionSecondary
      classNames="NewsOpenableFilter"
      title={
        <IconWithContent name={IconName.Filter} size={ControlSize.Small}>
          Filter
        </IconWithContent>
      }
      closeState={closeState}
      isIconPin={false}
    >
      <NewsFilter defaultValues={props.defaultValues} />
    </AccordionSecondary>
  );
};

export default NewsOpenableFilter;
