import { SimpleGridProps } from '../../shared/components/grid/simple-grid/SimpleGrid';
import { CustomAny } from '../../shared/types/generics';
import { GridRow } from '../../shared/components/grid/shared/types/GridRow';

const getTextByIndex = (index: number, text: string) => `${text}${index + 1}`;

const getFieldByIndex = (index: number) => getTextByIndex(index, 'field');
const getTitleByIndex = (index: number) => getTextByIndex(index, 'Fake data ');

export const tableData = (width: number, height: number): SimpleGridProps => ({
  rows: Array.from({ length: height }).map(() =>
    Array.from({ length: width }).reduce(
      (acum: CustomAny, value: CustomAny, index: number) => ({
        ...acum,
        [getFieldByIndex(index)]: getTitleByIndex(index),
      }),
      {},
    ),
  ) as GridRow[],
  columns: Array.from({ length: width }).map((value: CustomAny, index: number) => ({
    headerName: getTitleByIndex(index),
    field: getFieldByIndex(index),
  })),
});
