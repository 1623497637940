import React, { useCallback, useMemo, useState } from 'react';
import StatsPage from '../StatsPage';
import PrimarySectionRow from '../../../shared/components/primary-section/PrimarySectionRow';
import { getFilingsStatsElementOptions } from './constants/getFilingsStatsElementOptions';
import StatsElement from '../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../shared/entities/stats-element/enums/StatsElementType';
import { ViewFilterName } from '../../../shared/entities/filters/enum/types/ViewFilterName';
import { StatsGlobalFilter } from '../types/StatsGlobalFilter';
import { StatsFilterName } from '../enum/StatsFilterName';
import { StatsFilingsChartService } from './services/StatsFilingsChartService';

const StatsFilings: React.FC = () => {
  const [header, setHeader] = useState();
  const [filingsChartTitle, setFilingsChartTitle] = useState();

  const filtersNames = useMemo(
    () => [ViewFilterName.Headquarter, StatsFilterName.IncludeSPACs],
    [],
  ) as StatsGlobalFilter[];

  const onPageFiltersChange = useCallback(pageFilters => {
    setHeader(StatsFilingsChartService.getBlockTitle(pageFilters));
    // setFilingsChartTitle(StatsFilingsChartService.getChartTitle(pageFilters));
    setFilingsChartTitle('');
  }, []);

  return (
    <StatsPage header={header} filtersNames={filtersNames} onPageFiltersChange={onPageFiltersChange}>
      <PrimarySectionRow>
        <StatsElement type={StatsElementType.Filings} options={getFilingsStatsElementOptions(filingsChartTitle)} />
      </PrimarySectionRow>
    </StatsPage>
  );
};

export default StatsFilings;
