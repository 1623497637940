import LocationService from '../../shared/services/LocationService';
import { RoutePath } from '../../shared/enums/RoutePath';
import { SearchResultQueryParam } from '../enums/SearchResultQueryParam';
import { LocationSearchData } from '../../shared/interfaces/LocationSearchData';
import { exactTickerRegEx } from '../../company/constants/exactTickerRegEx';
import { CompanyLocationService } from '../../company/services/CompanyLocationService';

export class SearchResultsLocationService {
  static navigate(searchValue: string): void {
    const isCompanyTicker = exactTickerRegEx.test(searchValue);

    if (isCompanyTicker) {
      CompanyLocationService.navigateByTickerInText(searchValue);
    } else {
      this.navigateSearch(searchValue);
    }
  }

  private static navigateSearch(searchValue: string): void {
    LocationService.redirect(RoutePath.SearchResults as RoutePath, this.getSearchData(searchValue));
  }

  private static getSearchData(searchValue: string): LocationSearchData {
    return {
      [SearchResultQueryParam.Search]: searchValue,
    };
  }
}
