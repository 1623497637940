export enum CompanyHeaderBlock {
  Root = 'CompanyHeader',
}

export enum CompanyHeaderElement {
  Description = 'description',
  Star = 'star',
  Title = 'title',
  Subtitle = 'subtitle',
  Node = 'node',
  Sector = 'sector',
  SectorTreeView = 'sectorTreeView',
}
