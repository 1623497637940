import { MoneyColumnValue } from '../interfaces/column-values/MoneyColumnValue';
import { Nullable } from '../../../../../types/generics';
import { SortService } from '../../../../../services/SortService';

export class ViewGridSortService {
  static sortDate(valueA?: Nullable<string>, valueB?: Nullable<string>): number {
    return SortService.sortDate(valueA, valueB);
  }

  static sortNumber(valueA?: Nullable<number>, valueB?: Nullable<number>): number {
    return SortService.sortNumber(valueA, valueB);
  }

  static sortText(valueA?: Nullable<string>, valueB?: Nullable<string>): number {
    return SortService.sortText(valueA, valueB);
  }

  static sortMoney(valueA?: Nullable<MoneyColumnValue>, valueB?: Nullable<MoneyColumnValue>): number {
    return SortService.sortNumber(valueA?.value, valueB?.value);
  }

  static sortPercentage(valueA?: Nullable<number>, valueB?: Nullable<number>): number {
    return SortService.sortNumber(valueA, valueB);
  }

  static sortBoolean(valueA: boolean, valueB: boolean): number {
    return SortService.sortBoolean(valueA, valueB);
  }

  // Sort array by first value
  static sortTextArray(valueA: string[], valueB: string[]): number {
    return this.sortText(valueA[0], valueB[0]);
  }
}
