import React from 'react';
import { GridColumn } from '../../interfaces/GridColumn';
import GridCell from './GridCell';
import { GridCellFormatterCommonProps } from '../../interfaces/gridCellFormatter';

interface Props extends GridCellFormatterCommonProps {
  columns: GridColumn[];
}

const GridFittedCells: React.FC<Props> = props => {
  return (
    <>
      {props.columns.map(column => (
        <GridCell key={column.field} row={props.data} column={column} />
      ))}
    </>
  );
};

export default GridFittedCells;
