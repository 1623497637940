import React from 'react';
import Helmet from 'react-helmet';
import PrimarySection from '../shared/components/primary-section/PrimarySection';
import RaitingsSystemParagraph from './RatingsSystemParagraph';
import PrimarySectionRow from '../shared/components/primary-section/PrimarySectionRow';
import RatingsSystemColumns from './RatingsSystemColumns';
import Divider from '../shared/components/Divider';
import './RatingsSystem.scss';
import { AlignTypesHorizontal } from '../shared/enums/AlignTypesHorizontal';
import { ControlSize } from '../shared/enums/ControlSize';

const RaitingsSystem: React.FC = () => {
  return (
    <div className="RaitingsSystem">
      <Helmet>
        <title>Ratings System</title>
      </Helmet>

      <PrimarySection header="Ratings System" maxContainerSize={ControlSize.Large}>
        <PrimarySectionRow>
          <RaitingsSystemParagraph
            title={
              <>
                Renaissance Capital’s composite investment ratings fall into three broad categories:&nbsp;
                <b>Outperform (14 to 20)/Strong, Marketperform (11 to 13)/Neutral, and Underperform (4 to 10)/Weak.</b>
              </>
            }
          >
            <RatingsSystemColumns
              firstColumn={['Outperform', 'Marketperform', 'Underperform']}
              secondColumn={['(14 to 20)/Strong', '(11 to 13)/Neutral', '(4 to 10)/Weak']}
            />
          </RaitingsSystemParagraph>
        </PrimarySectionRow>

        <PrimarySectionRow>
          <RaitingsSystemParagraph
            className="RaitingsSystem__categoriesDescription"
            title={<b>These categories are defined as follows:</b>}
          >
            <p>
              <b>Outperform/Strong: </b>
              the subject company is expected to perform better than the relevant broad market benchmark over the next 3
              months.
              <Divider />
            </p>

            <p>
              <b>Marketperform/Neutral: </b>
              the subject company is expected to perform in-line with the relevant broad market benchmark over the next
              3 months.
              <Divider />
            </p>

            <p>
              <b>Underperform/Weak: </b>
              the subject company is expected to perform below the relevant broad market benchmark over the next 3
              months.
              <Divider />
            </p>
          </RaitingsSystemParagraph>
        </PrimarySectionRow>

        <PrimarySectionRow>
          <RaitingsSystemParagraph>
            Performance expectations are based on the midpoint of the proposed range at the time of the report.
          </RaitingsSystemParagraph>
        </PrimarySectionRow>

        <PrimarySectionRow>
          <RaitingsSystemParagraph title={<b>How are the ratings determined?</b>}>
            Ratings are based on four criteria evaluated by Renaissance Capital’s analysts: Company Fundamentals,
            Corporate Governance, Relative Valuation, and Group Technicals. For most deals, each of the four are rated
            on a scale of 1-5, and the overall IPO rating is the sum of those scores.
          </RaitingsSystemParagraph>
        </PrimarySectionRow>

        <PrimarySectionRow>
          <RaitingsSystemParagraph title={<b>Here’s an example below:</b>}>
            <div className="RaitingsSystem__image">
              <img src={require('./images/rating-example.png')} alt="Rating example" />
            </div>
          </RaitingsSystemParagraph>
        </PrimarySectionRow>

        <PrimarySectionRow>
          <RaitingsSystemParagraph title={<b>Why do some research reports not have a number rating?</b>}>
            Instead of a numbered rating, two types of research reports receive ratings of Very Strong, Strong, Neutral,
            Weak, or Very Weak. In some cases, Renaissance Capital will issue an Early Take a few days prior to the full
            Report, indicating the analyst’s initial impression. A Strong rating on an Early Take does not necessarily
            mean that the deal will receive an Outperform (14-20). On other deals, including biotechs and certain REITs
            and MLPs, RC will issue a shorter Basic Report. Basic Reports still evaluate IPOs based on the same criteria
            as Full Reports in arriving at a final rating.
          </RaitingsSystemParagraph>
        </PrimarySectionRow>

        <PrimarySectionRow>
          <RaitingsSystemParagraph title={<b>Why do some deals receive no rating at all?</b>}>
            Renaissance Capital issues research on a select number of non-US IPOs, and the rest are unrated. Some of
            these non-US reports do not include a research opinion, and instead simply describe the company, its
            management, its valuation, and peer trading. There are also certain US micro-cap IPOs where RC will not
            issue a research report, including deals that we do not count in our IPO stats, as these receive little
            interest from our institutional clients. However, even without a research rating, valuable information on
            each IPO can be found on our IPO profiles.
          </RaitingsSystemParagraph>
        </PrimarySectionRow>

        <PrimarySectionRow>
          <RaitingsSystemParagraph title={<b>Important Disclosure</b>}>
            For purposes of FINRA/NYSE disclosure rules, RC’s Outperform/Strong rating most closely corresponds to a buy
            recommendation; RC’s Marketperform/Neutral rating most closely corresponds to a hold/neutral rating; and
            RC’s Underperform/Weak rating most closely corresponds to a sell rating. However Outperform/Strong,
            Marketperform/Neutral and Underperform/Weak are not the same as buy, hold/neutral, and sell ratings, as RC’s
            stock ratings are determined on a relative basis. An investor’s decision to buy or sell a stock should
            depend on individual circumstances and risk tolerance. For the year ended 2022, the distribution of RC’s
            research opinions for US-listed IPOs is as follows:
          </RaitingsSystemParagraph>
        </PrimarySectionRow>

        <PrimarySectionRow alignHorizontal={AlignTypesHorizontal.Center}>
          <RaitingsSystemParagraph>
            <RatingsSystemColumns
              firstColumn={['Outperform', 'Marketperform', 'Underperform']}
              secondColumn={['17%', '83%', '0%']}
            />
          </RaitingsSystemParagraph>
        </PrimarySectionRow>
      </PrimarySection>
    </div>
  );
};

export default RaitingsSystem;
