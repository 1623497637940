import { CalendarEvent } from '../../../shared/entities/calendar/interfaces/CalendarEvent';
import CalendarApiService from '../../../shared/api/CalendarApiService';
import { CalendarEventDto } from '../../../shared/entities/calendar/interfaces/CalendarEventDto';
import { CalendarDataService } from '../../../shared/entities/calendar/services/CalendarDataService';
import { CalendarEventType } from '../../../shared/entities/calendar/enums/CalendarEventType';

export class ScheduledCalendarDataService {
  static async getEvents(): Promise<CalendarEvent[]> {
    const calendar = await CalendarApiService.getScheduledCalendar();

    return this.mapResponseToEvents(calendar);
  }

  private static mapResponseToEvents(calendarEventsDto: CalendarEventDto[]): CalendarEvent[] {
    return calendarEventsDto.map(event => CalendarDataService.getEventFromDto(event, CalendarEventType.TradeDate));
  }
}
