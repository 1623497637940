import React from 'react';
import FormGroup from '../../shared/components/forms/FormGroup';
import { TalkToAnalystFields } from './TalkToAnalystFields';
import FormTextarea from '../../shared/components/forms/FormTextarea';
import { QUESTION_TYPE_OPTIONS } from './constant/options';
import FormSelect from '../../shared/components/forms/components/select/FormSelect';

interface Props {
  isSubmitted: boolean;
}

const TalkToAnalystForm: React.FC<Props> = props => {
  return (
    <>
      <FormGroup>
        <b>I have a question about</b>

        <FormSelect
          isMultiSelect={false}
          name={TalkToAnalystFields.QuestionAbout}
          placeholder="Choose the question type"
          options={QUESTION_TYPE_OPTIONS}
          isDisabled={props.isSubmitted}
        />
      </FormGroup>

      <FormGroup>
        <b>Enter your question and we&apos;ll forward it to our research and support teams</b>

        <FormTextarea
          name={TalkToAnalystFields.QuestionToAnalyst}
          placeholder="Enter your question"
          isDisabled={props.isSubmitted}
        />
      </FormGroup>
    </>
  );
};

export default TalkToAnalystForm;
