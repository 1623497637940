import React, { useCallback, useEffect, useMemo, useRef, useState, Dispatch, SetStateAction } from 'react';
import './Tooltip.scss';
import ReactTooltipLite from 'react-tooltip-lite';
import { ensuredForwardRef, useClickAway } from 'react-use';
import { TooltipPosition } from './enums/TooltipPosition';
import { useClassName } from '../../hooks/useClassName';
import { TooltipBlock, TooltipElement, TooltipModifier } from './TooltipBem';
import { ControlSize } from '../../enums/ControlSize';
import { BreakpointName } from '../../enums/BreakpointName';
import useBreakpointAvailable from '../../effects/useBreakpointAvailable';
import TooltipTitle from './TooltipTitle';
import { TooltipTriggerType } from './enums/TooltipTriggerType';
import { mobileBreakpoints } from '../../constants/breakpoints/mobileBreakpoints';

interface Props {
  title: React.ReactNode;
  content: React.ReactNode;
  size?: ControlSize;
  hasMaxHeight?: boolean;
  isTitleHighlighted?: boolean;
  hideHighlight?: boolean;
  className?: string;
  position?: TooltipPosition;
  delayShow?: number;
  delayHide?: number;
  breakpoints?: BreakpointName[];
  triggerType?: TooltipTriggerType;
  hasIndentHorizontal?: boolean;
  hasIndentVertical?: boolean;
  isUnderFixed?: boolean;
  hasTitleLinkStyle?: boolean;
  onToggle?: (isShow: boolean) => void;
  onAvailabilityChange?: (isAvailable: boolean) => void;
  show?: boolean;
  setShow?: Dispatch<SetStateAction<boolean>>;
  arrowSize?: number;
  distance?: number;
  tipContentHover?: boolean;
}

const DEFAULT_DELAY_SHOW = 500;
const DEFAULT_DELAY_HIDE = 200;

const Tooltip: React.FC<Props> = (props, ref) => {
  const {
    position = TooltipPosition.Right,
    delayShow = DEFAULT_DELAY_SHOW,
    delayHide = DEFAULT_DELAY_HIDE,
    triggerType = props.isTitleHighlighted ? TooltipTriggerType.Click : TooltipTriggerType.Hover,
    hasIndentHorizontal = true,
    hasIndentVertical = true,
  } = props;

  const cn = useClassName(TooltipBlock.Root, props.className);

  const contentRef = useRef(null);

  const [isShowCurrent, setIsShowCurrent] = useState<boolean>(false);

  const isTooltipAvailable = !useBreakpointAvailable(mobileBreakpoints) || triggerType === TooltipTriggerType.Click;

  const title = useMemo(
    () => (
      <TooltipTitle
        className={cn(TooltipElement.Title)}
        isHighlighted={props.isTitleHighlighted && isTooltipAvailable && !props.hideHighlight}
        hasLinkStyle={props.hasTitleLinkStyle}
      >
        {props.title}
      </TooltipTitle>
    ),
    [props.title, props.isTitleHighlighted, isTooltipAvailable, cn, props.hasTitleLinkStyle],
  );

  const toggleTooltip = useCallback(
    (isVisible: boolean) => {
      ref.current.setState({
        showTip: isVisible,
      });
    },
    [ref],
  );

  const onToggle = useCallback(
    (isShow: boolean): void => {
      // Bug when tooltip triggered isShow several times
      if (isShow !== isShowCurrent) {
        props.onToggle?.(isShow);
      }
      setIsShowCurrent(isShow);
    },
    [isShowCurrent, props],
  );

  useEffect(() => {
    props.onAvailabilityChange?.(isTooltipAvailable);
  }, [props.onAvailabilityChange, isTooltipAvailable]);

  useClickAway(contentRef, () => {
    if (triggerType === TooltipTriggerType.Click) {
      toggleTooltip(false);
      props.setShow && props.setShow(false);
    }
  });

  return (
    <>
      {isTooltipAvailable ? (
        <ReactTooltipLite
          ref={ref}
          content={
            <div
              ref={contentRef}
              className={cn(TooltipElement.Content, {
                [TooltipModifier.IndentHorizontal]: hasIndentHorizontal,
                [TooltipModifier.IndentVertical]: hasIndentVertical,
                [TooltipModifier.MaxHeight]: props.hasMaxHeight,
              })}
            >
              {props.content}
            </div>
          }
          isOpen={props.show}
          direction={position}
          arrowSize={props.arrowSize ? props.arrowSize : 6}
          distance={props.distance !== undefined ? props.distance : undefined}
          tipContentHover={props.tipContentHover === false ? false : true}
          hoverDelay={delayShow}
          mouseOutDelay={delayHide}
          eventToggle={triggerType}
          onToggle={onToggle}
          padding={0}
          className={cn({
            [TooltipModifier.UnderFixed]: props.isUnderFixed,
            [TooltipModifier.Show]: isShowCurrent,
            [`${props.size}`]: props.size,
          })}
        >
          {title}
        </ReactTooltipLite>
      ) : (
        title
      )}
    </>
  );
};

export default ensuredForwardRef(Tooltip);
