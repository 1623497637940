import React, { useMemo } from 'react';
import StatsPage from '../StatsPage';
import PrimarySectionRow from '../../../shared/components/primary-section/PrimarySectionRow';
import StatsElement from '../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../shared/entities/stats-element/enums/StatsElementType';
import { backedIposStatsElementOptions } from './constants/backedIposStatsElementOptions';
import { ViewFilterName } from '../../../shared/entities/filters/enum/types/ViewFilterName';
import { StatsGlobalFilter } from '../types/StatsGlobalFilter';
import './StatsBackedIpos.scss';
import { useClassName } from '../../../shared/hooks/useClassName';
import { StatsBackedIposBlock } from './StatsBackedIposBem';

const StatsBackedIpos: React.FC = () => {
  const cn = useClassName(StatsBackedIposBlock.Root);

  const filtersNames = useMemo(() => [ViewFilterName.Headquarter], []) as StatsGlobalFilter[];

  return (
    <StatsPage className={cn()} header="Venture Capital & Private Equity Backed IPOs" filtersNames={filtersNames}>
      <PrimarySectionRow>
        <StatsElement type={StatsElementType.VentureCapital} options={backedIposStatsElementOptions} />
      </PrimarySectionRow>
    </StatsPage>
  );
};

export default StatsBackedIpos;
