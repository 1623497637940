import React from 'react';
import { StepProps } from '../../../../../../../shared/components/stepper/Step';
import { CompanyStagesDot } from '../../../../../../interfaces/CompanyStagesDot';
import { DateHelper } from '../../../../../../../shared/helpers/date/DateHelper';
import { StepStatus } from '../../../../../../../shared/components/stepper/enums/StepStatus';
import { CompanyProfileStepColor } from '../enums/CompanyProfileStepColor';
import { lockUpTooltipTitle } from '../constants/lockUpTooltipTitle';
import { CompanyStagesTooltipDto } from '../../../../../../interfaces/CompanyStagesTooltipDto';
import CompanyProfileStepsTooltipContent from '../tooltip-content/CompanyProfileStepsTooltipContent';
import { DateFormat } from '../../../../../../../shared/helpers/date/enums/DateFormat';

export class CompanyProfileStepsService {
  static convertToStepperData(data: CompanyStagesDot[], tooltipData?: CompanyStagesTooltipDto[]): StepProps[] {
    return data.map((step: CompanyStagesDot) => ({
      title: step.title,
      stepStatus: this.getStepStatusFromColor(step.color),
      description: DateHelper.formatISODateStr(step.date, DateFormat.MonthDayYearShort),
      tooltipContent: this.getTooltipContent(step, tooltipData),
    }));
  }

  static getTooltipContent(
    data: CompanyStagesDot,
    tooltipData?: CompanyStagesTooltipDto[],
  ): React.ReactNode | undefined {
    const isTooltipData: boolean = this.getIsLockUpStep(data) && !!tooltipData?.length;

    return isTooltipData && <CompanyProfileStepsTooltipContent items={tooltipData as CompanyStagesTooltipDto[]} />;
  }

  static getIsLockUpStep(data: CompanyStagesDot): boolean {
    return data.title === lockUpTooltipTitle;
  }

  static getStepStatusFromColor(color: CompanyProfileStepColor): StepStatus {
    switch (color) {
      case CompanyProfileStepColor.Blue:
        return StepStatus.Passed;
      case CompanyProfileStepColor.Empty:
        return StepStatus.NotPassed;
      case CompanyProfileStepColor.Gray:
        return StepStatus.Neutral;
      case CompanyProfileStepColor.Red:
        return StepStatus.Danger;
    }
  }
}
