export enum RangeSelectorButtonType {
  Day = 'day',
  Month = 'month',
  ThreeMonth = 'treeMonth',
  SixMonth = 'sixMonth',
  YTD = 'ytd',
  Year = 'year',
  TwoYear = 'twoYear',
  ThreeYear = 'treeYear',
  FiveYear = 'fiveYear',
  All = 'all',
}
