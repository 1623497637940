import React from 'react';
import AdditionalStatsContentContainer from '../../shared/components/AdditionalStatsContentContainer';
import StatsElement from '../../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../../shared/entities/stats-element/enums/StatsElementType';
import { withdrawalsStatsElementOptions } from './constants/withdrawalsStatsElementOptions';
import AdditionalStatsPage from '../AdditionalStatsPage';

const Withdrawals: React.FC = () => {
  return (
    <AdditionalStatsPage>
      <AdditionalStatsContentContainer title="Withdrawal Activity">
        <StatsElement type={StatsElementType.Withdrawals} options={withdrawalsStatsElementOptions} />
      </AdditionalStatsContentContainer>
    </AdditionalStatsPage>
  );
};

export default Withdrawals;
