import React, { memo } from 'react';
import './NewsSearchItem.scss';
import TextContainer from '../../marketing/shared/TextContainer';
import { Link } from 'react-router-dom';
import { NewsFilterResultDto } from '../interfaces/NewsFilterResultDto';
import FormattedDate from '../../shared/components/FormattedDate';
import { NewsLocationService } from '../shared/services/NewsLocationService';
import NewsCompanyLogo from '../components/NewsCompanyLogo';
import { CompanyTemplateService } from '../../company/services/CompanyTemplateService';
import NewsKeywords from '../shared/components/NewsKeywords';
import { useExpand } from '../../shared/hooks/expand/useExpand';
import Icon from '../../shared/components/icon/Icon';
import { IconName } from '../../shared/components/icon/IconName';
import { ControlSize } from '../../shared/enums/ControlSize';
import { ColorScheme } from '../../shared/enums/ColorScheme';

interface Props {
  data: NewsFilterResultDto;
}

const summaryLength = 400;

const NewsSearchItem: React.FC<Props> = props => {
  const { data } = props;

  const url = NewsLocationService.getRoutePath(data.newsKey);

  let { expandedData: summary } = useExpand(data.newsSummary, summaryLength) as { expandedData: string };

  summary =
    summary.substring(0, 150) +
    summary.substring(150, summary.length).replace(/<br\s*\/?>\r*\n*\r*\n*<br\s*\/?>/, '...');
  const indexOfEllipses = summary.indexOf('...');
  if (indexOfEllipses == -1) {
    summary = summary.substring(0, 300) + '...';
  } else {
    summary = summary.substring(0, indexOfEllipses + 3);
  }

  return (
    <div className="NewsSearchItem">
      <header className="NewsSearchItem__header">
        <div className="NewsSearchItem__date">
          <FormattedDate date={data.breakDate} />
        </div>
        {data.videoKey && (
          <div className="NewsSearchItem__icon">
            <Icon
              className={`${IconName.PlayCircle}`}
              name={IconName.PlayCircle}
              size={ControlSize.Large}
              colorScheme={ColorScheme.Attention}
            />
          </div>
        )}

        <Link className="NewsSearchItem__link" to={url}>
          {data.headline}
        </Link>
        <div className="NewsSearchItem__source">{data.source}</div>
      </header>

      <div className="NewsSearchItem__content">
        {data.companyLogo && (
          <NewsCompanyLogo ticker={data.tickerSymbol} companyLogo={data.companyLogo} alt={data.headline} />
        )}

        <div className="NewsSearchItem__text">
          <TextContainer>
            <div
              dangerouslySetInnerHTML={{
                __html: CompanyTemplateService.getTextWithHtmlLinksByTicker(summary),
              }}
            />
          </TextContainer>

          <NewsKeywords keywords={data.keywords} />
        </div>
      </div>
    </div>
  );
};

export default memo(NewsSearchItem);
