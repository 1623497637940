import { FileActionType } from '../../../../../shared/components/file-actions/enums/FileActionType';
import { screensLocationSwitchControl } from './controls/screensLocationSwitchControls';
import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { ScreensLocationFilterName } from '../enums/ScreensLocationFilterName';
import { CustomAny } from '../../../../../shared/types/generics';
import { LocationOptionValueService } from '../../../../../shared/entities/filters/services/LocationOptionValueService';
import { TimeFrame } from '../../../../../shared/entities/filters/enum/TimeFrame';
import { ViewColumnSystemName } from '../../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { ViewGridColumnWidth } from '../../../../../shared/entities/view/components/view-grid/enums/ViewGridColumnWidth';

export const screensLocationSharedOptions: Partial<ScreenOptions> = {
  header: 'Location Screen',
  toolbar: {
    fileActionTypes: [FileActionType.Save, FileActionType.Excel, FileActionType.Print],
    quickFilter: {
      hasLocation: true,
      hasIncludeSPAC: true,
      hasIncludeAShares: true,
      timeFrameOptions: [
        TimeFrame.Month,
        TimeFrame.Quarter,
        TimeFrame.YTD,
        TimeFrame.Year,
        TimeFrame.TwoYears,
        TimeFrame.All,
      ],
    },
  },
  search: {
    controls: [screensLocationSwitchControl],
  },
  screenTriggerOptions: {
    isInUseAvailable: filtersValues => {
      const value: CustomAny = filtersValues[ScreensLocationFilterName.ListingLocation];

      return !LocationOptionValueService.isAllLocationValue(value) && value;
    },
  },
  grid: {
    customColumnsSettings: [
      {
        field: ViewColumnSystemName.Sector,
        width: 140,
        minWidth: 140,
      },
      {
        field: ViewColumnSystemName.Headquarters,
        width: ViewGridColumnWidth.Lg,
        minWidth: ViewGridColumnWidth.Lg,
      },
    ],
  },
};
