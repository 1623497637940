import { CustomAny } from '../../../../types/generics';
import { useCallback, useState } from 'react';
import { useAsyncFn } from '../../../../hooks/async-fn/useAsyncFn';
import { FiltersValues } from '../../../filters/types/FiltersValues';
import { StatsApiService } from '../../../../api/StatsApiService';
import { StatsElementChartDynamicCharts } from '../chart/enums/StatsElementChartDynamicCharts';
import { StatsElementChartDto } from '../chart/types/StatsElementChartDto';
import { StatsElementData } from '../../interfaces/StatsElementData';
import { StatsElementOptions } from '../../interfaces/StatsElementOptions';
import { StatsElementChartsContextValue } from './StatsElementContext';

export function useStatsElementChartsContextValue({
  options,
  currentData,
  updateCurrentData,
  highCharts,
  pageFilters,
}: {
  pageFilters: FiltersValues;
  updateCurrentData: (newData: StatsElementData | undefined) => void;
  options?: StatsElementOptions;
  currentData?: StatsElementData;
  highCharts?: StatsElementChartDto[];
}): StatsElementChartsContextValue {
  const [loadingChartsIndexes, setLoadingChartsIndexes] = useState<number[]>([]);

  const addLoadingChart = useCallback(
    chartIndex => setLoadingChartsIndexes(prevLoadingChartsIndexes => [...prevLoadingChartsIndexes, chartIndex]),
    [],
  );

  const deleteLoadingChart = useCallback(
    chartIndex =>
      setLoadingChartsIndexes(prevLoadingChartsIndexes =>
        prevLoadingChartsIndexes.filter(prevLoadingChartIndex => chartIndex !== prevLoadingChartIndex),
      ),
    [],
  );

  const getIsChartLoading = useCallback((index: number) => loadingChartsIndexes.includes(index), [
    loadingChartsIndexes,
  ]);

  const getChartOptions = useCallback((index: number) => options?.chartsOptions?.[index], [options]);

  const getChartDataType = useCallback(
    (index: number) => {
      const chartData: CustomAny = currentData?.highCharts?.[index];
      const value: CustomAny = Object.values(chartData)[0];

      return value.type;
    },
    [currentData],
  );

  const setChartData = useCallback(
    (chartIndex: number, chartData: StatsElementChartDto) => {
      const newHighcharts = highCharts?.slice();

      if (newHighcharts) {
        newHighcharts[chartIndex] = chartData;
      }

      const newCurrentData = {
        ...currentData,
        highCharts: newHighcharts,
      };

      updateCurrentData(newCurrentData as StatsElementData);
    },
    [highCharts, currentData, updateCurrentData],
  );

  const updateChartData = useAsyncFn(
    useCallback(
      async (chartIndex: number, filters: FiltersValues) => {
        const chartDataType = getChartDataType(chartIndex);

        addLoadingChart(chartIndex);

        try {
          const newChartData = await StatsApiService.getStatsChartData(
            chartDataType as StatsElementChartDynamicCharts,
            {
              ...pageFilters,
              ...filters,
            },
          );

          setChartData(chartIndex, newChartData);
        } finally {
          deleteLoadingChart(chartIndex);
        }
      },
      [addLoadingChart, deleteLoadingChart, getChartDataType, pageFilters, setChartData],
    ),
  );

  return {
    highCharts,
    updateChartData,
    getIsChartLoading,
    getChartDataType,
    getChartOptions,
  };
}
