import React from 'react';
import './ViewColumnsAddSelectedItem.scss';
import { useClassName } from '../../../../../../../../../hooks/useClassName';
import {
  ViewColumnsAddSelectedItemBlock,
  ViewColumnsAddSelectedItemElement,
  ViewColumnsAddSelectedItemModifiers,
} from './viewColumnsAddSelectedItemBem';

interface Props {
  label?: string;
}

const ViewColumnsAddSelectedItem: React.FC<Props> = props => {
  const cn = useClassName(ViewColumnsAddSelectedItemBlock.Root);

  const isInfoColumn = (): boolean => {
    return !props.label;
  };

  return (
    <section className={cn()}>
      <div
        className={cn(ViewColumnsAddSelectedItemElement.Content, {
          [ViewColumnsAddSelectedItemModifiers.Info]: isInfoColumn(),
        })}
      >
        <div className={cn(ViewColumnsAddSelectedItemElement.Label)}>
          {props.label ? props.label : 'Select a Column from Above to Add to Screen'}
        </div>
      </div>
    </section>
  );
};

export default ViewColumnsAddSelectedItem;
