import { GridAgColumn } from '../../../../../components/grid/grid/interfaces/GridAgColumn';
import { ViewGridColumnGroupDto } from '../interfaces/ViewGridColumnGroupDto';
import { ViewGridSingleColumnFactory } from './ViewGridSingleColumnFactory';
import { ViewGridFittedColumnFactory } from './ViewGridFittedColumnFactory';
import { ViewGridColumnDto } from '../interfaces/ViewGridColumnDto';
import { ViewGridColumnsSetupOptions } from '../interfaces/ViewGridColumnsSetupOptions';
import { Nullable } from '../../../../../types/generics';
import { GridSort } from '../../../../../components/grid/grid/interfaces/GridSort';
import { SortDirection } from '../../../../../enums/SortDirection';

export class ViewGridColumnFactory {
  static getColumn(columnGroup: ViewGridColumnGroupDto, setupOptions?: ViewGridColumnsSetupOptions): GridAgColumn {
    const viewColumn = this.getFirstViewColumn(columnGroup);

    const column = this.isFittedColumn(columnGroup)
      ? ViewGridFittedColumnFactory.getColumn(columnGroup, setupOptions)
      : ViewGridSingleColumnFactory.getColumn(viewColumn, setupOptions);

    return {
      ...column,
      ...this.getSort(columnGroup.name, setupOptions?.sort || null),
    };
  }

  static isFittedColumn(columnGroup: ViewGridColumnGroupDto): boolean {
    return columnGroup.nestedColumns.length > 1;
  }

  private static getFirstViewColumn(columnGroup: ViewGridColumnGroupDto): ViewGridColumnDto {
    return columnGroup.nestedColumns[0];
  }

  private static getSort(columnSystemName: string, sort: Nullable<GridSort>): { sort: SortDirection } | {} {
    if (!sort || columnSystemName !== sort.column) {
      return {};
    }

    return {
      sort: sort.direction,
    };
  }
}
