import React, { useCallback, useContext } from 'react';
import FormGroup from '../../../../../../../../components/forms/FormGroup';
import FormInput from '../../../../../../../../components/forms/FormInput';
import { InputType } from '../../../../../../../../components/forms/Input';
import { SaveViewFormData } from './SaveViewFormData';
import { SaveViewFormFields } from './SaveViewFormFields';
import { validationSchema } from './validationSchema';
import { ControlSize } from '../../../../../../../../enums/ControlSize';
import { ViewContext } from '../../../../ViewContext';
import FormEntityActionModal from '../../../../../../../../components/modal/FormEntityActionModal';
import { ModalEntityActionType } from '../../../../../../../../components/modal/enums/ModalEntityActionType';
import { ModalShowState } from '../../../../../../../../components/modal/types/ModalShowState';
import { ViewSaveData } from '../../../view-modal/interfaces/ViewSaveData';
import { updateViewByDataChange } from '../../../../store/actions/view';
import { ViewTypeService } from '../../../../services/ViewTypeService';

interface Props {
  showState: ModalShowState;
  viewSaveData: Partial<ViewSaveData>;
  onHide?: () => void;
}

const SaveViewModalForm: React.FC<Props> = props => {
  const { dispatch, state } = useContext(ViewContext);

  const onSubmit = useCallback(
    async (data: SaveViewFormData) => {
      dispatch(updateViewByDataChange(props.viewSaveData, data[SaveViewFormFields.Name]));
    },
    [dispatch, props.viewSaveData],
  );

  return (
    <FormEntityActionModal
      showState={props.showState}
      validationSchema={validationSchema}
      entityName={ViewTypeService.getEntityName(state.viewType)}
      actionType={ModalEntityActionType.Save}
      body={
        <FormGroup>
          <FormInput
            name={SaveViewFormFields.Name}
            type={InputType.Text}
            placeholder="Enter a Name"
            size={ControlSize.Small}
          />
        </FormGroup>
      }
      onSubmit={onSubmit}
      onHide={props.onHide}
    />
  );
};

export default SaveViewModalForm;
