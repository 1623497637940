import { SelectOption } from '../../../../../shared/services/select/interfaces/SelectOption';
import { NewsRcExternalFilterType } from '../enums/NewsRcExternalFilterType';

export const newsRcExternalFilterOptions: SelectOption[] = [
  {
    value: NewsRcExternalFilterType.All,
    title: NewsRcExternalFilterType.All,
  },
  {
    value: NewsRcExternalFilterType.RCOnly,
    title: NewsRcExternalFilterType.RCOnly,
  },
  {
    value: NewsRcExternalFilterType.ExternalNewsOnly,
    title: NewsRcExternalFilterType.ExternalNewsOnly,
  },
];
