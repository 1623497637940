import React from 'react';
import { ViewOptions } from '../../../shared/entities/view/components/view/interfaces/options/ViewOptions';
import ScheduledCalendar from '../components/ScheduledCalendar';
import { scheduledStaticViewOptions } from './scheduledStaticViewOptions';
import { CalendarEvent } from '../../../shared/entities/calendar/interfaces/CalendarEvent';
import { ScheduledCalendarDataService } from '../services/ScheduledCalendarDataService';
import { ScheduledAdditionalDataProp } from '../enums/ScheduledAdditionalDataProp';
import { ViewColumnSystemName } from '../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { GridHeightMultiplier } from '../../../shared/components/grid/grid/enums/GridHeightMultiplier';
import { ViewGridColumnWidth } from '../../../shared/entities/view/components/view-grid/enums/ViewGridColumnWidth';

export const scheduledViewOptions: ViewOptions = {
  ...scheduledStaticViewOptions,
  grid: {
    customColumnsSettings: [
      {
        field: ViewColumnSystemName.CompanyWithDescription,
        heightMultiplier: GridHeightMultiplier.Sx,
      },
      {
        field: ViewColumnSystemName.OfferDate,
        width: ViewGridColumnWidth.Sx,
        minWidth: ViewGridColumnWidth.Sx,
      },
    ],
    isFixedHeight: false,
  },
  additionalDataOptions: [
    {
      fn: (): Promise<CalendarEvent[]> => ScheduledCalendarDataService.getEvents(),
      prop: ScheduledAdditionalDataProp.CalendarEvents,
    },
  ],
  topContent: <ScheduledCalendar />,
} as ViewOptions;
