import React, { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ClientSideRowModelModule,
  GridApi,
  GridReadyEvent,
  RowDataUpdatedEvent,
  RowGroupingModule,
} from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from '@ag-grid-community/react';
import './Grid.scss';
import { debounce } from 'lodash';
import { GridRow } from '../shared/types/GridRow';
import { GridAgColumn } from './interfaces/GridAgColumn';
import { GridCellFormatterType } from '../shared/enums/GridCellFormatterType';
import LinkCellFormatter from '../shared/components/formatters/cell/LinkCellFormatter';
import ColorNumberCellFormatter from '../shared/components/formatters/cell/ColorNumberCellFormatter';
import ColorSchemeCellFormatter from '../shared/components/formatters/cell/ColorSchemeCellFormatter';
import { OnGridSortFn } from '../../../entities/view/components/view/types/actionFunctions';
import FavoriteCellFormatter from '../shared/components/formatters/cell/FavouriteCellFormatter';
import { GridSortService } from './services/GridSortService';
import FileActionsCellFormatter from '../shared/components/formatters/cell/FileActionsCellFormatter';
import useBreakpointAvailable from '../../../effects/useBreakpointAvailable';
import { GridDomLayout } from './enums/GridDomLayout';
import CustomCellFormatter from '../shared/components/formatters/cell/CustomCellFormatter';
import { GridHeaderService } from './services/GridHeaderService';
import GridScroll from './GridScroll';
import GridOverlay from './GridOverlay';
import { ViewColumnSystemName } from '../../../entities/view/components/view-grid/enums/ViewColumnSystemName';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { AG_GRID_LICENSE_KEY } from '../../../constants/environment';
import { GridLayoutService } from './services/GridLayoutService';
import { OnGridReadyFn } from './types/OnGridReadyFn';
import { GridApiService } from './services/GridApiService';
import { OnGridViewportChangedFn } from './types/OnGridViewportChangedFn';
import { noGridData } from '../shared/constants/grid';
import { GridColumnFactory } from './factories/GridColumnFactory';
import { GridRowService } from './services/GridRowService';
import { ROWS_BUFFER_DEFAULT } from './constants/rows';
import { GridPaginationOptions } from './interfaces/GridPaginationOptions';
import { PortionSize } from '../../../enums/PortionSize';
import { GridCountReducerOptions } from './interfaces/GridCountReducerOptions';
import CountReducerControl from '../../forms/components/count-reducer-control/CountReducerControl';
import { CountControlService } from '../../forms/components/count-reducer-control/services/CountControlService';
import ContainerCellFormatter from '../shared/components/formatters/cell/ContainerCellFormatter';
import GridPaginationSize from './components/pagination/GridPaginationSize';
import { defaultPageSize } from './constants/pagination';
import { ControlSize } from '../../../enums/ControlSize';
import { useClassName } from '../../../hooks/useClassName';
import { GRID_COLUMNS_PIN_BREAKPOINTS } from './constants/gridColumnsPinBreakpoints';
import { ComponentMessage } from '../../../enums/ComponentMessage';

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

export interface GridProps {
  rows: GridRow[];
  columns: GridAgColumn[];
  error?: string;
  isLoading?: boolean;
  mobilePinColumns?: string[];
  isHeaderHidden?: boolean;
  isFixedHeight?: boolean;
  isColumnsFitGrid?: boolean;
  idCols?: string[];
  hasPagination?: boolean;
  paginationOptions?: GridPaginationOptions;
  hasCountReducer?: boolean;
  countReducerOptions?: GridCountReducerOptions;
  maxGridWidth?: ControlSize;
  className?: string;
  onGridSort?: OnGridSortFn;
  onGridReady?: OnGridReadyFn;
  onViewportChanged?: OnGridViewportChangedFn;
  overlayNoRowsTemplate?: ComponentMessage;
  onChange?: () => void;
}

const AG_GRID_MODULES = [ClientSideRowModelModule, RowGroupingModule];

const DEFAULT_COL_DEF = {
  suppressMovable: true,
  menuTabs: [],
};

const FRAMEWORK_COMPONENTS: { [key: string]: ReactNode } = {
  [GridCellFormatterType.Link]: LinkCellFormatter,
  [GridCellFormatterType.ColorNumber]: ColorNumberCellFormatter,
  [GridCellFormatterType.ColorScheme]: ColorSchemeCellFormatter,
  [GridCellFormatterType.Custom]: CustomCellFormatter,
  [GridCellFormatterType.Favourite]: FavoriteCellFormatter,
  [GridCellFormatterType.FileActions]: FileActionsCellFormatter,
  [GridCellFormatterType.Container]: ContainerCellFormatter,
};

const VIEW_PORT_CHANGE_DELAY = 500;

const COUNT_REDUCER_SIZE = [PortionSize.Ten, PortionSize.TwentyFive, PortionSize.Fifty, PortionSize.OneHundred];

const Grid: React.FC<GridProps> = props => {
  const {
    rows = [],
    isLoading = false,
    mobilePinColumns,
    isHeaderHidden = false,
    isFixedHeight = true,
    idCols = [ViewColumnSystemName.Id],
    isColumnsFitGrid = false,
    maxGridWidth,
    columns,
    onGridSort,
    onViewportChanged = (): void => {},
  } = props;

  const cn = useClassName('Grid', `${props.className} ag-theme-balham`);

  const paginationOptions = useMemo(
    () => ({
      pageSize: defaultPageSize,
      ...props.paginationOptions,
    }),
    [props.paginationOptions],
  );

  const countReducerOptions = useMemo(
    () => ({
      sizes: COUNT_REDUCER_SIZE,
      ...props.countReducerOptions,
    }),
    [props.countReducerOptions],
  );
  const countReducerSizeInitial = useMemo(() => CountControlService.getInitialValue(countReducerOptions.sizes), [
    countReducerOptions.sizes,
  ]);
  const [countReducerSize, setCountReducerSize] = useState(countReducerSizeInitial);

  const ref = useRef<HTMLElement>(null);

  const [isInit, setIsInit] = useState<boolean>(false);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [rowsBuffer, setRowsBuffer] = useState(ROWS_BUFFER_DEFAULT);

  const visibleRows = useMemo(() => {
    if (props.hasCountReducer) {
      return GridRowService.sliceRows(rows, countReducerSize);
    }

    return rows;
  }, [rows, countReducerSize, props.hasCountReducer]);

  const isPinColumnsBreakpoint: boolean = useBreakpointAvailable(GRID_COLUMNS_PIN_BREAKPOINTS);

  const shouldPinColumns = useMemo<boolean>(() => isPinColumnsBreakpoint && !!mobilePinColumns, [
    isPinColumnsBreakpoint,
    mobilePinColumns,
  ]);

  const { simpleColumns, treeColumn } = useMemo(() => {
    return GridColumnFactory.getLibColumns(columns, {
      isColumnsFitGrid,
      shouldPinColumns,
      pinColumns: mobilePinColumns,
    });
  }, [columns, isColumnsFitGrid, shouldPinColumns, mobilePinColumns]);

  const rowHeight = useMemo(() => GridRowService.getRowHeight(columns), [columns]);

  const getTreeDataPath = useMemo(() => treeColumn && ((row: GridRow): string[] => row[`${treeColumn.field}`]), [
    treeColumn,
  ]);

  const getRowNodeId = useCallback((row: GridRow): string => GridRowService.getRowId(row, idCols), [idCols]);

  const isOverlayAvailable = useMemo<boolean>((): boolean => {
    return !!ref.current && (isLoading || !!props.error);
  }, [isLoading, props.error]);

  const isScrollAvailable = useMemo<boolean>((): boolean => {
    return isInit && !isLoading && !!gridApi && !!ref.current;
  }, [isInit, isLoading, gridApi]);

  const domLayout = useMemo<GridDomLayout>(() => GridLayoutService.getDomLayout(isFixedHeight), [isFixedHeight]);

  const hasPaginationPanel = useMemo(() => props.hasPagination, [props.hasPagination]);
  const isPaginationSizeAvailable = useMemo(() => hasPaginationPanel && isInit && !!gridApi, [
    hasPaginationPanel,
    isInit,
    gridApi,
  ]);

  const onGridSortEvent = useCallback(
    event => {
      if (!isInit) {
        return;
      }

      onGridSort?.(GridSortService.getColumnSortFromGrid(event.columnApi.getColumnState()));
    },
    [onGridSort, isInit],
  );

  const autoSizeColumnsWhenReady = useCallback((event: { api: GridApi }): void => {
    const animationEndEvent = 'animationQueueEmpty';

    const autoSizeColumns = (event: { api: GridApi }): void => {
      event.api && event.api.removeEventListener(animationEndEvent, autoSizeColumns);
    };

    // wait for the render to complete: animationQueue will be empty once it is
    if (!event.api.isAnimationFrameQueueEmpty()) {
      event.api.addEventListener(animationEndEvent, autoSizeColumns);
    } else {
      autoSizeColumns(event);
    }
  }, []);

  const onPrint = useCallback(
    (isPrint: boolean, api: GridApi) => {
      if (isPrint) {
        setRowsBuffer(GridRowService.getRowsCount(rows));
      } else {
        setRowsBuffer(ROWS_BUFFER_DEFAULT);
      }
    },
    [setRowsBuffer, rows],
  );

  const onGridReady = useCallback(
    (event: GridReadyEvent): void => {
      setGridApi(event.api);

      props.onGridReady?.(GridApiService.getPublicApi(event.api, domLayout, onPrint));

      if (isColumnsFitGrid) {
        autoSizeColumnsWhenReady(event);
      }

      setIsInit(true);
    },
    [domLayout, props.onGridReady, autoSizeColumnsWhenReady, isColumnsFitGrid, onPrint],
  );

  const onRowDataUpdated = useCallback(
    (event: RowDataUpdatedEvent) => {
      // TODO: test for differet set of columns
      // If fine then move check to autoSizeColumnsWhenReady method
      if (props.onChange) {
        props.onChange();
      }

      if (isColumnsFitGrid) {
        autoSizeColumnsWhenReady(event);
      }
    },
    [autoSizeColumnsWhenReady, isColumnsFitGrid],
  );

  const onCountReducerChange = useCallback((size: PortionSize) => {
    setCountReducerSize(size);
  }, []);

  useEffect(() => {
    gridApi && gridApi.paginationGoToFirstPage();
  }, [gridApi, visibleRows]);

  useEffect(() => {
    const params = {
      force: true,
      columns: ['favorite'],
    };
    gridApi && gridApi.refreshCells(params);
    gridApi && gridApi.setRowData([]);
    gridApi && gridApi.setRowData(visibleRows);
  }, [visibleRows]);

  return (
    <section
      ref={ref}
      className={cn({
        headerHidden: isHeaderHidden,
        fixedHeight: isFixedHeight,
        initing: !isInit,
        [`${maxGridWidth}`]: maxGridWidth,
        pagination: hasPaginationPanel,
      })}
    >
      {props.hasCountReducer && (
        <CountReducerControl
          className="Grid__countReducer"
          sizes={countReducerOptions.sizes}
          initialValue={countReducerSizeInitial}
          onChange={onCountReducerChange}
        />
      )}

      <div className="Grid__gridContainer">
        <AgGridReact
          // Tree column definition
          autoGroupColumnDef={treeColumn}
          treeData={!!treeColumn}
          getDataPath={getTreeDataPath}
          // End Tree column definition
          defaultColDef={DEFAULT_COL_DEF}
          columnDefs={simpleColumns}
          rowData={visibleRows}
          rowHeight={rowHeight}
          headerHeight={GridHeaderService.HEADER_HEIGHT_DEFAULT}
          suppressNoRowsOverlay={false}
          suppressHorizontalScroll={isScrollAvailable}
          domLayout={domLayout}
          suppressLoadingOverlay={true}
          suppressCellSelection={false}
          suppressDragLeaveHidesColumns={true}
          immutableData={true}
          suppressContextMenu={true}
          suppressColumnVirtualisation={true}
          suppressRowClickSelection={false}
          overlayNoRowsTemplate={props.overlayNoRowsTemplate || noGridData}
          rowBuffer={rowsBuffer}
          pagination={props.hasPagination}
          paginationPageSize={paginationOptions.pageSize}
          suppressPaginationPanel={!hasPaginationPanel}
          applyColumnDefOrder
          debounceVerticalScrollbar
          disableStaticMarkup
          getRowNodeId={getRowNodeId}
          onGridReady={onGridReady}
          onSortChanged={onGridSortEvent}
          onViewportChanged={debounce(onViewportChanged, VIEW_PORT_CHANGE_DELAY)}
          onRowDataUpdated={onRowDataUpdated}
          frameworkComponents={FRAMEWORK_COMPONENTS}
          modules={AG_GRID_MODULES}
        />

        {isOverlayAvailable && (
          <GridOverlay isLoading={isLoading} error={props.error} parentEl={ref.current as HTMLElement} />
        )}

        {isScrollAvailable && <GridScroll parentEl={ref.current as HTMLElement} gridApi={gridApi as GridApi} />}

        {isPaginationSizeAvailable && (
          <GridPaginationSize
            parentEl={ref.current as HTMLElement}
            gridApi={gridApi as GridApi}
            initialPageSize={paginationOptions.pageSize}
          />
        )}
      </div>
    </section>
  );
};

export default memo(Grid);
