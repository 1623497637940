export enum CompanyTitleWrapperBlock {
  Root = 'CompanyTitleWrapper',
}

export enum CompanyTitleWrapperElement {
  Content = 'content',
}

export enum CompanyTitleWrapperModifier {
  Ellipsis = 'ellipsis',
}
