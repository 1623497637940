export enum StatsFilterName {
  IncludeSPACs = 'includeSpacs',
  IncludeAShares = 'includeAShares',
  CountryRegion = 'countryRegion',
  ADRsOnly = 'adrsOnly',
  _IPOsFromDateRange = '_IPOsFromDateRange',
  IPOsFrom = 'iposFrom',
  IPOsFromMax = 'iposFromMax',
  IPOsFromMin = 'iposFromMin',
  Industry = 'Industry',
  IncludeSpac = 'IncludeSpac',
}
