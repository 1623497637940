import React, { useContext, useMemo } from 'react';
import CompanyProfileSideItem from '../CompanyProfileSideItem';
import './CompanySecFilings.scss';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProfileData } from '../../../../../interfaces/ProfileData';
import { CompanyTab } from '../../../../../enums/CompanyTab';
import Hyperlink from '../../../../../../shared/components/typography/Hyperlink';

const CompanySecFilings: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const profile: ProfileData = getTabData(CompanyTab.Profile);

  const data = useMemo(() => profile.description, [profile]);

  const isData = useMemo(() => data?.secFilingsLink || data?.secProspectusLink, [data]);

  return (
    <>
      {isData && (
        <CompanyProfileSideItem
          className="CompanySecFilings"
          title={
            <>
              <Hyperlink to={data?.secFilingsLink as string} isExternal isBlank>
                {data?.secFilingsLink && 'SEC Filings'}
              </Hyperlink>
              <Hyperlink to={data?.secProspectusLink as string} isExternal isBlank>
                Prospectus
              </Hyperlink>
            </>
          }
        />
      )}
    </>
  );
};

export default CompanySecFilings;
