import { PortionSize } from '../../../../../enums/PortionSize';
import { SelectOption } from '../../../../../services/select/interfaces/SelectOption';

export class CountControlService {
  static getOptions(sizes: PortionSize[]): SelectOption[] {
    return sizes.map((item: PortionSize) => ({
      title: item.toString(),
      value: item,
    }));
  }

  static getInitialValue(sizes: PortionSize[]): PortionSize {
    return sizes[0];
  }
}
