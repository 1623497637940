import React, { useCallback } from 'react';
import './ViewLink.scss';
import { ViewReqParamsBase } from '../view/interfaces/api/ViewReqParamsBase';
import { useAsyncFn } from '../../../../hooks/async-fn/useAsyncFn';
import { ViewApiService } from '../../../../api/ViewApiService';
import { useClassName } from '../../../../hooks/useClassName';
import { ViewLinkBlock, ViewLinkModifier } from './viewLinkBem';
import Loader from '../../../../components/loader/Loader';
import { ViewLocationService } from '../view/services/ViewLocationService';

interface Props {
  title: string;
  data: ViewReqParamsBase;
  hasLinkStyle?: boolean;
}

const ViewLink: React.FC<Props> = props => {
  const cn = useClassName(ViewLinkBlock.Root);

  const [createView, isLoading] = useAsyncFn(async () => {
    const viewId = await ViewApiService.createView(props.data);
    ViewLocationService.navigateToView(props.data.viewType, viewId);
  });

  const onTitleClick = useCallback(() => {
    if (!isLoading) {
      createView();
    }
  }, [createView, isLoading]);

  return (
    <span
      className={cn({
        [ViewLinkModifier.Loading]: isLoading,
        [ViewLinkModifier.LinkStyle]: props.hasLinkStyle,
      })}
      onClick={onTitleClick}
    >
      {props.title}

      {isLoading && <Loader />}
    </span>
  );
};

export default ViewLink;
