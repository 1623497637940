import { Context, createContext } from 'react';
import { FilterSettings } from '../../../interfaces/FilterSettings';
import { ViewFiltersDictionaries } from '../../../interfaces/ViewFiltersDictionaries';
import { FilterSectionsFormState } from '../interfaces/FilterSectionsFormState';
import { FilterSectionType } from '../../../enum/FilterSectionType';
import { BreakpointName } from '../../../../../enums/BreakpointName';
import { AnyObject, CustomAny } from '../../../../../types/generics';
import { ChangeFormFn } from '../../../../../components/forms/types/ChangeFormFn';
import { FormValidationStateChangeFn } from '../../../../../components/forms/types/FormValidationStateChangeFn';

export interface FilterSectionsFormContextValue {
  isAdvanced: boolean;
  settings: FilterSettings;
  dictionaries?: Partial<ViewFiltersDictionaries>;
  sectionsStates: FilterSectionsFormState[];
  sectionsTypes: FilterSectionType[];
  sectionsBreakpoints: BreakpointName[] | undefined;
  defaultValues?: AnyObject;
  onCloseChange: (types: FilterSectionType[], isClose: boolean) => void;
  onFormChange: ChangeFormFn<CustomAny>;
  onValidationStateChange?: FormValidationStateChangeFn;
}

export const FilterSectionsFormContext: Context<FilterSectionsFormContextValue> = createContext<
  FilterSectionsFormContextValue
>({
  isAdvanced: false,
  settings: {
    sections: [],
  },
  sectionsStates: [],
  sectionsTypes: [],
  sectionsBreakpoints: undefined,
  onCloseChange: () => {},
  onFormChange: () => {},
});
