import { FilterControlSettings } from '../../../types/FilterControlSettings';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import { FormControlType } from '../../../../../components/forms/components/form-builder/enums/FormControlType';
import { InputType } from '../../../../../components/forms/Input';
import { ControlSize } from '../../../../../enums/ControlSize';
import { PrependValueType } from '../../../../../components/forms/enums/PrependValueType';
import { AppendValueType } from '../../../../../components/forms/enums/AppendValueType';

export const investorsControls: FilterControlSettings = {
  [ViewFilterName.Investor]: {
    type: FormControlType.Select,
    name: ViewFilterName.Investor,
    props: {
      name: ViewFilterName.Investor,
      label: 'Investor',
      placeholder: 'Choose Investor',
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isMultiSelect: false,
    },
  },
  [ViewFilterName.Investor]: {
    type: FormControlType.Select,
    name: ViewFilterName.Investor,
    props: {
      name: ViewFilterName.Investor,
      label: 'Investor',
      placeholder: 'Choose Investor',
      size: ControlSize.Small,
      width: ControlSize.Medium,
    },
  },
  [ViewFilterName.Ownership]: {
    type: FormControlType.Input,
    name: ViewFilterName.Ownership,
    props: {
      name: ViewFilterName.Ownership,
      label: 'Post-IPO Ownership',
      type: InputType.Number,
      size: ControlSize.Small,
      width: ControlSize.Small,
      prepend: [PrependValueType.GreaterEqual],
      append: [AppendValueType.Percentage],
    },
  },
  [ViewFilterName.TopShareholder]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.TopShareholder,
    props: {
      name: ViewFilterName.TopShareholder,
      label: 'Top Shareholder',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.HistoryShareholders]: {
    type: FormControlType.Input,
    name: ViewFilterName.HistoryShareholders,
    props: {
      name: ViewFilterName.HistoryShareholders,
      label: 'Historical Shareholders:',
      size: ControlSize.Small,
      width: ControlSize.Medium,
    },
  },
  [ViewFilterName.SpinOff]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.SpinOff,
    props: {
      name: ViewFilterName.SpinOff,
      label: 'Spinoff',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.MgmtOwned]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.MgmtOwned,
    props: {
      name: ViewFilterName.MgmtOwned,
      label: 'Founder/Management Owned',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.PE]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.PE,
    props: {
      name: ViewFilterName.PE,
      label: 'PE Backed: LBO',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.PeGrowthOther]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.PeGrowthOther,
    props: {
      name: ViewFilterName.PeGrowthOther,
      label: 'PE Backed: Growth/Other',
      size: ControlSize.Small,
    },
  },
  [ViewFilterName.VentureCapital]: {
    type: FormControlType.Checkbox,
    name: ViewFilterName.VentureCapital,
    props: {
      name: ViewFilterName.VentureCapital,
      label: 'Venture Capital',
      size: ControlSize.Small,
    },
  },
};
