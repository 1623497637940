import React from 'react';
import TreeNode, { TreeNodeCommonProps } from './TreeNode';
import { useClassName } from '../../hooks/useClassName';
import './TreeView.scss';
import { TreeViewBlock } from './treeViewBem';

interface Props {
  nodes: TreeNodeCommonProps[];
  className?: string;
}

const TreeView: React.FC<Props> = props => {
  const cn = useClassName(TreeViewBlock.Root, props.className);

  return (
    <div className={cn()}>
      {props.nodes.map((node: TreeNodeCommonProps, index: number) => (
        <TreeNode key={index} {...node} />
      ))}
    </div>
  );
};

export default TreeView;
