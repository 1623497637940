import React, { useCallback, useContext, useMemo } from 'react';
import { ViewType } from '../../../../../../shared/entities/view/components/view/enums/ViewType';
import { screensCustomInUseOptions } from './constants/screensCustomInUseOptions';
import ScreensInUse from '../../../../shared/pages/components/in-use/ScreensInUse';
import { ScreensContext } from '../../../../../contexts/screensContext';

const ScreensCustomInUse: React.FC = () => {
  const { savedScreensData } = useContext(ScreensContext);
  const [, , , setSavedScreens] = savedScreensData;

  const onSavedViewsChange = useCallback(savedViews => setSavedScreens(savedViews), [setSavedScreens]);

  const events = useMemo(() => ({ onSavedViewsChange }), [onSavedViewsChange]);

  return <ScreensInUse inUseType={ViewType.CustomInUse} options={screensCustomInUseOptions} events={events} />;
};

export default ScreensCustomInUse;
