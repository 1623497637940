import { FormControl, FormControlCheckboxList } from '../types/formControlsTypes';
import { FormControlType } from '../enums/FormControlType';
import { CheckboxType } from '../../../Checkbox';
import { AnyObject } from '../../../../../types/generics';
import { SelectOptionValue } from '../../../../../services/select/types/SelectOptionValue';
import { FORM_CONTROL_NO_ACTIVE_COUNT } from '../constants/counters';

export class FormControlCheckboxListService {
  static isCheckboxType(control: FormControl): boolean {
    return control.type === FormControlType.CheckboxList && control.props.type === CheckboxType.Checkbox;
  }

  static deleteCheckboxTypeValues(
    deleteValue: SelectOptionValue,
    control: FormControlCheckboxList,
    values: AnyObject,
  ): AnyObject {
    const name = control.props.name;
    const value: SelectOptionValue[] | undefined = values[name];
    const newValue = value && value.filter(valueItem => valueItem !== deleteValue);

    return {
      ...values,
      [name]: newValue,
    };
  }

  static getCheckboxTypeActiveControlValues(controlValues: SelectOptionValue[][] = []): number {
    const controlValue = controlValues[0]; // This type always have only one value

    if (controlValue) {
      return controlValue.length;
    } else {
      return FORM_CONTROL_NO_ACTIVE_COUNT;
    }
  }
}
