import React, { useContext, useMemo } from 'react';
import { CompanyContext } from '../context/CompanyContext';
import { CompanyParagraphItem } from '../../interfaces/CompanyParagraphItem';
import CompanyParagraph from '../shared/CompanyParagraph';
import { CompanyFundamentalInsightsDto } from '../../interfaces/CompanyFundamentalInsightsDto';
import { CompanyTab } from '../../enums/CompanyTab';
import CompanySection from '../shared/CompanySection';
import { Nullable } from '../../../shared/types/generics';
import HtmlStringFormatterJustified from '../../../shared/components/HtmlStringFormatterJustified';

const CompanyFundamentalInsights: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const data: Nullable<CompanyFundamentalInsightsDto> = getTabData(CompanyTab.FundamentalInsights);

  const titleProps = useMemo(() => {
    const ratingValue = data?.fundamentalRating;

    return {
      tab: CompanyTab.FundamentalInsights,
      ratingTitle: 'Fundamental Rating',
      ratingValue,
      lastUpdated: data?.fundInsightsPostDate,
    };
  }, [data]);

  return (
    <>
      {data && <h3 className="printHead">Fundamental Insights</h3>}
      <CompanySection className="CompanyFundamentalInsights" data={data} titleProps={titleProps}>
        {data?.paragraphs.map((item: CompanyParagraphItem, index: number) => (
          <CompanyParagraph
            key={index}
            label={item.label}
            text={<HtmlStringFormatterJustified value={item.text as string} />}
          />
        ))}
      </CompanySection>
    </>
  );
};

export default CompanyFundamentalInsights;
