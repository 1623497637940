import React from 'react';
import './SavedViewsSectionsGroup.scss';
import ActionListNavGroup from '../../../../../../components/nav/components/action-list/ActionListNavGroup';
import { BlockOrientation } from '../../../../../../enums/BlockOrientation';
import { useClassName } from '../../../../../../hooks/useClassName';
import { SavedViewsSectionsGroupBem } from './savedViewsSectionsGroupBem';

interface Props {
  orientation: BlockOrientation;
  className?: string;
}

const SavedViewsSectionsGroup: React.FC<Props> = props => {
  const cn = useClassName(SavedViewsSectionsGroupBem.Root, props.className);

  return (
    <ActionListNavGroup className={cn()} orientation={props.orientation}>
      {props.children}
    </ActionListNavGroup>
  );
};

export default SavedViewsSectionsGroup;
