import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import ViewDashboardSecondarySection from '../../entities/view/components/view-dashboard/components/ViewDashboardSecondarySection';
import ViewGrid from '../../entities/view/components/view-grid/ViewGrid';
import { ViewType } from '../../entities/view/components/view/enums/ViewType';
import { ViewDataTable } from '../../entities/view/components/view/interfaces/api/ViewDataTable';
import { ViewLocationService } from '../../entities/view/components/view/services/ViewLocationService';

interface Props {
  viewType?: ViewType;
  text?: string;
  dataTable: ViewDataTable;
  hasSearchBox?: boolean;
  hasActiveCheck?: boolean;
  dateFilters?: any;
  loadingGrid?: boolean;
  total?: any;
}

const BasicGrid: React.FC<Props> = props => {
  const [gridTable, setGridTable] = useState<ViewDataTable | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setGridTable(props.dataTable);
  }, [props.dataTable]);

  const path = useMemo(() => props.viewType && ViewLocationService.getRoutePath(props.viewType), [props.viewType]);

  function filterGridTable(value: any, checked: boolean) {
    const dataTableCopy = cloneDeep(props.dataTable);

    dataTableCopy.rows = dataTableCopy.rows.filter((row: any) => {
      for (const key in row) {
        if ((row[key] + '').toLowerCase().indexOf((value + '').toLowerCase()) > -1) {
          return checked === true ? row.isActive : true;
        }
      }
    });
    return dataTableCopy;
  }

  function filterResults(value: any, checked: boolean) {
    setGridTable(filterGridTable(value, checked));
  }

  function handleSearchTermChange(value: React.SetStateAction<string>) {
    setSearchTerm(value);
    filterResults(value, checked);
  }

  function handleCheckboxChange() {
    setChecked(checked => {
      setTimeout(() => filterResults(searchTerm, !checked));
      return !checked;
    });
  }
  return (
    <ViewDashboardSecondarySection title={props.text} routePath={path}>
      <div className="ga-filters">
        {props.hasActiveCheck && (
          <fieldset>
            <input id="active-checkbox" type="checkbox" checked={checked} onClick={handleCheckboxChange} />
            <label htmlFor="active-checkbox">Active Only</label>
          </fieldset>
        )}

        {props.hasSearchBox && (
          <fieldset>
            <label>Search:</label>
            <input
              className="Input__control form-control form-control-sm"
              type="text"
              value={searchTerm}
              onChange={e => handleSearchTermChange(e.target.value)}
            />
          </fieldset>
        )}

        {props.dateFilters && props.dateFilters}

        {props.total && (
          <span style={{ marginLeft: 'auto' }} className="totals">
            <strong>Total: {props.total}</strong>
          </span>
        )}
      </div>
      {gridTable && (
        <ViewGrid
          isLoading={props.loadingGrid === undefined ? false : props.loadingGrid}
          columns={gridTable?.columns}
          rows={gridTable?.rows}
        />
      )}
    </ViewDashboardSecondarySection>
  );
};

export default BasicGrid;
