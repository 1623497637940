import * as yup from 'yup';
import { ChangePasswordFormFields } from './change-password-form-fields';
import { getPasswordSchema } from '../../shared/components/forms/services/validation/contstants/validation/passwordSchema';
import { getConfirmPasswordSchema } from '../../shared/components/forms/services/validation/contstants/validation/confirmPasswordSchema';

export const validationSchema = yup.object().shape({
  [ChangePasswordFormFields.OldPassword]: getPasswordSchema('Old Password'),
  [ChangePasswordFormFields.NewPassword]: getPasswordSchema('New Password'),
  [ChangePasswordFormFields.ConfirmNewPassword]: getConfirmPasswordSchema(
    ChangePasswordFormFields.NewPassword,
    'Confirm New Password',
  ),
});
