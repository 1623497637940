import { createContext } from 'react';
import { GridPublicApi } from '../../../../../../components/grid/grid/interfaces/GridPublicApi';
import { GridAgColumn } from '../../../../../../components/grid/grid/interfaces/GridAgColumn';
import { ViewGridSearchProp } from '../../interfaces/ViewGridSearchProp';
import { ViewColumnSystemName } from '../../enums/ViewColumnSystemName';
import { GridRow } from '../../../../../../components/grid/shared/types/GridRow';

export interface ViewGridContextValue {
  setGridPublicApi: (gridPublicApi: GridPublicApi) => void;
  isGridVisible: boolean;
  hasHeader: boolean;
  columns: GridAgColumn[];
  idCols: ViewColumnSystemName[];
  mobilePinColumns: string[];
  rows: GridRow[];
  gridPublicApi?: GridPublicApi;
  search?: ViewGridSearchProp;
}

export const ViewGridContext = createContext<ViewGridContextValue>({
  isGridVisible: false,
  setGridPublicApi: () => {},
  hasHeader: false,
  columns: [],
  mobilePinColumns: [],
  idCols: [],
  rows: [],
});
