import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './FormCheckboxTextSelect.scss';
import FormTextSelect, { FormTextSelectProps } from './FormTextSelect';
import { useFormContext } from 'react-hook-form';
import uniqueId from 'lodash.uniqueid';
import { isBoolean } from 'lodash';
import classNames from 'classnames';
import Checkbox from './Checkbox';
import { usePrevious } from '../../effects/usePrevious';
import { SelectService } from '../../services/select/SelectService';

interface Props extends FormTextSelectProps {
  label: string;
}

const FormCheckboxTextSelect: React.FC<Props> = props => {
  const checkboxName = useMemo(() => uniqueId('FormCheckboxTextSelect-'), []);
  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);

  const formContext = useFormContext();
  const { watch, setValue } = formContext;
  const controlValue: string | boolean = watch(props.name) || false;
  const prevControlValue = usePrevious<string | boolean>(controlValue);

  const setCheckboxState = useCallback(
    (isCheck: boolean) => {
      const getDefaultValue = (): string | undefined =>
        props.options && (SelectService.getOptionByType(props.options[0]).value as string);

      const controlValue = isCheck ? getDefaultValue() : false;

      setValue(props.name, controlValue);
      setCheckboxValue(isCheck);
    },
    [props.name, props.options, setValue],
  );

  const onCheckboxClick = useCallback(
    (isCheck: boolean) => {
      setCheckboxState(isCheck);
    },
    [setCheckboxState],
  );

  useEffect(() => setCheckboxValue(!!controlValue), [setCheckboxValue, controlValue]);

  useEffect(() => {
    if (isBoolean(controlValue)) {
      if (!controlValue) {
        setCheckboxState(false);
        setValue(props.name, false);
      } else if (isBoolean(prevControlValue) && controlValue) {
        setCheckboxState(true);
      }
    }
  }, [controlValue, setCheckboxState, prevControlValue, props.name, setValue]);

  return (
    <FormTextSelect
      className={classNames('FormCheckboxTextSelect', {
        'FormCheckboxTextSelect--uncheck': !controlValue,
      })}
      name={props.name}
      options={props.options}
      label={
        <Checkbox
          name={checkboxName}
          label={props.label}
          isCheck={checkboxValue}
          onChange={onCheckboxClick}
          isRaw={true}
        />
      }
    />
  );
};

export default FormCheckboxTextSelect;
