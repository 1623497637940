export enum FileActionType {
  Save = 'save',
  Pdf = 'pdf',
  MGMTQ = 'q',
  Excel = 'excel',
  Print = 'print',
  Document = 'Paper',
  Link = 'link',
  Insight = 'insight',
}
