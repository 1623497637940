import React from 'react';
import axiosInstance from '../api/AxiosInstance';
import { ButtonSubmitBlock } from './forms/buttonSubmitBem';
import { useClassName } from '../hooks/useClassName';

type Props = {
  title: string;
  endpoint: string;
  className?: string;
};

const DownloadCsvButton: React.FC<Props> = props => {
  const cn = useClassName(ButtonSubmitBlock.Root, props.className);
  const handleDownload = async () => {
    // Your API response
    const response: any = await axiosInstance.get(props.endpoint);

    // Convert the data to a Blob
    const blob = new Blob([response['data']], { type: 'text/csv;charset=utf-8;' });

    // Create a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'data.csv');

    // Append the link to the body and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
  };

  return (
    <button className={cn() + ' btn btn-primary'} onClick={handleDownload}>
      {props.title}
    </button>
  );
};

export default DownloadCsvButton;
