import { searchFilterControls } from '../../../../../shared/entities/filters/constants/filter-controls/searchFilterControls';
import { FiltersMapValue } from '../../../../../shared/entities/filters/components/filterable-component/interfaces/FiltersMapValue';
import { ViewFilterName } from '../../../../../shared/entities/filters/enum/types/ViewFilterName';
import { screensDealStatusPriced } from '../../../../constants/controls/screensDealStatusControls';
import { ScreenOptions } from '../../../shared/pages/interfaces/ScreenOptions';
import { ViewSummaryAlignType } from '../../../../../shared/entities/view/components/view/enums/ViewSummaryAlignType';
import { TimeFrame } from '../../../../../shared/entities/filters/enum/TimeFrame';
import { estimatesSubheaderHelper } from '../../../../../views/constants/subHeaderHelpers';

export const screensEstimatesInUseOptions: Partial<ScreenOptions> = {
  header: 'Estimates Screen',
  subHeader: estimatesSubheaderHelper,
  search: {
    controls: [screensDealStatusPriced, searchFilterControls[ViewFilterName.Industry] as FiltersMapValue],
  },
  toolbar: {
    quickFilter: {
      timeFrameOptions: [
        TimeFrame.Month,
        TimeFrame.Quarter,
        TimeFrame.YTD,
        TimeFrame.Year,
        TimeFrame.TwoYears,
        TimeFrame.FiveYears,
      ],
    },
  },
  summary: {
    align: ViewSummaryAlignType.Grid,
  },
  screenTriggerOptions: {
    isInUseAvailable: (): boolean => true,
  },
};
