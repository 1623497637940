import React from 'react';
import Tooltip from '../../shared/components/tooltip/Tooltip';
import { ControlSize } from '../../shared/enums/ControlSize';

interface Props {}

export const PcmlCriteriaSubheader: React.FC<Props> = () => {
  const ttContent =
    'We consider a number of factors when adding to our PCW. Filing confidentially or hiring banks typically warrants an addition, but we also consider funding rounds, valuation, investors, key hires, news reports, comments from executives, and macroeconomic, geopolitical, sector, and company tailwinds/headwinds.';
  const ttTitle = 'criteria';
  return (
    <>
      The PCML is a curated list of companies that may be on track for an IPO, but do not meet the &nbsp;{' '}
      <Tooltip title={ttTitle} content={ttContent} isTitleHighlighted={true} size={ControlSize.Medium} /> &nbsp; of our
      PCW
    </>
  );
};
