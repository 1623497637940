import { NewsItemTag } from '../interfaces/NewsItemTag';
import { NewsType } from '../enums/NewsType';
import { NewsTagModifier } from '../enums/NewsTagModifier';
import { NewsLocationService } from '../shared/services/NewsLocationService';
import { NewsFilterField } from '../enums/NewsFilterField';

export function getNewsTypeTag(item: { type: NewsType }): NewsItemTag | undefined {
  // In accordance with https://wiki.itransition.com/pages/viewpage.action?pageId=606978107

  switch (item.type) {
    case NewsType.AnalystNote:
      return {
        title: 'RC Analyst Note',
        modifier: NewsTagModifier.Orange,
        url: NewsLocationService.createSearchUrl({
          [NewsFilterField.NewsType]: [NewsType.AnalystNote],
        }),
      };

    case NewsType.Blog:
      return {
        title: 'RC Commentary',
        modifier: NewsTagModifier.Orange,
        url: NewsLocationService.createSearchUrl({
          [NewsFilterField.NewsType]: [NewsType.Blog],
        }),
      };

    case NewsType.NewsAlert:
      return {
        title: 'RC',
        modifier: NewsTagModifier.Orange,
        url: NewsLocationService.createSearchUrl({
          [NewsFilterField.NewsType]: [NewsType.NewsAlert],
        }),
      };
  }
}
