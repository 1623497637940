import React from 'react';
import SecondarySectionsGroup from '../../../shared/components/secondary-section/SecondarySectionsGroup';
import SearchResultsCompaniesGrid from './SearchResultsCompaniesGrid';
import SearchResultsResearchGrid from './SearchResultsResearchGrid';
import SearchResultsNewsGrid from './SearchResultsNewsGrid';
import { SearchResultsBlock } from './SearchResultsBem';
import { useClassName } from '../../../shared/hooks/useClassName';
import SearchResultsVideosGrid from './SearchResultsVideosGrid';

interface Props {
  onChange?: () => void;
}
const SearchResultsDashboard: React.FC<Props> = props => {
  const cn = useClassName(SearchResultsBlock.Root);

  return (
    <SecondarySectionsGroup className={cn()}>
      <SearchResultsCompaniesGrid onChange={props.onChange} />
      <SearchResultsResearchGrid onChange={props.onChange} />
      <SearchResultsNewsGrid onChange={props.onChange} />
      <SearchResultsVideosGrid onChange={props.onChange} />
    </SecondarySectionsGroup>
  );
};

export default SearchResultsDashboard;
