import { ViewType } from '../../view/enums/ViewType';
import { ViewFilterName } from '../../../../filters/enum/types/ViewFilterName';
import { DealStatus } from '../../../../filters/enum/DealStatus';
import { ScreensLocationFilterName } from '../../../../../../screens/components/screens/location/enums/ScreensLocationFilterName';
import { ScreensLocationScreenByValue } from '../../../../../../screens/components/screens/location/enums/ScreensLocationScreenByValue';
import { TimeFrame } from '../../../../filters/enum/TimeFrame';
import { Location } from '../../../../filters/enum/Location';

export const getExchangeViewLinkData = (exchangeCountryKey: string) => ({
  sort: null,
  viewType: ViewType.LocationInUse,
  filters: {
    [ViewFilterName.DealStatusDealStatusList]: DealStatus.Priced,
    [ScreensLocationFilterName.ScreenBy]: ScreensLocationScreenByValue.ListingLocation,
    [ScreensLocationFilterName.ListingLocation]: exchangeCountryKey,
    [ViewFilterName.OfferTimeFrame]: TimeFrame.Year,
    [ViewFilterName.LocationListingLocation]: Location.Global,
  },
});
