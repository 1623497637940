import React, { useCallback } from 'react';
import PrimarySection from '../shared/components/primary-section/PrimarySection';
import PrimarySectionRow from '../shared/components/primary-section/PrimarySectionRow';
import SpecialReportsSection from './SpecialReportSection';
import { SpecialReportsApiService } from '../shared/api/SpecialReportsApiService';
import PageLoader from '../shared/components/loader/PageLoader';
import { usePromiseState } from '../shared/hooks/promise-state/usePromiseState';
import { SpecialReportsElement, SpecialReportsBlock } from './SpecialReportsBem';
import { useClassName } from '../shared/hooks/useClassName';
import Helmet from 'react-helmet';

const SpecialReports: React.FC = () => {
  const [data, isLoading] = usePromiseState(
    useCallback(() => SpecialReportsApiService.getGroups(), []),
    null,
  );
  const cn = useClassName(SpecialReportsBlock.Root);

  return (
    <>
      <Helmet>
        <title>Special Reports | IPO Intelligence - Renaissance Capital</title>
      </Helmet>
      <PrimarySection header="Special Reports">
        <PageLoader loading={isLoading}>
          <PrimarySectionRow className={cn(SpecialReportsElement.MoreRecentReports)}>
            {data && <SpecialReportsSection title={'Most Recent Reports'} reports={data.recentReports} />}
          </PrimarySectionRow>

          <PrimarySectionRow className={cn(SpecialReportsElement.AnnualReports)}>
            {data && <SpecialReportsSection title={'Annual Reviews'} reports={data.annualReviews} />}
          </PrimarySectionRow>

          <PrimarySectionRow className={cn(SpecialReportsElement.QuarterlyReports)}>
            {data && <SpecialReportsSection title={'Quarterly Reviews'} reports={data.quarterlyReviews} />}
          </PrimarySectionRow>

          <PrimarySectionRow className={cn(SpecialReportsElement.SpecialReports)}>
            {data && <SpecialReportsSection title={'Special Reports'} reports={data.specialReport} />}
          </PrimarySectionRow>
        </PageLoader>
      </PrimarySection>
    </>
  );
};

export default SpecialReports;
