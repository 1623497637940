import React from 'react';
import './NotificationToastBody.scss';
import ToastBody from '../toast/ToastBody';
import { useClassName } from '../../hooks/useClassName';
import { NotificationToastBodyBlock } from './notificationToastBodyBem';

interface Props {
  type: string;
  text: string;
}

const NotificationToastBody: React.FC<Props> = props => {
  const cn = useClassName(NotificationToastBodyBlock.Root);

  return (
    <ToastBody
      className={cn({
        [props.type]: props.type,
      })}
    >
      {props.text}
    </ToastBody>
  );
};

export default NotificationToastBody;
