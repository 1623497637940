import React, { useCallback } from 'react';
import './ClipboardCopy.scss';
import useClipboard from 'react-use-clipboard';

interface Props {
  valueToCopy: string;
  onCopy?: () => void;
}

const ClipboardCopy: React.FC<Props> = props => {
  const [, setCopied] = useClipboard(props.valueToCopy);

  const onCopyClick = useCallback(() => {
    setCopied();
    props.onCopy?.();
  }, [props.onCopy, setCopied]);

  return <div onClick={onCopyClick}>{props.children}</div>;
};

export default ClipboardCopy;
