import React from 'react';
import './StatsElementChartFilter.scss';
import { StatsElementChartFilterDynamic } from './StatsElementChartFilterDynamic';
import { StatsElementChartDto } from '../types/StatsElementChartDto';
import { StatsElementChartProps } from '../interfaces/StatsElementChartProps';
import { StatsElementChartData } from '../interfaces/StatsElementChartData';
import { FiltersValues } from '../../../../filters/types/FiltersValues';
import { StatsElementFilterInternalName } from '../../../enums/StatsElementGridFilterName';
import { useClassName } from '../../../../../hooks/useClassName';
import StatsElementChartFilterInternal from './StatsElementChartFilterInternal';

interface Props {
  data: StatsElementChartDto;
  chartValue?: StatsElementChartData;
  chartOptions?: Partial<StatsElementChartProps>;
  internalFilterValue?: StatsElementFilterInternalName;
  onChangeDynamicFilter: (filters: FiltersValues) => void;
  onChangeInternalFilter: (filter: StatsElementFilterInternalName) => void;
}

const StatsElementChartFilter: React.FC<Props> = props => {
  const { data, chartValue, chartOptions, internalFilterValue, onChangeDynamicFilter, onChangeInternalFilter } = props;

  const cn = useClassName('StatsElementChartFilter');

  return (
    <div className={cn()}>
      <StatsElementChartFilterDynamic
        className={cn('dynamic')}
        dynamicFilters={chartOptions?.dynamicFilters}
        options={chartValue?.filterDictionary}
        onChange={onChangeDynamicFilter}
      />

      <StatsElementChartFilterInternal
        className={cn('internal')}
        data={data}
        chartOptions={chartOptions}
        internalFilterValue={internalFilterValue}
        onChangeInternalFilter={onChangeInternalFilter}
      />
    </div>
  );
};

export default StatsElementChartFilter;
