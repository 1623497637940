import React, { useCallback, useMemo } from 'react';
import ViewDashboard from '../shared/entities/view/components/view-dashboard/ViewDashboard';
import DocumentHead from '../shared/components/DocumentHead';
import { RoutePath } from '../shared/enums/RoutePath';
import { useQueryParams } from '../shared/effects/useQueryParams';
import { SearchResultQueryParam } from './enums/SearchResultQueryParam';
import { SearchApiService } from '../shared/api/SearchApiService';
import LocationService from '../shared/services/LocationService';
import SearchResults from './SearchResults';
import { SearchResultsFilterGroupName } from './enums/SearchResultsFilterGroupName';
import { SearchResultsFilterName } from './enums/SearchResultsFilterName';
import { SearchResultsData } from './interfaces/SearchResultsData';
import { CustomAny } from '../shared/types/generics';

const SearchResultsContainer: React.FC = () => {
  const searchTerm = useQueryParams().get(SearchResultQueryParam.Search);

  const outerFilters = useMemo(
    () => ({
      [SearchResultsFilterGroupName.Search]: {
        [SearchResultsFilterName.SearchTerm]: searchTerm,
      },
    }),
    [searchTerm],
  );

  const getDataFn = useCallback((searchData: SearchResultsData) => SearchApiService.getSearchResults(searchData), []);

  if (!searchTerm) {
    LocationService.navigateNotFound();
    return <></>;
  }

  return (
    <ViewDashboard getDataFn={getDataFn as CustomAny} outerFilters={outerFilters}>
      <DocumentHead path={RoutePath.SearchResults} />

      <SearchResults searchValue={searchTerm} />
    </ViewDashboard>
  );
};

export default SearchResultsContainer;
