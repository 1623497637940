import { isEqual, sortBy, intersection } from 'lodash';

export class ArrayService {
  static areSameValues<T>(a: T[], b: T[]): boolean {
    return isEqual(sortBy(a), sortBy(b));
  }

  static containsSomeFromArray<T>(a: T[], b: T[]): boolean {
    return intersection(a, b).length > 0;
  }
}
