import { RoutePath } from '../../../shared/enums/RoutePath';
import { RoutesDescriptions } from '../../../shared/types/RoutesDescriptions';

export const additionalStatsRoutesTitles: RoutesDescriptions = {
  [RoutePath.AdditionalStatsLargestUSIPOs]: 'Largest US IPOs',
  [RoutePath.AdditionalStatsLargestGlobalIPOs]: 'Largest Global IPOs',
  [RoutePath.AdditionalStatsPricingsComparedToRange]: 'Pricings Compared to Range',
  [RoutePath.AdditionalStatsUSListingsFromNonUSIssuers]: 'US Listings from Non-US Issuers',
  [RoutePath.AdditionalStatsProfitableVsUnprofitable]: 'Profitable vs. Unprofitable',
  [RoutePath.AdditionalStatsWithdrawals]: 'Withdrawals',
  [RoutePath.AdditionalStatsAverageAge]: 'Average Age',
  [RoutePath.AdditionalStatsMedianAge]: 'Median Age',
};
