import React, { memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import './Select.scss';
import { TreeNode } from 'react-dropdown-tree-select';
import { uniqueId } from 'lodash';
import { FieldError, FormContextValues } from 'react-hook-form';
import { SelectOptionValue } from '../../../../services/select/types/SelectOptionValue';
import { ControlSize } from '../../../../enums/ControlSize';
import { DictionaryControlCommonProps } from '../../interfaces/DictionaryControlCommonProps';
import SelectLib from './SelectLib';
import { useClassName } from '../../../../hooks/useClassName';
import { TreeSelectService } from './services/TreeSelectService';
import { usePrevious } from '../../../../effects/usePrevious';
import Control, { ControlCommonProps } from '../../Control';
import Label from '../../Label';
import Error from './../../Error';
import { SelectBlock, SelectModifier } from './SelectBem';
import { SelectService } from '../../../../services/select/SelectService';
import { SelectOption } from '../../../../services/select/interfaces/SelectOption';
import { ELEMENTS_TO_SEARCH } from './constants/settings';
import { GetSelectOptionViewOptionsFn } from './types/GetSelectOptionViewOptionsFn';

// Todo: Fix typescript `props.options` issue with CustomAny, strange behaviour in SelectSwitchComponent
export interface SelectCommonProps
  extends Partial<FormContextValues>,
    DictionaryControlCommonProps,
    ControlCommonProps {
  isMultiSelect: boolean;
  label?: string | ReactNode;
  isDisabled?: boolean;
  placeholder?: string;
  size?: ControlSize;
  error?: FieldError;
  getSelectOptionViewOptionsFn?: GetSelectOptionViewOptionsFn;
  hasNoClear?: boolean;
}

interface Props extends SelectCommonProps {
  values: SelectOptionValue | SelectOptionValue[];
  onChange: (values: SelectOptionValue | SelectOptionValue[]) => void;
  className?: string;
  onBlur?: () => void;
}

const Select: React.FC<Props> = props => {
  const {
    placeholder = ' ',
    onChange,
    size = ControlSize.Medium,
    error,
    isMultiSelect,
    isDisabled = false,
    getSelectOptionViewOptionsFn,
  } = props;
  const values: SelectOptionValue[] = useMemo(() => SelectService.getMultiSelectValue(props.values), [props.values]);

  const cn = useClassName(SelectBlock.Root, props.className);
  const id = useMemo(() => uniqueId(`${SelectBlock.Root}-`), []);

  const options = useMemo(() => SelectService.getOptionsByType(props.options), [props.options]);
  const [initNodes, setInitNodes] = useState<TreeNode[]>([]);
  const [currentNodes, setCurrentNodes] = useState<TreeNode[]>([]);
  const prevOptions = usePrevious(options) as SelectOption[];
  const prevIsDisabled = usePrevious(isDisabled, true);
  const prevIsGetSelectOptionViewOptionsFn = usePrevious(getSelectOptionViewOptionsFn, true);

  const hasSearch = useMemo(() => options.length >= ELEMENTS_TO_SEARCH, [options.length]);

  const shouldRerender = useMemo(
    () =>
      !TreeSelectService.isEqualControlValues(values, currentNodes) ||
      !TreeSelectService.isEqualOptions(prevOptions, options) ||
      isDisabled !== prevIsDisabled ||
      getSelectOptionViewOptionsFn !== prevIsGetSelectOptionViewOptionsFn,
    [
      values,
      currentNodes,
      options,
      prevOptions,
      isDisabled,
      prevIsDisabled,
      getSelectOptionViewOptionsFn,
      prevIsGetSelectOptionViewOptionsFn,
    ],
  );

  // Rerender library component only if values and library state is different
  useEffect(() => {
    if (shouldRerender) {
      const nodes = TreeSelectService.getNodes(options, values, getSelectOptionViewOptionsFn);

      setInitNodes(nodes);
      setCurrentNodes(nodes);
    }
    // eslint-disable-next-line
  }, [props.values, options, isDisabled]);

  const onChangeValue = useCallback(
    (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
      setCurrentNodes(selectedNodes);

      const value = TreeSelectService.getValue(selectedNodes);
      const emitValue = isMultiSelect ? value : SelectService.getSingleSelectValue(value);

      onChange(emitValue);
    },
    [isMultiSelect, onChange],
  );

  return (
    <Control
      {...props}
      className={cn({
        [size]: size,
        [SelectModifier.Search]: hasSearch,
        [SelectModifier.NoClear]: props.hasNoClear,
        'is-invalid': error, // TODO: make is-invalid as global form modifier
      })}
    >
      {props.label && (
        <Label error={error} htmlFor={id}>
          {props.label}
        </Label>
      )}

      <SelectLib
        isMultiSelect={isMultiSelect}
        data={initNodes}
        placeholder={placeholder}
        isDisabled={isDisabled}
        onChangeValue={onChangeValue}
        onBlur={props.onBlur}
      />

      {error && <Error>{error.message}</Error>}
    </Control>
  );
};

export default memo(Select);
