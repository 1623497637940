import React from 'react';
import ViewSimpleGrid from '../../shared/entities/view/components/view-grid/ViewSimpleGrid';
import { GridRow } from '../../shared/components/grid/shared/types/GridRow';
import { viewGridColumns } from '../../shared/entities/view/components/view-grid/constants/viewGridColumns';
import { ViewColumnSystemName } from '../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { ViewGridColumnDto } from '../../shared/entities/view/components/view-grid/interfaces/ViewGridColumnDto';

interface Props {
  rows: GridRow[];
}

const viewColumns = [{ ...viewGridColumns[ViewColumnSystemName.NewsLink] }] as ViewGridColumnDto[];

const NewsLinksGrid: React.FC<Props> = props => {
  return <ViewSimpleGrid rows={props.rows} columns={viewColumns} isHeaderHidden hasAroundBorder />;
};

export default NewsLinksGrid;
