import { DISABLED_HEADQUARTERS_ROUTES } from '../constants/disabledHeadquartersRoutes';
import { RoutePath } from '../../../shared/enums/RoutePath';
import { Location } from '../../../shared/entities/filters/enum/Location';
import { SelectOption } from '../../../shared/services/select/interfaces/SelectOption';
import { locationOptions } from '../../../shared/entities/filters/constants/options/locationOptions';

export class AdditionalStatsService {
  static getDisabledHeadquarterFilter(currentRoutePath: RoutePath): Location | undefined {
    return DISABLED_HEADQUARTERS_ROUTES[currentRoutePath];
  }

  static getHeadquarterFilterLocationOptions(currentRoutePath: RoutePath): SelectOption[] {
    const location = this.getDisabledHeadquarterFilter(currentRoutePath);

    if (location) {
      return locationOptions.filter(option => option.value === location);
    }

    return locationOptions;
  }
}
