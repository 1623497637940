import { Nullable } from '../../../../../shared/types/generics';
import { SimpleGridProps } from '../../../../../shared/components/grid/simple-grid/SimpleGrid';
import { GridRow } from '../../../../../shared/components/grid/shared/types/GridRow';

export class ProfileDataService {
  static getCompanyTableRows(data: Nullable<SimpleGridProps>): Nullable<GridRow[]> {
    return data && data.rows;
  }

  static getInvestorsFromRows(data: Nullable<GridRow[]>): Nullable<GridRow[]> {
    if (data) {
      const investorsList = data?.map((item: GridRow) => {
        delete item.InvestorKey;
        return item;
      });
      return investorsList;
    }
    return data;
  }
}
