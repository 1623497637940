import React, { useEffect, useMemo, useState } from 'react';
import './Form.scss';
import { isEmpty, isEqual } from 'lodash';
import { FormContext, useForm } from 'react-hook-form';
import { SubmitFormFn } from './types/SubmitFormFn';
import { AnyObject, CustomAny } from '../../types/generics';
import { ErrorDataProps } from '../../services/error/enum/ErrorDataProps';
import { ManualFieldError } from 'react-hook-form/dist/types';
import FormError from './FormError';
import { ControlSize } from '../../enums/ControlSize';
import { AlignTypesHorizontal } from '../../enums/AlignTypesHorizontal';
import { FormStateContext } from './FormStateContext';
import { useDeepEffect } from '../../effects/useDeepEffect';
import { ChangeFormFn } from './types/ChangeFormFn';
import { usePrevious } from '../../effects/usePrevious';
import { FormErrorService } from './services/FormErrorService';
import { ErrorHandlerService } from '../../services/error/ErrorHandlerService';
import { ErrorData } from '../../services/error/interfaces/ErrorData';
import { BlockOrientation } from '../../enums/BlockOrientation';
import { useClassName } from '../../hooks/useClassName';
import { FormBlock } from './formBem';
import { useAsyncFn } from '../../hooks/async-fn/useAsyncFn';
import { GetNotificationSuccessMessageFn } from '../../../store/notification/types';
import { FormValidationStateChangeFn } from './types/FormValidationStateChangeFn';
import { FormValuesService } from './services/FormValuesService';

export interface FormProps {
  validationSchema?: CustomAny;
  defaultValues?: AnyObject;
  className?: string;
  size?: ControlSize;
  alignHorizontal?: AlignTypesHorizontal;
  orientation?: BlockOrientation;
  changeOnInit?: boolean;
  controlsNames?: string[]; // TODO: make required and spread across all forms, needed to generate valid default values for form initialization
  getSuccessMessage?: GetNotificationSuccessMessageFn;
  onSubmit?: SubmitFormFn<CustomAny>;
  onChange?: ChangeFormFn<CustomAny>;
  onValidationStateChange?: FormValidationStateChangeFn;
}

const Form: React.FC<FormProps> = props => {
  const { orientation = BlockOrientation.Column, controlsNames, onValidationStateChange } = props;

  const cn = useClassName(FormBlock.Root, props.className);

  const formDefaultValues = useMemo(
    () =>
      controlsNames
        ? FormValuesService.getValuesFromControlsNames(props.defaultValues, controlsNames)
        : props.defaultValues,
    [controlsNames, props.defaultValues],
  );

  const methods = useForm({
    validationSchema: props.validationSchema,
    defaultValues: formDefaultValues,
    mode: 'onBlur',
  });

  const { setError, reset, watch, handleSubmit, formState } = methods;

  const formValues: CustomAny = watch();
  const formValuesPrev = usePrevious<CustomAny>(formValues, true);
  const [isNewSubmitFormValues, setIsNewSubmitFormValues] = useState(false);

  const onFormSubmit: SubmitFormFn<CustomAny> = async data => {
    await props?.onSubmit?.(data);
    setIsNewSubmitFormValues(false);
  };

  const [onFormSubmitAsync, isLoading] = useAsyncFn(onFormSubmit, {
    message: props.getSuccessMessage && props.getSuccessMessage(formValues),
    onError: error => {
      return ErrorHandlerService.handle((errorData: ErrorData) => {
        const formattedErrors: ManualFieldError<CustomAny>[] = FormErrorService.getFormErrors(errorData);
        setError(formattedErrors);
      }, error);
    },
  });

  const formStateContextValue: FormStateContext = useMemo(() => ({ isLoading, isNewSubmitFormValues }), [
    isLoading,
    isNewSubmitFormValues,
  ]);

  // Form values change
  useDeepEffect(() => {
    const isChangeDisable = isEmpty(formValues) || isEqual(formValues, formValuesPrev);

    if (isChangeDisable) {
      return;
    }

    props?.onChange?.(formValues);
    setIsNewSubmitFormValues(true);
  }, [formValues]);

  // Reset form state
  useEffect(() => {
    reset(formDefaultValues);
  }, [formDefaultValues, reset]);

  useEffect(() => {
    if (props.changeOnInit) {
      props?.onChange?.(formValues);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    onValidationStateChange?.(formState.isValid);
  }, [onValidationStateChange, formState.isValid]);

  return (
    <FormStateContext.Provider value={formStateContextValue}>
      <FormContext {...methods}>
        <form
          className={cn({
            [`${props.size}`]: props.size,
            [`${props.alignHorizontal}`]: props.alignHorizontal,
            [`${orientation}`]: orientation,
          })}
          onSubmit={handleSubmit(onFormSubmitAsync)}
        >
          {props.children}

          <FormError name={ErrorDataProps.SummaryErrors} />
        </form>
      </FormContext>
    </FormStateContext.Provider>
  );
};

export default Form;
