export enum StepBlock {
  Root = 'Step',
}

export enum StepElement {
  ProgressLine = 'progressLine',
  Point = 'point',
  Title = 'title',
  Description = 'description',
  Circle = 'circle',
}
