import React from 'react';
import { Redirect, RouteProps, Route } from 'react-router-dom';
import { RoutePath } from '../enums/RoutePath';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setReturnLocation, setIsAuthorized } from '../../store/auth/actions';
import { history } from '../history';
import LocationService from '../services/LocationService';

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const isAuthorized = useSelector<RootState, boolean>(state => state.auth.isAuthorized);
  const dispatch = useDispatch();

  if (!isAuthorized) {
    dispatch(setReturnLocation(history.location));
    LocationService.redirect(RoutePath.Login);
  }

  const localRefresh = localStorage.getItem('RefreshToken');
  const localAccess = localStorage.getItem('AccessToken');
  const sessRefresh = sessionStorage.getItem('RefreshToken');
  const sessAccess = sessionStorage.getItem('AccessToken');
  React.useEffect(() => {
    // When the access or refresh tokens are not present, send user back to the login screen
    if (!(localRefresh || localAccess || sessAccess || sessRefresh)) {
      if (isAuthorized) {
        dispatch(setReturnLocation(history.location));
      }
      dispatch(setIsAuthorized(false));
      LocationService.redirect(RoutePath.Login);
    }
  }, [localRefresh, localAccess, sessRefresh, sessAccess]);

  return (
    <Route {...rest}>
      {isAuthorized ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: RoutePath.Login,
          }}
        />
      )}
    </Route>
  );
};

export default PrivateRoute;
