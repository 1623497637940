import cloneDeep from 'lodash.clonedeep';
import { ViewColumnsAddSettings } from '../interfaces/ViewColumnsAddSettings';
import { ViewColumnsAddItem } from '../interfaces/ViewColumnsAddItem';
import { ViewColumnsAddSection } from '../interfaces/ViewColumnsAddSection';

export class ViewColumnsAddService {
  static getColumnsSettings(
    baseColumnsSettings: ViewColumnsAddSettings[],
    columnIds: string[],
  ): ViewColumnsAddSettings[] {
    return columnIds.reduce(
      (updatedColumnSettings, columnId) => this.getSelectedColumnsSettings(updatedColumnSettings, columnId, true),
      baseColumnsSettings,
    );
  }

  static toggleColumn(columnId: string, isSelected: boolean, selectedColumns: ViewColumnsAddItem[]): string[] {
    const selectedColumnsIds = this.getSelectedColumnsIds(selectedColumns);
    let newSelectedColumnsIds;

    if (isSelected) {
      newSelectedColumnsIds = this.addColumn(columnId, selectedColumnsIds);
    } else {
      newSelectedColumnsIds = this.removeColumn(columnId, selectedColumnsIds);
    }

    return newSelectedColumnsIds;
  }

  public static getSelectedColumnsFromSettings(
    columnsSettings: ViewColumnsAddSettings[],
    selectedColumnsIds: string[],
  ): ViewColumnsAddItem[] {
    return selectedColumnsIds.map(columnId =>
      this.getColumnItemByIdFromSettings(columnsSettings, columnId),
    ) as ViewColumnsAddItem[];
  }

  static getSelectedColumnsIds(columns: ViewColumnsAddItem[]): string[] {
    return columns.map(column => column.id);
  }

  static hasSelectedColumns(selectedColumnsIds?: string[]): boolean {
    return !!selectedColumnsIds && selectedColumnsIds.length > 0;
  }

  private static addColumn(columnId: string, selectedColumnsIds: string[]): string[] {
    return [...selectedColumnsIds, columnId];
  }

  private static removeColumn(columnId: string, selectedColumnsIds: string[]): string[] {
    return selectedColumnsIds.filter(selectedColumnsId => selectedColumnsId !== columnId);
  }

  private static getSelectedColumnsSettings(
    columnsSettings: ViewColumnsAddSettings[],
    columnId: string,
    isSelected: boolean,
  ): ViewColumnsAddSettings[] {
    const columnsSettingsNew: ViewColumnsAddSettings[] = cloneDeep(columnsSettings);
    const columnItem: ViewColumnsAddItem | null = this.getColumnItemByIdFromSettings(columnsSettingsNew, columnId);

    if (columnItem) {
      columnItem.isSelected = isSelected;
    }

    return columnsSettingsNew;
  }

  private static getColumnItemsFromColumnSettings(columnsSettings: ViewColumnsAddSettings[]): ViewColumnsAddItem[] {
    return columnsSettings.reduce(
      (columnItems: ViewColumnsAddItem[], columnsSettings: ViewColumnsAddSettings) =>
        columnItems.concat(this.getColumnItemsFromColumnsSections(columnsSettings.items)),
      [],
    );
  }

  private static getColumnItemsFromColumnsSections(columnsSections: ViewColumnsAddSection[]): ViewColumnsAddItem[] {
    return columnsSections.reduce(
      (columnItems: ViewColumnsAddItem[], columnsSection: ViewColumnsAddSection) =>
        columnItems.concat(columnsSection.items),
      [],
    );
  }

  private static getColumnItemByIdFromSettings(
    columnsSettings: ViewColumnsAddSettings[],
    columnId: string,
  ): ViewColumnsAddItem | null {
    const columnItems: ViewColumnsAddItem[] = this.getColumnItemsFromColumnSettings(columnsSettings);
    return columnItems.find((columnItem: ViewColumnsAddItem) => columnItem.id === columnId) || null;
  }
}
