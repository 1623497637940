import React, { memo, useContext, useMemo } from 'react';
import { isEqual } from 'lodash';
import { FormControlProps } from '../../../../../components/forms/components/form-builder/types/formControlsTypes';
import { ViewFilterName } from '../../../enum/types/ViewFilterName';
import FormControlSwitch from '../../../../../components/forms/components/form-builder/FormControlSwitch';
import FormGroup from '../../../../../components/forms/FormGroup';
import { FilterSectionsFormContext } from '../context/FilterSectionsFormContext';
import FilterSectionItem from './FilterSectionItem';
import { FilterControlFactory } from '../factories/FilterControlFactory';

interface Props {
  name: ViewFilterName;
  controlProps?: Partial<FormControlProps>;
  isDisabled?: boolean;
}

const FilterControl: React.FC<Props> = props => {
  const { settings } = useContext(FilterSectionsFormContext);

  const control = useMemo(() => FilterControlFactory.get(props.name, settings.sections, props.controlProps), [
    props.name,
    props.controlProps,
    settings.sections,
  ]);

  return (
    <>
      {control && (
        <FilterSectionItem>
          {control.title && <h6>{control.title}</h6>}
          <FormGroup isDisabled={props.isDisabled}>
            <FormControlSwitch control={control} />
          </FormGroup>
        </FilterSectionItem>
      )}
    </>
  );
};

export default memo(FilterControl, isEqual);
