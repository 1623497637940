import React, { useState } from 'react';
import { LoginFormField } from './LoginFormField';
import { validationSchema } from './validationSchema';
import { InputType } from '../../../shared/components/forms/Input';
import { LoginFormData } from './LoginFormData';
import Form from '../../../shared/components/forms/Form';
import FormGroup from '../../../shared/components/forms/FormGroup';
import AuthApiService from '../../../shared/api/AuthApiService';
import FormCheckbox from '../../../shared/components/forms/FormCheckbox';
import { SubmitFormFn } from '../../../shared/components/forms/types/SubmitFormFn';
import FormInput from '../../../shared/components/forms/FormInput';
import { ControlSize } from '../../../shared/enums/ControlSize';
import { AlignTypesHorizontal } from '../../../shared/enums/AlignTypesHorizontal';
import ButtonSubmit from '../../../shared/components/forms/ButtonSubmit';
import { AuthService } from '../../../shared/services/AuthService';

const LoginForm: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const onSubmit: SubmitFormFn<LoginFormData> = async data => {
    await AuthService.authorize(AuthApiService.login.bind(AuthApiService, data), data[LoginFormField.Remember]);
    setIsSubmitted(true);
  };

  return (
    <Form
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      size={ControlSize.Medium}
      alignHorizontal={AlignTypesHorizontal.Center}
    >
      <FormGroup>
        <FormInput name={LoginFormField.Email} label="Email" type={InputType.Email} autoComplete="email" />
      </FormGroup>

      <FormGroup>
        <FormInput
          name={LoginFormField.Password}
          label="Password"
          type={InputType.Password}
          autoComplete="new-password"
        />
      </FormGroup>

      <FormGroup>
        <FormCheckbox name={LoginFormField.Remember} label="Remember me" />
      </FormGroup>

      <ButtonSubmit message="Login" submittedMessage="You are successfully logged in" isSubmitted={isSubmitted} />
    </Form>
  );
};

export default LoginForm;
