import React from 'react';
import AdditionalStatsContentContainer from '../../shared/components/AdditionalStatsContentContainer';
import { usLisingsStatsElementOptions } from './chart/constants/usLisingsStatsElementOptions';
import StatsElement from '../../../../shared/entities/stats-element/components/StatsElement';
import { StatsElementType } from '../../../../shared/entities/stats-element/enums/StatsElementType';
import AdditionalStatsPage from '../AdditionalStatsPage';

const USListingsFromNonUSIssuers: React.FC = () => {
  return (
    <AdditionalStatsPage>
      <AdditionalStatsContentContainer title="US Listings from Non-US Issuers">
        <StatsElement type={StatsElementType.UsListedAdr} options={usLisingsStatsElementOptions} />
      </AdditionalStatsContentContainer>
    </AdditionalStatsPage>
  );
};

export default USListingsFromNonUSIssuers;
