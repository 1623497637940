import React, { useContext } from 'react';
import './ViewSummary.scss';
import { useClassName } from '../../../../../../hooks/useClassName';
import { ViewContext } from '../../ViewContext';
import { ViewSummaryBlock, ViewSummaryElement } from './viewSummaryBem';
import ViewSummaryItem from './ViewSummaryItem';
import { ViewSummaryOptions } from '../../interfaces/options/ViewSummaryOptions';
import { viewSummaryItems } from './constants/viewSummaryItems';

const ViewSummary: React.FC = () => {
  const cn = useClassName(ViewSummaryBlock.Root);

  const { state } = useContext(ViewContext);
  const summary = state.options.summary as ViewSummaryOptions;

  return (
    <div
      className={cn({
        [summary.align]: summary.align,
      })}
    >
      <div className={cn(ViewSummaryElement.Row)}>
        {viewSummaryItems.map(item => (
          <ViewSummaryItem
            key={item.field}
            title={item.title}
            field={item.field}
            hasFormatter={item.hasFormatter}
            formatterBaseValue={item.formatterBaseValue}
          />
        ))}
      </div>
    </div>
  );
};

export default ViewSummary;
