import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DateSelectValue } from './types/DateSelectValue';
import DateRangeSelect, { DateRangeSelectProps } from './DateRangeSelect';

export interface FormDateRangeSelectProps extends Partial<DateRangeSelectProps> {
  minName: string;
  maxName: string;
}

const FormDateRangeSelect: React.FC<FormDateRangeSelectProps> = props => {
  const { register, setValue, watch } = useFormContext();
  const min: DateSelectValue = watch && watch(props.minName);
  const max: DateSelectValue = watch && watch(props.maxName);

  useEffect(() => {
    register({ name: props.minName });
    register({ name: props.maxName });
  }, [register, props.minName, props.maxName]);

  const onChangeValue = useCallback(
    (minValue, maxValue) => {
      setValue(props.minName, minValue);
      setValue(props.maxName, maxValue);
    },
    [setValue, props.minName, props.maxName],
  );

  return (
    <DateRangeSelect
      minName={props.minName}
      maxName={props.maxName}
      minValue={min}
      maxValue={max}
      onChange={onChangeValue}
      {...props}
    />
  );
};

export default FormDateRangeSelect;
