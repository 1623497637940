import React, { useCallback } from 'react';
import './ViewColumnsChooser.scss';
import TabsContainer, { TabsType } from '../../../../../../components/tabs/TabsContainer';
import { useClassName } from '../../../../../../hooks/useClassName';
import { ScreensColumnsChooserBlock } from './ViewColumnsChooserBem';
import Tab from '../../../../../../components/tabs/Tab';
import ViewColumnsWeb from './ViewColumnsWeb';
import ViewColumnsExcel from './ViewColumnsExcel';
import { ControlSize } from '../../../../../../enums/ControlSize';
import { ViewColumnsOptionsDto } from './add/interfaces/dto/ViewColumnsOptionsDto';
import classNames from 'classnames';

interface Props {
  selectedColumnsWeb: string[];
  selectedColumnsExcel: string[];
  columnsOptions: ViewColumnsOptionsDto;
  onChangeWeb: (selectedColumnsIds: string[]) => void;
  onChangeExcel: (selectedColumnsIds: string[]) => void;
}

const ViewColumnsChooser: React.FC<Props> = props => {
  const { onChangeWeb, onChangeExcel } = props;

  const cn = useClassName(ScreensColumnsChooserBlock.Root);

  const onColumnsWebChange = useCallback(columnIds => onChangeWeb(columnIds), [onChangeWeb]);
  const onColumnsExcelChange = useCallback(columnIds => onChangeExcel(columnIds), [onChangeExcel]);

  return (
    <TabsContainer
      className={classNames(cn(), 'add-modal-tabs-container')}
      type={TabsType.Tab}
      tabContentGutter={ControlSize.Small}
    >
      <Tab title="Web" eventKey="1">
        <ViewColumnsWeb
          isInModal
          columnsOptions={props.columnsOptions}
          selectedColumnsIds={props.selectedColumnsWeb}
          onChange={onColumnsWebChange}
        />
      </Tab>

      <Tab title="Excel" eventKey="2">
        <ViewColumnsExcel
          isInModal
          columnsOptions={props.columnsOptions}
          selectedColumnsIds={props.selectedColumnsExcel}
          onChange={onColumnsExcelChange}
        />
      </Tab>
    </TabsContainer>
  );
};

export default ViewColumnsChooser;
