import React, { useMemo } from 'react';
import './CompanyMilestonesTooltipContent.scss';
import { TooltipContentProps } from '../../../shared/components/tooltip/interface/TooltipContentProps';
import { useClassName } from '../../../shared/hooks/useClassName';
import { CompanyMilestonesTooltipContentBlock } from './CompanyMilestonesTooltipContentBem';
import { CompanyMilestoneDto } from '../../interfaces/CompanyMilestoneDto';
import SimpleGrid from '../../../shared/components/grid/simple-grid/SimpleGrid';
import { GridColumn } from '../../../shared/components/grid/shared/interfaces/GridColumn';
import { CompanyMilestoneColumn } from '../../enums/CompanyMilestoneColumn';
import { ControlSize } from '../../../shared/enums/ControlSize';
import { DateHelper } from '../../../shared/helpers/date/DateHelper';
import { DateFormat } from '../../../shared/helpers/date/enums/DateFormat';

const columns: GridColumn[] = [
  {
    field: CompanyMilestoneColumn.Date,
    headerName: 'Date',
  },
  {
    field: CompanyMilestoneColumn.Note,
    headerName: 'Milestone',
  },
];

const CompanyMilestonesTooltipContent: React.FC<TooltipContentProps<CompanyMilestoneDto[]>> = props => {
  const cn = useClassName(CompanyMilestonesTooltipContentBlock.Root);

  const { data } = props;

  const rows = useMemo(
    () =>
      data.map(row => ({
        ...row,
        [CompanyMilestoneColumn.Date]: DateHelper.formatISODateStr(
          row[CompanyMilestoneColumn.Date],
          DateFormat.MonthDayYearShort,
        ),
      })),
    [data],
  );

  return (
    <section className={cn()}>
      <SimpleGrid rows={rows} columns={columns} hasAroundBorder={false} size={ControlSize.Small} />
    </section>
  );
};

export default CompanyMilestonesTooltipContent;
