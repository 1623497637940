import { useMemo, useContext } from 'react';
import { FilterStoreService } from '../../../shared/entities/filters/services/FilterStoreService';
import { ViewFilterName } from '../../../shared/entities/filters/enum/types/ViewFilterName';
import { locationOptions } from '../../../shared/entities/filters/constants/options/locationOptions';
import { StatsPageContext } from '../context/StatsPageContext';

//Works only inside Stats Page context
export function useStatsTitle(text: string, prefix?: string): string {
  const { pageFilters } = useContext(StatsPageContext);

  const headquarterTitle = FilterStoreService.getTitle(pageFilters, ViewFilterName.Headquarter, locationOptions);

  const title = useMemo(() => `${prefix || ''} ${headquarterTitle} ${text}`, [text, headquarterTitle, prefix]);

  return useMemo(() => (headquarterTitle ? title : text), [title, headquarterTitle, text]);
}
