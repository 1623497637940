import { StatsElementOptions } from '../../../../../shared/entities/stats-element/interfaces/StatsElementOptions';
import { ChartType } from '../../../../../shared/components/chart/enums/ChartType';
import { getStatsElementSimpleGridColumnYearToViewMapper } from '../../../../../shared/entities/stats-element/components/simple-grid/constants/statsElementSimpleGridColumnMappers';
import { ViewType } from '../../../../../shared/entities/view/components/view/enums/ViewType';
import { ViewFilterName } from '../../../../../shared/entities/filters/enum/types/ViewFilterName';
import { BlockOrientation } from '../../../../../shared/enums/BlockOrientation';
import { ViewTriggerCellOptions } from '../../../../../shared/entities/view/components/view-grid/components/cells/view-trigger-cell/interface/ViewTriggerCellOptions';

const AVERAGE_IPOS_PRICING_GRID_INDEX = 0;
const TOTALS_IPOS_GRID_INDEX = 1;
const TOTAL_IPOS_GRID_INDEX = 2;

const GRID_COLUMN_PROPS_SHARED = {
  viewType: ViewType.Priced,
  startDateFilterName: ViewFilterName.OfferStartDate,
  endDateFilterName: ViewFilterName.OfferEndDate,
};

const IPOPriceRangeOptions: ViewTriggerCellOptions = {
  getFilterName: ViewFilterName.IPOPriceRange,
  getFilterValue: row => {
    switch (row?.Type) {
      case 'Above':
        return 'Above Range';
      case 'Within':
        return 'Within Range';
      case 'Below':
        return 'Below Range';
      default:
        return '';
    }
  },
};

export const pricingsComparedToRangeStatsElementOptions: StatsElementOptions = {
  pageOptions: {
    chartsOrientation: BlockOrientation.Line,
  },
  chartsOptions: [
    {
      type: ChartType.VerticalColumn,
      title: 'IPO Pricings Compared to Range',
      optionsList: [
        {
          yAxis: {
            title: {
              text: 'Total IPOs',
            },
          },
        },
      ],
    },
    {
      type: ChartType.VerticalPercentageColumn,
      title: 'IPO Pricings Compared to Range',
      optionsList: [
        {
          yAxis: {
            title: {
              text: 'Percentage of Total IPOs',
            },
          },
        },
      ],
    },
  ],
  gridOptions: {
    [AVERAGE_IPOS_PRICING_GRID_INDEX]: {
      title: 'Average IPO Prices Compared to Midpoint',
    },
    [TOTALS_IPOS_GRID_INDEX]: {
      title: 'IPO Pricings vs. Range',
      isHighlightLastRow: true,
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(() => {
        return {
          ...GRID_COLUMN_PROPS_SHARED,
          optionsList: [IPOPriceRangeOptions],
        };
      }),
    },
    [TOTAL_IPOS_GRID_INDEX]: {
      title: 'IPO Pricings Compared to Range & Avg. <u>Year End</u> Returns',
      isHighlightLastRow: true,
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(() => {
        return {
          ...GRID_COLUMN_PROPS_SHARED,
          optionsList: [IPOPriceRangeOptions],
        };
      }),
    },
  },
};
