import React, { useContext, useMemo, useState } from 'react';
import CompanyProfileSideItem from '../../profile/shared/components/CompanyProfileSideItem';
import { companyPerfomanceVsIpoIndexChartOptions } from './constants/companyPerfomanceVsIpoIndexChartOptions';
import { pricedCompanyPerfomanceVsIpoIndexChartOptions } from './constants/pricedCompanyPerfomanceVsIpoIndexChartOptions';
import Highcharts from 'highcharts';
import CompanyPerfomanceVsIpoIndexChartActions from './CompanyPerfomanceVsIpoIndexChartActions';
import { CompanyContext } from '../../context/CompanyContext';
import PageLoader from '../../../../shared/components/loader/PageLoader';
import { ChartFactory } from '../../../../shared/components/chart/factories/ChartFactory';
import { ChartType } from '../../../../shared/components/chart/enums/ChartType';
import { DateFormat } from '../../../../shared/helpers/date/enums/DateFormat';
import { DateHelper } from '../../../../shared/helpers/date/DateHelper';

interface Props {
  hasActions?: false;
}

const getDatesFromChart = (Chart: any) => {
  const startDateTimeStamp = Chart?.target?.xAxis[0]?.min + 84600000; // Adding one day to mantain offset
  const endDateTimeStamp = Chart?.target?.xAxis[0]?.max + 84600000;
  const startDateISO = startDateTimeStamp && new Date(startDateTimeStamp).toISOString();
  const endDateISO = endDateTimeStamp && new Date(endDateTimeStamp).toISOString();
  const startDate = DateHelper.formatISODateStr(startDateISO, DateFormat.YearMonthDayISO);
  const endDate = DateHelper.formatISODateStr(endDateISO, DateFormat.YearMonthDayISO);
  return { startDate, endDate };
};

const CompanyPerfomanceVsIpoIndexChart: React.FC<Props> = props => {
  const { hasActions = true } = props;

  const [extremes, setExtremes] = useState<{ minValue: any; maxValue: any }>({ minValue: null, maxValue: null });
  const { perfomanceVsIpoIndexChart, addPerfomanceChartTicker, tabsData, setPerfomanceVsIpoIndexTerm } = useContext(
    CompanyContext,
  );
  const [series, , isPerfomanceVsIpoIndexChartLoading] = perfomanceVsIpoIndexChart;
  const [, isAddPerfomanceChartTickerLoading] = addPerfomanceChartTicker;
  const isLoading = useMemo(() => isPerfomanceVsIpoIndexChartLoading || isAddPerfomanceChartTickerLoading, [
    isPerfomanceVsIpoIndexChartLoading,
    isAddPerfomanceChartTickerLoading,
  ]);

  const chartProps = useMemo(() => {
    const selectedChartOptions =
      tabsData[0]?.profile?.description?.dealStatus === 'Priced'
        ? pricedCompanyPerfomanceVsIpoIndexChartOptions
        : companyPerfomanceVsIpoIndexChartOptions;
    return {
      optionsList: [
        {
          ...selectedChartOptions,
          series: series as Highcharts.SeriesOptionsType[],
          ...{
            chart: {
              events: {
                load: function(thisPtr: any) {
                  setPerfomanceVsIpoIndexTerm(getDatesFromChart(thisPtr));
                },
                redraw: function(thisPtr: any) {
                  setPerfomanceVsIpoIndexTerm(getDatesFromChart(thisPtr));
                },
              },
            },
          },
        },
      ],
    };
  }, [series, hasActions]);

  const chartComponentRef = React.useRef<any>(null);
  const chart = useMemo(() => ChartFactory.getChart(chartProps, ChartType.LineStock, chartComponentRef), [
    chartProps,
    chartComponentRef,
  ]);

  const chartTitle = useMemo(
    () =>
      tabsData[0]?.profile?.description?.ipoTypeKey === 3
        ? 'Performance vs. IPO Index (IPOXUS)'
        : 'Performance vs. IPO Index (IPOUSA)',
    [tabsData],
  );

  React.useEffect(() => {
    const chartComponent = chartComponentRef?.current?.chart;
    if (extremes.minValue && extremes.maxValue) {
      chartComponent?.xAxis[0]?.setExtremes(extremes.minValue, extremes.maxValue);
    }
  }, [extremes.minValue, extremes.maxValue]);

  // on(eventType, handler)
  return (
    <CompanyProfileSideItem
      className="CompanyPerfomanceVsIpoIndexChart"
      title={chartTitle}
      actions={hasActions && <CompanyPerfomanceVsIpoIndexChartActions setExtremes={setExtremes} />}
    >
      <PageLoader loading={isLoading} otherwise={series} isOverlay>
        {chart}
      </PageLoader>
    </CompanyProfileSideItem>
  );
};

export default CompanyPerfomanceVsIpoIndexChart;
