import React from 'react';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { screensLockUpsInUseOptions } from './constants/screensLockUpsInUseOptions';
import ScreensInUse from '../../shared/pages/components/in-use/ScreensInUse';
import { LOCK_UPS_VIEW_ID } from '../../../../shared/constants/environment';

const ScreensLockUpsInUse: React.FC = () => {
  const viewId = parseInt(LOCK_UPS_VIEW_ID);

  return <ScreensInUse viewId={viewId} inUseType={ViewType.LockUpsInUse} options={screensLockUpsInUseOptions} />;
};

export default ScreensLockUpsInUse;
