import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { reducer as header } from './header/reducers';
import { reducer as auth } from './auth/reducers';
import { reducer as notification } from './notification/reducers';
import { reducer as favouriteCompany } from './favourite-company/reducers';
import { reducer as profilePrint } from './profile-print/reducers';

const rootReducer = combineReducers({
  header,
  auth,
  notification,
  favouriteCompany,
  profilePrint,
});

export type RootState = ReturnType<typeof rootReducer>;

export const getStore = () => createStore(rootReducer, applyMiddleware(thunk));

export const store = getStore();
