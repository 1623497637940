import { DateSelectRestrictionValue } from '../types/DateSelectRestrictionValue';
import { DatePickerRestrictionDateType } from '../enums/DatePickerRestrictionDateType';
import { DateHelper } from '../../../../../helpers/date/DateHelper';
import { DateSelectValue } from '../types/DateSelectValue';
import { DateFormat } from '../../../../../helpers/date/enums/DateFormat';

export class DateSelectService {
  static getRestrictionDate(date: DateSelectRestrictionValue): Date | undefined {
    if (this.isRestrictionDateType(date)) {
      switch (date as DatePickerRestrictionDateType) {
        case DatePickerRestrictionDateType.Today:
          return DateHelper.getToday();
        default:
          return;
      }
    }

    return DateHelper.parseISODate(date);
  }

  static isRestrictionDateType(
    dateRestrictionValue: DateSelectRestrictionValue,
  ): dateRestrictionValue is DatePickerRestrictionDateType {
    return Object.values(DatePickerRestrictionDateType).includes(dateRestrictionValue as DatePickerRestrictionDateType);
  }

  static getValueByRestriction(
    dateValue?: DateSelectValue,
    restrictionDateValue?: DateSelectRestrictionValue,
  ): DateSelectValue {
    if (!restrictionDateValue) {
      return dateValue;
    }

    const restrictionDate = DateSelectService.getRestrictionDate(restrictionDateValue);

    if (!dateValue) {
      return restrictionDateValue;
    }

    const date = DateHelper.getLocalDateFromISODateStr(dateValue);
    const isRestrictionDateAfter = DateHelper.isAfter(restrictionDate, date);

    return isRestrictionDateAfter ? dateValue : restrictionDateValue;
  }

  static getNewValue(date?: Date): DateSelectValue {
    return date && DateHelper.formatDate(date, DateFormat.YearMonthDayISO);
  }

  static getNewValueOnBlur(date?: string): DateSelectValue {
    if (!date) {
      return;
    }

    const parsedDate = DateHelper.getNewDate(date);

    if (DateHelper.isValid(parsedDate)) {
      return this.getNewValue(DateHelper.getNewDate(date));
    }

    return;
  }
}
