import * as yup from 'yup';
import { PHONE_NUMBER_REGEX } from '../../../../../../constants/validation';
import { StringSchema } from 'yup';

// Old phoneNumberSchema
// export const phoneNumberSchema: NumberSchema = yup
//   .number()
//   .min(MIN_PHONE_NUMBER_LENGTH)
//   .max(MAX_PHONE_NUMBER_LENGTH)
//   .required();

export const phoneNumberSchema: StringSchema = yup
  .string()
  .matches(PHONE_NUMBER_REGEX, 'Phone Number should be a valid number of 10 digits')
  .required();
