import React from 'react';
import './LatestVideoContent.scss';
import Helmet from 'react-helmet';
import PrimarySection from '../../shared/components/primary-section/PrimarySection';
import PageLoader from '../../shared/components/loader/PageLoader';
import { VideosDto } from '../shared/interfaces/VideosDto';
import { usePromiseState } from '../../shared/hooks/promise-state/usePromiseState';
import FormattedDate from '../../shared/components/FormattedDate';

interface Props {
  getMemoizedLatestVideo: () => Promise<VideosDto>;
}

const LatestVideosContent: React.FC<Props> = props => {
  const [latestVideo, isLatestVideoLoading] = usePromiseState(props.getMemoizedLatestVideo, null);

  return (
    <>
      <Helmet>
        <title>Video Tutorials</title>
      </Helmet>

      <PageLoader loading={isLatestVideoLoading}>
        {latestVideo && (
          <PrimarySection className={'latest-video'} header={latestVideo.title}>
            <div className="loom-embed" dangerouslySetInnerHTML={{ __html: latestVideo.embedCode }} />
            <p>
              {' '}
              {latestVideo.publishDate && (
                <span className="video-card-info">
                  <FormattedDate date={latestVideo.publishDate} />
                  :&nbsp;
                </span>
              )}
              {latestVideo.descript}{' '}
              {latestVideo.newsKey && (
                <strong>
                  {' '}
                  <br />
                  <a href={`/news/${latestVideo.newsKey}`}>Video featured in this article</a>
                </strong>
              )}
            </p>
          </PrimarySection>
        )}
      </PageLoader>
    </>
  );
};

export default LatestVideosContent;
