import React from 'react';
import { StatsElementOptions } from '../../../../../shared/entities/stats-element/interfaces/StatsElementOptions';
import { ChartType } from '../../../../../shared/components/chart/enums/ChartType';
import { getStatsElementSimpleGridColumnYearToViewMapper } from '../../../../../shared/entities/stats-element/components/simple-grid/constants/statsElementSimpleGridColumnMappers';
import { ViewType } from '../../../../../shared/entities/view/components/view/enums/ViewType';
import { ViewFilterName } from '../../../../../shared/entities/filters/enum/types/ViewFilterName';
import ViewLink from '../../../../../shared/entities/view/components/view-link/ViewLink';
import { ViewReqParamsBase } from '../../../../../shared/entities/view/components/view/interfaces/api/ViewReqParamsBase';

const WITHDRAWALS_GRID_INDEX = 0;

export const withdrawalsStatsElementOptions: StatsElementOptions = {
  chartsOptions: [
    {
      type: ChartType.Base,
      optionsList: [
        {
          yAxis: {
            title: {
              text: 'Number of IPOs Withdrawn',
            },
          },
          plotOptions: {
            column: {
              grouping: false,
            },
          },
        },
      ],
      // eslint-disable-next-line react/display-name
      getInternalFilterAdditionalComponent: () => {
        const linkData: ViewReqParamsBase = {
          sort: null,
          viewType: ViewType.Pipeline,
          filters: {
            [ViewFilterName.DealStatusWithdrawnAcquiredPostponed]: true,
          },
        };

        return <ViewLink title="See all postponements and withdrawals" data={linkData} hasLinkStyle />;
      },
    },
  ],
  gridOptions: {
    [WITHDRAWALS_GRID_INDEX]: {
      isHighlightLastRow: true,
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(() => ({
        viewType: ViewType.WithdrawnPostponedInUse,
        startDateFilterName: ViewFilterName.OfferStartDate,
        endDateFilterName: ViewFilterName.OfferEndDate,
        optionsList: [{ getFilterName: ViewFilterName.Withdrawn, getFilterValue: () => true }],
      })),
    },
  },
};
