import { StatsElementOptions } from '../../../../shared/entities/stats-element/interfaces/StatsElementOptions';
import { ChartType } from '../../../../shared/components/chart/enums/ChartType';
import { ChartTooltipFormatterService } from '../../../../shared/components/chart/services/ChartTooltipFormatterService';
import { CustomAny } from '../../../../shared/types/generics';
import { ChartValuesType } from '../../../../shared/components/chart/enums/ChartValuesType';
import { NumberHelper } from '../../../../shared/helpers/NumberHelper';
import { NumberFormat } from '../../../../shared/enums/NumberFormat';
import { ViewColumnSystemName } from '../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { getStatsElementSimpleGridColumnYearToViewMapper } from '../../../../shared/entities/stats-element/components/simple-grid/constants/statsElementSimpleGridColumnMappers';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { ViewFilterName } from '../../../../shared/entities/filters/enum/types/ViewFilterName';
import { DealStatus } from '../../../../shared/entities/filters/enum/DealStatus';
import { ScreensLocationFilterName } from '../../../../screens/components/screens/location/enums/ScreensLocationFilterName';
import { ScreensLocationScreenByValue } from '../../../../screens/components/screens/location/enums/ScreensLocationScreenByValue';
import { StatsFilterName } from '../../enum/StatsFilterName';
import { StatsFiltersTitle } from '../../shared/filter/enums/StatsFiltersTitle';
import { Location } from '../../../../shared/entities/filters/enum/Location';
const GLOBAL_BREAKDOWNS_VIEW_GRID_INDEX = 0;

export const globalBreakDownStatsElementOptions: StatsElementOptions = {
  chartsOptions: [
    {
      title: '',
      type: ChartType.Base,
      dynamicFilters: [StatsFilterName.CountryRegion],
      optionsList: [
        {
          yAxis: [
            {
              title: {
                text: 'Proceeds in Billions (US$)',
              },
              labels: {
                formatter() {
                  return NumberHelper.getFormatValue(this.value, NumberFormat.CurrencyIntegerWithLetter);
                },
              },
              stackLabels: {
                enabled: true,
                formatter() {
                  return NumberHelper.getFormatValue(this.total as number, NumberFormat.CurrencyFloatWithLetter);
                },
              },
            },
            {
              title: {
                text: 'Number of Total IPOs',
              },
              opposite: true,
            },
          ],
          series: [
            {
              type: 'line',
              yAxis: 1,
            },
          ],
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: false,
              },
            },
          },
          tooltip: {
            formatter() {
              return ChartTooltipFormatterService.getTooltip({
                points: this.points as CustomAny[],
                title: `${this.x}`,
                pointsValueType: [
                  ChartValuesType.Integer,
                  ChartValuesType.Currency,
                  ChartValuesType.Currency,
                  ChartValuesType.Currency,
                  ChartValuesType.Currency,
                  ChartValuesType.Currency,
                  ChartValuesType.Currency,
                ],
              });
            },
          },
        },
      ],
    },
    {
      title: 'Global IPO Returns by Region',
      internalFilter: {
        label: StatsFiltersTitle.ReturnsFrom,
      },
      dynamicFilters: [StatsFilterName._IPOsFromDateRange],
      optionsList: [
        {
          yAxis: {
            title: {
              text: '',
            },
            labels: {
              formatter() {
                return NumberHelper.getFormatValue(this.value as number, NumberFormat.PercentageInteger);
              },
            },
          },
          plotOptions: {
            column: {
              dataLabels: {
                formatter() {
                  return NumberHelper.getFormatValue(this.point.y as number, NumberFormat.PercentageFloat);
                },
              },
            },
          },
          tooltip: {
            formatter() {
              return ChartTooltipFormatterService.getTooltip({
                points: this.points as CustomAny[],
                title: `${this.x}`,
                pointsValueType: ChartValuesType.PercentageFloat,
              });
            },
          },
          legend: {
            title: {
              text: ' ',
            },
            enabled: false,
          },
        },
      ],
    },
  ],
  viewGridOptions: {
    [GLOBAL_BREAKDOWNS_VIEW_GRID_INDEX]: {
      hasNoCountReducing: true,
      idCols: [ViewColumnSystemName.RegionCountry],
      mobilePinColumns: [ViewColumnSystemName.RegionCountry],
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(context => {
        return {
          viewType: ViewType.LocationInUse,
          startDateFilterName: ViewFilterName.OfferStartDate,
          endDateFilterName: ViewFilterName.OfferEndDate,
          optionsList: [
            {
              getFilterName: ViewFilterName.ViewType,
              getFilterValue: row => {
                return row.regionCountry[0] === 'Total' ? ViewType.LocationLanding : ViewType.LocationInUse;
              },
            },
            {
              getFilterName: ViewFilterName.DealStatusDealStatusList,
              getFilterValue: () => DealStatus.Priced,
            },
            {
              getFilterName: ScreensLocationFilterName.ScreenBy,
              getFilterValue: () => ScreensLocationScreenByValue.ListingLocation,
            },
            {
              getFilterName: ViewFilterName.LocationListingLocation,
              getFilterValue: () => Location.Global,
            },
            {
              getFilterName: ScreensLocationFilterName.ListingLocation,
              getFilterValue: row => {
                if (row[ViewColumnSystemName.StatsCountryKey]) {
                  // country keys are coming in as something like 15, 100, 120, etc.
                  // 3 digits are required, so we prepend a 0 for 2 digit keys
                  // return type must be a string for Screen's filter to identify the key
                  return ('' + row[ViewColumnSystemName.StatsCountryKey]).length < 3
                    ? '0' + row[ViewColumnSystemName.StatsCountryKey]
                    : '' + row[ViewColumnSystemName.StatsCountryKey];
                }
                // if country key was not available, we send the region name,
                // and if 'Total' was the region name, we send '-1'
                return row.regionCountry[0] === 'Total' ? '-' + 1 : row.regionCountry[0]?.split(' ')?.join('');
              },
            },
          ],
        };
      }),
    },
  },
};
