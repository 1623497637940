export enum EmailPreference {
  AllManagementQuestions = 'allManagementQuestions',
  FlaggedManagementQuestions = 'flaggedManagementQuestions',
  ManagementQuestions = 'managementQuestions',

  AllPreIpoReport = 'allPreIpoReport',
  FlaggedPreIpoReport = 'flaggedPreIpoReport',
  PreIpoReport = 'preIpoReport',

  AllTermsChanges = 'allTermsChanges',
  FlaggedTermsChanges = 'flaggedTermsChanges',
  TermsChanges = 'termsChanges',

  AllPrisingAlerts = 'allPrisingAlerts',
  FlaggedPrisingAlerts = 'flaggedPrisingAlerts',
  PrisingAlerts = 'prisingAlerts',

  AllPostpones = 'allPostpones',
  FlaggedPostpones = 'flaggedPostpones',
  Postpones = 'postpones',

  MorningRecap = 'morningRecap',
  WeeklyCalendar = 'weeklyCalendar',
  WeeklyWinnersAndLoosers = 'weeklyWinnersAndLoosers',
  WeeklyWinnersAndLoosersIntl = 'weeklyWinnersAndLoosersIntl',
  SpecialReports = 'specialReports',

  NewPublicFiling = 'newPublicFiling',

  US = 'US',
  ADRs = 'ADRs',
  NonUS = 'nonUS',

  Micro = 'micro',
  SmallMicro = 'smallMicro',
  Mid = 'mid',
  MidLarge = 'midLarge',
  Large = 'large',

  Growth = 'growth',
  Value = 'value',

  ConsumerDiscStaples = 'consumerDiscStaples',
  EnergyMatLsUtilities = 'energyMatLsUtilities',
  Financials = 'financials',
  HealthCare = 'healthCare',
  Industrials = 'industrials',
  TechTelecom = 'techTelecom',
}
