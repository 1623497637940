import React from 'react';
import { GridCellFormatterCommonProps } from '../../../../../../components/grid/shared/interfaces/gridCellFormatter';
import ArticlesToolTip from '../../../view/articles-tooltip/ArticlesToolTip';
import './PcmlArticlesCell.scss';
type Props = GridCellFormatterCommonProps;

const PcmlArticlesCell: React.FC<Props> = props => {
  let TtArticlesId: any;
  if (props.data?.pcmlArticlesId) {
    TtArticlesId = props.data.pcmlArticlesId;
  }

  let artCount = 0;
  if (props.data?.pcmlArticlesCount) {
    artCount = props.data?.pcmlArticlesCount;
  }

  let formattedDate;

  if (props.data?.newsDate) {
    const date = new Date(props.data?.newsDate);
    formattedDate = `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date
      .getUTCFullYear()
      .toString()
      .substr(-2)}`;
  }

  let formattedArticle = '';
  if (props.data?.pcmlArticles && props.data?.pcmlArticles.length > 17) {
    formattedArticle = props.data.pcmlArticles.substring(0, 15) + '...';
  } else if (props.data?.pcmlArticles) {
    formattedArticle = props.data.pcmlArticles;
  }

  return (
    <>
      {/** Use Capitilization Data Cell as an example for the news tooltip */}
      {artCount > 1 ? (
        <>
          <a href={props.data?.newsUrl} target="_blank" rel="noopener noreferrer">
            {formattedArticle}
            <br />
            {formattedDate}&ensp;
          </a>
          <div className="ttArticlesContent">
            <ArticlesToolTip articlesId={TtArticlesId} />
          </div>
        </>
      ) : (
        <a href={props.data?.newsUrl} target="_blank" rel="noopener noreferrer">
          {formattedArticle}
          <br />
          {formattedDate}
        </a>
      )}
    </>
  );
};

export default PcmlArticlesCell;
