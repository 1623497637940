import React from 'react';
import { ControlSize } from '../../enums/ControlSize';
import { IconName } from '../icon/IconName';
import Icon from '../icon/Icon';
import './ButtonIcon.scss';
import Button from './Button';
import { ColorScheme } from '../../enums/ColorScheme';
import { useClassName } from '../../hooks/useClassName';
import { ButtonIconBlock } from './ButtonIconBem';

export interface ButtonIconProps {
  onClick?: () => void;
  isDisabled?: boolean;
  size?: ControlSize;
  description?: React.ReactNode;
  className?: string;
  colorScheme?: ColorScheme;
  iconName: IconName;
}

const ButtonIcon: React.FC<ButtonIconProps> = props => {
  const { size = ControlSize.Small, colorScheme = ColorScheme.Primary } = props;

  const cn = useClassName(ButtonIconBlock.Root, props.className);

  return (
    <Button
      className={cn({
        [`${colorScheme}`]: colorScheme,
      })}
      size={size}
      onClick={props.onClick}
      isDisabled={props.isDisabled}
    >
      {props.description} <Icon name={props.iconName} size={size} colorScheme={colorScheme} />
    </Button>
  );
};

export default ButtonIcon;
