import React, { useContext, useMemo } from 'react';
import './CompanyRelativeValuation.scss';
import { CompanyContext } from '../context/CompanyContext';
import CompanyParagraph from '../shared/CompanyParagraph';
import { Nullable } from '../../../shared/types/generics';
import { CompanyRelativeValuationDto } from '../../interfaces/CompanyRelativeValuationDto';
import { CompanyTab } from '../../enums/CompanyTab';
import HtmlStringFormatter from '../../../shared/components/HtmlStringFormatter';
import NewsArticleRelated from '../../../news/article/NewsArticleRelated';
import { NewsType } from '../../../news/enums/NewsType';
import HtmlStringFormatterJustified from '../../../shared/components/HtmlStringFormatterJustified';

const CompanyRelativeValuationDescription: React.FC = () => {
  const { getTabData } = useContext(CompanyContext);
  const data: Nullable<CompanyRelativeValuationDto> = getTabData(CompanyTab.RelativeValuation);

  const description = useMemo(() => data?.relativeValuation, [data]);

  const articles = useMemo(() => description?.note && [description.note], [description]);

  return (
    <>
      {articles && <NewsArticleRelated articles={articles} type={NewsType.AnalystNote} />}

      {description?.priceTargets && (
        <CompanyParagraph text={<HtmlStringFormatter content={description.priceTargets} />} />
      )}

      {description?.paragraph && (
        <CompanyParagraph
          label={description.paragraph.label}
          text={<HtmlStringFormatterJustified value={description.paragraph.text as string} />}
        />
      )}
    </>
  );
};

export default CompanyRelativeValuationDescription;
