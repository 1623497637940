import React, { useMemo } from 'react';
import Toast from 'react-bootstrap/Toast';
import { DateHelper } from '../../helpers/date/DateHelper';
import './ToastHeader.scss';
import classNames from 'classnames';
import { DateFormat } from '../../helpers/date/enums/DateFormat';
import { IsoDate } from '../../types';

interface Props {
  title: React.ReactNode;
  createdAt?: IsoDate;
  className?: string;
  closeButton?: boolean;
}

const ToastHeader: React.FC<Props> = props => {
  const createdAt = useMemo(
    () =>
      props.createdAt &&
      DateHelper.formatDate(
        DateHelper.getLocalDateFromISODateStr(props.createdAt),
        DateFormat.LongMonthDayYearWithTime,
      ),
    [props.createdAt],
  );

  return (
    <Toast.Header
      className={classNames('ToastHeader', props.className)}
      closeButton={props.closeButton === false ? false : true}
    >
      {props.children}

      <h6 className="ToastHeader__title">{props.title}</h6>

      {createdAt && <span className="ToastHeader__createdAt">{createdAt}</span>}
    </Toast.Header>
  );
};

export default ToastHeader;
