import React, { useMemo } from 'react';
import './StatsElementChartFilterInternal.scss';
import StatsElementFilterInternalTextSelect from '../../shared/filters-internal/components/StatsElementFilterInternalTextSelect';
import { ControlDivider } from '../../../../../components/forms/services/control-divider/enums/ControlDivider';
import { TextSelectElementType } from '../../../../../components/forms/components/text-select/enums/TextSelectElementType';
import { StatsElementChartProps } from '../interfaces/StatsElementChartProps';
import { StatsElementChartDto } from '../types/StatsElementChartDto';
import { useClassName } from '../../../../../hooks/useClassName';
import { StatsElementFiltersInternalService } from '../../shared/filters-internal/services/StatsElementFiltersInternalService';
import { StatsElementFilterInternalName } from '../../../enums/StatsElementGridFilterName';

interface Props {
  data: StatsElementChartDto;
  chartOptions?: Partial<StatsElementChartProps>;
  internalFilterValue?: StatsElementFilterInternalName;
  onChangeInternalFilter: (filter: StatsElementFilterInternalName) => void;
  className?: string;
}

const StatsElementChartFilterInternal: React.FC<Props> = props => {
  const { chartOptions, data, internalFilterValue, onChangeInternalFilter } = props;

  const cn = useClassName('StatsElementChartFilterInternal', props.className);

  const internalFiltersOptions = useMemo(
    () => StatsElementFiltersInternalService.getTextSelectOptionsFromFilters(data),
    [data],
  );

  return (
    <div className={cn()}>
      <StatsElementFilterInternalTextSelect
        {...chartOptions?.internalFilter}
        divider={ControlDivider.Slash}
        elementType={TextSelectElementType.Button}
        value={internalFilterValue}
        options={internalFiltersOptions}
        onChangeValue={onChangeInternalFilter}
      />

      {/* {chartOptions ?.getInternalFilterAdditionalComponent ?.()} */}
    </div>
  );
};

export default StatsElementChartFilterInternal;
