import React, { useContext } from 'react';
import Badge, { BadgeVariants } from '../../../shared/components/Badge';
import { CompanyContext } from '../context/CompanyContext';
import './CompanyTeamNotesTitle.scss';
import { useLoadDataEffect } from '../hooks/useLoadDataEffect';

const CompanyTeamNotesTitle: React.FC = () => {
  const { unreadTeamNotesCount, teamNotes } = useContext(CompanyContext);

  const [teamNotesData] = teamNotes;
  const [data, loadData] = unreadTeamNotesCount;
  useLoadDataEffect(data, loadData, [teamNotesData]);

  return (
    <div className="CompanyTeamNotesTitle">
      Team Notes
      {!!data && <Badge variant={BadgeVariants.Info}>{data}</Badge>}
    </div>
  );
};

export default CompanyTeamNotesTitle;
