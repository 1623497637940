import React, { useCallback, useContext, useMemo, useEffect } from 'react';
import './SearchResultsGrid.scss';
import { ViewDataTable } from '../../../shared/entities/view/components/view/interfaces/api/ViewDataTable';
import ViewDashboardGrid from '../../../shared/entities/view/components/view-dashboard/components/ViewDashboardGrid';
import { FiltersMapValue } from '../../../shared/entities/filters/components/filterable-component/interfaces/FiltersMapValue';
import { SearchResultsFilterGroupName } from '../../enums/SearchResultsFilterGroupName';
import { ViewDashboardContext } from '../../../shared/entities/view/components/view-dashboard/ViewDashboardContext';
import { ViewGridColumnService } from '../../../shared/entities/view/components/view-grid/services/ViewGridColumnService';
import { ViewColumnSystemName } from '../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { GridAgColumn } from '../../../shared/components/grid/grid/interfaces/GridAgColumn';
import { PortionSize } from '../../../shared/enums/PortionSize';
import { ComponentMessage } from '../../../shared/enums/ComponentMessage';
import ViewDashboardSecondarySection from '../../../shared/entities/view/components/view-dashboard/components/ViewDashboardSecondarySection';

interface Props {
  gridTitle?: string;
  dataTable: ViewDataTable;
  searchControls?: FiltersMapValue[];
  filterGroupName?: SearchResultsFilterGroupName;
  customColumnsSettings?: Partial<GridAgColumn>[];
  isColumnsFitGrid?: boolean;
  overlayNoRowsTemplate?: ComponentMessage;
  onChange?: () => void;
}

const SearchResultsGrid: React.FC<Props> = props => {
  const { isColumnsFitGrid = false } = props;

  const { innerFilters, isInnerLoading, onInnerFilterChange, onFilter } = useContext(ViewDashboardContext);

  const searchDefaultValues = useMemo(
    () => innerFilters && props.filterGroupName && innerFilters[props.filterGroupName],
    [innerFilters, props.filterGroupName],
  );

  const colIds = useMemo(
    () => ViewGridColumnService.getColumnsNames(props.dataTable.columns) as ViewColumnSystemName[],
    [props.dataTable.columns],
  );

  const paginationOptions = useMemo(() => ({ pageSize: PortionSize.TwentyFive }), []);

  const onSearch = useCallback(() => onFilter(), [onFilter]);

  const onSearchDataChange = useCallback(
    formData => {
      if (props.filterGroupName) {
        onInnerFilterChange(props.filterGroupName, formData);
      }
    },
    [props.filterGroupName, onInnerFilterChange],
  );

  useEffect(() => {
    if (props.onChange && !isInnerLoading) {
      props.onChange();
    }
  }, [isInnerLoading]);

  return (
    <ViewDashboardSecondarySection title={props.gridTitle}>
      <ViewDashboardGrid
        dataTable={props.dataTable}
        searchControls={props.searchControls}
        hasPagination
        paginationOptions={paginationOptions}
        isColumnsFitGrid={isColumnsFitGrid}
        colIds={colIds}
        searchDefaultValues={searchDefaultValues}
        customColumnsSettings={props.customColumnsSettings}
        shouldWrapCellContainer={true}
        isLoading={isInnerLoading}
        onSearch={onSearch}
        onSearchDataChange={onSearchDataChange}
        overlayNoRowsTemplate={props.overlayNoRowsTemplate}
        onChange={props.onChange}
      />
    </ViewDashboardSecondarySection>
  );
};

export default SearchResultsGrid;
