import React, { FunctionComponent, ReactNode, useCallback, useMemo } from 'react';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import { GridLayoutService } from './services/GridLayoutService';
import 'react-grid-layout/css/styles.css';
import './GridLayout.scss';
import { remSize } from '../../constants';
import classNames from 'classnames';
import { gridLayoutCols } from './constants/gridLayoutCols';
import { gridLayoutBreakpointSizes } from './constants/gridLayoutBreakpointSizes';
import { GridLayoutWidget } from './interfaces/GridLayoutWidget';
import isEqual from 'lodash/isEqual';
import { GridLayoutBreakpoint } from './enums/GridLayoutBreakpoint';
import { Pixels } from '../../types';
import useBreakpointAvailable from '../../effects/useBreakpointAvailable';
import { mobileBreakpoints } from '../../constants/breakpoints/mobileBreakpoints';
import { gridLayoutMargin } from './constants/gridLayoutMargin';
import { getGridLayoutBreakpoint, getUpdatedWidgets } from './services/helpers';

const ResponsiveGridLayout = WidthProvider(Responsive);

interface Props {
  widgets: GridLayoutWidget[];
  children: ReactNode[];
  onWidgetsChange?: (widgets: GridLayoutWidget[]) => void;
  onBreakpointChange?: (breakpoint: GridLayoutBreakpoint) => void;
  onWidthChange?: (width: Pixels) => void;
  className?: string;
  updateResizedFlag?: () => void;
  width?: number | null;
}

const GridLayout: FunctionComponent<Props> = props => {
  const { onWidgetsChange, onBreakpointChange, onWidthChange, width } = props;

  const isMobile = useBreakpointAvailable(mobileBreakpoints);

  const layouts = useMemo<Layouts>(() => GridLayoutService.createLayouts(props.widgets), [props.widgets]);
  const isChanged = useCallback((widgets: GridLayoutWidget[]) => !isEqual(widgets, props.widgets), [props.widgets]);

  const onGridLayoutChange = useCallback(
    (layout: Layout[], layouts: Layouts) => {
      if (onWidgetsChange) {
        const newWidgets = GridLayoutService.createWidgets(layouts);

        if (isChanged(newWidgets)) {
          onWidgetsChange(newWidgets);
        }
      }
    },
    [onWidgetsChange, isChanged],
  );

  const updateLayout = (layout: Layout[]) => {
    const breakPoint: GridLayoutBreakpoint = getGridLayoutBreakpoint(width ? width : 1189);
    const updatedWidgets = getUpdatedWidgets(props.widgets, layout, breakPoint);

    if (onWidgetsChange) {
      onWidgetsChange(updatedWidgets);
    }
  };

  const onDragOrResizeStop = (layout: Layout[]) => {
    updateLayout(layout);
    if (props.updateResizedFlag) {
      props.updateResizedFlag();
    }
  };

  const onGridBreakpointChange = useCallback(
    (breakpoint: string) => onBreakpointChange?.(breakpoint as GridLayoutBreakpoint),
    [onBreakpointChange],
  );

  const onGridWidthChange = useCallback(
    (width: number) => {
      onWidthChange?.(width);
    },
    [onWidthChange],
  );

  return (
    <ResponsiveGridLayout
      className={classNames('GridLayout', props.className)}
      layouts={layouts}
      cols={gridLayoutCols}
      breakpoints={gridLayoutBreakpointSizes}
      margin={gridLayoutMargin}
      onLayoutChange={onGridLayoutChange}
      onBreakpointChange={onGridBreakpointChange}
      onWidthChange={onGridWidthChange}
      rowHeight={remSize}
      isResizable={!isMobile}
      isDraggable={!isMobile}
      verticalCompact={true}
      onResizeStop={onDragOrResizeStop}
      onDragStop={onDragOrResizeStop}
      // useCSSTransforms={false}
    >
      {props.widgets.map((widget: GridLayoutWidget, index: number) => (
        <div key={widget.type}>{props.children[index]}</div>
      ))}
    </ResponsiveGridLayout>
  );
};

export default GridLayout;
