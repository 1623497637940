import { FilterSectionType } from '../../shared/entities/filters/enum/FilterSectionType';
import { FilterSection } from '../../shared/entities/filters/interfaces/FilterSection';
import { FilterSectionControl } from '../../shared/entities/filters/interfaces/FilterSectionControl';
import { dateControls } from '../../shared/entities/filters/constants/filter-controls/view-filter-sections/dateControls';
import { offerDateControls } from '../../shared/entities/filters/constants/filter-controls/view-filter-sections/offerDateControls';
import { fileDateControls } from '../../shared/entities/filters/constants/filter-controls/view-filter-sections/fileDateControls';
import { dealTimingControls } from '../../shared/entities/filters/constants/filter-controls/view-filter-sections/dealTimingControls';
import { companyInfoControls } from '../../shared/entities/filters/constants/filter-controls/view-filter-sections/companyInfoControls';
import { investorsControls } from '../../shared/entities/filters/constants/filter-controls/view-filter-sections/investorsControls';
import { securityTypeControls } from '../../shared/entities/filters/constants/filter-controls/view-filter-sections/securityTypeControls';
import { returnsControls } from '../../shared/entities/filters/constants/filter-controls/view-filter-sections/returnsControls';
import { dealInfoControls } from '../../shared/entities/filters/constants/filter-controls/view-filter-sections/dealInfoControls';
import { favouriteControls } from '../../shared/entities/filters/constants/filter-controls/view-filter-sections/favouriteControls';
import { dealStatusControls } from '../../shared/entities/filters/constants/filter-controls/view-filter-sections/dealStatusControls';
import { renaissanceRatingControls } from '../../shared/entities/filters/constants/filter-controls/view-filter-sections/renaissanceRatingControls';
import { financialsControls } from '../../shared/entities/filters/constants/filter-controls/view-filter-sections/financialsControls';
import { valuationControls } from '../../shared/entities/filters/constants/filter-controls/view-filter-sections/valuationControls';
import { locationControls } from '../../shared/entities/filters/constants/filter-controls/view-filter-sections/locationControls';

export const masterFilterFormSettings: FilterSection[] = [
  {
    type: FilterSectionType.Date,
    controls: Object.values(dateControls) as FilterSectionControl[],
  },
  {
    type: FilterSectionType.OfferDate,
    controls: Object.values(offerDateControls) as FilterSectionControl[],
  },
  {
    type: FilterSectionType.FileDate,
    controls: Object.values(fileDateControls) as FilterSectionControl[],
  },
  {
    type: FilterSectionType.DealTiming,
    controls: Object.values(dealTimingControls) as FilterSectionControl[],
  },
  {
    type: FilterSectionType.CompanyInfo,
    controls: Object.values(companyInfoControls) as FilterSectionControl[],
  },
  {
    type: FilterSectionType.Investors,
    controls: Object.values(investorsControls) as FilterSectionControl[],
  },
  {
    type: FilterSectionType.SecurityType,
    controls: Object.values(securityTypeControls) as FilterSectionControl[],
  },
  {
    type: FilterSectionType.Returns,
    controls: Object.values(returnsControls) as FilterSectionControl[],
  },
  {
    type: FilterSectionType.DealInfo,
    controls: Object.values(dealInfoControls) as FilterSectionControl[],
  },
  {
    type: FilterSectionType.Favorite,
    controls: Object.values(favouriteControls) as FilterSectionControl[],
  },
  {
    type: FilterSectionType.DealStatus,
    controls: Object.values(dealStatusControls) as FilterSectionControl[],
  },
  {
    type: FilterSectionType.RenaissanceRating,
    controls: Object.values(renaissanceRatingControls) as FilterSectionControl[],
  },
  {
    type: FilterSectionType.Financials,
    controls: Object.values(financialsControls) as FilterSectionControl[],
  },
  {
    type: FilterSectionType.Valuation,
    controls: Object.values(valuationControls) as FilterSectionControl[],
  },
  {
    type: FilterSectionType.Location,
    controls: Object.values(locationControls) as FilterSectionControl[],
  },
];
