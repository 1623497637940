import React, { useCallback, useState } from 'react';
import Form from '../../shared/components/forms/Form';
import FormGroup from '../../shared/components/forms/FormGroup';
import { TalkToAnalystData } from './TalkToAnalystData';
import { validationSchema } from './validationSchema';
import ButtonSubmit from '../../shared/components/forms/ButtonSubmit';
import { SubmitFormFn } from '../../shared/components/forms/types/SubmitFormFn';
import ApiContactService from '../../shared/api/ContactApiService';
import TalkToAnalystFormFields from './TalkToAnalystFormFields';
import './TalkToAnalystForm.scss';
import { ControlSize } from '../../shared/enums/ControlSize';
import { AlignTypesHorizontal } from '../../shared/enums/AlignTypesHorizontal';
import { SUCCESS_MESSAGE } from './constant/messages';

const TalkToAnalystForm: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const onSubmit: SubmitFormFn<TalkToAnalystData> = useCallback(
    async (data: TalkToAnalystData) => {
      await ApiContactService.requestTalkToAnalyst(data);

      setIsSubmitted(true);
    },
    [setIsSubmitted],
  );

  return (
    <Form
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      size={ControlSize.Large}
      alignHorizontal={AlignTypesHorizontal.Center}
    >
      <TalkToAnalystFormFields isSubmitted={isSubmitted} />

      <FormGroup>
        <ButtonSubmit isSubmitted={isSubmitted} message="Submit" submittedMessage={SUCCESS_MESSAGE} />
      </FormGroup>
    </Form>
  );
};

export default TalkToAnalystForm;
