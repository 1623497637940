import React from 'react';
import EntityActionModal, { EntityActionModalCommonProps } from './EntityActionModal';
import { AnyObject, CustomAny } from '../../types/generics';
import { ControlSize } from '../../enums/ControlSize';
import { AlignTypesHorizontal } from '../../enums/AlignTypesHorizontal';
import { GetNotificationSuccessMessageFn } from '../../../store/notification/types';
import { SubmitFormFn } from '../forms/types/SubmitFormFn';

interface Props extends EntityActionModalCommonProps {
  defaultValues?: AnyObject;
  validationSchema?: CustomAny;
  getSuccessMessage?: GetNotificationSuccessMessageFn;
  onSubmit: SubmitFormFn<CustomAny>;
}

const FormEntityActionModal: React.FC<Props> = props => {
  return (
    <EntityActionModal
      actionType={props.actionType}
      entityName={props.entityName}
      showState={props.showState}
      form={{
        validationSchema: props.validationSchema,
        defaultValues: props.defaultValues,
        size: ControlSize.ExtraLarge,
        alignHorizontal: AlignTypesHorizontal.Center,
        getSuccessMessage: props.getSuccessMessage,
      }}
      body={props.body}
      onAction={props.onSubmit as CustomAny} // Temporary stab
    />
  );
};

export default FormEntityActionModal;
