import { StatsElementOptions } from '../../../../shared/entities/stats-element/interfaces/StatsElementOptions';
import { ChartType } from '../../../../shared/components/chart/enums/ChartType';
import { StatsElementChartProps } from '../../../../shared/entities/stats-element/components/chart/interfaces/StatsElementChartProps';
import { getStatsElementSimpleGridColumnYearToViewMapper } from '../../../../shared/entities/stats-element/components/simple-grid/constants/statsElementSimpleGridColumnMappers';
import { ViewType } from '../../../../shared/entities/view/components/view/enums/ViewType';
import { ViewFilterName } from '../../../../shared/entities/filters/enum/types/ViewFilterName';
import { DealStatus } from '../../../../shared/entities/filters/enum/DealStatus';
import { TimeFrame } from '../../../../shared/entities/filters/enum/TimeFrame';
import { filingsCustomScreenColumns } from './filingsCustomScreenColumns';
import { StatsFilterName } from '../../enum/StatsFilterName';

const FILINGS_GRID_INDEX = 0;

export const getFilingsChartOptions: (chartTitle: string) => Partial<StatsElementChartProps> = (
  chartTitle: string,
) => ({
  title: chartTitle,
  type: ChartType.Base,
  optionsList: [
    {
      chart: {
        type: 'column',
      },
      yAxis: {
        title: {
          text: 'Number of Filings',
        },
        stackLabels: {
          enabled: false,
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
          },
        },
        column: {
          grouping: false,
        },
      },
    },
  ],
});

export const getFilingsStatsElementOptions: (filingsChartTitle: string) => StatsElementOptions = (
  filingsChartTitle: string,
) => ({
  chartsOptions: [getFilingsChartOptions(filingsChartTitle)],
  gridOptions: {
    [FILINGS_GRID_INDEX]: {
      onColumnMapReady: getStatsElementSimpleGridColumnYearToViewMapper(context => ({
        viewType: ViewType.CustomInUse,
        startDateFilterName: ViewFilterName.FileDateStartDate,
        endDateFilterName: ViewFilterName.FileDateEndDate,
        columns: {
          web: filingsCustomScreenColumns,
          excel: filingsCustomScreenColumns,
        },
        optionsList: [
          {
            getFilterName: ViewFilterName.DealStatusIn,
            getFilterValue: () => [DealStatus.Filed, DealStatus.Postponed, DealStatus.Priced],
          },
          {
            getFilterName: ViewFilterName.OfferTimeFrame,
            getFilterValue: () => TimeFrame.All,
          },
          {
            getFilterName: ViewFilterName.SecurityTypeBlankCheckList,
            getFilterValue: () => (context?.pageFilters?.[StatsFilterName.IncludeSPACs] ? 'include' : ''),
          },
        ],
      })),
      isHighlightLastRow: true,
      isStickySecondColumn: true,
    },
  },
});
