export class GaBuilders {
  // Building grid props with data retrieved from API for Page Views (and Specific Page Views)

  static buildAnalyticsData(data: any) {
    const gridProps: any = { columns: [], rows: [] };
    const dimensionHeaderNames: string[] = [];
    const metricHeaderNames: string[] = [];

    // Map for nice column titles, keys are names from JSON name/value pairs recieved from API
    const titleMap = new Map<string, string>([
      ['eventName', 'Event Name'],
      ['fullPageUrl', 'Full Page Url'],
      ['eventCount', 'Event Count'],
      ['pagePath', 'Page Path'],
      ['customUser:userId', 'User ID'],
      ['email', 'Email'],
      ['clientName', 'Client Name'],
      ['accountName', 'Account Name'],
    ]);

    data?.dimensionHeaders?.forEach((item: { name: string }) => {
      if (item.name) {
        const column: any = {};
        const nested: any = {};

        dimensionHeaderNames.push(item.name);

        column.name = item.name;
        column.nestedColumns = [];
        nested.name = item.name;
        nested.title = titleMap.get(item.name);
        nested.type = 'Text';
        column.nestedColumns.push(nested);
        gridProps.columns.push(column);
      }
    });
    data?.metricHeaders?.forEach((item: { name: string }) => {
      if (item.name) {
        const column: any = {};
        const nested: any = {};

        metricHeaderNames.push(item.name);

        column.name = item.name;
        column.nestedColumns = [];
        nested.name = item.name;
        nested.title = titleMap.get(item.name);
        nested.type = 'Text';
        column.nestedColumns.push(nested);
        gridProps.columns.push(column);
      }
    });
    data?.rows?.forEach((item: any, index: any) => {
      const row: any = {};

      item?.dimensionValues?.forEach((rowItem: any, rowItemIndex: number) => {
        row[dimensionHeaderNames[rowItemIndex]] = rowItem.value;
      });
      item?.metricValues?.forEach((rowItem: any, rowItemIndex: number) => {
        row[metricHeaderNames[rowItemIndex]] = rowItem.value;
      });
      gridProps.rows.push(row);
    });
    return gridProps;
  }

  // Building grid props with data retrieved from API for Activity per Account

  static buildActivityPerAccountData(data: any) {
    const gridProps: any = { columns: [], rows: [] };
    const headerNames: string[] = [];

    // Map for nice column titles, keys are names from JSON name/value pairs recieved from API
    const titleMap = new Map<string, string>([
      ['count', 'Count'],
      ['pagePath', 'Path'],
      ['firstName', 'First Name'],
      ['lastName', 'Last Name'],
      ['email', 'Email'],
      ['dateLogged', 'Date Logged'],
      ['browser', 'Browser'],
      ['deviceCategory', 'Device'],
      ['operatingSystemWithVersion', 'OS'],
      ['hostName', 'Host'],
      ['userId', 'User ID'],
    ]);

    if (data) {
      if (data.usersPages) {
        // Getting the key names to name column Headers from the JSON
        for (const index in data?.usersPages[0]) {
          const column: any = {};
          const nested: any = {};

          if (index !== 'userId' && index !== 'hostName') {
            if (index === 'email') {
              headerNames.push('userIdUrl');
            } else if (index === 'pagePath') {
              headerNames.push(index + 'Url');
            } else {
              headerNames.push(index);
            }
            column.name = index;
            column.nestedColumns = [];
            if (index === 'email') {
              nested.name = 'userIdUrl';
            } else if (index === 'pagePath') {
              nested.name = index + 'Url';
            } else {
              nested.name = index;
            }
            nested.title = titleMap.get(index);
            nested.type = 'Text';
            column.nestedColumns.push(nested);
            gridProps.columns.push(column);
          }
        }

        // Creating a new object to make a row and populating with
        // JSON information
        data?.usersPages?.forEach((item: any) => {
          const row: any = {};
          row[headerNames[0]] = item.count;
          row[headerNames[1]] = item.pagePath;
          row[headerNames[2]] = item.firstName;
          row[headerNames[3]] = item.lastName;
          row[headerNames[4]] = item.email;
          row[headerNames[5]] = item.dateLogged.split('T')[0]; // Removing time from dateTime
          row[headerNames[6]] = item.browser;
          row[headerNames[7]] = item.deviceCategory;
          row[headerNames[8]] = item.operatingSystemWithVersion;
          gridProps.rows.push(row);
        });
      }
    }
    return gridProps;
  }

  // Builds the data from the usersDownloads section of activityPerAccount call

  static buildDownloadsPerAccountData(data: any) {
    const gridProps: any = { columns: [], rows: [] };
    const headerNames: string[] = [];

    // Map for nice column titles, keys are names from JSON name/value pairs recieved from API
    const titleMap = new Map<string, string>([
      ['count', 'Count'],
      ['dateLogged', 'Date'],
      ['pdf', 'PDF'],
      ['excel', 'Excel'],
      ['firstName', 'First Name'],
      ['lastName', 'Last Name'],
      ['email', 'Email'],
      ['userId', 'User ID'],
    ]);

    if (data) {
      if (data.usersDownloads) {
        // Getting the key names to name column Headers from the JSON
        for (const i in data?.usersDownloads[0]) {
          const column: any = {};
          const nested: any = {};

          // Taking out user ID from the table
          if (i !== 'userId') {
            if (i === 'email') {
              headerNames.push('userIdUrl');
            } else if (i === 'pdf') {
              headerNames.push('fileNameUrl');
            } else if (i === 'excel') {
              headerNames.push('fileNameExcelUrl');
            } else {
              headerNames.push(i);
            }
            column.name = i;
            column.nestedColumns = [];
            if (i === 'email') {
              nested.name = 'userIdUrl';
            } else if (i === 'pdf') {
              nested.name = 'fileNameUrl';
            } else if (i === 'excel') {
              nested.name = 'fileNameExcelUrl';
            } else {
              nested.name = i;
            }
            nested.title = titleMap.get(i);
            nested.type = 'Text';
            column.nestedColumns.push(nested);
            gridProps.columns.push(column);
          }
        }

        // Creating a new object to make a row and populating with
        // JSON information
        data?.usersDownloads?.forEach((item: any) => {
          const row: any = {};
          row[headerNames[0]] = item.count;
          row[headerNames[1]] = item.dateLogged.split('T')[0]; // Removing time from dateTime
          row[headerNames[2]] = item.pdf;
          row[headerNames[3]] = item.excel;
          row[headerNames[4]] = item.firstName;
          row[headerNames[5]] = item.lastName;
          row[headerNames[6]] = item.email;
          // row[headerNames[7]] = item.userId;

          gridProps.rows.push(row);
        });
      }
    }
    return gridProps;
  }

  // ANALYTICS BUILDERS
  // OLD
  //////////////////////////////////////////////////////////////////////////////
  // NEW
  // LANDING PAGE BUILDERS

  // Builds Most Active Page Data from API Response
  static buildActivePagesData(data: any) {
    const gridProps: any = { columns: [], rows: [] };
    const headerNames: string[] = [];

    const titleMap = new Map<string, string>([
      ['count', 'Count'],
      ['pagePath', 'Page Path'],
    ]);

    if (data) {
      if (data?.mostActivePages) {
        // Creating table header data
        for (const i in data?.mostActivePages[0]) {
          const column: any = {};
          const nested: any = {};

          if (i === 'pagePath') {
            headerNames.push(i + 'Url');
          } else {
            headerNames.push(i);
          }
          column.name = i;
          column.nestedColumns = [];
          if (i === 'pagePath') {
            nested.name = i + 'Url';
          } else {
            nested.name = i;
          }
          nested.title = titleMap.get(i);
          nested.type = 'Text';

          column.nestedColumns.push(nested);
          gridProps.columns.push(column);
        }

        data?.mostActivePages?.forEach((item: any) => {
          const row: any = {};
          row[headerNames[0]] = item.count;
          row[headerNames[1]] = item.pagePath;
          gridProps.rows.push(row);
        });
      }
    }

    return gridProps;
  }

  static buildActivePdfData(data: any) {
    const gridProps: any = { columns: [], rows: [] };
    const headerNames: string[] = [];

    const titleMap = new Map<string, string>([
      ['count', 'Count'],
      ['fileName', 'File Name'],
      ['reportType', 'Report Type'],
    ]);

    if (data) {
      if (data?.mostActivePdfDownloads) {
        // Creating table header data
        for (const i in data?.mostActivePdfDownloads[0]) {
          const column: any = {};
          const nested: any = {};

          if (i === 'fileName') {
            headerNames.push(i + 'Url');
          } else {
            headerNames.push(i);
          }
          column.name = i;
          column.nestedColumns = [];
          if (i === 'fileName') {
            nested.name = i + 'Url';
          } else {
            nested.name = i;
          }
          nested.title = titleMap.get(i);
          nested.type = 'Text';

          column.nestedColumns.push(nested);
          gridProps.columns.push(column);
        }

        data?.mostActivePdfDownloads?.forEach((item: any) => {
          const row: any = {};
          row[headerNames[0]] = item.count;
          row[headerNames[1]] = item.fileName;
          row[headerNames[2]] = item.reportType;
          gridProps.rows.push(row);
        });
      }
    }

    return gridProps;
  }

  static buildActiveExcelData(data: any) {
    const gridProps: any = { columns: [], rows: [] };
    const headerNames: string[] = [];

    const titleMap = new Map<string, string>([
      ['count', 'Count'],
      ['fileName', 'File Name'],
      ['reportType', 'Report Type'],
    ]);

    if (data) {
      if (data.mostActiveExcelDownloads) {
        // Creating table header data
        for (const i in data?.mostActiveExcelDownloads[0]) {
          const column: any = {};
          const nested: any = {};

          if (i === 'fileName') {
            headerNames.push(i + 'Url');
          } else {
            headerNames.push(i);
          }
          column.name = i;
          column.nestedColumns = [];
          if (i === 'fileName') {
            nested.name = i + 'Url';
          } else {
            nested.name = i;
          }
          nested.title = titleMap.get(i);
          nested.type = 'Text';

          column.nestedColumns.push(nested);
          gridProps.columns.push(column);
        }

        data?.mostActiveExcelDownloads?.forEach((item: any) => {
          const row: any = {};
          row[headerNames[0]] = item.count;
          row[headerNames[1]] = item.fileName;
          row[headerNames[2]] = item.reportType;
          gridProps.rows.push(row);
        });
      }
    }
    return gridProps;
  }

  static buildActiveAccountsData(data: any) {
    const gridProps: any = { columns: [], rows: [] };
    const headerNames: string[] = [];

    const titleMap = new Map<string, string>([
      ['count', 'Count'],
      ['accountName', 'Account Name'],
      ['accountId', 'Account ID'],
    ]);

    if (data) {
      if (data?.mostActiveAccounts) {
        // Creating table header data
        for (const i in data?.mostActiveAccounts[0]) {
          const column: any = {};
          const nested: any = {};

          if (i === 'accountId') {
            headerNames.push(i + 'Url');
          } else {
            headerNames.push(i);
          }

          column.name = i;
          column.nestedColumns = [];
          nested.name = i;
          if (i === 'accountId') {
            nested.name = i + 'Url';
          } else {
            nested.name = i;
          }
          nested.title = titleMap.get(i);
          nested.type = 'Text';

          column.nestedColumns.push(nested);
          gridProps.columns.push(column);
        }

        data?.mostActiveAccounts?.forEach((item: any) => {
          const row: any = {};
          row[headerNames[0]] = item.count;
          row[headerNames[1]] = item.accountName;
          row[headerNames[2]] = item.accountId;
          gridProps.rows.push(row);
        });
      }
    }
    return gridProps;
  }

  static buildDownloadsByAccountData(data: any) {
    const gridProps: any = { columns: [], rows: [] };
    const headerNames: string[] = [];

    const titleMap = new Map<string, string>([
      ['count', 'Count'],
      ['accountName', 'Account Name'],
      ['accountId', 'Account ID'],
    ]);

    if (data) {
      if (data?.downloadsByAccounts) {
        // Creating table header data
        for (const i in data?.downloadsByAccounts[0]) {
          const column: any = {};
          const nested: any = {};

          if (i === 'accountId') {
            headerNames.push(i + 'Url');
          } else {
            headerNames.push(i);
          }
          column.name = i;
          column.nestedColumns = [];
          if (i === 'accountId') {
            nested.name = i + 'Url';
          } else {
            nested.name = i;
          }
          nested.title = titleMap.get(i);
          nested.type = 'Text';

          column.nestedColumns.push(nested);
          gridProps.columns.push(column);
        }

        data?.downloadsByAccounts?.forEach((item: any) => {
          const row: any = {};
          row[headerNames[0]] = item.count;
          row[headerNames[1]] = item.accountName;
          row[headerNames[2]] = item.accountId;
          gridProps.rows.push(row);
        });
      }
    }
    return gridProps;
  }

  static buildActiveUsersData(data: any) {
    const gridProps: any = { columns: [], rows: [] };
    const headerNames: string[] = [];

    const titleMap = new Map<string, string>([
      ['count', 'Count'],
      ['email', 'Email'],
    ]);

    if (data) {
      if (data?.mostActiveUsers) {
        // Creating table header data
        for (const i in data?.mostActiveUsers[0]) {
          const column: any = {};
          const nested: any = {};

          if (i !== 'userId') {
            if (i === 'email') {
              headerNames.push('userIdUrl');
            } else {
              headerNames.push(i);
            }
            column.name = i;
            column.nestedColumns = [];
            if (i === 'email') {
              nested.name = 'userIdUrl';
            } else {
              nested.name = i;
            }
            nested.title = titleMap.get(i);
            nested.type = 'Text';

            column.nestedColumns.push(nested);
            gridProps.columns.push(column);
          }
        }

        data?.mostActiveUsers?.forEach((item: any) => {
          const row: any = {};
          row[headerNames[0]] = item.count;
          row[headerNames[1]] = item.email;
          row['userId'] = item.userId;
          gridProps.rows.push(row);
        });
      }
    }
    return gridProps;
  }

  static buildUserActivityDataPages(data: any) {
    const pagesProps: any = { columns: [], rows: [] };

    const headerNames: string[] = [];

    const titleMap = new Map<string, string>([
      ['count', 'Count'],
      ['pagePath', 'Page'],
      ['eventDate', 'Date'],
    ]);

    if (data) {
      if (data?.pagesVisited) {
        // Pages Visited Props creation
        for (const i in data?.pagesVisited[0]) {
          const column: any = {};
          const nested: any = {};

          if (i === 'pagePath') {
            headerNames.push(i + 'Url');
          } else {
            headerNames.push(i);
          }
          column.name = i;
          column.nestedColumns = [];
          if (i === 'pagePath') {
            nested.name = i + 'Url';
          } else {
            nested.name = i;
          }

          nested.title = titleMap.get(i);
          nested.type = 'Text';

          column.nestedColumns.push(nested);
          pagesProps.columns.push(column);
        }

        data?.pagesVisited?.forEach((item: any) => {
          const row: any = {};
          row[headerNames[0]] = item.count;
          row[headerNames[1]] = item.pagePath;
          row[headerNames[2]] = item.eventDate.split('T')[0];
          pagesProps.rows.push(row);
        });
      }
    }

    return pagesProps;
  }

  static buildUserActivityDataFiles(data: any) {
    const downloadedProps: any = { columns: [], rows: [] };

    const headerNames: string[] = [];

    const titleMap = new Map<string, string>([
      ['count', 'Count'],
      ['fileName', 'File Name'],
      ['eventDate', 'Date'],
      ['reportType', 'Report Type'],
    ]);

    if (data) {
      if (data?.filesDownloaded) {
        // Files Downloaded Props creation
        for (const i in data?.filesDownloaded[0]) {
          const column: any = {};
          const nested: any = {};

          if (i === 'fileName') {
            headerNames.push(i + 'Url');
          } else {
            headerNames.push(i);
          }
          column.name = i;
          column.nestedColumns = [];
          if (i === 'fileName') {
            nested.name = i + 'Url';
          } else {
            nested.name = i;
          }
          nested.title = titleMap.get(i);
          nested.type = 'Text';

          column.nestedColumns.push(nested);
          downloadedProps.columns.push(column);
        }

        data?.filesDownloaded?.forEach((item: any) => {
          const row: any = {};
          row[headerNames[0]] = item.count;
          row[headerNames[1]] = item.fileName;
          row[headerNames[2]] = item.eventDate.split('T')[0];
          row[headerNames[3]] = item.reportType;
          downloadedProps.rows.push(row);
        });
      }
    }

    return downloadedProps;
  }

  static buildUserActivityDataTech(data: any) {
    const techProps: any = { columns: [], rows: [] };

    const headerNames: string[] = [];

    const titleMap = new Map<string, string>([
      ['browser', 'Browser'],
      ['eventDate', 'Date'],
      ['deviceCategory', 'Device'],
      ['mobileDeviceBranding', 'Brand'],
      ['mobileDeviceMarketingName', 'Mobile Marketing'],
      ['mobileDeviceModel', 'Model'],
      ['operatingSystemWithVersion', 'OS'],
    ]);

    if (data) {
      if (data?.technology) {
        // Files Downloaded Props creation
        for (const i in data?.technology[0]) {
          const column: any = {};
          const nested: any = {};

          headerNames.push(i);
          column.name = i;
          column.nestedColumns = [];
          nested.name = i;
          nested.title = titleMap.get(i);
          nested.type = 'Text';

          column.nestedColumns.push(nested);
          techProps.columns.push(column);
        }

        data?.technology?.forEach((item: any) => {
          const row: any = {};
          row[headerNames[0]] = item.browser;
          const newDate: any =
            item.eventDate.substring(4, 6) + '/' + item.eventDate.substring(6) + '/' + item.eventDate.substring(0, 4);
          const [month, day, year] = newDate.split('/');
          row[headerNames[1]] = new Date(+year, +month - 1, +day).toDateString();
          row[headerNames[2]] = item.deviceCategory;
          row[headerNames[3]] = item.mobileDeviceBranding;
          row[headerNames[4]] = item.mobileDeviceMarketingName;
          row[headerNames[5]] = item.mobileDeviceModel;
          row[headerNames[6]] = item.operatingSystemWithVersion;
          techProps.rows.push(row);
        });
      }
    }

    return techProps;
  }

  static buildFileResourceActivityData(data: any) {
    const props: any = { columns: [], rows: [] };

    const headerNames: string[] = [];

    const titleMap = new Map<string, string>([
      ['count', 'Count'],
      ['email', 'Email'],
      ['eventDate', 'Date'],
    ]);

    if (data) {
      if (data?.users) {
        // Files Downloaded Props creation
        for (const i in data?.users[0]) {
          const column: any = {};
          const nested: any = {};

          if (i !== 'userId') {
            if (i === 'email') {
              headerNames.push('userIdUrl');
            } else {
              headerNames.push(i);
            }
            column.name = i;
            column.nestedColumns = [];
            if (i === 'email') {
              nested.name = 'userIdUrl';
            } else {
              nested.name = i;
            }

            nested.title = titleMap.get(i);
            nested.type = 'Text';

            column.nestedColumns.push(nested);
            props.columns.push(column);
          }
        }

        data?.users?.forEach((item: any) => {
          const row: any = {};
          row[headerNames[0]] = item.count;
          row[headerNames[1]] = item.email;
          const newDate: any =
            item.eventDate.substring(4, 6) + '/' + item.eventDate.substring(6) + '/' + item.eventDate.substring(0, 4);
          const [month, day, year] = newDate.split('/');
          row[headerNames[2]] = new Date(+year, +month - 1, +day).toDateString();
          props.rows.push(row);
        });
      }
    }

    return props;
  }

  static buildPageResourceActivityData(data: any) {
    const techProps: any = { columns: [], rows: [] };

    const headerNames: string[] = [];

    const titleMap = new Map<string, string>([
      ['count', 'Count'],
      ['email', 'Email'],
      ['eventDate', 'Date'],
      ['pagePath', 'Page Path'],
      ['userId', 'User ID'],
    ]);

    if (data) {
      // Files Downloaded Props creation
      for (const i in data[0]) {
        const column: any = {};
        const nested: any = {};

        if (i !== 'userId' && i !== 'pagePath') {
          if (i === 'email') {
            headerNames.push('userIdUrl');
          } else {
            headerNames.push(i);
          }
          column.name = i;
          column.nestedColumns = [];
          if (i === 'email') {
            nested.name = 'userIdUrl';
          } else {
            nested.name = i;
          }
          nested.title = titleMap.get(i);
          nested.type = 'Text';

          column.nestedColumns.push(nested);
          techProps.columns.push(column);
        }
      }

      data?.forEach((item: any) => {
        const row: any = {};
        row[headerNames[0]] = item.count;
        row[headerNames[1]] = item.email;
        const newDate: any =
          item.eventDate.substring(4, 6) + '/' + item.eventDate.substring(6) + '/' + item.eventDate.substring(0, 4);
        const [month, day, year] = newDate.split('/');
        row[headerNames[2]] = new Date(+year, +month - 1, +day).toDateString();
        techProps.rows.push(row);
      });
    }

    return techProps;
  }

  static buildUserActivityHeaderData(data: any) {
    const headerData: any = { email: '', firstName: '', lastName: '', userId: '', accountId: '', accountName: '' };

    if (data?.pagesVisited !== 0) {
      headerData.email = data?.email;
      headerData.firstName = data?.firstName;
      headerData.lastName = data?.lastName;
      headerData.userId = data?.userId;
      headerData.accountId = data?.accountId;
      headerData.accountName = data?.accountName;
    }

    return headerData;
  }

  static buildFileResourceHeaderData(data: any) {
    const headerData: any = { fileName: '', reportType: '' };

    if (data?.users.length !== 0) {
      headerData.fileName = data?.fileName;
      headerData.reportType = data?.reportType;
    }

    return headerData;
  }

  static buildAccNameHeaderData(data: any) {
    const headerData: any = { accName: '' };

    headerData.accName = data?.accountName;

    return headerData;
  }
}

export default GaBuilders;
