import React from 'react';

import './ShowPdf.scss';
import { useLocation, useHistory } from 'react-router';
import { FileService } from '../shared/services/file/FileService';
import PageLoader from '../shared/components/loader/PageLoader';

const ShowPdf: React.FC = () => {
  const urlSearch = new URLSearchParams(useLocation().search);
  const history = useHistory();
  React.useEffect(() => {
    const fileUrl = `https://data.ipointelligence.com/api/v1/Profile/showPDF?filename=${urlSearch?.get('filename')}`;
    window.dataLayer?.push({ showPdf: urlSearch?.get('filename'), event: 'virtualPageview' });
    (async () => {
      try {
        await FileService.getAndDownload({ url: fileUrl });
        setTimeout(() => {
          window.close();
          history?.goBack();
        }, 1000);
      } catch (e) {
        window.alert('Sorry, no file found.');

        throw e;
      }
    })();
  }, []);
  return (
    <>
      <PageLoader loading={true} />
    </>
  );
};

export default ShowPdf;
