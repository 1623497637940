import React, { useCallback, useState } from 'react';
import Form from '../../shared/components/forms/Form';
import { validationSchema } from './validation-schema';
import FormGroup from '../../shared/components/forms/FormGroup';
import FormInput from '../../shared/components/forms/FormInput';
import { ChangePasswordFormFields } from './change-password-form-fields';
import { InputType } from '../../shared/components/forms/Input';
import { ControlSize } from '../../shared/enums/ControlSize';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '../../shared/constants/validation';
import AuthApiService from '../../shared/api/AuthApiService';
import { ChangePasswordFormData } from './change-password-form-data';
import ButtonSubmit from '../../shared/components/forms/ButtonSubmit';
import { SubmitFormFn } from '../../shared/components/forms/types/SubmitFormFn';

const ChangePasswordForm: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const onSubmit: SubmitFormFn<ChangePasswordFormData> = useCallback(
    data => AuthApiService.changePassword(data).then(() => setIsSubmitted(true)),
    [],
  );

  return (
    <Form validationSchema={validationSchema} onSubmit={onSubmit} size={ControlSize.Large}>
      <div>
        <b>{`Change your password (Must be between ${MIN_PASSWORD_LENGTH}-${MAX_PASSWORD_LENGTH} characters)`}</b>
      </div>

      <FormGroup>
        <FormInput
          name={ChangePasswordFormFields.OldPassword}
          label="Old Password"
          type={InputType.Password}
          isDisabled={isSubmitted}
        />
      </FormGroup>

      <FormGroup>
        <FormInput
          name={ChangePasswordFormFields.NewPassword}
          label="New Password"
          type={InputType.Password}
          autoComplete="new-password"
          isDisabled={isSubmitted}
        />
      </FormGroup>

      <FormGroup>
        <FormInput
          name={ChangePasswordFormFields.ConfirmNewPassword}
          label="Confirm New Password"
          type={InputType.Password}
          isDisabled={isSubmitted}
        />
      </FormGroup>

      <FormGroup>
        <ButtonSubmit
          isSubmitted={isSubmitted}
          message="Change Password"
          submittedMessage="Password successfully changed."
        />
      </FormGroup>
    </Form>
  );
};

export default ChangePasswordForm;
