import { FilterControlSettings } from '../../types/FilterControlSettings';
import { FormControlType } from '../../../../components/forms/components/form-builder/enums/FormControlType';
import { ControlSize } from '../../../../enums/ControlSize';
import { NewsFilterField } from '../../../../../news/enums/NewsFilterField';
import { newsStoryTypesSelectOptions } from '../../../../../news/constants/newsStoryTypesSelectOptions';
import { newsTypeSelectOptions } from '../../../../../news/constants/newsTypeSelectOptions';

export const newsFilterControls: FilterControlSettings = {
  [NewsFilterField.NewsType]: {
    type: FormControlType.Select,
    name: NewsFilterField.NewsType,
    props: {
      name: NewsFilterField.NewsType,
      label: 'News Type',
      placeholder: 'Choose News Type',
      options: newsTypeSelectOptions,
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isMultiSelect: true,
    },
  },
  [NewsFilterField.StoryType]: {
    type: FormControlType.Select,
    name: NewsFilterField.StoryType,
    props: {
      name: NewsFilterField.StoryType,
      label: 'Story Type',
      placeholder: 'Choose Story Type',
      options: newsStoryTypesSelectOptions,
      size: ControlSize.Small,
      width: ControlSize.Medium,
      isMultiSelect: true,
    },
  },
};
