import React, { useCallback, useMemo } from 'react';
import './ResearchDashboard.scss';
import PrimarySection from '../shared/components/primary-section/PrimarySection';
import PrimarySectionRow from '../shared/components/primary-section/PrimarySectionRow';
import PrimarySectionCol from '../shared/components/primary-section/PrimarySectionCol';
import { GridType } from '../shared/components/primary-section/enum/GridType';
import { RoutePath } from '../shared/enums/RoutePath';
import ResearchDashboardResearchGrid from './components/ResearchDashboardResearchGrid';
import ResearchDashboardAnalystNotesGrid from './components/ResearchDashboardAnalystNotesGrid';
import ResearchDashboardProfilesGrid from './components/ResearchDashboardProfilesGrid';
import ResearchDashboardSpecialReportsGrid from './components/ResearchDashboardSpecialReportsGrid';
import ViewDashboard from '../shared/entities/view/components/view-dashboard/ViewDashboard';
import { ResearchApiService } from '../shared/api/ResearchApiService';
import { NewsLocationService } from '../news/shared/services/NewsLocationService';
import { NewsFilterField } from '../news/enums/NewsFilterField';
import { NewsType } from '../news/enums/NewsType';
import ResearchDashboardAnnualRatingsPerformanceChart from './components/ResearchDashboardAnnualRatingsPerformanceChart';
import { AlignTypesHorizontal } from '../shared/enums/AlignTypesHorizontal';
import { Link } from 'react-router-dom';
import DocumentHead from '../shared/components/DocumentHead';
import ViewDashboardSecondarySection from '../shared/entities/view/components/view-dashboard/components/ViewDashboardSecondarySection';
import { useClassName } from '../shared/hooks/useClassName';
import { ResearchDashboardBlock, ResearchDashboardElement } from './researchDashboardBem';
import ResearchDashboardEnhancedProfilesGrid from './components/ResearchDashboardEnhancedProfilesGrid';

const ResearchDashboard: React.FC = () => {
  const cn = useClassName(ResearchDashboardBlock.Root);

  const getDataFn = useCallback(() => ResearchApiService.getDashboard(), []);

  const recentAnalystNotesRoutePath = useMemo(
    () =>
      NewsLocationService.createSearchUrl({
        [NewsFilterField.NewsType]: [NewsType.AnalystNote, NewsType.Blog],
      }),
    [],
  ) as RoutePath;

  return (
    <PrimarySection
      className={cn()}
      header="Research Dashboard"
      additionalAction={{
        alignTypeHorizontal: AlignTypesHorizontal.End,
        component: <Link to={RoutePath.ResearchRatingsSystem}>Ratings System</Link>,
      }}
    >
      <DocumentHead path={RoutePath.ResearchDashboard} />

      <ViewDashboard getDataFn={getDataFn}>
        <PrimarySectionRow gridType={GridType.AutoCol}>
          <PrimarySectionCol>
            <ViewDashboardSecondarySection title="Recent IPO Research" routePath={RoutePath.ResearchRecent}>
              <ResearchDashboardResearchGrid />
            </ViewDashboardSecondarySection>
          </PrimarySectionCol>

          <PrimarySectionCol>
            <ViewDashboardSecondarySection title="Recent Analyst Notes" routePath={recentAnalystNotesRoutePath}>
              <ResearchDashboardAnalystNotesGrid />
            </ViewDashboardSecondarySection>
          </PrimarySectionCol>
        </PrimarySectionRow>

        <PrimarySectionRow gridType={GridType.AutoCol}>
          <PrimarySectionCol>
            <ViewDashboardSecondarySection title="Recently Enhanced Profiles">
              <ResearchDashboardEnhancedProfilesGrid />
            </ViewDashboardSecondarySection>
          </PrimarySectionCol>

          <PrimarySectionCol>
            <ViewDashboardSecondarySection title="Special Reports" routePath={RoutePath.ResearchSpecialReports}>
              <ResearchDashboardSpecialReportsGrid />
            </ViewDashboardSecondarySection>
          </PrimarySectionCol>
        </PrimarySectionRow>

        <PrimarySectionRow gridType={GridType.AutoCol}>
          <PrimarySectionCol>
            <ViewDashboardSecondarySection title="Recently Updated Profiles">
              <ResearchDashboardProfilesGrid />
            </ViewDashboardSecondarySection>
          </PrimarySectionCol>
        </PrimarySectionRow>

        <PrimarySectionRow
          className={cn(ResearchDashboardElement.TrackRecord)}
          alignHorizontal={AlignTypesHorizontal.Center}
        >
          <ViewDashboardSecondarySection title="Renaissance Research Track Record">
            <ResearchDashboardAnnualRatingsPerformanceChart />
          </ViewDashboardSecondarySection>
        </PrimarySectionRow>
      </ViewDashboard>
    </PrimarySection>
  );
};

export default ResearchDashboard;
