export enum FilterSectionType {
  Date = 'Date',
  DealStatus = 'Deal Status',
  OfferDate = 'Offer Date',
  FileDate = 'File Date',
  DealTiming = 'Deal Timing',
  CompanyInfo = 'Company Info',
  Investors = 'Investors',
  SecurityType = 'Security/Offering Type',
  Returns = 'Returns',
  DealInfo = 'Deal Info',
  Location = 'Listing Location',
  Favorite = 'Favorite',
  RenaissanceRating = 'Renaissance Rating',
  Financials = 'Financials',
  Valuation = 'Valuation',
  ActionDate = 'Action Date',
}
