import React, { useContext, useMemo } from 'react';
import './ScreenTriggerCell.scss';
import { GridCellFormatterCommonProps } from '../../../../../../../shared/components/grid/shared/interfaces/gridCellFormatter';
import { ScreenTriggerCellBlock } from './ScreensTriggerCellBem';
import CustomCellFormatterWrapper from '../../../../../../../shared/components/grid/shared/components/formatters/cell/CustomCellFormatterWrapper';
import { useClassName } from '../../../../../../../shared/hooks/useClassName';
import { ScreenTriggerOptions } from '../../../interfaces/ScreenTriggerOptions';
import ViewLink from '../../../../../../../shared/entities/view/components/view-link/ViewLink';
import { ViewTypeScreen } from '../../../../../../../shared/entities/view/components/view/types/screen/ViewTypeScreen';
import { ScreenOptionsService } from '../../../services/ScreenOptionsService';
import { ViewContext } from '../../../../../../../shared/entities/view/components/view/ViewContext';
import { ViewGridCellValueService } from '../../../../../../../shared/entities/view/components/view-grid/services/ViewGridCellValueService';

interface Props extends GridCellFormatterCommonProps {
  triggerOptions: ScreenTriggerOptions;
  screenType: ViewTypeScreen;
  isLanding: boolean;
}

const ScreenTriggerCell: React.FC<Props> = props => {
  const cn = useClassName(ScreenTriggerCellBlock.Root);
  const { state } = useContext(ViewContext);

  const linkData = useMemo(
    () =>
      ScreenOptionsService.getInUseViewData(
        props.data,
        props.screenType,
        props.isLanding,
        state.filters,
        props.triggerOptions,
      ),
    [props.data, props.screenType, props.isLanding, props.triggerOptions, state.filters],
  );

  const title = useMemo(() => ViewGridCellValueService.getVisible(props.data, props.field), [props.data, props.field]);

  return (
    <CustomCellFormatterWrapper className={cn()} value={props.value}>
      <ViewLink title={title} data={linkData} />
    </CustomCellFormatterWrapper>
  );
};

export default ScreenTriggerCell;
