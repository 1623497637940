import React, { useContext } from 'react';
import { ViewDashboardContext } from '../../shared/entities/view/components/view-dashboard/ViewDashboardContext';
import { ResearchDashboardDto } from '../interfaces/ResearchDashboardDto';
import { ResearchDashboardProp } from '../enums/ResearchDashboardProp';
import NewsLinksGrid from '../../news/components/NewsLinksGrid';

const ResearchDashboardAnalystNotesGrid: React.FC = () => {
  const context = useContext(ViewDashboardContext);
  const data = context.data as ResearchDashboardDto;

  return <NewsLinksGrid rows={data[ResearchDashboardProp.AnalystNotes]} />;
};

export default ResearchDashboardAnalystNotesGrid;
