import { CompanyTab } from '../enums/CompanyTab';
import { CompanyTabsPath } from '../enums/CompanyTabsPath';

export const companyTabPathToName: { [key in CompanyTab]: CompanyTabsPath } = {
  [CompanyTab.Profile]: CompanyTabsPath.Profile,
  [CompanyTab.InvestmentRationale]: CompanyTabsPath.InvestmentRationale,
  [CompanyTab.FundamentalInsights]: CompanyTabsPath.FundamentalInsights,
  [CompanyTab.Financials]: CompanyTabsPath.Financials,
  [CompanyTab.MgmtQs]: CompanyTabsPath.MgmtQs,
  [CompanyTab.MgmtOwners]: CompanyTabsPath.MgmtOwners,
  [CompanyTab.RelativeValuation]: CompanyTabsPath.RelativeValuation,
  [CompanyTab.PeerTrading]: CompanyTabsPath.PeerTrading,
  [CompanyTab.TeamNotes]: CompanyTabsPath.TeamNotes,
  [CompanyTab.Milestones]: CompanyTabsPath.Milestones,
};
