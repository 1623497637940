import React from 'react';
import BootstrapTab from 'react-bootstrap/Tab';

interface Props {
  title?: React.ReactNode;
  eventKey?: string;
}

const Tab: React.FC<Props> = props => {
  return (
    <BootstrapTab eventKey={props.eventKey} title={props.title}>
      {props.children}
    </BootstrapTab>
  );
};

export default Tab;
