import React, { useMemo } from 'react';
import CompanyProfileTable, { CompanyProfileTableProps } from './CompanyProfileTable';
import { useCompanyProfileSidebarTableProps } from './hooks/useCompanyProfileSidebarTableProps';
import { GridAgColumn } from '../../../../../../shared/components/grid/grid/interfaces/GridAgColumn';
import { ViewColumnSystemName } from '../../../../../../shared/entities/view/components/view-grid/enums/ViewColumnSystemName';
import { ViewGridColumnWidth } from '../../../../../../shared/entities/view/components/view-grid/enums/ViewGridColumnWidth';

type Props = CompanyProfileTableProps;

const CUSTOM_COLUMNS_SETTINGS: Partial<GridAgColumn>[] = [
  {
    field: ViewColumnSystemName.Date,
    minWidth: ViewGridColumnWidth.Sx,
    width: ViewGridColumnWidth.Sx,
  },
];

const CompanyProfileSidebarTable: React.FC<Props> = props => {
  const { customColumnsSettings, isColumnsAlignEnd = true } = props;

  const tableProps = useCompanyProfileSidebarTableProps();

  const updatedCustomColumnsSettings = useMemo(() => {
    let settings = [...CUSTOM_COLUMNS_SETTINGS];

    if (customColumnsSettings) {
      settings = [...settings, ...customColumnsSettings];
    }

    return settings;
  }, [customColumnsSettings]);

  return (
    <CompanyProfileTable
      {...props}
      {...tableProps}
      isColumnsAlignEnd={isColumnsAlignEnd}
      customColumnsSettings={updatedCustomColumnsSettings}
    />
  );
};

export default CompanyProfileSidebarTable;
