import React, { Component } from 'react';
import { GridCellFormatterCommonProps } from '../../../interfaces/gridCellFormatter';
import { LinkColumnValue } from '../../../../../../entities/view/components/view-grid/interfaces/column-values/LinkColumnValue';
import Hyperlink from '../../../../../typography/Hyperlink';
import CustomCellFormatterWrapper from './CustomCellFormatterWrapper';

interface Props extends GridCellFormatterCommonProps {
  value: LinkColumnValue;
}

class LinkCellFormatter extends Component<Props> {
  render() {
    return (
      <CustomCellFormatterWrapper value={this.props.value}>
        <Hyperlink isExternal to={this.props.value.link}>
          {this.props.value.title}
        </Hyperlink>
      </CustomCellFormatterWrapper>
    );
  }
}

export default LinkCellFormatter;
